import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';

import { MaterialIcon } from '@components';

import { AuditEventsQuery } from '@api/gql/graphql';

export const BEFORE_PAGE_PARAM = 'before';
export const AFTER_PAGE_PARAM = 'after';

export interface AuditEventsPaginationProps {
  loading: boolean;
  pageInfo: AuditEventsQuery['auditEvents']['pageInfo'];
  firstCursor: string;
  lastCursor: string;
}

export default function AuditEventsPagination({
  loading: isFetching,
  pageInfo,
  firstCursor,
  lastCursor,
}: AuditEventsPaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!isFetching) {
      window.scrollTo(0, 0);
    }
  }, [isFetching]);

  const paginate = (before: string | null, after: string | null) => {
    const newSP = new URLSearchParams(searchParams);

    if (before) newSP.set(BEFORE_PAGE_PARAM, before);
    else newSP.delete(BEFORE_PAGE_PARAM);

    if (after) newSP.set(AFTER_PAGE_PARAM, after);
    else newSP.delete(AFTER_PAGE_PARAM);

    setSearchParams(newSP);
  };

  const handleFetchPreviousPage = () => {
    paginate(firstCursor, null);
  };

  const handleFetchNextPage = () => {
    paginate(null, lastCursor);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <div>
        {pageInfo.hasPreviousPage && (
          <LoadingButton
            startIcon={<MaterialIcon symbol="arrow_back" weight={600} />}
            onClick={handleFetchPreviousPage}
            loading={isFetching}
          >
            Previous Page
          </LoadingButton>
        )}
      </div>
      <div>
        {pageInfo.hasNextPage && (
          <LoadingButton
            endIcon={<MaterialIcon symbol="arrow_forward" weight={600} />}
            onClick={handleFetchNextPage}
            loading={isFetching}
          >
            Next Page
          </LoadingButton>
        )}
      </div>
    </Stack>
  );
}
