import { createContext, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Paper,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useDeleteUserToken, useListUserTokens, UserPersonalToken } from '@api';
import { ConfirmDialog, EmptyState, Loader, NoResults, Page, ProButton, SearchBar } from '@components';
import { DateTime, DocsUrls, Flag } from '@utils';
import { LoadingButton } from '@mui/lab';
import { routes } from '@routes';

interface PersonalApiTokensContextProps {
  apiTokens: UserPersonalToken[];
  fetchTokens: () => Promise<void>;
  filter: string;
  handleFilterChange: (val: string) => void;
  filteredTokens: UserPersonalToken[];
}

const PersonalApiTokensContext = createContext<PersonalApiTokensContextProps>({
  apiTokens: [],
  fetchTokens: () => Promise.resolve(),
  filter: '',
  handleFilterChange: () => {
    // noop
  },
  filteredTokens: [],
});

const usePersonalApiTokensContext = () => {
  return useContext(PersonalApiTokensContext);
};

function usePersonalApiTokensPage() {
  const [filter, setFilter] = useState<string>('');
  const [filteredTokens, setFilteredTokens] = useState<UserPersonalToken[]>([]);

  const handleFilterChange = (val: string) => setFilter(val);

  const { apiTokens, isApiTokensFetched } = useListUserTokens();

  useEffect(() => {
    const filtered = apiTokens.filter((token) => {
      const filterText = filter.toLowerCase();

      const foundByName = token.name.toLowerCase().includes(filterText);

      const prefix = filterText.slice(0, 4);
      const suffix = filterText.slice(-4);
      const foundByToken = token.masked_token.startsWith(prefix) && token.masked_token.endsWith(suffix);

      return foundByName || foundByToken;
    });
    setFilteredTokens(filtered);
  }, [apiTokens, filter]);

  return {
    isFetched: isApiTokensFetched,
    apiTokens,
    fetchTokens: () => Promise.resolve(),
    filter,
    handleFilterChange,
    filteredTokens,
  };
}

export function PersonalApiTokensPage() {
  return (
    <Page
      title="Personal API Tokens"
      button={
        <ProButton
          flag={Flag.NEW_UI_PERSONAL_TOKENS}
          variant="contained"
          component={Link}
          to={routes.AddPersonalApiToken.path}
          data-testid={'add-api-token-button'}
        >
          Add API Token
        </ProButton>
      }
      link={{
        label: 'API Reference',
        href: DocsUrls.PERSONAL_API_TOKENS,
        testId: 'api-reference-link',
      }}
    >
      <PersonalApiTokensPageContent />
    </Page>
  );
}

function PersonalApiTokensPageContent() {
  const props = usePersonalApiTokensPage();
  const { filteredTokens, apiTokens, handleFilterChange, isFetched } = props;

  if (!isFetched) {
    return <Loader />;
  }

  if (apiTokens.length === 0) {
    return (
      <EmptyState
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
        body={`Apono API Tokens can be used for integration with external tools and working with the Apono API. Tokens are
      specific to users. For more information on using the Apono API, see [this reference](
      ${DocsUrls.PERSONAL_API_TOKENS}).`}
        action={
          <ProButton
            flag={Flag.NEW_UI_PERSONAL_TOKENS}
            component={Link}
            to="/personal-api-tokens/add"
            data-testid="add-api-token-button"
            variant="contained"
          >
            Create Token
          </ProButton>
        }
      />
    );
  }

  return (
    <PersonalApiTokensContext.Provider value={props}>
      <Stack spacing={2}>
        <SearchBar total={filteredTokens.length} onChange={handleFilterChange} />
        <TokensTable />
      </Stack>
    </PersonalApiTokensContext.Provider>
  );
}

function TokensTable() {
  const { filteredTokens } = usePersonalApiTokensContext();

  if (filteredTokens.length === 0) {
    return <NoResults>No tokens found</NoResults>;
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <MuiTable size="small">
        <THead />
        <TableBody>
          {filteredTokens.map((apiToken) => (
            <TRow key={apiToken.id} apiToken={apiToken} />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

function THead() {
  const columns = ['Name', 'Token', 'Created Date', ''];

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell component="th" size="medium" key={`th-${index}`}>
            {column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TRowProps {
  apiToken: UserPersonalToken;
}

function TRow({ apiToken }: TRowProps) {
  return (
    <TableRow>
      <TableCell width={300}>
        <Typography variant="body2">{apiToken.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{apiToken.masked_token}</Typography>
      </TableCell>
      <TableCell width={200}>
        <Typography variant="body2">{DateTime.fromUnix(Number(apiToken.created_date)).readable}</Typography>
      </TableCell>
      <TableCell align="right" width={150}>
        <RowAction apiToken={apiToken} />
      </TableCell>
    </TableRow>
  );
}

function useRowAction(apiToken: TRowProps['apiToken']) {
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);
  const { deleteUserToken, isDeleteTokenLoading } = useDeleteUserToken();

  const openConfirmDelete = () => setIsConfirmDeleteOpened(true);
  const closeConfirmDelete = () => setIsConfirmDeleteOpened(false);

  const handleDelete = () => {
    closeConfirmDelete();
    deleteUserToken(apiToken.id);
  };

  return {
    isConfirmDeleteOpened,
    openConfirmDelete,
    closeConfirmDelete,
    handleDelete,
    isDeleteLoading: isDeleteTokenLoading,
  };
}

function RowAction({ apiToken }: TRowProps) {
  const { isConfirmDeleteOpened, openConfirmDelete, closeConfirmDelete, handleDelete, isDeleteLoading } =
    useRowAction(apiToken);

  return (
    <>
      <LoadingButton variant="outlined" size="small" onClick={openConfirmDelete} loading={isDeleteLoading}>
        Delete
      </LoadingButton>
      <ConfirmDialog open={isConfirmDeleteOpened} onClose={closeConfirmDelete} onConfirm={handleDelete}>
        Are you sure you want to delete <strong>{apiToken.name}</strong> token?
      </ConfirmDialog>
    </>
  );
}
