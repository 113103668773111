export function getSeverity(severity: number) {
  switch (severity) {
    case 10:
      return 'Low';
    case 20:
      return 'Medium';
    case 30:
    default:
      return 'High';
  }
}
