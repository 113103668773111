import {
  AccessFlowsService,
  AccessFlowUpsertAppModel,
  AccessFlowUpsertAppModelV2,
  SetAccessFlowActiveFlagRequestV2,
  TriggerTypeAppModel,
} from '@api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AccessFlowsQueryKeys, AccessFlowsQueryKeysV2 } from './queryKeys';

export function useAppChangeAccessFlowActiveFlag(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: appChangeAccessFlowActiveFlag,
    data: changedAccessFlow,
    ...rest
  } = useMutation({
    mutationFn: async (accessFlowSetActiveAppRequest: SetAccessFlowActiveFlagRequestV2) =>
      AccessFlowsService.appChangeAccessFlowActiveFlag({
        id,
        accessFlowSetActiveAppRequest,
      }),
    onSuccess: () => queryClient.invalidateQueries([AccessFlowsQueryKeys.List]),
  });

  return {
    appChangeAccessFlowActiveFlag,
    changedAccessFlow,
    ...rest,
  };
}

export function useAppCreateAccessFlow() {
  const queryClient = useQueryClient();
  const {
    mutate: createAccessFlow,
    data: createdAccessFlow,
    ...rest
  } = useMutation({
    mutationFn: async (accessFlowUpsertAppModel: AccessFlowUpsertAppModel) => {
      if (accessFlowUpsertAppModel.trigger_type === TriggerTypeAppModel.AutoGrant) {
        accessFlowUpsertAppModel.approvers = [];
        accessFlowUpsertAppModel.timeframe = undefined;
      }
      return AccessFlowsService.appCreateAccessFlow({
        accessFlowUpsertAppModel,
      });
    },
    onSuccess: () => queryClient.invalidateQueries([AccessFlowsQueryKeys.List]),
  });

  return {
    createAccessFlow,
    createdAccessFlow,
    ...rest,
  };
}

export function useAppDeleteAccessFlow(id: string) {
  const queryClient = useQueryClient();

  const { mutate: deleteAccessFlow, ...rest } = useMutation({
    mutationFn: async () => AccessFlowsService.appDeleteAccessFlow({ id }),
    onSuccess: () => queryClient.invalidateQueries([AccessFlowsQueryKeys.List]),
  });

  return {
    deleteAccessFlow,
    ...rest,
  };
}

// --------------------------------- V2 ---------------------------------

export function useAppChangeAccessFlowActiveFlagV2(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: appChangeAccessFlowActiveFlag,
    data: changedAccessFlow,
    ...rest
  } = useMutation({
    mutationFn: async (accessFlowSetActiveAppRequest: SetAccessFlowActiveFlagRequestV2) =>
      AccessFlowsService.appChangeAccessFlowActiveFlagV2({
        id,
        accessFlowSetActiveAppRequest,
      }),
    onSuccess: () => queryClient.invalidateQueries([AccessFlowsQueryKeysV2.List]),
  });

  return {
    appChangeAccessFlowActiveFlag,
    changedAccessFlow,
    ...rest,
  };
}

export function useAppCreateAccessFlowV2() {
  const queryClient = useQueryClient();
  const {
    mutate: createAccessFlow,
    data: createdAccessFlow,
    ...rest
  } = useMutation({
    mutationFn: async (accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2) => {
      if (accessFlowUpsertAppModelV2.trigger_type === TriggerTypeAppModel.AutoGrant) {
        accessFlowUpsertAppModelV2.approvers = [];
        accessFlowUpsertAppModelV2.timeframe = undefined;
      }
      return AccessFlowsService.appCreateAccessFlowV2({
        accessFlowUpsertAppModelV2,
      });
    },
    onSuccess: () => queryClient.invalidateQueries([AccessFlowsQueryKeysV2.List]),
  });

  return {
    createAccessFlow,
    createdAccessFlow,
    ...rest,
  };
}

export function useAppDeleteAccessFlowV2(id: string) {
  const queryClient = useQueryClient();

  const { mutate: deleteAccessFlow, ...rest } = useMutation({
    mutationFn: async () => AccessFlowsService.appDeleteAccessFlowV2({ id }),
    onSuccess: () => queryClient.invalidateQueries([AccessFlowsQueryKeysV2.List]),
  });

  return {
    deleteAccessFlow,
    ...rest,
  };
}

export function useUpdateAccessFlowFlowV2(id: string) {
  const queryClient = useQueryClient();

  const { mutate: updateAccessFlow, ...rest } = useMutation({
    mutationFn: async (accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2) =>
      AccessFlowsService.appUpdateAccessFlowV2({
        id,
        accessFlowUpsertAppModelV2,
      }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [AccessFlowsQueryKeysV2.Details, id] }),
  });

  return {
    updateAccessFlow,
    ...rest,
  };
}
