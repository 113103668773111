import { ConnectedSubIntegrationResourceTypesAppModel, IntegrationConfigAppModelV2 } from '@api';
import { SelectResourceTypes } from '@organisms/IntegrationConnectorForm/SelectResourceTypes';

export function SubIntegrationsResourceTypes({
  onChange,
  integrationConfig,
  value,
  disabled,
}: {
  onChange: (obj: ConnectedSubIntegrationResourceTypesAppModel) => void;
  integrationConfig: IntegrationConfigAppModelV2;
  value?: ConnectedSubIntegrationResourceTypesAppModel;
  disabled?: boolean;
}) {
  const handleSubResourceTypesChange = (item: ConnectedSubIntegrationResourceTypesAppModel) => {
    onChange(item);
  };

  return <SubResourceTypesList config={integrationConfig} selected={value} onSelect={handleSubResourceTypesChange} />;
}

function SubResourceTypesList({
  config,
  selected,
  onSelect,
  disabled,
}: {
  config: IntegrationConfigAppModelV2;
  selected?: ConnectedSubIntegrationResourceTypesAppModel;
  onSelect: (item: ConnectedSubIntegrationResourceTypesAppModel) => void;
  disabled?: boolean;
}) {
  const onSelectByConfig = (selectedResourceTypes: string[]) => {
    const item = {
      integration_type: config.type,
      connected_resource_types: selectedResourceTypes,
    } as ConnectedSubIntegrationResourceTypesAppModel;
    onSelect(item);
  };

  return (
    <SelectResourceTypes
      config={config}
      selected={selected?.connected_resource_types ?? []}
      onSelect={onSelectByConfig}
      disabled={disabled}
    />
  );
}
