import { ReactNode, isValidElement } from 'react';
import { styled, Box, Paper, Stack, Typography } from '@mui/material';

interface RequestDetailProps {
  label: string;
  value: ReactNode | string;
  noDivider?: boolean;
}

interface RequestDetailsProps {
  details: RequestDetailProps[];
}

const PaperDetailItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  '&:not(:last-child)': {
    '&:not(.no-divider)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },

  '& .paper-detail-key': {
    color: theme.palette.text.muted,
    width: '150px',
    display: 'block',
  },

  '& .paper-detail-value': {
    width: '350px',
    display: 'block',
    wordBreak: 'break-all',
  },
}));

export function PaperDetails({ details }: RequestDetailsProps) {
  return (
    <Paper>
      {details.map((d) => (
        <PaperDetail key={d.label} {...d} />
      ))}
    </Paper>
  );
}

export function PaperDetail({ label, value, noDivider }: RequestDetailProps) {
  return (
    <PaperDetailItem className={noDivider ? 'no-divider' : ''}>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Typography variant="inputLabel" className="paper-detail-key">
          {label}:
        </Typography>
        {isValidElement(value) ? (
          value
        ) : (
          <Typography variant="caption" className="paper-detail-value">
            {value}
          </Typography>
        )}
      </Stack>
    </PaperDetailItem>
  );
}
