import { FilterProps } from '@common/organisms/CommonFilters/types';
import { AnalyticsTriggerElementProps } from '@common/types';
import { useMemo, useState } from 'react';
import { useAlertResourceTypeFilterOptions } from '@AccessAlerts/services/use-alerts-filter-options';
import { getFragmentData } from '@api/gql';
import GenericAsyncFilterMultiSelect from '@common/organisms/CommonFilters/GenericAsyncFilterMultiSelect';
import { RESOURCE_TYPE_FRAGMENT } from '../../AccessRightSizing/services/gql';

export const RESOURCE_TYPE_FILTER_LABEL = 'Resource Type';
export const RESOURCE_TYPE_FILTER_PARAM = 'resourceType';

export function AlertResourceTypeFilter({
  opened,
  onClose,
  onOpen,
  selected,
  onSelect,
  onClear,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps) {
  const [enabled] = useState(() => selected.length > 0);

  const { data, isFetched, refetch } = useAlertResourceTypeFilterOptions(enabled);

  const options = useMemo(() => {
    return (
      data?.alertsFilterOptions.resourceTypes.map((i) => {
        const resourceType = getFragmentData(RESOURCE_TYPE_FRAGMENT, i);
        return {
          value: resourceType.type,
          label: resourceType.typeDisplayName,
          icon: resourceType.icons.svg,
        };
      }) || []
    );
  }, [data]);

  return (
    <GenericAsyncFilterMultiSelect
      opened={opened}
      onClear={onClear}
      onClose={onClose}
      options={options}
      filterLabel={RESOURCE_TYPE_FILTER_LABEL}
      filterParam={RESOURCE_TYPE_FILTER_PARAM}
      isOptionsFetched={isFetched}
      refetchOptions={refetch}
      {...analyticsProps}
    />
  );
}
