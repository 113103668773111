import { createContext, useMemo, useState } from 'react';

import { Edge, Node, ReactFlow } from 'reactflow';
import { alpha, Backdrop, Box, Button, CircularProgress, styled } from '@mui/material';

import 'reactflow/dist/style.css';
import { useAccessGraph } from './services/use-access-graph';
import { NodesBuilder } from './nodes-builder';
import { EdgeTypes, NodeTypes } from './components/node/node_and_edge_types';
import {
  isAccessTargetNode,
  isActiveAccessEdge,
  isAndConditionNode,
  isAvailableAccessEdge,
  isOrConditionNode,
  isResourceMoreNode,
  isResourceNode,
  isUserMoreNode,
} from './node-type';
import { AccessGraphFilterInput, AccessGraphNode, AccessGraphTakeInput } from '@api/gql/graphql';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { EmptyState } from '@components';
import { routes } from '@routes';
import { AccessGraphLegend } from './components/AccessGraphLegend';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

const MapContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  backgroundColor: theme.palette.background.active,
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.background.active, 0.5),
  zIndex: 5,
}));

export const StyledMap = styled(ReactFlow)(({ theme }) => ({
  '& .react-flow__handle': {
    backgroundColor: theme.palette.background.active,
    borderColor: theme.palette.border.button,
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
}));

export const SP_FILTER_PREFIX = 'filter.';
export const SP_TAKE_PREFIX = 'take.';

const TAKE_CONSTANT = 10;

export function searchParamsToFilters(searchParams: URLSearchParams) {
  const take: AccessGraphTakeInput[] = [];
  const filter: AccessGraphFilterInput[] = [];

  searchParams.forEach((value, key) => {
    if (key.startsWith(SP_TAKE_PREFIX)) {
      const parentNodeId = key.replace(SP_TAKE_PREFIX, '');
      take.push({ parentNodeId, takeChildren: parseInt(value, 10) });
    } else if (key.startsWith(SP_FILTER_PREFIX)) {
      const nodeId = key.replace(SP_FILTER_PREFIX, '');
      const newFilter = value.length > 0 ? { nodeId, childNodeId: value } : { nodeId };
      filter.push(newFilter);
    }
  });

  return { filter, take };
}

function useAccessMapContent(
  setAccessFlows: React.Dispatch<React.SetStateAction<string[]>>,
  setRequestIds: React.Dispatch<React.SetStateAction<string[]>>,
  filterAvailableAccessEdges: boolean,
  filterActiveAccessNodes: boolean,
) {
  const { track } = useAnalyticsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filter, take } = searchParamsToFilters(searchParams);
  const { data, isFetching: isActiveAccessLoading } = useAccessGraph({
    filter: filter.length ? filter : null,
    take: take.length ? take : null,
    onlyActiveAccess: filterActiveAccessNodes,
  });

  const { accessGraph } = data || {
    accessGraph: {
      nodes: [],
      edges: [],
    },
  };
  const { nodes, edges } = accessGraph;

  const builder = useMemo(() => new NodesBuilder(edges, nodes), [edges, nodes]);

  function handleEdgeClick(event: React.MouseEvent<Element, MouseEvent>, edge: Edge) {
    const flowEdge = builder.getEdge(edge.id);
    if (flowEdge) {
      if (isAvailableAccessEdge(flowEdge) && flowEdge.availableAccess) {
        setAccessFlows(flowEdge.availableAccess.accessFlowsIds);
        track(CustomAnalyticsEvents.ACCESS_GRAPH_AVAILABLE_ACCESS_EDGE_CLICKED);
      }
      if (isActiveAccessEdge(flowEdge) && flowEdge.activeAccess) {
        setRequestIds(flowEdge.activeAccess.requestIds);
        track(CustomAnalyticsEvents.ACCESS_GRAPH_ACTIVE_ACCESS_EDGE_CLICKED);
      }
    }
  }

  function handleGroupedClick(event: React.MouseEvent<Element, MouseEvent>, node: AccessGraphNode) {
    const clickedElem = event.target as HTMLElement;

    if (clickedElem.id !== 'expand-btn') {
      return;
    }

    if ((node && isAccessTargetNode(node)) || isOrConditionNode(node) || isAndConditionNode(node)) {
      const newSearchParams = new URLSearchParams(searchParams);
      if (node.children === undefined || node.children?.length === 0) {
        // change filter. append to existing filter
        newSearchParams.delete(`${SP_TAKE_PREFIX}${node.id}`);
        take.push({ parentNodeId: node.id, takeChildren: TAKE_CONSTANT });
        newSearchParams.set(`${SP_TAKE_PREFIX}${node.id}`, TAKE_CONSTANT.toString());
      } else {
        newSearchParams.delete(`${SP_TAKE_PREFIX}${node.id}`);
        take.push({ parentNodeId: node.id, takeChildren: 0 });
      }
      setSearchParams(newSearchParams);
    }
  }

  function handleNodeClick(event: React.MouseEvent<Element, MouseEvent>, node: Node) {
    const flowNode = builder.getNode(node.id);

    if (flowNode === undefined) return;

    if (isAccessTargetNode(node.data.node) || isOrConditionNode(node.data.node) || isAndConditionNode(node.data.node)) {
      return handleGroupedClick(event, node.data.node);
    }

    if (isResourceNode(node.data.node)) return;

    const newSearchParams = new URLSearchParams(searchParams);

    let name = `${SP_FILTER_PREFIX}${flowNode.id}`;
    let value = node.id;

    if ((node.data.node && isResourceMoreNode(node.data.node)) || isUserMoreNode(node.data.node)) {
      name = `${SP_TAKE_PREFIX}${flowNode.id}`;

      const qpLimit = searchParams.get(name);
      value = TAKE_CONSTANT.toString();
      if (qpLimit) {
        value = (parseInt(qpLimit, 10) + TAKE_CONSTANT).toString();
      }
    }

    newSearchParams.set(name, value);

    setSearchParams(newSearchParams);
  }

  return {
    isLoading: isActiveAccessLoading,
    nodes: builder.getFlowNodes(),
    edges: filterAvailableAccessEdges
      ? builder.getFlowEdges().filter((edge) => {
          return edge.data?.edge ? isActiveAccessEdge(edge.data?.edge) : false;
        })
      : builder.getFlowEdges(),
    handleEdgeClick,
    handleNodeClick,
  };
}

export const EdgeHoverContext = createContext<Edge | null>(null);

export function AccessGraphMap({
  setAccessFlows,
  setRequestIds,
}: {
  setAccessFlows: React.Dispatch<React.SetStateAction<string[]>>;
  setRequestIds: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const [filterAvailableAccessEdges, setFilterAvailableAccessEdges] = useState<boolean>(false);
  const [filterActiveAccessNodes, setFilterActiveAccessNodes] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const { nodes, edges, isLoading, handleEdgeClick, handleNodeClick } = useAccessMapContent(
    setAccessFlows,
    setRequestIds,
    filterAvailableAccessEdges,
    filterActiveAccessNodes,
  );

  const [hoveredEdge, setHoveredEdge] = useState<Edge | null>(null);

  const navigate = useNavigate();

  const handleClickEmptyState = () => {
    navigate(generatePath(routes.AccessFlows.path));
  };

  const allowToggleShowAvailableAccess = edges
    ? edges.some((edge) => (edge.data?.edge ? isActiveAccessEdge(edge.data.edge) : false))
    : false;

  if (!isLoading && searchParams.size == 0 && !nodes.length) {
    return (
      <MapContainer>
        <EmptyState
          title="You have no Apono Access"
          imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
          action={
            <Button onClick={handleClickEmptyState} variant="contained">
              Create Access Flow
            </Button>
          }
        />
      </MapContainer>
    );
  }

  return (
    <EdgeHoverContext.Provider value={hoveredEdge}>
      <MapContainer>
        <MapLoader isLoading={isLoading} />
        {!isLoading && nodes.length === 0 && (
          <MapContainer>
            <EmptyState
              title="No results match your current filters. Please refine your search."
              imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
            />
          </MapContainer>
        )}
        <StyledMap
          nodes={nodes}
          edges={edges}
          nodeTypes={NodeTypes}
          edgeTypes={EdgeTypes}
          nodesDraggable={false}
          nodesConnectable={false}
          zoomOnDoubleClick={false}
          onEdgeClick={handleEdgeClick}
          onEdgeMouseEnter={(event, edge) => {
            setHoveredEdge(edge);
          }}
          onEdgeMouseLeave={() => {
            setHoveredEdge(null);
          }}
          onNodeClick={handleNodeClick}
          proOptions={{ hideAttribution: true }}
        ></StyledMap>

        <AccessGraphLegend
          filterAvailableAccessEdges={filterAvailableAccessEdges}
          filterActiveAccessNodes={filterActiveAccessNodes}
          setFilterAvailableAccessEdges={setFilterAvailableAccessEdges}
          setFilterActiveAccessNodes={setFilterActiveAccessNodes}
          allowAvailableAccessToggle={allowToggleShowAvailableAccess}
        />
      </MapContainer>
    </EdgeHoverContext.Provider>
  );
}

function MapLoader({ isLoading }: { isLoading: boolean }) {
  return (
    <StyledBackdrop open={isLoading}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
}
