import { TableCell, TableHead, TableRow } from '@mui/material';

export function TableHeader() {
  return (
    <TableHead data-testid="access-alerts-table-header">
      <TableRow>
        <TableCell component="th" size="medium" width="10%">
          DETECTION TIME
        </TableCell>
        <TableCell component="th" size="medium">
          TYPE
        </TableCell>
        <TableCell component="th" size="medium">
          GRANTEE
        </TableCell>
        <TableCell component="th" size="medium">
          RESOURCE TYPES
        </TableCell>
        <TableCell component="th" size="medium">
          SEVERITY
        </TableCell>
        <TableCell component="th" size="medium">
          RECOMMENDATION
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
