export enum Flag {
  NEW_UI_ACCESS_FLOW_SHARE = 'new-ui-access-flow-share',
  NEW_UI_PERSONAL_TOKENS = 'new-ui-personal-tokens',
  ACCOUNT_TRIAL_BANNER = 'account-trial-banner',
  OVERVIEW_TEMPLATE_SUGGESTION = 'overview-template-suggestion',
  NEW_UI_ON_BOARDING = 'new-ui-on-boarding',
  ACCESS_FLOW_TIMEFRAME = 'access-flow-timeframe',
  NEW_UI_ACTIVE_ACCESS = 'new-ui-active-access',
  UI_BUNDLES = 'ui-bundles',
  NEW_RESOURCE_WITH_PATH_CELL = 'new-resource-with-path-cell',
  APPROVER_JUSTIFICATION = 'approver-justification',
  OUTBOUND_WEBHOOKS = 'outbound-webhooks',
  PREMIUM_CREATE_REPORT_BUTTON = 'premium-create-report-button',
  PREMIUM_DOWNLOAD_REPORT = 'premium-download-report-button',
  PREMIUM_SETTINGS_REQUIRE_JUSTIFICATION = 'premium-settings-require-justification',
  PREMIUM_SETTINGS_ENFORCE_ALLOF_APPROVERS = 'premium-settings-enforce-allof-approvers',
  SHOW_SET_ACCESS_DURATION = 'show-set-access-duration',
  REQUESTER_CANNOT_APPROVE = 'requester-cannot-approve',
  PREMIUM_CREDENTIALS_ROTATION_POLICY = 'premium-credentials-rotation-policy',
  LOCAL_CONNECTOR = 'local-connector',
  GROUPS_PAGE = 'groups-page',
  AUTO_GRANT = 'auto-grant',
  REFACTORED_IDENTITIES_PAGE = 'refactored-identities-page',
  SHOW_RESOURCES_COLUMN = 'show-resources-column',
  SHOW_ADD_TO_BUNDLE_BUTTON = 'show-add-to-bundle-button',
  HIDE_ERROR_NOTIFICATIONS = 'hide-error-notifications',
  ALLOW_SAOLA_SESSION_RECORDING = 'allow-saola-session-recording',
  COMPLEX_GRANTEES_CONDITION = 'complex-grantees-condition',
  ADVANCED_APPROVER = 'advanced-approver',
  TERRAFORM_UI = 'terraform-ui',
  PANIC_BUTTON_REVOKE_ALL = 'panic-button-revoke-all',
  WEBHOOK_CHALLENGES = 'webhook-challenges',
  ADMIN_CUSTOM_ACCESS_DETAILS = 'admin-custom-access-details',
  SESSION_AUDIT = 'session-audit',
  NEW_INTEGRATION_CONFIG_FORM = 'new-integration-config-form',
  APONO_ACCESS_GRAPH = 'apono-access-graph',
  RESOURCE_OWNERS = 'resource-owners',
  USER_CLEANUP_SETTING = 'user-cleanup-setting',
  ACCESS_RIGHT_SIZING = 'access-right-sizing',
  DESELECT_IN_EDIT = 'deselect-in-edit',
  DEEP_DISCOVERY = 'deep-discovery',
  DASHBOARD_V2 = 'dashboard-v2',
  ACCESS_ANOMALIES = 'access-anomalies',
  SHOW_HASHICORP_VAULT_SECRET_TYPE = 'show-hashicorp-vault-secret-type',
  REQUEST_MFA = 'request-mfa',
}
