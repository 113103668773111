import { PropsWithChildren, ReactNode } from 'react';
import { useFlagFixed } from '@hooks';

import { Flag } from '@utils';
import { ErrorPage } from '@pages';

export interface ConditionalFlagMiddlewareProps {
  flag: Flag;
  ifElement: ReactNode;
  elseElement: ReactNode;
}

export function FlagMiddleware({ children, flag }: PropsWithChildren<Pick<ConditionalFlagMiddlewareProps, 'flag'>>) {
  return <ConditionalFlagMiddleware flag={flag} ifElement={children} elseElement={<ErrorPage errorCode={404} />} />;
}

export function ConditionalFlagMiddleware({ flag, ifElement, elseElement }: ConditionalFlagMiddlewareProps) {
  const { isEnabled: isOn } = useFlagFixed(flag);
  return <>{isOn ? ifElement : elseElement}</>;
}
