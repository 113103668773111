import { useState } from 'react';
import { FormControl, MenuItem, Select, styled } from '@mui/material';
import { usePopoverContext } from '@components/Popover';

const StyledSearchFilterFieldSelect = styled(FormControl)({
  minWidth: 160,
});

export interface SearchFilterFieldSelectOption<T> {
  key: T;
  value: string;
  disabled?: boolean;
}

export interface SearchFilterFieldSelectProps<T> {
  options: SearchFilterFieldSelectOption<T>[];
  value?: T;
  onChange: (value: T) => void;
}

export function SearchFilterFieldSelect<T extends string | readonly string[] | undefined = string>({
  options,
  value,
  onChange,
}: SearchFilterFieldSelectProps<T>) {
  const [selectedValue, setSelectedValue] = useState<T | undefined>(value);
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();

  const handleFieldChange = (fieldValue: T) => {
    setSelectedValue(fieldValue);
    onChange(fieldValue);
  };

  return (
    <StyledSearchFilterFieldSelect size="small">
      <Select<T>
        data-testid="search-filter-field-select"
        value={selectedValue || ''}
        onChange={(e) => handleFieldChange(e.target.value as T)}
        onOpen={() => popoverDisableOnClose()}
        onClose={() => popoverEnableOnClose()}
        disabled={options.length === 0}
      >
        {options.map((f) => (
          <MenuItem key={String(f.key)} value={f.key} disabled={f.disabled}>
            {f.value}
          </MenuItem>
        ))}
      </Select>
    </StyledSearchFilterFieldSelect>
  );
}
