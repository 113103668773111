import { useEffect, useMemo, useRef, useState } from 'react';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { useListActivityFilterOptions } from '@api';
import { FilterProps } from './types';

export const RESOURCE_FILTER_LABEL = 'Resource';
export const RESOURCE_FILTER_PARAM = 'filter.resource';
const RESOURCE_PARAM = 'resource';

export default function ResourceFilterAsync({ opened, onClose, onOpen, selected, onClear, onSelect }: FilterProps) {
  const [enabled] = useState(() => selected.length > 0);

  const {
    options,
    isOptionsFetched,
    refetchQuery: refetchFilterOptions,
  } = useListActivityFilterOptions(RESOURCE_PARAM, enabled);

  const resourceOptions = useMemo(
    () =>
      options.map((resource) => ({
        value: resource,
        label: resource,
      })),
    [options],
  );

  const selectedOptions = useMemo(() => {
    const list: ListFilterOption[] = [];
    for (const option of resourceOptions) {
      if (selected.includes(option.value)) {
        list.push(option);
      }
    }
    return list;
  }, [resourceOptions, selected]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    !opened && onOpen && onOpen();
    if (!isOptionsFetched) {
      refetchFilterOptions();
    }
  };

  const open = (opened && Boolean(anchorEl)) || false;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isOptionsFetched) {
        refetchFilterOptions();
      }
    }
  }, [isOptionsFetched, opened, ref, refetchFilterOptions]);

  const isFetching = !isOptionsFetched && (opened || selected.length > 0);

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={RESOURCE_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{ top: 0, left: 200 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ position: 'absolute' }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={resourceOptions}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${RESOURCE_FILTER_LABEL} Filter`}
            loading={isFetching}
          />
        </Box>
      </Popover>
    </>
  );
}
