import { OnboardingStepper, Page } from '@components';
import { Button, Stack, Typography } from '@mui/material';
import { routes } from '@routes';
import { onboardingSteps } from '@Onboarding/layouts/OnboardingDrawer/OnboardingList';
import Lottie from 'react-lottie';
import animationData from '../../../static/animations/welcome.json';
import { RouterLink } from '@libs';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData,
};

export function OnboardingWelcomePage() {
  return (
    <Page contentPadded>
      <Stack spacing={5} alignItems="center">
        <Typography variant="h4">Welcome to Apono!</Typography>
        <Stack alignItems="center">
          <Typography variant="body1">Glad you’re here!</Typography>
          <Typography variant="body1">Complete three simple steps to connect and start working:</Typography>
        </Stack>
        <Lottie isClickToPauseDisabled options={animationOptions} width={300} />
        <OnboardingStepper steps={onboardingSteps} currentStep={0} orientation="horizontal" withStepTitle />
        <Button component={RouterLink} to={routes.OnboardingCatalog.path} variant="contained">
          Let’s get started
        </Button>
      </Stack>
    </Page>
  );
}
