import { Box, Grid } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

export function IntegrationConfigLayout({ sideInfo, children }: PropsWithChildren<{ sideInfo: ReactNode }>) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={7.5}>
        {children}
      </Grid>
      <Grid item xs={4.5}>
        <Box
          sx={{
            top: 32,
            left: 16,
            position: 'sticky',
            alignSelf: 'flex-start',
          }}
        >
          {sideInfo}
        </Box>
      </Grid>
    </Grid>
  );
}
