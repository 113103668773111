import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ActivityApiAppListActivityRequestsRequest } from '@api/apn-core';
import { ActivityService } from '@api/services';

import { QUERY_KEYS, REFETCH_INTERVAL } from '../../common/constants';

export function useListActivityRequests(props: ActivityApiAppListActivityRequestsRequest, refetch = REFETCH_INTERVAL) {
  const queryClient = useQueryClient();

  const {
    data: { data: requests, pagination },
    isFetched: isRequestsFetched,
    isLoading: isRequestsLoading,
  } = useQuery(
    [QUERY_KEYS.Activity, QUERY_KEYS.Search, props],
    async () => {
      const res = await ActivityService.appListActivityRequests(props);

      res.data.forEach((request) => {
        queryClient.setQueryData([QUERY_KEYS.Activity, QUERY_KEYS.Single, request.id], request);
      });

      return res;
    },
    {
      initialData: {
        data: [],
        pagination: {
          total: 0,
          limit: 100,
          offset: 0,
        },
      },
      keepPreviousData: true,
      refetchInterval: refetch,
    },
  );

  return {
    requests,
    pagination,
    isRequestsFetched,
    isRequestsLoading,
  };
}
