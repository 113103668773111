import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getFragmentData } from '@api/gql';

import GenericAsyncFilterMultiSelect from '@common/organisms/CommonFilters/GenericAsyncFilterMultiSelect';
import { ResourcesStatus_Fragment } from '@Integrations/services/gql';
import { useStatusesFilterOptions } from '@Integrations/services/use-resources-filter-options';

import { FiltersProps } from './filters-props.interface';
import { STATUS_FILTER_LABEL, STATUS_FILTER_PARAM } from '@common/organisms/CommonFilters/constants';

export default function StatusesFilter({ integrationId, search }: FiltersProps) {
  const [searchParams] = useSearchParams();
  const [enabled] = useState(() => searchParams.has(STATUS_FILTER_PARAM));

  const { isFetched, data, refetch } = useStatusesFilterOptions({
    args: {
      id: integrationId,
      search,
    },
    enabled,
  });

  const statusOptions = useMemo(() => {
    const statusesData = [
      ...getFragmentData(ResourcesStatus_Fragment, data?.integrationResourcesFilterOptions.statuses || []),
    ];

    return statusesData.map((status) => ({
      label: status.status,
      value: status.status,
    }));
  }, [data?.integrationResourcesFilterOptions.statuses]);

  return (
    <GenericAsyncFilterMultiSelect
      options={statusOptions}
      filterLabel={STATUS_FILTER_LABEL}
      filterParam={STATUS_FILTER_PARAM}
      isOptionsFetched={isFetched}
      refetchOptions={refetch}
    />
  );
}
