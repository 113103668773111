import { useQuery } from '@tanstack/react-query';
import { UsersService, QueryKeys } from '@api';

export function useListAttribute() {
  const {
    data: contextsAttributes,
    isFetched: isContextsAttributesFetched,
    refetch: refetchContextsAttributes,
  } = useQuery({
    queryKey: [QueryKeys.ContextsAttributes],
    queryFn: async () => {
      const { attributes } = await UsersService.listAvailableAttributes();
      return attributes;
    },
    initialData: [],
  });

  return {
    contextsAttributes,
    isContextsAttributesFetched,
    refetchContextsAttributes,
  };
}

export function useListAvailableAttributesV2() {
  const {
    data: contextsAttributes,
    isFetched: isContextsAttributesFetched,
    refetch: refetchContextsAttributes,
  } = useQuery({
    // TODO: remove v2 after deprecation of old attributes
    queryKey: [QueryKeys.ContextsAttributes + 'v2'],
    queryFn: async () => {
      const { attributes } = await UsersService.listAvailableAttributesV2();
      return attributes;
    },
    initialData: [],
  });

  return {
    contextsAttributes,
    isContextsAttributesFetched,
    refetchContextsAttributes,
  };
}

export const PAGERDUTY_SHIFT_CONTEXT_ATTRIBUTE_TYPE = 'pagerduty_shift';
export const OKTA_MANAGER_CONTEXT_ATTRIBUTE_TYPE = 'okta_manager';
