import { useState } from 'react';
import { BaseListFilterSelectProps, ListFilterOption, usePrepareOptions } from './common';
import ListFilterSelect from './ListFilterSelect';
import { AnalyticsTriggerElementProps } from '@common/types';

interface ListFilterSingleSelectProps extends BaseListFilterSelectProps {
  value?: ListFilterOption;
  onSelect: (value: ListFilterOption) => void;
  disabled?: boolean;
}

export default function ListFilterSingleSelect({
  value,
  onSelect,
  options,
  maxHeight,
  enableSearch = false,
  title,
  groupedByCategory,
  loading,
  disabled,
  ...analyticsProps
}: ListFilterSingleSelectProps & AnalyticsTriggerElementProps) {
  const [searchValue, setSearchValue] = useState('');

  const items = usePrepareOptions({
    options,
    handler: onSelect,
    enableSearch,
    searchValue,
    type: 'radio',
    selectedValues: value ? new Set([value.value]) : undefined,
    groupedByCategory,
  });

  return (
    <ListFilterSelect
      {...{
        maxHeight,
        title,
        onSearch: enableSearch ? setSearchValue : undefined,
        items,
        loading,
        disabled,
        ...analyticsProps,
      }}
    />
  );
}
