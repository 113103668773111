import { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, styled, TextField, Typography } from '@mui/material';
import { usePopoverContext } from '@components/Popover';
import { useAutocomplete } from '@mui/base';

const Wrapper = styled(Box)({
  minWidth: 220,
});

export interface SearchFilterValueSelectOption {
  value: string;
  label: string;
}

export interface SearchFilterValueSelectProps {
  loading?: boolean;
  options: SearchFilterValueSelectOption[];
  value?: string;
  onChange: (value?: string) => void;
}

export function stringsToOptions(value: string[]): SearchFilterValueSelectOption[] {
  return value.map((v) => ({ value: v, label: v }));
}

export function SearchFilterValueSelect({ loading, options, value, onChange }: SearchFilterValueSelectProps) {
  const [selectedValue, setSelectedValue] = useState<SearchFilterValueSelectOption | null>(null);
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();

  useEffect(() => {
    if (options.length === 0) return;

    const selected = options.find((o) => o.value === value);
    if (selected) setSelectedValue(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleValueChange = (val: SearchFilterValueSelectOption | null) => {
    setSelectedValue(val);
    onChange(val?.value);
  };

  return (
    <Wrapper>
      <Autocomplete
        loading={loading}
        size="small"
        disablePortal
        options={options}
        value={selectedValue}
        data-testid="search-filter-value-select"
        onChange={(_, val) => handleValueChange(val)}
        onOpen={() => popoverDisableOnClose()}
        onClose={() => popoverEnableOnClose()}
        isOptionEqualToValue={(option, val) => {
          return option.value == val?.value && option.label == val?.label;
        }}
        ListboxProps={
          { 'data-testid': 'autocomplete-options' } as ReturnType<ReturnType<typeof useAutocomplete>['getListboxProps']>
        }
        fullWidth
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography data-testid={'option-label'} variant="inherit" noWrap>
              {option.label}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
              inputProps: {
                ...params.inputProps,
                'data-testid': 'search-filter-value-select-input',
              },
            }}
          />
        )}
      />
    </Wrapper>
  );
}
