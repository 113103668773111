import { PropsWithChildren } from 'react';

import { Typography, Stack, TextField } from '@mui/material';
import { CloseButton } from '@components/CloseButton';

export interface AppDrawerHeaderProps {
  title: string;
  subTitle?: string;
  onCloseClick?: () => void;
  filter?: string;
  onFilterChange?: (filter: string) => void;
}

export function AppDrawerHeader({
  title,
  subTitle,
  onCloseClick,
  filter,
  onFilterChange,
}: PropsWithChildren<AppDrawerHeaderProps>) {
  return (
    <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography variant="subtitle1">{title}</Typography>
        {onCloseClick && <CloseButton onClose={onCloseClick} testId="app-drawer-close-button" />}
      </Stack>
      {subTitle && <Typography variant="tooltip">{subTitle}</Typography>}
      {onFilterChange && (
        <TextField
          type="search"
          data-testid="app-drawer-search-input"
          size="small"
          fullWidth
          variant="outlined"
          value={filter}
          onChange={(e) => onFilterChange(e.target.value)}
          placeholder="Search"
        />
      )}
    </Stack>
  );
}
