import { Avatar, Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import { Markdown } from '@components/Markdown';
import { RouterLink } from '@libs';
import { MaterialIcon } from '@components/MaterialIcon';

import { GetStartedProps, OnboardingStep } from './types';
import { CloseButton } from '@components/CloseButton';

export const GetStartedHeroContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.banner,
  borderRadius: theme.spacing(1),
}));

const GetStartedHeroImage = styled(Box)(({ theme }) => ({
  position: 'relative',

  '& .MuiAvatar-root': {
    width: '100%',
    height: '170px',
  },

  '& .icon': {
    position: 'absolute',
    visibility: 'hidden',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: 2,
    color: theme.palette.background.paper,
    fontSize: '6rem',
    fontWeight: 900,
  },

  '.onboarding-step-finished &': {
    '& .icon': {
      visibility: 'visible',
    },

    '& .MuiAvatar-root img': {
      filter: 'blur(4px)',
    },
  },
}));

export function GetStartedHero({ meta, steps, onHide }: GetStartedProps) {
  const allFinished = Object.values(steps).every((step) => step);

  return (
    <GetStartedHeroContainer data-testid="onboarding">
      <Stack spacing={2} direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="subtitle1">Get started</Typography>
        {allFinished ? (
          <CloseButton onClose={onHide} testId="onboarding-hide-button" tooltip="Hide get started" />
        ) : null}
      </Stack>
      <Grid container direction="row" justifyContent="space-between" alignItems="stretch" columnSpacing={4}>
        <GetStartedHeroItem finished={steps.added_resource_integration} {...meta.added_resource_integration} />
        <GetStartedHeroItem finished={steps.added_idp_integration} {...meta.added_idp_integration} />
        <GetStartedHeroItem finished={steps.added_access_flow} {...meta.added_access_flow} />
        <GetStartedHeroItem finished={steps.added_slack_integration} {...meta.added_slack_integration} />
      </Grid>
    </GetStartedHeroContainer>
  );
}

interface GetStartedHeroItemProps extends OnboardingStep {
  finished?: boolean;
}

function GetStartedHeroItem({ finished, src, step, description, label, to }: GetStartedHeroItemProps) {
  return (
    <Grid
      item
      xs
      container
      spacing={1.5}
      direction="column"
      data-testid="onboarding-step"
      className={finished ? 'onboarding-step-finished' : ''}
    >
      <Grid item xs>
        <GetStartedHeroImage>
          <MaterialIcon symbol="done" className="icon" />
          <Avatar src={src} variant="rounded" />
        </GetStartedHeroImage>
        <Typography variant="body2" mt={1.5} component="div">
          <Markdown>{`**${step}.** ${description}`}</Markdown>
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Button
          color="primary"
          variant="contained"
          disabled={finished}
          component={RouterLink}
          to={to}
          fullWidth
          data-testid="onboarding-step-button"
        >
          {label}
        </Button>
      </Grid>
    </Grid>
  );
}
