import { reportUrlToCopy } from '@utils';
import { CopyButton, MaterialIcon } from '@components';

export interface ReportFormCopyLinkProps {
  reportId: string;
  onlyIcon?: boolean;
}

export function ReportCopyLink({ reportId, onlyIcon }: ReportFormCopyLinkProps) {
  const icon = <MaterialIcon symbol="link" />;

  if (onlyIcon) {
    return <CopyButton text={reportUrlToCopy(reportId)} copyTooltip="Copy link" icon={icon} />;
  }

  return (
    <CopyButton text={reportUrlToCopy(reportId)} icon={icon}>
      Copy report link
    </CopyButton>
  );
}
