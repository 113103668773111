import { Divider, Stack } from '@mui/material';
import { AccessRightSizingListHeader } from './AccessRightSizingListHeader';
import { AccessRightSizingListItem } from './AccessRightSizingListItem';
import { RightSizingReportsFieldsFragment } from '@api/gql/graphql';
import { getFragmentData } from '@api/gql';
import { RIGHT_SIZING_REPORT_FRAGMENTS } from '@AccessRightSizing/services/gql';

export function AccessRightSizingWidget({ reports }: { reports: RightSizingReportsFieldsFragment }) {
  return (
    <Stack direction="column" spacing={1}>
      <AccessRightSizingListHeader />
      <Divider />
      {reports.edges.map((item, index) => {
        const report = getFragmentData(RIGHT_SIZING_REPORT_FRAGMENTS, item.node);
        return (
          <>
            <AccessRightSizingListItem report={report} />
            {index < reports.edges.length - 1 && <Divider />}
          </>
        );
      })}
    </Stack>
  );
}
