import { useMemo } from 'react';
import { MenuList } from '@mui/material';

import { SelectDropdownItem, SelectDropdownItemProps, SelectDropdownOption } from './SelectDropdownItem';

export interface SelectDropdownSingleProps<T> {
  options: SelectDropdownOption<T>[];
  value?: T;
  onChange: (value: T) => void;
}

function useSelectDropdownSingle<T>({ value, onChange, options }: SelectDropdownSingleProps<T>) {
  const preparedOptions = useMemo(() => {
    const newOptions: SelectDropdownItemProps<T>[] = [];

    for (const option of options) {
      newOptions.push({
        option,
        type: 'radio',
        selected: value ? option.value === value : false,
        onClick: (optionVal: T) => onChange(optionVal),
      });
    }

    return newOptions;
  }, [options, value, onChange]);

  return {
    preparedOptions,
  };
}

export function SelectDropdownSingle<T>({ value, onChange, options }: SelectDropdownSingleProps<T>) {
  const { preparedOptions } = useSelectDropdownSingle({
    value,
    onChange,
    options,
  });

  return (
    <MenuList>
      {preparedOptions.map((option) => (
        <SelectDropdownItem key={option.option.value as string} {...option} />
      ))}
    </MenuList>
  );
}
