import { useCallback, useEffect } from 'react';

import { ReportForm } from '@organisms';
import { Loader, Page } from '@components';
import { ModifyActivityReportModel, useGetReportTemplate, useUpdateReportTemplate } from '@api';
import { routes } from '@routes';
import { useGlobalNotifier, useRequiredParams } from '@hooks';

export function EditReportPage() {
  const { id: reportId } = useRequiredParams(['id']);

  return (
    <Page
      breadcrumbs={[
        {
          label: 'Audit',
          href: routes.Activity.path,
        },
        {
          label: 'Reports',
          href: routes.Reports.path,
        },
      ]}
      title="Edit Report"
    >
      <EditReportPageContent reportId={reportId} />
    </Page>
  );
}

function useEditReportPageContent(reportId: string) {
  const { reportTemplate, isReportTemplateFetched } = useGetReportTemplate(reportId);
  const { updateReportTemplate, isUpdateReportTemplateLoading, isReportTemplateUpdated } = useUpdateReportTemplate();
  const { notifySuccess } = useGlobalNotifier();

  useEffect(() => {
    if (isReportTemplateUpdated) {
      notifySuccess('Report updated');
    }
  }, [isReportTemplateUpdated, notifySuccess]);

  const onChange = useCallback(
    (payload: ModifyActivityReportModel) => updateReportTemplate({ id: reportId, payload }),
    [reportId, updateReportTemplate],
  );

  return {
    reportTemplate,
    isFetched: isReportTemplateFetched,
    isUpdating: isUpdateReportTemplateLoading,
    onChange,
  };
}

function EditReportPageContent({ reportId }: { reportId: string }) {
  const { reportTemplate, isFetched, isUpdating, onChange } = useEditReportPageContent(reportId);

  if (!isFetched) {
    return <Loader />;
  }

  return <ReportForm report={reportTemplate} loading={isUpdating} onChange={onChange} />;
}
