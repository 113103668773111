import RightSizingAccessFlowFilter, {
  ACCESS_FLOW_QUERY_PARAM,
} from '@AccessRightSizing/components/RightSizingAccessFlowFilter';
import RightSizingPeriodFilter, { PERIOD_QUERY_PARAM } from '@AccessRightSizing/components/RightSizingPeriodFilter';
import {
  ACCESS_FLOW_FRAGMENT,
  RIGHT_SIZING_REPORT_FRAGMENTS,
  RightSizingReports_Fragment,
} from '@AccessRightSizing/services/gql';
import { useAccessRightSizingReports } from '@AccessRightSizing/services/use-right-sizing-reports';
import { getFragmentData } from '@api/gql';
import {
  AccessFlowFieldsFragment,
  AvailablePeriodsFieldsFragmentDoc,
  RightSizingReportFieldsFragment,
} from '@api/gql/graphql';
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const TAKE_CONSTANT = 9999;

export function AccessRightSizingFilters() {
  const [searchParams] = useSearchParams();
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const accessFlowId = searchParams.get(ACCESS_FLOW_QUERY_PARAM);

  const {
    data,
    isFetched: isAccessFlowsFetched,
    isFetching: isLoading,
  } = useAccessRightSizingReports({ take: TAKE_CONSTANT });

  const { rightSizingReports } = data || { rightSizingReports: null };
  const rightSizingReportsData = getFragmentData(RightSizingReports_Fragment, rightSizingReports);

  const reportData: {
    report: RightSizingReportFieldsFragment;
    accessFlow: AccessFlowFieldsFragment;
  }[] = useMemo(() => {
    const reportDataNotFiltered = rightSizingReportsData?.edges.map((edge) => {
      const report = getFragmentData(RIGHT_SIZING_REPORT_FRAGMENTS, edge.node);
      const accessFlow = getFragmentData(ACCESS_FLOW_FRAGMENT, report.accessFlow);
      if (!report || !accessFlow) return null;
      return {
        report,
        accessFlow,
      };
    });
    const reports = [];
    if (reportDataNotFiltered) {
      for (const report of reportDataNotFiltered) {
        if (report !== null && report !== undefined) {
          reports.push(report);
        }
      }
    }
    return reports;
  }, [rightSizingReportsData]);

  const accessFlowOptions = reportData.map(({ accessFlow }) => ({
    value: accessFlow.id,
    label: accessFlow.name,
  }));

  const periodOptions = useMemo(() => {
    if (accessFlowId) {
      const report = reportData.find(({ accessFlow }) => accessFlow.id === accessFlowId)?.report;

      if (!report) return [];

      const availablePeriods = getFragmentData(AvailablePeriodsFieldsFragmentDoc, report.availablePeriods);
      return availablePeriods.map((month) => ({
        value: month.key,
        label: month.value,
      }));
    }
    return [];
  }, [accessFlowId, reportData]);

  return (
    <Stack direction="row" justifyContent="space-between" width="100%" display="flex">
      <RightSizingAccessFlowFilter
        options={accessFlowOptions}
        opened={openedFilter === ACCESS_FLOW_QUERY_PARAM}
        onClose={onCloseFilter}
        onOpen={() => onOpenFilter(ACCESS_FLOW_QUERY_PARAM)}
        isLoading={isLoading || !isAccessFlowsFetched}
      />
      {accessFlowId && (
        <RightSizingPeriodFilter
          options={periodOptions}
          opened={openedFilter === PERIOD_QUERY_PARAM}
          onClose={onCloseFilter}
          onOpen={() => onOpenFilter(PERIOD_QUERY_PARAM)}
          isLoading={isLoading || !isAccessFlowsFetched}
          defaultOption={periodOptions[0]?.value || ''}
        />
      )}
    </Stack>
  );
}
