import { Box, Button, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

interface ErrorPageProps {
  errorCode: number;
  errorMessage?: string;
}

export function ErrorPage({ errorCode, errorMessage }: ErrorPageProps) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Typography variant="h1">{errorCode}</Typography>
        {errorMessage ? <Typography gutterBottom>{errorMessage}</Typography> : null}
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={() => navigate(0)}>
            Reload
          </Button>
          <Button variant="text" to="/" component={Link}>
            Home page
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
