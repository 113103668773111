import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { aponoColors } from '@utils';
import confetti from 'canvas-confetti';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

type OnboardingDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function OnboardingDialog({ isOpen, onClose }: OnboardingDialogProps) {
  const { track } = useAnalyticsContext();
  useEffect(() => {
    if (isOpen) {
      track(CustomAnalyticsEvents.ONBOARDING_STEP_3_COMPLETED);
      confetti({
        angle: 60,
        origin: { x: 0.1 },
        zIndex: 1500,
        spread: 100,
      });
      confetti({
        angle: 120,
        origin: { x: 0.9 },
        zIndex: 1500,
        spread: 100,
      });
    }
  }, [track, isOpen]);

  return (
    <Portal>
      <Dialog
        open={isOpen}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: aponoColors.neutral[900],
            border: `1px solid ${aponoColors.neutral[800]}`,
            borderRadius: '1rem',
            padding: '2rem',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4">You Did it!</Typography>
          <Typography variant="body1">
            You created your first{' '}
            <Typography component="span" color={aponoColors.uglyGreen}>
              JIT access request.
            </Typography>{' '}
            Easy, right?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box mx="auto" my="1rem" width="50%">
            <img src="/static/illustrations/onboarding_end.svg" alt="celebration" width="100%" />
          </Box>
          <Stack direction="column" spacing={3}>
            <Typography variant="body2" color="textSecondary">
              Feel free to revoke the access at any time. Now thats what we mean by frictionless, temporary access!
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Now it’s time to create more Access Flows, invite teammates and connect your Identity Provider and Slack
              workspace
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
