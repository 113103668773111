import { useMemo } from 'react';
import { generatePath, useSearchParams } from 'react-router-dom';
import { Alert, Button, Skeleton, Stack } from '@mui/material';

import { AccessBundleAppModel } from '@api';

import { useAppGetBundle } from '@AccessFlows/services/bundlesQueries';
import AccessFlowItemTargetChip from '@AccessFlows/components/AccessFlowItemTargetChip';
import AccessFlowReviewModal from '@AccessFlows/components/AccessFlowReviewModal';
import { useRemappedIntegrations } from '@common/hooks/use-remapped-integrations';
import { RouterLink } from '@libs';
import { routes } from '@routes';
import {
  BUNDLE_QUERY_PARAM,
  EDIT_BUNDLE_QUERY_PARAM,
  FROM_ACCESS_FLOW_QUERY_PARAM,
  NEW_ACCESS_FLOW_QUERY_PARAM_VALUE,
} from '@AccessFlows/common/constants';

interface BundleViewModalProps {
  accessFlowId?: string;
}

function BundleViewModal({ accessFlowId }: BundleViewModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const bundleId = searchParams.get(BUNDLE_QUERY_PARAM);

  const handleOnClose = () => {
    searchParams.delete(BUNDLE_QUERY_PARAM);
    setSearchParams(searchParams);
  };

  if (!bundleId) return null;

  return <BundleViewModalContent bundleId={bundleId} handleOnClose={handleOnClose} accessFlowId={accessFlowId} />;
}

interface BundleViewModalContentProps {
  bundleId: string;
  handleOnClose: () => void;
  accessFlowId?: string;
}

function BundleViewModalContent({ bundleId, handleOnClose, accessFlowId }: BundleViewModalContentProps) {
  const { bundle, isFetched } = useAppGetBundle(bundleId);

  const modalActions = useMemo(() => {
    if (!bundle)
      return (
        <Button onClick={handleOnClose} variant="outlined" sx={{ alignSelf: 'flex-end' }}>
          Close
        </Button>
      );

    const editLinkQP = new URLSearchParams({
      [FROM_ACCESS_FLOW_QUERY_PARAM]: accessFlowId || NEW_ACCESS_FLOW_QUERY_PARAM_VALUE,
      [EDIT_BUNDLE_QUERY_PARAM]: 'true',
    });
    const editLink = `${generatePath(routes.EditBundle.path, { id: bundle.id })}?${editLinkQP.toString()}`;

    return (
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="contained" component={RouterLink} to={editLink}>
          Edit Bundle
        </Button>
        <Button onClick={handleOnClose} variant="outlined">
          Close
        </Button>
      </Stack>
    );
  }, [accessFlowId, bundle, handleOnClose]);

  const modalBody = useMemo(() => {
    if (!isFetched) {
      return <Skeleton variant="rounded" height={32} />;
    }

    if (!bundle) {
      return <Alert severity="error">Bundle not found</Alert>;
    }

    return <BundleViewContent bundle={bundle} />;
  }, [bundle, isFetched]);

  return (
    <AccessFlowReviewModal
      open
      title="Preview Bundle"
      subTitle={bundle?.name}
      onClose={handleOnClose}
      actions={modalActions}
      width={800}
    >
      {modalBody}
    </AccessFlowReviewModal>
  );
}

function BundleViewContent({ bundle }: { bundle: AccessBundleAppModel }) {
  const { remappedIntegrations, isIntegrationsFetched } = useRemappedIntegrations();

  const targetItems = useMemo(() => {
    return bundle.targets.map((target) => {
      let icon = undefined;
      if (target.integration?.integration_id && remappedIntegrations[target.integration.integration_id]) {
        icon = remappedIntegrations[target.integration.integration_id].config.icons.svg;
      }

      return {
        target,
        icon,
      };
    });
  }, [bundle, remappedIntegrations]);

  if (!isIntegrationsFetched) return <Skeleton variant="rounded" height={32} />;

  return (
    <Stack alignItems="flex-start" spacing={1}>
      {targetItems.map((target, i) => (
        <AccessFlowItemTargetChip key={i} target={target} />
      ))}
    </Stack>
  );
}

export default BundleViewModal;
