import { useCallback, useMemo } from 'react';

import { AppDrawer, AppDrawerFooter, AppDrawerHeader, Loader } from '@components';

import { ResourceAppModel } from '@api';

import { DrawerType, ID_TAG, NAME_TAG, SelectResourceMode, useSelectResourceDrawerContext } from '../provider';
import { ResourcesTree } from '../components/ResourcesTree';

export function DrawerSelectResources() {
  const {
    resourceType,
    resourceTypeHumanName,
    drawerOpened,
    onCloseDrawer,
    resources,
    selectedMode,
    selectedMatchers,
    onSelectMatchers,
    selectedExcludes,
    onSelectExcludes,
    onDoneSelect,
    integrationConfig,
    isFetched,
  } = useSelectResourceDrawerContext();

  const selectedResources = useMemo(() => {
    const selectedTags = selectedMode === SelectResourceMode.AnyExcept ? selectedExcludes : selectedMatchers;

    const selectedIds = new Set<string>();
    const selectedNames = new Set<string>();
    for (const tag of selectedTags) {
      if (tag.name === ID_TAG) {
        selectedIds.add(tag.value);
      }

      if (tag.name === NAME_TAG) {
        selectedNames.add(tag.value);
      }
    }

    return resources.filter((r) => selectedIds.has(r.id) || selectedNames.has(r.name));
  }, [selectedMode, selectedMatchers, selectedExcludes, resources]);

  const handleOnChange = useCallback(
    (value: ResourceAppModel[]) => {
      const newTags = value.map((r) => ({ name: ID_TAG, value: r.id }));
      if (selectedMode === SelectResourceMode.AnyExcept) {
        onSelectMatchers([]);
        onSelectExcludes(newTags);
      } else {
        onSelectMatchers(newTags);
        onSelectExcludes([]);
      }
    },
    [onSelectExcludes, onSelectMatchers, selectedMode],
  );

  const handleOnDoneSelect = () => {
    onDoneSelect();
  };

  const handleOnCloseDrawer = () => {
    onCloseDrawer();
  };

  const drawerTitle = useMemo(() => `Select ${resourceTypeHumanName} resources`, [resourceTypeHumanName]);

  const tree = useMemo(() => {
    if (!isFetched || !integrationConfig) {
      return <Loader />;
    }

    return (
      <ResourcesTree
        resourceType={resourceType}
        resourceTypeConfigs={integrationConfig.resource_types}
        resources={resources}
        value={selectedResources}
        onChange={handleOnChange}
      />
    );
  }, [isFetched, integrationConfig, resourceType, resources, selectedResources, handleOnChange]);

  return (
    <AppDrawer
      width={550}
      open={drawerOpened === DrawerType.SelectResources}
      onClose={handleOnCloseDrawer}
      header={
        <AppDrawerHeader
          title={drawerTitle}
          subTitle="Use hierarchies to select multiple resources. Resources added in the future will not be automatically added."
          onCloseClick={handleOnCloseDrawer}
        />
      }
      headerBorder
      footer={
        <AppDrawerFooter onSubmit={handleOnDoneSelect} onClear={() => handleOnChange([])} disabled={!isFetched} />
      }
      noScroll
    >
      {tree}
    </AppDrawer>
  );
}
