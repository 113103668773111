import { useEffect, useMemo, useRef, useState } from 'react';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { useListActivityFilterOptions } from '@api';
import { useAppListAccessFlowsV2 } from '@AccessFlows/services/accessFlowsQueries';
import { FilterProps } from './types';

export const ACCESS_FLOW_FILTER_LABEL = 'Access Flow Name';
export const ACCESS_FLOW_PARAM = 'access_flow_id';
export const ACCESS_FLOW_FILTER_PARAM = 'filter.access_flow_id';

export default function AccessFlowFilterAsync({ opened, onClose, onOpen, selected, onSelect, onClear }: FilterProps) {
  const [enabled] = useState(() => selected.length > 0);

  const {
    options,
    isOptionsFetched,
    refetchQuery: refetchFilterOptions,
  } = useListActivityFilterOptions(ACCESS_FLOW_PARAM, enabled);
  const {
    accessFlows,
    isFetched: isAccessFlowsFetched,
    refetch: refetchAccessFlows,
  } = useAppListAccessFlowsV2(enabled);

  const accessFlowOptions = useMemo(() => {
    return options
      .map((id) => {
        const accessFlow = accessFlows.find((ac) => ac.id === id);
        if (accessFlow) return { value: id, label: accessFlow.name };
        return null;
      })
      .filter((option) => option !== null) as ListFilterOption[];
  }, [options, accessFlows]);

  const selectedOptions = useMemo(() => {
    const list: ListFilterOption[] = [];
    for (const option of accessFlowOptions) {
      if (selected.includes(option.value)) {
        list.push(option);
      }
    }
    return list;
  }, [accessFlowOptions, selected]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    !opened && onOpen && onOpen();
    setAnchorEl(event.currentTarget);

    if (!isAccessFlowsFetched && !isOptionsFetched) {
      refetchFilterOptions();
      refetchAccessFlows();
    }
  };

  const open = (opened && Boolean(anchorEl)) || false;

  const ref = useRef<HTMLButtonElement>(null);

  const isFetching = (!isAccessFlowsFetched || !isOptionsFetched) && (opened || selected.length > 0);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isAccessFlowsFetched && !isOptionsFetched) {
        refetchFilterOptions();
        refetchAccessFlows();
      }
    }
  }, [isAccessFlowsFetched, isOptionsFetched, onOpen, opened, ref, refetchAccessFlows, refetchFilterOptions]);

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={ACCESS_FLOW_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={accessFlowOptions}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${ACCESS_FLOW_FILTER_LABEL} Filter`}
            loading={isFetching}
          />
        </Box>
      </Popover>
    </>
  );
}
