import { Grid, styled } from '@mui/material';
import { ReactNode } from 'react';

export const COLUMNS_LAYOUT_HEADER_HEIGHT = '80px';

export interface ColumnsLayoutHeaderProps {
  children?: ReactNode;
  actions?: ReactNode;
}

const ColumnsLayoutHeaderContainer = styled(Grid)(({ theme }) => ({
  minHeight: COLUMNS_LAYOUT_HEADER_HEIGHT,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}));

export function ColumnsLayoutHeader({ children, actions }: ColumnsLayoutHeaderProps) {
  return (
    <ColumnsLayoutHeaderContainer container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>{children}</Grid>
      <Grid item>{actions}</Grid>
    </ColumnsLayoutHeaderContainer>
  );
}
