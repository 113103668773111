import { memo, useMemo, useState } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import buildHierarchyPath from '@utils/build-hierarchy-path';
import { IntegrationAppModel, IntegrationConfigAppModelV2, ResourceAppModel, ResourceStatusModel } from '@api';
import { Loader, NoResults, SearchBar, StatusColumn, TableWrapper } from '@components';
import { isEmpty, useSearchParams } from '@libs';
import TablePagination from '@components/TablePagination';
import IntegrationResourcesFilters from '@Integrations/organisms/IntegrationResourcesFiltersOld';
import useIntegrationResourcesFetch from '@Integrations/hooks/use-integration-resources-fetch-old';
import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import TableListTotal from '@common/components/TableListTotal';

const PAGE_QUERY_PARAM = 'page';
const LIMIT = 100;

export default function IntegrationResourcesPaginated({
  integration,
  integrationConfig,
}: {
  integration: IntegrationAppModel;
  integrationConfig: IntegrationConfigAppModelV2;
}) {
  const { isFetched, integrationResourcesList, filterOptions, totalIntegrations } = useIntegrationResourcesFetch(
    integration.id,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get(SEARCH_QUERY_PARAM) || '');

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(SEARCH_QUERY_PARAM);
    if (!isEmpty(value)) {
      newSearchParams.set(SEARCH_QUERY_PARAM, value);
      newSearchParams.delete(PAGE_QUERY_PARAM);
    }
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(PAGE_QUERY_PARAM, String(page));
    setSearchParams(newSearchParams);
    window.scrollTo(0, 0);
  };

  const pagination = useMemo(() => {
    const page = Number(searchParams.get(PAGE_QUERY_PARAM)) || 1;

    return {
      total: integrationResourcesList.length,
      limit: LIMIT,
      offset: (page - 1) * LIMIT,
    };
  }, [integrationResourcesList.length, searchParams]);

  const integrationResourcesPage = useMemo(
    () => integrationResourcesList.slice(pagination.offset, pagination.offset + pagination.limit),
    [integrationResourcesList, pagination.limit, pagination.offset],
  );

  if (!isFetched) {
    return <Loader />;
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <SearchBar value={searchQuery} onChange={handleSearchQuery} placeholder={'Search by resource & location'} />
          <IntegrationResourcesFilters options={filterOptions} />
        </Stack>
        <TableListTotal totalResults={totalIntegrations} displayedResults={integrationResourcesList.length} />
      </Stack>
      <IntegrationResourcesTable
        {...{
          resources: integrationResourcesPage,
          config: integrationConfig,
        }}
      />
      <TablePagination pagination={pagination} isLoading={false} onChange={handlePageChange} />
    </Stack>
  );
}

const IntegrationResourcesTable = memo(function IntegrationResourcesTable({
  resources,
  config,
}: {
  resources: ResourceAppModel[];
  config: IntegrationConfigAppModelV2;
}) {
  if (resources.length === 0) {
    return <NoResults>No resources found</NoResults>;
  }

  return (
    <TableWrapper>
      <MuiTable size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th" size="medium">
              Resource
            </TableCell>
            <TableCell component="th" size="medium">
              Location
            </TableCell>
            <TableCell component="th" size="medium" width="25%">
              Tags
            </TableCell>
            <TableCell component="th" size="medium" width="20%">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.map((r) => (
            <IntegrationResourcesTableRow key={r.id} resource={r} integrationConfig={config} />
          ))}
        </TableBody>
      </MuiTable>
    </TableWrapper>
  );
});

const IntegrationResourcesTableRow = memo(function IntegrationResourcesTableRow({
  resource,
  integrationConfig,
}: {
  resource: ResourceAppModel;
  integrationConfig: IntegrationConfigAppModelV2;
}) {
  const { logicalPath, literalPath } = buildHierarchyPath(resource, integrationConfig, false, true);
  const resourceTypeConfig = integrationConfig.resource_types[resource.type];

  return (
    <TableRow className={resource.status.status === 'Error' ? 'MuiTableRow-error' : ''}>
      <TableCell>
        <PrimarySecondary
          primary={resource.name}
          secondary={resourceTypeConfig.display_name}
          icon={resourceTypeConfig?.icons.svg}
        />
      </TableCell>
      <TableCell>
        <PrimarySecondary primary={literalPath} secondary={logicalPath} />
      </TableCell>
      <TableCell>
        <IntegrationResourcesTableTags tags={resource.tags} />
      </TableCell>
      <TableCell>{resource.status && <IntegrationResourcesStatus status={resource.status} />}</TableCell>
    </TableRow>
  );
});

const IntegrationResourcesTableTags = memo(function IntegrationResourcesTableTags({
  tags,
}: {
  tags: { [key: string]: string };
}) {
  const tagsStringified = Object.entries(tags).map(([key, value]) => `${key}: ${value}`);
  const tagsSliced = tagsStringified.slice(0, 3);
  const otherTags = tagsStringified.slice(3);

  return (
    <Stack direction="row" spacing={0.5}>
      {tagsSliced.map((t) => (
        <IntegrationResourcesTableTag key={t} tag={t} />
      ))}
      {otherTags.length > 0 && <OtherTags tags={otherTags} />}
    </Stack>
  );
});

const IntegrationResourcesTableTag = memo(function IntegrationResourcesTableTag({ tag }: { tag: string }) {
  const tagSliced = tag.slice(0, 20);

  if (tagSliced.length === tag.length) {
    return <Chip label={tagSliced} size="small" />;
  }

  return (
    <Tooltip title={tag} arrow placement="top">
      <Chip label={`${tagSliced}...`} size="small" />
    </Tooltip>
  );
});

const OtherTags = memo(function OtherTags({ tags }: { tags: string[] }) {
  const title = (
    <Stack direction="column" spacing={1}>
      {tags.map((t) => (
        <Typography key={t} variant="inherit">
          {t}
        </Typography>
      ))}
    </Stack>
  );

  return (
    <Tooltip title={title} arrow placement="top">
      <Chip label={`+${tags.length}`} size="small" variant="outlined" />
    </Tooltip>
  );
});

const IntegrationResourcesStatus = memo(function IntegrationResourcesStatus({
  status,
}: {
  status: ResourceStatusModel;
}) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <StatusColumn status={status.status} />
      {status.status === 'Error' && (
        <Typography color="error.main" variant="body2">
          {status.message}
        </Typography>
      )}
    </Stack>
  );
});
