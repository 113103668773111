import { ReactNode, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Stack, Tab, Tabs, Typography } from '@mui/material';

import { IntegrationConfigAppModelV2 } from '@api';

import { IntegrationConfigFormPayload, SecretStoreType } from '../../Provider';
import { AwsSecretForm } from './AwsSecretForm';
import { GcpSecretForm } from './GcpSecretForm';
import { AzureSecretForm } from './AzureSecretForm';
import { K8sSecretForm } from './K8sSecretForm';
import { AponoSecretForm } from './AponoSecretForm';
import { useIsInViewMode } from '@Integrations/IntegrationConfigForm/utils';
import { HashicorpSecretForm } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/SecretStoreForm/HashicorpSecretForm';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

export const SupportedSecretTypes: { [k: string]: SecretStoreType } = {
  'external-secret-manager': SecretStoreType.AWS,
  'aws-secret-manager': SecretStoreType.AWS,
  AWS: SecretStoreType.AWS,
  'gcp-cloud-secret': SecretStoreType.GCP,
  GCP: SecretStoreType.GCP,
  kubernetes: SecretStoreType.KUBERNETES,
  KUBERNETES: SecretStoreType.KUBERNETES,
  azure: SecretStoreType.AZURE,
  AZURE: SecretStoreType.AZURE,
  APONO: SecretStoreType.APONO,
  HASHICORP_VAULT: SecretStoreType.HASHICORP_VAULT,
};

export type SecretStoreFormProps = Pick<
  IntegrationConfigAppModelV2,
  'requires_secret' | 'supported_secret_types' | 'secret_params'
> & { withTitle?: boolean };

function useSecretStoreForm() {
  const { watch } = useFormContext<IntegrationConfigFormPayload>();
  const secretConfig = watch('secret_config');

  const configType = useMemo(() => secretConfig?.type, [secretConfig]);

  return { configType };
}

export function SecretStoreForm({
  requires_secret,
  supported_secret_types,
  secret_params,
  withTitle = true,
}: SecretStoreFormProps) {
  const isViewMode = useIsInViewMode();
  const { configType } = useSecretStoreForm();

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      {withTitle && <SecretFormTitle required={requires_secret} />}
      <SecretFormTabs secretTypes={supported_secret_types} />
      {configType === SecretStoreType.AWS && <AwsSecretForm required={requires_secret} isViewOnly={isViewMode} />}
      {configType === SecretStoreType.GCP && <GcpSecretForm required={requires_secret} isViewOnly={isViewMode} />}
      {configType === SecretStoreType.AZURE && <AzureSecretForm required={requires_secret} isViewOnly={isViewMode} />}
      {configType === SecretStoreType.KUBERNETES && (
        <K8sSecretForm required={requires_secret} isViewOnly={isViewMode} />
      )}
      {configType === SecretStoreType.APONO && (
        <AponoSecretForm secret_params={secret_params} isViewOnly={isViewMode} />
      )}
      {configType === SecretStoreType.HASHICORP_VAULT && (
        <HashicorpSecretForm required={requires_secret} isViewOnly={isViewMode} />
      )}
    </Stack>
  );
}

function SecretFormTitle({ required }: { required: boolean }) {
  return (
    <Typography variant="subtitle2" component="div">
      Secret Store
      {!required && (
        <Typography variant="tooltip" component="span" color="text.disabled">
          {' '}
          (Optional)
        </Typography>
      )}
    </Typography>
  );
}

interface SecretFormTabsProps {
  secretTypes: Array<string>;
}

function useSecretFormTabs({ secretTypes }: SecretFormTabsProps) {
  const { control, setValue } = useFormContext<IntegrationConfigFormPayload>();
  const { isEnabled: isHashicorpEnabled } = useFlagFixed(Flag.SHOW_HASHICORP_VAULT_SECRET_TYPE);

  if (!isHashicorpEnabled) {
    delete SupportedSecretTypes.HASHICORP_VAULT;
  }

  const tabs: ReactNode[] = [];
  secretTypes.forEach((type) => {
    if (!SupportedSecretTypes[type]) return;
    tabs.push(
      <Tab key={type} label={SupportedSecretTypes[type].replace(/_/g, ' ')} value={SupportedSecretTypes[type]} />,
    );
  });

  const handleChange = (newVal: SecretStoreType) => {
    if (newVal === SecretStoreType.AWS) {
      setValue('secret_config', {
        type: SecretStoreType.AWS,
        region: '',
        secret_id: '',
      });
    }

    if (newVal === SecretStoreType.GCP) {
      setValue('secret_config', {
        type: SecretStoreType.GCP,
        project: '',
        secret_id: '',
      });
    }

    if (newVal === SecretStoreType.AZURE) {
      setValue('secret_config', {
        type: SecretStoreType.AZURE,
        vault_url: '',
        name: '',
      });
    }

    if (newVal === SecretStoreType.KUBERNETES) {
      setValue('secret_config', {
        type: SecretStoreType.KUBERNETES,
        namespace: '',
        name: '',
      });
    }

    if (newVal === SecretStoreType.APONO) {
      setValue('secret_config', {
        type: SecretStoreType.APONO,
        params: {},
      });
    }

    if (newVal === SecretStoreType.HASHICORP_VAULT) {
      setValue('secret_config', {
        type: SecretStoreType.HASHICORP_VAULT,
        secret_engine: '',
        path: '',
      });
    }
  };

  return { control, tabs, handleChange };
}

function SecretFormTabs({ secretTypes }: SecretFormTabsProps) {
  const { control, tabs, handleChange } = useSecretFormTabs({ secretTypes });

  return (
    <Controller
      control={control}
      name="secret_config.type"
      render={({ field: { value } }) => (
        <Tabs value={value} onChange={(e, newVal) => handleChange(newVal)} variant="fullWidth">
          {tabs}
        </Tabs>
      )}
    />
  );
}
