import { useAsync as useAsyncHook, UseAsyncOptions } from 'react-async-hook';
import { useRef } from 'react';

export interface AsyncStateStatus<T> {
  isLoading: boolean;
  doneOnce: boolean;
  result: T | undefined;
  hasExecuted: boolean;
  hasSucceeded: boolean;
  reset: () => void;
  error: Error | undefined;
  execute: () => Promise<T>;
}

export function useAsync<R>(
  asyncFunction: () => Promise<R>,
  params: unknown[],
  options?: UseAsyncOptions<R>,
): AsyncStateStatus<R> {
  const hasExecutedRef = useRef(false);
  const doneOnceRef = useRef(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps
  const { error, execute, loading, reset, result, status } = useAsyncHook(asyncFunction, params, {
    executeOnMount: false,
    ...options,
  });

  if (!hasExecutedRef.current && status !== 'not-requested') {
    hasExecutedRef.current = true;
  }

  if (!doneOnceRef.current && (status === 'success' || status === 'error')) {
    doneOnceRef.current = true;
  }

  return {
    isLoading: loading,
    hasSucceeded: status === 'success',
    doneOnce: doneOnceRef.current,
    hasExecuted: hasExecutedRef.current,
    result,
    error,
    execute,
    reset,
  };
}
