import { Outlet } from 'react-router-dom';

import { Page } from '@components';
import { routes } from '@routes';
import { AccessGraphButton } from '@AccessGraph/organisms/AccessGraphButton';
import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';

export function ActivityLayout() {
  const { isEnabled } = useFlagFixed(Flag.APONO_ACCESS_GRAPH);

  return (
    <Page
      title="Activity"
      tabs={[
        {
          label: 'Activity',
          to: routes.Activity.path,
        },
        {
          label: 'Reports',
          to: routes.Reports.path,
        },
      ]}
      button={isEnabled ? <AccessGraphButton /> : undefined}
    >
      <Outlet />
    </Page>
  );
}
