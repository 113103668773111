import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { Stack } from '@mui/material';
import { ConnectedIntegrationStatus, ConnectorsStatus, DateTime } from '@utils';
import { IntegrationStatus as IntegrationStatusModel, ResourceStatusV1 } from '@api';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import StatusIcon from '@common/ui/StatusIcon';
import { AccessStatusModel } from '@common/constants';

export interface StatusProps {
  status:
    | ConnectedIntegrationStatus
    | IntegrationStatusModel
    | ConnectorsStatus
    | AccessStatusModel
    | ResourceStatusV1
    | string;
  icon?: ReactNode;
  last_sync_time?: number;
  last_sync_ago?: boolean;
  prefix?: string;
}

export function StatusColumn({
  status,
  icon,
  last_sync_time,
  prefix = 'Synced',
  last_sync_ago = true,
  children,
}: PropsWithChildren<StatusProps>) {
  const statusName = useMemo(() => {
    switch (status) {
      case AccessStatusModel.PendingMFA:
        return 'Pending MFA';
      default:
        return status.toLowerCase().replace(/^./, status[0].toUpperCase());
    }
  }, [status]);

  return (
    <Stack direction="column" alignItems="start">
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon || <StatusIcon status={status} />}
        <div>
          <PrimarySecondary
            primary={statusName}
            secondary={
              last_sync_time
                ? `${prefix} ${
                    last_sync_ago
                      ? DateTime.fromUnix(last_sync_time).readableRelative
                      : DateTime.fromUnixWithFormat(last_sync_time)
                  }`
                : undefined
            }
          />
          {children}
        </div>
      </Stack>
    </Stack>
  );
}
