import { PropsWithChildren, useEffect } from 'react';

import { getOrganizationQp, Provider } from '@libs/auth';
import { Loader } from '@components';

import { useCheckAuthentication } from '@hooks';
import { useAuthUser } from '@frontegg/react';
import { useSession } from '@api';
import * as Sentry from '@sentry/react';

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const fronteggContext = {
    baseUrl: FRONTEGG_URL || '',
    clientId: FRONTEGG_CLIENT_ID || '',
    tenantResolver,
  };

  const fronteggAuth = {
    keepSessionAlive: true,
  };

  function tenantResolver() {
    return {
      tenant: getOrganizationQp(),
    };
  }

  return (
    <>
      <Provider contextOptions={fronteggContext} authOptions={fronteggAuth} customLoader={true} hostedLoginBox={false}>
        {children}
      </Provider>
    </>
  );
}

export function CheckAuthentication({ children }: PropsWithChildren<unknown>) {
  const isAuthenticated = useCheckAuthentication();
  const user = useAuthUser();
  const { session } = useSession();

  useEffect(() => {
    if (!session || !user) return;
    const { id, email, name: username } = user;
    const { id: accountId, name: accountName, plan } = session?.account || {};
    Sentry.setUser({
      email,
      username,
      id,
      account: {
        id: accountId,
        name: accountName,
        plan,
      },
    });
  }, [session, user]);

  return isAuthenticated ? <>{children}</> : <Loader />;
}

export function Authenticated({ children }: PropsWithChildren<unknown>) {
  return (
    <AuthProvider>
      <CheckAuthentication>{children}</CheckAuthentication>
    </AuthProvider>
  );
}
