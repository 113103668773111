import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { IntegrationStatus, useGetCatalogV2, useListAgents } from '@api';
import {
  MaterialIcon,
  SearchFilterFieldSelect,
  SearchFilterFieldSelectOption,
  SearchFilterItem,
  SearchFilters,
  SearchFilterValueSelect,
} from '@components';
import { kababToCapitalCase } from '@utils';
import { Category } from '@organisms/CatalogV2';

export const FilterQueryParams = {
  Category: 'category',
  Type: 'type',
  Status: 'status',
  Connector: 'connector',
} as const;

export type FilterQueryParamsKeys = (typeof FilterQueryParams)[keyof typeof FilterQueryParams];

const FilterNames: Record<FilterQueryParamsKeys, string> = {
  [FilterQueryParams.Category]: 'Category',
  [FilterQueryParams.Type]: 'Type',
  [FilterQueryParams.Status]: 'Status',
  [FilterQueryParams.Connector]: 'Connector',
};

interface ConnectedIntegrationsFilter {
  key: number;
  field?: FilterQueryParamsKeys;
  value?: string;
}

const queryParamsStrings = Object.values(FilterQueryParams);

function spToFilters(sp: URLSearchParams): ConnectedIntegrationsFilter[] {
  let key = 0;
  const filters: ConnectedIntegrationsFilter[] = [];

  queryParamsStrings.forEach((field) => {
    const value = sp.get(field);
    if (value) {
      filters.push({
        key: key++,
        field,
        value,
      });
    }
  });

  if (filters.length === 0) {
    filters.push({ key: 0 });
  }

  return filters;
}

function useConnectedIntegrationsFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<ConnectedIntegrationsFilter[]>(spToFilters(searchParams));
  const [popoverOpened, setPopoverOpened] = useState(false);

  function togglePopover() {
    setPopoverOpened((prev) => !prev);
    if (!popoverOpened) {
      setFilters(spToFilters(searchParams));
    }
  }

  function closePopover() {
    setPopoverOpened(false);
  }

  const options: SearchFilterFieldSelectOption<FilterQueryParamsKeys>[] = useMemo(
    () =>
      queryParamsStrings.map((param) => ({
        key: param,
        value: FilterNames[param],
        disabled: !!filters.find((f) => f.field === param),
      })),
    [filters],
  );

  function addFilter() {
    setFilters((prev) => {
      if (prev.length === 0) {
        return [{ key: 0 }];
      }

      const lastFilter = prev[prev.length - 1];
      return [...prev, { key: lastFilter.key + 1 }];
    });
  }

  function updateFilter(updatedFilter: ConnectedIntegrationsFilter) {
    setFilters((prev) => prev.map((f) => (f.key === updatedFilter.key ? updatedFilter : f)));
  }

  function deleteFilter(key: number) {
    setFilters((prev) => prev.filter((f) => f.key !== key));
  }

  function clearAllFilters() {
    const lastFilter = filters[filters.length - 1];
    setFilters([{ key: lastFilter.key + 1 }]);

    const newSearchParams = new URLSearchParams(searchParams);
    queryParamsStrings.forEach((k) => {
      newSearchParams.delete(k);
    });
    setSearchParams(newSearchParams);
  }

  function applyFilters() {
    const newSearchParams = new URLSearchParams(searchParams);
    queryParamsStrings.forEach((k) => {
      newSearchParams.delete(k);
    });

    filters.forEach(({ field, value }) => {
      if (!field || !value) return;
      newSearchParams.set(field, value);
    });

    setSearchParams(newSearchParams);

    closePopover();
  }

  const realFiltersCount = useMemo(() => {
    let count = 0;
    queryParamsStrings.forEach((k) => {
      count += searchParams.getAll(k).length;
    });
    return count;
  }, [searchParams]);

  return {
    filters,
    realFiltersCount,
    popover: {
      isOpen: popoverOpened,
      toggleDropdown: togglePopover,
      closeDropdown: closePopover,
    },
    options,
    addFilter,
    updateFilter,
    deleteFilter,
    clearAllFilters,
    applyFilters,
  };
}

export function ConnectedIntegrationsFilters() {
  const {
    filters,
    realFiltersCount,
    popover,
    options,
    addFilter,
    clearAllFilters,
    applyFilters,
    updateFilter,
    deleteFilter,
  } = useConnectedIntegrationsFilters();

  return (
    <SearchFilters
      popover={popover}
      trigger={
        <Button
          size="small"
          variant={realFiltersCount > 0 ? 'contained-filters' : 'outlined-filters'}
          startIcon={<MaterialIcon symbol="filter_list" />}
          endIcon={<MaterialIcon symbol="expand_more" />}
          component="div"
        >
          Filters{realFiltersCount > 0 && `/${realFiltersCount}`}
        </Button>
      }
      onNewItemClick={addFilter}
      onClearClick={realFiltersCount > 0 ? clearAllFilters : undefined}
      onApplyClick={applyFilters}
    >
      {filters.map((filter, index) => (
        <ConnectedIntegrationsFilterItem
          key={filter.key}
          prefix={index === 0 ? 'Where' : 'And'}
          options={options}
          filter={filter}
          onUpdate={(value) => updateFilter(value)}
          onDelete={Object.keys(filters).length > 1 ? () => deleteFilter(filter.key) : undefined}
        />
      ))}
    </SearchFilters>
  );
}

interface ConnectedIntegrationsFilterItemProps {
  options: SearchFilterFieldSelectOption<FilterQueryParamsKeys>[];
  filter: ConnectedIntegrationsFilter;
  onUpdate: (value: ConnectedIntegrationsFilter) => void;
  prefix?: string;
  onDelete?: () => void;
}

function useConnectedIntegrationsFilterItem({ filter, onUpdate }: ConnectedIntegrationsFilterItemProps) {
  function handleTypeSelect(value: FilterQueryParamsKeys) {
    onUpdate({ ...filter, field: value, value: undefined });
  }

  const operation = useMemo(() => {
    if (!filter.field) return undefined;
    return <>{'is'}</>;
  }, [filter]);

  const valueSelect = useMemo(() => {
    if (!filter.field) return;

    const selectProps = {
      value: filter.value,
      onChange: (value?: string) => onUpdate({ ...filter, value }),
    };

    switch (filter.field) {
      case FilterQueryParams.Category:
        return <ConnectedIntegrationsFilterCategoryValueSelect {...selectProps} />;
      case FilterQueryParams.Type:
        return <ConnectedIntegrationsFilterTypeValueSelect {...selectProps} />;
      case FilterQueryParams.Status:
        return <ConnectedIntegrationsFilterStatusValueSelect {...selectProps} />;
      case FilterQueryParams.Connector:
        return <ConnectedIntegrationsFilterConnectorValueSelect {...selectProps} />;
    }
  }, [filter, onUpdate]);

  return {
    operation,
    valueSelect,
    handleTypeSelect,
  };
}

function ConnectedIntegrationsFilterItem({
  options,
  filter: value,
  onUpdate,
  prefix = 'Where',
  onDelete,
}: ConnectedIntegrationsFilterItemProps) {
  const { operation, valueSelect, handleTypeSelect } = useConnectedIntegrationsFilterItem({
    options,
    filter: value,
    onUpdate,
  });

  return (
    <SearchFilterItem
      prefix={<>{prefix}</>}
      fieldSelect={<SearchFilterFieldSelect options={options} value={value.field} onChange={handleTypeSelect} />}
      operation={operation}
      onDelete={onDelete}
    >
      {valueSelect}
    </SearchFilterItem>
  );
}

interface ConnectedIntegrationsFilterValueSelectProps {
  value?: string;
  onChange: (value?: string) => void;
}

function useConnectedIntegrationsFilterCategoryValueSelectV2() {
  const { isIntegrationsConfigsFetched, integrationsConfigs } = useGetCatalogV2();

  const options = useMemo(() => {
    if (!isIntegrationsConfigsFetched) return [];
    // return categories from integrationsConfigs, but dont repeat them
    const categories = new Set<string>();
    integrationsConfigs.forEach((config) => {
      categories.add(config.category);
    });

    return Array.from(categories).map((c) => ({
      label: Category[c as keyof typeof Category],
      value: c,
    }));
  }, [integrationsConfigs, isIntegrationsConfigsFetched]);

  return {
    options,
    isIntegrationsConfigsFetched,
  };
}

function ConnectedIntegrationsFilterCategoryValueSelect({
  value,
  onChange,
}: ConnectedIntegrationsFilterValueSelectProps) {
  const { options: optionsV2, isIntegrationsConfigsFetched } = useConnectedIntegrationsFilterCategoryValueSelectV2();

  return (
    <SearchFilterValueSelect
      value={value}
      options={optionsV2}
      loading={!isIntegrationsConfigsFetched}
      onChange={onChange}
    />
  );
}

function useConnectedIntegrationsFilterTypeValueSelectV2() {
  const { isIntegrationsConfigsFetched, integrationsConfigs } = useGetCatalogV2();

  const options = useMemo(() => {
    if (!isIntegrationsConfigsFetched) return [];
    // return types from integrationsConfigs but dont repeat them
    const types = new Set<string>();
    integrationsConfigs.forEach((config) => {
      types.add(config.type);
    });

    return Array.from(types).map((c) => ({
      label: kababToCapitalCase(c),
      value: c,
    }));
  }, [integrationsConfigs, isIntegrationsConfigsFetched]);

  return {
    options,
    isIntegrationsConfigsFetched,
  };
}

function ConnectedIntegrationsFilterTypeValueSelect({ value, onChange }: ConnectedIntegrationsFilterValueSelectProps) {
  const { options: optionsV2, isIntegrationsConfigsFetched } = useConnectedIntegrationsFilterTypeValueSelectV2();

  return (
    <SearchFilterValueSelect
      value={value}
      options={optionsV2}
      loading={!isIntegrationsConfigsFetched}
      onChange={onChange}
    />
  );
}

function ConnectedIntegrationsFilterStatusValueSelect({
  value,
  onChange,
}: ConnectedIntegrationsFilterValueSelectProps) {
  const options = Object.values(IntegrationStatus).map((s) => ({
    label: s,
    value: s,
  }));
  return <SearchFilterValueSelect value={value} options={options} onChange={onChange} />;
}

function useConnectedIntegrationsFilterConnectorValueSelect() {
  const { isConnectorsFetched, connectors } = useListAgents();

  const options = useMemo(() => {
    if (!isConnectorsFetched) return [];
    return connectors.map((c) => ({
      label: c.name || 'Connector',
      value: c.agent_id,
    }));
  }, [connectors, isConnectorsFetched]);

  return {
    options,
    isOptionsFetched: isConnectorsFetched,
  };
}

function ConnectedIntegrationsFilterConnectorValueSelect({
  value,
  onChange,
}: ConnectedIntegrationsFilterValueSelectProps) {
  const { options, isOptionsFetched } = useConnectedIntegrationsFilterConnectorValueSelect();
  return <SearchFilterValueSelect value={value} options={options} loading={!isOptionsFetched} onChange={onChange} />;
}
