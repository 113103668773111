import type { AxiosError } from '@libs';
import { axios, getLoginPartialPath, useNavigate } from '@libs';

import { useGlobalNotifier } from '@hooks';
import { useEffect } from 'react';

axios.interceptors.response.use(
  (r) => {
    if (!r) return Promise.reject(new Error('No response'));
    return r.data;
  },
  (e) => Promise.reject(e),
);

export function useAxiosInterceptor() {
  const { notifyError } = useGlobalNotifier();
  const navigate = useNavigate();
  const loginPath = getLoginPartialPath();

  useEffect(() => {
    axios.interceptors.response.use(
      (r) => r,
      function (error: AxiosError) {
        let message = error.message;

        if (axios.isAxiosError<{ message: string } | string>(error) && error.response) {
          if (error.response.status === 401) {
            navigate(loginPath);
            return Promise.reject(error);
          }

          if (error.response.data && error.response.data !== '') {
            if (typeof error.response.data === 'string') {
              message = error.response.data;
            } else if (error.response.data.message) {
              message = error.response.data.message;
            } else {
              message = JSON.stringify(error.response.data);
            }
          }
        }

        if (!error?.config?.headers['Disable-Notification']) notifyError(message);

        return Promise.reject(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
