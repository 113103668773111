import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CreateUserPersonalTokenRequest } from '@api/apn-core';
import { AccountsService } from '@api';
import { QueryKeys } from '@api/query';

export function useListUserTokens() {
  const { data: apiTokens, isFetched: isApiTokensFetched } = useQuery({
    queryKey: [QueryKeys.UserTokens],
    queryFn: async () => AccountsService.listUserTokens(),
    initialData: [],
  });

  return {
    apiTokens,
    isApiTokensFetched,
  };
}

export function useCreateUserToken() {
  const queryClient = useQueryClient();

  const {
    mutate: createUserToken,
    data: newUserToken,
    isLoading: isCreateTokenLoading,
  } = useMutation({
    mutationFn: async (payload: CreateUserPersonalTokenRequest) =>
      AccountsService.createUserToken({
        createUserPersonalTokenRequest: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.UserTokens] });
    },
  });

  return {
    createUserToken,
    newUserToken,
    isCreateTokenLoading,
  };
}

export function useDeleteUserToken() {
  const queryClient = useQueryClient();

  const { mutate: deleteUserToken, isLoading: isDeleteTokenLoading } = useMutation({
    mutationFn: async (id: string) => AccountsService.deleteUserToken({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.UserTokens] });
    },
  });

  return {
    deleteUserToken,
    isDeleteTokenLoading,
  };
}
