import { AccordionSummary, AccordionSummaryProps } from '@mui/material';
import { MaterialIcon } from '@components';
import FormSectionHeader, { FormSectionHeaderProps } from './FormSectionHeader';

type FormSectionSummaryProps = FormSectionHeaderProps &
  Omit<AccordionSummaryProps, 'title'> & {
    withIcon?: boolean;
  };

export function FormSectionSummary({
  titleIcon,
  title,
  tooltip,
  optional,
  onClick,
  withIcon = true,
}: FormSectionSummaryProps) {
  return (
    <AccordionSummary expandIcon={withIcon && <MaterialIcon symbol="expand_more" color="disabled" />} onClick={onClick}>
      <FormSectionHeader titleIcon={titleIcon} title={title} tooltip={tooltip} optional={optional} />
    </AccordionSummary>
  );
}
