import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Stack, Grid, Box, FormLabel, Paper, Tooltip, Button, IconButton } from '@mui/material';

import { LabeledTextField, MaterialIcon } from '@components';
import { WebhookUpdateAppModel } from '@api';
import { LabeledTagsField } from '@components/LabeledTagsField';

export default function ResponseValidatorsFields() {
  const { control } = useFormContext<WebhookUpdateAppModel>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'response_validators',
  });

  return (
    <Stack direction="column" spacing={1}>
      <ResponseValidatorsFieldsLabel
        onAdd={() =>
          append({
            json_path: '',
            expected_values: [],
          })
        }
      />
      {fields.length > 0 && (
        <Stack direction="column" spacing={2}>
          {fields.map((item, index) => (
            <Paper variant="contained" key={item.id}>
              <Grid container columnGap={2} direction="row" flexWrap="nowrap" alignItems="center">
                <Grid item xs={4}>
                  <ResponseValidatorsFieldJsonPath index={index} />
                </Grid>
                <Grid item xs>
                  <ResponseValidatorsFieldExpectedValues index={index} />
                </Grid>
                <Grid item sx={{ width: 30 }} display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{ mt: '5px' }}
                    data-testid={`header-delete-btn-${item.id}`}
                  >
                    <MaterialIcon symbol="cancel" />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

function ResponseValidatorsFieldsLabel({ onAdd }: { onAdd: () => void }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <FormLabel component="div">Response Validators</FormLabel>
        <Tooltip
          title="Response validators are used to validate the response from the webhook. If the response does not match the validators, the webhook will be considered failed."
          arrow
        >
          <Box>
            <MaterialIcon symbol="help_outline" block size="small" />
          </Box>
        </Tooltip>
      </Stack>
      <Button onClick={onAdd} size="small">
        + Add
      </Button>
    </Stack>
  );
}

function ResponseValidatorsFieldJsonPath({ index }: { index: number }) {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Controller
      control={control}
      name={`response_validators.${index}.json_path`}
      rules={{ required: 'Json Path is required' }}
      render={({ field, fieldState: { invalid, error } }) => (
        <LabeledTextField
          label="Json Path"
          placeholder="$.data"
          error={invalid}
          helperText={error?.message}
          inputProps={field}
        />
      )}
    />
  );
}

function ResponseValidatorsFieldExpectedValues({ index }: { index: number }) {
  const { control } = useFormContext<WebhookUpdateAppModel>();
  return (
    <Controller
      control={control}
      name={`response_validators.${index}.expected_values`}
      rules={{ required: 'Expected Values is required' }}
      render={({ field, fieldState: { invalid, error } }) => (
        <LabeledTagsField
          label="Expected Values"
          placeholder="Type and press Enter"
          error={invalid}
          helperText={error?.message}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
}
