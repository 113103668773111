import { Chip, Stack, Typography } from '@mui/material';

import { AttributeFilterAppModel, GranteeFilterGroupAppModel, GranteeFilterGroupOperatorAppModel } from '@api';
import { nanoid } from 'nanoid';
import AccessFlowItemAdvancedGranteeChip from '@AccessFlows/components/AccessFlowItemAdvancedGranteeChip';
import { RouterLink } from '@libs';

interface AccessFlowItemGranteesProps {
  grantees: GranteeFilterGroupAppModel;
  maxGrantees?: number;
  moreLink?: string;
}

export default function AccessFlowItemAdvancedGrantees({
  grantees,
  maxGrantees = 5,
  moreLink,
}: AccessFlowItemGranteesProps) {
  const slicedFilters = grantees.attribute_filters.slice(0, maxGrantees);
  const moreCount = grantees.attribute_filters.length - slicedFilters.length;

  return (
    <Stack direction="column" alignItems="flex-end" spacing={1}>
      {grantees.attribute_filters.length === 0 && (
        <Typography variant="body2" color="text.muted">
          <em>No grantees</em>
        </Typography>
      )}
      {slicedFilters.map((filter, i) => (
        <AccessFlowItemGrantee
          key={`${nanoid()}`}
          filter={filter}
          operator={grantees.logical_operator}
          showOperator={i > 0}
        />
      ))}
      {moreCount > 0 && moreLink && (
        <Chip
          label={
            <Typography variant="body2" color="primary">
              +{moreCount} more
            </Typography>
          }
          size="small"
          variant="outlined"
          clickable
          component={RouterLink}
          to={moreLink}
        />
      )}
    </Stack>
  );
}

interface AccessFlowItemGranteeProps {
  filter: AttributeFilterAppModel;
  operator: GranteeFilterGroupOperatorAppModel;
  showOperator?: boolean;
}

function AccessFlowItemGrantee({ filter, operator, showOperator }: AccessFlowItemGranteeProps) {
  // Its for later use

  // const [searchParams, setSearchParams] = useSearchParams();

  // const isActive = searchParams.getAll(GRANTEE_FILTER_PARAM).includes(grantee.id);

  // const handleOnClick = useCallback(() => {
  //   const allFilterGrantees = searchParams.getAll(GRANTEE_FILTER_PARAM);

  //   if (allFilterGrantees.includes(grantee.id)) {
  //     searchParams.delete(GRANTEE_FILTER_PARAM);
  //     allFilterGrantees.forEach((granteeId) => {
  //       if (granteeId !== grantee.id) {
  //         searchParams.append(GRANTEE_FILTER_PARAM, granteeId);
  //       }
  //     });
  //   } else {
  //     searchParams.append(GRANTEE_FILTER_PARAM, grantee.id);
  //   }

  //   setSearchParams(searchParams);
  // }, [grantee.id, searchParams, setSearchParams]);

  return (
    <Stack direction="row" spacing={1}>
      {showOperator && <Chip label={operator} variant="outlined" />}
      <AccessFlowItemAdvancedGranteeChip grantee={filter} />
    </Stack>
  );
}
