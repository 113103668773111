import { QueryFunctionContext, useQuery, useQueryClient } from '@tanstack/react-query';

import { API_LIST_LIMIT, WebhooksService, useListQuery } from '@api';

import { WebhooksQueryKeys } from './queryKeys';

export function useAppGetWebhook(id: string) {
  const { data: webhook, ...rest } = useQuery([WebhooksQueryKeys.WebhookDetails, id], async () =>
    WebhooksService.appGetWebhook({ id }),
  );

  return {
    webhook,
    ...rest,
  };
}

export function useAppGetWebhookHistory(id: string) {
  const fetchMethod = async ({ pageParam: skipParam = 0 }: QueryFunctionContext) =>
    WebhooksService.appGetWebhookHistory({
      id,
      limit: API_LIST_LIMIT,
      skip: skipParam,
    });

  return useListQuery({
    fetchMethod,
    queryKey: [WebhooksQueryKeys.WebhookDetails, id, WebhooksQueryKeys.WebhookHistory],
  });
}

export function useAppListWebhooks(search?: string) {
  const queryClient = useQueryClient();

  const fetchMethod = async ({ pageParam: skipParam = 0 }: QueryFunctionContext) => {
    const res = await WebhooksService.appListWebhooks({
      search,
      limit: API_LIST_LIMIT,
      skip: Math.max(skipParam, 0),
    });

    res.data.forEach((webhook) => {
      queryClient.setQueryData([WebhooksQueryKeys.WebhookDetails, webhook.id], webhook);
    });

    return res;
  };

  return useListQuery({
    fetchMethod,
    queryKey: [WebhooksQueryKeys.WebhooksList, search],
  });
}
