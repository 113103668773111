import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useLocalStorage } from 'usehooks-ts';

const THEME_COLOR_SCHEME = '_THEME_COLOR_SCHEME_';

export enum ThemeColorScheme {
  system = 'system',
  light = 'light',
  dark = 'dark',
}

export function useThemeMode(forceDarkMode?: boolean) {
  const systemPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [themeColorScheme, setThemeColorScheme] = useLocalStorage(THEME_COLOR_SCHEME, ThemeColorScheme.dark);

  const prefersDarkMode = useMemo(() => {
    if (forceDarkMode !== undefined) return forceDarkMode;

    switch (themeColorScheme) {
      case ThemeColorScheme.light:
        return false;
      case ThemeColorScheme.dark:
        return true;
      case ThemeColorScheme.system:
        return systemPrefersDarkMode;
      default:
        return true;
    }
  }, [forceDarkMode, systemPrefersDarkMode, themeColorScheme]);

  const handleThemeModeSwitch = (scheme: ThemeColorScheme) => {
    setThemeColorScheme(scheme);
  };

  return {
    themeColorScheme,
    prefersDarkMode,
    handleThemeModeSwitch,
  };
}
