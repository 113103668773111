import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useFlagFixed } from '@hooks';
import { Flag, getStartEndDateFromQP } from '@utils';

import { ProState } from '@components/Packages/ProState';
import TableListTotal from '@common/components/TableListTotal';
import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';
import { USER_FILTER_PARAM } from '@AuditEvents/organisms/AuditEventsUsersFilter';
import { INTEGRATION_FILTER_PARAM } from '@common/organisms/CommonFilters/IntegrationFilter';

import AuditEventsTable, { AFTER_PAGE_PARAM, BEFORE_PAGE_PARAM } from '@AuditEvents/organisms/AuditEventsTable';
import AuditEventFilters from '@AuditEvents/organisms/AuditEventFilters';
import { useListAuditEvents } from '@AuditEvents/services/use-list-audit-events';
import AuditEventsPagination from '@AuditEvents/organisms/AuditEventsPagination';
import { EVENT_TYPE_FILTER_PARAM } from '@AuditEvents/organisms/AuditEventTypeFilter';
import { AuditEventsFilterInput, InputMaybe } from '@api/gql/graphql';

export function AuditEventsPage() {
  const { isPremium: isPremiumBundlesPage } = useFlagFixed(Flag.SESSION_AUDIT);
  return isPremiumBundlesPage ? <ProState /> : <AuditEventsPageContent />;
}

function useListAuditEventsFilter(): InputMaybe<AuditEventsFilterInput> | undefined {
  const [searchParams] = useSearchParams();
  const { startDate, endDate } = getStartEndDateFromQP(searchParams);

  const filter: InputMaybe<AuditEventsFilterInput> = {};

  const eventType = searchParams.getAll(EVENT_TYPE_FILTER_PARAM);
  if (eventType.length > 0) filter.eventType = eventType;

  const search = searchParams.get(SEARCH_QUERY_PARAM);
  if (search) filter.search = search;

  const userEmail = searchParams.getAll(USER_FILTER_PARAM);
  if (userEmail.length > 0) filter.userEmail = userEmail;

  const integrationId = searchParams.getAll(INTEGRATION_FILTER_PARAM);
  if (integrationId.length > 0) filter.integrationId = integrationId;

  if (startDate && endDate) filter.timestamp = { between: [startDate.fullIsoString, endDate.fullIsoString] };
  else if (startDate) filter.timestamp = { after: startDate.fullIsoString };
  else if (endDate) filter.timestamp = { before: endDate.fullIsoString };

  return Object.keys(filter).length === 0 ? undefined : filter;
}

function useListAuditEventsPagination() {
  const [searchParams] = useSearchParams();
  return {
    take: 50,
    after: searchParams.get(AFTER_PAGE_PARAM),
    before: searchParams.get(BEFORE_PAGE_PARAM),
  };
}

function AuditEventsPageContent() {
  const filter = useListAuditEventsFilter();
  const pagination = useListAuditEventsPagination();
  const { data, isFetched, isFetching } = useListAuditEvents({ filter, pagination });

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <AuditEventFilters />
        {data && <TableListTotal displayedResults={data?.auditEvents.totalCount} totalResults={0} />}
      </Stack>
      <AuditEventsTable data={data} isFetched={isFetched} isLoading={isFetching} />
      {data && data.auditEvents.edges.length > 0 && (
        <AuditEventsPagination
          loading={isFetching}
          pageInfo={data.auditEvents.pageInfo}
          firstCursor={data.auditEvents.edges[0].cursor}
          lastCursor={data.auditEvents.edges[data.auditEvents.edges.length - 1]?.cursor}
        />
      )}
    </Stack>
  );
}
