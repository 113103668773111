import { PropsWithChildren, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';

import { aponoTheme } from '../../theme';
import { useThemeMode } from '@common/hooks/use-theme-mode.hook';

export interface ThemeProps {
  isDark?: boolean;
}

export function Theme({ isDark, children }: PropsWithChildren<ThemeProps>) {
  const { prefersDarkMode } = useThemeMode(isDark);
  const theme = useMemo(() => aponoTheme(prefersDarkMode), [prefersDarkMode]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
