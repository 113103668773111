import { selector } from 'recoil';
import { IntegrationsService, IntegrationsCatalogModel as IntegrationCatalog, Integration } from '@api';

export const integrationsConfigsSelector = selector<IntegrationCatalog>({
  key: 'IntegrationsConfigsSelector',
  get: async () => IntegrationsService.getCatalog(),
});

export const connectedIntegrationsSelector = selector<Integration[]>({
  key: 'ConnectedIntegrationsSelector',
  get: async () => {
    const { data } = await IntegrationsService.listIntegrationsV2();
    return data;
  },
});
