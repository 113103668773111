import { Stack } from '@mui/material';

import { NoResults } from '@components';

import { CommonAccessFlowAppModel, GranteeItem, TargetItem } from '@AccessFlows/common/types';

import AccessFlowItemGrid from '@AccessFlows/components/AccessFlowItemGrid';

import AccessFlowItemName from './AccessFlowListItemColumns/AccessFlowItemName';
import AccessFlowItemGrantees from './AccessFlowListItemColumns/AccessFlowItemGrantees';
import AccessFlowItemTargets from './AccessFlowListItemColumns/AccessFlowItemTargets';
import AccessFlowItemAdvancedGrantees from './AccessFlowListItemColumns/AccessFlowItemAdvancedGrantees';

import AccessFlowItemActions from './AccessFlowListItemColumns/AccessFlowItemActions';
import { generatePath } from 'react-router-dom';
import { routes } from '@routes';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import AccessFlowItemContainer from '@AccessFlows/components/AccessFlowItemContainer';

interface AccessFlowsListProps {
  accessFlowsItems: AccessFlowsListItemProps[];
}

export default function AccessFlowsList({ accessFlowsItems }: AccessFlowsListProps) {
  if (accessFlowsItems.length === 0) {
    return <NoResults>No access flows found</NoResults>;
  }

  return (
    <Stack direction="column" spacing={2}>
      {accessFlowsItems.map((item) => (
        <AccessFlowListItem key={item.accessFlow.id} {...item} />
      ))}
    </Stack>
  );
}

export interface AccessFlowsListItemProps {
  accessFlow: CommonAccessFlowAppModel;
  grantees: GranteeItem[];
  targets: TargetItem[];
}

function AccessFlowListItem({ accessFlow, grantees, targets }: AccessFlowsListItemProps) {
  const { isEnabled: isAdvancedGranteesEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);

  return (
    <AccessFlowItemContainer
      targets={
        <AccessFlowItemGrid
          grantees={
            isAdvancedGranteesEnabled ? (
              <AccessFlowItemAdvancedGrantees
                grantees={accessFlow.granteesV2}
                moreLink={generatePath(routes.EditAccessFlow.path, { id: accessFlow.id })}
              />
            ) : (
              <AccessFlowItemGrantees grantees={grantees} />
            )
          }
          targets={
            <AccessFlowItemTargets
              targets={targets}
              moreLink={generatePath(routes.EditAccessFlow.path, { id: accessFlow.id })}
            />
          }
        />
      }
    >
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <AccessFlowItemName accessFlow={accessFlow} />
        <AccessFlowItemActions accessFlow={accessFlow} />
      </Stack>
    </AccessFlowItemContainer>
  );
}
