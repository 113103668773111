import { Page } from '@components';
import { AccessFlowsUsage } from '@DashboardV2/organisms/AccessFlowsUsage';
import { AttackSurfaceReduction } from '@DashboardV2/organisms/AttackSurfaceReduction';
import { CurrentActiveAccess } from '@DashboardV2/organisms/CurrentActiveAccess';
import YourEnvironments from '@DashboardV2/organisms/YourEnvironments';
import { NewRequests } from '@DashboardV2/organisms/NewRequests';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Top10Environments } from '@DashboardV2/organisms/TopListWidgets/Top10Environments';
import { Top10CloudServices } from '@DashboardV2/organisms/TopListWidgets/Top10CloudServices';
import { AccessRightSizing } from '@DashboardV2/organisms/AccessRightSizing';

import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { AlertTypes } from '@DashboardV2/organisms/AlertsTypes';
import { AlertStatsOverTimeDashboard } from '@DashboardV2/organisms/AlertStatsOverTimeDashboard';
export default function DashboardV2() {
  const { isEnabled: isAccessRightSizingEnable } = useFlagFixed(Flag.ACCESS_RIGHT_SIZING);
  const { isEnabled: isAnomaliesEnabled } = useFlagFixed(Flag.ACCESS_ANOMALIES);

  return (
    <Page title="Overview">
      <Stack paddingBottom={1}>
        <Typography variant="helperText" color="text.muted" display="flex" flexDirection="row-reverse">
          Last sync within the last 5 minutes
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CurrentActiveAccess />
        </Grid>
        <Grid item xs={3}>
          <div style={{ height: '100%' }}>
            <Stack direction="column" spacing={2} alignItems="stretch" style={{ height: '100%' }}>
              <Box sx={{ flex: 1 }}>
                <AttackSurfaceReduction />
              </Box>
              <Box sx={{ flex: 1 }}>
                <NewRequests />
              </Box>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={6}>
          <YourEnvironments />
        </Grid>
        <Grid item xs={6}>
          <Top10Environments />
        </Grid>
        <Grid item xs={6}>
          <Top10CloudServices />
        </Grid>
        <Grid item xs={3}>
          <AccessFlowsUsage />
        </Grid>
        <Grid item xs={9}>
          {isAccessRightSizingEnable && <AccessRightSizing />}
        </Grid>
        {isAnomaliesEnabled && (
          <>
            <Grid item xs={6}>
              <AlertTypes />
            </Grid>
            <Grid item xs={6}>
              <AlertStatsOverTimeDashboard />
            </Grid>
          </>
        )}
      </Grid>
    </Page>
  );
}
