import { sub } from 'date-fns';
import { AlertStatsOverTime } from '@AccessAlerts/organisms/AlertStatsOverTime';

export function AlertStatsOverTimeDashboard() {
  const currentDate = new Date();
  const previous = sub(currentDate, { days: 30 });
  const timestamp = { between: [previous.toISOString(), currentDate.toISOString()] };

  return <AlertStatsOverTime filters={{ timestamp }} />;
}
