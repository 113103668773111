import { Backdrop, Box, CircularProgress, Paper, TableContainer, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const TableWrapperContainer = styled(Box)({
  position: 'relative',
  width: '100%',

  '& .MuiTableContainer-root': {
    overflow: 'auto',
  },

  '& .MuiTable-root': {
    width: '100%',
  },
});

export interface TableWrapperProps {
  loading?: boolean;
}

export default function TableWrapper({ children, loading = false }: PropsWithChildren<TableWrapperProps>) {
  return (
    <TableWrapperContainer>
      <Backdrop sx={{ position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress />
      </Backdrop>
      <TableContainer component={Paper} elevation={0}>
        {children}
      </TableContainer>
    </TableWrapperContainer>
  );
}
