import { MaterialIcon } from '@components';
import { Stack, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface WidgetCardTitleProps {
  title: string;
  subtitle?: string;
  tooltip?: string;
  button?: ReactNode;
}

export function WidgetCardTitle({ title, subtitle, tooltip, button }: WidgetCardTitleProps) {
  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-start">
      <Stack direction="column">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle2">{title}</Typography>
          {tooltip && (
            <Tooltip title={tooltip} arrow placement="top">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <MaterialIcon symbol="info" size="small" />
              </div>
            </Tooltip>
          )}
        </Stack>
        {subtitle && (
          <Typography variant="helperText" color="text.muted">
            {subtitle}
          </Typography>
        )}
      </Stack>
      {button}
    </Stack>
  );
}
