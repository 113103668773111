import { Controller, useFormContext } from 'react-hook-form';

import { WebhookUpdateAppModel } from '@api';
import { LabeledTextField } from '@components';

export default function UrlField() {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  const urlPattern = /^(ftp|http|https):\/\/.*$/;

  return (
    <Controller
      control={control}
      name="url"
      rules={{
        required: 'Url is required',
        pattern: {
          value: urlPattern,
          message: 'Invalid URL',
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <LabeledTextField
          label="URL"
          placeholder="https://example.com/webhook"
          error={invalid}
          helperText={error?.message}
          type="url"
          inputProps={field}
        />
      )}
    />
  );
}
