import { ReactNode } from 'react';

function DisableClick({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        pointerEvents: 'none',
        userSelect: 'none',
      }}
    >
      {children}
    </div>
  );
}

export default DisableClick;
