import { Divider, Grid } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface AttributeItemContainerProps {
  groupName: ReactNode;
  hasDivider?: boolean;
}

export default function AttributeItemContainer({
  groupName,
  hasDivider,
  children,
}: PropsWithChildren<AttributeItemContainerProps>) {
  return (
    <>
      {hasDivider && <Divider />}
      <Grid container alignItems="center" columnSpacing={1}>
        <Grid item width={150}>
          {groupName}
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
