import { Box, Stack, useTheme } from '@mui/material';
import { IntegrationRowModel, IntegrationRows } from '@DashboardV2/components/TopListWidget/IntegrationRows';
import { ResponsivePie } from '@nivo/pie';
import { useMemo } from 'react';
import { DashboardPieWidgetTooltip } from '@DashboardV2/components/DashboardWidgetTooltip';

export function TopListWidget({ integrationsData }: { integrationsData: IntegrationRowModel[] }) {
  const theme = useTheme();

  const sortedData = useMemo(() => integrationsData.sort((a, b) => b.sum - a.sum), [integrationsData]);

  const firstFiveRows = sortedData.slice(0, 5);
  const otherRows = sortedData.slice(5);

  const otherRow: IntegrationRowModel = {
    title: 'Other',
    color: '#495469',
    sum: otherRows.reduce((acc, row) => acc + row.sum, 0),
    percent: otherRows.reduce((acc, row) => acc + row.percent, 0),
    id: 'other',
  };

  const displayRows = firstFiveRows.concat(otherRows.length > 0 ? [otherRow] : []);

  const pieData = useMemo(
    () =>
      displayRows.map((row) => ({
        id: row.id,
        label: row.title,
        value: row.sum,
        color: row.color,
      })),
    [displayRows],
  );

  return (
    <Stack direction="row" spacing={4} alignItems="center" height={320}>
      <Box width={150} height={150}>
        <ResponsivePie
          data={pieData}
          borderWidth={1}
          theme={{
            tooltip: {
              container: { background: theme.palette.background.default },
            },
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          tooltip={DashboardPieWidgetTooltip}
          colors={{ datum: 'data.color' }}
        />
      </Box>
      <IntegrationRows firstFiveRows={firstFiveRows} otherRows={otherRows} />
    </Stack>
  );
}
