import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';

import AppSidebarItem from '@common/ui/AppSidebarItem';
import { MaterialIcon, SidebarBadge, UpgradeWrapper } from '@components';
import { routes } from '@routes';
import { useFlagFixed, useIntegrationCatalogStatuses } from '@hooks';
import { Flag } from '@utils';
import { useSession } from '@api';
import NavBarSearch from './NavBarSearch';
import { GetStartedNavIcon } from '@organisms';
import NewBadge from '@common/ui/NewBadge';
import { CustomAnalyticsEvents } from '@utils/analytics';
import DividerText from '@common/ui/DividerText/DividerText';

export default function Navigation({
  minimized,
  setMinimized,
}: {
  minimized?: boolean;
  setMinimized: (value: boolean) => void;
}) {
  const { isEnabled: templateSuggestionIsOn } = useFlagFixed(Flag.OVERVIEW_TEMPLATE_SUGGESTION);
  const { isEnabled: showActiveAccess } = useFlagFixed(Flag.NEW_UI_ACTIVE_ACCESS);
  const { isEnabled: showAccessGraph, isPremium: isPremiumAccessGraph } = useFlagFixed(Flag.APONO_ACCESS_GRAPH);
  const { isPremium: isPremiumShowActiveAccess } = useFlagFixed(Flag.NEW_UI_ACTIVE_ACCESS);
  const { isEnabled: isBundlesAllowed, isPremium: isPremiumBundles } = useFlagFixed(Flag.UI_BUNDLES);
  const { isEnabled: isSessionAuditEnabled, isPremium: isSessionAuditPremium } = useFlagFixed(Flag.SESSION_AUDIT);
  const { isEnabled: webhooksIsOn } = useFlagFixed(Flag.OUTBOUND_WEBHOOKS);
  const { isEnabled: isAccessRightSizingEnable, isPremium: isPremiumAccessRightSizing } = useFlagFixed(
    Flag.ACCESS_RIGHT_SIZING,
  );
  const { isEnabled: isAlertsEnabled, isPremium: isPremiumAlerts } = useFlagFixed(Flag.ACCESS_ANOMALIES);

  const { session } = useSession();
  const isUserAdmin = session?.user?.roles?.includes('Admin');

  const location = useLocation();
  const isActive = useCallback(
    (prefix: string | string[]) => {
      const prefixes = Array.isArray(prefix) ? prefix : [prefix];
      return prefixes.some((p) => matchPath({ path: p, end: p === '/' }, location.pathname));
    },
    [location],
  );

  return (
    <Stack direction="column">
      <NavBarSearch minimized={minimized} setMinimized={setMinimized} />
      <DividerText text="Overview" minimized={minimized} />
      {templateSuggestionIsOn && (
        <AppSidebarItem
          enableAnimation
          label="Dashboard"
          minimized={minimized}
          icon={
            <GetStartedNavIcon testId="onboarding-badge" icon={<MaterialIcon symbol="view_quilt" weight={800} />} />
          }
          to={routes.Dashboard.path}
          active={isActive(routes.Dashboard.path)}
        />
      )}
      {isAccessRightSizingEnable && (
        <AppSidebarItem
          enableAnimation
          label="Right Sizing"
          minimized={minimized}
          icon={
            isPremiumAccessRightSizing ? (
              <UpgradeWrapper isPremium={isPremiumShowActiveAccess} withPopover={false}>
                <MaterialIcon symbol="vertical_align_center" weight={800} />
              </UpgradeWrapper>
            ) : (
              <NewBadge icon={<MaterialIcon symbol="vertical_align_center" weight={800} />} />
            )
          }
          to={routes.AccessRightSizing.path}
          active={isActive(routes.AccessRightSizing.path)}
        />
      )}
      {showActiveAccess && (
        <AppSidebarItem
          enableAnimation
          label="Access Graph"
          minimized={minimized}
          icon={
            <UpgradeWrapper isPremium={isPremiumShowActiveAccess} withPopover={false}>
              <MaterialIcon symbol="account_tree" weight={800} />
            </UpgradeWrapper>
          }
          to={routes.ActiveAccess.path}
          active={isActive(routes.ActiveAccess.path)}
        />
      )}
      {showAccessGraph && (
        <AppSidebarItem
          enableAnimation
          label="Access Graph"
          minimized={minimized}
          icon={
            isPremiumAccessGraph ? (
              <UpgradeWrapper isPremium={isPremiumAccessGraph} withPopover={false}>
                <MaterialIcon symbol="account_tree" weight={800} />
              </UpgradeWrapper>
            ) : (
              <NewBadge icon={<MaterialIcon symbol="account_tree" weight={800} />} />
            )
          }
          to={routes.AccessGraph.path}
          active={isActive(routes.AccessGraph.path)}
          data-trigger={CustomAnalyticsEvents.ACCESS_GRAPH_SIDEBAR_MENU_CLICKED}
        />
      )}
      {isAlertsEnabled && (
        <AppSidebarItem
          enableAnimation
          label="Anomalies"
          minimized={minimized}
          icon={
            isPremiumAlerts ? (
              <UpgradeWrapper isPremium={isPremiumAlerts} withPopover={false}>
                <MaterialIcon symbol="troubleshoot" weight={800} />
              </UpgradeWrapper>
            ) : (
              <NewBadge icon={<MaterialIcon symbol="troubleshoot" weight={800} />} />
            )
          }
          to={routes.AccessAlerts.path}
          active={isActive(routes.AccessAlerts.path)}
          data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_SIDEBAR_MENU_CLICKED}
        />
      )}

      <DividerText text="Access Management" minimized={minimized} />
      <AppSidebarItem
        enableAnimation
        label="Access Flows"
        minimized={minimized}
        icon={<MaterialIcon symbol="lock" weight={800} />}
        to={routes.AccessFlows.path}
        active={isActive(routes.AccessFlows.path)}
      />
      {isBundlesAllowed && (
        <AppSidebarItem
          enableAnimation
          label="Bundles"
          minimized={minimized}
          icon={
            <UpgradeWrapper isPremium={isPremiumBundles} withPopover={false}>
              <MaterialIcon symbol="stacks" weight={800} />
            </UpgradeWrapper>
          }
          to={routes.Bundles.path}
          active={isActive(routes.Bundles.path)}
        />
      )}
      <DividerText text="Environment" minimized={minimized} />
      <AppSidebarItem
        enableAnimation
        label="Integrations"
        minimized={minimized}
        icon={isUserAdmin ? <IntegrationsErrorBadge /> : <MaterialIcon symbol="cable" weight={800} />}
        to={routes.ConnectedIntegrations.path}
        active={isActive([routes.ConnectedIntegrations.path, routes.Catalog.path])}
      />
      <AppSidebarItem
        enableAnimation
        label="Connectors"
        minimized={minimized}
        icon={<MaterialIcon symbol="electrical_services" weight={800} />}
        to={routes.Connectors.path}
        active={isActive(routes.Connectors.path)}
      />
      <AppSidebarItem
        enableAnimation
        label="Identities"
        minimized={minimized}
        icon={<MaterialIcon symbol="group" weight={800} />}
        to={routes.Identities.path}
        active={isActive(routes.Identities.path)}
      />
      <DividerText text="Administration" minimized={minimized} />
      <AppSidebarItem
        enableAnimation
        label="Activity"
        minimized={minimized}
        icon={<MaterialIcon symbol="overview" weight={800} />}
        to={routes.Activity.path}
        active={isActive(routes.Activity.path)}
      />
      <AppSidebarItem
        enableAnimation
        label="Reports"
        minimized={minimized}
        icon={<MaterialIcon symbol="list" weight={800} />}
        to={routes.Reports.path}
        active={isActive(routes.Reports.path)}
      />
      {isSessionAuditEnabled && (
        <AppSidebarItem
          enableAnimation
          minimized={minimized}
          to={routes.AuditEvents.path}
          label={routes.AuditEvents.label}
          active={isActive(routes.AuditEvents.path)}
          icon={
            <UpgradeWrapper isPremium={isSessionAuditPremium} withPopover={false}>
              <MaterialIcon symbol="quick_reference_all" weight={800} />
            </UpgradeWrapper>
          }
          data-trigger={CustomAnalyticsEvents.SESSION_AUDIT_SIDEBAR_MENU_CLICKED}
        />
      )}
      {webhooksIsOn && (
        <AppSidebarItem
          enableAnimation
          label="Webhooks"
          minimized={minimized}
          icon={<MaterialIcon symbol="webhook" weight={800} />}
          to={routes.Webhooks.path}
          active={isActive(routes.Webhooks.path)}
        />
      )}
    </Stack>
  );
}

function IntegrationsErrorBadge() {
  const { aggregated_status } = useIntegrationCatalogStatuses();
  const integrationsErrorTotal = aggregated_status.find((r) => r.status == 'Error')?.total || 0;
  return <SidebarBadge icon={<MaterialIcon symbol="cable" weight={800} />} content={integrationsErrorTotal} />;
}
