import { adminLayoutWrap } from '@middlewares';

import { OnboardingConnectIntegrationGroupPage } from './pages/OnboardingConnectIntegrationGroupPage';
import { OnboardingConnectIntegrationPage } from './pages/OnboardingConnectIntegrationPage';
import { OnboardingAccessFlowPage } from './pages/OnboardingAccessFlowPage';
import { OnboardingEditIntegrationPage } from './pages/OnboardingEditIntegrationPage';
import { OnboardingRequestAccessPage } from './pages/OnboardingRequestAccessPage';
import { OnboardingCatalogPage } from './pages/OnboardingCatalogPage';
import { OnboardingAddAccessFlowPage } from './pages/OnboardingAddAccessFlowPage';
import { OnboardingEditAccessFlowPage } from './pages/OnboardingEditAccessFlowPage';
import { OnboardingWelcomePage } from './pages/OnboardingWelcomePage';

export const routes = {
  OnboardingWelcome: {
    label: 'Onboarding Welcome',
    path: '/onboarding-steps/welcome',
    page: adminLayoutWrap(<OnboardingWelcomePage />),
  },
  OnboardingCatalog: {
    label: 'Onboarding Catalog',
    path: '/onboarding-steps/catalog',
    page: adminLayoutWrap(<OnboardingCatalogPage />),
  },
  OnboardingConnectIntegration: {
    label: 'Onboarding Connect Integration',
    path: '/onboarding-steps/connect-integration/:type',
    page: adminLayoutWrap(<OnboardingConnectIntegrationPage />),
  },
  OnboardingEditIntegration: {
    label: 'Onboarding Edit Integration',
    path: '/onboarding-steps/edit-integration',
    page: adminLayoutWrap(<OnboardingEditIntegrationPage />),
  },
  OnboardingConnectIntegrationsGroup: {
    label: 'Onboarding Connect Integrations Group',
    path: '/onboarding-steps/connect-integrations-group/:group',
    page: adminLayoutWrap(<OnboardingConnectIntegrationGroupPage />),
  },
  OnboardingAccessFlow: {
    label: null,
    path: '/onboarding-steps/access-flow',
    page: adminLayoutWrap(<OnboardingAccessFlowPage />),
  },
  OnboardingAddAccessFlow: {
    label: 'Onboarding Create Access Flow',
    path: '/onboarding-steps/add-access-flow',
    page: adminLayoutWrap(<OnboardingAddAccessFlowPage />),
  },
  OnboardingEditAccessFlow: {
    label: 'Onboarding Edit Access Flow',
    path: '/onboarding-steps/edit-access-flow',
    page: adminLayoutWrap(<OnboardingEditAccessFlowPage />),
  },
  OnboardingRequestAccess: {
    label: 'Onboarding Request Access',
    path: '/onboarding-steps/request-access',
    page: adminLayoutWrap(<OnboardingRequestAccessPage />),
  },
};
