import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { Avatar, Box, Divider, Input, MenuItem, Select, Stack, styled, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';

import { SidebarSection } from './SidebarSection';
import { NavItem } from '@common/ui/NavItem/NavItem';
import { getLogoutUrl, useAuthUser } from '@libs';
import { MaterialIcon } from '@components/MaterialIcon';
import { AdminPortal } from '@libs/auth';
import { useAuthActions, useTenantsState } from '@frontegg/react';
import { ThemeColorScheme, useThemeMode } from '@common/hooks/use-theme-mode.hook';
import AppSidebarItem from '@common/ui/AppSidebarItem';

const UserSettingsPopover = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
  border: `1px solid ${theme.palette.background.active}`,
  color: theme.palette.text.primary,
}));

const UserSettingsProfile = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function UserSettingsThemeSwitcher() {
  const { enablePopoverOnClose, disablePopoverOnClose } = useUserPopoverContext();
  const { themeColorScheme, handleThemeModeSwitch } = useThemeMode();

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <NavItem icon={<MaterialIcon symbol="dark_mode" weight={800} />} text="Theme" />
        <Select<ThemeColorScheme>
          disableUnderline
          input={<UserSelectTenantInput />}
          value={themeColorScheme}
          onOpen={() => disablePopoverOnClose()}
          onClose={() => enablePopoverOnClose()}
          onChange={(e) => {
            handleThemeModeSwitch(e.target.value as ThemeColorScheme);
            enablePopoverOnClose();
          }}
        >
          <MenuItem key="system" value={ThemeColorScheme.system}>
            <Typography variant="helperText">System</Typography>
          </MenuItem>
          <MenuItem key="dark" value={ThemeColorScheme.dark}>
            <Typography variant="helperText">Dark</Typography>
          </MenuItem>
          <MenuItem key="light" value={ThemeColorScheme.light}>
            <Typography variant="helperText">Light</Typography>
          </MenuItem>
        </Select>
      </Stack>
    </Box>
  );
}

const UserSelectTenantInput = styled(Input)(({ theme }) => ({
  ...theme.typography.caption,
}));

function UserSwitchTenant({ tenantId }: { tenantId: string }) {
  const { enablePopoverOnClose, disablePopoverOnClose } = useUserPopoverContext();
  const [activeTenant, setActiveTenant] = useState<string>(tenantId);
  const { tenants } = useTenantsState();
  const { switchTenant } = useAuthActions();
  const sortedTenants = useMemo(() => {
    if (!tenants) return [];
    return [...tenants].sort((a, b) => a.name.localeCompare(b.name));
  }, [tenants]);

  return (
    <Select
      disableUnderline
      value={activeTenant}
      IconComponent={(props) => <ArrowDropDownIcon {...props} fontSize="small" />}
      onOpen={() => disablePopoverOnClose()}
      onClose={() => enablePopoverOnClose()}
      onChange={(e) => {
        setActiveTenant(e.target.value);
        switchTenant({
          tenantId: e.target.value,
          callback: (data, err) => {
            if (err) throw new Error(`Unexpected error when switching tenants: ${err}`);
            window.location.reload();
          },
        });
      }}
      input={<UserSelectTenantInput />}
    >
      {sortedTenants.map(({ name, tenantId: tid }) => (
        <MenuItem key={tid} value={tid}>
          <Typography variant="helperText">{name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

function UserSettingsProfileBox({ userName, role, tenantId }: { userName: string; role: string; tenantId: string }) {
  return (
    <UserSettingsProfile>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">{userName}</Typography>
        <Typography variant="caption">{role}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <UserSwitchTenant tenantId={tenantId} />
      </Stack>
    </UserSettingsProfile>
  );
}

interface UserPopoverProps extends PropsWithChildren {
  userName: string;
  tenantId: string;
  role: string;
  noSettings?: boolean;
  noAdmin?: boolean;
}

function UserPopover({ userName, tenantId, role, noAdmin = false }: UserPopoverProps) {
  const { isEnabled: personalTokensIsOn } = useFlagFixed(Flag.NEW_UI_PERSONAL_TOKENS);
  return (
    <UserSettingsPopover>
      <UserSettingsProfileBox userName={userName} role={role} tenantId={tenantId} />
      <Divider />
      <NavItem icon={<MaterialIcon symbol="settings" weight={800} />} text="General" to="/settings" />
      {!noAdmin && (
        <NavItem
          icon={<MaterialIcon symbol="handyman" weight={800} />}
          text="Users Management"
          to="#"
          onClick={() => {
            AdminPortal.show();
          }}
        />
      )}
      {personalTokensIsOn && (
        <NavItem
          icon={<MaterialIcon symbol="passkey" weight={800} />}
          text="Personal API Tokens"
          to="/personal-api-tokens"
        />
      )}
      <UserSettingsThemeSwitcher />
      <NavItem
        icon={<MaterialIcon symbol="account_circle" weight={800} />}
        text="Go to User Portal"
        to={USER_PORTAL_URL}
        aType="external"
      />
      <NavItem
        color="error"
        icon={<MaterialIcon color="error" symbol="logout" weight={800} />}
        text="Log Out"
        aType="internal"
        to={getLogoutUrl()}
      />
    </UserSettingsPopover>
  );
}

export interface UserProps {
  wide?: boolean;
  noSettings?: boolean;
  noAdmin?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const UserPopoverContext = createContext({
  enablePopoverOnClose: () => {
    // noop
  },
  disablePopoverOnClose: () => {
    // noop
  },
});

function useUserPopoverContext() {
  return useContext(UserPopoverContext);
}

export function User({ wide, noSettings, noAdmin }: UserProps) {
  const [disabledOnClose, setDisabledOnClose] = useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const user = useAuthUser();

  useEffect(() => {
    if (!wide) setPopoverIsOpen(false);
  }, [wide]);

  const userRoles = user.roles.map((r) => r.name).join(', ');

  return (
    <SidebarSection>
      <UserPopoverContext.Provider
        value={{
          enablePopoverOnClose: () => setDisabledOnClose(false),
          disablePopoverOnClose: () => setDisabledOnClose(true),
        }}
      >
        <Popover
          isOpen={popoverIsOpen}
          reposition
          positions={['top']}
          align="center"
          containerStyle={{ zIndex: '100', width: '240px' }}
          onClickOutside={() => {
            if (disabledOnClose) return;
            setPopoverIsOpen(false);
          }}
          content={
            <UserPopover
              userName={user.name}
              tenantId={user.tenantId}
              role={userRoles}
              noSettings={noSettings}
              noAdmin={noAdmin}
            />
          }
        >
          <div>
            <AppSidebarItem
              icon={<Avatar alt={user.name} src={user.profilePictureUrl || undefined} sx={{ width: 24, height: 24 }} />}
              label={
                <Typography variant="caption" data-testid="user-avatar-button">
                  {user.name}
                </Typography>
              }
              onClick={() => setPopoverIsOpen(!popoverIsOpen)}
              minimized={!wide}
              enableAnimation
              hoverable
            />
          </div>
        </Popover>
      </UserPopoverContext.Provider>
    </SidebarSection>
  );
}
