import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '@routes';

import { useOnboardingContext } from '@Onboarding/context';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export function OnboardingAccessFlowPage() {
  const { onboardingIds } = useOnboardingContext();
  const { track } = useAnalyticsContext();

  useEffect(() => {
    track(CustomAnalyticsEvents.ONBOARDING_STEP_1_COMPLETED);
  }, [track]);

  if (onboardingIds?.added_access_flow_id) {
    return <Navigate to={routes.OnboardingEditAccessFlow.path} />;
  }

  return <Navigate to={routes.OnboardingAddAccessFlow.path} />;
}
