import { useDeleteGroup } from '@Groups/services/queries';
import { AponoGroupModel } from '@api';
import { ConfirmDialog } from '@components';
import { useGlobalNotifier } from '@hooks';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

const DeleteGroupModal = ({
  groupInDeletion,
  onClose,
}: {
  groupInDeletion: AponoGroupModel | null;
  onClose: () => void;
}) => {
  const { deleteGroup, isSuccess } = useDeleteGroup();
  const { notifySuccess } = useGlobalNotifier();

  useEffect(() => {
    if (isSuccess) {
      notifySuccess(`Group ${groupInDeletion?.name} deleted`);
      onClose();
    }
  }, [isSuccess, groupInDeletion?.name, onClose, notifySuccess]);

  return (
    <ConfirmDialog open={!!groupInDeletion} onClose={onClose} onConfirm={() => deleteGroup(groupInDeletion?.id || '')}>
      <Typography variant="body2" component="div">
        Are you sure you want to delete the group <strong>{groupInDeletion?.name}</strong>?
      </Typography>
    </ConfirmDialog>
  );
};

export default DeleteGroupModal;
