import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn,
  useFormContext,
} from 'react-hook-form';
import { Typography } from '@mui/material';

import { LabeledAutocompleteField, LabeledTextField, Markdown } from '@components';
import { useListAgents, useListSecrets } from '@api';
import { useDebounce } from '@libs';
import { awsRegions } from '@utils';

import { IntegrationConfigFormPayload } from '../../Provider';
import { useMemo } from 'react';
import { useIsInViewMode } from '@Integrations/IntegrationConfigForm/utils';

const INFO = [
  'Apono doesn&apos;t store credentials. Please, specify where secret ID (to connect to the DB) is located and tag the secret in the AWS secret store the following way:',
  `Tag name: <code>apono-connector-read</code>`,
  `Tag value: <code>true</code>`,
  'For a detailed explanation see the [docs](https://docs.apono.io/docs/creating-secrets-in-aws-secret-store).',
].join('\n\n');

interface AwsSecretFormProps {
  required: boolean;
  isViewOnly?: boolean;
}

export function AwsSecretForm({ required, isViewOnly }: AwsSecretFormProps) {
  const { watch } = useFormContext<IntegrationConfigFormPayload>();
  const { connectors } = useListAgents();
  const provisioner_id = useDebounce(watch('provisioner_id'), 500);

  const awsAccountId = connectors.find((c) => c.agent_id === provisioner_id)?.cloud_provider?.aws_account_id;

  return (
    <>
      <Typography variant="inputLabel" component="div" gutterBottom>
        <Markdown>{INFO}</Markdown>
      </Typography>
      <RegionInput disabled={isViewOnly} required={required} />
      <IdSelectInput disabled={isViewOnly} required={required} awsAccountId={awsAccountId} />
    </>
  );
}

interface RegionInputProps {
  required: boolean;
  disabled?: boolean;
}

function RegionInput({ required, disabled }: RegionInputProps) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <Controller
      control={control}
      name="secret_config.region"
      rules={{
        required: {
          value: required,
          message: `Secret region is required`,
        },
      }}
      render={({ field: { onChange, value, ...restField }, fieldState: { invalid, error } }) => {
        if (typeof value !== 'string' && typeof value !== 'undefined') return <></>;

        return (
          <LabeledAutocompleteField
            options={awsRegions.map((r) => ({ id: r, label: r }))}
            label="Secret region"
            placeholder="Select region"
            error={invalid}
            helperText={error?.message}
            optional={!required}
            disabled={disabled}
            InputProps={restField}
            onChange={onChange}
            value={value}
          />
        );
      }}
    />
  );
}

interface IdSelectInputProps {
  required: boolean;
  awsAccountId?: string;
  disabled?: boolean;
}

function IdSelectInput({ required, awsAccountId, disabled }: IdSelectInputProps) {
  const { control, watch } = useFormContext<IntegrationConfigFormPayload>();
  const secretRegion = useDebounce(watch('secret_config.region'), 500);

  if (typeof secretRegion !== 'string' && typeof secretRegion !== 'undefined') return <></>;

  return (
    <Controller
      control={control}
      name="secret_config.secret_id"
      rules={{ required: { value: required, message: `Secret ID is required` } }}
      render={(props) => {
        if (!awsAccountId || !secretRegion) {
          const {
            field: { onChange, onBlur, value, name },
            fieldState: { invalid, error },
          } = props;
          return (
            <LabeledTextField
              label="Secret ID"
              placeholder="Select secret ID"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              disabled={disabled}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }

        return <SecretIdSelectField awsAccountId={awsAccountId} secretRegion={secretRegion} {...props} />;
      }}
    />
  );
}

interface SecretIdSelectFieldProps {
  awsAccountId: string;
  secretRegion: string;
  field: ControllerRenderProps<IntegrationConfigFormPayload, `secret_config.secret_id`>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<IntegrationConfigFormPayload>;
}

function SecretIdSelectField({
  awsAccountId,
  secretRegion,
  field: { value, onChange },
  fieldState: { invalid, error },
}: SecretIdSelectFieldProps) {
  const isViewMode = useIsInViewMode();
  const { secrets, isSecretsFetched } = useListSecrets(awsAccountId, secretRegion);

  return useMemo(() => {
    const isLoading = !isSecretsFetched;
    const options = secrets.map((s) => ({ id: s.arn, label: s.name }));

    if (isLoading) {
      return <LabeledTextField label="Secret ID" placeholder="Select secret ID" disabled />;
    }

    if (typeof value !== 'string' && typeof value !== 'undefined') return <></>;

    return (
      <LabeledAutocompleteField
        label="Secret ID"
        freeSolo
        placeholder="Select secret ID"
        loading={isLoading}
        disabled={isLoading || isViewMode}
        options={options}
        value={value}
        onChange={onChange}
        error={invalid}
        helperText={error?.message}
      />
    );
  }, [error?.message, invalid, isSecretsFetched, onChange, secrets, value]);
}
