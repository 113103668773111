import { PropsWithChildren } from 'react';
import { FlagProvider, useFlagsStatus } from '@unleash/proxy-client-react';
import { useAuthUser } from '@libs';
import { Loader } from '@components';
import { ErrorPage } from '@pages';

const host = window.location.host;
const protocol = window.location.protocol;

const apiCoreV1 = '/apn/core/api/v1';

const config = {
  url: `${protocol}//${host}${apiCoreV1}/feature-flags`,
  refreshInterval: 0,
  disableMetrics: true,
  appName: 'default', //required-field, ignored
  environment: 'default', //required-field, ignored
  clientKey: 'proxy-client-key', //required-field, ignored
};

export function FeatureFlagsProvider({ children }: PropsWithChildren) {
  const user = useAuthUser();

  config.clientKey = `Bearer ${user.accessToken}`;

  return (
    <FlagProvider config={config}>
      <FlagMiddlewareWrap>{children}</FlagMiddlewareWrap>
    </FlagProvider>
  );
}

function FlagMiddlewareWrap({ children }: PropsWithChildren<unknown>) {
  const { flagsReady, flagsError } = useFlagsStatus();

  if (!flagsReady) {
    return <Loader />;
  }

  if (flagsError) {
    return <ErrorPage errorCode={500} errorMessage={flagsError} />;
  }

  return <>{children}</>;
}
