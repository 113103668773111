import { Box, Stack, styled } from '@mui/material';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import MetricsBoxBody from './MetricsBoxBody';

export const MetricsCardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: theme.palette.background.active,
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
}));

interface MetricsBoxProps {
  mainValue: string;
  changeValue?: number;
  changePercentage?: number;
  timePeriod: string;
  title: string;
  subtitle?: string;
  isPositiveTrendError?: boolean;
}

export default function MetricsBox({
  mainValue,
  changeValue,
  changePercentage,
  timePeriod,
  title,
  subtitle,
  isPositiveTrendError = true,
}: MetricsBoxProps) {
  return (
    <MetricsCardContainer>
      <Stack direction="column" spacing={2} alignItems="start">
        <Stack direction="column" spacing={0.5} alignItems="start">
          <PrimarySecondary primary={title} secondary={subtitle} />
        </Stack>
        <MetricsBoxBody
          mainValue={mainValue}
          timePeriod={timePeriod}
          changeValue={changeValue}
          changePercentage={changePercentage}
          isPositiveTrendError={isPositiveTrendError}
        />
      </Stack>
    </MetricsCardContainer>
  );
}
