import { PropsWithChildren, ReactNode } from 'react';

import { Box, styled, Typography } from '@mui/material';
import { DateTime } from '@utils';
import { ActivityTimelineEventModel } from '@api';

const TimelineItemContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: '15px',
    bottom: 0,
    left: '7px',
    width: '1px',
    background: `repeating-linear-gradient(to bottom, ${theme.palette.divider}, ${theme.palette.divider} 5px, transparent 5px, transparent 10px)`,
  },

  '&:last-child:before': {
    display: 'none',
  },
}));

const TimelineItemIconContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '15px',
});

const TimelineItemIconWrap = styled(Box)(({ theme }) => ({
  width: '15px',
  height: '15px',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: '50%',
  position: 'relative',

  '&.success': {
    borderColor: theme.palette.success.main,

    '.MuiIcon-root': {
      color: theme.palette.success.main,
    },
  },

  '&.error': {
    borderColor: theme.palette.error.main,

    '.MuiIcon-root': {
      color: theme.palette.error.main,
    },
  },

  '&.warning': {
    borderColor: theme.palette.warning.main,

    '.MuiIcon-root': {
      color: theme.palette.warning.main,
    },
  },

  '&.primary': {
    borderColor: theme.palette.primary.main,

    '.MuiIcon-root': {
      color: theme.palette.primary.main,
    },
  },

  '.MuiIcon-root': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '10px',
    display: 'block',
  },
}));

export interface TimelineItemProps {
  date: number;
  title: string;
  secondTitle?: string;
  icon: ReactNode;
  mood?: 'success' | 'error' | 'warning' | 'primary';
  meta?: ActivityTimelineEventModel['metadata'];
}

export function TimelineItem({ date, title, secondTitle, icon, mood, children }: PropsWithChildren<TimelineItemProps>) {
  return (
    <TimelineItemContainer>
      <TimelineItemIconContainer>
        <TimelineItemIconWrap className={mood}>{icon}</TimelineItemIconWrap>
      </TimelineItemIconContainer>
      <Box>
        <Typography variant="tooltip" component="div" color="muted">
          {DateTime.fromUnix(date).readableWithSeconds}
        </Typography>
        <Typography variant="inputLabel" component={'span'}>
          {title}
        </Typography>
        {secondTitle && (
          <Typography variant="tooltip" color="muted" component="span" mx={0.5}>
            {secondTitle}
          </Typography>
        )}
        {children && <Box mt={1}>{children}</Box>}
      </Box>
    </TimelineItemContainer>
  );
}
