import { ReactNode } from 'react';
import { Badge, BadgeProps } from '@mui/material';

export interface SidebarBadgeProps {
  color?: BadgeProps['color'];
  icon: JSX.Element;
  content?: ReactNode;
  testId?: string;
}

export function SidebarBadge({ icon, color, content, testId }: SidebarBadgeProps) {
  return (
    <Badge
      sx={color ? undefined : { backgroundColor: 'transparent' }}
      badgeContent={content ? <span data-testid={testId}>{content}</span> : null}
      color={color || 'error'}
    >
      {icon}
    </Badge>
  );
}
