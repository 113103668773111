import { AccessFlowFormProps } from '@AccessFlows/common/types';
import FlowBuilderInfoBanner from '@AccessFlows/components/FlowBuilderInfoBanner';
import { AutoGrantForm } from '@AccessFlows/components/forms/AutoGrantForm';
import { ComplexAutoGrantForm } from '@AccessFlows/components/forms/ComplexAutoGrantForm';
import { ComplexUserRequestForm } from '@AccessFlows/components/forms/ComplexUserRequestForm';
import { UserRequestForm } from '@AccessFlows/components/forms/UserRequestForm';
import { useAccessFlowForm } from '@AccessFlows/hooks/use-access-flow-form';
import BundleViewModal from '@AccessFlows/organisms/BundleViewModal';
import FlowBuilderLabelsSelector from '@AccessFlows/organisms/FlowBuilderLabelsSelector';
import { TriggerType } from '@api';
import TabsSwitch from '@common/ui/TabsSwitch';
import { SettingSelector } from '@components/SettingSelector/SettingSelector';
import { useFlagFixed } from '@hooks';
import { Grid, Stack, Tab } from '@mui/material';
import { Flag } from '@utils';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { PropsWithChildren, useMemo } from 'react';
import { AccessFlowSettingsDrawer, Setting } from '../AccessFlowSettingsDrawer';
import AutoGrantReviewModal from '../AutoGrantReviewModal';
import AutoGrantReviewModalV2 from '../AutoGrantReviewModalV2';
import { FlowFormLine, FlowFormName } from '../FlowForm';
import { MfaSwitcher } from '@AccessFlows/organisms/MfaSwitcher';

export function AccessFlowForm({
  children,
  formData,
  template,
  onChange,
  onSubmit,
  onSubmitV2,
  isSettingsOpen,
  onCloseSettings,
  accessFlowId,
}: PropsWithChildren<AccessFlowFormProps>) {
  const { track } = useAnalyticsContext();
  const { isEnabled: isComplexAccessFlowEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);

  const props = useAccessFlowForm({
    formData,
    onChange,
    onSubmit,
    onSubmitV2,
    accessFlowId,
  });

  const {
    selectedTriggerType,
    setSelectedTriggerType,
    getValidationError,
    handleSubmit,
    handleSubmitV2,
    name,
    setName,
    requiredMfa,
    setRequiredMfa,
    selectedJustification,
    setSelectedJustification,
    enforceAllApprovers,
    setEnforceAllApprovers,
    requireApproverJustification,
    setRequireApproverJustification,
    isApproverJustification,
    isApproverCannotApproveHimself,
    approverCannotApproveHimself,
    setApproverCannotApproveHimself,
    labels,
    setLabels,
    autoGrantFormModel,
    handleAutoGrantModal,
    autoGrantFormModelV2,
    handleAutoGrantModalV2,
    selectedGranteesV2,
  } = props;

  const { isPremium: isPremiumRequireJustificationSetting } = useFlagFixed(Flag.PREMIUM_SETTINGS_REQUIRE_JUSTIFICATION);
  const { isPremium: isPremiumEnforceAllSetting } = useFlagFixed(Flag.PREMIUM_SETTINGS_ENFORCE_ALLOF_APPROVERS);
  const { isEnabled: isAutoGrantEnabled } = useFlagFixed(Flag.AUTO_GRANT);
  const { isEnabled: isAdvancedApproversEnabled } = useFlagFixed(Flag.ADVANCED_APPROVER);
  const { isEnabled: isRequiredMfaEnabled } = useFlagFixed(Flag.REQUEST_MFA);

  const settings: Setting[] = [
    {
      key: 'required_mfa',
      title: 'Require MFA',
      description:
        'If enabled, users will be required to pass multi-factor authentication (MFA) when requesting access.',
      value: requiredMfa,
      onChange: (e) => {
        track(CustomAnalyticsEvents.REQUIRED_MFA_TOGGLED, {
          value: e.target.checked,
        });
        setRequiredMfa(e.target.checked);
      },
      isPremium: false,
    },
    {
      key: 'require_justification',
      title: 'Require justification',
      description: 'When enabled, grantees will be required to enter a justification for their request.',
      value: selectedJustification,
      onChange: (e) => {
        track(CustomAnalyticsEvents.REQUIRE_JUSTIFICATION_TOGGLED, {
          value: e.target.checked,
        });
        setSelectedJustification(e.target.checked);
      },
      isPremium: isPremiumRequireJustificationSetting,
    },
    {
      key: 'require_approver_justification',
      title: 'Require Approver Justification',
      description:
        'When enabled, approvers will be required to enter a justification for their approving or rejecting a request.',
      value: requireApproverJustification,
      onChange: (e) => {
        track(CustomAnalyticsEvents.REQUIRE_APPROVER_TOGGLED, {
          value: e.target.checked,
        });
        setRequireApproverJustification(e.target.checked);
      },
      isPremium: isApproverJustification.isPremium,
    },
    {
      key: 'enforce_all',
      title: 'Enforce "All of" on approvers',
      description:
        'When enabled, Apono will require approval from all approvers and/or at least 1 group member from all groups. If at least 1 approver rejects the request, it will not be approved\n' +
        '\n' +
        'If disabled, Apono will require approval from at least 1 approver from selected identities and at least 1 approver from each selected group',
      value: enforceAllApprovers,
      onChange: (e) => {
        track(CustomAnalyticsEvents.ENFORCE_ALL_APPROVERS_TOGGLED, {
          value: e.target.checked,
        });
        setEnforceAllApprovers(e.target.checked);
      },
      isPremium: isPremiumEnforceAllSetting,
    },
    {
      key: 'approver_cannot_approve_himself',
      title: 'Requester cannot approve their own request',
      description:
        'When enabled, this toggle will prevent users from approving their own access. If the user is a member of an approver group, they will not get a notification to approve the request.',
      value: approverCannotApproveHimself,
      onChange: (e) => {
        track(CustomAnalyticsEvents.REQUESTER_CANNOT_APPROVE_HIMSELF_TOGGLED, {
          value: e.target.checked,
        });
        setApproverCannotApproveHimself(e.target.checked);
      },
      isPremium: isApproverCannotApproveHimself.isPremium,
    },
  ];

  if (!isApproverJustification.isEnabled)
    delete settings[settings.findIndex((setting) => setting?.key === 'require_approver_justification')];

  if (!isApproverCannotApproveHimself.isEnabled)
    delete settings[settings.findIndex((setting) => setting?.key === 'approver_cannot_approve_himself')];

  if (!isRequiredMfaEnabled) delete settings[settings.findIndex((setting) => setting?.key === 'required_mfa')];

  if (isAdvancedApproversEnabled) {
    const enforceAllIndex = settings.findIndex((setting) => setting?.key === 'enforce_all');
    if (enforceAllIndex !== -1) {
      delete settings[enforceAllIndex];
    }
  }

  const triggerForm = useMemo(() => {
    switch (selectedTriggerType) {
      case TriggerType.UserRequest:
        return isComplexAccessFlowEnabled ? (
          <ComplexUserRequestForm {...props} template={template} />
        ) : (
          <UserRequestForm {...props} template={template} />
        );
      case TriggerType.AutoGrant:
        return isComplexAccessFlowEnabled ? (
          <ComplexAutoGrantForm {...props} template={template} />
        ) : (
          <AutoGrantForm {...props} template={template} />
        );
    }
  }, [isComplexAccessFlowEnabled, props, selectedTriggerType, template]);

  const triggerTabs = useMemo(() => {
    if (!isAutoGrantEnabled || accessFlowId) return null;

    return (
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <TabsSwitch value={selectedTriggerType} onChange={(e, v) => setSelectedTriggerType(v)}>
          <Tab value={TriggerType.UserRequest} label="Self Serve" />
          <Tab
            value={TriggerType.AutoGrant}
            label="Automatic"
            data-trigger={CustomAnalyticsEvents.AUTOMATIC_TAB_CLICKED}
          />
        </TabsSwitch>
      </Stack>
    );
  }, [accessFlowId, isAutoGrantEnabled, selectedTriggerType, setSelectedTriggerType]);

  const triggerInfoBanner = useMemo(() => {
    if (!isAutoGrantEnabled) return null;

    if (selectedTriggerType === TriggerType.UserRequest) {
      return (
        <FlowBuilderInfoBanner title="Important to know">
          <ul>
            <li>Grants access based on a user request for a defined time period.</li>
            <li>Best used for JIT, break-glass, and access to production, sensitive or highly regulated resources.</li>
          </ul>
        </FlowBuilderInfoBanner>
      );
    }

    if (selectedTriggerType === TriggerType.AutoGrant) {
      return (
        <FlowBuilderInfoBanner title="Important to know">
          <ul>
            <li>Automatically grants and revokes access based on user context.</li>
            <li>Best used for RBAC&JML and on-call shift baselines</li>
          </ul>
        </FlowBuilderInfoBanner>
      );
    }
  }, [isAutoGrantEnabled, selectedTriggerType]);

  return (
    <Stack spacing={3}>
      {triggerTabs}
      {triggerInfoBanner}
      <form onSubmit={isComplexAccessFlowEnabled ? handleSubmitV2 : handleSubmit}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={10}>
            <FlowFormName
              value={name}
              onChange={(v) => setName(v)}
              placeholder="Access Flow Name"
              error={getValidationError('name')}
            />
          </Grid>
          {isRequiredMfaEnabled && (
            <Grid item>
              <MfaSwitcher requiredMfa={requiredMfa} setRequiredMfa={setRequiredMfa} />
            </Grid>
          )}
        </Grid>
        {triggerForm}
        <FlowFormLine></FlowFormLine>
        {children}
        {autoGrantFormModel && (
          <AutoGrantReviewModal
            formData={autoGrantFormModel}
            onClose={() => handleAutoGrantModal()}
            onApprove={() => handleAutoGrantModal(true)}
          />
        )}
        {autoGrantFormModelV2 && selectedGranteesV2 && (
          <AutoGrantReviewModalV2
            formData={autoGrantFormModelV2}
            granteesV2={selectedGranteesV2}
            onClose={() => handleAutoGrantModalV2()}
            onApprove={() => handleAutoGrantModalV2(true)}
          />
        )}
        <AccessFlowSettingsDrawer open={isSettingsOpen} onClose={onCloseSettings}>
          <FlowBuilderLabelsSelector value={labels} onChange={setLabels} />
          {settings.map((setting) => (
            <SettingSelector {...setting} key={setting.key} />
          ))}
        </AccessFlowSettingsDrawer>
      </form>
      <BundleViewModal accessFlowId={accessFlowId} />
    </Stack>
  );
}
