import { IntegrationErrorTypeAppModel, IntegrationStatus, useGetIntegrationErrors } from '@api';
import { Loader, Markdown, MaterialIcon } from '@components';
import { Avatar, Link, Stack, styled, Typography } from '@mui/material';
import { aponoColors } from '@utils';
import { TYPE_TO_TROUBLESHOOTING } from '@organisms/AlertListDrawer/AlertsOptions';
import { routes } from '@routes';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { ReactElement } from 'react';

const ALERTS: IntegrationStatus[] = ['Warning', 'Error'];
function useErrorListByIntegration(integrationId: string) {
  const { integrationErrors, isIntegrationErrorsFetched } = useGetIntegrationErrors(integrationId);

  const resourceTypesAlerts = integrationErrors?.data.filter((integrationResource) =>
    ALERTS.includes(integrationResource.status),
  );

  return {
    resourceTypesAlerts,
    isFetched: isIntegrationErrorsFetched,
  };
}

export function AlertListContent({ integrationId }: { integrationId: string }) {
  const { resourceTypesAlerts, isFetched } = useErrorListByIntegration(integrationId);

  if (!isFetched) return <Loader />;

  return (
    <Stack spacing={1}>
      {resourceTypesAlerts?.map((alert) => {
        const bottomLink = getLinkByAlertType(alert.type, integrationId);

        return (
          <AlertBox
            key={`${alert.name}_${alert.type}`}
            alertLevel={alert.status}
            name={alert.name}
            iconSrc={alert.icon.svg}
            bodyText={alert.message}
            errorType={alert.type}
            link={bottomLink}
          />
        );
      })}
    </Stack>
  );
}

interface AlertBoxProps {
  alertLevel: string;
  iconSrc: string;
  name: string;
  bodyText: string;
  errorType: string;
  link?: ReactElement;
}

const AlertContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.border.button}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,

  '& .error': {
    color: aponoColors.error['500'],
  },
  '& .warning': {
    color: aponoColors.warning['500'],
  },
}));

function AlertBox({ alertLevel, iconSrc, name, bodyText, errorType, link }: AlertBoxProps) {
  return (
    <AlertContainer spacing={1}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <MaterialIcon symbol={'warning'} className={alertLevel.toLowerCase()} />
        <Avatar src={iconSrc} sx={{ width: 16, height: 16 }} variant="square" />
        <Typography variant="tooltip">{name}</Typography>
      </Stack>
      <Typography variant="tooltip" component="div" gutterBottom>
        <Markdown>{bodyText}</Markdown>
      </Typography>
      {link}
      {TYPE_TO_TROUBLESHOOTING[errorType] && (
        <Typography variant="tooltip">
          <Markdown>{TYPE_TO_TROUBLESHOOTING[errorType]}</Markdown>
        </Typography>
      )}
    </AlertContainer>
  );
}

function getLinkByAlertType(alertType: string, integrationId: string) {
  return alertType === IntegrationErrorTypeAppModel.FailedProvisioning ? (
    <Link
      to={`${generatePath(routes.Activity.path)}?filter.status=FAILED&filter.integration_id=${integrationId}`}
      key="go-to-activity-page"
      component={RouterLink}
      variant="tooltip"
    >
      See all failed requests
    </Link>
  ) : undefined;
}
