import { ErrorPage } from '@pages';
import { Loader } from '@components';
import { useRequiredParams } from '@hooks';
import { ConnectResourceIntegrationPathsProps } from '@Integrations/organisms/ConnectIntegration/ConnectResourceIntegration';
import { IntegrationConfigWithSubIntegrations, useGetCatalogV2 } from '@api';
import { useIntegrationForm } from '@Integrations/hooks/use-integration-form';
import { IntegrationFormStepper } from '@Integrations/IntegrationConfigForm/organisms/IntegrationFormStepper';

export function ConnectIntegrationGroupForm({ paths }: { paths: ConnectResourceIntegrationPathsProps }) {
  const { group } = useRequiredParams(['group']);
  const { integrationsConfigs, isIntegrationsConfigsFetched } = useGetCatalogV2();
  const groupConfigs = integrationsConfigs?.filter((c) => c.integrations_group === group);

  const withSubs: IntegrationConfigWithSubIntegrations[] = groupConfigs.map((gc) => {
    const subIntegrations = integrationsConfigs.filter((ic) => gc.sub_integration_types.includes(ic.type));

    return {
      ...gc,
      subIntegrationConfigs: subIntegrations,
    };
  });

  if (!isIntegrationsConfigsFetched) return <Loader />;

  if (!groupConfigs?.length) return <ErrorPage errorCode={404} errorMessage="Integration config not found" />;

  return <ConnectIntegrationGroupFormContent configs={withSubs} paths={paths} />;
}

function ConnectIntegrationGroupFormContent({
  configs,
  paths,
}: {
  configs: IntegrationConfigWithSubIntegrations[];
  paths: ConnectResourceIntegrationPathsProps;
}) {
  const props = useIntegrationForm({ paths, configs });
  return <IntegrationFormStepper options={configs} {...props} />;
}
