// eslint-disable-next-line no-restricted-imports
import dayjs, { Dayjs, extend, ManipulateType, unix } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import capitalize from 'lodash/capitalize';

extend(relativeTime);

export class DateTime {
  private readonly dateTime: Dayjs;

  private constructor(dateTime: Dayjs) {
    this.dateTime = dateTime;
  }

  get readable() {
    return this.dateTime.format('MMM D, YYYY [at] HH:mm');
  }

  get readableWithSeconds() {
    return this.dateTime.format('MMM D, YYYY [at] HH:mm:ss');
  }

  get readableRelative() {
    return this.dateTime.fromNow();
  }

  get isoString() {
    return this.dateTime.format('YYYY-MM-DDTHH:mm');
  }

  get fullIsoString() {
    return this.dateTime.format();
  }

  get readableRelativeWithoutSuffix() {
    return capitalize(this.dateTime.fromNow(true));
  }

  get unix() {
    return this.dateTime.unix();
  }

  format(dateFormat = 'DD/MM/YYYY HH:mm'): string {
    return this.dateTime.format(dateFormat);
  }

  static timeZone() {
    const offset = new Date().getTimezoneOffset();
    const tz = (offset / 60) * -1;

    if (tz === 0) return '±0';

    return tz >= 0 ? `+${tz}` : `-${tz}`;
  }

  static fromUnix(dateTimeInSec: number | string): DateTime {
    return new DateTime(unix(Number(dateTimeInSec)));
  }

  static fromUnixWithFormat(dateTimeInSec: number, dateFormat = 'DD/MM/YYYY HH:mm'): string {
    return new DateTime(unix(dateTimeInSec)).dateTime.format(dateFormat);
  }

  static fromMilliseconds(dateTimeInSec: number): DateTime {
    return new DateTime(dayjs(dateTimeInSec));
  }

  static fromDate(date: Date): DateTime {
    return new DateTime(dayjs(date));
  }

  static fromString(value: string): DateTime {
    return new DateTime(dayjs(value));
  }

  static now(): DateTime {
    return new DateTime(dayjs());
  }

  static startOfWeek(): number {
    return dayjs().startOf('week').day();
  }

  static utcOffset(): number {
    return dayjs().utcOffset();
  }

  static shortDate(dateTimeInSec: number): string {
    return new DateTime(unix(dateTimeInSec)).dateTime.format('D MMM');
  }

  addSeconds(seconds: number) {
    return new DateTime(this.dateTime.add(seconds, 'second'));
  }

  isAfter(dateTimeToCompare: DateTime): boolean {
    return this.dateTime.isAfter(dateTimeToCompare.dateTime);
  }

  add(period: number, periodUnit: ManipulateType, roundToUnit?: boolean): DateTime {
    let added = this.dateTime.add(period, periodUnit);
    if (roundToUnit) {
      added = added.endOf(periodUnit);
    }

    return new DateTime(added);
  }

  subtract(period: number, periodUnit: ManipulateType, roundToUnit?: boolean): DateTime {
    let subtracted = this.dateTime.subtract(period, periodUnit);
    if (roundToUnit) {
      subtracted = subtracted.startOf(periodUnit);
    }

    return new DateTime(subtracted);
  }

  public toString = () => this.readable;
}
