import { PropsWithChildren } from 'react';

import { PremiumPageProps, PremiumPage, Loader } from '@components';
import { useRequiredParams } from '@hooks';
import { routes } from '@routes';
import { Flag } from '@utils';
import { ErrorPage } from '@pages';

import { useAppGetWebhook } from '../services/webhooksQueries';
import { Outlet, generatePath } from 'react-router-dom';

export default function WebhookLayout() {
  const { id } = useRequiredParams(['id']);

  const { webhook, isFetched } = useAppGetWebhook(id);

  if (!isFetched) {
    return (
      <PageWrap>
        <Loader />
      </PageWrap>
    );
  }

  if (!webhook) {
    return (
      <PageWrap>
        <ErrorPage errorCode={404} errorMessage="Webhook not found" />
      </PageWrap>
    );
  }

  return (
    <PageWrap
      title={webhook.name}
      tabs={[
        {
          label: 'Edit Webhook',
          to: generatePath(routes.EditWebhook.path, { id }),
        },
        {
          label: 'Invocation History',
          to: generatePath(routes.WebhookHistory.path, { id }),
        },
      ]}
    >
      <Outlet />
    </PageWrap>
  );
}

function PageWrap({ title, tabs, children }: PropsWithChildren<Pick<PremiumPageProps, 'title' | 'tabs'>>) {
  return (
    <PremiumPage
      flag={Flag.OUTBOUND_WEBHOOKS}
      breadcrumbs={[
        {
          label: routes.Webhooks.label,
          href: routes.Webhooks.path,
        },
      ]}
      title={title || 'loading...'}
      tabs={tabs}
    >
      {children}
    </PremiumPage>
  );
}
