import { Link, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { IntegrationConfigInfo, IntegrationConfigLink, useGetAgent } from '@api';
import { Markdown } from '@components';
import { ConnectorNameAndId } from '@pages/ConnectorsPage/ConnectorNameAndId';

export interface ConfigInfoProps {
  info: IntegrationConfigInfo | string[];
  links: Array<IntegrationConfigLink>;
  connectorId?: string;
}

export function ConfigInfo({ info, links = [], connectorId }: ConfigInfoProps) {
  return (
    <Stack spacing={4}>
      {connectorId && (
        <Stack direction="column" spacing={2}>
          <Typography variant="subtitle2">Connector</Typography>
          <ConnectorView connectorId={connectorId} />
        </Stack>
      )}
      <ConfigInfoLinks links={links} />
      <ConfigInfoItems info={info} />
    </Stack>
  );
}

export function ConnectorView({ connectorId }: { connectorId: string }) {
  const { connector } = useGetAgent(connectorId);

  if (!connector || !connector.name) return <></>;

  return <ConnectorNameAndId id={connector.agent_id} name={connector.name} provider={connector.cloud_provider?.type} />;
}

function isIntegrationConfigInfo(info: IntegrationConfigInfo | string[]): info is IntegrationConfigInfo {
  return (info as IntegrationConfigInfo).title !== undefined && (info as IntegrationConfigInfo).items !== undefined;
}

function ConfigInfoLinks({ links = [] }: { links: IntegrationConfigLink[] }) {
  if (!Array.isArray(links) || links.length === 0) return <></>;

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" component="p">
        Related Links
      </Typography>
      {links.map((link, index) => {
        return (
          <div key={`link-${index}`}>
            <Link variant="body2" href={link.url} target="_blank">
              {link.label}
            </Link>
          </div>
        );
      })}
    </Stack>
  );
}

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
  paddingRight: theme.spacing(1.5),
}));

function ConfigInfoItems({ info }: { info: IntegrationConfigInfo | string[] }) {
  let infoTitle = '';
  let infoItems: string[] = [];

  if (Array.isArray(info)) {
    const infoCopy = [...info];
    infoTitle = infoCopy.shift() || 'Best used for';
    infoItems = infoCopy;
  } else if (isIntegrationConfigInfo(info)) {
    infoTitle = info.title;
    infoItems = info.items;
  }

  if (infoItems.length === 0) return <></>;

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2" component="p">
        {infoTitle}
      </Typography>
      <List disablePadding>
        {infoItems.map((item, index) => {
          return (
            <ListItem key={`item-${index}`} disablePadding disableGutters>
              <ListItemIconStyled>
                <CheckIcon color="success" />
              </ListItemIconStyled>
              <ListItemText primary={<Markdown>{item}</Markdown>} />
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
}
