import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface AlertTypesItemGridProps {
  alertType: ReactNode;
  severity: ReactNode;
  count: ReactNode;
  action: ReactNode;
}

export function AlertTypesItemGrid({ alertType, severity, count, action }: AlertTypesItemGridProps) {
  return (
    <Grid container alignItems="center" columnSpacing={2}>
      <Grid item xs={6}>
        {alertType}
      </Grid>
      <Grid item xs={2}>
        {severity}
      </Grid>
      <Grid item xs={2} container>
        {count}
      </Grid>
      <Grid item xs={2} container>
        {action}
      </Grid>
    </Grid>
  );
}
