import { ResourceTypeFieldsFragment } from '@api/gql/graphql';
import LeftOversChip from '@common/ui/LeftOversChip';
import PrimarySecondary from '@common/ui/PrimarySecondary';

export default function ResourceTypesCell({ resourceTypes }: { resourceTypes: readonly ResourceTypeFieldsFragment[] }) {
  if (resourceTypes.length < 1) return <></>;

  const leftOvers = resourceTypes.length > 1 ? resourceTypes.slice(1) : [];

  const primaryMore =
    leftOvers.length > 0 ? (
      <LeftOversChip label={`+${leftOvers.length}`} tooltip={leftOvers.map((r) => r.typeDisplayName).join(', ')} />
    ) : undefined;

  return (
    <PrimarySecondary
      icon={resourceTypes[0].icons.svg}
      iconSpacing={1.5}
      primary={resourceTypes[0].typeDisplayName}
      primaryMore={primaryMore}
    />
  );
}
