import { ElementType } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

import { Box, Stack, styled, Typography } from '@mui/material';
import { RESOURCE_NODE_HEIGHT, RESOURCE_NODE_WIDTH } from './common/common';
import { AccessNodeModel } from '@api';

const MoreNodeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.active,
  width: RESOURCE_NODE_WIDTH,
  height: RESOURCE_NODE_HEIGHT,
  position: 'relative',
  cursor: 'pointer',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: `1px solid transparent`,
  },

  '&:hover': {
    '&:before': {
      borderColor: theme.palette.warning.light,
    },
  },
}));

const MoreNodeContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: RESOURCE_NODE_HEIGHT,
}));

const MoreNodeTypography = styled(Typography)<{
  component: ElementType;
}>({
  lineHeight: 1,
});

export interface MoreNodeProps {
  node: AccessNodeModel;
}

export function MoreNode({ data }: NodeProps<MoreNodeProps>) {
  return (
    <MoreNodeWrapper>
      <Handle type="target" position={Position.Left} />
      <MoreNodeMeta {...data} />
      <Handle type="source" position={Position.Right} />
    </MoreNodeWrapper>
  );
}

export function MoreNodeInput({ data }: NodeProps<MoreNodeProps>) {
  return (
    <MoreNodeWrapper>
      <Handle type="source" position={Position.Right} />
      <MoreNodeMeta {...data} />
    </MoreNodeWrapper>
  );
}

export function MoreNodeOutput({ data }: NodeProps<MoreNodeProps>) {
  return (
    <MoreNodeWrapper>
      <MoreNodeMeta {...data} />
      <Handle type="target" position={Position.Left} />
    </MoreNodeWrapper>
  );
}

function MoreNodeMeta({ node }: MoreNodeProps) {
  return (
    <MoreNodeContainer direction="row" alignItems="center">
      <MoreNodeTypography variant="caption" component="div" color="text.dropdown" noWrap>
        {node.more} more {node.label}
      </MoreNodeTypography>
    </MoreNodeContainer>
  );
}
