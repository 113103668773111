import { Box, styled } from '@mui/material';

export const PIE_CHART_DIMENSIONS = 124;
export const MARGIN = 80;

export interface PieChartData {
  id: string;
  label: string;
  value: number;
  color: string;
}

export const ResponsivePieWrapper = styled(Box)({
  height: PIE_CHART_DIMENSIONS + MARGIN,
  width: PIE_CHART_DIMENSIONS + 2 * MARGIN,
  overflow: 'hidden',
  alignSelf: 'center',
});
