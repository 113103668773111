import { useState, useMemo, useCallback } from 'react';
import { Stack, Chip } from '@mui/material';

import { UserIdentityAttributeAppModel } from '@api';
import LeftOversChip from '@common/ui/LeftOversChip';

interface UsersListItemAttributesGroupsProps {
  attributes: UserIdentityAttributeAppModel[];
  renderAttribute?: (attribute: UserIdentityAttributeAppModel) => JSX.Element;
}

const MAX_ATTRIBUTES = 5;

export default function UsersListItemAttributesList({
  attributes,
  renderAttribute,
}: UsersListItemAttributesGroupsProps) {
  const [showMore, setShowMore] = useState(false);

  const sliced = useMemo(() => {
    if (showMore) return attributes;
    return attributes.slice(0, MAX_ATTRIBUTES);
  }, [attributes, showMore]);

  const leftOvers = attributes.length - sliced.length;

  const renderAttributeFunction = useCallback(
    (attribute: UserIdentityAttributeAppModel) => {
      if (renderAttribute) return renderAttribute(attribute);
      return <Chip label={attribute.value} variant="outlined" size="small" />;
    },
    [renderAttribute],
  );

  return (
    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
      {sliced.map((a) => (
        <div key={a.id}>{renderAttributeFunction(a)}</div>
      ))}
      {leftOvers > 0 && (
        <LeftOversChip tooltip="Show more" onClick={() => setShowMore((prev) => !prev)} label={`+${leftOvers}`} />
      )}
    </Stack>
  );
}
