import { Alert, Button, Typography } from '@mui/material';
import { generatePath, RouterLink } from '@libs';
import { routes } from '@routes';
import { MaterialIcon } from '@components';
import { useAppGetIntegration } from '@api';

export function InfoAlertForSubIntegration({ parentIntegrationId }: { parentIntegrationId: string }) {
  const { integration: parentIntegration } = useAppGetIntegration(parentIntegrationId);
  return (
    <Alert
      severity="info"
      variant="outlined"
      sx={{ alignItems: 'center' }}
      action={
        <Button
          component={RouterLink}
          to={generatePath(routes.EditIntegration.path, { id: parentIntegrationId })}
          variant="contained"
          color="primary"
          startIcon={<MaterialIcon symbol={'edit_square'} />}
        >
          Edit parent integration
        </Button>
      }
    >
      <Typography>
        This integration is not editable. It is managed by its parent integration:{' '}
        <strong>{parentIntegration?.name}</strong>
      </Typography>
    </Alert>
  );
}
