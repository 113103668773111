import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { useAccessFlowDraft, useAccessFlowsPack, useFlagFixed } from '@hooks';
import { AccessFlowTemplate, useTemplateFromQueryParam } from '@af-templates';
import { AccessTargetType, TriggerType, useInvalidateSession } from '@api';
import { useEffect, useState } from 'react';
import { Loader } from '@components';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { RouterLink } from '@libs';
import { AccessFlowFormData } from '@AccessFlows/common/types';
import { FlowFormSummary } from '@AccessFlows/organisms/FlowForm';
import { useAppCreateAccessFlow, useAppCreateAccessFlowV2 } from '@AccessFlows/services/accessFlowsMutations';
import { AccessFlowForm } from '@AccessFlows/organisms/AccessFlowForm/AccessFlowForm';
import { Flag } from '@utils';

const FROM_INTEGRATION_QUERY_PARAM = 'fromIntegration';
const WITH_RESOURCE_TYPE_QUERY_PARAM = 'withResourceType';
const FROM_BUNDLE_QUERY_PARAM = 'fromBundle';

function useInitFormData(template?: AccessFlowTemplate): AccessFlowFormData {
  const [searchParams] = useSearchParams();
  const fromIntegration = searchParams.get(FROM_INTEGRATION_QUERY_PARAM);
  const withResourceType = searchParams.get(WITH_RESOURCE_TYPE_QUERY_PARAM);
  const fromBundle = searchParams.get(FROM_BUNDLE_QUERY_PARAM);

  const blankFormData: AccessFlowFormData = {
    name: '',
    active: true,
    trigger_type: TriggerType.UserRequest,
    revoke_after_in_sec: 60 * 60, // 1 hour
    justification_required: true,
    require_all_approvers: false,
    settings: {
      approver_cannot_approve_himself: false,
      require_approver_justification: false,
      required_mfa: false,
    },
    timeframe: undefined,
    labels: [],
  };

  if (template) {
    blankFormData.name = template.name;
    blankFormData.trigger_type = template.trigger_type;
    blankFormData.revoke_after_in_sec = template.revoke_after_in_sec;
  } else {
    blankFormData.approvers = [];
    blankFormData.targets = [];
    blankFormData.grantees = [];
    blankFormData.granteesV2 = {
      logical_operator: 'AND',
      attribute_filters: [],
    };
  }

  if (fromIntegration) {
    blankFormData.trigger_type = TriggerType.UserRequest;
    if (withResourceType) {
      blankFormData.targets = [
        {
          target_type: AccessTargetType.Integration,
          integration: {
            integration_id: fromIntegration,
            resource_type: withResourceType,
            resource_tag_matchers: [],
            resource_tag_excludes: [],
          },
        },
      ];
    }
  }

  if (fromBundle) {
    blankFormData.trigger_type = TriggerType.UserRequest;
    blankFormData.targets = [
      {
        target_type: AccessTargetType.Bundle,
        bundle: {
          bundle_id: fromBundle,
        },
      },
    ];
  }

  return blankFormData;
}

function useAddAccessFlowPage({ withDraft, onSaveAccessFlow }: { withDraft: boolean; onSaveAccessFlow?: () => void }) {
  const { isEnabled: isAdvancedGranteesOn } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);
  const { isEnabled: isAdvancedApproverOn } = useFlagFixed(Flag.ADVANCED_APPROVER);
  const { track } = useAnalyticsContext();
  const { accessFlowDraft, handleAccessFlowDraftChange, clearAccessFlowDraft } = useAccessFlowDraft();
  const template = useTemplateFromQueryParam();
  const invalidateSession = useInvalidateSession();
  const [rerenderTrigger, updateTrigger] = useState(0);

  const iniFormData = useInitFormData(template);

  const { isLoading: isPackLoading } = useAccessFlowsPack();
  const { createAccessFlow, isLoading: isCreateAccessFlowLoading, createdAccessFlow } = useAppCreateAccessFlow();
  const {
    createAccessFlow: createAccessFlowV2,
    isLoading: isCreateAccessFlowLoadingV2,
    createdAccessFlow: createdAccessFlowV2,
  } = useAppCreateAccessFlowV2();

  const [searchParams] = useSearchParams();
  const fromIntegration = searchParams.get(FROM_INTEGRATION_QUERY_PARAM);
  const fromBundle = searchParams.get(FROM_BUNDLE_QUERY_PARAM);

  const [isPackLoaded, setIsPackLoaded] = useState<boolean>(false);
  const [formData, setFormData] = useState<AccessFlowFormData>(
    withDraft && !fromIntegration && !fromBundle ? accessFlowDraft || iniFormData : iniFormData,
  );

  useEffect(() => {
    if (!isPackLoading && !isPackLoaded) {
      setIsPackLoaded(true);
    }
  }, [isPackLoading, isPackLoaded]);

  useEffect(() => {
    const created = createdAccessFlow || createdAccessFlowV2;
    if (!created) return;

    clearAccessFlowDraft();
    invalidateSession();
    onSaveAccessFlow && onSaveAccessFlow();

    track(CustomAnalyticsEvents.ACCESS_FLOW_CREATED, {
      name: created.name,
      advancedGrantees: isAdvancedGranteesOn,
      advancedApprovers: isAdvancedApproverOn,
    });

    if (template) {
      track(CustomAnalyticsEvents.TEMPLATE_CREATED, {
        templateId: template.id,
      });
    }
  }, [
    createdAccessFlow,
    createdAccessFlowV2,
    track,
    invalidateSession,
    clearAccessFlowDraft,
    template,
    onSaveAccessFlow,
    isAdvancedGranteesOn,
    isAdvancedApproverOn,
  ]);

  const handleChange = (value: AccessFlowFormData) => {
    setFormData(value);
    handleAccessFlowDraftChange(value);
  };

  const handleClearDraft = () => {
    clearAccessFlowDraft();
    setFormData(iniFormData);
    updateTrigger(rerenderTrigger + 1);
  };

  return {
    isPackLoaded,
    template,
    formData,
    createdAccessFlow,
    handleChange,
    handleSubmit: createAccessFlow,
    isSubmitting: isCreateAccessFlowLoading || isCreateAccessFlowLoadingV2,
    createdAccessFlowV2,
    handleSubmitV2: createAccessFlowV2,
    rerenderTrigger,
    accessFlowDraft,
    handleClearDraft,
  };
}

type AddAccessFlowPageContentProps = {
  isSettingsOpen?: boolean;
  onCloseSettings?: () => void;
  paths: {
    backPath?: string;
    donePath: string;
    doneButtonLabel?: string;
  };
  withDraft?: boolean;
  onSaveAccessFlow?: () => void;
};

export function AddAccessFlowPageContent({
  isSettingsOpen,
  onCloseSettings,
  paths: { backPath, donePath, doneButtonLabel },
  withDraft = true,
  onSaveAccessFlow,
}: AddAccessFlowPageContentProps) {
  const {
    isPackLoaded,
    template,
    formData,
    createdAccessFlow,
    createdAccessFlowV2,
    handleChange,
    handleSubmit,
    isSubmitting,
    rerenderTrigger,
    accessFlowDraft,
    handleClearDraft,
    handleSubmitV2,
  } = useAddAccessFlowPage({ withDraft, onSaveAccessFlow });

  if (!isPackLoaded) return <Loader />;

  if (createdAccessFlow) {
    return <FlowFormSummary accessFlow={createdAccessFlow} donePath={donePath} doneButtonLabel={doneButtonLabel} />;
  }

  if (createdAccessFlowV2) {
    return <FlowFormSummary accessFlow={createdAccessFlowV2} donePath={donePath} doneButtonLabel={doneButtonLabel} />;
  }

  const isAutoGrant = formData.trigger_type === TriggerType.AutoGrant;

  return (
    <AccessFlowForm
      key={rerenderTrigger}
      formData={formData}
      template={template}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onSubmitV2={handleSubmitV2}
      isSettingsOpen={isSettingsOpen}
      onCloseSettings={onCloseSettings}
    >
      <Stack direction="row" spacing={2}>
        {backPath && (
          <Button component={RouterLink} to={backPath} variant="outlined">
            Back
          </Button>
        )}
        <LoadingButton
          data-testid="create-access-flow-btn"
          variant="contained"
          loading={isSubmitting}
          type="submit"
          data-trigger={isAutoGrant ? CustomAnalyticsEvents.REVIEW_AND_CREATE_CLICKED : undefined}
          data-props={
            isAutoGrant
              ? JSON.stringify({
                  conditionType: formData.grantees?.map((g) => g.type),
                  targets: formData.targets?.map((t) => t.target_type),
                })
              : undefined
          }
        >
          {isAutoGrant ? 'Review and Create' : 'Create Access Flow'}
        </LoadingButton>
        {!!accessFlowDraft && <Button onClick={handleClearDraft}>Clear Form</Button>}
      </Stack>
    </AccessFlowForm>
  );
}
