import { useState, ReactNode, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';
import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { ATTRIBUTE_FILTER_PARAM } from '@Identities/common/constants';
import { PAGE_PARAM } from '@api';

export type AttributeFilterOption = ListFilterOption;

interface AttributeFilterTriggerProps {
  label: string;
  icon?: ReactNode;
}
interface AttributeFilterProps {
  trigger: AttributeFilterTriggerProps;
  options: AttributeFilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export default function AttributeFilter({ trigger, options, opened = false, onClose, onOpen }: AttributeFilterProps) {
  const [isOpen, setIsOpen] = useState(opened);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.getAll(ATTRIBUTE_FILTER_PARAM);

  const selectedOptions = [];
  for (const option of options) {
    if (selectedQp.includes(option.value)) {
      selectedOptions.push(option);
    }
  }

  const applySearchParams = useCallback(
    (sp: URLSearchParams) => {
      if (sp.has(PAGE_PARAM)) sp.delete(PAGE_PARAM);
      setSearchParams(sp);
    },
    [setSearchParams],
  );

  const getClearedSearchParams = () => {
    const optionsValues = new Set(options.map((o) => o.value));
    const newSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (!optionsValues.has(value)) {
        newSearchParams.append(key, value);
      }
    });
    return newSearchParams;
  };

  const handleOnSelect = (values: ListFilterOption[]) => {
    const newSearchParams = getClearedSearchParams();
    for (const value of values) {
      newSearchParams.append(ATTRIBUTE_FILTER_PARAM, value.value);
    }
    applySearchParams(newSearchParams);
  };

  const handleOnClear = () => {
    applySearchParams(getClearedSearchParams());
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={trigger.label}
          icon={trigger.icon}
          value={selectedOptions}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
      width={300}
    >
      <ListFilterMultiSelect
        options={options}
        value={selectedOptions}
        onSelect={handleOnSelect}
        enableSearch
        title={`Select ${trigger.label} Filter`}
      />
    </ListFilter>
  );
}
