import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ConfirmDialog } from '@components';
import { useGlobalNotifier } from '@hooks';
import { useSearchParams } from 'react-router-dom';
import { useRevokeAccessRequests } from './mutation';
import { RevokeAccessRequestsAppModel } from '@api';
import { QueryParams } from '../../common/constants';
import { getArrayQP, getStartEndDateFromQP } from '@utils';
import { CustomAnalyticsEvents } from '@utils/analytics';

function queryParamsToAppModel(queryParams: URLSearchParams): RevokeAccessRequestsAppModel {
  const { startDate, endDate } = getStartEndDateFromQP(queryParams);
  return {
    grantee_user_ids: getArrayQP(queryParams, QueryParams.filterRequestorId),
    integration_ids: getArrayQP(queryParams, QueryParams.filterIntegrationId),
    resource_names: getArrayQP(queryParams, QueryParams.filterResource),
    permissions: getArrayQP(queryParams, QueryParams.filterPermission),
    statuses: getArrayQP(queryParams, QueryParams.filterStatus),
    resource_types: getArrayQP(queryParams, QueryParams.filterResourceType),
    trigger_types: getArrayQP(queryParams, QueryParams.filterTriggerType),
    access_flow_ids: getArrayQP(queryParams, QueryParams.filterAccessFlowId),
    start_date: startDate ? startDate.unix : undefined,
    end_date: endDate ? endDate.unix : undefined,
  };
}

export default function RevokeAll() {
  const [queryParams] = useSearchParams();
  const { notifySuccess } = useGlobalNotifier();
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { revokeAccessRequests, revokedAccess, isLoading, isSuccess } = useRevokeAccessRequests();
  const revokeAccessRequestsAppModel = queryParamsToAppModel(queryParams);

  useEffect(() => {
    if (isSuccess && showNotification && revokedAccess?.affected_count) {
      notifySuccess(`Revoking ${revokedAccess.affected_count} active access`);
      setShowNotification(false);
    }
  }, [isSuccess, showNotification, revokedAccess, notifySuccess]);

  const handleOpenDialog = () => {
    setOpen(true);
    // Perform a dry run first to get the affected count
    revokeAccessRequests({ revokeAccessRequestsAppModel, dryRun: true });
  };

  const onConfirm = () => {
    setShowNotification(true);
    revokeAccessRequests({ revokeAccessRequestsAppModel });
  };

  const onClose = () => {
    if (isLoading) return;
    setOpen(false);
  };

  const getFieldsFiltered = () => {
    return Object.entries(revokeAccessRequestsAppModel).map(([key, value]) => {
      if (value !== undefined) return key;
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpenDialog}
        disabled={isLoading}
        data-trigger={CustomAnalyticsEvents.REVOKE_ALL_CLICKED_IN_ACTIVITY_PAGE}
        data-props={JSON.stringify({
          filterTypesUsed: getFieldsFiltered(),
        })}
      >
        Revoke All
      </Button>
      {revokedAccess?.affected_count ? (
        <ConfirmDialog
          open={open}
          loading={isLoading}
          onConfirm={onConfirm}
          onClose={onClose}
          data-trigger={CustomAnalyticsEvents.CONFIRM_DIALOG_YES_CLICKED}
          data-props={JSON.stringify({ effected_count: revokedAccess?.affected_count })}
        >
          You are about to revoke {revokedAccess?.affected_count} active access. Users will be logged out of active
          sessions. This cannot be undone. Are you sure?
        </ConfirmDialog>
      ) : (
        <ConfirmDialog open={open} loading={isLoading} onClose={onClose} disproveLabel="Close">
          There is no access to revoke
        </ConfirmDialog>
      )}
    </>
  );
}
