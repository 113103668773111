import { TriggerType } from '@api';
import { TriggerValues } from '@utils';

import { FlowFormErrorProps, FlowFormText, FlowInlineText, FlowInlineTextProps } from '../common/Text';

type Props = FlowInlineTextProps & FlowFormErrorProps;

export interface HumanReadableTriggerProps extends Props {
  value?: TriggerType;
}

export function HumanReadableTrigger(props: HumanReadableTriggerProps) {
  const { value, inline, ...rest } = props;

  const text = value ? TriggerValues[value] : undefined;

  if (inline) {
    return <FlowInlineText inline={inline}>{text || <em>Undefined Trigger</em>}</FlowInlineText>;
  }

  return (
    <FlowFormText selectable muted={!value} {...rest}>
      {text || 'something happens'}
    </FlowFormText>
  );
}
