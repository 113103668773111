import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Stack, Tooltip, Switch, Typography } from '@mui/material';

import { routes } from '@routes';
import { useFlagFixed, useGlobalNotifier } from '@hooks';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

import {
  useAppChangeAccessFlowActiveFlag,
  useAppChangeAccessFlowActiveFlagV2,
  useAppCreateAccessFlow,
  useAppCreateAccessFlowV2,
  useAppDeleteAccessFlow,
  useAppDeleteAccessFlowV2,
} from '@AccessFlows/services/accessFlowsMutations';
import { ActionsMenuV2, ConfirmDialog } from '@components';
import { markEventHandledByButton } from '@utils/mouse-events';
import { DateTime, Flag } from '@utils';
import { CommonAccessFlowAppModel } from '@AccessFlows/common/types';
import { AccessFlowUpsertAppModelV2 } from '@api';
import { toGranteeFilterGroupUpsertAppModel } from '@AccessFlows/common/helpers';

function useCommonChangeAccessFlowActiveFlag(id: string) {
  const { isEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);

  const { appChangeAccessFlowActiveFlag, isSuccess, isLoading, changedAccessFlow } =
    useAppChangeAccessFlowActiveFlag(id);
  const {
    appChangeAccessFlowActiveFlag: appChangeAccessFlowActiveFlagV2,
    isSuccess: isSuccessV2,
    isLoading: isLoadingV2,
    changedAccessFlow: changedAccessFlowV2,
  } = useAppChangeAccessFlowActiveFlagV2(id);

  const toggleActive = (active: { active: boolean }) =>
    isEnabled ? appChangeAccessFlowActiveFlagV2(active) : appChangeAccessFlowActiveFlag(active);

  return {
    toggleActive,
    isSuccess: isEnabled ? isSuccessV2 : isSuccess,
    isToggleLoading: isEnabled ? isLoadingV2 : isLoading,
    changedAccessFlow: isEnabled ? changedAccessFlowV2 : changedAccessFlow,
  };
}

function useItemToggleActive({ id, active }: CommonAccessFlowAppModel) {
  const { track } = useAnalyticsContext();
  const { notifySuccess } = useGlobalNotifier();
  const { toggleActive, isSuccess, isToggleLoading, changedAccessFlow } = useCommonChangeAccessFlowActiveFlag(id);

  const [activeState, setActiveState] = useState(active);

  useEffect(() => {
    if (!isSuccess || !changedAccessFlow) return;

    setActiveState(changedAccessFlow.active);

    if (changedAccessFlow.active) {
      track(CustomAnalyticsEvents.ACCESS_FLOW_ENABLED);
    } else {
      track(CustomAnalyticsEvents.ACCESS_FLOW_DISABLED);
    }

    notifySuccess(`Access flow ${changedAccessFlow.name} ${changedAccessFlow.active ? 'activated' : 'deactivated'}`);
  }, [changedAccessFlow, notifySuccess, isSuccess, track]);

  return {
    activeState,
    toggleActive,
    isToggleLoading,
  };
}

function useCommonDuplicate(accessFlow: CommonAccessFlowAppModel) {
  const { isEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);

  const { createAccessFlow, createdAccessFlow, isSuccess, isLoading } = useAppCreateAccessFlow();
  const duplicateV1 = () => createAccessFlow({ ...accessFlow, name: accessFlow.name + ' (Copy)' });

  const {
    createAccessFlow: createAccessFlowV2,
    createdAccessFlow: createdAccessFlowV2,
    isSuccess: isSuccessV2,
    isLoading: isLoadingV2,
  } = useAppCreateAccessFlowV2();
  const duplicateV2 = () => {
    const newAccessFlows: AccessFlowUpsertAppModelV2 = {
      ...accessFlow,
      grantees: toGranteeFilterGroupUpsertAppModel(accessFlow.granteesV2),
    };
    createAccessFlowV2({ ...newAccessFlows, name: accessFlow.name + ' (Copy)' });
  };

  return {
    isSuccess: isEnabled ? isSuccessV2 : isSuccess,
    isDuplicateLoading: isEnabled ? isLoadingV2 : isLoading,
    createdAccessFlow: isEnabled ? createdAccessFlowV2 : createdAccessFlow,
    duplicate: isEnabled ? duplicateV2 : duplicateV1,
  };
}

function useItemDuplicate(accessFlow: CommonAccessFlowAppModel) {
  const { track } = useAnalyticsContext();
  const { notifySuccess } = useGlobalNotifier();
  const { isSuccess, isDuplicateLoading, createdAccessFlow, duplicate } = useCommonDuplicate(accessFlow);

  useEffect(() => {
    if (!isSuccess || !createdAccessFlow) return;

    track(CustomAnalyticsEvents.ACCESS_FLOW_DUPLICATED, {
      policyId: createdAccessFlow.id,
    });
    notifySuccess(`Access flow ${createdAccessFlow.name} duplicated`);
  }, [createdAccessFlow, track, isSuccess, notifySuccess]);

  return {
    duplicate,
    isDuplicateLoading,
  };
}

function useCommonDelete(id: string) {
  const { isEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);
  const { deleteAccessFlow, isLoading, isSuccess } = useAppDeleteAccessFlow(id);
  const {
    deleteAccessFlow: deleteAccessFlowV2,
    isLoading: isLoadingV2,
    isSuccess: isSuccessV2,
  } = useAppDeleteAccessFlowV2(id);

  return {
    deleteAccessFlow: isEnabled ? deleteAccessFlowV2 : deleteAccessFlow,
    isDeleteLoading: isEnabled ? isLoadingV2 : isLoading,
    isSuccess: isEnabled ? isSuccessV2 : isSuccess,
  };
}

function useItemDelete(accessFlow: CommonAccessFlowAppModel) {
  const { track } = useAnalyticsContext();
  const { notifySuccess } = useGlobalNotifier();

  const { deleteAccessFlow, isDeleteLoading, isSuccess } = useCommonDelete(accessFlow.id);

  useEffect(() => {
    if (!isSuccess) return;

    track(CustomAnalyticsEvents.ACCESS_FLOW_DELETED, {
      policyId: accessFlow.id,
    });

    notifySuccess(`Access flow ${accessFlow.name} deleted`);
  }, [isSuccess, accessFlow, track, notifySuccess]);

  return {
    deleteAccessFlow,
    isDeleteLoading,
  };
}

export default function AccessFlowItemActions({ accessFlow }: { accessFlow: CommonAccessFlowAppModel }) {
  const navigate = useNavigate();

  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);

  const { toggleActive, isToggleLoading, activeState } = useItemToggleActive(accessFlow);
  const { duplicate, isDuplicateLoading } = useItemDuplicate(accessFlow);
  const { deleteAccessFlow, isDeleteLoading } = useItemDelete(accessFlow);

  const { isEnabled: isAccessRightSizingEnabled } = useFlagFixed(Flag.ACCESS_RIGHT_SIZING);

  const loading = isToggleLoading || isDuplicateLoading || isDeleteLoading;

  const options = [
    {
      label: 'Edit',
      iconSymbol: 'edit_square',
      onClick: () => navigate(generatePath(routes.EditAccessFlow.path, { id: accessFlow.id })),
    },
    {
      label: 'Duplicate',
      iconSymbol: 'content_copy',
      onClick: duplicate,
    },
    {
      label: 'Delete',
      iconSymbol: 'delete',
      onClick: () => setIsConfirmDeleteOpened(true),
    },
  ];

  if (isAccessRightSizingEnabled) {
    options.push({
      label: 'Right Sizing',
      iconSymbol: 'auto_awesome',
      onClick: () => navigate(`${routes.AccessRightSizing.path}?access_flow_id=${accessFlow.id}`),
    });
  }

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} onClick={markEventHandledByButton}>
      <Typography variant="helperText" color="text.muted">
        Created: <strong>{DateTime.fromUnix(Number(accessFlow.created_date)).readable}</strong>
      </Typography>
      <Tooltip arrow title={`${activeState ? 'Deactivate' : 'Activate'} your access flow`} placement="top">
        <Switch
          data-testid="access-flow-active-switch"
          color="success"
          defaultChecked={accessFlow.active}
          disabled={loading}
          size="small"
          onClick={(e) => e.stopPropagation()}
          onChange={() =>
            toggleActive({
              active: !activeState,
            })
          }
        />
      </Tooltip>
      <ActionsMenuV2 loading={loading} options={options} />
      <ConfirmDialog
        open={isConfirmDeleteOpened}
        onClose={() => setIsConfirmDeleteOpened(false)}
        onConfirm={deleteAccessFlow}
      >
        Are you sure you want to delete <strong>{accessFlow.name}</strong>?
      </ConfirmDialog>
    </Stack>
  );
}
