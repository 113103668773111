import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ListFilters from '@components/ListFilters/ListFilters';

import { ATTRIBUTE_FILTER_PARAM, ROLE_FILTER_PARAM } from '@Identities/common/constants';
import RolesFilter, { ROLE_FILTER_LABEL, RolesFilterOption } from '@Identities/organisms/UsersListFilters/RolesFilter';
import AttributeFilter, { AttributeFilterOption } from './UsersListFilters/AttributeFilter';
import SearchBarFilter from '@common/organisms/SearchBarFilter';
import { PAGE_PARAM } from '@api';

export interface UsersFiltersAttributeFilterTrigger {
  label: string;
  icon?: ReactNode;
}

export interface UsersFiltersAttributeFilter {
  filterParam: string;
  trigger: UsersFiltersAttributeFilterTrigger;
  options: AttributeFilterOption[];
}

export interface UsersFiltersProps {
  attributes: UsersFiltersAttributeFilter[];
  roles: RolesFilterOption[];
}

const MIN_EMPTY_FILTERS = 1;

export function UsersFiltersList({ attributes, roles }: UsersFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);

  const applySearchParams = useCallback(
    (sp: URLSearchParams) => {
      if (sp.has(PAGE_PARAM)) sp.delete(PAGE_PARAM);
      setSearchParams(sp);
    },
    [setSearchParams],
  );

  const getVisibleFiltersFromQP = useCallback(() => {
    const selectedAttributes = searchParams.getAll(ATTRIBUTE_FILTER_PARAM);
    const visibleAttrTypes = new Set<string>();

    for (const attribute of attributes) {
      for (const option of attribute.options) {
        if (selectedAttributes.includes(option.value)) {
          visibleAttrTypes.add(attribute.filterParam);
        }
      }
    }
    return visibleAttrTypes;
  }, [attributes, searchParams]);

  const getVisibleFilters = useCallback(() => {
    const visibleAttrTypes = getVisibleFiltersFromQP();

    if (visibleAttrTypes.size === 0) {
      const initVisibleFilters = attributes.map((attribute) => attribute.filterParam).slice(0, MIN_EMPTY_FILTERS);
      initVisibleFilters.unshift(ROLE_FILTER_PARAM);
      return initVisibleFilters;
    }

    return [ROLE_FILTER_PARAM, ...Array.from(visibleAttrTypes)];
  }, [attributes, getVisibleFiltersFromQP]);

  const [defaultVisibleFilters] = useState(getVisibleFilters());

  const [visibleFilters, setVisibleFilters] = useState<string[]>(defaultVisibleFilters);

  useEffect(() => {
    const visibleFiltersFromQP = getVisibleFiltersFromQP();
    const additionalVisibleFilters: string[] = [];
    for (const visibleFilterFromQP of visibleFiltersFromQP) {
      if (!visibleFilters.includes(visibleFilterFromQP)) {
        additionalVisibleFilters.push(visibleFilterFromQP);
      }
    }

    const stillVisibleFilters: string[] = [];
    for (const visibleFilter of visibleFilters) {
      if (
        visibleFiltersFromQP.has(visibleFilter) ||
        defaultVisibleFilters.includes(visibleFilter) ||
        openedFilter === visibleFilter
      ) {
        stillVisibleFilters.push(visibleFilter);
      }
    }
    if (additionalVisibleFilters.length > 0 || stillVisibleFilters.length != visibleFilters.length) {
      setVisibleFilters([...stillVisibleFilters, ...additionalVisibleFilters]);
    }
  }, [getVisibleFiltersFromQP, visibleFilters, defaultVisibleFilters, openedFilter]);

  const handleOnAddFilter = (filter: string) => {
    setVisibleFilters((prev) => [...prev, filter]);
    setOpenedFilter(filter);
  };

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (filter: string) => setOpenedFilter(filter);

  const filters = useMemo(() => {
    const attribFilters = attributes.map((attribute) => ({
      queryParam: attribute.filterParam,
      name: attribute.trigger.label,
      icon: attribute.trigger.icon,
      element: (
        <AttributeFilter
          {...attribute}
          opened={openedFilter === attribute.filterParam}
          onClose={onCloseFilter}
          onOpen={() => onOpenFilter(attribute.filterParam)}
        />
      ),
    }));

    attribFilters.unshift({
      name: ROLE_FILTER_LABEL,
      queryParam: ROLE_FILTER_PARAM,
      element: <RolesFilter options={roles} />,
      icon: undefined,
    });

    return attribFilters;
  }, [attributes, roles, openedFilter]);

  const hasActiveFilters = useMemo(() => {
    if (searchParams.has(ATTRIBUTE_FILTER_PARAM)) return true;

    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    if (searchParams.has(ATTRIBUTE_FILTER_PARAM)) {
      searchParams.delete(ATTRIBUTE_FILTER_PARAM);
    }

    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }

    applySearchParams(searchParams);
  }, [applySearchParams, filters, searchParams]);

  return (
    <ListFilters
      visibleFilters={visibleFilters}
      filters={filters}
      onAddFilter={handleOnAddFilter}
      onClear={hasActiveFilters ? handleOnClear : undefined}
      prefix={
        <SearchBarFilter placeholder="Search by name or email" onSetSearchParams={(sp) => applySearchParams(sp)} />
      }
    />
  );
}
