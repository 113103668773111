import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import { CloseButton } from '@components';
import { PropsWithChildren } from 'react';

export type Setting = {
  key: string;
  title: string;
  description: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isPremium: boolean;
};

export const AccessFlowSettingsDrawer = ({ children, onClose, ...props }: PropsWithChildren<DrawerProps>) => {
  return (
    <Drawer {...props} anchor="right" variant="persistent">
      <Stack spacing={1.5} p={3} width={500}>
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" py={1}>
              Settings
            </Typography>
            <CloseButton onClose={() => onClose?.({}, 'backdropClick')} />
          </Stack>
          <Alert severity="info" variant="simple-info">
            Click Save Access Flow for changes to take affect
          </Alert>
        </Stack>
        <Divider />
        {children}
      </Stack>
    </Drawer>
  );
};
