import { LabelAppModel } from '@api';
import { Chip, ChipProps } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from '@mui/system';

interface AccessFlowLabelChipProps {
  label: LabelAppModel;
  active?: boolean;
  size?: ChipProps['size'];
  onClick?: () => void;
  onDelete?: () => void;
}

export default function AccessFlowLabel({ label, size, active, onDelete, onClick }: AccessFlowLabelChipProps) {
  return (
    <Chip
      icon={
        <Box sx={(theme) => ({ paddingLeft: theme.spacing(0.5) })}>
          <FiberManualRecordIcon
            sx={(theme) => ({
              width: theme.spacing(2),
              height: theme.spacing(1.75),
              fill: label.color,
              display: 'block',
            })}
          />
        </Box>
      }
      label={label.name}
      onClick={onClick}
      variant={active ? 'filled' : 'outlined'}
      size={size}
      onDelete={onDelete}
    />
  );
}
