export const aponoColors = {
  light: {
    50: '#FBFBFB',
    100: '#F6F6F6',
    200: '#F0F0F0',
    400: '#B2BECC',
    800: '#3E485A',
    900: '#252A35',
  },
  neutral: {
    50: '#EFEFEF',
    100: '#CDD3DD',
    200: '#B2BECC',
    300: '#98A7B9',
    400: '#70889E',
    500: '#5D6A83',
    600: '#495469',
    700: '#212834',
    750: '#1C232D',
    800: '#171D26',
    900: '#12161F',
  },
  primary: {
    50: '#EEFAFF',
    100: '#00AEFF',
    200: '#D8F3FF',
    300: '#ABDDFF',
    400: '#7BCAFF',
    500: '#49BAFF',
    600: '#00AEFF',
    700: '#008BE3',
    800: '#005DAD',
    900: '#01408E',
  },
  secondary: {
    50: '#F3F0FF',
    100: '#E5DBFF',
    200: '#D0BFFF',
    300: '#B197FC',
    400: '#9775FA',
    500: '#845EF7',
    600: '#7950F2',
    700: '#7048E8',
    800: '#6741D9',
    900: '#5F3DC4',
  },
  tertiary: {
    0: '#FF9900',
    50: '#4939B2',
    300: '#FFC078',
    400: '#FFA94D',
    500: '#FF922B',
    600: '#FD7E14',
    700: '#F76707',
    800: '#E8590C',
    900: '#FE9340',
  },
  success: {
    50: '#ECFDF5',
    100: '#D1FAE5',
    200: '#A7F3D0',
    300: '#6EE7B7',
    400: '#34D399',
    500: '#10B981',
    600: '#059669',
    700: '#047857',
    800: '#065F46',
    900: '#064E3B',
  },
  warning: {
    50: '#FFF9DB',
    100: '#FFF3BF',
    200: '#FFEC99',
    300: '#FFE066',
    400: '#FFD43B',
    500: '#FCC419',
    600: '#FAB005',
    700: '#F59F00',
    800: '#F08C00',
    900: '#E67700',
  },
  error: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  white: '#FFFFFF',
  black: '#000000',
  uglyGreen: '#D3F85A',
};
