import { TriggerTypeAppModel } from '@api';
import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { ListFilterOption } from '@components/ListFilters/common';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TRIGGER_TYPE_FILTER_PARAM } from '@AccessFlows/common/constants';

export const TRIGGER_TYPE_FILTER_LABEL = 'Trigger Type';

interface TriggerTypeFilterProps {
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export default function TriggerTypeFilter({ opened = false, onClose, onOpen }: TriggerTypeFilterProps) {
  const { isEnabled: showAutoGrant } = useFlagFixed(Flag.AUTO_GRANT);
  const [isOpen, setIsOpen] = useState(opened);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.getAll(TRIGGER_TYPE_FILTER_PARAM);

  interface TriggerTypeOption {
    label: string;
    value: TriggerTypeAppModel;
  }
  const activeOptions: TriggerTypeOption[] = [
    {
      label: 'Self Serve',
      value: TriggerTypeAppModel.UserRequest,
    },
  ];

  if (showAutoGrant) {
    activeOptions.push({
      label: 'Automatic',
      value: TriggerTypeAppModel.AutoGrant,
    });
  }

  const triggerTypeOptions: TriggerTypeOption[] = [];
  const selectedOptions: TriggerTypeOption[] = [];
  for (const option of activeOptions) {
    const listOption = {
      label: option.label,
      value: option.value,
    };

    if (selectedQp.includes(option.value)) {
      selectedOptions.push(listOption);
    }

    triggerTypeOptions.push(listOption);
  }

  const handleOnSelect = (values: ListFilterOption[]) => {
    searchParams.delete(TRIGGER_TYPE_FILTER_PARAM);
    for (const value of values) {
      searchParams.append(TRIGGER_TYPE_FILTER_PARAM, value.value);
    }

    setSearchParams(searchParams);
  };

  const handleOnClear = () => {
    searchParams.delete(TRIGGER_TYPE_FILTER_PARAM);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={TRIGGER_TYPE_FILTER_LABEL}
          value={selectedOptions ? selectedOptions : []}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      <ListFilterMultiSelect
        options={activeOptions}
        value={selectedOptions}
        onSelect={handleOnSelect}
        title="Select Trigger Type Filter"
      />
    </ListFilter>
  );
}
