import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const AppSidebarItemWrapperContainer = styled('div')(({ theme }) => ({
  '&.padded': {
    padding: theme.spacing(1.5),
  },
}));

interface AppSidebarItemWrapperProps {
  padded?: boolean;
}

export default function AppSidebarItemWrapper({
  padded = true,
  children,
}: PropsWithChildren<AppSidebarItemWrapperProps>) {
  return <AppSidebarItemWrapperContainer className={padded ? 'padded' : ''}>{children}</AppSidebarItemWrapperContainer>;
}
