import { useState } from 'react';
import { nanoid } from 'nanoid';
import { ApproverConditionGroupAppModel, ApproverPolicyAppModel, ApproverPolicyGroupsOperatorAppModel } from '@api';
import FlowFormAdvancedApprovers from '@AccessFlows/organisms/FlowForm/Form/AdvancedApprovers';
import TabsSwitch from '@common/ui/TabsSwitch';
import { Button, Stack, Tab, Typography } from '@mui/material';
import FlowFormSection from '@AccessFlows/components/FlowFormSection';
import { MaterialIcon } from '@components';

interface FlowFormPolicyApproverProps {
  value?: ApproverPolicyAppModel;
  onChange: (value: ApproverPolicyAppModel) => void;
}

interface ConditionGroupItem {
  key: string;
  group?: ApproverConditionGroupAppModel;
}

export function FlowFormPolicyApprovers({ value, onChange }: FlowFormPolicyApproverProps) {
  const [groupsOperator, setGroupsOperator] = useState<ApproverPolicyGroupsOperatorAppModel>(
    value?.groups_operator || 'ANY_OF',
  );
  const [conditionGroups, setConditionGroups] = useState<ConditionGroupItem[]>(() => {
    if (value?.condition_groups && value.condition_groups.length > 0) {
      return value.condition_groups.map((group) => ({
        key: `key-${nanoid()}`,
        group,
      }));
    }
    return [{ key: `key-${nanoid()}`, group: undefined }];
  });

  const handleOnChange = (operator: ApproverPolicyGroupsOperatorAppModel, groups: ConditionGroupItem[]) => {
    onChange({
      groups_operator: operator,
      condition_groups: groups.map((item) => item.group).filter((g) => g) as ApproverConditionGroupAppModel[],
    });
  };

  const handleGroupChange = (key: string, group?: ApproverConditionGroupAppModel) => {
    const index = conditionGroups.findIndex((f) => f.key === key);
    if (index === -1) return;

    const newGroups = [...conditionGroups];
    newGroups[index] = { key, group };

    setConditionGroups(newGroups);
    handleOnChange(groupsOperator, newGroups);
  };

  const handleAddGroup = () => {
    setConditionGroups((prev) => [...prev, { key: `key-${nanoid()}`, group: undefined }]);
  };

  const handleDeleteGroup = (key: string) => {
    const newGroups = conditionGroups.filter((f) => f.key !== key);
    setConditionGroups(newGroups);
    handleOnChange(groupsOperator, newGroups);
  };

  const handleDuplicateGroup = (key: string) => {
    const index = conditionGroups.findIndex((f) => f.key === key);
    if (index === -1) return;
    const item = conditionGroups[index];
    const newGroups = [
      ...conditionGroups.slice(0, index + 1),
      { key: `key-${nanoid()}`, group: item.group },
      ...conditionGroups.slice(index + 1),
    ];
    setConditionGroups(newGroups);
    handleOnChange(groupsOperator, newGroups);
  };

  const handleOperatorChange = (operator: ApproverPolicyGroupsOperatorAppModel) => {
    setGroupsOperator(operator);
    handleOnChange(operator, conditionGroups);
  };

  return (
    <FlowFormSection
      operator={
        <TabsSwitch
          value={groupsOperator}
          onChange={(_, newTab) => handleOperatorChange(newTab)}
          size="smallest"
          data-testid="tab-switch-and-or"
        >
          <Tab value={ApproverPolicyGroupsOperatorAppModel.AnyOf} label="ANY OF" />
          <Tab value={ApproverPolicyGroupsOperatorAppModel.AllOf} label="ALL OF" />
        </TabsSwitch>
      }
    >
      {conditionGroups.map((item) => (
        <Stack key={item.key} direction="column" spacing={0.5}>
          <Typography variant="helperText">Approver #{conditionGroups.indexOf(item) + 1}</Typography>
          <FlowFormAdvancedApprovers
            key={item.key}
            value={item.group}
            onAddGroup={handleAddGroup}
            onDuplicateGroup={() => handleDuplicateGroup(item.key)}
            onChange={(group) => handleGroupChange(item.key, group)}
            onDeleteGroup={conditionGroups.length > 1 ? () => handleDeleteGroup(item.key) : undefined}
          />
        </Stack>
      ))}
      <Button onClick={handleAddGroup} variant="outlined-narrow" data-testid="add-attribute-group-button">
        <MaterialIcon symbol="add" />
      </Button>
    </FlowFormSection>
  );
}
