import { AccessBundleUpsertAppModel, AccessFlowsService } from '@api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BundleQueryKeys } from './queryKeys';

export function useAppDeleteBundle(id: string) {
  const queryClient = useQueryClient();

  const { mutate: deleteBundle, ...rest } = useMutation({
    mutationFn: async () => AccessFlowsService.appDeleteAccessBundle({ id }),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([BundleQueryKeys.List]),
        queryClient.invalidateQueries([BundleQueryKeys.Get, id]),
      ]),
  });

  return {
    deleteBundle,
    ...rest,
  };
}

export function useAppCreateBundle() {
  const queryClient = useQueryClient();

  const {
    mutate: createBundle,
    mutateAsync: createBundleAsync,
    ...rest
  } = useMutation({
    mutationFn: async (payload: AccessBundleUpsertAppModel) =>
      AccessFlowsService.appCreateAccessBundle({
        accessBundleUpsertAppModel: payload,
      }),
    onSettled: () => queryClient.invalidateQueries([BundleQueryKeys.List]),
  });

  return {
    createBundle,
    createBundleAsync,
    ...rest,
  };
}

export function useAppUpdateBundle(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: updateBundle,
    mutateAsync: updateBundleAsync,
    ...rest
  } = useMutation({
    mutationFn: async (payload: AccessBundleUpsertAppModel) =>
      AccessFlowsService.appUpdateAccessBundle({
        id,
        accessBundleUpsertAppModel: payload,
      }),
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries([BundleQueryKeys.List]),
        queryClient.invalidateQueries([BundleQueryKeys.Get, id]),
      ]),
  });

  return {
    updateBundle,
    updateBundleAsync,
    ...rest,
  };
}
