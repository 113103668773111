import { AccountsService, UsersService } from '@api';
import { useQuery } from '@tanstack/react-query';

import { AccountQueryKeys } from '@common/services/queryKeys';

export function useListUsers() {
  const { data: users, ...rest } = useQuery({
    queryKey: [AccountQueryKeys.ListUsers],
    queryFn: async () => AccountsService.listUsers(),
    initialData: [],
  });

  return {
    users,
    ...rest,
  };
}

export function useListGroups() {
  const { data: groups, ...rest } = useQuery({
    queryKey: [AccountQueryKeys.ListGroups],
    queryFn: async () => AccountsService.listGroups(),
    initialData: [],
  });

  return {
    groups,
    ...rest,
  };
}

export function useListAttributes() {
  const { data: attributes, ...rest } = useQuery({
    queryKey: [AccountQueryKeys.ListAttributes],
    queryFn: async () => {
      const { attributes: attr } = await UsersService.listAvailableAttributesV2();
      return attr;
    },
    initialData: [],
  });

  return {
    attributes,
    ...rest,
  };
}
