import { useMemo } from 'react';
import { Alert, Avatar, Chip, Stack, styled, Typography } from '@mui/material';

import { IntegrationConfigAppModelV2 } from '@api';
import { MaterialIcon, UpgradeWrapper } from '@components';
import { CustomAnalyticsEvents } from '@utils/analytics';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

const ResourceTypeChipAvatar = styled(Avatar)(({ theme }) => ({
  width: `${theme.spacing(2)} !important`,
  height: `${theme.spacing(2)} !important`,
}));

export interface SelectResourceTypesProps {
  config: IntegrationConfigAppModelV2;
  connected?: string[];
  selected?: string[];
  onSelect: (selected: string[]) => void;
  withDescription?: boolean;
  disabled?: boolean;
  align?: 'left' | 'center';
}

export function useGetAllowedResourceTypes(config: IntegrationConfigAppModelV2) {
  const allowedResourceTypes: string[] = useMemo(() => {
    const configResourceTypes = Object.entries(config.resource_types);
    const resourceTypesKeys = [];
    for (const [resourceTypeKey, value] of configResourceTypes) {
      if (!value.requestable) {
        continue;
      }
      resourceTypesKeys.push(resourceTypeKey);
    }
    return resourceTypesKeys;
  }, [config]);

  return { allowedResourceTypes };
}

function useSelectResourceTypes({ config, selected, connected, onSelect }: Omit<SelectResourceTypesProps, 'title'>) {
  const { allowedResourceTypes } = useGetAllowedResourceTypes(config);
  const { isEnabled: isDeselectInEditEnabled } = useFlagFixed(Flag.DESELECT_IN_EDIT);

  const chipsOptions = useMemo(() => {
    const chips = [];

    for (const resourceTypeKey of allowedResourceTypes) {
      const resourceTypeValue = config.resource_types[resourceTypeKey];
      if (resourceTypeValue.enabled) {
        chips.push({
          value: resourceTypeValue.type,
          name: resourceTypeValue.display_name,
          icon: resourceTypeValue.icons.svg,
          selected: selected?.includes(resourceTypeValue.type),
          installed: !isDeselectInEditEnabled && connected?.includes(resourceTypeValue.type), // remove this prop & usage when removing the flag
          isPremium: resourceTypeValue.premium,
        });
      }
    }

    return chips;
  }, [allowedResourceTypes, config.resource_types, selected, isDeselectInEditEnabled, connected]);

  const handleSelect = (value: string) => {
    if (selected?.includes(value)) {
      onSelect(selected.filter((v) => v !== value));
    } else {
      onSelect([...(selected || []), value]);
    }
  };

  return {
    chipsOptions,
    handleSelect,
  };
}

function ResourceTypesHeader({ text, icon, isOptional }: { text: string; icon: string; isOptional?: boolean }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar src={icon} sx={{ width: 20, height: 20 }} variant="square" />
      <Typography variant="subtitle2">{text}</Typography>
      {isOptional && (
        <Typography variant="helperText" color="muted">
          (Optional)
        </Typography>
      )}
    </Stack>
  );
}

export function SelectResourceTypes({
  config,
  selected,
  connected, // remove this prop & usage when removing the flag
  onSelect,
  withDescription,
  disabled,
  align = 'left',
}: SelectResourceTypesProps) {
  const { chipsOptions, handleSelect } = useSelectResourceTypes({
    config,
    selected,
    connected,
    onSelect,
  });

  return (
    <Stack direction="column" justifyContent="center" alignItems={align} spacing={2}>
      <Stack direction="column" justifyContent="center" alignItems={align}>
        <ResourceTypesHeader text={config.name} icon={config.icons.svg} />
        {withDescription && (
          <Typography variant="inputLabel">
            Apono automatically discovers all the instances in the environment. Then, you can start managing access to
            resources with Access Flows!
          </Typography>
        )}
      </Stack>
      {chipsOptions.length === 0 && <Alert severity="warning">No resource types available</Alert>}
      <Stack spacing={1} direction="row" justifyContent="left" alignItems="center" useFlexGap flexWrap="wrap">
        {chipsOptions.map((chip) => (
          <ResourceTypeChip
            {...chip}
            key={chip.value}
            onClick={() => handleSelect(chip.value)}
            isPremium={chip.isPremium}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Stack>
  );
}

interface ResourceTypeChipProps {
  name: string;
  icon: string;
  selected?: boolean;
  installed?: boolean;
  onClick: () => void;
  isPremium: boolean;
  disabled?: boolean;
}

function ResourceTypeChip({ name, icon, selected, installed, onClick, isPremium, disabled }: ResourceTypeChipProps) {
  const handleClick = installed ? undefined : onClick;
  const common = {
    label: name,
    avatar: <ResourceTypeChipAvatar src={icon} />,
    clickable: true,
    onClick: handleClick,
    onDelete: handleClick,
    className: 'rt-chip-avatar',
    'data-trigger': CustomAnalyticsEvents.ADD_INTEGRATION_RESOURCE_TYPE_ENGAGED,
    'data-props': JSON.stringify({
      resourceTypeName: name,
      resourceTypeSelected: !selected,
    }),
  };

  if (installed) {
    return <Chip {...common} color="success" disabled={disabled} />;
  }

  if (selected) {
    return (
      <Chip
        {...common}
        deleteIcon={<MaterialIcon symbol="close" weight={700} />}
        color="primary"
        data-testid={`delete-resource-type-chip`}
        disabled={disabled}
      />
    );
  }

  return (
    <UpgradeWrapper isPremium={isPremium}>
      <Chip
        {...common}
        variant="outlined"
        deleteIcon={<MaterialIcon symbol="add" weight={700} />}
        disabled={isPremium || disabled}
        data-testid={`add-resource-type-chip`}
      />
    </UpgradeWrapper>
  );
}
