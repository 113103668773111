import { TopListWidget } from '@DashboardV2/components/TopListWidget/TopListWidget';
import { useResourceTypesUsageStats } from '@DashboardV2/services/gql';
import { AVAILABLE_COLORS } from '@common/constants';
import { useMemo } from 'react';
import { Loader } from '@components';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { IntegrationRowModel } from '@DashboardV2/components/TopListWidget/IntegrationRows';

export function Top10CloudServices() {
  return (
    <WidgetCard title="Top 10 Requested Resource Types" subtitle="Last 30 days">
      <Top10ResourceTypes />
    </WidgetCard>
  );
}

function Top10ResourceTypes() {
  const { data, isFetched } = useResourceTypesUsageStats({ take: 10 });

  const resourceTypeData: IntegrationRowModel[] = useMemo(() => {
    if (!data) return [];

    const totalRequests = data.requestsPerResourceTypeStats.reduce((acc, curr) => acc + curr.totalRequests, 0);
    const result: IntegrationRowModel[] = [];

    data.requestsPerResourceTypeStats.forEach((request, i) => {
      if (request.resourceType && request.integration && request.totalRequests) {
        result.push({
          iconSrc: request.resourceType.icons.svg,
          title: request.resourceType.typeDisplayName,
          subTitle: request.integration.name,
          color: AVAILABLE_COLORS[i % AVAILABLE_COLORS.length],
          sum: request.totalRequests,
          percent: Number(((request.totalRequests / totalRequests) * 100).toFixed(2)),
          id: `${request.resourceType.typeDisplayName}-${i}`,
        });
      }
    });

    return result;
  }, [data]);

  if (!isFetched) return <Loader />;

  if (resourceTypeData.length === 0)
    return (
      <PieChartEmptyState
        title="No Requests Created for this period"
        subtitle="No access requests match the chosen time period."
        size={{ width: 100, height: 80 }}
        animationSrc={'/static/EmptyStateImages/no-data-big-pie-chart.svg'}
      />
    );

  return <TopListWidget integrationsData={resourceTypeData} />;
}
