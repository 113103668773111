import { MaterialIcon } from '@components';
import { Stack, Tooltip, Typography } from '@mui/material';
import { aponoColors } from '@utils';

interface DashboardLegendProps {
  color: string;
  label: string;
  subLabel?: string;
  tooltip?: string;
}

function Square({ color }: { color: string }) {
  return <div style={{ width: '12px', height: '12px', backgroundColor: color, borderRadius: 2 }} />;
}

export default function DashboardLegend({ color, label, subLabel, tooltip }: DashboardLegendProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Square color={color} />
      <Typography variant="helperText">{label}</Typography>
      {subLabel && (
        <Typography variant="helperText" color="text.muted">
          {subLabel}
        </Typography>
      )}
      {tooltip && (
        <Tooltip title={tooltip} arrow placement="top">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <MaterialIcon symbol="info" style={{ color: aponoColors.neutral[300] }} size="small" />
          </div>
        </Tooltip>
      )}
    </Stack>
  );
}
