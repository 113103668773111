import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PAGE_PARAM } from '@api';
import { SearchBar } from '@components';

import { SEARCH_PARAM } from '../common/constants';

export default function WebhooksTableSearch() {
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useMemo(() => searchParams.get(SEARCH_PARAM) || undefined, [searchParams]);

  const handleSearchChange = (value: string) => {
    if (value === search) return;

    searchParams.delete(PAGE_PARAM);
    if (value === '') searchParams.delete(SEARCH_PARAM);
    else searchParams.set(SEARCH_PARAM, value);

    setSearchParams(searchParams);
  };

  return <SearchBar value={search} onChange={handleSearchChange} />;
}
