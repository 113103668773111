import { Controller, useFormContext } from 'react-hook-form';

import { WebhookUpdateAppModel } from '@api';
import { LabeledTextField } from '@components';

export default function TimeoutField() {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Controller
      control={control}
      name="retry_policy.timeout_in_sec"
      render={({ field, fieldState: { invalid, error } }) => (
        <LabeledTextField
          type="number"
          label="Timeout in seconds"
          error={invalid}
          helperText={error?.message}
          inputProps={field}
          optional
        />
      )}
    />
  );
}
