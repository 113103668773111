import { memo, useMemo } from 'react';
import { Avatar, SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import LabelIcon from '@mui/icons-material/Label';

import { aponoColors } from '@utils';

const GranteeTypeIcon = memo(function GranteeTypeIcon({
  type,
  size = 'medium',
  inChip = false,
}: {
  type: string;
  size?: 'small' | 'medium';
  inChip?: boolean;
}) {
  const avatarSx: SxProps<Theme> = (theme: Theme) => {
    let defaultStyles: SystemStyleObject<Theme> = {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: aponoColors.primary[500],

      '& .MuiSvgIcon-root': {
        width: theme.spacing(1.8),
        height: theme.spacing(1.8),
      },
    };

    if (inChip) {
      defaultStyles = {
        ...defaultStyles,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(-0.5),
      };
    }

    switch (type) {
      case 'GROUP':
        defaultStyles = {
          ...defaultStyles,
          backgroundColor: aponoColors.tertiary[500],
        };
        break;
      case 'CONTEXT_ATTRIBUTE':
        defaultStyles = {
          ...defaultStyles,
          backgroundColor: aponoColors.tertiary[800],
        };
    }

    if (size === 'small') {
      defaultStyles = {
        ...defaultStyles,
        width: theme.spacing(2),
        height: theme.spacing(2),

        '& .MuiSvgIcon-root': {
          width: theme.spacing(1.2),
          height: theme.spacing(1.2),
        },
      };
    }

    return defaultStyles;
  };

  const icon = useMemo(() => {
    switch (type) {
      case 'GROUP':
        return <GroupIcon />;
      case 'CONTEXT_ATTRIBUTE':
        return <LabelIcon />;
      case 'USER':
        return <PersonIcon />;
      default:
        return null;
    }
  }, [type]);

  if (!icon) {
    return null;
  }

  return <Avatar sx={avatarSx}>{icon}</Avatar>;
});

export default GranteeTypeIcon;
