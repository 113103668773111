import { LabeledSelectField } from '@components';
import { WebhookTypeAppModel } from '@Webhooks/common/constants';

interface WebhookTypeFieldProps {
  value?: WebhookTypeAppModel;
  onChange: (value: WebhookTypeAppModel) => void;
}

export default function WebhookTypeField({ value, onChange }: WebhookTypeFieldProps) {
  const handleOnChange = (newVal: string) => onChange(newVal as WebhookTypeAppModel);

  return (
    <LabeledSelectField
      label="Type"
      options={[
        { key: WebhookTypeAppModel.Http, value: 'HTTP Webhook' },
        { key: WebhookTypeAppModel.IntegrationAction, value: 'Integration Action' },
      ]}
      value={String(value)}
      onChange={(e) => handleOnChange(String(e.target.value))}
      inputProps={{
        id: 'type',
      }}
    />
  );
}
