import { WebhookInvocationAppModel } from '@api';
import { AppDrawer, AppDrawerHeader } from '@components';

import WebhookInvocation from './WebhookInvocation';

export default function WebhookDrawer({
  invocationData,
  onClose,
}: {
  invocationData?: WebhookInvocationAppModel;
  onClose: () => void;
}) {
  return (
    <AppDrawer
      width={600}
      open={Boolean(invocationData)}
      onClose={onClose}
      header={<AppDrawerHeader title="Webhook Test" onCloseClick={onClose} />}
    >
      {invocationData && <WebhookInvocation invocation={invocationData} />}
    </AppDrawer>
  );
}
