import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';

import { WebhookAppModel } from '@api';
import { DateTime } from '@utils';
import { ActionsMenuV2, ConfirmDialog } from '@components';

import { routes } from '@routes';

import UrlCell from '../components/UrlCell';
import { useAppDeleteWebhook, useAppUpdateWebhook } from '../services/webhooksMutations';
import { CustomMouseEvent, isEventHandledByButton, markEventHandledByButton } from '@utils/mouse-events';
import { useGlobalNotifier } from '@hooks';
import StatusIcon from '@common/ui/StatusIcon';
import { AccessStatusModel } from '@common/constants';

export default function WebhooksTableRow({ webhook }: { webhook: WebhookAppModel }) {
  const { notifySuccess } = useGlobalNotifier();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const {
    updateWebhook,
    isLoading: isUpdateLoading,
    isSuccess: isUpdated,
    data: updatedWebhook,
  } = useAppUpdateWebhook(webhook.id);
  const { deleteWebhook, isLoading: isDeleteLoading, isSuccess: isDeleted } = useAppDeleteWebhook(webhook.id);

  const navigate = useNavigate();

  const handleEditClick = useCallback(
    () => navigate(generatePath(routes.EditWebhook.path, { id: webhook.id })),
    [navigate, webhook.id],
  );

  const handleHistoryClick = useCallback(
    () => navigate(generatePath(routes.WebhookHistory.path, { id: webhook.id })),
    [navigate, webhook.id],
  );

  const handleRowClick = useCallback(
    () => navigate(generatePath(routes.EditWebhook.path, { id: webhook.id })),
    [navigate, webhook.id],
  );

  const handleToggleActivate = useCallback(
    () =>
      updateWebhook({
        ...webhook,
        active: !webhook.active,
      }),
    [updateWebhook, webhook],
  );

  const actionsOptions = useMemo(() => {
    let options = [
      {
        label: 'Edit',
        iconSymbol: 'edit_square',
        onClick: handleEditClick,
      },
    ];

    if (webhook.active) {
      options = [
        ...options,
        {
          label: 'Inactivate',
          iconSymbol: 'toggle_off',
          onClick: handleToggleActivate,
        },
      ];
    } else {
      options = [
        ...options,
        {
          label: 'Activate',
          iconSymbol: 'toggle_on',
          onClick: handleToggleActivate,
        },
      ];
    }

    return [
      ...options,
      {
        label: 'History',
        iconSymbol: 'history',
        onClick: handleHistoryClick,
      },
      {
        label: 'Delete',
        iconSymbol: 'delete',
        onClick: () => setOpenConfirmDelete(true),
      },
    ];
  }, [handleEditClick, handleHistoryClick, handleToggleActivate, webhook.active]);

  useEffect(() => {
    if (isUpdated) {
      notifySuccess(`Webhook successfully ${updatedWebhook.active ? 'activated' : 'inactivated'}`);
    }
  }, [isUpdated, notifySuccess, updatedWebhook]);

  useEffect(() => {
    if (isDeleted) {
      notifySuccess(`Webhook successfully deleted`);
    }
  }, [isDeleted, notifySuccess]);

  const webhookStatus = webhook.active ? 'Active' : 'Inactive';

  return useMemo(
    () => (
      <TableRow
        hover
        className="MuiTableRow-clickable"
        onClick={(e: CustomMouseEvent) => {
          if (isEventHandledByButton(e)) return;
          handleRowClick();
        }}
        data-testid={'webhook-row-' + webhookStatus}
      >
        <TableCell>
          <Typography variant="body2">{webhook.name}</Typography>
        </TableCell>
        <TableCell>
          <UrlCell method={webhook.method} url={webhook.url} />
        </TableCell>
        <TableCell>
          <Typography variant="body2">{webhook.triggers.join(', ')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {webhook.last_invocation_date ? DateTime.fromUnix(Number(webhook.last_invocation_date)).readable : '-'}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <StatusIcon status={webhook.active ? AccessStatusModel.Active : ''} />
            <Typography variant="body2" component="div">
              {webhookStatus}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="right" onClick={markEventHandledByButton}>
          <ActionsMenuV2 loading={isUpdateLoading || isDeleteLoading} options={actionsOptions} />
          <ConfirmDialog open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)} onConfirm={deleteWebhook}>
            Are you sure you want to delete this Webhook? It cannot be undone
          </ConfirmDialog>
        </TableCell>
      </TableRow>
    ),
    [
      webhookStatus,
      webhook,
      isUpdateLoading,
      isDeleteLoading,
      actionsOptions,
      openConfirmDelete,
      deleteWebhook,
      handleRowClick,
    ],
  );
}
