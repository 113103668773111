import { QueryFunctionContext } from '@tanstack/react-query';

import { ActivityService } from '@api/services';

import { QUERY_KEYS } from '../../../common/constants';
import {
  ActivityApiAppGetActivityRequestAccessUnitGroupsRequest,
  ActivityApiAppGetActivityRequestAccessUnitsRequest,
  API_LIST_LIMIT,
  useListQuery,
} from '@api';

export function useGetActivityRequestAccessUnits(props: ActivityApiAppGetActivityRequestAccessUnitsRequest) {
  const fetchMethod = async ({ pageParam: skipParam = 0 }: QueryFunctionContext) => {
    const res = await ActivityService.appGetActivityRequestAccessUnits({
      ...props,
      limit: props.limit || API_LIST_LIMIT,
      skip: Math.max(skipParam, 0),
    });

    return res;
  };

  return useListQuery({
    fetchMethod,
    queryKey: [QUERY_KEYS.AccessUnits, props],
    withoutPageParam: true,
  });
}

export function useGetActivityRequestAccessUnitGroups(props: ActivityApiAppGetActivityRequestAccessUnitGroupsRequest) {
  const fetchMethod = async ({ pageParam: skipParam = 0 }: QueryFunctionContext) => {
    const res = await ActivityService.appGetActivityRequestAccessUnitGroups({
      ...props,
      limit: props.limit || API_LIST_LIMIT,
      skip: Math.max(skipParam, 0),
    });

    return res;
  };

  return useListQuery({
    withoutPageParam: true,
    fetchMethod,
    queryKey: [QUERY_KEYS.AccessUnitGroups, props],
  });
}
