import { ElementType } from 'react';

import { Avatar, Box, Stack, styled, Typography } from '@mui/material';
import { AccessNodeModel } from '@api';

import { RESOURCE_NODE_HEIGHT, RESOURCE_NODE_HEIGHT_EXTRA, RESOURCE_NODE_WIDTH } from './common';
import { ExpandButton } from './ExpandButton';

const NodeMetaContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  width: RESOURCE_NODE_WIDTH,
  height: RESOURCE_NODE_HEIGHT,

  '&.extra-height': {
    height: RESOURCE_NODE_HEIGHT_EXTRA,
  },
}));

const ResourceNodeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

const ResourceNodeTypography = styled(Typography)<{
  component: ElementType;
}>(() => ({
  lineHeight: 1.2,
}));

const LabelWrap = styled(Box)(() => ({
  maxWidth: RESOURCE_NODE_WIDTH - 40,
}));

export interface NodeMetaProps {
  node: AccessNodeModel;
  icon?: string;
  total?: number;
  showExpand?: boolean;
}

export function hasMetadata(node: AccessNodeModel) {
  return node.metadata && Object.keys(node.metadata).length > 0;
}

export function NodeMeta({ node, icon, total, showExpand }: NodeMetaProps) {
  const has = hasMetadata(node);

  return (
    <NodeMetaContainer
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={0.5}
      className={has ? 'extra-height' : ''}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon && <ResourceNodeAvatar src={icon} variant="square" />}
        <LabelWrap>
          <ResourceNodeTypography variant="caption" component="div" color="text.primary" noWrap>
            {node.label}
          </ResourceNodeTypography>
          {has && (
            <ResourceNodeTypography variant="inputLabel" component="div" color="text.dropdown" noWrap>
              {node.metadata?.namespace}
            </ResourceNodeTypography>
          )}
        </LabelWrap>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {total && (
          <ResourceNodeTypography variant="caption" component="div" color="text.dropdown">
            {total}
          </ResourceNodeTypography>
        )}
        {showExpand && <ExpandButton />}
      </Stack>
    </NodeMetaContainer>
  );
}
