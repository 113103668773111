import { useCallback, useMemo, useState } from 'react';

import ListFilters from '@components/ListFilters/ListFilters';
import IntegrationFilterAsync, { INTEGRATION_FILTER_LABEL } from '@common/organisms/CommonFilters/IntegrationFilter';
import GranteeFilterAsync, { GRANTEE_FILTER_LABEL } from '@common/organisms/CommonFilters/GranteeFilterAsync';
import PermissionFilterAsync, { PERMISSION_FILTER_LABEL } from '@common/organisms/CommonFilters/PermissionFilterAsync';
import ResourceTypeFilterAsync, {
  RESOURCE_TYPE_FILTER_LABEL,
} from '@common/organisms/CommonFilters/ResourceTypeFilterAsync';
import AccessFlowFilterAsync, { ACCESS_FLOW_FILTER_LABEL } from '@common/organisms/CommonFilters/AccessFlowFilter';
import ResourceFilterAsync, { RESOURCE_FILTER_LABEL } from '@common/organisms/CommonFilters/ResourceFilterAsync';
import TriggerTypeFilter, { TRIGGER_TYPE_FILTER_LABEL } from '@common/organisms/CommonFilters/TriggerTypeFilter';
import { Controller, useFormContext } from 'react-hook-form';
import { WebhookAppModelFilters, WebhookUpdateAppModel } from '@api';
import { InputLabel, Stack } from '@mui/material';

const ACCESS_FLOW_PARAM = 'access_flow_ids';
const GRANTEE_PARAM = 'grantee_user_ids';
const INTEGRATION_PARAM = 'integration_ids';
const PERMISSION_PARAM = 'permissions';
const RESOURCE_PARAM = 'resource_names';
const RESOURCE_TYPE_PARAM = 'resource_types';
const TRIGGER_TYPE_PARAM = 'trigger_types';

type WebhookAppModelFiltersProp = keyof WebhookAppModelFilters;

const WEBHOOK_ACTIVITY_FILTERS_KEY_MAP: { [K in WebhookAppModelFiltersProp]: K } = {
  [ACCESS_FLOW_PARAM]: ACCESS_FLOW_PARAM,
  [GRANTEE_PARAM]: GRANTEE_PARAM,
  [INTEGRATION_PARAM]: INTEGRATION_PARAM,
  [PERMISSION_PARAM]: PERMISSION_PARAM,
  [RESOURCE_PARAM]: RESOURCE_PARAM,
  [RESOURCE_TYPE_PARAM]: RESOURCE_TYPE_PARAM,
  [TRIGGER_TYPE_PARAM]: TRIGGER_TYPE_PARAM,
};

export default function WebhookActivityFilters() {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Stack direction="column" spacing={1}>
      <InputLabel>Filters</InputLabel>
      <Controller
        control={control}
        name="filters"
        render={({ field: { onChange, value } }) => (
          <WebhookActivityFiltersInner selectedOptions={value || {}} setSelectedOptions={onChange} />
        )}
      />
    </Stack>
  );
}

function WebhookActivityFiltersInner({
  selectedOptions,
  setSelectedOptions,
}: {
  selectedOptions: WebhookAppModelFilters;
  setSelectedOptions: (selectedOptions: WebhookAppModelFilters) => void;
}) {
  const visibleFilters = Object.keys(WEBHOOK_ACTIVITY_FILTERS_KEY_MAP);

  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const onSelect = useCallback(
    ({ values, filterParam }: { values: string[]; filterParam: WebhookAppModelFiltersProp }) => {
      setSelectedOptions({ ...selectedOptions, [filterParam]: values });
    },
    [setSelectedOptions, selectedOptions],
  );

  const onClear = useCallback(
    (filterParam: keyof WebhookAppModelFilters) => {
      const { [filterParam]: _, ...rest } = selectedOptions;
      setSelectedOptions(rest);
    },
    [selectedOptions, setSelectedOptions],
  );

  const filters = useMemo(
    () => [
      {
        name: ACCESS_FLOW_FILTER_LABEL,
        queryParam: ACCESS_FLOW_PARAM,
        element: (
          <AccessFlowFilterAsync
            opened={openedFilter === ACCESS_FLOW_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(ACCESS_FLOW_PARAM)}
            selected={selectedOptions[ACCESS_FLOW_PARAM] || []}
            onClear={() => onClear(ACCESS_FLOW_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: ACCESS_FLOW_PARAM })}
          />
        ),
      },
      {
        name: GRANTEE_FILTER_LABEL,
        queryParam: GRANTEE_PARAM,
        element: (
          <GranteeFilterAsync
            opened={openedFilter === GRANTEE_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(GRANTEE_PARAM)}
            selected={selectedOptions[GRANTEE_PARAM] || []}
            onClear={() => onClear(GRANTEE_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: GRANTEE_PARAM })}
          />
        ),
      },
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_PARAM,
        element: (
          <IntegrationFilterAsync
            opened={openedFilter === INTEGRATION_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INTEGRATION_PARAM)}
            selected={selectedOptions[INTEGRATION_PARAM] || []}
            onClear={() => onClear(INTEGRATION_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: INTEGRATION_PARAM })}
          />
        ),
      },
      {
        name: PERMISSION_FILTER_LABEL,
        queryParam: PERMISSION_PARAM,
        element: (
          <PermissionFilterAsync
            opened={openedFilter === PERMISSION_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(PERMISSION_PARAM)}
            selected={selectedOptions[PERMISSION_PARAM] || []}
            onClear={() => onClear(PERMISSION_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: PERMISSION_PARAM })}
          />
        ),
      },
      {
        name: RESOURCE_FILTER_LABEL,
        queryParam: RESOURCE_PARAM,
        element: (
          <ResourceFilterAsync
            opened={openedFilter === RESOURCE_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_PARAM)}
            selected={selectedOptions[RESOURCE_PARAM] || []}
            onClear={() => onClear(RESOURCE_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: RESOURCE_PARAM })}
          />
        ),
      },
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_PARAM,
        element: (
          <ResourceTypeFilterAsync
            opened={openedFilter === RESOURCE_TYPE_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_TYPE_PARAM)}
            selected={selectedOptions[RESOURCE_TYPE_PARAM] || []}
            onClear={() => onClear(RESOURCE_TYPE_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: RESOURCE_TYPE_PARAM })}
          />
        ),
      },
      {
        name: TRIGGER_TYPE_FILTER_LABEL,
        queryParam: TRIGGER_TYPE_PARAM,
        element: (
          <TriggerTypeFilter
            opened={openedFilter === TRIGGER_TYPE_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(TRIGGER_TYPE_PARAM)}
            selected={selectedOptions[TRIGGER_TYPE_PARAM] || []}
            onClear={() => onClear(TRIGGER_TYPE_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: TRIGGER_TYPE_PARAM })}
          />
        ),
      },
    ],
    [onClear, onSelect, openedFilter, selectedOptions],
  );

  const hasActiveFilters = useMemo(() => {
    if (Object.keys(selectedOptions).length > 0) {
      return true;
    }
    return false;
  }, [selectedOptions]);

  const handleOnClear = useCallback(() => {
    setSelectedOptions({});
  }, [setSelectedOptions]);

  return (
    <ListFilters
      visibleFilters={visibleFilters}
      filters={filters}
      onClear={hasActiveFilters ? handleOnClear : undefined}
    />
  );
}
