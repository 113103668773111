import { RightSizingReportFieldsFragment } from '@api/gql/graphql';
import { Box, Grid, styled } from '@mui/material';
import { getFragmentData } from '@api/gql';
import { ACCESS_FLOW_METRICS_FRAGMENT, AccessFlowUsageMetricValue_Fragment } from '@AccessRightSizing/services/gql';
import {
  DURATION_METRIC,
  METRIC_TYPE_LABELS,
  METRIC_TYPES,
  MetricType,
  TOTAL_REQUESTS_METRIC,
} from '@AccessRightSizing/common/constants';
import { isValidMetricType, SECONDS_TO_HOURS } from '@AccessRightSizing/common/common';
import MetricsBox from '@common/ui/MetricBox';

const StyledMetricsContainer = styled(Box)(() => ({
  padding: 0,
}));

const METRICS_ORDER = [
  METRIC_TYPES.total_requests,
  METRIC_TYPES.total_users_not_requesting,
  METRIC_TYPES.total_resources_not_requested,
  METRIC_TYPES.total_permissions_not_requested,
  METRIC_TYPES.avg_access_request_duration,
];

export function AccessRightSizingMetrics({
  rightSizingReport,
}: {
  rightSizingReport: RightSizingReportFieldsFragment;
}) {
  const metrics = getFragmentData(ACCESS_FLOW_METRICS_FRAGMENT, rightSizingReport.metrics);

  const sortedMetrics = [...metrics]
    .filter((metric) => isValidMetricType(metric.metricType))
    .sort((a, b) => {
      return METRICS_ORDER.indexOf(a.metricType as MetricType) - METRICS_ORDER.indexOf(b.metricType as MetricType);
    });

  return (
    <StyledMetricsContainer>
      <Grid container spacing={2}>
        {sortedMetrics.map((metric) => {
          const current = getFragmentData(AccessFlowUsageMetricValue_Fragment, metric.currentValue);
          const previous = getFragmentData(AccessFlowUsageMetricValue_Fragment, metric.previousValue);

          let changeValue,
            changePercentage = undefined;
          if (previous) {
            changeValue = parseFloat((current.notUsed - previous.notUsed).toFixed(2));
            const changePercentageCalculation =
              previous.notUsed != 0 ? parseFloat(((changeValue / previous.notUsed) * 100).toFixed(0)) : 100;
            changePercentage = changeValue === 0 ? 0 : changePercentageCalculation;
          }
          if (metric.metricType === DURATION_METRIC) {
            if (changeValue !== undefined) {
              changeValue = changeValue === 0 ? 0 : parseFloat((changeValue / SECONDS_TO_HOURS).toFixed(2));
            }
          }

          let mainValue = '';
          if (metric.metricType === TOTAL_REQUESTS_METRIC) {
            mainValue = current.defined.toString();
          } else {
            mainValue =
              current.notUsed !== 0
                ? parseFloat(((current.notUsed / current.defined) * 100).toFixed(1)).toString() + '%'
                : 'None defined';
          }

          const title = getLabel(metric.metricType);
          return (
            <Grid item xs={2.4} key={metric.metricType}>
              <MetricsBox
                key={metric.metricType}
                title={title}
                changeValue={changeValue}
                changePercentage={changePercentage}
                mainValue={mainValue}
                timePeriod="Compared to previous period"
              />
            </Grid>
          );
        })}
      </Grid>
    </StyledMetricsContainer>
  );
}

const getLabel = (key: string): string => {
  if (key in METRIC_TYPE_LABELS) {
    return METRIC_TYPE_LABELS[key as MetricType];
  }
  return 'Unknown metric type';
};
