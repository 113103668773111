import { useListGroupMembers, useListUsers, useUpdateGroup, useUpdateGroupMembers } from '@Groups/services/queries';
import { AponoGroupModel } from '@api';
import AppModal from '@common/ui/AppModal';
import { LabeledTextField, SearchFilterMultipleSelect } from '@components';
import { useGlobalNotifier } from '@hooks';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const EditGroupModal = ({ groupInEdit, onClose }: { groupInEdit: AponoGroupModel; onClose: () => void }) => {
  const { id, name } = groupInEdit;

  const { groupMembers } = useListGroupMembers(id);

  const [usersSelected, setUsersSelected] = useState<string[]>(groupMembers?.user_ids || []);

  const [groupName, setGroupName] = useState(name);

  const { users } = useListUsers();

  const { updateGroup, isLoading: isUpdateGroupLoading, isSuccess } = useUpdateGroup();

  const { updateGroupMember } = useUpdateGroupMembers();

  const { notifySuccess } = useGlobalNotifier();

  useEffect(() => {
    if (groupMembers) setUsersSelected(groupMembers.user_ids);
  }, [groupMembers]);

  useEffect(() => {
    if (isSuccess) {
      updateGroupMember({ groupId: groupInEdit?.id, user_ids: usersSelected });
      notifySuccess(`Group ${groupInEdit?.name} updated`);
      onClose();
    }
  }, [isSuccess, groupInEdit?.name, usersSelected, updateGroupMember, onClose, notifySuccess, groupInEdit?.id]);

  return (
    <AppModal open={true} onClose={onClose} header="Edit Group" width={540}>
      <Stack spacing={2}>
        <LabeledTextField label="Group name" value={groupName} onChange={(event) => setGroupName(event.target.value)} />
        <Stack spacing={1}>
          <Typography variant="inputLabel">Users</Typography>
          <SearchFilterMultipleSelect
            options={users?.map((user) => ({ label: `${user.first_name} ${user.last_name}`, value: user.id })) || []}
            value={usersSelected}
            onChange={setUsersSelected}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }} spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            variant={'contained'}
            onClick={() => updateGroup({ id, aponoGroupModelCreateUpdateRequest: { name: groupName } })}
            loading={isUpdateGroupLoading}
            disabled={groupName === '' || usersSelected.length === 0}
          >
            Update Group
          </LoadingButton>
        </Stack>
      </Stack>
    </AppModal>
  );
};

export default EditGroupModal;
