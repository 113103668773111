import { routes } from '@routes';

import { generatePath } from 'react-router-dom';
import { GetStartedBanner, GetStartedHero, GetStartedStepsMeta, PageBanner } from '@components';
import { useFinishOnboarding, useSession } from '@api';
import { useLocalStorage } from '@libs';

import { GET_STARTED_BANNER_HIDDEN } from './constants';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

function useGetStarted() {
  const { session } = useSession();
  const { finishOnboarding } = useFinishOnboarding();
  const [bannerHidden, setBannerHidden] = useLocalStorage<boolean>(GET_STARTED_BANNER_HIDDEN, false);

  const hideBanner = () => {
    setBannerHidden(true);
  };

  const stepsMeta: GetStartedStepsMeta = {
    added_resource_integration: {
      step: 1,
      src: '/static/get-started/connect-integration.png',
      description:
        'Choose the wanted integration from the catalog. [Learn more](https://docs.apono.io/docs/integrate-with-apono)',
      label: 'Connect integration',
      to: routes.Catalog.path,
    },
    added_idp_integration: {
      step: 2,
      src: '/static/get-started/connect-idp.png',
      description:
        "Connect Apono to your IDP to manage access based on your organization's existing users and groups. [Learn more](https://docs.apono.io/docs/identity-provider-integrations)",
      label: 'Connect Identity Provider',
      to: `${routes.Catalog.path}?search=Identity Provider`,
    },
    added_access_flow: {
      step: 3,
      src: '/static/get-started/create-access-flow.png',
      description:
        'Create your first access flow! Choose from the access flow recommendations below or create your own customized access flow. [Learn more](https://docs.apono.io/docs/access-flow)',
      label: 'Create your own Access flow',
      to: routes.AddAccessFlow.path,
    },
    added_slack_integration: {
      step: 4,
      src: '/static/get-started/connect-slack.png',
      description:
        'Add the Apono Slack app to request, approve and grant access. [Learn more](https://docs.apono.io/docs/slack)',
      label: 'Connect slack app',
      to: generatePath(routes.AddIntegration.path, { type: 'slack' }),
    },
  };

  return { session, stepsMeta, bannerHidden, hideBanner, finishOnboarding };
}

export interface GetStartedProps {
  isBanner?: boolean;
}

export function GetStarted({ isBanner }: GetStartedProps) {
  const { session, stepsMeta, hideBanner, finishOnboarding, bannerHidden } = useGetStarted();
  const { isEnabled: isOn } = useFlagFixed(Flag.NEW_UI_ON_BOARDING);

  if (!isOn || !session?.onboarding?.active) {
    return <></>;
  }

  if (!isBanner) {
    return <GetStartedHero meta={stepsMeta} steps={session.onboarding.steps} onHide={finishOnboarding} />;
  }

  if (bannerHidden) {
    return <></>;
  }

  return (
    <PageBanner>
      <GetStartedBanner meta={stepsMeta} steps={session.onboarding.steps} onHide={hideBanner} />
    </PageBanner>
  );
}
