import { QueryIntegrationResourcesFilterOptionsArgs } from '@api/gql/graphql';
import { IntegrationsQueryKeys } from '@Integrations/services/queryKeys';
import { FrontierService } from '@api';
import { resourceTypesFilterOptions, statusesFilterOptions, tagsFilterOptions } from './gql';
import { useQuery } from '@tanstack/react-query';

export interface FilterOptionsHookProps {
  args: QueryIntegrationResourcesFilterOptionsArgs;
  enabled: boolean;
}

export function useResourceTypesFilterOptions({ args, enabled }: FilterOptionsHookProps) {
  const fetchMethod = () => FrontierService.request(resourceTypesFilterOptions, args);
  return useQuery([IntegrationsQueryKeys.ListResourceTypesFilterOptions, JSON.stringify(args)], fetchMethod, {
    enabled,
  });
}

export function useStatusesFilterOptions({ args, enabled }: FilterOptionsHookProps) {
  const fetchMethod = () => FrontierService.request(statusesFilterOptions, args);
  return useQuery([IntegrationsQueryKeys.ListStatusesFilterOptions, JSON.stringify(args)], fetchMethod, {
    enabled,
  });
}

export function useTagsFilterOptions({ args, enabled }: FilterOptionsHookProps) {
  const fetchMethod = () => FrontierService.request(tagsFilterOptions, args);
  return useQuery([IntegrationsQueryKeys.ListTagsFilterOptions, JSON.stringify(args)], fetchMethod, {
    enabled,
  });
}
