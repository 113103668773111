import { ReactNode } from 'react';
import { Avatar, Checkbox, MenuItem, Radio, Stack, Typography } from '@mui/material';
import { AnalyticsTriggerElementProps } from '@common/types';

export interface ListFilterSelectItemProps {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
  type?: 'checkbox' | 'radio';
}

export function isListFilterSelectItemProps(props: ListFilterSelectItemProps): props is ListFilterSelectItemProps {
  return props.type === 'checkbox' || props.type === 'radio';
}

export default function ListFilterSelectItem({
  label,
  icon,
  onClick,
  selected = false,
  disabled = false,
  type = 'checkbox',
  ...analyticsProps
}: ListFilterSelectItemProps & AnalyticsTriggerElementProps) {
  const RadioOrCheck = type === 'checkbox' ? Checkbox : Radio;
  const iconElem = icon ? typeof icon === 'string' ? <Avatar src={icon} className="size-20" /> : icon : undefined;

  return (
    <MenuItem onClick={onClick} disabled={disabled} component="div" data-testid="list-filter-select-item">
      <Stack direction="row" alignItems="center" spacing={1} {...analyticsProps}>
        <RadioOrCheck checked={selected} size="small" sx={{ padding: 0 }} {...analyticsProps} />
        {iconElem}
        <Typography variant="inherit">{label}</Typography>
      </Stack>
    </MenuItem>
  );
}
