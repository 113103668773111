import { MaterialIcon } from '@components';
import { Button } from '@mui/material';
import { routes } from '@routes';
import { generatePath, useNavigate } from 'react-router-dom';

export function AccessGraphButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(generatePath(routes.AccessGraph.path));
  };

  return (
    <Button onClick={handleClick} startIcon={<MaterialIcon symbol="account_tree" weight={800} />}>
      See As Graph
    </Button>
  );
}
