import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  MaterialIcon,
  SearchFilterFieldSelect,
  SearchFilterFieldSelectOption,
  SearchFilterItem,
  SearchFilters,
  SearchFilterValueSelect,
  SearchFilterValueSelectOption,
} from '@components';
import { Button } from '@mui/material';

import { useGetActiveAccessFilterOptions, useGetActiveAccessFilterTypes } from '@api';
import { QueryParams } from '@utils';

export const SP_FILTER_PREFIX = 'filter.';

interface SelectedFilter {
  key: number;
  type?: string;
  value?: string;
}

function spToFilters(sp: URLSearchParams): SelectedFilter[] {
  let key = 0;
  const initState: SelectedFilter[] = [];

  sp.forEach((spValue, spKey) => {
    if (!spKey.startsWith(SP_FILTER_PREFIX)) return;
    initState.push({
      key: key++,
      type: spKey.replace(SP_FILTER_PREFIX, ''),
      value: spValue,
    });
  });

  if (initState.length === 0) {
    initState.push({ key: 0 });
  }

  return initState;
}

function useActiveAccessFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<SelectedFilter[]>(spToFilters(searchParams));
  const [popoverOpened, setPopoverOpened] = useState(false);

  function togglePopover() {
    setPopoverOpened((prev) => !prev);
    if (!popoverOpened) {
      setFilters(spToFilters(searchParams));
    }
  }

  function closePopover() {
    setPopoverOpened(false);
  }

  const integrationId = useMemo(() => searchParams.get(QueryParams.integrationId), [searchParams]);

  function addFilter() {
    setFilters((prev) => {
      if (prev.length === 0) {
        return [{ key: 0 }];
      }

      const lastFilter = prev[prev.length - 1];
      return [...prev, { key: lastFilter.key + 1 }];
    });
  }

  function updateFilter(updatedFilter: SelectedFilter) {
    setFilters((prev) => prev.map((f) => (f.key === updatedFilter.key ? updatedFilter : f)));
  }

  function deleteFilter(key: number) {
    setFilters((prev) => prev.filter((f) => f.key !== key));
  }

  function clearAllFilters() {
    const lastFilter = filters[filters.length - 1];
    setFilters([{ key: lastFilter.key + 1 }]);

    const newSearchParams = new URLSearchParams(searchParams);
    searchParams.forEach((spValue, spKey) => {
      if (spKey.startsWith(SP_FILTER_PREFIX)) {
        newSearchParams.delete(spKey);
      }
    });
    setSearchParams(newSearchParams);
  }

  function applyFilters() {
    const newSearchParams = new URLSearchParams(searchParams);

    searchParams.forEach((spValue, spKey) => {
      if (spKey.startsWith(SP_FILTER_PREFIX)) {
        newSearchParams.delete(spKey);
      }
    });

    filters.forEach((filter) => {
      if (filter.type && filter.value) {
        newSearchParams.set(`${SP_FILTER_PREFIX}${filter.type}`, filter.value);
      }
    });

    setSearchParams(newSearchParams);

    closePopover();
  }

  const realFiltersCount = useMemo(() => {
    let count = 0;
    searchParams.forEach((spValue, spKey) => {
      if (spKey.startsWith(SP_FILTER_PREFIX)) {
        count++;
      }
    });
    return count;
  }, [searchParams]);

  return {
    integrationId,
    filters,
    realFiltersCount,
    popover: {
      isOpen: popoverOpened,
      toggleDropdown: togglePopover,
      closeDropdown: closePopover,
    },
    addFilter,
    updateFilter,
    deleteFilter,
    clearAllFilters,
    applyFilters,
  };
}

export function ActiveAccessFilters() {
  const {
    integrationId,
    filters,
    realFiltersCount,
    popover,
    addFilter,
    updateFilter,
    deleteFilter,
    clearAllFilters,
    applyFilters,
  } = useActiveAccessFilters();

  return (
    <SearchFilters
      popover={popover}
      trigger={
        <Button
          size="small"
          variant={realFiltersCount > 0 ? 'contained-filters' : 'outlined-filters'}
          startIcon={<MaterialIcon symbol="filter_list" />}
          endIcon={<MaterialIcon symbol="expand_more" />}
          component="div"
        >
          Filters{realFiltersCount > 0 && `/${realFiltersCount}`}
        </Button>
      }
      onNewItemClick={addFilter}
      onClearClick={clearAllFilters}
      onApplyClick={applyFilters}
    >
      {integrationId && (
        <ActiveAccessFiltersList
          integrationId={integrationId}
          filters={filters}
          updateFilter={updateFilter}
          deleteFilter={deleteFilter}
        />
      )}
    </SearchFilters>
  );
}

interface ActiveAccessFiltersListProps {
  integrationId: string;
  filters: SelectedFilter[];
  updateFilter: (updatedFilter: SelectedFilter) => void;
  deleteFilter: (key: number) => void;
}

function useActiveAccessFiltersList(integrationId: string, filters: SelectedFilter[]) {
  const { filterTypes } = useGetActiveAccessFilterTypes(integrationId);

  return useMemo(
    () =>
      filterTypes.map((type) => ({
        key: type,
        value: type,
        disabled: !!filters.find((f) => f.type === type),
      })),
    [filterTypes, filters],
  );
}

function ActiveAccessFiltersList({ integrationId, filters, updateFilter, deleteFilter }: ActiveAccessFiltersListProps) {
  const options = useActiveAccessFiltersList(integrationId, filters);

  return (
    <>
      {filters.map((filter, index) => (
        <ActiveAccessFilterItem
          key={filter.key}
          prefix={index === 0 ? 'Where' : 'And'}
          options={options}
          filter={filter}
          integrationId={integrationId}
          onUpdate={(value) => updateFilter(value)}
          onDelete={Object.keys(filters).length > 1 ? () => deleteFilter(filter.key) : undefined}
        />
      ))}
    </>
  );
}

interface ActivityFilterItemProps {
  options: SearchFilterFieldSelectOption<string>[];
  filter: SelectedFilter;
  onUpdate: (value: SelectedFilter) => void;
  integrationId: string;
  prefix?: string;
  onDelete?: () => void;
}

function useActiveAccessFilterItem({ filter, onUpdate }: ActivityFilterItemProps) {
  function handleTypeSelect(value: string) {
    onUpdate({ ...filter, type: value, value: undefined });
  }

  const handleValueSelect = (value?: string) => {
    onUpdate({ ...filter, value });
  };

  const operation = useMemo(() => {
    if (!filter.type) return undefined;
    return <>{'is'}</>;
  }, [filter]);

  return {
    handleTypeSelect,
    handleValueSelect,
    operation,
  };
}

function ActiveAccessFilterItem({
  options,
  filter: value,
  onUpdate,
  integrationId,
  prefix = 'Where',
  onDelete,
}: ActivityFilterItemProps) {
  const { operation, handleTypeSelect, handleValueSelect } = useActiveAccessFilterItem({
    options,
    filter: value,
    integrationId,
    onUpdate,
  });

  return (
    <SearchFilterItem
      prefix={<>{prefix}</>}
      fieldSelect={<SearchFilterFieldSelect options={options} value={value.type} onChange={handleTypeSelect} />}
      operation={operation}
      onDelete={onDelete}
    >
      {value.type && (
        <ActiveAccessFilterValueSelect
          integrationId={integrationId}
          type={value.type}
          value={value.value}
          onChange={handleValueSelect}
        />
      )}
    </SearchFilterItem>
  );
}

interface ActiveAccessFilterValueSelectProps {
  integrationId: string;
  type: string;
  value?: string;
  onChange: (value?: string) => void;
}

function useActiveAccessFilterValueSelect({ integrationId, type }: ActiveAccessFilterValueSelectProps) {
  const { filterOptions, isFilterOptionsFetched: isOptionsFetched } = useGetActiveAccessFilterOptions(
    integrationId,
    type,
  );

  const options: SearchFilterValueSelectOption[] = useMemo(
    () =>
      filterOptions.map((f) => ({
        value: f.id,
        label: f.value,
      })),
    [filterOptions],
  );

  return {
    isOptionsFetched,
    options,
  };
}

function ActiveAccessFilterValueSelect({ integrationId, type, value, onChange }: ActiveAccessFilterValueSelectProps) {
  const { options, isOptionsFetched } = useActiveAccessFilterValueSelect({
    integrationId,
    type,
    value,
    onChange,
  });
  return <SearchFilterValueSelect value={value} options={options} loading={!isOptionsFetched} onChange={onChange} />;
}
