import { getFragmentData } from '@api/gql';
import { Loader } from '@components';
import { useNewRequestsStats, newRequestsStatsFields } from '@DashboardV2/services/gql';
import { NewRequestsWidget } from '@DashboardV2/components/NewRequestsWidget';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { Typography } from '@mui/material';

export function NewRequests() {
  return (
    <WidgetCard title="New Requests" subtitle="Last 30 days" half>
      <NewRequestsBody />
    </WidgetCard>
  );
}

function NewRequestsBody() {
  const { data, isFetched } = useNewRequestsStats();

  const newRequests = getFragmentData(newRequestsStatsFields, data?.newRequestsStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (!newRequests) {
    return (
      <Typography variant="helperText" color="text.muted">
        No requests have been created in the last 30 days.
      </Typography>
    );
  }

  return <NewRequestsWidget data={newRequests} />;
}
