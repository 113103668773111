import { useTheme } from '@mui/material';

export function SuccessIllustration() {
  const { palette } = useTheme();
  const isDarkMode = palette.mode === 'dark';

  return (
    <img
      src={isDarkMode ? '/static/illustrations/grantee-dark.svg' : '/static/illustrations/grantee-light.svg'}
      alt="illustration"
    />
  );
}
