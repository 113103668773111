import { useCallback, useMemo } from 'react';
import { useListAccessBundles, AponoUserModel, AponoGroupModel, AttributeModel, AccessBundleAppModel } from '@api';

import { RESOURCE_TYPE_FILTER_PARAM } from '@common/constants';
import useFilterSearchParams from '@common/hooks/use-filter-search-params';

import { useAppListAccessFlows, useAppListAccessFlowsV2 } from '@AccessFlows/services/accessFlowsQueries';
import { useListUsers, useListGroups, useListAttributes } from '@AccessFlows/services/accountQueries';

import {
  APPROVAL_FILTER_PARAM,
  BUNDLE_FILTER_PARAM,
  GRANTEE_FILTER_PARAM,
  INTEGRATION_FILTER_PARAM,
  STATUS_FILTER_PARAM,
  LABEL_FILTER_PARAM,
  TRIGGER_TYPE_FILTER_PARAM,
} from '@AccessFlows/common/constants';

import { APPROVAL_FILTER_AUTO, APPROVAL_FILTER_MANUAL } from '@AccessFlows/organisms/AccessFlowsFilters/ApprovalFilter';
import { STATUS_FILTER_ACTIVE, STATUS_FILTER_INACTIVE } from '@AccessFlows/organisms/AccessFlowsFilters/StatusFilter';
import { LabelFilterOption } from '@AccessFlows/organisms/AccessFlowsFilters/LabelFilter';

import { AccessFlowsListItemProps } from '@AccessFlows/organisms/AccessFlowsList';
import { useLoadingProgress } from './shared';
import { CommonAccessFlowAppModel, GranteeItem, TargetItem } from '@AccessFlows/common/types';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { useRemappedIntegrations } from '@common/hooks/use-remapped-integrations';
import { FilterOption } from '@common/organisms/CommonFilters/GenericFilterMultiSelect';

function useRemappedUsers() {
  const { users, isFetched: isUsersFetched } = useListUsers();

  const remappedUsers = users.reduce<Record<string, AponoUserModel>>((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {});

  return {
    remappedUsers,
    isUsersFetched,
  };
}

function useRemappedGroups() {
  const { groups, isFetched: isGroupsFetched } = useListGroups();

  const remappedGroups = groups.reduce<Record<string, AponoGroupModel>>((acc, group) => {
    acc[group.id] = group;
    return acc;
  }, {});

  return {
    remappedGroups,
    isGroupsFetched,
  };
}

function useRemappedAttributes() {
  const { attributes, isFetched: isListAttributesFetched } = useListAttributes();

  const remappedAttributes = attributes.reduce<Record<string, AttributeModel>>((acc, attr) => {
    acc[attr.id] = attr;
    return acc;
  }, {});

  return {
    remappedAttributes,
    isListAttributesFetched,
  };
}

function useRemappedBundles() {
  const { accessBundles, isAccessBundlesFetched } = useListAccessBundles();

  const remappedBundles = accessBundles.reduce<Record<string, AccessBundleAppModel>>((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {});

  return {
    remappedBundles,
    isAccessBundlesFetched,
  };
}

export function useAccessFlowFetch() {
  const { remappedUsers, isUsersFetched } = useRemappedUsers();
  const { remappedGroups, isGroupsFetched } = useRemappedGroups();
  const { remappedAttributes, isListAttributesFetched } = useRemappedAttributes();
  const { remappedIntegrations, isIntegrationsFetched } = useRemappedIntegrations();
  const { remappedBundles, isAccessBundlesFetched } = useRemappedBundles();

  const granteeFilterOptionsMap = useMemo<Record<string, FilterOption>>(() => ({}), []);
  const integrationFilterOptionsMap = useMemo<Record<string, FilterOption>>(() => ({}), []);
  const resourceTypeFilterOptionsMap = useMemo<Record<string, FilterOption>>(() => ({}), []);
  const bundleFilterOptionsMap = useMemo<Record<string, FilterOption>>(() => ({}), []);
  const labelFilterOptionsMap = useMemo<Record<string, LabelFilterOption>>(() => ({}), []);

  const processAccessFlow = useCallback(
    (accessFlow: CommonAccessFlowAppModel) => {
      const granteesToFilterBy = new Set<string>();
      const integrationsToFilterBy = new Set<string>();
      const resourceTypesToFilterBy = new Set<string>();
      const bundlesToFilterBy = new Set<string>();
      const labelsToFilterBy = new Set<string>();
      const accessFlowSearchToFilterBy = new Set<string>([accessFlow.name]);
      const grantees: GranteeItem[] = [];
      const targets: TargetItem[] = [];

      for (const accessFlowGrantee of accessFlow.grantees) {
        granteesToFilterBy.add(accessFlowGrantee.id);

        let granteeFilterOption:
          | (FilterOption & {
              type: string;
            })
          | null = null;
        if (accessFlowGrantee.type === 'USER') {
          const user = remappedUsers[accessFlowGrantee.id];
          if (user) {
            granteeFilterOption = {
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
              searchHelper: `${user.first_name} ${user.last_name} ${user.email}`,
              type: accessFlowGrantee.type,
            };
          }
        } else if (accessFlowGrantee.type === 'GROUP') {
          const group = remappedGroups[accessFlowGrantee.id];
          if (group) {
            granteeFilterOption = {
              value: group.id,
              label: group.name,
              searchHelper: `${group.name} ${group.source_idp_type}`,
              type: accessFlowGrantee.type,
            };
          }
        } else if (accessFlowGrantee.type === 'CONTEXT_ATTRIBUTE') {
          const attribute = remappedAttributes[accessFlowGrantee.id];
          if (attribute) {
            granteeFilterOption = {
              value: attribute.id,
              label: attribute.value,
              searchHelper: `${attribute.value} ${attribute.category}`,
              type: accessFlowGrantee.type,
            };
          }
        } else {
          granteeFilterOption = {
            value: accessFlowGrantee.id,
            label: accessFlowGrantee.id,
            searchHelper: accessFlowGrantee.id,
            type: accessFlowGrantee.type,
          };
        }

        if (granteeFilterOption) {
          if (granteeFilterOption.searchHelper) {
            accessFlowSearchToFilterBy.add(granteeFilterOption.searchHelper);
          }

          grantees.push({
            accessFlowId: accessFlow.id,
            id: granteeFilterOption.value,
            name: granteeFilterOption.label,
            type: granteeFilterOption.type,
          });

          granteeFilterOptionsMap[granteeFilterOption.value] = granteeFilterOption;
        }
      }

      for (const target of accessFlow.targets) {
        if (target.integration) {
          integrationsToFilterBy.add(target.integration.integration_id);
          resourceTypesToFilterBy.add(target.integration.resource_type);
          accessFlowSearchToFilterBy.add(target.integration.resource_type);

          const integration = remappedIntegrations[target.integration.integration_id];
          if (!integration) continue;

          targets.push({
            icon: integration.config.icons.svg,
            target,
          });

          integrationFilterOptionsMap[integration.integration.id] = {
            value: integration.integration.id,
            label: integration.integration.name,
            icon: integration.config.icons.svg,
            searchHelper: `${integration.integration.name} ${integration.config.name}`,
          };

          const rtConfig = integration.config.resource_types[target.integration.resource_type];
          if (rtConfig) {
            resourceTypeFilterOptionsMap[rtConfig.type] = {
              value: rtConfig.type,
              label: rtConfig.display_name,
              icon: rtConfig.icons.svg,
            };
          }
        } else if (target.bundle) {
          bundlesToFilterBy.add(target.bundle.bundle_id);
          const bundle = remappedBundles[target.bundle.bundle_id];
          if (!bundle) continue;

          targets.push({
            target,
          });

          bundleFilterOptionsMap[bundle.id] = {
            value: bundle.id,
            label: bundle.name,
          };
        }
      }

      const approvalToFilterBy = new Set([
        accessFlow.approvers.length > 0 ? APPROVAL_FILTER_MANUAL : APPROVAL_FILTER_AUTO,
      ]);
      const statusToFilterBy = new Set([accessFlow.active ? STATUS_FILTER_ACTIVE : STATUS_FILTER_INACTIVE]);

      for (const label of accessFlow.labels) {
        labelsToFilterBy.add(label.name);
        labelFilterOptionsMap[label.name] = {
          value: label.name,
          label: label.name,
          color: label.color,
          searchHelper: label.name,
        };
      }

      return {
        toFilterBy: {
          granteesToFilterBy,
          integrationsToFilterBy,
          resourceTypesToFilterBy,
          bundlesToFilterBy,
          labelsToFilterBy,
          accessFlowSearchToFilterBy,
          approvalToFilterBy,
          statusToFilterBy,
        },
        grantees,
        targets,
      };
    },
    [
      bundleFilterOptionsMap,
      granteeFilterOptionsMap,
      integrationFilterOptionsMap,
      labelFilterOptionsMap,
      remappedAttributes,
      remappedBundles,
      remappedGroups,
      remappedIntegrations,
      remappedUsers,
      resourceTypeFilterOptionsMap,
    ],
  );

  return {
    remappedLoadingProgress: [
      isUsersFetched,
      isGroupsFetched,
      isListAttributesFetched,
      isIntegrationsFetched,
      isAccessBundlesFetched,
    ],
    filterOptions: {
      grantee: Object.values(granteeFilterOptionsMap),
      integration: Object.values(integrationFilterOptionsMap),
      resourceType: Object.values(resourceTypeFilterOptionsMap),
      bundle: Object.values(bundleFilterOptionsMap),
      label: Object.values(labelFilterOptionsMap),
    },
    processAccessFlow,
  };
}

function useListCommonAccessFlows(complexEnabled = false): {
  accessFlows: CommonAccessFlowAppModel[];
  isFetched: boolean;
} {
  const { accessFlows: accessFlowsV2, isFetched: isAccessFlowsFetchedV2 } = useAppListAccessFlowsV2(complexEnabled);
  const { accessFlows, isFetched: isAccessFlowsFetched } = useAppListAccessFlows(!complexEnabled);

  if (complexEnabled) {
    return {
      accessFlows: accessFlowsV2.map((accessFlow) => ({
        ...accessFlow,
        grantees: [],
        granteesV2: accessFlow.grantees,
      })),
      isFetched: isAccessFlowsFetchedV2,
    };
  }

  const list: CommonAccessFlowAppModel[] = [];
  for (const v1 of accessFlows) {
    list.push({
      ...v1,
      grantees: v1.grantees,
      granteesV2: {
        logical_operator: 'AND',
        attribute_filters: [],
      },
    });
  }

  return {
    accessFlows: list,
    isFetched: isAccessFlowsFetched,
  };
}

export default function useAccessFlowsFetch() {
  const { isEnabled: complexEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);
  const { accessFlows, isFetched: isAccessFlowsFetched } = useListCommonAccessFlows(complexEnabled);
  const { remappedLoadingProgress, filterOptions, processAccessFlow } = useAccessFlowFetch();

  const loadingProgress = useLoadingProgress([isAccessFlowsFetched, ...remappedLoadingProgress]);

  const { isFilteredBy, isSearchedBy } = useFilterSearchParams();

  const accessFlowsList: AccessFlowsListItemProps[] = [];
  for (const accessFlow of accessFlows) {
    const { toFilterBy, grantees, targets } = processAccessFlow(accessFlow);

    if (
      isFilteredBy(GRANTEE_FILTER_PARAM, toFilterBy.granteesToFilterBy) &&
      isFilteredBy(INTEGRATION_FILTER_PARAM, toFilterBy.integrationsToFilterBy) &&
      isFilteredBy(RESOURCE_TYPE_FILTER_PARAM, toFilterBy.resourceTypesToFilterBy) &&
      isFilteredBy(BUNDLE_FILTER_PARAM, toFilterBy.bundlesToFilterBy) &&
      isFilteredBy(APPROVAL_FILTER_PARAM, toFilterBy.approvalToFilterBy) &&
      isFilteredBy(STATUS_FILTER_PARAM, toFilterBy.statusToFilterBy) &&
      isFilteredBy(LABEL_FILTER_PARAM, toFilterBy.labelsToFilterBy) &&
      isFilteredBy(TRIGGER_TYPE_FILTER_PARAM, new Set([accessFlow.trigger_type])) &&
      isSearchedBy(toFilterBy.accessFlowSearchToFilterBy)
    ) {
      accessFlowsList.push({
        accessFlow,
        grantees,
        targets,
      });
    }
  }

  return {
    accessFlowsList,
    loadingProgress,
    filterOptions,
    totalAccessFlows: accessFlows.length,
  };
}
