import { useState } from 'react';
import { useAppQuery } from '../../api/query/use-app-query';
import { FrontierService } from '../../api/services';
import { rightSizingReportsQueryDocument } from './gql';
import { QueryRightSizingReportsArgs } from '@api/gql/graphql';

export function useAccessRightSizingReports(args: QueryRightSizingReportsArgs) {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(rightSizingReportsQueryDocument, args);
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery(['right-sizing-reports', JSON.stringify(args)], fetch, {
    keepPreviousData: true,
  });

  return { ...res, isFetched: isFirstTimeFetched };
}
