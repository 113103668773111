import { Accordion, AccordionDetails, AccordionSummary, Stack, styled, Typography } from '@mui/material';
import { MaterialIcon } from '@components';
import { ReactNode } from 'react';

const StyledAccordion = styled(Accordion)({
  elevation: 0,
  borderRadius: 1,
  '&:before': { display: 'none' },
});

export default function CustomAccessDetails({ title, content }: { title: ReactNode; content: ReactNode }) {
  return (
    <StyledAccordion disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<MaterialIcon symbol="expand_more" />}>
        <Stack direction="row" spacing={1} alignItems="center">
          <MaterialIcon symbol="chat_bubble" />
          <Typography variant="helperText">{title}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <Typography variant="body2" color="text.muted" whiteSpace="pre-line">
          {content}
        </Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
}
