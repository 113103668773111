import { PropsWithChildren, ReactNode } from 'react';
import { Box, Button, Link, Paper, Stack, styled, Typography } from '@mui/material';
import { Popover, UsePopoverProps } from '@components/Popover';
import { ClearTextButton } from '@components/ClearTextButton';

const SearchFiltersHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.palette.border.hover}`,
}));

const SearchFiltersBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const SearchFiltersActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

export interface SearchFiltersProps {
  trigger?: ReactNode;
  popover?: UsePopoverProps;
  onPopoverOpen?: () => void;
  onPopoverClose?: () => void;
  onNewItemClick?: () => void;
  onApplyClick?: () => void;
  onClearClick?: () => void;
}

export function SearchFilters({
  trigger,
  children,
  popover,
  onPopoverOpen,
  onPopoverClose,
  onNewItemClick,
  onApplyClick,
  onClearClick,
}: PropsWithChildren<SearchFiltersProps>) {
  return (
    <Popover
      testId="flow-form-select-filters"
      trigger={trigger}
      externalState={popover}
      onClose={onPopoverClose}
      onOpen={onPopoverOpen}
    >
      <Paper elevation={1}>
        <SearchFiltersHeader>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="caption">Advanced filters</Typography>
            {onClearClick && <ClearTextButton onClick={onClearClick} />}
          </Stack>
        </SearchFiltersHeader>
        <SearchFiltersBody>
          <table>
            <tbody>{children}</tbody>
          </table>
          <SearchFiltersActions>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              {onNewItemClick && <SearchFiltersNewItemButton onClick={onNewItemClick} />}
              {onApplyClick && <SearchFiltersApplyButton onClick={onApplyClick} />}
            </Stack>
          </SearchFiltersActions>
        </SearchFiltersBody>
      </Paper>
    </Popover>
  );
}

function SearchFiltersNewItemButton({ onClick }: { onClick: () => void }) {
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Typography variant="tooltip" component="div">
      <Link href="#" onClick={handleOnClick}>
        + Add new filter
      </Link>
    </Typography>
  );
}

function SearchFiltersApplyButton({ onClick }: { onClick: () => void }) {
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Button variant="contained" size="small" onClick={handleOnClick}>
      Apply
    </Button>
  );
}
