import { WebhookMethodAppModel } from '@api';
import { Typography, Box, styled } from '@mui/material';

const UrlCellWrapper = styled(Box)({
  maxWidth: 600,
  display: 'inline-block',
});

export interface UrlCellProps {
  method: WebhookMethodAppModel;
  url: string;
}

export default function UrlCell({ method, url }: UrlCellProps) {
  return (
    <UrlCellWrapper>
      <Typography variant="inputLabel" noWrap component="div">
        {method} {url}
      </Typography>
    </UrlCellWrapper>
  );
}
