import { generatePath, Link } from 'react-router-dom';
import { Avatar, Button, Grid, Stack, Typography } from '@mui/material';

import { IntegrationAppModel, IntegrationConfigAppModelV2, IntegrationStatus } from '@api';

import { AlertListContent } from '@organisms/AlertListContent/AlertListContent';
import { IntegrationResourceTypeItem } from '@components/IntegrationState/IntegrationResourcesTypeItem';
import { useMemo } from 'react';
import LoadingSentences from '@common/ui/LoadingSentences';

export interface IntegrationStatePaths {
  back: string;
  next?: string;
  skip?: string;
}

const loadingSentences = [
  'Connecting to the app...',
  'Fetching all resources and permissions...',
  'Wrapping up...',
  'Almost there...',
];

export function IntegrationState({
  integration,
  config,
  paths,
  successBasePath,
}: {
  integration: IntegrationAppModel;
  config: IntegrationConfigAppModelV2;
  paths: IntegrationStatePaths;
  successBasePath: string | undefined;
}) {
  switch (integration.status) {
    case IntegrationStatus.Active:
    case IntegrationStatus.Warning:
      return (
        <Stack spacing={4} alignItems="center">
          <IntegrationSuccess integration={integration} config={config} basePath={successBasePath} />
          <NavButtons integration={integration} paths={paths} />
        </Stack>
      );
    case IntegrationStatus.Error:
      return (
        <Stack spacing={4} alignItems="center">
          <IntegrationError integration={integration} />
          <NavButtons nextDisabled integration={integration} paths={paths} />
        </Stack>
      );
    default:
      return (
        <Stack spacing={4} alignItems="center">
          <LoadingSentences sentences={loadingSentences} direction="row" />
          {paths.skip && <NavButtons integration={integration} backDisabled nextDisabled paths={paths} />}
        </Stack>
      );
  }
}

function IntegrationSuccess({
  integration,
  config,
  basePath,
}: {
  integration: IntegrationAppModel;
  config: IntegrationConfigAppModelV2;
  basePath: string | undefined;
}) {
  const ResourceTypeSuggestions = useMemo(() => {
    const byResourceType: Record<
      string,
      {
        synced: number;
        failed: number;
      }
    > = {};

    for (const synced of integration?.entities_summary?.synced || []) {
      const resourceType = synced.sub_type?.toLowerCase();
      if (resourceType === undefined) continue;
      if (!byResourceType[resourceType]) byResourceType[resourceType] = { synced: 0, failed: 0 };
      byResourceType[resourceType].synced = synced.count;
    }

    for (const failed of integration?.entities_summary?.failed || []) {
      const resourceType = failed.sub_type?.toLowerCase();
      if (resourceType === undefined) continue;
      if (!byResourceType[resourceType]) byResourceType[resourceType] = { synced: 0, failed: 0 };
      byResourceType[resourceType].failed = failed.count;
    }

    return (
      <Grid
        container
        justifyContent="center"
        direction="row"
        spacing={2}
        flexWrap="wrap"
        columns={{ md: 9, sm: 6, xs: 3 }}
      >
        {Object.entries(byResourceType).map(([resourceType, { synced, failed }]) => (
          <Grid item key={resourceType} xs={3} minWidth="380px">
            <IntegrationResourceTypeItem
              key={resourceType}
              id={integration.id}
              type="resource"
              resourceType={config?.resource_types[resourceType].type}
              displayName={config?.resource_types[resourceType].display_name}
              textBtn="Access Flow"
              iconPath={config?.resource_types[resourceType].icons.svg}
              synced={synced}
              failed={failed}
              basePath={basePath}
            />
          </Grid>
        ))}
      </Grid>
    );
  }, [integration, config.resource_types, basePath]);

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center">
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar src={config.icons.svg} sx={{ width: 20, height: 20 }} variant="square" />
        <Typography variant="subtitle1">
          You successfully integrated Apono with <strong>{integration.name}</strong>
        </Typography>
      </Stack>
      <Typography variant="body2" color="text.muted" textAlign="center">
        Apono automatically discovers who has access to what in your <strong>{integration.name}</strong>. <br />
        {"That's"} the secret sauce :)
      </Typography>
      {ResourceTypeSuggestions}
    </Stack>
  );
}

function IntegrationError({ integration }: { integration: IntegrationAppModel }) {
  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1">There is an error with your integration. Fix the issue and try again.</Typography>
      <AlertListContent integrationId={integration.id} />
    </Stack>
  );
}

function NavButtons({
  integration,
  backDisabled,
  nextDisabled,
  paths,
}: {
  integration: IntegrationAppModel;
  backDisabled?: boolean;
  nextDisabled?: boolean;
  paths: IntegrationStatePaths;
}) {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        component={Link}
        to={generatePath(paths.back, { id: integration.id })}
        variant="text"
        disabled={backDisabled}
      >
        Back
      </Button>
      {paths.next && (
        <Button
          component={Link}
          to={`${generatePath(paths.next)}?fromIntegration=${integration.id}`}
          variant="contained"
          disabled={nextDisabled}
        >
          Automate Access Flows
        </Button>
      )}
      {paths.skip && (
        <Button component={Link} to={`${generatePath(paths.skip)}?type=${integration.type}`} variant="contained">
          Go To Integrations
        </Button>
      )}
    </Stack>
  );
}
