import { alertStatsFields } from '@AccessAlerts/services/gql';
import { getFragmentData } from '@api/gql';
import { AlertStatsOverTimeFragment } from '@api/gql/graphql';
import { Box, Stack, styled } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { aponoColors } from '@utils';
import { AlertWidgetLegend } from './AlertWidgetLegend';
import { AlertBarWidgetTooltip, getColor } from './AlertWidgetTooltip';

export interface AlertBarData {
  time: string;
  low: number;
  medium: number;
  high: number;
}

export const GraphWrapper = styled(Box)({
  height: '230px',
  width: '100%',

  'svg line': {
    'stroke-dasharray': '4 4',
    stroke: aponoColors.neutral[600],
  },
});

export function AlertStatsOverTimeWidget({ data }: { data: readonly AlertStatsOverTimeFragment[] }) {
  const didChangeYear =
    new Date(data[0].timestamp[1]).getFullYear() !== new Date(data[data.length - 1].timestamp[1]).getFullYear();
  const formatter = new Intl.DateTimeFormat(
    'en-GB',
    didChangeYear ? { day: 'numeric', month: 'short', year: 'numeric' } : { day: 'numeric', month: 'short' },
  );
  const chartData = [];
  for (const row of data) {
    const severityData = getFragmentData(alertStatsFields, row.stats);
    const formattedDate = formatter.format(new Date(row.timestamp[1]));
    chartData.push({
      time: formattedDate,
      low: severityData.find((d) => d.severity === 10)?.count || 0,
      medium: severityData.find((d) => d.severity === 20)?.count || 0,
      high: severityData.find((d) => d.severity === 30)?.count || 0,
      color: 'blue',
    });
  }

  return (
    <Stack direction="column" justifyContent="center" spacing={1}>
      <GraphWrapper>
        <ResponsiveBar
          data={chartData}
          keys={['low', 'medium', 'high']}
          indexBy="time"
          layout="vertical"
          padding={0.7}
          borderRadius={0}
          margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colorBy="id"
          colors={getColor}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            format: (value) => (value === Math.floor(value) ? value : ''),
          }}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
          }}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: '#FFFFFF',
                },
              },
            },
          }}
          tooltip={AlertBarWidgetTooltip}
          animate={false}
          enableLabel={false}
        />
      </GraphWrapper>
      <AlertWidgetLegend />
    </Stack>
  );
}
