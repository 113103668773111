import { PropsWithChildren } from 'react';

import { Box, Breakpoint, Container, styled } from '@mui/material';

const PageContentStyled = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(0, 3),
  position: 'relative',

  '&.hCentered': {
    display: 'flex',
    justifyContent: 'center',

    '&.vCentered': {
      alignItems: 'center',
    },
  },

  '&.full-height': {
    display: 'flex',
  },

  '&.padded': {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },

  '&.special-layout': {
    padding: theme.spacing(7, 3, 0, 11),
  },
}));

export interface PageContentProps {
  fullHeight?: boolean;
  hCentered?: boolean;
  vCentered?: boolean;
  padded?: boolean;
  specialLayout?: boolean;
  containerWidth?: false | Breakpoint;
}

export function PageContent({
  fullHeight,
  hCentered,
  vCentered,
  padded,
  containerWidth,
  specialLayout,
  children,
}: PropsWithChildren<PageContentProps>) {
  const classes = [];
  if (hCentered) classes.push('hCentered');
  if (vCentered) classes.push('vCentered');
  if (fullHeight) classes.push('full-height');
  if (padded) classes.push('padded');
  if (specialLayout) classes.push('special-layout');

  return (
    <PageContentStyled className={classes.join(' ')}>
      <Container maxWidth={containerWidth}>{children}</Container>
    </PageContentStyled>
  );
}
