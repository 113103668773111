import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IntegrationsService } from '@api/services';
import { QueryKeys } from '@api/query';
import { IntegrationConfigAppModelV2 } from '@api/apn-core';

export type IntegrationConfigWithSubIntegrations = IntegrationConfigAppModelV2 & {
  subIntegrationConfigs: Array<IntegrationConfigAppModelV2>;
};

export function useGetCatalogV2(enabled = true) {
  const queryClient = useQueryClient();

  const {
    data: integrationsConfigs,
    isFetched: isIntegrationsConfigsFetched,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.CatalogV2],
    queryFn: async () => {
      const { data } = await IntegrationsService.appListIntegrationConfigs();

      data.forEach((config) => {
        queryClient.setQueryData([QueryKeys.CatalogV2, config.type], config);
      });

      return data;
    },
    initialData: [],
    enabled,
  });

  return {
    integrationsConfigs,
    isIntegrationsConfigsFetched,
    refetch,
  };
}

export function useGetIntegrationConfigV2(type: string): {
  integrationConfig: IntegrationConfigWithSubIntegrations | undefined;
  isIntegrationConfigFetched: boolean;
} {
  const { data, isFetched: isIntegrationConfigFetched } = useQuery({
    queryKey: [QueryKeys.Catalog, type],
    queryFn: async () => {
      const { data: configs } = await IntegrationsService.appListIntegrationConfigs();
      const remapped: Record<string, IntegrationConfigAppModelV2> = configs.reduce((acc, config) => {
        acc[config.type] = config;
        return acc;
      }, {} as Record<string, IntegrationConfigAppModelV2>);

      const integrationConfig = remapped[type];
      if (!integrationConfig) {
        return { integrationConfig: undefined };
      }

      const subIntegrationConfigs: Array<IntegrationConfigAppModelV2> = [];
      if (integrationConfig?.sub_integration_types) {
        for (const sub_type of integrationConfig.sub_integration_types) {
          if (remapped[sub_type]) {
            subIntegrationConfigs.push(remapped[sub_type]);
          }
        }
      }

      return {
        integrationConfig: {
          ...integrationConfig,
          subIntegrationConfigs,
        },
      };
    },
  });

  return {
    integrationConfig: data?.integrationConfig,
    isIntegrationConfigFetched,
  };
}
