import { IntegrationsStatsFieldsFragment } from '@api/gql/graphql';
import { Box, Stack, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { aponoColors } from '@utils';
import styled from 'styled-components';
import DashboardLegend from './DashboardLegend';
import { DashboardBarWidgetTooltip } from './DashboardWidgetTooltip';

export const GraphWrapper = styled(Box)({
  height: '90px',
  width: '100%',

  'svg line': {
    'stroke-dasharray': '4 4',
    stroke: aponoColors.neutral[600],
  },
});

interface YourEnvironmentsChartProps {
  data: IntegrationsStatsFieldsFragment;
}

export default function YourEnvironmentsChart({ data }: YourEnvironmentsChartProps) {
  const chartData = [
    {
      type: 'Requested',
      connected: 0,
      inAccessFlows: 0,
      requested: data.requested,
      color: aponoColors.tertiary[400],
    },
    {
      type: 'In Access Flows',
      connected: 0,
      inAccessFlows: data.inAccessFlows,
      requested: 0,
      color: aponoColors.secondary[400],
    },
    {
      type: 'Connected',
      connected: data.connected,
      inAccessFlows: 0,
      requested: 0,
      color: aponoColors.primary[400],
    },
  ];

  return (
    <Stack direction="column" justifyContent="center" spacing={7} mt={2}>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          <Typography variant="h5">{data.connected}</Typography>
          <DashboardLegend color={aponoColors.primary[400]} label="Connected" />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          <Typography variant="h5">{data.inAccessFlows}</Typography>
          <DashboardLegend color={aponoColors.secondary[400]} label="In Access Flows" />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          <Typography variant="h5">{data.requested}</Typography>
          <DashboardLegend color={aponoColors.tertiary[400]} label="Requested" subLabel="(Last 30 Days)" />
        </Stack>
      </Stack>
      <GraphWrapper>
        <ResponsiveBar
          data={chartData}
          keys={['connected', 'inAccessFlows', 'requested']}
          indexBy="type"
          layout="horizontal"
          padding={0}
          borderRadius={2}
          margin={{ top: 0, right: 10, bottom: 20, left: 5 }}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colorBy="id"
          colors={(bar) => bar.data.color}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            format: (value) => (value === Math.floor(value) ? value : ''),
          }}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: '#555555',
                },
                text: {
                  fill: '#FFFFFF',
                },
              },
              legend: {
                text: {
                  fill: '#aaaaaa',
                },
              },
            },
          }}
          axisLeft={null}
          enableLabel={false}
          tooltip={DashboardBarWidgetTooltip}
          animate={false}
        />
      </GraphWrapper>
    </Stack>
  );
}
