import { Box, Paper, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function NoResults({ children }: { children?: ReactNode }) {
  return (
    <Box p={2} textAlign="center" component={Paper} elevation={0} data-testid="no-results-message" width="100%">
      <Typography variant="body2" component="em">
        {children}
      </Typography>
    </Box>
  );
}
