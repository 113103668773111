import { ReactNode, useEffect, useMemo, useReducer, useState } from 'react';

import { Avatar, Stack, styled, Tooltip, Typography } from '@mui/material';

import { DayOfWeek, Timeframe } from '@api';

import {
  DateTime,
  generateTimeOptions,
  getClosestTimeOption,
  getCurrentTimezoneOption,
  getTimeOptionFromSeconds,
  getTimezoneOptionFromValue,
  getTimezonesOptions,
  TimeItem,
  TimezoneItem,
  WeekDayName,
} from '@utils';
import { usePopoverContext } from '@components/Popover';
import { InlineSelect } from '@components/InlineSelect';

const WEEK_DAYS: DayOfWeek[] = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
];

const START_OF_WEEK = DateTime.startOfWeek();

const DEFAULT_TIMEFRAME = 60 * 60; // 1 hour

interface TimeframeProps {
  disabled?: boolean;
  value?: Timeframe;
  onChange: (value: Timeframe) => void;
}

const DayChip = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.active,

  '&:hover': {
    backgroundColor: theme.palette.background.hover,
  },

  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },

  '&.disabled': {
    color: theme.palette.text.secondary,
  },
}));

enum ActionType {
  SetDaysInWeek = 'SET_DAYS_IN_WEEK',
  SetStartTime = 'SET_START_TIME',
  SetEndTime = 'SET_END_TIME',
  SetTimezone = 'SET_TIMEZONE',
}

interface TimeframeReducerAction {
  type: ActionType;
  payload: Timeframe;
}

function initTimeframeReducer(state: Timeframe) {
  return state;
}

function timeframeReducer(state: Timeframe, action: TimeframeReducerAction): Timeframe {
  const newState = { ...state };

  function checkStartTimeDate() {
    if (newState.end_time_sec <= newState.start_time_sec && newState.days_in_week.length < 2) {
      newState.start_time_sec = newState.end_time_sec - DEFAULT_TIMEFRAME;
    }
  }

  function checkEndTimeDate() {
    if (newState.start_time_sec >= newState.end_time_sec && newState.days_in_week.length < 2) {
      newState.end_time_sec = newState.start_time_sec + DEFAULT_TIMEFRAME;
    }
  }

  switch (action.type) {
    case ActionType.SetDaysInWeek:
      newState.days_in_week = action.payload.days_in_week;
      checkEndTimeDate();
      break;
    case ActionType.SetStartTime:
      newState.start_time_sec = action.payload.start_time_sec;
      checkEndTimeDate();
      break;
    case ActionType.SetEndTime:
      newState.end_time_sec = action.payload.end_time_sec;
      checkStartTimeDate();
      break;
    case ActionType.SetTimezone:
      newState.time_zone = action.payload.time_zone;
      break;
  }

  return newState;
}

export function TimeframeForm({ disabled, value, onChange }: TimeframeProps) {
  const timeOptions = generateTimeOptions(true);
  const timezoneOptions = getTimezonesOptions();

  const closestTimeOption = getClosestTimeOption(timeOptions);
  const currentTimezoneOption = getCurrentTimezoneOption(timezoneOptions);

  const [selectedTimeframe, setSelectedTimeframe] = useReducer(
    timeframeReducer,
    value || {
      start_time_sec: closestTimeOption.value,
      end_time_sec: closestTimeOption.value + 3600,
      days_in_week: [],
      time_zone: currentTimezoneOption?.value,
    },
    initTimeframeReducer,
  );

  useEffect(() => {
    if (!disabled) onChange(selectedTimeframe);
  }, [disabled, onChange, selectedTimeframe]);

  const handleOnChange = (type: ActionType, payload: Partial<Timeframe>) =>
    setSelectedTimeframe({
      type,
      payload: { ...selectedTimeframe, ...payload },
    });

  return (
    <Stack direction="column" spacing={1.7} mb={2} mt={1}>
      <Typography variant="body2" component="span">
        Only on
      </Typography>
      <SelectDayInWeek
        disabled={disabled}
        value={selectedTimeframe.days_in_week}
        onChange={(v) => handleOnChange(ActionType.SetDaysInWeek, { days_in_week: v })}
      />
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.7}>
        <Typography variant="body2" color="text.muted">
          From
        </Typography>
        <SelectTime
          disabled={disabled}
          options={timeOptions}
          value={selectedTimeframe.start_time_sec}
          onChange={(v) => handleOnChange(ActionType.SetStartTime, { start_time_sec: v })}
        />
        <Typography variant="body2" color="text.muted">
          to
        </Typography>
        <SelectTime
          disabled={disabled}
          options={timeOptions}
          value={selectedTimeframe.end_time_sec}
          onChange={(v) => handleOnChange(ActionType.SetEndTime, { end_time_sec: v })}
        />
      </Stack>
      <SelectTimezone
        disabled={disabled}
        options={timezoneOptions}
        value={selectedTimeframe.time_zone}
        onChange={(v) => handleOnChange(ActionType.SetTimezone, { time_zone: v })}
      />
    </Stack>
  );
}

interface SelectDaysInWeekProps {
  disabled?: boolean;
  value: Array<DayOfWeek>;
  onChange: (value: Array<DayOfWeek>) => void;
}

function SelectDayInWeek({ disabled, value, onChange }: SelectDaysInWeekProps) {
  const [daysInWeek, setDaysInWeek] = useState(value || []);

  const handleDayClick = (day: DayOfWeek) => {
    const newDaysInWeek = daysInWeek.includes(day) ? daysInWeek.filter((d) => d !== day) : [...daysInWeek, day];
    setDaysInWeek(newDaysInWeek);
    onChange(newDaysInWeek);
  };

  const dayChips: ReactNode[] = [];
  for (let i = START_OF_WEEK; i < 7 + START_OF_WEEK; i++) {
    const day = WEEK_DAYS[i % 7];
    const classes = [];
    if (disabled) {
      classes.push('disabled');
    } else {
      if (daysInWeek.includes(day)) classes.push('active');
    }

    dayChips.push(
      <Tooltip key={day} title={WeekDayName[day]} arrow placement="top">
        <DayChip
          data-testid="select-day-in-week-day-chip"
          key={day}
          className={classes.join(' ')}
          onClick={() => handleDayClick(day)}
        >
          <Typography component="span" variant="helperText" color="inherit">
            {day[0]}
          </Typography>
        </DayChip>
      </Tooltip>,
    );
  }

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      {dayChips}
    </Stack>
  );
}

interface SelectTimeProps {
  disabled?: boolean;
  options: TimeItem[];
  value: number;
  onChange: (value: number) => void;
}

function SelectTime({ disabled, options, value, onChange }: SelectTimeProps) {
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();

  const handleOnChange = (e: React.ChangeEvent<unknown>, changeValue: TimeItem) => onChange(changeValue.value);

  const selectedOption = useMemo(
    () => getTimeOptionFromSeconds(value, options) || getClosestTimeOption(options),
    [options, value],
  );

  return (
    <InlineSelect
      data-testid="select-time"
      disabled={disabled}
      options={options}
      sx={{ width: 60 }}
      value={selectedOption}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      onChange={handleOnChange}
      onOpen={() => popoverDisableOnClose()}
      onClose={() => popoverEnableOnClose()}
      freeSolo={false}
      multiple={false}
      disableClearable
      textAlign="center"
    />
  );
}

interface SelectTimezoneProps {
  disabled?: boolean;
  options: TimezoneItem[];
  value: string;
  onChange: (value: string) => void;
}

function SelectTimezone({ disabled, options, value, onChange }: SelectTimezoneProps) {
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();

  const selectedTimezone = useMemo(
    () => getTimezoneOptionFromValue(value, options) || getCurrentTimezoneOption(options),
    [options, value],
  );

  const handleOnChange = (e: React.ChangeEvent<unknown>, timezoneItem: TimezoneItem) => onChange(timezoneItem.value);

  return (
    <InlineSelect
      data-testid="select-timezone"
      disabled={disabled}
      options={options}
      value={selectedTimezone}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      onChange={handleOnChange}
      onOpen={() => popoverDisableOnClose()}
      onClose={() => popoverEnableOnClose()}
      freeSolo={false}
      multiple={false}
      disableClearable
      fullWidth
    />
  );
}
