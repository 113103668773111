import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccessFlowsApiUpdateFallbackPolicyRequest, AccessFlowsService, QueryKeys } from '@api';

export function useGetFallbackPolicy() {
  const { data: fallbackPolicy, isFetched: isFallbackPolicyFetched } = useQuery({
    queryKey: [QueryKeys.FallbackPolicy],
    queryFn: async () => AccessFlowsService.getFallbackPolicy(),
    initialData: {
      approver: {},
      duration_in_sec: 0,
      is_active: false,
      active: false,
    },
  });

  return {
    fallbackPolicy,
    isFallbackPolicyFetched,
  };
}

export function useUpdateFallbackPolicy() {
  const queryClient = useQueryClient();
  const { mutate: updateFallbackPolicy, isLoading: isUpdateFallbackPolicyLoading } = useMutation({
    mutationFn: async (payload: AccessFlowsApiUpdateFallbackPolicyRequest) =>
      AccessFlowsService.updateFallbackPolicy(payload),
    onSuccess: async () => queryClient.invalidateQueries([QueryKeys.FallbackPolicy]),
  });

  return {
    updateFallbackPolicy,
    isUpdateFallbackPolicyLoading,
  };
}
