import { useQuery, useQueryClient } from '@tanstack/react-query';

import { AccessFlowsService } from '@api';

import { AccessFlowsQueryKeys, AccessFlowsQueryKeysV2 } from './queryKeys';

export function useAppListAccessFlows(enabled = true) {
  const queryClient = useQueryClient();

  const fetchMethod = async () => {
    const res = await AccessFlowsService.appListAccessFlows();

    res.data.forEach((accessFlow) => {
      queryClient.setQueryData([AccessFlowsQueryKeys.Details, accessFlow.id], accessFlow);
    });

    return res;
  };

  const {
    data: { data: accessFlows, filter_result: filterResult },
    refetch,
    ...rest
  } = useQuery([AccessFlowsQueryKeys.List], fetchMethod, {
    initialData: () => ({ data: [], filter_result: [] }),
    enabled,
  });

  return {
    accessFlows,
    filterResult,
    refetch,
    ...rest,
  };
}

// ---------------------------- V2 ----------------------------

export function useAppListAccessFlowsV2(enabled = true) {
  const queryClient = useQueryClient();

  const fetchMethod = async () => {
    const res = await AccessFlowsService.appListAccessFlowsV2();

    res.data.forEach((accessFlow) => {
      queryClient.setQueryData([AccessFlowsQueryKeysV2.Details, accessFlow.id], accessFlow);
    });

    return res;
  };

  const {
    data: { data: accessFlows, filter_result: filterResult },
    ...rest
  } = useQuery([AccessFlowsQueryKeysV2.List], fetchMethod, {
    initialData: () => ({ data: [], filter_result: [] }),
    enabled,
  });

  return {
    accessFlows,
    filterResult,
    ...rest,
  };
}

export function useGetAccessFlowV2(id: string, enabled = true) {
  const { data: accessFlow, ...rest } = useQuery({
    queryKey: [AccessFlowsQueryKeysV2.Details, id],
    queryFn: async () => AccessFlowsService.appGetAccessFlowV2({ id }),
    enabled,
  });

  return {
    accessFlow,
    ...rest,
  };
}
