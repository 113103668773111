import { useMemo, useState } from 'react';
import { Link } from '@mui/material';

import {
  InteractiveDropdown,
  InteractiveDropdownFooter,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
  Popover,
} from '@components';
import { Link as RouterLink } from 'react-router-dom';
import { AccessFlowAreaModel, GranteeModel, GranteeTypeModel, TriggerType } from '@api';
import { yup } from '@libs';
import { DropdownContextAttributeView, DropdownGroupView, DropdownUserView } from './common/DropdownIdpView';
import { DropdownEmailView } from './common/DropdownEmailView';
import { HumanReadableGrantee } from './HumanReadable';
import { routes } from '@routes';
import { useErrorText } from './common/use-error-text.hook';
import { useAccessFlowsPack, useGroups } from '@hooks';
import { useAttributeOptions } from './common/use-attributes-options';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { useOnboardingContext } from '@Onboarding/context';

export interface FlowFormSelectGranteesProps {
  triggerType?: TriggerType;
  template?: string;
  value?: GranteeModel[];
  error?: yup.ValidationError;
  onChange: (value: GranteeModel[]) => void;
}

const DEFAULT_PLACEHOLDER = 'someone';

function useFlowFormSelectGrantees({ template, onChange }: Pick<FlowFormSelectGranteesProps, 'template' | 'onChange'>) {
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();
  const { refetchPack } = useAccessFlowsPack();

  const [templatePlaceholder, setTemplatePlaceholder] = useState<string | undefined>(template);
  const [currentView, setCurrentView] = useState(0);
  const [granteeType, setGranteeType] = useState<string | undefined>();
  const { track } = useAnalyticsContext();

  const resetState = () => {
    setCurrentView(0);
    setGranteeType(undefined);
  };

  const handleGranteeTypeChange = (type: string) => {
    setGranteeType(type);
    setCurrentView(1);
  };

  const handleOnBackClick = () => resetState();

  const onOpen = () => {
    refetchPack();
    hideErrorText();
  };

  const onClose = () => {
    showErrorText();
    resetState();
  };

  const handleGranteeChange = (value: GranteeModel[]) => {
    onChange(value);
    if (templatePlaceholder) setTemplatePlaceholder(undefined);
    track(CustomAnalyticsEvents.SELECT_GRANTEE, {
      granteeType: value,
    });
  };

  return {
    templatePlaceholder,
    errorTextHidden,
    currentView,
    granteeType,
    handleGranteeTypeChange,
    handleGranteeChange,
    handleOnBackClick,
    onOpen,
    onClose,
  };
}

export function FlowFormSelectGrantees({ template, value, onChange, error }: FlowFormSelectGranteesProps) {
  const {
    templatePlaceholder,
    errorTextHidden,
    currentView,
    granteeType,
    handleGranteeTypeChange,
    handleGranteeChange,
    handleOnBackClick,
    onOpen,
    onClose,
  } = useFlowFormSelectGrantees({ template, onChange });

  return (
    <Popover
      testId="flow-form-select-grantees"
      trigger={
        <HumanReadableGrantee
          value={value || []}
          placeholder={templatePlaceholder || DEFAULT_PLACEHOLDER}
          placeholderMuted
          errorText={error?.message}
          hideErrorText={errorTextHidden}
        />
      }
      onOpen={onOpen}
      onClose={onClose}
    >
      <InteractiveDropdown multipleViews currentView={currentView} wide>
        <DropdownGranteeTypeView
          current={currentView === 0}
          value={granteeType}
          granteesValue={value}
          onChange={handleGranteeTypeChange}
          onClearAll={() => handleGranteeChange([])}
        />
        <DropdownGranteeView
          current={currentView === 1}
          granteeType={granteeType}
          value={value || []}
          onChange={handleGranteeChange}
          onBackClick={handleOnBackClick}
        />
      </InteractiveDropdown>
    </Popover>
  );
}

interface DropdownGranteeTypeViewProps {
  current: boolean;
  value?: string;
  granteesValue?: GranteeModel[];
  onChange: (value: string) => void;
  onClearAll?: () => void;
}

function useDropdownGranteeTypeView({ granteesValue }: Pick<DropdownGranteeTypeViewProps, 'granteesValue'>) {
  const { groups, doneOnce } = useGroups();
  const { attributeOptions, isAttributesFetched } = useAttributeOptions({
    flowArea: AccessFlowAreaModel.Grantee,
    values: granteesValue,
  });

  const options = useMemo(() => {
    function typedOption(type: string, label: string) {
      const count = granteesValue?.filter((v) => v.type === type).length || 0;
      if (count > 0) {
        label = `${label}/${count}`;
      }

      return {
        key: type,
        value: label,
        hasNextView: true,
        lookMultiple: true,
        lookSelected: count > 0,
      };
    }

    const selectOptions: InteractiveDropdownSelectOption<string>[] = [];

    selectOptions.push(typedOption(GranteeTypeModel.User, 'Users'));

    if (groups && groups.length > 0) {
      selectOptions.push(typedOption(GranteeTypeModel.Group, 'Groups'));
    }

    if (attributeOptions.length > 0) {
      selectOptions.push(...attributeOptions);
    }

    return selectOptions;
  }, [groups, attributeOptions, granteesValue]);

  return {
    options,
    isLoading: !(doneOnce && isAttributesFetched),
  };
}

function DropdownGranteeTypeView({
  current,
  value,
  granteesValue,
  onChange,
  onClearAll,
}: DropdownGranteeTypeViewProps) {
  const { isInOnboarding } = useOnboardingContext();
  const { options, isLoading } = useDropdownGranteeTypeView({
    granteesValue,
  });

  const linkOptions = useMemo(() => {
    if (isInOnboarding) return [];
    return [
      <Link to={routes.Catalog.path} key="add-idp" component={RouterLink}>
        + Add Identity Provider
      </Link>,
    ];
  }, [isInOnboarding]);

  if (isLoading) {
    return <InteractiveDropdownView current loading />;
  }

  return (
    <InteractiveDropdownView
      current={current}
      header={<InteractiveDropdownHeader title="Select grantees" />}
      footer={<InteractiveDropdownFooter onClearAllClick={onClearAll} />}
    >
      <InteractiveDropdownSelect<string>
        options={options}
        value={value}
        onChange={onChange}
        hideEmptyState
        linkOptions={linkOptions}
      />
    </InteractiveDropdownView>
  );
}

interface DropdownGranteeViewProps {
  current: boolean;
  granteeType?: string;
  value: GranteeModel[];
  onChange: (value: GranteeModel[]) => void;
  onBackClick?: () => void;
}

function DropdownGranteeView({ current, granteeType, onBackClick, value, onChange }: DropdownGranteeViewProps) {
  const [granteeTypeModel, granteeTypeCategoryKey] = useMemo(() => {
    if (!granteeType) return [undefined, undefined];

    const parts = granteeType.split('/');
    return [parts[0] as GranteeTypeModel, parts.length > 1 ? parts[1] : undefined];
  }, [granteeType]);

  const handleChange = (type: GranteeTypeModel, typeValue: string[]) => {
    const filteredByType = value.filter((v) => v.type !== type);
    const newValue = filteredByType.concat(typeValue.map((v) => ({ type, id: v })));
    onChange(newValue);
  };

  const commonProps = {
    current,
    onBackClick,
    value: value.filter((v) => v.type === granteeTypeModel).map((v) => v.id),
  };

  switch (granteeTypeModel) {
    case GranteeTypeModel.Group:
      return <DropdownGroupView {...commonProps} onChange={(val) => handleChange(GranteeTypeModel.Group, val)} />;
    case GranteeTypeModel.User:
      return <DropdownUserView {...commonProps} onChange={(val) => handleChange(GranteeTypeModel.User, val)} />;
    case GranteeTypeModel.ExternalEmail:
      return (
        <DropdownEmailView {...commonProps} onChange={(val) => handleChange(GranteeTypeModel.ExternalEmail, val)} />
      );
    case GranteeTypeModel.ContextAttribute:
      if (granteeTypeCategoryKey) {
        return (
          <DropdownContextAttributeView
            {...commonProps}
            contextKey={granteeTypeCategoryKey}
            onChange={(val) => handleChange(GranteeTypeModel.ContextAttribute, val)}
          />
        );
      }
  }
  return <></>;
}
