import { Typography } from '@mui/material';
import { LabeledTextField, Markdown } from '@components';
import { Controller, useFormContext } from 'react-hook-form';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';

const INFO = [
  'Apono doesn&apos;t store credentials and uses Hashicorp Vault. Please specify where the secret is located.',
].join('\n\n');

export function HashicorpSecretForm({ required, isViewOnly }: { required: boolean; isViewOnly?: boolean }) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <>
      <Typography variant="inputLabel" component="div" gutterBottom>
        <Markdown>{INFO}</Markdown>
      </Typography>
      <Controller
        control={control}
        name="secret_config.secret_engine"
        rules={{
          required: {
            value: required,
            message: `Secret engine is required`,
          },
        }}
        render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
          return (
            <LabeledTextField
              label="Secret engine"
              placeholder="Enter secret engine"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              disabled={isViewOnly}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="secret_config.path"
        rules={{
          required: {
            value: required,
            message: `Secret Path is required`,
          },
        }}
        render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
          return (
            <LabeledTextField
              label="Secret Path"
              placeholder="Enter secret path"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              disabled={isViewOnly}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }}
      />
    </>
  );
}
