import { styled, Chip, Avatar, Typography } from '@mui/material';

import { TargetItem } from '@AccessFlows/common/types';

import { HumanReadableAccessTarget } from '../organisms/FlowForm';

const TargetItemChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.disabled,
  maxWidth: '100%',

  '& strong': {
    color: theme.palette.text.primary,
  },
}));

const TargetItemAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.background.active,
}));

interface AccessFlowItemTargetChipProps {
  target: TargetItem;
  isActive?: boolean;
  onClick?: () => void;
}

export default function AccessFlowItemTargetChip({ target, isActive, onClick }: AccessFlowItemTargetChipProps) {
  return (
    <TargetItemChip
      label={
        <Typography variant="inherit" noWrap sx={{ maxWidth: '100%' }} component="div">
          <HumanReadableAccessTarget value={target.target} inline="strong" />
        </Typography>
      }
      variant={isActive ? 'filled' : 'outlined'}
      icon={target.icon ? <TargetItemAvatar src={target.icon} /> : undefined}
      clickable={onClick !== undefined}
      onClick={onClick}
    />
  );
}
