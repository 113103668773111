import { Stack } from '@mui/material';
import { EmptyState, UpgradeButton } from '@components';

export function ProState() {
  return (
    <Stack padding={10}>
      <EmptyState
        imgSrc={'/static/EmptyStateImages/pro-rocket-state.svg'}
        title="Upgrade to a paid plan!"
        body={`Apono Premium unlocks frictionless access management for Security, IT and DevOps teams. Enjoy more integrations and cloud resources, advanced admin settings, enhanced DevX and robust reporting and audit logs capabilities.`}
        action={<UpgradeButton />}
      />
    </Stack>
  );
}
