import { Icon, IconProps, styled } from '@mui/material';
import { useMemo } from 'react';

export interface MaterialIconProps {
  symbol: string;
  weight?: number;
  gray?: boolean;
  block?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const IconStyled = styled(Icon)(({ theme }) => ({
  '&.gray': {
    color: theme.palette.text.disabled,
  },
}));

export function MaterialIcon({ sx, weight, block, size, gray, symbol, ...restProps }: MaterialIconProps & IconProps) {
  const inlineSx = useMemo(() => {
    let styles = { ...sx, fontWeight: weight || 400 };
    if (block) {
      styles = {
        ...styles,
        display: 'block',
        textAlign: 'center',
      };
    }

    if (size === 'small') {
      styles = {
        ...styles,
        fontSize: '1.2rem !important',
      };
    }

    if (size === 'medium') {
      styles = {
        ...styles,
        fontSize: '1.5rem !important',
      };
    }

    if (size === 'large') {
      styles = {
        ...styles,
        fontSize: '2rem !important',
      };
    }

    return styles;
  }, [block, size, sx, weight]);

  return (
    <IconStyled className={gray ? 'gray' : ''} baseClassName="material-symbols-outlined" {...restProps} sx={inlineSx}>
      {symbol}
    </IconStyled>
  );
}
