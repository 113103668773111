import { useMemo } from 'react';
import { MenuList } from '@mui/material';

import { SelectDropdownItem, SelectDropdownItemProps, SelectDropdownOption } from './SelectDropdownItem';

export interface SelectDropdownMultipleProps<T> {
  options: SelectDropdownOption<T>[];
  value?: T[];
  onChange: (value: T[]) => void;
}

function useSelectDropdownMultiple<T>({ value, onChange, options }: SelectDropdownMultipleProps<T>) {
  const preparedOptions = useMemo(() => {
    const newOptions: SelectDropdownItemProps<T>[] = [];

    const handleOnClick = (option: T) => {
      if (value) {
        const newValue = value.includes(option) ? value.filter((item) => item !== option) : [...value, option];
        onChange(newValue);
      } else {
        onChange([option]);
      }
    };

    for (const option of options) {
      newOptions.push({
        option,
        type: 'checkbox',
        selected: value ? value.includes(option.value) : false,
        onClick: (optionVal: T) => handleOnClick(optionVal),
      });
    }

    return newOptions;
  }, [options, value, onChange]);

  return {
    preparedOptions,
  };
}

export function SelectDropdownMultiple<T>({ value, onChange, options }: SelectDropdownMultipleProps<T>) {
  const { preparedOptions } = useSelectDropdownMultiple({
    value,
    onChange,
    options,
  });

  return (
    <MenuList>
      {preparedOptions.map((option) => (
        <SelectDropdownItem key={option.option.value as string} {...option} />
      ))}
    </MenuList>
  );
}
