import { Page } from '@components';
import { routes } from '@routes';
import { Search } from '@organisms';

export function SearchPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: routes.Dashboard.label,
          href: routes.Dashboard.path,
        },
      ]}
      title="Search"
    >
      <Search />
    </Page>
  );
}
