import { useFlags } from '@unleash/proxy-client-react';
import { useMemo } from 'react';

export function useFlagFixed(name: string): {
  isEnabled: boolean;
  isUsable: boolean;
  isPremium: boolean;
} {
  const flags = useFlags();

  return useMemo(() => {
    const featureFlag = flags.find((flag) => flag.name === name);
    const featureUsable = !(
      featureFlag?.variant?.name === 'premium_icon' && featureFlag.variant.payload?.value === 'true'
    );
    const featureEnabled = featureFlag?.enabled ?? false;
    return {
      isEnabled: featureEnabled,
      isUsable: featureUsable,
      isPremium: featureEnabled && !featureUsable,
    };
  }, [flags, name]);
}
