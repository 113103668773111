import { ReactNode, PropsWithChildren } from 'react';
import { Chip, Stack, Typography } from '@mui/material';

import { FormSection } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSection';
import { FormSectionSummary } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionSummary';

interface GetMoreFormSectionProps {
  actions?: ReactNode;
  expanded?: boolean;
  isEdit?: boolean;
  onSummaryClick?: () => void;
}

export function GetMoreFormSection({
  actions,
  expanded,
  isEdit,
  onSummaryClick,
  children,
}: PropsWithChildren<GetMoreFormSectionProps>) {
  return (
    <FormSection
      expanded={expanded}
      summary={
        <FormSectionSummary
          titleIcon="/static/logo-only.svg"
          withIcon={!isEdit}
          title={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Get more with Apono</Typography>
              <Chip label="New" size="small" variant="outlined" color="warning" />
            </Stack>
          }
          onClick={onSummaryClick}
        />
      }
      actions={expanded && actions}
    >
      <>{children}</>
    </FormSection>
  );
}
