import { Context, useState } from 'react';

import { ListItem, ListItemButton, ListItemText, Radio, styled } from '@mui/material';

import { Timeframe } from '@api';

import { InteractiveDropdownSelectList, ListItemIconStyled } from '../InteractiveDropdownSelect';
import { TimeframeForm } from './TimeframeForm';

const ALWAYS = 'Always';
const ONLY_ON = 'Only on';

export const InteractiveDropdownTimeframeSelectList = styled(InteractiveDropdownSelectList)({
  width: '100%',
});

export interface InteractiveDropdownTimeframeSelectProps {
  value?: Timeframe;
  onChange: (value?: Timeframe) => void;
  context?: Context<{
    lockPopover: () => void;
    unlockPopover: () => void;
  }>;
}

function useInteractiveDropdownTimeframeSelect({ value, onChange }: InteractiveDropdownTimeframeSelectProps) {
  const [selected, setSelected] = useState<string | undefined>(value ? ONLY_ON : ALWAYS);
  const [timeframeValue, setTimeframe] = useState<Timeframe | undefined>(value);

  const handleSelectedChange = (innerValue: string) => {
    setSelected(innerValue);
    onChange(innerValue === ALWAYS ? undefined : timeframeValue);
  };

  const handleTimeframeChange = (tfValue: Timeframe) => {
    setTimeframe(tfValue);
    onChange(tfValue);
  };

  return {
    selected,
    handleSelectedChange,
    timeframeValue,
    handleTimeframeChange,
  };
}

export function InteractiveDropdownTimeframeSelect({ value, onChange }: InteractiveDropdownTimeframeSelectProps) {
  const { selected, handleSelectedChange, timeframeValue, handleTimeframeChange } =
    useInteractiveDropdownTimeframeSelect({
      onChange,
      value,
    });

  return (
    <InteractiveDropdownTimeframeSelectList dense disablePadding>
      <ListItem disablePadding>
        <ListItemButton
          data-testid="interactive-dropdown-timeframe-select-enabled"
          onClick={() => handleSelectedChange(ALWAYS)}
        >
          <ListItemIconStyled>
            <Radio edge="start" tabIndex={-1} value={ALWAYS} disableRipple checked={selected === ALWAYS} />
          </ListItemIconStyled>
          <ListItemText primary={ALWAYS} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          data-testid="interactive-dropdown-timeframe-select-only-on"
          onClick={() => handleSelectedChange(ONLY_ON)}
          alignItems="flex-start"
        >
          <ListItemIconStyled>
            <Radio edge="start" tabIndex={-1} value={ONLY_ON} disableRipple checked={selected === ONLY_ON} />
          </ListItemIconStyled>
          <TimeframeForm
            disabled={selected !== ONLY_ON}
            value={timeframeValue}
            onChange={(v) => handleTimeframeChange(v)}
          />
        </ListItemButton>
      </ListItem>
    </InteractiveDropdownTimeframeSelectList>
  );
}
