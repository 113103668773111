import { routes } from '@routes';
import { ErrorPage } from '@pages';
import { ReactNode, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { IntegrationState, IntegrationStatePaths, Loader } from '@components';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { ConfigInfo } from '@Integrations/organisms/IntegrationConfigForm/ConfigInfo';
import {
  getIntegrationDefaultValues,
  groupParamsByCategory,
  useIsInViewMode,
} from '@Integrations/IntegrationConfigForm/utils';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { SYNC_PARAM } from '@Integrations/organisms/EditConnectedIntegration/EditConnectedIntegration';
import { IntegrationConfigLayout } from '@Integrations/IntegrationConfigForm/layout/IntegrationConfigLayout';
import { IntegrationFormHeader } from '@Integrations/IntegrationConfigForm/components/IntegrationFormHeader';
import { FormSectionActions } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionActions';
import {
  IntegrationAppModel,
  IntegrationConfigWithSubIntegrations,
  IntegrationFamilyType,
  useAppGetIntegration,
  useUpdateIntegrationV2,
} from '@api';
import { DiscoveryFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/DiscoveryFormSection';
import { ConnectorFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/ConnectorFormSection';
import { SecretStoreFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/SecretStoreFormSection';
import { IntegrationConfigFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/IntegrationConfigFormSection';
import { ConnectedIntegrationLoader } from '@Integrations/pages/EditIntegrationPageV2';
import { GetMoreFormSection } from './FormSections/GetMoreFormSection';
import { CategoryConfigFormSection } from './FormSections/CategoryConfigFormSection';
import { CustomAccessDetailsFormSection } from './FormSections/CustomAccessDetailsFormSection';
import { IntegrationOwnerFormSection } from './FormSections/IntegrationOwnerFormSection';
import { ResourceOwnerFormSection } from './FormSections/ResourceOwnerFormSection';
import { InfoAlertForSubIntegration } from '@Integrations/IntegrationConfigForm/components/InfoAlertForSubIntegration';

export function EditIntegrationForm({ integrationId, paths }: { integrationId: string; paths: IntegrationStatePaths }) {
  const [searchParams] = useSearchParams();
  const integrationSync = useMemo(() => Boolean(searchParams.get(SYNC_PARAM)), [searchParams]);
  const {
    integration,
    integrationConfig: config,
    isIntegrationFetched,
  } = useAppGetIntegration(integrationId, integrationSync);

  if (!isIntegrationFetched) return <Loader />;

  if (!config || !integration) return <ErrorPage errorCode={404} errorMessage="Integration not found" />;

  if (config.family_type !== IntegrationFamilyType.ResourceIntegration) {
    return <ConnectedIntegrationLoader />;
  }

  if (integrationSync)
    return (
      <IntegrationState
        {...{
          paths,
          config,
          integration,
          successBasePath: routes.IntegrationResources.path,
        }}
      />
    );

  return <EditIntegrationFormContent {...{ config, integration, paths }} />;
}

function EditIntegrationFormContent({
  config,
  integration,
}: {
  config: IntegrationConfigWithSubIntegrations;
  integration: IntegrationAppModel;
}) {
  const { isEnabled: isResourceOwnersEnabled } = useFlagFixed(Flag.RESOURCE_OWNERS);
  const { isEnabled: isUserDeletionEnabled } = useFlagFixed(Flag.USER_CLEANUP_SETTING);
  const isViewMode = useIsInViewMode();

  const { updateIntegration, isUpdateIntegrationLoading, isIntegrationUpdated } = useUpdateIntegrationV2(
    integration.id,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isIntegrationUpdated) {
      searchParams.set(SYNC_PARAM, 'true');
      setSearchParams(searchParams);
    }
  }, [isIntegrationUpdated, searchParams, setSearchParams]);

  const { watch, ...methods } = useForm<IntegrationConfigFormPayload>({
    defaultValues: getIntegrationDefaultValues(integration, config),
  });

  const onSubmit = (data: IntegrationConfigFormPayload) => {
    if (data.secret_config) {
      const values = Object.values(data.secret_config).filter(Boolean);
      if (values.length === 1) delete data.secret_config;
    }

    if (data.custom_access_details_settings?.instructions_access_message === '')
      delete data.custom_access_details_settings.instructions_access_message;

    if (data.connected_sub_integration_resource_types?.every((csi) => csi.connected_resource_types.length === 0)) {
      delete data.connected_sub_integration_resource_types;
    } else if (
      data.connected_sub_integration_resource_types?.some((csi) => csi.connected_resource_types.length === 0)
    ) {
      data.connected_sub_integration_resource_types = data.connected_sub_integration_resource_types.filter(
        (csi) => csi.connected_resource_types.length > 0,
      );
    }

    updateIntegration(data);
  };

  const isFormDisabled =
    !methods.formState.isValid ||
    methods.formState.isSubmitting ||
    watch('connected_resource_types')?.length === 0 ||
    isViewMode;

  const hasSecretStore = config.supported_secret_types.length > 0;

  const { credentialsRotationsParams, userDeletionParams, otherParams } = groupParamsByCategory(config);

  return (
    <FormProvider watch={watch} {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <IntegrationConfigLayout sideInfo={<ConfigInfo info={config.info} links={config.links} />}>
          <IntegrationFormHeader config={config} title={integration.name} connectorId={watch('provisioner_id')} />
          {isViewMode && integration.parent_integration_id && (
            <InfoAlertForSubIntegration parentIntegrationId={integration.parent_integration_id} />
          )}
          <Stepper orientation="vertical" sx={{ '& .MuiStepConnector-root': { display: 'none' } }}>
            <Step key={0} expanded active>
              <StepWrap>
                <DiscoveryFormSection
                  isEdit
                  config={config}
                  expanded
                  connectedResourceTypes={integration.connected_resource_types}
                  isOnlyView={isViewMode}
                />
              </StepWrap>
            </Step>
            <Step key={1} expanded active>
              <StepWrap>
                <ConnectorFormSection config={config} expanded isEdit />
              </StepWrap>
            </Step>
            <Step key={2} expanded active>
              <StepWrap>
                <IntegrationConfigFormSection params={otherParams} expanded isEdit isViewOnly={isViewMode} />
              </StepWrap>
            </Step>
            {hasSecretStore && (
              <Step key={3} expanded active>
                <StepWrap>
                  <SecretStoreFormSection config={config} expanded isEdit />
                </StepWrap>
              </Step>
            )}
            <Step key={hasSecretStore ? 4 : 3} expanded active>
              <StepWrap>
                <GetMoreFormSection expanded isEdit>
                  <Stack spacing={2}>
                    {credentialsRotationsParams.length > 0 && (
                      <CategoryConfigFormSection
                        title="Credential Rotation"
                        icon="password"
                        categorizedParams={credentialsRotationsParams}
                        disabled={isViewMode}
                      />
                    )}
                    {isUserDeletionEnabled && userDeletionParams.length > 0 && (
                      <CategoryConfigFormSection
                        title="User Deletion"
                        icon="group_remove"
                        categorizedParams={userDeletionParams}
                        disabled={isViewMode}
                      />
                    )}
                    <CustomAccessDetailsFormSection
                      config={config}
                      divider={isResourceOwnersEnabled}
                      disabled={isViewMode}
                    />
                    {isResourceOwnersEnabled && (
                      <>
                        <IntegrationOwnerFormSection isViewOnly={isViewMode} />
                        <ResourceOwnerFormSection divider={false} disabled={isViewMode} />
                      </>
                    )}
                  </Stack>
                </GetMoreFormSection>
              </StepWrap>
            </Step>
            <Stack direction="column" px={1.5} py={2}>
              {!isViewMode && (
                <FormSectionActions
                  nextText="Update"
                  nextProps={{
                    type: 'submit',
                    loading: isUpdateIntegrationLoading,
                    disabled: isFormDisabled,
                  }}
                />
              )}
            </Stack>
          </Stepper>
        </IntegrationConfigLayout>
      </form>
    </FormProvider>
  );
}

function StepWrap({ children }: { children: ReactNode }) {
  return (
    <>
      <StepLabel />
      <StepContent>{children}</StepContent>
    </>
  );
}
