export enum UserRole {
  ADMIN = 'Admin',
  GRANTEE = 'Grantee',
}

export enum ConnectedIntegrationStatus {
  ACTIVE = 'Active',
  ERROR = 'Error',
  WARNING = 'Warning',
  REFRESHING = 'Refreshing',
  INITIALIZING = 'Initializing',
}

export enum ResourceIntegrationStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  Unmanaged = 'Unmanaged',
  Unreachable = 'Unreachable',
  Syncing = 'Syncing',
  Connecting = 'Connecting',
}

export enum ConnectorsStatus {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
}
