import { TableWrapper } from '@components';
import { Table as MuiTable, TableHead, TableRow, TableCell, TableBody, Skeleton } from '@mui/material';
import { DateTime } from '@utils';
import { Children, PropsWithChildren } from 'react';

interface AuditEventsTableContainerProps {
  isLoading?: boolean;
  isNotFetched?: boolean;
}

export default function AuditEventsTableContainer({
  isLoading,
  isNotFetched,
  children,
}: PropsWithChildren<AuditEventsTableContainerProps>) {
  const timeZone = DateTime.timeZone();
  const columns = ['User', `Time (UTC${timeZone})`, 'Integration', 'Event Type', 'Action', 'Target'];

  if (isNotFetched) {
    children = [...Array(3)].map((i) => {
      return (
        <TableRow key={i}>
          {columns.map((column) => (
            <TableCell key={column}>
              <Skeleton variant="text" width="100%" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  const arrayChildren = Children.toArray(children);

  return (
    <TableWrapper loading={isLoading}>
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{arrayChildren}</TableBody>
      </MuiTable>
    </TableWrapper>
  );
}
