import { Page } from '@components';
import { ProState } from '@components/Packages/ProState';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { AccessGraphMap } from '../AccessGraphMap';
import { useState } from 'react';
import { AccessFlowDrawer } from '../organisms/AccessFlowDrawer';
import { AccessRequestDrawer } from '@AccessGraph/organisms/AccessRequestDrawer';
import AccessGraphFilters from '@AccessGraph/organisms/AccessGraphFilters/AccessGraphFilters';
import { Stack } from '@mui/material';
import { usePrepareFilterOptions } from '@AccessGraph/services/use-prepare-filter-options';

export function AccessGraphPage() {
  const { isPremium } = useFlagFixed(Flag.APONO_ACCESS_GRAPH);

  const [accessFlowIds, setAccessFlowIds] = useState<string[]>([]);
  const [requestIds, setRequestIds] = useState<string[]>([]);

  const { attributeOptions, accessTargetOptions, isLoading } = usePrepareFilterOptions();

  return (
    <Page title="Access Graph" fullHeight>
      {isPremium ? (
        <ProState />
      ) : (
        <>
          <Stack direction="column" spacing={2} sx={{ height: '100%' }}>
            <AccessGraphFilters
              attributes={attributeOptions}
              accessTargets={accessTargetOptions}
              isLoading={isLoading}
            />
            <AccessGraphMap setAccessFlows={setAccessFlowIds} setRequestIds={setRequestIds} />
          </Stack>
          <AccessFlowDrawer
            open={accessFlowIds.length > 0}
            onClose={() => setAccessFlowIds([])}
            accessFlowsIds={accessFlowIds}
          />
          <AccessRequestDrawer
            open={requestIds.length > 0}
            onClose={() => setRequestIds([])}
            accessRequestIds={requestIds}
          />
        </>
      )}
    </Page>
  );
}
