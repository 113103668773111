import { TagAppModel } from '@api';

import { HumanReadableText } from '../common/HumanReadableText';

export interface HumanReadableResourcesCountProps {
  resourceType: string;
  matchers?: Partial<TagAppModel>[];
  excludes?: Partial<TagAppModel>[];
}

export function HumanReadableResourcesCount(props: HumanReadableResourcesCountProps) {
  const { matchers, excludes, resourceType } = props;

  if (matchers && matchers.length > 0) {
    return (
      <HumanReadableText
        value={`${matchers.length} ${resourceType}${matchers.length > 1 ? 's' : ''}`}
        inline="strong"
      />
    );
  }

  if (excludes && excludes.length > 0) {
    return (
      <HumanReadableText
        value={`any ${resourceType} except ${excludes.length} ${resourceType}${excludes.length > 1 ? 's' : ''}`}
        inline="strong"
      />
    );
  }

  return <HumanReadableText value={`any ${resourceType}`} inline="strong" />;
}
