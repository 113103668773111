import { styled, Tooltip } from '@mui/material';
import { MaterialIcon } from '@components';

interface SentenceWrapperProps {
  messages?: string[];
  children: JSX.Element;
}

const SpanStyledWrapper = styled('span')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  color: theme.palette.text.highlight,
  paddingRight: theme.spacing(1.85),
  '& .icon': {
    position: 'absolute',
    fontSize: '16px',
    top: '-2px',
    right: 0,
    color: theme.palette.text.highlight,
    height: '16px',
    width: '16px',
  },
}));

export const FilterHighlighterWrapper = ({ messages, children }: SentenceWrapperProps) => {
  if (!messages || messages.length === 0) return children;

  return (
    <Tooltip arrow title={messages.join('\n')}>
      <SpanStyledWrapper>
        {children} <MaterialIcon className="icon" symbol="magic_button" />
      </SpanStyledWrapper>
    </Tooltip>
  );
};
