import { LabeledSelectField } from '@components';

interface WebhookActionsFieldProps {
  value?: string;
  onChange: (value: string) => void;
}

export default function WebhookActionsField({ value, onChange }: WebhookActionsFieldProps) {
  return (
    <LabeledSelectField
      label="Actions"
      options={[
        { key: 'does_user_have_permission', value: 'does_user_have_permission' },
        { key: 'invoke_azure_function', value: 'invoke_azure_function' },
      ]}
      value={String(value)}
      onChange={(e) => onChange(String(e.target.value))}
    />
  );
}
