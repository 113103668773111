import { useQuery } from '@tanstack/react-query';
import { IntegrationsService, QueryKeys } from '@api';
import { IntegrationInfo } from '@AccessFlows/organisms/FlowForm';

export function useIntegrationResources(integration?: IntegrationInfo) {
  const {
    isFetched,
    refetch: fetchResources,
    data: integrationResources,
  } = useQuery(
    [QueryKeys.IntegrationResources, integration?.integrationId, integration?.resourceType],
    async () => {
      if (!integration) return;
      const { data } = await IntegrationsService.getIntegrationResourcesV2({
        id: integration.integrationId,
        resourceType: integration.resourceType,
      });
      return data;
    },
    {
      initialData: [],
    },
  );

  return { isFetched, fetchResources, integrationResources };
}

export function useIntegrationResourcesV2(integrationId: string, resourceType?: string) {
  const {
    isFetched,
    refetch: fetchResources,
    data: integrationResources,
  } = useQuery(
    [QueryKeys.IntegrationResources, integrationId, resourceType],
    async () => {
      const { data } = await IntegrationsService.appGetIntegrationResources({
        id: integrationId,
        resourceType,
      });
      return data;
    },
    {
      initialData: [],
    },
  );

  return { isFetched, fetchResources, integrationResources };
}
