import { useEffect, useMemo, useRef, useState } from 'react';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { useRemappedIntegrations } from '@common/hooks/use-remapped-integrations';
import { FilterProps } from './types';

export const INTEGRATION_FILTER_LABEL = 'Integration';
export const INTEGRATION_FILTER_PARAM = 'filter.integration_id';

export default function IntegrationFilterAsync({ opened, onClose, onOpen, selected, onSelect, onClear }: FilterProps) {
  const [enabled] = useState(() => selected.length > 0);

  const {
    remappedIntegrations,
    isIntegrationsFetched,
    refetchQuery: refetchListIntegrations,
  } = useRemappedIntegrations(enabled);

  const integrationOptions = useMemo(() => {
    return Object.values(remappedIntegrations).map((i) => {
      return {
        value: i.integration.id,
        label: i.integration.name,
        icon: i.config.icons.svg,
        searchHelper: `${i.integration.name} ${i.config.name}`,
      };
    });
  }, [remappedIntegrations]);

  const selectedOptions = useMemo(() => {
    const list: ListFilterOption[] = [];
    for (const option of integrationOptions) {
      if (selected.includes(option.value)) {
        list.push(option);
      }
    }
    return list;
  }, [integrationOptions, selected]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    !opened && onOpen && onOpen();
    setAnchorEl(event.currentTarget);

    if (!isIntegrationsFetched) {
      refetchListIntegrations();
    }
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isIntegrationsFetched) {
        refetchListIntegrations();
      }
    }
  }, [isIntegrationsFetched, opened, ref, refetchListIntegrations]);

  const isFetching = !isIntegrationsFetched && (opened || selected.length > 0);

  const open = (opened && Boolean(anchorEl)) || false;

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={INTEGRATION_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={integrationOptions}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${INTEGRATION_FILTER_LABEL} Filter`}
            loading={isFetching}
          />
        </Box>
      </Popover>
    </>
  );
}
