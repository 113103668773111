import { Avatar, Stack, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ProIconType, ProSizes, UpgradeWrapper } from '@components';
import { CustomAnalyticsEvents } from '@utils/analytics';

const IntegrationCatalogItemStyled = styled(Link)(({ theme }) => ({
  display: 'block',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    border: `1px solid ${theme.palette.background.button}`,
    backgroundColor: theme.palette.background.active,
  },
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

type NewIntegrationCatalogItemProps = {
  iconPath: string;
  name: string;
  link: string;
  label: string;
  isPremium: boolean;
};

export const IntegrationCatalogItem: FC<NewIntegrationCatalogItemProps> = ({
  iconPath,
  name,
  link,
  label,
  isPremium,
}) => {
  return (
    <UpgradeWrapper isPremium={isPremium} size={ProSizes.Large} withPopover iconType={ProIconType.IconWithText} overly>
      <IntegrationCatalogItemStyled
        to={link}
        data-trigger={CustomAnalyticsEvents.CATALOG_PICKED_INTEGRATION}
        data-props={JSON.stringify({
          integrationName: name,
          integrationLabel: label,
        })}
      >
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <Avatar src={iconPath} variant="square" sx={{ width: 24, height: 24 }} />
            <Typography variant="subtitle2">{name}</Typography>
          </Stack>
          <Typography variant="tooltip" color="text.disabled" noWrap>
            {label}
          </Typography>
        </Stack>
      </IntegrationCatalogItemStyled>
    </UpgradeWrapper>
  );
};
