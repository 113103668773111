import { useMemo, useState } from 'react';

import {
  MaterialIcon,
  SearchFilterFieldSelect,
  SearchFilterFieldSelectOption,
  SearchFilterItem,
  SearchFilters,
  SearchFilterValueSelect,
} from '@components';
import { Button } from '@mui/material';

import { ActivityRequestAppModel } from '@api';

export const SP_FILTER_PREFIX = 'filter.';

interface SelectedFilter {
  key: number;
  type?: string;
  value?: string;
}

interface RequestAccessUnitsFiltersValue {
  resourceTypes: string[];
}

interface RequestAccessUnitsFiltersProps {
  request: ActivityRequestAppModel;
  value: RequestAccessUnitsFiltersValue;
  onChange: (value: RequestAccessUnitsFiltersValue) => void;
}

export enum FilterTypes {
  ResourceTypes = 'resourceTypes',
}

function useRequestAccessUnitsFilters({ value, onChange }: RequestAccessUnitsFiltersProps) {
  const initFilters = useMemo(() => {
    const init: SelectedFilter[] = [];

    if (value.resourceTypes.length > 0) {
      init.push({ key: 0, type: FilterTypes.ResourceTypes, value: value.resourceTypes.join(',') });
    }

    if (init.length === 0) {
      init.push({ key: 0 });
    }

    return init;
  }, [value]);
  const [filters, setFilters] = useState<SelectedFilter[]>(initFilters);
  const [popoverOpened, setPopoverOpened] = useState(false);

  function togglePopover() {
    setPopoverOpened((prev) => !prev);
    if (!popoverOpened) {
      setFilters(initFilters);
    }
  }

  function closePopover() {
    setPopoverOpened(false);
  }

  function addFilter() {
    setFilters((prev) => {
      if (prev.length === 0) {
        return [{ key: 0 }];
      }

      const lastFilter = prev[prev.length - 1];
      return [...prev, { key: lastFilter.key + 1 }];
    });
  }

  function updateFilter(updatedFilter: SelectedFilter) {
    setFilters((prev) => prev.map((f) => (f.key === updatedFilter.key ? updatedFilter : f)));
  }

  function deleteFilter(key: number) {
    setFilters((prev) => prev.filter((f) => f.key !== key));
  }

  function clearAllFilters() {
    const lastFilter = filters[filters.length - 1];
    setFilters([{ key: lastFilter.key + 1 }]);
  }

  function applyFilters() {
    const newFilters: RequestAccessUnitsFiltersValue = {
      resourceTypes: [],
    };

    if (filters.length > 0) {
      const resourceTypesFilter = filters.find((f) => f.type === FilterTypes.ResourceTypes);
      if (resourceTypesFilter) {
        newFilters.resourceTypes = resourceTypesFilter.value?.split(',') || [];
      }
    }

    onChange(newFilters);

    closePopover();
  }

  const realFiltersCount = useMemo(() => {
    return value.resourceTypes.length || 0;
  }, [value]);

  return {
    filters,
    realFiltersCount,
    popover: {
      isOpen: popoverOpened,
      toggleDropdown: togglePopover,
      closeDropdown: closePopover,
    },
    addFilter,
    updateFilter,
    deleteFilter,
    clearAllFilters,
    applyFilters,
  };
}

export default function RequestAccessUnitsFilters({ request, value, onChange }: RequestAccessUnitsFiltersProps) {
  const { filters, realFiltersCount, popover, addFilter, updateFilter, deleteFilter, clearAllFilters, applyFilters } =
    useRequestAccessUnitsFilters({ request, value, onChange });

  return (
    <SearchFilters
      popover={popover}
      trigger={
        <Button
          size="small"
          variant={realFiltersCount > 0 ? 'contained-filters' : 'outlined-filters'}
          startIcon={<MaterialIcon symbol="filter_list" />}
          endIcon={<MaterialIcon symbol="expand_more" />}
          component="div"
        >
          Filters{realFiltersCount > 0 && `/${realFiltersCount}`}
        </Button>
      }
      onNewItemClick={addFilter}
      onClearClick={clearAllFilters}
      onApplyClick={applyFilters}
    >
      <RequestAccessUnitsFiltersList
        request={request}
        filters={filters}
        updateFilter={updateFilter}
        deleteFilter={deleteFilter}
      />
    </SearchFilters>
  );
}

interface RequestAccessUnitsFiltersListProps {
  request: ActivityRequestAppModel;
  filters: SelectedFilter[];
  updateFilter: (updatedFilter: SelectedFilter) => void;
  deleteFilter: (key: number) => void;
}

function RequestAccessUnitsFiltersList({
  request,
  filters,
  updateFilter,
  deleteFilter,
}: RequestAccessUnitsFiltersListProps) {
  const options = useMemo(
    () => [
      {
        key: FilterTypes.ResourceTypes,
        value: 'Resource Types',
        disabled: !!filters.find((f) => f.type === FilterTypes.ResourceTypes),
      },
    ],
    [filters],
  );

  return (
    <>
      {filters.map((filter, index) => (
        <RequestAccessUnitsFilterItem
          key={filter.key}
          request={request}
          prefix={index === 0 ? 'Where' : 'And'}
          options={options}
          filter={filter}
          onUpdate={(value) => updateFilter(value)}
          onDelete={Object.keys(filters).length > 1 ? () => deleteFilter(filter.key) : undefined}
        />
      ))}
    </>
  );
}

interface RequestAccessUnitsFilterItemProps {
  request: ActivityRequestAppModel;
  options: SearchFilterFieldSelectOption<string>[];
  filter: SelectedFilter;
  onUpdate: (value: SelectedFilter) => void;
  prefix?: string;
  onDelete?: () => void;
}

function RequestAccessUnitsFilterItem({
  request,
  options,
  filter,
  onUpdate,
  prefix = 'Where',
  onDelete,
}: RequestAccessUnitsFilterItemProps) {
  function handleTypeSelect(value: string) {
    onUpdate({ ...filter, type: value, value: undefined });
  }

  const handleValueSelect = (value?: string) => {
    onUpdate({ ...filter, value });
  };

  const operation = useMemo(() => {
    if (!filter.type) return undefined;
    return <>{'is'}</>;
  }, [filter]);

  const filterOptions = useMemo(() => {
    if (!filter.type) return [];

    switch (filter.type) {
      case FilterTypes.ResourceTypes:
        return request.aggregated_resource_types.map((rt) => ({ value: rt.type, label: rt.display_name }));
      default:
        return [];
    }
  }, [request, filter]);

  return (
    <SearchFilterItem
      prefix={<>{prefix}</>}
      fieldSelect={<SearchFilterFieldSelect options={options} value={filter.type} onChange={handleTypeSelect} />}
      operation={operation}
      onDelete={onDelete}
    >
      {filter.type && (
        <SearchFilterValueSelect value={filter.value} options={filterOptions} onChange={handleValueSelect} />
      )}
    </SearchFilterItem>
  );
}
