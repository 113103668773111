import { Box, Button, Stack, styled } from '@mui/material';

import { RouterLink } from '@libs';
import { Loader } from '@components';
import { routes } from '@routes';

import useAccessFlowsFetch from '@AccessFlows/hooks/use-access-flows-fetch';

import AccessFlowsList from '@AccessFlows/organisms/AccessFlowsList';
import AccessFlowsFilters from '@AccessFlows/organisms/AccessFlowsFilters';
import { useScrollToTop } from '@AccessFlows/hooks/shared';
import TableListTotal from '@common/components/TableListTotal';

const ListHeader = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export default function AccessFlowsPage() {
  useScrollToTop();

  const { accessFlowsList, loadingProgress, filterOptions, totalAccessFlows } = useAccessFlowsFetch();

  if (loadingProgress < 100) {
    return <Loader widthProgress value={loadingProgress} />;
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <ListHeader>
        <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <AccessFlowsFilters options={filterOptions} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TableListTotal totalResults={totalAccessFlows} displayedResults={accessFlowsList.length} />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              component={RouterLink}
              to={routes.AddAccessFlow.path}
            >
              Create Access Flow
            </Button>
          </Stack>
        </Stack>
      </ListHeader>
      <AccessFlowsList accessFlowsItems={accessFlowsList} />
    </Stack>
  );
}
