import { CUSTOM_COLORS } from '@AccessAlerts/organisms/AlertStatsOverTime';
import { Box, styled, Typography } from '@mui/material';
import { BarTooltipProps, ComputedDatum } from '@nivo/bar';
import { AlertBarData } from './AlertStatsOverTimeWidget';

const TooltipWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),
  padding: '5px',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(0.5),
}));

export const getColor = (bar: ComputedDatum<AlertBarData>) => {
  switch (bar.id) {
    case 'low':
      return CUSTOM_COLORS[2];
    case 'medium':
      return CUSTOM_COLORS[1];
    case 'high':
      return CUSTOM_COLORS[0];
    default:
      return 'blue';
  }
};

export const AlertBarWidgetTooltip: React.FC<
  BarTooltipProps<{ time: string; low: number; medium: number; high: number; color: string }>
> = (props) => {
  return (
    <TooltipWrapper>
      <div style={{ width: '12px', height: '12px', backgroundColor: getColor(props) }}></div>
      <Typography variant="tooltip">
        {props.indexValue}: {props.value}
      </Typography>
    </TooltipWrapper>
  );
};
