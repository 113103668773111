import { Loader, NoResults, StatusColumn, TableWrapper } from '@components';
import {
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AggregatedResourceTypesCell from './AggregatedResourceTypesCell';
import AggregatedPermissionsCell from './AggregatedPermissionsCell';
import AggregatedResourcesCell from './AggregatedResourcesCell';
import TablePagination from './TablePagination';
import useActivityTable from './hook';
import TriggerTypeChip from '@common/ui/TriggerTypeChip/TriggerTypeChip';
import { AccessUnitStatus, AccessUnitStatusSummaryAppModel } from '@api';
import StatusIcon from '@common/ui/StatusIcon';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { DateTime, Flag } from '@utils';
import { strToTriggerType } from '../../utils';
import { useFlagFixed } from '@hooks';
import { AccessStatusModel } from '@common/constants';

export default function ActivityTable() {
  const { isEnabled: showResourcesColumn } = useFlagFixed(Flag.SHOW_RESOURCES_COLUMN);
  const { requests, pagination, isFetched, isLoading, handleOnPageChange, handleAccessRequestClick, selectedRow } =
    useActivityTable();

  if (!isFetched) {
    return <Loader />;
  }

  if (requests.length === 0) {
    return <NoResults>No activity found</NoResults>;
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <TableWrapper loading={isLoading}>
        <MuiTable>
          <TableHead>
            <TableRow>
              <TableCell component="th" size="medium">
                Access ID
              </TableCell>
              <TableCell component="th" size="medium">
                Access Flow
              </TableCell>
              <TableCell component="th" size="medium">
                Grantee
              </TableCell>
              <TableCell component="th" size="medium">
                Resource Types
              </TableCell>
              {showResourcesColumn && (
                <TableCell component="th" size="medium">
                  Resources
                </TableCell>
              )}
              <TableCell component="th" size="medium" width="14%">
                Permissions
              </TableCell>
              <TableCell component="th" size="medium" width="15%">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow
                key={request.id}
                hover
                className="MuiTableRow-clickable"
                onClick={() => handleAccessRequestClick(request.id)}
                selected={selectedRow === request.id}
              >
                <TableCell component="td">
                  <PrimarySecondary
                    primary={request.friendly_id}
                    secondary={DateTime.fromUnixWithFormat(Number(request.created_date))}
                    icon={<TriggerTypeChip triggerType={strToTriggerType(request.trigger_type)} />}
                  />
                </TableCell>
                <TableCell component="td">
                  <PrimarySecondary primary={request.access_flow?.name ?? '-'} maxWidth={150} />
                </TableCell>
                <TableCell component="td">
                  <PrimarySecondary primary={request.user.name} secondary={request.user.email} />
                </TableCell>
                <TableCell component="td">
                  <AggregatedResourceTypesCell aggregatedResourceTypes={request.aggregated_resource_types} />
                </TableCell>
                {showResourcesColumn && (
                  <TableCell component="td">
                    <AggregatedResourcesCell
                      accessUnitsCount={request.access_units_count}
                      accessUnitsPreview={request.access_units_preview}
                      accessBundle={request.access_bundle}
                    />
                  </TableCell>
                )}
                <TableCell component="td">
                  <AggregatedPermissionsCell aggregatedPermissions={request.aggregated_permissions} />
                </TableCell>
                <TableCell component="td">
                  <StatusColumn
                    status={request.status}
                    last_sync_time={Number(request.updated_date)}
                    last_sync_ago={false}
                    prefix="Updated at"
                    icon={<ActivityStatusIcon status={request.status} total={request.access_units_summary.total} />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableWrapper>
      <TablePagination pagination={pagination} isLoading={isLoading} onChange={handleOnPageChange} />
    </Stack>
  );
}

function ActivityStatusIcon({ status, total }: { status: string; total: AccessUnitStatusSummaryAppModel[] }) {
  const failedRevoke = total.find((au) => au.status === AccessUnitStatus.FailedRevoking);
  const failedGrant = total.find((au) => au.status === AccessUnitStatus.FailedGranting);

  const noFailedAccessUnits = !failedRevoke && !failedGrant;

  if (noFailedAccessUnits && status !== AccessStatusModel.Failed && status !== AccessStatusModel.FAILED) {
    return <StatusIcon status={status} />;
  }

  return (
    <Tooltip
      title={
        noFailedAccessUnits ? (
          'Request failed'
        ) : (
          <ResourcesSummary failedRevokingCount={failedRevoke?.count} failedGrantingCount={failedGrant?.count} />
        )
      }
      placement="top"
      arrow
    >
      <div>
        <StatusIcon status={status} />
      </div>
    </Tooltip>
  );
}

function ResourcesSummary({
  failedRevokingCount,
  failedGrantingCount,
}: {
  failedRevokingCount?: number;
  failedGrantingCount?: number;
}) {
  return (
    <Stack spacing={0.5}>
      {failedRevokingCount && (
        <Typography variant="helperText">
          <strong>{failedRevokingCount}</strong> Resource{' '}
          <Typography variant="helperText" color="error">
            failed to revoke
          </Typography>
        </Typography>
      )}
      {failedGrantingCount && (
        <Typography variant="helperText">
          <strong>{failedGrantingCount}</strong> Resource{' '}
          <Typography variant="helperText" color="graph.warning">
            failed to grant
          </Typography>
        </Typography>
      )}
    </Stack>
  );
}
