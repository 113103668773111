import { Avatar, Chip, styled } from '@mui/material';
import { AttributeFilterAppModel } from '@api';

import HumanReadableAttributeValue from '@AccessFlows/organisms/FlowForm/HumanReadable/AttributeValue';
import HumanReadableAttributeOperator from '@AccessFlows/organisms/FlowForm/HumanReadable/AttributeOperator';
import { HumanReadableText } from '@AccessFlows/organisms/FlowForm';

const AdvancedGranteeChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.disabled,

  '& strong': {
    color: theme.palette.text.primary,
  },
}));

interface AccessFlowItemAdvancedGranteeChipProps {
  grantee: AttributeFilterAppModel;
  isActive?: boolean;
  onClick?: () => void;
}

export default function AccessFlowItemAdvancedGranteeChip({
  grantee,
  isActive,
  onClick,
}: AccessFlowItemAdvancedGranteeChipProps) {
  return (
    <AdvancedGranteeChip
      label={
        <>
          <strong>
            <HumanReadableText value={grantee.attribute_type.display_name} inline />
          </strong>{' '}
          <HumanReadableAttributeOperator operator={grantee?.operator} inline />{' '}
          <strong>
            <HumanReadableAttributeValue
              attributeValue={grantee?.attribute_value}
              matchingAttributes={grantee?.matching_attributes || []}
              inline
            />
          </strong>
        </>
      }
      variant={isActive ? 'filled' : 'outlined'}
      icon={grantee.integration ? <Avatar src={grantee.integration?.icons.svg} className="size-24" /> : undefined}
      clickable={onClick !== undefined}
      onClick={onClick}
    />
  );
}
