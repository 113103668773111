import { PropsWithChildren, ReactNode } from 'react';

import { Tooltip, Typography } from '@mui/material';

const VALUES_LIMIT = 5;

export interface TooltippedProps {
  values: ReactNode[];
  andPrefix?: boolean;
  inline?: boolean;
}

export function Tooltipped({ values, andPrefix, children }: PropsWithChildren<TooltippedProps>) {
  const prefix = andPrefix ? 'AND' : 'OR';

  const length = values.length;
  if (length > VALUES_LIMIT) {
    values = values.slice(0, VALUES_LIMIT);
    values.push(<strong> to {length - VALUES_LIMIT} more...</strong>);
  }

  const title = (
    <>
      {values.map((m, i) => (
        <Typography key={`m-${i}`} variant="body4" color="inherit" component="p">
          <strong>{prefix}</strong> {m}
        </Typography>
      ))}
    </>
  );

  return (
    <Tooltip arrow title={title}>
      <span>{children}</span>
    </Tooltip>
  );
}
