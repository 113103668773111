import { Box, Button, Typography } from '@mui/material';
import { AlertTypesItemGrid } from './AlertTypesItemGrid';
import { SeverityCell } from '@AccessAlerts/components/SeverityCell';
import { MaterialIcon } from '@components';
import { AlertStatsFieldsFragment } from '@api/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { routes } from '@routes';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function AlertTypesListItem({ alert }: { alert: AlertStatsFieldsFragment }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${routes.AccessAlerts.path}?filter.type=${alert.id}`);
  };
  return (
    <Box>
      <AlertTypesItemGrid
        alertType={<Typography variant="body2">{alert.label}</Typography>}
        severity={<SeverityCell severity={alert.severity} />}
        count={<Typography variant="body2">{alert.count}</Typography>}
        action={
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={handleClick}
            endIcon={<MaterialIcon symbol="east" weight={800} />}
            data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_VIEW_ALL_BY_TYPE_CLICKED}
            data-props={JSON.stringify({
              type: alert.id,
              typeName: alert.label,
            })}
          >
            More Details{' '}
          </Button>
        }
      />
    </Box>
  );
}
