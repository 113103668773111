import { ElementType, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { AgentViewModel, InstallationMethod, useConnectorToken, useGetConnectorInstallLink, useListAgents } from '@api';
import { Markdown, MaterialIcon } from '@components';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

import { InstallMethod } from './types';

const InstructionContainer = styled(Typography)<{
  component: ElementType;
}>({
  '& ol, ul': {
    margin: 0,
  },
});

function useInstallInstructions({ method, token }: InstallInstructionsProps) {
  const { installConnectorLink, isInstallConnectorLinkFetched: isFetched } = useGetConnectorInstallLink(method);

  const installConnectorLinkLabel = useMemo(() => {
    switch (method) {
      case InstallationMethod.GcpProjectCliGke:
        return 'GCP Project installation and connection in GKE (Kubernetes) using CLI';
      case InstallationMethod.GcpProjectCloudRun:
        return 'GCP Project installation and connection in Cloud Run using CLI';
      case InstallationMethod.GcpOrganizationCliGke:
        return 'GCP Organization installation and connection in GKE (Kubernetes) using CLI';
      case InstallationMethod.GcpOrganizationCloudRun:
        return 'GCP Organization installation and connection in Cloud Run using CLI';
      case InstallationMethod.GcpTerraformGke:
        return 'GCP installation and connection using Terraform';
      case InstallationMethod.AwsAccountCloudFormationEcs:
        return 'AWS installation and connection using Cloudformation';
      case InstallationMethod.AwsAccountTerraformEcs:
        return 'AWS installation and connection using Terraform';
      case InstallationMethod.AwsAccountTerraformEks:
        return 'AWS installation and connection using Terraform';
      case InstallationMethod.AwsAccountCliEks:
        return 'AWS installation and connection using CLI';
      case InstallationMethod.AwsAccountCloudFormationEcsPermissionless:
        return 'AWS installation using Cloudformation';
      case InstallationMethod.AwsAccountTerraformEcsPermissionless:
        return 'AWS installation using Terraform';
      case InstallationMethod.AwsOrganizationTerraformEcs:
        return 'AWS installation and connection using Terraform';
      case InstallationMethod.K8sTerraform:
        return 'Kubernetes installation and connection using Terraform';
      case InstallationMethod.K8sHelm:
        return 'Kubernetes installation and connection using Helm';
      case InstallationMethod.K8sTerraformPermissionless:
        return 'Kubernetes installation using Terraform';
      case InstallationMethod.K8sHelmPermissionless:
        return 'Kubernetes installation using Helm';
      case InstallationMethod.AzureSubscriptionTerraformCi:
        return 'Azure installation & connection using Terraform';
      case InstallationMethod.AzureSubscriptionCliCi:
        return 'Azure installation & connection using CLI';
      case InstallationMethod.AzureSubscriptionTerraformCiPermissionless:
        return 'Azure installation using Terraform';
      case InstallationMethod.AzureSubscriptionCliCiPermissionless:
        return 'Azure installation using CLI';
      default:
        return 'this installation';
    }
  }, [method]);

  const installationInstructions = useMemo(() => {
    if (
      method === InstallationMethod.AwsAccountCloudFormationEcs ||
      method === InstallationMethod.AwsOrganizationCloudFormationEcs
    ) {
      return `
1. [Open Cloud Formation](${installConnectorLink})
2. Review the parameters
3. Approve by clicking "Create Stack" at the bottom of the page
      `;
    }

    return `
1. Copy your token: \`${token}\`
2. Follow [${installConnectorLinkLabel}](${installConnectorLink}) guide
    `;
  }, [method, installConnectorLinkLabel, installConnectorLink, token]);

  return {
    isFetched,
    installationInstructions,
  };
}

interface InstallInstructionsProps {
  method: InstallationMethod;
  token: string;
}

function InstallInstructions({ method, token }: InstallInstructionsProps) {
  const { isFetched, installationInstructions } = useInstallInstructions({
    method,
    token,
  });

  if (!isFetched) {
    return <CircularProgress size={16} />;
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="subtitle1">Follow these steps to install connector</Typography>
      <InstructionContainer variant="body2" component="div">
        <Markdown>{installationInstructions}</Markdown>
      </InstructionContainer>
    </Stack>
  );
}

interface ConnectorsWatcherProps {
  onConnectorInstalled?: (connector: AgentViewModel) => void;
}

function useConnectorsWatcher({ onConnectorInstalled }: ConnectorsWatcherProps) {
  const { track } = useAnalyticsContext();
  const { connectors, isConnectorsFetched } = useListAgents(true);

  const [initialConnectors, setInitialConnectors] = useState<AgentViewModel[] | undefined>(undefined);
  const [newConnectors, setConnectorsWatcher] = useState<AgentViewModel[]>([]);

  useEffect(() => {
    if (Array.isArray(initialConnectors) || !isConnectorsFetched) return;
    setInitialConnectors(connectors);
  }, [connectors, isConnectorsFetched, initialConnectors]);

  useEffect(() => {
    if (!initialConnectors || connectors.length <= initialConnectors.length) return;
    const delta = connectors.filter((connector) => !initialConnectors.find((it) => it.agent_id === connector.agent_id));
    setConnectorsWatcher(delta);
  }, [connectors, initialConnectors, onConnectorInstalled]);

  useEffect(() => {
    if (!newConnectors.length) return;

    onConnectorInstalled?.(newConnectors[0]);

    track(CustomAnalyticsEvents.CONNECTOR_CONNECTED, {
      connectorName: newConnectors[0].name,
      connectorType: newConnectors[0].cloud_provider,
    });
  }, [newConnectors, onConnectorInstalled, track]);

  return {
    newConnectors,
  };
}

function ConnectorsWatcher({ onConnectorInstalled }: ConnectorsWatcherProps) {
  const { newConnectors } = useConnectorsWatcher({
    onConnectorInstalled,
  });

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="subtitle1">Searching for new connections</Typography>

      {newConnectors.length === 0 && <CircularProgress size={16} />}

      {newConnectors.map((item, index) => (
        <Stack key={index} direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
          <Typography color="success.main">
            <MaterialIcon symbol="check" fontSize="small" weight={700} />
          </Typography>
          <Typography variant="subtitle1" color="success.main">
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

export function LocalInstallInstructions({ token, noTitle }: { token: string; noTitle?: boolean }) {
  const [value, setValue] = useState(0);

  const instructions = useMemo(() => {
    if (value === 0) {
      return `
\`\`\`bash
bash <(curl -s https://apono-public.s3.amazonaws.com/local-connector/install.sh) --apono-token ${token}
\`\`\`

Make sure you have a configured AWS profile in your AWS CLI with these permissions: List and IAM to the AWS account.\n
For more information, see our [step-by-step guide](https://docs.apono.io/docs/getting-started#how-to-deploy-the-local-connector).
`;
    }

    return `
\`\`\`powershell
iex ([System.Text.Encoding]::UTF8.GetString((Invoke-WebRequest -Uri "https://apono-public.s3.amazonaws.com/local-connector/install.ps1" -UseBasicParsing).Content))
\`\`\`
When prompted, paste this token: **\`${token}\`**

Make sure you have a configured AWS profile in your AWS CLI with these permissions: List and IAM to the AWS account.\n
For more information, see our [step-by-step guide](https://docs.apono.io/docs/getting-started#how-to-deploy-the-local-connector).
`;
  }, [token, value]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      {!noTitle && <Typography variant="subtitle1">Run this one-liner</Typography>}
      <Box>
        <Tabs variant="fullWidth" value={value} onChange={(e, val) => setValue(val)}>
          <Tab label="MacOS" value={0} />
          <Tab label="Windows" value={1} />
        </Tabs>
        <InstructionContainer variant="body2" component="div">
          <Markdown>{instructions}</Markdown>
        </InstructionContainer>
      </Box>
    </Stack>
  );
}

interface NewConnectorsWatcherProps {
  method: InstallMethod;
  onConnectorInstalled?: (connector: AgentViewModel) => void;
}

export function NewConnectorsWatcher({ method, onConnectorInstalled }: NewConnectorsWatcherProps) {
  const { token, isConnectorsTokenFetched } = useConnectorToken();

  if (!isConnectorsTokenFetched || !token) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <CircularProgress size={16} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
      {method === InstallMethod.Local ? (
        <LocalInstallInstructions token={token} />
      ) : (
        <InstallInstructions method={method} token={token} />
      )}
      <ConnectorsWatcher onConnectorInstalled={onConnectorInstalled} />
    </Stack>
  );
}
