import { Stack, Typography } from '@mui/material';
import { Markdown } from '@components';

export function FormHeader({ name, description }: { name: string; description: string }) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Typography variant="h5" color="text.primary" component="p">
          {name}
        </Typography>
      </Stack>
      <Typography variant="subtitle2" color="text.filter" component="div">
        <Markdown>{description}</Markdown>
      </Typography>
    </Stack>
  );
}
