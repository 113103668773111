import { Avatar, FormControlLabel, FormControlLabelProps, Radio, Stack, Typography, styled } from '@mui/material';
import { ElementType, PropsWithChildren } from 'react';

const RadioBoxContainer = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.button}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 2),
  margin: 0,
  justifyContent: 'space-between',

  ':has(.Mui-checked)': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.active,
  },
}));

const RadioInput = styled(Radio)({
  padding: 0,
});

const LabelSimple = styled(Typography)<{
  component: ElementType;
}>(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const LabelComplexIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

const RadioBoxLabelComplexContainer = styled(Stack)(({ theme }) => ({
  maxWidth: theme.spacing(42),
  textAlign: 'center',
  padding: theme.spacing(1, 1, 2),
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export type RadioBoxProps = Omit<FormControlLabelProps, 'control'>;

export function RadioBox(props: RadioBoxProps) {
  return <RadioBoxContainer {...props} control={<RadioInput size="small" />} />;
}

export function RadioBoxLabelSimple({ children }: PropsWithChildren) {
  return (
    <LabelSimple variant="buttonSmall" component="div">
      {children}
    </LabelSimple>
  );
}

export interface RadioBoxLabelComplexProps {
  iconPath?: string;
  title: string;
  description?: string;
}

export function RadioBoxLabelComplex({ iconPath, title, description }: RadioBoxLabelComplexProps) {
  return (
    <RadioBoxLabelComplexContainer spacing={1}>
      {iconPath && <LabelComplexIcon src={iconPath} alt={title} variant="square" />}
      <Typography variant="buttonSmall" component="div">
        <strong>{title}</strong>
      </Typography>
      {description && (
        <Typography variant="inputLabel" component="div" color="text.filter">
          {description}
        </Typography>
      )}
    </RadioBoxLabelComplexContainer>
  );
}
