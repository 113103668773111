import { TagAppModel } from '@api';

import { HumanReadableText, HumanReadableTextProps } from '../common/HumanReadableText';
import { TagsType } from '@utils';
import { Skeleton } from '@mui/material';

export interface HumanReadableResourcesProps extends Omit<HumanReadableTextProps, 'value' | 'values'> {
  isLoading?: boolean;
  resourceType?: string;
  matchers?: Partial<TagAppModel>[];
  excludes?: Partial<TagAppModel>[];
}

export function HumanReadableResources(props: HumanReadableResourcesProps) {
  const { matchers: restrictors, excludes, resourceType, isLoading, ...rest } = props;

  if (isLoading && ((restrictors && restrictors?.length > 0) || (excludes && excludes?.length > 0))) {
    return <HumanReadableText placeholder={<Skeleton variant="text" width={100} />} placeholderMuted />;
  }

  const tags = [...(restrictors || []), ...(excludes || [])];

  const values: string[] = [];
  for (const tag of tags) {
    if (!tag.value) continue;

    let value = tag.value;
    if (tag.name !== TagsType.Name && tag.name !== TagsType.Id) {
      value = `${tag.name}:${value}`;
    }

    values.push(value);
  }

  if (excludes && excludes.length > 0) {
    values[0] = `any ${resourceType} except ${values[0]}`;
  }

  return <HumanReadableText values={values} {...rest} />;
}
