import { Link, styled } from '@mui/material';
import { isUrlExternal } from '@utils';

import { MaterialIcon } from '@components/MaterialIcon';
import { ReactNode } from 'react';

const LinkStyled = styled(Link)(({ theme }) => ({
  '&.external': {
    display: 'inline-block',
    position: 'relative',
    padding: `0 ${theme.spacing(2.5)} 0 0`,

    '& > span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      zIndex: 99,
    },
  },
}));

export function Alink({
  href,
  children,
  externalIcon,
}: {
  href?: string;
  children: ReactNode;
  externalIcon?: boolean;
}) {
  const isExternal = isUrlExternal(href || '', window.location.hostname);

  return (
    <LinkStyled
      href={href}
      className={externalIcon ? 'external' : ''}
      underline="hover"
      target={isExternal ? '_blank' : '_self'}
    >
      {children}
      {externalIcon ? <MaterialIcon symbol="open_in_new" fontSize="small" /> : null}
    </LinkStyled>
  );
}
