import { PropsWithChildren, ReactNode } from 'react';

import { Box, Drawer, styled } from '@mui/material';

const DEFAULT_WIDTH = 400;

const DrawerContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.drawer,
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DrawerFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 3),
}));

const DrawerBody = styled(Box)({
  flex: 1,
  overflow: 'auto',

  '&.no-scroll': {
    overflow: 'hidden',
  },
});

const DrawerContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  height: '100%',

  '&.no-padding': {
    padding: 0,
  },
}));

export interface AppDrawerProps {
  open: boolean;
  onClose: () => void;
  header?: ReactNode;
  headerBorder?: boolean;
  footer?: ReactNode;
  noScroll?: boolean;
  width?: number;
}

export function AppDrawer({
  open,
  onClose,
  header,
  headerBorder = false,
  footer,
  children,
  width,
  noScroll,
}: PropsWithChildren<AppDrawerProps>) {
  return (
    <Drawer
      anchor="right"
      open={open}
      variant="temporary"
      onClose={onClose}
      data-testid="app-drawer"
      ModalProps={{
        slotProps: {
          backdrop: {
            invisible: true,
          },
        },
      }}
    >
      <DrawerContainer sx={{ width: width || DEFAULT_WIDTH }}>
        {header && <DrawerHeader className={headerBorder ? 'bordered' : ''}>{header}</DrawerHeader>}
        <DrawerBody className={noScroll ? 'no-scroll' : ''}>
          <DrawerContent className={noScroll ? 'no-padding' : ''}>{children}</DrawerContent>
        </DrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContainer>
    </Drawer>
  );
}
