import { MaterialIcon } from '@components';
import { Stack, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { IconPropsColorOverrides } from '@mui/material/Icon';

interface MetricsBoxBodyProps {
  mainValue: string;
  timePeriod: string;
  changeValue?: number;
  changePercentage?: number;
  isPositiveTrendError?: boolean;
}

type IconColor = OverridableStringUnion<
  'inherit' | 'success' | 'error' | 'disabled' | 'action' | 'primary' | 'secondary' | 'info' | 'warning',
  IconPropsColorOverrides
>;

const SUCCESS = { icon: 'success' as IconColor, text: 'success.main' };
const ERROR = { icon: 'error' as IconColor, text: 'error' };

export default function MetricsBoxBody({
  mainValue,
  changeValue,
  changePercentage,
  timePeriod,
  isPositiveTrendError = true,
}: MetricsBoxBodyProps) {
  if (changeValue === undefined || changePercentage === undefined) {
    return <Typography variant="h5">{mainValue}</Typography>;
  }

  const isPositiveTrend = changeValue && changeValue > 0;
  const noChange = changeValue === 0;

  const colorNegativeTrend = isPositiveTrendError ? SUCCESS : ERROR;
  const colorPositiveTrend = isPositiveTrendError ? ERROR : SUCCESS;

  const iconColor = isPositiveTrend ? colorPositiveTrend.icon : colorNegativeTrend.icon;
  const iconSymbol = isPositiveTrend ? 'north_east' : 'south_west';

  const textColor = isPositiveTrend ? colorPositiveTrend.text : noChange ? 'text.default' : colorNegativeTrend.text;

  return (
    <Stack direction="column" spacing={2} alignItems="start">
      <Typography variant="h5">{mainValue}</Typography>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {!noChange && (
          <MaterialIcon
            symbol={iconSymbol}
            color={iconColor}
            size="small"
            data-testid={isPositiveTrend ? 'upward-trend' : 'downward-trend'}
          />
        )}
        <Typography variant="helperText" color={textColor}>
          {changeValue}
        </Typography>
        <Typography variant="helperText" color={textColor}>
          ({changePercentage}%)
        </Typography>
        <Typography variant="helperText" color="text.muted">
          {timePeriod}
        </Typography>
      </Stack>
    </Stack>
  );
}
