import { useEffect, useState } from 'react';

export default function useAnimatedLabel({
  visible = false,
  enabled = false,
  delay = 250,
}: {
  visible?: boolean;
  enabled?: boolean;
  delay?: number;
}) {
  const [labelVisible, setLabelVisible] = useState(!visible);

  useEffect(() => {
    if (!enabled) {
      setLabelVisible(!visible);
      return;
    }

    if (visible) {
      setLabelVisible(false);
    } else {
      const timeout = setTimeout(() => {
        setLabelVisible(true);
      }, delay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [delay, enabled, visible]);

  return labelVisible;
}
