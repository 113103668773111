import { PropsWithChildren, ReactNode } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  MenuProps as MenuPropsType,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps: Partial<MenuPropsType> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const FilledInputStyled = styled(FilledInput)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.filter,
  '&:before': {
    borderColor: theme.palette.text.dropdown,
  },
  '&.MuiInputBase-adornedEnd': {
    paddingRight: 0,
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: theme.spacing(4),
  },
  '& .MuiSvgIcon-root': { color: theme.palette.text.dropdown },
}));

export interface FilterSelectProps<T> {
  value: T[];
  onChange: (e: SelectChangeEvent<T[]>) => void;
  renderValue: (value: T[]) => ReactNode;
  onClear?: () => void;
  onClose?: () => void;
}

export function FilterSelect<T>({
  value,
  renderValue,
  onClear,
  onChange,
  onClose,
  children,
}: PropsWithChildren<FilterSelectProps<T>>) {
  const endAdornment =
    value.length > 0 && onClear ? (
      <InputAdornment position="end">
        <IconButton onClick={onClear} edge="end" size="small">
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    ) : undefined;

  const input = <FilledInputStyled hiddenLabel endAdornment={endAdornment} />;

  return (
    <FormControl variant="filled" fullWidth hiddenLabel>
      <Select
        value={value}
        multiple
        onChange={onChange}
        onClose={onClose}
        input={input}
        size="small"
        renderValue={renderValue}
        displayEmpty
        MenuProps={MenuProps}
      >
        {children}
      </Select>
    </FormControl>
  );
}
