export function intersection<T>(first: Array<T>, second: Array<T>): Array<T> {
  const s = new Set(second);
  return first.filter((item) => s.has(item));
}

export interface EnumItem {
  value: string;
  key: string;
}

export function enumToArray<E>(num: { [key: string]: E; [key: number]: E }): EnumItem[] {
  return Object.keys(num).map((key) => ({ value: num[key as never], key } as EnumItem));
}

export function isEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// a function that change string from kabab-case to Capital Case

export function kababToCapitalCase(str: string): string {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function splitStringToWords(text: string) {
  return text
    .split('_')
    .map((t) => capitalizeFirstLetter(t))
    .join(' ');
}
