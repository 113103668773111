import { aponoColors } from '@utils/colors';

import { SidebarSection } from './SidebarSection';
import { SidebarItem } from './SidebarItem';
import { PlanBanner } from '@components/PlanBanner/PlanBanner';
import { Stack } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export function Branding({ wide = false }: { wide?: boolean }) {
  return (
    <SidebarSection>
      <SidebarItem icon={!wide && <Logo fill={aponoColors.primary[400]} />}>
        {wide && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Brand fill={aponoColors.white} />
            <PlanBanner mini />
          </Stack>
        )}
      </SidebarItem>
    </SidebarSection>
  );
}

const SpecialLogo = styled.svg`
  .animated {
    animation: 'easter 2s';
  }
  @keyframes easter {
    0%,
    100% {
      transform: rotate(0deg);
      filter: hue-rotate(0deg);
    }
    25% {
      transform: rotate(180deg) scale(0.75) skew(30deg);
      filter: hue-rotate(90deg);
    }
    50% {
      transform: rotate(360deg) scale(2);
      filter: hue-rotate(360deg) drop-shadow(0 0 0.5rem #fff);
    }
    75% {
      transform: rotate(-180deg) scale(0.75) skew(-30deg);
      filter: hue-rotate(-180deg);
    }
  }
`;

function Logo({ fill }: { fill: string }) {
  const [easterEgg, setEasterEgg] = useState(0);
  const { track } = useAnalyticsContext();

  const handleEasterEgg = () => {
    if (easterEgg !== 10) {
      setEasterEgg(easterEgg + 1);
      track(CustomAnalyticsEvents.LOGO_EASTER_EGG);
    } else setTimeout(() => setEasterEgg(0), 2000);
  };

  return (
    <SpecialLogo
      style={easterEgg === 10 ? { animation: 'easter 2s' } : {}}
      onClick={handleEasterEgg}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 2C9.60342 1.99993 7.28635 2.77375 5.47095 4.18054C3.65555 5.58733 2.46257 7.53351 2.10927 9.66466C1.75596 11.7958 2.26583 13.9701 3.54603 15.7917C4.82623 17.6133 6.7916 18.9609 9.0842 19.5891C9.1807 19.6155 9.28493 19.6065 9.37396 19.5638C9.463 19.5212 9.52954 19.4485 9.55897 19.3617C9.58839 19.275 9.57828 19.1813 9.53085 19.1012C9.48342 19.0212 9.40257 18.9614 9.30608 18.9349C7.66711 18.4872 6.19874 17.639 5.06478 16.4848C3.93083 15.3306 3.17588 13.9158 2.88419 12.3984C2.59249 10.8809 2.77551 9.32043 3.41282 7.89103C4.05014 6.46164 5.1167 5.21955 6.49349 4.30334C7.87027 3.38713 9.50315 2.83283 11.2099 2.70229C12.9167 2.57175 14.6303 2.8701 16.1594 3.56406C17.6886 4.25801 18.9732 5.32028 19.87 6.63234C20.7667 7.9444 21.2403 9.45466 21.2379 10.9946C21.2304 12.7854 20.5827 14.5266 19.3904 15.9612C18.1981 17.3959 16.5244 18.448 14.6163 18.9622C14.5289 18.9857 14.4538 19.0365 14.4046 19.1056C14.3554 19.1747 14.3355 19.2574 14.3485 19.3385C14.3614 19.4197 14.4064 19.494 14.4752 19.5478C14.544 19.6017 14.6321 19.6315 14.7233 19.6318C14.76 19.6318 14.7964 19.627 14.8316 19.6176C16.8771 19.0698 18.676 17.9518 19.9688 16.4251C21.2928 14.8655 22.0069 12.9565 22 10.9946C21.9986 8.61029 20.9447 6.32393 19.0699 4.63752C17.195 2.95112 14.6523 2.00252 12.0002 2V2Z"
        fill={fill}
      />
      <path
        d="M12.0005 11.9099C12.4341 11.9099 12.8579 11.7943 13.2184 11.5778C13.5789 11.3612 13.8599 11.0534 14.0258 10.6933C14.1918 10.3332 14.2352 9.93691 14.1506 9.5546C14.066 9.1723 13.8572 8.82113 13.5506 8.54551C13.244 8.26988 12.8534 8.08218 12.4282 8.00613C12.0029 7.93009 11.5621 7.96912 11.1615 8.11828C10.761 8.26745 10.4186 8.52006 10.1777 8.84416C9.9368 9.16826 9.80823 9.5493 9.80823 9.93909C9.80858 10.4617 10.0397 10.9628 10.4507 11.3323C10.8618 11.7019 11.4192 11.9096 12.0005 11.9099V11.9099ZM13.0042 8.82308C13.1105 8.82308 13.2144 8.85142 13.3028 8.90452C13.3912 8.95761 13.46 9.03308 13.5007 9.12137C13.5414 9.20967 13.5521 9.30683 13.5313 9.40056C13.5106 9.49429 13.4594 9.5804 13.3842 9.64797C13.3091 9.71555 13.2133 9.76157 13.109 9.78022C13.0047 9.79886 12.8967 9.78929 12.7985 9.75272C12.7003 9.71615 12.6163 9.65421 12.5572 9.57475C12.4982 9.49529 12.4667 9.40186 12.4667 9.30629C12.4667 9.17814 12.5233 9.05523 12.6241 8.96461C12.7249 8.87399 12.8616 8.82308 13.0042 8.82308V8.82308Z"
        fill={fill}
      />
      <path
        d="M17.4374 11.8256C18.1611 11.6831 19.1436 11.8256 19.5742 12.0524C19.6539 12.094 19.7478 12.1082 19.8384 12.0923C19.9289 12.0763 20.0099 12.0313 20.0663 11.9657C20.1227 11.9 20.1506 11.8182 20.1448 11.7354C20.1391 11.6526 20.1001 11.5746 20.035 11.5157L12.2724 4.39222C12.2366 4.36026 12.1942 4.335 12.1475 4.31788C12.1008 4.30076 12.0508 4.29213 12.0004 4.29249C11.9011 4.29313 11.8062 4.32898 11.7362 4.39222L3.97358 11.5157C3.90855 11.5746 3.86952 11.6526 3.86378 11.7354C3.85803 11.8182 3.88595 11.9 3.94233 11.9657C3.99872 12.0313 4.07973 12.0763 4.17027 12.0923C4.26081 12.1082 4.3547 12.094 4.43447 12.0524C4.86368 11.8303 5.84754 11.6831 6.57124 11.8256C9.06327 12.3183 11.0482 16.1911 11.6293 21.6928C11.6388 21.777 11.6828 21.855 11.7527 21.9118C11.8226 21.9685 11.9134 21.9999 12.0076 21.9999C12.1018 21.9999 12.1926 21.9685 12.2625 21.9118C12.3324 21.855 12.3764 21.777 12.386 21.6928C12.9604 16.1875 14.9453 12.3147 17.4374 11.8256ZM12.0004 5.12119L13.7766 6.74297C12.6106 6.51029 11.402 6.51029 10.236 6.74297L12.0004 5.12119ZM8.18638 11.752C7.74952 11.4676 7.2531 11.2656 6.72708 11.1584C6.46654 11.1093 6.2009 11.0854 5.9347 11.0871C5.78547 11.0871 5.63361 11.0931 5.48042 11.1061L8.20223 8.61289C8.69355 8.15921 9.27959 7.79712 9.9268 7.54735C10.574 7.29758 11.2697 7.16504 11.9739 7.15732H12.0307C12.735 7.16523 13.4306 7.29786 14.0777 7.54762C14.7249 7.79738 15.311 8.15937 15.8024 8.61289L18.5229 11.1061C18.1064 11.0666 17.6856 11.0842 17.2749 11.1584C16.7514 11.2668 16.2574 11.4687 15.8222 11.752C15.3706 12.0224 14.8771 12.2317 14.3577 12.3729C12.8321 12.824 11.1858 12.824 9.66019 12.3729C9.13735 12.2312 8.64071 12.0208 8.18638 11.7484V11.752ZM12.0004 19.1355C11.4563 16.4452 10.5899 14.2678 9.55323 12.995C11.1462 13.4478 12.8558 13.4478 14.4488 12.995C13.4121 14.2689 12.5458 16.4416 12.0004 19.1319V19.1355Z"
        fill={fill}
      />
    </SpecialLogo>
  );
}

function Brand({ fill }: { fill: string }) {
  return (
    <div>
      <svg width="44" height="9" viewBox="0 0 43 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.107 8C9.98096 8 9.86011 7.94834 9.77101 7.85639C9.6819 7.76444 9.63184 7.63972 9.63184 7.50968V0.490316C9.63184 0.360276 9.6819 0.235564 9.77101 0.143611C9.86011 0.0516593 9.98096 0 10.107 0H13.0091C13.6634 -1.79784e-06 14.291 0.267814 14.7541 0.744693C15.2173 1.22157 15.4783 1.86857 15.4798 2.54376C15.479 3.20949 15.2222 3.84766 14.7657 4.31803C14.3091 4.7884 13.6903 5.05248 13.0452 5.05222H12.2251C12.0991 5.05222 11.9783 5.00056 11.8892 4.90861C11.8001 4.81666 11.75 4.69194 11.75 4.5619C11.75 4.43186 11.8001 4.30715 11.8892 4.2152C11.9783 4.12325 12.0991 4.07159 12.2251 4.07159H13.0452C13.2399 4.07184 13.4327 4.03253 13.6127 3.95589C13.7926 3.87925 13.9562 3.76678 14.094 3.62491C14.2318 3.48304 14.3412 3.31454 14.416 3.12904C14.4907 2.94353 14.5293 2.74466 14.5295 2.54376C14.528 2.12865 14.3672 1.73108 14.0822 1.4381C13.7972 1.14513 13.4114 0.98063 13.0091 0.980632H10.5793V7.50968C10.5793 7.63922 10.5296 7.76348 10.4411 7.85535C10.3526 7.94722 10.2325 7.99922 10.107 8Z"
          fill={fill}
        />
        <path
          d="M20.6399 8C19.8915 8 19.1599 7.7654 18.5376 7.32588C17.9153 6.88635 17.4303 6.26164 17.1439 5.53074C16.8575 4.79983 16.7826 3.99556 16.9286 3.21964C17.0746 2.44372 17.435 1.73098 17.9642 1.17157C18.4934 0.612164 19.1676 0.231201 19.9017 0.0768604C20.6357 -0.0774805 21.3965 0.00173401 22.0879 0.304484C22.7794 0.607235 23.3703 1.11992 23.7861 1.77772C24.2019 2.43552 24.4238 3.20887 24.4238 4C24.4229 5.06055 24.0239 6.07736 23.3145 6.82728C22.605 7.5772 21.6431 7.99896 20.6399 8ZM20.6399 0.980634C20.075 0.980634 19.5227 1.15772 19.053 1.48949C18.5833 1.82126 18.2172 2.29282 18.001 2.84454C17.7848 3.39626 17.7282 4.00335 17.8384 4.58905C17.9487 5.17475 18.2207 5.71275 18.6202 6.13502C19.0196 6.55728 19.5286 6.84485 20.0826 6.96135C20.6367 7.07786 21.211 7.01806 21.7329 6.78953C22.2548 6.561 22.7009 6.174 23.0148 5.67747C23.3287 5.18094 23.4962 4.59717 23.4962 4C23.4952 3.19953 23.1939 2.43215 22.6585 1.86613C22.1231 1.30012 21.3971 0.981672 20.6399 0.980634Z"
          fill={fill}
        />
        <path
          d="M39.216 8C38.4676 8 37.7361 7.7654 37.1138 7.32588C36.4915 6.88635 36.0065 6.26164 35.7201 5.53074C35.4337 4.79983 35.3588 3.99556 35.5048 3.21964C35.6508 2.44372 36.0112 1.73098 36.5404 1.17157C37.0696 0.612164 37.7438 0.231201 38.4778 0.0768604C39.2119 -0.0774805 39.9727 0.00173401 40.6641 0.304484C41.3555 0.607235 41.9465 1.11992 42.3623 1.77772C42.7781 2.43552 43 3.20887 43 4C42.999 5.06055 42.6001 6.07736 41.8906 6.82728C41.1812 7.5772 40.2193 7.99896 39.216 8ZM39.216 0.980634C38.6511 0.980634 38.0989 1.15772 37.6292 1.48949C37.1595 1.82126 36.7934 2.29282 36.5772 2.84454C36.361 3.39626 36.3044 4.00335 36.4146 4.58905C36.5248 5.17475 36.7969 5.71275 37.1963 6.13502C37.5958 6.55728 38.1047 6.84485 38.6588 6.96135C39.2129 7.07786 39.7872 7.01806 40.3091 6.78953C40.831 6.561 41.2771 6.174 41.591 5.67747C41.9048 5.18094 42.0723 4.59717 42.0723 4C42.0714 3.19953 41.7701 2.43215 41.2347 1.86613C40.6992 1.30012 39.9733 0.981672 39.216 0.980634Z"
          fill={fill}
        />
        <path
          d="M32.5754 8C32.5097 7.99997 32.4448 7.98415 32.3853 7.95364C32.3258 7.92314 32.2731 7.87871 32.2311 7.82349L27.7286 1.91419V7.5038C27.7286 7.63384 27.6813 7.75855 27.5973 7.8505C27.5132 7.94246 27.3991 7.99412 27.2802 7.99412C27.1613 7.99412 27.0473 7.94246 26.9632 7.8505C26.8791 7.75855 26.8318 7.63384 26.8318 7.5038V0.562884C26.8319 0.462152 26.8603 0.36388 26.9132 0.281429C26.9661 0.198978 27.041 0.136351 27.1276 0.102064C27.2142 0.0677779 27.3084 0.0634961 27.3973 0.0898005C27.4862 0.116105 27.5656 0.17172 27.6246 0.249081L32.1271 6.15543V0.490316C32.1271 0.360276 32.1743 0.235564 32.2584 0.143611C32.3425 0.0516593 32.4565 0 32.5754 0C32.6943 0 32.8084 0.0516593 32.8925 0.143611C32.9766 0.235564 33.0238 0.360276 33.0238 0.490316V7.50968C33.0238 7.63972 32.9766 7.76444 32.8925 7.85639C32.8084 7.94834 32.6943 8 32.5754 8Z"
          fill={fill}
        />
        <path
          d="M7.85702 7.28567L4.37347 0.263545C4.33406 0.184231 4.27401 0.117638 4.19999 0.0711274C4.12596 0.0246166 4.04084 0 3.95403 0C3.86721 0 3.78209 0.0246166 3.70807 0.0711274C3.63404 0.117638 3.57401 0.184231 3.53459 0.263545L0.0510466 7.28567C-0.00551071 7.39987 -0.0155109 7.53242 0.0232452 7.65417C0.0620013 7.77592 0.146334 7.8769 0.257701 7.93489C0.369067 7.99288 0.498344 8.00313 0.617084 7.9634C0.735824 7.92366 0.834299 7.83718 0.890856 7.72299C1.08608 7.32407 1.35768 6.96957 1.68945 6.68064C2.02122 6.39171 2.40635 6.17427 2.82187 6.04131C3.221 5.91315 3.63611 5.84486 4.0543 5.83858C4.21166 5.83894 4.36886 5.84894 4.52505 5.86851C5.04897 5.94115 5.54765 6.14394 5.97772 6.45923C6.40779 6.77453 6.75622 7.19278 6.99272 7.67762L7.01531 7.72299C7.04216 7.78144 7.08018 7.83377 7.12713 7.87688C7.17407 7.91999 7.22898 7.95299 7.28859 7.97394C7.3482 7.99488 7.41131 8.00334 7.47417 7.99881C7.53703 7.99429 7.59836 7.97687 7.65454 7.94758C7.71071 7.9183 7.76057 7.87775 7.80117 7.82834C7.84178 7.77893 7.8723 7.72166 7.89092 7.65993C7.90954 7.59821 7.91588 7.53327 7.90957 7.46899C7.90327 7.4047 7.88443 7.34236 7.85419 7.28567H7.85702ZM4.05147 4.87224C3.3864 4.8775 2.72796 5.00841 2.10916 5.25839L3.95261 1.54363L5.75936 5.18309C5.21132 4.98123 4.63379 4.87644 4.05147 4.87321V4.87224Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
