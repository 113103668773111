import { FormSection } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSection';
import { SelectResourceTypes } from '@organisms/IntegrationConnectorForm/SelectResourceTypes';
import { ConnectedSubIntegrationResourceTypesAppModel, IntegrationConfigWithSubIntegrations } from '@api';
import { FormSectionSummary } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionSummary';
import { SelectConfig } from '@Integrations/organisms/ConnectIntegration/ConnectIntegrationsGroup/SelectConfig';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { getCreateFormDefaultValues } from '@Integrations/IntegrationConfigForm/utils';
import { SubIntegrationsResourceTypes } from '@organisms/IntegrationConnectorForm/SubIntegrationResourceTypes';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { Divider, Stack, Typography } from '@mui/material';

export function DiscoveryFormSection({
  config,
  options,
  actions,
  isEdit,
  expanded,
  onSummaryClick,
  connectedResourceTypes,
  isOnlyView,
}: {
  config?: IntegrationConfigWithSubIntegrations;
  options?: IntegrationConfigWithSubIntegrations[];
  actions?: ReactNode;
  expanded: boolean;
  isEdit?: boolean;
  onSummaryClick?: () => void;
  connectedResourceTypes?: string[];
  isOnlyView?: boolean;
}) {
  const { isEnabled: isDeepDiscoveryEnabled } = useFlagFixed(Flag.DEEP_DISCOVERY);
  const { control, setValue, reset } = useFormContext<IntegrationConfigFormPayload>();

  const { fields, update } = useFieldArray({
    control,
    name: `connected_sub_integration_resource_types`,
  });

  return (
    <FormSection
      expanded={expanded}
      summary={
        <FormSectionSummary
          title="Discovery"
          withIcon={!isEdit}
          onClick={onSummaryClick}
          tooltip="Apono automatically discovers all the instances in the environment. Then, you can start managing access to resources with Access Flows!"
        />
      }
      actions={expanded && actions}
    >
      {options && options.length > 1 && (
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectConfig
              withDescription={false}
              groupedConfigs={options}
              selectedConfigType={field.value}
              onSelect={(newValue) => {
                if (newValue !== field.value) {
                  field.onChange(newValue);
                  reset(getCreateFormDefaultValues(options.find((c) => c.type === newValue)));
                  setValue('connected_resource_types', []);
                }
              }}
            />
          )}
        />
      )}
      {config && (
        <Controller
          name="connected_resource_types"
          control={control}
          render={({ field }) => (
            <SelectResourceTypes
              config={config}
              selected={field.value}
              onSelect={field.onChange}
              connected={connectedResourceTypes}
              withDescription={false}
              disabled={isOnlyView}
            />
          )}
        />
      )}
      {isDeepDiscoveryEnabled && config && config?.subIntegrationConfigs?.length > 0 && (
        <Stack spacing={1} paddingTop={2}>
          <Divider />
          <Typography variant="subtitle1">Connect Sub Integration</Typography>
          {config?.subIntegrationConfigs.map((subIntegrations, index) => (
            <Stack key={index} spacing={2}>
              <SubIntegrationsResourceTypes
                value={fields.find((f) => subIntegrations.type === f.integration_type)}
                onChange={(data: ConnectedSubIntegrationResourceTypesAppModel) => {
                  let valIndex = fields.findIndex((f) => f.integration_type === data.integration_type);
                  if (valIndex === -1) {
                    valIndex = fields.length;
                    update(valIndex, data);
                  }
                  update(valIndex, data);
                }}
                integrationConfig={subIntegrations}
              />
              {index < config?.subIntegrationConfigs.length - 1 && <Divider />}
            </Stack>
          ))}
        </Stack>
      )}
    </FormSection>
  );
}
