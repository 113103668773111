import { ActivityReportFiltersModel, ActivityReportModelPeriod, ModifyActivityReportModel } from '@api';
// import { routes } from '@routes';
import {
  DateTime,
  getCurrentTimezoneOption,
  getFieldsFromQP,
  getStartEndDateFromQP,
  getStringQP,
  joinPeriod,
  parseRelativePeriod,
  QueryParams,
  QueryParamsKeys,
} from '@utils';
import { generatePath } from 'react-router-dom';
import { routes } from '@routes';

export type ReportFormQP = Pick<
  ModifyActivityReportModel,
  'filters' | 'start_date' | 'end_date' | 'period' | 'time_zone' | 'fields'
>;

export function queryParamsToReport(origQp: URLSearchParams): ReportFormQP {
  const qp = new URLSearchParams(origQp);

  function getFilters(): ActivityReportFiltersModel {
    const filters: ActivityReportFiltersModel = {};

    const filterArgs: [QueryParamsKeys, keyof ActivityReportFiltersModel][] = [
      [QueryParams.filterRequestorId, 'requestor_id'],
      [QueryParams.filterIntegrationId, 'integration_id'],
      [QueryParams.filterResource, 'resource'],
      [QueryParams.filterPermission, 'permission'],
      [QueryParams.filterStatus, 'status'],
      [QueryParams.filterResourceType, 'resource_type'],
      [QueryParams.filterTriggerType, 'trigger_type'],
    ];
    for (const [param, key] of filterArgs) {
      if (qp.has(param)) {
        filters[key] = qp.getAll(param);
      }
    }

    return filters;
  }

  function getPeriod(): ActivityReportModelPeriod | undefined {
    const periodQp = getStringQP(qp, QueryParams.period);
    if (!periodQp) return;

    return parseRelativePeriod(periodQp);
  }

  const period = getPeriod();

  let start_date;
  let end_date;
  let time_zone;

  if (!period) {
    // eslint-disable-next-line no-inner-declarations
    function getTimeZone() {
      const utcQp = getStringQP(qp, QueryParams.utc);
      if (utcQp) return undefined;
      const currentTimezoneOption = getCurrentTimezoneOption();
      return currentTimezoneOption.value;
    }

    time_zone = getTimeZone();
    const { startDate, endDate } = getStartEndDateFromQP(qp);
    if (startDate && endDate) {
      start_date = Number(startDate);
      end_date = Number(endDate);
    }
  }

  return {
    fields: getFieldsFromQP(qp),
    filters: getFilters(),
    period,
    start_date,
    end_date,
    time_zone,
  };
}

export function reportToQueryParams(report: ReportFormQP): URLSearchParams {
  const qp = new URLSearchParams();

  if (report.filters) {
    const { requestor_id, integration_id, resource, resource_type, permission, status, trigger_type } = report.filters;
    if (requestor_id) qp.set(QueryParams.filterRequestorId, requestor_id.join(','));
    if (integration_id) qp.set(QueryParams.filterIntegrationId, integration_id.join(','));
    if (resource) qp.set(QueryParams.filterResource, resource.join(','));
    if (permission) qp.set(QueryParams.filterPermission, permission.join(','));
    if (status) qp.set(QueryParams.filterStatus, status.join(','));
    if (resource_type) qp.set(QueryParams.filterResourceType, resource_type.join(','));
    if (trigger_type) qp.set(QueryParams.filterTriggerType, trigger_type.join(','));
  }

  if (report.start_date) qp.set(QueryParams.startDate, DateTime.fromUnix(report.start_date).isoString);
  if (report.end_date) qp.set(QueryParams.endDate, DateTime.fromUnix(report.end_date).isoString);
  if (!report.time_zone) qp.set(QueryParams.utc, 'true');
  if (report.period) {
    qp.set(QueryParams.period, joinPeriod(report.period));
  }

  if (report.fields) qp.set(QueryParams.fields, report.fields.join(','));

  return qp;
}

export function reportUrlToCopy(id: string) {
  const path = generatePath(routes.EditReport.path, { id });
  return `${window.location.origin}${path}`;
}
