import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IntegrationsService } from '@api/services';

import { QueryKeys } from './query-keys';
import {
  CreateIntegrationAppModel,
  IntegrationAppModel,
  IntegrationConfigAppModelV2,
  UpdateIntegrationAppModel,
} from '@api/apn-core';
import { IntegrationConfigWithSubIntegrations } from '@api/query';

const REFETCH_INTERVAL = 10 * 1000; // 10 sec

function toIntegrationQuery(integration: IntegrationAppModel, configs: IntegrationConfigAppModelV2[]) {
  const integrationConfig = configs.find((i) => i.type === integration.type);

  const subIntegrationConfigs: Array<IntegrationConfigAppModelV2> = [];
  if (integrationConfig?.sub_integration_types) {
    for (const sub_config of configs) {
      if (integrationConfig?.sub_integration_types.includes(sub_config.type)) {
        subIntegrationConfigs.push(sub_config);
      }
    }
  }

  if (!integrationConfig) {
    return { integrationConfig: undefined };
  }

  return {
    integrationConfig: {
      ...integrationConfig,
      subIntegrationConfigs,
    },
    integration,
  };
}

export function useAppGetIntegration(
  integrationId: string,
  refetch?: boolean,
): {
  integration: IntegrationAppModel | undefined;
  integrationConfig: IntegrationConfigWithSubIntegrations | undefined;
  isIntegrationFetched?: boolean;
} {
  const { data, isFetched: isIntegrationFetched } = useQuery({
    queryKey: [QueryKeys.Integrations, integrationId],
    queryFn: async () => {
      const integration = await IntegrationsService.appGetIntegration({
        id: integrationId,
      });
      const configs = await IntegrationsService.appListIntegrationConfigs();

      return toIntegrationQuery(integration, configs.data);
    },
    refetchInterval: () => (refetch ? REFETCH_INTERVAL : false),
  });

  return {
    integration: data?.integration,
    integrationConfig: data?.integrationConfig,
    isIntegrationFetched,
  };
}

export function useListIntegrationsV2(refetch = false, enabled = true) {
  const queryClient = useQueryClient();

  const {
    data,
    isFetched: isIntegrationsFetched,
    refetch: refetchQuery,
  } = useQuery({
    queryKey: [QueryKeys.Integrations],
    queryFn: async () => {
      const [integrationRes, configsRes] = await Promise.all([
        await IntegrationsService.appListIntegrations(),
        await IntegrationsService.appListIntegrationConfigs(),
      ]);

      integrationRes.data.forEach((integration) => {
        queryClient.setQueryData(
          [QueryKeys.Integrations, integration.id],
          toIntegrationQuery(integration, configsRes.data),
        );
      });

      queryClient.setQueryData([QueryKeys.CatalogV2], configsRes.data);
      configsRes.data.forEach((config) => {
        queryClient.setQueryData([QueryKeys.CatalogV2, config.type], config);
      });

      return { integrations: integrationRes.data, configs: configsRes.data };
    },
    initialData: {
      integrations: [],
      configs: [],
    },
    refetchInterval: refetch ? REFETCH_INTERVAL : false,
    enabled,
  });
  return {
    ...data,
    isIntegrationsFetched,
    refetchQuery,
  };
}

export function useCreateIntegrationV2() {
  const queryClient = useQueryClient();

  const {
    mutate: createIntegration,
    isLoading: isCreateIntegrationLoading,
    isSuccess: isIntegrationCreated,
    data: createdIntegration,
  } = useMutation({
    mutationFn: async (payload: CreateIntegrationAppModel) =>
      IntegrationsService.appCreateIntegration({
        createIntegrationAppModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Integrations] });
    },
  });

  return {
    createIntegration,
    createdIntegration,
    isCreateIntegrationLoading,
    isIntegrationCreated,
  };
}

export function useUpdateIntegrationV2(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: updateIntegration,
    isLoading: isUpdateIntegrationLoading,
    isSuccess: isIntegrationUpdated,
  } = useMutation({
    mutationFn: async (payload: UpdateIntegrationAppModel) =>
      IntegrationsService.appUpdateIntegration({
        id,
        updateIntegrationAppModel: payload,
      }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QueryKeys.Integrations, id] }),
  });

  return {
    updateIntegration,
    isUpdateIntegrationLoading,
    isIntegrationUpdated,
  };
}

export function useDeleteIntegration() {
  const queryClient = useQueryClient();

  const { mutate: deleteIntegration, isLoading: isDeleteIntegrationLoading } = useMutation({
    mutationFn: async (id: string) => IntegrationsService.deleteIntegrationV2({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Integrations] });
    },
  });

  return {
    deleteIntegration,
    isDeleteIntegrationLoading,
  };
}

export function useCanDeleteIntegration(integrationId: string) {
  const { data: canDeleteIntegration, isLoading: isCanDeleteIntegrationLoading } = useQuery({
    queryKey: [QueryKeys.CanDeleteIntegration, integrationId],
    queryFn: async () => IntegrationsService.canDeleteIntegrationV2({ id: integrationId }),
  });

  return {
    canDeleteIntegration,
    isCanDeleteIntegrationLoading,
  };
}

export function useRefreshIntegration() {
  const queryClient = useQueryClient();

  const { mutate: refreshIntegration, isLoading: isRefreshIntegrationLoading } = useMutation({
    mutationFn: async (id: string) => IntegrationsService.refreshIntegrationV2({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Integrations] });
    },
  });

  return {
    refreshIntegration,
    isRefreshIntegrationLoading,
  };
}

export function useListSecrets(awsAccountId: string, region: string) {
  const { data: secrets, isFetched: isSecretsFetched } = useQuery({
    queryKey: [QueryKeys.IntegrationAwsSecrets, awsAccountId, region],
    queryFn: async () =>
      IntegrationsService.listSecrets({
        awsAccountId,
        filter: undefined,
        region,
      }),
    initialData: [],
  });

  return {
    secrets,
    isSecretsFetched,
  };
}

export function useGetIntegrationErrors(integrationId: string) {
  const { data: integrationErrors, isFetched: isIntegrationErrorsFetched } = useQuery({
    queryKey: [QueryKeys.IntegrationResourcesErrors, integrationId],
    queryFn: async () =>
      IntegrationsService.appGetIntegrationErrors({
        id: integrationId,
      }),
    initialData: {
      data: [],
      pagination: {
        limit: 100,
        total: 0,
        offset: 0,
      },
    },
  });

  return {
    integrationErrors,
    isIntegrationErrorsFetched,
  };
}

export function useGetCustomAccessDetailsPreview(integrationType: string) {
  const { data: customAccessDetailsPreview, ...rest } = useQuery({
    queryKey: [QueryKeys.CustomAccessDetailsPreview, integrationType],
    queryFn: async () => IntegrationsService.generateIntegrationAccessDetailsPreview({ integrationType }),
  });

  return {
    customAccessDetailsPreview,
    ...rest,
  };
}
