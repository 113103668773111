import { Integration, IntegrationStatus } from '@api';
import { useAsyncState } from '@hooks';
import { connectedIntegrationsSelector, integrationsConfigsSelector } from '@state';

export function useIntegrationsConfigs() {
  const {
    value: catalog,
    isLoading: isConfigsLoading,
    reload: refetchConfigs,
  } = useAsyncState(integrationsConfigsSelector, null);

  return {
    isConfigsLoading,
    configs: catalog?.integration_configs || [],
    refetchConfigs,
  };
}

export function useConnectedIntegrationsFromState() {
  const {
    value: connectedIntegrations,
    isLoading: isConnectedIntegrationsLoading,
    reload: refetchConnectedIntegrationsLoading,
  } = useAsyncState(connectedIntegrationsSelector, []);

  return {
    connectedIntegrations,
    isConnectedIntegrationsLoading,
    refetchConnectedIntegrationsLoading,
  };
}

export function useConnectedIntegrationByIdFromState(id?: string): Integration | undefined {
  const { value: connectedIntegrations } = useAsyncState(connectedIntegrationsSelector, []);
  return connectedIntegrations.find((ci) => ci.id === id);
}

export function useConnectedIntegrationsByTypeFromState(type?: string): {
  isLoading: boolean;
  connectedIntegrations: Integration[];
} {
  const { value: connectedIntegrations, isLoading } = useAsyncState(connectedIntegrationsSelector, []);

  return {
    isLoading,
    connectedIntegrations: connectedIntegrations.filter((ci) => ci.type === type),
  };
}

export function useIntegrationsPack() {
  const { connectedIntegrations, isConnectedIntegrationsLoading, refetchConnectedIntegrationsLoading } =
    useConnectedIntegrationsFromState();
  const { configs, isConfigsLoading, refetchConfigs } = useIntegrationsConfigs();

  const refetch = () => {
    refetchConnectedIntegrationsLoading();
    refetchConfigs();
  };

  return {
    isLoading: isConnectedIntegrationsLoading || isConfigsLoading,
    configs,
    connectedIntegrations,
    refetch,
  };
}

export function useIsIntegrationCanBeUsed() {
  const activeStatuses: IntegrationStatus[] = [
    IntegrationStatus.Active,
    IntegrationStatus.Warning,
    IntegrationStatus.Refreshing,
  ];

  return (status: IntegrationStatus) => activeStatuses.includes(status);
}
