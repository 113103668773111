import { PropsWithChildren, ReactNode } from 'react';

import { Box, styled } from '@mui/material';

import { PageTitle, PageTitleProps } from './PageTitle';
import { PageContent, PageContentProps } from './PageContent';
import { PageBanner } from './PageBanner';
import { PageTabProps, PageTabs } from './PageTabs';

const PageWrapper = styled(Box)({
  display: 'flex',
  minHeight: '100%',
  flexDirection: 'column',
});

export interface PageProps extends PageTitleProps {
  fullHeight?: boolean;
  contentCentered?: boolean;
  contentMiddled?: boolean;
  contentNarrowed?: boolean;
  contentPadded?: boolean;
  specialLayout?: boolean;
  tabs?: PageTabProps[];
  banner?: ReactNode;
  containerWidth?: PageContentProps['containerWidth'];
  withTransition?: boolean;
}

export function Page({
  contentCentered,
  contentMiddled,
  contentNarrowed,
  contentPadded,
  specialLayout,
  fullHeight,
  tabs,
  banner,
  children,
  containerWidth,
  withTransition = true,
  ...titleProps
}: PropsWithChildren<PageProps>) {
  let pageContainerWidth: PageContentProps['containerWidth'] = false;
  if (contentCentered) pageContainerWidth = 'lg';
  if (contentNarrowed) pageContainerWidth = 'sm';
  if (containerWidth) pageContainerWidth = containerWidth;

  return (
    <PageWrapper className={withTransition ? 'transition' : ''}>
      {banner && <PageBanner>{banner}</PageBanner>}
      {Object.keys(titleProps).length !== 0 ? <PageTitle {...titleProps} /> : null}
      {tabs ? <PageTabs tabs={tabs} /> : null}
      <PageContent
        fullHeight={fullHeight}
        hCentered={contentCentered || contentMiddled}
        vCentered={contentMiddled}
        padded={contentPadded}
        specialLayout={specialLayout}
        containerWidth={pageContainerWidth}
      >
        {children}
      </PageContent>
    </PageWrapper>
  );
}
