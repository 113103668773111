export enum AlertsQueryKeys {
  AlertsList = 'alert/alertsList',
  AlertSingle = 'alert/alertSingle',
  AlertTypesOptions = 'alert/alertTypesOptions',
  AlertSeverityOptions = 'alert/alertSeverityOptions',
  AlertUserOptions = 'alert/alertUserOptions',
  AlertIntegrationOptions = 'alert/alertIntegrationOptions',
  AlertResourceTypeOptions = 'alert/alertResourceTypeOptions',
  AlertStats = 'dashboard-v2/alerts-stats',
  AlertStatsOverTime = 'dashboard-v2/alerts-stats-over-time',
}
