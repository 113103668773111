import { getFragmentData } from '@api/gql';
import { Loader } from '@components';
import { useAttackSurfaceReductionStats, attackSurfaceReductionStatsFields } from '@DashboardV2/services/gql';
import { AttackSurfaceReductionWidget } from '@DashboardV2/components/AttackSurfaceReductionWidget';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { Typography } from '@mui/material';

export function AttackSurfaceReduction() {
  return (
    <WidgetCard
      title="Attack Surface Reduction"
      subtitle="Last 30 days"
      tooltip="Apono helps reduce attack surfaces by replacing open-ended access with just-in-time access. We compare each user's per environment Apono access with 24/7 access to calculate this reduction."
      half
    >
      <AttackSurfaceReductionBody />
    </WidgetCard>
  );
}

function AttackSurfaceReductionBody() {
  const { data, isFetched } = useAttackSurfaceReductionStats();

  const attackSurfaceStats = getFragmentData(attackSurfaceReductionStatsFields, data?.attackSurfaceStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (!attackSurfaceStats || attackSurfaceStats?.currentPeriod == 0 || attackSurfaceStats.previousPeriod === 0) {
    return (
      <Typography variant="helperText" color="text.muted">
        No reduction was detected. Create Access Flows and instruct users to request access with Apono.
      </Typography>
    );
  }

  return <AttackSurfaceReductionWidget data={attackSurfaceStats} />;
}
