import { Avatar, Checkbox, ListItemIcon, ListItemText, styled, Typography } from '@mui/material';

const FilterAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

const FilterCheckbox = styled(Checkbox)({
  paddingLeft: 0,
  paddingRight: 0,
});

export interface FilterSelectItemProps {
  option?: string;
  icon?: string;
  hasCheckbox?: boolean;
  checked?: boolean;
}

export function FilterSelectItem({ option, icon, hasCheckbox, checked }: FilterSelectItemProps) {
  return (
    <>
      {icon ? (
        <ListItemIcon>
          <FilterAvatar src={icon} alt={option} variant="square" />
        </ListItemIcon>
      ) : null}
      <ListItemText title={option}>
        <Typography variant="inherit" noWrap>
          {option}
        </Typography>
      </ListItemText>
      {hasCheckbox ? <FilterCheckbox checked={checked} size="small" /> : null}
    </>
  );
}
