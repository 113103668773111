import { Stack, Typography } from '@mui/material';

import { IntegrationConfigAppModel } from '@api';
import { IntegrationAvatar } from '@components/IntegrationAvatar/IntegrationAvatar';
import { Markdown } from '@components';

export function ConnectIntegrationFormHeader({ integrationConfig }: { integrationConfig: IntegrationConfigAppModel }) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <IntegrationAvatar icon={integrationConfig.icons.svg} />
        <Typography variant="h5" color="text.primary" component="p">
          {integrationConfig.name}
        </Typography>
      </Stack>
      <Typography variant="subtitle2" color="text.primary" component="div">
        <Markdown>{integrationConfig.description}</Markdown>
      </Typography>
    </Stack>
  );
}
