import { CHILD_NODE_WIDTH } from './common';
import { Box, Stack, styled, Tooltip } from '@mui/material';
import { Handle, NodeProps, Position } from 'reactflow';
import { LabelWrap, ResourceNodeTypography } from './node';
import { AccessGraphResourceNode, AccessGraphUserNode } from '@api/gql/graphql';
import { ACTIVE_ACCESS_COLOR } from './node_and_edge_types';

const ResourceNodeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: CHILD_NODE_WIDTH,
  borderRadius: '4px',
  position: 'relative',
  cursor: 'pointer',
  padding: '8px',

  '&.resource': {
    border: `1px solid ${ACTIVE_ACCESS_COLOR}`,
  },

  '.selected &': {
    cursor: 'default',
  },
}));

type ResourceNodeProps = { node: AccessGraphResourceNode };
type UserNodeProps = { node: AccessGraphUserNode };

export function UserNode({ data }: NodeProps<UserNodeProps>) {
  const { node } = data;
  return (
    <ResourceNodeWrapper>
      <Handle
        type="source"
        position={Position.Right}
        id={node.id}
        style={{ backgroundColor: ACTIVE_ACCESS_COLOR, borderColor: ACTIVE_ACCESS_COLOR }}
      />
      <Stack direction="column" alignItems="start">
        <LabelWrap>
          <ResourceNodeTypography variant="caption" component="div" color="text.primary" noWrap>
            {node?.user?.firstName} {node?.user?.lastName}
          </ResourceNodeTypography>
        </LabelWrap>
        <ResourceNodeTypography variant="helperText" component="div" color="text.muted">
          {node?.user?.email}
        </ResourceNodeTypography>
      </Stack>
    </ResourceNodeWrapper>
  );
}

export function ResourceNode({ data }: NodeProps<ResourceNodeProps>) {
  const { node } = data;

  return (
    <ResourceNodeWrapper className="resource">
      <Handle
        type="target"
        position={Position.Left}
        id={node.id}
        style={{ backgroundColor: ACTIVE_ACCESS_COLOR, borderColor: ACTIVE_ACCESS_COLOR }}
      />
      <Stack direction="column" alignItems="start">
        <LabelWrap width={CHILD_NODE_WIDTH - 16}>
          <Tooltip title={node?.resource?.name} arrow placement="top" enterDelay={750}>
            <ResourceNodeTypography
              variant="caption"
              component="div"
              color="text.primary"
              noWrap
              sx={{ textOverflow: 'ellipsis' }}
            >
              {node?.resource?.name}
            </ResourceNodeTypography>
          </Tooltip>
        </LabelWrap>
        <LabelWrap width={CHILD_NODE_WIDTH - 16}>
          <Tooltip title={node?.resource?.path} arrow placement="top" enterDelay={750}>
            <ResourceNodeTypography
              variant="helperText"
              component="div"
              color="text.muted"
              noWrap
              sx={{ textOverflow: 'ellipsis' }}
            >
              {node?.resource?.path}
            </ResourceNodeTypography>
          </Tooltip>
        </LabelWrap>
      </Stack>
    </ResourceNodeWrapper>
  );
}
