import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { MaterialIcon } from '@components';
import AppModal from '@common/ui/AppModal';
import { IntegrationConfigAppModelV2, useGetCustomAccessDetailsPreview } from '@api';
import CustomAccessDetails from '@common/ui/CustomAccessDetails/CustomAccessDetails';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import AccessDetailsPreview from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/CustomAccessDetails/AccessDetailsPreview';

export function CustomAccessDetailsPreview({
  integrationConfig,
  adminMessage,
}: {
  integrationConfig: IntegrationConfigAppModelV2;
  adminMessage: string;
}) {
  const [open, setOpen] = useState(false);
  const { customAccessDetailsPreview } = useGetCustomAccessDetailsPreview(integrationConfig.type);
  return (
    <>
      <Button
        size="small"
        variant="text"
        onClick={() => setOpen(true)}
        startIcon={<MaterialIcon symbol="visibility" />}
      >
        Preview
      </Button>
      <AppModal
        open={open}
        onClose={() => setOpen(false)}
        header={
          <PrimarySecondary
            primary={integrationConfig.name}
            secondary={integrationConfig.type}
            icon={integrationConfig.icons.svg}
          />
        }
      >
        <Stack spacing={2}>
          <CustomAccessDetails title="Message from your admin" content={adminMessage} />
          <AccessDetailsPreview accessDetails={customAccessDetailsPreview?.with_credentials.instructions.markdown} />
        </Stack>
      </AppModal>
    </>
  );
}
