import { PropsWithChildren, ReactNode, useState } from 'react';

import { Button, ButtonBase, IconButton, styled } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useCopyToClipboard } from 'usehooks-ts';
import { MaterialIcon } from '@components/MaterialIcon';
import { AnalyticsTriggerElementProps } from '@common/types';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    '&.success': {
      backgroundColor: theme.palette.success.main,
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&.success': {
      '&:before': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
}));

export interface CopyButtonProps {
  text: string;
  copyTooltip?: string;
  noButton?: boolean;
  icon?: ReactNode;
}

export function CopyButton({
  children,
  copyTooltip,
  text,
  noButton,
  icon,
  ...analyticsProps
}: PropsWithChildren<CopyButtonProps> & AnalyticsTriggerElementProps) {
  const [success, setSuccess] = useState(false);
  const [, copy] = useCopyToClipboard();

  function handleCopy() {
    copy(text);
    setSuccess(true);
  }

  function handleClose() {
    setTimeout(() => setSuccess(false), 250);
  }

  const tooltipSuccessClass = success ? 'success' : '';
  icon = icon || <MaterialIcon symbol="content_copy" />;

  const button = () => {
    if (children) {
      if (noButton) {
        return (
          <ButtonBase data-testid="copy-button" onClick={handleCopy} {...analyticsProps}>
            {children}
          </ButtonBase>
        );
      }

      return (
        <Button
          data-testid="copy-button"
          variant="text"
          size="small"
          color="inherit"
          startIcon={icon}
          onClick={handleCopy}
          {...analyticsProps}
        >
          {children}
        </Button>
      );
    }

    return (
      <IconButton data-testid="copy-button" aria-label="delete" size="small" onClick={handleCopy} {...analyticsProps}>
        {icon}
      </IconButton>
    );
  };

  return (
    <StyledTooltip
      title={success ? 'Copied!' : copyTooltip || 'Copy to clipboard'}
      arrow
      placement="top"
      onClose={handleClose}
      componentsProps={{
        tooltip: {
          className: tooltipSuccessClass,
        },
        arrow: {
          className: tooltipSuccessClass,
        },
      }}
    >
      {button()}
    </StyledTooltip>
  );
}
