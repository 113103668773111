import { adminLayoutWrap } from '@middlewares';

import { Flag } from '@utils';

import AccessFlowsPage from '@AccessFlows/pages/AccessFlowsPage';
import BundlesPage from '@AccessFlows/pages/BundlesPage';
import { AddAccessFlowPage } from '@AccessFlows/pages/AddAccessFlowPage';
import { EditAccessFlowPage } from '@AccessFlows/pages/EditAccessFlowPage';
import { AddBundlePage } from '@AccessFlows/pages/AddBundlePage';
import { EditBundlePage } from '@AccessFlows/pages/EditBundlePage';
import { AccessRightSizingPage } from '@AccessRightSizing/pages/AccessRightSizingPage';

export const routes = {
  AccessFlows: {
    label: 'Access Flows',
    path: '/access-flows',
    page: adminLayoutWrap(<AccessFlowsPage />),
  },
  AccessRightSizing: {
    label: 'Access Right Sizing',
    path: '/access-right-sizing',
    page: adminLayoutWrap(<AccessRightSizingPage />, Flag.ACCESS_RIGHT_SIZING),
  },
  Bundles: {
    label: 'Bundles',
    path: '/bundles',
    page: adminLayoutWrap(<BundlesPage />, Flag.UI_BUNDLES),
  },
  AddAccessFlow: {
    label: 'Add Access Flow',
    path: '/access-flows/add',
    page: adminLayoutWrap(<AddAccessFlowPage />),
  },
  EditAccessFlow: {
    label: 'Edit Access Flow',
    path: '/access-flows/edit/:id',
    page: adminLayoutWrap(<EditAccessFlowPage />),
  },
  AddBundle: {
    label: 'Create Bundle',
    path: '/bundles/create',
    page: adminLayoutWrap(<AddBundlePage />, Flag.UI_BUNDLES),
  },
  EditBundle: {
    label: 'Edit Bundle',
    path: '/bundles/edit/:id',
    page: adminLayoutWrap(<EditBundlePage />, Flag.UI_BUNDLES),
  },
};
