export const RESOURCE_NODE_WIDTH = 282;
export const RESOURCE_NODE_HEIGHT_EXTRA = 40;
export const RESOURCE_NODES_GAP = 16;
export const INTEGRATION_NODE_GAP = 8;
export const GROUPED_NODE_H_PADDING = 10;
export const GROUPED_NODE_COLLAPSE_BUTTON_HEIGHT = 32;
export const RESOURCE_NODE_HEIGHT = 40;
export const GROUPED_NODE_WIDTH = RESOURCE_NODE_WIDTH + 2 * GROUPED_NODE_H_PADDING;
export const COLLAPSED_GROUPED_NODE_HEIGHT = RESOURCE_NODE_HEIGHT + 2 * RESOURCE_NODES_GAP;
export const AND_NODE_DEFAULT_HEIGHT = 40;
export const AND_NODE_CONDITION_HEIGHT = 24;
export const INTEGRATION_NODE_HEIGHT = 24;
export const NODE_PADDING = 12;
export const NODE_BORDER = 1;
export const CHILD_NODE_HEIGHT = 50;
export const CHILD_NODE_GAP = 10;
export const CHILD_NODE_WIDTH = 260;
export const MORE_NODE_HEIGHT = 30;

export type NodeTypeTypes =
  | 'accessTarget'
  | 'accessTargetExpanded'
  | 'conditionOr'
  | 'conditionOrExpanded'
  | 'conditionAnd'
  | 'conditionAndExpanded'
  | 'resourceMoreNode'
  | 'resourceNode'
  | 'userMoreNode'
  | 'userNode'
  | 'integrationNode';
