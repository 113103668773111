import { Fragment, useCallback, useMemo, useState } from 'react';

import {
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  AccessFlowsLinkedToIntegration,
  ActiveAccessRequestLinkedToIntegration,
  AgentViewModel,
  Integration,
  IntegrationAppModel,
  IntegrationCategoryAppModel,
  IntegrationConfigAppModelV2,
  IntegrationFamilyType,
  IntegrationStatus,
  useCanDeleteIntegration,
  useDeleteIntegration,
  useListAgents,
  useListIntegrationsV2,
  useRefreshIntegration,
} from '@api';
import {
  ActionsMenuV2,
  ConfirmDialog,
  ConfirmDialogProps,
  Loader,
  MaterialIcon,
  NoResults,
  StatusColumn,
} from '@components';
import { useIsIntegrationCanBeUsed } from '@hooks';
import { routes } from '@routes';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { generatePath, RouterLink, useNavigate, useSearchParams } from '@libs';
import { AlertListDrawer } from '@organisms/AlertListDrawer/AlertListDrawer';
import { ActionsMenuV2Option } from '@components/ActionsMenuV2/ActionsMenuV2';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import LeftOversChip from '@common/ui/LeftOversChip';
import CollapsibleTableRows from '@common/components/CollapsibleTableRows';
import { FilterQueryParams } from '@Integrations/organisms/ConnectedIntegrationsFilters';
import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type IntegrationRow = {
  integration: IntegrationAppModel;
  integrationConfig?: IntegrationConfigAppModelV2;
  connector?: AgentViewModel;
  subIntegrationsSummary?: { integrations: number; errors: number };
};

function useConnectedIntegrationsTable(
  setIntegrationResults?: ({ total, displayed }: { total: number; displayed: number }) => void,
) {
  const [searchParams] = useSearchParams();
  const {
    integrations,
    isIntegrationsFetched: isIntegrationsAndConfigsFetchedV2,
    configs,
  } = useListIntegrationsV2(true);
  const { connectors, isConnectorsFetched } = useListAgents();

  const toIntegrationRow = useCallback(
    (integration: IntegrationAppModel): IntegrationRow => {
      const integrationConfig = configs.find((config) => config.type === integration.type);
      const connector = connectors.find((c) => c.agent_id === integration.provisioner_id);
      return { integration, integrationConfig, connector };
    },
    [configs, connectors],
  );

  const rows = useMemo(() => {
    const searchFilter = searchParams.get(SEARCH_QUERY_PARAM)?.toLowerCase() || '';
    const categoryFilter = searchParams.get(FilterQueryParams.Category);
    const typeFilter = searchParams.get(FilterQueryParams.Type);
    const statusFilter = searchParams.get(FilterQueryParams.Status);
    const connectorFilter = searchParams.get(FilterQueryParams.Connector);

    const matchesFilter = ({ integration, integrationConfig }: IntegrationRow) => {
      if (categoryFilter && integrationConfig?.category !== categoryFilter) return false;
      if (typeFilter && integration.type !== typeFilter) return false;
      if (statusFilter && integration.status !== statusFilter) return false;
      if (connectorFilter && integration.provisioner_id !== connectorFilter) return false;

      const resourceTypeName = integrationConfig?.name || '';
      return `${integration.name} ${resourceTypeName}`.toLowerCase().includes(searchFilter);
    };

    const parentIntegrations: Record<string, IntegrationRow> = {};
    const subIntegrations: Record<string, IntegrationRow[]> = {};
    for (const integration of integrations) {
      if (!integration.parent_integration_id) {
        parentIntegrations[integration.id] = toIntegrationRow(integration);
      } else {
        const integrationRow = toIntegrationRow(integration);
        if (matchesFilter(integrationRow)) {
          subIntegrations[integration.parent_integration_id] = subIntegrations[integration.parent_integration_id] || [];
          subIntegrations[integration.parent_integration_id].push(integrationRow);
        }
      }
    }

    const filteredParentIntegrations: IntegrationRow[] = [];
    for (const parentIntegration of Object.values(parentIntegrations)) {
      if (subIntegrations[parentIntegration.integration.id] || matchesFilter(parentIntegration)) {
        if (subIntegrations[parentIntegration.integration.id]) {
          const summary = {
            integrations: subIntegrations[parentIntegration.integration.id].length,
            errors: subIntegrations[parentIntegration.integration.id].reduce(
              (acc, { integration }) => acc + (integration.details_summary?.errors || 0),
              0,
            ),
          };
          parentIntegration.subIntegrationsSummary = summary;
        }
        filteredParentIntegrations.push(parentIntegration);
      }
    }

    if (setIntegrationResults) {
      setIntegrationResults({
        total: integrations.length,
        displayed: filteredParentIntegrations.length + Object.values(subIntegrations).flat().length,
      });
    }

    return {
      parentIntegrations: filteredParentIntegrations,
      subIntegrations,
    };
  }, [searchParams, setIntegrationResults, integrations, toIntegrationRow]);

  return { ...rows, isFetched: isIntegrationsAndConfigsFetchedV2 && isConnectorsFetched };
}

const ALERT_DRAWER_QUERY_PARAM = 'alertDrawer';

export function ConnectedIntegrationsTable({
  setIntegrationResults,
}: {
  setIntegrationResults?: ({ total, displayed }: { total: number; displayed: number }) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { parentIntegrations, subIntegrations, isFetched } = useConnectedIntegrationsTable(setIntegrationResults);
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});

  const handleErrorsClick = (integrationId: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(ALERT_DRAWER_QUERY_PARAM, integrationId);
    setSearchParams(newSearchParams);
  };

  const handleAlertErrorsClose = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(ALERT_DRAWER_QUERY_PARAM);
    setSearchParams(newSearchParams);
  };

  const handleOpenRow = (id: string) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const selectedIntegrationId = useMemo(() => searchParams.get(ALERT_DRAWER_QUERY_PARAM) || undefined, [searchParams]);

  if (!isFetched) return <Loader />;

  if (parentIntegrations.length === 0) return <NoResults>No connected integrations found</NoResults>;

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {['Name', 'Connector', 'Resource Types', 'Sync Summary', 'Status', '', ''].map((column, index) => (
                <TableCell component="th" size="medium" key={`th-${index}`}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {parentIntegrations.map((row) =>
              subIntegrations[row.integration.id]?.length > 0 ? (
                <CollapsibleTableRows
                  key={row.integration.id}
                  open={openRows[row.integration.id]}
                  colSpan={7}
                  mainRow={
                    <ConnectedIntegrationsTableRow
                      {...row}
                      open={openRows[row.integration.id]}
                      onToggle={() => handleOpenRow(row.integration.id)}
                      onSelect={() => handleErrorsClick(row.integration.id)}
                    />
                  }
                >
                  {subIntegrations[row.integration.id].map((subRow) => (
                    <ConnectedIntegrationsTableRow
                      key={subRow.integration.id}
                      {...subRow}
                      onSelect={() => handleErrorsClick(subRow.integration.id)}
                    />
                  ))}
                </CollapsibleTableRows>
              ) : (
                <ConnectedIntegrationsTableRow
                  key={row.integration.id}
                  {...row}
                  onSelect={() => handleErrorsClick(row.integration.id)}
                />
              ),
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <AlertListDrawer integrationId={selectedIntegrationId} onClose={handleAlertErrorsClose} />
    </>
  );
}

interface ConnectedIntegrationsTableRowProps {
  integration: IntegrationAppModel;
  integrationConfig?: IntegrationConfigAppModelV2;
  connector?: AgentViewModel;
  onSelect?: () => void;
  open?: boolean;
  onToggle?: () => void;
  subIntegrationsSummary?: { integrations: number; errors: number };
}

const USER_INFO_CONFIG_LABELS = ['incident response', 'idp', 'idp group', 'user context'];

function useConnectedIntegrationsTableRow({
  integration,
  integrationConfig,
  connector,
  onSelect,
}: ConnectedIntegrationsTableRowProps) {
  const isCanBeUsed = useIsIntegrationCanBeUsed();

  const NameCell = useMemo(() => {
    return (
      <PrimarySecondary
        icon={integrationConfig?.icons.svg}
        primary={integration.name}
        secondary={integrationConfig?.name}
        iconSpacing={2}
      />
    );
  }, [integration, integrationConfig]);

  const ConnectorCell = useMemo(() => {
    if (!connector?.name) {
      return (
        <Typography variant="inputLabel" color="text.muted" component="em">
          None
        </Typography>
      );
    }

    return <PrimarySecondary primary={connector.name} maxWidth={400} />;
  }, [connector]);

  const ResourceTypesCell = useMemo(() => {
    if (!integration || !integrationConfig) return;

    const resources = integrationConfig.resource_types;
    const connectedResourcesType = integration?.connected_resource_types ?? [];
    const connectedResources = Object.values(resources).filter((resource) =>
      connectedResourcesType.includes(resource.type),
    );

    if (connectedResources.length < 1) return <></>;

    const leftOvers = connectedResources.length > 1 ? connectedResources.slice(1) : [];

    const primaryMore =
      leftOvers.length > 0 ? (
        <LeftOversChip label={`+${leftOvers.length}`} tooltip={leftOvers.map((r) => r.display_name).join(', ')} />
      ) : undefined;

    return (
      <PrimarySecondary
        icon={connectedResources[0].icons.svg}
        iconSpacing={1.5}
        primary={connectedResources[0].display_name}
        primaryMore={primaryMore}
      />
    );
  }, [integrationConfig, integration]);

  const SummaryCell = useMemo(() => {
    const byType: Record<
      string,
      {
        synced: number;
        failed: number;
      }
    > = {};

    for (const synced of integration.details_summary?.synced || []) {
      const type = synced.type.toLowerCase();
      if (!byType[type]) byType[type] = { synced: 0, failed: 0 };
      byType[type].synced += synced.count;
    }

    for (const failed of integration.details_summary?.failed || []) {
      const type = failed.type.toLowerCase();
      if (!byType[type]) byType[type] = { synced: 0, failed: 0 };
      byType[type].failed += failed.count;
    }

    return (
      <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
        {Object.entries(byType).map(([type, { synced, failed }]) => {
          let link = generatePath(routes.IntegrationResources.path, { id: integration.id });

          const configLabelsLowercase = integrationConfig?.labels_categories.map((label) => label.toLowerCase()) ?? [];
          if (configLabelsLowercase.some((label) => USER_INFO_CONFIG_LABELS.includes(label)))
            link = routes.Identities.path;

          return (
            <Fragment key={type}>
              {synced > 0 && (
                <Link component={RouterLink} underline="hover" to={link} variant="body2" color="text.primary">
                  <strong>{synced}</strong> {type + (synced > 1 ? 's' : '')} synced
                </Link>
              )}
              {failed > 0 && (
                <Link
                  component={RouterLink}
                  underline="hover"
                  variant={synced > 0 ? 'helperText' : 'body2'}
                  color="error.main"
                  to={`${link}?search=error`}
                >
                  <strong>{failed}</strong>
                  {!synced ? ' ' + type + (failed > 1 ? 's' : '') : null} failed to sync
                </Link>
              )}
            </Fragment>
          );
        })}
      </Stack>
    );
  }, [integration, integrationConfig?.labels_categories]);

  const StatusCell = useMemo(() => {
    const { errors, warnings } = integration.details_summary || {
      errors: 0,
      warnings: 0,
    };
    const errorsString = errors > 0 ? `${errors} error${errors > 1 ? 's' : ''}` : null;
    const warningsString = warnings > 0 ? `${warnings} warning${warnings > 1 ? 's' : ''}` : null;

    if (onSelect && (errorsString || warningsString)) {
      return (
        <StatusColumn status={integration.status}>
          <Link
            href="#"
            underline="hover"
            color={errorsString ? 'error.main' : 'warning.main'}
            variant="helperText"
            onClick={(e) => {
              e.preventDefault();
              onSelect();
            }}
          >
            View{' '}
            {errorsString && warningsString ? `${errorsString} and ${warningsString}` : errorsString || warningsString}
          </Link>
        </StatusColumn>
      );
    }

    return (
      <StatusColumn
        status={integration.status}
        last_sync_time={integration.last_sync_time ? +integration.last_sync_time : undefined}
      />
    );
  }, [integration.details_summary, integration.last_sync_time, integration.status, onSelect]);

  const CreateAccessFlowButtonCell = useMemo(() => {
    if (integrationConfig?.family_type !== IntegrationFamilyType.ResourceIntegration) return;

    return (
      <Button
        data-testid="create-access-flow-btn"
        size="small"
        variant="outlined"
        disabled={!isCanBeUsed(integration.status)}
        component={RouterLink}
        to={`${routes.AddAccessFlow.path}?fromIntegration=${integration.id}`}
      >
        Create Access Flow
      </Button>
    );
  }, [integration, integrationConfig, isCanBeUsed]);

  return {
    NameCell,
    ConnectorCell,
    ResourceTypesCell,
    SummaryCell,
    StatusCell,
    CreateAccessFlowButtonCell,
  };
}

function ConnectedIntegrationsTableRow({
  integration,
  integrationConfig,
  connector,
  onSelect,
  open,
  onToggle,
  subIntegrationsSummary,
}: ConnectedIntegrationsTableRowProps) {
  const { NameCell, ConnectorCell, ResourceTypesCell, SummaryCell, StatusCell, CreateAccessFlowButtonCell } =
    useConnectedIntegrationsTableRow({
      integration,
      integrationConfig,
      connector,
      onSelect,
    });

  return (
    <TableRow>
      <TableCell width={400}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {onToggle && (
              <IconButton aria-label="expand row" size="small" onClick={onToggle}>
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            )}
            {integration.parent_integration_id && <MaterialIcon symbol="subdirectory_arrow_right" size="small" />}
            {NameCell}
          </Stack>
          {subIntegrationsSummary && <SubIntegrationSummary subIntegrationsSummary={subIntegrationsSummary} />}
        </Stack>
      </TableCell>
      <TableCell width={350}>{ConnectorCell}</TableCell>
      <TableCell width={250}>{ResourceTypesCell}</TableCell>
      <TableCell width={350}>{SummaryCell}</TableCell>
      <TableCell>{StatusCell}</TableCell>
      <TableCell width={150} align="right">
        {CreateAccessFlowButtonCell}
      </TableCell>
      <TableCell align="right" width={50}>
        <ConnectedIntegrationsTableRowActions integration={integration} integrationConfig={integrationConfig} />
      </TableCell>
    </TableRow>
  );
}

function SubIntegrationSummary({
  subIntegrationsSummary,
}: {
  subIntegrationsSummary: { integrations: number; errors: number };
}) {
  return (
    <>
      {subIntegrationsSummary && (
        <Typography variant="helperText" color="text.muted">
          Sub Integrations:{' '}
          <Typography variant="helperText" component="span" color="text.primary">
            {subIntegrationsSummary.integrations} Integration{subIntegrationsSummary.integrations > 1 ? 's' : ''}
            {subIntegrationsSummary.errors > 0 && (
              <>
                {', '}
                <Typography variant="helperText" component="span" color="error.main">
                  {subIntegrationsSummary.errors} Error{subIntegrationsSummary.errors > 1 ? 's' : ''}
                </Typography>
              </>
            )}
          </Typography>
        </Typography>
      )}
    </>
  );
}

function useConnectedIntegrationsTableRowActions(integration: Integration) {
  const navigate = useNavigate();
  const { track } = useAnalyticsContext();
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);

  const { isDeleteIntegrationLoading, deleteIntegration } = useDeleteIntegration();
  const { isRefreshIntegrationLoading, refreshIntegration } = useRefreshIntegration();

  const isActionsLoading = useMemo(
    () => isDeleteIntegrationLoading || isRefreshIntegrationLoading,
    [isDeleteIntegrationLoading, isRefreshIntegrationLoading],
  );

  const openConfirmDeleteDialog = () => setIsConfirmDeleteOpened(true);
  const closeConfirmDeleteDialog = () => setIsConfirmDeleteOpened(false);

  const handleOnEdit = () => {
    track(CustomAnalyticsEvents.EDIT_INTEGRATION, {
      id: integration.id,
    });
    navigate(`/catalog/edit-integration/${integration.id}`);
  };

  const handleOnView = () => {
    track(CustomAnalyticsEvents.VIEW_INTEGRATION, {
      id: integration.id,
    });
    navigate(`/catalog/view-integration/${integration.id}`);
  };

  const handleOnRefresh = async () => {
    track(CustomAnalyticsEvents.REFRESH_INTEGRATION, {
      id: integration.id,
    });
    refreshIntegration(integration.id);
  };

  const handleOnConfirmDelete = async () => {
    track(CustomAnalyticsEvents.DELETE_INTEGRATION, {
      id: integration.id,
    });
    deleteIntegration(integration.id);
  };

  return {
    isActionsLoading,
    isConfirmDeleteOpened,
    openConfirmDeleteDialog,
    closeConfirmDeleteDialog,
    handleOnEdit,
    handleOnView,
    handleOnRefresh,
    handleOnConfirmDelete,
  };
}

function ConnectedIntegrationsTableRowActions({ integration, integrationConfig }: ConnectedIntegrationsTableRowProps) {
  const {
    isActionsLoading,
    isConfirmDeleteOpened,
    openConfirmDeleteDialog,
    closeConfirmDeleteDialog,
    handleOnEdit,
    handleOnView,
    handleOnRefresh,
    handleOnConfirmDelete,
  } = useConnectedIntegrationsTableRowActions(integration);
  const navigate = useNavigate();

  const options: ActionsMenuV2Option[] = [];

  if (!integration.parent_integration_id) {
    options.push(
      {
        label: 'Edit',
        iconSymbol: 'edit_square',
        onClick: handleOnEdit,
      },
      {
        label: 'Delete',
        iconSymbol: 'delete',
        onClick: openConfirmDeleteDialog,
      },
    );
  } else {
    options.push({
      label: 'View',
      iconSymbol: 'visibility',
      onClick: handleOnView,
    });
  }

  if (integrationConfig?.category === IntegrationCategoryAppModel.Resources) {
    options.push({
      label: 'Resources',
      iconSymbol: 'note_stack',
      onClick: () => navigate(generatePath(routes.IntegrationResources.path, { id: integration.id })),
    });
  }

  options.push({
    label: 'Refresh',
    iconSymbol: 'cached',
    onClick: handleOnRefresh,
    disabled: integration.status === IntegrationStatus.Refreshing,
  });

  return (
    <>
      <ActionsMenuV2 loading={isActionsLoading} options={options} />
      {isConfirmDeleteOpened && (
        <ConfirmDeleteDialog
          integration={integration}
          open={isConfirmDeleteOpened}
          onClose={closeConfirmDeleteDialog}
          onConfirm={handleOnConfirmDelete}
        />
      )}
    </>
  );
}

function ConfirmDeleteDialog({
  integration,
  open,
  onClose,
  onConfirm,
}: ConfirmDialogProps & {
  integration: Integration;
}) {
  const { canDeleteIntegration, isCanDeleteIntegrationLoading } = useCanDeleteIntegration(integration.id);

  if (isCanDeleteIntegrationLoading) {
    return <ConfirmDialog open={open} onClose={onClose} loading={isCanDeleteIntegrationLoading}></ConfirmDialog>;
  }

  if (!canDeleteIntegration?.can_delete) {
    const hasAccessFlows =
      canDeleteIntegration?.access_flows_used && canDeleteIntegration?.access_flows_used.length > 0;
    const hasAccessRequests =
      canDeleteIntegration?.access_requests_used && canDeleteIntegration?.access_requests_used.length > 0;
    return (
      <ConfirmDialog open={open} onClose={onClose} disproveLabel="Ok">
        <Stack direction={'column'} spacing={2}>
          {hasAccessFlows && <AccessFlowsUsed accessFlows={canDeleteIntegration.access_flows_used} />}
          {hasAccessRequests && <AccessRequestsUsed accessRequests={canDeleteIntegration?.access_requests_used} />}
        </Stack>
      </ConfirmDialog>
    );
  }

  return (
    <ConfirmDialog open={open} onClose={onClose} onConfirm={onConfirm}>
      Are you sure you want to delete <strong>{integration.name}</strong> integration?
    </ConfirmDialog>
  );
}

function AccessFlowsUsed({ accessFlows }: { accessFlows: AccessFlowsLinkedToIntegration[] }) {
  return (
    <div>
      This integration is used by those access flows:
      <ul>
        {accessFlows.map((flow) => (
          <li key={flow.id}>
            <Link
              component={RouterLink}
              to={generatePath(routes.EditAccessFlow.path, { id: flow.id })}
              underline="hover"
            >
              {flow.name}
            </Link>
          </li>
        ))}
      </ul>
      Please, remove it from the flows before deleting.
    </div>
  );
}

function AccessRequestsUsed({ accessRequests }: { accessRequests: ActiveAccessRequestLinkedToIntegration[] }) {
  return (
    <div>
      This integration is used by those access requests:
      <ul>
        {accessRequests.map((request) => (
          <li key={request.id}>
            <Link
              component={RouterLink}
              to={`${generatePath(routes.Activity.path)}?request_id=${request.id}`}
              underline="hover"
            >
              {request.friendly_id}
            </Link>
          </li>
        ))}
      </ul>
      Please, revoke active access before deleting.
    </div>
  );
}
