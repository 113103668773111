import { useMemo, useRef } from 'react';
import { RecoilValue, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil';
import { axios, AxiosError } from '@libs';

interface AsyncValueState<T> {
  value: T;
  freshValue: T;
  isLoading: boolean;
  error?: AxiosError;
  reload: () => void;
}

export function useAsyncState<T>(recoilValue: RecoilValue<T>, defaultValue: T): AsyncValueState<T> {
  const { contents, state } = useRecoilValueLoadable(recoilValue);
  const refresh = useRecoilRefresher_UNSTABLE(recoilValue);
  const previousValueRef = useRef<T>(defaultValue);
  const previousErrorRef = useRef<AxiosError | undefined>();

  const isAxiosError = axios.isAxiosError(contents);

  if (state === 'hasValue') {
    previousValueRef.current = contents;
  }

  if (state === 'hasError' || isAxiosError) {
    previousErrorRef.current = contents;
    previousValueRef.current = defaultValue;
  }

  return useMemo(
    () => ({
      isLoading: state === 'loading',
      value: previousValueRef.current ?? defaultValue,
      freshValue: state === 'hasValue' ? contents : defaultValue,
      error: previousErrorRef.current,
      reload: refresh,
    }),
    [contents, defaultValue, refresh, state],
  );
}
