import {
  AccessGraphAccessTargetNode,
  AccessGraphActiveAccessEdge,
  AccessGraphAttributeConditionAndNode,
  AccessGraphAttributeConditionOrNode,
  AccessGraphAvailableAccessEdge,
  AccessGraphChildNode,
  AccessGraphEdge,
  AccessGraphNode,
  AccessGraphResourceMoreNode,
  AccessGraphResourceNode,
  AccessGraphUserMoreNode,
  AccessGraphUserNode,
} from '@api/gql/graphql';

export function isAndConditionNode(
  node: AccessGraphNode | AccessGraphChildNode,
): node is AccessGraphAttributeConditionAndNode {
  return (node as AccessGraphAttributeConditionAndNode).attributeConditionAnd != undefined;
}

export function isOrConditionNode(
  node: AccessGraphNode | AccessGraphChildNode,
): node is AccessGraphAttributeConditionOrNode {
  return (node as AccessGraphAttributeConditionOrNode).attributeConditionOr != undefined;
}

export function isAccessTargetNode(node: AccessGraphNode | AccessGraphChildNode): node is AccessGraphAccessTargetNode {
  return (node as AccessGraphAccessTargetNode).accessTarget != undefined;
}

export function isUserNode(node: AccessGraphChildNode): node is AccessGraphUserNode {
  return (node as AccessGraphUserNode).user != undefined;
}

export function isUserMoreNode(node: AccessGraphChildNode): node is AccessGraphUserMoreNode {
  return (node as AccessGraphUserMoreNode).userMore != undefined;
}

export function isResourceNode(node: AccessGraphChildNode): node is AccessGraphResourceNode {
  return (node as AccessGraphResourceNode).resource != undefined;
}

export function isResourceMoreNode(node: AccessGraphChildNode): node is AccessGraphResourceMoreNode {
  return (node as AccessGraphResourceMoreNode).resourceMore != undefined;
}

export function isAvailableAccessEdge(
  edge: AccessGraphEdge | AccessGraphChildNode,
): edge is AccessGraphAvailableAccessEdge {
  return (edge as AccessGraphAvailableAccessEdge).availableAccess != undefined;
}

export function isActiveAccessEdge(edge: AccessGraphEdge | AccessGraphChildNode): edge is AccessGraphActiveAccessEdge {
  return (edge as AccessGraphActiveAccessEdge).activeAccess != undefined;
}
