import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface BundleItemGridProps {
  bundle: ReactNode;
  targets: ReactNode;
  actions: ReactNode;
}

export default function BundleItemGrid({ bundle, targets, actions }: BundleItemGridProps) {
  return (
    <Grid container alignItems="center" columnSpacing={2}>
      <Grid item xs={3}>
        {bundle}
      </Grid>
      <Grid item xs={7}>
        {targets}
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        {actions}
      </Grid>
    </Grid>
  );
}
