import {
  ConnectedSubIntegrationResourceTypesAppModel,
  IntegrationAppModel,
  IntegrationConfigAppModelV2,
  IntegrationConfigParamAppModel,
  IntegrationConfigWithSubIntegrations,
} from '@api';
import { IntegrationConfigFormPayload, SecretStoreType } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { UseFormWatch } from 'react-hook-form';

export function getMetadataDefaults(config?: IntegrationConfigWithSubIntegrations) {
  return config?.params?.reduce((acc, param) => ({ ...acc, [param.id]: param.default }), {}) || {};
}

export function getInitialSecretConfig(config?: IntegrationConfigAppModelV2) {
  return { type: config?.supported_secret_types?.[0] || SecretStoreType.AWS };
}

export function getAllowedResourceTypes(config?: IntegrationConfigAppModelV2) {
  if (!config) return [];
  const allowedResourceTypes = [];
  const configResourceTypes = Object.entries(config.resource_types);

  for (const [resourceTypeKey, value] of configResourceTypes) {
    if (value.requestable) {
      allowedResourceTypes.push(resourceTypeKey);
    }
  }

  return allowedResourceTypes;
}

export function getCreateFormDefaultValues(config?: IntegrationConfigWithSubIntegrations) {
  const resourceTypes = getAllowedResourceTypes(config);
  const defaultValues: ConnectedSubIntegrationResourceTypesAppModel[] = config?.subIntegrationConfigs?.map(
    (subConfig) => ({ integration_type: subConfig.type, connected_resource_types: [] }),
  ) ?? [{ integration_type: '', connected_resource_types: [] }];
  return {
    type: config?.type,
    connected_resource_types: resourceTypes.length === 1 ? resourceTypes : [],
    metadata: getMetadataDefaults(config),
    secret_config: getInitialSecretConfig(config),
    connected_sub_integration_resource_types: defaultValues,
  };
}

export function getIntegrationDefaultValues(integration?: IntegrationAppModel, config?: IntegrationConfigAppModelV2) {
  return {
    name: integration?.name,
    type: integration?.type,
    metadata: integration?.metadata,
    secret_config: integration?.secret_config || getInitialSecretConfig(config),
    provisioner_id: integration?.provisioner_id,
    connected_resource_types: integration?.connected_resource_types,
    custom_access_details_settings: integration?.custom_access_details_settings,
    integration_owners: integration?.integration_owners,
    resource_owners_mappings: integration?.resource_owners_mappings,
    connected_sub_integration_resource_types: integration?.connected_sub_integration_resource_types,
  };
}

export function checkIntegrationConfigDisabled(
  config: IntegrationConfigAppModelV2,
  watch: UseFormWatch<IntegrationConfigFormPayload>,
) {
  return (
    config.params
      .filter((p) => !p.optional)
      .map((p) => p.id)
      .some((param) => !watch(`metadata.${param as keyof IntegrationConfigFormPayload}`)) || !watch('name')
  );
}

export function checkSecretStoreDisabled(
  config: IntegrationConfigAppModelV2,
  watch: UseFormWatch<IntegrationConfigFormPayload>,
) {
  if (!config.requires_secret) return false;
  const secretConfig = watch('secret_config');
  if (!secretConfig) return true;

  if (secretConfig.type === SecretStoreType.APONO)
    return config.secret_params.some((p) => !watch(`secret_config.params.${p.id}`) && !p.optional);

  return Object.values(secretConfig).some((v) => !v);
}

export function groupParamsByCategory(config: IntegrationConfigAppModelV2) {
  const credentialsRotationsParams: IntegrationConfigParamAppModel[] = [];
  const userDeletionParams: IntegrationConfigParamAppModel[] = [];
  const otherParams: IntegrationConfigParamAppModel[] = [];

  config.params.forEach((p) => {
    if (p.category === 'Credentials Rotation Policy') {
      credentialsRotationsParams.push(p);
    } else if (p.category === 'User Deletion Policy') {
      userDeletionParams.push(p);
    } else {
      otherParams.push(p);
    }
  });

  return {
    credentialsRotationsParams,
    userDeletionParams,
    otherParams,
  };
}

export function useIsInViewMode() {
  return window.location.href.includes('view-integration');
}
