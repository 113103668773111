import { Grid, styled } from '@mui/material';
import { COLUMNS_LAYOUT_HEADER_HEIGHT } from './ColumnsLayoutHeader';
import { ReactNode } from 'react';

export interface ColumnsLayoutPros {
  header?: ReactNode;
  bordered?: boolean;
  left?: ReactNode;
  right?: ReactNode;
}

const ColumnsLayoutContainer = styled(Grid)(({ theme }) => ({
  height: '100vh',

  '&.columns-layout-with-header': {
    height: `calc(100vh - ${COLUMNS_LAYOUT_HEADER_HEIGHT})`,
  },

  '&.columns-layout-bordered > .MuiGrid-item:first-of-type': {
    borderRight: `1px solid ${theme.palette.border.default}`,
  },
}));

export function ColumnsLayout({ header, bordered, left, right }: ColumnsLayoutPros) {
  const className = [];
  if (bordered) className.push('columns-layout-bordered');
  if (header) className.push('columns-layout-with-header');

  return (
    <>
      {header}
      <ColumnsLayoutContainer
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className={className.join(' ')}
      >
        <Grid item xs={7}>
          {left}
        </Grid>
        <Grid item xs={5}>
          {right}
        </Grid>
      </ColumnsLayoutContainer>
    </>
  );
}
