import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ActivityService } from '@api/services';
import { QueryKeys } from '@api/query';
import { ModifyActivityReportModel } from '@api/apn-core';

export function useListReportTemplates() {
  const { data: reportTemplates, isFetched: isReportTemplatesFetched } = useQuery({
    queryKey: [QueryKeys.ReportTemplates],
    queryFn: async () => {
      const resp = await ActivityService.listReportTemplates();
      if (!resp || !resp.data) return [];
      const data = resp.data;
      return data.sort((a, b) => (a.created_date < b.updated_date ? 1 : -1));
    },
    initialData: [],
    refetchOnWindowFocus: false,
  });

  return {
    reportTemplates,
    isReportTemplatesFetched,
  };
}

export function useCreateReportTemplate() {
  const queryClient = useQueryClient();

  const {
    mutate: createReportTemplate,
    isLoading: isCreateReportTemplateLoading,
    isSuccess: isReportTemplateCreated,
  } = useMutation({
    mutationFn: async (payload: ModifyActivityReportModel) =>
      ActivityService.createReportTemplate({
        modifyActivityReportModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ReportTemplates] });
    },
  });

  return {
    createReportTemplate,
    isCreateReportTemplateLoading,
    isReportTemplateCreated,
  };
}

export function useGetReportTemplate(id: string) {
  const { data: reportTemplate, isFetched: isReportTemplateFetched } = useQuery({
    queryKey: [QueryKeys.ReportTemplates, id],
    queryFn: async () => ActivityService.getReportTemplate({ id }),
    refetchOnWindowFocus: false,
  });

  return {
    reportTemplate,
    isReportTemplateFetched,
  };
}

export interface UseUpdateReportTemplateProps {
  id: string;
  payload: ModifyActivityReportModel;
}

export function useUpdateReportTemplate() {
  const queryClient = useQueryClient();

  const {
    mutate: updateReportTemplate,
    isLoading: isUpdateReportTemplateLoading,
    isSuccess: isReportTemplateUpdated,
  } = useMutation({
    mutationFn: async ({ id, payload }: UseUpdateReportTemplateProps) =>
      ActivityService.updateReportTemplate({
        id,
        modifyActivityReportModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ReportTemplates] });
    },
  });

  return {
    updateReportTemplate,
    isUpdateReportTemplateLoading,
    isReportTemplateUpdated,
  };
}

export function useDeleteReportTemplate() {
  const queryClient = useQueryClient();

  const {
    mutate: deleteReportTemplate,
    isLoading: isDeleteReportTemplateLoading,
    isSuccess: isReportTemplateDeleted,
  } = useMutation({
    mutationFn: async (id: string) => ActivityService.deleteReportTemplate({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ReportTemplates] });
    },
  });

  return {
    deleteReportTemplate,
    isDeleteReportTemplateLoading,
    isReportTemplateDeleted,
  };
}
