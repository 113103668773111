import { PropsWithChildren, ReactNode } from 'react';
import { Avatar, Link, Stack, styled, Typography } from '@mui/material';
import { RouterLink } from '@libs';

const WidgetCardListItemAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
}));

export interface WidgetCardListItemProps {
  primary: ReactNode;
  secondary?: ReactNode;
  icon?: ReactNode;
  iconSrc?: string;
  count?: ReactNode;
  actionLabel?: string;
  actionTo?: string;
}

export function WidgetCardListItem({
  primary,
  secondary,
  icon,
  iconSrc,
  count,
  actionLabel,
  actionTo,
}: WidgetCardListItemProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <WidgetCardListItemIcon icon={icon} iconSrc={iconSrc} />
        <WidgetCardListItemText primary={primary} secondary={secondary} />
      </Stack>
      <WidgetCardListItemActions count={count} actionLabel={actionLabel} actionTo={actionTo} />
    </Stack>
  );
}

function WidgetCardListItemIcon({ icon, iconSrc }: Pick<WidgetCardListItemProps, 'icon' | 'iconSrc'>) {
  if (icon) {
    return <WidgetCardListItemAvatar>{icon}</WidgetCardListItemAvatar>;
  }

  if (iconSrc) {
    return <WidgetCardListItemAvatar src={iconSrc} />;
  }

  return null;
}

function WidgetCardListItemText({ primary, secondary }: Pick<WidgetCardListItemProps, 'primary' | 'secondary'>) {
  return (
    <Stack direction="column">
      <Typography variant="caption">{primary}</Typography>
      {secondary && <WidgetCardListItemTextSecondaryText>{secondary}</WidgetCardListItemTextSecondaryText>}
    </Stack>
  );
}

function WidgetCardListItemTextSecondaryText({ children }: PropsWithChildren<unknown>) {
  if (typeof children === 'string') {
    return (
      <Typography variant="helperText" color="text.muted">
        {children}
      </Typography>
    );
  }

  return <>{children}</>;
}

function WidgetCardListItemActions({
  count,
  actionLabel,
  actionTo,
}: Pick<WidgetCardListItemProps, 'count' | 'actionLabel' | 'actionTo'>) {
  if (!count && !actionLabel && !actionTo) return null;

  return (
    <Stack direction="column" alignItems="flex-end">
      {count && (
        <Typography variant="subtitle2" color="text.secondary">
          {count}
        </Typography>
      )}
      {actionLabel && actionTo && (
        <Link to={actionTo} component={RouterLink} variant="tooltip">
          {actionLabel}
        </Link>
      )}
    </Stack>
  );
}

export interface WidgetCardListProps {
  items: WidgetCardListItemProps[];
}

export function WidgetCardList({ items }: WidgetCardListProps) {
  return (
    <Stack direction="column" spacing={2}>
      {items.map((item, index) => (
        <WidgetCardListItem key={index} {...item} />
      ))}
    </Stack>
  );
}
