import { MaterialIcon, NoResults, TableWrapper } from '@components';
import { Stack, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { RightSizingInsightFieldsFragment } from '@api/gql/graphql';
import { getFragmentData } from '@api/gql';
import { INTEGRATION_FRAGMENT, RESOURCE_TYPE_FRAGMENT } from '@AccessRightSizing/services/gql';
import { getInsightConfig, secondsToHours } from '@AccessRightSizing/common/common';
import { DURATION_METRIC } from '@AccessRightSizing/common/constants';
import {
  INSIGHT_TYPE_FILTER_PARAM,
  INTEGRATION_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_PARAM,
} from '@AccessRightSizing/components/AccessRightSizingInsightFilters/AccessRightSizingInsightFilters';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

export function AccessRightSizingReportTable({
  insights,
  setChosenInsight,
  chosenInsightId,
}: {
  insights: readonly RightSizingInsightFieldsFragment[];
  setChosenInsight: (insight: RightSizingInsightFieldsFragment) => void;
  chosenInsightId?: string;
}) {
  const [searchParams] = useSearchParams();

  const filters = getFiltersFromSearchParams(searchParams);

  const filteredInsights = useMemo(() => {
    return insights.filter((insight) => {
      if (filters.integration && filters.integration.length > 0) {
        const integration = getFragmentData(INTEGRATION_FRAGMENT, insight.integration);
        if (!integration || (integration && !filters.integration.includes(integration.id))) {
          return false;
        }
      }
      if (filters.resourceType && filters.resourceType.length > 0) {
        const resourceType = getFragmentData(RESOURCE_TYPE_FRAGMENT, insight.resourceType);
        if (!resourceType || (resourceType && !filters.resourceType.includes(resourceType.type))) {
          return false;
        }
      }
      if (filters.insightType && filters.insightType.length > 0 && !filters.insightType.includes(insight.insightType)) {
        return false;
      }
      return true;
    });
  }, [insights, filters]);

  if (!filteredInsights.length) return <NoResults>No insights found.</NoResults>;

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <TableWrapper>
        <MuiTable>
          <TableHead>
            <TableRow>
              <TableCell component="th" size="medium">
                INTEGRATION
              </TableCell>
              <TableCell component="th" size="medium">
                RESOURCE TYPE
              </TableCell>
              <TableCell component="th" size="medium">
                INSIGHT TYPE
              </TableCell>
              <TableCell component="th" size="medium">
                FINDINGS
              </TableCell>
              <TableCell component="th" size="medium">
                RECOMMENDATIONS
              </TableCell>
              <TableCell component="th" size="medium"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInsights.map((insight) => {
              if (!insight.integration || !insight.resourceType) return null;
              const integration = getFragmentData(INTEGRATION_FRAGMENT, insight.integration);
              const resourceType = getFragmentData(RESOURCE_TYPE_FRAGMENT, insight.resourceType);
              const config = getInsightConfig(insight.insightType);
              const findingsPrimaryText =
                insight.insightType === DURATION_METRIC
                  ? secondsToHours(insight.findingsCount)
                  : `${insight.findingsCount} ${config.entity}`;
              return (
                <TableRow key={insight.id} selected={chosenInsightId === insight.id}>
                  <TableCell component="td">
                    {integration && (
                      <PrimarySecondary
                        primary={integration.name}
                        secondary={integration.typeDisplayName}
                        icon={integration.icons.svg}
                      />
                    )}
                  </TableCell>
                  <TableCell component="td">
                    {resourceType && (
                      <PrimarySecondary
                        icon={resourceType.icons.svg}
                        iconSpacing={1.5}
                        primary={resourceType.typeDisplayName}
                      />
                    )}
                  </TableCell>
                  <TableCell component="td">
                    <PrimarySecondary primary={config.title} maxWidth={250} />
                  </TableCell>
                  <TableCell component="td">
                    <Stack direction="column" spacing={1}>
                      <PrimarySecondary primary={findingsPrimaryText} secondary={config.findingsText} maxWidth={250} />
                    </Stack>
                  </TableCell>
                  <TableCell component="td">
                    <PrimarySecondary
                      primary={config.recommendation}
                      maxWidth={250}
                      icon={<MaterialIcon color={'primary'} symbol="recommend" />}
                    />
                  </TableCell>
                  <TableCell component="td" width={50}>
                    <Button
                      variant="explore-button"
                      onClick={() => {
                        setChosenInsight(insight);
                      }}
                      size="small"
                    >
                      {' '}
                      <MaterialIcon symbol="auto_awesome" weight={800} size="small" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableWrapper>
    </Stack>
  );
}

function getFiltersFromSearchParams(searchParams: URLSearchParams) {
  const filters = {
    integration: searchParams.getAll(INTEGRATION_FILTER_PARAM) || undefined,
    resourceType: searchParams.getAll(RESOURCE_TYPE_FILTER_PARAM) || undefined,
    insightType: searchParams.getAll(INSIGHT_TYPE_FILTER_PARAM) || undefined,
  };

  return filters;
}
