import { ReactNode } from 'react';
import { Badge, Chip, styled, Typography } from '@mui/material';

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  height: '20px',
  backgroundColor: theme.palette.background.paper,
}));

function NewBadge({ icon, asBadge = true }: { icon?: ReactNode; asBadge?: boolean }) {
  if (asBadge) {
    return (
      <Badge
        sx={{ '& .MuiBadge-badge': { top: '-4px', right: '-1px' } }}
        badgeContent={
          <StyledChip label={<Typography fontSize="10px">New</Typography>} variant="outlined" color="warning" />
        }
      >
        {icon}
      </Badge>
    );
  }
  return <StyledChip label={<Typography fontSize="10px">New</Typography>} variant="outlined" color="warning" />;
}

export default NewBadge;
