export default function Brand({ fill }: { fill: string }) {
  return (
    <div>
      <svg width="44" height="9" viewBox="0 0 43 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.107 8C9.98096 8 9.86011 7.94834 9.77101 7.85639C9.6819 7.76444 9.63184 7.63972 9.63184 7.50968V0.490316C9.63184 0.360276 9.6819 0.235564 9.77101 0.143611C9.86011 0.0516593 9.98096 0 10.107 0H13.0091C13.6634 -1.79784e-06 14.291 0.267814 14.7541 0.744693C15.2173 1.22157 15.4783 1.86857 15.4798 2.54376C15.479 3.20949 15.2222 3.84766 14.7657 4.31803C14.3091 4.7884 13.6903 5.05248 13.0452 5.05222H12.2251C12.0991 5.05222 11.9783 5.00056 11.8892 4.90861C11.8001 4.81666 11.75 4.69194 11.75 4.5619C11.75 4.43186 11.8001 4.30715 11.8892 4.2152C11.9783 4.12325 12.0991 4.07159 12.2251 4.07159H13.0452C13.2399 4.07184 13.4327 4.03253 13.6127 3.95589C13.7926 3.87925 13.9562 3.76678 14.094 3.62491C14.2318 3.48304 14.3412 3.31454 14.416 3.12904C14.4907 2.94353 14.5293 2.74466 14.5295 2.54376C14.528 2.12865 14.3672 1.73108 14.0822 1.4381C13.7972 1.14513 13.4114 0.98063 13.0091 0.980632H10.5793V7.50968C10.5793 7.63922 10.5296 7.76348 10.4411 7.85535C10.3526 7.94722 10.2325 7.99922 10.107 8Z"
          fill={fill}
        />
        <path
          d="M20.6399 8C19.8915 8 19.1599 7.7654 18.5376 7.32588C17.9153 6.88635 17.4303 6.26164 17.1439 5.53074C16.8575 4.79983 16.7826 3.99556 16.9286 3.21964C17.0746 2.44372 17.435 1.73098 17.9642 1.17157C18.4934 0.612164 19.1676 0.231201 19.9017 0.0768604C20.6357 -0.0774805 21.3965 0.00173401 22.0879 0.304484C22.7794 0.607235 23.3703 1.11992 23.7861 1.77772C24.2019 2.43552 24.4238 3.20887 24.4238 4C24.4229 5.06055 24.0239 6.07736 23.3145 6.82728C22.605 7.5772 21.6431 7.99896 20.6399 8ZM20.6399 0.980634C20.075 0.980634 19.5227 1.15772 19.053 1.48949C18.5833 1.82126 18.2172 2.29282 18.001 2.84454C17.7848 3.39626 17.7282 4.00335 17.8384 4.58905C17.9487 5.17475 18.2207 5.71275 18.6202 6.13502C19.0196 6.55728 19.5286 6.84485 20.0826 6.96135C20.6367 7.07786 21.211 7.01806 21.7329 6.78953C22.2548 6.561 22.7009 6.174 23.0148 5.67747C23.3287 5.18094 23.4962 4.59717 23.4962 4C23.4952 3.19953 23.1939 2.43215 22.6585 1.86613C22.1231 1.30012 21.3971 0.981672 20.6399 0.980634Z"
          fill={fill}
        />
        <path
          d="M39.216 8C38.4676 8 37.7361 7.7654 37.1138 7.32588C36.4915 6.88635 36.0065 6.26164 35.7201 5.53074C35.4337 4.79983 35.3588 3.99556 35.5048 3.21964C35.6508 2.44372 36.0112 1.73098 36.5404 1.17157C37.0696 0.612164 37.7438 0.231201 38.4778 0.0768604C39.2119 -0.0774805 39.9727 0.00173401 40.6641 0.304484C41.3555 0.607235 41.9465 1.11992 42.3623 1.77772C42.7781 2.43552 43 3.20887 43 4C42.999 5.06055 42.6001 6.07736 41.8906 6.82728C41.1812 7.5772 40.2193 7.99896 39.216 8ZM39.216 0.980634C38.6511 0.980634 38.0989 1.15772 37.6292 1.48949C37.1595 1.82126 36.7934 2.29282 36.5772 2.84454C36.361 3.39626 36.3044 4.00335 36.4146 4.58905C36.5248 5.17475 36.7969 5.71275 37.1963 6.13502C37.5958 6.55728 38.1047 6.84485 38.6588 6.96135C39.2129 7.07786 39.7872 7.01806 40.3091 6.78953C40.831 6.561 41.2771 6.174 41.591 5.67747C41.9048 5.18094 42.0723 4.59717 42.0723 4C42.0714 3.19953 41.7701 2.43215 41.2347 1.86613C40.6992 1.30012 39.9733 0.981672 39.216 0.980634Z"
          fill={fill}
        />
        <path
          d="M32.5754 8C32.5097 7.99997 32.4448 7.98415 32.3853 7.95364C32.3258 7.92314 32.2731 7.87871 32.2311 7.82349L27.7286 1.91419V7.5038C27.7286 7.63384 27.6813 7.75855 27.5973 7.8505C27.5132 7.94246 27.3991 7.99412 27.2802 7.99412C27.1613 7.99412 27.0473 7.94246 26.9632 7.8505C26.8791 7.75855 26.8318 7.63384 26.8318 7.5038V0.562884C26.8319 0.462152 26.8603 0.36388 26.9132 0.281429C26.9661 0.198978 27.041 0.136351 27.1276 0.102064C27.2142 0.0677779 27.3084 0.0634961 27.3973 0.0898005C27.4862 0.116105 27.5656 0.17172 27.6246 0.249081L32.1271 6.15543V0.490316C32.1271 0.360276 32.1743 0.235564 32.2584 0.143611C32.3425 0.0516593 32.4565 0 32.5754 0C32.6943 0 32.8084 0.0516593 32.8925 0.143611C32.9766 0.235564 33.0238 0.360276 33.0238 0.490316V7.50968C33.0238 7.63972 32.9766 7.76444 32.8925 7.85639C32.8084 7.94834 32.6943 8 32.5754 8Z"
          fill={fill}
        />
        <path
          d="M7.85702 7.28567L4.37347 0.263545C4.33406 0.184231 4.27401 0.117638 4.19999 0.0711274C4.12596 0.0246166 4.04084 0 3.95403 0C3.86721 0 3.78209 0.0246166 3.70807 0.0711274C3.63404 0.117638 3.57401 0.184231 3.53459 0.263545L0.0510466 7.28567C-0.00551071 7.39987 -0.0155109 7.53242 0.0232452 7.65417C0.0620013 7.77592 0.146334 7.8769 0.257701 7.93489C0.369067 7.99288 0.498344 8.00313 0.617084 7.9634C0.735824 7.92366 0.834299 7.83718 0.890856 7.72299C1.08608 7.32407 1.35768 6.96957 1.68945 6.68064C2.02122 6.39171 2.40635 6.17427 2.82187 6.04131C3.221 5.91315 3.63611 5.84486 4.0543 5.83858C4.21166 5.83894 4.36886 5.84894 4.52505 5.86851C5.04897 5.94115 5.54765 6.14394 5.97772 6.45923C6.40779 6.77453 6.75622 7.19278 6.99272 7.67762L7.01531 7.72299C7.04216 7.78144 7.08018 7.83377 7.12713 7.87688C7.17407 7.91999 7.22898 7.95299 7.28859 7.97394C7.3482 7.99488 7.41131 8.00334 7.47417 7.99881C7.53703 7.99429 7.59836 7.97687 7.65454 7.94758C7.71071 7.9183 7.76057 7.87775 7.80117 7.82834C7.84178 7.77893 7.8723 7.72166 7.89092 7.65993C7.90954 7.59821 7.91588 7.53327 7.90957 7.46899C7.90327 7.4047 7.88443 7.34236 7.85419 7.28567H7.85702ZM4.05147 4.87224C3.3864 4.8775 2.72796 5.00841 2.10916 5.25839L3.95261 1.54363L5.75936 5.18309C5.21132 4.98123 4.63379 4.87644 4.05147 4.87321V4.87224Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
