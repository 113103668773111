import { MouseEvent, useState } from 'react';
import { ButtonBase, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MaterialIcon } from '@components';

export interface ActionsMenuProps {
  loading?: boolean;
  onDuplicate?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onRefresh?: () => void;
}

export function ActionsMenu({ loading, onDuplicate, onEdit, onDelete, onRefresh }: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (action: () => void) => {
    action();
    handleClose();
  };

  return (
    <div>
      <ButtonBase data-testid="actions-menu-trigger" onClick={handleClick} disabled={loading}>
        {loading ? <CircularProgress size="24px" /> : <MoreVertIcon />}
      </ButtonBase>
      <Menu
        PaperProps={{ sx: { width: '183px' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {onEdit ? (
          <MenuItem data-testid="actions-menu-edit" onClick={() => handleSelect(onEdit)}>
            <ListItemIcon>
              <MaterialIcon symbol="edit_square" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }}>Edit</ListItemText>
          </MenuItem>
        ) : null}
        {onDuplicate ? (
          <MenuItem data-testid="actions-menu-duplicate" onClick={() => handleSelect(onDuplicate)}>
            <ListItemIcon>
              <MaterialIcon symbol="content_copy" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }}>Duplicate</ListItemText>
          </MenuItem>
        ) : null}
        {onRefresh ? (
          <MenuItem data-testid="actions-menu-refresh" onClick={() => handleSelect(onRefresh)}>
            <ListItemIcon>
              <MaterialIcon symbol="cached" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }}>Refresh</ListItemText>
          </MenuItem>
        ) : null}
        {onDelete ? (
          <MenuItem data-testid="actions-menu-delete" onClick={() => handleSelect(onDelete)}>
            <ListItemIcon>
              <MaterialIcon symbol="delete" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }}>Delete</ListItemText>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
}
