import {
  AccessUnitFields_Fragment,
  AlertRequestContextFields_Fragment,
  RequestAlertAccessGroupFields_Fragment,
} from '@AccessAlerts/services/gql';
import { INTEGRATION_FRAGMENT, RESOURCE_TYPE_FRAGMENT } from '@AccessRightSizing/services/gql';
import { getFragmentData } from '@api/gql';
import { AlertFieldsFragment } from '@api/gql/graphql';
import { IntegrationsListAccessUnits } from './IntegrationsListAccessUnits';

export function AlertAccessGroups({ alertData }: { alertData: AlertFieldsFragment }) {
  const requestContext = getFragmentData(AlertRequestContextFields_Fragment, alertData.requestContext);
  const accessGroups = getFragmentData(RequestAlertAccessGroupFields_Fragment, requestContext.accessGroups);
  return (
    <>
      {accessGroups.map((group) => {
        const integration = getFragmentData(INTEGRATION_FRAGMENT, group.integration);
        const resourceType = getFragmentData(RESOURCE_TYPE_FRAGMENT, group.resourceType);

        if (!group.accessUnits || !integration || !resourceType) return null;
        const accessUnits = getFragmentData(AccessUnitFields_Fragment, group.accessUnits);

        return (
          <IntegrationsListAccessUnits
            key={integration.id}
            integration={integration}
            resourceType={resourceType}
            defaultExpanded={true}
            accessUnits={accessUnits}
          />
        );
      })}
    </>
  );
}
