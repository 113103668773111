import { WebhookTriggerEventAppModel } from '@api';

export const SEARCH_PARAM = 'search';

export const WebhookTypeAppModel = {
  Http: 'Http',
  IntegrationAction: 'IntegrationAction',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WebhookTypeAppModel = (typeof WebhookTypeAppModel)[keyof typeof WebhookTypeAppModel];

export const WebhookSchemaType = {
  UserRequest: 'UserRequest',
  RequestAlert: 'RequestAlert',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WebhookSchemaType = (typeof WebhookSchemaType)[keyof typeof WebhookSchemaType];

export const triggerOptions = {
  [WebhookSchemaType.UserRequest]: [
    WebhookTriggerEventAppModel.RequestCreated,
    WebhookTriggerEventAppModel.RequestApproved,
    WebhookTriggerEventAppModel.RequestExpired,
    WebhookTriggerEventAppModel.RequestFailed,
    WebhookTriggerEventAppModel.RequestGranted,
    WebhookTriggerEventAppModel.RequestRejected,
    WebhookTriggerEventAppModel.Manual,
  ],
  [WebhookSchemaType.RequestAlert]: [WebhookTriggerEventAppModel.RequestAnomalyDetected],
};
