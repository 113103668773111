import { Box, Stack, styled } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { aponoColors } from '@utils';

const StyledSidebarLink = styled(Link)(({ theme }) => ({
  display: 'block',
  color: aponoColors.white,
  textDecoration: 'none',

  '&.hovered': {
    color: theme.palette.primary.main,
  },
}));

const StyledSidebarA = styled('a')(({ theme }) => ({
  display: 'block',
  color: aponoColors.white,
  textDecoration: 'none',

  '&.hovered': {
    color: theme.palette.primary.main,
  },
}));

const StyledSidebarItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: 'transparent',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '1px',
  },

  '&.active': {
    backgroundColor: theme.palette.background.default,

    '&:before': {
      backgroundColor: aponoColors.primary[600],
    },
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&.hovered': {
    color: theme.palette.primary.main,
  },
  '&.no-icon': {
    paddingLeft: theme.spacing(7.5),
  },
  '&.text-active': {
    color: aponoColors.primary[400],
  },
}));

const StyledSidebarItemIconWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: theme.spacing(4),
  height: theme.spacing(3),
}));

const StyledSidebarItemStack = styled(Stack)(({ theme }) => ({
  height: theme.spacing(3),
}));

const StyledSidebarItemIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export interface SidebarItemProps {
  icon?: ReactNode;
  to?: To;
  aType?: 'internal' | 'external';
  external?: boolean;
  active?: boolean;
  textActive?: boolean;
  onClick?: () => void;
  hovered?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export function SidebarItem({
  active = false,
  textActive = false,
  icon,
  to,
  aType,
  children,
  onClick,
  hovered = false,
  onMouseEnter,
  onMouseLeave,
}: PropsWithChildren<SidebarItemProps>) {
  function renderSidebarItem() {
    const className = [];
    if (active) className.push('active');
    if (textActive) className.push('text-active');
    if (!icon) className.push('no-icon');
    if (hovered) className.push('hovered');

    return (
      <StyledSidebarItem className={className.join(' ')}>
        <StyledSidebarItemStack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          {icon ? (
            <StyledSidebarItemIconWrap>
              <StyledSidebarItemIcon>{icon}</StyledSidebarItemIcon>
            </StyledSidebarItemIconWrap>
          ) : null}
          {children}
        </StyledSidebarItemStack>
      </StyledSidebarItem>
    );
  }

  if (to) {
    if (aType) {
      return (
        <StyledSidebarA
          href={to as string}
          target={aType === 'external' ? '_blank' : '_self'}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {renderSidebarItem()}
        </StyledSidebarA>
      );
    }

    if (to === '#') {
      return (
        <StyledSidebarA
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (onClick) onClick();
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {renderSidebarItem()}
        </StyledSidebarA>
      );
    }

    return (
      <StyledSidebarLink to={to} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {renderSidebarItem()}
      </StyledSidebarLink>
    );
  }

  return renderSidebarItem();
}
