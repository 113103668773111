import { useState } from 'react';

import { MaterialIcon } from '@components';

import { Box, Checkbox, Collapse, FormControlLabel, Link, Stack, Typography, styled } from '@mui/material';

import { ResourceDetailedItem, ResourceDetailedItemProps } from '@components/ResourceDetailedItem';

const OptionItemResourcesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 3),
}));

export interface SelectTagOptionItemProps {
  value: string;
  resources: ResourceDetailedItemProps[];
  selected?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
}

function useSelectTagOptionItem() {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return {
    expanded,
    handleExpandClick,
  };
}

export function SelectTagOptionItem({ value, resources, selected, disabled, onChange }: SelectTagOptionItemProps) {
  const { expanded, handleExpandClick } = useSelectTagOptionItem();

  return (
    <Box>
      <FormControlLabel
        data-testid="interactive-dropdown-select-option"
        checked={selected}
        disabled={disabled}
        control={<Checkbox size="small" />}
        label={<SelectTagOptionItemLabel value={value} />}
        onChange={() => onChange(value)}
      />
      <Link href="#" onClick={handleExpandClick} color="white">
        <Stack direction="row" alignItems="center" spacing={0}>
          <MaterialIcon symbol={expanded ? 'arrow_drop_down' : 'arrow_right'} />
          <Typography variant="inputLabel" component="p">
            {resources.length} tagged resources
          </Typography>
        </Stack>
      </Link>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <OptionItemResourcesContainer>
          <Stack direction="column" spacing={1}>
            {resources.map((resource, i) => (
              <ResourceDetailedItem key={i} {...resource} />
            ))}
          </Stack>
        </OptionItemResourcesContainer>
      </Collapse>
    </Box>
  );
}

function SelectTagOptionItemLabel({ value }: { value: string }) {
  return (
    <Typography variant="caption" noWrap maxWidth={300} component="p" title={value}>
      {value}
    </Typography>
  );
}
