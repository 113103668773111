import { useRef, useState } from 'react';
import { axios } from '@libs';
import { createRequestFunction } from '@api/apn-core/common';
import { Alert } from '@mui/material';
import { axiosConfig, SessionApiAxiosParamCreator } from '@api';
import { useEffectOnce } from 'usehooks-ts';

interface AuthorizeResponse {
  redirect_uri: string;
}

function useAuthorizePage() {
  const [error, setError] = useState<Error | null>(null);
  const fetched = useRef(false); // Prevents multiple fetches

  useEffectOnce(() => {
    if (fetched.current) return;
    fetched.current = true;

    async function fetch() {
      try {
        const configParamCreator = SessionApiAxiosParamCreator(axiosConfig);
        const axiosArgs = await configParamCreator.getSession();

        axiosArgs.url = '/apn/auth/oauth/authorize' + window.location.search;

        const request = createRequestFunction(axiosArgs, axios, '');
        const response = await request<AuthorizeResponse>();
        if (response.redirect_uri) {
          window.location.href = response.redirect_uri;
        }
      } catch (err: Error | unknown) {
        setError(err as Error);
      }
    }

    fetch();
  });

  return { error };
}

export function AuthorizePage() {
  const { error } = useAuthorizePage();
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return <div></div>;
}
