import { MouseEvent } from 'react';

export type CustomMouseEvent = MouseEvent<HTMLElement> & {
  nativeEvent: { handledByButton?: boolean };
};

export const isEventHandledByButton = (e: CustomMouseEvent) => {
  return e.nativeEvent.handledByButton;
};

export const markEventHandledByButton = (e: CustomMouseEvent) => {
  e.nativeEvent.handledByButton = true;
};
