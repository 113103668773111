export interface DurationValue {
  amount: number;
  unitInSeconds: number;
}

export enum DurationUnitInSeconds {
  Minutes = 60,
  Hours = 3600,
  Days = 86400,
  Weeks = 604800,
}

export enum DurationUnitLabel {
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
}

export const toDurationUnitInSeconds = (seconds: number): DurationUnitInSeconds => {
  if (seconds % DurationUnitInSeconds.Weeks === 0) {
    return DurationUnitInSeconds.Weeks;
  } else if (seconds % DurationUnitInSeconds.Days === 0) {
    return DurationUnitInSeconds.Days;
  } else if (seconds % DurationUnitInSeconds.Hours === 0) {
    return DurationUnitInSeconds.Hours;
  } else {
    return DurationUnitInSeconds.Minutes;
  }
};

export const toDurationUnitLabel = (seconds: number): DurationUnitLabel => {
  const unit = toDurationUnitInSeconds(seconds);
  switch (unit) {
    case DurationUnitInSeconds.Weeks:
      return DurationUnitLabel.Week;
    case DurationUnitInSeconds.Days:
      return DurationUnitLabel.Day;
    case DurationUnitInSeconds.Hours:
      return DurationUnitLabel.Hour;
    case DurationUnitInSeconds.Minutes:
      return DurationUnitLabel.Minute;
    default:
      return DurationUnitLabel.Minute;
  }
};

export const toPluralDurationUnitLabel = (label: DurationUnitLabel): string => {
  return label + 's';
};

export const toDurationValue = (seconds: number): DurationValue => {
  const unit = toDurationUnitInSeconds(seconds);
  const duration = seconds / unit;
  return { amount: duration, unitInSeconds: unit };
};

export const fromDurationValue = (value?: DurationValue): number => {
  return value ? value.amount * value.unitInSeconds : -1;
};

export function toHumanReadableDuration(seconds: number) {
  const { amount, unitInSeconds } = toDurationValue(seconds);
  const label = toDurationUnitLabel(unitInSeconds).toLocaleLowerCase();
  return `${amount} ${label + (amount > 1 ? 's' : '')}`;
}
