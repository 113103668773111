import { ResourceAppModel, useAppGetIntegration, useIntegrationResourcesV2 } from '@api';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { RESOURCE_TYPE_FILTER_PARAM } from '@common/constants';
import { ResourceTypeFilterOption } from '@common/organisms/CommonFilters/ResourceTypeFilter';
import { StatusFilterOption } from '@Integrations/organisms/IntegrationResourcesFilters/StatusFilter';
import { TagFilterOption } from '@Integrations/organisms/IntegrationResourcesFilters/TagFilter';
import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';

const STATUS_FILTER_PARAM = 'status';
const TAG_FILTER_PARAM = 'tags';

function useRemappedFilterSearchParams() {
  const [searchParams] = useSearchParams();

  const isFilteredBy = useCallback(
    (param: string, filter: Set<string>) => {
      if (!searchParams.has(param)) return true;
      if (filter.size === 0) return false;

      let isFiltered = false;
      filter.forEach((value) => {
        if (searchParams.getAll(param).includes(value)) isFiltered = true;
      });

      return isFiltered;
    },
    [searchParams],
  );

  return {
    isFilteredByResourceType: (filter: Set<string>) => isFilteredBy(RESOURCE_TYPE_FILTER_PARAM, filter),
    isFilteredByStatus: (filter: Set<string>) => isFilteredBy(STATUS_FILTER_PARAM, filter),
    isFilteredByTags: (filter: Set<string>) => isFilteredBy(TAG_FILTER_PARAM, filter),
  };
}

export default function useIntegrationResourcesFetch(integrationId: string) {
  const [searchParams] = useSearchParams();
  const { integrationConfig, isIntegrationFetched } = useAppGetIntegration(integrationId);
  const { isFetched: isResourcesFetched, integrationResources } = useIntegrationResourcesV2(integrationId);

  const { isFilteredByResourceType, isFilteredByTags, isFilteredByStatus } = useRemappedFilterSearchParams();

  return useMemo(() => {
    const integrationResourcesList: ResourceAppModel[] = [];

    const resourceTypeFilterOptionsMap: Record<string, ResourceTypeFilterOption> = {};
    const statusFilterOptionsMap: Record<string, StatusFilterOption> = {};
    const tagsFilterOptionsMap: Record<string, TagFilterOption> = {};

    for (const resource of integrationResources) {
      const resourceTypesToFilterBy = new Set<string>();
      const tagsToFilterBy = new Set<string>();
      const statusToFilterBy = new Set<string>();

      if (integrationConfig) {
        const resourceType = integrationConfig.resource_types[resource.type].display_name;
        resourceTypesToFilterBy.add(resourceType);
        resourceTypeFilterOptionsMap[resourceType] = {
          value: resourceType,
          label: resourceType,
          icon: integrationConfig.resource_types[resource.type].icons.svg,
        };
      }

      statusToFilterBy.add(resource.status.status);

      statusFilterOptionsMap[resource.status.status] = {
        value: resource.status.status,
        label: resource.status.status,
      };

      const remappedTags = Object.entries(resource.tags).map(([key, value]) => `${key}: ${value}`);

      remappedTags.forEach((tag) => {
        tagsToFilterBy.add(tag);
        tagsFilterOptionsMap[tag] = {
          value: tag,
          label: tag,
        };
      });

      if (
        isFilteredByResourceType(resourceTypesToFilterBy) &&
        isFilteredByStatus(statusToFilterBy) &&
        isFilteredByTags(tagsToFilterBy)
      ) {
        integrationResourcesList.push(resource);
      }
    }

    const searchQueryParam = searchParams.get(SEARCH_QUERY_PARAM);
    const filteredIntegrationResources = integrationResourcesList.filter((r: ResourceAppModel) => {
      if (!searchQueryParam) {
        return true;
      }

      return JSON.stringify(r).toLowerCase().includes(searchQueryParam.toLowerCase());
    });

    return {
      integrationResourcesList: filteredIntegrationResources,
      isFetched: isResourcesFetched && isIntegrationFetched,
      filterOptions: {
        status: Object.values(statusFilterOptionsMap),
        resourceType: Object.values(resourceTypeFilterOptionsMap),
        tag: Object.values(tagsFilterOptionsMap),
      },
      totalIntegrations: integrationResources.length,
    };
  }, [
    searchParams,
    integrationResources,
    integrationConfig,
    isIntegrationFetched,
    isResourcesFetched,
    isFilteredByResourceType,
    isFilteredByStatus,
    isFilteredByTags,
  ]);
}
