import { useCreateGroup, useListUsers, useUpdateGroupMembers } from '@Groups/services/queries';
import AppModal from '@common/ui/AppModal';
import { LabeledTextField, SearchFilterMultipleSelect } from '@components';
import { useGlobalNotifier } from '@hooks';
import { LoadingButton } from '@mui/lab';
import { Typography, Stack, Button } from '@mui/material';
import { useEffect, useState } from 'react';

const CreateGroupModal = ({ isCreateModalOpen, onClose }: { isCreateModalOpen: boolean; onClose: () => void }) => {
  const [usersSelected, setUsersSelected] = useState<string[]>([]);
  const [groupName, setGroupName] = useState('');
  const { createGroup, isLoading: isCreatingGroupLoading, groupId } = useCreateGroup();

  const { notifySuccess } = useGlobalNotifier();

  const { users } = useListUsers();

  const { updateGroupMember } = useUpdateGroupMembers();

  const [usersAdded, setUsersAdded] = useState(false);

  useEffect(() => {
    if (groupId && !usersAdded) {
      updateGroupMember({ groupId, user_ids: usersSelected });
      setUsersAdded(true);
      notifySuccess(`Group ${groupName} created`);
      onClose();
    }
  }, [groupId, usersAdded, updateGroupMember, usersSelected, groupName, notifySuccess, onClose]);

  return (
    <AppModal open={isCreateModalOpen} onClose={onClose} header="Create Group" width={540}>
      <Stack spacing={1}>
        <LabeledTextField label="Group name" value={groupName} onChange={(event) => setGroupName(event.target.value)} />
        <Typography variant="inputLabel">Users</Typography>
        <SearchFilterMultipleSelect
          options={users?.map((user) => ({ label: `${user.first_name} ${user.last_name}`, value: user.id })) || []}
          value={usersSelected}
          onChange={setUsersSelected}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }} spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            onClick={() => createGroup(groupName)}
            loading={isCreatingGroupLoading}
            variant="contained"
            color="primary"
            disabled={groupName === '' || usersSelected.length === 0}
          >
            Create
          </LoadingButton>
        </Stack>
      </Stack>
    </AppModal>
  );
};

export default CreateGroupModal;
