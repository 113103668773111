import { useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';

export function FeatureFlagsContext() {
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
