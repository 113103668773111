import { Stack, styled, Typography } from '@mui/material';

const Dot = styled('div')({
  width: 10,
  height: 10,
  borderRadius: '2px',
});

export interface LegendItemProps {
  color: string;
  name: string;
}

function LegendItem({ color, name }: LegendItemProps) {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <Dot sx={{ backgroundColor: color }} />
      <Typography variant="helperText">{name}</Typography>
    </Stack>
  );
}

export interface ChartLegendsProps {
  legends: LegendItemProps[];
}

export function ChartLegends({ legends }: ChartLegendsProps) {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      {legends.map((legend) => (
        <LegendItem key={legend.name} {...legend} />
      ))}
    </Stack>
  );
}
