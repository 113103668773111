import { useState } from 'react';
import { QueryIntegrationResourcesArgs } from '@api/gql/graphql';
import { IntegrationsQueryKeys } from '@Integrations/services/queryKeys';
import { useAppQuery } from '@api/query/use-app-query';
import { FrontierService } from '@api';
import { integrationResourcesQueryDocument } from './gql';

export function useResourcesInventory(args: QueryIntegrationResourcesArgs) {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(integrationResourcesQueryDocument, args);
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery([IntegrationsQueryKeys.ListResources, JSON.stringify(args)], fetch, {
    keepPreviousData: true,
  });

  return { ...res, isFetched: isFirstTimeFetched };
}
