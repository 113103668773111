import { AccessUnitStatus, ActivityRequestAppModel } from '@api';
import { AccessStatusModel } from '@common/constants';

export const TabValues = {
  Granted: 'granted',
  Revoked: 'revoked',
  Errors: 'errors',
};

export const AccessUnitsFailedStatuses = [
  AccessUnitStatus.Failure,
  AccessUnitStatus.FailedRevoking,
  AccessUnitStatus.FailedGranting,
];

export const AccessUnitsGrantedStatuses: string[] = [
  AccessStatusModel.PENDING,
  AccessStatusModel.APPROVED,
  AccessStatusModel.REJECTED,
  AccessStatusModel.GRANTED,
  AccessStatusModel.Active,
];

export function determineGrantedOrRevokedTab(request: ActivityRequestAppModel) {
  if (AccessUnitsGrantedStatuses.includes(request.status)) {
    return TabValues.Granted;
  } else {
    return TabValues.Revoked;
  }
}
