import { Page } from '@components';
import { ConnectIntegrationsGroupContent } from '@Integrations/organisms/ConnectIntegration/ConnectIntegrationsGroupContent';
import { routes } from '@routes';

export function OnboardingConnectIntegrationGroupPage() {
  return (
    <Page contentCentered contentPadded>
      <ConnectIntegrationsGroupContent
        paths={{
          catalogPath: routes.OnboardingWelcome.path,
          editPath: routes.OnboardingEditIntegration.path,
        }}
        align="center"
      />
    </Page>
  );
}
