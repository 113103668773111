export const RESOURCE_NODE_WIDTH = 180;
export const RESOURCE_NODE_HEIGHT = 50;
export const INTEGRATION_NODE_HEIGHT = 40;
export const RESOURCE_NODE_HEIGHT_EXTRA = 40;
export const RESOURCE_NODES_GAP = 32;
export const GROUPED_NODE_H_PADDING = 10;
export const GROUPED_NODE_COLLAPSE_BUTTON_HEIGHT = 16;
export const GROUPED_NODE_WIDTH = RESOURCE_NODE_WIDTH + 2 * GROUPED_NODE_H_PADDING;
export const COLLAPSED_GROUPED_NODE_HEIGHT = RESOURCE_NODE_HEIGHT + 2 * RESOURCE_NODES_GAP;

import { ResourceNode, ResourceNodeInput, ResourceNodeOutput } from '../ResourceNode';
import { GroupedNode, GroupedNodeExpanded, GroupedNodeInput, GroupedNodeOutput } from '../GroupedNode';
import { MoreNode, MoreNodeInput, MoreNodeOutput } from '../MoreNode';

export const NodeTypes = {
  resourceInput: ResourceNodeInput,
  resourceOutput: ResourceNodeOutput,
  resource: ResourceNode,
  grouped: GroupedNode,
  groupedInput: GroupedNodeInput,
  groupedOutput: GroupedNodeOutput,
  groupedExpanded: GroupedNodeExpanded,
  more: MoreNode,
  moreInput: MoreNodeInput,
  moreOutput: MoreNodeOutput,
} as const;
