import { useLocation } from 'react-router-dom';

export function useActiveLocation() {
  const location = useLocation();

  function isActive(path: string | string[], exact?: boolean) {
    if (!Array.isArray(path)) {
      return exact ? location.pathname === path : location.pathname.startsWith(path);
    }

    let notMatched = false;
    for (const p of path) {
      if (exact ? location.pathname === p : location.pathname.startsWith(p)) {
        notMatched = true;
      }
    }

    return notMatched;
  }

  return isActive;
}
