import { graphql } from '@api/gql';

export const ResourcesStatus_Fragment = graphql(/* GraphQL */ `
  fragment ResourcesStatus_Fragment on Status {
    message
    status
  }
`);

export const ResourcesTag_Fragment = graphql(/* GraphQL */ `
  fragment ResourcesTag_Fragment on Tag {
    key
    value
  }
`);

export const ResourcesType_Fragment = graphql(/* GraphQL */ `
  fragment ResourcesType_Fragment on ResourceType {
    type
    typeDisplayName
    icons {
      svg
    }
  }
`);

export const integrationResourcesQueryDocument = graphql(/* GraphQL */ `
  query IntegrationResources(
    $id: String!
    $offset: Int
    $take: Int
    $search: String
    $filter: IntegrationResourcesFilterInput
  ) {
    integrationResources(id: $id, take: $take, offset: $offset, search: $search, filter: $filter) {
      totalCount
      edges {
        node {
          id
          name
          owners {
            attributeValue {
              id
              name
              typDisplayName
              type
            }
            integration {
              id
              name
              type
              typeDisplayName
              icons {
                svg
                png
              }
            }
          }
          path {
            key
            keyDisplayName
            value
          }
          status {
            ...ResourcesStatus_Fragment
          }
          tags {
            ...ResourcesTag_Fragment
          }
          type {
            ...ResourcesType_Fragment
          }
        }
      }
    }
  }
`);

export const resourceTypesFilterOptions = graphql(/* GraphQL */ `
  query ResourceTypesFilterOptions($id: String!, $search: String) {
    integrationResourcesFilterOptions(id: $id, search: $search) {
      resourceTypes {
        ...ResourcesType_Fragment
      }
    }
  }
`);

export const statusesFilterOptions = graphql(/* GraphQL */ `
  query StatusesFilterOptions($id: String!, $search: String) {
    integrationResourcesFilterOptions(id: $id, search: $search) {
      statuses {
        ...ResourcesStatus_Fragment
      }
    }
  }
`);

export const tagsFilterOptions = graphql(/* GraphQL */ `
  query TagsFilterOptions($id: String!, $search: String) {
    integrationResourcesFilterOptions(id: $id, search: $search) {
      tags {
        ...ResourcesTag_Fragment
      }
    }
  }
`);
