import { Stack } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { AlertBanner } from '@components/AlertBanner';
import {
  AccessFlowAppModelV2,
  IntegrationAppModel,
  IntegrationConfigAppModelV2,
  useGetAccessFlowIssues,
  useListIntegrationsV2,
} from '@api';

export interface WarningResourceBannerProps {
  accessFlow: Pick<AccessFlowAppModelV2, 'id'>;
}

export function WarningResourceBanner({ accessFlow }: WarningResourceBannerProps) {
  const { accessFlowIssues, isAccessFlowIssuesFetched } = useGetAccessFlowIssues(accessFlow.id);
  const { integrations, configs, isIntegrationsFetched } = useListIntegrationsV2();

  if (!accessFlowIssues || !isAccessFlowIssuesFetched || !isIntegrationsFetched) return null;

  const remappedIntegrations = integrations.reduce((acc, curr) => {
    acc[curr.id] = {
      integration: curr,
      config: configs.find((c) => c.type === curr.type),
    };
    return acc;
  }, {} as { [key: string]: { integration: IntegrationAppModel; config?: IntegrationConfigAppModelV2 } });

  const bannerMessages = accessFlowIssues.resources.map((res) => {
    const itg = remappedIntegrations[res.integration_id];
    return {
      integrationId: res.integration_id,
      integrationName: [
        itg?.config?.name,
        itg?.integration.name,
        itg?.config?.resource_types[res.resource_type]?.display_name,
      ].join('/'),
      resources: res.resource_names,
    };
  });

  if (bannerMessages.length === 0) return null;

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      {bannerMessages.map((m) => {
        const firstResource = m.resources[0];
        const more = m.resources.length - 1;
        const message = [
          <>
            Problem with <strong>{firstResource}</strong>
          </>,
        ];

        if (more > 0) message.push(<> and </>, <strong>{more} more</strong>, <> resources</>);

        message.push(
          <> in </>,
          <strong>{m.integrationName}</strong>,
          <>
            .<br />
            This can effect your users when they need access.
          </>,
        );

        return (
          <AlertBanner
            key={m.integrationId}
            message={message}
            icon={<WarningIcon />}
            link={'https://docs.apono.io/docs/troubleshooting-errors'}
          />
        );
      })}
    </Stack>
  );
}
