import { useAppListAttributeGroups } from '@AccessFlows/services/attributesQueries';
import { AccessFlowAreaModel, AttributeGroupAppModel } from '@api';
import {
  InteractiveDropdown,
  InteractiveDropdownCategory,
  InteractiveDropdownFilterInput,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
  Popover,
  usePopover,
} from '@components';
import { ReactNode, useCallback, useMemo, useState } from 'react';

const __APONO__ = '__APONO__';

interface SelectAttributeProps {
  trigger: ReactNode;
  value?: AttributeGroupAppModel;
  onChange: (value: AttributeGroupAppModel) => void;
  applicablePlaces: AccessFlowAreaModel[];
}

export default function FlowFormSelectAttribute({ trigger, value, onChange, applicablePlaces }: SelectAttributeProps) {
  const externalState = usePopover();

  const handleChange = (newVal: AttributeGroupAppModel) => {
    onChange(newVal);
    externalState.closeDropdown();
  };

  return (
    <Popover testId="flow-form-select-attribute-type" trigger={trigger} externalState={externalState}>
      <InteractiveDropdown wide>
        <SelectAttributeDropdown value={value} onChange={handleChange} applicablePlaces={applicablePlaces} />
      </InteractiveDropdown>
    </Popover>
  );
}

interface SelectAttributeDropdownProps {
  value?: AttributeGroupAppModel;
  onChange: (value: AttributeGroupAppModel) => void;
  applicablePlaces: AccessFlowAreaModel[];
}

function SelectAttributeDropdown(props: SelectAttributeDropdownProps) {
  const { attributeGroups, isFetched } = useAppListAttributeGroups({ applicablePlaces: props.applicablePlaces });

  if (!isFetched) {
    return <InteractiveDropdownView current loading header={<InteractiveDropdownHeader title="Select attribute" />} />;
  }

  return <AttributesTypeList attributeGroups={attributeGroups} {...props} />;
}

interface AttributesTypeListProps extends SelectAttributeDropdownProps {
  attributeGroups: AttributeGroupAppModel[];
}

function AttributesTypeList({ attributeGroups, value, onChange }: AttributesTypeListProps) {
  const [filter, setFilter] = useState('');

  const filteredGroups = useMemo(() => {
    if (!filter) {
      return attributeGroups;
    }

    const lowerFilter = filter.toLowerCase();
    return attributeGroups.filter((group) => group.type.display_name.toLowerCase().includes(lowerFilter));
  }, [attributeGroups, filter]);

  const remapped = useMemo(() => {
    const options: InteractiveDropdownSelectOption[] = [];
    const categories: InteractiveDropdownCategory[] = [];

    const categoriesSet = new Set<string>();
    for (const group of filteredGroups) {
      const categoryKey = group.integration?.id || __APONO__;
      if (!categoriesSet.has(categoryKey)) {
        categoriesSet.add(categoryKey);
        categories.push({
          key: categoryKey,
          value: group.integration?.name || 'Apono',
          iconSrc: group.integration?.icons.svg,
        });
      }

      options.push({
        key: `${categoryKey}:${group.type.type}`,
        value: group.type.display_name,
        hasNextView: true,
        categoryKey,
        noInput: true,
      });
    }

    return { options, categories };
  }, [filteredGroups]);

  const handleOnChange = useCallback(
    (newKey: string) => {
      const [categoryKey, type] = newKey.split(':');

      const group = attributeGroups.find((g) => {
        if (categoryKey === __APONO__) return g.type.type === type && !g.integration?.id;
        return g.type.type === type && g.integration?.id === categoryKey;
      });

      if (!group) return;

      onChange(group);
    },
    [attributeGroups, onChange],
  );

  const selectedOption = useMemo(() => {
    const group = attributeGroups.find(
      (g) => g.type.type === value?.type.type && g.integration?.id === value?.integration?.id,
    );
    if (!group) {
      return '';
    }

    const categoryKey = group.integration?.id || __APONO__;
    return `${categoryKey}:${group.type.type}`;
  }, [attributeGroups, value?.integration?.id, value?.type.type]);

  return (
    <InteractiveDropdownView
      current
      header={<InteractiveDropdownHeader title="Select attribute" />}
      filter={<InteractiveDropdownFilterInput value={filter} onChange={setFilter} placeholder={`Search attributes`} />}
    >
      <InteractiveDropdownSelect<string>
        categories={remapped.categories}
        options={remapped.options}
        value={selectedOption}
        onChange={handleOnChange}
        selectedFirst
      />
    </InteractiveDropdownView>
  );
}
