import { getFragmentData } from '@api/gql';

import { DrawerDetail } from '@common/ui/DrawerDetail/DrawerDetail';
import { Paper, Typography, Link, Stack } from '@mui/material';
import { ACCESS_FLOW_FIELDS_WITH_LABELS, AlertRequestContextFields_Fragment } from '../services/gql';
import { SeverityCell } from './SeverityCell';
import { USER_FRAGMENT } from '@AccessRightSizing/services/gql';
import { generatePath } from 'react-router-dom';
import { RouterLink } from '@libs';
import { routes } from '@routes';
import { DateTime } from '@utils';
import LabelWithColor from '@common/ui/LabelItem/LabelItem';
import { AlertFieldsFragment } from '@api/gql/graphql';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function AlertDetails({ alertData }: { alertData: AlertFieldsFragment }) {
  const requestData = getFragmentData(AlertRequestContextFields_Fragment, alertData.requestContext);
  const user = getFragmentData(USER_FRAGMENT, requestData.user);
  const accessFlow = getFragmentData(ACCESS_FLOW_FIELDS_WITH_LABELS, requestData.accessFlow);

  const labels = accessFlow?.labels || [];
  const hasAccessFlowLabels = labels.length > 0;

  return (
    <Paper>
      <DrawerDetail
        label="Request ID"
        value={
          <Typography variant="body2">
            <Link
              component={RouterLink}
              to={`${generatePath(routes.Activity.path)}?request_id=${requestData.requestId}`}
              data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_REQUEST_ID_CLICKED}
              data-props={JSON.stringify({ type: alertData.type, typeName: alertData.typeName })}
            >
              {requestData.friendlyRequestId}
            </Link>
          </Typography>
        }
      />
      <DrawerDetail label="Type" value={alertData.typeName} />
      <DrawerDetail label="Severity" value={<SeverityCell severity={alertData.severity} />} />
      {user && <DrawerDetail label="Grantee" value={`${user.firstName} ${user.lastName}`} />}
      <DrawerDetail
        label="Creation Date"
        value={DateTime.fromUnixWithFormat(DateTime.fromString(alertData.timestamp).unix)}
      />
      {accessFlow && (
        <DrawerDetail
          label="Access Flow"
          value={
            <Typography variant="body2">
              <Link
                component={RouterLink}
                to={generatePath(routes.EditAccessFlow.path, {
                  id: accessFlow.id,
                })}
              >
                {accessFlow.name}
              </Link>
            </Typography>
          }
          noDivider={!hasAccessFlowLabels}
        />
      )}
      {hasAccessFlowLabels && (
        <DrawerDetail
          label="Access Flow Labels"
          noDivider
          value={
            <Stack direction="row" alignItems="center" spacing={1}>
              {hasAccessFlowLabels &&
                labels.map((l) => <LabelWithColor key={l.name} label={{ ...l, color: l.color || undefined }} />)}
            </Stack>
          }
        />
      )}
    </Paper>
  );
}
