import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Divider,
  Fade,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import {
  AccessUnitAppModel,
  AccessUnitStatus,
  IntegrationPartialAppModel,
  ResourceTypeAccessUnitGroupAppModel,
} from '@api';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { MaterialIcon } from '@components';
import AlertStatusMessage from '@components/AlertStatusMessage';
import { DateTime } from '@utils';
import { useMemo } from 'react';
import { useGetActivityRequestAccessUnits } from './query';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  '&:before': { display: 'none' },
}));

export default function IntegrationsList({
  resourceTypes,
  requestId,
  integration,
  status,
  defaultExpanded = false,
}: {
  resourceTypes: ResourceTypeAccessUnitGroupAppModel[];
  requestId: string;
  integration: IntegrationPartialAppModel;
  status?: string[];
  defaultExpanded?: boolean;
}) {
  const resourceTypeCountSum = useMemo(
    () => resourceTypes.reduce((acc, resourceType) => acc + resourceType.total, 0),
    [resourceTypes],
  );

  return (
    <Stack direction="column" spacing={2}>
      <Card sx={{ border: 'none' }}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <PrimarySecondary
                primary={integration.name}
                icon={integration.icons.svg}
                secondary={integration.type_display_name}
              />
              <Typography variant="tooltip" color="text.muted">
                {resourceTypeCountSum} Resources
              </Typography>
            </Stack>
            {resourceTypes.map((resourceType) => (
              <ResourceTypeContainer
                key={resourceType.type.type}
                status={status}
                resourceType={resourceType}
                requestId={requestId}
                integrationId={integration.id}
                defaultExpanded={defaultExpanded}
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

function ResourceTypeContainer({
  resourceType,
  requestId,
  integrationId,
  status,
  defaultExpanded = false,
}: {
  resourceType: ResourceTypeAccessUnitGroupAppModel;
  requestId: string;
  integrationId: string;
  status?: string[];
  defaultExpanded?: boolean;
}) {
  const { data, isFetched, hasNextPage, fetchNextPage } = useGetActivityRequestAccessUnits({
    id: requestId,
    integrationId: [integrationId],
    resourceType: [resourceType.type.type],
    status,
  });

  const accessUnits = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data?.pages]);

  if (!isFetched) {
    return (
      <Fade in={true} timeout={500}>
        <Skeleton variant="rounded" height={50} animation="wave" />
      </Fade>
    );
  }

  return (
    <StyledAccordion elevation={0} disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<MaterialIcon symbol="expand_more" />}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width="100%">
          <PrimarySecondary primary={resourceType.type.display_name} icon={resourceType.type.icons.svg} />
          <Typography variant="tooltip" color="text.muted">
            {resourceType.total} Resources
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {accessUnits.map((au) => (
            <Stack key={`${au.resource.name}-${au.permission}`} spacing={1}>
              <Divider />
              <AccessUnitItem accessUnit={au} />
            </Stack>
          ))}
          {hasNextPage && accessUnits.length < resourceType.total && (
            <Stack spacing={2}>
              <Divider />
              <Button onClick={() => fetchNextPage({ pageParam: accessUnits.length })}>Load More</Button>
            </Stack>
          )}
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
}

export function AccessUnitItem({ accessUnit }: { accessUnit: AccessUnitAppModel }) {
  const elem = (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} width="100%">
      <PrimarySecondary
        primary={accessUnit.resource.name}
        secondary={Object.values(accessUnit.resource.path).join('/')}
      />
      <Stack spacing={0.5}>
        <Typography variant="tooltip" color="text.muted" align="right">
          {accessUnit.permission}
        </Typography>
        <Typography variant="tooltip" color="text.muted" align="right">
          {`${accessUnit.status.status} at ${DateTime.fromUnixWithFormat(accessUnit.updated_date)}`}
        </Typography>
      </Stack>
    </Stack>
  );

  if (accessUnit.status.message) {
    return (
      <AlertStatusMessage
        message={accessUnit.status.message}
        isError={AccessUnitStatus.FailedRevoking === accessUnit.status.status}
      >
        {elem}
      </AlertStatusMessage>
    );
  }

  return <>{elem}</>;
}
