import { useState } from 'react';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';
import { AccessStatusModel } from '@common/constants';
import { FilterProps } from './types';

export const STATUS_FILTER_LABEL = 'Status';
export const STATUS_FILTER_PARAM = 'filter.status';

export default function StatusFilter({ opened, onClose, onOpen, selected, onClear, onSelect }: FilterProps) {
  const [isOpen, setIsOpen] = useState(opened || false);

  const options = [
    { value: AccessStatusModel.Granting, label: AccessStatusModel.Granting },
    { value: AccessStatusModel.Rejected, label: AccessStatusModel.Rejected },
    { value: AccessStatusModel.Active, label: AccessStatusModel.Active },
    { value: AccessStatusModel.Pending, label: AccessStatusModel.Pending },
    { value: AccessStatusModel.PendingMFA, label: 'Pending MFA' },
    { value: AccessStatusModel.Revoking, label: AccessStatusModel.Revoking },
    { value: AccessStatusModel.Revoked, label: AccessStatusModel.Revoked },
    { value: AccessStatusModel.Failed, label: AccessStatusModel.Failed },
  ];

  const selectedOption = (selected.length > 0 && options.find((option) => option.value === selected[0])) || undefined;

  const handleOnSelect = (value: ListFilterOption) => {
    onSelect([value.value]);
    closeDropdown();
  };

  const handleOnClear = () => {
    onClear();
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={STATUS_FILTER_LABEL}
          value={selectedOption ? [selectedOption] : []}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      <ListFilterSingleSelect
        options={options}
        value={selectedOption}
        onSelect={handleOnSelect}
        title={`Select ${STATUS_FILTER_LABEL} Filter`}
      />
    </ListFilter>
  );
}
