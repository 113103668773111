import { FlowFormTargets, FlowFormText } from '@AccessFlows/organisms/FlowForm';
import { TriggerTypeFormProps } from '@AccessFlows/common/types';
import { Stack } from '@mui/material';
import FlowFormAdvancedGrantees from '@AccessFlows/organisms/FlowForm/Form/AdvancedGrantees';

export function ComplexAutoGrantForm({
  selectedGranteesV2,
  setSelectedGranteesV2,
  accessTargets,
  setAccessTargets,
  getValidationError,
  template,
  noBundles,
}: TriggerTypeFormProps) {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <FlowFormText>While user</FlowFormText>
        <FlowFormAdvancedGrantees value={selectedGranteesV2} onChange={setSelectedGranteesV2} />
      </Stack>
      <Stack spacing={1}>
        <FlowFormText>They will have access to:</FlowFormText>
        <FlowFormTargets
          and
          withLines
          noEmptyPrefix
          accessTargets={accessTargets}
          onChange={(v) => setAccessTargets(v)}
          error={getValidationError('targets')}
          template={template?.targets}
          noBundles={noBundles}
        />
      </Stack>
    </Stack>
  );
}
