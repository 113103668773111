import { PropsWithChildren, ReactNode } from 'react';
import { Collapse, styled, Table, TableBody, TableCell, TableRow } from '@mui/material';

interface CollapsibleTableRowProps extends PropsWithChildren {
  mainRow: ReactNode;
  colSpan: number;
  open: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  padding: 0,
  backgroundColor: theme.palette.background.default,
}));

export default function CollapsibleTableRows({ mainRow, children, colSpan, open }: CollapsibleTableRowProps) {
  return (
    <>
      {mainRow}
      <TableRow>
        <StyledTableCell colSpan={colSpan + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="sub-rows">
              <TableBody>{children}</TableBody>
            </Table>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}
