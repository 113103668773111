import { PropsWithChildren, ReactNode } from 'react';

import { Box, styled } from '@mui/material';
import { Loader } from '@components/Loader';

const InteractiveDropdownViewWrapper = styled(Box)({
  '& .page-content': {
    position: 'absolute',
    visibility: 'hidden',
    top: -9999,
    left: -9999,

    '&.active': {
      visibility: 'visible',
      top: 0,
      left: 0,
      position: 'relative',
    },
  },
});

export interface InteractiveDropdownViewProps {
  header?: ReactNode;
  footer?: ReactNode;
  filter?: ReactNode;
  loading?: boolean;
  current?: boolean;
}

export function InteractiveDropdownView({
  header,
  footer,
  filter,
  children,
  loading,
  current = false,
}: PropsWithChildren<InteractiveDropdownViewProps>) {
  if (loading) {
    return (
      <InteractiveDropdownViewWrap current={current} header={header}>
        <Loader sx={{ pt: 4, pb: 4 }} absolute={false} />
      </InteractiveDropdownViewWrap>
    );
  }

  return (
    <InteractiveDropdownViewWrap current={current} header={header} footer={footer}>
      {filter}
      {children}
    </InteractiveDropdownViewWrap>
  );
}

type InteractiveDropdownViewWrapProps = {
  current: boolean;
} & PropsWithChildren<Pick<InteractiveDropdownViewProps, 'header' | 'footer'>>;

function InteractiveDropdownViewWrap({ current, header, footer, children }: InteractiveDropdownViewWrapProps) {
  const classes = ['page-content'];
  if (current) classes.push('active');

  return (
    <InteractiveDropdownViewWrapper>
      {header}
      <div className={classes.join(' ')}>{children}</div>
      {footer}
    </InteractiveDropdownViewWrapper>
  );
}
