import { ReactNode } from 'react';
import { Button, Alert, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface AlertBannerProps {
  icon?: ReactNode;
  message: ReactNode;
  link?: string;
  type?: 'warning-banner' | 'error-banner' | 'outlinedInfo';
  button?: ReactNode;
}
export function AlertBanner({ icon, message, link, type = 'warning-banner', button }: AlertBannerProps) {
  return (
    <Alert
      variant={type}
      icon={icon}
      action={
        link ? (
          <Button variant="outlined-base" size="small" component={Link} to={link}>
            More Details
          </Button>
        ) : (
          button || null
        )
      }
    >
      <Typography variant="body2">{message}</Typography>
    </Alert>
  );
}
