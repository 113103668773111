import { AlertStatsOverTimeWidget } from '@AccessAlerts/components/AlertStatsOverTimeWidget';
import { alertStatsOverTimeFields } from '@AccessAlerts/services/gql';
import { useAlertStatsOverTime } from '@AccessAlerts/services/use-alert-stats-over-time';
import { getFragmentData } from '@api/gql';
import { AlertFilterInput } from '@api/gql/graphql';
import { Loader } from '@components';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { aponoColors } from '@utils';
import { sub } from 'date-fns';
import { useMemo } from 'react';

export const CUSTOM_COLORS = [aponoColors.error[500], aponoColors.warning[700], aponoColors.neutral[300]];

const DEFAULT_TIMESTAMP = { between: [sub(new Date(), { years: 1 }).toISOString(), new Date().toISOString()] };

export function AlertStatsOverTime({ filters }: { filters?: AlertFilterInput }) {
  return (
    <WidgetCard title="Anomalies Over Time">
      <AlertStatsOverTimeContent filters={filters} />
    </WidgetCard>
  );
}

function AlertStatsOverTimeContent({ filters }: { filters?: AlertFilterInput }) {
  const filtersWithDateFilter: AlertFilterInput = useMemo(() => {
    const filtersCopy = filters || {};
    if (!filters?.timestamp) {
      filtersCopy.timestamp = DEFAULT_TIMESTAMP;
    }
    return filtersCopy;
  }, [filters]);

  const { data, isFetched } = useAlertStatsOverTime({ filter: filtersWithDateFilter });
  const { alertStatsOverTime } = data || { alertStatsOverTime: [] };

  const alertStatsOverTimeData = getFragmentData(alertStatsOverTimeFields, alertStatsOverTime);

  const isEmpty = useMemo(() => {
    return alertStatsOverTimeData?.every(element => element.stats.length === 0);
  }, [alertStatsOverTimeData]);

  if (!isFetched) {
    return <Loader />;
  }

  if (!alertStatsOverTimeData || isEmpty) {
    return (
      <PieChartEmptyState
        title="No alert data to display"
        animationSrc={'/static/EmptyStateImages/no-data-table-chart.svg'}
        size={{ width: 130, height: 80 }}
      />
    );
  }

  return <AlertStatsOverTimeWidget data={alertStatsOverTimeData} />;
}
