import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Alert, Table as MuiTable, TableBody, TableHead, TableRow, TableCell, Stack, Typography } from '@mui/material';

import { CodeLine, Loader, NoResults, TableWrapper } from '@components';

import { useAppGetWebhookHistory } from '../services/webhooksQueries';
import TablePagination from '../components/TablePagination';

import { WebhookAppModel, WebhookInvocationAppModel } from '@api';
import { DateTime } from '@utils';
import WebhookDrawer from '@Webhooks/components/WebhookDrawer';
import StatusIcon from '@common/ui/StatusIcon';
import { AccessStatusModel } from '@common/constants';
import PrimarySecondary from '@common/ui/PrimarySecondary';

export default function WebhookHistoryTablePaginated({ webhook }: { webhook: WebhookAppModel }) {
  const { isFetched, isFetchingNextPage, pageData, handleOnPageChange } = useAppGetWebhookHistory(webhook.id);
  const [selectedInvocation, setSelectedInvocation] = useState<WebhookInvocationAppModel | undefined>();

  const handleOnRowClick = useCallback((invocation?: WebhookInvocationAppModel) => {
    setSelectedInvocation(invocation);
  }, []);

  return useMemo(() => {
    if (!isFetched) {
      return <Loader />;
    }

    if (!pageData) {
      return <Alert severity="error">No invocations history found</Alert>;
    }

    return (
      <Stack spacing={2}>
        <HistoryTableWrapper isEmpty={pageData.pagination.total === 0}>
          {pageData.data.map((invocation) => (
            <HistoryTableRow
              key={invocation.id}
              invocation={invocation}
              onClick={() => handleOnRowClick(invocation)}
              selected={invocation.id === selectedInvocation?.id}
            />
          ))}
        </HistoryTableWrapper>
        {pageData.pagination.total > 0 && (
          <TablePagination pageData={pageData} isLoading={isFetchingNextPage} onChange={handleOnPageChange} />
        )}
        <WebhookDrawer invocationData={selectedInvocation} onClose={() => handleOnRowClick(undefined)} />
      </Stack>
    );
  }, [isFetched, pageData, isFetchingNextPage, handleOnPageChange, selectedInvocation, handleOnRowClick]);
}

function HistoryTableWrapper({ isEmpty, children }: PropsWithChildren<{ isEmpty: boolean }>) {
  if (isEmpty) {
    return <NoResults>No invocation history found</NoResults>;
  }

  return (
    <TableWrapper>
      <MuiTable size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th" size="medium" width={200}>
              Invocation Time
            </TableCell>
            <TableCell component="th" size="medium" width={200}>
              Trigger
            </TableCell>
            <TableCell component="th" size="medium">
              Request
            </TableCell>
            <TableCell component="th" size="medium">
              Response
            </TableCell>
            <TableCell component="th" size="medium" width={200}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </MuiTable>
    </TableWrapper>
  );
}

function HistoryTableRow({
  invocation,
  onClick,
  selected,
}: {
  invocation: WebhookInvocationAppModel;
  onClick: () => void;
  selected?: boolean;
}) {
  return (
    <TableRow key={invocation.id} hover className="MuiTableRow-clickable" onClick={onClick} selected={selected}>
      <TableCell>
        <Typography variant="body2">{DateTime.fromUnix(Number(invocation.invocation_date)).readable}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{invocation.trigger}</Typography>
      </TableCell>
      <TableCell>
        <CodeLine width={400}>{invocation.request.body}</CodeLine>
      </TableCell>
      <TableCell>
        {invocation.response?.body ? <CodeLine width={400}>{invocation.response?.body}</CodeLine> : <em>No body</em>}
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <StatusIcon status={invocation.success ? AccessStatusModel.APPROVED : 'Error'} />
          <PrimarySecondary
            primary={invocation.success ? 'Success' : 'Failed'}
            secondary={invocation.response && ` (code: ${invocation.response.status_code})`}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
