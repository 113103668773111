import { AccessFlowTemplate } from '@af-templates';
import { AccessFlowUpsertAppModel, FilterResultContext, TriggerType } from '@api';
import { Typography } from '@mui/material';
import { FilterHighlighterWrapper } from './FilterHighlighterWrapper';
import {
  HumanReadableAccessTargets,
  HumanReadableApprover,
  HumanReadableDuration,
  HumanReadableGrantee,
  HumanReadableTrigger,
} from './HumanReadable';

export interface AccessFlowSentenceProps {
  accessFlow?: AccessFlowUpsertAppModel;
  template?: AccessFlowTemplate;
  filterMessages?: Record<FilterResultContext, string[]>;
}

export function AccessFlowSentence({ accessFlow, template, filterMessages }: AccessFlowSentenceProps) {
  const trigger_type = accessFlow?.trigger_type || template?.trigger_type;
  switch (trigger_type) {
    case TriggerType.UserRequest:
      return <UserRequestSentence {...{ accessFlow, template, filterMessages }} />;
    default:
      return <em>Access flow is empty</em>;
  }
}

function UserRequestSentence({ accessFlow, template, filterMessages }: AccessFlowSentenceProps) {
  const inline = template ? 'underline' : 'strong';
  const variant = template ? 'body3' : 'body4';
  return (
    <Typography variant={variant}>
      When{' '}
      <FilterHighlighterWrapper messages={filterMessages?.Grantees}>
        <HumanReadableGrantee
          value={accessFlow?.grantees || []}
          placeholder={template?.grantees || 'anyone'}
          inline={inline}
          noTooltip={filterMessages?.Grantees ? true : false}
        />
      </FilterHighlighterWrapper>{' '}
      <HumanReadableTrigger value={accessFlow?.trigger_type || template?.trigger_type} inline />{' '}
      <FilterHighlighterWrapper messages={filterMessages?.AccessTarget}>
        <HumanReadableAccessTargets
          value={accessFlow?.targets || []}
          template={template?.targets}
          inline={inline}
          noTooltip={filterMessages?.AccessTarget ? true : false}
        />
      </FilterHighlighterWrapper>
      , grant access for{' '}
      <HumanReadableDuration value={accessFlow?.revoke_after_in_sec || template?.revoke_after_in_sec} inline={inline} />{' '}
      with{' '}
      <FilterHighlighterWrapper messages={filterMessages?.Approvers}>
        <HumanReadableApprover
          value={accessFlow?.approvers || []}
          placeholder={template?.approvers || 'automatic'}
          inline={inline}
          noTooltip={filterMessages?.Approvers ? true : false}
        />
      </FilterHighlighterWrapper>{' '}
      approval.
    </Typography>
  );
}
