import { Divider, Stack } from '@mui/material';
import { AlertTypesListHeader } from './AlertTypesListHeader';
import { AlertTypesListItem } from './AlertTypesListItem';
import { AlertStatsFieldsFragment } from '@api/gql/graphql';

export function AlertTypesWidget({ alerts }: { alerts: readonly AlertStatsFieldsFragment[] }) {
  return (
    <Stack direction="column" spacing={1}>
      <AlertTypesListHeader />
      <Divider />
      {alerts.map((item, index) => {
        return (
          <>
            <AlertTypesListItem alert={item} />
            {index < alerts.length - 1 && <Divider />}
          </>
        );
      })}
    </Stack>
  );
}
