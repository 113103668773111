import { useIntegrationsPack, useRequiredParams } from '@hooks';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useCreateResourceIntegration, useIntegration } from '@hooks/integrations-catalog.hooks';
import { Alert, Box, Grid, Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Integration,
  IntegrationConfigAppModel as IntegrationConfig,
  IntegrationConfigParamAppModel,
  IntegrationFamilyType,
  IntegrationsService,
  IntegrationStatus,
  useInvalidateSession,
} from '@api';
import { IntegrationInfo, Loader, Page } from '@components';
import { ErrorPage } from '@pages/ErrorPage';
import LoadingButton from '@mui/lab/LoadingButton';
import { ConnectIntegrationForm } from '@Integrations/organisms/AddIntegration/ConnectIntegrationForm/ConnectIntegrationForm';
import { ConnectIntegrationFormHeader } from '@Integrations/organisms/AddIntegration/ConnectIntegrationForm/ConnectIntegrationFormHeader';
import { AxiosResponse } from 'axios';

export function AddIntegrationPage() {
  return (
    <Page contentCentered contentPadded breadcrumbs={[{ label: 'Catalog', href: '/catalog' }]} title="Add Integration">
      <AddIntegrationPageContent />
    </Page>
  );
}

function AddIntegrationPageContent() {
  const { type } = useRequiredParams(['type']);
  const { isLoadingIntegration, integrationConfig } = useIntegration(type);
  const [resourceRequest, setResourceRequest] = useState<Integration>({
    id: '',
    name: '',
    type,
    provisioner_id: '',
    metadata: {},
    status: IntegrationStatus.Initializing,
    connected_resource_types: [],
  });

  type MetadataObject = { [k: string]: object };

  useEffect(() => {
    if (!integrationConfig) return;
    const metadata: MetadataObject = integrationConfig.params.reduce((res, param: IntegrationConfigParamAppModel) => {
      res[param.id] = param.default as unknown as object;
      return res;
    }, {} as MetadataObject);

    setResourceRequest({
      ...resourceRequest,
      metadata,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationConfig]);

  if (isLoadingIntegration) {
    return <Loader />;
  }

  if (!integrationConfig) {
    return <ErrorPage errorCode={500} />;
  }

  return (
    <IntegrationContent
      integrationConfig={integrationConfig}
      integration={resourceRequest}
      setIntegration={setResourceRequest}
      useCreateOrUpdateHook={useCreateResourceIntegration}
    />
  );
}

interface IntegrationContentProps {
  integrationConfig: IntegrationConfig;
  integration: Integration;
  setIntegration: Dispatch<SetStateAction<Integration>>;
  useCreateOrUpdateHook: (integration: Integration) => {
    execute: () => Promise<AxiosResponse<Integration>>;
    inProgress: boolean;
    doneSuccessfully: boolean;
  };
  isEdit?: boolean;
}

export function IntegrationContent({
  isEdit,
  integration,
  setIntegration,
  integrationConfig,
  useCreateOrUpdateHook,
}: IntegrationContentProps) {
  const navigate = useNavigate();
  const { execute, inProgress, doneSuccessfully } = useCreateOrUpdateHook(integration);
  const { connectedIntegrations, configs, refetch } = useIntegrationsPack();
  const invalidateSession = useInvalidateSession();

  useEffect(() => {
    if (doneSuccessfully) {
      refetch();
      invalidateSession();
      navigate(`/catalog/connected?type=${integrationConfig.type}`);
    }
  }, [doneSuccessfully, integrationConfig.type, invalidateSession, navigate, refetch]);

  const handleOauth = async () => {
    const paramsMetadata = integration.metadata;
    const link = await IntegrationsService.appGetIntegrationInstallLink({
      type: integration.type,
      getOauthInstallLinkModel: { metadata: paramsMetadata },
    });
    if (link) window.location.replace(link.link);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (integrationConfig.connection_type === 'aws') {
      const link = await IntegrationsService.getAwsIntegrationInstallLink();
      window.open(link.link, '_blank')?.focus();
    } else {
      execute();
    }
  };

  const isIdpConnectionDisabled = useMemo(() => {
    if (
      !connectedIntegrations.length ||
      integrationConfig.family_type !== IntegrationFamilyType.IdentityProvider ||
      (integrationConfig.connection_type === 'oauth' && integration.id)
    )
      return false;

    return connectedIntegrations.some((integ) =>
      configs.some(
        (config) => config.family_type === IntegrationFamilyType.IdentityProvider && config.type === integ.type,
      ),
    );
  }, [
    connectedIntegrations,
    integrationConfig.family_type,
    integrationConfig.connection_type,
    integration.id,
    configs,
  ]);

  return (
    <Stack spacing={4}>
      {isIdpConnectionDisabled && (
        <Alert severity="warning">
          Only one identity provider is allowed. Please, remove current Identity Provider before adding a new one.
        </Alert>
      )}
      <IntegrationContentGeneric
        isEdit={isEdit}
        progress={inProgress}
        integration={integration}
        handleSubmit={handleSubmit}
        handleOauth={handleOauth}
        integrationConfig={integrationConfig}
        onChange={(resourceRequest) => setIntegration(resourceRequest)}
      />
    </Stack>
  );
}

interface IntegrationContentGenericProps {
  integration: Integration;
  integrationConfig: IntegrationConfig;
  progress: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleOauth: () => Promise<void>;
  onChange: (resourceRequest: Integration) => void;
  disabled?: boolean;
  isEdit?: boolean;
}

export function IntegrationContentGeneric({
  isEdit,
  progress,
  onChange,
  integration,
  handleOauth,
  handleSubmit,
  integrationConfig,
}: IntegrationContentGenericProps) {
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get('message');

  const isOauth = integrationConfig.connection_type === 'oauth';
  const isIdentityContext = integrationConfig.family_type === IntegrationFamilyType.IdentityContext;

  const OauthButton = () => (
    <LoadingButton type="button" variant="outlined" onClick={handleOauth}>
      {isEdit ? 'Renew Token' : 'Connect'}
    </LoadingButton>
  );

  const ConnectOrUpdateButton = () => (
    <LoadingButton type="submit" loading={progress} variant="contained" color="primary">
      {isEdit ? 'Update' : 'Connect'}
    </LoadingButton>
  );

  return (
    <Grid container justifyContent="space-between" direction="row">
      <Grid item xs={6}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <ConnectIntegrationFormHeader integrationConfig={integrationConfig} />
            <ConnectIntegrationForm
              integration={integration}
              integrationConfig={integrationConfig}
              onChange={onChange}
            />
            <Stack spacing={1} direction="row" justifyContent="flex-end">
              {isOauth ? <OauthButton /> : <ConnectOrUpdateButton />}
              {isEdit && isOauth && !isIdentityContext && <ConnectOrUpdateButton />}
            </Stack>
            {errorMessage && (
              <Box display="flex" justifyContent="center" alignItems="flex-end">
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
          </Stack>
        </form>
      </Grid>
      <Grid item xs={4}>
        <IntegrationInfo info={integrationConfig.info} links={integrationConfig.links} />
      </Grid>
    </Grid>
  );
}
