import { useMemo, useReducer, PropsWithChildren } from 'react';
import { styled, Stack, Divider, Fade, Skeleton, Typography } from '@mui/material';

import { ActivityRequestAppModel } from '@api';
import { EmptyState, SearchBar } from '@components';

import { useGetActivityRequestAccessUnits } from './query';
import { AccessUnitItem } from './IntegrationsList';
import RequestAccessUnitsFilters from './RequestAccessUnitsFilters';
import { LoadingButton } from '@mui/lab';

interface RequestAccessUnitsFilteredProps {
  request: ActivityRequestAppModel;
  statuses: string[];
}

interface FiltersState {
  search: string;
  integrationIds: string[];
  resourceTypes: string[];
}

type FiltersAction =
  | { type: 'search'; newSearch: string }
  | { type: 'integrationIds'; newIntegrationIds: string[] }
  | { type: 'resourceTypes'; newResourceTypes: string[] };

function filtersReducer(state: FiltersState, action: FiltersAction) {
  switch (action.type) {
    case 'search':
      return { ...state, search: action.newSearch };
    case 'integrationIds':
      return { ...state, integrationIds: action.newIntegrationIds };
    case 'resourceTypes':
      return { ...state, resourceTypes: action.newResourceTypes };
    default:
      throw new Error('Invalid action type');
  }
}

export default function RequestAccessUnitsFiltered({
  request,
  statuses,
  children,
}: PropsWithChildren<RequestAccessUnitsFilteredProps>) {
  const [filters, dispatchFilters] = useReducer(filtersReducer, {
    search: '',
    integrationIds: [],
    resourceTypes: [],
  });

  const isFiltered = useMemo(() => {
    return filters.search.length > 0 || filters.integrationIds.length > 0 || filters.resourceTypes.length > 0;
  }, [filters]);

  return (
    <Stack spacing={2}>
      <Filters {...{ request, filters, dispatchFilters }} />
      {isFiltered ? <FilteredContent {...{ request, statuses, filters }} /> : <>{children}</>}
    </Stack>
  );
}

interface FiltersProps {
  request: ActivityRequestAppModel;
  filters: FiltersState;
  dispatchFilters: (action: FiltersAction) => void;
}

function Filters({ request, filters, dispatchFilters }: FiltersProps) {
  return (
    <Stack spacing={2} direction="row">
      <SearchBar
        value={filters.search}
        onChange={(val) =>
          dispatchFilters({
            type: 'search',
            newSearch: val,
          })
        }
      />
      <RequestAccessUnitsFilters
        request={request}
        value={{
          resourceTypes: filters.resourceTypes,
        }}
        onChange={(value) =>
          dispatchFilters({
            type: 'resourceTypes',
            newResourceTypes: value.resourceTypes,
          })
        }
      />
    </Stack>
  );
}

const SearchResultsWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

function FilteredContent({
  request,
  statuses,
  filters,
}: {
  request: ActivityRequestAppModel;
  statuses: string[];
  filters: FiltersState;
}) {
  const { data, isFetched, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetActivityRequestAccessUnits({
    id: request.id,
    integrationId: filters.integrationIds,
    resourceType: filters.resourceTypes,
    search: filters.search,
    status: statuses,
  });

  const accessUnits = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap((page) => page.data);
  }, [data]);

  const total = useMemo(() => {
    if (!data || data.pages.length === 0) return 0;
    return data.pages[0].pagination.total;
  }, [data]);

  if (!isFetched) {
    return (
      <Fade in={true} timeout={500}>
        <Skeleton variant="rounded" height={50} animation="wave" />
      </Fade>
    );
  }

  if (accessUnits.length === 0) {
    return (
      <EmptyState
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
        title="No results found"
        body="Try different search terms or remove filters"
      />
    );
  }

  return (
    <>
      <Typography variant="body2" color="text.muted" align="right" component="div">
        Found {total} results
      </Typography>
      <SearchResultsWrapper>
        <Stack spacing={1}>
          {accessUnits.map((au, i) => (
            <Stack key={`${au.resource.name}-${au.permission}`} spacing={1}>
              {i > 0 && <Divider />}
              <AccessUnitItem accessUnit={au} />
            </Stack>
          ))}
          {hasNextPage && accessUnits.length < total && (
            <Stack spacing={2}>
              <Divider />
              <LoadingButton
                onClick={() => fetchNextPage({ pageParam: accessUnits.length })}
                loading={isFetchingNextPage}
              >
                Load More
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </SearchResultsWrapper>
    </>
  );
}
