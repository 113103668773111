import { ResourceNodeTypography } from './node';

export function AttributeConditionNodeTotal({ total = 0 }: { total?: number }) {
  if (total === 0) {
    return (
      <ResourceNodeTypography variant="helperText" component="span" color="text.muted">
        No active access
      </ResourceNodeTypography>
    );
  }

  return (
    <ResourceNodeTypography variant="helperText" component="span" color="success.light">
      {total} active user{total > 1 ? 's' : ''}
    </ResourceNodeTypography>
  );
}
