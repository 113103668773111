import { useQuery, useQueryClient } from '@tanstack/react-query';

import { SessionService } from '@api/services';

import { QueryKeys } from './query-keys';
import { UserSessionApiModel } from '@api/apn-core';
import { AxiosError } from '@libs/axios';

export function useSession() {
  const {
    data: session,
    isFetched: isSessionFetched,
    error: sessionError,
    refetch: refetchSession,
  } = useQuery<UserSessionApiModel, AxiosError, UserSessionApiModel, QueryKeys[]>({
    queryKey: [QueryKeys.Session],
    queryFn: async () => SessionService.getSession(),
    refetchOnWindowFocus: true,
  });

  return {
    session,
    isSessionFetched,
    sessionError,
    refetchSession,
  };
}

export function useInvalidateSession() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [QueryKeys.Session] });
}
