import { useMemo } from 'react';
import { Alert } from '@mui/material';

import { useGetIntegrationConfigV2, IntegrationFamilyType } from '@api';
import { useRequiredParams } from '@hooks';
import { ErrorPage } from '@pages';
import { Loader } from '@components';

import { ConnectResourceIntegration, ConnectResourceIntegrationPathsProps } from './ConnectResourceIntegration';

export interface ConnectIntegrationContentProps {
  paths: ConnectResourceIntegrationPathsProps;
  align?: 'center' | 'left';
}

function useConnectIntegrationContent() {
  const { type } = useRequiredParams(['type']);
  const { integrationConfig, isIntegrationConfigFetched } = useGetIntegrationConfigV2(type);

  const isFetched = useMemo(() => isIntegrationConfigFetched, [isIntegrationConfigFetched]);

  return {
    isFetched,
    config: integrationConfig,
  };
}

export function ConnectIntegrationContent({ paths }: ConnectIntegrationContentProps) {
  const { isFetched, config } = useConnectIntegrationContent();

  if (!isFetched) return <Loader />;

  if (!config) {
    return <ErrorPage errorCode={404} errorMessage="Integration config not found" />;
  }

  switch (config.family_type) {
    case IntegrationFamilyType.ResourceIntegration:
      return <ConnectResourceIntegration config={config} paths={paths} />;
    default:
      return <Alert color="warning">Integration family type not supported yet</Alert>;
  }
}
