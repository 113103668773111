import { Node, XYPosition } from 'reactflow';

import { AccessEdgeModel, AccessNodeCategoryModel, AccessNodeModel } from '@api';
import {
  COLLAPSED_GROUPED_NODE_HEIGHT,
  GROUPED_NODE_COLLAPSE_BUTTON_HEIGHT,
  GROUPED_NODE_H_PADDING,
  GROUPED_NODE_WIDTH,
  GroupedNodeExpandedProps,
  hasMetadata,
  MoreNodeProps,
  NodeMetaProps,
  NodeTypes,
  RESOURCE_NODE_HEIGHT,
  RESOURCE_NODE_HEIGHT_EXTRA,
  RESOURCE_NODES_GAP,
} from '@components';

type NodeTypeTypes = keyof typeof NodeTypes;
export type FlowNode = Node<NodeMetaProps | GroupedNodeExpandedProps | MoreNodeProps, NodeTypeTypes>;

type NodeType = string;
type ChildNodeId = string;
type NodeTypeMap = Map<NodeType, Map<ChildNodeId, AccessNodeModel>>;
type NodesMap = Map<AccessNodeCategoryModel, NodeTypeMap>;

/**
 * NodesBuilder
 * @description Build nodes for react-flow
 */
export class NodesBuilder {
  private edges: AccessEdgeModel[] = [];

  private nodes: AccessNodeModel[] = [];

  private edgesMap: {
    targets: Map<string, boolean>;
    sources: Map<string, boolean>;
  } = {
    targets: new Map<string, boolean>(),
    sources: new Map<string, boolean>(),
  };

  private nodesHashMap: Map<string, AccessNodeModel> = new Map();

  private nodesMap: NodesMap = new Map();

  private flowNodes: FlowNode[] = [];

  constructor(edges: AccessEdgeModel[], nodes: AccessNodeModel[]) {
    this.edges = edges;
    this.nodes = nodes;

    this.toMaps();
    this.build();
  }

  public getFlowNodes() {
    return this.flowNodes;
  }

  public getNode(id: string): AccessNodeModel | undefined {
    return this.nodesHashMap.get(id);
  }

  /*
   * Public API
   */

  /*
   * Private API
   */
  private build() {
    const initX = 20;
    const initY = 20;
    const categoriesCoords = { x: initX, y: initY };
    this.nodesMap.forEach((categoryMap) => {
      categoryMap.forEach((typeMap) => {
        if (typeMap.size === 0) return;

        const groupedNode = this.findGroupedNodeInMap(typeMap);
        if (!groupedNode) return;

        if (typeMap.size === 1 && this.hasOnlyGroupedNodeInMap(typeMap)) {
          this.flowNodes.push(this.toGroupedCollapsedNode(groupedNode, groupedNode.more || 0, categoriesCoords));
          categoriesCoords.y += COLLAPSED_GROUPED_NODE_HEIGHT * 2;
          return;
        }

        const childNodes: FlowNode[] = [];
        // moving X coord to the right to place children nodes
        const resourcesCoords = { x: GROUPED_NODE_H_PADDING, y: COLLAPSED_GROUPED_NODE_HEIGHT };

        // iterating over children nodes
        typeMap.forEach((node) => {
          if (node.id === groupedNode.id) return;

          // adding resource node
          childNodes.push(this.toResourceNode(node, resourcesCoords));

          // moving Y coord to the bottom to place next children node
          resourcesCoords.y +=
            (hasMetadata(node) ? RESOURCE_NODE_HEIGHT_EXTRA : RESOURCE_NODE_HEIGHT) + RESOURCE_NODES_GAP;
        });

        // adding 'more' node
        if (groupedNode.more) {
          childNodes.push(this.toMoreNode(groupedNode, groupedNode.more, resourcesCoords));
          resourcesCoords.y += RESOURCE_NODE_HEIGHT + RESOURCE_NODES_GAP;
        }

        // adding parent node that will look like expanded grouped node
        // it will have id 'expanded:nodeType'
        this.flowNodes.push(
          this.toGroupedExpandedNode(
            groupedNode,
            groupedNode.more ? groupedNode.more + typeMap.size - 1 : typeMap.size, // -1 because we don't count grouped node
            resourcesCoords.y - COLLAPSED_GROUPED_NODE_HEIGHT - RESOURCE_NODES_GAP,
            categoriesCoords,
          ),
        );

        this.flowNodes.push(...childNodes);

        categoriesCoords.y +=
          resourcesCoords.y + RESOURCE_NODES_GAP + GROUPED_NODE_COLLAPSE_BUTTON_HEIGHT + COLLAPSED_GROUPED_NODE_HEIGHT;
      });

      categoriesCoords.y = initY;
      categoriesCoords.x += GROUPED_NODE_WIDTH + 100;
    });
  }

  private findGroupedNodeInMap(map: Map<string, AccessNodeModel>): AccessNodeModel | undefined {
    for (const node of map.values()) {
      if (node.more !== undefined) return node;
    }

    const result = map.values().next();
    if (result.done) {
      return undefined;
    }

    const firstNode = { ...result.value };
    firstNode.id = 'grouped:' + firstNode.type;
    firstNode.label = `${firstNode.type}s`;
    firstNode.metadata = {};

    return firstNode;
  }

  private hasOnlyGroupedNodeInMap(map: Map<string, AccessNodeModel>): boolean {
    const result = map.values().next();
    if (result.done) {
      return false;
    }

    return result.value.more !== undefined;
  }

  private toMaps() {
    this.edgesMap = this.edges.reduce((acc, edge) => {
      acc.targets.set(edge.target, true);
      acc.sources.set(edge.source, true);
      return acc;
    }, this.edgesMap);

    const nodesMap = new Map();
    for (const node of this.nodes) {
      const parentsMap = nodesMap.get(node.category) || new Map();
      const typesMap = parentsMap.get(node.type) || new Map();

      this.nodesHashMap.set(node.id, node);
      typesMap.set(node.id, node);
      parentsMap.set(node.type, typesMap);
      nodesMap.set(node.category, parentsMap);
    }

    // sort nodes map
    for (const category of [
      AccessNodeCategoryModel.Identity,
      AccessNodeCategoryModel.Other,
      AccessNodeCategoryModel.Action,
      AccessNodeCategoryModel.Resource,
    ]) {
      const categoryMap = nodesMap.get(category);
      if (categoryMap) {
        this.nodesMap.set(category, categoryMap);
      }
    }
  }

  private toGroupedCollapsedNode(
    node: AccessNodeModel,
    total: number,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    let type: NodeTypeTypes = 'grouped';
    if (this.isNodeInput(node)) {
      type = 'groupedInput';
    } else if (this.isNodeOutput(node)) {
      type = 'groupedOutput';
    }

    return {
      id: node.id,
      type,
      data: {
        node,
        icon: this.nodeIcon(node),
        total,
      },
      position: { ...position },
    };
  }

  private toGroupedExpandedNode(
    node: AccessNodeModel,
    total: number,
    childrenHeight: number,
    position: XYPosition,
  ): Node<GroupedNodeExpandedProps, NodeTypeTypes> {
    return {
      id: this.parentId(node),
      type: 'groupedExpanded',
      data: {
        node,
        icon: this.nodeIcon(node),
        total,
        childrenHeight,
      },
      position: { ...position },
    };
  }

  private parentId(node: AccessNodeModel): string {
    return `parent:${node.type}`;
  }

  private toResourceNode(node: AccessNodeModel, position: XYPosition): Node<NodeMetaProps, NodeTypeTypes> {
    let type: NodeTypeTypes = 'resource';
    if (this.isNodeInput(node)) {
      type = 'resourceInput';
    } else if (this.isNodeOutput(node)) {
      type = 'resourceOutput';
    }

    return {
      id: node.id,
      type,
      data: {
        node,
        icon: this.nodeIcon(node),
      },
      position: { ...position },
      parentNode: this.parentId(node),
    };
  }

  private toMoreNode(node: AccessNodeModel, more: number, position: XYPosition): Node<MoreNodeProps, NodeTypeTypes> {
    let type: NodeTypeTypes = 'more';
    if (this.isNodeInput(node)) {
      type = 'moreInput';
    } else if (this.isNodeOutput(node)) {
      type = 'moreOutput';
    }

    return {
      id: node.id,
      type,
      data: {
        node,
      },
      position: { ...position },
      parentNode: this.parentId(node),
    };
  }

  private isNodeInput(node: AccessNodeModel): boolean {
    return this.edgesMap.sources.has(node.id) && !this.edgesMap.targets.has(node.id);
  }

  private isNodeOutput(node: AccessNodeModel): boolean {
    return this.edgesMap.targets.has(node.id) && !this.edgesMap.sources.has(node.id);
  }

  private nodeIcon(node: AccessNodeModel): string {
    switch (node.type) {
      default:
        return '/static/k8s-icons/default.svg';
        break;
    }
  }
}
