import { useState } from 'react';
import { MaterialIcon } from '@components/MaterialIcon';
import { InputAdornment, IconButton } from '@mui/material';

import { LabeledTextFieldProps, LabeledTextField } from '@components/LabeledTextField';

export default function LabeledPasswordField(props: LabeledTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <LabeledTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="small"
              edge="end"
            >
              {showPassword ? (
                <MaterialIcon symbol="visibility_off" sx={{ display: 'block' }} />
              ) : (
                <MaterialIcon symbol="visibility" sx={{ display: 'block' }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
