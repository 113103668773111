import { Stack, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { DashboardPieWidgetTooltip } from './DashboardWidgetTooltip';
import { AccessFlowsUsageStatsFieldsFragment } from '@api/gql/graphql';
import { MARGIN, ResponsivePieWrapper } from '@DashboardV2/common/utils';

export function AccessFlowsUsageWidget({
  data,
  colors,
}: {
  data: AccessFlowsUsageStatsFieldsFragment;
  colors: string[];
}) {
  const widgetData = [
    {
      id: 'requested',
      label: 'Requested',
      value: data?.requested || 0,
      color: colors[0],
    },
    {
      id: 'notRequested',
      label: 'Not Requested',
      value: data?.notRequested || 0,
      color: colors[1],
    },
  ].filter((item) => item.value != 0);

  const Square = ({ color }: { color: string }) => {
    return <div style={{ width: '12px', height: '12px', backgroundColor: color }} />;
  };

  const Legend = () => (
    <Stack direction="row" spacing={1} alignItems="center">
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Square color={colors[0]} />
        <Typography variant="tooltip">Requested</Typography>
      </Stack>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Square color={colors[1]} />
        <Typography variant="tooltip">Not Requested</Typography>
      </Stack>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={2}>
      <ResponsivePieWrapper>
        <ResponsivePie
          data={widgetData}
          margin={{ right: MARGIN, left: MARGIN }}
          innerRadius={0}
          colors={(bar) => bar.data.color}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={0}
          activeInnerRadiusOffset={0}
          borderWidth={1}
          layers={['arcs', Legend]}
          tooltip={DashboardPieWidgetTooltip}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
        />
      </ResponsivePieWrapper>
      <Legend />
    </Stack>
  );
}
