import { ReactNode, useMemo } from 'react';
import { Box, Link, Stack, styled, Typography } from '@mui/material';

const InteractiveDropdownHeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.palette.text.dropdown}`,
}));

export interface InteractiveDropdownHeaderProps {
  title: string;
  onBackClick?: () => void;
  action?: ReactNode;
}

export function InteractiveDropdownHeader({ title, onBackClick, action }: InteractiveDropdownHeaderProps) {
  return (
    <InteractiveDropdownHeaderContainer>
      <InteractiveDropdownHeaderTitle title={title} onBackClick={onBackClick} action={action} />
    </InteractiveDropdownHeaderContainer>
  );
}

function InteractiveDropdownHeaderTitle({ title, onBackClick, action }: InteractiveDropdownHeaderProps) {
  const titleElement = useMemo(
    () => (
      <Typography variant="helperText" component="span">
        {title}
      </Typography>
    ),
    [title],
  );

  const onBackClickElement = useMemo(() => {
    if (!onBackClick) return undefined;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      onBackClick();
    };

    return (
      <Link data-testid="interactive-dropdown-header-back-btn" href="#" underline="none" onClick={handleClick}>
        <Typography variant="caption" color="inherit" component="span">
          Back
        </Typography>
      </Link>
    );
  }, [onBackClick]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      {titleElement}
      {action}
      {onBackClickElement}
    </Stack>
  );
}
