import { ReactNode, useMemo, useState } from 'react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { awsRegions } from '@utils';
import { AddIntegrationSelectField } from './ConnectIntegrationForm/AddIntegrationSelectField';
import { Markdown } from '@components/Markdown';
import { IntegrationConfigSecretParamAppModel, IntegrationsService } from '@api';
import { LabeledTextField } from '@components';
import { SearchSelectField, SelectOption } from './ConnectIntegrationForm/AddIntegrationSearchSelectField';
import isEmpty from 'lodash/isEmpty';
import LabeledPasswordField from '@components/LabeledPasswordField';

enum SecretType {
  AWS = 'AWS',
  GCP = 'GCP',
  KUBERNETES = 'KUBERNETES',
  AZURE = 'AZURE',
  APONO = 'APONO',
}

export type SecretConfig = {
  type: SecretType;
  secret_id?: string;
  region?: string;
  project?: string;
  namespace?: string;
  name?: string;
  vault_url?: string;
  params?: { [key: string]: string };
};

export const SupportedSecretTypes: { [k: string]: SecretType } = {
  'external-secret-manager': SecretType.AWS,
  'aws-secret-manager': SecretType.AWS,
  AWS: SecretType.AWS,
  'gcp-cloud-secret': SecretType.GCP,
  GCP: SecretType.GCP,
  kubernetes: SecretType.KUBERNETES,
  KUBERNETES: SecretType.KUBERNETES,
  azure: SecretType.AZURE,
  AZURE: SecretType.AZURE,
  APONO: SecretType.APONO,
};

export function CloudSecretForm({
  supportedSecretTypes,
  required,
  cloudAccountId,
  secretConfig,
  secretParams,
  onChange,
  disabled,
}: {
  supportedSecretTypes: Array<string>;
  required?: boolean;
  cloudAccountId?: string;
  secretConfig?: SecretConfig;
  secretParams?: IntegrationConfigSecretParamAppModel[];
  onChange: (value: SecretConfig) => void;
  disabled?: boolean;
}) {
  const [configType, setConfigType] = useState<SecretType>(
    secretConfig?.type || SupportedSecretTypes[supportedSecretTypes[0]],
  );

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1" color="text.primary" component="p">
        Secret Store {!required && '(optional)'}
      </Typography>
      <CloudSecretFormTabs
        configType={configType}
        supportedSecretTypes={supportedSecretTypes}
        onChange={(value) => setConfigType(value)}
      />
      {configType === SecretType.AWS && (
        <AWCSecretForm
          secretConfig={secretConfig}
          onChange={onChange}
          cloudAccountId={cloudAccountId}
          disabled={disabled}
          required={required}
        />
      )}
      {configType === SecretType.GCP && (
        <GCPSecretForm
          secretConfig={secretConfig}
          onChange={onChange}
          cloudAccountId={cloudAccountId}
          disabled={disabled}
          required={required}
        />
      )}
      {configType === SecretType.KUBERNETES && (
        <KubernetesSecretForm
          secretConfig={secretConfig}
          onChange={onChange}
          cloudAccountId={cloudAccountId}
          disabled={disabled}
          required={required}
        />
      )}
      {configType === SecretType.AZURE && (
        <AzureSecretForm
          secretConfig={secretConfig}
          onChange={onChange}
          cloudAccountId={cloudAccountId}
          disabled={disabled}
          required={required}
        />
      )}
      {configType === SecretType.APONO && secretParams && (
        <AponoSecretForm {...{ secretConfig, secretParams, onChange, cloudAccountId, disabled, required }} />
      )}
    </Stack>
  );
}

function CloudSecretFormTabs({
  configType,
  supportedSecretTypes,
  onChange,
}: {
  supportedSecretTypes: Array<string>;
  onChange: (value: SecretType) => void;
  configType: SecretType;
  disabled?: boolean;
}) {
  const tabs: Array<ReactNode> = [];
  supportedSecretTypes.forEach((type) => {
    if (!SupportedSecretTypes[type]) return;
    tabs.push(<Tab key={type} label={SupportedSecretTypes[type]} value={SupportedSecretTypes[type]} />);
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: SecretType) => {
    onChange(newValue);
  };

  return (
    <Tabs value={configType} onChange={handleTabChange}>
      {tabs}
    </Tabs>
  );
}

interface SecretFormProps {
  secretConfig?: SecretConfig;
  onChange: (value: SecretConfig) => void;
  cloudAccountId?: string;
  disabled?: boolean;
  required?: boolean;
}

function AWCSecretForm({ secretConfig, onChange, cloudAccountId, disabled, required }: SecretFormProps) {
  const [secretId, setSecretId] = useState<string>(secretConfig?.secret_id || '');
  const [region, setRegion] = useState<string>(secretConfig?.region || '');

  const isRequired = useMemo(() => required || !isEmpty(region) || !isEmpty(secretId), [region, secretId, required]);

  const handleRegionChange = (regionValue: string) => {
    setRegion(regionValue);
    handleOnChange(regionValue, secretId);
  };

  const handleSecretIdChange = (secretIdValue: string) => {
    setSecretId(secretIdValue);
    handleOnChange(region, secretIdValue);
  };

  const handleOnChange = (regionValue?: string, secretIdValue?: string) => {
    if (!isEmpty(regionValue) && !isEmpty(secretId)) {
      onChange({
        type: SecretType.AWS,
        region: regionValue,
        secret_id: secretIdValue,
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="body2" component="div" gutterBottom>
        <Markdown>
          {[
            'Apono doesn&apos;t store credentials. Please, specify where secret ID (to connect to the DB) is located and tag the secret in the AWS secret store the following way:',
            '<pre>Tag name: **apono-connector-read**, Value: **true**</pre>',
            'for a detailed explanation see the [docs](https://docs.apono.io/docs/creating-secrets-in-aws-secret-store).',
          ].join('')}
        </Markdown>
      </Typography>
      <AddIntegrationSelectField
        label={'Region'}
        values={awsRegions.map((regionValue) => ({
          id: regionValue,
          label: regionValue,
        }))}
        selectedValue={region}
        onChange={handleRegionChange}
        placeholder={'Region'}
        disabled={disabled}
        required={isRequired}
      />
      <SearchSelectField
        label="Secret Id"
        noOptionsText="No Secrets"
        selectedValue={secretId}
        onChange={handleSecretIdChange}
        fetchFn={async (input) => {
          if (isEmpty(region) || isEmpty(cloudAccountId) || !input) {
            return [];
          }
          const secrets = (
            input && cloudAccountId
              ? await IntegrationsService.listSecrets({
                  awsAccountId: cloudAccountId,
                  filter: input,
                  region,
                })
              : []
          ).filter((item) => {
            return item && item.name?.includes(input);
          });

          return secrets.map(
            (item) =>
              ({
                id: item.arn,
                label: item.name,
              } as SelectOption),
          );
        }}
        disabled={disabled}
        required={isRequired}
      />
    </Stack>
  );
}

function GCPSecretForm({ secretConfig, onChange, disabled, required }: SecretFormProps) {
  const [secretId, setSecretId] = useState<string>(secretConfig?.secret_id || '');
  const [project, setProject] = useState<string | undefined>(secretConfig?.project);

  const isRequired = useMemo(() => required || !isEmpty(secretId) || !isEmpty(project), [secretId, project, required]);

  const handleProjectChange = (projectValue: string) => {
    setProject(projectValue);
    handleOnChange(projectValue, secretId);
  };

  const handleSecretIdChange = (secretIdValue: string) => {
    setSecretId(secretIdValue);
    handleOnChange(project, secretIdValue);
  };

  const handleOnChange = (projectValue?: string, secretIdValue?: string) => {
    if (!isEmpty(projectValue) && !isEmpty(secretIdValue)) {
      onChange({
        type: SecretType.GCP,
        secret_id: secretIdValue,
        project: projectValue,
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="body2" component="div" gutterBottom>
        <Markdown>
          {[
            'Apono doesn&apos;t store credentials and uses the GCP secret manager. Please specify where the secret ID (used to connect to the DB) is located.',
            'For a detailed explanation see the [docs](https://docs.apono.io/docs/creating-secrets-in-google-secret-manager).',
          ].join('<br />')}
        </Markdown>
      </Typography>
      <LabeledTextField
        label="Project"
        value={project}
        onChange={(e) => handleProjectChange(e.target.value)}
        placeholder="Project"
        disabled={disabled}
        required={isRequired}
      />
      <LabeledTextField
        label="Secret Id"
        onChange={(e) => handleSecretIdChange(e.target.value)}
        placeholder="Secret Id"
        value={secretId}
        disabled={disabled}
        required={isRequired}
      />
    </Stack>
  );
}

function KubernetesSecretForm({ secretConfig, onChange, disabled, required }: SecretFormProps) {
  const [namespace, setNamespace] = useState<string>(secretConfig?.namespace || '');
  const [name, setName] = useState<string>(secretConfig?.name || '');

  const isRequired = useMemo(() => required || !isEmpty(namespace) || !isEmpty(name), [namespace, name, required]);

  const handleNamespaceChange = (namespaceValue: string) => {
    setNamespace(namespaceValue);
    handleOnChange(namespaceValue, name);
  };

  const handleNameChange = (nameValue: string) => {
    setName(nameValue);
    handleOnChange(namespace, nameValue);
  };

  const handleOnChange = (namespaceValue?: string, nameValue?: string) => {
    if (!isEmpty(namespaceValue) && !isEmpty(nameValue)) {
      onChange({
        type: SecretType.KUBERNETES,
        name: nameValue,
        namespace: namespaceValue,
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="body2" component="div" gutterBottom>
        <Markdown>
          {[
            'Apono doesn&apos;t store credentials and uses Kubernetes secrets. Please specify where the secret is located. [docs](https://docs.apono.io/docs/creating-secrets-in-kubernetes).',
          ].join('<br />')}
        </Markdown>
      </Typography>
      <LabeledTextField
        label="Namespace"
        value={namespace}
        onChange={(e) => handleNamespaceChange(e.target.value)}
        placeholder="Namespace"
        disabled={disabled}
        required={isRequired}
      />
      <LabeledTextField
        label="Name"
        value={name}
        onChange={(e) => handleNameChange(e.target.value)}
        placeholder="Name"
        disabled={disabled}
        required={isRequired}
      />
    </Stack>
  );
}

function AzureSecretForm({ secretConfig, onChange, disabled, required }: SecretFormProps) {
  const [vaultUrl, setVaultUrl] = useState<string>(secretConfig?.vault_url || '');
  const [name, setName] = useState<string>(secretConfig?.name || '');

  const isRequired = useMemo(() => required || !isEmpty(vaultUrl) || !isEmpty(name), [vaultUrl, name, required]);

  const handleVaultUrlChange = (vaultUrlValue: string) => {
    setVaultUrl(vaultUrlValue);
    handleOnChange(vaultUrlValue, name);
  };

  const handleNameChange = (nameValue: string) => {
    setName(nameValue);
    handleOnChange(vaultUrl, nameValue);
  };

  const handleOnChange = (vaultUrlValue?: string, nameValue?: string) => {
    if (!isEmpty(vaultUrlValue) && !isEmpty(nameValue)) {
      onChange({
        type: SecretType.AZURE,
        name: nameValue,
        vault_url: vaultUrlValue,
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="body2" component="div" gutterBottom>
        <Markdown>
          {[
            'Apono doesn&apos;t store credentials and uses Azure secrets. Please specify where the secret is located. [docs](https://docs.apono.io/docs/creating-secrets-in-azure-key-vault).',
          ].join('<br />')}
        </Markdown>
      </Typography>
      <LabeledTextField
        label="Vault URL"
        value={vaultUrl}
        onChange={(e) => handleVaultUrlChange(e.target.value)}
        placeholder="https://my-vault.vault.azure.net/"
        disabled={disabled}
        required={isRequired}
      />
      <LabeledTextField
        label="Name"
        value={name}
        onChange={(e) => handleNameChange(e.target.value)}
        placeholder="Name"
        disabled={disabled}
        required={isRequired}
      />
    </Stack>
  );
}

function AponoSecretForm({
  secretConfig,
  secretParams,
  onChange,
  disabled,
}: SecretFormProps & {
  secretParams: IntegrationConfigSecretParamAppModel[];
}) {
  const handleOnChange = (inputKey: string, inputVal?: string) => {
    const params = secretConfig?.params || {};
    params[inputKey] = inputVal || '';

    onChange({
      type: SecretType.APONO,
      params,
    });
  };

  return (
    <Stack spacing={1}>
      <Typography variant="body2" component="div" gutterBottom>
        Apono works with most cloud Secret Stores to prevent storing your secrets. For playground and testing purposes,
        you can supply Apono with the secrets to authenticate directly with the integration.
      </Typography>
      {secretParams.map((param: IntegrationConfigSecretParamAppModel) => {
        return (
          <LabeledPasswordField
            key={param.id}
            type="password"
            label={param.label}
            value={secretConfig?.params?.[param.id] || ''}
            placeholder={param.placeholder}
            disabled={disabled}
            required={!param.optional}
            onChange={(e) => handleOnChange(param.id, e.target.value)}
          />
        );
      })}
    </Stack>
  );
}
