import { GranteeModel, GranteeTypeModel, useListAvailableAttributesV2 } from '@api';
import { useGroupsFromState, useUsersFromState } from '@hooks';

import { HumanReadablePlaceholderProps, HumanReadableText } from '../common/HumanReadableText';
import { FlowFormErrorProps, FlowInlineTextProps } from '../common/Text';

function useHumanReadableGrantee(value: GranteeModel[]) {
  const { groups } = useGroupsFromState();
  const { users } = useUsersFromState();
  const { contextsAttributes } = useListAvailableAttributesV2();

  const txtGrantees: string[] = [];
  value.forEach((grantee) => {
    switch (grantee.type) {
      case GranteeTypeModel.Group: {
        const group = groups.find((g) => g.id === grantee.id);
        txtGrantees.push(group?.name || '');
        break;
      }
      case GranteeTypeModel.User: {
        const user = users?.find((u) => u.id === grantee.id);
        txtGrantees.push(user ? `${user.first_name} ${user.last_name}` : '');
        break;
      }
      case GranteeTypeModel.ExternalEmail:
        txtGrantees.push(grantee.id);
        break;
      case GranteeTypeModel.ContextAttribute: {
        const contextAttribute = contextsAttributes?.find((oc) => oc.id === grantee.id);
        txtGrantees.push(contextAttribute?.value || '');
        break;
      }
    }
  });

  return txtGrantees;
}

type Props = HumanReadablePlaceholderProps & FlowInlineTextProps & FlowFormErrorProps;

export interface HumanReadableGranteeProps extends Props {
  value: GranteeModel[];
}

export function HumanReadableGrantee(props: HumanReadableGranteeProps) {
  const { value, ...rest } = props;
  const grantees = useHumanReadableGrantee(value);
  return <HumanReadableText values={grantees} {...rest} />;
}
