import { HumanReadableText, HumanReadableTextProps } from '../common/HumanReadableText';
import { Timeframe } from '@api';
import { generateTimeOptions, getTimeOptionFromSeconds, getTimezoneFromValue, WeekDayNameShort } from '@utils';

interface HumanReadableTimeframeProps extends Omit<HumanReadableTextProps, 'value' | 'values'> {
  value?: Timeframe;
}

function useHumanReadableTimeframe(value?: Timeframe, inline?: HumanReadableTextProps['inline']) {
  if (!value) return 'When';

  const days: string[] = [];
  for (const day of value.days_in_week) {
    days.push(WeekDayNameShort[day]);
  }

  const timeOptions = generateTimeOptions(true);
  const startTime = getTimeOptionFromSeconds(value.start_time_sec, timeOptions);
  const endTime = getTimeOptionFromSeconds(value.end_time_sec, timeOptions);

  const timezone = getTimezoneFromValue(value.time_zone);
  const timezoneStr = timezone ? ` (GMT${timezone.utcOffsetStr})` : '';

  return `Only on ${days.join(', ')}, ${startTime?.label} to ${endTime?.label}${timezoneStr}${inline ? '' : ', when'}`;
}

export function HumanReadableTimeframe(props: HumanReadableTimeframeProps) {
  const { value, ...rest } = props;
  const timeframe = useHumanReadableTimeframe(value, props.inline);
  return <HumanReadableText value={timeframe} {...rest} />;
}
