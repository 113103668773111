import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert, CircularProgress, Stack, Tab } from '@mui/material';

import { AppDrawer, AppDrawerHeader } from '@components';

import { QueryParams } from '../../common/constants';

import { useGetActivityRequest } from './query';

import RequestDetails from './RequestDetails';
import RequestAccessUnitsWrapper from './RequestAccessUnits/RequestAccessUnitsWrapper';
import ActivityTimeline from './RequestTimeline/RequestTimeline';
import RevokeAccess from './RevokeAccess/RevokeAccess';
import TabsSwitch from '@common/ui/TabsSwitch';
import { AccessStatusModel } from '@common/constants';
import { TriggerTypeAppModel } from '@api';

const ACTIVITY_DRAWER_WIDTH = 800;

export default function ActivityDrawer() {
  const [queryParams, setQueryParams] = useSearchParams();

  const activityId = useMemo(() => queryParams.get(QueryParams.requestId), [queryParams]);

  const handleClose = () => {
    queryParams.delete(QueryParams.requestId);
    setQueryParams(queryParams);
  };

  return (
    <AppDrawer
      open={!!activityId}
      onClose={handleClose}
      header={<AppDrawerHeader title={`Access request details`} onCloseClick={handleClose} />}
      footer={activityId && <AppDrawerFooter id={activityId} />}
      width={ACTIVITY_DRAWER_WIDTH}
    >
      {activityId && <AppDrawerContent id={activityId} />}
    </AppDrawer>
  );
}

enum TabValues {
  Resources,
  Timeline,
}

function AppDrawerContent({ id }: { id: string }) {
  const { request, isFetched } = useGetActivityRequest(id);

  const [tab, setTab] = useState(TabValues.Resources);

  if (!isFetched) {
    return <CircularProgress />;
  }

  if (!request) {
    return <Alert severity="error">No activity found</Alert>;
  }

  return (
    <Stack spacing={2}>
      <RequestDetails request={request} />
      <TabsSwitch value={tab} onChange={(_, newTab) => setTab(newTab)} variant="fullWidth">
        <Tab label="Resources" value={TabValues.Resources} />
        <Tab label="Timeline" value={TabValues.Timeline} />
      </TabsSwitch>
      {tab === TabValues.Resources && <RequestAccessUnitsWrapper request={request} />}
      {tab === TabValues.Timeline && <ActivityTimeline request={request} />}
    </Stack>
  );
}

const revokableStatuses: string[] = [
  AccessStatusModel.GRANTED,
  AccessStatusModel.PENDING,
  AccessStatusModel.APPROVED,
  AccessStatusModel.Initializing,
  AccessStatusModel.Pending,
  AccessStatusModel.Granting,
  AccessStatusModel.Active,
];

function AppDrawerFooter({ id }: { id: string }) {
  const { request, isFetched } = useGetActivityRequest(id);

  if (
    !isFetched ||
    !request ||
    !revokableStatuses.includes(request.status) ||
    request.trigger_type === TriggerTypeAppModel.AutoGrant
  ) {
    return null;
  }

  return <RevokeAccess request={request} />;
}
