import { PropsWithChildren } from 'react';
import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';
import { init as initSaola } from '@saola.ai/browser';

export function SmokeTestsWrapper({ children }: PropsWithChildren) {
  const { isEnabled: allowSaolaSessionRecording } = useFlagFixed(Flag.ALLOW_SAOLA_SESSION_RECORDING);

  if (allowSaolaSessionRecording) {
    initSaola(SAOLA_CLIENT_ID ?? '');
  }

  return <>{children}</>;
}
