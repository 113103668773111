import { Stack, Typography } from '@mui/material';
import { Markdown } from '@components';
import { IntegrationConfigSecretParamAppModel } from '@api';
import { Controller, useFormContext } from 'react-hook-form';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import LabeledPasswordField from '@components/LabeledPasswordField';

const INFO =
  'Apono works with most cloud Secret Stores to prevent storing your secrets. For playground and testing purposes, you can supply Apono with the secrets to authenticate directly with the integration.';

export function AponoSecretForm({
  secret_params,
  isViewOnly,
}: {
  secret_params: IntegrationConfigSecretParamAppModel[];
  isViewOnly?: boolean;
}) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();
  return (
    <Stack spacing={3}>
      <Typography variant="body2" component="div" gutterBottom>
        <Markdown>{INFO}</Markdown>
      </Typography>
      <Stack direction="column" spacing={2}>
        {secret_params.map((params: IntegrationConfigSecretParamAppModel) => (
          <Controller
            key={params.label}
            control={control}
            name={`secret_config.params.${params.id}`}
            rules={{
              required: {
                value: !params.optional,
                message: `${params.label} is required`,
              },
            }}
            render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
              return (
                <LabeledPasswordField
                  label={params.label}
                  placeholder={params.placeholder}
                  error={invalid}
                  helperText={error?.message}
                  optional={!!params.optional}
                  value={value ?? ''}
                  disabled={isViewOnly}
                  {...{ onChange, onBlur, name }}
                />
              );
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
