import { useMemo } from 'react';
import { CreateIntegrationAppModel, IntegrationAppModel, IntegrationConfigAppModelV2, useListAgents } from '@api';
import { Box, Button, Stack, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { IntegrationConfigFormPayload, IntegrationConfigFormProvider, SecretStoreType } from './Provider';
import { ConfigInfo } from './ConfigInfo';
import { ConfigForm } from './ConfigForm/ConfigForm';
import { useGetAllowedResourceTypes } from '@organisms/IntegrationConnectorForm/SelectResourceTypes';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import ViewAsCode from './ConfigForm/ViewAsCode';
import { CustomAnalyticsEvents } from '@utils/analytics';

const FormColumn = styled(Box)({
  width: '420px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const EARLIEST_SUPPORTED_AONO_CONNECTOR_VERSION = 'v1.5.3';

export interface IntegrationConfigFormProps {
  config: IntegrationConfigAppModelV2;
  integration?: IntegrationAppModel;
  selectedResourceTypes?: string[];
  selectedConnectorId?: string;
  submitButtonText: string;
  loading?: boolean;
  onSubmit: (data: CreateIntegrationAppModel) => void;
  onBackClick?: () => void;
}

export function IntegrationConfigForm({
  config,
  integration,
  selectedResourceTypes,
  selectedConnectorId,
  ...rest
}: IntegrationConfigFormProps) {
  return (
    <IntegrationConfigFormProvider {...{ config, integration, selectedResourceTypes, selectedConnectorId }}>
      <IntegrationConfigFormContent {...{ config, integration, selectedConnectorId, ...rest }} />
    </IntegrationConfigFormProvider>
  );
}

function useIntegrationConfigFormContent({
  onSubmit,
  config,
}: Pick<IntegrationConfigFormProps, 'config' | 'onSubmit'>) {
  const { handleSubmit, formState } = useFormContext<IntegrationConfigFormPayload>();
  const { allowedResourceTypes } = useGetAllowedResourceTypes(config);

  const handleOnSubmit: SubmitHandler<IntegrationConfigFormPayload> = (data) => {
    if (!formState.isValid) {
      return;
    }

    if (data.secret_config) {
      const values = Object.values(data.secret_config).filter((x) => x);
      if (values.length === 1) {
        delete data.secret_config;
      }
    }

    if (allowedResourceTypes.length === 1) {
      data.connected_resource_types = allowedResourceTypes;
    }

    if (data.custom_access_details_settings?.instructions_access_message === '') {
      delete data.custom_access_details_settings.instructions_access_message;
    }

    onSubmit(data);
  };

  const isValidToSubmit = useMemo(() => formState.isValid, [formState.isValid]);

  return {
    isValidToSubmit,
    handleOnSubmit: handleSubmit(handleOnSubmit),
  };
}

function IntegrationConfigFormContent({
  config,
  integration,
  submitButtonText,
  loading,
  onSubmit,
  onBackClick,
  selectedConnectorId,
}: IntegrationConfigFormProps) {
  const { isValidToSubmit, handleOnSubmit } = useIntegrationConfigFormContent({ config, onSubmit });
  const { connectors } = useListAgents();
  const { isEnabled: isTerraFormUIEnabled } = useFlagFixed(Flag.TERRAFORM_UI);

  const connector = useMemo(
    () => connectors.find((c) => c.agent_id === selectedConnectorId || c.agent_id === integration?.provisioner_id),
    [connectors, integration, selectedConnectorId],
  );

  if (config.supported_secret_types.includes(SecretStoreType.APONO)) {
    const allowAponoSecret = isSupportedVersion(connector?.version);
    if (!allowAponoSecret) {
      const supportedSecretTypes = config.supported_secret_types.filter((t) => t !== SecretStoreType.APONO);
      config = { ...config, supported_secret_types: supportedSecretTypes };
    }
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={6}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={8}>
          <FormColumn>
            <ConfigForm config={config} integration={integration} />
          </FormColumn>
          <FormColumn>
            {isTerraFormUIEnabled && connector && (
              <ViewAsCode
                config={config}
                connectorId={connector.agent_id}
                data-trigger={CustomAnalyticsEvents.VIEW_AS_CODE_CLICKED_IN_INTEGRATION_FORM}
                data-props={JSON.stringify({
                  integrationType: config.type,
                })}
              />
            )}
            <ConfigInfo
              info={config.info}
              links={config.links}
              connectorId={integration?.provisioner_id || selectedConnectorId}
            />
          </FormColumn>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          {onBackClick && (
            <Button type="button" onClick={onBackClick}>
              Back
            </Button>
          )}
          <LoadingButton variant="contained" type="submit" disabled={!isValidToSubmit} loading={loading}>
            {submitButtonText}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}

function isSupportedVersion(selectedVersion?: string) {
  if (!selectedVersion) {
    return false;
  }

  const extractNumbers = (version: string) => version.slice(1).split('.').map(Number);

  const [num1, num2, num3] = extractNumbers(selectedVersion);

  const [num4, num5, num6] = extractNumbers(EARLIEST_SUPPORTED_AONO_CONNECTOR_VERSION);

  return !(num1 < num4 || (num1 === num4 && (num2 < num5 || (num2 === num5 && num3 < num6))));
}
