import { CSSProperties, ElementType, PropsWithChildren, ReactNode, useMemo, useState } from 'react';

import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  styled,
} from '@mui/material';
import { VariableSizeList as VirtualList } from 'react-window';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { EmptyState } from '@components/EmptyState';

const NON_VIRTUALIZED_LIMIT = 20;
const SELECT_ALL = 'Select All';
const SELECT_ALL_KEY = 'select_all_key';
const NOT_CATEGORIZED = '__not_categorized__';

export const InteractiveDropdownSelectList = styled(List)<{
  component?: ElementType;
}>({
  '&.not-virtualized': {
    maxHeight: 250,
    overflow: 'auto',
    width: '100%',
  },
});

export const ListItemIconStyled = styled(ListItemIcon)({
  minWidth: 'auto',
  marginTop: 0,
});

export interface InteractiveDropdownCategory {
  key: string;
  value: string;
  iconSrc?: string;
  icon?: ReactNode;
  secondary?: string;
  rightIcon?: ReactNode;
}

export interface InteractiveDropdownSelectOption<T = string> {
  key: T;
  value: string;
  iconSrc?: string;
  icon?: ReactNode;
  secondary?: string;
  hasNextView?: boolean;
  lookSelected?: boolean;
  lookMultiple?: boolean;
  deselectOnly?: boolean;
  isDisabled?: boolean;
  rightIcon?: ReactNode;
  categoryKey?: string;
  noInput?: boolean;
  dense?: boolean;
}

export interface GroupedInteractiveDropdownSelectOption<T = string> {
  key: T;
  value: string;
  icon?: ReactNode;
  secondary?: string;
  options: InteractiveDropdownSelectOption<T>[];
}

type InteractiveDropdownSelectProps<T = string, TMultiple = boolean> = TMultiple extends true
  ? {
      multiple: TMultiple;
      options: InteractiveDropdownSelectOption<T>[];
      value?: T[];
      onChange: (value: T[]) => void;
    }
  : {
      multiple?: TMultiple;
      options: InteractiveDropdownSelectOption<T>[];
      value?: T;
      onChange: (value: T) => void;
    };

interface CommonInteractiveDropdownSelectProps {
  hideEmptyState?: boolean;
  linkOptions?: ReactNode[];
  selectedFirst?: boolean;
  hasSelectAll?: boolean;
  emptyState?: ReactNode;
  categories?: InteractiveDropdownCategory[];
}

type OptionToRender<T> = {
  selectOption?: InteractiveDropdownSelectOption<T>;
  linkOption?: ReactNode;
  selectAllOption?: InteractiveDropdownSelectOption;
  categoryOption?: InteractiveDropdownCategory;
};

export function InteractiveDropdownSelect<T = string>({
  multiple,
  options,
  categories,
  value,
  hideEmptyState,
  linkOptions,
  selectedFirst,
  onChange,
  hasSelectAll,
  emptyState = <EmptyState imgSrc="/static/EmptyStateImages/not-found-illustration.svg" title="No options available" />,
}: InteractiveDropdownSelectProps<T, boolean> & CommonInteractiveDropdownSelectProps) {
  const virtualized = options.length > NON_VIRTUALIZED_LIMIT;
  const [isSelectAllOn, setIsSelectAllOn] = useState(false);

  const optionsToRender: OptionToRender<T>[] = useMemo(() => {
    const linkOptionsToRender = linkOptions?.map((linkOption) => ({ linkOption })) || [];

    const optionsMap = options.reduce<Record<string, InteractiveDropdownSelectOption<T>[]>>((acc, option) => {
      const category = option.categoryKey || NOT_CATEGORIZED;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(option);
      return acc;
    }, {});

    let renderOptions: OptionToRender<T>[] = [];

    for (const categoryKey of Object.keys(optionsMap)) {
      if (categoryKey !== NOT_CATEGORIZED) {
        const category = categories?.find((c) => c.key === categoryKey);
        if (category) {
          renderOptions.push({ categoryOption: category });
        }
      }

      const categoryOptions = optionsMap[categoryKey];
      let categoryRenderedOptions = [];
      if (selectedFirst) {
        const selected: OptionToRender<T>[] = [];
        const unselected: OptionToRender<T>[] = [];

        categoryOptions.forEach((option) => {
          const isSelected = multiple ? (value || []).includes(option.key) : value === option.key;
          if (isSelected) {
            selected.push({ selectOption: option });
          } else {
            unselected.push({ selectOption: option });
          }
        });

        categoryRenderedOptions = [...selected, ...unselected];
      } else {
        categoryRenderedOptions = categoryOptions.map((option) => ({ selectOption: option }));
      }

      renderOptions = [...renderOptions, ...categoryRenderedOptions];
    }

    if (hasSelectAll) {
      const selectAllOption: InteractiveDropdownSelectOption = {
        key: SELECT_ALL_KEY,
        value: SELECT_ALL,
      };
      return [{ selectAllOption }, ...renderOptions, ...linkOptionsToRender];
    }

    return [...renderOptions, ...linkOptionsToRender];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, options, linkOptions, selectedFirst, hasSelectAll]);

  const handleOnChange = (changeValue: T[]) => {
    if (multiple) {
      onChange(changeValue);
      setIsSelectAllOn(changeValue.length === optionsToRender.filter((o) => o.selectOption).length);
    }
  };

  const handleChange = (option: InteractiveDropdownSelectOption<T>) => {
    if (!multiple) {
      onChange(option.key);
      return;
    }

    let copyValue = value ? [...value] : [];

    if (copyValue.includes(option.key)) {
      copyValue = copyValue.filter((v) => v !== option.key);
    } else if (!option.deselectOnly) {
      copyValue.push(option.key);
    }

    handleOnChange(copyValue);
  };

  const handleSelectAllChange = () => {
    const newValue = [];
    if (isSelectAllOn) {
      handleOnChange([]);
      return;
    }
    for (const o of optionsToRender) {
      if (!o.selectOption) continue;
      newValue.push(o.selectOption.key);
    }
    handleOnChange(newValue);
    return;
  };

  const renderOption = (
    { selectAllOption, linkOption, selectOption, categoryOption }: OptionToRender<T>,
    style?: CSSProperties,
  ) => {
    if (selectAllOption) {
      return renderSelectAllOption(selectAllOption, style);
    }

    if (linkOption) {
      return renderLinkOption(linkOption, style);
    }

    if (selectOption) {
      return renderSelectOption(selectOption, style);
    }

    if (categoryOption) {
      return renderCategoryOption(categoryOption, style);
    }

    return <></>;
  };

  const getItemSize = (index: number) => {
    const { linkOption, selectOption } = optionsToRender[index];

    if (linkOption) return 48;

    if (selectOption) {
      return selectOption.secondary ? 54 : selectOption.dense ? 30 : 50;
    }

    return 50;
  };

  const renderSelectAllOption = (selectAllOption: InteractiveDropdownSelectOption<string>, style?: CSSProperties) => {
    return (
      <ListOption
        key={selectAllOption.key}
        multiple={multiple}
        selected={isSelectAllOn}
        onClick={handleSelectAllChange}
        option={selectAllOption}
        style={style}
      />
    );
  };

  const renderSelectOption = (option: InteractiveDropdownSelectOption<T>, style?: CSSProperties) => {
    const selected = multiple ? value?.includes(option.key) || false : value === option.key;
    return (
      <ListOption
        key={option.key as string}
        multiple={multiple}
        selected={selected}
        onClick={() => handleChange(option)}
        option={option}
        style={style}
      />
    );
  };

  const renderLinkOption = (option: ReactNode, style?: CSSProperties) => (
    <ListLink key={`link-option-${option}`} style={style}>
      {option}
    </ListLink>
  );

  const renderCategoryOption = (option: InteractiveDropdownCategory, style?: CSSProperties) => (
    <ListCategory key={option.key} option={option} style={style} />
  );

  const renderOptions = () =>
    virtualized ? (
      <VirtualList
        height={250}
        width="100%"
        itemSize={getItemSize}
        itemCount={optionsToRender.length}
        overscanCount={20}
      >
        {({ index, style }) => renderOption(optionsToRender[index], style)}
      </VirtualList>
    ) : (
      optionsToRender.map((option) => renderOption(option))
    );

  return (
    <InteractiveDropdownSelectList
      dense
      disablePadding
      component="div"
      className={!virtualized ? 'not-virtualized' : ''}
    >
      {options.length === 0 && !hideEmptyState ? emptyState : renderOptions()}
    </InteractiveDropdownSelectList>
  );
}

interface ListOptionProps<T> {
  multiple?: boolean;
  selected: boolean;
  onClick: () => void;
  option: InteractiveDropdownSelectOption<T>;
  style?: CSSProperties;
}

function ListOption<T>({ multiple, selected, onClick, option, style }: ListOptionProps<T>) {
  const RadioOrCheckbox = option.lookMultiple || multiple ? Checkbox : Radio;

  const secondary = option.secondary ? <small>{option.secondary}</small> : undefined;

  const avatar = option.icon ? (
    option.icon
  ) : option.iconSrc ? (
    <Avatar src={option.iconSrc} sx={{ width: 24, height: 24 }} variant="square" />
  ) : undefined;

  const isDisabledRadioOrCheckbox = option.isDisabled || (option.deselectOnly && !selected);

  return (
    <ListItem disablePadding style={style} component="div">
      <ListItemButton
        onClick={() => onClick()}
        data-testid="interactive-dropdown-select-option"
        disabled={option.isDisabled}
        sx={option.noInput && !option.dense ? { py: 1.6 } : undefined}
      >
        {!option.noInput && (
          <ListItemIconStyled>
            <RadioOrCheckbox
              data-testid="interactive-dropdown-select-option-radio-checkbox"
              edge="start"
              tabIndex={-1}
              value={option.key}
              disableRipple
              disabled={isDisabledRadioOrCheckbox}
              checked={option.lookSelected || selected}
            />
          </ListItemIconStyled>
        )}
        {avatar ? <ListItemAvatar sx={{ minWidth: 'auto', mr: 1.5 }}>{avatar}</ListItemAvatar> : undefined}
        <ListItemText
          primary={option.value}
          secondary={secondary}
          primaryTypographyProps={{ variant: 'body2', lineHeight: 1, noWrap: true }}
        />
        {option.rightIcon}
        {option.hasNextView ? <KeyboardArrowRightIcon /> : null}
      </ListItemButton>
    </ListItem>
  );
}

function ListLink({ children, style }: PropsWithChildren<{ style?: CSSProperties }>) {
  return (
    <ListItem style={style} component="div">
      <ListItemText primary={children} />
    </ListItem>
  );
}

interface ListCategoryProps {
  option: InteractiveDropdownCategory;
  style?: CSSProperties;
}

function ListCategory({ option, style }: PropsWithChildren<ListCategoryProps>) {
  const secondary = option.secondary ? <small>{option.secondary}</small> : undefined;

  const avatar = option.icon ? (
    option.icon
  ) : option.iconSrc ? (
    <Avatar src={option.iconSrc} sx={{ width: 24, height: 24 }} variant="square" />
  ) : undefined;

  return (
    <Box sx={{ width: '100%' }} style={style}>
      <Divider />
      <Stack direction="row" p={1.5} spacing={1} alignItems="center">
        {avatar ? <ListItemAvatar sx={{ minWidth: 'auto' }}>{avatar}</ListItemAvatar> : undefined}
        <ListItemText
          primary={option.value}
          secondary={secondary}
          primaryTypographyProps={{ variant: 'caption', lineHeight: 1 }}
        />
      </Stack>
    </Box>
  );
}
