export enum AccessFlowsQueryKeys {
  List = 'accessFlows/list',
  Details = 'accessFlows/details',
}

export enum AccessFlowsQueryKeysV2 {
  List = 'accessFlows/list/v2',
  Details = 'accessFlows/details/v2',
}

export enum BundleQueryKeys {
  List = 'bundles/list',
  Get = 'bundles/get',
}

export enum AttributesQueryKeys {
  ListGroups = 'attributes/listGroups',
  ListAttributes = 'attributes/listAttributes',
}
