import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Paper, Stack, styled, Typography } from '@mui/material';
import { MaterialIcon } from '@components/MaterialIcon';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

const TemplateSuggestionContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  height: '100%',
  transition: 'box-shadow 0.2s ease-in-out',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    border: `1px solid transparent`,
    zIndex: 0,
  },

  '&:hover': {
    boxShadow: theme.shadows[15],

    '&:before': {
      borderWidth: 2,
      borderColor: theme.palette.success.main,
    },
  },

  '& .content': {
    height: '100%',
  },
}));

const TemplateSuggestionSentence = styled(Box)({
  lineHeight: '36px',
});

function TemplateSuggestionTitle({ children }: { children: ReactNode }) {
  return (
    <Stack spacing={1} direction="row">
      <MaterialIcon symbol="assistant" color="success" />
      <Typography variant="subtitle1" color="primary">
        {children}
      </Typography>
    </Stack>
  );
}

export interface TemplateSuggestionProps {
  id: string;
  title: string;
  to: string;
}

export function TemplateSuggestion({ id, title, to, children }: PropsWithChildren<TemplateSuggestionProps>) {
  const { track } = useAnalyticsContext();

  const handleClick = () => {
    track(CustomAnalyticsEvents.TEMPLATE_CLICKED, {
      templateId: id,
    });
  };

  return (
    <TemplateSuggestionContainer elevation={1}>
      <Stack spacing={2} direction="column" justifyContent="space-between" className="content">
        <TemplateSuggestionTitle>{title}</TemplateSuggestionTitle>
        <TemplateSuggestionSentence>{children}</TemplateSuggestionSentence>
        <Button variant="outlined" color="primary" fullWidth to={to} component={Link} onClick={handleClick}>
          Start Here
        </Button>
      </Stack>
    </TemplateSuggestionContainer>
  );
}
