import { AttributeOperatorModel } from '@AccessFlows/common/constants';
import { FlowFormErrorProps, FlowInlineTextProps, HumanReadablePlaceholderProps, HumanReadableText } from '../common';

type Props = HumanReadablePlaceholderProps & FlowInlineTextProps & FlowFormErrorProps;

export interface HumanReadableAttributeOperatorProps extends Props {
  operator?: string;
}

export default function HumanReadableAttributeOperator({
  operator = 'is',
  ...rest
}: HumanReadableAttributeOperatorProps) {
  const operatorsMap: Record<string, string> = {
    [AttributeOperatorModel.Is]: 'is',
    [AttributeOperatorModel.IsNot]: 'is not',
    [AttributeOperatorModel.Contains]: 'contains',
    [AttributeOperatorModel.DoesNotContain]: 'does not contain',
    [AttributeOperatorModel.StartsWith]: 'starts with',
  };

  return <HumanReadableText value={operatorsMap[operator] ?? operator} {...rest} />;
}
