import { useQuery, useQueryClient } from '@tanstack/react-query';

import { AccessFlowsService } from '@api';

import { BundleQueryKeys } from './queryKeys';

export function useAppListAccessBundles() {
  const queryClient = useQueryClient();

  const fetchMethod = async () => {
    const { data } = await AccessFlowsService.appListAccessBundles();

    data.forEach((bundle) => {
      queryClient.setQueryData([BundleQueryKeys.Get, bundle.id], bundle);
    });

    return data;
  };

  const { data: bundles, ...rest } = useQuery([BundleQueryKeys.List], fetchMethod, {
    initialData: [],
  });

  return {
    bundles,
    ...rest,
  };
}

export function useAppGetBundle(id: string) {
  const { data: bundle, ...rest } = useQuery({
    queryKey: [BundleQueryKeys.Get, id],
    queryFn: async () => AccessFlowsService.appGetAccessBundle({ id }),
  });

  return {
    bundle,
    ...rest,
  };
}
