import {
  AccessTargetAppModel,
  AccessTargetType,
  ApproverAppModel,
  AttributeFilterAppModel,
  DayOfWeek,
  GranteeFilterGroupOperatorAppModel,
  GranteeModel,
  LabelAppModel,
  TagSelectorModel,
  TriggerType,
} from '@api';
import { yup } from '@libs';

export const messages = {
  name: 'Please enter a name',
  trigger_type: 'Please choose a trigger that needs to occur',
  target_type: 'Please choose a target',
  active: 'Please select active',
  grantees: 'Please choose who should be able to receive the access via this flow',
  approvers: 'Please choose the approval method that should be used via this flow',
  integration_id: 'Please choose what application or service this flow will grant access to',
  bundle_id: 'Please choose what bundle this flow will grant access to',
  resources: 'Please choose the resources that should be received via this flow',
  permissions: 'Please choose the permissions that should be received via this flow',
  timeframe: 'Please choose the timeframe that this flow should be active',
};

export const resourceTagTagSchema = yup.object({
  name: yup.string().required(),
  value: yup.string().required(),
});

export const accessTargetIntegrationSchema = yup.object({
  integration_id: yup.string().min(1, messages.integration_id).required(messages.integration_id),
  resource_type: yup.string().min(1, messages.integration_id).required(messages.integration_id),
  resource_tag_matchers: yup.array<TagSelectorModel>(resourceTagTagSchema),
  resource_tag_excludes: yup.array<TagSelectorModel>(resourceTagTagSchema),
  permissions: yup.array().of(yup.string()).min(1, messages.permissions).required(),
});

export const accessTargetBundleSchema = yup.object({
  bundle_id: yup.string().min(1, messages.bundle_id).required(messages.bundle_id),
});

export const accessTargetSchema = yup.object({
  target_type: yup
    .mixed<AccessTargetType>()
    .oneOf(Object.values(AccessTargetType), messages.target_type)
    .required(messages.target_type),
  integration: yup.object().when('target_type', {
    is: AccessTargetType.Integration,
    then: accessTargetIntegrationSchema,
  }),
  bundle: yup.object().when('target_type', {
    is: AccessTargetType.Bundle,
    then: accessTargetBundleSchema,
  }),
});

const schemaObject = {
  name: yup.string().required(messages.name),
  active: yup.boolean().required(messages.active),
  trigger_type: yup
    .mixed<TriggerType>()
    .oneOf(Object.values(TriggerType), messages.trigger_type)
    .required(messages.trigger_type),
  grantees: yup.array<GranteeModel>().required(messages.grantees).min(1, messages.grantees),
  granteesV2: yup.mixed<GranteeFilterGroupOperatorAppModel>().default(undefined),
  targets: yup
    .array<AccessTargetAppModel>(accessTargetSchema)
    .required(messages.integration_id)
    .min(1, messages.integration_id),
  approvers: yup.array<ApproverAppModel>().required(messages.approvers),
  revoke_after_in_sec: yup.number().required(),
  justification_required: yup.boolean().required(),
  require_all_approvers: yup.boolean(),
  settings: yup
    .object({
      require_approver_justification: yup.boolean(),
      approver_cannot_approve_himself: yup.boolean(),
      required_mfa: yup.boolean(),
    })
    .default(undefined),
  timeframe: yup
    .object({
      start_time_sec: yup.number().required(),
      end_time_sec: yup.number().required(),
      days_in_week: yup.array<typeof DayOfWeek>().required(messages.timeframe),
      time_zone: yup.string().required(messages.timeframe),
    })
    .default(undefined),
  labels: yup.array<LabelAppModel>().required(),
};
export const schema = yup.object(schemaObject);

export const schemaV2 = yup.object({
  ...schemaObject,
  grantees: yup.array<GranteeModel>().default(undefined),
  granteesV2: yup
    .object({
      logical_operator: yup
        .mixed<GranteeFilterGroupOperatorAppModel>()
        .oneOf(Object.values(GranteeFilterGroupOperatorAppModel), messages.trigger_type)
        .required(messages.trigger_type),
      attribute_filters: yup.array<AttributeFilterAppModel>().required(messages.grantees).min(1, messages.grantees),
    })
    .required(messages.grantees),
  approver_policy: yup
    .object({
      groups_operator: yup.string().required(),
      condition_groups: yup.array().of(
        yup.object({
          logical_operator: yup.string().required(),
          conditions: yup.array().of(
            yup.object({
              attribute_condition: yup.object().default(undefined),
              request_context_based_attribute_condition: yup.object().default(undefined),
            }),
          ),
        }),
      ),
    })
    .default(undefined),
});
