import { AccessGraphAccessTargetNode } from '@api/gql/graphql';
import { RESOURCE_NODE_WIDTH, RESOURCE_NODES_GAP } from './common';
import { ExpandButton } from '@components';
import { Box, Stack, styled } from '@mui/material';
import { LabelWrap, NodeMetaContainer, ResourceNodeAvatar, ResourceNodeTypography } from './node';
import { Handle, NodeProps, Position } from 'reactflow';
import { AVAILABLE_ACCESS_COLOR } from './node_and_edge_types';

export type AccessTargetNodeProps = {
  node: AccessGraphAccessTargetNode;
  childrenHeight: number;
  hasConnectedEdges: boolean;
};

export interface NodeMetaProps {
  node: AccessGraphAccessTargetNode;
  allowExpand?: boolean;
}

const GroupedNodeWrapper = styled(Box)(({ theme }) => ({
  cursor: 'default',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
  padding: `12px`,
  width: RESOURCE_NODE_WIDTH,

  '& .collapse-btn': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    width: RESOURCE_NODE_WIDTH,
    color: theme.palette.text.dropdown,
  },
}));

export function AccessTargetNodeExpanded({ data }: NodeProps<AccessTargetNodeProps>) {
  const { childrenHeight, node, hasConnectedEdges } = data;

  return (
    <GroupedNodeWrapper>
      {hasConnectedEdges && (
        <Handle
          type="target"
          position={Position.Left}
          id={node.id}
          style={{ backgroundColor: AVAILABLE_ACCESS_COLOR, borderColor: AVAILABLE_ACCESS_COLOR }}
        />
      )}
      <Stack direction="column" alignItems="start" spacing={`${RESOURCE_NODES_GAP / 2}px`}>
        <AccessTargetNodeMeta node={node} />
        {childrenHeight > 0 ? <Box sx={{ height: childrenHeight }}></Box> : null}
        <div style={{ alignSelf: 'center' }}>
          <ExpandButton less />
        </div>
      </Stack>
    </GroupedNodeWrapper>
  );
}

export function AccessTargetNode({ data }: NodeProps<AccessTargetNodeProps>) {
  const { node } = data;
  return (
    <GroupedNodeWrapper>
      <Handle
        type="target"
        position={Position.Left}
        id={node.id}
        style={{ backgroundColor: AVAILABLE_ACCESS_COLOR, borderColor: AVAILABLE_ACCESS_COLOR }}
      />
      <AccessTargetNodeMeta node={node} allowExpand />
    </GroupedNodeWrapper>
  );
}

function AccessTargetNodeMeta({ node, allowExpand }: NodeMetaProps) {
  // Build Label
  const label = buildAccessTargetLabel(node);
  const icon = getAccessTargetIcon(node);
  const total = node?.totalChildren;
  const showExpand = (node.totalChildren || 0) > 0 && allowExpand;

  return (
    <NodeMetaContainer direction="row" alignItems="center" justifyContent="space-between" spacing={0.5}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon && <ResourceNodeAvatar src={icon} variant="circular" />}
        <Stack direction="column" alignItems="start">
          <LabelWrap>
            <ResourceNodeTypography variant="caption" component="div" color="text.primary" noWrap>
              {label}
            </ResourceNodeTypography>
          </LabelWrap>
          {total ? (
            <ResourceNodeTypography variant="helperText" component="div" color="text.muted">
              {total} {total == 0 || total > 1 ? 'Resources' : 'Resource'}
            </ResourceNodeTypography>
          ) : null}
        </Stack>
      </Stack>
      {showExpand && <ExpandButton />}
    </NodeMetaContainer>
  );
}

export function buildAccessTargetLabel(node: AccessGraphAccessTargetNode) {
  return node?.accessTarget?.resourceType.typeDisplayName;
}

export function getAccessTargetIcon(node: AccessGraphAccessTargetNode) {
  return node?.accessTarget?.resourceType.icons.png;
}
