import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RouterLink } from '@libs';
import { routes } from '@routes';

import { QueryParams } from '../common/constants';
import { Flag } from '@utils';
import { ProButton } from '@components';

export default function ReportButton() {
  const [qp] = useSearchParams();

  const to = useMemo(() => {
    qp.delete(QueryParams.page);
    return `${routes.AddReport.path}?${qp.toString()}`;
  }, [qp]);

  return (
    <ProButton flag={Flag.PREMIUM_CREATE_REPORT_BUTTON} variant="contained" size="small" component={RouterLink} to={to}>
      Create Report
    </ProButton>
  );
}
