import { TableRow, TableCell, Typography } from '@mui/material';

import { AuditEventsQuery } from '@api/gql/graphql';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { DateTime } from '@utils';

interface AuditEventsTableRowProps {
  node: AuditEventsQuery['auditEvents']['edges'][0]['node'];
}

export default function AuditEventsTableRow({ node }: AuditEventsTableRowProps) {
  return (
    <TableRow>
      <TableCell component="td">
        {node.user && (
          <PrimarySecondary primary={`${node.user.firstName} ${node.user.lastName}`} secondary={node.user.email} />
        )}
      </TableCell>
      <TableCell component="td">{DateTime.fromString(node.timestamp).readableWithSeconds}</TableCell>
      <TableCell component="td">
        {node.integration && <PrimarySecondary primary={node.integration.name} icon={node.integration.icons.svg} />}
      </TableCell>
      <TableCell component="td">{node.eventType}</TableCell>
      <TableCell component="td" sx={{ maxWidth: 250 }}>
        <Typography variant="body2" noWrap title={node.action || ''}>
          {node.action}
        </Typography>
      </TableCell>
      <TableCell component="td" sx={{ maxWidth: 250 }}>
        <Typography variant="body2" noWrap title={node.target || ''}>
          {node.target}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
