import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';

import { LABEL_FILTER_PARAM } from '@AccessFlows/common/constants';

import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { Avatar, styled } from '@mui/material';

const LabelColor = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  marginRight: theme.spacing(1),
}));

export interface LabelFilterOption {
  value: string;
  label: string;
  color?: string;
  searchHelper?: string;
}

interface LabelsFilterProps {
  options: LabelFilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export const LABEL_FILTER_LABEL = 'Label';

export default function LabelFilter({ options, opened = false, onClose, onOpen }: LabelsFilterProps) {
  const [isOpen, setIsOpen] = useState(opened);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedQp = searchParams.getAll(LABEL_FILTER_PARAM);

  const granteeOptions = [];
  const selectedOptions = [];
  for (const option of options) {
    const listOption = {
      label: option.label,
      value: option.value,
      searchHelper: option.searchHelper,
      icon: (
        <LabelColor sx={{ backgroundColor: option.color }} variant="rounded">
          {' '}
        </LabelColor>
      ),
    };

    if (selectedQp.includes(option.value)) {
      selectedOptions.push(listOption);
    }

    granteeOptions.push(listOption);
  }

  const handleOnSelect = (values: ListFilterOption[]) => {
    searchParams.delete(LABEL_FILTER_PARAM);
    for (const value of values) {
      searchParams.append(LABEL_FILTER_PARAM, value.value);
    }
    setSearchParams(searchParams);
  };

  const handleOnClear = () => {
    searchParams.delete(LABEL_FILTER_PARAM);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={LABEL_FILTER_LABEL}
          value={selectedOptions}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
      width={250}
    >
      <ListFilterMultiSelect
        options={granteeOptions}
        value={selectedOptions}
        onSelect={handleOnSelect}
        enableSearch
        title="Select Label Filter"
      />
    </ListFilter>
  );
}
