import { Controller, useFormContext } from 'react-hook-form';
import { Box, Divider, Grid, Stack } from '@mui/material';

import { IntegrationConfigParamAppModel } from '@api';

import { MetadataInputField } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/ConfigForm';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import FormSectionHeader from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionHeader';
import { MaterialIcon } from '@components';

export function CategoryConfigFormSection({
  title,
  icon,
  categorizedParams,
  divider = true,
  disabled,
}: {
  title: string;
  icon?: string;
  categorizedParams: IntegrationConfigParamAppModel[];
  divider?: boolean;
  disabled?: boolean;
}) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <Stack spacing={2}>
      <FormSectionHeader titleIcon={icon ? <MaterialIcon symbol={icon} /> : undefined} title={title} />
      <Box>
        <Grid container spacing={2}>
          {categorizedParams.map((p) => (
            <Grid item xs={6} key={p.id}>
              <Controller
                control={control}
                name={`metadata.${p.id}`}
                render={(props) => <MetadataInputField disabled={disabled} param={p} {...props} />}
                rules={{
                  required: {
                    value: !p.optional,
                    message: `${p.label} is required`,
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {divider && <Divider />}
    </Stack>
  );
}
