import { WebhookTriggerEventAppModel, WebhookUpdateAppModel } from '@api';
import { LabeledMultiSelectField } from '@components';
import { Controller, useFormContext } from 'react-hook-form';

export default function TriggersField({ triggers }: { triggers: string[] }) {
  const { control, setValue } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Controller
      control={control}
      name="triggers"
      rules={{
        required: 'Triggers are required',
      }}
      render={({ field: { onChange, value, ...restField }, fieldState: { invalid, error } }) => (
        <LabeledMultiSelectField
          data-testid="triggers-field"
          label="Triggers"
          options={triggers}
          values={value}
          onChange={(newValue) => {
            const valuesArray = newValue as string[];
            onChange(valuesArray);
            if (valuesArray.includes(WebhookTriggerEventAppModel.Manual)) {
              setValue('filters', {});
            }
          }}
          error={invalid}
          helperText={error?.message}
          inputProps={restField}
        />
      )}
    />
  );
}
