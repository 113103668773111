import { IntegrationConfigAppModelV2 } from '@api';
import { Divider, Stack } from '@mui/material';
import { CustomAccessDetailsField } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/CustomAccessDetails/CustomAccessDetailsField';
import FormSectionHeader from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionHeader';
import { MaterialIcon } from '@components';

export function CustomAccessDetailsFormSection({
  config,
  divider = true,
  disabled,
}: {
  config: IntegrationConfigAppModelV2;
  divider?: boolean;
  disabled?: boolean;
}) {
  return (
    <Stack spacing={2}>
      <FormSectionHeader
        optional
        titleIcon={<MaterialIcon symbol="add_notes" />}
        title="Custom Access Details"
        tooltip="Customize the access details message that will be displayed to end users when they access this integration."
      />
      <CustomAccessDetailsField integrationConfig={config} disabled={disabled} />
      {divider && <Divider />}
    </Stack>
  );
}
