import { CHILD_NODE_WIDTH, MORE_NODE_HEIGHT } from './common';
import { Box, styled, Typography } from '@mui/material';
import { Handle, NodeProps, Position } from 'reactflow';
import { aponoColors } from '@utils';
import { AccessGraphResourceMoreNode } from '@api/gql/graphql';
import { ACTIVE_ACCESS_COLOR } from './node_and_edge_types';

const MoreNodeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: CHILD_NODE_WIDTH,
  height: MORE_NODE_HEIGHT,
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center',
  padding: '8px',

  '&.resource': {
    border: `1px solid ${ACTIVE_ACCESS_COLOR}`,
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: `1px solid transparent`,
  },

  '&:hover': {
    '&:before': {
      borderColor: theme.palette.warning.light,
    },
  },
}));

type MoreNodeProps = { additionalNodes: number; node: AccessGraphResourceMoreNode };

export function ResourceMoreNode({ data }: NodeProps<MoreNodeProps>) {
  const { additionalNodes, node } = data;

  return (
    <MoreNodeWrapper className="resource">
      <Handle
        type="target"
        id={node.id}
        position={Position.Left}
        style={{ backgroundColor: ACTIVE_ACCESS_COLOR, borderColor: ACTIVE_ACCESS_COLOR }}
      />
      <Typography variant="caption" color={aponoColors.primary[500]}>
        {additionalNodes} More
      </Typography>
    </MoreNodeWrapper>
  );
}

export function UserMoreNode({ data }: NodeProps<MoreNodeProps>) {
  const { additionalNodes, node } = data;
  return (
    <MoreNodeWrapper>
      <Handle
        type="source"
        position={Position.Right}
        id={node.id}
        style={{ borderColor: ACTIVE_ACCESS_COLOR, backgroundColor: ACTIVE_ACCESS_COLOR }}
      />
      <Typography variant="caption" color={aponoColors.primary[500]}>
        {additionalNodes} More
      </Typography>
    </MoreNodeWrapper>
  );
}
