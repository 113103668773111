import { isValidElement, PropsWithChildren, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, ButtonBase, Fade, Link, Stack, styled, Tooltip, Typography } from '@mui/material';

import { AnalyticsTriggerElementProps } from '@common/types';

import AppSidebarItemWrapper from './AppSidebarItemWrapper';
import { MaterialIcon } from '@components';
import useAnimatedLabel from '@common/hooks/use-animated-label';

const AppSidebarItemContainer = styled(Box)(({ theme }) => ({
  transition: 'all 0.3s ease',

  '& .item-icon': {
    display: 'block',
    width: theme.spacing(4),
    height: theme.spacing(3),
    position: 'relative',

    '& > .item-icon-container': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },

  '& > a': {
    display: 'block',
    padding: theme.spacing(1, 1.5),
    color: theme.palette.text.primary,
    cursor: 'pointer',
    transition: 'all 0.3s ease',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  '& > button': {
    display: 'block',
    padding: theme.spacing(1.5),
    color: theme.palette.text.primary,
    transition: 'all 0.3s ease',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  '& > div': {
    display: 'block',
    padding: theme.spacing(1.5),
    color: theme.palette.text.primary,
    transition: 'all 0.3s ease',
  },

  '&.active': {
    margin: theme.spacing(0, 0.75),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    transition: 'all 0.3s ease',

    '& > a': {
      color: theme.palette.primary.main,
    },
  },

  '&.hoverable': {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const AppSidebarItemButton = styled(ButtonBase)({
  display: 'block',
  width: '100%',
  textAlign: 'left',
  position: 'relative',
});

interface AppSidebarItemProps {
  icon: React.ReactNode;
  label?: React.ReactNode;
  minimized?: boolean;
  enableAnimation?: boolean;
  to?: string;
  onClick?: () => void;
  newTab?: boolean;
}

export default function AppSidebarItem({
  icon,
  label,
  minimized,
  enableAnimation,
  to,
  onClick,
  active,
  newTab,
  hoverable,
  ...analyticsProps
}: AppSidebarItemProps & ItemWrapperProps & AnalyticsTriggerElementProps) {
  const labelVisible = useAnimatedLabel({ visible: minimized, enabled: enableAnimation });

  const ItemIcon = useMemo(() => {
    const elem = (
      <div className="item-icon">
        <div className="item-icon-container">{typeof icon === 'string' ? <MaterialIcon symbol={icon} /> : icon}</div>
      </div>
    );

    if (!minimized) return elem;

    return (
      <Tooltip title={typeof label === 'string' ? label : ''} placement="right" arrow>
        {elem}
      </Tooltip>
    );
  }, [icon, label, minimized]);

  const ItemLabel = useMemo(() => {
    if (!label || !labelVisible) return null;

    return (
      <Fade in={labelVisible} timeout={200}>
        <Box sx={{ flex: 1 }}>
          {isValidElement(label) ? (
            label
          ) : (
            <Typography variant="caption" component="div" color="inherit" noWrap>
              {label}
            </Typography>
          )}
        </Box>
      </Fade>
    );
  }, [label, labelVisible]);

  const ItemContent = useMemo(() => {
    return (
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        {ItemIcon}
        {ItemLabel}
      </Stack>
    );
  }, [ItemIcon, ItemLabel]);

  if (to) {
    return (
      <ItemWrapper {...{ active, hoverable }}>
        <Link component={RouterLink} to={to} target={newTab ? '_blank' : undefined} {...analyticsProps}>
          {ItemContent}
        </Link>
      </ItemWrapper>
    );
  }

  if (onClick) {
    return (
      <ItemWrapper {...{ active, hoverable }}>
        <AppSidebarItemButton onClick={onClick}>{ItemContent}</AppSidebarItemButton>
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper {...{ active, hoverable }}>
      <div>{ItemContent}</div>
    </ItemWrapper>
  );
}

interface ItemWrapperProps {
  active?: boolean;
  hoverable?: boolean;
}

function ItemWrapper({ children, active, hoverable }: PropsWithChildren<ItemWrapperProps>) {
  const classes = [];
  if (active) classes.push('active');
  if (hoverable) classes.push('hoverable');

  return (
    <AppSidebarItemWrapper padded={false}>
      <AppSidebarItemContainer className={classes.join(' ')}>{children}</AppSidebarItemContainer>
    </AppSidebarItemWrapper>
  );
}
