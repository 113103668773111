import { Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { IntegrationRow } from '@DashboardV2/components/TopListWidget/IntegrationRow';

export type IntegrationRowModel = {
  iconSrc?: string;
  id: string;
  title: string;
  subTitle?: string;
  color: string;
  sum: number;
  percent: number;
};

const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    background: theme.palette.background.default,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.default,
  },
}));

export function IntegrationRows({
  firstFiveRows,
  otherRows,
}: {
  firstFiveRows: IntegrationRowModel[];
  otherRows: IntegrationRowModel[];
}) {
  const otherRow: IntegrationRowModel = {
    title: 'Other',
    color: '#495469',
    sum: otherRows.reduce((acc, row) => acc + row.sum, 0),
    percent: Number(otherRows.reduce((acc, row) => acc + row.percent, 0).toFixed(2)),
    id: 'other',
  };

  return (
    <Stack spacing={2} flexGrow={1}>
      {firstFiveRows.map((integration, index) => (
        <IntegrationRow key={index} {...integration} />
      ))}
      {otherRows.length > 0 && (
        <StyledTooltip
          title={
            <Stack spacing={2} p={2} width={380}>
              {otherRows.map((integration, index) => (
                <IntegrationRow key={index} {...integration} />
              ))}
            </Stack>
          }
          placement="top-start"
        >
          <div>
            <IntegrationRow {...otherRow} />
          </div>
        </StyledTooltip>
      )}
    </Stack>
  );
}
