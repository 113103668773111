import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { MaterialIcon } from '@components';

export function OnboardingStepper({
  steps,
  currentStep,
  orientation = 'vertical',
  withStepTitle = false,
}: {
  steps: string[];
  currentStep?: number;
  orientation?: 'horizontal' | 'vertical';
  withStepTitle?: boolean;
}) {
  return (
    <Box width="100%" maxWidth="42rem">
      <Stepper activeStep={currentStep} orientation={orientation} alternativeLabel={orientation === 'horizontal'}>
        {steps.map((label, index) => {
          const isCompleted = currentStep ? index < currentStep : false;
          return (
            <Step key={label} completed={isCompleted}>
              <StepLabel
                icon={
                  isCompleted ? (
                    <MaterialIcon symbol="check_circle" color="primary" />
                  ) : (
                    <MaterialIcon
                      symbol="radio_button_checked"
                      color={index === currentStep ? 'primary' : 'disabled'}
                    />
                  )
                }
              >
                {withStepTitle && <Typography variant="muted">Step {index + 1}</Typography>}
                <Typography
                  variant="body2"
                  fontWeight={index === currentStep || withStepTitle ? 'bold' : 'normal'}
                  color={index === currentStep || withStepTitle ? 'text.primary' : 'text.disabled'}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
