import { Stack, Typography } from '@mui/material';
import { SidebarItem } from '@components/Sidebar/SidebarItem';
import { ProIconType, UpgradeWrapper } from '@components/Packages/UpgradeWrapper';

type CategoryData = {
  text: string;
  link: string;
  active?: boolean;
};

export interface NavItemMainProps {
  icon?: JSX.Element;
  text?: string;
  color?: string;
  active?: boolean;
  hasArrow?: boolean;
  aType?: 'internal' | 'external';
  to?: string;
  onClick?: () => void;
  isHovered?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isPremium?: boolean;
}

export function NavItem({
  icon,
  text,
  color,
  to,
  active,
  aType,
  onClick,
  isHovered = false,
  onMouseEnter,
  onMouseLeave,
  isPremium = false,
}: NavItemMainProps) {
  return (
    <SidebarItem
      icon={icon}
      active={active}
      to={to}
      aType={aType}
      onClick={onClick}
      hovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <UpgradeWrapper isPremium={isPremium} iconType={ProIconType.NoIcon} withPopover={false}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Typography variant="caption" fontWeight="600" color={color}>
            {text}
          </Typography>
        </Stack>
      </UpgradeWrapper>
    </SidebarItem>
  );
}

export interface NavItemSecondaryProps {
  text?: string;
  to?: string;
  external?: boolean;
  active?: boolean;
  onClick?: () => void;
}

export function NavItemSecondary({ text, to, external, active, onClick }: NavItemSecondaryProps) {
  return (
    <SidebarItem to={to} textActive={active} external={external} onClick={onClick}>
      <Typography variant="caption">{text}</Typography>
    </SidebarItem>
  );
}

export interface NavItemProps {
  icon?: JSX.Element;
  categoryText?: string;
  to?: string;
  subCategories: CategoryData[];
  wide?: boolean;
  active?: boolean;
  onClick?: () => void;
  isHovered?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isPremium: boolean;
}

export default NavItem;
