import { AVAILABLE_COLORS } from '@common/constants';
import { toInitials } from '@common/utils/helpers';
import { Avatar, Stack, Typography, Card } from '@mui/material';
import { useMemo } from 'react';

interface UserBannerProps {
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
}

export default function UserBanner({ firstName, lastName, email, role }: UserBannerProps) {
  const avatarColor = useMemo(() => AVAILABLE_COLORS[Math.floor(Math.random() * AVAILABLE_COLORS.length)], []);

  const initials = toInitials([firstName, lastName]);

  return (
    <Card variant="userBanner">
      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar variant="small" sx={{ backgroundColor: avatarColor }}>
          {initials}
        </Avatar>
        <Stack gap={0.5}>
          <Typography variant="body1">
            {firstName} {lastName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {email}
          </Typography>
        </Stack>
      </Stack>
      {role && (
        <Typography variant="helperText" color="text.disabled" textTransform="capitalize">
          {role}
        </Typography>
      )}
    </Card>
  );
}
