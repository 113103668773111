import { useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { ConfigFormHeader } from '@components';
import { IntegrationConnectorForm } from '@organisms';
import { IntegrationConfigFormQP } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { SYNC_PARAM } from '@Integrations/organisms/EditConnectedIntegration/EditConnectedIntegration';
import { IntegrationConfigAppModelV2, useCreateIntegrationV2 } from '@api';
import { Button, Stack } from '@mui/material';
import { RouterLink } from '@libs';
import { NEW_CONNECTOR_VALUE } from '@organisms/IntegrationConnectorForm/SelectConnector';
import { useGetAllowedResourceTypes } from '@organisms/IntegrationConnectorForm/SelectResourceTypes';
import { CustomAnalyticsEvents } from '@utils/analytics';
import { IntegrationConfigForm } from '@Integrations/organisms/IntegrationConfigForm/IntegrationConfigForm';

export const ConnectResourceIntegrationQP = {
  ...IntegrationConfigFormQP,
  ConfigForm: 'config',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ConnectResourceIntegrationQP =
  (typeof ConnectResourceIntegrationQP)[keyof typeof ConnectResourceIntegrationQP];

export interface ConnectResourceIntegrationPathsProps {
  catalogPath: string;
  editPath: string;
}

export interface ConnectResourceIntegrationProps {
  config: IntegrationConfigAppModelV2;
  paths: ConnectResourceIntegrationPathsProps;
}

function useConnectResourceIntegration({ config, paths }: ConnectResourceIntegrationProps) {
  const { allowedResourceTypes } = useGetAllowedResourceTypes(config);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedConnectorId, setSelectedConnectorId] = useState<string | undefined>(
    searchParams.get(ConnectResourceIntegrationQP.ConnectorId) ?? undefined,
  );

  const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[]>(
    searchParams.getAll(ConnectResourceIntegrationQP.ResourceTypes) ?? [],
  );

  const { createIntegration, isCreateIntegrationLoading, createdIntegration } = useCreateIntegrationV2();

  useEffect(() => {
    if (createdIntegration) {
      navigate(`${generatePath(paths.editPath, { id: createdIntegration.id })}?${SYNC_PARAM}=true`);
    }
  }, [createdIntegration, navigate, paths.editPath]);

  const handleNextClick = () => {
    if (!selectedConnectorId || (allowedResourceTypes?.length > 1 && selectedResourceTypes?.length === 0)) {
      return;
    }

    const newSearchParams = new URLSearchParams();
    newSearchParams.set(ConnectResourceIntegrationQP.ConnectorId, selectedConnectorId);
    newSearchParams.set(ConnectResourceIntegrationQP.ConfigForm, 'true');
    selectedResourceTypes.forEach((t) => newSearchParams.append(ConnectResourceIntegrationQP.ResourceTypes, t));
    setSearchParams(newSearchParams);
  };

  const handleBackClick = () => {
    searchParams.delete(ConnectResourceIntegrationQP.ConfigForm);
    setSearchParams(searchParams);
  };

  const showConfigForm = useMemo(
    () => searchParams.get(ConnectResourceIntegrationQP.ConfigForm) === 'true',
    [searchParams],
  );

  const nextDisabled = useMemo(
    () =>
      !selectedConnectorId ||
      selectedConnectorId === NEW_CONNECTOR_VALUE ||
      (allowedResourceTypes?.length > 1 && selectedResourceTypes?.length === 0),
    [selectedConnectorId, allowedResourceTypes?.length, selectedResourceTypes?.length],
  );

  return {
    showConfigForm,
    selectedConnectorId,
    selectedResourceTypes,
    setSelectedConnectorId,
    setSelectedResourceTypes: allowedResourceTypes.length < 2 ? undefined : setSelectedResourceTypes,
    handleNextClick,
    handleBackClick,
    createIntegration,
    isCreateIntegrationLoading,
    nextDisabled,
  };
}

export function ConnectResourceIntegration({ config, paths }: ConnectResourceIntegrationProps) {
  const {
    showConfigForm,
    selectedConnectorId,
    selectedResourceTypes,
    setSelectedConnectorId,
    setSelectedResourceTypes,
    handleNextClick,
    handleBackClick,
    createIntegration,
    isCreateIntegrationLoading,
    nextDisabled,
  } = useConnectResourceIntegration({ config, paths });

  if (showConfigForm) {
    return (
      <>
        <ConfigFormHeader
          icons={[config.icons.svg]}
          title="Complete setup"
          description="To complete the integration process, provide Apono with the configuration parameters below."
        />
        <IntegrationConfigForm
          config={config}
          selectedConnectorId={selectedConnectorId}
          selectedResourceTypes={selectedResourceTypes}
          submitButtonText="Connect"
          loading={isCreateIntegrationLoading}
          onSubmit={createIntegration}
          onBackClick={handleBackClick}
        />
      </>
    );
  }

  return (
    <>
      <ConfigFormHeader icons={[config.icons.svg]} title={`Connect ${config.name}`} description={config.description} />
      <IntegrationConnectorForm
        config={config}
        connectorId={selectedConnectorId}
        onConnectorIdChange={setSelectedConnectorId}
        resourceTypes={selectedResourceTypes}
        onResourceTypesChange={setSelectedResourceTypes}
      >
        <Stack direction="row" spacing={2}>
          <Button variant="text" component={RouterLink} to={paths.catalogPath}>
            Cancel
          </Button>
          <Button
            data-trigger={CustomAnalyticsEvents.INTEGRATION_NEXT_CLICKED}
            variant="contained"
            onClick={handleNextClick}
            disabled={nextDisabled}
          >
            Next
          </Button>
        </Stack>
      </IntegrationConnectorForm>
    </>
  );
}
