import { InteractiveDropdown, Popover } from '@components';
import { yup } from '@libs';

import { DropdownPermissionsView } from './DropdownPermissionsView';

import {
  FlowFormSelectPermissionsContextProvider,
  FlowFormSelectPermissionsValue,
  useFlowFormSelectPermissionsContextProvider,
} from './context';
import { useErrorText } from '../common/use-error-text.hook';
import { HumanReadableText } from '../common/HumanReadableText';
import { IntegrationInfo } from '../common';

export interface FlowFormSelectPermissionProps {
  integrationInfo?: IntegrationInfo;
  template?: string;
  value: FlowFormSelectPermissionsValue;
  onChange: (value: FlowFormSelectPermissionsValue) => void;
  error?: yup.ValidationError;
}

const DEFAULT_PLACEHOLDER = 'permissions';

export function FlowFormSelectPermissions({
  integrationInfo,
  template,
  value,
  onChange,
  error,
}: FlowFormSelectPermissionProps) {
  const props = useFlowFormSelectPermissionsContextProvider({
    integrationInfo,
    value,
    template,
    onChange,
  });
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();

  const onOpen = () => {
    hideErrorText();
    props.fetchPermissions();
  };

  const onClose = () => {
    showErrorText();
    props.resetToInit();
  };

  return (
    <FlowFormSelectPermissionsContextProvider {...props}>
      <Popover
        testId="flow-form-select-permissions"
        trigger={
          <HumanReadableText
            values={value.permissions || []}
            placeholder={props.templatePlaceholder || DEFAULT_PLACEHOLDER}
            placeholderMuted
            errorText={error?.message}
            hideErrorText={errorTextHidden}
          />
        }
        onOpen={onOpen}
        onClose={onClose}
      >
        <InteractiveDropdown wide>
          <DropdownPermissionsView />
        </InteractiveDropdown>
      </Popover>
    </FlowFormSelectPermissionsContextProvider>
  );
}
