import { PropsWithChildren } from 'react';
import { styled, Alert, AlertTitle, Typography } from '@mui/material';

import { MaterialIcon } from '@components';

const StyledBody = styled(Typography)<{
  component: string;
}>(({ theme }) => ({
  '& ul': {
    paddingInlineStart: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function FlowBuilderInfoBanner({
  title,
  children,
}: PropsWithChildren<{
  title: string;
}>) {
  return (
    <Alert
      severity="warning"
      variant="outlined"
      sx={{ maxWidth: 700 }}
      icon={<MaterialIcon symbol="lightbulb" weight={700} />}
    >
      {!!title && <AlertTitle>{title}</AlertTitle>}
      <StyledBody variant="body2" component="div">
        {children}
      </StyledBody>
    </Alert>
  );
}
