import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QueryFunctionContext, QueryKey, useInfiniteQuery } from '@tanstack/react-query';

import { PaginationInfo } from '../apn-core/api';

export interface PaginatedResponseModel<T> {
  data: Array<T>;
  pagination: PaginationInfo;
}

export const PAGE_PARAM = 'page';
export const API_LIST_LIMIT = 15;

export const getSkipParam = (page: number) => (page - 1) * API_LIST_LIMIT;

export function useListQuery<T>({
  fetchMethod,
  queryKey,
  refetch,
  withoutPageParam = false,
  enabled,
}: {
  fetchMethod: (params: QueryFunctionContext) => Promise<PaginatedResponseModel<T>>;
  queryKey: QueryKey;
  refetch?: number;
  withoutPageParam?: boolean;
  enabled?: boolean;
}) {
  const [queryParams, setQueryParams] = useSearchParams();
  const [lastPageData, setLastPageData] = useState<PaginatedResponseModel<T> | undefined>();

  const pageParam = useMemo(() => {
    if (withoutPageParam) return 0;
    const pageQp = queryParams.get(PAGE_PARAM);
    return getSkipParam(pageQp ? parseInt(pageQp) : 1);
  }, [queryParams, withoutPageParam]);

  const query = useInfiniteQuery(queryKey, fetchMethod, {
    getNextPageParam: (lastPage) => {
      const skip = lastPage.pagination.offset + lastPage.pagination.limit;
      return skip >= (lastPage.pagination.total || 0) ? undefined : skip;
    },
    getPreviousPageParam: (firstPage) => {
      const skip = firstPage.pagination.offset - firstPage.pagination.limit;
      return skip <= 0 ? undefined : skip;
    },
    keepPreviousData: true,
    refetchInterval: refetch,
    initialData: {
      pages: [{ data: [], pagination: { limit: API_LIST_LIMIT, offset: pageParam, total: 0 } }],
      pageParams: [pageParam],
    },
    enabled,
  });

  const pageData = useMemo(() => {
    if (!query.data) return;

    const pageIndex = query.data.pageParams.findIndex((p) => p === pageParam);
    if (pageIndex === -1) {
      return lastPageData;
    }

    const newPageData = query.data.pages[pageIndex];
    setLastPageData(newPageData);
    return newPageData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data, pageParam]);

  const handleOnPageChange = (newPage: number) => {
    queryParams.set(PAGE_PARAM, String(newPage));
    setQueryParams(queryParams);

    query.fetchNextPage({
      pageParam: getSkipParam(newPage),
    });
  };

  return { ...query, pageData, handleOnPageChange };
}
