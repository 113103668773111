import { ReactNode } from 'react';
import { FormControl, FormControlLabel, FormLabel, Stack, Switch, SwitchProps, styled } from '@mui/material';
import slugify from '@sindresorhus/slugify';

const InputLabelWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export interface LabeledSwitchFieldLabelProps {
  label: string;
  switchText: string;
  subLabel?: ReactNode;
  testId?: string;
}

export type LabeledSwitchFieldProps = SwitchProps & LabeledSwitchFieldLabelProps;

export function LabeledSwitchField({ label, subLabel, testId, switchText, ...rest }: LabeledSwitchFieldProps) {
  const id = rest.id || slugify(label) || testId;

  return (
    <FormControl>
      <InputLabelWrapper direction="row" spacing={0.5} alignItems="center">
        <FormLabel htmlFor={id}>{label}</FormLabel>
        {subLabel}
      </InputLabelWrapper>
      <FormControlLabel control={<Switch id={id} {...rest} />} label={switchText} />
    </FormControl>
  );
}
