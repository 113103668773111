import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';

export default function useFilterSearchParams() {
  const [searchParams] = useSearchParams();

  const isFilteredBy = useCallback(
    (param: string, filter: Set<string>) => {
      if (!searchParams.has(param)) return true;
      if (filter.size === 0) return false;

      let isFiltered = false;
      filter.forEach((value) => {
        if (searchParams.getAll(param).includes(value)) isFiltered = true;
      });

      return isFiltered;
    },
    [searchParams],
  );

  const isSearchedBy = useCallback(
    (filter: Set<string>) => {
      const searchQuery = searchParams.get(SEARCH_QUERY_PARAM)?.toLowerCase();
      if (!searchQuery) return true;
      if (filter.size === 0) return false;

      let isFiltered = false;
      filter.forEach((value) => {
        if (value.toLowerCase().includes(searchQuery)) isFiltered = true;
      });

      return isFiltered;
    },
    [searchParams],
  );

  return {
    isFilteredBy,
    isSearchedBy,
  };
}
