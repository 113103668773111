import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useFinishOnboardingV2, UserSessionApiModelOnboardingFlow, useSession } from '@api';
import { Loader } from '@components';
import { routes } from '@routes';

import { OnboardingDialog } from '../components/OnboardingDialog';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export const ONBOARDING_QUERY_PARAM = 'finishedOnboarding';

interface OnboardingContextProps {
  isInOnboarding: boolean;
  onboardingIds?: UserSessionApiModelOnboardingFlow;
}

const OnboardingContext = createContext<OnboardingContextProps | null>(null);

export function OnboardingProvider({ children }: PropsWithChildren<{}>) {
  const { session, refetchSession } = useSession();
  const { finishOnboarding } = useFinishOnboardingV2();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [onboardingReady, setOnboardingReady] = useState(false);
  const { track } = useAnalyticsContext();
  const [params, setParams] = useSearchParams();

  const isInOnboarding = pathname.startsWith('/onboarding-steps/');

  useEffect(() => {
    if (!session) return;

    if (isInOnboarding) {
      if (session.onboarding_flow) {
        refetchSession();
      } else {
        navigate(routes.Dashboard.path, { replace: true });
      }
    } else if (session.onboarding_flow && !pathname.startsWith('/activity')) {
      const { added_integration_id, added_access_flow_id } = session.onboarding_flow;
      if (added_integration_id && added_access_flow_id) {
        navigate(routes.OnboardingRequestAccess.path, { replace: true });
      } else if (added_integration_id) {
        navigate(routes.OnboardingAccessFlow.path, { replace: true });
      } else {
        navigate(routes.OnboardingWelcome.path, { replace: true });
      }
    }

    if (!onboardingReady) setOnboardingReady(true);
  }, [session, isInOnboarding, onboardingReady, pathname, refetchSession, navigate]);

  const handleOnCloseDialog = useCallback(() => {
    params.delete(ONBOARDING_QUERY_PARAM);
    setParams(params);
    finishOnboarding();
    track(CustomAnalyticsEvents.ONBOARDING_COMPLETED);
  }, [params, track, setParams, finishOnboarding]);

  if (!onboardingReady) return <Loader />;

  return (
    <OnboardingContext.Provider value={{ isInOnboarding, onboardingIds: session?.onboarding_flow }}>
      {children}
      <OnboardingDialog isOpen={params.has(ONBOARDING_QUERY_PARAM)} onClose={handleOnCloseDialog} />
    </OnboardingContext.Provider>
  );
}

export function useOnboardingContext() {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }

  return context;
}
