import { Stack, Typography, Autocomplete, TextField, Avatar, styled } from '@mui/material';
import { LabelAppModel } from '@api';
import { useState } from 'react';

const ColorSquare = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

interface AccessFlowLabelsSettingProps {
  options: LabelAppModel[];
  onSelect: (newVal: string | LabelAppModel | null) => void;
  disabled?: boolean;
}

export default function AccessFlowLabelsSelector({ options, onSelect, disabled }: AccessFlowLabelsSettingProps) {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleSelect = (selected: string | LabelAppModel | null) => {
    onSelect(selected);
    setInputValue('');
  };

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1.5}>
      <Stack direction="column" justifyContent="center" alignItems="stretch">
        <Typography variant="subtitle2">Access flow labels</Typography>
        <Typography variant="body2" color="text.filter">
          Use one or more labels to organize your Access Flows. You can create new labels by typing anything and
          clicking enter.
        </Typography>
      </Stack>
      <Autocomplete
        disabled={disabled}
        freeSolo
        size="small"
        value={null}
        options={options}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="access-flow-labels-selector"
            placeholder={`Type a label and press "Enter" or select existing one`}
            onKeyDown={handleKeyDown}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack direction="row" spacing={1} alignItems="center">
              <ColorSquare sx={{ backgroundColor: option.color }} variant="rounded">
                {' '}
              </ColorSquare>
              <Typography variant="inherit">{option.name}</Typography>
            </Stack>
          </li>
        )}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        onChange={(_, val) => handleSelect(val)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterSelectedOptions
      />
    </Stack>
  );
}
