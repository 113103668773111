import {
  FronteggProvider,
  useAuth,
  useAuthUserOrNull,
  useLoginWithRedirect,
  AdminPortal,
  useAuthUser,
} from '@frontegg/react';

const getOrganizationQp = (): string | null => {
  const urlQueryParams = new URLSearchParams(window.location.search);
  return urlQueryParams.get('organization');
};

const getLogoutUrl = (): string => {
  return `/account/logout`;
};

const getLoginPartialPath = (): string => {
  const orgQp = getOrganizationQp();
  return `/account/login${orgQp ? `?organization=${orgQp}` : ''}`;
};

export {
  useAuth,
  useAuthUser,
  useAuthUserOrNull,
  useLoginWithRedirect,
  FronteggProvider as Provider,
  AdminPortal,
  getLogoutUrl,
  getLoginPartialPath,
  getOrganizationQp,
};
