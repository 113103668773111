import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  EmptyState,
  InteractiveDropdownFilterInput,
  InteractiveDropdownFooter,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownView,
  MaterialIcon,
  usePopoverContext,
} from '@components';
import { useListAccessBundles } from '@api';
import { Button } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { routes } from '@routes';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { BUNDLE_QUERY_PARAM } from '@AccessFlows/common/constants';

interface DropdownBundlesViewProps {
  current: boolean;
  value?: string;
  isLoading: boolean;
  onBundleChange: (value: string) => void;
  onBackClick?: () => void;
}

function useDropdownBundlesView({ isLoading }: Pick<DropdownBundlesViewProps, 'isLoading'>) {
  const { accessBundles, isAccessBundlesFetched } = useListAccessBundles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState('');
  const { track } = useAnalyticsContext();

  const { popoverEnableOnClose, popoverDisableOnClose } = usePopoverContext();

  const handleFilterChange = (val: string) => setFilter(val);

  useEffect(() => {
    if (!searchParams.has(BUNDLE_QUERY_PARAM)) {
      popoverEnableOnClose();
    }
  }, [searchParams, popoverEnableOnClose]);

  const handleBundleView = useCallback(
    (e: React.MouseEvent, bundleId: string) => {
      e.stopPropagation();
      popoverDisableOnClose();
      searchParams.set(BUNDLE_QUERY_PARAM, bundleId);
      setSearchParams(searchParams);
      track(CustomAnalyticsEvents.BUNDLE_VIEW_INITIATED, { bundleId });
    },
    [popoverDisableOnClose, searchParams, setSearchParams, track],
  );

  const options = useMemo(() => {
    return accessBundles
      .map((bundle) => ({
        key: bundle.id,
        value: bundle.name,
        rightIcon: <MaterialIcon symbol="visibility" color="primary" onClick={(e) => handleBundleView(e, bundle.id)} />,
      }))
      .filter((v) => `${v.key} ${v.value}`.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  }, [accessBundles, filter, handleBundleView]);

  const isFetched = useMemo(() => isAccessBundlesFetched && !isLoading, [isAccessBundlesFetched, isLoading]);

  return {
    isFetched,
    options,
    filter,
    handleFilterChange,
  };
}

export function DropdownBundlesView({
  current,
  value,
  isLoading,
  onBundleChange,
  onBackClick,
}: DropdownBundlesViewProps) {
  const { isFetched, options, filter, handleFilterChange } = useDropdownBundlesView({ isLoading });
  const { id } = useParams();

  if (!isFetched) {
    return <InteractiveDropdownView current loading />;
  }

  return (
    <InteractiveDropdownView
      current={current}
      header={<InteractiveDropdownHeader title="Select bundle" onBackClick={onBackClick} />}
      filter={<InteractiveDropdownFilterInput value={filter} onChange={handleFilterChange} />}
      footer={
        options.length > 0 && (
          <InteractiveDropdownFooter>
            <Button
              component={Link}
              to={`${routes.AddBundle.path}?fromAccessFlow=${id ? id : 'true'}`}
              variant="text"
              startIcon={<MaterialIcon symbol="add" />}
              data-trigger={CustomAnalyticsEvents.BUNDLE_CREATE_INITIATED_IN_BUILDER}
            >
              Create new bundle
            </Button>
          </InteractiveDropdownFooter>
        )
      }
    >
      <InteractiveDropdownSelect<string>
        options={options}
        value={value}
        onChange={onBundleChange}
        selectedFirst
        emptyState={
          <EmptyState
            imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
            title="No bundles created yet"
            action={
              <Button
                component={Link}
                to={`${routes.AddBundle.path}?fromAccessFlow=${id ? id : 'true'}`}
                variant="contained"
              >
                Create New Bundle
              </Button>
            }
          />
        }
      />
    </InteractiveDropdownView>
  );
}
