import React, { useState, useEffect, useRef } from 'react';
import { Popover, Box } from '@mui/material';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { ListFilterOption } from '@components/ListFilters/common';
import { useSearchParams } from 'react-router-dom';
import { AnalyticsTriggerElementProps } from '@common/types';

export interface FilterProps {
  options: ListFilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  filterParam: string;
  filterLabel: string;
  isOptionsFetched: boolean;
  refetchOptions: () => void;
  onClear?: () => void;
  onSelect?: (values: ListFilterOption[]) => void;
}

export default function GenericAsyncFilterMultiSelect({
  opened = false,
  onClose,
  onOpen,
  options,
  filterLabel,
  filterParam,
  isOptionsFetched,
  refetchOptions,
  onClear,
  onSelect,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps) {
  const [isOpen, setIsOpen] = useState(opened);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedQp = searchParams.getAll(filterParam);

  const selectedOptions = [];
  for (const option of options) {
    if (selectedQp.includes(option.value)) {
      selectedOptions.push(option);
    }
  }

  const handleOnSelect = (values: ListFilterOption[]) => {
    searchParams.delete(filterParam);
    for (const value of values) {
      searchParams.append(filterParam, value.value);
    }
    setSearchParams(searchParams);
    onSelect && onSelect(values);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setIsOpen(false);
    onClose && onClose();
  };

  const handleOnClear = () => {
    searchParams.delete(filterParam);
    setSearchParams(searchParams);
    onClear && onClear();
    handleClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen((prev) => !prev);
    !isOpen && onOpen && onOpen();
    setAnchorEl(event.currentTarget);

    if (!isOptionsFetched) {
      refetchOptions();
    }
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (isOpen && !isOptionsFetched) {
        refetchOptions();
      }
    }
  }, [isOptionsFetched, isOpen, ref, refetchOptions]);

  const isFetching = !isOptionsFetched && (isOpen || selectedQp.length > 0);
  const open = (isOpen && Boolean(anchorEl)) || false;

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={filterLabel}
        value={selectedOptions}
        onClear={handleOnClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={options}
            value={selectedOptions}
            onSelect={handleOnSelect}
            enableSearch
            title={`Select ${filterLabel} Filter`}
            loading={isFetching}
            {...analyticsProps}
          />
        </Box>
      </Popover>
    </>
  );
}
