import { Box, Container, Link, Stack, styled, Typography } from '@mui/material';

const AppFooterWrap = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3),
}));

const AppFooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}));

const links = [
  {
    href: 'https://www.apono.io/privacy-policy/',
    label: 'Privacy Policy',
  },
  {
    href: 'https://www.apono.io/legal-terms/',
    label: 'Terms and Conditions',
  },
  {
    href: 'https://docs.apono.io/',
    label: 'Docs',
  },
  {
    href: 'https://www.apono.io/blog/',
    label: 'Blog',
  },
  {
    href: 'https://www.apono.io/meet-the-team/',
    label: 'About Us',
  },
];

export default function AppFooter() {
  return (
    <AppFooterWrap>
      <Container maxWidth={false}>
        <AppFooterContainer>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
            <Typography variant="tooltip" color="text.muted">
              &copy; 2024 Apono Inc; All rights reserved
            </Typography>
            {links.map((link) => (
              <Link
                key={link.label}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                variant="tooltip"
              >
                {link.label}
              </Link>
            ))}
          </Stack>
        </AppFooterContainer>
      </Container>
    </AppFooterWrap>
  );
}
