import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import {
  AccessUnitFieldsFragment,
  IntegrationFieldsFragment,
  ResourceFieldsFragment,
  ResourceTypeFieldsFragment,
} from '@api/gql/graphql';
import { MaterialIcon } from '@components';
import { getFragmentData } from '@api/gql';
import { RESOURCE_FRAGMENT } from '@AccessRightSizing/services/gql';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  '&:before': { display: 'none' },
}));

export function IntegrationsListAccessUnits({
  resourceType,
  integration,
  defaultExpanded = false,
  accessUnits,
}: {
  resourceType: ResourceTypeFieldsFragment;
  integration: IntegrationFieldsFragment;
  defaultExpanded?: boolean;
  accessUnits: readonly AccessUnitFieldsFragment[];
}) {
  return (
    <Stack direction="column" spacing={2}>
      <Card sx={{ border: 'none' }}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <PrimarySecondary
                primary={integration.name}
                icon={integration.icons.svg}
                secondary={integration.typeDisplayName}
              />
              <Typography variant="tooltip" color="text.muted">
                {accessUnits.length} {accessUnits.length != 1 ? 'Resources' : 'Resource'}
              </Typography>
            </Stack>
            <ResourceTypeContainer
              key={resourceType.type}
              resourceType={resourceType}
              defaultExpanded={defaultExpanded}
              accessUnits={accessUnits}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

function ResourceTypeContainer({
  resourceType,
  defaultExpanded = false,
  accessUnits,
}: {
  resourceType: ResourceTypeFieldsFragment;
  defaultExpanded?: boolean;
  accessUnits: readonly AccessUnitFieldsFragment[];
}) {
  return (
    <StyledAccordion elevation={0} disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<MaterialIcon symbol="expand_more" />}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width="100%">
          <PrimarySecondary primary={resourceType.typeDisplayName} icon={resourceType.icons.svg} />
          <Typography variant="tooltip" color="text.muted">
            {accessUnits.length} {accessUnits.length != 1 ? 'Resources' : 'Resource'}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {accessUnits.map((au) => {
            const resource = getFragmentData(RESOURCE_FRAGMENT, au.resource);
            if (!resource) return null;
            return (
              <Stack key={`${resource.name}-${au.permission}`} spacing={1}>
                <Divider />
                <AccessUnitItem resource={resource} permission={au.permission} />
              </Stack>
            );
          })}
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
}

export function AccessUnitItem({ resource, permission }: { resource: ResourceFieldsFragment; permission: string }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} width="100%">
      <PrimarySecondary primary={resource.name} secondary={resource.path.map((item) => item.value).join('/')} />
      <Stack spacing={0.5}>
        <Typography variant="tooltip" color="text.muted" align="right">
          {permission}
        </Typography>
      </Stack>
    </Stack>
  );
}
