import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Popper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import { MaterialIcon } from '@components';
import { useDebounce } from '@libs';
import { AnalyticsTriggerElementProps } from '@common/types';

const StyledChip = styled(Chip)(({ theme }) => ({
  lineHeight: 1,
  height: '20px',
  margin: `${theme.spacing(0, 0.5, 0, 0)} !important`,
}));

const InputWrap = styled(Box)({
  width: '320px',
});

const StyledPopper = styled(Popper)({
  display: 'none',
});

interface MultipleSearchInputProps {
  placeholder?: string;
  value?: string[];
  onChange: (value: string[]) => void;
}

interface DefaultSearchInputProps {
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
}

export type SearchBarProps = {
  placeholder?: string;
  total?: number;
} & (
  | (DefaultSearchInputProps & {
      multiple?: false;
    })
  | (MultipleSearchInputProps & {
      multiple: true;
    })
);

export function SearchBar({
  value,
  total,
  placeholder,
  onChange,
  multiple,
  ...analyticsProps
}: SearchBarProps & AnalyticsTriggerElementProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <InputWrap>
        {multiple ? (
          <MultipleSearchInput placeholder={placeholder} value={value} onChange={onChange} />
        ) : (
          <SearchBarDefaultInput placeholder={placeholder} value={value} onChange={onChange} {...analyticsProps} />
        )}
      </InputWrap>
      {total !== undefined ? (
        <Typography variant="caption" color="text.muted" component="div">
          Total {total} results
        </Typography>
      ) : null}
    </Stack>
  );
}

export function MultipleSearchInput({ value, onChange, placeholder }: MultipleSearchInputProps) {
  return (
    <Autocomplete
      data-testid="multiple-search-bar"
      multiple
      options={[]}
      freeSolo
      fullWidth
      defaultValue={value || []}
      onChange={(event, newValue: string[]) => {
        onChange([...newValue]);
      }}
      renderTags={(strings: string[], getTagProps) =>
        strings.map((option: string, index: number) => (
          // disabling because key in getTagProps
          // eslint-disable-next-line react/jsx-key
          <StyledChip variant="filled" size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" size="small" placeholder={placeholder || 'Search'} />
      )}
      PopperComponent={StyledPopper}
    />
  );
}

interface SearchBarDefaultInputProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

function useSearchBarDefaultInput({ value, onChange }: SearchBarDefaultInputProps) {
  const [searchInput, setSearchInput] = useState<string>(value || '');
  const debouncedInput = useDebounce<string>(searchInput, 250);
  const prevDebouncedInputRef = useRef(debouncedInput);

  useEffect(() => {
    setSearchInput(value || '');
  }, [value]);

  useEffect(() => {
    if (prevDebouncedInputRef.current !== debouncedInput) {
      onChange(debouncedInput);
      prevDebouncedInputRef.current = debouncedInput;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  const endAdornment = useMemo(
    () =>
      searchInput === '' ? (
        <MaterialIcon symbol="search" sx={{ display: 'block' }} />
      ) : (
        <IconButton onClick={() => setSearchInput('')} edge="end" size="small" data-testid="search-bar-clear-button">
          <MaterialIcon symbol="close" sx={{ display: 'block' }} />
        </IconButton>
      ),
    [searchInput],
  );

  const handleOnChange = (searchValue: string) => {
    setSearchInput(searchValue);
  };

  return {
    searchInput,
    endAdornment,
    handleOnChange,
  };
}

export function SearchBarDefaultInput({
  value,
  onChange,
  placeholder,
  ...analyticsProps
}: SearchBarDefaultInputProps & AnalyticsTriggerElementProps) {
  const { searchInput, handleOnChange, endAdornment } = useSearchBarDefaultInput({ value, onChange });

  return (
    <TextField
      data-testid="search-bar"
      size="small"
      fullWidth
      variant="outlined"
      value={searchInput}
      onChange={(e) => handleOnChange(e.target.value)}
      placeholder={placeholder || 'Search'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" color="text.dropdown">
            {endAdornment}
          </InputAdornment>
        ),
      }}
      {...analyticsProps}
    />
  );
}
