import { useQuery } from '@tanstack/react-query';

import { ActivityService } from '@api/services';

import { QUERY_KEYS, REFETCH_INTERVAL } from '../../../common/constants';

export function useGetActivityRequestTimeline(id: string) {
  const {
    data: { data: timeline, pagination },
    isFetched: isFetched,
    isLoading: isLoading,
  } = useQuery(
    [QUERY_KEYS.Activity, QUERY_KEYS.TimeLine, id],
    async () => ActivityService.appGetActivityRequestTimeline({ id }),
    {
      initialData: {
        data: [],
        pagination: {
          total: 0,
          limit: 100,
          offset: 0,
        },
      },
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  return {
    timeline,
    pagination,
    isFetched,
    isLoading,
  };
}
