import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';

import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';
import {
  INSIGHT_TYPE_FILTER_PARAM,
  INTEGRATION_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_PARAM,
} from './AccessRightSizingInsightFilters/AccessRightSizingInsightFilters';

export const PERIOD_FILTER_LABEL = 'Period';
export const PERIOD_QUERY_PARAM = 'period';

interface RightSizingPeriodFilterProps {
  options: ListFilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  isLoading: boolean;
  defaultOption: string;
}

export default function RightSizingPeriodFilter({
  options,
  opened,
  onClose,
  onOpen,
  isLoading,
  defaultOption,
}: RightSizingPeriodFilterProps) {
  const [isOpen, setIsOpen] = useState(opened || false);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.get(PERIOD_QUERY_PARAM) || undefined;

  const selectedOption =
    options.find((option) => option.value === selectedQp) || options.find((option) => option.value === defaultOption);

  const handleOnSelect = (value: ListFilterOption) => {
    searchParams.set(PERIOD_QUERY_PARAM, value.value);
    searchParams.delete(INSIGHT_TYPE_FILTER_PARAM);
    searchParams.delete(INTEGRATION_FILTER_PARAM);
    searchParams.delete(RESOURCE_TYPE_FILTER_PARAM);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={PERIOD_FILTER_LABEL}
          value={selectedOption ? [selectedOption] : []}
          onClear={() => {}}
          enableClear={false}
          loading={isLoading}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      <ListFilterSingleSelect
        options={options}
        value={selectedOption}
        onSelect={handleOnSelect}
        title="Select Period Filter"
        loading={isLoading}
      />
    </ListFilter>
  );
}
