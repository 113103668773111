import { ReportPeriodUnit } from '@api';
import { DateTime } from './DateTime';

export interface RelativePeriodParams {
  value: number;
  unit: ReportPeriodUnit;
  rounded: boolean;
}

const ROUND = 'r';

export function parseRelativePeriod(period?: string): RelativePeriodParams {
  const periodParts = (period || '').split('-');

  return {
    value: periodParts[0] ? parseInt(periodParts[0]) : 1,
    unit: periodParts[1] ? (periodParts[1] as ReportPeriodUnit) : ReportPeriodUnit.Hours,
    rounded: periodParts[2] ? periodParts[2] === ROUND : false,
  };
}

export function joinPeriod({ value, unit, rounded }: RelativePeriodParams): string {
  const relativeParts = [];

  if (value) relativeParts.push(value.toString());
  if (unit) relativeParts.push(unit);
  if (rounded) relativeParts.push(ROUND);

  return relativeParts.join('-');
}

export function dateFromRelativePeriod(
  { value: periodNumeric, unit: periodUnit, rounded: roundToUnit }: RelativePeriodParams,
  fromDate?: DateTime,
): DateTime {
  let newDate = fromDate || DateTime.now();

  if (periodNumeric && periodUnit) {
    newDate = newDate.subtract(periodNumeric, periodUnit, roundToUnit);
  }

  return newDate;
}

export function lastFromRelativePeriod({
  value: periodNumeric,
  unit: periodUnit,
  rounded: roundToUnit,
}: RelativePeriodParams): string {
  const periodUnitLabel = periodNumeric > 1 ? periodUnit : periodUnit.slice(0, -1);
  return `Last${roundToUnit ? ' rounded' : ''} ${periodNumeric} ${periodUnitLabel}`;
}
