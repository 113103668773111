import { useMemo } from 'react';
import { MaterialIcon } from '@components';
import { ModifyActivityReportModel } from '@api';
import { QueryParams, reportToQueryParams } from '@utils';
import { routes } from '@routes';
import { Button, IconButton, Tooltip } from '@mui/material';
import { RouterLink } from '@libs';

export interface ReportItemPreviewActivityProps {
  report: ModifyActivityReportModel;
  onlyIcon?: boolean;
}

export function ReportItemPreviewActivity({ report, onlyIcon }: ReportItemPreviewActivityProps) {
  const to = useMemo(() => {
    const qp = reportToQueryParams(report);
    qp.delete(QueryParams.fields);
    return `${routes.Activity.path}?${qp.toString()}`;
  }, [report]);

  const icon = <MaterialIcon symbol="preview" />;

  if (onlyIcon) {
    return (
      <Tooltip title="View filtered activity" arrow placement="top">
        <IconButton component={RouterLink} to={to}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button variant="text" size="small" color="inherit" startIcon={icon} component={RouterLink} to={to}>
      View Activity
    </Button>
  );
}
