import { PropsWithChildren, useEffect, useState } from 'react';

import { AccessTargetAppModel, AccessBundleUpsertAppModel, TagSelectorModel, AccessTargetType } from '@api';
import { isEmpty, objectDiff, yup } from '@libs';
import { useGlobalNotifier } from '@hooks';
import { FlowFormLine, FlowFormName, FlowFormTargetType, FlowFormTargets } from './FlowForm';

const messages = {
  name: 'Please enter a name',
  target_type: 'Please choose a target',
  integration_id: 'Please choose what application or service this flow will grant access to',
  resources: 'Please choose the resources that should be received via this flow',
  permissions: 'Please choose the permissions that should be received via this flow',
};

const resourceTagTagSchema = yup.object({
  name: yup.string().required(),
  value: yup.string().required(),
});

const accessTargetIntegrationSchema = yup.object({
  integration_id: yup.string().min(1, messages.integration_id).required(messages.integration_id),
  resource_type: yup.string().min(1, messages.integration_id).required(messages.integration_id),
  resource_tag_matchers: yup.array<TagSelectorModel>(resourceTagTagSchema),
  resource_tag_excludes: yup.array<TagSelectorModel>(resourceTagTagSchema),
  permissions: yup.array().of(yup.string()).min(1, messages.permissions).required(),
});

const accessTargetSchema = yup.object({
  target_type: yup
    .mixed<AccessTargetType>()
    .oneOf(Object.values(AccessTargetType), messages.target_type)
    .required(messages.target_type),
  integration: accessTargetIntegrationSchema.required(), // TODO: make it optional when bundle is ready
});

const schema = yup.object({
  name: yup.string().required(messages.name),
  targets: yup
    .array<AccessTargetAppModel>(accessTargetSchema)
    .required(messages.integration_id)
    .min(1, messages.integration_id),
});

export interface BundleFormData extends Omit<AccessBundleUpsertAppModel, 'targets'> {
  targets?: FlowFormTargetType[];
}

export interface BundleFormProps {
  formData: BundleFormData;
  onChange?: (value: BundleFormData) => void;
  onSubmit: (value: AccessBundleUpsertAppModel) => void;
}

function useBundleForm({ formData, onChange, onSubmit }: BundleFormProps) {
  const { notifyServerError } = useGlobalNotifier();

  const [formModel, setFormModel] = useState<BundleFormData>(formData);

  const [name, setName] = useState<string>(formData.name);
  const [accessTargets, setAccessTargets] = useState<FlowFormTargetType[] | undefined>(formData.targets);

  const [validationError, setValidationError] = useState<yup.ValidationError | undefined>();

  useEffect(() => {
    const newFormData: BundleFormData = {
      name,
      targets: accessTargets,
    };

    setFormModel(newFormData);
    setValidationError(undefined);

    if (onChange && !isEmpty(objectDiff(formModel, newFormData))) onChange(newFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, accessTargets]);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    try {
      const validatedValue = schema.validateSync(formModel, {
        abortEarly: false,
      });
      onSubmit(validatedValue);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationError(error.inner.length > 0 ? error.inner[0] : error);
      } else {
        notifyServerError(error, 'Something wrong with form');
      }
    }
  }

  const getValidationError = (path: keyof BundleFormData) => {
    if (!validationError || !validationError.path) return undefined;
    return validationError.path.startsWith(path) ? validationError : undefined;
  };

  return {
    name,
    setName,
    accessTargets,
    setAccessTargets,
    getValidationError,
    handleSubmit,
  };
}

export function BundleForm({ children, formData, onChange, onSubmit }: PropsWithChildren<BundleFormProps>) {
  const { getValidationError, handleSubmit, name, setName, accessTargets, setAccessTargets } = useBundleForm({
    formData,
    onChange,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <FlowFormName
        value={name}
        onChange={(v) => setName(v)}
        placeholder="Bundle Name"
        error={getValidationError('name')}
      />
      <FlowFormTargets
        noPrefix
        accessTargets={accessTargets}
        onChange={(v) => setAccessTargets(v)}
        error={getValidationError('targets')}
        placeholder="Select integration"
        noBundles
      />
      <FlowFormLine></FlowFormLine>
      {children}
    </form>
  );
}
