import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AccessRequestsService } from '@api/services';

import { QUERY_KEYS } from '../../../common/constants';

export function useRevokeAccess(id: string) {
  const queryClient = useQueryClient();

  const { mutate: revokeAccess, isLoading: isRevokeAccessLoading } = useMutation({
    mutationFn: async () => AccessRequestsService.revokeAccess({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.Activity, QUERY_KEYS.Single, id],
      });
    },
  });

  return {
    revokeAccess,
    isRevokeAccessLoading,
  };
}
