import { adminLayoutWrap } from '@middlewares';

import UsersPagePaginated from './pages/UsersPagePaginated';

export const routes = {
  Identities: {
    label: 'Identities',
    path: '/identities',
    page: adminLayoutWrap(<UsersPagePaginated />),
  },
};
