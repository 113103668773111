import { useCallback, useMemo, useState } from 'react';
import { Button, FormGroup, Stack } from '@mui/material';

import { ResourceAppModel } from '@api';
import { AppDrawer, AppDrawerHeader, ResourceDetailedItemProps } from '@components';

import { DrawerType, useSelectResourceDrawerContext } from '../provider';
import { SelectTagOptionItem } from '../components/SelectTagOptionItem';

function useDrawerSelectTags() {
  const {
    resourceTypeHumanName,
    drawerOpened,
    onCloseDrawer,
    resources,
    selectedTagsKey,
    selectedMatchers,
    onSelectMatchers,
    onSelectExcludes,
    onDoneSelect,
    integrationConfig,
  } = useSelectResourceDrawerContext();

  const [filter, setFilter] = useState<string>('');

  const getPathParts = useCallback(
    (resource: ResourceAppModel) => {
      const pathResourceType = integrationConfig?.resource_types[resource.type];
      const pathTypeHierarchy = pathResourceType?.hierarchy.split('/');
      return {
        logicalPathParts: pathTypeHierarchy?.map((t) => integrationConfig?.resource_types[t].display_name),
        literalPathParts: pathTypeHierarchy?.map((t) => resource.path[t]),
      };
    },
    [integrationConfig],
  );

  const toResourceDetailedItem = useCallback(
    (resource: ResourceAppModel): ResourceDetailedItemProps => {
      const { logicalPathParts, literalPathParts } = getPathParts(resource);

      return {
        displayName: resource.name,
        logicalPath: logicalPathParts?.join('/'),
        literalPath: literalPathParts?.join('/'),
      };
    },
    [getPathParts],
  );

  // Function to handle tag selection
  const onSelectTag = useCallback(
    (tagValue: string) => {
      if (!selectedTagsKey) return;

      const filteredSelectedByTagKey = selectedMatchers.filter((tag) => tag.name === selectedTagsKey);
      const selectedTagIndex = filteredSelectedByTagKey.findIndex((tag) => tag.value === tagValue);

      if (selectedTagIndex > -1) {
        filteredSelectedByTagKey.splice(selectedTagIndex, 1);
      } else {
        filteredSelectedByTagKey.push({ name: selectedTagsKey, value: tagValue });
      }

      onSelectMatchers(filteredSelectedByTagKey);
      onSelectExcludes([]);
    },
    [onSelectExcludes, onSelectMatchers, selectedMatchers, selectedTagsKey],
  );

  // Compute options for tags
  const options = useMemo(() => {
    const tagsResources: Record<string, ResourceDetailedItemProps[]> = {};

    for (const resource of resources) {
      for (const [key, value] of Object.entries(resource.tags)) {
        if (key === selectedTagsKey) {
          tagsResources[value] = [...(tagsResources[value] || []), toResourceDetailedItem(resource)];
        }
      }
    }

    return Object.entries(tagsResources)
      .map(([value, res]) => ({
        value,
        resources: res,
        selected: selectedMatchers.some((tag) => tag.name === selectedTagsKey && tag.value === value),
        onChange: () => onSelectTag(value),
      }))
      .filter((r) => r.value.toLowerCase().includes(filter.toLowerCase()));
  }, [filter, onSelectTag, resources, selectedMatchers, selectedTagsKey, toResourceDetailedItem]);

  const drawerTitle = useMemo(() => `Select ${resourceTypeHumanName} tags`, [resourceTypeHumanName]);

  // Check if the drawer is opened
  const isDrawerOpened = drawerOpened === DrawerType.SelectTags;

  const handleCloseDrawer = () => {
    setFilter('');
    onCloseDrawer();
  };

  const handleDoneSelect = () => {
    setFilter('');
    onDoneSelect();
  };

  return {
    isDrawerOpened,
    handleCloseDrawer,
    filter,
    setFilter,
    options,
    handleDoneSelect,
    onSelectMatchers,
    drawerTitle,
  };
}

export function DrawerSelectTags() {
  const {
    isDrawerOpened,
    drawerTitle,
    handleCloseDrawer,
    filter,
    setFilter,
    options,
    onSelectMatchers,
    handleDoneSelect,
  } = useDrawerSelectTags();

  return (
    <AppDrawer
      open={isDrawerOpened}
      onClose={handleCloseDrawer}
      header={
        <AppDrawerHeader
          title={drawerTitle}
          subTitle="Selecting a tag creates a dynamic scope: all current and future resources tagged with it in the system of origin will be included in the Access Flow."
          onCloseClick={handleCloseDrawer}
          filter={filter}
          onFilterChange={setFilter}
        />
      }
      footer={
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Button variant="contained" onClick={handleDoneSelect}>
            Done
          </Button>
          <Button type="button" onClick={() => onSelectMatchers([])}>
            Clear All
          </Button>
        </Stack>
      }
    >
      <FormGroup>
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
          {options.map((option) => (
            <SelectTagOptionItem key={option.value} {...option} />
          ))}
        </Stack>
      </FormGroup>
    </AppDrawer>
  );
}
