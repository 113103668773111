import { ReactNode, useMemo } from 'react';
import { ListFilterCategoryOrSelectItem } from './ListFilterSelect';

export interface ListFilterCategory {
  key: string;
  label: string;
  icon?: ReactNode;
}

export interface ListFilterOption {
  value: string;
  label: string;
  searchHelper?: string;
  icon?: ReactNode;
  category?: ListFilterCategory;
}

export interface BaseListFilterSelectProps {
  options: ListFilterOption[];
  maxHeight?: number;
  width?: number;
  enableSearch?: boolean;
  title?: string;
  groupedByCategory?: boolean;
  loading?: boolean;
}

interface PrepareOptionsProps {
  searchValue: string;
  enableSearch: boolean;
  type: 'radio' | 'checkbox';
  options: ListFilterOption[];
  handler: (option: ListFilterOption) => void;
  selectedValues?: Set<string>;
  groupedByCategory?: boolean;
}

const UNCATEGORIZED = '__UNCATEGORIZED__';

export function usePrepareOptions({
  options,
  handler,
  enableSearch,
  searchValue,
  type,
  selectedValues,
  groupedByCategory = false,
}: PrepareOptionsProps) {
  return useMemo(() => {
    const groups: Record<string, ListFilterCategory> = {};
    const groupedOptions: Record<string, ListFilterCategoryOrSelectItem[] | undefined> = {};

    const searchValueTrimmed = searchValue.trim().toLowerCase() ?? '';
    for (const option of options) {
      const searchable =
        `${option.label} ${option.value} ${option.searchHelper} ${option.category?.label}`.toLowerCase();
      if (enableSearch && !searchable.includes(searchValueTrimmed)) {
        continue;
      }

      const newOption: ListFilterCategoryOrSelectItem = {
        ...option,
        type,
        selected: selectedValues?.has(option.value),
        onClick: () => handler(option),
      };

      let groupKey = UNCATEGORIZED;
      if (option.category) {
        groupKey = option.category.key;
        groups[groupKey] = option.category;
      }

      const groupOptions = groupedOptions[groupKey] ?? [];
      groupOptions.push(newOption);
      groupedOptions[groupKey] = groupOptions;
    }

    if (!groupedByCategory) {
      return groupedOptions[UNCATEGORIZED] ?? [];
    }

    const result: ListFilterCategoryOrSelectItem[] = [];
    for (const group of Object.values(groups)) {
      const groupOptions = groupedOptions[group.key];
      if (groupOptions) {
        result.push({
          type: 'category',
          label: group.label,
          icon: group.icon,
        });
        result.push(...groupOptions);
      }
    }

    return result;
  }, [searchValue, options, enableSearch, type, selectedValues, groupedByCategory, handler]);
}
