import { Stack } from '@mui/material';
import { LabeledTextField } from '@components';
import { IntegrationConfigAppModelV2 } from '@api';
import { Controller, useFormContext } from 'react-hook-form';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { CustomAccessDetailsPreview } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/CustomAccessDetails/CustomAccessDetailsPreview';

export function CustomAccessDetailsField({
  integrationConfig,
  label = 'Access Details Instructions',
  placeholder = "Insert a message to end users, letting them know how to access this integration's resources.",
  disabled,
}: {
  integrationConfig: IntegrationConfigAppModelV2;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <Stack direction="column" spacing={2}>
      <Controller
        control={control}
        name="custom_access_details_settings.instructions_access_message"
        rules={{ required: false }}
        render={({ field, fieldState: { invalid, error } }) => (
          <LabeledTextField
            optional
            {...field}
            multiline
            minRows={2}
            showCharCount
            maxLength={400}
            error={invalid}
            spaceBetweenLabels
            helperText={error?.message}
            label={label}
            disabled={disabled}
            subLabel={
              <CustomAccessDetailsPreview
                integrationConfig={integrationConfig}
                adminMessage={field.value || 'Your custom message here'}
              />
            }
            placeholder={placeholder}
            data-testid="custom-access-details"
          />
        )}
      />
    </Stack>
  );
}
