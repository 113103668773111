import { MouseEvent, useState } from 'react';
import { ButtonBase, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MaterialIcon } from '@components';

export interface ActionsMenuV2Option {
  label: string;
  iconSymbol: string;
  onClick: () => void;
  hasDivider?: boolean;
  disabled?: boolean;
}

export interface ActionsMenuV2Props {
  loading?: boolean;
  options: ActionsMenuV2Option[];
}

export default function ActionsMenuV2({ loading, options }: ActionsMenuV2Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: ActionsMenuV2Option) => {
    option.onClick();
    handleClose();
  };

  return (
    <div>
      <ButtonBase data-testid="actions-menu-trigger" onClick={handleTriggerClick} disabled={loading}>
        {loading ? <CircularProgress size="24px" /> : <MoreVertIcon />}
      </ButtonBase>
      <Menu
        PaperProps={{ sx: { width: '183px' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            data-testid={`actions-menu-${option.label}`}
            onClick={() => handleOptionClick(option)}
            disabled={option.disabled}
          >
            <ListItemIcon>
              <MaterialIcon symbol={option.iconSymbol} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }}>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
