import { useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';

import { Loader, Page } from '@components';
import { AccessTargetType } from '@api';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { useSearchParams } from 'react-router-dom';

import { routes } from '@routes';
import { useAccessFlowsPack, useGlobalNotifier } from '@hooks';
import { DocsUrls } from '@utils';

import { useAppCreateBundle } from '@AccessFlows/services/bundlesMutations';
import { BundleForm, BundleFormData } from '@AccessFlows/organisms/BundleForm';
import useHandleBundleUpdate from '@AccessFlows/hooks/use-handle-bundle-update';

const FROM_INTEGRATION_QUERY_PARAM = 'fromIntegration';

export function AddBundlePage() {
  return (
    <Page
      specialLayout
      title={routes.AddBundle.label}
      breadcrumbs={[
        {
          label: routes.Bundles.label,
          href: routes.Bundles.path,
        },
      ]}
      link={{
        label: 'Read Documentation',
        href: DocsUrls.ACCESS_BUNDLES,
      }}
    >
      <AddBundlePageContent />
    </Page>
  );
}

function useAddBundlePage() {
  const { track } = useAnalyticsContext();
  const [searchParams] = useSearchParams();
  const handleBundleUpdate = useHandleBundleUpdate();

  const { notifySuccess } = useGlobalNotifier();

  const { isLoading: isPackLoading } = useAccessFlowsPack();
  const { createBundle, isLoading, data: createdAccessBundle } = useAppCreateBundle();

  const [isPackLoaded, setIsPackLoaded] = useState<boolean>(false);

  const formData = useMemo(() => {
    const fromIntegration = searchParams.get(FROM_INTEGRATION_QUERY_PARAM);

    const blankFormData: BundleFormData = {
      name: '',
      targets: [],
    };

    if (fromIntegration) {
      let fromIntegrationParams = { id: '', resourceOptionType: '' };
      try {
        fromIntegrationParams = JSON.parse(fromIntegration);
        blankFormData.targets = [
          {
            target_type: AccessTargetType.Integration,
            integration: {
              integration_id: fromIntegrationParams.id,
              resource_type: fromIntegrationParams.resourceOptionType,
              resource_tag_matchers: [],
              resource_tag_excludes: [],
            },
          },
        ];
      } catch {
        console.error(`Failed fetching integration id and resource type option from params`);
      }
    }

    return blankFormData;
  }, [searchParams]);

  useEffect(() => {
    if (!isPackLoading && !isPackLoaded) {
      setIsPackLoaded(true);
    }
  }, [isPackLoading, isPackLoaded]);

  useEffect(() => {
    if (!createdAccessBundle) return;

    track(CustomAnalyticsEvents.BUNDLE_CREATED, {
      name: createdAccessBundle.name,
    });

    notifySuccess('Bundle created successfully');

    handleBundleUpdate(createdAccessBundle.id);
  }, [createdAccessBundle, handleBundleUpdate, notifySuccess, searchParams, track]);

  return {
    isPackLoaded,
    formData,
    handleSubmit: createBundle,
    isSubmitting: isLoading,
  };
}

export function AddBundlePageContent() {
  const { isPackLoaded, formData, handleSubmit, isSubmitting } = useAddBundlePage();

  if (!isPackLoaded) {
    return <Loader />;
  }

  return (
    <BundleForm formData={formData} onSubmit={handleSubmit}>
      <LoadingButton data-testid="create-bundle-btn" variant="contained" loading={isSubmitting} type="submit">
        Create Bundle
      </LoadingButton>
    </BundleForm>
  );
}
