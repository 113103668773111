import { CUSTOM_COLORS } from '@AccessAlerts/organisms/AlertSeverity';
import DashboardLegend from '@DashboardV2/components/DashboardLegend';
import { Stack } from '@mui/material';

export const AlertWidgetLegend = () => (
  <Stack direction="column" spacing={1} alignItems="start">
    <Stack direction="row" spacing={2} alignItems="center">
      <DashboardLegend color={CUSTOM_COLORS[0]} label="High" />
      <DashboardLegend color={CUSTOM_COLORS[1]} label="Medium" />
      <DashboardLegend color={CUSTOM_COLORS[2]} label="Low" />
    </Stack>
  </Stack>
);
