import { Typography, Stack, Link } from '@mui/material';

import { RouterLink, generatePath } from '@libs';
import { routes } from '@routes';
import { AccessBundleAppModel } from '@api';
import { DateTime } from '@utils';

export default function BundleItemName({ bundle }: { bundle: AccessBundleAppModel }) {
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      <Link
        component={RouterLink}
        to={generatePath(routes.EditBundle.path, { id: bundle.id })}
        variant="body2"
        underline="hover"
        sx={{ inlineSize: '100%', overflowWrap: 'break-word' }}
      >
        <strong>{bundle.name}</strong>
      </Link>
      <Typography variant="helperText" color="text.muted">
        Updated at: <strong>{DateTime.fromUnix(Number(bundle.updated_date)).readable}</strong>
      </Typography>
    </Stack>
  );
}
