import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';

import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';
import { PERIOD_QUERY_PARAM } from './RightSizingPeriodFilter';
import {
  INSIGHT_TYPE_FILTER_PARAM,
  INTEGRATION_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_PARAM,
} from '@AccessRightSizing/components/AccessRightSizingInsightFilters/AccessRightSizingInsightFilters';

export const ACCESS_FLOW_QUERY_PARAM = 'access_flow_id';
export const ACCESS_FLOW_FILTER_LABEL = 'Access Flow';

interface RightSizingAccessFlowFilterProps {
  options: ListFilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  isLoading: boolean;
}

export default function RightSizingAccessFlowFilter({
  options,
  opened,
  onClose,
  onOpen,
  isLoading,
}: RightSizingAccessFlowFilterProps) {
  const [isOpen, setIsOpen] = useState(opened || false);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.get(ACCESS_FLOW_QUERY_PARAM) || undefined;

  const selectedOption = options.find((option) => option.value === selectedQp) || undefined;

  const handleOnSelect = (value: ListFilterOption) => {
    searchParams.set(ACCESS_FLOW_QUERY_PARAM, value.value);
    searchParams.delete(PERIOD_QUERY_PARAM);
    searchParams.delete(INSIGHT_TYPE_FILTER_PARAM);
    searchParams.delete(INTEGRATION_FILTER_PARAM);
    searchParams.delete(RESOURCE_TYPE_FILTER_PARAM);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={ACCESS_FLOW_FILTER_LABEL}
          value={selectedOption ? [selectedOption] : []}
          onClear={() => {}}
          loading={isLoading}
          enableClear={false}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
      width={300}
    >
      <ListFilterSingleSelect
        options={options}
        value={selectedOption}
        onSelect={handleOnSelect}
        title="Select Access Flow Filter"
        loading={isLoading}
        enableSearch
      />
    </ListFilter>
  );
}
