import { useFlagFixed } from '@hooks';
import { useSession } from '@api';
import { Chip, Link, styled, Typography } from '@mui/material';

import { Flag } from '@utils';

function useTrailBanner() {
  const { session } = useSession();
  const { isEnabled: showTrialBanner } = useFlagFixed(Flag.ACCOUNT_TRIAL_BANNER);
  return showTrialBanner && session?.account.plan === 'Trial';
}

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const PlanBanner = ({ mini }: { mini?: boolean }) => {
  const shouldShowTrial = useTrailBanner();

  if (!shouldShowTrial) {
    return <></>;
  }

  const text = mini ? (
    'Trial'
  ) : (
    <span>
      Congrats! You are enjoying a free trial of the Starter Plan. Click{' '}
      <Link href="https://www.apono.io/pricing" target="blank">
        here
      </Link>{' '}
      to see what is included in this plan.
    </span>
  );

  return (
    <StyledChip
      size="small"
      label={
        <Typography variant="inputLabel" component="span" color="success.main">
          {text}
        </Typography>
      }
    />
  );
};
