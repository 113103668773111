import Drawer from '@mui/material/Drawer';
import { aponoColors } from '@utils';
import { Logo } from '@Onboarding/layouts/OnboardingDrawer/Logo';
import { OnboardingList } from '@Onboarding/layouts/OnboardingDrawer/OnboardingList';
import { Stack } from '@mui/material';
import { GlobalNotifications, Theme, User } from '@components';

export function OnboardingDrawer() {
  return (
    <Theme isDark>
      <Drawer
        open
        anchor="left"
        variant="permanent"
        sx={{
          zIndex: 100,
          width: '16rem',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '16rem',
            padding: 1,
            boxSizing: 'border-box',
            backgroundColor: aponoColors.neutral[900],
          },
        }}
      >
        <Stack direction="column" height="100%">
          <Stack flexGrow={1}>
            <Logo />
            <OnboardingList />
          </Stack>
          <User wide noAdmin noSettings />
        </Stack>
        <GlobalNotifications />
      </Drawer>
    </Theme>
  );
}
