import { PropsWithChildren, ReactNode } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';

const WidgetCardTitleContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5, 3),
}));

export interface WidgetCardTitleProps {
  titleCentered?: boolean;
  titleActions?: ReactNode;
}

export function WidgetCardTitle({ titleCentered, titleActions, children }: PropsWithChildren<WidgetCardTitleProps>) {
  const title = () => {
    if (typeof children === 'string')
      return (
        <Typography variant="subtitle1" textAlign={titleCentered ? 'center' : 'inherit'} component="div">
          {children}
        </Typography>
      );

    return <>{children}</>;
  };

  if (titleActions) {
    return (
      <WidgetCardTitleContainer>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {title()}
          {titleActions}
        </Stack>
      </WidgetCardTitleContainer>
    );
  }

  return <WidgetCardTitleContainer>{title()}</WidgetCardTitleContainer>;
}
