import { useState } from 'react';
import { useAppQuery } from '@api/query/use-app-query';
import { FrontierService } from '@api/services';
import { QueryAlertArgs } from '@api/gql/graphql';
import { alertQueryDocument } from './gql';
import { AlertsQueryKeys } from './queryKeys';

export function useAlert(args: QueryAlertArgs) {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(alertQueryDocument, { ...args, withAccessGroups: true });
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery([AlertsQueryKeys.AlertSingle, JSON.stringify(args)], fetch, {
    keepPreviousData: true,
  });

  return { ...res, isFetched: isFirstTimeFetched };
}
