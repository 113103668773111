import { PropsWithChildren } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Portal } from '@mui/material';
import { RouterLink } from '@libs';
import { Loader } from '@components/Loader';
import { AnalyticsTriggerElementProps } from '@common/types';

export interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;

  onConfirm?: () => void;
  confirmLabel?: string;
  confirmTo?: string;

  onDisprove?: () => void;
  disproveLabel?: string;
  disproveTo?: string;
  loading?: boolean;
}

export function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  confirmLabel,
  confirmTo,
  onDisprove,
  disproveLabel,
  disproveTo,
  children,
  loading,
  ...analyticsProps
}: PropsWithChildren<ConfirmDialogProps> & AnalyticsTriggerElementProps) {
  const hasConfirmButton = onConfirm || confirmTo;

  if (loading) {
    return (
      <Portal>
        <Dialog open={open} data-testid="confirm-dialog">
          <DialogContent>
            <Loader absolute={false} />
          </DialogContent>
        </Dialog>
      </Portal>
    );
  }

  return (
    <Portal>
      <Dialog open={open} onClose={onClose} data-testid="confirm-dialog">
        {children ? (
          <DialogContent>
            <DialogContentText variant="body2" component="div">
              {children}
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <ConfirmDialogButton
            testId="dialog-no-button"
            onClose={onClose}
            onClick={onDisprove}
            to={disproveTo}
            label={disproveLabel || 'No'}
          />
          {hasConfirmButton ? (
            <ConfirmDialogButton
              testId="dialog-yes-button"
              onClose={onClose}
              onClick={onConfirm}
              to={confirmTo}
              label={confirmLabel || 'Yes'}
              {...analyticsProps}
            />
          ) : null}
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

interface ConfirmDialogButtonProps {
  label: string;
  testId: string;
  onClose: () => void;
  onClick?: () => void;
  to?: string;
}

function ConfirmDialogButton({
  testId,
  onClose,
  onClick,
  to,
  label,
  ...analyticsProps
}: ConfirmDialogButtonProps & AnalyticsTriggerElementProps) {
  if (onClick) {
    return (
      <Button
        data-testid={testId}
        onClick={() => {
          onClick();
          onClose();
        }}
        {...analyticsProps}
      >
        {label}
      </Button>
    );
  }

  if (to) {
    return (
      <Button data-testid={testId} component={RouterLink} to={to}>
        {label}
      </Button>
    );
  }

  return (
    <Button data-testid={testId} onClick={() => onClose()}>
      {label}
    </Button>
  );
}
