import { Box, styled, Typography } from '@mui/material';
import { BarTooltipProps } from '@nivo/bar';
import { PieTooltipProps } from '@nivo/pie';
import React from 'react';

const TooltipWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),
  padding: '5px',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(0.5),
}));

export const DashboardPieWidgetTooltip: React.FC<
  PieTooltipProps<{ id: string; label: string; value: number; color: string }>
> = ({ datum }) => (
  <TooltipWrapper>
    <div style={{ width: '12px', height: '12px', backgroundColor: datum.color, borderRadius: 2 }}></div>
    <Typography variant="tooltip">
      {datum.label}: {datum.value}
    </Typography>
  </TooltipWrapper>
);

export const DashboardBarWidgetTooltip: React.FC<
  BarTooltipProps<{ type: string; connected: number; inAccessFlows: number; requested: number; color: string }>
> = (props) => {
  return (
    <TooltipWrapper>
      <div style={{ width: '12px', height: '12px', backgroundColor: props.color }}></div>
      <Typography variant="tooltip">
        {props.indexValue}: {props.value}
      </Typography>
    </TooltipWrapper>
  );
};
