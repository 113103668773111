import { generatePath } from 'react-router-dom';
import { Alert } from '@mui/material';

import { routes } from '@routes';

import { useOnboardingContext } from '@Onboarding/context';
import { OnboardingAccessFlowPageWrap } from '@Onboarding/components/OnboardingAccessFlowPageWrap';
import { EditAccessFlowPageContent } from '@AccessFlows/organisms/EditAccessFlowPageContent';

export function OnboardingEditAccessFlowPage() {
  const { onboardingIds } = useOnboardingContext();

  return (
    <OnboardingAccessFlowPageWrap>
      {onboardingIds?.added_access_flow_id ? (
        <EditAccessFlowPageContent
          accessFlowId={onboardingIds.added_access_flow_id}
          paths={{
            backPath: generatePath(routes.OnboardingEditIntegration.path, { id: onboardingIds.added_integration_id }),
            donePath: routes.OnboardingRequestAccess.path,
            doneButtonLabel: 'Request Access',
          }}
        />
      ) : (
        <Alert severity="error">Access Flow ID is missing</Alert>
      )}
    </OnboardingAccessFlowPageWrap>
  );
}
