import { Stack, styled } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { CUSTOM_COLORS } from '@DashboardV2/organisms/CurrentActiveAccess';
import { DashboardPieWidgetTooltip } from './DashboardWidgetTooltip';
import { MARGIN, ResponsivePieWrapper } from '@DashboardV2/common/utils';
import { ActiveAccessStatsFieldsFragment } from '@api/gql/graphql';
import DashboardLegend from './DashboardLegend';

const TotalOverlay = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  fontSize: '24px',
  fontWeight: '600',
  dominantBaseline: 'central',
  textAnchor: 'middle',
}));

export function CurrentActiveAccessWidget({ data }: { data: ActiveAccessStatsFieldsFragment }) {
  const widgetData = [
    {
      id: 'Automatic Approval',
      label: 'Automatic Approval',
      value: data?.automaticApproval || 0,
      color: CUSTOM_COLORS[0],
    },
    {
      id: 'Manual Approval',
      label: 'Manual Approval',
      value: data?.manualApproval || 0,
      color: CUSTOM_COLORS[1],
    },
    {
      id: 'Automatic Access',
      label: 'Automatic Access',
      value: data?.autoGrant || 0,
      color: CUSTOM_COLORS[2],
    },
  ].filter((item) => item.value != 0);

  const CenteredDataLayer = ({
    centerX,
    centerY,
  }: {
    centerX: string | number | undefined;
    centerY: string | number | undefined;
  }) => (
    <TotalOverlay x={centerX} y={centerY}>
      {widgetData.reduce((acc, item) => acc + item.value, 0)}
    </TotalOverlay>
  );

  const Legend = () => (
    <Stack direction="column" spacing={1} alignItems="start">
      <Stack direction="row" spacing={2} alignItems="center">
        <DashboardLegend color={CUSTOM_COLORS[0]} label="Automatically Approved" />
        <DashboardLegend color={CUSTOM_COLORS[1]} label="Manually Approved" />
      </Stack>
      <DashboardLegend
        color={CUSTOM_COLORS[2]}
        label="Automatic"
        tooltip="Access requests generated by Automatic Access Flows, without the need for a user request"
      />
    </Stack>
  );

  return (
    <Stack direction="column" spacing={2}>
      <ResponsivePieWrapper>
        <ResponsivePie
          data={widgetData}
          margin={{ right: MARGIN, left: MARGIN }}
          innerRadius={0.8}
          colors={(bar) => bar.data.color}
          padAngle={2}
          cornerRadius={2}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          motionConfig="gentle"
          borderWidth={1}
          layers={['arcs', CenteredDataLayer, Legend]}
          tooltip={DashboardPieWidgetTooltip}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
        />
      </ResponsivePieWrapper>
      <Legend />
    </Stack>
  );
}
