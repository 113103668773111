import { Checkbox, MenuItem, Radio, Stack, Typography } from '@mui/material';

export interface SelectDropdownOption<T> {
  value: T;
  label: string;
}

export interface SelectDropdownItemProps<T> {
  option: SelectDropdownOption<T>;
  onClick: (option: T) => void;
  selected?: boolean;
  disabled?: boolean;
  type?: 'radio' | 'checkbox';
}

export function SelectDropdownItem<T = string>({
  onClick,
  option,
  selected = false,
  disabled = false,
  type = 'checkbox',
}: SelectDropdownItemProps<T>) {
  const RadioOrCheck = type === 'checkbox' ? Checkbox : Radio;

  return (
    <MenuItem onClick={() => onClick(option.value)} disabled={disabled}>
      <Stack direction="row" spacing={1}>
        <RadioOrCheck checked={selected} size="small" sx={{ padding: 0 }} />
        <Typography variant="inherit">{option.label}</Typography>
      </Stack>
    </MenuItem>
  );
}
