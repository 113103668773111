import { DurationUnit } from './DurationUnit';
import { Duration } from './Duration';

export const unitInSeconds: Record<DurationUnit, number> = {
  [DurationUnit.Indefinite]: -1,
  [DurationUnit.Years]: Duration.fromYears(1).toSeconds,
  [DurationUnit.Months]: Duration.fromMonths(1).toSeconds,
  [DurationUnit.Weeks]: Duration.fromWeeks(1).toSeconds,
  [DurationUnit.Days]: Duration.fromDays(1).toSeconds,
  [DurationUnit.Hours]: Duration.fromHours(1).toSeconds,
  [DurationUnit.Minutes]: Duration.fromMinutes(1).toSeconds,
  [DurationUnit.Seconds]: Duration.fromSeconds(1).toSeconds,
};
