import { ActivityRequestAppModel } from '@api';
import LeftOversChip from '@common/ui/LeftOversChip';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { Typography } from '@mui/material';

export default function AggregatedPermissionsCell({
  aggregatedPermissions,
}: {
  aggregatedPermissions: ActivityRequestAppModel['aggregated_permissions'];
}) {
  if (aggregatedPermissions.length === 0) {
    return (
      <Typography variant="helperText" color="text.muted">
        -
      </Typography>
    );
  }

  const leftOvers = aggregatedPermissions.length > 1 ? aggregatedPermissions.slice(1) : [];
  const primaryMore =
    leftOvers.length > 0 ? <LeftOversChip label={`+${leftOvers.length}`} tooltip={leftOvers.join(', ')} /> : undefined;

  return <PrimarySecondary primary={aggregatedPermissions[0]} primaryMore={primaryMore} />;
}
