import { AttributeOperatorModel } from '@AccessFlows/common/constants';
import { AccessFlowAreaModel, AttributeAppModel, AttributeFilterAppModel, AttributeGroupAppModel } from '@api';
import { useCallback, useMemo, useState } from 'react';
import HumanReadableAttribute from '../HumanReadable/Attribute';
import HumanReadableAttributeOperator from '../HumanReadable/AttributeOperator';
import HumanReadableAttributeValue from '../HumanReadable/AttributeValue';
import FlowFormSelectAttribute from '../SelectAttribute';
import FlowFormSelectAttributeOperator from '../SelectAttributeOperator';
import FlowFormSelectAttributeValue from '../SelectAttributeValue';
import { FlowFormLine } from '../common';

interface AdvancedAttributeProps {
  filter?: AttributeFilterAppModel;
  onChange: (filter?: AttributeFilterAppModel) => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  applicablePlaces: AccessFlowAreaModel[];
}

function isAttrValueSelectorImplicitMode(operator: AttributeOperatorModel) {
  switch (operator) {
    case AttributeOperatorModel.Is:
    case AttributeOperatorModel.IsNot:
      return false;
    default:
      return true;
  }
}

export default function AdvancedAttribute({
  filter,
  onChange,
  onDuplicate,
  onDelete,
  applicablePlaces,
}: AdvancedAttributeProps) {
  const [attribute, setAttribute] = useState<AttributeGroupAppModel | undefined>(() => {
    if (!filter) return;
    return { type: filter.attribute_type, integration: filter.integration };
  });

  const [attributeOperator, setAttributeOperator] = useState<AttributeOperatorModel>(() => {
    if (!filter) return AttributeOperatorModel.Is;
    switch (filter.operator) {
      case AttributeOperatorModel.Is:
      case AttributeOperatorModel.IsNot:
      case AttributeOperatorModel.Contains:
      case AttributeOperatorModel.DoesNotContain:
      case AttributeOperatorModel.StartsWith:
        return filter.operator as AttributeOperatorModel;
      default:
        return AttributeOperatorModel.Is;
    }
  });

  const [initOpenSelector, setInitOpenSelector] = useState(false);

  const handleAttributeValueChange = useCallback(
    (attributeValue?: string[], newMatchingAttributes?: AttributeAppModel[]) => {
      if (!attribute) return onChange(undefined);

      let newFilter: AttributeFilterAppModel | undefined;
      if (attributeValue?.length) {
        newFilter = {
          attribute_type: attribute.type,
          integration: attribute.integration,
          operator: attributeOperator,
          attribute_value: attributeValue,
          matching_attributes: newMatchingAttributes || [],
        };
      }
      onChange(newFilter);
    },
    [attribute, attributeOperator, onChange],
  );

  const handleAttributeChange = useCallback(
    (newAttr: AttributeGroupAppModel) => {
      setAttribute(newAttr);
      if (newAttr.type.request_context_based) {
        onChange({
          attribute_type: newAttr.type,
          integration: newAttr.integration,
          operator: AttributeOperatorModel.Is,
          attribute_value: [],
          matching_attributes: [],
        });
        return;
      }
      setInitOpenSelector(true);
      handleAttributeValueChange(undefined);
    },
    [handleAttributeValueChange, onChange],
  );

  const attrValueSelectorImplicitMode = useMemo(
    () => isAttrValueSelectorImplicitMode(attributeOperator),
    [attributeOperator],
  );

  const handleAttributeOperatorChange = useCallback(
    (newOperator: AttributeOperatorModel) => {
      setAttributeOperator(newOperator);

      if (isAttrValueSelectorImplicitMode(newOperator) !== attrValueSelectorImplicitMode) {
        onChange(undefined);
      } else if (filter) {
        onChange({ ...filter, operator: newOperator });
      }
    },
    [attrValueSelectorImplicitMode, filter, onChange],
  );

  return (
    <FlowFormLine withBorder onDelete={onDelete} onDuplicate={onDuplicate}>
      <FlowFormSelectAttribute
        data-testid="flow-form-select-approver-attribute-type"
        trigger={<HumanReadableAttribute attribute={attribute} />}
        value={attribute}
        onChange={handleAttributeChange}
        applicablePlaces={applicablePlaces}
      />
      {attribute && !attribute.type.request_context_based && (
        <>
          <FlowFormSelectAttributeOperator
            trigger={<HumanReadableAttributeOperator operator={attributeOperator} />}
            value={attributeOperator}
            onChange={handleAttributeOperatorChange}
          />
          <FlowFormSelectAttributeValue
            key={`${attribute.type.type}-${attribute.integration?.id}`}
            implicit={attrValueSelectorImplicitMode}
            trigger={
              <HumanReadableAttributeValue
                attributeValue={filter?.attribute_value}
                matchingAttributes={filter?.matching_attributes || []}
                placeholder="Select value"
              />
            }
            attributeType={attribute}
            value={filter?.attribute_value}
            onChange={handleAttributeValueChange}
            initOpen={initOpenSelector}
            onClose={() => setInitOpenSelector(false)}
          />
        </>
      )}
    </FlowFormLine>
  );
}
