import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface AccessRightSizingItemGridProps {
  accessFlows: ReactNode;
  unusedResources: ReactNode;
  unusedPermissions: ReactNode;
  usersNotRequesting: ReactNode;
  avgDurationRequested: ReactNode;
  actions: ReactNode;
}

export default function AccessRightSizingItemGrid({
  accessFlows,
  unusedResources,
  unusedPermissions,
  usersNotRequesting,
  avgDurationRequested,
  actions,
}: AccessRightSizingItemGridProps) {
  return (
    <Grid container alignItems="center" columnSpacing={2}>
      <Grid item xs={4}>
        {accessFlows}
      </Grid>
      <Grid item xs={1.5}>
        {unusedResources}
      </Grid>
      <Grid item xs={1.5} container>
        {unusedPermissions}
      </Grid>
      <Grid item xs={1.5} container>
        {usersNotRequesting}
      </Grid>
      <Grid item xs={2} container>
        {avgDurationRequested}
      </Grid>
      <Grid item xs={1.5} container>
        {actions}
      </Grid>
    </Grid>
  );
}
