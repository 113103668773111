import { CloudTypeModel, InstallationMethod } from '@api';

export const PlatformType = {
  ...CloudTypeModel,
  K8s: 'K8S',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PlatformType = (typeof PlatformType)[keyof typeof PlatformType];

export enum PermissionsRequirement {
  WITH = 'with',
  WITHOUT = 'without',
  GCP_PROJECT = 'gcp_project',
  GCP_ORGANIZATION = 'gcp_organization',
  AWS_ACCOUNT = 'aws_account',
  AWS_ORGANIZATION = 'aws_organization',
}

export enum InstallStrategy {
  LOCAL = 'local',
  PLATFORM = 'platform',
}

export const InstallMethod = {
  ...InstallationMethod,
  Local: 'Local',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InstallMethod = (typeof InstallMethod)[keyof typeof InstallMethod];
