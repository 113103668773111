import { useState } from 'react';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { AttributeAppModel } from '@api';

import AppModal from '@common/ui/AppModal';
import { EmptyState, Loader, SearchBar } from '@components';
import { useAppListIdentitiesInfinityPagination } from '@common/services/accountQueries';
import UserBanner from '@Groups/components/UserBanner';

interface IdentitiesByAttributeModalPaginatedProps {
  attribute: AttributeAppModel;
  onClose: () => void;
}

export default function IdentitiesByAttributeModalPaginated({
  attribute,
  onClose,
}: IdentitiesByAttributeModalPaginatedProps) {
  const [search, setSearch] = useState('');
  const { data, isFetched, hasNextPage, fetchNextPage, isFetchingNextPage } = useAppListIdentitiesInfinityPagination(
    {
      search,
      attributeId: [attribute.id],
    },
    {
      withoutPageParam: true,
    },
  );

  const usersList = data?.pages.flatMap((page) => page.data) ?? [];
  const total = data?.pages?.[0].pagination.total || 0;

  return (
    <AppModal
      open
      onClose={onClose}
      header={`View users by ${attribute.type.display_name} - ${attribute.value}`}
      width={540}
    >
      <Stack spacing={2}>
        <SearchBar value={search} onChange={setSearch} total={total} />
        <Stack spacing={1}>
          {!isFetched && <Loader absolute={false} />}
          {isFetched && total === 0 && <EmptyState title="No users found" />}
          {usersList.map((user) => (
            <UserBanner
              key={user.id}
              firstName={user.first_name}
              lastName={user.last_name}
              email={user.email}
              role={user.roles.length > 0 ? user.roles[0] : undefined}
            />
          ))}
          {hasNextPage && (
            <LoadingButton onClick={() => fetchNextPage()} loading={isFetchingNextPage} variant="text" fullWidth>
              Load more
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </AppModal>
  );
}
