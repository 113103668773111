import { IntegrationConfigWithSubIntegrations } from '@api';
import { FormProvider } from 'react-hook-form';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { ConfigInfo } from '@Integrations/organisms/IntegrationConfigForm/ConfigInfo';
import { IntegrationConfigLayout } from '@Integrations/IntegrationConfigForm/layout/IntegrationConfigLayout';
import { IntegrationFormHeader } from '@Integrations/IntegrationConfigForm/components/IntegrationFormHeader';
import { Stack, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { DiscoveryFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/DiscoveryFormSection';
import { FormSectionActions } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionActions';
import { ConnectorFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/ConnectorFormSection';
import { IntegrationConfigFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/IntegrationConfigFormSection';
import { SecretStoreFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/SecretStoreFormSection';
import { GetMoreFormSection } from '@Integrations/IntegrationConfigForm/organisms/FormSections/GetMoreFormSection';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { ReactNode } from 'react';
import { CategoryConfigFormSection } from './FormSections/CategoryConfigFormSection';
import { CustomAccessDetailsFormSection } from './FormSections/CustomAccessDetailsFormSection';
import { groupParamsByCategory } from '../utils';
import { IntegrationOwnerFormSection } from './FormSections/IntegrationOwnerFormSection';
import { ResourceOwnerFormSection } from './FormSections/ResourceOwnerFormSection';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

interface IntegrationFormStepperProps {
  config: IntegrationConfigWithSubIntegrations;
  options?: IntegrationConfigWithSubIntegrations[];
  methods: UseFormReturn<IntegrationConfigFormPayload>;
  onSubmit: (data: IntegrationConfigFormPayload) => void;
  openSection: number;
  currentSection: number;
  setOpenSection: (section: number) => void;
  hasSecretStore: boolean;
  isFormDisabled: boolean;
  isConnectorsDisabled: boolean;
  isSecretStoreDisabled: boolean;
  isResourceTypeDisabled: boolean;
  isCreateIntegrationLoading: boolean;
  integrationConfigDisabled: boolean;
  isViewOnly?: boolean;
}

export function IntegrationFormStepper({
  config,
  options,
  methods,
  onSubmit,
  openSection,
  currentSection,
  setOpenSection,
  hasSecretStore,
  isFormDisabled,
  isConnectorsDisabled,
  isSecretStoreDisabled,
  isResourceTypeDisabled,
  isCreateIntegrationLoading,
  integrationConfigDisabled,
  isViewOnly,
}: IntegrationFormStepperProps) {
  const { watch } = methods;

  const { isEnabled: isResourceOwnersEnabled } = useFlagFixed(Flag.RESOURCE_OWNERS);
  const { isEnabled: isUserDeletionEnabled } = useFlagFixed(Flag.USER_CLEANUP_SETTING);

  const { credentialsRotationsParams, userDeletionParams, otherParams } = groupParamsByCategory(config);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <IntegrationConfigLayout sideInfo={<ConfigInfo info={config.info} links={config.links} />}>
          <IntegrationFormHeader config={config} connectorId={watch('provisioner_id')} />
          <Stepper
            orientation="vertical"
            activeStep={openSection}
            sx={{ '& .MuiStepConnector-root': { display: 'none' } }}
          >
            <Step key={0} expanded>
              <StepWrap>
                <DiscoveryFormSection
                  config={config}
                  options={options}
                  expanded={openSection === 0}
                  onSummaryClick={() => setOpenSection(0)}
                  actions={
                    <FormSectionActions
                      nextProps={{
                        onClick: () => setOpenSection(1),
                        disabled: isResourceTypeDisabled,
                        disabledtooltiptext: 'Please select at least one resource type',
                      }}
                    />
                  }
                />
              </StepWrap>
            </Step>
            <Step key={1} expanded>
              <StepWrap>
                <ConnectorFormSection
                  config={config}
                  expanded={openSection === 1}
                  onSummaryClick={() => setOpenSection(1)}
                  actions={
                    <FormSectionActions
                      backProps={{ onClick: () => setOpenSection(0) }}
                      nextProps={{
                        onClick: () => setOpenSection(2),
                        disabled: isConnectorsDisabled,
                        disabledtooltiptext: 'Please select a connector',
                      }}
                    />
                  }
                />
              </StepWrap>
            </Step>
            <Step key={2} expanded>
              <StepWrap>
                <IntegrationConfigFormSection
                  params={otherParams}
                  expanded={openSection === 2}
                  onSummaryClick={() => setOpenSection(2)}
                  isViewOnly={isViewOnly}
                  actions={
                    <FormSectionActions
                      backProps={{ onClick: () => setOpenSection(1) }}
                      nextProps={{
                        onClick: () => setOpenSection(3),
                        disabled: integrationConfigDisabled,
                        disabledtooltiptext: 'Please fill in all required fields',
                      }}
                    />
                  }
                />
              </StepWrap>
            </Step>
            {hasSecretStore && (
              <Step key={3} expanded>
                <StepWrap>
                  <SecretStoreFormSection
                    config={config}
                    expanded={openSection === 3}
                    onSummaryClick={() => setOpenSection(3)}
                    actions={
                      <FormSectionActions
                        backProps={{ onClick: () => setOpenSection(2) }}
                        nextProps={{
                          onClick: () => setOpenSection(4),
                          disabled: isSecretStoreDisabled,
                          disabledtooltiptext: 'Please fill in all required fields',
                        }}
                      />
                    }
                  />
                </StepWrap>
              </Step>
            )}
            <Step key={currentSection} expanded>
              <StepWrap>
                <GetMoreFormSection
                  expanded={openSection === currentSection}
                  onSummaryClick={() => setOpenSection(currentSection)}
                >
                  <Stack spacing={2}>
                    {credentialsRotationsParams.length > 0 && (
                      <CategoryConfigFormSection
                        title="Credential Rotation"
                        icon="password"
                        categorizedParams={credentialsRotationsParams}
                        disabled={isViewOnly}
                      />
                    )}
                    {isUserDeletionEnabled && userDeletionParams.length > 0 && (
                      <CategoryConfigFormSection
                        title="User Deletion"
                        icon="group_remove"
                        categorizedParams={userDeletionParams}
                        disabled={isViewOnly}
                      />
                    )}
                    <CustomAccessDetailsFormSection
                      config={config}
                      divider={isResourceOwnersEnabled}
                      disabled={isViewOnly}
                    />
                    {isResourceOwnersEnabled && (
                      <>
                        <IntegrationOwnerFormSection />
                        <ResourceOwnerFormSection divider={false} disabled={isViewOnly} />
                      </>
                    )}
                  </Stack>
                </GetMoreFormSection>
              </StepWrap>
            </Step>
            {openSection === currentSection && (
              <Stack direction="column" px={1.5} py={2}>
                <FormSectionActions
                  nextText="Confirm"
                  backProps={{
                    onClick: () => setOpenSection(currentSection - 1),
                  }}
                  nextProps={{
                    type: 'submit',
                    loading: isCreateIntegrationLoading,
                    disabled: isFormDisabled,
                    disabledtooltiptext: 'Please fill in all required fields',
                  }}
                />
              </Stack>
            )}
          </Stepper>
        </IntegrationConfigLayout>
      </form>
    </FormProvider>
  );
}

function StepWrap({ children }: { children: ReactNode }) {
  return (
    <>
      <StepLabel />
      <StepContent>{children}</StepContent>
    </>
  );
}
