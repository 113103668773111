import { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { Drawer as MuiDrawer, styled } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { aponoColors } from '@utils';

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  backgroundColor: aponoColors.neutral[900],
  width: theme.spacing(7),

  '& .MuiDrawer-paper': {
    borderRight: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    boxShadow: 'none',
  },
}));

export interface SidebarProps {
  opened?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export function Sidebar({ children }: PropsWithChildren<SidebarProps>) {
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  const [hovered, setHovered] = useState(false);

  useLayoutEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useLayoutEffect(() => {
    closeDrawer();
  }, [pathname]);

  function closeDrawer() {
    setHovered(false);
  }

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <Drawer
        variant="permanent"
        onMouseEnter={() => {
          if (timer) clearTimeout(timer);
          setTimer(setTimeout(() => setHovered(true), 250));
        }}
        onMouseLeave={() => {
          setTimer(setTimeout(() => setHovered(false), 250));
        }}
        className={hovered ? 'opened' : ''}
      >
        {children}
      </Drawer>
    </ClickAwayListener>
  );
}
