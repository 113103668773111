import { Typography } from '@mui/material';

import { FlowFormText, FlowInlineText } from '../common/Text';
import { HumanReadableText, HumanReadableTextProps } from '../common/HumanReadableText';
import { IntegrationInfo } from '../common/interfaces';
import { useListIntegrationsV2 } from '@api';

export interface HumanReadableIntegrationProps extends Omit<HumanReadableTextProps, 'value' | 'values'> {
  value?: IntegrationInfo;
}

function useHumanReadableIntegration(value?: IntegrationInfo) {
  const { integrations: connectedIntegrations, configs } = useListIntegrationsV2();

  if (!value?.integrationId) return;

  const connectedIntegration = connectedIntegrations.find((i) => i.id === value.integrationId);
  const integrationConfig = configs.find((c) => c.type === connectedIntegration?.type);
  const resourceType = integrationConfig?.resource_types[value.resourceType]?.display_name;
  return {
    notFound: !connectedIntegration,
    configIcon: integrationConfig?.icons.svg,
    configName: integrationConfig?.name,
    integrationName: connectedIntegration?.name || '',
    resourceType: resourceType || '',
  };
}

export function HumanReadableIntegration({
  value,
  inline,
  errorText,
  hideErrorText,
  placeholder,
}: HumanReadableIntegrationProps) {
  const parts = useHumanReadableIntegration(value);
  const errorProps = { errorText, hideErrorText };

  if (!parts) {
    return inline ? (
      <FlowInlineText inline={inline}>{placeholder}</FlowInlineText>
    ) : (
      <FlowFormText selectable muted {...errorProps}>
        {placeholder}
      </FlowFormText>
    );
  }

  const { notFound, configName, integrationName, resourceType } = parts;

  if (notFound) {
    const notFoundText = (
      <Typography color="error" variant="inherit" component="span">
        Integration not found
      </Typography>
    );

    return inline ? (
      <FlowInlineText inline={inline}>{notFoundText}</FlowInlineText>
    ) : (
      <FlowFormText selectable {...errorProps}>
        {notFoundText}
      </FlowFormText>
    );
  }

  return (
    <HumanReadableText
      value={
        <>
          {configName}/{integrationName}/{resourceType}
        </>
      }
      inline={inline}
      {...errorProps}
    />
  );
}
