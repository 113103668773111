import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Input, List, Typography } from '@mui/material';
import {
  AccountsApiUpdateAccountSettingsRequest,
  AccountSettingsModel,
  FallbackPolicyApiModel,
  useGetAccountSettings,
  useGetFallbackPolicy,
  useUpdateAccountSettings,
  useUpdateFallbackPolicy,
} from '@api';
import { Loader } from '@components';
import { SettingSelector } from '@components/SettingSelector/SettingSelector';
import { useDebounce } from '@libs';
import { useFlagFixed, useGlobalNotifier } from '@hooks';
import { Flag } from '@utils';

interface SettingsListInterface {
  name: keyof AccountSettingsModel | keyof FallbackPolicyApiModel;
  title: string;
  description: ReactNode;
  docs?: string;
  checked: boolean;
  handleToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  isPremium: boolean;
}
const DEFAULT_RESET_PERIOD = 90;
export function Settings() {
  const { isPremium: isResetCredentialsPremium } = useFlagFixed(Flag.PREMIUM_CREDENTIALS_ROTATION_POLICY);
  const { isEnabled: isRequireDurationEnabled } = useFlagFixed(Flag.SHOW_SET_ACCESS_DURATION);
  const { isEnabled: isInactiveUserRetentionEnabled, isPremium: isInactiveUserRetentionPremium } = useFlagFixed(
    Flag.USER_CLEANUP_SETTING,
  );
  const { settings, isSettingsFetched } = useGetAccountSettings();
  const { updateAccountSettings, isUpdateAccountSettingsLoading, isSuccess } = useUpdateAccountSettings();
  const { fallbackPolicy, isFallbackPolicyFetched } = useGetFallbackPolicy();
  const { updateFallbackPolicy, isUpdateFallbackPolicyLoading } = useUpdateFallbackPolicy();
  const { notifySuccess } = useGlobalNotifier();

  useEffect(() => {
    if (isSuccess) {
      notifySuccess('Settings successfully updated');
    }
  }, [isSuccess, notifySuccess]);

  const handleAccountSettingsChange = useCallback(
    (name: keyof AccountSettingsModel, value: boolean | number | null | undefined) => {
      const updatedSettingsModel: AccountSettingsModel = {
        ...settings,
        [name]: value,
      };
      const updatePayload: AccountsApiUpdateAccountSettingsRequest = {
        updateAccountSettingsModel: updatedSettingsModel,
      };
      updateAccountSettings(updatePayload);
    },
    [settings, updateAccountSettings],
  );

  const handleFallbackPolicyChange = useCallback(
    (name: keyof FallbackPolicyApiModel, value: boolean) => {
      const fallbackPolicyApiModel = {
        ...fallbackPolicy,
        [name]: value,
      };
      updateFallbackPolicy({ fallbackPolicyApiModel });
    },
    [fallbackPolicy, updateFallbackPolicy],
  );

  const settingsList: SettingsListInterface[] = [
    {
      name: 'enable_flow_failure_notifications',
      title: 'Notifications',
      description: 'Notify admins about access flow failures',
      checked: settings.enable_flow_failure_notifications,
      handleToggleChange: () =>
        handleAccountSettingsChange('enable_flow_failure_notifications', !settings.enable_flow_failure_notifications),
      isLoading: isUpdateAccountSettingsLoading,
      isPremium: false,
    },
    {
      name: 'integrations_updates_notifications',
      title: 'Integration Updates Notifications',
      description: 'Notify admins about integrations status updates by email',
      checked: settings.integrations_updates_notifications ?? false,
      handleToggleChange: () =>
        handleAccountSettingsChange('integrations_updates_notifications', !settings.integrations_updates_notifications),
      isLoading: isUpdateAccountSettingsLoading,
      isPremium: false,
    },
    {
      name: 'is_active',
      title: 'Fallback',
      description: 'Enable fallback access flow',
      docs: 'https://docs.apono.io/docs/access-flow',
      checked: fallbackPolicy.is_active,
      handleToggleChange: () => handleFallbackPolicyChange('is_active', !fallbackPolicy.is_active),
      isLoading: isUpdateFallbackPolicyLoading,
      isPremium: false,
    },
    {
      name: 'credentials_rotation_period_in_days',
      title: 'Reset credentials',
      description: (
        <RotationPeriodInput
          value={settings.credentials_rotation_period_in_days}
          onChange={(val) => {
            handleAccountSettingsChange('credentials_rotation_period_in_days', val);
          }}
          description="days, credentials will be reset for a users next access request."
          isPremium={isResetCredentialsPremium}
        />
      ),
      docs: 'https://docs.apono.io/docs/access-flow',
      checked: settings.credentials_rotation_period_in_days !== undefined,
      handleToggleChange: () => {
        handleAccountSettingsChange(
          'credentials_rotation_period_in_days',
          settings.credentials_rotation_period_in_days === undefined ? DEFAULT_RESET_PERIOD : undefined,
        );
      },
      isLoading: isUpdateAccountSettingsLoading,
      isPremium: isResetCredentialsPremium,
    },
  ];

  if (isInactiveUserRetentionEnabled) {
    settingsList.push({
      name: 'inactive_user_retention_in_days',
      title: 'User Deletion',
      description: (
        <RotationPeriodInput
          value={settings.inactive_user_retention_in_days}
          onChange={(val) => {
            handleAccountSettingsChange('inactive_user_retention_in_days', val);
          }}
          description={'days, accounts created by Apono that were not requested will be deleted.'}
          isPremium={isInactiveUserRetentionPremium}
        />
      ),
      docs: 'https://docs.apono.io/docs/access-flow',
      checked: settings.inactive_user_retention_in_days !== undefined,
      handleToggleChange: () => {
        handleAccountSettingsChange(
          'inactive_user_retention_in_days',
          settings.inactive_user_retention_in_days === undefined ? DEFAULT_RESET_PERIOD : undefined,
        );
      },
      isLoading: isUpdateAccountSettingsLoading,
      isPremium: isInactiveUserRetentionPremium,
    });
  }

  if (isRequireDurationEnabled) {
    settingsList.push({
      name: 'require_duration_for_access_request',
      title: 'Require duration for access request',
      description: 'Require duration when requesting access',
      checked: settings.require_duration_for_access_request ?? false,
      handleToggleChange: () =>
        handleAccountSettingsChange(
          'require_duration_for_access_request',
          !settings.require_duration_for_access_request,
        ),
      isLoading: isUpdateAccountSettingsLoading,
      isPremium: false,
    });
  }

  if (!isSettingsFetched || !isFallbackPolicyFetched) return <Loader />;

  return (
    <List sx={{ width: '30rem' }}>
      {settingsList.map((item) => (
        <SettingSelector
          key={item.name}
          title={item.title}
          description={item.description}
          onChange={item.handleToggleChange}
          value={item.checked}
          isLoading={item.isLoading}
          isPremium={item.isPremium}
        />
      ))}
    </List>
  );
}

function RotationPeriodInput({
  value,
  onChange,
  isPremium = false,
  description,
  defaultRotationPeriod = DEFAULT_RESET_PERIOD,
}: {
  value?: number;
  onChange: (val: number) => void;
  isPremium?: boolean;
  description: string;
  defaultRotationPeriod?: number;
}) {
  const [resetCredentialsInput, setResetCredentialsInput] = useState(
    value !== undefined ? value : defaultRotationPeriod,
  );
  const debouncedInput = useDebounce(resetCredentialsInput, 450);
  const prevDebouncedInputRef = useRef(debouncedInput);

  useEffect(() => {
    if (value) setResetCredentialsInput(value);
  }, [value]);

  useEffect(() => {
    if (prevDebouncedInputRef.current !== debouncedInput) {
      onChange(debouncedInput);
      prevDebouncedInputRef.current = debouncedInput;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  const handleOnChange = (val: number) => {
    setResetCredentialsInput(val);
  };

  return (
    <Typography variant="inputLabel" color="text.filter" sx={{ whiteSpace: 'pre-line' }}>
      After period of{' '}
      <Input
        id="filled-number"
        size="small"
        disabled={value === undefined || isPremium}
        type="number"
        value={resetCredentialsInput}
        onChange={(event) => handleOnChange(parseInt(event.target.value))}
        sx={{
          display: 'inline-block',
          width: '50px',
          marginX: '5px',
          padding: 0,
          verticalAlign: 'middle',

          '& .MuiInput-input': {
            padding: '5px 0 !important',
            textAlign: 'center',
          },
        }}
      />{' '}
      {description}
    </Typography>
  );
}
