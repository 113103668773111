import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Chip, Tooltip, Typography } from '@mui/material';

import { UserIdentityAttributeAppModel } from '@api';

import { ATTRIBUTE_FILTER_PARAM } from '@Identities/common/constants';

interface UserListAttributeProps {
  attribute: UserIdentityAttributeAppModel;
}

export default function UserListAttribute({ attribute }: UserListAttributeProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = searchParams.getAll(ATTRIBUTE_FILTER_PARAM).includes(attribute.id);

  const handleOnClick = useCallback(() => {
    const allFilterGrantees = searchParams.getAll(ATTRIBUTE_FILTER_PARAM);

    if (allFilterGrantees.includes(attribute.id)) {
      searchParams.delete(ATTRIBUTE_FILTER_PARAM);
      allFilterGrantees.forEach((attributeId) => {
        if (attributeId !== attribute.id) {
          searchParams.append(ATTRIBUTE_FILTER_PARAM, attributeId);
        }
      });
    } else {
      searchParams.append(ATTRIBUTE_FILTER_PARAM, attribute.id);
    }

    setSearchParams(searchParams);
  }, [attribute.id, searchParams, setSearchParams]);

  const attr = attribute.name.toLowerCase();
  if (attr === 'manager' || attr === 'okta_manager' || attr === 'okta manager') {
    return <Typography variant="body2">{attribute.value}</Typography>;
  }

  return (
    <Tooltip title={`Filter by "${attribute.value.toLowerCase()}"`} placement="top" enterDelay={750} arrow>
      <div>
        <Chip
          label={attribute.value}
          variant={isActive ? 'filled' : 'outlined'}
          size="small"
          clickable
          onClick={handleOnClick}
        />
      </div>
    </Tooltip>
  );
}
