import { Controller, useFormContext } from 'react-hook-form';

import { WebhookUpdateAppModel } from '@api';
import { LabeledSwitchField } from '@components';

export default function StatusField() {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Controller
      control={control}
      name="active"
      render={({ field }) => (
        <LabeledSwitchField
          label="Status"
          switchText={field.value ? 'Active' : 'Inactive'}
          checked={field.value}
          value={field.value}
          onChange={() => field.onChange(!field.value)}
          color="success"
        />
      )}
    />
  );
}
