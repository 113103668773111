import { Page } from '@components';
import { useSession } from '@api';
import { Button, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SuccessIllustration from '@components/SuccessIllustration';

export function GranteePage() {
  const { session } = useSession();

  return (
    <Page title={`Welcome to Apono, ${session?.user?.first_name} 👋`}>
      <GranteePageContent />
    </Page>
  );
}

const GranteePageContent = () => {
  return (
    <Stack alignItems="center">
      <SuccessIllustration />
      <Stack gap={2} textAlign="center" width={380} justifyContent="center">
        <GranteeText />
        {USER_PORTAL_URL && (
          <Button
            component={RouterLink}
            to={USER_PORTAL_URL}
            variant="contained"
            target="_blank"
            sx={{ width: 'fit-content', mx: 'auto' }}
          >
            Go to Portal
          </Button>
        )}
        <GranteeIcons />
      </Stack>
    </Stack>
  );
};

const GranteeText = () => (
  <>
    <Typography variant="h5">{`You're all set!`}</Typography>
    <Typography variant="body1">
      Need access? Read our{' '}
      <Link href={'https://docs.apono.io/docs/requesting-viewing-or-approving-access-in-slack/'} target="_blank">
        documentation
      </Link>{' '}
      and create a new access request.
    </Typography>
  </>
);

const GranteeIcons = () => (
  <Stack flexDirection={'row'} justifyContent={'center'} gap={1}>
    <img src="/static/integrations/teams.svg " alt="teams" width={24} />
    <img src="/static/icons/terminal.svg " alt="terminal" width={24} />
    <img src="/static/integrations/slack.svg " alt="slack" width={24} />
  </Stack>
);
