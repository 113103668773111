import { Box, Paper, styled, Typography } from '@mui/material';
import { Popover } from '@components/Popover';

import { SelectDropdownOption } from './SelectDropdownItem';
import { SelectDropdownSingle, SelectDropdownSingleProps } from './SelectDropdownSingle';
import { SelectDropdownMultiple, SelectDropdownMultipleProps } from './SelectDropdownMultiple';
import { ReactNode } from 'react';

const DropdownContainer = styled(Paper)({
  width: 290,
  maxWidth: '100%',
});

const DropdownTitle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  borderBottom: `1px solid ${theme.palette.border.hover}`,
}));

export type MultipleSelectDropdownProps<T = string, TMultiple = boolean> = TMultiple extends true
  ? { multiple: TMultiple } & SelectDropdownMultipleProps<T>
  : { multiple?: TMultiple } & SelectDropdownSingleProps<T>;

export interface CommonSelectDropdownProps<T> {
  options: SelectDropdownOption<T>[];
  trigger: ReactNode;
  title?: string;
  testId?: string;
}

export type SelectDropdownProps<T = string> = MultipleSelectDropdownProps<T> & CommonSelectDropdownProps<T>;

export function SelectDropdown<T = string>({
  multiple,
  value,
  onChange,
  options,
  trigger,
  title,
  testId,
}: SelectDropdownProps<T>) {
  return (
    <Popover testId={testId || 'select-dropdown'} trigger={trigger}>
      <DropdownContainer>
        {title && (
          <DropdownTitle>
            <Typography variant="caption">{title}</Typography>
          </DropdownTitle>
        )}
        {multiple ? (
          <SelectDropdownMultiple value={value} onChange={onChange} options={options} />
        ) : (
          <SelectDropdownSingle value={value} onChange={onChange} options={options} />
        )}
      </DropdownContainer>
    </Popover>
  );
}
