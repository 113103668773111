import { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import { AnalyticsBrowser, ID, UserTraits } from '@segment/analytics-next';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import { useAuthUser } from '@frontegg/react';
import { routes } from '@routes';
import { useSession } from '@api';
import OpenReplayTracker from '@openreplay/tracker';
import { nanoid } from 'nanoid';

import { CustomAnalyticsEvents } from './events';

const EMAIL_FILTERS = ['@apono.io'];

const PREFIX = 'App';

const analytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_WRITE_KEY ?? '',
});

let openReplayTracker: OpenReplayTracker;

export const OPEN_REPLAY_PROJECT_QUERY_PARAM = 'openReplayProject';
export const OPEN_REPLAY_SESSION_QUERY_PARAM = 'openReplaySession';

const sessionHash = nanoid();

export interface AnalyticsContextProps {
  track: (event: CustomAnalyticsEvents, properties?: object) => void;
  sessionHash: string;
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  track: () => {},
  sessionHash: '',
});

export const useAnalyticsContext = () => useContext(AnalyticsContext);

const wrapEvent = (event: string) => `${PREFIX}: ${event}`;

function useAnalyticsProvider() {
  const { session } = useSession();
  const user = useAuthUser();
  const { pathname } = useLocation();
  const [queryParams] = useSearchParams();

  const openReplayProjectQPKey = queryParams.get(OPEN_REPLAY_PROJECT_QUERY_PARAM);
  const openReplaySessionHash = queryParams.get(OPEN_REPLAY_SESSION_QUERY_PARAM) || sessionHash;

  const isAnalyticsDisabled = EMAIL_FILTERS.some((f) => user.email.includes(f));

  if (!openReplayTracker) {
    openReplayTracker = new OpenReplayTracker({
      projectKey: openReplayProjectQPKey || OPEN_REPLAY_PROJECT_KEY || '',
      __DISABLE_SECURE_MODE: true,
    });
  }

  const track = useCallback(
    (event: string, properties?: object) => {
      if (!isAnalyticsDisabled) analytics?.track(wrapEvent(event), properties);
    },
    [isAnalyticsDisabled],
  );

  const trackPage = useCallback(
    (category: string, name: string) => {
      if (!isAnalyticsDisabled) analytics?.page(category, `${wrapEvent(name)} Page`);
    },
    [isAnalyticsDisabled],
  );

  const trackIdentify = useCallback(
    (id?: object | ID, traits?: UserTraits) => {
      if (!isAnalyticsDisabled) analytics.identify(id, traits);
    },
    [isAnalyticsDisabled],
  );

  useEffect(() => {
    if (!session || !user) return;

    const { account } = session;
    const userId = user.email;
    const userMetadata = {
      name: user.name,
      userId: user.id,
      email: user.email,
      accountId: account.id,
      accountName: account.name,
      plan: account.plan,
    };

    trackIdentify(userId, { ...userMetadata, roles: user.roles });

    if (!isAnalyticsDisabled) {
      openReplayTracker.start({
        userID: userId,
        metadata: userMetadata,
        sessionHash: openReplaySessionHash,
      });
    }

    // We don't want to rerender on sessionHash change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, session, isAnalyticsDisabled, trackIdentify]);

  // Track page view events
  useEffect(() => {
    for (const route of Object.values(routes)) {
      const matchedRoute = matchPath(route.path, pathname);

      if (!matchedRoute || !route.label) continue;

      const paramsKeys = Object.keys(matchedRoute.params).filter((key) => key !== 'id');
      const params = paramsKeys.map((key) => `${key}: ${matchedRoute.params[key]}`);
      if (params.length > 0) {
        trackPage('page visit', `${route.label} (${params.join(', ')})`);
      } else {
        trackPage('page visit', route.label);
      }

      break;
    }
  }, [pathname, track, trackPage]);

  // Track click events
  useEffect(() => {
    const parentElement = document.body;
    const handleClick = (event: Event) => {
      const targetElement = event.target as HTMLElement;

      const triggerElement = targetElement.closest('[data-trigger], [data-analytics-trigger]');

      if (!triggerElement) return;

      const trigger =
        triggerElement.getAttribute('data-trigger') || triggerElement.getAttribute('data-analytics-trigger');
      const metaData = triggerElement.getAttribute('data-props') || triggerElement.getAttribute('data-analytics-props');

      if (!trigger) return;

      let props: undefined | object;
      if (metaData) {
        try {
          props = JSON.parse(metaData);
        } catch (error) {
          console.error('Failed to parse data-analytics-meta', metaData);
        }
      }

      track(trigger, props);
    };

    parentElement.addEventListener('click', handleClick);

    return () => {
      parentElement.removeEventListener('click', handleClick);
    };
  }, [track]);

  return {
    track,
    sessionHash: openReplaySessionHash,
  };
}

export function AnalyticsProvider({ children }: PropsWithChildren) {
  const value = useAnalyticsProvider();
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}
