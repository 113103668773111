import { ReactNode } from 'react';
import { FormControl, FormLabel, MenuItem, Select, Stack, styled, SelectProps, FormHelperText } from '@mui/material';
import slugify from '@sindresorhus/slugify';

const InputLabelWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export interface LabeledSelectFieldOption {
  key: string;
  value: string;
}

export interface LabeledSelectFieldLabelProps {
  label: string;
  subLabel?: ReactNode;
  optional?: boolean;
  testId?: string;
  helperText?: string;
}

export type LabeledSelectFieldProps = SelectProps &
  LabeledSelectFieldLabelProps & {
    options: LabeledSelectFieldOption[];
    loading?: boolean;
  };

export function LabeledSelectField({
  label,
  subLabel,
  optional,
  testId,
  options,
  helperText,
  loading,
  ...rest
}: LabeledSelectFieldProps) {
  const id = rest.id || slugify(label) || testId;
  const required = !optional;

  return (
    <FormControl fullWidth error={rest.error}>
      <InputLabelWrapper direction="row" spacing={0.5} alignItems="center">
        <FormLabel htmlFor={id}>
          {label}
          {!required && ' (optional)'}
        </FormLabel>
        {subLabel}
      </InputLabelWrapper>
      <Select
        {...rest}
        disabled={rest.disabled || loading}
        id={id}
        data-testid={testId}
        required={required}
        size="small"
      >
        {loading && (
          <MenuItem disabled value="">
            <em>Loading...</em>
          </MenuItem>
        )}
        {options.map((f) => (
          <MenuItem key={String(f.key)} value={f.key}>
            {f.value}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
