import { Paper, Stack, styled } from '@mui/material';
import { ReactNode } from 'react';
import { WidgetCardTitle } from './WidgetCardTitle';

interface WidgetCardProps {
  title: string;
  subtitle?: string;
  tooltip?: string;
  button?: ReactNode;
  children?: ReactNode;
  half?: boolean;
}

const ItemContainer = styled(Paper)(({ theme }) => ({
  minHeight: 300,
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: theme.palette.background.active,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 3),

  '&.half': {
    minHeight: 150,
  },
}));

export function WidgetCard({ title, subtitle, tooltip, button, children, half }: WidgetCardProps) {
  return (
    <ItemContainer variant="contained" className={half ? 'half' : ''}>
      <Stack direction="column" spacing={2}>
        <WidgetCardTitle title={title} subtitle={subtitle} tooltip={tooltip} button={button} />
        {children}
      </Stack>
    </ItemContainer>
  );
}
