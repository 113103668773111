import { PropsWithChildren } from 'react';

import { Box, Container, styled } from '@mui/material';

const PageBannerContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(2, 3, 0),
}));

export function PageBanner({ children }: PropsWithChildren<unknown>) {
  return (
    <PageBannerContainer>
      <Container maxWidth={false}>{children}</Container>
    </PageBannerContainer>
  );
}
