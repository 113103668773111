import { IntegrationAppModel, IntegrationConfigAppModelV2, useUpdateIntegrationV2 } from '@api';
import { ConfigFormHeader } from '@components';
import { Stack, Typography } from '@mui/material';
import {
  SelectResourceTypes,
  useGetAllowedResourceTypes,
} from '@organisms/IntegrationConnectorForm/SelectResourceTypes';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IntegrationConfigForm } from '@Integrations/organisms/IntegrationConfigForm/IntegrationConfigForm';

export const SYNC_PARAM = 'sync';

export interface EditConnectedIntegrationProps {
  integration: IntegrationAppModel;
  config: IntegrationConfigAppModelV2;
}

function useEditConnectedIntegration({ integration }: Pick<EditConnectedIntegrationProps, 'integration'>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { updateIntegration, isUpdateIntegrationLoading, isIntegrationUpdated } = useUpdateIntegrationV2(
    integration.id,
  );

  const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[]>(
    integration.connected_resource_types || [],
  );

  useMemo(() => {
    if (isIntegrationUpdated) {
      searchParams.set(SYNC_PARAM, 'true');
      setSearchParams(searchParams);
    }
  }, [isIntegrationUpdated, searchParams, setSearchParams]);

  return {
    updateIntegration,
    isUpdateIntegrationLoading,
    selectedResourceTypes,
    setSelectedResourceTypes,
  };
}

export function EditConnectedIntegration({ integration, config }: EditConnectedIntegrationProps) {
  const { updateIntegration, selectedResourceTypes, setSelectedResourceTypes, isUpdateIntegrationLoading } =
    useEditConnectedIntegration({
      integration,
    });

  const { allowedResourceTypes } = useGetAllowedResourceTypes(config);

  return (
    <>
      <ConfigFormHeader
        title="Edit Integration"
        description="Add more resource types and edit the integration config, like credentials, secret, etc."
      />
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={6}>
        {allowedResourceTypes.length > 1 && (
          <SelectResourceTypes
            config={config}
            connected={integration.connected_resource_types}
            selected={selectedResourceTypes}
            onSelect={setSelectedResourceTypes}
          />
        )}
        <Stack direction="column" justifyContent="center" spacing={4}>
          <Typography variant="subtitle1">Edit the config:</Typography>
          <IntegrationConfigForm
            config={config}
            integration={integration}
            submitButtonText="Update"
            onSubmit={updateIntegration}
            selectedResourceTypes={selectedResourceTypes}
            loading={isUpdateIntegrationLoading}
          />
        </Stack>
      </Stack>
    </>
  );
}
