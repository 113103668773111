import { FormControl, MenuItem, Select, Stack, styled, Tooltip, Typography } from '@mui/material';

import { Dot, MaterialIcon } from '@components';

const SelectConnectorContainer = styled(FormControl)({
  width: '320px',
});

export const NEW_CONNECTOR_VALUE = '__new__';

export interface SelectConnectorProps {
  options: {
    value: string;
    label?: string;
    version?: string;
    status?: string;
  }[];
  connectorId?: string;
  onSelect: (connectorId: string) => void;
  withDescription?: boolean;
}

export function SelectConnector({ options, connectorId, onSelect, withDescription = true }: SelectConnectorProps) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="subtitle1">Select an Apono Connector</Typography>
        {withDescription && (
          <Typography variant="inputLabel" color="text.filter" textAlign="center">
            The Apono connector separates the Apono app from your environment for security purposes.
          </Typography>
        )}
      </Stack>
      <SelectConnectorContainer size="small">
        <Select
          value={connectorId || ''}
          onChange={(e) => onSelect(e.target.value)}
          renderValue={(selected) => options.find((option) => option.value === selected)?.label}
          fullWidth
          data-testid="select-connector"
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} data-testid="select-connector-option">
              <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center" spacing={1}>
                <Typography variant="body2" width="80%" noWrap>
                  {option.label}
                </Typography>
                {option.status && (
                  <Tooltip title={option.status === 'connected' ? 'Connected' : 'Disconnected'} placement="right" arrow>
                    <div>
                      <Dot color={option.status === 'connected' ? 'success' : undefined} size="small" />
                    </div>
                  </Tooltip>
                )}
                {option.version && (
                  <Typography variant="body2" color="text.muted">
                    {option?.version}
                  </Typography>
                )}
              </Stack>
            </MenuItem>
          ))}
          <MenuItem value={NEW_CONNECTOR_VALUE}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
              <MaterialIcon symbol="add" color="primary" fontSize="small" />
              <Typography variant="buttonMedium" color="primary" component="div">
                Add new connector
              </Typography>
            </Stack>
          </MenuItem>
        </Select>
      </SelectConnectorContainer>
    </Stack>
  );
}
