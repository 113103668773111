import { RadioGroup, Stack, Typography } from '@mui/material';

import { RadioBox, RadioBoxLabelComplex } from '@components';
import { PlatformType } from './types';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

interface SelectPlatformProps {
  platform?: PlatformType;
  onSelect: (platform: PlatformType) => void;
}

function useSelectPlatform(onSelect: SelectPlatformProps['onSelect']) {
  const { track } = useAnalyticsContext();
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    track(CustomAnalyticsEvents.ADD_CONNECTOR_PLATFORM_SELECTED, {
      addConnectorPlatform: event.target.value,
    });
    onSelect(event.target.value as PlatformType);
  };

  return {
    handleSelect,
  };
}

export function SelectPlatform({ platform, onSelect }: SelectPlatformProps) {
  const { handleSelect } = useSelectPlatform(onSelect);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="subtitle1" component="div">
        Select platform
      </Typography>
      <RadioGroup value={platform || ''} onChange={handleSelect}>
        <Stack direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
          <RadioBox
            value={PlatformType.Aws}
            label={<RadioBoxLabelComplex iconPath="/static/applications/aws.svg" title="AWS" description="Amazon" />}
            labelPlacement="top"
            sx={{ width: '175px' }}
          />
          <RadioBox
            value={PlatformType.Gcp}
            label={<RadioBoxLabelComplex iconPath="/static/applications/gcp.svg" title="GCP" description="Google" />}
            labelPlacement="top"
            sx={{ width: '175px' }}
          />
          <RadioBox
            value={PlatformType.K8s}
            label={
              <RadioBoxLabelComplex
                iconPath="/static/applications/kubernetes.svg"
                title="Kubernetes"
                description="Custom"
              />
            }
            labelPlacement="top"
            sx={{ width: '175px' }}
          />
          <RadioBox
            value={PlatformType.Azure}
            label={
              <RadioBoxLabelComplex iconPath="/static/applications/azure.svg" title="Azure" description="Microsoft" />
            }
            labelPlacement="top"
            sx={{ width: '175px' }}
          />
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
