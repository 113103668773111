import { Checkbox, FormControlLabel, Stack, TextField, Typography, styled } from '@mui/material';
import { memo, useEffect } from 'react';

const BarWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export interface TreeFilterBarProps {
  filter: string;
  onFilterChange: (filter: string) => void;
  selectedCount?: number;
  filteredCount?: number;
  totalCount?: number;
  showSelected?: boolean;
  onChangeShowSelected?: () => void;
  onSelectToggle?: () => void;
}

const TreeFilterBar = memo(function TreeFilterBar({
  filter,
  onFilterChange,
  selectedCount,
  filteredCount,
  totalCount,
  showSelected,
  onChangeShowSelected,
  onSelectToggle,
}: TreeFilterBarProps) {
  useEffect(() => {
    if (selectedCount === 0 && showSelected) {
      onChangeShowSelected?.();
    }
  }, [onChangeShowSelected, selectedCount, showSelected]);

  const allSelected = filteredCount === selectedCount && selectedCount !== 0;

  return (
    <BarWrapper>
      <Stack direction="column" spacing={2}>
        <TextField
          type="search"
          data-testid="app-drawer-search-input"
          size="small"
          fullWidth
          variant="outlined"
          value={filter}
          onChange={(e) => onFilterChange(e.target.value)}
          placeholder="Search"
        />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" justifyContent="flex-start" spacing={2}>
            {totalCount && (
              <Typography variant="helperText" color="text.muted">
                {filteredCount !== undefined && filteredCount < totalCount ? (
                  <>
                    Showing {filteredCount} of {totalCount} resources
                  </>
                ) : (
                  <>{totalCount} resources</>
                )}
              </Typography>
            )}
            {onSelectToggle && (
              <FormControlLabel
                disabled={totalCount === 0}
                control={<Checkbox checked={allSelected} size="small" sx={{ padding: 0, marginRight: 1 }} />}
                onChange={onSelectToggle}
                label={
                  <Typography variant="helperText" color="text.muted">
                    {allSelected ? 'Deselect' : 'Select'} all
                  </Typography>
                }
              />
            )}
          </Stack>
          {onChangeShowSelected && (
            <FormControlLabel
              disabled={selectedCount === 0}
              onChange={onChangeShowSelected}
              control={<Checkbox checked={showSelected} size="small" sx={{ padding: 0, marginRight: 1 }} />}
              label={
                <Typography variant="helperText" color="text.muted">
                  Show {selectedCount} selected
                </Typography>
              }
            />
          )}
        </Stack>
      </Stack>
    </BarWrapper>
  );
});

export default TreeFilterBar;
