import { ConnectorsService, Integration, IntegrationsService } from '@api';
import { useAsync, useAsyncState } from '@hooks';
import { useEffect, useState } from 'react';

import { CatalogStatusesState } from '@state/catalog-statuses.state';
import { integrationsConfigsSelector } from '@state/integrations.state';
import sortBy from 'lodash/sortBy';
import { useInterval } from 'usehooks-ts';

// **** IMPORTANT ****
// Do not import @routes here! It, for some reason, messes up HMR of vite and webpack.
// Work around for now, hardcoded paths.
// **** IMPORTANT ****

const routes = {
  AddAwsCloud: {
    path: '/connectors/install',
  },
  AddGcpCloud: {
    path: '/connectors/install',
  },
  InstallConnector: {
    path: '/connectors/install',
  },
};

type IntegrationConnectionType = {
  label: string;
  placeholder: string;
  values: Array<{ id: string; label: string; md?: object }>;
};

type IntegrationConnectionConfig = { [k: string]: IntegrationConnectionType };

export function useIntegrationsCatalog() {
  const { value: result, isLoading, reload } = useAsyncState(integrationsConfigsSelector, null);

  return {
    isLoading,
    catalog: result,
    execute: reload,
  };
}

export function useIntegration(type: string) {
  const { isLoading, result } = useAsync(
    async () => {
      const integrationConfig = await IntegrationsService.getIntegrationConfig({
        type,
      });

      return {
        config: integrationConfig,
      };
    },
    [type],
    {
      executeOnMount: true,
    },
  );

  return {
    isLoadingIntegration: isLoading,
    integrationConfig: result?.config,
  };
}

export function useUpdateResourceIntegration(resourceRequest: Integration) {
  const {
    execute: updateResource,
    isLoading: updateInProgress,
    hasSucceeded: resourceUpdatedSuccessfully,
  } = useAsync(
    () =>
      IntegrationsService.updateIntegrationV2({
        id: resourceRequest.id,
        updateIntegration: {
          name: resourceRequest.name,
          provisioner_id: resourceRequest.provisioner_id as string, //TODO - fix api
          metadata: resourceRequest.metadata,
          secret_config: resourceRequest.secret_config,
        },
      }),
    [resourceRequest.id, resourceRequest],
    {
      executeOnUpdate: false,
    },
  );

  return {
    execute: updateResource,
    inProgress: updateInProgress,
    doneSuccessfully: resourceUpdatedSuccessfully,
  };
}

export function useCreateResourceIntegration(resourceRequest: Integration) {
  const {
    execute: createResource,
    isLoading: creationInProgress,
    hasSucceeded: resourceCreatedSuccessfully,
  } = useAsync(
    () =>
      IntegrationsService.createIntegrationV2({
        createIntegration: {
          name: resourceRequest.name,
          provisioner_id: resourceRequest.provisioner_id,
          type: resourceRequest.type,
          metadata: resourceRequest.metadata,
          secret_config: resourceRequest.secret_config,
        },
      }),
    [
      resourceRequest.metadata,
      resourceRequest.name,
      resourceRequest.provisioner_id,
      resourceRequest.secret_config,
      resourceRequest.type,
    ],
    { executeOnUpdate: false },
  );

  return {
    execute: createResource,
    inProgress: creationInProgress,
    doneSuccessfully: resourceCreatedSuccessfully,
  };
}

export function useResourceIntegration(id: string) {
  const {
    execute: getResourceIntegration,
    isLoading: isLoadingResourceIntegration,
    result: resourceIntegration,
    hasSucceeded: resourceIntegrationLoaded,
  } = useAsync(async () => IntegrationsService.getIntegrationV2({ id }), [id], {
    executeOnMount: true,
  });

  return {
    getResourceIntegration,
    resourceIntegration,
    isLoadingResourceIntegration,
    resourceIntegrationLoaded,
  };
}

export function useConnectedAWS() {
  const {
    isLoading: isLoadingAWSAccounts,
    result: connectedAWSAccounts,
    hasSucceeded: AWSAccountsLoaded,
  } = useAsync(
    async () => {
      const response = await IntegrationsService.listAccountsWithConnectors();
      // this is done because the API returns an array of objects with all optional properties
      return response.map((connectedAWS) => ({
        ...connectedAWS,
        md: connectedAWS.extra_metadata,
      }));
    },
    [],
    { executeOnMount: true },
  );
  return { connectedAWSAccounts, isLoadingAWSAccounts, AWSAccountsLoaded };
}

export function useConnectedAzure() {
  const {
    isLoading: isLoadingAzureAccounts,
    result: connectedAzureAccounts,
    hasSucceeded: AzureAccountsLoaded,
  } = useAsync(
    async () => {
      const response = await IntegrationsService.listAzureIntegrations();
      // this is done because the API returns an array of objects with all optional properties
      return response.map((connectedAzure) => ({
        ...connectedAzure,
      }));
    },
    [],
    { executeOnMount: true },
  );
  return {
    connectedAzureAccounts,
    isLoadingAzureAccounts,
    AzureAccountsLoaded,
  };
}

export function useConnectors() {
  const {
    isLoading: isLoadingConnectors,
    result: connectors,
    hasSucceeded: connectorsLoaded,
    execute: reloadConnectors,
  } = useAsync(
    async () => {
      const connectorsService = await ConnectorsService.listAgents();
      return sortBy(connectorsService, (connector) => connector.status);
    },
    [],
    { executeOnMount: true },
  );
  return {
    connectors: connectors || [],
    isLoadingConnectors,
    connectorsLoaded,
    reloadConnectors,
  };
}

export const useGcpIntegrations = () => {
  const {
    result: gcpIntegrations,
    hasSucceeded: gcpIntegrationsLoaded,
    isLoading: isLoadingGcpIntegrations,
  } = useAsync(
    async () => {
      const response = await IntegrationsService.listGcpIntegrations();
      // this is done because the API returns an array of objects with all optional properties
      return response.map((c) => ({
        connector_id: c.connector_id,
        organization_id: c.organization_id,
        project_id: c.project_id,
        region: c.region,
        zone: c.zone,
      }));
    },
    [],
    { executeOnMount: true },
  );

  return {
    gcpIntegrationsLoaded,
    gcpIntegrations,
    isLoadingGcpIntegrations,
  };
};

export function useIntegrationConnectionConfig() {
  const { connectors, isLoadingConnectors, connectorsLoaded } = useConnectors();
  const { connectedAWSAccounts, isLoadingAWSAccounts, AWSAccountsLoaded } = useConnectedAWS();
  const { gcpIntegrations, isLoadingGcpIntegrations, gcpIntegrationsLoaded } = useGcpIntegrations();
  const { connectedAzureAccounts, isLoadingAzureAccounts, AzureAccountsLoaded } = useConnectedAzure();

  const [integrationConnectionConfig, setIntegrationConnectionConfig] = useState<IntegrationConnectionConfig>({});

  const installConnectorOption = (cloudType?: string) => {
    return {
      id: routes.AddAwsCloud.path + (cloudType ? `?cloudType=${cloudType}` : ''),
      label: '+ Install Connector',
    };
  };

  useEffect(() => {
    if (connectedAWSAccounts && connectors && gcpIntegrations && connectedAzureAccounts) {
      setIntegrationConnectionConfig({
        ...integrationConnectionConfig,
        'cloud-aws': {
          label: 'Cloud Account',
          placeholder: 'Choose Cloud Account',
          values: [
            ...connectedAWSAccounts.map((value) => {
              const connector = connectors.find((currentConnector) => currentConnector.agent_id == value.connector_id);
              return {
                id: JSON.stringify({
                  connector_id: value.connector_id,
                  cloud_account: value.aws_account_id,
                }),
                label: `${value.aws_account_id} (${connector?.name || value.connector_id})`,
                md: value.md,
              };
            }),
            installConnectorOption('aws'),
          ],
        },
        'cloud-gcp': {
          label: 'Cloud Account',
          placeholder: 'Choose Cloud Account',
          values: [
            ...gcpIntegrations.map((value) => {
              const connector = connectors.find((currentConnector) => currentConnector.agent_id == value.connector_id);
              return {
                id: JSON.stringify({
                  connector_id: value.connector_id,
                  cloud_account: value.organization_id,
                }),
                label: `${value.organization_id} (${connector?.name || value.connector_id})`,
              };
            }),
            installConnectorOption('gcp'),
          ],
        },
        'cloud-azure': {
          label: 'Cloud Account',
          placeholder: 'Choose Cloud Account',
          values: [
            ...connectedAzureAccounts.map((value) => {
              const connector = connectors.find((currentConnector) => currentConnector.agent_id == value.connector_id);
              return {
                id: JSON.stringify({
                  connector_id: value.connector_id,
                  cloud_account: value.subscription_id,
                }),
                label: `${value.subscription_id} (${connector?.name || value.connector_id})`,
              };
            }),
            installConnectorOption('azure'),
          ],
        },
        k8s: {
          label: 'Select Connector',
          placeholder: 'Select Connection',
          values: [
            ...connectors.map((connector) => ({
              id: JSON.stringify({
                connector_id: connector.agent_id,
                cloud_account: null,
              }),
              label: connector.name || connector.agent_id,
            })),
            installConnectorOption('kubernetes'),
          ],
        },
        custom: {
          label: 'Select Connector',
          placeholder: 'Select Connection',
          values: [
            ...connectors.map((connector) => ({
              id: JSON.stringify({
                connector_id: connector.agent_id,
                cloud_account: null,
              }),
              label: connector.name || connector.agent_id,
            })),
            installConnectorOption(),
          ],
        },
        none: {
          label: '',
          placeholder: '',
          values: [],
        },
        oauth: {
          label: '',
          placeholder: '',
          values: [],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedAWSAccounts, connectors, gcpIntegrations, connectedAzureAccounts]);

  const getConnectorAccount = (provisioner_id: string) => {
    const connector = connectors?.find((currentConnector) => currentConnector.agent_id === provisioner_id);
    const connectedAccount = connectedAWSAccounts?.find((a) => a.connector_id === connector?.agent_id);
    return connectedAccount?.aws_account_id;
  };
  return {
    integrationConnectionConfig,
    connectors,
    getConnectorAccount,
    isLoading: isLoadingConnectors && isLoadingAWSAccounts && isLoadingGcpIntegrations && isLoadingAzureAccounts,
    hasLoaded: gcpIntegrationsLoaded && connectorsLoaded && AWSAccountsLoaded && AzureAccountsLoaded,
  };
}

export function useIntegrationCatalogStatuses() {
  const { value, reload, ...asyncValueState } = useAsyncState(CatalogStatusesState, {
    summary: [],
    aggregated_status: [],
  });

  useInterval(() => reload(), 10000);

  return {
    ...asyncValueState,
    summary: value.summary,
    aggregated_status: value.aggregated_status,
  };
}
