import { useMemo } from 'react';

import { useGetCatalogV2 } from '@api';
import { useRequiredParams } from '@hooks';
import { Loader } from '@components';

import { ConnectIntegrationsGroup } from './ConnectIntegrationsGroup/ConnectIntegrationsGroup';
import { ConnectIntegrationContentProps } from './ConnectIntegrationContent';

function useConnectIntegrationsGroupContent() {
  const { group } = useRequiredParams(['group']);
  const { integrationsConfigs, isIntegrationsConfigsFetched } = useGetCatalogV2();

  const isFetched = useMemo(() => isIntegrationsConfigsFetched, [isIntegrationsConfigsFetched]);

  return {
    isFetched,
    group,
    configs: integrationsConfigs,
  };
}

export function ConnectIntegrationsGroupContent({ paths, align }: ConnectIntegrationContentProps) {
  const { isFetched, group, configs } = useConnectIntegrationsGroupContent();

  if (!isFetched) return <Loader />;
  return <ConnectIntegrationsGroup group={group} configs={configs} paths={paths} align={align} />;
}
