import { ButtonBase, InputAdornment, ListItem, OutlinedInput, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export interface InteractiveDropdownFilterInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onAddClick?: () => void;
}

export function InteractiveDropdownFilterInput({
  value,
  onChange,
  placeholder,
  onAddClick,
}: InteractiveDropdownFilterInputProps) {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && onAddClick) {
      onAddClick();
    }
  };

  return (
    <ListItem component="div">
      <OutlinedInput
        data-testid="interactive-dropdown-filter-input"
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        endAdornment={
          <InputAdornment position="end">
            {onAddClick ? (
              <ButtonBase data-testid="interactive-dropdown-chips-btn" onClick={onAddClick}>
                <Typography color="primary" fontSize="small">
                  Add
                </Typography>
              </ButtonBase>
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        }
        fullWidth
      />
    </ListItem>
  );
}
