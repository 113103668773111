import {
  Configuration,
  AccessFlowsApi,
  AccessRequestsApi,
  AccountsApi,
  ConnectorsApi,
  DashboardApi,
  FeatureFlagsApi,
  IntegrationsApi,
  SearchApi,
  SessionApi,
  UsersApi,
  ActivityApi,
  ActiveAccessApi,
  WebhooksApi,
  AttributesApi,
} from './apn-core';
import { GraphQLClient } from 'graphql-request';

const graphqlClient = new GraphQLClient('/apn/frontier/api/v1/graphql');

let ACCESS_TOKEN: string | null = null;
export const setAccessToken = (token: string) => {
  ACCESS_TOKEN = token;
  graphqlClient.setHeader('authorization', `Bearer ${token}`);
};

const basePath = '';
export const axiosConfig = new Configuration({
  basePath,
  accessToken: () => ACCESS_TOKEN || '',
});

export const AccessFlowsService = new AccessFlowsApi(axiosConfig, basePath);
export const AccessRequestsService = new AccessRequestsApi(axiosConfig, basePath);
export const AccountsService = new AccountsApi(axiosConfig, basePath);
export const ConnectorsService = new ConnectorsApi(axiosConfig, basePath);
export const DashboardService = new DashboardApi(axiosConfig, basePath);
export const FeatureFlagsService = new FeatureFlagsApi(axiosConfig, basePath);
export const IntegrationsService = new IntegrationsApi(axiosConfig, basePath);
export const SearchService = new SearchApi(axiosConfig, basePath);
export const SessionService = new SessionApi(axiosConfig, basePath);
export const UsersService = new UsersApi(axiosConfig, basePath);
export const ActivityService = new ActivityApi(axiosConfig, basePath);
export const ActiveAccessService = new ActiveAccessApi(axiosConfig, basePath);
export const WebhooksService = new WebhooksApi(axiosConfig, basePath);
export const AttributesService = new AttributesApi(axiosConfig, basePath);
export const FrontierService = graphqlClient;
