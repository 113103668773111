import { AccessUnitStatus, ActivityRequestAppModel, TriggerTypeAppModel } from '@api';
import { EmptyState, Loader } from '@components';
import { Badge, Stack, Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import RequestAccessUnits from './RequestAccessUnits';
import RequestAccessUnitsFiltered from './RequestAccessUnitsFiltered';
import { AccessUnitsFailedStatuses, TabValues, determineGrantedOrRevokedTab } from './consts';
import { useGetActivityRequestAccessUnits } from './query';

function ErrorBadge({ hasErrors }: { hasErrors: boolean }) {
  return (
    <Badge color="error" variant="dot" invisible={!hasErrors} sx={{ '& .MuiBadge-badge': { right: -4 } }}>
      Errors
    </Badge>
  );
}

function UserRequestAccessUnitsTabs({ request, hasErrors }: { request: ActivityRequestAppModel; hasErrors: boolean }) {
  const grantedOrRevokedTab = determineGrantedOrRevokedTab(request);

  const [tab, setTab] = useState(hasErrors ? TabValues.Errors : grantedOrRevokedTab);

  return (
    <Stack direction="column" spacing={2} pb={2}>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab label={grantedOrRevokedTab === TabValues.Granted ? 'Granted' : 'Revoked'} value={grantedOrRevokedTab} />
        <Tab label={<ErrorBadge hasErrors={hasErrors} />} value={TabValues.Errors} />
      </Tabs>
      {tab === TabValues.Granted && (
        <RequestAccessUnitsWithFilters
          request={request}
          statuses={[AccessUnitStatus.Granted, AccessUnitStatus.Pending]}
        />
      )}
      {tab === TabValues.Revoked && (
        <RequestAccessUnitsWithFilters
          request={request}
          statuses={[AccessUnitStatus.Revoked, AccessUnitStatus.Pending]}
        />
      )}
      {tab === TabValues.Errors && <FailedRequestAccessUnits request={request} hasErrors={hasErrors} />}
    </Stack>
  );
}

function AutoGrantAccessUnitsTabs({ request, hasErrors }: { request: ActivityRequestAppModel; hasErrors: boolean }) {
  const [tab, setTab] = useState(hasErrors ? TabValues.Errors : TabValues.Granted);

  return (
    <Stack direction="column" spacing={2} pb={2}>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab label="Granted" value={TabValues.Granted} />
        <Tab label="Revoked" value={TabValues.Revoked} />
        <Tab label={<ErrorBadge hasErrors={hasErrors} />} value={TabValues.Errors} />
      </Tabs>
      {tab === TabValues.Granted && (
        <RequestAccessUnitsWithFilters
          request={request}
          statuses={[AccessUnitStatus.Granted, AccessUnitStatus.Pending]}
        />
      )}
      {tab === TabValues.Revoked && (
        <RequestAccessUnitsWithFilters request={request} statuses={[AccessUnitStatus.Revoked]} />
      )}
      {tab === TabValues.Errors && <FailedRequestAccessUnits request={request} hasErrors={hasErrors} />}
    </Stack>
  );
}

function RequestAccessUnitsWithFilters({
  request,
  statuses,
}: {
  request: ActivityRequestAppModel;
  statuses: string[];
}) {
  return (
    <RequestAccessUnitsFiltered {...{ request, statuses }}>
      <RequestAccessUnits {...{ request, statuses }} />
    </RequestAccessUnitsFiltered>
  );
}

function FailedRequestAccessUnits({ request, hasErrors }: { request: ActivityRequestAppModel; hasErrors: boolean }) {
  if (!hasErrors) {
    return (
      <EmptyState
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
        title="No results found"
        body="Try different search terms or remove filters"
      />
    );
  }

  return (
    <RequestAccessUnitsFiltered
      request={request}
      statuses={[AccessUnitStatus.FailedRevoking, AccessUnitStatus.Failure, AccessUnitStatus.FailedGranting]}
    >
      <RequestAccessUnits
        request={request}
        withLoader={false}
        withEmptyState={false}
        statuses={[AccessUnitStatus.FailedRevoking]}
        defaultExpanded
      />
      <RequestAccessUnits
        request={request}
        withEmptyState={false}
        statuses={[AccessUnitStatus.Failure, AccessUnitStatus.FailedGranting]}
        defaultExpanded
      />
    </RequestAccessUnitsFiltered>
  );
}

export default function RequestAccessUnitsWrapper({ request }: { request: ActivityRequestAppModel }) {
  // get failed access units to show the error tab

  const { data, isFetched } = useGetActivityRequestAccessUnits({
    id: request.id,
    status: AccessUnitsFailedStatuses,
    limit: 1,
  });
  const hasErrors = useMemo(() => (data?.pages.flatMap((page) => page.data).length ?? 0) > 0, [data]);

  return !isFetched ? (
    <Loader absolute={false} />
  ) : request.trigger_type === TriggerTypeAppModel.UserRequest ? (
    <UserRequestAccessUnitsTabs request={request} hasErrors={hasErrors} />
  ) : (
    <AutoGrantAccessUnitsTabs request={request} hasErrors={hasErrors} />
  );
}
