import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import {
  BUNDLE_QUERY_PARAM,
  EDIT_BUNDLE_QUERY_PARAM,
  FROM_ACCESS_FLOW_QUERY_PARAM,
  NEW_ACCESS_FLOW_QUERY_PARAM_VALUE,
  WITH_BUNDLE_QUERY_PARAM,
} from '@AccessFlows/common/constants';
import { routes } from '@routes';

export default function useHandleBundleUpdate() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (accessBundleId: string) => {
    const fromAccessFlow = searchParams.get(FROM_ACCESS_FLOW_QUERY_PARAM);
    if (!fromAccessFlow) {
      navigate(routes.Bundles.path, { replace: true });
      return;
    }

    let accessFlowFormUrl = routes.AddAccessFlow.path;
    if (fromAccessFlow !== NEW_ACCESS_FLOW_QUERY_PARAM_VALUE) {
      accessFlowFormUrl = generatePath(routes.EditAccessFlow.path, { id: fromAccessFlow });
    }

    const editLinkQP = new URLSearchParams();
    if (searchParams.has(EDIT_BUNDLE_QUERY_PARAM)) {
      editLinkQP.set(BUNDLE_QUERY_PARAM, accessBundleId);
    } else {
      editLinkQP.set(WITH_BUNDLE_QUERY_PARAM, accessBundleId);
    }

    navigate(`${accessFlowFormUrl}?${editLinkQP.toString()}`, { replace: true });
  };
}
