import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ListFilters from '@components/ListFilters/ListFilters';
import { CustomAnalyticsEvents } from '@utils/analytics';
import {
  ALERT_TYPE_FILTER_LABEL,
  ALERT_TYPE_FILTER_PARAM,
  AlertTypeFilter,
} from '@AccessAlerts/organisms/AlertTypeFilter';
import {
  AlertSeverityFilter,
  SEVERITY_FILTER_LABEL,
  SEVERITY_FILTER_PARAM,
} from '@AccessAlerts/organisms/AlertSeverityFilter';
import { DateFilter } from '@common/organisms/CommonFilters/DateFilter';
import { USER_FILTER_LABEL, AlertUserFilter, USER_FILTER_PARAM } from './AlertUserFilter';
import { AlertIntegrationFilter, INTEGRATION_FILTER_LABEL, INTEGRATION_FILTER_PARAM } from './AlertIntegrationFilter';
import {
  AlertResourceTypeFilter,
  RESOURCE_TYPE_FILTER_LABEL,
  RESOURCE_TYPE_FILTER_PARAM,
} from './AlertResourceTypeFilter';

const DEFAULT_VISIBLE_FILTERS = [ALERT_TYPE_FILTER_PARAM, SEVERITY_FILTER_PARAM, RESOURCE_TYPE_FILTER_PARAM];

export default function AccessAlertsFilters() {
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const selected = useMemo(() => {
    return {
      ALERT_TYPE_FILTER_PARAM: searchParams.getAll(ALERT_TYPE_FILTER_PARAM) || [],
      SEVERITY_FILTER_PARAM: searchParams.getAll(SEVERITY_FILTER_PARAM) || [],
      USER_FILTER_PARAM: searchParams.getAll(USER_FILTER_PARAM) || [],
      INTEGRATION_FILTER_PARAM: searchParams.getAll(INTEGRATION_FILTER_PARAM) || [],
      RESOURCE_TYPE_FILTER_PARAM: searchParams.getAll(RESOURCE_TYPE_FILTER_PARAM) || [],
    };
  }, [searchParams]);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const applySearchParams = useCallback(
    (newSearchParams: URLSearchParams) => {
      setSearchParams(newSearchParams);
    },
    [setSearchParams],
  );

  const onSelect = useCallback(
    ({ values, filterParam }: { values: string[]; filterParam: string }) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(filterParam);
      for (const value of values) {
        newSearchParams.append(filterParam, value);
      }
      applySearchParams(newSearchParams);
    },
    [applySearchParams, searchParams],
  );

  const onClear = useCallback(
    (filterParam: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(filterParam);
      applySearchParams(newSearchParams);
    },
    [applySearchParams, searchParams],
  );

  const filters = useMemo(
    () => [
      {
        name: ALERT_TYPE_FILTER_LABEL,
        queryParam: ALERT_TYPE_FILTER_PARAM,
        element: (
          <AlertTypeFilter
            opened={openedFilter === ALERT_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(ALERT_TYPE_FILTER_PARAM)}
            selected={selected.ALERT_TYPE_FILTER_PARAM}
            onClear={() => onClear(ALERT_TYPE_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: ALERT_TYPE_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_ALERT_TYPE_FILTER_APPLIED}
          />
        ),
      },
      {
        name: SEVERITY_FILTER_LABEL,
        queryParam: SEVERITY_FILTER_PARAM,
        element: (
          <AlertSeverityFilter
            opened={openedFilter === SEVERITY_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(SEVERITY_FILTER_PARAM)}
            selected={selected.SEVERITY_FILTER_PARAM}
            onClear={() => onClear(SEVERITY_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: SEVERITY_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_SEVERITY_FILTER_APPLIED}
          />
        ),
      },
      {
        name: USER_FILTER_LABEL,
        queryParam: USER_FILTER_PARAM,
        element: (
          <AlertUserFilter
            opened={openedFilter === USER_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(USER_FILTER_PARAM)}
            selected={selected.USER_FILTER_PARAM}
            onClear={() => onClear(USER_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: USER_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_USER_FILTER_APPLIED}
          />
        ),
      },
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_FILTER_PARAM,
        element: (
          <AlertIntegrationFilter
            opened={openedFilter === INTEGRATION_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INTEGRATION_FILTER_PARAM)}
            selected={selected.INTEGRATION_FILTER_PARAM}
            onClear={() => onClear(INTEGRATION_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: INTEGRATION_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_INTEGRATION_FILTER_APPLIED}
          />
        ),
      },
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_FILTER_PARAM,
        element: (
          <AlertResourceTypeFilter
            opened={openedFilter === RESOURCE_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_TYPE_FILTER_PARAM)}
            selected={selected.RESOURCE_TYPE_FILTER_PARAM}
            onClear={() => onClear(RESOURCE_TYPE_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: RESOURCE_TYPE_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_RESOURCE_TYPE_FILTER_APPLIED}
          />
        ),
      },
    ],
    [onClear, onSelect, openedFilter, selected],
  );

  const [visibleFilters, setVisibleFilters] = useState<string[]>(() => {
    const searchParamsVisibleFilters = [];
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam) && !DEFAULT_VISIBLE_FILTERS.includes(filter.queryParam)) {
        searchParamsVisibleFilters.push(filter.queryParam);
      }
    }

    return [...DEFAULT_VISIBLE_FILTERS, ...searchParamsVisibleFilters];
  });

  useEffect(() => {
    const filtersFromQP: string[] = [];
    for (const filterParam of searchParams.keys()) {
      if (!visibleFilters.includes(filterParam)) {
        filtersFromQP.push(filterParam);
      }
    }

    const stillVisibleFilters: string[] = [];
    for (const visibleFilter of visibleFilters) {
      if (
        searchParams.has(visibleFilter) ||
        DEFAULT_VISIBLE_FILTERS.includes(visibleFilter) ||
        visibleFilter === openedFilter
      ) {
        stillVisibleFilters.push(visibleFilter);
      }
    }

    if (filtersFromQP.length > 0 || stillVisibleFilters.length !== visibleFilters.length) {
      setVisibleFilters([...stillVisibleFilters, ...filtersFromQP]);
    }
  }, [openedFilter, searchParams, visibleFilters]);

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    setSearchParams(searchParams);
    setVisibleFilters(DEFAULT_VISIBLE_FILTERS);
  }, [filters, searchParams, setSearchParams]);

  const handleOnOnAddFilter = (qp: string) => {
    setVisibleFilters((prev) => [...prev, qp]);
    setOpenedFilter(qp);
  };

  return (
    <ListFilters
      visibleFilters={visibleFilters}
      filters={filters}
      onClear={hasActiveFilters ? handleOnClear : undefined}
      onAddFilter={handleOnOnAddFilter}
      prefix={<DateFilter />}
    />
  );
}
