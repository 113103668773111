import { ReactNode } from 'react';
import { Button, Card, CardContent, Stack } from '@mui/material';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { AnalyticsTriggerElementProps } from '@common/types';

export function DrawerItemCard({
  title,
  icon,
  onClick,
  subtitle,
  actionText,
  ...analyticsProps
}: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  subtitle?: string;
  actionText?: string;
} & AnalyticsTriggerElementProps) {
  return (
    <Card sx={{ border: 0, borderRadius: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <PrimarySecondary primary={title} icon={icon} secondary={subtitle} />
          <Button variant="contained" color="primary" onClick={onClick} {...analyticsProps}>
            {actionText}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
