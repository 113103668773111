import { Accordion, AccordionDetails, AccordionDetailsProps, AccordionProps, Stack, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledAccordion = styled(Accordion)({
  borderRadius: 10,
  boxShadow: 'none',
  ':first-of-type': { borderRadius: 10 },
});

interface FormSectionProps extends AccordionProps {
  summary?: ReactNode;
  actions?: ReactNode;
  accordionDetailsProps?: AccordionDetailsProps;
}

export function FormSection({ summary, children, actions, ...props }: FormSectionProps) {
  return (
    <Stack direction="column" spacing={2}>
      <StyledAccordion disableGutters {...props}>
        {summary}
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>
      {actions}
    </Stack>
  );
}
