import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { Stack, Tooltip, Typography } from '@mui/material';
import { MaterialIcon } from '@components';
import NewBadge from '@common/ui/NewBadge';
import Switch from '@mui/material/Switch';

export function MfaSwitcher({
  requiredMfa,
  setRequiredMfa,
}: {
  requiredMfa: boolean;
  setRequiredMfa: (value: boolean) => void;
}) {
  const { track } = useAnalyticsContext();

  const tooltip =
    'If enabled, users will be required to pass multi-factor authentication (MFA) when requesting access.';
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="caption">Require MFA</Typography>
      {tooltip && (
        <Tooltip title={tooltip} arrow placement="top">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <MaterialIcon symbol="info" size="small" />
          </div>
        </Tooltip>
      )}
      <NewBadge asBadge={false} />
      <Switch
        checked={requiredMfa}
        color="success"
        onChange={(e) => {
          track(CustomAnalyticsEvents.REQUIRED_MFA_TOGGLED, {
            value: e.target.checked,
          });
          setRequiredMfa(e.target.checked);
        }}
        data-testid="setting-selector-switch"
      />
    </Stack>
  );
}
