import { PAGE_PARAM } from '@api';
import { FilterOption } from '@common/organisms/CommonFilters/GenericFilterMultiSelect';
import {
  RESOURCE_TYPE_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_LABEL,
  STATUS_FILTER_LABEL,
  STATUS_FILTER_PARAM,
  TAG_FILTER_LABEL,
  TAG_FILTER_PARAM,
} from '@common/organisms/CommonFilters/constants';
import ListFilters from '@components/ListFilters/ListFilters';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import StatusesFilterAsync from './IntegrationResourcesFilters/StatusesFilterAsync';
import TagsFilterAsync from './IntegrationResourcesFilters/TagsFilterAsync';
import ResourceTypesFilterAsync from './IntegrationResourcesFilters/ResourceTypesFilterAsync';

export interface IntegrationResourcesFiltersOptions {
  status: FilterOption[];
  tag: FilterOption[];
  resourceType: FilterOption[];
}

interface IntegrationResourcesFiltersProps {
  integrationId: string;
  search: string;
}

export default function IntegrationResourcesFilters({ integrationId, search }: IntegrationResourcesFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(
    () => [
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_FILTER_PARAM,
        element: <ResourceTypesFilterAsync integrationId={integrationId} search={search} />,
      },
      {
        name: STATUS_FILTER_LABEL,
        queryParam: STATUS_FILTER_PARAM,
        element: <StatusesFilterAsync integrationId={integrationId} search={search} />,
      },
      {
        name: TAG_FILTER_LABEL,
        queryParam: TAG_FILTER_PARAM,
        element: <TagsFilterAsync integrationId={integrationId} search={search} />,
      },
    ],
    [integrationId, search],
  );

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    searchParams.delete(PAGE_PARAM);
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <ListFilters
      visibleFilters={[RESOURCE_TYPE_FILTER_PARAM, STATUS_FILTER_PARAM, TAG_FILTER_PARAM]}
      filters={filters}
      onClear={hasActiveFilters ? handleOnClear : undefined}
    />
  );
}
