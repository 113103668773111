import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';
import { APPROVAL_FILTER_PARAM } from '@AccessFlows/common/constants';

import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';

export const APPROVAL_FILTER_LABEL = 'Approval';
export const APPROVAL_FILTER_AUTO = 'auto';
export const APPROVAL_FILTER_MANUAL = 'manual';

interface ApprovalFilterProps {
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export default function ApprovalFilter({ opened = false, onClose, onOpen }: ApprovalFilterProps) {
  const [isOpen, setIsOpen] = useState(opened);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.get(APPROVAL_FILTER_PARAM) || undefined;

  const activeOptions = [
    {
      label: 'Automatic',
      value: APPROVAL_FILTER_AUTO,
    },
    {
      label: 'Manual',
      value: APPROVAL_FILTER_MANUAL,
    },
  ];

  const selectedOption = activeOptions.find((option) => option.value === selectedQp) || undefined;

  const handleOnSelect = (value: ListFilterOption) => {
    searchParams.set(APPROVAL_FILTER_PARAM, value.value);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const handleOnClear = () => {
    searchParams.delete(APPROVAL_FILTER_PARAM);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={APPROVAL_FILTER_LABEL}
          value={selectedOption ? [selectedOption] : []}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      <ListFilterSingleSelect
        options={activeOptions}
        value={selectedOption}
        onSelect={handleOnSelect}
        title="Select Approval Filter"
      />
    </ListFilter>
  );
}
