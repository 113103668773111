import { useEffect, useState } from 'react';

import { Box, Container, styled, Tab, Tabs } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useActiveLocation } from '@hooks';
import { ProIconType, ProSizes, UpgradeWrapper } from '@components/Packages/UpgradeWrapper';

export interface PageTabProps {
  label: string;
  to: string;
  isPremium?: boolean;
}

export interface PageTabsProps {
  tabs: PageTabProps[];
}

const PageTabsContainer = styled(Box)(({ theme }) => ({
  borderBottom: 1,
  borderColor: 'divider',
  padding: `${theme.spacing(0, 3, 2)}`,
}));

export function PageTabs({ tabs }: PageTabsProps) {
  const isActive = useActiveLocation();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(() => {
    const index = tabs.findIndex((tab) => isActive(tab.to));
    return index > -1 ? index : 0;
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const index = tabs.findIndex((tab) => isActive(tab.to));
    if (index !== activeTab) setActiveTab(index);
  }, [activeTab, tabs, isActive, location.pathname]);

  return (
    <PageTabsContainer>
      <Container maxWidth={false}>
        <Tabs value={activeTab} onChange={handleChange}>
          {tabs.map(({ isPremium, ...restTab }) => {
            if (isPremium) return <PageProTab key={restTab.to} {...restTab} />;
            return <PageTab key={restTab.to} {...restTab} />;
          })}
        </Tabs>
      </Container>
    </PageTabsContainer>
  );
}

function PageTab(props: PageTabProps) {
  return <Tab component={RouterLink} {...props} />;
}

function PageProTab(props: PageTabProps) {
  return (
    <Tab
      component={RouterLink}
      {...props}
      label={
        <UpgradeWrapper
          size={ProSizes.Tiny}
          isPremium={true}
          iconType={ProIconType.IconWithoutText}
          withPopover={false}
        >
          {props.label}
        </UpgradeWrapper>
      }
    />
  );
}
