import { MaterialIcon } from '@components/MaterialIcon';
import { Stack, Typography, Box, styled } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

const BlockSectionWrapper = styled(Box)(({ theme }) => ({
  '&.wrapped': {
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.active,
  },
}));

interface ReportFormSectionProps {
  title: string;
  icon?: string;
  actions?: ReactNode;
  wrapped?: boolean;
}

export default function BlockSection({
  title,
  icon,
  actions,
  wrapped,
  children,
}: PropsWithChildren<ReportFormSectionProps>) {
  return (
    <BlockSectionWrapper className={wrapped ? 'wrapped' : ''}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {icon && <MaterialIcon symbol={icon} />}
            <Typography variant="subtitle2" component="div">
              {title}
            </Typography>
          </Stack>
          {actions && <Box>{actions}</Box>}
        </Stack>
        {children}
      </Stack>
    </BlockSectionWrapper>
  );
}
