import { ReactElement } from 'react';

import { ApplyConditionalFlagMiddlewares, ApplyMiddlewares } from '@middlewares';
import { Flag, UserRole } from '@utils';

export function conditionalAdminLayoutWrap(page: ReactElement, legacyPage: ReactElement, flag: Flag) {
  return (
    <ApplyConditionalFlagMiddlewares
      flag={flag}
      ifElement={adminLayoutWrap(page)}
      elseElement={adminLayoutWrap(legacyPage)}
    />
  );
}

export function adminLayoutWrap(children: ReactElement, flag?: Flag) {
  return (
    <ApplyMiddlewares flag={flag} allowedRoles={[UserRole.ADMIN]}>
      {children}
    </ApplyMiddlewares>
  );
}
