import { FilterProps } from '@common/organisms/CommonFilters/types';
import { AnalyticsTriggerElementProps } from '@common/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { Box, Popover } from '@mui/material';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import { ListFilterOption } from '@components/ListFilters/common';
import { useAlertSeverityFilterOptions } from '@AccessAlerts/services/use-alerts-filter-options';
import { getSeverity } from '@AccessAlerts/common/constants';

export const SEVERITY_FILTER_LABEL = 'Severity';
export const SEVERITY_FILTER_PARAM = 'severity';

export function AlertSeverityFilter({
  opened,
  onClose,
  onOpen,
  selected,
  onSelect,
  onClear,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps) {
  const [enabled] = useState(() => selected.length > 0);

  const { data, isFetched, refetch } = useAlertSeverityFilterOptions(enabled);

  const options = useMemo(() => {
    return (
      data?.alertsFilterOptions.severities.map((i) => ({
        value: i.toString(),
        label: getSeverity(i),
      })) || []
    );
  }, [data]);

  const selectedOptions = useMemo(() => {
    return options.filter((option) => selected.includes(option.value));
  }, [options, selected]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    !opened && onOpen && onOpen();
    setAnchorEl(event.currentTarget);
    if (!isFetched) {
      refetch();
    }
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isFetched) {
        refetch();
      }
    }
  }, [isFetched, opened, refetch]);

  const isFetching = !isFetched && (opened || selected.length > 0);

  const open = (opened && Boolean(anchorEl)) || false;

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={SEVERITY_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={options}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${SEVERITY_FILTER_LABEL} Filter`}
            loading={isFetching}
            {...analyticsProps}
          />
        </Box>
      </Popover>
    </>
  );
}
