import { Avatar, Stack, styled, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { isValidElement, ReactNode } from 'react';

const FormSectionSummaryAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.background.active,
}));

export interface FormSectionHeaderProps {
  titleIcon?: ReactNode;
  title: ReactNode;
  tooltip?: string;
  optional?: boolean;
}

export default function FormSectionHeader({ titleIcon, title, tooltip, optional }: FormSectionHeaderProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {titleIcon ? (
        isValidElement(titleIcon) ? (
          titleIcon
        ) : (
          <FormSectionSummaryAvatar src={titleIcon as string} />
        )
      ) : null}
      {isValidElement(title) ? title : <Typography variant="subtitle1">{title}</Typography>}
      {tooltip && (
        <Tooltip title={tooltip} arrow placement={optional ? 'top' : 'right'}>
          <InfoOutlined color="disabled" fontSize="small" />
        </Tooltip>
      )}
      {optional && (
        <Typography variant="body2" color="text.muted">
          (Optional)
        </Typography>
      )}
    </Stack>
  );
}
