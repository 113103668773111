import { EmptyState, Loader, MaterialIcon } from '@components';
import { IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccessRightSizingReportTable } from '@AccessRightSizing/organisms/AccessRightSizingReportTable';
import { AccessRightSizingMetrics } from '@AccessRightSizing/organisms/AccessRightSizingMetrics';
import { useAccessRightSizingReport } from '@AccessRightSizing/services/use-right-sizing-report';
import { AlertBanner } from '@components/AlertBanner';
import { aponoColors } from '@utils';
import { InsightDrawer } from '@AccessRightSizing/organisms/InsightDrawer';
import { getFragmentData } from '@api/gql';
import { AccessRightSizingFilters } from '@AccessRightSizing/organisms/AccessRightSizingFilters';
import { ACCESS_FLOW_QUERY_PARAM } from '@AccessRightSizing/components/RightSizingAccessFlowFilter';
import { PERIOD_QUERY_PARAM } from '@AccessRightSizing/components/RightSizingPeriodFilter';
import { RIGHT_SIZING_INSIGHT_FRAGMENT, RIGHT_SIZING_REPORT_FRAGMENTS } from '@AccessRightSizing/services/gql';
import { RightSizingInsightFieldsFragment } from '@api/gql/graphql';
import AccessRightSizingInsightFilters from '@AccessRightSizing/components/AccessRightSizingInsightFilters/AccessRightSizingInsightFilters';

export function AccessRightSizingPage() {
  const [searchParams] = useSearchParams();
  const accessFlowId = searchParams.get(ACCESS_FLOW_QUERY_PARAM);

  const [chosenInsight, setChosenInsight] = useState<RightSizingInsightFieldsFragment | null>();

  const [showBanner, setShowBanner] = useState(true);

  return (
    <Stack direction="column" spacing={2} justifyContent="center" display="flex">
      {showBanner && (
        <AlertBanner
          type="outlinedInfo"
          icon={<MaterialIcon symbol="lightbulb" style={{ color: aponoColors.tertiary[300] }} />}
          message="This report updates monthly to reflect last month's Access Flow usage based on access requests"
          button={
            <IconButton
              onClick={() => {
                setShowBanner(false);
              }}
              style={{ color: aponoColors.tertiary[300] }}
            >
              <MaterialIcon symbol="close" weight={700} />
            </IconButton>
          }
        />
      )}
      <AccessRightSizingFilters />
      {!accessFlowId && (
        <EmptyState title="Please Choose an Access Flow" imgSrc="/static/EmptyStateImages/not-found-illustration.svg" />
      )}
      {accessFlowId && (
        <>
          <AccessRightSizingContent
            accessFlowId={accessFlowId}
            setChosenInsight={setChosenInsight}
            chosenInsightId={chosenInsight?.id}
          />
        </>
      )}
      {chosenInsight && <InsightDrawer open insight={chosenInsight} onClose={() => setChosenInsight(null)} />}
    </Stack>
  );
}

function AccessRightSizingContent({
  accessFlowId,
  setChosenInsight,
  chosenInsightId,
}: {
  accessFlowId: string;
  setChosenInsight: (insight: RightSizingInsightFieldsFragment) => void;
  chosenInsightId?: string;
}) {
  const [searchParams] = useSearchParams();
  const selectedMonth = searchParams.get(PERIOD_QUERY_PARAM);

  const {
    data,
    isFetched: isAccessFlowReportFetched,
    isError: isReportFetchError,
    isFetching: isLoading,
  } = useAccessRightSizingReport({ id: accessFlowId, period: selectedMonth });
  const { rightSizingReport } = data || { rightSizingReport: null };

  const rightSizingReportData = getFragmentData(RIGHT_SIZING_REPORT_FRAGMENTS, rightSizingReport);

  const { insights } = rightSizingReportData || { insights: [] };

  const insightsData = getFragmentData(RIGHT_SIZING_INSIGHT_FRAGMENT, insights);

  if (isReportFetchError) {
    return (
      <EmptyState
        title="Reports for Access Flow Not Found"
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
      />
    );
  }

  if (isLoading || !isAccessFlowReportFetched) {
    return <Loader />;
  }

  if (rightSizingReportData === null || rightSizingReportData === undefined) {
    return <EmptyState title="No Data Found" imgSrc="/static/EmptyStateImages/not-found-illustration.svg" />;
  }

  return (
    <>
      <AccessRightSizingMetrics rightSizingReport={rightSizingReportData} />
      <AccessRightSizingInsightFilters insights={insightsData} />
      <AccessRightSizingReportTable
        insights={insightsData}
        setChosenInsight={setChosenInsight}
        chosenInsightId={chosenInsightId}
      />
    </>
  );
}
