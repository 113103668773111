import { AttributeGroupAppModel } from '@api';
import { Stack, Avatar, Typography } from '@mui/material';

import { FlowFormText } from '../common';

export interface HumanReadableAttributeProps {
  attribute?: AttributeGroupAppModel;
}

export default function HumanReadableAttribute({ attribute }: HumanReadableAttributeProps) {
  if (!attribute) {
    return (
      <FlowFormText selectable muted>
        Select attribute
      </FlowFormText>
    );
  }

  return (
    <FlowFormText selectable>
      <Stack direction="row" spacing={1} alignItems="center">
        {attribute.integration && (
          <Avatar src={attribute.integration.icons.svg} variant="rounded" className="size-20" />
        )}
        <Typography variant="inherit">{attribute.type.display_name}</Typography>
      </Stack>
    </FlowFormText>
  );
}
