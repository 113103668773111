import { Paper, styled } from '@mui/material';

import { Loader } from '@components';

const WidgetCardLoadingContainer = styled(Paper)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.5,
}));

export function WidgetCardLoading() {
  return (
    <WidgetCardLoadingContainer>
      <Loader absolute={false} />
    </WidgetCardLoadingContainer>
  );
}
