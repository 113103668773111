import { useCallback } from 'react';
import { Stack, Link } from '@mui/material';

import { RouterLink, generatePath, useSearchParams } from '@libs';
import { routes } from '@routes';
import { AccessFlowAppModel, LabelAppModel } from '@api';

import AccessFlowLabel from '@AccessFlows/components/AccessFlowLabel';
import { LABEL_FILTER_PARAM } from '@AccessFlows/common/constants';
import { TriggerTypeClickableChip } from '@common/organisms/TriggerTypeClickableChip/TriggerTypeClickableChip';

export default function AccessFlowItemName({ accessFlow }: { accessFlow: AccessFlowAppModel }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <TriggerTypeClickableChip triggerType={accessFlow.trigger_type} />
      <Link
        component={RouterLink}
        to={generatePath(routes.EditAccessFlow.path, { id: accessFlow.id })}
        variant="body2"
        underline="hover"
        sx={{ inlineSize: '100%', overflowWrap: 'break-word' }}
      >
        <strong>{accessFlow.name}</strong>
      </Link>
      {accessFlow.labels.length > 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          {accessFlow.labels.map((l) => (
            <LabelItem key={l.name} label={l} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

function LabelItem({ label }: { label: LabelAppModel }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = searchParams.getAll(LABEL_FILTER_PARAM).includes(label.name);

  const handleOnClick = useCallback(() => {
    const allFilterGrantees = searchParams.getAll(LABEL_FILTER_PARAM);

    if (allFilterGrantees.includes(label.name)) {
      searchParams.delete(LABEL_FILTER_PARAM);
      allFilterGrantees.forEach((granteeId) => {
        if (granteeId !== label.name) {
          searchParams.append(LABEL_FILTER_PARAM, granteeId);
        }
      });
    } else {
      searchParams.append(LABEL_FILTER_PARAM, label.name);
    }

    setSearchParams(searchParams);
  }, [label.name, searchParams, setSearchParams]);

  return <AccessFlowLabel key={label.name} label={label} size="small" onClick={handleOnClick} active={isActive} />;
}
