export enum CustomAnalyticsEvents {
  ACCESS_FLOW_DUPLICATED = 'Access Flow Duplicated',
  ACCESS_FLOW_DELETED = 'Access Flow Deleted',
  ACCESS_FLOW_CREATED = 'Access Flow Created',
  ACCESS_FLOW_FAILED = 'Access Flow Failed',
  ACCESS_FLOW_EDITED = 'Access Flow Edited',
  ACCESS_FLOW_SEARCH = 'Access Flow Searched',
  ACCESS_FLOW_ENABLED = 'Access Flow Enabled',
  ACCESS_FLOW_DISABLED = 'Access Flow Disabled',
  ACCESS_JUSTIFICATION_SUBMITTED = 'Access Justification Submitted',
  BUNDLE_CREATED = 'Bundle Created',
  BUNDLE_EDITED = 'Bundle Edited',
  BUNDLE_DUPLICATED = 'Bundle Duplicated',
  BUNDLE_DELETED = 'Bundle Deleted',
  ACCESS_FLOW_CREATE_INITIATED_IN_BUNDLE_LIST = 'Access Flow Create Initiated in Bundle List',
  BUNDLE_SELECTED_IN_FLOW_BUILDER = 'Bundle Selected in Flow Builder',
  BUNDLE_CREATE_INITIATED_IN_BUILDER = 'Bundle Create Initiated in Flow Builder',
  BUNDLE_VIEW_INITIATED = 'Bundle View Initiated',
  UPDATE_CONNECTOR = 'Connector Updated',
  EDIT_INTEGRATION = 'Integration Edit Initiated',
  VIEW_INTEGRATION = 'Integration View Initiated',
  DELETE_INTEGRATION = 'Integration Delete Initiated',
  REFRESH_INTEGRATION = 'Integration Refresh Initiated',
  TEMPLATE_CLICKED = 'Access Flow Template Selected',
  TEMPLATE_CREATED = 'Access Flow Created from Template',
  CREATE_ACCESS_FLOW_BUTTON_CLICKED = 'Access Flow Created',
  SELECT_FORM_TYPE = 'Trigger Type Selected in Flow Builder',
  SELECT_GRANTEE = 'Grantee Selected in Flow Builder',
  SELECT_INTEGRATION = 'Integration Selected in Flow Builder',
  SELECT_RESOURCE_TYPE = 'Resource Type Selected in Flow Builder',
  SELECT_TARGET_TYPE = 'Target Type Selected in Flow Builder',
  SELECT_TIMEFRAME = 'Timeframe Selected in Flow Builder',
  SELECT_APPROVER = 'Approver Selected in Flow Builder',
  SELECT_DURATION = 'Duration Selected in Flow Builder',
  UPGRADE_BUTTON_CLICKED = 'Upgrade Button Engaged',
  UPGRADE_TOOLTIP_OPENED = 'Upgrade Tooltip Viewed',
  ONBOARDING_STEP_1_COMPLETED = 'Onboarding Step 1 Completed',
  ONBOARDING_STEP_2_COMPLETED = 'Onboarding Step 2 Completed',
  ONBOARDING_STEP_3_COMPLETED = 'Onboarding Step 3 Completed',
  ONBOARDING_COMPLETED = 'Onboarding Completed',
  CATALOG_PICKED_INTEGRATION = 'Picked Integration from Catalog',
  ADD_INTEGRATION_ENVIRONMENT_SELECTED = 'Environment Selected in Add Integration',
  ADD_INTEGRATION_CONNECTOR_SELECTED = 'Connector Selected in Add Integration',
  ADD_INTEGRATION_NEW_CONNECTOR_SELECTED = 'Add Connector Selected in Add Integration',
  ADD_CONNECTOR_PLATFORM_SELECTED = 'Platform Selected in Add Connector',
  ADD_CONNECTOR_INSTALL_METHOD_SELECTED = 'Install Method Selected in Add Connector',
  ADD_INTEGRATION_RESOURCE_TYPE_ENGAGED = 'Resource Type Engaged in Add Integration',
  CONNECTOR_CONNECTED = 'Connector Connected',
  INTEGRATION_NEXT_CLICKED = 'Integration Next Clicked',
  LOGO_EASTER_EGG = 'Logo Easter Egg',
  REQUIRE_JUSTIFICATION_TOGGLED = 'Require Justification Toggled in Flow Builder',
  REQUIRE_APPROVER_TOGGLED = 'Require Approver Toggled in Flow Builder',
  ENFORCE_ALL_APPROVERS_TOGGLED = 'Enforce All Approvers Toggled in Flow Builder',
  REQUESTER_CANNOT_APPROVE_HIMSELF_TOGGLED = 'Requester Cannot Approver Himself in Flow Builder',
  REQUIRED_MFA_TOGGLED = 'Required MFA Toggled in Flow Builder',
  AUTOMATIC_TAB_CLICKED = 'Automatic Tab Clicked in Flow Builder',
  REVIEW_AND_CREATE_CLICKED = 'Review and Create Clicked in Flow Builder',
  CREATE_AND_GRANT_CLICKED = 'Create and Grant Clicked in Flow Builder',
  AUTO_GRANT_SUCCEED = 'Auto Grant Succeed',
  VIEW_IN_ACTIVITY_CLICKED = 'View in Activity Clicked in Flow Builder',
  VIEW_GROUP_MEMBERS_IN_FLOW_BUILDER = 'View Group Member in Flow Builder',
  VIEW_AS_CODE_CLICKED_IN_INTEGRATION_FORM = 'View as Code Clicked in Integration Form',
  COPY_AS_CODE_CLICKED = 'Copy as Code Clicked',
  REVOKE_ALL_CLICKED_IN_ACTIVITY_PAGE = 'Revoke All Clicked in Activity Page',
  CONFIRM_DIALOG_YES_CLICKED = 'Confirm Dialog Yes Clicked',
  OWNER_RESOURCE_ATTRIBUTE_SELECTED = 'Owner ResourceAttribute Selected',
  OWNER_RESOURCE_ATTRIBUTE_VALUE_SELECTED = 'Owner Resource Attribute Value Selected',
  OWNER_SELECTED_AS_APPROVER_IN_FLOW_BUILDER = 'Owner Selected as Approver in Flow Builder',
  OWNER_INTEGRATION_ATTRIBUTE_AND_VALUE_SELECTED = 'Owner Integration Attribute and Value Selected',
  ACCESS_GRAPH_SIDEBAR_MENU_CLICKED = 'Access Graph Sidebar Menu Clicked',
  LEGEND_SHOW_AVAILABLE_ACCESS_TOGGLES = 'Legend Show Available Access Toggles',
  LEGEND_SHOW_ONLY_ACTIVE_ACCESS_TOGGLES = 'Legend Show Only Active Access Toggles',
  VIEW_ACCESS_REQUEST_CLICKED = 'View Access Request Clicked',
  VIEW_ACCESS_FLOW_CLICKED = 'View Access Flow Clicked',
  ACCESS_GRAPH_AVAILABLE_ACCESS_EDGE_CLICKED = 'Access Graph Available Access Edge Clicked',
  ACCESS_GRAPH_ACTIVE_ACCESS_EDGE_CLICKED = 'Access Graph Active Access Edge Clicked',
  ACCESS_GRAPH_GROUP_EXPANDED = 'Access Graph Group Expanded',
  ACCESS_GRAPH_RESOURCES_EXPANDED = 'Access Graph Resources Expanded',
  SESSION_AUDIT_SIDEBAR_MENU_CLICKED = 'Session Audit Sidebar Menu Clicked',
  SESSION_AUDIT_SEARCH_BAR = 'Session Audit Search Bar Used',
  SESSION_AUDIT_USER_FILTER = 'Session Audit User Filter Applied',
  SESSION_AUDIT_INTEGRATION_FILTER = 'Session Audit Integration Filter Applied',
  SESSION_AUDIT_EVENT_TYPE_FILTER = 'Session Audit Event Type Filter Applied',
  ACCESS_ANOMALIES_SIDEBAR_MENU_CLICKED = 'Access Anomalies Sidebar Menu Clicked',
  ACCESS_ANOMALIES_SEVERITY_FILTER_APPLIED = 'Access Anomalies Severity Filter Applied',
  ACCESS_ANOMALIES_ALERT_TYPE_FILTER_APPLIED = 'Access Anomalies Alert Type Filter Applied',
  ACCESS_ANOMALIES_USER_FILTER_APPLIED = 'Access Anomalies User Filter Applied',
  ACCESS_ANOMALIES_RESOURCE_TYPE_FILTER_APPLIED = 'Access Anomalies Resource Type Filter Applied',
  ACCESS_ANOMALIES_INTEGRATION_FILTER_APPLIED = 'Access Anomalies Integration Filter Applied',
  ACCESS_ANOMALIES_VIEW_ALL_CLICKED = 'Access Anomalies See All Clicked (On Dashboard)',
  ACCESS_ANOMALIES_VIEW_ALL_BY_TYPE_CLICKED = 'Access Anomalies See All Clicked By Type (On Dashboard)',
  ACCESS_ANOMALIES_DRAWER_OPENED = 'Access Anomaly Drawer Opened',
  ACCESS_ANOMALIES_REQUEST_ID_CLICKED = 'Access Anomalies Request ID Clicked',
}
