import { PropsWithChildren } from 'react';

import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';
import { ProState } from '@components/Packages/ProState';

import { Page, PageProps } from './Page';

export interface PremiumPageProps extends PageProps {
  flag: Flag;
}

export function PremiumPage({ flag, children, ...restProps }: PropsWithChildren<PremiumPageProps>) {
  const { isPremium } = useFlagFixed(flag);
  return <Page {...restProps}>{isPremium ? <ProState /> : children}</Page>;
}
