import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';

import { RESOURCE_TYPE_FILTER_PARAM } from '@common/constants';
import { RouterLink } from '@libs';

import { INTEGRATION_FILTER_PARAM, BUNDLE_FILTER_PARAM } from '@AccessFlows/common/constants';
import { TargetItem } from '@AccessFlows/common/types';

import AccessFlowItemTargetChip from '@AccessFlows/components/AccessFlowItemTargetChip';

interface AccessFlowItemTargetsProps {
  targets: TargetItem[];
  maxTargets?: number;
  moreLink?: string;
}

export default function AccessFlowItemTargets({ targets, maxTargets = 5, moreLink }: AccessFlowItemTargetsProps) {
  const slicedTargets = targets.slice(0, maxTargets);
  const moreCount = targets.length - slicedTargets.length;

  return (
    <Stack direction="column" alignItems="flex-start" spacing={1} style={{ maxWidth: '100%' }}>
      {slicedTargets.map((target, i) => (
        <AccessFlowItemTarget key={i} target={target} />
      ))}
      {moreCount > 0 && moreLink && (
        <Chip
          label={
            <Typography variant="body2" color="primary">
              +{moreCount} more
            </Typography>
          }
          size="small"
          variant="outlined"
          clickable
          component={RouterLink}
          to={moreLink}
        />
      )}
    </Stack>
  );
}

function searchParamsHas(searchParams: URLSearchParams, param: string, value: string) {
  return searchParams.getAll(param).includes(value);
}

function addOrDeleteSearchParams(searchParams: URLSearchParams, param: string, value: string) {
  const allFilterGrantees = searchParams.getAll(param);
  if (allFilterGrantees.includes(value)) {
    searchParams.delete(param);
    allFilterGrantees.forEach((granteeId) => {
      if (granteeId !== value) {
        searchParams.append(param, granteeId);
      }
    });
  } else {
    searchParams.append(param, value);
  }
}

function AccessFlowItemTarget({ target }: { target: TargetItem }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = useMemo(() => {
    if (target.target.integration) {
      return (
        searchParamsHas(searchParams, INTEGRATION_FILTER_PARAM, target.target.integration.integration_id) &&
        searchParamsHas(searchParams, RESOURCE_TYPE_FILTER_PARAM, target.target.integration.resource_type)
      );
    } else if (target.target.bundle) {
      return searchParamsHas(searchParams, BUNDLE_FILTER_PARAM, target.target.bundle.bundle_id);
    }
    return false;
  }, [searchParams, target.target.bundle, target.target.integration]);

  const handleOnClick = useCallback(() => {
    if (target.target.integration) {
      addOrDeleteSearchParams(searchParams, INTEGRATION_FILTER_PARAM, target.target.integration.integration_id);
      addOrDeleteSearchParams(searchParams, RESOURCE_TYPE_FILTER_PARAM, target.target.integration.resource_type);
    } else if (target.target.bundle) {
      addOrDeleteSearchParams(searchParams, BUNDLE_FILTER_PARAM, target.target.bundle.bundle_id);
    }
    setSearchParams(searchParams);
  }, [target.target.integration, target.target.bundle, setSearchParams, searchParams]);

  return (
    <Tooltip title={`Filter by access target`} placement="top" enterDelay={750} arrow>
      <div style={{ maxWidth: '100%' }}>
        <AccessFlowItemTargetChip target={target} onClick={handleOnClick} isActive={isActive} />
      </div>
    </Tooltip>
  );
}
