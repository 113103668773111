import { Box, Checkbox, FormControlLabel, styled } from '@mui/material';

import { ResourceDetailedItem } from '@components/ResourceDetailedItem';
import AlertStatusMessage from '@components/AlertStatusMessage';

const NODE_LINE_WIDTH = 25;

const TreeNodeLine = styled(Box)(({ theme }) => ({
  width: `${NODE_LINE_WIDTH}px`,
  position: 'absolute',
  left: 0,
  bottom: 0,
  top: 0,

  '&:before': {
    content: '""',
    left: `${NODE_LINE_WIDTH / 2 + 2}px`,
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
  },
}));

const TreeNodeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const TreeNodeContainer = styled(Box)(({ theme }) => ({
  position: 'relative',

  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export interface TreeNodeItem {
  id: string;
  name: string;
  icon?: string;
  logicalPath?: string;
  literalPath?: string;
  selected?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}

export interface TreeNodeProps {
  item: TreeNodeItem;
  level: number;
  onSelect: () => void;
}

export function TreeNode({ item, level, onSelect }: TreeNodeProps) {
  return (
    <TreeNodeContainer sx={{ paddingLeft: `${level * NODE_LINE_WIDTH}px` }}>
      {Array.from({ length: level }).map((_, index) => (
        <TreeNodeLine key={index} sx={{ left: `${index * NODE_LINE_WIDTH}px` }} />
      ))}
      <TreeNodeWrapper>
        <FormControlLabel
          data-testid="interactive-dropdown-select-option"
          checked={item.selected}
          disabled={item.disabled}
          onChange={() => onSelect()}
          control={<Checkbox size="small" id={item.id} />}
          label={
            <AlertStatusMessage message={item.errorMessage}>
              <ResourceDetailedItem
                icon={item.icon}
                displayName={item.name}
                logicalPath={item.logicalPath}
                literalPath={item.literalPath}
                maxNameLength={50}
              />
            </AlertStatusMessage>
          }
        />
      </TreeNodeWrapper>
    </TreeNodeContainer>
  );
}
