import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';

export interface FilterOption {
  value: string;
  label: string;
  icon?: string;
  searchHelper?: string;
}

export interface FilterProps {
  options: FilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  filterParam: string;
  filterLabel: string;
}

export default function GenericFilterMultiSelect({
  options,
  opened = false,
  onClose,
  onOpen,
  filterLabel,
  filterParam,
}: FilterProps) {
  const [isOpen, setIsOpen] = useState(opened);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.getAll(filterParam);

  const selectedOptions = [];
  for (const option of options) {
    if (selectedQp.includes(option.value)) {
      selectedOptions.push(option);
    }
  }

  const handleOnSelect = (values: ListFilterOption[]) => {
    searchParams.delete(filterParam);
    for (const value of values) {
      searchParams.append(filterParam, value.value);
    }
    setSearchParams(searchParams);
  };

  const handleOnClear = () => {
    searchParams.delete(filterParam);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={filterLabel}
          value={selectedOptions}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
      width={300}
    >
      <ListFilterMultiSelect
        options={options}
        value={selectedOptions}
        onSelect={handleOnSelect}
        enableSearch
        title={`Select ${filterLabel} Filter`}
      />
    </ListFilter>
  );
}
