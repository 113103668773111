import { ResourceStatusV1 } from '@api';
import { AccessStatusModel } from '@common/constants';
import { MaterialIcon, StatusProps } from '@components';
import { Box, CircularProgress, styled } from '@mui/material';
import { ConnectedIntegrationStatus, ConnectorsStatus } from '@utils';

export const Dot = styled(Box)(({ theme }) => ({
  width: theme.spacing(0.5),
  height: theme.spacing(0.5),
  position: 'relative',

  '&:after': {
    content: '""',
    display: 'block',
    width: theme.spacing(0.75),
    height: theme.spacing(0.75),
    borderRadius: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.text.disabled,
  },

  '&.success': {
    '&:after': {
      backgroundColor: theme.palette.success.main,
    },
  },

  '&.primary': {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  '&.error': {
    '&:after': {
      backgroundColor: theme.palette.error.main,
    },
  },

  '&.warning': {
    '&:after': {
      backgroundColor: theme.palette.warning.main,
    },
  },
}));

function isPrimary(status: StatusProps['status']) {
  return status === AccessStatusModel.Active || status === AccessStatusModel.GRANTED;
}

function isSuccess(status: StatusProps['status']) {
  return (
    status === AccessStatusModel.APPROVED ||
    status === ConnectedIntegrationStatus.ACTIVE ||
    status === ConnectorsStatus.CONNECTED ||
    status === ResourceStatusV1.Active
  );
}

function isError(status: StatusProps['status']) {
  return (
    status === AccessStatusModel.Rejected ||
    status === AccessStatusModel.REJECTED ||
    status === AccessStatusModel.Failed ||
    status === AccessStatusModel.FAILED ||
    status === ConnectedIntegrationStatus.ERROR ||
    status === ConnectorsStatus.DISCONNECTED ||
    status === ResourceStatusV1.Error
  );
}

function isWarning(status: StatusProps['status']) {
  return status === ConnectedIntegrationStatus.WARNING || status === ResourceStatusV1.Deleted;
}

function isPending(status: StatusProps['status']) {
  return (
    status === ConnectedIntegrationStatus.REFRESHING ||
    status === ConnectedIntegrationStatus.INITIALIZING ||
    status === AccessStatusModel.Pending ||
    status === AccessStatusModel.PENDING ||
    status === AccessStatusModel.Revoking ||
    status === AccessStatusModel.REVOKING ||
    status === AccessStatusModel.Granting ||
    status === AccessStatusModel.PendingMFA
  );
}

export default function StatusIcon({ status, enforceColor }: { status: StatusProps['status']; enforceColor?: string }) {
  if (enforceColor) return <Dot className={enforceColor} p={1.5} />;

  if (isPrimary(status)) return <Dot className="primary" p={1.5} />;
  if (isSuccess(status)) return <Dot className="success" p={1.5} />;
  if (isError(status)) return <MaterialIcon symbol="warning-error" weight={700} color="error" />;
  if (isWarning(status)) return <MaterialIcon symbol="warning-error" weight={700} color="warning" />;
  if (isPending(status))
    return (
      <Box py={0.25} px={1}>
        <CircularProgress size="8px" color="inherit" />
      </Box>
    );
  return <Dot p={1.5} />;
}
