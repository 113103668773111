import { Fragment, ReactNode, useState } from 'react';

import { MaterialIcon } from '@components/MaterialIcon';
import { Button, Chip, Stack } from '@mui/material';
import ListFilter from './ListFilter';
import ListFilterSingleSelect from './ListFilterSingleSelect';
import { ListFilterOption } from './common';

interface ListFiltersFilter {
  queryParam: string;
  name: string;
  icon?: ReactNode;
  element: ReactNode;
}

interface ListFiltersProps {
  visibleFilters: string[];
  filters: ListFiltersFilter[];
  onClear?: () => void;
  onAddFilter?: (filter: string) => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export default function ListFilters({
  visibleFilters,
  filters,
  onClear,
  onAddFilter,
  prefix,
  suffix,
}: ListFiltersProps) {
  const availableFiltersOptions = [];
  const remappedFilters: { [key: string]: ListFiltersFilter } = {};

  for (const filter of filters) {
    if (!visibleFilters.includes(filter.queryParam)) {
      availableFiltersOptions.push({
        value: filter.queryParam,
        label: filter.name,
        icon: filter.icon,
      });
    }

    remappedFilters[filter.queryParam] = filter;
  }

  return (
    <Stack direction="row" alignItems="center" useFlexGap flexWrap="wrap" spacing={2}>
      {prefix}
      {visibleFilters.map((f) => remappedFilters[f] && <Fragment key={f}>{remappedFilters[f].element}</Fragment>)}
      {availableFiltersOptions.length > 0 && onAddFilter && (
        <SelectFilter options={availableFiltersOptions} onSelect={(v) => onAddFilter(v.value)} />
      )}
      {onClear && (
        <Button
          onClick={onClear}
          startIcon={<MaterialIcon symbol="backspace" weight={500} size="medium" />}
          size="small"
        >
          Clear Filters
        </Button>
      )}
      {suffix}
    </Stack>
  );
}

function SelectFilter({
  options,
  onSelect,
}: {
  options: ListFilterOption[];
  onSelect: (value: ListFilterOption) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleOnSelect = (value: ListFilterOption) => {
    onSelect(value);
    closeDropdown();
  };

  return (
    <ListFilter
      trigger={
        <Chip
          label={<strong>More Filters</strong>}
          icon={<MaterialIcon symbol="filter_alt" weight={700} />}
          variant="filter"
          clickable
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown: () => {
          setIsOpen((prev) => !prev);
        },
        closeDropdown,
      }}
    >
      <ListFilterSingleSelect options={options} onSelect={handleOnSelect} />
    </ListFilter>
  );
}
