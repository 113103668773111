import { Avatar, ButtonBase, Chip, CircularProgress, Stack, Typography, styled } from '@mui/material';
import { forwardRef, Fragment, ReactElement, ReactNode, useMemo } from 'react';

import { MaterialIcon } from '@components/MaterialIcon';
import { ListFilterOption } from './common';
import LeftOversChip from '@common/ui/LeftOversChip';

const ListFilterSelectChipTriggerAvatar = styled(Avatar)(({ theme }) => ({
  width: `${theme.spacing(2.5)} !important`,
  height: `${theme.spacing(2.5)} !important`,
  backgroundColor: theme.palette.background.active,
}));

interface ListFilterSelectChipTriggerProps {
  active?: boolean;
  label: string;
  icon?: ReactNode;
  value: ListFilterOption[];
  onClear: () => void;
  withCategory?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  enableClear?: boolean;
}

const ListFilterSelectChipTrigger = forwardRef<HTMLButtonElement, ListFilterSelectChipTriggerProps>(
  function ListFilterSelectChipTrigger(
    { active = false, label, icon, value, onClear, withCategory, loading, onClick, enableClear = true },
    ref,
  ) {
    const labelText = useMemo(() => {
      if (loading) {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <strong>{label}</strong>
            <CircularProgress size="10px" />
          </Stack>
        );
      }

      if (value.length === 0) {
        return (
          <Stack direction="row" alignItems="center">
            <Typography variant="inherit">
              <strong>{label}</strong>
            </Typography>
            <MaterialIcon symbol="expand_more" sx={{ display: 'block', marginRight: '-8px' }} />
          </Stack>
        );
      }

      let firstLabel = value[0].label;
      if (withCategory && value[0].category) {
        firstLabel = `${value[0].category.label} > ${firstLabel}`;
      }

      if (value.length > 1)
        return (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <strong>{label}:</strong>
            <span>{firstLabel}</span>
            <LeftOversChip label={`+${value.length - 1}`} tooltip={value.map((v) => v.label).join(', ')} />
          </Stack>
        );

      return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <strong>{label}:</strong>
          <span>{firstLabel}</span>
        </Stack>
      );
    }, [label, loading, value, withCategory]);

    const avatar = useMemo(() => {
      if (!icon) return;

      if (typeof icon === 'string') return <ListFilterSelectChipTriggerAvatar src={icon} />;

      return icon as ReactElement;
    }, [icon]);

    const ChipWrapper = onClick ? ButtonBase : Fragment;
    const ChipWrapperProps = onClick ? { ref, onClick } : {};

    return (
      <ChipWrapper {...ChipWrapperProps}>
        <Chip
          avatar={avatar}
          label={labelText}
          clickable
          onDelete={value.length > 0 && enableClear ? onClear : undefined}
          variant={active || value.length > 0 ? 'filter-active' : 'filter'}
        />
      </ChipWrapper>
    );
  },
);

export default ListFilterSelectChipTrigger;
