import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RightSizingInsightFieldsFragment } from '@api/gql/graphql';
import { getFragmentData } from '@api/gql';
import { INTEGRATION_FRAGMENT, RESOURCE_TYPE_FRAGMENT } from '@AccessRightSizing/services/gql';
import ListFilters from '@components/ListFilters/ListFilters';

import { getInsightConfig } from '@AccessRightSizing/common/common';
import { QueryParams } from '@Activity/common/constants';
import { RESOURCE_TYPE_FILTER_LABEL } from '@common/organisms/CommonFilters/ResourceTypeFilterAsync';
import GenericFilterMultiSelect from '@common/organisms/CommonFilters/GenericFilterMultiSelect';
import { INTEGRATION_FILTER_LABEL } from '@common/organisms/CommonFilters/constants';

export const INTEGRATION_FILTER_PARAM = QueryParams.filterIntegrationId;
export const RESOURCE_TYPE_FILTER_PARAM = QueryParams.filterResourceType;
export const INSIGHT_TYPE_FILTER_PARAM = 'filter.insightType';
export const INSIGHT_TYPE_FILTER_LABEL = 'Insight Type';

const DEFAULT_VISIBLE_FILTERS = [INTEGRATION_FILTER_PARAM, RESOURCE_TYPE_FILTER_PARAM, INSIGHT_TYPE_FILTER_PARAM];

export default function AccessRightSizingInsightFilters({
  insights,
}: {
  insights: readonly RightSizingInsightFieldsFragment[];
}) {
  const { integrationOptions, insightTypeOptions, resourceTypeOptions } = useMemo(() => {
    const integrationOptionsMap = new Map();
    const resourceTypeOptionsMap = new Map();
    const insightTypeOptionsMap = new Map();

    insights.forEach((insight) => {
      if (!insightTypeOptionsMap.has(insight.insightType)) {
        insightTypeOptionsMap.set(insight.insightType, {
          value: insight.insightType,
          label: getInsightConfig(insight.insightType).title,
        });
      }

      const integrationData = getFragmentData(INTEGRATION_FRAGMENT, insight.integration);
      if (integrationData && !integrationOptionsMap.has(integrationData.id)) {
        integrationOptionsMap.set(integrationData.id, {
          value: integrationData.id,
          label: integrationData.name,
          icon: integrationData.icons.svg,
          searchHelper: integrationData.name,
        });
      }

      const resourceTypeData = getFragmentData(RESOURCE_TYPE_FRAGMENT, insight.resourceType);
      if (resourceTypeData && !resourceTypeOptionsMap.has(resourceTypeData.type)) {
        resourceTypeOptionsMap.set(resourceTypeData.type, {
          value: resourceTypeData.type,
          label: resourceTypeData.typeDisplayName,
          icon: resourceTypeData.icons.svg,
          searchHelper: resourceTypeData.typeDisplayName,
        });
      }
    });

    return {
      integrationOptions: Array.from(integrationOptionsMap.values()),
      insightTypeOptions: Array.from(insightTypeOptionsMap.values()),
      resourceTypeOptions: Array.from(resourceTypeOptionsMap.values()),
    };
  }, [insights]);

  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const filters = useMemo(
    () => [
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            opened={openedFilter === INTEGRATION_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INTEGRATION_FILTER_PARAM)}
            filterParam={INTEGRATION_FILTER_PARAM}
            options={integrationOptions}
            filterLabel={INTEGRATION_FILTER_LABEL}
          />
        ),
      },
      {
        name: INSIGHT_TYPE_FILTER_LABEL,
        queryParam: INSIGHT_TYPE_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            opened={openedFilter === INSIGHT_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INSIGHT_TYPE_FILTER_PARAM)}
            options={insightTypeOptions}
            filterParam={INSIGHT_TYPE_FILTER_PARAM}
            filterLabel={INSIGHT_TYPE_FILTER_LABEL}
          />
        ),
      },
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            opened={openedFilter === RESOURCE_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_TYPE_FILTER_PARAM)}
            options={resourceTypeOptions}
            filterParam={RESOURCE_TYPE_FILTER_PARAM}
            filterLabel={RESOURCE_TYPE_FILTER_LABEL}
          />
        ),
      },
    ],
    [openedFilter, insightTypeOptions, resourceTypeOptions, integrationOptions],
  );

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  const handleOnOnAddFilter = (qp: string) => {
    setOpenedFilter(qp);
  };

  return (
    <ListFilters
      visibleFilters={DEFAULT_VISIBLE_FILTERS}
      filters={filters}
      onAddFilter={handleOnOnAddFilter}
      onClear={hasActiveFilters ? handleOnClear : undefined}
    />
  );
}
