import AuditEventsTableContainer from '@AuditEvents/components/AuditEventsTableContainer';

import AuditEventsTableRow from '@AuditEvents/components/AuditEventsTableRow';
import { AuditEventsQuery } from '@api/gql/graphql';
import { NoResults } from '@components';

export const BEFORE_PAGE_PARAM = 'before';
export const AFTER_PAGE_PARAM = 'after';

export interface AuditEventsTableProps {
  isFetched: boolean;
  isLoading: boolean;
  data?: AuditEventsQuery;
}

export default function AuditEventsTable({ isFetched, isLoading, data }: AuditEventsTableProps) {
  if (!isFetched) return <AuditEventsTableContainer isNotFetched />;

  if (!data?.auditEvents.edges.length && !isLoading) return <NoResults>No audit events found.</NoResults>;

  return (
    <AuditEventsTableContainer isLoading={isLoading}>
      {data?.auditEvents.edges.map(({ node, cursor }) => (
        <AuditEventsTableRow key={cursor} node={node} />
      ))}
    </AuditEventsTableContainer>
  );
}
