import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, IconButton, Link, Stack, styled, Tooltip, Typography } from '@mui/material';

import { ActivityReportModel, useDeleteReportTemplate } from '@api';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { routes } from '@routes';
import { DateTime, reportToQueryParams } from '@utils';
import { ConfirmDialog, MaterialIcon } from '@components';
import { ReportCopyLink } from '@organisms/ReportForm/ReportCopyLink';
import { ReportItemPreviewActivity } from '@organisms/ReportForm/ReportPreviewActivity';
import { useGlobalNotifier } from '@hooks';

const ReportItemContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const ReportItemIconContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export interface ReportItemProps {
  report: ActivityReportModel;
}

export function ReportListItem({ report }: ReportItemProps) {
  const editPath = useMemo(() => {
    const qp = reportToQueryParams(report);
    return `${generatePath(routes.EditReport.path, {
      id: report.id,
    })}?${qp.toString()}`;
  }, [report]);

  return (
    <ReportItemContainer>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Link component={RouterLink} to={editPath} color="inherit" variant="body2">
          {report.name}
        </Link>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <ReportItemCreatedAt report={report} />
          <ReportItemSchedule report={report} />
          <ReportCopyLink reportId={report.id} onlyIcon />
          <ReportItemPreviewActivity report={report} onlyIcon />
          <ReportItemDelete report={report} />
        </Stack>
      </Stack>
    </ReportItemContainer>
  );
}

function ReportItemCreatedAt({ report }: ReportItemProps) {
  return (
    <Tooltip title="Created at" arrow placement="top">
      <Typography variant="tooltip" color="text.muted">
        {DateTime.fromUnix(report.created_date).readable}
      </Typography>
    </Tooltip>
  );
}

function ReportItemSchedule({ report }: ReportItemProps) {
  if (!report.schedule) return <ReportItemIconContainer></ReportItemIconContainer>;

  return (
    <Tooltip title="Schedule info will be here" arrow placement="top">
      <div>
        <MaterialIcon symbol="event_available" color="success" />
      </div>
    </Tooltip>
  );
}

function useReportItemDelete(report: ActivityReportModel) {
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);
  const {
    deleteReportTemplate,
    isDeleteReportTemplateLoading: isDeleteLoading,
    isReportTemplateDeleted: isDeleted,
  } = useDeleteReportTemplate();
  const { notifySuccess } = useGlobalNotifier();

  const handleDelete = useCallback(() => {
    deleteReportTemplate(report.id);
  }, [report, deleteReportTemplate]);

  const closeConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpened(false);
  }, [setIsConfirmDeleteOpened]);

  const handleDeleteConfirm = useCallback(() => {
    setIsConfirmDeleteOpened(true);
  }, [setIsConfirmDeleteOpened]);

  useEffect(() => {
    if (isDeleted) {
      notifySuccess('Report deleted');
    }
  }, [isDeleted, notifySuccess]);

  return {
    isConfirmDeleteOpened,
    isDeleteLoading,
    handleDelete,
    handleDeleteConfirm,
    closeConfirmDelete,
  };
}

function ReportItemDelete({ report }: ReportItemProps) {
  const { isConfirmDeleteOpened, isDeleteLoading, handleDelete, handleDeleteConfirm, closeConfirmDelete } =
    useReportItemDelete(report);

  const actionButton = useMemo(
    () =>
      isDeleteLoading ? (
        <CircularProgress size="small" />
      ) : (
        <Tooltip title="Delete report" arrow placement="top">
          <IconButton onClick={handleDeleteConfirm}>
            <MaterialIcon symbol="delete" />
          </IconButton>
        </Tooltip>
      ),
    [isDeleteLoading, handleDeleteConfirm],
  );

  return (
    <>
      {actionButton}
      <ConfirmDialog open={isConfirmDeleteOpened} onClose={closeConfirmDelete} onConfirm={handleDelete}>
        Are you sure you want to delete <strong>{report.name}</strong> report?
      </ConfirmDialog>
    </>
  );
}
