import { useEffect } from 'react';

import { useGlobalNotifier, useRequiredParams } from '@hooks';
import { ErrorPage } from '@pages';
import { Loader } from '@components';

import { useAppUpdateWebhook } from '../services/webhooksMutations';
import { useAppGetWebhook } from '../services/webhooksQueries';
import WebhookForm from '../organisms/WebhookForm';

export function EditWebhookPage() {
  const { id } = useRequiredParams(['id']);
  const { notifySuccess, notifyServerError } = useGlobalNotifier();

  const { webhook, isFetched } = useAppGetWebhook(id);
  const { updateWebhook, isLoading, isSuccess } = useAppUpdateWebhook(id);

  useEffect(() => {
    if (isSuccess) {
      notifySuccess('Webhook successfully updated ');
    }
  }, [isSuccess, notifySuccess, notifyServerError]);

  if (!isFetched) {
    return <Loader />;
  }

  if (!webhook) {
    return <ErrorPage errorCode={404} errorMessage="Webhook not found" />;
  }

  return <WebhookForm loading={isLoading} webhook={webhook} onSubmit={updateWebhook} />;
}
