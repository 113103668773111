import { Page } from '@components';
import { routes } from '@routes';
import { CatalogV2 } from '@organisms';

export function CatalogPageV2() {
  return (
    <Page
      title="Integrations"
      tabs={[
        {
          label: 'Connected',
          to: routes.ConnectedIntegrations.path,
        },
        {
          label: 'Catalog',
          to: routes.Catalog.path,
        },
      ]}
    >
      <CatalogV2 />
    </Page>
  );
}
