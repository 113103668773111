import { useRef } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

export const useAppQuery = <TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  ...args: Parameters<typeof useQuery<TQueryFnData, TError, TData, TQueryKey>>
) => {
  const previousDataRef = useRef<TData>();
  const result = useQuery(...args);

  let currentData = result.data;

  if (!result.isError) {
    previousDataRef.current = result.data;
  } else if (previousDataRef.current) {
    currentData = previousDataRef.current;
  }

  return {
    ...result,
    isFetched: !!currentData,
    data: currentData,
  };
};
