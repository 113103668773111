import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getFragmentData } from '@api/gql';

import GenericAsyncFilterMultiSelect from '@common/organisms/CommonFilters/GenericAsyncFilterMultiSelect';
import { ResourcesType_Fragment } from '@Integrations/services/gql';
import { useResourceTypesFilterOptions } from '@Integrations/services/use-resources-filter-options';

import { FiltersProps } from './filters-props.interface';
import { RESOURCE_TYPE_FILTER_LABEL, RESOURCE_TYPE_FILTER_PARAM } from '@common/organisms/CommonFilters/constants';

export default function ResourceTypesFilterAsync({ integrationId, search }: FiltersProps) {
  const [searchParams] = useSearchParams();
  const [enabled] = useState(() => searchParams.has(RESOURCE_TYPE_FILTER_PARAM));

  const { isFetched, data, refetch } = useResourceTypesFilterOptions({
    args: {
      id: integrationId,
      search,
    },
    enabled,
  });

  const statusOptions = useMemo(() => {
    const resourceTypesData = [
      ...getFragmentData(ResourcesType_Fragment, data?.integrationResourcesFilterOptions.resourceTypes || []),
    ];

    return resourceTypesData.map((rt) => ({
      icon: rt.icons.svg,
      label: rt.typeDisplayName,
      value: rt.type,
    }));
  }, [data?.integrationResourcesFilterOptions.resourceTypes]);

  return (
    <GenericAsyncFilterMultiSelect
      options={statusOptions}
      filterLabel={RESOURCE_TYPE_FILTER_LABEL}
      filterParam={RESOURCE_TYPE_FILTER_PARAM}
      isOptionsFetched={isFetched}
      refetchOptions={refetch}
    />
  );
}
