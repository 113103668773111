import { useMemo, useState } from 'react';

import {
  InteractiveDropdown,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
  Popover,
} from '@components';
import { AccessFlowAreaModel, GranteeModel, GranteeTypeModel } from '@api';
import { yup } from '@libs';
import { DropdownContextAttributeView, DropdownGroupView, DropdownUserView } from './common/DropdownIdpView';
import { DropdownEmailView } from './common/DropdownEmailView';
import { HumanReadableGrantee } from './HumanReadable';
import { useErrorText } from './common/use-error-text.hook';
import { useAccessFlowsPack, useGroups } from '@hooks';
import { useAttributeOptions } from './common/use-attributes-options';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

const DEFAULT_PLACEHOLDER = 'Select condition';

interface FlowFormSelectConditionsProps {
  template?: string;
  disabledGranteeTypes?: string[];
  value?: GranteeModel[];
  error?: yup.ValidationError;
  initGranteeType?: string;
  onChange: (value: GranteeModel[]) => void;
}

export default function FlowFormSelectConditions({
  template,
  disabledGranteeTypes,
  value,
  error,
  initGranteeType,
  onChange,
}: FlowFormSelectConditionsProps) {
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();
  const { refetchPack } = useAccessFlowsPack();

  const initView = useMemo(() => (initGranteeType ? 1 : 0), [initGranteeType]);

  const [templatePlaceholder, setTemplatePlaceholder] = useState<string | undefined>(template);
  const [currentView, setCurrentView] = useState(initView);
  const [granteeType, setGranteeType] = useState<string | undefined>(initGranteeType);
  const { track } = useAnalyticsContext();

  const resetState = () => {
    setCurrentView(initView);
    setGranteeType(initGranteeType);
  };

  const handleGranteeTypeChange = (type: string) => {
    setGranteeType(type);
    setCurrentView(1);
  };

  const handleOnBackClick = () => resetState();

  const onOpen = () => {
    refetchPack();
    hideErrorText();
  };

  const onClose = () => {
    showErrorText();
    resetState();
  };

  const handleGranteeChange = (val: GranteeModel[]) => {
    onChange(val);
    if (templatePlaceholder) setTemplatePlaceholder(undefined);
    track(CustomAnalyticsEvents.SELECT_GRANTEE, {
      granteeType: val,
    });
  };

  return (
    <Popover
      testId="flow-form-select-conditions"
      trigger={
        <HumanReadableGrantee
          value={value || []}
          placeholder={templatePlaceholder || DEFAULT_PLACEHOLDER}
          placeholderMuted
          errorText={error?.message}
          hideErrorText={errorTextHidden}
        />
      }
      onOpen={onOpen}
      onClose={onClose}
    >
      <InteractiveDropdown multipleViews currentView={currentView} wide>
        <DropdownGranteeTypeView
          current={currentView === 0}
          value={granteeType}
          onChange={handleGranteeTypeChange}
          disabledGranteeTypes={disabledGranteeTypes}
        />
        <DropdownGranteeView
          current={currentView === 1}
          granteeType={granteeType}
          value={value || []}
          onChange={handleGranteeChange}
          onBackClick={!initGranteeType ? handleOnBackClick : undefined}
        />
      </InteractiveDropdown>
    </Popover>
  );
}

function DropdownGranteeTypeView({
  current,
  disabledGranteeTypes,
  value,
  onChange,
}: {
  current: boolean;
  value?: string;
  disabledGranteeTypes?: string[];
  onChange: (value: string) => void;
}) {
  const { groups, doneOnce } = useGroups();
  const { attributeOptions, isAttributesFetched } = useAttributeOptions({
    flowArea: AccessFlowAreaModel.Grantee,
    lookMultiple: false,
    labelPrefix: 'User in',
  });

  const options = useMemo(() => {
    function typedOption(type: GranteeTypeModel, label: string) {
      return {
        key: type,
        value: label,
        hasNextView: true,
        lookMultiple: false,
        isDisabled: disabledGranteeTypes?.includes(type),
      };
    }

    const selectOptions: InteractiveDropdownSelectOption<string>[] = [];

    selectOptions.push(typedOption(GranteeTypeModel.User, 'User in IdP'));

    if (groups && groups.length > 0) {
      selectOptions.push(typedOption(GranteeTypeModel.Group, 'User in Group'));
    }

    if (attributeOptions.length > 0) {
      for (const attrOption of attributeOptions) {
        selectOptions.push({
          ...attrOption,
          lookMultiple: false,
          isDisabled: disabledGranteeTypes?.includes(attrOption.key),
        });
      }
    }

    return selectOptions;
  }, [groups, attributeOptions, disabledGranteeTypes]);

  const isLoading = !(doneOnce && isAttributesFetched);

  if (isLoading) {
    return <InteractiveDropdownView current loading />;
  }

  return (
    <InteractiveDropdownView current={current} header={<InteractiveDropdownHeader title="Select condition" />}>
      <InteractiveDropdownSelect<string> options={options} value={value} onChange={onChange} hideEmptyState />
    </InteractiveDropdownView>
  );
}

function DropdownGranteeView({
  current,
  granteeType,
  onBackClick,
  value,
  onChange,
}: {
  current: boolean;
  granteeType?: string;
  value: GranteeModel[];
  onChange: (value: GranteeModel[]) => void;
  onBackClick?: () => void;
}) {
  const [granteeTypeModel, granteeTypeCategoryKey] = useMemo(() => {
    if (!granteeType) return [undefined, undefined];
    const parts = granteeType.split('/');
    return [parts[0] as GranteeTypeModel, parts.length > 1 ? parts[1] : undefined];
  }, [granteeType]);

  const handleChange = (type: GranteeTypeModel, typeValue: string[]) => {
    const filteredByType = value.filter((v) => v.type !== type);
    const newValue = filteredByType.concat(typeValue.map((v) => ({ type, id: v })));
    onChange(newValue);
  };

  const commonProps = {
    current,
    onBackClick,
    value: value.filter((v) => v.type === granteeTypeModel).map((v) => v.id),
  };

  switch (granteeTypeModel) {
    case GranteeTypeModel.Group:
      return <DropdownGroupView {...commonProps} onChange={(val) => handleChange(GranteeTypeModel.Group, val)} />;
    case GranteeTypeModel.User:
      return <DropdownUserView {...commonProps} onChange={(val) => handleChange(GranteeTypeModel.User, val)} />;
    case GranteeTypeModel.ExternalEmail:
      return (
        <DropdownEmailView {...commonProps} onChange={(val) => handleChange(GranteeTypeModel.ExternalEmail, val)} />
      );
    case GranteeTypeModel.ContextAttribute:
      if (granteeTypeCategoryKey) {
        return (
          <DropdownContextAttributeView
            {...commonProps}
            contextKey={granteeTypeCategoryKey}
            onChange={(val) => handleChange(GranteeTypeModel.ContextAttribute, val)}
          />
        );
      }
  }
  return null;
}
