import { MARGIN, PIE_CHART_DIMENSIONS } from '@DashboardV2/common/utils';
import { Box, Stack, styled } from '@mui/material';
import { EmptyState } from '@components';

const EmptyPieWrapper = styled(Box)({
  width: PIE_CHART_DIMENSIONS + 2 * MARGIN,
  overflow: 'hidden',
  alignSelf: 'center',
});

interface PieChartEmptyStateProps {
  title: string;
  subtitle?: string;
  action?: React.ReactNode;
  size?: { width: number; height: number };
  animationSrc: string;
}

export function PieChartEmptyState({ title, subtitle, action, size, animationSrc }: PieChartEmptyStateProps) {
  return (
    <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
      <EmptyPieWrapper>
        <EmptyState imgSrc={animationSrc} title={title} body={subtitle} action={action} size={size} />
      </EmptyPieWrapper>
    </Stack>
  );
}
