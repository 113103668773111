import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ListFilters from '@components/ListFilters/ListFilters';
import { APPROVAL_FILTER_PARAM, STATUS_FILTER_PARAM, TRIGGER_TYPE_FILTER_PARAM } from '@AccessFlows/common/constants';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import ApprovalFilter, { APPROVAL_FILTER_LABEL } from './AccessFlowsFilters/ApprovalFilter';
import StatusFilter, { STATUS_FILTER_LABEL } from './AccessFlowsFilters/StatusFilter';
import TriggerTypeFilter, { TRIGGER_TYPE_FILTER_LABEL } from './AccessFlowsFilters/TriggerTypeFilter';
import SearchBarFilter from '@common/organisms/SearchBarFilter';
import GenericFilterMultiSelect, { FilterOption } from '@common/organisms/CommonFilters/GenericFilterMultiSelect';
import {
  RESOURCE_TYPE_FILTER_LABEL,
  RESOURCE_TYPE_FILTER_PARAM,
  BUNDLE_FILTER_LABEL,
  BUNDLE_FILTER_PARAM,
  LABEL_FILTER_LABEL,
  LABEL_FILTER_PARAM,
  INTEGRATION_FILTER_LABEL,
  INTEGRATION_FILTER_PARAM,
  GRANTEE_FILTER_LABEL,
  GRANTEE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/constants';
import LabelFilter from './AccessFlowsFilters/LabelFilter';

export interface AccessFlowsFiltersOptions {
  grantee: FilterOption[];
  integration: FilterOption[];
  resourceType: FilterOption[];
  bundle: FilterOption[];
  label: FilterOption[];
}

interface AccessFlowsFiltersProps {
  options: AccessFlowsFiltersOptions;
}

const DEFAULT_VISIBLE_FILTERS = [
  GRANTEE_FILTER_PARAM,
  INTEGRATION_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_PARAM,
  APPROVAL_FILTER_PARAM,
];

export default function AccessFlowsFilters({ options }: AccessFlowsFiltersProps) {
  const { isEnabled: complexEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);

  const [searchParams, setSearchParams] = useSearchParams();
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const filters = useMemo(() => {
    const baseFilters = [];

    if (!complexEnabled) {
      baseFilters.push({
        name: GRANTEE_FILTER_LABEL,
        queryParam: GRANTEE_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(GRANTEE_FILTER_PARAM)}
            options={options.grantee}
            opened={openedFilter === GRANTEE_FILTER_PARAM}
            filterLabel={GRANTEE_FILTER_LABEL}
            filterParam={GRANTEE_FILTER_PARAM}
          />
        ),
      });
    }

    return [
      ...baseFilters,
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            options={options.integration}
            opened={openedFilter === INTEGRATION_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INTEGRATION_FILTER_PARAM)}
            filterLabel={INTEGRATION_FILTER_LABEL}
            filterParam={INTEGRATION_FILTER_PARAM}
          />
        ),
      },
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            options={options.resourceType}
            opened={openedFilter === RESOURCE_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_TYPE_FILTER_PARAM)}
            filterLabel={RESOURCE_TYPE_FILTER_LABEL}
            filterParam={RESOURCE_TYPE_FILTER_PARAM}
          />
        ),
      },
      {
        name: BUNDLE_FILTER_LABEL,
        queryParam: BUNDLE_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            options={options.bundle}
            opened={openedFilter === BUNDLE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(BUNDLE_FILTER_PARAM)}
            filterLabel={BUNDLE_FILTER_LABEL}
            filterParam={BUNDLE_FILTER_PARAM}
          />
        ),
      },
      {
        name: STATUS_FILTER_LABEL,
        queryParam: STATUS_FILTER_PARAM,
        element: (
          <StatusFilter
            opened={openedFilter === STATUS_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(STATUS_FILTER_PARAM)}
          />
        ),
      },
      {
        name: LABEL_FILTER_LABEL,
        queryParam: LABEL_FILTER_PARAM,
        element: (
          <LabelFilter
            options={options.label}
            opened={openedFilter === LABEL_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(LABEL_FILTER_PARAM)}
          />
        ),
      },
      {
        name: APPROVAL_FILTER_LABEL,
        queryParam: APPROVAL_FILTER_PARAM,
        element: (
          <ApprovalFilter
            opened={openedFilter === APPROVAL_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(APPROVAL_FILTER_PARAM)}
          />
        ),
      },
      {
        name: TRIGGER_TYPE_FILTER_LABEL,
        queryParam: TRIGGER_TYPE_FILTER_PARAM,
        element: (
          <TriggerTypeFilter
            opened={openedFilter === TRIGGER_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(TRIGGER_TYPE_FILTER_PARAM)}
          />
        ),
      },
    ];
  }, [complexEnabled, openedFilter, options]);

  const [visibleFilters, setVisibleFilters] = useState<string[]>(() => {
    const searchParamsVisibleFilters = [];
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam) && !DEFAULT_VISIBLE_FILTERS.includes(filter.queryParam)) {
        searchParamsVisibleFilters.push(filter.queryParam);
      }
    }

    return [...DEFAULT_VISIBLE_FILTERS, ...searchParamsVisibleFilters];
  });

  useEffect(() => {
    const filtersFromQP: string[] = [];
    for (const filterParam of searchParams.keys()) {
      if (!visibleFilters.includes(filterParam)) {
        filtersFromQP.push(filterParam);
      }
    }

    const stillVisibleFilters: string[] = [];
    for (const visibleFilter of visibleFilters) {
      if (
        searchParams.has(visibleFilter) ||
        DEFAULT_VISIBLE_FILTERS.includes(visibleFilter) ||
        visibleFilter === openedFilter
      ) {
        stillVisibleFilters.push(visibleFilter);
      }
    }

    if (filtersFromQP.length > 0 || stillVisibleFilters.length !== visibleFilters.length) {
      setVisibleFilters([...stillVisibleFilters, ...filtersFromQP]);
    }
  }, [openedFilter, searchParams, visibleFilters]);

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  const handleOnOnAddFilter = (qp: string) => {
    setVisibleFilters((prev) => [...prev, qp]);
    setOpenedFilter(qp);
  };

  return (
    <ListFilters
      visibleFilters={visibleFilters}
      filters={filters}
      onAddFilter={handleOnOnAddFilter}
      onClear={hasActiveFilters ? handleOnClear : undefined}
      prefix={<SearchBarFilter placeholder="Search by name, grantee & resource type" />}
    />
  );
}
