import { getFragmentData } from '@api/gql';
import { Loader } from '@components';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { useAccessFlowUsageStats, accessFlowsUsageStatsFields } from '@DashboardV2/services/gql';
import { aponoColors } from '@utils';
import { AccessFlowsUsageWidget } from '@DashboardV2/components/AccessFlowsUsageWidget';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';

export const CUSTOM_COLORS = [aponoColors.success[500], aponoColors.neutral[500]];

export function AccessFlowsUsage() {
  return (
    <WidgetCard
      title="Access Flows Usage"
      subtitle="Last 30 days"
      tooltip="How many Access Flows have not been triggered at all by access requests"
    >
      <AccessFlowsUsageContent />
    </WidgetCard>
  );
}

function AccessFlowsUsageContent() {
  const { data, isFetched } = useAccessFlowUsageStats();

  const accessFlowUsageStats = getFragmentData(accessFlowsUsageStatsFields, data?.accessFlowsUsageStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (!accessFlowUsageStats || (!accessFlowUsageStats.requested && !accessFlowUsageStats.notRequested)) {
    return (
      <PieChartEmptyState
        title="No data available"
        animationSrc={'/static/EmptyStateImages/no-data-pie-chart.svg'}
        size={{ width: 100, height: 75 }}
      />
    );
  }

  return <AccessFlowsUsageWidget data={accessFlowUsageStats} colors={CUSTOM_COLORS} />;
}
