import { useAppGetIntegration } from '@api';
import { Loader, Page } from '@components';
import { useFlagFixed, useRequiredParams } from '@hooks';
import { ErrorPage } from '@pages';
import { routes } from '@routes';

import IntegrationResourcesPaginatedOld from '@Integrations/organisms/IntegrationResourcesPaginatedOld';
import IntegrationResourcesPaginated from '@Integrations/organisms/IntegrationResourcesPaginated';
import { Flag } from '@utils';

export default function IntegrationResourcesPage() {
  const { id } = useRequiredParams(['id']);
  const { isIntegrationFetched, integration, integrationConfig } = useAppGetIntegration(id);
  const { isEnabled: isResourceOwnersEnabled } = useFlagFixed(Flag.RESOURCE_OWNERS);

  const tabs = [
    {
      label: 'Integrations',
      href: routes.ConnectedIntegrations.path,
    },
  ];

  if (!isIntegrationFetched) {
    return (
      <Page breadcrumbs={tabs} title="Loading...">
        <Loader />
      </Page>
    );
  }

  if (!integration || !integrationConfig) {
    return (
      <Page breadcrumbs={tabs} title="Error">
        <ErrorPage errorCode={404} />
      </Page>
    );
  }

  return (
    <Page breadcrumbs={tabs} title={`${integration.name} Resources`}>
      {isResourceOwnersEnabled ? (
        <IntegrationResourcesPaginated {...{ integration, integrationConfig }} />
      ) : (
        <IntegrationResourcesPaginatedOld {...{ integration, integrationConfig }} />
      )}
    </Page>
  );
}
