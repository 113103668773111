import { RadioGroup, Stack, Typography } from '@mui/material';

import { IntegrationConfigAppModelV2 } from '@api';
import { ProIconType, ProSizes, RadioBox, RadioBoxLabelComplex, UpgradeWrapper } from '@components';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export interface SelectConfigProps {
  groupedConfigs: IntegrationConfigAppModelV2[];
  selectedConfigType?: string;
  onSelect: (type: string) => void;
  withDescription?: boolean;
  align?: 'left' | 'center';
}

function useSelectConfig({ groupedConfigs, onSelect }: SelectConfigProps) {
  const { track } = useAnalyticsContext();

  const handleConfigSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const config = groupedConfigs.find((c) => c.type === event.target.value);
    if (config) {
      track(CustomAnalyticsEvents.ADD_INTEGRATION_ENVIRONMENT_SELECTED, {
        configType: config.type,
        configName: config.name,
      });
      onSelect(config.type);
    }
  };

  return {
    handleConfigSelect,
  };
}

export function SelectConfig({
  groupedConfigs,
  selectedConfigType,
  onSelect,
  withDescription = true,
  align = 'left',
}: SelectConfigProps) {
  const { handleConfigSelect } = useSelectConfig({ groupedConfigs, selectedConfigType, onSelect });

  return (
    <Stack direction="column" justifyContent="center" alignItems={align} spacing={2} mb={6}>
      <Stack direction="column" justifyContent="center" alignItems={align}>
        <Typography variant="subtitle1">Select an environment to integrate</Typography>
        {withDescription && (
          <Typography variant="body2" color="text.filter" component="p" textAlign={align}>
            Apono automatically discovers all the resources in the environment. Then, start managing access to resources
            with Access Flows!
          </Typography>
        )}
      </Stack>
      <RadioGroup value={selectedConfigType} onChange={handleConfigSelect}>
        <Stack direction="row" justifyContent="left" alignItems="stretch" spacing={2}>
          {groupedConfigs.map((c) => (
            <UpgradeWrapper
              key={c.type as React.Key}
              isPremium={c.premium}
              size={ProSizes.Large}
              withPopover
              iconType={ProIconType.IconWithText}
              overly
            >
              <RadioBox
                key={c.type as React.Key}
                value={c.type}
                label={<RadioBoxLabelComplex iconPath={c.icons.svg} title={c.name} description={c.description} />}
                labelPlacement="top"
                sx={{
                  width: '300px',
                }}
              />
            </UpgradeWrapper>
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
