import { useEffect, useState } from 'react';
import { useAuthUser, useLocalStorage } from '@libs';

import { useGroupsFromState, useUsersFromState } from './accounts.hooks';
import { useListAttribute, useListIntegrationsV2 } from '@api';
import { AccessFlowFormData } from '@AccessFlows/common/types';

export function useAccessFlowsPack() {
  const { users, isUsersLoading, refetchUsers } = useUsersFromState();
  const { groups, isGroupsLoading, refetchGroups } = useGroupsFromState();
  const { isContextsAttributesFetched, contextsAttributes, refetchContextsAttributes } = useListAttribute();

  const {
    integrations: connectedIntegrations,
    isIntegrationsFetched: isConnectedIntegrationsFetched,
    configs: catalog,
  } = useListIntegrationsV2();

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (
      isFetched ||
      isUsersLoading ||
      isGroupsLoading ||
      !isConnectedIntegrationsFetched ||
      !isContextsAttributesFetched
    ) {
      return;
    }

    setIsFetched(true);
  }, [isFetched, isGroupsLoading, isConnectedIntegrationsFetched, isUsersLoading, isContextsAttributesFetched]);

  const refetchPack = () => {
    refetchUsers();
    refetchGroups();
    refetchContextsAttributes();
  };

  return {
    isLoading: isUsersLoading || isGroupsLoading || !isContextsAttributesFetched || !isConnectedIntegrationsFetched,
    isFetched,
    users,
    groups,
    contextsAttributes,
    catalog,
    connectedIntegrations,
    refetchPack,
  };
}

const DRAFT_KEY = 'access_flow_draft';

export function useAccessFlowDraft() {
  const user = useAuthUser();
  const [accessFlowDraft, setDraft] = useLocalStorage<AccessFlowFormData | null>(
    `${user.id}.${user.tenantId}.${DRAFT_KEY}`,
    null,
  );

  const handleAccessFlowDraftChange = (value: AccessFlowFormData) => {
    setDraft(value);
  };

  const clearAccessFlowDraft = () => {
    setDraft(null);
  };

  return {
    accessFlowDraft,
    handleAccessFlowDraftChange,
    clearAccessFlowDraft,
  };
}
