import { Link } from 'react-router-dom';
import { Box, Button, Stack, styled } from '@mui/material';

import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { routes } from '@routes';
import { Loader } from '@components';

import { ProState } from '@components/Packages/ProState';

import { useScrollToTop } from '@AccessFlows/hooks/shared';
import BundlesList from '@AccessFlows/organisms/BundlesList';
import useBundlesFetch from '@AccessFlows/hooks/use-bundles-fetch';
import BundlesFilters from '@AccessFlows/organisms/BundlesFilters';
import TableListTotal from '@common/components/TableListTotal';

const ListHeader = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export default function BundlesPage() {
  const { isPremium: isPremiumBundlesPage } = useFlagFixed(Flag.UI_BUNDLES);

  return isPremiumBundlesPage ? <ProState /> : <BundlesPageContent />;
}

function BundlesPageContent() {
  useScrollToTop();

  const { bundlesList, loadingProgress, filterOptions, totalBundles } = useBundlesFetch();

  if (loadingProgress < 100) {
    return <Loader widthProgress value={loadingProgress} />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <ListHeader>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <BundlesFilters options={filterOptions} />
          <Stack direction="row" alignItems="center" spacing={2}>
            <TableListTotal totalResults={totalBundles} displayedResults={bundlesList.length} />
            <Button color="primary" size="medium" component={Link} to={`${routes.AddBundle.path}`} variant="contained">
              Create Bundle
            </Button>
          </Stack>
        </Stack>
      </ListHeader>
      <BundlesList bundlesItems={bundlesList} />
    </Stack>
  );
}
