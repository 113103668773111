import { ReactElement } from 'react';
import { adminLayoutWrap } from '@middlewares';
import { Flag } from '@utils';
import {
  ActiveAccessPage,
  AddPersonalApiTokenPage,
  AddReportPage,
  AdminBoxPage,
  CatalogPageV2,
  EditReportPage,
  HomePage,
  InstallConnectorPage,
  PersonalApiTokensPage,
  ReportsPage,
  SettingsPage,
} from '@pages';
import { ConnectorsPage } from '@pages/ConnectorsPage/ConnectorsPage';
import { EditConnectorPage } from '@pages/EditConnectorPage';
import { SearchPage } from '@pages/SearchPage';
import { ActivityPage } from './Activity/Audit/ActivityPage';
import { routes as webhookRoutes } from './Webhooks/routes';
import { routes as onboardingRoutes } from '@Onboarding/routes';
import { routes as integrationsRoutes } from '@Integrations/routes';
import { routes as accessFlowsRoutes } from '@AccessFlows/routes';
import { routes as identitiesRoutes } from '@Identities/routes';
import { routes as auditEventsRoutes } from '@AuditEvents/routes';
import { routes as dashboardRoutes } from '@DashboardV2/routes';
import GroupsPage from '@Groups/pages/GroupsPage';
import { AccessGraphPage } from './AccessGraph/pages/AccessGraphPage';
import { AccessAlertsPage } from './AccessAlerts/pages/AccessAlertsPage';

export interface PageRoute {
  label: string;
  path: string;
  page: ReactElement;
}

export const routes = {
  Search: {
    label: 'Search',
    path: '/search',
    page: adminLayoutWrap(<SearchPage />),
  },
  Activity: {
    label: 'Audit',
    path: '/activity',
    page: adminLayoutWrap(<ActivityPage />),
  },
  Groups: {
    label: 'Groups',
    path: '/groups',
    page: adminLayoutWrap(<GroupsPage />),
  },
  EditConnector: {
    label: 'Edit Connector',
    path: '/connectors/edit/:id',
    page: adminLayoutWrap(<EditConnectorPage />),
  },
  InstallConnector: {
    label: 'Add Connector',
    path: '/connectors/install',
    page: adminLayoutWrap(<InstallConnectorPage />),
  },
  Connectors: {
    label: 'Connectors',
    path: '/connectors',
    page: adminLayoutWrap(<ConnectorsPage />),
  },
  PersonalApiTokens: {
    label: 'Personal API Tokens',
    path: '/personal-api-tokens',
    page: adminLayoutWrap(<PersonalApiTokensPage />, Flag.NEW_UI_PERSONAL_TOKENS),
  },
  AddPersonalApiToken: {
    label: 'Add Personal API Token',
    path: '/personal-api-tokens/add',
    page: adminLayoutWrap(<AddPersonalApiTokenPage />, Flag.NEW_UI_PERSONAL_TOKENS),
  },
  Settings: {
    label: 'Settings',
    path: '/settings',
    page: adminLayoutWrap(<SettingsPage />),
  },
  Catalog: {
    label: 'Catalog',
    path: '/catalog',
    page: adminLayoutWrap(<CatalogPageV2 />),
  },
  TemplateSuggestions: {
    label: 'Templates',
    path: '/template-suggestions',
    page: adminLayoutWrap(<HomePage />, Flag.OVERVIEW_TEMPLATE_SUGGESTION),
  },
  Reports: {
    label: 'Reports',
    path: '/reports',
    page: adminLayoutWrap(<ReportsPage />),
  },
  AddReport: {
    label: 'Add Report',
    path: '/reports/add',
    page: adminLayoutWrap(<AddReportPage />),
  },
  EditReport: {
    label: 'Edit Report',
    path: '/reports/edit/:id',
    page: adminLayoutWrap(<EditReportPage />),
  },
  ActiveAccess: {
    label: 'Active Access',
    path: '/active-access',
    page: adminLayoutWrap(<ActiveAccessPage />, Flag.NEW_UI_ACTIVE_ACCESS),
  },
  AccessGraph: {
    label: 'Access Graph',
    path: '/access-graph',
    page: adminLayoutWrap(<AccessGraphPage />, Flag.APONO_ACCESS_GRAPH),
  },
  AccessAlerts: {
    label: 'Anomalies',
    path: '/access-anomalies',
    page: adminLayoutWrap(<AccessAlertsPage />, Flag.ACCESS_ANOMALIES),
  },
  AdminBoxPage: {
    label: 'Admin Box',
    path: '/admin-box',
    page: adminLayoutWrap(<AdminBoxPage />),
  },
  ...onboardingRoutes,
  ...accessFlowsRoutes,
  ...webhookRoutes,
  ...integrationsRoutes,
  ...identitiesRoutes,
  ...auditEventsRoutes,
  ...dashboardRoutes,
};
