import { graphql } from '@api/gql';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@AuditEvents/services/queryKeys';
import { FrontierService } from '@api';

const auditEventsUsersQuery = graphql(/* GraphQL */ `
  query AuditEventsUsers {
    auditEventsUsers {
      id
      email
      firstName
      lastName
    }
  }
`);

const auditEventsIntegrationsQuery = graphql(/* GraphQL */ `
  query AuditEventsIntegrations {
    auditEventsIntegrations {
      id
      name
      type
      typeDisplayName
      icons {
        svg
      }
    }
  }
`);

const auditEventTypesQuery = graphql(/* GraphQL */ `
  query AuditEventTypes {
    auditEventTypes
  }
`);

export function useAuditEventsUsers(enabled?: boolean) {
  return useQuery([QueryKeys.AuditEventsUsers], () => FrontierService.request(auditEventsUsersQuery), {
    enabled,
  });
}

export function useAuditEventsIntegrations(enabled?: boolean) {
  return useQuery([QueryKeys.AuditEventsIntegrations], () => FrontierService.request(auditEventsIntegrationsQuery), {
    enabled,
  });
}

export function useAuditEventTypes(enabled?: boolean) {
  return useQuery([QueryKeys.AuditEventTypes], () => FrontierService.request(auditEventTypesQuery), {
    enabled,
  });
}
