import { useEffect } from 'react';

import { ReportForm } from '@organisms';
import { Page } from '@components';
import { useCreateReportTemplate } from '@api';
import { routes } from '@routes';
import { useNavigate } from 'react-router-dom';

function useAddReportPage() {
  const { createReportTemplate, isCreateReportTemplateLoading, isReportTemplateCreated } = useCreateReportTemplate();
  const navigate = useNavigate();

  useEffect(() => {
    if (isReportTemplateCreated) {
      navigate(routes.Reports.path);
    }
  }, [isReportTemplateCreated, navigate]);

  return {
    loading: isCreateReportTemplateLoading,
    onChange: createReportTemplate,
  };
}

export function AddReportPage() {
  const { loading, onChange } = useAddReportPage();
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Audit',
          href: routes.Activity.path,
        },
        {
          label: 'Reports',
          href: routes.Reports.path,
        },
      ]}
      title="Add Report"
    >
      <ReportForm loading={loading} onChange={onChange} />
    </Page>
  );
}
