import { aponoColors } from '@utils';

export const RESOURCE_TYPE_FILTER_PARAM = 'resource_type';

export const AVAILABLE_COLORS = [
  aponoColors.secondary[400],
  aponoColors.neutral[400],
  aponoColors.primary[400],
  aponoColors.tertiary[400],
  aponoColors.success[400],
  aponoColors.warning[400],
  aponoColors.error[400],
  aponoColors.primary[700],
];

export const AccessStatusModel = {
  Initializing: 'Initializing',
  Pending: 'Pending',
  Granting: 'Granting',
  Rejected: 'Rejected',
  Active: 'Active',
  Revoking: 'Revoking',
  Revoked: 'Revoked',
  Failed: 'Failed',
  // BC
  PENDING: 'PENDING',
  PendingMFA: 'PendingMFA',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  GRANTED: 'GRANTED',
  REVOKING: 'REVOKING',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccessStatusModel = (typeof AccessStatusModel)[keyof typeof AccessStatusModel];

export const AttributeModelStatus = {
  Active: 'Active',
  Deleted: 'Deleted',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AttributeModelStatus = (typeof AttributeModelStatus)[keyof typeof AttributeModelStatus];
