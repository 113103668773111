import { useMemo } from 'react';

import { useReportContext } from './ReportProvider';
import { MaterialIcon, ProButton } from '@components';
import { Flag } from '@utils';

export function ReportExportButton() {
  const { columns, reportPreview, exportReportFile } = useReportContext();

  const isDisabled = useMemo(() => columns.length === 0 || reportPreview.length === 0, [columns, reportPreview]);

  return (
    <ProButton
      flag={Flag.PREMIUM_DOWNLOAD_REPORT}
      variant="outlined-filters"
      size="small"
      endIcon={<MaterialIcon symbol="system_update_alt" />}
      onClick={() => exportReportFile()}
      disabled={isDisabled}
    >
      Download Report
    </ProButton>
  );
}
