import { Page } from '@components';
import { Outlet } from 'react-router-dom';
import { routes } from '@routes';

export default function AuditEventsLayout() {
  return (
    <Page title={routes.AuditEvents.label}>
      <Outlet />
    </Page>
  );
}
