import { Avatar, Stack, Tooltip, Typography } from '@mui/material';

export interface ResourceDetailedItemProps {
  icon?: string;
  iconLocation?: 'flex-start' | 'center';
  displayName: string;
  logicalPath?: string;
  literalPath?: string;
  maxNameLength?: number;
}

const MAX_LENGTH = 30;

export function ResourceDetailedItem({
  icon,
  iconLocation = 'center',
  displayName,
  logicalPath,
  literalPath,
  maxNameLength = MAX_LENGTH,
}: ResourceDetailedItemProps) {
  return (
    <Stack width="100%" direction="row" spacing={1} alignItems={iconLocation}>
      {icon && <Avatar src={icon} sx={{ width: 14, height: 14 }} variant="square" />}
      <Stack direction="column" spacing={0.25}>
        <ResourceDetailedItemDisplayName name={displayName} maxLength={maxNameLength} />

        {(logicalPath || literalPath) && (
          <Stack>
            {logicalPath && (
              <Typography variant="tooltip" color="text.muted">
                {logicalPath}
              </Typography>
            )}
            {literalPath && (
              <Typography variant="tooltip" color="text.muted">
                {literalPath}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function ResourceDetailedItemDisplayName({ name, maxLength }: { name: string; maxLength: number }) {
  const shorterName = `${name.length > maxLength ? name.slice(0, maxLength) + '...' : name}`;

  const nameElem = (
    <Typography variant="body2" noWrap fontWeight={700}>
      {shorterName}
    </Typography>
  );

  if (name.length > shorterName.length) {
    return (
      <Tooltip title={name} arrow placement="left">
        {nameElem}
      </Tooltip>
    );
  }

  return <>{nameElem}</>;
}
