import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Popper,
  PopperProps,
  styled,
  TextField,
  Typography,
} from '@mui/material';

const InlineField = styled(TextField)(({ theme }) => ({
  input: {
    cursor: 'pointer',
    textAlign: 'center',
    ...theme.typography.body2,
    color: theme.palette.text.muted,
    padding: `${theme.spacing(0.2)} !important`,

    '&:hover': {
      color: theme.palette.text.highlight,
    },
  },

  '&.text-align-left': {
    input: {
      textAlign: 'left',
    },
  },

  '&.text-align-right': {
    input: {
      textAlign: 'right',
    },
  },
}));

const InlineAutocomplete = styled(Box)({
  '& .MuiAutocomplete-input': {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },

  '& .MuiAutocomplete-inputRoot': {
    paddingRight: '0 !important',

    '&:before': {
      height: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },

  '&.Mui-focused': {
    '& .MuiAutocomplete-inputRoot': {
      '&:before': {
        visibility: 'visible',
      },
    },
  },
});

const InlineSelectPopper = function (props: PopperProps) {
  return <Popper {...props} style={{ minWidth: 150, maxWidth: 250 }} placement="bottom-start" />;
};

export function InlineSelect<
  T extends { label: string },
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  sx,
  options,
  onOpen,
  onClose,
  onChange,
  value,
  textAlign = 'left',
  ...rest
}: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  textAlign?: 'left' | 'center' | 'right';
}) {
  return (
    <InlineAutocomplete>
      <Autocomplete
        {...rest}
        className="inline-select"
        options={options}
        onOpen={onOpen}
        onClose={onClose}
        onChange={onChange}
        value={value}
        sx={sx}
        renderOption={(props, option) => (
          <li {...props} data-testid="inline-select-option">
            <Typography variant="helperText">{option.label}</Typography>
          </li>
        )}
        renderInput={(params) => {
          params.InputProps.endAdornment = null;
          params.InputProps.startAdornment = null;
          return <InlineField {...params} size="small" variant="standard" className={`text-align-${textAlign}`} />;
        }}
        forcePopupIcon={false}
        PopperComponent={InlineSelectPopper}
        noOptionsText={<Typography variant="helperText">No options</Typography>}
      />
    </InlineAutocomplete>
  );
}
