import { useEffect } from 'react';

import { useAxiosInterceptor, setAccessToken } from '@api';
import { getLoginPartialPath, useAuth, useNavigate, useSessionStorage } from '@libs';

export const AUTH_LS_KEY = '_REDIRECT_AFTER_LOGIN_';

export const useCheckAuthentication = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const loginPath = getLoginPartialPath();
  const [redirectUrlAfterLogin, setRedirectUrlAfterLogin] = useSessionStorage<string | null>(AUTH_LS_KEY, null);
  useAxiosInterceptor();

  if (isAuthenticated && user && user.accessToken) {
    setAccessToken(user.accessToken);
  }

  useEffect(() => {
    if (isAuthenticated || window.location.pathname.includes(loginPath)) return;

    setRedirectUrlAfterLogin(window.location.pathname + window.location.search);
    navigate(loginPath);
  }, [isAuthenticated, navigate, loginPath, setRedirectUrlAfterLogin]);

  useEffect(() => {
    if (!isAuthenticated || !user || !redirectUrlAfterLogin) return;

    navigate(redirectUrlAfterLogin);
    setRedirectUrlAfterLogin(null);
  }, [isAuthenticated, user, redirectUrlAfterLogin, navigate, setRedirectUrlAfterLogin]);

  return isAuthenticated && !!user && !!user.accessToken;
};
