import { Box, Typography } from '@mui/material';
import { AlertTypesItemGrid } from './AlertTypesItemGrid';

export function AlertTypesListHeader() {
  return (
    <Box>
      <AlertTypesItemGrid
        alertType={
          <Typography variant="helperText" color="text.muted">
            Type
          </Typography>
        }
        severity={
          <Typography variant="helperText" color="text.muted">
            Severity
          </Typography>
        }
        count={
          <Typography variant="helperText" color="text.muted">
            Total
          </Typography>
        }
        action={<></>}
      />
    </Box>
  );
}
