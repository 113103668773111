import { TriggerTypeAppModel } from '@api';

export const strToTriggerType = (str: string) => {
  switch (str) {
    case 'AUTO_GRANT':
      return TriggerTypeAppModel.AutoGrant;
    case 'USER_REQUEST':
    default:
      return TriggerTypeAppModel.UserRequest;
  }
};
