import { useListIntegrationsV2, IntegrationConfigAppModelV2, IntegrationAppModel } from '@api';

export function useRemappedIntegrations(enabled?: boolean) {
  const { integrations, configs, isIntegrationsFetched, refetchQuery } = useListIntegrationsV2(undefined, enabled);

  const remappedConfigs = configs.reduce<Record<string, IntegrationConfigAppModelV2>>((acc, config) => {
    acc[config.type] = config;
    return acc;
  }, {});

  const remappedIntegrations = integrations.reduce<
    Record<string, { integration: IntegrationAppModel; config: IntegrationConfigAppModelV2 }>
  >((acc, attr) => {
    const config = remappedConfigs[attr.type];
    if (config) {
      acc[attr.id] = {
        integration: attr,
        config,
      };
    }

    return acc;
  }, {});

  return {
    remappedIntegrations,
    isIntegrationsFetched,
    refetchQuery,
  };
}
