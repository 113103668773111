import { useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { ChartLegends, Loader, WidgetCard, WidgetCardEmpty } from '@components';
import { AccessOverTimeDataPoint } from '@api';
import { DateTime } from '@utils';
import type { Theme } from '@nivo/core';

const ResponsiveBarWrapper = styled(Box)({
  height: 200,
  overflow: 'hidden',
});

export interface RequestsOverTimeCardProps {
  isLoading?: boolean;
  isFetched?: boolean;
  dataPoints: AccessOverTimeDataPoint[];
}

function useColors() {
  const theme = useTheme();

  const colors: { [key: string]: string } = {
    total_revoked: theme.palette.graph.warning,
    total_granted: theme.palette.graph.primary,
    active_access: theme.palette.graph.success,
  };

  return colors;
}

function useRequestsOverTimeCard(dataPoints: RequestsOverTimeCardProps['dataPoints']) {
  const colors = useColors();

  const prepared: { data: BarDatum[]; minValue: number; maxValue: number } = useMemo(() => {
    let min = 0;
    let max = 0;

    const data = dataPoints
      .sort((a, b) => (a.date > b.date ? 1 : -1))
      .map((dataPoint) => {
        const revoked = dataPoint.total_revoked * -1;

        if (revoked < min) {
          min = revoked;
        }
        if (dataPoint.total_granted > max) {
          max = dataPoint.total_granted;
        }

        return {
          date: DateTime.shortDate(Number(dataPoint.date)),
          total_revoked: revoked,
          total_granted: dataPoint.total_granted,
        };
      });

    return {
      data,
      minValue: min - 1,
      maxValue: max + 1,
    };
  }, [dataPoints]);

  const legends = [
    {
      color: colors.total_granted,
      name: 'Granted Access',
    },
    {
      color: colors.total_revoked,
      name: 'Expired Access',
    },
  ];

  return {
    data: prepared.data,
    minValue: prepared.minValue,
    maxValue: prepared.maxValue,
    colors,
    legends,
  };
}

function useGraphTheme(): Theme {
  const theme = useTheme();

  return {
    grid: {
      line: {
        stroke: theme.palette.divider,
      },
    },
    axis: {
      ticks: {
        text: {
          stroke: theme.palette.text.primary,
          fontFamily: theme.typography.fontFamily,
          fontWeight: 200,
        },
      },
    },
  };
}

export function RequestsOverTimeCard({ isLoading = false, isFetched = false, dataPoints }: RequestsOverTimeCardProps) {
  const { data, minValue, maxValue, colors, legends } = useRequestsOverTimeCard(dataPoints);
  const theme = useGraphTheme();

  const title = 'Access Requests Over Time';

  if (!isFetched) {
    return (
      <WidgetCard title={title}>
        <Loader absolute={false} />
      </WidgetCard>
    );
  }

  if (data.length === 0) {
    return (
      <WidgetCard title={title}>
        <WidgetCardEmpty />
      </WidgetCard>
    );
  }

  return (
    <WidgetCard title={title} titleActions={<ChartLegends legends={legends} />} loading={isLoading}>
      <ResponsiveBarWrapper>
        <ResponsiveBar
          data={data}
          keys={['total_granted', 'total_revoked']}
          indexBy="date"
          groupMode="grouped"
          padding={0.5}
          minValue={minValue}
          maxValue={maxValue}
          colors={(b) => colors[b.id]}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.7]] }}
          label={(d) => (d.value ? `${Math.abs(d.value)}` : '0')}
          margin={{
            top: 25,
            right: 10,
            bottom: 25,
            left: 10,
          }}
          layers={['grid', 'axes', 'bars', 'markers', 'legends']}
          enableGridX
          enableGridY={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
          }}
          isInteractive={false}
          theme={theme}
        />
      </ResponsiveBarWrapper>
    </WidgetCard>
  );
}
