import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { IntegrationsService, ResourcePermissions } from '@api';
import { useAsync } from '@hooks';
import { IntegrationInfo } from '../common';

export type FlowFormSelectPermissionsValue = {
  permissions: string[];
};

interface FlowFormSelectPermissionsContextProps {
  singleView: boolean;
  currentView: number;
  isPermissionsFetched: boolean;
  fetchedPermissions: ResourcePermissions[];
  selectedResourceType?: string;
  selectedPermissions: string[];
  handlePermissionsSelect: (permissions: string[]) => void;
  resetToInit: () => void;
}

const noop = () => {
  // noop
};

export const FlowFormSelectPermissionsContext = createContext<FlowFormSelectPermissionsContextProps>({
  singleView: false,
  currentView: 0,
  isPermissionsFetched: false,
  fetchedPermissions: [],
  selectedPermissions: [],
  handlePermissionsSelect: noop,
  resetToInit: noop,
});

export function useFlowFormSelectPermissionsContext() {
  return useContext(FlowFormSelectPermissionsContext);
}

export interface FlowFormSelectPermissionsProps {
  integrationInfo?: IntegrationInfo;
  template?: string;
  value?: FlowFormSelectPermissionsValue;
  onChange: (value: FlowFormSelectPermissionsValue) => void;
}

function getInits(value: FlowFormSelectPermissionsProps['value']) {
  return {
    currentView: 0,
    selectedPermissions: value?.permissions || [],
  };
}

export function useFlowFormSelectPermissionsContextProvider({
  integrationInfo,
  template,
  value,
  onChange,
}: FlowFormSelectPermissionsProps) {
  const [fetchedPermissions, setFetchedPermissions] = useState<ResourcePermissions[]>([]);

  const inits = getInits(value);

  const [singleView, setSingleView] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState(inits.currentView);
  const [selectedResourceType, setSelectedResourceType] = useState<string | undefined>(integrationInfo?.resourceType);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(inits.selectedPermissions);
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string | undefined>(template);

  const { isLoading: isPermissionsLoading, execute: fetchPermissions } = useAsync(async () => {
    if (!integrationInfo?.integrationId) {
      return;
    }

    const { resources_permissions } = await IntegrationsService.getIntegrationPermissionsV2({
      id: integrationInfo?.integrationId,
    });
    const filteredFetchedPermissions = resources_permissions.filter(
      (perm) => perm.resource_type === integrationInfo.resourceType,
    );
    setFetchedPermissions(filteredFetchedPermissions);

    const initials = getInits(value);

    setSelectedResourceType(integrationInfo.resourceType);
    setSingleView(true);

    setSelectedPermissions(initials.selectedPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationInfo?.integrationId, integrationInfo?.resourceType]);

  function handlePermissionsSelect(values: string[]) {
    setSelectedPermissions(values);

    if (!selectedResourceType) return;

    onChange({
      ...value,
      permissions: values,
    });

    if (templatePlaceholder) setTemplatePlaceholder(undefined);
  }

  function resetToInit() {
    const initials = getInits(value);

    setCurrentView(initials.currentView);
    setSelectedResourceType(integrationInfo?.resourceType);
    setSelectedPermissions(initials.selectedPermissions);
  }

  return {
    templatePlaceholder,
    singleView,
    currentView,
    selectedResourceType,
    selectedPermissions,
    isPermissionsFetched: !isPermissionsLoading,
    fetchedPermissions,
    handlePermissionsSelect,
    resetToInit,
    fetchPermissions,
  };
}

export function FlowFormSelectPermissionsContextProvider({
  children,
  ...props
}: PropsWithChildren<FlowFormSelectPermissionsContextProps>) {
  return (
    <FlowFormSelectPermissionsContext.Provider value={props}>{children}</FlowFormSelectPermissionsContext.Provider>
  );
}
