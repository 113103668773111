import { useEffect, useMemo } from 'react';

import { Node, ReactFlow } from 'reactflow';
import { Alert, alpha, Backdrop, Box, CircularProgress, styled } from '@mui/material';

import 'reactflow/dist/style.css';
import { GroupedNodeExpandedProps, NodeTypes } from '@components';
import { AccessNodeFilterModel, useSearchActiveAccess } from '@api';
import { FlowNode, NodesBuilder } from './nodes-builder';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '@utils';
import { SP_FILTER_PREFIX } from './ActiveAccessFilters';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

const MapContainer = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
});

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  zIndex: 5,
}));

const StyledMap = styled(ReactFlow)(({ theme }) => ({
  '& .react-flow__handle': {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.border.button,
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
}));

function useActiveAccessMap() {
  const [searchParams] = useSearchParams();
  const integrationId = useMemo(() => searchParams.get(QueryParams.integrationId), [searchParams]);

  return {
    integrationId,
  };
}

export function ActiveAccessMap() {
  const { integrationId } = useActiveAccessMap();

  if (!integrationId) {
    return (
      <Alert color="warning" icon={false}>
        Please select an integration
      </Alert>
    );
  }

  return <ActiveAccessMapContent integrationId={integrationId} />;
}

interface ActiveAccessMapContentProps {
  integrationId: string;
}

type TypeFilter = Partial<Pick<AccessNodeFilterModel, 'limit' | 'filters'>>;
type TypesMap = Record<string, TypeFilter>;

const SP_LIMIT_PREFIX = 'limit.';

function searchParamsToTypeFilter(searchParams: URLSearchParams): AccessNodeFilterModel[] {
  const typeFilters: TypesMap = {};

  searchParams.forEach((value, key) => {
    if (key.startsWith(SP_FILTER_PREFIX)) {
      const type = key.replace(SP_FILTER_PREFIX, '');

      if (typeFilters[type]) {
        typeFilters[type].filters = [value];
        typeFilters[type].limit = typeFilters[type].limit || 1;
        return;
      }

      typeFilters[type] = {
        filters: [value],
        limit: 1,
      };

      return;
    }

    if (key.startsWith(SP_LIMIT_PREFIX)) {
      const type = key.replace(SP_LIMIT_PREFIX, '');

      if (typeFilters[type]) {
        typeFilters[type].limit = parseInt(value, 10);
        return;
      }

      typeFilters[type] = {
        limit: parseInt(value, 10),
      };
      return;
    }
  });

  return Object.keys(typeFilters).map((type) => ({
    type,
    limit: typeFilters[type].limit || 0,
    filters: typeFilters[type].filters || [],
  }));
}

function useActiveAccessMapContent(integrationId: string) {
  const { track } = useAnalyticsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchActiveAccess, activeAccess, isActiveAccessLoading } = useSearchActiveAccess(integrationId);

  const builder = useMemo(() => new NodesBuilder(activeAccess.edges, activeAccess.nodes), [activeAccess]);

  useEffect(
    () =>
      searchActiveAccess({
        type_filters: searchParamsToTypeFilter(searchParams),
      }),
    [searchActiveAccess, searchParams],
  );

  function handleGroupedClick(event: React.MouseEvent<Element, MouseEvent>, node: Node) {
    const flowNode = node as FlowNode;
    const clickedElem = event.target as HTMLElement;

    if (clickedElem.id !== 'expand-btn') {
      return;
    }

    const sourceNode = (flowNode as Node<GroupedNodeExpandedProps>).data.node;
    if (!sourceNode) return;

    const name = `${SP_LIMIT_PREFIX}${sourceNode.type}`;

    const newSearchParams = new URLSearchParams(searchParams);
    if (flowNode.type === 'groupedExpanded') {
      newSearchParams.set(name, '0');
    } else {
      newSearchParams.set(name, '3');
    }

    setSearchParams(newSearchParams);
  }

  function handleNodeClick(event: React.MouseEvent<Element, MouseEvent>, node: Node) {
    const flowNode = node as FlowNode;
    switch (flowNode.type) {
      case 'groupedExpanded':
      case 'grouped':
      case 'groupedInput':
      case 'groupedOutput': {
        track(CustomAnalyticsEvents.ACCESS_GRAPH_GROUP_EXPANDED);
        return handleGroupedClick(event, node);
      }
    }

    track(CustomAnalyticsEvents.ACCESS_GRAPH_RESOURCES_EXPANDED);
    const newSearchParams = new URLSearchParams(searchParams);

    const sourceNode = builder.getNode(flowNode.id);
    if (!sourceNode) return;

    if (sourceNode.type === 'Action' && !sourceNode.more) return; // disable action

    let name = `${SP_FILTER_PREFIX}${sourceNode.type}`;
    let value = node.id;

    if (sourceNode.more) {
      name = `${SP_LIMIT_PREFIX}${sourceNode.type}`;

      const qpLimit = searchParams.get(name);
      value = '3';
      if (qpLimit) {
        value = (parseInt(qpLimit, 10) + 3).toString();
      }
    }

    newSearchParams.set(name, value);

    setSearchParams(newSearchParams);
  }

  return {
    isLoading: isActiveAccessLoading,
    nodes: builder.getFlowNodes(),
    edges: activeAccess.edges,
    handleNodeClick,
  };
}

function ActiveAccessMapContent({ integrationId }: ActiveAccessMapContentProps) {
  const { nodes, edges, isLoading, handleNodeClick } = useActiveAccessMapContent(integrationId);

  return (
    <MapContainer>
      <MapLoader isLoading={isLoading} />
      <StyledMap
        nodes={nodes}
        edges={edges}
        nodeTypes={NodeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        onNodeClick={handleNodeClick}
        proOptions={{ hideAttribution: true }}
      >
        {!isLoading && nodes.length === 0 && edges.length === 0 && (
          <Alert color="warning" icon={false}>
            No graph data
          </Alert>
        )}
      </StyledMap>
    </MapContainer>
  );
}

function MapLoader({ isLoading }: { isLoading: boolean }) {
  return (
    <StyledBackdrop open={isLoading}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
}
