import { PropsWithChildren } from 'react';
import { useSession } from '@api';
import { Loader } from '@components';
import { intersection, UserRole } from '@utils';
import { ErrorPage, GranteePage } from '@pages';

export interface RoleMiddlewareProps {
  allowedRoles: UserRole[];
}

export function RoleMiddleware({ children, allowedRoles }: PropsWithChildren<RoleMiddlewareProps>) {
  const { session, isSessionFetched, sessionError } = useSession();

  if (!isSessionFetched) {
    return <Loader />;
  }

  if (sessionError && sessionError.response) {
    return <ErrorPage errorCode={sessionError.response.status} errorMessage={sessionError.message} />;
  }

  if (session) {
    if (session?.user && session?.user.roles && intersection(allowedRoles, session?.user.roles).length === 0) {
      return <GranteePage />;
    }

    return <>{children}</>;
  }

  return <ErrorPage errorCode={500} />;
}
