import { alpha, Backdrop, CircularProgress, styled } from '@mui/material';

const LoadingBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  zIndex: theme.zIndex.drawer + 1,
}));

export function TableLoading({ loading }: { loading: boolean }) {
  return (
    <LoadingBackdrop open={loading}>
      <CircularProgress />
    </LoadingBackdrop>
  );
}
