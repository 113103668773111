import { AccountsApiUpdateGroupRequest, AccountsService } from '@api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// ----------------------------------Groups----------------------------------

const groupsQueryKey = 'groups';

export function useListGroups() {
  const { data, ...rest } = useQuery({
    queryKey: [groupsQueryKey],
    queryFn: async () => AccountsService.listGroups(),
  });

  return {
    groups: data,
    ...rest,
  };
}

export function useCreateGroup() {
  const queryClient = useQueryClient();
  const { mutate: createGroup, ...rest } = useMutation({
    mutationFn: async (name: string) => AccountsService.createGroup({ aponoGroupModelCreateUpdateRequest: { name } }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [groupsQueryKey] });
    },
  });

  return {
    createGroup,
    groupId: rest.data?.id,
    ...rest,
  };
}

export function useUpdateGroup() {
  const queryClient = useQueryClient();
  const { mutate: updateGroup, ...rest } = useMutation({
    mutationFn: async (requestParameters: AccountsApiUpdateGroupRequest) =>
      AccountsService.updateGroup(requestParameters),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [groupsQueryKey] });
    },
  });

  return {
    updateGroup,
    ...rest,
  };
}

export function useDeleteGroup() {
  const queryClient = useQueryClient();
  const { mutate: deleteGroup, ...rest } = useMutation({
    mutationFn: async (id: string) => AccountsService.deleteGroup({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [groupsQueryKey] });
    },
  });

  return {
    deleteGroup,
    ...rest,
  };
}

export function useGetGroup(id: string) {
  const { data, ...rest } = useQuery({
    queryKey: [groupsQueryKey, id],
    queryFn: async () => AccountsService.getGroup({ id }),
  });

  return {
    group: data,
    ...rest,
  };
}

// ----------------------------------Group Members----------------------------------

const groupMembersQueryKey = 'groupMembers';

export function useListGroupMembers(id: string) {
  const { data, ...rest } = useQuery({
    queryKey: [groupMembersQueryKey, id],
    queryFn: async () => AccountsService.listGroupMembers({ id }),
  });

  return {
    groupMembers: data,
    ...rest,
  };
}

export function useUpdateGroupMembers() {
  const queryClient = useQueryClient();
  const { mutate: updateGroupMember, ...rest } = useMutation({
    mutationFn: async ({ groupId, user_ids }: { groupId: string; user_ids: string[] }) =>
      AccountsService.updateGroupMembers({ id: groupId, groupMembersModel: { user_ids } }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [groupMembersQueryKey] });
    },
  });

  return {
    updateGroupMember,
    ...rest,
  };
}

// ----------------------------------Group Users----------------------------------

const groupUsersQueryKey = 'groupUsers';

export function useListUsers() {
  const { data, ...rest } = useQuery({
    queryKey: [groupUsersQueryKey],
    queryFn: async () => AccountsService.listUsers(),
  });

  return {
    users: data,
    ...rest,
  };
}
