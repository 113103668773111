import { useQuery } from '@tanstack/react-query';
import { SearchService } from '@api/services';
import { QueryKeys } from '@api';
import { useState } from 'react';

export function useSearchResults(filters: string[]) {
  const [loading, setLoading] = useState(false);
  const searchQuery = useQuery(
    [QueryKeys.Search, filters],
    async () => {
      setLoading(true);
      const response = await SearchService.search({
        filters: filters.join(','),
      });
      setLoading(false);
      return response;
    },
    {
      enabled: !!filters.length,
      initialData: {
        users: [],
        groups: [],
        resources: [],
        access_flows: [],
        tags: [],
      },
    },
  );

  return {
    searchResults: searchQuery.data,
    isLoadingSearchResults: loading,
    isFetchedSearchResults: searchQuery.isFetched,
    isSearchResultEmpty: Object.values(searchQuery.data).every((value) => !value.length),
  };
}
