import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { Theme } from '@components';
import { CssBaseline } from '@mui/material';
import AppFooter from '@components/AppFooter';

const SIDEBAR_MINIMIZED_WIDTH = 56;
const SIDEBAR_EXPANDED_WIDTH = 184;

const AppWrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
`;

const AppWrapperSidebarPlaceholder = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${SIDEBAR_MINIMIZED_WIDTH}px;
  transition: flex-basis 0.3s ease-in-out;
`;

const AppWrapperSidebar = styled.div`
  position: fixed;
  height: 100%;
  left: 0;
  z-index: 99;
  display: flex;
  transition: width 0.3s ease-in-out;
`;

const AppBody = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const AppContentContainer = styled.div`
  flex: 1;
  transition: width 0.3s ease-in-out;
`;

export interface LayoutProps {
  sidebar?: ReactNode;
  minimizedSidebar?: boolean;
}

export default function AppWrapper({ sidebar, minimizedSidebar, children }: PropsWithChildren<LayoutProps>) {
  const width = minimizedSidebar ? SIDEBAR_MINIMIZED_WIDTH : SIDEBAR_EXPANDED_WIDTH;

  const sidebarWidth = 15;

  return (
    <Theme>
      <CssBaseline />
      <AppWrapperContainer>
        {sidebar ? (
          <Theme isDark>
            <AppWrapperSidebarPlaceholder
              style={{
                flexBasis: `${width}px`,
              }}
            >
              <AppWrapperSidebar
                style={{
                  width: `${width}px`,
                }}
              >
                {sidebar}
              </AppWrapperSidebar>
            </AppWrapperSidebarPlaceholder>
          </Theme>
        ) : null}
        <AppBody>
          <AppContentContainer style={{ width: `calc(100vw - ${width + sidebarWidth}px)` }}>
            {children}
          </AppContentContainer>
          <AppFooter />
        </AppBody>
      </AppWrapperContainer>
    </Theme>
  );
}
