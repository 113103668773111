import { Link as RouterLink } from 'react-router-dom';
import { Autocomplete, Link, ListItem, Stack, TextField, Typography } from '@mui/material';
import { IntegrationConfigParamValueModel } from '@api';

export function AddIntegrationSelectField({
  selectedValue,
  label,
  values,
  placeholder,
  onChange,
  required,
  disabled,
}: {
  selectedValue?: string;
  label: string;
  values: Array<IntegrationConfigParamValueModel>;
  placeholder: string;
  onChange: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
}) {
  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: IntegrationConfigParamValueModel | null) =>
    onChange(value?.id || '');
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" component="div" color="text.primary">
        {label}
      </Typography>
      <Autocomplete
        disabled={disabled}
        disablePortal
        options={values}
        value={values.find(({ id }) => id === selectedValue) || null}
        isOptionEqualToValue={(option, value) => {
          return option.id == value?.id && option.label == value?.label;
        }}
        size="small"
        onChange={handleChange}
        renderInput={(params) => (
          <TextField required={required} {...params} variant="outlined" placeholder={placeholder} />
        )}
        renderOption={(props, option) => {
          if (!option.id.startsWith('/')) return <li {...props}>{option.label}</li>;

          // Disable onClick on the option item
          props.onClick = () => {
            //noop
          };
          // Remove the class
          props.className = '';

          return (
            <ListItem {...props}>
              <Link component={RouterLink} to={option.id} variant="body2" underline="hover">
                {option.label}
              </Link>
            </ListItem>
          );
        }}
      />
    </Stack>
  );
}
