import { routes } from '@routes';
import { Page } from '@components';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { ConnectIntegrationGroupForm } from '@Integrations/IntegrationConfigForm/organisms/ConnectIntegrationGroupForm';
import { ConnectIntegrationsGroupContent } from '../organisms/ConnectIntegration/ConnectIntegrationsGroupContent';

export function ConnectIntegrationsGroupPage() {
  const { isEnabled: isNewDesignEnabled } = useFlagFixed(Flag.NEW_INTEGRATION_CONFIG_FORM);
  return (
    <Page
      contentCentered={!isNewDesignEnabled}
      contentPadded={!isNewDesignEnabled}
      breadcrumbs={[
        {
          label: routes.Catalog.label,
          href: routes.Catalog.path,
        },
      ]}
      title={routes.ConnectIntegrationsGroup.label}
      containerWidth={isNewDesignEnabled ? false : 'lg'}
    >
      {isNewDesignEnabled ? (
        <ConnectIntegrationGroupForm
          paths={{
            catalogPath: routes.Catalog.path,
            editPath: routes.EditIntegration.path,
          }}
        />
      ) : (
        <ConnectIntegrationsGroupContent
          paths={{
            catalogPath: routes.Catalog.path,
            editPath: routes.EditIntegration.path,
          }}
          align="center"
        />
      )}
    </Page>
  );
}
