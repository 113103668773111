import { InteractiveDropdownHeader, InteractiveDropdownSelect, InteractiveDropdownView } from '@components';

import { useSelectResourceDrawerContext } from '../provider';
import { useMemo } from 'react';

function useDropdownSelectTagsKeys() {
  const { resources, selectedTagsKey, onSelectTagsKey } = useSelectResourceDrawerContext();

  const options = useMemo(() => {
    const tagKeys = new Set<string>();
    resources.forEach((resource) => {
      Object.keys(resource.tags).forEach((key) => tagKeys.add(key));
    });

    return Array.from(tagKeys).map((key) => {
      return { key, value: key, hasNextView: true };
    });
  }, [resources]);

  return {
    options,
    selectedTagsKey,
    onSelectTagsKey,
  };
}

export function DropdownSelectTagsKeys() {
  const { selectedTagsKey, options, onSelectTagsKey } = useDropdownSelectTagsKeys();

  return (
    <InteractiveDropdownView current header={<InteractiveDropdownHeader title="Tags" />}>
      <InteractiveDropdownSelect value={selectedTagsKey} options={options} onChange={onSelectTagsKey} />
    </InteractiveDropdownView>
  );
}
