import { useMemo, PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert, Table as MuiTable, TableBody, TableHead, TableRow, TableCell, Stack } from '@mui/material';

import { Loader, NoResults, TableWrapper } from '@components';

import { useAppListWebhooks } from '../services/webhooksQueries';
import TablePagination from '../components/TablePagination';
import { SEARCH_PARAM } from '../common/constants';

import WebhooksTableRow from './WebhooksTableRow';

export default function WebhooksTablePaginated() {
  const [searchParams] = useSearchParams();
  const { isFetched, isFetchingNextPage, pageData, handleOnPageChange } = useAppListWebhooks(
    searchParams.get(SEARCH_PARAM) || undefined,
  );

  return useMemo(() => {
    if (!isFetched) {
      return <Loader />;
    }

    if (!pageData) {
      return <Alert severity="error">No webhooks found</Alert>;
    }

    return (
      <Stack spacing={2}>
        <WebhooksTableWrapper isEmpty={pageData.pagination.total === 0}>
          {pageData.data.map((webhook) => (
            <WebhooksTableRow key={webhook.id} webhook={webhook} />
          ))}
        </WebhooksTableWrapper>
        {pageData.pagination.total > 0 && (
          <TablePagination pageData={pageData} isLoading={isFetchingNextPage} onChange={handleOnPageChange} />
        )}
      </Stack>
    );
  }, [handleOnPageChange, isFetched, isFetchingNextPage, pageData]);
}

function WebhooksTableWrapper({ isEmpty, children }: PropsWithChildren<{ isEmpty: boolean }>) {
  if (isEmpty) {
    return <NoResults>No webhooks found</NoResults>;
  }

  return (
    <TableWrapper>
      <MuiTable size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th" size="medium">
              Name
            </TableCell>
            <TableCell component="th" size="medium">
              URL
            </TableCell>
            <TableCell component="th" size="medium" width="400">
              Triggers
            </TableCell>
            <TableCell component="th" size="medium" width="200">
              Last Run
            </TableCell>
            <TableCell component="th" size="medium" width="200">
              Status
            </TableCell>
            <TableCell component="th" size="medium"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </MuiTable>
    </TableWrapper>
  );
}
