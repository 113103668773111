import { useRef, useState } from 'react';
import { Avatar, Box, Popover } from '@mui/material';

import { AccessFlowAreaModel } from '@api';

import { useAppListAttributeGroups } from '@AccessFlows/services/attributesQueries';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';
import { ListFilterOption } from '@components/ListFilters/common';
import { LabeledTextField, MaterialIcon } from '@components';

export interface AttributeGroupSelectValue {
  integrationId?: string;
  attributeType: string;
}

interface AttributeGroupSelectProps {
  value?: AttributeGroupSelectValue;
  onChange: (newVal: AttributeGroupSelectValue) => void;
  onOpen?: () => void;
  onClose?: () => void;
  preload?: boolean;
  optional?: boolean;
  isViewOnly?: boolean;
}

export default function AttributeGroupSelect({
  value,
  onChange,
  onOpen,
  onClose,
  preload,
  optional = true,
  isViewOnly,
}: AttributeGroupSelectProps) {
  const { attributeGroups, isFetched, refetch } = useAppListAttributeGroups(
    { applicablePlaces: [AccessFlowAreaModel.Grantee] },
    preload,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>();

  const ref = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  const toId = (val: AttributeGroupSelectValue) => `${val.integrationId || '__APONO__'}.${val.attributeType}`;

  const options: ListFilterOption[] = attributeGroups.map((group) => {
    return {
      value: toId({ integrationId: group.integration?.id, attributeType: group.type.type }),
      label: group.type.display_name,
      searchHelper: [group.type.display_name, group.integration?.name, group.integration?.type_display_name].join(' '),
      category: {
        key: group.integration?.id || '__APONO__',
        label: group.integration?.name || 'Apono',
        icon: group.integration?.icons.svg || '/static/logo-only.svg',
      },
    };
  });

  const selectedValue = options.find((option) => value && option.value === toId(value));

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    onOpen && onOpen();
    setAnchorEl(event.currentTarget);

    if (!isFetched) {
      refetch();
    }
  };

  const handleOnSelect = (newVal: ListFilterOption) => {
    const newSelected = attributeGroups.find(
      (group) =>
        toId({
          integrationId: group.integration?.id,
          attributeType: group.type.type,
        }) === newVal.value,
    );

    if (!newSelected) return;

    onChange({
      integrationId: newSelected.integration?.id,
      attributeType: newSelected.type.type,
    });

    handleClose();
  };

  return (
    <>
      <LabeledTextField
        label="Attribute"
        placeholder="Select Attribute"
        ref={ref}
        variant="outlined"
        size="small"
        onClick={toggleDropdown}
        value={selectedValue?.label}
        optional={optional}
        disabled={isViewOnly}
        InputProps={{
          readOnly: !!selectedValue,
          startAdornment: selectedValue ? (
            <Avatar src={selectedValue.category?.icon?.toString()} className="size-20" />
          ) : undefined,
          endAdornment: <MaterialIcon symbol="expand_more" />,
          sx: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
        data-testid="attribute-group-select-label"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterSingleSelect
            options={options}
            value={selectedValue}
            onSelect={handleOnSelect}
            enableSearch
            title={`Select Attribute`}
            loading={!isFetched}
            groupedByCategory
            disabled={isViewOnly}
          />
        </Box>
      </Popover>
    </>
  );
}
