import { Box, FormControlLabel, Stack, styled, Switch, Typography } from '@mui/material';
import { CustomAnalyticsEvents } from '@utils/analytics';

const LegendWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  zIndex: 2,
  backgroundColor: theme.palette.background.active,
  border: `1px solid ${theme.palette.border.default}`,
  padding: '12px',
  borderRadius: theme.shape.borderRadius,
}));

const LegendItem = styled(Box)(({ theme }) => ({
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  borderRadius: '2px',

  '&.active-access': {
    backgroundColor: theme.palette.success.main,
  },
  '&.available-access': {
    backgroundColor: theme.palette.grey[600],
  },
}));

export function AccessGraphLegend({
  filterAvailableAccessEdges,
  setFilterAvailableAccessEdges,
  filterActiveAccessNodes,
  setFilterActiveAccessNodes,
  allowAvailableAccessToggle,
}: {
  filterAvailableAccessEdges: boolean;
  setFilterAvailableAccessEdges: (value: React.SetStateAction<boolean>) => void;
  filterActiveAccessNodes: boolean;
  setFilterActiveAccessNodes: (value: React.SetStateAction<boolean>) => void;
  allowAvailableAccessToggle: boolean;
}) {
  return (
    <LegendWrapper>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="column" alignItems="start" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1.3}>
            <LegendItem className="available-access" />
            <Typography fontSize="14px" color="text.default">
              Available Access
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.3}>
            <LegendItem className="active-access" />
            <Typography fontSize="14px" color="text.default">
              Active Access
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" alignItems="start" spacing={1}>
          <FormControlLabel
            onChange={() => setFilterAvailableAccessEdges((prev) => !prev)}
            control={
              <Switch
                checked={!filterAvailableAccessEdges}
                size="small"
                color="success"
                disableRipple
                data-trigger={CustomAnalyticsEvents.LEGEND_SHOW_AVAILABLE_ACCESS_TOGGLES}
                data-props={JSON.stringify({
                  isOn: !filterAvailableAccessEdges,
                })}
              />
            }
            disabled={!allowAvailableAccessToggle}
            label={
              <Typography variant="helperText" color="text.muted" sx={{ ml: 1 }}>
                Show available access
              </Typography>
            }
          />
          <FormControlLabel
            onChange={() => setFilterActiveAccessNodes((prev) => !prev)}
            control={
              <Switch
                checked={filterActiveAccessNodes}
                size="small"
                color="success"
                disableRipple
                data-trigger={CustomAnalyticsEvents.LEGEND_SHOW_ONLY_ACTIVE_ACCESS_TOGGLES}
                data-props={JSON.stringify({
                  isOn: filterActiveAccessNodes,
                })}
              />
            }
            label={
              <Typography variant="helperText" color="text.muted" sx={{ ml: 1 }}>
                Show only active access
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </LegendWrapper>
  );
}
