import { Chip, Link, List, ListItem, Typography } from '@mui/material';
import { aponoColors } from '@utils';
import { OnboardingStepper } from '@components';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { routes } from '@routes';

export const onboardingSteps = ['Connect and Integrate', 'Create an Access Flow', 'Request and gain access'];

export function OnboardingList() {
  const { pathname } = useLocation();

  const currentStep = useMemo(() => {
    const accessFlowStepPaths = [
      routes.OnboardingAccessFlow.path,
      routes.OnboardingAddAccessFlow.path,
      routes.OnboardingEditAccessFlow.path,
    ];
    if (accessFlowStepPaths.includes(pathname)) return 1;
    else if (pathname === routes.OnboardingRequestAccess.path) return 2;
    return 0;
  }, [pathname]);

  return (
    <List>
      <ListItem>
        <Chip
          size="small"
          label={`${currentStep + 1} of 3`}
          sx={{
            color: aponoColors.primary[500],
            backgroundColor: aponoColors.neutral[700],
            width: 'fit-content',
          }}
        />
      </ListItem>
      <ListItem>
        <Typography variant="subtitle2" color={aponoColors.neutral[300]}>
          Quick & Easy Setup
        </Typography>
      </ListItem>
      <ListItem>
        <OnboardingStepper steps={onboardingSteps} currentStep={currentStep} orientation="vertical" />
      </ListItem>
      <ListItem>
        <Typography variant="inputLabel" color={aponoColors.neutral[300]}>
          Want to learn more? <Link href="https://docs.apono.io/">Our docs</Link>
        </Typography>
      </ListItem>
    </List>
  );
}
