import { PropsWithChildren, useMemo } from 'react';
import { styled, Typography } from '@mui/material';
import { CloseButton } from '@components/CloseButton';

const AlignedTd = styled('td')({
  '&.align-right': {
    textAlign: 'right',
  },
  '&.align-left': {
    textAlign: 'left',
  },
  '&.align-center': {
    textAlign: 'center',
  },
});

const TDContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

export interface SearchFilterItemFields {
  key: string;
  value: string;
}

export interface SearchFilterItemProps {
  prefix?: JSX.Element;
  fieldSelect: JSX.Element;
  operation?: JSX.Element;
  valueSelect?: JSX.Element;
  onDelete?: () => void;
}

export function SearchFilterItem({
  prefix = <>Where</>,
  fieldSelect,
  operation,
  children,
  onDelete,
}: PropsWithChildren<SearchFilterItemProps>) {
  const colspan = useMemo(() => {
    let span = 0;
    if (!operation) span++;
    if (!children) span++;
    if (!onDelete) span++;
    return span;
  }, [operation, children, onDelete]);

  return (
    <tr>
      <AlignedTd className="align-right">
        <TDContainer>
          <Typography variant="body2" component="div">
            {prefix}
          </Typography>
        </TDContainer>
      </AlignedTd>
      <td>
        <TDContainer>{fieldSelect}</TDContainer>
      </td>
      {operation && (
        <AlignedTd className="align-center">
          <TDContainer>
            <Typography variant="body2" component="div">
              {operation}
            </Typography>
          </TDContainer>
        </AlignedTd>
      )}
      {children && (
        <td>
          <TDContainer>{children}</TDContainer>
        </td>
      )}
      {colspan > 0 && <td colSpan={colspan} />}
      {onDelete && (
        <td>
          <TDContainer>
            <CloseButton onClose={() => onDelete()} testId="search-filter-hide-button" tooltip="Delete filter" />
          </TDContainer>
        </td>
      )}
    </tr>
  );
}
