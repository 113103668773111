import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import ListFilters from '@components/ListFilters/ListFilters';
import SearchBarFilter from '@common/organisms/SearchBarFilter';
import GenericFilterMultiSelect, { FilterOption } from '@common/organisms/CommonFilters/GenericFilterMultiSelect';
import {
  INTEGRATION_FILTER_LABEL,
  INTEGRATION_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_LABEL,
  RESOURCE_TYPE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/constants';

export interface BundlesFiltersOptions {
  integration: FilterOption[];
  resourceType: FilterOption[];
}

interface BundlesFiltersProps {
  options: BundlesFiltersOptions;
}

export default function BundlesFilters({ options }: BundlesFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(
    () => [
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            options={options.integration}
            filterLabel={INTEGRATION_FILTER_LABEL}
            filterParam={INTEGRATION_FILTER_PARAM}
          />
        ),
      },
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_FILTER_PARAM,
        element: (
          <GenericFilterMultiSelect
            options={options.resourceType}
            filterLabel={RESOURCE_TYPE_FILTER_LABEL}
            filterParam={RESOURCE_TYPE_FILTER_PARAM}
          />
        ),
      },
    ],
    [options],
  );

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <ListFilters
      visibleFilters={[INTEGRATION_FILTER_PARAM, RESOURCE_TYPE_FILTER_PARAM]}
      filters={filters}
      onClear={hasActiveFilters ? handleOnClear : undefined}
      prefix={<SearchBarFilter placeholder="Search by name or resource" />}
    />
  );
}
