import { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';

const WidgetCardBodyContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3),

  '&.no-side-padding': {
    padding: theme.spacing(0, 0, 3),
  },

  '&.with-top-padding': {
    paddingTop: theme.spacing(3),
  },
}));

export interface WidgetCardBodyProps {
  disableSidePadding?: boolean;
  withTopPadding?: boolean;
}

export function WidgetCardBody({
  children,
  disableSidePadding,
  withTopPadding,
}: PropsWithChildren<WidgetCardBodyProps>) {
  const classes: string[] = [];
  if (disableSidePadding) classes.push('no-side-padding');
  if (withTopPadding) classes.push('with-top-padding');

  return <WidgetCardBodyContainer className={classes.join(' ')}>{children}</WidgetCardBodyContainer>;
}
