import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Stack,
  Grid,
  TextField,
  IconButton,
  Box,
  FormLabel,
  Paper,
  Tooltip,
} from '@mui/material';

import { MaterialIcon } from '@components';
import { isEmpty } from '@libs';
import { WebhookUpdateAppModel } from '@api';

interface HeaderProps {
  key: number;
  headerKey?: string;
  headerValue?: string;
}

interface HeaderFieldsProps {
  header: HeaderProps;
  headerKeyError?: string;
  onChange: (newHeader: HeaderProps) => void;
  onDelete?: () => void;
}

interface HeadersFieldsProps {
  value: Record<string, string>;
  onChange: (newHeaders: Record<string, string>) => void;
  error?: boolean;
  helperText?: string;
}

export default function HeadersFields() {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Stack direction="column" spacing={1}>
      <HeadersFieldsLabel />
      <Paper variant="contained">
        <Controller
          control={control}
          name="headers"
          render={({ field, fieldState }) => (
            <HeadersFieldsList
              value={field.value}
              onChange={field.onChange}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Paper>
    </Stack>
  );
}

function HeadersFieldsLabel() {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <FormLabel component="div">Headers</FormLabel>
      <Tooltip
        title="You may insert up to 5 headers. Header namers may contain letters, numbers, underscores and hyphens. Spaces are not allowed."
        arrow
      >
        <Box>
          <MaterialIcon symbol="help_outline" block size="small" />
        </Box>
      </Tooltip>
    </Stack>
  );
}

function HeadersFieldsList({ value, onChange, error, helperText }: HeadersFieldsProps) {
  const [headers, setHeaders] = useState<HeaderProps[]>(() => {
    const init: HeaderProps[] = [];
    const headersKeys = Object.keys(value || {});
    for (const iterator of headersKeys) {
      init.push({ key: init.length, headerKey: iterator, headerValue: value[iterator] });
    }
    init.push({ key: init.length });
    return init;
  });

  const [listKey, setListKey] = useState(() => headers[headers.length - 1].key);

  const hasContent = (header: HeaderProps) => !isEmpty(header.headerKey) || !isEmpty(header.headerValue);

  useEffect(() => {
    const newHeaders: HeadersFieldsProps['value'] = {};
    headers.forEach((h) => {
      if (h.headerKey && !isEmpty(h.headerKey) && h.headerValue && !isEmpty(h.headerValue))
        newHeaders[h.headerKey] = h.headerValue;
    });
    onChange(newHeaders);

    const lastHeader = headers[headers.length - 1];
    if (!hasContent(lastHeader)) return;

    const nextIndex = listKey + 1;
    setListKey(nextIndex);
    setHeaders((prevHeaders) => [...prevHeaders, { key: nextIndex }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers]);

  const isDuplicateKey = useCallback(
    (headerKey: string) => {
      const headerKeys = headers.map((h) => h.headerKey).filter((k) => !isEmpty(k));
      return headerKeys.filter((k) => k === headerKey).length > 1;
    },
    [headers],
  );

  const handleHeaderChange = (newHeader: HeaderProps) => {
    setHeaders((prevHeaders) => {
      const index = prevHeaders.findIndex((h) => h.key === newHeader.key);
      const newHeaders = [...prevHeaders];
      newHeaders[index] = newHeader;
      return newHeaders;
    });
  };

  const handleDeleteHeader = (key: number) => {
    setHeaders((prevHeaders) => prevHeaders.filter((h) => h.key !== key));
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Grid container columnGap={2} direction="row" flexWrap="nowrap">
          <Grid item xs={4}>
            <FormLabel>Key</FormLabel>
          </Grid>
          <Grid item xs>
            <FormLabel>Value</FormLabel>
          </Grid>
        </Grid>
      </Box>

      {headers.map((h) => (
        <HeaderFields
          key={h.key}
          header={h}
          headerKeyError={isDuplicateKey(h.headerKey || '') ? 'Duplicate header key' : undefined}
          onChange={handleHeaderChange}
          onDelete={h.key !== listKey ? () => handleDeleteHeader(h.key) : undefined}
        />
      ))}
      {helperText && (
        <FormControl fullWidth error={error}>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </Stack>
  );
}

function HeaderFields({ header, headerKeyError, onChange, onDelete }: HeaderFieldsProps) {
  return (
    <Box>
      <Grid container columnGap={2} direction="row" flexWrap="nowrap" alignItems="flex-start">
        <Grid item xs={4}>
          <TextField
            data-testid={`header-key-${header.key}`}
            size="small"
            variant="outlined"
            value={header.headerKey || ''}
            onChange={(e) => onChange({ ...header, headerKey: e.target.value })}
            fullWidth
            error={!!headerKeyError}
            helperText={headerKeyError}
          />
        </Grid>
        <Grid item xs>
          <TextField
            data-testid={`header-value-${header.key}`}
            size="small"
            variant="outlined"
            fullWidth
            value={header.headerValue || ''}
            onChange={(e) => onChange({ ...header, headerValue: e.target.value })}
          />
        </Grid>
        <Grid item sx={{ width: 30 }} display="flex" justifyContent="center" alignItems="center">
          {onDelete && (
            <IconButton onClick={onDelete} sx={{ mt: '5px' }} data-testid={`header-delete-btn-${header.key}`}>
              <MaterialIcon symbol="cancel" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
