import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { useSearchParams } from 'react-router-dom';
import { templates } from './templates';
import { AccessFlowTemplate } from './types';

export function useTemplate(templateId: string): AccessFlowTemplate | undefined {
  return templates.find((template) => template.id === templateId);
}

const FROM_TEMPLATE_QUERY_PARAM = 'fromTemplate';

export function useTemplateFromQueryParam(): AccessFlowTemplate | undefined {
  const { isEnabled: templateSuggestionIsOn } = useFlagFixed(Flag.OVERVIEW_TEMPLATE_SUGGESTION);

  const [searchParams] = useSearchParams();

  if (!templateSuggestionIsOn) return undefined;

  const fromTemplateId = searchParams.get(FROM_TEMPLATE_QUERY_PARAM);

  return templates.find((template) => template.id === fromTemplateId);
}
