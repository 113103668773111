import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';
import { STATUS_FILTER_PARAM } from '@AccessFlows/common/constants';

import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';

export const STATUS_FILTER_LABEL = 'Status';

export const STATUS_FILTER_ACTIVE = 'true';
export const STATUS_FILTER_INACTIVE = 'false';
interface StatusFilterProps {
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export default function StatusFilter({ opened, onClose, onOpen }: StatusFilterProps) {
  const [isOpen, setIsOpen] = useState(opened || false);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.get(STATUS_FILTER_PARAM) || undefined;

  const activeOptions = [
    {
      label: 'Active',
      value: STATUS_FILTER_ACTIVE,
    },
    {
      label: 'Inactive',
      value: STATUS_FILTER_INACTIVE,
    },
  ];

  const selectedOption = activeOptions.find((option) => option.value === selectedQp) || undefined;

  const handleOnSelect = (value: ListFilterOption) => {
    searchParams.set(STATUS_FILTER_PARAM, value.value);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const handleOnClear = () => {
    searchParams.delete(STATUS_FILTER_PARAM);
    setSearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={STATUS_FILTER_LABEL}
          value={selectedOption ? [selectedOption] : []}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      <ListFilterSingleSelect
        options={activeOptions}
        value={selectedOption}
        onSelect={handleOnSelect}
        title="Select Status Filter"
      />
    </ListFilter>
  );
}
