import { Stack, styled } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { MARGIN, ResponsivePieWrapper } from '@DashboardV2/common/utils';
import { DashboardPieWidgetTooltip } from '@DashboardV2/components/DashboardWidgetTooltip';
import { AlertStatsFieldsFragment } from '@api/gql/graphql';
import { CUSTOM_COLORS } from '@AccessAlerts/organisms/AlertSeverity';
import { AlertWidgetLegend } from './AlertWidgetLegend';

const TotalOverlay = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  fontSize: '24px',
  fontWeight: '600',
  dominantBaseline: 'central',
  textAnchor: 'middle',
}));

export function AlertSeverityWidget({ data }: { data: readonly AlertStatsFieldsFragment[] }) {
  const widgetData = [
    {
      id: 'High',
      label: 'High',
      value: (data?.find((item) => item.id === '30') || { count: 0 }).count,
      color: CUSTOM_COLORS[0],
    },
    {
      id: 'Medium',
      label: 'Medium',
      value: (data?.find((item) => item.id === '20') || { count: 0 }).count,
      color: CUSTOM_COLORS[1],
    },
    {
      id: 'Low',
      label: 'Low',
      value: (data?.find((item) => item.id === '10') || { count: 0 }).count,
      color: CUSTOM_COLORS[2],
    },
  ].filter((item) => item.value != 0);

  const CenteredDataLayer = ({
    centerX,
    centerY,
  }: {
    centerX: string | number | undefined;
    centerY: string | number | undefined;
  }) => (
    <TotalOverlay x={centerX} y={centerY}>
      {widgetData.reduce((acc, item) => acc + item.value, 0)}
    </TotalOverlay>
  );

  return (
    <Stack direction="column" spacing={2}>
      <ResponsivePieWrapper>
        <ResponsivePie
          data={widgetData}
          margin={{ right: MARGIN, left: MARGIN }}
          innerRadius={0.8}
          colors={(bar) => bar.data.color}
          padAngle={2}
          cornerRadius={2}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          motionConfig="gentle"
          borderWidth={1}
          layers={['arcs', CenteredDataLayer, AlertWidgetLegend]}
          tooltip={DashboardPieWidgetTooltip}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
        />
      </ResponsivePieWrapper>
      <AlertWidgetLegend />
    </Stack>
  );
}
