import { routes } from '@routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ONBOARDING_QUERY_PARAM } from '@Onboarding/context';
import {
  CustomAnalyticsEvents,
  OPEN_REPLAY_PROJECT_QUERY_PARAM,
  OPEN_REPLAY_SESSION_QUERY_PARAM,
  useAnalyticsContext,
} from '@utils/analytics';
import { Loader } from '@components';

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
  }
`;
export function OnboardingRequestAccessPage() {
  const navigate = useNavigate();
  const { track, sessionHash } = useAnalyticsContext();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    track(CustomAnalyticsEvents.ONBOARDING_STEP_2_COMPLETED);

    const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
      const type = ev.data?.type;

      if (type === 'back-button') {
        navigate(routes.OnboardingAccessFlow.path);
      } else if (type === 'next-button') {
        navigate(`${routes.Activity.path}?${ONBOARDING_QUERY_PARAM}=true`);
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [navigate, track]);

  let src = `${USER_PORTAL_URL}/onboarding-steps/access-request-fetching`;
  if (OPEN_REPLAY_PROJECT_KEY) {
    const queryParams = [
      `${OPEN_REPLAY_PROJECT_QUERY_PARAM}=${OPEN_REPLAY_PROJECT_KEY}`,
      `${OPEN_REPLAY_SESSION_QUERY_PARAM}=${sessionHash}`,
    ];

    src = `${src}?${queryParams.join('&')}`;
  }

  return (
    <IframeContainer>
      {!iframeLoaded && <Loader />}
      <iframe
        src={src}
        allow="clipboard-read; clipboard-write"
        onLoad={() => setIframeLoaded(true)}
        style={{ opacity: iframeLoaded ? 1 : 0 }}
      />
    </IframeContainer>
  );
}
