import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountsApiUpdateAccountSettingsRequest, AccountsService } from '@api';
import { QueryKeys } from '@api/query';

export function useGetAccountSettings() {
  const { data: settings, isFetched: isSettingsFetched } = useQuery({
    queryKey: [QueryKeys.Settings],
    queryFn: async () => AccountsService.getAccountSettings(),
    initialData: {
      enable_flow_failure_notifications: true,
      max_access_grant_extend_times: 0,
      default_integration_metadata_by_connection_type: {},
    },
  });

  return {
    settings,
    isSettingsFetched,
  };
}

export function useUpdateAccountSettings() {
  const queryClient = useQueryClient();
  const {
    mutate: updateAccountSettings,
    isLoading: isUpdateAccountSettingsLoading,
    ...rest
  } = useMutation({
    mutationFn: async (payload: AccountsApiUpdateAccountSettingsRequest) =>
      AccountsService.updateAccountSettings(payload),
    onSuccess: async () => queryClient.invalidateQueries([QueryKeys.Settings]),
  });

  return {
    updateAccountSettings,
    isUpdateAccountSettingsLoading,
    ...rest,
  };
}
