import { InteractiveDropdownSelect, InteractiveDropdownSelectOption, InteractiveDropdownView } from '@components';

import { SelectResourceMode, useSelectResourceDrawerContext } from '../provider';

function useDropdownSelectMode() {
  const { selectedMode, onSelectMode } = useSelectResourceDrawerContext();

  const options: InteractiveDropdownSelectOption<SelectResourceMode>[] = [
    { key: SelectResourceMode.Any, value: 'Any resources' },
    {
      key: SelectResourceMode.AnyExcept,
      value: 'Any resources except specific',
      hasNextView: true,
    },

    {
      key: SelectResourceMode.ByName,
      value: 'Select by name',
      hasNextView: true,
    },
    {
      key: SelectResourceMode.ByTag,
      value: 'Select by tags',
      hasNextView: true,
    },
  ];

  return {
    options,
    selectedMode,
    onSelectMode,
  };
}

export function DropdownSelectMode() {
  const { options, selectedMode, onSelectMode } = useDropdownSelectMode();

  return (
    <InteractiveDropdownView current>
      <InteractiveDropdownSelect<SelectResourceMode> value={selectedMode} options={options} onChange={onSelectMode} />
    </InteractiveDropdownView>
  );
}
