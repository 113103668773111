import { AttackSurfaceStats } from '@api/gql/graphql';
import { MetricsBoxBody } from '@common/ui/MetricBox';

export function AttackSurfaceReductionWidget({ data }: { data: AttackSurfaceStats }) {
  const mainValue = `${data.currentPeriod}%`;
  const isSameValue = data.currentPeriod === data.previousPeriod;
  const diff = data.previousPeriod != undefined ? Number((data.currentPeriod - data.previousPeriod).toFixed(1)) : undefined;

;  const changeValue = isSameValue ? 0 : diff;
  const changePercentage =
    changeValue && data?.previousPeriod != undefined
      ? Number(((changeValue / data?.previousPeriod) * 100).toFixed(1))
      : 0;

  return (
    <MetricsBoxBody
      mainValue={mainValue}
      changeValue={changeValue}
      changePercentage={changePercentage}
      timePeriod="Previous period"
      isPositiveTrendError={false}
    />
  );
}
