import { useSession } from '@api';
import { SidebarBadge } from '@components';
import { useLocalStorage } from '@libs';

import { GET_STARTED_BANNER_HIDDEN } from './constants';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

export interface GetStartedNavIconProps {
  icon: JSX.Element;
  testId?: string;
}

function useGetStartedNavIcon(): string | undefined {
  const { session } = useSession();
  const [bannerHidden] = useLocalStorage<boolean>(GET_STARTED_BANNER_HIDDEN, false);

  const { isEnabled: isOn } = useFlagFixed(Flag.NEW_UI_ON_BOARDING);
  if (!isOn) return;

  const badgeHidden = !session?.onboarding || !session.onboarding.active || !bannerHidden;
  if (badgeHidden) return;

  let total = 0;
  let finished = 0;
  let key: keyof typeof session.onboarding.steps;
  for (key in session.onboarding.steps) {
    total++;
    if (session.onboarding.steps[key] === true) finished++;
  }

  return `${finished}/${total}`;
}

export function GetStartedNavIcon({ icon, testId }: GetStartedNavIconProps) {
  const badgeContent = useGetStartedNavIcon();

  return <SidebarBadge color="info" content={badgeContent} icon={icon} testId={testId} />;
}
