import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ListFilters from '@components/ListFilters/ListFilters';
import AccessTargetNodeFilter, { ACCESS_TARGET_FILTER_LABEL, ACCESS_TARGET_FILTER_PARAM } from './AccessTargetFilter';
import AttributeNodeFilter, { ATTRIBUTE_FILTER_LABEL, ATTRIBUTE_FILTER_PARAM } from './AttributeFilter';
import { SP_FILTER_PREFIX, SP_TAKE_PREFIX } from '@AccessGraph/AccessGraphMap';
import { ListFilterOption } from '@components/ListFilters/common';

export interface AccessGraphFiltersOptions {
  attributes: ListFilterOption[];
  accessTargets: ListFilterOption[];
  isLoading: boolean;
}

const DEFAULT_VISIBLE_FILTERS = [ATTRIBUTE_FILTER_PARAM, ACCESS_TARGET_FILTER_PARAM];

export default function AccessGraphFilters({ attributes, accessTargets, isLoading }: AccessGraphFiltersOptions) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const filters = useMemo(
    () => [
      {
        name: ATTRIBUTE_FILTER_LABEL,
        queryParam: ATTRIBUTE_FILTER_PARAM,
        element: (
          <AttributeNodeFilter
            options={attributes}
            opened={openedFilter === ATTRIBUTE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(ATTRIBUTE_FILTER_PARAM)}
            loading={isLoading}
          />
        ),
      },
      {
        name: ACCESS_TARGET_FILTER_LABEL,
        queryParam: ACCESS_TARGET_FILTER_PARAM,
        element: (
          <AccessTargetNodeFilter
            options={accessTargets}
            opened={openedFilter === ACCESS_TARGET_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(ACCESS_TARGET_FILTER_PARAM)}
            loading={isLoading}
          />
        ),
      },
    ],
    [attributes, openedFilter, isLoading, accessTargets],
  );

  const hasActiveFilters = useMemo(() => {
    return Array.from(searchParams.keys()).some((key) => key.startsWith(SP_FILTER_PREFIX));
  }, [searchParams]);

  const handleOnClear = useCallback(() => {
    Array.from(searchParams.keys()).forEach((key) => {
      if (key.startsWith(SP_FILTER_PREFIX) || key.startsWith(SP_TAKE_PREFIX)) {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <ListFilters
      visibleFilters={DEFAULT_VISIBLE_FILTERS}
      filters={filters}
      onClear={hasActiveFilters ? handleOnClear : undefined}
    />
  );
}
