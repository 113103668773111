import { useQuery } from '@tanstack/react-query';

import { ActivityService } from '@api/services';

import { QUERY_KEYS, REFETCH_INTERVAL } from '../../common/constants';

export function useGetActivityRequest(id: string) {
  const {
    data: request,
    isFetched: isFetched,
    isLoading: isLoading,
  } = useQuery(
    [QUERY_KEYS.Activity, QUERY_KEYS.Single, id],
    async () => ActivityService.appGetActivityRequest({ id }),
    {
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  return {
    request,
    isFetched,
    isLoading,
  };
}
