import {
  FlowFormLine,
  FlowFormSelectApprovers,
  FlowFormSelectDuration,
  FlowFormSelectGrantees,
  FlowFormSelectTimeframe,
  FlowFormTargets,
  FlowFormText,
} from '@AccessFlows/organisms/FlowForm';
import { TriggerTypeFormProps } from '@AccessFlows/common/types';

export function UserRequestForm({
  selectedGrantees,
  setSelectedGrantees,
  accessTargets,
  setAccessTargets,
  selectedApprovers,
  setSelectedApprovers,
  selectedDuration,
  setSelectedDuration,
  selectedTimeframe,
  setSelectedTimeframe,
  getValidationError,
  template,
  noBundles,
}: TriggerTypeFormProps) {
  return (
    <>
      <FlowFormLine>
        <FlowFormSelectTimeframe
          value={selectedTimeframe}
          onChange={(v) => setSelectedTimeframe(v)}
          error={getValidationError('timeframe')}
        />
        <FlowFormSelectGrantees
          template={template?.grantees}
          value={selectedGrantees}
          onChange={(v) => setSelectedGrantees(v)}
          error={getValidationError('grantees')}
        />
        <FlowFormText>requests</FlowFormText>
      </FlowFormLine>
      <FlowFormTargets
        accessTargets={accessTargets}
        onChange={(v) => setAccessTargets(v)}
        error={getValidationError('targets')}
        template={template?.targets}
        noBundles={noBundles}
      />
      <FlowFormLine>
        <FlowFormText>grant for</FlowFormText>
        <FlowFormSelectDuration
          value={selectedDuration}
          onChange={(v) => setSelectedDuration(v)}
          error={getValidationError('revoke_after_in_sec')}
        />
        <FlowFormText>with</FlowFormText>
        <FlowFormSelectApprovers
          template={template?.approvers}
          value={selectedApprovers}
          onChange={(v) => setSelectedApprovers(v)}
          error={getValidationError('approvers')}
        />
        <FlowFormText>approval.</FlowFormText>
      </FlowFormLine>
    </>
  );
}
