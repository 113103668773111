import { RadioBox, RadioBoxLabelSimple } from '@components';
import { RadioGroup, Stack, Typography } from '@mui/material';
import { PermissionsRequirement } from './types';

export interface SelectPermissionsRequirementOption {
  value: PermissionsRequirement;
  label: string;
}

export interface SelectPermissionsRequirementProps {
  options: SelectPermissionsRequirementOption[];
  requirement?: PermissionsRequirement;
  onSelect: (value: PermissionsRequirement) => void;
}

export function SelectPermissionsRequirement({ options, requirement, onSelect }: SelectPermissionsRequirementProps) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="subtitle1" component="div">
        Select permissions options
      </Typography>
      <RadioGroup value={requirement} onChange={(e) => onSelect(e.target.value as PermissionsRequirement)}>
        <Stack direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
          {options.map((option) => (
            <RadioBox
              key={option.value}
              value={option.value}
              label={<RadioBoxLabelSimple>{option.label}</RadioBoxLabelSimple>}
            />
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
