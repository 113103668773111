import { useRequiredParams } from '@hooks';
import { ErrorPage } from '@pages';
import { Loader } from '@components';

import { useAppGetWebhook } from '../services/webhooksQueries';
import WebhookHistoryTablePaginated from '@Webhooks/organisms/WebhookHistoryTablePaginated';

export function WebhookHistoryPage() {
  const { id } = useRequiredParams(['id']);

  const { webhook, isFetched } = useAppGetWebhook(id);

  if (!isFetched) {
    return <Loader />;
  }

  if (!webhook) {
    return <ErrorPage errorCode={404} errorMessage="Webhook not found" />;
  }

  return <WebhookHistoryTablePaginated webhook={webhook} />;
}
