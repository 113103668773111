import { Button, Divider, Stack } from '@mui/material';

import UsersFiltersFromAttributes from '@Identities/organisms/UsersFiltersFromAttributes';
import UsersListPaginated from '@Identities/organisms/UsersListPaginated';
import { RouterLink } from '@libs';
import { AdminPortal } from '@libs/auth';

export default function UsersPagePaginated() {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <UsersFiltersFromAttributes />
        <Button
          component={RouterLink}
          variant="contained"
          color="primary"
          to="#"
          onClick={() => {
            AdminPortal.show();
          }}
        >
          Add User
        </Button>
      </Stack>
      <Divider />
      <UsersListPaginated />
    </Stack>
  );
}
