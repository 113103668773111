import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { useEffect, useState } from 'react';

export type SelectOption = {
  id: string;
  label: string;
};

export function SearchSelectField({
  label,
  selectedValue,
  noOptionsText,
  onChange,
  fetchFn,
  disabled,
  required,
}: {
  label: string;
  selectedValue: string;
  noOptionsText: string;
  onChange: (value: string) => void;
  fetchFn: (input: string) => Promise<Array<SelectOption>>;
  disabled?: boolean;
  required?: boolean;
}) {
  const [value, setValue] = useState<string | undefined>(selectedValue);
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedInput = useDebounce<string>(inputValue, 400);
  const [options, setOptions] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    if (debouncedInput === '') {
      setOptions([]);
      return undefined;
    }

    (async function () {
      const newOptions = await fetchFn(debouncedInput);
      setOptions([...newOptions]);
    })();
  }, [debouncedInput, fetchFn]);

  return (
    <Stack>
      <Typography variant="subtitle2" component="div" color="text.primary">
        {label}
      </Typography>
      <Autocomplete
        disabled={disabled}
        freeSolo
        disablePortal
        filterOptions={(x) => x}
        options={options}
        size="small"
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        isOptionEqualToValue={(option, selected) => {
          return option.id == selected?.id && option.label == selected?.label;
        }}
        noOptionsText={noOptionsText}
        onChange={(event, newValue) => {
          setValue(typeof newValue == 'string' ? newValue : newValue?.id || '');
          onChange(typeof newValue == 'string' ? newValue : newValue?.id || '');
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'input') {
            setInputValue(newInputValue);
            onChange(newInputValue);
          }
        }}
        renderInput={(params) => <TextField {...params} required={required} placeholder={label} fullWidth />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.label}>
              {option.label}
            </li>
          );
        }}
      />
    </Stack>
  );
}
