import { generatePath } from 'react-router-dom';

import { routes } from '@routes';

import { useOnboardingContext } from '@Onboarding/context';
import { OnboardingAccessFlowPageWrap } from '@Onboarding/components/OnboardingAccessFlowPageWrap';
import { AddAccessFlowPageContent } from '@AccessFlows/organisms/AddAccessFlowPageContent';

export function OnboardingAddAccessFlowPage() {
  const { onboardingIds } = useOnboardingContext();

  return (
    <OnboardingAccessFlowPageWrap>
      <AddAccessFlowPageContent
        paths={{
          backPath: onboardingIds?.added_integration_id
            ? generatePath(routes.OnboardingEditIntegration.path, {
                id: onboardingIds?.added_integration_id,
              })
            : undefined,
          donePath: routes.OnboardingRequestAccess.path,
          doneButtonLabel: 'Request Access',
        }}
        withDraft={false}
      />
    </OnboardingAccessFlowPageWrap>
  );
}
