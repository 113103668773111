import { useCallback, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import { LabelAppModel } from '@api';

import { useAppListAccessFlows } from '@AccessFlows/services/accessFlowsQueries';
import AccessFlowLabelsSelector from '@AccessFlows/components/AccessFlowLabelsSelector';
import AccessFlowLabel from '@AccessFlows/components/AccessFlowLabel';
import { AVAILABLE_COLORS } from '@common/constants';

interface FlowBuilderLabelsSelectorProps {
  value: LabelAppModel[];
  onChange: (label: LabelAppModel[]) => void;
}

const LABELS_LIMIT = 10;

export default function FlowBuilderLabelsSelector({ value, onChange }: FlowBuilderLabelsSelectorProps) {
  const { accessFlows } = useAppListAccessFlows();

  const colorsInUse = useMemo(() => {
    const inUse = new Set<string>();
    value.forEach((label) => {
      if (label.color) inUse.add(label.color);
    });
    return inUse;
  }, [value]);

  const randomizeColor = useCallback(() => {
    let filteredColors = AVAILABLE_COLORS.filter((color) => !colorsInUse.has(color));
    if (filteredColors.length === 0) filteredColors = AVAILABLE_COLORS;
    return filteredColors[Math.floor(Math.random() * filteredColors.length)];
  }, [colorsInUse]);

  const valueSet = useMemo(() => new Set(value.map((label) => label.name)), [value]);

  const labelsOptions = useMemo(() => {
    const availableLabels: Record<string, string> = {};
    for (const flow of accessFlows) {
      for (const label of flow.labels) {
        if (valueSet.has(label.name)) continue;
        if (label.color) colorsInUse.add(label.color);
        availableLabels[label.name] = label.color || randomizeColor();
      }
    }

    return Object.keys(availableLabels).map((name) => ({ name, color: availableLabels[name] }));
  }, [accessFlows, colorsInUse, randomizeColor, valueSet]);

  const handleOnchange = (newVal: string | LabelAppModel | null) => {
    if (!newVal) return;

    const newLabel = typeof newVal === 'string' ? { name: newVal, color: randomizeColor() } : newVal;

    if (!value.find((l) => l.name === newLabel.name)) {
      onChange([...value, newLabel]);
    }
  };

  const removeLabel = (label: LabelAppModel) => {
    onChange(value.filter((l) => l.name !== label.name));
  };

  const isDisabled = value.length >= LABELS_LIMIT;

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1.5}>
      <AccessFlowLabelsSelector options={labelsOptions} onSelect={handleOnchange} disabled={isDisabled} />
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
        {value.map((l) => (
          <AccessFlowLabel key={l.name} label={l} onDelete={() => removeLabel(l)} />
        ))}
      </Stack>
      {isDisabled && (
        <Typography variant="caption" color="warning.main">
          Limit of {LABELS_LIMIT} labels reached
        </Typography>
      )}
    </Stack>
  );
}
