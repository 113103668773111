import { adminLayoutWrap } from '@middlewares';
import ConnectedIntegrationsPageV2 from './pages/ConnectedIntegrationsPageV2';
import IntegrationResourcesPage from './pages/IntegrationResourcesPage';
import { AddIntegrationPage } from './pages/AddIntegrationPage';
import { EditIntegrationPageV2 } from './pages/EditIntegrationPageV2';
import { ConnectIntegrationPage } from './pages/ConnectIntegrationPage';
import { ConnectIntegrationsGroupPage } from './pages/ConnectIntegrationsGroupPage';
import { ViewIntegrationPageV2 } from '@Integrations/pages/ViewIntegrationPageV2';
import { Flag } from '@utils';

export const routes = {
  ConnectedIntegrations: {
    label: 'Connected Integrations',
    path: '/catalog/connected',
    page: adminLayoutWrap(<ConnectedIntegrationsPageV2 />),
  },
  IntegrationResources: {
    label: 'Integration Resources',
    path: '/catalog/integration-resources/:id',
    page: adminLayoutWrap(<IntegrationResourcesPage />),
  },
  AddIntegration: {
    label: 'Add Integration',
    path: '/catalog/add-integration/:type',
    page: adminLayoutWrap(<AddIntegrationPage />),
  },
  EditIntegration: {
    label: 'Edit Integration',
    path: '/catalog/edit-integration/:id',
    page: adminLayoutWrap(<EditIntegrationPageV2 />),
  },
  ViewIntegration: {
    label: 'View Integration',
    path: '/catalog/view-integration/:id',
    page: adminLayoutWrap(<ViewIntegrationPageV2 />, Flag.NEW_INTEGRATION_CONFIG_FORM),
  },
  ConnectIntegration: {
    label: 'Connect Integration',
    path: '/catalog/connect-integration/:type',
    page: adminLayoutWrap(<ConnectIntegrationPage />),
  },
  ConnectIntegrationsGroup: {
    label: 'Connect Integrations Group',
    path: '/catalog/connect-integrations-group/:group',
    page: adminLayoutWrap(<ConnectIntegrationsGroupPage />),
  },
};
