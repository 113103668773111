import { Controller, useFormContext } from 'react-hook-form';

import { WebhookUpdateAppModel } from '@api';
import { CodeEditor } from '@components/CodeEditor';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { CodeViewer, MaterialIcon } from '@components';
import { useCallback, useMemo, useState } from 'react';
import { WebhookSchemaType } from '@Webhooks/common/constants';

const HEIGHT = 300;

const payloadSchema = {
  [WebhookSchemaType.UserRequest]: JSON.stringify(
    {
      event_type: 'string',
      event_time: '{seconds}.{nanos}',
      data: {
        id: 'string',
        friendly_id: 'string',
        requester: {
          id: 'string',
          name: 'string',
          email: 'string',
        },
        justification: 'string',
        creation_date: '{seconds}.{nanos}',
        revocation_date: 'number',
        access_flow: {
          id: 'string',
          name: 'string',
        },
        access_bundle: {
          id: 'string',
          name: 'string',
        },
        access_groups: [
          {
            integration: {
              id: 'string',
              type: 'string',
              name: 'string',
            },
            resource_types: [
              {
                id: 'string',
                name: 'string',
                display_path: 'string',
              },
            ],
            access_units: [
              {
                resource: {
                  id: 'string',
                  name: 'string',
                  path: 'string',
                  type: {
                    id: 'string',
                    name: 'string',
                    display_path: 'string',
                  },
                },
                permission: {
                  id: 'string',
                  name: 'string',
                },
              },
            ],
          },
        ],
        approvals_logical_relation: 'string',
        approvals: [
          {
            name: 'string',
            type: 'string',
            status: 'string',
            approver: {
              id: 'string',
              name: 'string',
              email: 'string',
            },
          },
        ],
      },
    },
    null,
    2,
  ),
  [WebhookSchemaType.RequestAlert]: JSON.stringify(
    {
      event_type: 'string',
      event_time: '{seconds}.{nanos}',
      data: {
        id: 'string',
        timestamp: '{seconds}.{nanos}',
        anomaly_type: 'string',
        anomaly_type_name: 'string',
        severity_name: 'string',
        severity_level: 'number',
        reason: 'string',
        recommendation: 'string',
        affected_user: {
          id: 'string',
          name: 'string',
          email: 'string',
        },
        affected_access_groups: [
          {
            integration: {
              id: 'string',
              type: 'string',
              name: 'string',
            },
            resource_types: [
              {
                id: 'string',
                name: 'string',
                display_path: 'string',
              },
            ],
            access_units: [
              {
                resource: {
                  id: 'string',
                  name: 'string',
                  path: 'string',
                  type: {
                    id: 'string',
                    name: 'string',
                    display_path: 'string',
                  },
                },
                permission: {
                  id: 'string',
                  name: 'string',
                },
              },
            ],
          },
        ],
        access_request: {
          id: 'string',
          friendly_id: 'string',
          requester: {
            id: 'string',
            name: 'string',
            email: 'string',
          },
          justification: 'string',
          creation_date: '{seconds}.{nanos}',
          revocation_date: 'number',
          access_flow: {
            id: 'string',
            name: 'string',
          },
          access_bundle: {
            id: 'string',
            name: 'string',
          },
          access_groups: [
            {
              integration: {
                id: 'string',
                type: 'string',
                name: 'string',
              },
              resource_types: [
                {
                  id: 'string',
                  name: 'string',
                  display_path: 'string',
                },
              ],
              access_units: [
                {
                  resource: {
                    id: 'string',
                    name: 'string',
                    path: 'string',
                    type: {
                      id: 'string',
                      name: 'string',
                      display_path: 'string',
                    },
                  },
                  permission: {
                    id: 'string',
                    name: 'string',
                  },
                },
              ],
            },
          ],
          approvals_logical_relation: 'string',
          approvals: [
            {
              name: 'string',
              type: 'string',
              status: 'string',
              approver: {
                id: 'string',
                name: 'string',
                email: 'string',
              },
            },
          ],
        },
      },
    },
    null,
    2,
  ),
};

export default function BodyTemplateField({ schemaType }: { schemaType: WebhookSchemaType }) {
  const { control } = useFormContext<WebhookUpdateAppModel>();
  const [showPayloadSchema, setShowPayloadSchema] = useState(false);

  const handlePayloadSchemaClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setShowPayloadSchema((prev) => !prev);
  }, []);

  const linkText = useMemo(() => {
    return `${showPayloadSchema ? 'Hide' : 'View'} ${
      schemaType === WebhookSchemaType.RequestAlert ? 'request anomaly' : 'request'
    } event's payload schema`;
  }, [schemaType, showPayloadSchema]);

  return (
    <Controller
      control={control}
      name="body_template"
      render={({ field, fieldState: { invalid, error } }) => {
        return (
          <FormControl fullWidth error={invalid}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <BodyTemplateFieldLabel />
              <Link href="#" variant="inputLabel" onClick={handlePayloadSchemaClick}>
                {linkText}
              </Link>
            </Stack>
            <Paper sx={{ mt: 1 }}>
              <Grid container direction="row">
                <Grid item xs={showPayloadSchema ? 6 : 12}>
                  <CodeEditor
                    value={field.value || ''}
                    defaultLanguage="handlebars"
                    onChange={field.onChange}
                    height={HEIGHT}
                    options={{
                      minimap: { enabled: false },
                      scrollBeyondLastLine: false,
                    }}
                    testId="body-template-editor"
                  />
                </Grid>
                {showPayloadSchema && (
                  <Grid item xs={6}>
                    <CodeViewer value={payloadSchema[schemaType]} defaultLanguage="json" height={HEIGHT} />
                  </Grid>
                )}
              </Grid>
            </Paper>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}

function BodyTemplateFieldLabel() {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <FormLabel component="div">Body Template</FormLabel>
      <Tooltip title={<BodyTemplateFieldLabelTitle />} arrow>
        <Box>
          <MaterialIcon symbol="help_outline" block size="small" />
        </Box>
      </Tooltip>
    </Stack>
  );
}

function BodyTemplateFieldLabelTitle() {
  return (
    <Stack spacing={2}>
      <Typography variant="helperText">
        Edit the payload data to match your needs, use double curly brackets syntax to reference data from the event.
        For example: {'{{user.email}}'}
      </Typography>
      <Typography variant="helperText">
        For advanced customizations, the payload is compatible with the Handlebars templating language. For detailed
        information see the Handlebars guide.
      </Typography>
      <Typography variant="helperText">The request must be in JSON format</Typography>
    </Stack>
  );
}
