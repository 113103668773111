import { useCallback, useEffect, useMemo, useState } from 'react';

import { AponoGroupModel, AponoUserModel, AttributeModel, useListAvailableAttributesV2 } from '@api';
import {
  InteractiveDropdownFilterInput,
  InteractiveDropdownFooter,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
  MaterialIcon,
  usePopoverContext,
} from '@components';

import ViewGroupModal, { GROUP_MODAL_QUERY_PARAM } from '@Groups/organisms/modals/ViewGroupModal';
import { AttributeModelStatus } from '@common/constants';
import { useGroupsFromState, useUsersFromState } from '@hooks';
import { Avatar, Tooltip } from '@mui/material';
import { CustomAnalyticsEvents } from '@utils/analytics';
import { idpTypeIcon } from '@utils/identities';
import { useSearchParams } from 'react-router-dom';
import { fromContextKey } from './use-attributes-options';

function useDropdownIdpView<T>(
  entities: T[],
  mapFn: (entity: T) => InteractiveDropdownSelectOption,
  onChange: (value: string[]) => void,
) {
  const [filter, setFilter] = useState('');
  const [filteredEntities, setFilteredEntities] = useState<InteractiveDropdownSelectOption[]>([]);

  const handleFilterChange = (filterValue: string) => setFilter(filterValue.trim());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => entities.map(mapFn), [entities]);

  useEffect(() => {
    setFilteredEntities(
      options.filter((i) => {
        const searchable = `${i.value} ${i.secondary}`.trim().toLowerCase();
        return searchable.includes(filter.toLowerCase());
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, entities]);

  const handleChange = (newValue: string[]) => {
    newValue = newValue.filter((v) => options.find((o) => o.key === v));
    onChange(newValue);
  };

  return {
    filter,
    handleFilterChange,
    filteredEntities,
    handleChange,
  };
}

interface DropdownIdpViewProps<T> {
  current: boolean;
  title: string;
  isLoading: boolean;
  entities: T[];
  mapFn: (entity: T) => InteractiveDropdownSelectOption;
  onBackClick?: () => void;
  value: string[];
  onChange: (value: string[]) => void;
}

export function DropdownIdpView<T>({
  current,
  title,
  entities,
  isLoading,
  mapFn,
  onBackClick,
  value,
  onChange,
}: DropdownIdpViewProps<T>) {
  if (isLoading) {
    return (
      <InteractiveDropdownView
        current={current}
        header={<InteractiveDropdownHeader title={title} onBackClick={onBackClick} />}
        loading
      />
    );
  }

  return (
    <DropdownIdpViewContent
      value={value}
      mapFn={mapFn}
      title={title}
      current={current}
      onChange={onChange}
      entities={entities}
      onBackClick={onBackClick}
    />
  );
}

function DropdownIdpViewContent<T>({
  current,
  title,
  entities,
  mapFn,
  onBackClick,
  value,
  onChange,
}: Omit<DropdownIdpViewProps<T>, 'isLoading'>) {
  const { filter, handleFilterChange, filteredEntities, handleChange } = useDropdownIdpView<T>(
    entities,
    mapFn,
    onChange,
  );

  return (
    <InteractiveDropdownView
      current={current}
      header={<InteractiveDropdownHeader title={title} onBackClick={onBackClick} />}
      filter={
        <InteractiveDropdownFilterInput value={filter} onChange={handleFilterChange} placeholder={`Search ${title}`} />
      }
      footer={<InteractiveDropdownFooter onClearAllClick={() => handleChange([])} />}
    >
      <InteractiveDropdownSelect<string>
        multiple
        options={filteredEntities}
        value={value}
        onChange={handleChange}
        selectedFirst
      />
    </InteractiveDropdownView>
  );
}

export interface DropdownGroupViewProps {
  current: boolean;
  value: string[];
  onBackClick?: () => void;
  onChange: (value: string[]) => void;
}

function useDropdownGroupView() {
  const { groups, isGroupsLoading } = useGroupsFromState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { popoverEnableOnClose, popoverDisableOnClose } = usePopoverContext();

  useEffect(() => {
    if (!searchParams.has(GROUP_MODAL_QUERY_PARAM)) {
      popoverEnableOnClose();
    }
  }, [searchParams, popoverEnableOnClose]);

  const handleGroupView = useCallback(
    (e: React.MouseEvent, groupId: string) => {
      e.stopPropagation();
      popoverDisableOnClose();
      searchParams.set(GROUP_MODAL_QUERY_PARAM, groupId);
      setSearchParams(searchParams);
    },
    [popoverDisableOnClose, searchParams, setSearchParams],
  );

  const mapFn = (group: AponoGroupModel): InteractiveDropdownSelectOption => ({
    key: group.id,
    icon: group.source_idp_type ? (
      <Avatar src={idpTypeIcon[group.source_idp_type]} sx={{ width: 24, height: 24 }} variant="square" />
    ) : (
      <MaterialIcon symbol="person" weight={400} sx={{ display: 'block' }} />
    ),
    value: group.name,
    rightIcon: (
      <MaterialIcon
        symbol="visibility"
        color="primary"
        onClick={(e) => handleGroupView(e, group.id)}
        data-trigger={CustomAnalyticsEvents.VIEW_GROUP_MEMBERS_IN_FLOW_BUILDER}
      />
    ),
  });

  return {
    groups,
    isGroupsLoading,
    mapFn,
    groupId: searchParams.get(GROUP_MODAL_QUERY_PARAM),
  };
}

export function DropdownGroupView({ current, onBackClick, value, onChange }: DropdownGroupViewProps) {
  const { groups, isGroupsLoading, mapFn, groupId } = useDropdownGroupView();

  return (
    <>
      <DropdownIdpView
        current={current}
        title="Group"
        isLoading={isGroupsLoading}
        entities={groups}
        mapFn={mapFn}
        onBackClick={onBackClick}
        value={value}
        onChange={onChange}
      />
      {groupId && <ViewGroupModal groupId={groupId} />}
    </>
  );
}

export type DropdownUserViewProps = DropdownGroupViewProps;

function useDropdownUserView() {
  const { users, isUsersLoading } = useUsersFromState();

  const mapFn = (user: AponoUserModel): InteractiveDropdownSelectOption => ({
    key: user.id,
    value: `${user.first_name} ${user.last_name}`,
    icon: user.source_idp_type ? (
      <Avatar src={idpTypeIcon[user.source_idp_type]} sx={{ width: 24, height: 24 }} variant="square" />
    ) : (
      <MaterialIcon symbol={'person'} sx={{ display: 'block' }} />
    ),
    secondary: user.email,
  });

  return {
    users,
    isUsersLoading,
    mapFn,
  };
}

export function DropdownUserView({ current, onBackClick, value, onChange }: DropdownUserViewProps) {
  const { users, isUsersLoading, mapFn } = useDropdownUserView();

  const filteredUsers = useMemo(
    () => users.filter((user) => user.active || (!user.active && value.includes(user.id))),
    [value, users],
  );

  return (
    <DropdownIdpView
      current={current}
      title="User"
      isLoading={isUsersLoading}
      entities={filteredUsers}
      mapFn={mapFn}
      onBackClick={onBackClick}
      value={value}
      onChange={onChange}
    />
  );
}

export function DropdownContextAttributeView({
  current,
  value,
  contextKey,
  onBackClick,
  onChange,
}: DropdownGroupViewProps & { contextKey: string }) {
  const { contextsAttributes, isContextsAttributesFetched } = useListAvailableAttributesV2();

  const { integrationId, category } = fromContextKey(contextKey);

  const entities = useMemo(
    () =>
      contextsAttributes.filter((attr) => {
        const sameIntegration = integrationId && attr.integration_id === integrationId;
        const noIntegration = integrationId === null && !attr.integration_id;
        return attr.category === category && (noIntegration || sameIntegration);
      }),
    [contextsAttributes, category, integrationId],
  );

  const categoryValues = useMemo(() => {
    const entitiesIds = entities.map((attr) => attr.id);
    return value.filter((v) => entitiesIds.includes(v));
  }, [entities, value]);

  const handleOnChange = useCallback(
    (newValues: string[]) => {
      const restValues = value.filter((v) => !categoryValues.includes(v));
      onChange([...restValues, ...newValues]);
    },
    [categoryValues, onChange, value],
  );

  return (
    <DropdownIdpView
      current={current}
      title={category}
      isLoading={!isContextsAttributesFetched}
      entities={entities}
      mapFn={(attribute: AttributeModel) => ({
        key: attribute.id,
        value: attribute.value,
        deselectOnly: attribute.status === AttributeModelStatus.Deleted,
        rightIcon: <OptionDeletedWarning attribute={attribute} />,
      })}
      onBackClick={onBackClick}
      value={categoryValues}
      onChange={handleOnChange}
    />
  );
}

function OptionDeletedWarning({ attribute }: { attribute: AttributeModel }) {
  if (attribute.status !== AttributeModelStatus.Deleted) return null;

  return (
    <Tooltip title="This attribute is deleted and can be only deselected" placement="top" arrow>
      <div>
        <MaterialIcon symbol="warning" color="warning" />
      </div>
    </Tooltip>
  );
}
