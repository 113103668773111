import { ErrorPage } from '@pages';
import { Loader } from '@components';
import { useRequiredParams } from '@hooks';
import { ConnectResourceIntegrationPathsProps } from '@Integrations/organisms/ConnectIntegration/ConnectResourceIntegration';
import { IntegrationConfigWithSubIntegrations, useGetIntegrationConfigV2 } from '@api';
import { useIntegrationForm } from '@Integrations/hooks/use-integration-form';
import { IntegrationFormStepper } from '@Integrations/IntegrationConfigForm/organisms/IntegrationFormStepper';

export function ConnectIntegrationForm({ paths }: { paths: ConnectResourceIntegrationPathsProps }) {
  const { type } = useRequiredParams(['type']);
  const { integrationConfig, isIntegrationConfigFetched } = useGetIntegrationConfigV2(type);

  if (!isIntegrationConfigFetched) return <Loader />;

  if (!integrationConfig) return <ErrorPage errorCode={404} errorMessage="Integration config not found" />;

  return <ConnectIntegrationFormContent config={integrationConfig} paths={paths} />;
}

function ConnectIntegrationFormContent({
  config,
  paths,
}: {
  config: IntegrationConfigWithSubIntegrations;
  paths: ConnectResourceIntegrationPathsProps;
}) {
  const props = useIntegrationForm({ paths, configs: [config] });
  return <IntegrationFormStepper {...props} />;
}
