import { toAlertFilterProps, useAlerts } from '../services/use-alerts-context';
import { getFragmentData } from '@api/gql';
import { EmptyState, Loader, NoResults, Page, TableWrapper } from '@components';
import { AlertDrawer } from '../organisms/AlertDrawer';
import { AccessAlertsTable } from '../organisms/AccessAlertsTable';
import TablePagination from '@components/TablePagination';
import { useMemo } from 'react';
import { useSearchParams } from '@libs';
import { Stack } from '@mui/material';
import { API_LIST_LIMIT, PAGE_PARAM, getSkipParam } from '@api';
import { AlertEdgesFields_Fragment, AlertsPaginatedFields_Fragment } from '@AccessAlerts/services/gql';
import AccessAlertsFilters from '@AccessAlerts/organisms/AccessAlertsFilters';
import { AccessAlertsWidgets } from '@AccessAlerts/organisms/AccessAlertWidgets';

export function AccessAlertsPage() {
  return (
    <Page title="Anomalies">
      <AccessAlertsContent />
    </Page>
  );
}

function AccessAlertsContent() {
  const [searchParams, setSearchParams] = useSearchParams();

  const offset = getSkipParam(Number(searchParams.get(PAGE_PARAM) ?? 1));
  const alertFilterProps = useMemo(() => toAlertFilterProps(searchParams), [searchParams]);

  const {
    data,
    isFetched: isAlertsFetched,
    isError: isAlertsFetchError,
    isFetching: isLoading,
  } = useAlerts({ offset, take: API_LIST_LIMIT, filter: alertFilterProps });
  const { alerts } = data || { alerts: null };
  const alertsData = getFragmentData(AlertsPaginatedFields_Fragment, alerts);
  const alertEdges = getFragmentData(AlertEdgesFields_Fragment, alertsData?.edges);

  const handlePageChange = (page: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(PAGE_PARAM, String(page));
    setSearchParams(newSearchParams);
    window.scrollTo(0, 0);
  };

  const pagination = useMemo(() => {
    return {
      total: alertsData?.totalCount ?? 0,
      limit: API_LIST_LIMIT,
      offset,
    };
  }, [alertsData?.totalCount, searchParams]);

  if (isAlertsFetchError) {
    return <EmptyState title="Anomalies Not Found" imgSrc="/static/EmptyStateImages/not-found-illustration.svg" />;
  }

  if (!isAlertsFetched) {
    return <Loader />;
  }

  if ((alertEdges === null || alertEdges === undefined) && alertFilterProps === undefined) {
    return <EmptyState title="No Data Found" imgSrc="/static/EmptyStateImages/not-found-illustration.svg" />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <AccessAlertsFilters />
      <AccessAlertsWidgets filters={alertFilterProps} />
      <TableWrapper loading={isLoading}>
        <AccessAlertsTable alerts={alertEdges} />
        {!alertEdges?.length && alertFilterProps && <NoResults>No anomalies found.</NoResults>}
      </TableWrapper>
      <TablePagination pagination={pagination} isLoading={isLoading} onChange={handlePageChange} />
      <AlertDrawer />
    </Stack>
  );
}
