import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import AuditEventsUsersFilter, {
  USER_FILTER_LABEL,
  USER_FILTER_PARAM,
} from '@AuditEvents/organisms/AuditEventsUsersFilter';
import { INTEGRATION_FILTER_LABEL, INTEGRATION_FILTER_PARAM } from '@common/organisms/CommonFilters/IntegrationFilter';

import SearchBarFilter from '@common/organisms/SearchBarFilter';
import { DateFilter } from '@common/organisms/CommonFilters/DateFilter';

import ListFilters from '@components/ListFilters/ListFilters';
import { QueryParams } from '@Activity/common/constants';

import AuditEventTypeFilter, { EVENT_TYPE_FILTER_PARAM, EVENT_TYPE_LABEL } from './AuditEventTypeFilter';
import { AFTER_PAGE_PARAM, BEFORE_PAGE_PARAM } from './AuditEventsTable';
import AuditEventsIntegrationFilter from '@AuditEvents/organisms/AuditEventsIntegrationFilter';
import { CustomAnalyticsEvents } from '@utils/analytics';

const DEFAULT_VISIBLE_FILTERS = [USER_FILTER_PARAM, INTEGRATION_FILTER_PARAM, EVENT_TYPE_FILTER_PARAM];

export default function AuditEventFilters() {
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const selected = useMemo(() => {
    return {
      USER_FILTER_PARAM: searchParams.getAll(USER_FILTER_PARAM) || [],
      INTEGRATION_FILTER_PARAM: searchParams.getAll(INTEGRATION_FILTER_PARAM) || [],
      EVENT_TYPE_FILTER_PARAM: searchParams.getAll(EVENT_TYPE_FILTER_PARAM) || [],
    };
  }, [searchParams]);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const applySearchParams = useCallback(
    (newSearchParams: URLSearchParams) => {
      newSearchParams.delete(BEFORE_PAGE_PARAM);
      newSearchParams.delete(AFTER_PAGE_PARAM);
      setSearchParams(newSearchParams);
    },
    [setSearchParams],
  );

  const onSelect = useCallback(
    ({ values, filterParam }: { values: string[]; filterParam: string }) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(filterParam);
      for (const value of values) {
        newSearchParams.append(filterParam, value);
      }
      applySearchParams(newSearchParams);
    },
    [applySearchParams, searchParams],
  );

  const onClear = useCallback(
    (filterParam: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(filterParam);
      applySearchParams(newSearchParams);
    },
    [applySearchParams, searchParams],
  );

  const filters = useMemo(
    () => [
      {
        name: USER_FILTER_LABEL,
        queryParam: USER_FILTER_PARAM,
        element: (
          <AuditEventsUsersFilter
            opened={openedFilter === USER_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(USER_FILTER_PARAM)}
            selected={selected.USER_FILTER_PARAM}
            onClear={() => onClear(USER_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: USER_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.SESSION_AUDIT_USER_FILTER}
          />
        ),
      },
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_FILTER_PARAM,
        element: (
          <AuditEventsIntegrationFilter
            opened={openedFilter === INTEGRATION_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INTEGRATION_FILTER_PARAM)}
            selected={selected.INTEGRATION_FILTER_PARAM}
            onClear={() => onClear(INTEGRATION_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: INTEGRATION_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.SESSION_AUDIT_INTEGRATION_FILTER}
          />
        ),
      },
      {
        name: EVENT_TYPE_LABEL,
        queryParam: EVENT_TYPE_FILTER_PARAM,
        element: (
          <AuditEventTypeFilter
            opened={openedFilter === EVENT_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(EVENT_TYPE_FILTER_PARAM)}
            selected={selected.EVENT_TYPE_FILTER_PARAM}
            onClear={() => onClear(EVENT_TYPE_FILTER_PARAM)}
            onSelect={(values: string[]) => onSelect({ values, filterParam: EVENT_TYPE_FILTER_PARAM })}
            data-trigger={CustomAnalyticsEvents.SESSION_AUDIT_EVENT_TYPE_FILTER}
          />
        ),
      },
    ],
    [onClear, onSelect, openedFilter, selected],
  );

  const [visibleFilters, setVisibleFilters] = useState<string[]>(() => {
    const searchParamsVisibleFilters: string[] = [];
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam) && !DEFAULT_VISIBLE_FILTERS.includes(filter.queryParam)) {
        searchParamsVisibleFilters.push(filter.queryParam);
      }
    }

    return [...DEFAULT_VISIBLE_FILTERS, ...searchParamsVisibleFilters];
  });

  useEffect(() => {
    const filtersFromQP: string[] = [];
    for (const filterParam of searchParams.keys()) {
      if (!visibleFilters.includes(filterParam)) {
        filtersFromQP.push(filterParam);
      }
    }

    const stillVisibleFilters: string[] = [];
    for (const visibleFilter of visibleFilters) {
      if (
        searchParams.has(visibleFilter) ||
        DEFAULT_VISIBLE_FILTERS.includes(visibleFilter) ||
        visibleFilter === openedFilter
      ) {
        stillVisibleFilters.push(visibleFilter);
      }
    }

    if (filtersFromQP.length > 0 || stillVisibleFilters.length !== visibleFilters.length) {
      setVisibleFilters([...stillVisibleFilters, ...filtersFromQP]);
    }
  }, [openedFilter, searchParams, visibleFilters]);

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    for (const dateParam of [QueryParams.startDate, QueryParams.endDate, QueryParams.utc, QueryParams.period]) {
      searchParams.delete(dateParam);
    }
    applySearchParams(searchParams);
  }, [applySearchParams, filters, searchParams]);

  const handleOnOnAddFilter = (qp: string) => {
    setVisibleFilters((prev) => [...prev, qp]);
    setOpenedFilter(qp);
  };

  return (
    <ListFilters
      visibleFilters={visibleFilters}
      filters={filters}
      onAddFilter={handleOnOnAddFilter}
      onClear={hasActiveFilters ? handleOnClear : undefined}
      prefix={
        <Stack direction="row" spacing={2}>
          <SearchBarFilter
            onSetSearchParams={applySearchParams}
            data-trigger={CustomAnalyticsEvents.SESSION_AUDIT_SEARCH_BAR}
          />
          <DateFilter />
        </Stack>
      }
    />
  );
}
