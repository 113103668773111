import { Controller, useFormContext } from 'react-hook-form';

import { WebhookUpdateAppModel } from '@api';
import { LabeledTextField } from '@components';

export default function NameField({ label }: { label?: string }) {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  return (
    <Controller
      control={control}
      name="name"
      rules={{
        required: 'Name is required',
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <LabeledTextField
          label={label ?? 'Name'}
          placeholder="My awesome webhook"
          error={invalid}
          helperText={error?.message}
          inputProps={field}
        />
      )}
    />
  );
}
