import { ReactNode, useState } from 'react';
import { Chip, FormControl, FormLabel, Stack, styled, TextField, TextFieldProps, Typography } from '@mui/material';
import slugify from '@sindresorhus/slugify';

const InputLabelWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const InputLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export interface LabeledTagsFieldLabelProps {
  label: string;
  subLabel?: ReactNode;
  optional?: boolean;
  loading?: boolean;
  testId?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
}

export type LabeledTagsFieldProps = TextFieldProps & LabeledTagsFieldLabelProps;

export function LabeledTagsField({
  label,
  subLabel,
  optional,
  testId,
  value,
  onChange,
  id,
  error,
  placeholder,
}: LabeledTagsFieldProps) {
  id = id || slugify(label) || testId;
  const required = !optional;

  const [localValue, setLocalValue] = useState<string>('');

  function handleOnChange() {
    if (onChange && localValue) {
      if (!value?.includes(localValue)) onChange([...(value || []), localValue]);
      setLocalValue('');
    }
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;

    event.preventDefault();
    handleOnChange();
  };

  const handleOnBlur = () => handleOnChange();

  function handleDelete(tag: string) {
    if (onChange) {
      onChange(value?.filter((t) => t !== tag) || []);
    }
  }

  return (
    <FormControl fullWidth error={error}>
      <InputLabelWrapper direction="row" spacing={0.5} alignItems="center">
        <InputLabel htmlFor={id}>
          {label}
          {!required && ' (optional)'}
        </InputLabel>
        {subLabel}
      </InputLabelWrapper>
      <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
        {value?.map((tag) => (
          <Chip
            key={tag}
            label={
              <Typography noWrap sx={{ maxWidth: '100px' }} variant="inherit">
                {tag}
              </Typography>
            }
            onDelete={() => handleDelete(tag)}
          />
        ))}
        <TextField
          id={id}
          size="small"
          variant="outlined"
          value={localValue}
          onChange={(event) => setLocalValue(event.target.value)}
          onKeyDown={handleOnKeyDown}
          onBlur={handleOnBlur}
          placeholder={placeholder}
        />
      </Stack>
    </FormControl>
  );
}
