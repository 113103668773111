import { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ActionsMenu, ConfirmDialog, EmptyState, Loader, NoResults, Page, SearchBar, StatusColumn } from '@components';
import {
  Button,
  Link,
  Paper,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { ConnectorsStatus, DocsUrls } from '@utils';
import { useGlobalNotifier } from '@hooks';
import {
  AgentViewModel,
  CloudTypeModel,
  Integration,
  useDeleteAgent,
  useListAgents,
  useListIntegrationsV2,
} from '@api';
import { routes } from '@routes';
import { RouterLink } from '@libs';
import { ConnectorNameAndId } from '@pages/ConnectorsPage/ConnectorNameAndId';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import ConnectorUpdate from './ConnectorUpdate';

export function ConnectorsPage() {
  return (
    <Page
      title="Connectors"
      button={
        <Button variant="contained" component={RouterLink} to={routes.InstallConnector.path}>
          Install Connector
        </Button>
      }
    >
      <ConnectorsPageContent />
    </Page>
  );
}

function ConnectorsPageContent() {
  const [filter, setFilter] = useState<string>('');
  const { connectors, isConnectorsFetched } = useListAgents();
  const { integrations, isIntegrationsFetched } = useListIntegrationsV2();

  const filteredConnectors = useMemo(() => connectors.filter((c) => c.name?.includes(filter)), [connectors, filter]);

  if (!isConnectorsFetched || !isIntegrationsFetched) {
    return <Loader />;
  }

  if (connectors?.length == 0) {
    return (
      <EmptyState
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
        body={`Apono Connectors can be used for integration with external tools and working with the Apono Integrations, see [this reference](
      ${DocsUrls.CONNECTOR_MANAGEMENT}).`}
        action={
          <Button variant="contained" component={RouterLink} to={routes.InstallConnector.path}>
            Create Connector
          </Button>
        }
      />
    );
  }

  return (
    <Stack spacing={2}>
      <SearchBar total={filteredConnectors.length} onChange={(value) => setFilter(value)} />
      <Table connectors={filteredConnectors} integrations={integrations} />
    </Stack>
  );
}

function Table({ connectors, integrations }: { connectors: Array<AgentViewModel>; integrations: Array<Integration> }) {
  if (connectors.length === 0) {
    return <NoResults>No connectors found</NoResults>;
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <MuiTable size="small">
        <THead />
        <TableBody>
          {connectors.map((connector) => (
            <TRow key={connector.agent_id} connector={connector} integrations={integrations} />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

function THead() {
  const columns = ['Name', 'Location', 'Version', 'Status', ''];
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell component="th" size="medium" key={`th-${index}`}>
            {column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function getCloudProviderIdByType(connector: AgentViewModel): string {
  if (connector.cloud_provider?.type === CloudTypeModel.Aws && connector.cloud_provider.aws_account_id)
    return connector.cloud_provider.aws_account_id;
  if (connector.cloud_provider?.type === CloudTypeModel.Gcp && connector.cloud_provider.project_id)
    return connector.cloud_provider.project_id;
  if (connector.cloud_provider?.type === CloudTypeModel.Azure && connector.cloud_provider.subscription_id)
    return connector.cloud_provider.subscription_id;
  return 'None';
}

interface TRowProps {
  connector: AgentViewModel;
  integrations: Array<Integration>;
}

function TRow({ connector, integrations }: TRowProps) {
  const status = (connector.status.charAt(0).toUpperCase() + connector.status.slice(1)) as ConnectorsStatus;
  const navigate = useNavigate();
  const [openConfirmReset, setOpenConfirmReset] = useState(false);
  const cloudProviderId: string = getCloudProviderIdByType(connector);
  const providerLink = getProviderLink(
    connector.cloud_provider?.type ?? CloudTypeModel.Generic,
    !!connector.cloud_provider?.kubernetes_type,
  );

  return (
    <TableRow>
      <TableCell width={400}>
        <ConnectorNameAndId
          id={connector.agent_id}
          name={connector.name ?? 'None'}
          provider={connector.cloud_provider?.type}
        />
      </TableCell>
      <TableCell width={400}>
        <PrimarySecondary primary={cloudProviderId} secondary={connector.cloud_provider?.region ?? 'None'} />
      </TableCell>
      <TableCell width={200}>
        {connector.version && <Typography variant="body2">{connector.version}</Typography>}
      </TableCell>
      <TableCell width={200}>
        <StatusColumn
          status={status}
          last_sync_time={connector.last_connected ? +connector.last_connected : undefined}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
          {connector.version && (
            <ConnectorUpdate isLatestVersion={connector.is_latest_version} providerLink={providerLink} />
          )}
          <ActionsMenu
            onDelete={() => setOpenConfirmReset(true)}
            onEdit={async () =>
              navigate(
                generatePath(routes.EditConnector.path, {
                  id: connector.agent_id,
                }),
              )
            }
          />
        </Stack>
      </TableCell>
      <DeleteConfirmation
        connector={connector}
        integrations={integrations}
        openConfirmReset={openConfirmReset}
        setOpenConfirmReset={setOpenConfirmReset}
      />
    </TableRow>
  );
}

interface DeleteConfirmationProps {
  openConfirmReset: boolean;
  setOpenConfirmReset: React.Dispatch<React.SetStateAction<boolean>>;
  integrations: Array<Integration>;
  connector: AgentViewModel;
}

function DeleteConfirmation({
  openConfirmReset,
  setOpenConfirmReset,
  integrations,
  connector,
}: DeleteConfirmationProps): JSX.Element {
  const { deleteAgent } = useDeleteAgent(connector.agent_id);
  const { notifySuccess } = useGlobalNotifier();

  const handleCloseDelete = () => setOpenConfirmReset(false);
  const handleConfirmDelete = () => {
    deleteAgent();
    notifySuccess('Connector uninstalled');
  };

  const DeleteConfirmDialog = (
    <ConfirmDialog open={openConfirmReset} onClose={handleCloseDelete} onConfirm={handleConfirmDelete}>
      {`Are you sure you want to uninstall ${connector.name}?`}
    </ConfirmDialog>
  );

  const ShowIntegrationsConfirmation = (
    <ConfirmDialog
      open={openConfirmReset}
      disproveLabel="Cancel"
      onClose={handleCloseDelete}
      confirmTo={`${routes.ConnectedIntegrations.path}?connector=${connector.agent_id}`}
      confirmLabel="Show Integrations"
    >
      The Connector is being used by integrations, please uninstall the integrations before disconnecting the Connector
    </ConfirmDialog>
  );

  const ConnectedConfirmation = (
    <ConfirmDialog open={openConfirmReset} disproveLabel="Ok" onClose={handleCloseDelete}>
      {'To uninstall the Connector you must first delete it from your environment. '}
      <Link href={'https://docs.apono.io/docs/connector-management'} target={'_blank'}>
        How to uninstall a Connector
      </Link>
    </ConfirmDialog>
  );

  const connectedIntegration = integrations.find((integration) => integration.provisioner_id == connector.agent_id);
  if (connectedIntegration) {
    return ShowIntegrationsConfirmation;
  }

  if (connector.status.toLowerCase() === ConnectorsStatus.CONNECTED.toLowerCase()) {
    return ConnectedConfirmation;
  }

  return DeleteConfirmDialog;
}

function getProviderLink(providerType: CloudTypeModel, isInstalledOnK8s: boolean): string {
  if (isInstalledOnK8s) {
    return 'https://docs.apono.io/docs/updating-a-kubernetes-connector';
  }

  switch (providerType) {
    case 'AWS':
      return 'https://docs.apono.io/docs/updating-a-connector-in-aws';
    case 'GCP':
      return 'https://docs.apono.io/docs/updating-a-connector-in-google-cloud';
    case 'AZURE':
      return 'https://docs.apono.io/docs/updating-a-connector-in-azure';
    case 'GENERIC':
    default:
      return 'https://docs.apono.io/docs/getting-started#install-the-connector';
  }
}
