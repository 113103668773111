import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { ActiveAccessService, QueryKeys } from '@api';

import { ActiveAccessResponse, FilterActiveAccessRequest } from '@api/apn-core';

export function useSearchActiveAccess(integrationId: string) {
  const [isActiveAccessFetched, setIsActiveAccessFetched] = useState(false);
  const [activeAccess, setActiveAccess] = useState<ActiveAccessResponse>({
    nodes: [],
    edges: [],
  });

  const { mutate: searchActiveAccess, isLoading: isActiveAccessLoading } = useMutation({
    mutationFn: async (filter: FilterActiveAccessRequest) => {
      const res = await ActiveAccessService.searchActiveAccess({
        integrationId,
        filterActiveAccessRequest: filter,
      });
      if (res) {
        setActiveAccess(res);
      }

      setIsActiveAccessFetched(true);
    },
  });

  return {
    activeAccess,
    searchActiveAccess,
    isActiveAccessLoading,
    isActiveAccessFetched,
  };
}

export function useGetActiveAccessFilterOptions(integrationId: string, filterType: string) {
  const { data: filterOptions, isFetched: isFilterOptionsFetched } = useQuery({
    queryKey: [QueryKeys.ActiveAccessFilterOptions, integrationId, filterType],
    queryFn: async () => {
      const res = await ActiveAccessService.getActiveAccessFilterOptions({
        integrationId,
        type: filterType,
      });
      if (res) {
        return res.options;
      }

      return [];
    },
    initialData: [],
  });

  return {
    filterOptions,
    isFilterOptionsFetched,
  };
}

export function useGetActiveAccessFilterTypes(integrationId: string) {
  const { data: filterTypes, isFetched: isFilterTypesFetched } = useQuery({
    queryKey: [QueryKeys.ActiveAccessFilterTypes, integrationId],
    queryFn: async () => ActiveAccessService.getActiveAccessFilterTypes({ integrationId }),
    initialData: [],
  });

  return {
    filterTypes,
    isFilterTypesFetched,
  };
}
