import { Link, Typography } from '@mui/material';

export function ClearTextButton({ onClick }: { onClick: () => void }) {
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Typography variant="tooltip" component="div">
      <Link href="#" onClick={handleOnClick}>
        Clear
      </Link>
    </Typography>
  );
}
