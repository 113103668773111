import { ElementType } from 'react';

import { Avatar, Box, Link, Stack, styled, Typography } from '@mui/material';
import { RouterLink } from '@libs';

import { GetStartedProps, OnboardingStep } from './types';
import { CloseButton } from '@components/CloseButton';
import { MaterialIcon } from '@components/MaterialIcon';

export const GetStartedBannerContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  backgroundColor: theme.palette.background.banner,
  borderRadius: theme.spacing(1),
}));

export const GetStartedBannerLink = styled(Link)<{
  component: ElementType;
  to: string;
}>(({ theme }) => ({
  display: 'block',
  '& .get-started-label': {
    color: theme.palette.text.primary,
  },

  '&:hover': {
    '& .get-started-label': {
      color: theme.palette.primary.main,
    },
  },

  '&.onboarding-step-finished': {
    '& .get-started-icon': {
      background: theme.palette.success.main,
    },

    '& .get-started-label': {
      color: theme.palette.success.main,
    },
  },
}));

export const StepAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,

  '&.finished': {
    backgroundColor: theme.palette.success.main,
  },
}));

export function GetStartedBanner({ meta, steps, onHide }: GetStartedProps) {
  return (
    <GetStartedBannerContainer data-testid="onboarding">
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="subtitle1">Get started</Typography>
          <GetStartedBannerItem finished={steps.added_resource_integration} {...meta.added_resource_integration} />
          <GetStartedBannerItem finished={steps.added_idp_integration} {...meta.added_idp_integration} />
          <GetStartedBannerItem finished={steps.added_access_flow} {...meta.added_access_flow} />
          <GetStartedBannerItem finished={steps.added_slack_integration} {...meta.added_slack_integration} />
        </Stack>
        <CloseButton onClose={onHide} testId="onboarding-hide-button" tooltip="Hide get started" />
      </Stack>
    </GetStartedBannerContainer>
  );
}

interface GetStartedBannerItemProps extends Pick<OnboardingStep, 'step' | 'label' | 'to'> {
  finished?: boolean;
}

function GetStartedBannerItem({ finished, step, label, to }: GetStartedBannerItemProps) {
  return (
    <GetStartedBannerLink
      component={RouterLink}
      to={to}
      data-testid="onboarding-step"
      className={finished ? 'onboarding-step-finished' : ''}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <StepAvatar className="get-started-icon">
          {finished ? (
            <MaterialIcon symbol="done" className="icon" />
          ) : (
            <Typography variant="subtitle2" component="span">
              {step}
            </Typography>
          )}
        </StepAvatar>
        <Typography
          variant="subtitle2"
          component="div"
          className="get-started-label"
          data-testid="onboarding-step-button"
        >
          {label}
        </Typography>
      </Stack>
    </GetStartedBannerLink>
  );
}
