import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { InstallConnectorWizard, InstallStrategy, PermissionsRequirement, PlatformType } from '@organisms';

import { AgentViewModel, CloudTypeModel, IntegrationConfigAppModelV2, useListAgents } from '@api';
import { CircularProgress, Stack } from '@mui/material';
import { NEW_CONNECTOR_VALUE, SelectConnector } from './SelectConnector';
import { SelectResourceTypes } from './SelectResourceTypes';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export interface IntegrationConnectorFormProps {
  config: IntegrationConfigAppModelV2;
  connectorId?: string;
  onConnectorIdChange: (connectorId: string) => void;
  resourceTypes?: string[];
  onResourceTypesChange?: (resourceTypes: string[]) => void;
  withDescription?: boolean;
}

function useIntegrationConnectorForm({
  config,
  onConnectorIdChange,
}: Pick<IntegrationConnectorFormProps, 'config' | 'onConnectorIdChange'>) {
  const { track } = useAnalyticsContext();
  const { isEnabled: isLocalConnectorEnabled } = useFlagFixed(Flag.LOCAL_CONNECTOR);
  const { connectors, isConnectorsFetched } = useListAgents();

  const [showInstallConnectorWizard, setShowInstallConnectorWizard] = useState(false);

  const handleConnectorIdChange = (val: string) => {
    if (val === NEW_CONNECTOR_VALUE) {
      track(CustomAnalyticsEvents.ADD_INTEGRATION_NEW_CONNECTOR_SELECTED);
      setShowInstallConnectorWizard(true);
    } else {
      track(CustomAnalyticsEvents.ADD_INTEGRATION_CONNECTOR_SELECTED);
      setShowInstallConnectorWizard(false);
    }

    onConnectorIdChange(val);
  };

  const handleConnectorChange = (connector: AgentViewModel) => {
    onConnectorIdChange(connector.agent_id);
  };

  const fixedPlatform = useMemo(() => {
    switch (config.connection_type) {
      case 'cloud-aws':
        return PlatformType.Aws;
      case 'cloud-azure':
        return PlatformType.Azure;
      case 'cloud-gcp':
        return PlatformType.Gcp;
      case 'k8s':
        return PlatformType.K8s;
    }
  }, [config]);

  const newConnectorInstallationRequirement = useMemo(() => {
    switch (config.connection_type) {
      case 'cloud-aws':
        return config.type.includes('aws-organization')
          ? PermissionsRequirement.AWS_ORGANIZATION
          : PermissionsRequirement.AWS_ACCOUNT;
      case 'cloud-azure':
      case 'k8s':
        return PermissionsRequirement.WITH;
      case 'cloud-gcp':
        return config.type.includes('gcp-organization')
          ? PermissionsRequirement.GCP_ORGANIZATION
          : PermissionsRequirement.GCP_PROJECT;
    }
  }, [config]);

  const connectorsOptions = useMemo(
    () =>
      connectors
        .filter((connector) => {
          const cloudType = connector.cloud_provider?.type ?? null;
          const isGenericLocalConnector =
            cloudType === CloudTypeModel.Generic && connector.cloud_provider?.local_deploy;

          switch (config.connection_type) {
            case 'k8s':
            case 'custom':
              return true;
            case 'cloud-aws':
              return cloudType === CloudTypeModel.Aws || isGenericLocalConnector;
            case 'cloud-azure':
              return cloudType === CloudTypeModel.Azure || isGenericLocalConnector;
            case 'cloud-gcp':
              return cloudType === CloudTypeModel.Gcp || isGenericLocalConnector;
            default:
              return false;
          }
        })
        .map((c) => ({
          value: c.agent_id,
          label: c.name,
          version: c.version,
          status: c.status,
        })),
    [config.connection_type, connectors],
  );

  useEffect(() => {
    if (isConnectorsFetched && connectorsOptions.length === 0) {
      setShowInstallConnectorWizard(true);
    }
  }, [connectorsOptions, isConnectorsFetched]);

  return {
    connectorsOptions,
    isConnectorsFetched,
    fixedPlatform,
    fixedStrategy: isLocalConnectorEnabled ? undefined : InstallStrategy.PLATFORM,
    showInstallConnectorWizard,
    newConnectorInstallationRequirement,
    handleConnectorIdChange,
    handleConnectorChange,
  };
}

export function IntegrationConnectorForm({
  config,
  connectorId,
  onConnectorIdChange,
  resourceTypes,
  onResourceTypesChange,
  children,
  withDescription = true,
}: PropsWithChildren<IntegrationConnectorFormProps>) {
  const {
    connectorsOptions,
    isConnectorsFetched,
    fixedPlatform,
    fixedStrategy,
    showInstallConnectorWizard,
    newConnectorInstallationRequirement,
    handleConnectorIdChange,
    handleConnectorChange,
  } = useIntegrationConnectorForm({
    config,
    onConnectorIdChange,
  });

  if (!isConnectorsFetched) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <CircularProgress size={16} />
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" spacing={6}>
      {connectorsOptions.length > 0 && (
        <SelectConnector
          options={connectorsOptions}
          connectorId={connectorId}
          onSelect={handleConnectorIdChange}
          withDescription={withDescription}
        />
      )}
      {showInstallConnectorWizard && (
        <InstallConnectorWizard
          fixedStrategy={fixedStrategy}
          fixedPlatform={fixedPlatform}
          fixedRequirement={newConnectorInstallationRequirement}
          onConnectorInstalled={handleConnectorChange}
        />
      )}
      {onResourceTypesChange && connectorId && connectorId !== NEW_CONNECTOR_VALUE && (
        <SelectResourceTypes config={config} selected={resourceTypes} onSelect={onResourceTypesChange} align="center" />
      )}
      {children}
    </Stack>
  );
}
