import { AvatarGroup, Stack, Tooltip, Typography } from '@mui/material';

import { ActivityRequestAppModel } from '@api';
import PrimarySecondary, { PrimarySecondaryAvatarWrapped } from '@common/ui/PrimarySecondary';

const MAX_RESOURCE_TYPES = 3;

export default function AggregatedResourceTypesCell({
  aggregatedResourceTypes,
}: {
  aggregatedResourceTypes: ActivityRequestAppModel['aggregated_resource_types'];
}) {
  if (aggregatedResourceTypes.length === 0) {
    return (
      <Typography variant="helperText" color="text.muted">
        -
      </Typography>
    );
  }

  const first = aggregatedResourceTypes[0];
  if (aggregatedResourceTypes.length === 1) {
    return <PrimarySecondary icon={first.icons.svg} primary={first.display_name} secondary={first.path} />;
  }

  const slicedResourceTypes = aggregatedResourceTypes.slice(0, MAX_RESOURCE_TYPES);
  const leftOvers = slicedResourceTypes.length - MAX_RESOURCE_TYPES;

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <AvatarGroup>
        {slicedResourceTypes.map((rt) => {
          return (
            <Tooltip
              title={<PrimarySecondary primary={rt.display_name} secondary={rt.path} />}
              key={rt.type}
              arrow
              placement="top"
            >
              <PrimarySecondaryAvatarWrapped icon={rt.icons.svg} />
            </Tooltip>
          );
        })}
      </AvatarGroup>
      {leftOvers > 0 && (
        <Typography variant="helperText" color="text.muted">
          +{leftOvers} more
        </Typography>
      )}
    </Stack>
  );
}
