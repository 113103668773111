import {
  CatalogTitles,
  Category,
  CategoryDescription,
  CategoryListProps,
  CategoryTitleProps,
  ConfigsListPaths,
  ConfigsListProps,
  formatIntegrationData,
  generateLink,
  IntegrationGroup,
} from './consts';
import { EmptyState, Loader, MaterialIcon, SearchBar } from '@components';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, useSearchParams } from '@libs';
import { Fragment, useMemo, useState } from 'react';
import { IntegrationCategoryAppModel, useGetCatalogV2 } from '@api';
import { IntegrationCatalogItem } from '@components/CatalogV2/IntegrationCatalogItem';
import { routes } from '@routes';

const SEARCH_QUERY_PARAM = 'search';
const SEARCH_QUERY_LABELS = 'label';
const SEARCH_QUERY_CATEGORY = 'category';

function useCatalog() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { integrationsConfigs, isIntegrationsConfigsFetched: isCatalogFetched } = useGetCatalogV2();
  const [searchQuery, setSearchQuery] = useState(searchParams.get(SEARCH_QUERY_PARAM) || '');
  const [searchQueryLabel, setSearchQueryLabel] = useState(searchParams.get(SEARCH_QUERY_LABELS) || '');
  const [searchQueryCategory, setSearchQueryCategory] = useState(searchParams.get(SEARCH_QUERY_CATEGORY) || '');

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(SEARCH_QUERY_PARAM);
    if (!isEmpty(value)) newSearchParams.set(SEARCH_QUERY_PARAM, value);
    setSearchParams(newSearchParams);
  };

  const handleFilterByLabel = (label: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (label === searchQueryLabel) {
      setSearchQueryLabel('');
      newSearchParams.delete(SEARCH_QUERY_LABELS);
    } else {
      setSearchQueryLabel(label);
      newSearchParams.delete(SEARCH_QUERY_LABELS);
      if (!isEmpty(label)) newSearchParams.set(SEARCH_QUERY_LABELS, label);
    }
    setSearchParams(newSearchParams);
  };

  const handleFilterByCategory = (category: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (category === searchQueryCategory) {
      setSearchQueryCategory('');
      newSearchParams.delete(SEARCH_QUERY_CATEGORY);
    } else {
      setSearchQueryCategory(category);
      newSearchParams.delete(SEARCH_QUERY_CATEGORY);
      if (!isEmpty(category)) newSearchParams.set(SEARCH_QUERY_CATEGORY, category);
    }
    setSearchParams(newSearchParams);
  };

  const distinctLabelCategories = useMemo(() => {
    const values = integrationsConfigs.flatMap((conf) => conf.labels_categories);
    return [...new Set(values)];
  }, [integrationsConfigs]);

  const distinctCategories = useMemo(() => {
    const values = integrationsConfigs.flatMap((conf) => conf.category);
    return [...new Set(values)];
  }, [integrationsConfigs]);

  const configs = useMemo(
    () => formatIntegrationData(integrationsConfigs, searchQuery, searchQueryLabel, searchQueryCategory),
    [integrationsConfigs, searchQuery, searchQueryLabel, searchQueryCategory],
  );

  return {
    configs,
    isCatalogFetched,
    searchQuery,
    handleSearchQuery,
    distinctLabelCategories,
    handleFilterByLabel,
    selectedLabel: searchQueryLabel,
    distinctCategories,
    handleFilterByCategory,
    selectedCategory: searchQueryCategory,
  };
}

export interface CatalogV2Props {
  withCategoryFilters?: boolean;
  enforceCategory?: string;
  paths?: ConfigsListPaths;
  titles?: CatalogTitles;
}

export interface CategoriesFiltersProps {
  searchQuery: string;
  handleSearchQuery: (value: string) => void;
  distinctLabelCategories: string[];
  handleFilterByLabel: (label: string) => void;
  selectedLabel: string;
  distinctCategories: string[];
  handleFilterByCategory: (category: string) => void;
  selectedCategory: string;
}
export function CatalogV2({
  withCategoryFilters = true,
  enforceCategory,
  paths = {
    connectIntegration: routes.ConnectIntegration.path,
    connectIntegrationGroup: routes.ConnectIntegrationsGroup.path,
    addIntegration: routes.AddIntegration.path,
  },
  titles = {
    available: 'Available',
    premium: 'Premium',
  },
}: CatalogV2Props) {
  const {
    configs,
    isCatalogFetched,
    searchQuery,
    handleSearchQuery,
    distinctLabelCategories,
    handleFilterByLabel,
    selectedLabel,
    distinctCategories,
    handleFilterByCategory,
    selectedCategory,
  } = useCatalog();

  if (enforceCategory && enforceCategory !== selectedCategory) {
    handleFilterByCategory(enforceCategory);
  }

  if (!isCatalogFetched) return <Loader />;

  return (
    <Grid container columnGap={3}>
      {withCategoryFilters && (
        <CategoriesFiltersWithSearchBar
          searchQuery={searchQuery}
          handleSearchQuery={handleSearchQuery}
          distinctCategories={distinctCategories}
          distinctLabelCategories={distinctLabelCategories}
          selectedCategory={selectedCategory}
          handleFilterByCategory={handleFilterByCategory}
          selectedLabel={selectedLabel}
          handleFilterByLabel={handleFilterByLabel}
        />
      )}
      <Grid item xs>
        <Stack spacing={2}>
          {!withCategoryFilters && <SearchBox searchQuery={searchQuery} handleSearchQuery={handleSearchQuery} />}
          <ConfigsList configs={configs} paths={paths} withCategoryFilters={withCategoryFilters} titles={titles} />
        </Stack>
      </Grid>
    </Grid>
  );
}

function ConfigsList({ configs, paths, withCategoryFilters, titles }: ConfigsListProps) {
  const totalGroups = Object.values(configs).reduce((acc, val) => acc + val.length, 0);
  const categoryConfigs = Object.entries(configs);

  if (totalGroups === 0) {
    return (
      <EmptyState
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
        title="No results found"
        body="Try using different keywords"
      />
    );
  }

  return (
    <Stack spacing={4}>
      {categoryConfigs.map(([categoryKey, categoryItems], i) => (
        <Fragment key={categoryKey}>
          <CategoryList
            categories={categoryItems}
            category={categoryKey as IntegrationCategoryAppModel}
            paths={paths}
            withCategoryFilters={withCategoryFilters}
            titles={titles}
          />
          {categoryConfigs.length - 1 > i && <Divider />}
        </Fragment>
      ))}
    </Stack>
  );
}

function CategoryList({ categories, category, paths, withCategoryFilters, titles }: CategoryListProps) {
  const availableIntegrations = categories.filter(({ isPremium }) => !isPremium);
  const premiumIntegrations = categories.filter(({ isPremium }) => isPremium);

  return (
    <Stack spacing={3}>
      {withCategoryFilters && <CategoryTitle title={Category[category]} description={CategoryDescription[category]} />}
      {availableIntegrations.length > 0 && (
        <SubCategoryList
          title={titles.available}
          description={titles.availableDescription}
          integrations={availableIntegrations}
          paths={paths}
        />
      )}
      {premiumIntegrations.length > 0 && (
        <SubCategoryList
          title={titles.premium}
          description={titles.premiumDescription}
          integrations={premiumIntegrations}
          paths={paths}
        />
      )}
    </Stack>
  );
}

function SubCategoryList({
  title,
  description,
  integrations,
  paths,
}: {
  title: string;
  description?: string;
  integrations: IntegrationGroup[];
  paths: ConfigsListPaths;
}) {
  return (
    <Stack spacing={2}>
      <CategoryTitle title={title} description={description} sub />
      <Box>
        <Grid container direction="row" spacing={2} flexWrap="wrap" columns={{ md: 9, sm: 6, xs: 3 }}>
          {integrations.map(({ type, name, icon, labelsCategories, config, isPremium }) => (
            <Grid item key={type} xs={3}>
              <IntegrationCatalogItem
                name={name}
                link={generateLink(config, paths)}
                iconPath={icon}
                label={labelsCategories[0]}
                isPremium={isPremium}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}

function CategoryTitle({ title, description, sub = false }: CategoryTitleProps) {
  return (
    <Stack>
      <Typography variant={sub ? 'subtitle2' : 'subtitle1'}>{title}</Typography>
      {description && (
        <Typography variant="inputLabel" color="text.filter">
          {description}
        </Typography>
      )}
    </Stack>
  );
}

function SearchBox({
  searchQuery,
  handleSearchQuery,
}: {
  searchQuery: string;
  handleSearchQuery: (value: string) => void;
}) {
  return (
    <Box>
      <SearchBar value={searchQuery} onChange={handleSearchQuery} />
    </Box>
  );
}

function CategoriesFiltersWithSearchBar({
  searchQuery,
  handleSearchQuery,
  distinctCategories,
  distinctLabelCategories,
  selectedCategory,
  handleFilterByCategory,
  selectedLabel,
  handleFilterByLabel,
}: CategoriesFiltersProps) {
  return (
    <Grid item width={320}>
      <Stack spacing={2}>
        <SearchBox searchQuery={searchQuery} handleSearchQuery={handleSearchQuery} />
        <Box sx={{ width: '100%' }}>
          <nav aria-label="secondary mailbox folders">
            <List>
              <ListSubheader component="div" id="nested-list-subheader" disableGutters>
                <Typography variant="subtitle1" color="text.muted" gutterBottom>
                  Categories
                </Typography>
              </ListSubheader>
              {distinctCategories.map((category) => (
                <ListItem disablePadding key={category}>
                  <ListItemButton onClick={() => handleFilterByCategory(category)}>
                    <ListItemText
                      primary={category.split(/(?=[A-Z])/).join(' ')}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                    {selectedCategory === category ? <MaterialIcon symbol={'Done'} /> : <></>}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <List>
              <ListSubheader component="div" id="nested-list-subheader2" disableGutters>
                <Typography variant="subtitle1" color="text.muted" gutterBottom>
                  Labels
                </Typography>
              </ListSubheader>
              {distinctLabelCategories.map((label) => (
                <ListItem disablePadding key={label}>
                  <ListItemButton onClick={() => handleFilterByLabel(label)}>
                    <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
                    {selectedLabel === label ? <MaterialIcon symbol={'Done'} /> : <></>}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
        </Box>
      </Stack>
    </Grid>
  );
}
