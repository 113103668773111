import { Outlet } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import AppWrapper from '@common/ui/AppWrapper';
import AppSidebar from '@common/ui/AppSidebar';
import AppSidebarItem from '@common/ui/AppSidebarItem';
import Navigation from './organisms/Sidebar';
import Brand from './components/Brand';
import Logo from './components/Logo';
import { aponoColors, Flag } from '@utils';
import { GlobalNotifications, MaterialIcon, User } from '@components';
import { routes } from '@routes';
import { GetStarted } from '@organisms';
import LocalConnectorFailedBanner from '@Integrations/organisms/LocalConnectorBanner';
import { useActiveLocation, useFlagFixed } from '@hooks';
import { Divider } from '@mui/material';

const MINIMIZED_KEY = 'sidebar-minimized';

export default function AppLayout() {
  const [minimized, setMinimized] = useLocalStorage(MINIMIZED_KEY, false);

  const isActive = useActiveLocation();
  const showGetStartedBanner = !isActive([routes.Dashboard.path, routes.Activity.path], true);
  const { isEnabled: isLocalConnectorOn } = useFlagFixed(Flag.LOCAL_CONNECTOR);

  return (
    <AppWrapper
      minimizedSidebar={minimized}
      sidebar={
        <AppSidebar
          header={
            <AppSidebarItem
              minimized={minimized}
              icon={<Logo fill={aponoColors.primary[400]} />}
              label={<Brand fill={aponoColors.white} />}
              enableAnimation
            />
          }
          footer={
            <>
              <Divider />
              <AppSidebarItem
                icon={<MaterialIcon symbol="import_contacts" weight={800} />}
                label="Docs"
                to="https://docs.apono.io"
                minimized={minimized}
                enableAnimation
                newTab
              />
              <Divider />
              <User wide={!minimized} />
            </>
          }
          minimized={minimized}
          onMinimizeToggle={() => setMinimized((p) => !p)}
        >
          <Navigation minimized={minimized} setMinimized={setMinimized} />
        </AppSidebar>
      }
    >
      {showGetStartedBanner && <GetStarted isBanner />}
      {isLocalConnectorOn && <LocalConnectorFailedBanner />}
      <Outlet />
      <GlobalNotifications />
    </AppWrapper>
  );
}
