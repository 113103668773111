import { ReactNode } from 'react';

import { Avatar, Box, Stack, styled, Typography } from '@mui/material';

import { Markdown } from '@components/Markdown';

const EmptyStateWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),

  '&.no-padding': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const EmptyStateContainer = styled(Box)({
  maxWidth: 380,
  margin: '0 auto',
});

export interface EmptyStateProps {
  imgSrc?: string;
  title?: string;
  body?: string;
  action?: ReactNode;
  noPadding?: boolean;
  size?: { width: number; height: number };
}

export function EmptyState({ action, imgSrc, title, body, noPadding, size }: EmptyStateProps) {
  return (
    <EmptyStateWrapper data-testid="empty-state-container" className={noPadding ? 'no-padding' : ''}>
      <EmptyStateContainer textAlign="center">
        <Stack spacing={2} alignItems="center">
          {imgSrc && (
            <Avatar
              alt="Empty State"
              variant="square"
              src={imgSrc}
              sx={{ width: size?.width ?? 120, height: size?.height ?? 120 }}
            />
          )}
          {title && (
            <Typography variant="subtitle2" gutterBottom>
              {title}
            </Typography>
          )}
          {body && (
            <Typography variant="body2" color="text.muted" gutterBottom component="div">
              <Markdown>{body}</Markdown>
            </Typography>
          )}
          {action && <Box>{action}</Box>}
        </Stack>
      </EmptyStateContainer>
    </EmptyStateWrapper>
  );
}
