import { useRequiredParams } from '@hooks';
import { Page } from '@components';
import { routes } from '@routes';
import { EditIntegrationForm } from '@Integrations/IntegrationConfigForm/organisms/EditIntegrationForm';

export function ViewIntegrationPageV2() {
  const { id } = useRequiredParams(['id']);

  return (
    <Page
      breadcrumbs={[
        {
          label: routes.ConnectedIntegrations.label,
          href: routes.ConnectedIntegrations.path,
        },
      ]}
      title={routes.ViewIntegration.label}
    >
      <EditIntegrationForm
        integrationId={id}
        paths={{
          back: routes.ViewIntegration.path,
          skip: routes.ConnectedIntegrations.path,
        }}
      />
    </Page>
  );
}
