import { FormSection } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSection';
import { IntegrationConnectorForm } from '@organisms';
import { FormSectionSummary } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionSummary';
import { Controller, useFormContext } from 'react-hook-form';
import { IntegrationConfigAppModelV2 } from '@api';
import { ReactNode } from 'react';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { ConnectorView } from '@Integrations/organisms/IntegrationConfigForm/ConfigInfo';
import { ErrorPage } from '@pages';

export function ConnectorFormSection({
  config,
  actions,
  expanded,
  isEdit,
  onSummaryClick,
}: {
  config: IntegrationConfigAppModelV2;
  actions?: ReactNode;
  expanded: boolean;
  isEdit?: boolean;
  onSummaryClick?: () => void;
}) {
  const { control, watch } = useFormContext<IntegrationConfigFormPayload>();

  const connectorId = watch('provisioner_id');

  return (
    <FormSection
      expanded={expanded}
      summary={
        <FormSectionSummary
          title="Apono connector"
          withIcon={!isEdit}
          onClick={onSummaryClick}
          tooltip="The Apono connector separates the Apono app from your environment for security purposes."
        />
      }
      actions={expanded && actions}
    >
      {isEdit ? (
        <>
          {connectorId ? (
            <ConnectorView connectorId={connectorId} />
          ) : (
            <ErrorPage errorCode={404} errorMessage="Connector not found" />
          )}
        </>
      ) : (
        <Controller
          name="provisioner_id"
          control={control}
          render={({ field }) => (
            <IntegrationConnectorForm
              config={config}
              connectorId={field.value}
              onConnectorIdChange={field.onChange}
              withDescription={false}
            />
          )}
        />
      )}
    </FormSection>
  );
}
