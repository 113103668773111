import { Table as MuiTable, TableBody } from '@mui/material';
import { TableHeader } from '../components/TableHeader';
import { AlertTableRow } from '../components/AlertTableRow';
import { AlertEdgesFieldsFragment } from '@api/gql/graphql';

export function AccessAlertsTable({ alerts }: { alerts: readonly AlertEdgesFieldsFragment[] | undefined | null }) {
  return (
    <MuiTable data-testid="access-alerts-table">
      <TableHeader />
      <TableBody>
        {alerts?.map((alert, index) => {
          return <AlertTableRow key={index} alert={alert} />;
        })}
      </TableBody>
    </MuiTable>
  );
}
