import { HomePage } from '@pages/Home';
import { useEffect } from 'react';
import { AdminPortal } from '@frontegg/react';

export function AdminBoxPage() {
  useEffect(() => {
    AdminPortal.show();
  }, []);

  return <HomePage />;
}
