import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { routes } from '@routes';
import { IntegrationState, IntegrationStatePaths, Loader, Page } from '@components';
import { useFlagFixed, useRequiredParams } from '@hooks';
import { Integration, IntegrationFamilyType, useAppGetIntegration } from '@api';
import { ErrorPage } from '@pages';

import {
  useIntegration,
  useResourceIntegration,
  useUpdateResourceIntegration,
} from '@hooks/integrations-catalog.hooks';
import { IntegrationContent } from '@Integrations/pages/AddIntegrationPage';
import { useOnboardingContext } from '@Onboarding/context';
import { EditConnectedIntegration, SYNC_PARAM } from '../organisms/EditConnectedIntegration/EditConnectedIntegration';
import { Flag } from '@utils';
import { EditIntegrationForm } from '@Integrations/IntegrationConfigForm/organisms/EditIntegrationForm';

export function EditIntegrationPageV2() {
  const { id } = useRequiredParams(['id']);
  const { isEnabled: isNewDesignEnabled } = useFlagFixed(Flag.NEW_INTEGRATION_CONFIG_FORM);

  return (
    <Page
      contentCentered={!isNewDesignEnabled}
      contentPadded={!isNewDesignEnabled}
      breadcrumbs={[
        {
          label: routes.ConnectedIntegrations.label,
          href: routes.ConnectedIntegrations.path,
        },
      ]}
      title={routes.EditIntegration.label}
      containerWidth={isNewDesignEnabled ? false : 'lg'}
    >
      {isNewDesignEnabled ? (
        <EditIntegrationForm
          integrationId={id}
          paths={{
            back: routes.EditIntegration.path,
            skip: routes.ConnectedIntegrations.path,
          }}
        />
      ) : (
        <EditIntegrationContent
          integrationId={id}
          paths={{
            back: routes.EditIntegration.path,
            skip: routes.ConnectedIntegrations.path,
          }}
        />
      )}
    </Page>
  );
}

function useEditIntegrationContent(integrationId: string) {
  const [searchParams] = useSearchParams();
  const integrationSync = useMemo(() => Boolean(searchParams.get(SYNC_PARAM)), [searchParams]);
  const { integration, integrationConfig, isIntegrationFetched } = useAppGetIntegration(integrationId, integrationSync);

  const isFetched = useMemo(() => isIntegrationFetched, [isIntegrationFetched]);

  return {
    integration,
    config: integrationConfig,
    isFetched,
    integrationSync,
  };
}

export function EditIntegrationContent({
  integrationId,
  paths,
}: {
  integrationId: string;
  paths: IntegrationStatePaths;
}) {
  const { integration, config, isFetched, integrationSync } = useEditIntegrationContent(integrationId);
  const { isInOnboarding } = useOnboardingContext();

  if (!isFetched) {
    return <Loader />;
  }

  if (!integration || !config) {
    return <ErrorPage errorCode={404} errorMessage="Integration not found" />;
  }

  if (integrationSync) {
    const successBasePath = isInOnboarding ? undefined : routes.IntegrationResources.path;
    return <IntegrationState {...{ integration, config, paths, successBasePath }} />;
  }

  switch (config.family_type) {
    case IntegrationFamilyType.ResourceIntegration:
      return <EditConnectedIntegration {...{ integration, config, paths }} />;
    default:
      return <ConnectedIntegrationLoader />; // Legacy edit integration page
  }
}

export function ConnectedIntegrationLoader() {
  const { id } = useRequiredParams(['id']);
  const { resourceIntegration: connectedIntegration, resourceIntegrationLoaded } = useResourceIntegration(id);

  if (!resourceIntegrationLoaded) {
    return <Loader />;
  }

  if (!connectedIntegration) {
    return <ErrorPage errorCode={500} />;
  }

  return <LegacyEditIntegrationContent integration={connectedIntegration} />;
}

function LegacyEditIntegrationContent({ integration }: { integration: Integration }) {
  const [resourceRequest, setResourceRequest] = useState<Integration>(integration);
  const { isLoadingIntegration, integrationConfig } = useIntegration(integration.type);

  if (isLoadingIntegration) {
    return <Loader />;
  }

  if (!integrationConfig) {
    return <ErrorPage errorCode={500} />;
  }

  return (
    <IntegrationContent
      isEdit
      integration={resourceRequest}
      setIntegration={setResourceRequest}
      integrationConfig={integrationConfig}
      useCreateOrUpdateHook={useUpdateResourceIntegration}
    />
  );
}
