import {
  FlowFormLine,
  FlowFormSelectIntegrationOrBundleDrawer,
  FlowFormSelectPermissions,
  FlowFormSelectResourceDrawer,
  FlowFormText,
  IntegrationInfo,
} from '../../FlowForm';
import { MaterialIcon } from '@components';
import { parsePath, yup } from '@libs';
import { useEffect, useMemo, useState } from 'react';
import { AccessFlowTargetTemplate } from '@af-templates';
import {
  AccessTargetAppModel,
  AccessTargetAppModelBundle,
  AccessTargetAppModelIntegration,
  AccessTargetType,
  TagAppModel,
} from '@api';
import { FlowFormSelectPermissionsValue } from '../../FlowForm/SelectPermissions/context';
import FlowFormSection from '@AccessFlows/components/FlowFormSection';

export type FlowFormTargetIntegrationType = Partial<AccessTargetAppModelIntegration>;
export type FlowFormTargetBundleType = Partial<AccessTargetAppModelBundle>;

export interface FlowFormTargetType {
  target_type?: AccessTargetType;
  integration?: FlowFormTargetIntegrationType;
  bundle?: FlowFormTargetBundleType;
}

interface FlowFormTargetsProps {
  and?: boolean;
  template?: AccessFlowTargetTemplate;
  accessTargets?: FlowFormTargetType[];
  onChange: (value: FlowFormTargetType[]) => void;
  error?: yup.ValidationError;
  noPrefix?: boolean;
  noEmptyPrefix?: boolean;
  noBundles?: boolean;
  placeholder?: string;
  withLines?: boolean;
}

interface AccessTargetsListItem {
  key: string;
  accessTarget: FlowFormTargetType;
}

function useAccessTargetsList({
  accessTargets,
  onChange,
  error,
}: Pick<FlowFormTargetsProps, 'accessTargets' | 'onChange' | 'error'>) {
  const emptyList = () => [
    {
      key: 'ac-0',
      accessTarget: {},
    },
  ];

  const [list, setList] = useState<AccessTargetsListItem[]>(
    !accessTargets || accessTargets.length === 0
      ? emptyList()
      : accessTargets.map((item, i) => ({ key: `ac-${i}`, accessTarget: item })),
  );
  const [erroredIndex, setErroredIndex] = useState<number>(-1);
  const [validationError, setValidationError] = useState<FlowFormTargetsProps['error']>();

  useEffect(() => {
    let errorIndex = -1;
    let yupValidationError: yup.ValidationError | undefined = undefined;

    if (error && error.path) {
      const parsed = parsePath(error.path);
      if (parsed.length > 2) {
        errorIndex = parseInt(parsed[1]);
        if (parsed.length > 3) {
          yupValidationError = new yup.ValidationError(error.message, undefined, parsed[3]);
        } else {
          yupValidationError = new yup.ValidationError(error.message, undefined, parsed[2]);
        }
      } else {
        errorIndex = 0;
        yupValidationError = new yup.ValidationError(error.message, undefined, 'target_type');
      }
    }

    setErroredIndex(errorIndex);
    setValidationError(yupValidationError);
  }, [error]);

  const _updateList = (accessTargetsListItems: AccessTargetsListItem[]) => {
    setList(accessTargetsListItems);

    const accessTargetsArray = [];
    for (const item of accessTargetsListItems) {
      accessTargetsArray.push(item.accessTarget);
    }

    onChange(accessTargetsArray);
  };

  const addItem = () => _updateList([...list, { key: `ac-${list.length}`, accessTarget: {} }]);

  const deleteItem = (index: number) => {
    const newList = [...list];
    newList.splice(index, 1);
    _updateList(newList);
  };

  const duplicateItem = (index: number) => {
    const newList = [...list];
    newList.splice(index, 0, {
      key: `ac-${list.length}`,
      accessTarget: newList[index].accessTarget,
    });
    _updateList(newList);
  };

  const handleItemChange = (index: number, value: FlowFormTargetType) => {
    const newList = [...list];
    newList[index] = { ...newList[index], accessTarget: value };
    _updateList(newList);
  };

  return {
    list,
    addItem,
    deleteItem,
    duplicateItem,
    handleItemChange,
    erroredIndex,
    validationError,
  };
}

export function FlowFormTargets({
  and,
  accessTargets,
  onChange,
  error,
  template,
  noPrefix,
  noEmptyPrefix,
  noBundles,
  placeholder,
  withLines,
}: FlowFormTargetsProps) {
  const { list, addItem, deleteItem, duplicateItem, handleItemChange, erroredIndex, validationError } =
    useAccessTargetsList({ accessTargets, onChange, error });

  return (
    <FlowFormSection returnDefault={!withLines}>
      {list.map(({ key, accessTarget }, index) => {
        const first = index === 0;
        const last = index === list.length - 1;
        const icon = first ? <MaterialIcon symbol="east" color="success" /> : undefined;

        return (
          <FlowFormLine
            key={key}
            withBorder={withLines}
            padded={!noPrefix}
            icon={icon}
            onDuplicate={() => duplicateItem(index)}
            onAdd={last ? () => addItem() : undefined}
            onDelete={list.length > 1 ? () => deleteItem(index) : undefined}
          >
            <AccessTargetItem
              noPrefix={noPrefix}
              noEmptyPrefix={noEmptyPrefix}
              noBundles={noBundles}
              and={and}
              first={first}
              target={accessTarget}
              onChange={(val) => handleItemChange(index, val)}
              error={erroredIndex === index ? validationError : undefined}
              template={first ? template : undefined}
              placeholder={placeholder}
            />
          </FlowFormLine>
        );
      })}
    </FlowFormSection>
  );
}

interface AccessTargetItemProps {
  first: boolean;
  and?: boolean;
  target?: FlowFormTargetType;
  onChange: (value: FlowFormTargetType) => void;
  error?: yup.ValidationError;
  template?: AccessFlowTargetTemplate;
  noPrefix?: boolean;
  noEmptyPrefix?: boolean;
  noBundles?: boolean;
  placeholder?: string;
}

interface TargetInfo {
  integration?: IntegrationInfo;
}

function useAccessTargetItem({
  noPrefix,
  noEmptyPrefix,
  first,
  and,
  target,
  onChange,
  error,
  template,
}: AccessTargetItemProps) {
  const integrationInfo: TargetInfo = useMemo(() => {
    if (target?.integration && target?.integration?.integration_id && target?.integration?.resource_type) {
      return {
        integration: {
          integrationId: target.integration.integration_id,
          resourceType: target.integration.resource_type,
        },
      };
    }

    return {
      integration: undefined,
    };
  }, [target?.integration]);

  const handleBundleIdChange = (value: string) => {
    onChange({
      target_type: AccessTargetType.Bundle,
      bundle: {
        bundle_id: value,
      },
    });
  };

  const handleIntegrationInfoChange = (value: IntegrationInfo) => {
    onChange({
      target_type: AccessTargetType.Integration,
      integration: {
        integration_id: value.integrationId,
        resource_type: value.resourceType,
        resource_tag_matchers: !template ? [] : undefined,
        resource_tag_excludes: !template ? [] : undefined,
        permissions:
          target?.integration?.resource_type === value.resourceType
            ? target?.integration.permissions
            : !template
            ? []
            : undefined,
      },
    });
  };

  const handlePermissionsChange = (value: FlowFormSelectPermissionsValue) => {
    onChange({
      target_type: AccessTargetType.Integration,
      integration: {
        ...target?.integration,
        permissions: value.permissions,
      },
    });
  };

  const handleResourcesChange = (matchers: TagAppModel[], excludes: TagAppModel[]) => {
    onChange({
      target_type: AccessTargetType.Integration,
      integration: {
        ...target?.integration,
        resource_tag_matchers: matchers,
        resource_tag_excludes: excludes,
      },
    });
  };

  const validationError: Partial<
    Record<keyof AccessTargetAppModelIntegration | keyof AccessTargetAppModel, yup.ValidationError>
  > = useMemo(() => {
    if (!error || !error.path) return {};
    return { [error.path]: error };
  }, [error]);

  const empty = useMemo(
    () => !target?.integration && !target?.bundle && !template,
    [template, target?.bundle, target?.integration],
  );

  const prefix = useMemo(() => {
    if (noPrefix) return;

    let text = 'or';

    if (first) {
      if (empty && !noEmptyPrefix) {
        text = 'access to';
      } else {
        text = '';
      }
    } else if (and) {
      text = 'and';
    }

    if (target?.target_type === AccessTargetType.Bundle) {
      text = `${text} access to resources from`;
    }

    return <FlowFormText>{text}</FlowFormText>;
  }, [noPrefix, first, and, target?.target_type, empty, noEmptyPrefix]);

  return {
    empty,
    prefix,
    integrationInfo,
    validationError,
    handlePermissionsChange,
    handleResourcesChange,
    handleIntegrationInfoChange,
    handleBundleIdChange,
  };
}

function AccessTargetItem({
  first = false,
  and,
  target,
  onChange,
  error,
  template,
  noPrefix,
  noEmptyPrefix,
  noBundles,
  placeholder,
}: AccessTargetItemProps) {
  const {
    empty,
    prefix,
    validationError,
    handlePermissionsChange,
    handleResourcesChange,
    handleIntegrationInfoChange,
    handleBundleIdChange,
    integrationInfo,
  } = useAccessTargetItem({
    first,
    and,
    target,
    onChange,
    error,
    template,
    noPrefix,
    noEmptyPrefix,
    placeholder,
  });

  if (empty) {
    return (
      <>
        {prefix}
        <FlowFormSelectIntegrationOrBundleDrawer
          onIntegrationInfoChange={handleIntegrationInfoChange}
          onBundleIdChange={handleBundleIdChange}
          error={validationError.target_type}
          placeholder={placeholder}
          noBundles={noBundles}
        />
      </>
    );
  }

  if (target?.target_type === AccessTargetType.Bundle || !integrationInfo.integration) {
    return (
      <>
        {prefix}
        <FlowFormSelectIntegrationOrBundleDrawer
          bundleId={target?.bundle?.bundle_id}
          onIntegrationInfoChange={handleIntegrationInfoChange}
          onBundleIdChange={handleBundleIdChange}
          error={validationError.target_type}
          placeholder={placeholder}
          noBundles={noBundles}
        />
      </>
    );
  }

  return (
    <>
      {prefix}
      <FlowFormSelectPermissions
        integrationInfo={integrationInfo.integration}
        value={{
          permissions: target?.integration?.permissions || [],
        }}
        onChange={handlePermissionsChange}
        error={validationError.permissions}
        template={template?.permissions}
      />
      <FlowFormText>to</FlowFormText>
      <FlowFormSelectResourceDrawer
        integration={integrationInfo.integration}
        matchers={target?.integration?.resource_tag_matchers}
        excludes={target?.integration?.resource_tag_excludes}
        onChange={handleResourcesChange}
        error={validationError.resource_tag_matchers || validationError.resource_tag_excludes}
        template={template?.resource_tag_matchers || template?.resource_tag_excludes}
      />
      <FlowFormText>from</FlowFormText>
      <FlowFormSelectIntegrationOrBundleDrawer
        integrationInfo={integrationInfo.integration}
        onIntegrationInfoChange={handleIntegrationInfoChange}
        onBundleIdChange={handleBundleIdChange}
        error={validationError.integration_id}
        template={template?.integration_id}
        noBundles={noBundles}
      />
    </>
  );
}
