import { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { useFlagFixed, useGlobalNotifier, useNotification } from '@hooks';
import { Flag } from '@utils';

export interface NotificationSnackProps {
  text: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  onClose?: () => void;
}

export function NotificationSnack({ text, severity, onClose }: NotificationSnackProps) {
  const notification = useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  const hide = () => {
    setIsOpen(false);

    if (timer) clearTimeout(timer);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (!notification) {
      setIsOpen(false);
      return;
    }

    setIsOpen(true);

    if (timer) clearTimeout(timer);
    setTimer(setTimeout(() => hide(), 5000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <Snackbar
      data-testid="notification-snack"
      open={isOpen}
      key={text}
      onClose={(_, reason) => {
        reason !== 'clickaway' && hide();
      }}
    >
      <Alert onClose={hide} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export function GlobalNotifications() {
  const { isEnabled: hideErrorNotifications } = useFlagFixed(Flag.HIDE_ERROR_NOTIFICATIONS);
  const notification = useNotification();
  const { clearNotifications } = useGlobalNotifier();

  // For Demo use - not to show error notifications
  if (hideErrorNotifications && notification?.severity === 'error') return null;

  return notification ? (
    <NotificationSnack
      text={notification.text}
      severity={notification.severity}
      onClose={() => {
        clearNotifications();
      }}
    />
  ) : (
    <></>
  );
}
