import { useAsyncState } from '@hooks';
import { usersSelector, groupsSelector } from '@state';
import { useAsync } from '@hooks/use-async.hook';
import { AccountsService, AponoGroupModel, AponoUserModel } from '@api';

export function useGroupsFromState() {
  const { value: groups, isLoading: isGroupsLoading, reload: refetchGroups } = useAsyncState(groupsSelector, []);

  return {
    groups,
    isGroupsLoading,
    refetchGroups,
  };
}

export function useGroupByIdFromState(id?: string): AponoGroupModel | undefined {
  const { value: groups } = useAsyncState(groupsSelector, []);
  return groups.find((group) => group.id === id);
}

export function useUsersFromState() {
  const { value: users, isLoading: isUsersLoading, reload: refetchUsers } = useAsyncState(usersSelector, []);

  return {
    users,
    isUsersLoading,
    refetchUsers,
  };
}

export function useUserByIdFromState(id?: string): AponoUserModel | undefined {
  const { value: users } = useAsyncState(usersSelector, []);
  return users.find((user) => user.id === id);
}

export function useGroups() {
  const {
    result: groups,
    execute,
    doneOnce,
  } = useAsync(async () => AccountsService.listGroups(), [], {
    executeOnMount: true,
  });

  return {
    groups,
    execute,
    doneOnce,
  };
}

export function useUsers() {
  const {
    result: users,
    execute,
    doneOnce,
  } = useAsync(async () => AccountsService.listUsers(), [], {
    executeOnMount: true,
  });

  return {
    users,
    execute,
    doneOnce,
  };
}

export function useAccountsPack() {
  const { groups, isGroupsLoading, refetchGroups } = useGroupsFromState();
  const { users, isUsersLoading, refetchUsers } = useUsersFromState();

  function refetchPack() {
    refetchGroups();
    refetchUsers();
  }

  return {
    isLoading: isGroupsLoading || isUsersLoading,
    groups,
    users,
    refetchPack,
  };
}
