import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface FormActionsProps {
  loading?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export function FormActions({ loading, cancelLabel, submitLabel, onCancel, onSubmit }: FormActionsProps) {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      {onCancel && (
        <Button variant="outlined" onClick={() => onCancel()}>
          {cancelLabel || 'Cancel'}
        </Button>
      )}
      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        type="submit"
        onClick={() => onSubmit && onSubmit()}
      >
        {submitLabel || 'Save'}
      </LoadingButton>
    </Stack>
  );
}
