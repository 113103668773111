import { styled } from '@mui/material/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';

const TabsSwitchStyled = styled(Tabs)(({ theme }) => ({
  borderBottom: 0,

  '& .MuiTabs-flexContainer': {
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: theme.shape.borderRadius,
    padding: '0 2px',
  },

  '& .MuiTab-root': {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    zIndex: 2,
    padding: theme.spacing(1.5, 6),
    lineHeight: 1,
    transition: 'color 0.3s ease',

    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
    },
  },

  '& .MuiTabs-indicator': {
    bottom: 3,
    height: 'auto',
    top: 3,
    zIndex: 1,
    borderRadius: theme.shape.borderRadius / 2,
  },

  '&.MuiTabs-small': {
    '& .MuiTab-root': {
      padding: theme.spacing(1.05, 4),
    },
  },

  '&.MuiTabs-smallest': {
    '& .MuiTab-root': {
      ...theme.typography.caption,
      height: 26,
      lineHeight: 1,
      padding: theme.spacing(1.05, 2),
    },
  },
}));

type TabsSwitchProps = TabsProps & {
  size?: 'smallest' | 'small' | 'medium';
};

export default function TabsSwitch({ children, size = 'medium', ...restProps }: TabsSwitchProps) {
  const classes = [];
  classes.push(`MuiTabs-${size}`);

  return (
    <TabsSwitchStyled className={classes.join(' ')} {...restProps} data-testid="tab-switch">
      {children}
    </TabsSwitchStyled>
  );
}
