import { useRecoilValue, useSetRecoilState } from 'recoil';
import { globalNotificationsState } from '../state/global-notifications.state';

export function getApiExceptionMessage(exception: unknown) {
  return (exception as { message: string })?.message;
}

export function useGlobalNotifier() {
  const setNotifications = useSetRecoilState(globalNotificationsState);

  return {
    notifySuccess: (text: string) => setNotifications({ text, severity: 'success' }),
    notifyError: (text: string) => setNotifications({ text, severity: 'error' }),
    notifyServerError: (exception: unknown, fallbackText: string) =>
      setNotifications({ text: getApiExceptionMessage(exception) ?? fallbackText, severity: 'error' }),
    notifyWarning: (text: string) => setNotifications({ text, severity: 'warning' }),
    notifyInfo: (text: string) => setNotifications({ text, severity: 'info' }),
    clearNotifications: () => setNotifications(null),
  };
}

export function useNotification() {
  return useRecoilValue(globalNotificationsState);
}
