import { Stack, Typography } from '@mui/material';
import ViewAsCodeModal from '@common/components/ViewAsCodeModal';
import { IntegrationConfigAppModelV2 } from '@api';
import { convertIntegrationConfigToHcl } from '@Integrations/utils/converter';
import { useFormContext } from 'react-hook-form';
import { IntegrationConfigFormPayload } from '../Provider';
import { AnalyticsTriggerElementProps } from '@common/types';

interface ViewAsCodeProps {
  config: IntegrationConfigAppModelV2;
  connectorId?: string;
  withTitle?: boolean;
  buttonText?: string;
}

export default function ViewAsCode({
  config,
  connectorId,
  withTitle = true,
  buttonText,
  ...analyticsProps
}: ViewAsCodeProps & AnalyticsTriggerElementProps) {
  const { watch } = useFormContext<IntegrationConfigFormPayload>();
  const secretType = String(watch('secret_config')?.type || '');
  const params = watch('metadata');
  const integrationName = watch('name');

  return (
    <Stack direction="column" spacing={1}>
      {withTitle && <Typography variant="subtitle2">View as code</Typography>}
      <ViewAsCodeModal
        code={convertIntegrationConfigToHcl(config, integrationName, params, secretType, connectorId)}
        {...analyticsProps}
        buttonText={buttonText}
      />
    </Stack>
  );
}
