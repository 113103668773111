import { Box, Link, Stack, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const InteractiveDropdownFooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ClearAllLink = styled(Link)({
  display: 'block',
});

export interface InteractiveDropdownFooterProps extends PropsWithChildren<{}> {
  onClearAllClick?: () => void;
}

export function InteractiveDropdownFooter({ onClearAllClick, children }: InteractiveDropdownFooterProps) {
  return (
    <InteractiveDropdownFooterContainer>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        {onClearAllClick && <ClearAllButton onClick={onClearAllClick} />}
        {children}
      </Stack>
    </InteractiveDropdownFooterContainer>
  );
}

function ClearAllButton({ onClick }: { onClick: () => void }) {
  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <ClearAllLink
      data-testid="interactive-dropdown-footer-clear-btn"
      variant="caption"
      href="#"
      underline="none"
      onClick={handleOnClick}
    >
      Clear all
    </ClearAllLink>
  );
}
