import { useMemo, useState } from 'react';

import { AgentViewModel } from '@api';

import { SelectPlatform } from './SelectPlatform';
import { SelectPermissionsRequirement } from './SelectPermissionsRequirement';
import { SelectInstallationMethod } from './SelectInstallationMethod';
import { NewConnectorsWatcher } from './NewConnectorsWatcher';
import { InstallMethod, InstallStrategy, PermissionsRequirement, PlatformType } from './types';
import SelectInstallStrategy from './SelectInstallStrategy';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export interface InstallConnectorWizardProps {
  fixedStrategy?: InstallStrategy;
  initStrategy?: InstallStrategy;
  fixedPlatform?: PlatformType;
  initPlatform?: PlatformType;
  fixedRequirement?: PermissionsRequirement;
  initRequirement?: PermissionsRequirement;
  fixedMethod?: InstallMethod;
  initMethod?: InstallMethod;
  onConnectorInstalled?: (connector: AgentViewModel) => void;
}

function useInstallConnectorWizard({
  fixedStrategy,
  initStrategy,
  fixedPlatform,
  initPlatform,
  fixedRequirement,
  initRequirement,
  fixedMethod,
  initMethod,
}: Omit<InstallConnectorWizardProps, 'onConnectorInstalled'>) {
  const { track } = useAnalyticsContext();

  const [strategy, setStrategy] = useState<InstallStrategy | undefined>(fixedStrategy ?? initStrategy);
  const [platform, setPlatform] = useState<PlatformType | undefined>(fixedPlatform ?? initPlatform);
  const [requirement, setRequirement] = useState<PermissionsRequirement | undefined>(
    fixedRequirement ?? initRequirement,
  );
  const [method, setMethod] = useState<InstallMethod | undefined>(fixedMethod ?? initMethod);

  const handleStrategySelect = (val: InstallStrategy) => {
    setStrategy(val);
    setPlatform(fixedPlatform);
    setRequirement(fixedRequirement);

    if (val === InstallStrategy.LOCAL) {
      setMethod('Local');
    } else {
      setMethod(fixedMethod);
    }
  };

  const handlePlatformSelect = (val: PlatformType) => {
    setPlatform(val);
    setRequirement(fixedRequirement);
    setMethod(fixedMethod);
  };

  const handleRequirementSelect = (value: PermissionsRequirement) => {
    setRequirement(value);
    setMethod(fixedMethod);
  };

  const handleMethodSelect = (value: InstallMethod) => {
    track(CustomAnalyticsEvents.ADD_CONNECTOR_INSTALL_METHOD_SELECTED, {
      addConnectorInstallMethod: value,
    });
    setMethod(value);
  };

  return {
    strategy,
    handleStrategySelect,
    platform,
    handlePlatformSelect,
    requirement,
    handleRequirementSelect,
    method,
    handleMethodSelect,
  };
}

export function InstallConnectorWizard({
  fixedStrategy,
  initStrategy,
  fixedPlatform,
  initPlatform,
  fixedRequirement,
  initRequirement,
  fixedMethod,
  initMethod,
  onConnectorInstalled,
}: InstallConnectorWizardProps) {
  const { strategy, handleStrategySelect, method, ...restProps } = useInstallConnectorWizard({
    fixedStrategy,
    initStrategy,
    fixedPlatform,
    initPlatform,
    fixedRequirement,
    initRequirement,
    fixedMethod,
    initMethod,
  });

  return (
    <>
      {!fixedStrategy && <SelectInstallStrategy strategy={strategy} onSelect={handleStrategySelect} />}
      {!fixedMethod && strategy && (
        <StrategyWizard {...restProps} {...{ strategy, fixedPlatform, fixedRequirement, fixedMethod }} />
      )}
      {method && <NewConnectorsWatcher method={method} onConnectorInstalled={onConnectorInstalled} />}
    </>
  );
}

interface StrategyWizardProps {
  strategy: InstallStrategy;

  platform?: PlatformType;
  fixedPlatform?: PlatformType;
  handlePlatformSelect: (value: PlatformType) => void;

  requirement?: PermissionsRequirement;
  fixedRequirement?: PermissionsRequirement;
  handleRequirementSelect: (value: PermissionsRequirement) => void;

  method?: InstallMethod;
  fixedMethod?: InstallMethod;
  handleMethodSelect: (value: InstallMethod) => void;
}

function StrategyWizard({
  strategy,
  platform,
  fixedPlatform,
  handlePlatformSelect,
  fixedMethod,
  ...restProps
}: StrategyWizardProps) {
  if (strategy === InstallStrategy.LOCAL) {
    return <></>;
  }

  return (
    <>
      {!fixedPlatform && <SelectPlatform platform={platform} onSelect={handlePlatformSelect} />}
      {!fixedMethod && platform && <PlatformWizard {...restProps} platform={platform} />}
    </>
  );
}

interface PlatformWizardProps
  extends Omit<StrategyWizardProps, 'strategy' | 'handlePlatformSelect' | 'fixedPlatform' | 'fixedMethod'> {
  platform: PlatformType;
}

function PlatformWizard({ platform, ...rest }: PlatformWizardProps) {
  switch (platform) {
    case PlatformType.Gcp:
      return <GcpPlatformWizard {...rest} />;
    case PlatformType.Aws:
      return <AwsPlatformWizard {...rest} />;
    case PlatformType.K8s:
      return <K8sPlatformWizard {...rest} />;
    case PlatformType.Azure:
      return <AzurePlatformWizard {...rest} />;
    default:
      return <></>;
  }
}

function AwsPlatformWizard({
  fixedRequirement,
  requirement,
  method,
  handleRequirementSelect,
  handleMethodSelect,
}: Omit<PlatformWizardProps, 'platform'>) {
  const selectMethod = useMemo(() => {
    switch (requirement) {
      case PermissionsRequirement.WITH:
      case PermissionsRequirement.AWS_ACCOUNT:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.AwsAccountCloudFormationEcs,
                label: 'Cloudformation (ECS)',
              },
              {
                value: InstallMethod.AwsAccountTerraformEcs,
                label: 'Terraform (ECS)',
              },
              {
                value: InstallMethod.AwsAccountTerraformEks,
                label: 'Terraform (EKS)',
              },
              {
                value: InstallMethod.AwsAccountCliEks,
                label: 'CLI (EKS)',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      case PermissionsRequirement.WITHOUT:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.AwsAccountCloudFormationEcsPermissionless,
                label: 'Cloudformation (ECS)',
              },
              {
                value: InstallMethod.AwsAccountTerraformEcsPermissionless,
                label: 'Terraform (ECS)',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      case PermissionsRequirement.AWS_ORGANIZATION:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.AwsOrganizationCloudFormationEcs,
                label: 'Cloudformation (ECS)',
              },
              {
                value: InstallMethod.AwsOrganizationTerraformEcs,
                label: 'Terraform (ECS)',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      default:
        return <></>;
    }
  }, [requirement, method, handleMethodSelect]);

  if (fixedRequirement) {
    return selectMethod;
  }

  return (
    <>
      <SelectPermissionsRequirement
        requirement={requirement}
        options={[
          {
            value: PermissionsRequirement.WITH,
            label: 'Install and Connect AWS Account',
          },
          {
            value: PermissionsRequirement.WITHOUT,
            label: 'No, Just Install The Connector',
          },
        ]}
        onSelect={handleRequirementSelect}
      />
      {selectMethod}
    </>
  );
}

function GcpPlatformWizard({
  fixedRequirement,
  requirement,
  method,
  handleRequirementSelect,
  handleMethodSelect,
}: Omit<PlatformWizardProps, 'platform'>) {
  const selectMethod = useMemo(() => {
    switch (requirement) {
      case PermissionsRequirement.GCP_PROJECT:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.GcpProjectCliGke,
                label: 'CLI (GKE)',
              },
              {
                value: InstallMethod.GcpProjectCloudRun,
                label: 'CLI (Cloud Run)',
              },
              {
                value: InstallMethod.GcpTerraformGke,
                label: 'Terraform (GKE)',
              }
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      case PermissionsRequirement.GCP_ORGANIZATION:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.GcpOrganizationCliGke,
                label: 'CLI (GKE)',
              },
              {
                value: InstallMethod.GcpOrganizationCloudRun,
                label: 'CLI (Cloud Run)',
              },
              {
                value: InstallMethod.GcpTerraformGke,
                label: 'Terraform (GKE)',
              }
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      default:
        return <></>;
    }
  }, [requirement, method, handleMethodSelect]);

  if (fixedRequirement) {
    return selectMethod;
  }

  return (
    <>
      <SelectPermissionsRequirement
        requirement={requirement}
        options={[
          {
            value: PermissionsRequirement.GCP_PROJECT,
            label: 'Install and Connect GCP Project',
          },
          {
            value: PermissionsRequirement.GCP_ORGANIZATION,
            label: 'Install and Connect GCP Organization',
          },
        ]}
        onSelect={handleRequirementSelect}
      />
      {selectMethod}
    </>
  );
}

function K8sPlatformWizard({
  fixedRequirement,
  requirement,
  method,
  handleRequirementSelect,
  handleMethodSelect,
}: Omit<PlatformWizardProps, 'platform'>) {
  const selectMethod = useMemo(() => {
    switch (requirement) {
      case PermissionsRequirement.WITH:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.K8sTerraform,
                label: 'Terraform',
              },
              {
                value: InstallMethod.K8sHelm,
                label: 'Helm',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      case PermissionsRequirement.WITHOUT:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.K8sTerraformPermissionless,
                label: 'Terraform',
              },
              {
                value: InstallMethod.K8sHelmPermissionless,
                label: 'Helm',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      default:
        return <></>;
    }
  }, [requirement, method, handleMethodSelect]);

  if (fixedRequirement) {
    return selectMethod;
  }

  return (
    <>
      <SelectPermissionsRequirement
        requirement={requirement}
        options={[
          {
            value: PermissionsRequirement.WITH,
            label: 'Install and Connect K8S Cluster',
          },
          {
            value: PermissionsRequirement.WITHOUT,
            label: 'No, Just Install The Connector',
          },
        ]}
        onSelect={handleRequirementSelect}
      />
      {selectMethod}
    </>
  );
}

function AzurePlatformWizard({
  fixedRequirement,
  requirement,
  method,
  handleRequirementSelect,
  handleMethodSelect,
}: Omit<PlatformWizardProps, 'platform'>) {
  const selectMethod = useMemo(() => {
    switch (requirement) {
      case PermissionsRequirement.WITH:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.AzureSubscriptionTerraformCi,
                label: 'Terraform (Container Instance)',
              },
              {
                value: InstallMethod.AzureSubscriptionCliCi,
                label: 'CLI (Container Instance)',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      case PermissionsRequirement.WITHOUT:
        return (
          <SelectInstallationMethod
            options={[
              {
                value: InstallMethod.AzureSubscriptionTerraformCiPermissionless,
                label: 'Terraform (Container Instance)',
              },
              {
                value: InstallMethod.AzureSubscriptionCliCiPermissionless,
                label: 'CLI (Container Instance)',
              },
            ]}
            method={method}
            onSelect={handleMethodSelect}
          />
        );
      default:
        return <></>;
    }
  }, [requirement, method, handleMethodSelect]);

  if (fixedRequirement) {
    return selectMethod;
  }

  return (
    <>
      <SelectPermissionsRequirement
        requirement={requirement}
        options={[
          {
            value: PermissionsRequirement.WITH,
            label: 'Install and Connect Azure Account',
          },
          {
            value: PermissionsRequirement.WITHOUT,
            label: 'No, Just Install The Connector',
          },
        ]}
        onSelect={handleRequirementSelect}
      />
      {selectMethod}
    </>
  );
}
