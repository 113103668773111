export const QueryParams = {
  filterIntegrationId: 'filter.integration_id',
  filterPermission: 'filter.permission',
  filterRequestorId: 'filter.requestor_id',
  filterResource: 'filter.resource',
  filterStatus: 'filter.status',
  filterResourceType: 'filter.resource_type',
  filterBundleId: 'filter.bundle_id',
  filterTriggerType: 'filter.trigger_type',
  filterAccessFlowId: 'filter.access_flow_id',
  page: 'page',
  fields: 'fields',
  period: 'period',
  startDate: 'start_date',
  endDate: 'end_date',
  utc: 'utc',
  requestId: 'request_id',
} as const;

export type QueryParamsKeys = (typeof QueryParams)[keyof typeof QueryParams];

export const QUERY_KEYS = {
  Activity: 'Activity',
  Search: 'Search',
  Single: 'Single',
  AccessUnits: 'AccessUnits',
  AccessUnitGroups: 'AccessUnitGroups',
  TimeLine: 'TimeLine',
};

export const REFETCH_INTERVAL = 10 * 1000; // 10 sec
