import { Box, BoxProps, CircularProgress, CircularProgressProps, Stack, Typography, styled } from '@mui/material';

const StyledLoader = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  '&.absolute': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.muted">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export interface LoaderProps extends BoxProps {
  absolute?: boolean;
  widthProgress?: boolean;
  value?: number;
}

export function Loader({ absolute = true, widthProgress = false, value = 100, ...rest }: LoaderProps) {
  return (
    <StyledLoader className={absolute ? 'absolute' : ''}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box {...rest}>{widthProgress ? <CircularProgressWithLabel value={value} /> : <CircularProgress />}</Box>
      </Stack>
    </StyledLoader>
  );
}
