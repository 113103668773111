import { Typography } from '@mui/material';

export default function TableListTotal({
  totalResults,
  displayedResults,
}: {
  totalResults: number;
  displayedResults: number;
}) {
  const formatter = new Intl.NumberFormat();
  const formattedTotalResults = formatter.format(totalResults);
  const formattedDisplayedResults = formatter.format(displayedResults);

  return (
    <Typography variant="caption" color="text.muted" component="div" width="150px" textAlign="right" pt={1}>
      {totalResults > displayedResults
        ? `${formattedDisplayedResults} of ${formattedTotalResults} results`
        : `${formattedDisplayedResults} results`}
    </Typography>
  );
}
