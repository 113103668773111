import CreateGroupModal from '@Groups/organisms/modals/CreateGroupModal';
import DeleteGroupModal from '@Groups/organisms/modals/DeleteGroupModal';
import EditGroupModal from '@Groups/organisms/modals/EditGroupModal';
import { AponoGroupModel } from '@api';
import ViewGroupModal from './modals/ViewGroupModal';

export default function GroupsModals({
  isCreateModalOpen,
  setIsCreateModalOpen,
  groupInEdit,
  setGroupInEdit,
  groupInDeletion,
  setGroupInDeletion,
  groupId,
}: {
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: (value: boolean) => void;
  groupInEdit: AponoGroupModel | null;
  setGroupInEdit: (value: AponoGroupModel | null) => void;
  groupInDeletion: AponoGroupModel | null;
  setGroupInDeletion: (value: AponoGroupModel | null) => void;
  groupId: string | null;
}) {
  return (
    <>
      <CreateGroupModal isCreateModalOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
      {groupId && <ViewGroupModal groupId={groupId} />}
      {groupInEdit && <EditGroupModal groupInEdit={groupInEdit} onClose={() => setGroupInEdit(null)} />}
      {groupInDeletion && (
        <DeleteGroupModal groupInDeletion={groupInDeletion} onClose={() => setGroupInDeletion(null)} />
      )}
    </>
  );
}
