import { useAppQuery } from '@api/query/use-app-query';
import { AccountQueryKeys } from '@common/services/queryKeys';

import { AccountsService } from '@api/services';
import { AccountsApiAppGetIdentitiesRequest, API_LIST_LIMIT, useListQuery } from '@api';
import { QueryFunctionContext } from '@tanstack/react-query';

export function useAppListIdentities(props?: AccountsApiAppGetIdentitiesRequest, enabled = true) {
  const fetch = async () => {
    const res = await AccountsService.appGetIdentities(props);
    return res;
  };

  const { data, ...rest } = useAppQuery([AccountQueryKeys.AppGetIdentities, props], fetch, {
    enabled,
    keepPreviousData: true,
  });

  return {
    identities: data?.data || [],
    pagination: data?.pagination || { total: 0, limit: 10, offset: 0 },
    ...rest,
  };
}

export function useAppListIdentitiesInfinityPagination(
  props?: AccountsApiAppGetIdentitiesRequest,
  restProps?: { refetch?: number; withoutPageParam?: boolean; enabled?: boolean },
) {
  const fetchMethod = async ({ pageParam: skipParam = 0 }: QueryFunctionContext) =>
    AccountsService.appGetIdentities({
      ...props,
      limit: API_LIST_LIMIT,
      skip: skipParam,
    });

  return useListQuery({ fetchMethod, queryKey: [AccountQueryKeys.AppGetIdentities, props], ...restProps });
}
