import { graphql } from '@api/gql';
import { QueryAuditEventsArgs } from '@api/gql/graphql';
import { QueryKeys } from '@AuditEvents/services/queryKeys';
import { FrontierService } from '@api';
import { useState } from 'react';
import { useAppQuery } from '@api/query/use-app-query';

const auditEventsQueryDocument = graphql(/* GraphQL */ `
  query AuditEvents($pagination: PaginationInput, $filter: AuditEventsFilterInput) {
    auditEvents(pagination: $pagination, filter: $filter) {
      totalCount
      edges {
        node {
          id
          user {
            id
            email
            firstName
            lastName
          }
          integration {
            id
            name
            type
            typeDisplayName
            icons {
              svg
            }
          }
          eventType
          action
          target
          timestamp
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`);

export function useListAuditEvents(args: QueryAuditEventsArgs) {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(auditEventsQueryDocument, args);
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery([QueryKeys.AuditEvents, JSON.stringify(args)], fetch, {
    keepPreviousData: true,
  });

  return { ...res, isFetched: isFirstTimeFetched };
}
