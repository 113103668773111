import { useMemo, useState } from 'react';
import {
  InteractiveDropdown,
  InteractiveDropdownFooter,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
  Popover,
  usePopover,
} from '@components';
import { AccessFlowAreaModel, ApproverModel, ApproverTypeModel } from '@api';
import { yup } from '@libs';
import { DropdownContextAttributeView, DropdownGroupView, DropdownUserView } from './common/DropdownIdpView';
import { HumanReadableApprover } from './HumanReadable/Approver';
import { useErrorText } from './common/use-error-text.hook';
import { useAccessFlowsPack, useGroups } from '@hooks';
import { useAttributeOptions } from './common/use-attributes-options';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

enum ApproverTypeAutoModel {
  Auto = 'auto',
}

export interface FlowFormSelectApproversProps {
  template?: string;
  value?: ApproverModel[];
  onChange: (value: ApproverModel[]) => void;
  error?: yup.ValidationError;
}

function isAuto(type?: string): type is ApproverTypeAutoModel {
  return (type as ApproverTypeAutoModel) === ApproverTypeAutoModel.Auto;
}

const DEFAULT_PLACEHOLDER = 'automatic';

function useFlowFormSelectApprovers({ onChange, template }: FlowFormSelectApproversProps) {
  const { refetchPack } = useAccessFlowsPack();
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string | undefined>(template);
  const [currentView, setCurrentView] = useState(0);
  const [approverType, setApproverType] = useState<string | undefined>();
  const { track } = useAnalyticsContext();
  const onClose = () => {
    setCurrentView(0);
    setApproverType(undefined);
    showErrorText();
  };

  const onOpen = () => {
    refetchPack();
    hideErrorText();
  };

  const externalState = usePopover({ onClose, onOpen });

  const handleApproverTypeChange = (type: string) => {
    if (isAuto(type)) {
      handleOnClearAll();
      setCurrentView(0);
      externalState.closeDropdown(true);
    } else {
      setApproverType(type);
      setCurrentView(1);
    }
    track(CustomAnalyticsEvents.SELECT_APPROVER, {
      approverType: type,
    });
    clearTemplatePlaceholder();
  };

  function handleOnClearAll() {
    setApproverType(ApproverTypeAutoModel.Auto);
    onChange([]);
  }

  const handleOnBackClick = () => {
    setCurrentView(0);
    setApproverType(undefined);
  };

  const handleApproversListChange = (approvers: ApproverModel[]) => {
    onChange(approvers);
    clearTemplatePlaceholder();
  };

  const clearTemplatePlaceholder = () => {
    if (templatePlaceholder) setTemplatePlaceholder(undefined);
  };

  return {
    templatePlaceholder,
    errorTextHidden,
    currentView,
    approverType,
    handleApproverTypeChange,
    handleOnBackClick,
    handleApproversListChange,
    handleOnClearAll,
    externalState,
  };
}

export function FlowFormSelectApprovers({ value, onChange, error, template }: FlowFormSelectApproversProps) {
  const {
    templatePlaceholder,
    errorTextHidden,
    currentView,
    approverType,
    handleApproverTypeChange,
    handleOnBackClick,
    handleApproversListChange,
    handleOnClearAll,
    externalState,
  } = useFlowFormSelectApprovers({ template, onChange });

  return (
    <Popover
      testId="flow-form-select-approvers"
      trigger={
        <HumanReadableApprover
          value={value || []}
          placeholder={templatePlaceholder || DEFAULT_PLACEHOLDER}
          placeholderMuted={!!templatePlaceholder}
          errorText={error?.message}
          hideErrorText={errorTextHidden}
        />
      }
      externalState={externalState}
    >
      <InteractiveDropdown wide multipleViews currentView={currentView}>
        <DropdownApproverTypeView
          current={currentView === 0}
          value={approverType}
          approversValue={value}
          onChange={handleApproverTypeChange}
          onClearAll={handleOnClearAll}
        />
        <DropdownApproversListView
          current={currentView === 1}
          approverType={approverType}
          value={value || []}
          onChange={handleApproversListChange}
          onBackClick={handleOnBackClick}
        />
      </InteractiveDropdown>
    </Popover>
  );
}

interface DropdownApproverTypeViewProps {
  current: boolean;
  value?: string;
  approversValue?: ApproverModel[];
  onChange: (value: string) => void;
  onClearAll?: () => void;
}

function useDropdownApproverTypeView({ approversValue }: Pick<DropdownApproverTypeViewProps, 'approversValue'>) {
  const { groups, doneOnce } = useGroups();
  const { attributeOptions, isAttributesFetched } = useAttributeOptions({
    flowArea: AccessFlowAreaModel.Approver,
    values: approversValue,
  });

  const options = useMemo(() => {
    function typedOption(type: ApproverTypeModel, label: string) {
      const count = approversValue?.filter((v) => v.type === type).length || 0;
      if (count > 0) {
        label = `${label}/${count}`;
      }

      return {
        key: type,
        value: label,
        hasNextView: true,
        lookMultiple: true,
        lookSelected: count > 0,
      };
    }

    const selectOptions: InteractiveDropdownSelectOption<string>[] = [
      {
        key: ApproverTypeAutoModel.Auto,
        value: 'Automatically',
        lookSelected: (approversValue || []).length === 0,
      },
      typedOption(ApproverTypeModel.User, 'Users'),
    ];

    if (groups && groups.length > 0) {
      selectOptions.push(typedOption(ApproverTypeModel.Group, 'Groups'));
    }

    if (attributeOptions.length > 0) {
      selectOptions.push(...attributeOptions);
    }

    return selectOptions;
  }, [groups, attributeOptions, approversValue]);

  return {
    options,
    isLoading: !(doneOnce && isAttributesFetched),
  };
}

function DropdownApproverTypeView({
  current,
  value,
  approversValue,
  onChange,
  onClearAll,
}: DropdownApproverTypeViewProps) {
  const { options, isLoading } = useDropdownApproverTypeView({
    approversValue,
  });

  if (isLoading) {
    return <InteractiveDropdownView current loading />;
  }

  return (
    <InteractiveDropdownView
      current={current}
      header={<InteractiveDropdownHeader title="Select approvers" />}
      footer={<InteractiveDropdownFooter onClearAllClick={onClearAll} />}
    >
      <InteractiveDropdownSelect<string> options={options} value={value} onChange={onChange} />
    </InteractiveDropdownView>
  );
}

interface DropdownApproversListViewProps {
  current: boolean;
  approverType?: string;
  approverTypeCategory?: string;
  value: ApproverModel[];
  onChange: (value: ApproverModel[]) => void;
  onBackClick?: () => void;
}

function DropdownApproversListView({
  current,
  approverType,
  onBackClick,
  value,
  onChange,
}: DropdownApproversListViewProps) {
  const [approverTypeModel, approverTypeCategoryKey] = useMemo(() => {
    if (!approverType) return [undefined, undefined];

    const parts = approverType.split('/');
    return [parts[0] as ApproverTypeModel, parts.length > 1 ? parts[1] : undefined];
  }, [approverType]);

  const handleChange = (type: ApproverTypeModel, typeValue: string[]) => {
    const filteredByType = value.filter((v) => v.type !== type);
    const newValue = filteredByType.concat(typeValue.map((v) => ({ type, id: v })));
    onChange(newValue);
  };

  const commonProps = {
    current,
    onBackClick,
    value: value.filter((v) => v.type === approverTypeModel).map((v) => v.id),
  };

  switch (approverTypeModel) {
    case ApproverTypeModel.Group:
      return <DropdownGroupView {...commonProps} onChange={(val) => handleChange(ApproverTypeModel.Group, val)} />;
    case ApproverTypeModel.User:
      return <DropdownUserView {...commonProps} onChange={(val) => handleChange(ApproverTypeModel.User, val)} />;
    case ApproverTypeModel.ContextAttribute:
      if (approverTypeCategoryKey) {
        return (
          <DropdownContextAttributeView
            {...commonProps}
            contextKey={approverTypeCategoryKey}
            onChange={(val) => handleChange(ApproverTypeModel.ContextAttribute, val)}
          />
        );
      }
  }
  return <></>;
}
