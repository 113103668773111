export enum DurationUnit {
  Indefinite = 'Indefinite',
  Years = 'Years',
  Months = 'Months',
  Weeks = 'Weeks',
  Days = 'Days',
  Hours = 'Hours',
  Minutes = 'Minutes',
  Seconds = 'Seconds',
}
