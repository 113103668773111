import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountsService, ConnectorsService, InstallationMethod, QueryKeys } from '@api';

const REFETCH_INTERVAL = 10000;

export function useGetConnectorInstallLink(method: InstallationMethod) {
  const { data: installConnectorLink, isFetched: isInstallConnectorLinkFetched } = useQuery({
    queryKey: [QueryKeys.ConnectorInstallation, method],
    queryFn: async () => {
      const res = await ConnectorsService.getInstallLink({ method });
      return res.link;
    },
  });

  return {
    installConnectorLink,
    isInstallConnectorLinkFetched,
  };
}

export function useListAgents(refetch = false) {
  const { data: connectors, isFetched: isConnectorsFetched } = useQuery({
    queryKey: [QueryKeys.Connectors],
    queryFn: async () => ConnectorsService.listAgents(),
    initialData: [],
    refetchInterval: refetch ? REFETCH_INTERVAL : false,
  });

  return {
    connectors,
    isConnectorsFetched,
  };
}

export function useGetAgent(id: string) {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.Connectors, id],
    queryFn: async () => ConnectorsService.getAgent({ id }),
  });

  return {
    connector: data,
    ...rest,
  };
}

export function useDeleteAgent(id: string) {
  const queryClient = useQueryClient();

  const { mutate: deleteAgent, ...rest } = useMutation({
    mutationFn: async () =>
      ConnectorsService.deleteAgent({
        id,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Connectors] });
    },
  });

  return {
    deleteAgent,
    ...rest,
  };
}

export function useConnectorToken() {
  const { data: token, isFetched: isConnectorsTokenFetched } = useQuery({
    queryKey: [QueryKeys.ConnectorsTokens],
    queryFn: async () => {
      const tokens = await AccountsService.listConnectorTokens();

      if (tokens.length === 0) {
        return;
      }

      return tokens[0];
    },
  });

  return {
    token,
    isConnectorsTokenFetched,
  };
}
