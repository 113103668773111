import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { TopQueryEntityType, useListAccessFlows, useQueryTopOverTime } from '@api';
import { useAccessFlowsPack } from '@hooks';
import {
  Loader,
  MaterialIcon,
  WidgetCard,
  WidgetCardEmpty,
  WidgetCardList,
  WidgetCardListItemProps,
} from '@components';
import { routes } from '@routes';
import { useUpdateEffect } from '@libs';

export interface TopOverTimeProps {
  type: TopQueryEntityType;
  endDate: string;
  startDate: string;
}

function useTopOverTime({ type, endDate, startDate }: TopOverTimeProps) {
  const { topOverTime, isTopOverTimeFetched, refetchTopOverTime } = useQueryTopOverTime(endDate, startDate, type);
  const { isFetched, users, catalog, connectedIntegrations, refetchPack } = useAccessFlowsPack();
  const { accessFlows, isAccessFlowsFetched, refetchAccessFlows } = useListAccessFlows([]);

  useUpdateEffect(() => {
    refetchTopOverTime();
    refetchPack();
    refetchAccessFlows();
  }, [endDate, startDate]);

  function getUser(id: string) {
    return users.find((user) => user.id === id);
  }

  function getIntegration(id: string) {
    const ci = connectedIntegrations.find((int) => int.id === id);
    const config = getIntegrationConfig(ci?.type);

    return {
      integration: ci,
      config,
    };
  }

  function getIntegrationConfig(typeStr?: string) {
    return catalog?.find((cat) => cat.type === typeStr);
  }

  function getAccessFlow(id: string) {
    return accessFlows.data.find((cat) => cat.id === id);
  }

  const fetched = useMemo(
    () => isTopOverTimeFetched && isFetched && isAccessFlowsFetched,
    [isTopOverTimeFetched, isFetched, isAccessFlowsFetched],
  );

  const title = useMemo(() => {
    switch (type) {
      case TopQueryEntityType.Grantees:
        return 'Top Requestors';
      case TopQueryEntityType.Approvers:
        return 'Top Approvers';
      case TopQueryEntityType.Integrations:
        return 'Top requested resources';
      case TopQueryEntityType.AccessFlows:
        return 'Top triggered Access Flows';
    }
  }, [type]);

  const items = useMemo(() => {
    const list: WidgetCardListItemProps[] = [];

    switch (type) {
      case TopQueryEntityType.Grantees:
      case TopQueryEntityType.Approvers:
        topOverTime.forEach((item) => {
          const user = getUser(item.id);
          if (!user) return;

          const listItem: WidgetCardListItemProps = {
            primary: `${user.first_name} ${user.last_name}`,
            secondary: user.email,
            count: item.count,
            actionLabel: 'Show details',
            actionTo: generatePath(routes.Activity.path),
          };

          const idpConfig = getIntegrationConfig(user.source_idp_type || undefined);
          if (idpConfig) {
            listItem.iconSrc = idpConfig.icons.svg;
          } else {
            listItem.icon = <MaterialIcon symbol="person" sx={{ display: 'block' }} />;
          }

          list.push(listItem);
        });
        break;
      case TopQueryEntityType.Integrations:
        topOverTime.forEach((item) => {
          const { integration, config } = getIntegration(item.id);
          if (!integration) return;
          list.push({
            primary: integration.name,
            iconSrc: config?.icons.svg,
            count: item.count.toLocaleString(),
            actionLabel: 'Show details',
            actionTo: generatePath(routes.Activity.path),
          });
        });
        break;
      case TopQueryEntityType.AccessFlows:
        topOverTime.forEach((item) => {
          const accessFlow = getAccessFlow(item.id);
          if (!accessFlow) return;
          list.push({
            primary: accessFlow.name,
            count: item.count.toLocaleString(),
            actionLabel: 'Show details',
            actionTo: generatePath(routes.Activity.path),
          });
        });
        break;
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, topOverTime, users, catalog, connectedIntegrations]);

  return { fetched, loading: false, title, items };
}

export function TopOverTime(props: TopOverTimeProps) {
  const { fetched, title, items, loading } = useTopOverTime(props);

  if (!fetched) {
    return (
      <WidgetCard title={title}>
        <Loader absolute={false} />
      </WidgetCard>
    );
  }

  if (items.length === 0) {
    return (
      <WidgetCard title={title}>
        <WidgetCardEmpty />
      </WidgetCard>
    );
  }

  return (
    <WidgetCard title={title} loading={loading}>
      <WidgetCardList items={items} />
    </WidgetCard>
  );
}
