import { Flag } from '@utils';
import { adminLayoutWrap } from '@middlewares';

import { WebhooksPage } from './pages/WebhooksPage';
import { AddWebhookPage } from './pages/AddWebhookPage';
import { EditWebhookPage } from './pages/EditWebhookPage';
import { WebhookHistoryPage } from './pages/WebhookHistoryPage';

export const routes = {
  Webhooks: {
    label: 'Webhooks',
    path: '/webhooks',
    page: adminLayoutWrap(<WebhooksPage />, Flag.OUTBOUND_WEBHOOKS),
  },
  AddWebhook: {
    label: 'Add Webhook',
    path: '/webhooks/create',
    page: adminLayoutWrap(<AddWebhookPage />, Flag.OUTBOUND_WEBHOOKS),
  },
  EditWebhook: {
    label: 'Edit Webhook',
    path: '/webhooks/edit/:id',
    page: adminLayoutWrap(<EditWebhookPage />, Flag.OUTBOUND_WEBHOOKS),
  },
  WebhookHistory: {
    label: 'Webhook History',
    path: '/webhooks/history/:id',
    page: adminLayoutWrap(<WebhookHistoryPage />, Flag.OUTBOUND_WEBHOOKS),
  },
};
