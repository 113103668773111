import { generatePath, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SYNC_PARAM } from '@Integrations/organisms/EditConnectedIntegration/EditConnectedIntegration';
import { IntegrationConfigWithSubIntegrations, useCreateIntegrationV2 } from '@api';
import { ConnectResourceIntegrationPathsProps } from '@Integrations/organisms/ConnectIntegration/ConnectResourceIntegration';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import {
  checkIntegrationConfigDisabled,
  checkSecretStoreDisabled,
  getCreateFormDefaultValues,
  useIsInViewMode,
} from '@Integrations/IntegrationConfigForm/utils';
import { NEW_CONNECTOR_VALUE } from '@organisms/IntegrationConnectorForm/SelectConnector';

export function useIntegrationForm({
  paths,
  configs,
}: {
  paths: ConnectResourceIntegrationPathsProps;
  configs: IntegrationConfigWithSubIntegrations[];
}) {
  const isViewMode = useIsInViewMode();
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState(0);
  const { createIntegration, isCreateIntegrationLoading, createdIntegration } = useCreateIntegrationV2();
  const { ...methods } = useForm<IntegrationConfigFormPayload>({
    defaultValues: getCreateFormDefaultValues(configs[0]),
  });
  const { watch } = methods;

  const type = methods.watch('type');
  const config = useMemo(() => configs.find((c) => c.type === type) || configs[0], [type, configs]);

  useEffect(() => {
    if (createdIntegration)
      navigate(
        `${generatePath(paths.editPath, {
          id: createdIntegration.id,
        })}?${SYNC_PARAM}=true`,
      );
  }, [createdIntegration, navigate, paths.editPath]);

  function onSubmit(data: IntegrationConfigFormPayload) {
    if (data.secret_config) {
      const values = Object.values(data.secret_config).filter(Boolean);
      if (values.length === 1) delete data.secret_config;
    }

    if (data.custom_access_details_settings?.instructions_access_message === '')
      delete data.custom_access_details_settings.instructions_access_message;

    if (data.connected_sub_integration_resource_types?.every((csi) => csi.connected_resource_types.length === 0)) {
      delete data.connected_sub_integration_resource_types;
    } else if (
      data.connected_sub_integration_resource_types?.some((csi) => csi.connected_resource_types.length === 0)
    ) {
      data.connected_sub_integration_resource_types = data.connected_sub_integration_resource_types.filter(
        (csi) => csi.connected_resource_types.length > 0,
      );
    }
    createIntegration(data);
  }

  const hasSecretStore = config.supported_secret_types.length > 0;
  const currentSection = hasSecretStore ? 4 : 3;
  const isConnectorsDisabled = !watch('provisioner_id') || watch('provisioner_id') === NEW_CONNECTOR_VALUE;
  const isResourceTypeDisabled = !watch('connected_resource_types')?.length;
  const integrationConfigDisabled = checkIntegrationConfigDisabled(config, watch);
  const isSecretStoreDisabled = checkSecretStoreDisabled(config, watch);
  const isFormDisabled =
    !methods.formState.isValid ||
    methods.formState.isSubmitting ||
    isConnectorsDisabled ||
    isSecretStoreDisabled ||
    isResourceTypeDisabled ||
    integrationConfigDisabled;

  return {
    config,
    methods,
    onSubmit,
    openSection,
    setOpenSection,
    hasSecretStore,
    currentSection,
    isFormDisabled,
    isConnectorsDisabled,
    isSecretStoreDisabled,
    isResourceTypeDisabled,
    integrationConfigDisabled,
    isCreateIntegrationLoading,
    isViewMode,
  };
}
