import { styled, Box, Stack, Typography, Avatar } from '@mui/material';
import { ReactNode } from 'react';

const ConfigFormHeaderContainer = styled(Box)(({ theme }) => ({
  maxWidth: '700px',
  margin: theme.spacing(0, 'auto', 6),
}));

const ConfigFormHeaderIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export interface ConfigFormHeaderProps {
  icons?: string[];
  title: string;
  description?: ReactNode;
}

export function ConfigFormHeader({ icons, title, description }: ConfigFormHeaderProps) {
  return (
    <ConfigFormHeaderContainer>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        {icons && <ConfigFormHeaderIcons icons={icons} />}
        <Typography variant="h5" color="text.primary" component="p" textAlign="center">
          {title}
        </Typography>
        {description && <ConfigFormHeaderDescription description={description} />}
      </Stack>
    </ConfigFormHeaderContainer>
  );
}

function ConfigFormHeaderIcons({ icons }: { icons: string[] }) {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
      {icons.map((icon, i) => (
        <ConfigFormHeaderIcon key={i} src={icon} />
      ))}
    </Stack>
  );
}

function ConfigFormHeaderDescription({ description }: { description: ReactNode }) {
  return (
    <Typography variant="body2" color="text.filter" component="p" textAlign="center">
      {description}
    </Typography>
  );
}
