import { Box, Grid, Stack, Typography } from '@mui/material';

import { Page, TemplateSuggestion } from '@components';
import { templates } from '@af-templates';
import { routes } from '@routes';
import { useAuthUser } from '@libs';
import { Dashboard, GetStarted } from '@organisms';
import { useSession } from '@api';
import { useMemo } from 'react';
import { AccessFlowSentence } from '@AccessFlows/organisms/FlowForm';

export function HomePage() {
  const user = useAuthUser();
  const { session } = useSession();
  const showDashboard = useMemo(() => !session?.onboarding?.active, [session]);

  return (
    <Page contentPadded contentCentered>
      <Stack direction="column" justifyContent="center" spacing={3} mb={3}>
        <Typography variant="h5">Welcome to Apono, {user.name} 👋</Typography>
        {showDashboard ? <Dashboard /> : <GetStarted />}
        <Typography variant="subtitle1">Recommended Access Flows</Typography>
        <Box>
          <Grid container spacing={3}>
            {templates.map((template) => (
              <Grid item xs={12} sm={6} md={4} key={template.id}>
                <TemplateSuggestion
                  id={template.id}
                  title={template.name}
                  to={`${routes.AddAccessFlow.path}?fromTemplate=${template.id}`}
                >
                  <AccessFlowSentence template={template} />
                </TemplateSuggestion>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Page>
  );
}
