import { useCallback, useState } from 'react';
import { BaseListFilterSelectProps, ListFilterOption, usePrepareOptions } from './common';
import ListFilterSelect from './ListFilterSelect';
import { AnalyticsTriggerElementProps } from '@common/types';

interface ListFilterMultiSelectProps extends BaseListFilterSelectProps {
  value?: ListFilterOption[];
  onSelect: (value: ListFilterOption[]) => void;
}

export default function ListFilterMultiSelect({
  value,
  onSelect,
  options,
  title,
  enableSearch = false,
  maxHeight = 300,
  groupedByCategory,
  loading,
  ...analyticsProps
}: ListFilterMultiSelectProps & AnalyticsTriggerElementProps) {
  const [searchValue, setSearchValue] = useState('');

  const handleSelect = useCallback(
    (option: ListFilterOption) => {
      const selectedValues = new Set(value?.map((v) => v.value));
      if (selectedValues.has(option.value)) {
        onSelect(value?.filter((v) => v.value !== option.value) ?? []);
      } else {
        onSelect([...(value ?? []), option]);
      }
    },
    [value, onSelect],
  );

  const items = usePrepareOptions({
    options,
    handler: handleSelect,
    enableSearch,
    searchValue,
    type: 'checkbox',
    selectedValues: value ? new Set(value?.map((v) => v.value)) : undefined,
    groupedByCategory,
  });

  return (
    <ListFilterSelect
      {...{
        maxHeight,
        title,
        onSearch: enableSearch ? setSearchValue : undefined,
        items,
        loading,
        ...analyticsProps,
      }}
    />
  );
}
