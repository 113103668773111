import { Box, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';

import { WebhookInvocationAppModel } from '@api';
import { CodeViewer, PaperDetails, PaperDetail } from '@components';
import { useMemo, useState } from 'react';
import { DateTime } from '@utils';

interface WebhookInvocationProps {
  invocation: WebhookInvocationAppModel;
}

enum TabValues {
  Request,
  Response,
}

export default function WebhookInvocation({ invocation }: WebhookInvocationProps) {
  const [tab, setTab] = useState(TabValues.Response);

  const tabContent = useMemo(() => {
    if (tab === TabValues.Request) {
      return <InvocationData body={invocation.request.body} headers={invocation.request.headers} />;
    }

    if (tab === TabValues.Response && invocation.response) {
      return <InvocationData body={invocation.response.body} headers={invocation.response.headers} />;
    }

    return null;
  }, [invocation.request, invocation.response, tab]);

  return (
    <Stack spacing={2}>
      <PaperDetails
        details={[
          {
            label: 'Status',
            value: (
              <Typography variant="caption" color={invocation.success ? 'success.main' : 'error.main'}>
                {invocation.success ? 'Success' : 'Failed'}
                {invocation.response && ` (${invocation.response.status_code})`}
              </Typography>
            ),
          },
          {
            label: 'Invocation Date',
            value: DateTime.fromUnixWithFormat(Number(invocation.invocation_date)),
          },
          {
            label: 'Trigger',
            value: invocation.trigger,
          },
          {
            label: 'Method',
            value: invocation.request.method,
          },
          {
            label: 'URL',
            value: invocation.request.url,
          },
        ]}
      />
      <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)}>
        {invocation.response && <Tab label="Response" value={TabValues.Response} />}
        <Tab label="Request" value={TabValues.Request} />
      </Tabs>
      {tabContent}
    </Stack>
  );
}

function InvocationData({ body, headers }: { body?: string; headers: Record<string, string> }) {
  const bodyString = useMemo(() => {
    if (!body) return null;
    try {
      return JSON.stringify(JSON.parse(body), null, 2);
    } catch {
      return body;
    }
  }, [body]);

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Body</Typography>
      <Paper>
        {bodyString ? (
          <CodeViewer value={bodyString} height={300} />
        ) : (
          <Typography variant="body2" component="em" p={1}>
            No body
          </Typography>
        )}
      </Paper>
      <Typography variant="subtitle2">Headers</Typography>
      <Box>
        {Object.entries(headers).map(([key, value]) => (
          <PaperDetail key={key} label={key} value={value} noDivider />
        ))}
      </Box>
    </Stack>
  );
}
