import { TriggerType } from '@api';

export const TriggerValues = {
  [TriggerType.UserRequest]: 'requests',
  [TriggerType.AutoGrant]: 'auto grant',
};

export enum TagsType {
  Name = '__name',
  Id = '__id',
  Tags = 'tags',
}
