import { INSIGHT_CONFIG_MAP, InsightType, MetricType } from './constants';

export const getInsightConfig = (key: string) => {
  if (key in INSIGHT_CONFIG_MAP) {
    return INSIGHT_CONFIG_MAP[key as InsightType];
  }
  return { title: '', recommendation: '', insightBannerHeader: '', description: '', findingsText: '', entity: '' };
};

export const SECONDS_TO_HOURS = 3600;

export const secondsToHours = (seconds: number) => {
  const hours = Math.round(seconds / SECONDS_TO_HOURS);
  let formattedHours = hours.toFixed(1);
  // Remove the decimal part if it is zero
  if (formattedHours.endsWith('.0')) {
    formattedHours = formattedHours.slice(0, -2);
  }

  return parseFloat(formattedHours) === 1 ? `${hours} hour` : `${hours} hours`;
};

export function isValidMetricType(value: string): value is MetricType {
  return [
    'total_resources_not_requested',
    'total_permissions_not_requested',
    'total_users_not_requesting',
    'avg_access_request_duration',
    'total_requests',
  ].includes(value);
}
