import { ApproverConditionAppModel, ApproverConditionGroupAppModel, ApproverGroupOperatorAppModel } from '@api';
import { nanoid } from 'nanoid';

import FlowFormSection from '@AccessFlows/components/FlowFormSection';

import TabsSwitch from '@common/ui/TabsSwitch';
import { MaterialIcon } from '@components';
import { Button, Tab } from '@mui/material';
import { useState } from 'react';
import ApproverConditionSelector from '@AccessFlows/organisms/FlowForm/Form/ApproverConditionSelector';
import { LineActions } from '@AccessFlows/organisms/FlowForm';

interface FlowFormAdvancedApproverProps {
  value?: ApproverConditionGroupAppModel;
  onChange: (value: ApproverConditionGroupAppModel) => void;
  onAddGroup?: () => void;
  onDeleteGroup?: () => void;
  onDuplicateGroup?: () => void;
}

interface AttributeFilterItem {
  key: string;
  filter?: ApproverConditionAppModel;
}

export default function FlowFormAdvancedApprovers({
  value,
  onChange,
  onAddGroup,
  onDeleteGroup,
  onDuplicateGroup,
}: FlowFormAdvancedApproverProps) {
  const [attributeFiltersOperator, setAttributeFiltersOperator] = useState<ApproverGroupOperatorAppModel>(
    value?.logical_operator || ApproverGroupOperatorAppModel.And,
  );

  const [approverFiltersItems, setApproverFiltersItems] = useState<AttributeFilterItem[]>(() => {
    if (value?.conditions && value?.conditions.length > 0) {
      return value.conditions.map((filter) => ({
        key: `key-${nanoid()}`,
        filter,
      }));
    }

    return [
      {
        key: `key-${nanoid()}`,
        filter: undefined,
      },
    ];
  });

  const handleOnChange = (operator: ApproverGroupOperatorAppModel, items: AttributeFilterItem[]) => {
    onChange({
      logical_operator: operator,
      conditions: items.map((item) => item.filter).filter((f) => f) as ApproverConditionAppModel[],
    });
  };

  const handleApproverFilterChange = (key: string, filter?: ApproverConditionAppModel) => {
    const index = approverFiltersItems.findIndex((f) => f.key === key);
    if (index === -1) return approverFiltersItems;

    const newItems = [...approverFiltersItems];
    newItems[index] = {
      key,
      filter,
    };

    setApproverFiltersItems(newItems);
    handleOnChange(attributeFiltersOperator, newItems);
  };

  const handleAddAttributeFilter = () => {
    setApproverFiltersItems((prev) => [
      ...prev,
      {
        key: `key-${nanoid()}`,
        filter: undefined,
      },
    ]);
  };

  const handleOnDeleteApproverFilter = (key: string) => {
    const newFilters = approverFiltersItems.filter((f) => f.key !== key);
    setApproverFiltersItems(newFilters);
    handleOnChange(attributeFiltersOperator, newFilters);
  };

  const handleOnDuplicateApproverFilter = (key: string) => {
    const index = approverFiltersItems.findIndex((f) => f.key === key);
    if (index === -1) return;
    const item = approverFiltersItems[index];
    const copyItems = [...approverFiltersItems];
    const newItems = [
      ...copyItems.slice(0, index + 1),
      {
        key: `key-${nanoid()}`,
        filter: item.filter,
      },
      ...copyItems.slice(index + 1),
    ];
    setApproverFiltersItems(newItems);
    handleOnChange(attributeFiltersOperator, newItems);
  };

  const handleOperatorChange = (operator: ApproverGroupOperatorAppModel) => {
    setAttributeFiltersOperator(operator);
    handleOnChange(operator, approverFiltersItems);
  };

  return (
    <FlowFormSection
      operator={
        <TabsSwitch
          value={attributeFiltersOperator}
          onChange={(_, newTab) => handleOperatorChange(newTab)}
          size="smallest"
          data-testid="tab-switch-and-or"
        >
          <Tab value={ApproverGroupOperatorAppModel.And} label="AND" />
          <Tab value={ApproverGroupOperatorAppModel.Or} label="OR" />
        </TabsSwitch>
      }
      actions={<LineActions onAdd={onAddGroup} onDelete={onDeleteGroup} onDuplicate={onDuplicateGroup} />}
    >
      {approverFiltersItems.map((item) => (
        <ApproverConditionSelector
          key={item.key}
          value={item.filter}
          onChange={(v) => handleApproverFilterChange(item.key, v)}
          onDuplicate={() => handleOnDuplicateApproverFilter(item.key)}
          onDelete={approverFiltersItems.length > 1 ? () => handleOnDeleteApproverFilter(item.key) : undefined}
        />
      ))}
      <Button onClick={handleAddAttributeFilter} variant="outlined-narrow" data-testid="add-attribute-button">
        <MaterialIcon symbol="add" />
      </Button>
    </FlowFormSection>
  );
}
