import { PropsWithChildren } from 'react';

import { Popover, UsePopoverProps } from '@components/Popover';
import { Paper } from '@mui/material';

export type ListFilterProps = PropsWithChildren<{
  trigger: React.ReactNode;
  testId?: string;
  width?: number;
  popoverProps?: UsePopoverProps;
}>;

export default function ListFilter({ trigger, testId, width = 200, popoverProps, children }: ListFilterProps) {
  return (
    <Popover testId={testId || 'select-dropdown'} trigger={trigger} externalState={popoverProps}>
      <Paper sx={{ width }}>{children}</Paper>
    </Popover>
  );
}
