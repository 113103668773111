import { toHumanReadableDuration } from '@utils';

import { HumanReadableText, HumanReadableTextProps } from '../common/HumanReadableText';

interface HumanReadableDurationProps extends Omit<HumanReadableTextProps, 'value' | 'values'> {
  value?: number;
}

export function HumanReadableDuration(props: HumanReadableDurationProps) {
  const { value, ...rest } = props;
  const duration = value && value > -1 ? toHumanReadableDuration(value) : undefined;
  return <HumanReadableText value={duration || 'indefinite'} {...rest} />;
}
