import { Skeleton, Stack } from '@mui/material';

import { PrimarySecondaryProps } from './PrimarySecondary';

interface PrimarySecondaryLoadingProps extends Omit<PrimarySecondaryProps, 'primary' | 'secondary' | 'icon'> {
  secondary?: boolean;
  icon?: boolean;
  iconSpacing?: number;
  iconSize?: number;
  variant?: 'default' | 'big';
}

export default function PrimarySecondaryLoading({
  secondary,
  variant = 'default',
  icon,
  iconSpacing = 1,
  iconSize = 24,
}: PrimarySecondaryLoadingProps) {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={iconSpacing}>
      {icon && <Skeleton variant="circular" width={iconSize} height={iconSize} data-testid="icon-skeleton" />}
      <Stack direction="column" spacing={0.25}>
        <Skeleton variant="text" width={120} height={variant === 'big' ? 24 : 16} data-testid="primary-skeleton" />
        {secondary && (
          <Skeleton variant="text" width={80} height={variant === 'big' ? 20 : 14} data-testid="secondary-skeleton" />
        )}
      </Stack>
    </Stack>
  );
}
