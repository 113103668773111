import {
  AccessFlowAreaModel,
  AttributeFilterAppModel,
  GranteeFilterGroupAppModel,
  GranteeFilterGroupOperatorAppModel,
} from '@api';
import { nanoid } from 'nanoid';

import FlowFormSection from '@AccessFlows/components/FlowFormSection';

import TabsSwitch from '@common/ui/TabsSwitch';
import { MaterialIcon } from '@components';
import { Button, Tab } from '@mui/material';
import { useState } from 'react';
import AdvancedAttribute from './AdvancedAttribute';

interface FlowFormAdvancedGranteesProps {
  value?: GranteeFilterGroupAppModel;
  onChange: (value: GranteeFilterGroupAppModel) => void;
}

interface AttributeFilterItem {
  key: string;
  filter?: AttributeFilterAppModel;
}

export default function FlowFormAdvancedGrantees({ value, onChange }: FlowFormAdvancedGranteesProps) {
  const [attributeFiltersOperator, setAttributeFiltersOperator] = useState<GranteeFilterGroupOperatorAppModel>(
    value?.logical_operator || GranteeFilterGroupOperatorAppModel.And,
  );

  const [attributeFiltersItems, setAttributeFiltersItems] = useState<AttributeFilterItem[]>(() => {
    if (value?.attribute_filters && value?.attribute_filters.length > 0) {
      return value.attribute_filters.map((filter) => ({
        key: `key-${nanoid()}`,
        filter,
      }));
    }

    return [
      {
        key: `key-${nanoid()}`,
        filter: undefined,
      },
    ];
  });

  const handleOnChange = (operator: GranteeFilterGroupOperatorAppModel, items: AttributeFilterItem[]) => {
    onChange({
      logical_operator: operator,
      attribute_filters: items.map((item) => item.filter).filter((f) => f) as AttributeFilterAppModel[],
    });
  };

  const handleAttributeFilterChange = (key: string, filter?: AttributeFilterAppModel) => {
    const index = attributeFiltersItems.findIndex((f) => f.key === key);
    if (index === -1) return attributeFiltersItems;

    const newItems = [...attributeFiltersItems];
    newItems[index] = {
      key,
      filter,
    };

    setAttributeFiltersItems(newItems);
    handleOnChange(attributeFiltersOperator, newItems);
  };

  const handleAddAttributeFilter = () => {
    setAttributeFiltersItems((prev) => [
      ...prev,
      {
        key: `key-${nanoid()}`,
        filter: undefined,
      },
    ]);
  };

  const handleOnDeleteAttributeFilter = (key: string) => {
    const newFilters = attributeFiltersItems.filter((f) => f.key !== key);
    setAttributeFiltersItems(newFilters);
    handleOnChange(attributeFiltersOperator, newFilters);
  };

  const handleOnDuplicateAttributeFilter = (key: string) => {
    const index = attributeFiltersItems.findIndex((f) => f.key === key);
    if (index === -1) return;
    const item = attributeFiltersItems[index];
    const copyItems = [...attributeFiltersItems];
    const newItems = [
      ...copyItems.slice(0, index + 1),
      {
        key: `key-${nanoid()}`,
        filter: item.filter,
      },
      ...copyItems.slice(index + 1),
    ];
    setAttributeFiltersItems(newItems);
    handleOnChange(attributeFiltersOperator, newItems);
  };

  const handleOperatorChange = (operator: GranteeFilterGroupOperatorAppModel) => {
    setAttributeFiltersOperator(operator);
    handleOnChange(operator, attributeFiltersItems);
  };

  return (
    <FlowFormSection
      operator={
        <TabsSwitch
          value={attributeFiltersOperator}
          onChange={(_, newTab) => handleOperatorChange(newTab)}
          size="smallest"
          data-testid="tab-switch-and-or"
        >
          <Tab value={GranteeFilterGroupOperatorAppModel.And} label="AND" />
          <Tab value={GranteeFilterGroupOperatorAppModel.Or} label="OR" />
        </TabsSwitch>
      }
    >
      {attributeFiltersItems.map((item) => (
        <AdvancedAttribute
          key={item.key}
          filter={item.filter}
          applicablePlaces={[AccessFlowAreaModel.Grantee]}
          onChange={(newFilter) => handleAttributeFilterChange(item.key, newFilter)}
          onDelete={attributeFiltersItems.length > 1 ? () => handleOnDeleteAttributeFilter(item.key) : undefined}
          onDuplicate={() => handleOnDuplicateAttributeFilter(item.key)}
        />
      ))}
      <Button onClick={handleAddAttributeFilter} variant="outlined-narrow" data-testid="add-attribute-button">
        <MaterialIcon symbol="add" />
      </Button>
    </FlowFormSection>
  );
}
