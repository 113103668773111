import { useEffect, useMemo, useState } from 'react';
import { Editor, EditorProps } from '@monaco-editor/react';
import { Box, useTheme } from '@mui/material';

import aponoDarkTheme from './apono-dark';
import useSafeMonaco from './use-safe-monaco';

export interface CodeEditorProps {
  value: string;
  onChange?: EditorProps['onChange'];
  height?: number;
  defaultLanguage?: EditorProps['defaultLanguage'];
  options?: EditorProps['options'];
  testId?: string;
}

export default function CodeEditor({
  value,
  onChange,
  height = 300,
  defaultLanguage = 'text',
  options,
  testId,
}: CodeEditorProps) {
  const theme = useTheme();
  const monaco = useSafeMonaco();

  const [themeDefined, setThemeDefined] = useState(false);

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('apono-dark-theme', aponoDarkTheme);
      setThemeDefined(true);
    }
  }, [monaco]);

  const editorTheme = useMemo(() => {
    if (theme.palette.mode === 'light') return 'light';
    return themeDefined ? 'apono-dark-theme' : 'vs-dark';
  }, [theme.palette.mode, themeDefined]);

  return (
    <Box data-testid={testId ?? 'code-editor'}>
      <Editor
        height={`${height}px`}
        defaultLanguage={defaultLanguage}
        theme={editorTheme}
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
}
