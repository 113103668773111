import { DateRangePicker, MaterialIcon } from '@components';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import {
  DateTime,
  getBooleanQP,
  getDateQP,
  getStringQP,
  joinPeriod,
  lastFromRelativePeriod,
  parseRelativePeriod,
  QueryParams,
  RelativePeriodParams,
  removeFromQP,
} from '@utils';

function useDateFilter() {
  const [qp, setQP] = useSearchParams();

  const onRelativeChange = (period: RelativePeriodParams) => {
    const newQP = removeFromQP(qp, [QueryParams.startDate, QueryParams.endDate, QueryParams.utc]);
    newQP.set(QueryParams.period, joinPeriod(period));
    setQP(newQP);
  };

  const onAbsoluteChange = (startDate: DateTime, endDate: DateTime, utc?: boolean) => {
    const newQP = removeFromQP(qp, [QueryParams.period]);

    newQP.set(QueryParams.startDate, startDate.isoString);
    newQP.set(QueryParams.endDate, endDate.isoString);

    if (utc) {
      newQP.set(QueryParams.utc, 'true');
    } else {
      newQP.delete(QueryParams.utc);
    }

    setQP(newQP);
  };
  const onClear = () => {
    const newQP = removeFromQP(qp, [QueryParams.startDate, QueryParams.endDate, QueryParams.utc, QueryParams.period]);
    setQP(newQP);
  };

  const isClearable =
    qp.has(QueryParams.period) ||
    qp.has(QueryParams.startDate) ||
    qp.has(QueryParams.endDate || qp.has(QueryParams.utc));
  const periodQp = getStringQP(qp, QueryParams.period);

  return {
    period: periodQp ? parseRelativePeriod(periodQp) : undefined,
    startDate: getDateQP(qp, QueryParams.startDate),
    endDate: getDateQP(qp, QueryParams.endDate),
    utc: getBooleanQP(qp, QueryParams.utc),
    onRelativeChange,
    onAbsoluteChange,
    onClear,
    isClearable,
  };
}

export function DateFilter() {
  const { period, startDate, endDate, utc, onRelativeChange, onAbsoluteChange, onClear, isClearable } = useDateFilter();

  return (
    <DateRangePicker
      trigger={<Trigger period={period} startDate={startDate} endDate={endDate} />}
      period={period}
      startDate={startDate}
      endDate={endDate}
      utc={utc}
      onRelativeChange={onRelativeChange}
      onAbsoluteChange={onAbsoluteChange}
      onClear={onClear}
      isClearable={isClearable}
    />
  );
}

function Trigger({
  period,
  startDate,
  endDate,
}: {
  period?: RelativePeriodParams;
  startDate?: DateTime;
  endDate?: DateTime;
}) {
  let variant: 'default-filters' | 'contained-filters' = 'default-filters';
  let text = 'All time';

  if (period) {
    variant = 'contained-filters';
    text = lastFromRelativePeriod(period);
  }

  if (startDate && endDate) {
    variant = 'contained-filters';
    text = `${startDate.readable} - ${endDate.readable}`;
  }

  return (
    <Button component="div" variant={variant} size="small" startIcon={<MaterialIcon symbol="alarm" />}>
      {text}
    </Button>
  );
}
