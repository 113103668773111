import { AccessFlowAreaModel, GranteeModel, GranteeTypeModel, useListAvailableAttributesV2 } from '@api';
import { yup } from '@libs';

import FlowFormSelectConditions from '../SelectConditions';
import { FlowFormLine, FlowFormText } from '../common';
import { MaterialIcon } from '@components';
import { useCallback, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import { fromContextKey, toContextKey } from '../common/use-attributes-options';
import FlowFormSection from '@AccessFlows/components/FlowFormSection';

function useGranteeType() {
  const { contextsAttributes, isContextsAttributesFetched } = useListAvailableAttributesV2();

  const getGranteeType = useCallback(
    (grantee: GranteeModel) => {
      let granteeType: string = grantee.type;

      if (granteeType === GranteeTypeModel.ContextAttribute) {
        const attr = contextsAttributes?.find((a) => a.id === grantee.id);
        if (attr) {
          granteeType = toContextKey(attr);
        }
      }

      return granteeType;
    },
    [contextsAttributes],
  );

  return {
    contextsAttributes,
    isContextsAttributesFetched,
    getGranteeType,
  };
}

const NO_TYPE = 'NO_TYPE';

interface FlowFormConditionsProps {
  value?: GranteeModel[];
  onChange: (value?: GranteeModel[]) => void;
  error?: yup.ValidationError;
  withBorder?: boolean;
}

export default function FlowFormConditions({ value, onChange, error, withBorder = false }: FlowFormConditionsProps) {
  const { contextsAttributes, isContextsAttributesFetched, getGranteeType } = useGranteeType();

  const availableTypes = useMemo(() => {
    const types = new Set<string>([GranteeTypeModel.User, GranteeTypeModel.Group]);

    if (contextsAttributes) {
      for (const attr of contextsAttributes) {
        if (attr.applicable_places.includes(AccessFlowAreaModel.Grantee)) {
          types.add(toContextKey(attr));
        }
      }
    }

    return Array.from(types);
  }, [contextsAttributes]);

  const granteesByType: GranteeByType[] = useMemo(() => {
    const byType = new Map<string, GranteeModel[]>();

    if (!value || value.length === 0) {
      return [
        {
          key: NO_TYPE,
          grantees: [],
        },
      ];
    }

    for (const grantee of value) {
      const granteeType = getGranteeType(grantee);
      const grantees = byType.get(granteeType) || [];
      grantees.push(grantee);
      byType.set(granteeType, grantees);
    }

    return Array.from(byType.entries()).map(([type, grantees]) => ({ key: type, type, grantees }));
  }, [getGranteeType, value]);

  if (!isContextsAttributesFetched)
    return (
      <FlowFormLine padded>
        <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: 200 }} />
      </FlowFormLine>
    );

  return <FlowFormConditionsList {...{ availableTypes, granteesByType, onChange, error, withBorder }} />;
}

type GranteeByType = { key: string; type?: string; grantees: GranteeModel[] };

type FlowFormConditionsListProps = {
  availableTypes: string[];
  granteesByType: GranteeByType[];
} & Omit<FlowFormConditionsProps, 'value'>;

function FlowFormConditionsList({
  availableTypes,
  granteesByType,
  onChange,
  error,
  withBorder,
}: FlowFormConditionsListProps) {
  const { getGranteeType } = useGranteeType();
  const [conditions, setConditions] = useState(granteesByType);
  const [unselectedIndex, setUnselectedIndex] = useState(0);

  const _updateConditions = useCallback(
    (newConditions: GranteeByType[]) => {
      setConditions(newConditions);

      const newGrantees = newConditions.flatMap((c) => c.grantees);
      onChange(newGrantees.length > 0 ? newGrantees : undefined);
    },
    [onChange],
  );

  const handleOnAdd = useCallback(() => {
    setConditions((prev) => [
      ...prev,
      {
        key: `${NO_TYPE}_${unselectedIndex}`,
        grantees: [],
      },
    ]);
    setUnselectedIndex((prev) => prev + 1);
  }, [unselectedIndex]);

  const handleOnChange = useCallback(
    (key: string, newVal: GranteeModel[]) => {
      const typeIndex = conditions.findIndex((c) => c.key === key);
      const newConditions = [...conditions];
      newConditions[typeIndex].grantees = newVal;

      if (newVal.length > 0) {
        newConditions[typeIndex].type = getGranteeType(newVal[0]);
      } else {
        newConditions[typeIndex].type = undefined;
      }

      _updateConditions(newConditions);
    },
    [_updateConditions, conditions, getGranteeType],
  );

  const handleOnDelete = useCallback(
    (key: string) => {
      _updateConditions(conditions.filter((c) => c.key !== key));
    },
    [_updateConditions, conditions],
  );

  const typesInUse = useMemo(() => new Set(granteesByType.map((c) => c.key)), [granteesByType]);

  return (
    <FlowFormSection returnDefault={!withBorder}>
      {conditions.map(({ key, type, grantees }) => {
        const isFirst = conditions[0].key === key;
        const hasOnDelete = !isFirst && conditions.length > 1;
        const hasOnAdd = conditions[conditions.length - 1].key === key && conditions.length < availableTypes.length;
        const disabledTypes = !type ? Array.from(typesInUse) : availableTypes.filter((t) => t !== type);

        return (
          <ConditionLine
            key={key}
            withBorder={withBorder}
            condition={{ key, type, grantees }}
            isFirst={isFirst}
            disabledTypes={disabledTypes}
            handleOnAdd={hasOnAdd ? handleOnAdd : undefined}
            handleOnChange={(newVal) => handleOnChange(key, newVal)}
            handleOnDelete={hasOnDelete ? () => handleOnDelete(key) : undefined}
            error={error}
          />
        );
      })}
    </FlowFormSection>
  );
}

interface ConditionLineProps {
  condition: GranteeByType;
  isFirst: boolean;
  disabledTypes: string[];
  handleOnChange: (newVal: GranteeModel[]) => void;
  handleOnAdd?: () => void;
  handleOnDelete?: () => void;
  error?: yup.ValidationError;
  withBorder?: boolean;
}

function ConditionLine({
  condition,
  isFirst,
  handleOnAdd,
  handleOnDelete,
  disabledTypes,
  handleOnChange,
  error,
  withBorder,
}: ConditionLineProps) {
  const { type, grantees } = condition;

  const prefix = useMemo(() => {
    let str = `${isFirst ? 'While' : 'Or'}`;
    if (!type) return str;

    const moreThanOne = grantees.length > 1;

    str += ` user${moreThanOne ? 's' : ''}`;

    if (type === GranteeTypeModel.User) {
      str += ` ${moreThanOne ? 'are' : 'is'}`;
    } else {
      str += ` in`;
    }

    return str;
  }, [isFirst, type, grantees.length]);

  const suffix = useMemo(() => {
    if (!type || type === GranteeTypeModel.User) return null;

    const moreThanOne = grantees.length > 1;

    let str = null;
    if (type === GranteeTypeModel.Group) {
      str = 'Group';
    } else if (type === GranteeTypeModel.ExternalEmail) {
      str = 'Email';
    } else {
      const { category } = fromContextKey(type);
      str = category;
    }

    return str ? str + (moreThanOne ? 's' : '') : null;
  }, [type, grantees.length]);

  const initGranteeType = useMemo(() => {
    if (!type) return undefined;

    switch (type) {
      case GranteeTypeModel.User:
        return GranteeTypeModel.User;
      case GranteeTypeModel.Group:
        return GranteeTypeModel.Group;
      case GranteeTypeModel.ExternalEmail:
        return GranteeTypeModel.ExternalEmail;
      default:
        return `${GranteeTypeModel.ContextAttribute}/${type}`;
    }
  }, [type]);

  if (withBorder) {
    return (
      <FlowFormLine withBorder onAdd={handleOnAdd} onDelete={handleOnDelete}>
        {!isFirst && <FlowFormText>{prefix}</FlowFormText>}
        <FlowFormSelectConditions
          error={error}
          value={grantees}
          onChange={handleOnChange}
          initGranteeType={initGranteeType}
          disabledGranteeTypes={disabledTypes}
        />
      </FlowFormLine>
    );
  }

  return (
    <FlowFormLine
      padded
      icon={isFirst ? <MaterialIcon symbol="east" color="success" /> : undefined}
      onAdd={handleOnAdd}
      onDelete={handleOnDelete}
    >
      <FlowFormText>{prefix}</FlowFormText>
      <FlowFormSelectConditions
        initGranteeType={initGranteeType}
        disabledGranteeTypes={disabledTypes}
        value={grantees}
        onChange={handleOnChange}
        error={error}
      />
      {suffix && <FlowFormText>{suffix}</FlowFormText>}
    </FlowFormLine>
  );
}
