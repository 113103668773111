import { InteractiveDropdownChips, InteractiveDropdownHeader, InteractiveDropdownView } from '@components';

export interface DropdownEmailViewProps {
  current: boolean;
  onBackClick?: () => void;
  value: string[];
  onChange: (value: string[]) => void;
}

export function DropdownEmailView({ current, onBackClick, value, onChange }: DropdownEmailViewProps) {
  return (
    <InteractiveDropdownView
      current={current}
      header={<InteractiveDropdownHeader title="Email" onBackClick={onBackClick} />}
    >
      <InteractiveDropdownChips inputType="email" emptyText="No emails added" value={value} onChange={onChange} />
    </InteractiveDropdownView>
  );
}
