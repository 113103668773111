import { graphql } from '@api/gql';

export const USER_FRAGMENT = graphql(/* GraphQL */ `
  fragment userFields on User {
    __typename
    id
    email
    firstName
    lastName
  }
`);

export const ACCESS_REQUEST_FRAGMENT = graphql(/* GraphQL */ `
  fragment accessRequestFields on AccessRequest {
    __typename
    id
    grantee {
      ...userFields
    }
    durationInSeconds
    date
  }
`);

export const PAGE_INFO_FRAGMENT = graphql(/* GraphQL */ `
  fragment pageInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
`);

export const INTEGRATION_FRAGMENT = graphql(/* GraphQL */ `
  fragment integrationFields on Integration {
    id
    name
    type
    typeDisplayName
    icons {
      png
      svg
    }
  }
`);

export const RESOURCE_TYPE_FRAGMENT = graphql(/* GraphQL */ `
  fragment resourceTypeFields on ResourceType {
    type
    typeDisplayName
    icons {
      png
      svg
    }
  }
`);

export const RESOURCE_FRAGMENT = graphql(/* GraphQL */ `
  fragment resourceFields on Resource {
    __typename
    id
    name
    path {
      key
      keyDisplayName
      value
    }
    type {
      ...resourceTypeFields
    }
    status {
      status
      message
    }
    tags {
      key
      value
    }
    owners {
      attributeValue {
        id
        type
        typDisplayName
        name
      }
      integration {
        ...integrationFields
      }
    }
  }
`);

export const RIGHT_SIZING_INSIGHT_FRAGMENT = graphql(/* GraphQL */ `
  fragment rightSizingInsightFields on RightSizingReportInsight {
    id
    insightType
    integration {
      ...integrationFields
    }
    resourceType {
      ...resourceTypeFields
    }
    findingsCount
    recommendation
  }
`);

export const AccessFlowUsageMetricValue_Fragment = graphql(/* GraphQL */ `
  fragment AccessFlowUsageMetricValue_Fragment on AccessFlowUsageMetricValue {
    defined
    notUsed
  }
`);

export const ACCESS_FLOW_METRICS_FRAGMENT = graphql(/* GraphQL */ `
  fragment accessFlowMetricsFields on AccessFlowUsageMetric {
    metricType
    currentValue {
      ...AccessFlowUsageMetricValue_Fragment
    }
    previousValue {
      ...AccessFlowUsageMetricValue_Fragment
    }
  }
`);

export const ACCESS_FLOW_FRAGMENT = graphql(/* GraphQL */ `
  fragment accessFlowFields on AccessFlow {
    id
    name
  }
`);

export const availablePeriods_Fragment = graphql(/* GraphQL */ `
  fragment availablePeriodsFields on RightSizingReportAvailablePeriod {
    key
    value
  }
`);

export const periodField_Fragment = graphql(/* GraphQL */ `
  fragment periodFields on RightSizingReportPeriod {
    from
    to
  }
`);

export const RIGHT_SIZING_REPORT_FRAGMENTS = graphql(/* GraphQL */ `
  fragment rightSizingReportFields on RightSizingReport {
    id
    accessFlow {
      ...accessFlowFields
    }

    period {
      ...periodFields
    }
    metrics {
      ...accessFlowMetricsFields
    }
    insights {
      ...rightSizingInsightFields
    }
    availablePeriods {
      ...availablePeriodsFields
    }
  }
`);

export const RightSizingReports_Fragment = graphql(/* GraphQL */ `
  fragment rightSizingReportsFields on RightSizingReports {
    totalCount
    pageInfo {
      ...pageInfoFields
    }
    edges {
      cursor
      node {
        ...rightSizingReportFields
      }
    }
  }
`);

export const RightSizingInsightFindingsEdge_Fragment = graphql(/* GraphQL */ `
  fragment rightSizingInsightFindingsEdgeFields on RightSizingInsightFindingsEdge {
    cursor
    node {
      ... on User {
        ...userFields
      }
      ... on Resource {
        ...resourceFields
      }
      ... on Permission {
        __typename
        id
        name
      }
      ... on AccessRequest {
        ...accessRequestFields
      }
    }
  }
`);

export const rightSizingReportQueryDocument = graphql(/* GraphQL */ `
  query RightSizingReport($id: ID!) {
    rightSizingReport(id: $id) {
      ...rightSizingReportFields
    }
  }
`);

export const rightSizingReportsQueryDocument = graphql(/* GraphQL */ `
  query RightSizingReports($take: Int, $period: String) {
    rightSizingReports(take: $take, period: $period) {
      ...rightSizingReportsFields
    }
  }
`);

export const rightSizingInsightsQueryDocument = graphql(/* GraphQL */ `
  query RightSizingInsightFindings($id: ID!, $take: Int) {
    rightSizingInsightFindings(id: $id, take: $take) {
      totalCount
      pageInfo {
        ...pageInfoFields
      }
      edges {
        ...rightSizingInsightFindingsEdgeFields
      }
    }
  }
`);
