import { Stack } from '@mui/material';

import ActivityTable from './ActivityTable/ActivityTable';
import ReportButton from './ReportButton';
import ActivityDrawer from './ActivityDrawer/ActivityDrawer';
import RevokeAll from './RevokeAll/RevokeAll';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import ActivityFiltersNew from '@Activity/common/organisms/ActivityFiltersNew';

export function ActivityPage() {
  const { isEnabled: isRevokeAllEnabled } = useFlagFixed(Flag.PANIC_BUTTON_REVOKE_ALL);
  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <ActivityFiltersNew />
        </Stack>
        <Stack direction="row" alignItems="stretch" spacing={1}>
          {isRevokeAllEnabled && <RevokeAll />}
          <ReportButton />
        </Stack>
      </Stack>
      <ActivityTable />
      <ActivityDrawer />
    </Stack>
  );
}
