import { Button, Typography } from '@mui/material';

interface ConnectorUpdateProps {
  isLatestVersion: boolean;
  providerLink: string;
}

export default function ConnectorUpdate({ isLatestVersion, providerLink }: ConnectorUpdateProps) {
  return (
    <Button size="small" variant="outlined" disabled={isLatestVersion} onClick={() => window.open(providerLink)}>
      <Typography variant="tooltip" className="text">
        Update
      </Typography>
    </Button>
  );
}
