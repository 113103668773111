import { ReactNode, useState } from 'react';

import { Popover } from '@components/Popover';
import { Box, Paper, Stack, styled, Tab, Tabs } from '@mui/material';
import { DateRangePickerAbsolute } from './DateRangePickerAbsolute';
import { DateRangePickerRelative } from './DateRangePickerRelative';
import { DateTime, RelativePeriodParams } from '@utils';
import { ClearTextButton } from '@components/ClearTextButton';

const PickerContainer = styled(Box)(({ theme }) => ({
  width: '290px',
  padding: theme.spacing(2),
}));

export interface DateRangePickerProps {
  testId?: string;
  trigger: ReactNode;
  period?: RelativePeriodParams;
  startDate?: DateTime;
  endDate?: DateTime;
  utc?: boolean;
  onRelativeChange: (period: RelativePeriodParams) => void;
  onAbsoluteChange: (startDate: DateTime, endDate: DateTime, utc?: boolean) => void;
  isClearable?: boolean;
  onClear: () => void;
}

export interface PeriodOption {
  label: string;
  value: string;
  selected?: boolean;
}

enum DatePickerTab {
  Relative = 'Relative',
  Absolute = 'Absolute',
}

export function DateRangePicker({
  testId,
  trigger,
  period,
  startDate,
  endDate,
  utc,
  onRelativeChange,
  onAbsoluteChange,
  isClearable,
  onClear,
}: DateRangePickerProps) {
  const initTab = () => {
    if (startDate && endDate) return DatePickerTab.Absolute;
    return DatePickerTab.Relative;
  };

  const [tab, setTab] = useState(initTab());

  return (
    <Popover testId={testId || 'date-picker'} trigger={trigger}>
      <Paper>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)} variant="fullWidth">
          <Tab value={DatePickerTab.Relative} label={DatePickerTab.Relative} />
          <Tab value={DatePickerTab.Absolute} label={DatePickerTab.Absolute} />
        </Tabs>
        <PickerContainer>
          {tab === DatePickerTab.Relative && <DateRangePickerRelative period={period} onChange={onRelativeChange} />}
          {tab === DatePickerTab.Absolute && (
            <DateRangePickerAbsolute startDate={startDate} endDate={endDate} utc={utc} onChange={onAbsoluteChange} />
          )}
          {isClearable && (
            <Stack alignItems="center">
              <ClearTextButton onClick={onClear} />
            </Stack>
          )}
        </PickerContainer>
      </Paper>
    </Popover>
  );
}
