import { Integration, IntegrationConfigAppModelV2, IntegrationConfigParamAppModel } from '@api';
import { LabeledAutocompleteField, LabeledTextField } from '@components';
import { Stack } from '@mui/material';

import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { IntegrationConfigFormPayload } from '../Provider';
import { SecretStoreForm } from './SecretStoreForm/SecretStoreForm';
import { CustomAccessDetailsField } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/CustomAccessDetails/CustomAccessDetailsField';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

export interface ConfigFormProps {
  config: IntegrationConfigAppModelV2;
  integration?: Integration;
  disabled?: boolean;
}

export function ConfigForm({ config, disabled }: ConfigFormProps) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();
  const { isEnabled: isCustomAccessDetailsEnabled } = useFlagFixed(Flag.ADMIN_CUSTOM_ACCESS_DETAILS);

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={5}>
      <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: 'Name is required',
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <LabeledTextField
              label="Integration Name"
              placeholder="Eg. My Integration"
              error={invalid}
              helperText={error?.message}
              inputProps={field}
              autoFocus
            />
          )}
        />
        {config.params.map((p) => (
          <MetadataInput key={p.id} param={p} />
        ))}
      </Stack>
      {isCustomAccessDetailsEnabled && <CustomAccessDetailsField integrationConfig={config} disabled={disabled} />}
      {config.supported_secret_types.length > 0 && (
        <SecretStoreForm
          requires_secret={config.requires_secret}
          supported_secret_types={config.supported_secret_types}
          secret_params={config.secret_params}
        />
      )}
    </Stack>
  );
}

interface MetadataInputProps {
  param: IntegrationConfigParamAppModel;
}

function MetadataInput({ param }: MetadataInputProps) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <Controller
      control={control}
      name={`metadata.${param.id}`}
      rules={{
        required: {
          value: !param.optional,
          message: `${param.label} is required`,
        },
      }}
      render={(props) => <MetadataInputField param={param} {...props} />}
    />
  );
}

interface MetadataInputFieldProps {
  param: IntegrationConfigParamAppModel;
  field: ControllerRenderProps<IntegrationConfigFormPayload, `metadata.${string}`>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<IntegrationConfigFormPayload>;
  disabled?: boolean;
}

export function MetadataInputField({
  param,
  field: { onChange, value, ...restField },
  fieldState: { invalid, error },
  disabled,
}: MetadataInputFieldProps) {
  const { values: options, type, default: defaultValue, ...inputProps } = param;

  if (param.type === 'Select' && (typeof value === 'string' || typeof value === 'undefined')) {
    return (
      <LabeledAutocompleteField
        {...inputProps}
        options={options}
        error={invalid}
        helperText={error?.message}
        value={value}
        onChange={(val) => onChange(val)}
        inputProps={restField}
        data-testid={`${inputProps.label}-metadata-input-field`}
        disabled={disabled}
      />
    );
  }

  if (param.type === 'Number') {
    return (
      <LabeledTextField
        {...inputProps}
        type="number"
        value={value}
        error={invalid}
        inputProps={restField}
        optional={param.optional}
        helperText={error?.message}
        onChange={(val) => onChange(val)}
        data-testid={`${inputProps.label}-metadata-input-field`}
        disabled={disabled}
      />
    );
  }

  return (
    <LabeledTextField
      {...inputProps}
      error={invalid}
      helperText={error?.message}
      value={value}
      onChange={(val) => onChange(val)}
      inputProps={restField}
      data-testid={`${inputProps.label}-metadata-input-field`}
      disabled={disabled}
    />
  );
}
