import { useState } from 'react';
import { DurationValue, fromDurationValue, toDurationValue } from '@utils';
import { InteractiveDropdown, InteractiveDropdownDurationSelect, Popover } from '@components';
import { yup } from '@libs';
import { HumanReadableDuration } from './HumanReadable';
import { useErrorText } from './common/use-error-text.hook';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export interface FlowFormSelectDurationProps {
  value?: number;
  onChange: (value: number) => void;
  error?: yup.ValidationError;
}

function useFlowFormSelectDuration({ value, onChange }: FlowFormSelectDurationProps) {
  const { track } = useAnalyticsContext();
  const [duration, setDuration] = useState<DurationValue | undefined>(
    value && value > -1 ? toDurationValue(value) : undefined,
  );

  const handleDurationChange = (durationValue?: DurationValue) => {
    setDuration(durationValue);
    onChange(fromDurationValue(durationValue));
    track(CustomAnalyticsEvents.SELECT_DURATION, { durationValue });
  };

  return {
    duration,
    handleDurationChange,
  };
}

export function FlowFormSelectDuration({ value, onChange, error }: FlowFormSelectDurationProps) {
  const { duration, handleDurationChange } = useFlowFormSelectDuration({
    value,
    onChange,
  });
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();

  return (
    <Popover
      testId="flow-form-select-duration"
      trigger={<HumanReadableDuration value={value} errorText={error?.message} hideErrorText={errorTextHidden} />}
      onOpen={hideErrorText}
      onClose={showErrorText}
    >
      <InteractiveDropdown>
        <InteractiveDropdownDurationSelect value={duration} onChange={handleDurationChange} />
      </InteractiveDropdown>
    </Popover>
  );
}
