import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TAG_FILTER_LABEL, TAG_FILTER_PARAM } from '@common/organisms/CommonFilters/constants';
import { getFragmentData } from '@api/gql';

import GenericAsyncFilterMultiSelect from '@common/organisms/CommonFilters/GenericAsyncFilterMultiSelect';
import { ResourcesTag_Fragment } from '@Integrations/services/gql';
import { useTagsFilterOptions } from '@Integrations/services/use-resources-filter-options';

import { FiltersProps } from './filters-props.interface';

export default function TagsFilterAsync({ integrationId, search }: FiltersProps) {
  const [searchParams] = useSearchParams();
  const [enabled] = useState(() => searchParams.has(TAG_FILTER_PARAM));

  const { isFetched, data, refetch } = useTagsFilterOptions({
    args: {
      id: integrationId,
      search,
    },
    enabled,
  });

  const tagOptions = useMemo(() => {
    const tagsData = [...getFragmentData(ResourcesTag_Fragment, data?.integrationResourcesFilterOptions.tags || [])];

    return tagsData.map((tag) => ({
      label: `${tag.key}:${tag.value}`,
      value: JSON.stringify(tag),
    }));
  }, [data?.integrationResourcesFilterOptions.tags]);

  return (
    <GenericAsyncFilterMultiSelect
      options={tagOptions}
      filterLabel={TAG_FILTER_LABEL}
      filterParam={TAG_FILTER_PARAM}
      isOptionsFetched={isFetched}
      refetchOptions={refetch}
    />
  );
}
