import { Box, Button, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

const UpgradeWrapperContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  //display: "inline-block",
  '&.overly': {
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: `1px solid ${theme.palette.border.default}`,
      borderRadius: theme.spacing(1),
      content: '""',
      backgroundColor: 'white',
      opacity: 0.08,
      zIndex: 99,
      '&:hover': {
        border: `1px solid ${theme.palette.border.default}`,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },

  '& .icon': {
    position: 'absolute',
    zIndex: 100,

    '&.tiny': {
      width: theme.spacing(2),
      height: theme.spacing(2),
      right: theme.spacing(-1.7),
      top: theme.spacing(-1),
    },
    '&.small': {
      width: theme.spacing(2.6),
      height: theme.spacing(2.6),
      right: theme.spacing(-1.3),
      top: theme.spacing(-1.3),
    },
    '&.medium': {
      width: theme.spacing(5.8),
      height: theme.spacing(5.8),
      right: theme.spacing(-4.5),
      top: theme.spacing(-2.9),
    },
    '&.large': {
      width: theme.spacing(6.6),
      height: theme.spacing(6.6),
      right: theme.spacing(-1.8),
      top: theme.spacing(-3),
    },
  },
}));

const ProTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '420px',
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.background.popup,
  },
}));

export enum ProSizes {
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ProIconType {
  NoIcon = '',
  IconWithText = '/static/icons/upgrade-icon-with-text.svg',
  IconWithoutText = '/static/icons/pro-icon.svg',
}
export interface UpgradeWrapperProps {
  isPremium: boolean;
  size?: ProSizes;
  iconType?: ProIconType;
  overly?: boolean;
  withPopover?: boolean;
}

export function UpgradeWrapper({
  children,
  isPremium,
  size = ProSizes.Small,
  iconType = ProIconType.IconWithoutText,
  overly = false,
  withPopover = true,
}: PropsWithChildren<UpgradeWrapperProps>) {
  const { track } = useAnalyticsContext();

  const premiumClassName = `icon ${size}`;
  if (!isPremium) return <>{children}</>;
  if (!withPopover)
    return (
      <UpgradeWrapperContainer className={overly ? 'overly' : ''}>
        {iconType === ProIconType.NoIcon ? null : <img src={iconType} className={premiumClassName} />}
        {children}
      </UpgradeWrapperContainer>
    );

  return (
    <ProTooltip
      onOpen={() => track(CustomAnalyticsEvents.UPGRADE_TOOLTIP_OPENED)}
      title={
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Typography variant="subtitle1">Upgrade to paid plan!</Typography>
          <Typography typography="tooltip" component="div">
            Apono Premium unlocks frictionless access management for Security, IT and DevOps teams.
          </Typography>
          <Typography typography="tooltip" component="div">
            Enjoy more integrations and cloud resources, advanced admin settings, enhanced DevX and robust reporting and
            audit logs capabilities.
          </Typography>
          <div>
            <UpgradeButton />
          </div>
        </Stack>
      }
      arrow
    >
      <UpgradeWrapperContainer className={overly ? 'overly' : ''}>
        {iconType === ProIconType.NoIcon ? null : <img src={iconType} className={premiumClassName} />}
        {children}
      </UpgradeWrapperContainer>
    </ProTooltip>
  );
}

const ProButton = styled(Button)(({ theme }) => ({
  color: '#9473F7',
  border: '1px solid #9473F7',
  background: 'linear-gradient(to left, #3F0E6B,transparent)',
  backgroundColor: '#15135C',
  paddingRight: theme.spacing(14),
  paddingLeft: theme.spacing(14),
}));

export function UpgradeButton() {
  const { track } = useAnalyticsContext();
  const handleClick = () => {
    track(CustomAnalyticsEvents.UPGRADE_BUTTON_CLICKED);
    window.open('https://www.apono.io/get-in-touch/');
  };

  return <ProButton onClick={handleClick}>Upgrade</ProButton>;
}
