import { getFragmentData } from '@api/gql';
import { useAlert } from '../services/use-alert';
import { AppDrawer, AppDrawerHeader, EmptyState, Loader, MaterialIcon } from '@components';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { AlertDetails } from '../components/AlertDetails';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AlertFields_Fragment } from '@AccessAlerts/services/gql';
import { AlertAccessGroups } from '@AccessAlerts/components/AlertAccessGroups';

export const ALERT_ID_PARAM = 'alert_id';

export function AlertDrawer() {
  const [queryParams, setQueryParams] = useSearchParams();

  const alertId = useMemo(() => queryParams.get(ALERT_ID_PARAM), [queryParams]);

  const closeDrawer = () => {
    const newQueryParam = new URLSearchParams(queryParams);
    newQueryParam.delete(ALERT_ID_PARAM);
    setQueryParams(newQueryParam);
  };

  return (
    <AppDrawer
      open={!!alertId}
      onClose={closeDrawer}
      width={800}
      header={<AppDrawerHeader title="" onCloseClick={closeDrawer} />}
    >
      {alertId && <AlertDrawerInner alertId={alertId} />}
    </AppDrawer>
  );
}

function AlertDrawerInner({ alertId }: { alertId: string }) {
  const { data, isFetched: isAlertFetched, error } = useAlert({ id: alertId });

  const alertData = getFragmentData(AlertFields_Fragment, data?.alert);

  if (!isAlertFetched) {
    return <Loader />;
  }

  if (error || (isAlertFetched && !alertData)) {
    return <EmptyState title="No Data Found" imgSrc="/static/EmptyStateImages/not-found-illustration.svg" />;
  }

  return (
    <Stack direction="column" spacing={2} data-testid="alert-drawer">
      <Alert variant="outlinedInsights" icon={<MaterialIcon symbol="auto_awesome" size="small" />}>
        <Stack direction="column" spacing={1} maxWidth="500px">
          <AlertTitle>{`Recommendation: ${alertData?.recommendation}`}</AlertTitle>
          {alertData?.reason && <Typography variant="body2">{alertData.reason}</Typography>}
        </Stack>
      </Alert>
      {alertData && (
        <>
          <Typography variant="subtitle1" data-testid="alert-drawer-sub-title">
            Anomaly Details
          </Typography>
          <AlertDetails alertData={alertData} />
          <Typography variant="subtitle1">Resources that triggered the anomaly</Typography>
          <AlertAccessGroups alertData={alertData} />
        </>
      )}
    </Stack>
  );
}
