import { AccessGraphAttributeConditionOrNode } from '@api/gql/graphql';
import { RESOURCE_NODE_WIDTH, RESOURCE_NODES_GAP } from './common';
import { ExpandButton } from '@components';
import { Box, Stack, styled } from '@mui/material';
import { LabelWrap, NodeMetaContainer, ResourceNodeAvatar, ResourceNodeTypography } from './node';
import { Handle, NodeProps, Position } from 'reactflow';
import { AttributeConditionNodeTotal } from './AccessGraphAttributeConditionNodeTotal';
import { AVAILABLE_ACCESS_COLOR } from './node_and_edge_types';

const GroupedNodeWrapper = styled(Box)(({ theme }) => ({
  cursor: 'default',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
  padding: `12px`,
  width: RESOURCE_NODE_WIDTH,

  '& .collapse-btn': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.dropdown,
  },
}));

export type ConditionOrNodeProps = {
  node: AccessGraphAttributeConditionOrNode;
  childrenHeight: number;
  hasConnectedEdges: boolean;
};

export function AttributeConditionOrNodeExpanded({ data }: NodeProps<ConditionOrNodeProps>) {
  const { childrenHeight, node, hasConnectedEdges } = data;

  return (
    <GroupedNodeWrapper>
      {hasConnectedEdges && (
        <Handle
          type="source"
          position={Position.Right}
          id={node.id}
          style={{ backgroundColor: AVAILABLE_ACCESS_COLOR, borderColor: AVAILABLE_ACCESS_COLOR }}
        />
      )}
      <AttributeConditionOrNodeMeta node={node} />
      <Stack direction="column" alignItems="center" spacing={`${RESOURCE_NODES_GAP}px`}>
        {childrenHeight > 0 ? <Box sx={{ height: childrenHeight }}></Box> : null}
        <ExpandButton less />
      </Stack>
    </GroupedNodeWrapper>
  );
}

export function AttributeConditionOrNode({ data }: NodeProps<ConditionOrNodeProps>) {
  const { node } = data;

  return (
    <GroupedNodeWrapper className="outer">
      <Handle
        type="source"
        position={Position.Right}
        id={node.id}
        style={{ backgroundColor: AVAILABLE_ACCESS_COLOR, borderColor: AVAILABLE_ACCESS_COLOR }}
      />
      <AttributeConditionOrNodeMeta node={node} allowExpand />
    </GroupedNodeWrapper>
  );
}

function AttributeConditionOrNodeMeta({
  node,
  allowExpand,
}: {
  node: AccessGraphAttributeConditionOrNode;
  allowExpand?: boolean;
}) {
  // Build Label
  const label = buildOrNodeLabel(node);
  const total = node?.totalChildren || 0;
  const icon = getOrNodeIcon(node);
  const showExpand = total > 0 && allowExpand;

  return (
    <NodeMetaContainer direction="row" alignItems="center" spacing={2}>
      {icon && <ResourceNodeAvatar src={icon} variant="circular" />}
      <Stack direction="column" alignItems="start">
        <LabelWrap sx={icon ? { width: RESOURCE_NODE_WIDTH - 100 } : { width: RESOURCE_NODE_WIDTH - 60 }}>
          <ResourceNodeTypography
            variant="caption"
            component="div"
            color="text.primary"
            noWrap
            sx={{ textOverflow: 'ellipsis' }}
          >
            {label}
          </ResourceNodeTypography>
        </LabelWrap>
        <AttributeConditionNodeTotal total={total} />
      </Stack>
      {showExpand && <ExpandButton />}
    </NodeMetaContainer>
  );
}

export function buildOrNodeLabel(node: AccessGraphAttributeConditionOrNode) {
  return node?.attributeConditionOr?.operator == 'is'
    ? node?.attributeConditionOr?.value[0]
    : `${node?.attributeConditionOr?.attributeType.name} ${node?.attributeConditionOr?.operator} ${node?.attributeConditionOr?.value[0]}`;
}

export function getOrNodeIcon(node: AccessGraphAttributeConditionOrNode) {
  return node?.attributeConditionOr?.integration?.icons?.png;
}
