import { RadioBox, RadioBoxLabelSimple } from '@components';
import { RadioGroup, Stack, Typography } from '@mui/material';
import { InstallMethod } from './types';

export interface SelectInstallationMethodOption {
  value: InstallMethod;
  label: string;
}

export interface SelectInstallationMethodProps {
  options: SelectInstallationMethodOption[];
  method?: InstallMethod;
  onSelect: (value: InstallMethod) => void;
}

export function SelectInstallationMethod({ options, method, onSelect }: SelectInstallationMethodProps) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="subtitle1" component="div">
        Select installation method
      </Typography>
      <RadioGroup value={method} onChange={(e) => onSelect(e.target.value as InstallMethod)}>
        <Stack direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
          {options.map((option) => (
            <RadioBox
              key={option.value}
              value={option.value}
              label={<RadioBoxLabelSimple>{option.label}</RadioBoxLabelSimple>}
            />
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
