import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';

export function IntegrationAvatar({ icon, name }: { icon: string; name?: string }) {
  const size = 32;
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Avatar src={icon} alt={name} sx={{ width: size, height: size }} variant={'rounded'}>
        {icon ? null : <SettingsIcon fontSize={'small'} />}
      </Avatar>
      {name ? (
        <Typography variant="h5" component="div" noWrap>
          {name}
        </Typography>
      ) : null}
    </Stack>
  );
}
