import { memo, useMemo, useState } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  Stack,
  Tooltip,
  Typography,
  Avatar,
} from '@mui/material';

import { IntegrationAppModel } from '@api';
import { Loader, NoResults, SearchBar, StatusColumn, TableWrapper } from '@components';
import { isEmpty, useSearchParams } from '@libs';
import TablePagination from '@components/TablePagination';
import IntegrationResourcesFilters from '@Integrations/organisms/IntegrationResourcesFilters';
import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import {
  IntegrationResourcesFilterInput,
  IntegrationResourcesQuery,
  ResourceOwner,
  ResourcesTag_FragmentFragment,
  Status,
} from '@api/gql/graphql';
import { useResourcesInventory } from '@Integrations/services/use-resources-inventory';
import { getFragmentData } from '@api/gql';
import { ResourcesStatus_Fragment, ResourcesTag_Fragment, ResourcesType_Fragment } from '@Integrations/services/gql';
import {
  STATUS_FILTER_PARAM,
  TAG_FILTER_PARAM,
  RESOURCE_TYPE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/constants';

const PAGE_QUERY_PARAM = 'page';
const LIMIT = 15;

type IntegrationResourceNode = IntegrationResourcesQuery['integrationResources']['edges'][0]['node'];
export default function IntegrationResourcesPaginated({ integration }: { integration: IntegrationAppModel }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get(SEARCH_QUERY_PARAM) || '');

  const page = useMemo(() => Number(searchParams.get(PAGE_QUERY_PARAM)) || 1, [searchParams]);
  const offset = useMemo(() => (page - 1) * LIMIT, [page]);
  const filter = useMemo(() => {
    const input: IntegrationResourcesFilterInput = {};

    if (searchParams.has(STATUS_FILTER_PARAM)) {
      input.status = searchParams.getAll(STATUS_FILTER_PARAM);
    }

    if (searchParams.has(TAG_FILTER_PARAM)) {
      const tagsQP = searchParams.getAll(TAG_FILTER_PARAM);
      input.tag = tagsQP.map((tag) => JSON.parse(tag));
    }

    if (searchParams.has(RESOURCE_TYPE_FILTER_PARAM)) {
      input.resourceType = searchParams.getAll(RESOURCE_TYPE_FILTER_PARAM);
    }

    return input;
  }, [searchParams]);

  const { isFetched, data, isFetching } = useResourcesInventory({
    id: integration.id,
    offset,
    take: LIMIT,
    search: searchQuery,
    filter,
  });

  const total = useMemo(() => data?.integrationResources.totalCount || 0, [data]);
  const resources = useMemo(() => data?.integrationResources.edges.map((edge) => edge.node) || [], [data]);

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(SEARCH_QUERY_PARAM);
    if (!isEmpty(value)) {
      newSearchParams.set(SEARCH_QUERY_PARAM, value);
      newSearchParams.delete(PAGE_QUERY_PARAM);
    }
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (pageNumber: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(PAGE_QUERY_PARAM, String(pageNumber));
    setSearchParams(newSearchParams);
    window.scrollTo(0, 0);
  };

  if (!isFetched) {
    return <Loader />;
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <SearchBar value={searchQuery} onChange={handleSearchQuery} placeholder={'Search by resource & location'} />
          <IntegrationResourcesFilters integrationId={integration.id} search={searchQuery} />
        </Stack>
      </Stack>
      <IntegrationResourcesTable
        {...{
          resources,
          isLoading: isFetching,
        }}
      />
      <TablePagination
        pagination={{
          limit: LIMIT,
          offset,
          total,
        }}
        isLoading={false}
        onChange={handlePageChange}
      />
    </Stack>
  );
}

const IntegrationResourcesTable = memo(function IntegrationResourcesTable({
  resources,
  isLoading,
}: {
  resources: IntegrationResourceNode[];
  isLoading?: boolean;
}) {
  if (resources.length === 0) {
    return <NoResults>No resources found</NoResults>;
  }

  return (
    <TableWrapper loading={isLoading}>
      <MuiTable size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th" size="medium">
              Resource
            </TableCell>
            <TableCell component="th" size="medium">
              Location
            </TableCell>
            <TableCell component="th" size="medium">
              Owner
            </TableCell>
            <TableCell component="th" size="medium" width="25%">
              Tags
            </TableCell>
            <TableCell component="th" size="medium" width="15%">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.map((r) => (
            <IntegrationResourcesTableRow key={r.id} resource={r} />
          ))}
        </TableBody>
      </MuiTable>
    </TableWrapper>
  );
});

const IntegrationResourcesTableRow = memo(function IntegrationResourcesTableRow({
  resource,
}: {
  resource: IntegrationResourceNode;
}) {
  const { literalPath, logicalPath } = buildResourcePath(resource.path);

  const status = getFragmentData(ResourcesStatus_Fragment, resource.status);
  const resourceType = getFragmentData(ResourcesType_Fragment, resource.type);
  const tags = [...getFragmentData(ResourcesTag_Fragment, resource.tags)]; // to lose the readonly

  return (
    <TableRow className={status.status === 'Error' ? 'MuiTableRow-error' : ''}>
      <TableCell>
        <PrimarySecondary primary={resource.name} secondary={resource.name} icon={resourceType.icons.svg} />
      </TableCell>
      <TableCell>
        <PrimarySecondary primary={literalPath} secondary={logicalPath} />
      </TableCell>
      <TableCell>
        <ResourceOwners owners={resource.owners} />
      </TableCell>
      <TableCell align="left">
        <IntegrationResourcesTableTags tags={tags} />
      </TableCell>
      <TableCell width="5%" align="right">
        {resource.status && <IntegrationResourcesStatus status={status} />}
      </TableCell>
    </TableRow>
  );
});

const ResourceOwners = memo(function ResourceOwnersPrep({ owners }: { owners: ResourceOwner[] }) {
  const ownersSliced = owners.slice(0, 3);

  const otherOwners = owners
    .slice(3)
    .map((otherOwner) => `${otherOwner.attributeValue.type}:  <strong>{otherOwner.tagName}</strong>`);

  return (
    <Stack direction="row" spacing={0.5}>
      {ownersSliced.map((owner) => (
        <Chip
          key={owner.attributeValue.typDisplayName}
          label={
            <>
              {`${owner.attributeValue.typDisplayName}:`} <strong>{owner.attributeValue.name}</strong>
            </>
          }
          icon={<Avatar src={owner.integration?.icons?.svg} sx={{ width: 16, height: 16 }} />}
          size="small"
          variant="outlined"
        />
      ))}
      {otherOwners.length > 0 && <OtherTags tags={otherOwners} />}
    </Stack>
  );
});

const IntegrationResourcesTableTags = memo(function IntegrationResourcesTableTags({
  tags,
}: {
  tags: ResourcesTag_FragmentFragment[];
}) {
  const tagsStringified = Object.values(tags).map((tag) => `${tag.key}: ${tag.value}`);
  const tagsSliced = tagsStringified.slice(0, 3);
  const otherTags = tagsStringified.slice(3);

  return (
    <Stack direction="row" spacing={0.5}>
      {tagsSliced.map((tag) => (
        <IntegrationResourcesTableTag key={tag} tag={tag} />
      ))}
      {otherTags.length > 0 && <OtherTags tags={otherTags} />}
    </Stack>
  );
});

const IntegrationResourcesTableTag = memo(function IntegrationResourcesTableTag({ tag }: { tag: string }) {
  const tagSliced = tag.slice(0, 20);

  if (tagSliced.length === tag.length) {
    return <Chip label={tagSliced} size="small" />;
  }

  return (
    <Tooltip title={tag} arrow placement="top">
      <Chip label={`${tagSliced}...`} size="small" />
    </Tooltip>
  );
});

const OtherTags = memo(function OtherTags({ tags }: { tags: string[] }) {
  const title = (
    <Stack direction="column" spacing={1}>
      {tags.map((t) => (
        <Typography key={t} variant="inherit">
          {t}
        </Typography>
      ))}
    </Stack>
  );

  return (
    <Tooltip title={title} arrow placement="top">
      <Chip label={`+${tags.length}`} size="small" variant="outlined" />
    </Tooltip>
  );
});

const IntegrationResourcesStatus = memo(function IntegrationResourcesStatus({ status }: { status: Status }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <StatusColumn status={status.status} />
      {status.status === 'Error' && (
        <Typography color="error.main" variant="body2">
          {status.message}
        </Typography>
      )}
    </Stack>
  );
});

function buildResourcePath(resourcePath: IntegrationResourceNode['path']) {
  const logicalPath: string[] = [];
  const literalPath: string[] = [];
  resourcePath.map((path) => {
    logicalPath.push(path.keyDisplayName);
    if (typeof path.value === 'string') literalPath.push(path.value);
  });
  literalPath.pop();
  logicalPath.pop();

  return {
    literalPath: literalPath.join('/'),
    logicalPath: logicalPath.join('/'),
  };
}
