import { useState } from 'react';
import { useAppQuery } from '../../api/query/use-app-query';
import { FrontierService } from '../../api/services';
import { AlertFilterInput, QueryAlertsArgs } from '@api/gql/graphql';
import { alertsQueryDocument } from './gql';
import { AlertsQueryKeys } from './queryKeys';
import { SEVERITY_FILTER_PARAM } from '@AccessAlerts/organisms/AlertSeverityFilter';
import { ALERT_TYPE_FILTER_PARAM } from '@AccessAlerts/organisms/AlertTypeFilter';
import { getStartEndDateFromQP } from '@utils';
import { USER_FILTER_PARAM } from '@AccessAlerts/organisms/AlertUserFilter';
import { RESOURCE_TYPE_FILTER_PARAM } from '@AccessAlerts/organisms/AlertResourceTypeFilter';
import { INTEGRATION_FILTER_PARAM } from '@AccessAlerts/organisms/AlertIntegrationFilter';

export function toAlertFilterProps(qp: URLSearchParams): AlertFilterInput | undefined {
  const filters: AlertFilterInput = {};

  const { startDate, endDate } = getStartEndDateFromQP(qp);
  if (startDate && endDate) filters.timestamp = { between: [startDate.fullIsoString, endDate.fullIsoString] };
  else if (startDate) filters.timestamp = { after: startDate.fullIsoString };
  else if (endDate) filters.timestamp = { before: endDate.fullIsoString };

  if (qp.has(SEVERITY_FILTER_PARAM)) {
    const severity: number[] = qp.getAll(SEVERITY_FILTER_PARAM).map((s) => parseInt(s));
    filters.severity = severity;
  }
  if (qp.has(ALERT_TYPE_FILTER_PARAM)) {
    const alertType = qp.getAll(ALERT_TYPE_FILTER_PARAM);
    filters.type = alertType;
  }

  if (qp.has(USER_FILTER_PARAM)) {
    const users = qp.getAll(USER_FILTER_PARAM);
    filters.user = users;
  }

  if (qp.has(RESOURCE_TYPE_FILTER_PARAM)) {
    const resourceType = qp.getAll(RESOURCE_TYPE_FILTER_PARAM);
    filters.resourceTypeTypes = resourceType;
  }

  if (qp.has(INTEGRATION_FILTER_PARAM)) {
    const integration = qp.getAll(INTEGRATION_FILTER_PARAM);
    filters.integrationIds = integration;
  }

  return Object.keys(filters).length === 0 ? undefined : filters;
}

export function useAlerts(args: QueryAlertsArgs) {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(alertsQueryDocument, { ...args, withAccessGroups: false });
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery([AlertsQueryKeys.AlertsList, JSON.stringify(args)], fetch, {
    keepPreviousData: true,
  });

  return {
    ...res,
    isFetched: isFirstTimeFetched,
  };
}
