import { useEffect, useMemo, useRef, useState } from 'react';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { useGetCatalogV2, useListActivityFilterOptions } from '@api';
import { FilterProps } from './types';

export const RESOURCE_TYPE_FILTER_LABEL = 'Resource Type';
export const RESOURCE_TYPE_FILTER_PARAM = 'filter.resource_type';
const RESOURCE_TYPE_PARAM = 'resource_type';

export default function ResourceTypeFilterAsync({ opened, onClose, onOpen, selected, onClear, onSelect }: FilterProps) {
  const [enabled] = useState(() => selected.length > 0);

  const {
    options,
    isOptionsFetched,
    refetchQuery: refetchFilterOptions,
  } = useListActivityFilterOptions(RESOURCE_TYPE_PARAM, enabled);
  const { isIntegrationsConfigsFetched, refetch: refetchIntegrationsConfigs } = useGetCatalogV2(enabled);

  const resourceOptions = useMemo(
    () =>
      options.map((resource) => ({
        value: resource,
        label: resource,
      })),
    [options],
  );

  const selectedOptions = useMemo(() => {
    const list: ListFilterOption[] = [];
    for (const option of resourceOptions) {
      if (selected.includes(option.value)) {
        list.push(option);
      }
    }
    return list;
  }, [resourceOptions, selected]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    !opened && onOpen && onOpen();
    if (opened && !isIntegrationsConfigsFetched && !isOptionsFetched) {
      refetchFilterOptions();
      refetchIntegrationsConfigs();
    }
  };

  const isFetching = (!isIntegrationsConfigsFetched || !isOptionsFetched) && (opened || selected.length > 0);

  const open = (Boolean(anchorEl) && opened) || false;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isOptionsFetched) {
        refetchFilterOptions();
        refetchIntegrationsConfigs();
      }
    }
  }, [isOptionsFetched, opened, ref, refetchFilterOptions, refetchIntegrationsConfigs]);

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={RESOURCE_TYPE_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={resourceOptions}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${RESOURCE_TYPE_FILTER_LABEL} Filter`}
            loading={isFetching}
          />
        </Box>
      </Popover>
    </>
  );
}
