import { useEffect, useState } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { toString as humanizeCron } from 'cronstrue';
import { useSearchParams } from 'react-router-dom';

import { Grid, Link, Stack, Typography } from '@mui/material';
import { BlockSection, FormActions, LabeledTextField } from '@components';
import {
  ReportColumnsSelect,
  ReportCopyLink,
  ReportExportButton,
  ReportItemPreviewActivity,
  ReportProvider,
  ReportTable,
} from '@organisms';
import { ActivityReportModel, ModifyActivityReportModel } from '@api';
import { isEmail, queryParamsToReport, ReportFormQP } from '@utils';
import { isEmpty } from '@libs';
import ActivityFiltersNew from '@Activity/common/organisms/ActivityFiltersNew';

export interface ReportFormProps {
  loading?: boolean;
  report?: ActivityReportModel;
  onChange: (report: ModifyActivityReportModel) => void;
}

export function ReportForm(props: ReportFormProps) {
  const [qp] = useSearchParams();
  const methods = useForm<ModifyActivityReportModel>({
    mode: 'onChange',
    defaultValues: {
      name: props.report?.name || '',
      active: true,
      ...queryParamsToReport(qp),
      schedule: props.report?.schedule,
    },
  });

  return (
    <FormProvider {...methods}>
      <ReportFormContent {...props} />
    </FormProvider>
  );
}

function useReportFormContent(onChange: ReportFormProps['onChange']) {
  const [qp] = useSearchParams();
  const { control, handleSubmit, setValue } = useFormContext<ModifyActivityReportModel>();

  useEffect(() => {
    const qpProps = queryParamsToReport(qp);
    Object.keys(qpProps).forEach((key) => {
      const reportFormKey = key as keyof ReportFormQP;
      setValue(reportFormKey, qpProps[reportFormKey]);
    });
  }, [qp, setValue]);

  const onSubmit: SubmitHandler<ModifyActivityReportModel> = (data) => {
    if (isEmpty(data.schedule?.cron)) {
      delete data.schedule;
    }

    onChange(data);
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
  };
}

function ReportFormContent({ loading, report, onChange }: ReportFormProps) {
  const { control, onSubmit } = useReportFormContent(onChange);

  return (
    <ReportProvider>
      <Stack direction="column" spacing={4}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" columnGap={2}>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: 'Name is required',
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <LabeledTextField
                  label="Report name"
                  placeholder="Type name"
                  error={invalid}
                  helperText={error?.message}
                  inputProps={field}
                  autoFocus
                />
              )}
            />
          </Grid>
          <Grid>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <ReportExportButton />
              <form onSubmit={onSubmit}>
                <FormActions loading={loading} submitLabel={report ? 'Save report' : 'Create report'} />
              </form>
            </Stack>
          </Grid>
        </Grid>
        <BlockSection
          title="Data"
          icon="equalizer"
          actions={
            report ? (
              <>
                <ReportCopyLink reportId={report.id} />
                <ReportItemPreviewActivity report={report} />
              </>
            ) : null
          }
        >
          <Stack direction="row" alignItems="start" spacing={1}>
            <ActivityFiltersNew />
            <ReportColumnsSelect />
          </Stack>
        </BlockSection>
        <BlockSection title="Schedule" icon="event_available">
          <FormSchedule />
        </BlockSection>
        <BlockSection title="Preview (first 10 access requests)" icon="visibility">
          <ReportTable />
        </BlockSection>
      </Stack>
    </ReportProvider>
  );
}

function isCronValid(cron: string): boolean {
  try {
    humanizeCron(cron);
    return true;
  } catch (error) {
    return false;
  }
}

function useFormSchedule() {
  const { control, watch } = useFormContext<ModifyActivityReportModel>();
  const [humanReadableCron, setHumanReadableCron] = useState<string>('');

  const watchedCron = watch('schedule.cron');
  useEffect(() => {
    if (watchedCron !== '' && isCronValid(watchedCron)) {
      setHumanReadableCron(humanizeCron(watchedCron));
    }
  }, [watchedCron]);

  return {
    control,
    humanReadableCron,
  };
}

function FormSchedule() {
  const { control, humanReadableCron } = useFormSchedule();

  return (
    <Grid container columnGap={1}>
      <Grid item xs={3}>
        <Controller
          name="schedule.cron"
          control={control}
          defaultValue=""
          rules={{
            validate: (value) => (value ? isCronValid(value) || 'Invalid cron expression' : true),
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <LabeledTextField
              label="Cron expression"
              subLabel={
                <Typography variant="inputLabel">
                  (for more details,{' '}
                  <Link target="_blank" href="https://crontab.guru/" rel="noreferrer">
                    click here
                  </Link>
                  )
                </Typography>
              }
              placeholder="Use a sample cron expression, like: 0 4 8-14 * *"
              inputProps={field}
              error={invalid}
              helperText={error?.message || humanReadableCron}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="schedule.format"
          control={control}
          defaultValue="csv"
          render={() => (
            <LabeledTextField
              label="Format"
              value="CSV (.csv)"
              InputProps={{
                disabled: true,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="schedule.recipients"
          control={control}
          defaultValue={[]}
          rules={{
            validate: (value, state) => {
              if (!state.schedule?.cron) return true;
              if (value.length === 0) return 'At least one recipient is required';
              if (value.some((email) => !isEmail(email.trim()))) return 'Invalid email address';
              return true;
            },
          }}
          render={(props) => <RecipientsInput {...props} />}
        />
      </Grid>
    </Grid>
  );
}

interface RecipientsInputProps {
  field: ControllerRenderProps<ModifyActivityReportModel, 'schedule.recipients'>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<ModifyActivityReportModel>;
}

function useRecipientsInput(value: string[], onChange: (value: string[]) => void) {
  const { getValues } = useFormContext<ModifyActivityReportModel>();
  const [inputValue, setInputValue] = useState<string>(value.join(', '));
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | undefined>();

  const handleChange = (newValue: string) => {
    setInputValue(newValue);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => onChange(newValue.split(',').map((email) => email.trim())), 500);
    setDebounceTimer(timer);
  };

  const isDisabled = isEmpty(getValues('schedule.cron'));

  return {
    inputValue,
    isDisabled,
    handleChange,
  };
}

function RecipientsInput({
  field: { onChange, value, ...restField },
  fieldState: { invalid, error },
}: RecipientsInputProps) {
  const { inputValue, handleChange, isDisabled } = useRecipientsInput(value, onChange);

  return (
    <LabeledTextField
      label="Recipients"
      value={inputValue}
      placeholder="Type emails, separated by commas"
      onChange={(event) => handleChange(event.target.value)}
      inputProps={restField}
      disabled={isDisabled}
      error={invalid}
      helperText={error?.message}
    />
  );
}
