import { Button, ButtonBase, CircularProgress, ListItemText, Menu, MenuItem, Stack } from '@mui/material';

import { ActiveAccessFilters, ActiveAccessIntegrationSelect, ActiveAccessMap } from '@organisms';
import { Page } from '@components';
import { ProState } from '@components/Packages/ProState';
import { useFlagFixed } from '@hooks';
import { Flag, QueryParams } from '@utils';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '@routes';
import useBundlesFetch from '@AccessFlows/hooks/use-bundles-fetch';
import { MouseEvent, useEffect, useState } from 'react';

export function ActiveAccessPage() {
  const { isPremium } = useFlagFixed(Flag.NEW_UI_ACTIVE_ACCESS);
  const { isEnabled: showAddToBundleButton } = useFlagFixed(Flag.SHOW_ADD_TO_BUNDLE_BUTTON);
  const { bundlesList, loadingProgress } = useBundlesFetch();
  const bundlesOptions = bundlesList.map((bundle) => ({ label: bundle.bundle.name, value: bundle.bundle.id }));

  return (
    <Page title="Active Access" fullHeight>
      {isPremium ? (
        <ProState />
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={2}>
            <Stack direction="row" alignItems="center" justifyItems="center" spacing={2} mb={2}>
              <ActiveAccessIntegrationSelect />
              <ActiveAccessFilters />
            </Stack>
            {showAddToBundleButton && (
              <BundlesOptionsSelect options={bundlesOptions} loadingBundles={loadingProgress < 1} />
            )}
          </Stack>
          <ActiveAccessMap />
        </>
      )}
    </Page>
  );
}

// TODO: Temporary for Demo - Remove
function BundlesOptionsSelect({
  loadingBundles,
  options,
}: {
  loadingBundles: boolean;
  options: { label: string; value: string }[];
}) {
  const [searchParams] = useSearchParams();
  const [hasIntegrationId, setHasIntegrationId] = useState(searchParams.has(QueryParams.integrationId));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    setHasIntegrationId(searchParams.has(QueryParams.integrationId));
  }, [searchParams, setHasIntegrationId]);

  const handleTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (value: string) => {
    handleClose();
    navigate(generatePath(routes.EditBundle.path, { id: value }));
  };

  return (
    <div>
      <ButtonBase data-testid="actions-menu-trigger" onClick={handleTriggerClick} disabled={loadingBundles}>
        {loadingBundles ? (
          <CircularProgress size="24px" />
        ) : (
          <Button variant="contained" color="primary" disabled={!hasIntegrationId}>
            Add to Bundle
          </Button>
        )}
      </ButtonBase>
      <Menu
        PaperProps={{ sx: { width: '200px' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '40px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            data-testid={`actions-menu-${option.label}`}
            onClick={() => handleOnClick(option.value)}
          >
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }}>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
