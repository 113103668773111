import { CircularProgress, styled } from '@mui/material';

import Avatar from '@mui/material/Avatar';

const IndicatorAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  '& > .dot': {
    backgroundColor: theme.palette.success.main,
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    position: 'relative',

    '&.pulsing': {
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: `1px solid ${theme.palette.success.main}`,
        content: '""',
      },
    },
  },

  '&.passive': {
    backgroundColor: theme.palette.background.active,
    width: '50px',
    height: '50px',

    '& > .dot': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.border.default}`,
    },
  },

  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export interface IndicatorProps {
  passive?: boolean;
  pulsing?: boolean;
  loading?: boolean;
}

export function Indicator({ passive, pulsing, loading }: IndicatorProps) {
  const className = [];
  if (passive) className.push('passive');

  return (
    <IndicatorAvatar className={className.join(' ')}>
      {loading ? <CircularProgress size={20} /> : <span className={'dot ' + (pulsing ? 'pulsing' : '')} />}
    </IndicatorAvatar>
  );
}
