import { useState } from 'react';
import styled from 'styled-components';

const SpecialLogo = styled.svg`
  .animated {
    animation: 'easter 2s';
  }
  @keyframes easter {
    0%,
    100% {
      transform: rotate(0deg);
      filter: hue-rotate(0deg);
    }
    25% {
      transform: rotate(180deg) scale(0.75) skew(30deg);
      filter: hue-rotate(90deg);
    }
    50% {
      transform: rotate(360deg) scale(2);
      filter: hue-rotate(360deg) drop-shadow(0 0 0.5rem #fff);
    }
    75% {
      transform: rotate(-180deg) scale(0.75) skew(-30deg);
      filter: hue-rotate(-180deg);
    }
  }
`;

export default function Logo({ fill }: { fill: string }) {
  const [easterEgg, setEasterEgg] = useState(0);

  const handleEasterEgg = () => {
    if (easterEgg !== 10) {
      setEasterEgg(easterEgg + 1);
    } else setTimeout(() => setEasterEgg(0), 2000);
  };

  return (
    <SpecialLogo
      style={easterEgg === 10 ? { animation: 'easter 2s' } : {}}
      onClick={handleEasterEgg}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 2C9.60342 1.99993 7.28635 2.77375 5.47095 4.18054C3.65555 5.58733 2.46257 7.53351 2.10927 9.66466C1.75596 11.7958 2.26583 13.9701 3.54603 15.7917C4.82623 17.6133 6.7916 18.9609 9.0842 19.5891C9.1807 19.6155 9.28493 19.6065 9.37396 19.5638C9.463 19.5212 9.52954 19.4485 9.55897 19.3617C9.58839 19.275 9.57828 19.1813 9.53085 19.1012C9.48342 19.0212 9.40257 18.9614 9.30608 18.9349C7.66711 18.4872 6.19874 17.639 5.06478 16.4848C3.93083 15.3306 3.17588 13.9158 2.88419 12.3984C2.59249 10.8809 2.77551 9.32043 3.41282 7.89103C4.05014 6.46164 5.1167 5.21955 6.49349 4.30334C7.87027 3.38713 9.50315 2.83283 11.2099 2.70229C12.9167 2.57175 14.6303 2.8701 16.1594 3.56406C17.6886 4.25801 18.9732 5.32028 19.87 6.63234C20.7667 7.9444 21.2403 9.45466 21.2379 10.9946C21.2304 12.7854 20.5827 14.5266 19.3904 15.9612C18.1981 17.3959 16.5244 18.448 14.6163 18.9622C14.5289 18.9857 14.4538 19.0365 14.4046 19.1056C14.3554 19.1747 14.3355 19.2574 14.3485 19.3385C14.3614 19.4197 14.4064 19.494 14.4752 19.5478C14.544 19.6017 14.6321 19.6315 14.7233 19.6318C14.76 19.6318 14.7964 19.627 14.8316 19.6176C16.8771 19.0698 18.676 17.9518 19.9688 16.4251C21.2928 14.8655 22.0069 12.9565 22 10.9946C21.9986 8.61029 20.9447 6.32393 19.0699 4.63752C17.195 2.95112 14.6523 2.00252 12.0002 2V2Z"
        fill={fill}
      />
      <path
        d="M12.0005 11.9099C12.4341 11.9099 12.8579 11.7943 13.2184 11.5778C13.5789 11.3612 13.8599 11.0534 14.0258 10.6933C14.1918 10.3332 14.2352 9.93691 14.1506 9.5546C14.066 9.1723 13.8572 8.82113 13.5506 8.54551C13.244 8.26988 12.8534 8.08218 12.4282 8.00613C12.0029 7.93009 11.5621 7.96912 11.1615 8.11828C10.761 8.26745 10.4186 8.52006 10.1777 8.84416C9.9368 9.16826 9.80823 9.5493 9.80823 9.93909C9.80858 10.4617 10.0397 10.9628 10.4507 11.3323C10.8618 11.7019 11.4192 11.9096 12.0005 11.9099V11.9099ZM13.0042 8.82308C13.1105 8.82308 13.2144 8.85142 13.3028 8.90452C13.3912 8.95761 13.46 9.03308 13.5007 9.12137C13.5414 9.20967 13.5521 9.30683 13.5313 9.40056C13.5106 9.49429 13.4594 9.5804 13.3842 9.64797C13.3091 9.71555 13.2133 9.76157 13.109 9.78022C13.0047 9.79886 12.8967 9.78929 12.7985 9.75272C12.7003 9.71615 12.6163 9.65421 12.5572 9.57475C12.4982 9.49529 12.4667 9.40186 12.4667 9.30629C12.4667 9.17814 12.5233 9.05523 12.6241 8.96461C12.7249 8.87399 12.8616 8.82308 13.0042 8.82308V8.82308Z"
        fill={fill}
      />
      <path
        d="M17.4374 11.8256C18.1611 11.6831 19.1436 11.8256 19.5742 12.0524C19.6539 12.094 19.7478 12.1082 19.8384 12.0923C19.9289 12.0763 20.0099 12.0313 20.0663 11.9657C20.1227 11.9 20.1506 11.8182 20.1448 11.7354C20.1391 11.6526 20.1001 11.5746 20.035 11.5157L12.2724 4.39222C12.2366 4.36026 12.1942 4.335 12.1475 4.31788C12.1008 4.30076 12.0508 4.29213 12.0004 4.29249C11.9011 4.29313 11.8062 4.32898 11.7362 4.39222L3.97358 11.5157C3.90855 11.5746 3.86952 11.6526 3.86378 11.7354C3.85803 11.8182 3.88595 11.9 3.94233 11.9657C3.99872 12.0313 4.07973 12.0763 4.17027 12.0923C4.26081 12.1082 4.3547 12.094 4.43447 12.0524C4.86368 11.8303 5.84754 11.6831 6.57124 11.8256C9.06327 12.3183 11.0482 16.1911 11.6293 21.6928C11.6388 21.777 11.6828 21.855 11.7527 21.9118C11.8226 21.9685 11.9134 21.9999 12.0076 21.9999C12.1018 21.9999 12.1926 21.9685 12.2625 21.9118C12.3324 21.855 12.3764 21.777 12.386 21.6928C12.9604 16.1875 14.9453 12.3147 17.4374 11.8256ZM12.0004 5.12119L13.7766 6.74297C12.6106 6.51029 11.402 6.51029 10.236 6.74297L12.0004 5.12119ZM8.18638 11.752C7.74952 11.4676 7.2531 11.2656 6.72708 11.1584C6.46654 11.1093 6.2009 11.0854 5.9347 11.0871C5.78547 11.0871 5.63361 11.0931 5.48042 11.1061L8.20223 8.61289C8.69355 8.15921 9.27959 7.79712 9.9268 7.54735C10.574 7.29758 11.2697 7.16504 11.9739 7.15732H12.0307C12.735 7.16523 13.4306 7.29786 14.0777 7.54762C14.7249 7.79738 15.311 8.15937 15.8024 8.61289L18.5229 11.1061C18.1064 11.0666 17.6856 11.0842 17.2749 11.1584C16.7514 11.2668 16.2574 11.4687 15.8222 11.752C15.3706 12.0224 14.8771 12.2317 14.3577 12.3729C12.8321 12.824 11.1858 12.824 9.66019 12.3729C9.13735 12.2312 8.64071 12.0208 8.18638 11.7484V11.752ZM12.0004 19.1355C11.4563 16.4452 10.5899 14.2678 9.55323 12.995C11.1462 13.4478 12.8558 13.4478 14.4488 12.995C13.4121 14.2689 12.5458 16.4416 12.0004 19.1319V19.1355Z"
        fill={fill}
      />
    </SpecialLogo>
  );
}
