import { PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';

export type TableListTitleProps = TypographyProps;

export default function TableListTitle({ children, ...rest }: PropsWithChildren<TableListTitleProps>) {
  return (
    <Typography variant="inputLabel" color="text.muted" {...rest}>
      <strong>{children}</strong>
    </Typography>
  );
}
