import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Tooltip, Typography } from '@mui/material';

import { GRANTEE_FILTER_PARAM } from '@AccessFlows/common/constants';

import { GranteeItem } from '@AccessFlows/common/types';
import AccessFlowItemGranteeChip from '@AccessFlows/components/AccessFlowItemGranteeChip';

interface AccessFlowItemGranteesProps {
  grantees: GranteeItem[];
  maxGrantees?: number;
}

export default function AccessFlowItemGrantees({ grantees, maxGrantees = 5 }: AccessFlowItemGranteesProps) {
  const slicedGrantees = grantees.slice(0, maxGrantees);
  const moreCount = grantees.length - slicedGrantees.length;

  return (
    <Stack direction="column" alignItems="flex-end" spacing={1}>
      {slicedGrantees.length === 0 && (
        <Typography variant="body2" color="text.muted">
          <em>No grantees</em>
        </Typography>
      )}
      {slicedGrantees.map((grantee) => (
        <AccessFlowItemGrantee key={`${grantee.accessFlowId}-${grantee.id}`} grantee={grantee} />
      ))}
      {moreCount > 0 && (
        <Typography variant="body2" color="text.muted">
          +{moreCount} more
        </Typography>
      )}
    </Stack>
  );
}

interface AccessFlowItemGranteeProps {
  grantee: GranteeItem;
}

function AccessFlowItemGrantee({ grantee }: AccessFlowItemGranteeProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = searchParams.getAll(GRANTEE_FILTER_PARAM).includes(grantee.id);

  const handleOnClick = useCallback(() => {
    const allFilterGrantees = searchParams.getAll(GRANTEE_FILTER_PARAM);

    if (allFilterGrantees.includes(grantee.id)) {
      searchParams.delete(GRANTEE_FILTER_PARAM);
      allFilterGrantees.forEach((granteeId) => {
        if (granteeId !== grantee.id) {
          searchParams.append(GRANTEE_FILTER_PARAM, granteeId);
        }
      });
    } else {
      searchParams.append(GRANTEE_FILTER_PARAM, grantee.id);
    }

    setSearchParams(searchParams);
  }, [grantee.id, searchParams, setSearchParams]);

  return (
    <Tooltip title={`Filter by ${grantee.type.toLowerCase()}`} placement="top" enterDelay={750} arrow>
      <div>
        <AccessFlowItemGranteeChip grantee={grantee} onClick={handleOnClick} isActive={isActive} />
      </div>
    </Tooltip>
  );
}
