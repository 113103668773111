import { Stack } from '@mui/material';

import { AddIntegrationSelectField } from './ConnectIntegrationForm/AddIntegrationSelectField';
import { IntegrationConfigParamAppModel } from '@api';
import { LabeledTextField } from '@components';

export function AddIntegrationParamsForm({
  params,
  values,
  onChange,
  disabled,
}: {
  params: Array<IntegrationConfigParamAppModel>;
  values: { [p: string]: string };
  onChange: (params: { [k: string]: string }) => void;
  disabled?: boolean;
}) {
  const formElements = params.map((param) => {
    switch (param.type) {
      case 'Select':
        return (
          <AddIntegrationSelectField
            selectedValue={values[param.id]}
            key={param.id}
            label={param.label}
            values={param.values ? param.values : []}
            placeholder={param.placeholder}
            onChange={(value) => {
              onChange({
                [param.id]: value,
              });
            }}
            disabled={disabled}
          />
        );
      default:
        return (
          <LabeledTextField
            label={param.label}
            key={param.id}
            defaultValue={values[param.id]}
            onChange={(e) =>
              onChange({
                [param.id]: e.target.value,
              })
            }
            placeholder={param.placeholder}
            optional={param.optional}
            disabled={disabled}
          />
        );
    }
  });
  return <Stack spacing={1.5}>{formElements}</Stack>;
}
