import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';

import { LabeledTextField, Markdown } from '@components';

import { IntegrationConfigFormPayload } from '../../Provider';

const INFO = [
  'Apono doesn&apos;t store credentials and uses Azure secrets. Please specify where the secret is located.',
  'For a detailed explanation see the [docs](https://docs.apono.io/docs/creating-secrets-in-azure-key-vault).',
].join('\n\n');

export function AzureSecretForm({ required, isViewOnly }: { required: boolean; isViewOnly?: boolean }) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <>
      <Typography variant="inputLabel" component="div" gutterBottom>
        <Markdown>{INFO}</Markdown>
      </Typography>
      <Controller
        control={control}
        name="secret_config.vault_url"
        rules={{
          required: {
            value: required,
            message: `Vault Url is required`,
          },
        }}
        render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
          return (
            <LabeledTextField
              label="Vault Url"
              placeholder="Enter vault url"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              disabled={isViewOnly}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="secret_config.name"
        rules={{
          required: {
            value: required,
            message: `Name is required`,
          },
        }}
        render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
          return (
            <LabeledTextField
              label="Name"
              placeholder="Enter secret name"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }}
      />
    </>
  );
}
