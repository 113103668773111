import { graphql } from '@api/gql';

// shared fragments
export const ACCESS_FLOW_FIELDS_WITH_LABELS = graphql(/* GraphQL */ `
  fragment accessFlowFieldsWithLabels on AccessFlow {
    id
    name
    labels {
      name
      color
    }
  }
`);

export const AlertRequestContextFields_Fragment = graphql(/* GraphQL */ `
  fragment alertRequestContextFields on AlertRequestContext {
    requestId
    friendlyRequestId
    accessFlow {
      ...accessFlowFieldsWithLabels
    }
    user {
      ...userFields
    }
    accessGroups {
      ...requestAlertAccessGroupFields
    }
  }
`);

export const AccessUnitFields_Fragment = graphql(/* GraphQL */ `
  fragment accessUnitFields on RequestAlertAccessUnit {
    permission
    resource {
      ...resourceFields
    }
  }
`);

export const RequestAlertAccessGroupFields_Fragment = graphql(/* GraphQL */ `
  fragment requestAlertAccessGroupFields on RequestAlertAccessGroup {
    integration {
      ...integrationFields
    }
    resourceType {
      ...resourceTypeFields
    }
    accessUnits @include(if: $withAccessGroups) {
      ...accessUnitFields
    }
  }
`);

export const AlertFields_Fragment = graphql(/* GraphQL */ `
  fragment alertFields on Alert {
    id
    type
    typeName
    severity
    reason
    recommendation
    timestamp
    requestContext {
      ...alertRequestContextFields
    }
  }
`);

export const AlertEdgesFields_Fragment = graphql(/* GraphQL */ `
  fragment alertEdgesFields on AlertEdge {
    node {
      ...alertFields
    }
    cursor
  }
`);

export const AlertsPaginatedFields_Fragment = graphql(/* GraphQL */ `
  fragment alertsPaginatedFields on AlertsPaginated {
    totalCount
    edges {
      ...alertEdgesFields
    }
  }
`);

export const alertsQueryDocument = graphql(/* GraphQL */ `
  query Alerts($take: Int, $offset: Int, $filter: AlertFilterInput, $withAccessGroups: Boolean!) {
    alerts(take: $take, offset: $offset, filter: $filter) {
      ...alertsPaginatedFields
    }
  }
`);

export const alertQueryDocument = graphql(/* GraphQL */ `
  query Alert($id: ID, $withAccessGroups: Boolean!) {
    alert(id: $id) {
      ...alertFields
    }
  }
`);

export const alertTypesOptionsQuery = graphql(/* GraphQL */ `
  query AlertTypesOptions($timestamp: DateTimeFilterInput) {
    alertsFilterOptions(timestamp: $timestamp) {
      types {
        id
        label
      }
    }
  }
`);

export const alertSeverityOptionsQuery = graphql(/* GraphQL */ `
  query AlertSeverityOptions($timestamp: DateTimeFilterInput) {
    alertsFilterOptions(timestamp: $timestamp) {
      severities
    }
  }
`);

export const alertUserOptionsQuery = graphql(/* GraphQL */ `
  query AlertUserOptions($timestamp: DateTimeFilterInput) {
    alertsFilterOptions(timestamp: $timestamp) {
      users {
        ...userFields
      }
    }
  }
`);

export const alertIntegrationOptionsQuery = graphql(/* GraphQL */ `
  query AlertIntegrationOptions($timestamp: DateTimeFilterInput) {
    alertsFilterOptions(timestamp: $timestamp) {
      integrations {
        ...integrationFields
      }
    }
  }
`);

export const alertResourceTypeOptionsQuery = graphql(/* GraphQL */ `
  query AlertResourceTypeOptions($timestamp: DateTimeFilterInput) {
    alertsFilterOptions(timestamp: $timestamp) {
      resourceTypes {
        ...resourceTypeFields
      }
    }
  }
`);

export const alertStatsFields = graphql(/* GraphQL */ `
  fragment alertStatsFields on AlertStats {
    id
    label
    count
    severity
  }
`);

export const alertStats = graphql(/* GraphQL */ `
  query AlertStats($filter: AlertFilterInput!, $type: AlertStatsType!) {
    alertStats(filter: $filter, type: $type) {
      ...alertStatsFields
    }
  }
`);

export const alertStatsOverTimeFields = graphql(/* GraphQL */ `
  fragment alertStatsOverTime on AlertStatsOverTime {
    timestamp
    stats {
      ...alertStatsFields
    }
  }
`);

export const alertStatsOverTime = graphql(/* GraphQL */ `
  query AlertStatsOverTime($filter: AlertFilterInput!) {
    alertStatsOverTime(filter: $filter) {
      ...alertStatsOverTime
    }
  }
`);
