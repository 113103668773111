import { MaterialIcon } from '@components';
import { Stack, Tooltip, Typography } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';

export default function AlertStatusMessage({
  message,
  maxChars = 30,
  children,
  isError,
}: PropsWithChildren<{
  message?: string;
  maxChars?: number;
  isError?: boolean;
}>) {
  const messageElem = useMemo(() => {
    if (!message) return undefined;

    if (message.length <= maxChars) {
      return (
        <Typography variant="inputLabel" pl={4} color={isError ? 'error.main' : 'warning.main'}>
          Reason:{' '}
          <Typography variant="inputLabel" color="white">
            {message}
          </Typography>
        </Typography>
      );
    }

    return (
      <Tooltip arrow title={<Typography variant="inputLabel">{message}</Typography>} placement="left">
        <Typography variant="inputLabel" pl={4} color={isError ? 'error.main' : 'warning.main'}>
          Reason:{' '}
          <Typography variant="inputLabel" color="white">
            {message.substring(0, maxChars) + '...'}
          </Typography>
        </Typography>
      </Tooltip>
    );
  }, [message, maxChars, isError]);

  if (!message) return <>{children}</>;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <MaterialIcon symbol="warning" color={isError ? 'error' : 'warning'} />
        {children}
      </Stack>
      {messageElem}
    </Stack>
  );
}
