import { MaterialIcon } from '@components';
import { Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';

interface AccessFlowItemGridProps {
  accessFlow?: ReactNode;
  grantees: ReactNode;
  targets: ReactNode;
  actions?: ReactNode;
}

export default function AccessFlowItemGrid({ accessFlow, grantees, targets, actions }: AccessFlowItemGridProps) {
  let granteesXs = 2;
  if (!accessFlow) granteesXs += 3;
  if (!actions) granteesXs += 1;

  return (
    <Grid container alignItems="center" columnSpacing={2}>
      {accessFlow && (
        <Grid item xs={3}>
          {accessFlow}
        </Grid>
      )}
      <Grid item xs={granteesXs}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <div>{grantees}</div>
          <MaterialIcon symbol="east" color="success" />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        {targets}
      </Grid>
      {actions && (
        <Grid item xs={1} container justifyContent="flex-end">
          {actions}
        </Grid>
      )}
    </Grid>
  );
}
