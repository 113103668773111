import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AccessFlowsService } from '@api/services';
import { orderBy } from '@libs';

import { QueryKeys } from './query-keys';
import {
  AccessBundleUpsertAppModel,
  AccessFlowUpsertAppModel,
  PaginatedAccessFlowAppSearchResponse,
  SetAccessFlowActiveFlagRequestV2,
} from '@api/apn-core';
import { useState } from 'react';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export function useListAccessFlows(filters: string[]) {
  const queryClient = useQueryClient();
  const [previousFilters, setPreviousFilters] = useState<string[]>(filters);
  const [isAccessFlowsFetched, setAccessFlowsFetched] = useState<boolean>(false);

  const {
    data: accessFlows,
    isFetched,
    refetch: refetchAccessFlows,
  } = useQuery({
    queryKey: [QueryKeys.AccessFlows, filters],
    queryFn: async () => {
      setPreviousFilters(filters);
      const filtersString = filters.map((item) => item.toLowerCase()).join(',');

      const { data, filter_result } = await AccessFlowsService.appListAccessFlows({
        filters: filtersString,
        onlyActive: false,
      });

      return { data: orderBy(data, ['created_date'], ['desc']), filter_result };
    },
    keepPreviousData: true,
    onSuccess: () => {
      if (!isAccessFlowsFetched) setAccessFlowsFetched(true);
    },
    initialData: (): PaginatedAccessFlowAppSearchResponse => {
      const previousData = queryClient.getQueryData<PaginatedAccessFlowAppSearchResponse | undefined>([
        QueryKeys.AccessFlows,
        previousFilters,
      ]);

      return (
        previousData || {
          data: [],
          filter_result: [],
        }
      );
    },
    refetchOnWindowFocus: false,
  });

  return {
    accessFlows,
    isAccessFlowsFetched,
    isAccessFlowsLoading: !isFetched,
    refetchAccessFlows,
  };
}

export function useCreateAccessFlow() {
  const queryClient = useQueryClient();
  const { track } = useAnalyticsContext();
  const {
    mutate: createAccessFlow,
    data: createdAccessFlow,
    isLoading: isCreateAccessFlowLoading,
  } = useMutation({
    mutationFn: async (payload: AccessFlowUpsertAppModel) =>
      AccessFlowsService.appCreateAccessFlow({
        accessFlowUpsertAppModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AccessFlows] });
    },
    onError: (error) => {
      track(CustomAnalyticsEvents.ACCESS_FLOW_FAILED, { error });
    },
  });

  return {
    createAccessFlow,
    isCreateAccessFlowLoading,
    createdAccessFlow,
  };
}

export function useGetAccessFlow(id: string, enabled = true) {
  const { data: accessFlow, isFetched: isAccessFlowFetched } = useQuery({
    queryKey: [QueryKeys.AccessFlows, id],
    queryFn: async () => AccessFlowsService.appGetAccessFlow({ id }),
    enabled,
  });

  return {
    accessFlow,
    isAccessFlowFetched,
  };
}

export function useChangeAccessFlowActiveFlag(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: changeAccessFlowActiveFlag,
    isLoading: isChangeAccessFlowActiveFlagLoading,
    isSuccess: isChangeAccessFlowActiveFlagSuccess,
    data: changedAccessFlow,
  } = useMutation({
    mutationFn: async (payload: SetAccessFlowActiveFlagRequestV2) =>
      AccessFlowsService.appChangeAccessFlowActiveFlag({
        id,
        accessFlowSetActiveAppRequest: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AccessFlows, id] });
    },
  });

  return {
    changeAccessFlowActiveFlag,
    isChangeAccessFlowActiveFlagLoading,
    isChangeAccessFlowActiveFlagSuccess,
    changedAccessFlow,
  };
}

export function useUpdateAccessFlowFlow(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: updateAccessFlow,
    isLoading: isUpdateAccessFlowFlowLoading,
    data: updatedAccessFlow,
  } = useMutation({
    mutationFn: async (payload: AccessFlowUpsertAppModel) =>
      AccessFlowsService.appUpdateAccessFlow({
        id,
        accessFlowUpsertAppModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AccessFlows, id] });
    },
  });

  return {
    updateAccessFlow,
    isUpdateAccessFlowFlowLoading,
    updatedAccessFlow,
  };
}

export function useDeleteAccessFlow(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: deleteAccessFlow,
    isLoading: isDeleteAccessFlowFlowLoading,
    isSuccess: isDeleteAccessFlowFlowSuccess,
  } = useMutation({
    mutationFn: async () => AccessFlowsService.appDeleteAccessFlow({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AccessFlows] });
    },
  });

  return {
    deleteAccessFlow,
    isDeleteAccessFlowFlowLoading,
    isDeleteAccessFlowFlowSuccess,
  };
}

export function useListAccessBundles() {
  const { data: accessBundles, isFetched: isAccessBundlesFetched } = useQuery({
    queryKey: [QueryKeys.AccessBundles],
    queryFn: async () => {
      const { data } = await AccessFlowsService.appListAccessBundles();
      return orderBy(data, ['created_date'], ['desc']);
    },
    initialData: [],
  });

  return {
    accessBundles,
    isAccessBundlesFetched,
  };
}

export function useCreateAccessBundle() {
  const queryClient = useQueryClient();

  const {
    mutate: createAccessBundle,
    data: createdAccessBundle,
    isLoading: isCreateAccessBundleLoading,
  } = useMutation({
    mutationFn: async (payload: AccessBundleUpsertAppModel) =>
      AccessFlowsService.appCreateAccessBundle({
        accessBundleUpsertAppModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AccessBundles] });
    },
  });

  return {
    createAccessBundle,
    isCreateAccessBundleLoading,
    createdAccessBundle,
  };
}

export function useGetAccessBundle(id: string) {
  const { data: accessBundle, isFetched: isAccessBundleFetched } = useQuery({
    queryKey: [QueryKeys.AccessBundles, id],
    queryFn: async () => AccessFlowsService.appGetAccessBundle({ id }),
  });

  return {
    accessBundle,
    isAccessBundleFetched,
  };
}

export function useUpdateAccessBundle(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: updateAccessBundle,
    isLoading: isUpdateAccessBundleLoading,
    data: updatedAccessBundle,
  } = useMutation({
    mutationFn: async (payload: AccessBundleUpsertAppModel) =>
      AccessFlowsService.appUpdateAccessBundle({
        id,
        accessBundleUpsertAppModel: payload,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.AccessBundles, id],
      });
    },
  });

  return {
    updateAccessBundle,
    isUpdateAccessBundleLoading,
    updatedAccessBundle,
  };
}

export function useCanDeleteAccessBundle(id: string) {
  const { data: canDeleteAccessBundle, isLoading: isCanDeleteAccessBundleLoading } = useQuery({
    queryKey: [QueryKeys.CanDeleteAccessBundle, id],
    queryFn: async () => AccessFlowsService.appCanDeleteAccessBundle({ id }),
  });

  return {
    canDeleteAccessBundle,
    isCanDeleteAccessBundleLoading,
  };
}

export function useDeleteAccessBundle(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: deleteAccessBundle,
    isLoading: isDeleteAccessBundleLoading,
    isSuccess: isDeleteAccessBundleSuccess,
  } = useMutation({
    mutationFn: async () => AccessFlowsService.appDeleteAccessBundle({ id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AccessBundles] });
    },
  });

  return {
    deleteAccessBundle,
    isDeleteAccessBundleLoading,
    isDeleteAccessBundleSuccess,
  };
}

export function useGetAccessFlowIssues(id: string) {
  const { data: accessFlowIssues, isFetched: isAccessFlowIssuesFetched } = useQuery({
    queryKey: [QueryKeys.AccessFlowsIssues, id],
    queryFn: async () => AccessFlowsService.appAccessFlowIssues({ id }),
  });

  return {
    accessFlowIssues,
    isAccessFlowIssuesFetched,
  };
}
