import { useAppListAttributes } from '@AccessFlows/services/attributesQueries';
import { Skeleton, Stack } from '@mui/material';
import { UsersFiltersAttributeFilter, UsersFiltersList } from './UsersFiltersList';
import { AttributeFilterOption } from './UsersListFilters/AttributeFilter';

export default function UsersFiltersFromAttributes() {
  const { data, isFetched } = useAppListAttributes({
    limit: 9999,
  });

  if (!isFetched) {
    return <UsersFiltersLoading />;
  }

  const fullAttributesList = data?.pages.flatMap((page) => page.data) || [];

  const attributeNamesMap: Record<string, Omit<UsersFiltersAttributeFilter, 'options'>> = {};
  const attributeNameOptionsMap: Record<string, Set<string>> = {};
  const attributeFilterOptionsMap: Record<string, AttributeFilterOption> = {};

  for (const attr of fullAttributesList) {
    if (attr.type.type === 'user') {
      continue;
    }

    attributeFilterOptionsMap[attr.id] = {
      value: attr.id,
      label: attr.value,
    };

    const nameMapKey = `${attr.integration?.id}.${attr.type.type}`;
    attributeNamesMap[nameMapKey] = {
      filterParam: nameMapKey,
      trigger: {
        label: attr.type.display_name,
        icon: attr.integration?.icons.svg ?? '/static/logo-only.svg',
      },
    };

    const nameOptionsMap = attributeNameOptionsMap[nameMapKey] || new Set();
    nameOptionsMap.add(attr.id);
    attributeNameOptionsMap[nameMapKey] = nameOptionsMap;
  }

  const attributes: UsersFiltersAttributeFilter[] = [];
  for (const [key, value] of Object.entries(attributeNamesMap)) {
    const options = Array.from(attributeNameOptionsMap[key]).map((option) => attributeFilterOptionsMap[option]);
    attributes.push({
      ...value,
      options,
    });
  }

  return (
    <UsersFiltersList
      attributes={attributes}
      roles={[
        {
          value: 'Admin',
          label: 'Admin',
        },
        {
          value: 'Grantee',
          label: 'Grantee',
        },
      ]}
    />
  );
}

function UsersFiltersLoading() {
  return (
    <Stack direction="row" spacing={2}>
      <Skeleton variant="rounded" width={280} height={32} />
      <Skeleton variant="rounded" width={100} height={32} />
      <Skeleton variant="rounded" width={100} height={32} />
    </Stack>
  );
}
