import { useMutation, useQueryClient } from '@tanstack/react-query';

import { WebhookUpdateAppModel, WebhooksService, WebhookTestAppModel } from '@api';
import { WebhooksQueryKeys } from './queryKeys';

export function useAppCreateWebhook() {
  const queryClient = useQueryClient();

  const {
    mutate: createWebhook,
    mutateAsync: createWebhookAsync,
    ...rest
  } = useMutation(async (webhookUpdateAppModel: WebhookUpdateAppModel) => {
    const res = await WebhooksService.appCreateWebhook({ webhookUpdateAppModel });

    queryClient.invalidateQueries([WebhooksQueryKeys.WebhooksList]);

    return res;
  });

  return {
    createWebhook,
    createWebhookAsync,
    ...rest,
  };
}

export function useAppDeleteWebhook(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: deleteWebhook,
    mutateAsync: deleteWebhookAsync,
    ...rest
  } = useMutation(async () => {
    const res = await WebhooksService.appDeleteWebhook({ id });

    queryClient.invalidateQueries([WebhooksQueryKeys.WebhooksList]);
    queryClient.invalidateQueries([WebhooksQueryKeys.WebhookDetails, id]);

    return res;
  });

  return {
    deleteWebhook,
    deleteWebhookAsync,
    ...rest,
  };
}

export function useAppTestWebhook() {
  const {
    mutate: testWebhook,
    mutateAsync: testWebhookAsync,
    ...rest
  } = useMutation(async (webhookTestAppModel: WebhookTestAppModel) =>
    WebhooksService.appTestWebhook({ webhookTestAppModel }),
  );

  return {
    testWebhook,
    testWebhookAsync,
    ...rest,
  };
}

export function useAppUpdateWebhook(id: string) {
  const queryClient = useQueryClient();

  const {
    mutate: updateWebhook,
    mutateAsync: updateWebhookAsync,
    ...rest
  } = useMutation(async (webhookUpdateAppModel: WebhookUpdateAppModel) => {
    const res = await WebhooksService.appUpdateWebhook({ id, webhookUpdateAppModel });

    queryClient.invalidateQueries([WebhooksQueryKeys.WebhooksList]);
    queryClient.invalidateQueries([WebhooksQueryKeys.WebhookDetails, id]);

    return res;
  });

  return {
    updateWebhook,
    updateWebhookAsync,
    ...rest,
  };
}
