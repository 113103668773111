import { FlowNode } from '@AccessGraph/nodes-builder';
import { AccessGraphAvailableAccessEdge } from '@api/gql/graphql';
import { Box, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { aponoColors } from '@utils';
import { EdgeHoverContext } from '@AccessGraph/AccessGraphMap';
import { AVAILABLE_ACCESS_COLOR } from '../node/node_and_edge_types';

export type AvailableAccessEdgeProps = {
  edge: AccessGraphAvailableAccessEdge;
  source: FlowNode;
  target: FlowNode;
};

export interface EdgeMetaProps {
  edge: AccessGraphAvailableAccessEdge;
}

const EdgeLabel = styled(Box)(({ theme }) => ({
  cursor: 'default',
  border: `1px solid ${theme.palette.border.default}`,
  backgroundColor: theme.palette.background.active,
  color: theme.palette.border.default,
  borderRadius: '20px',
  alignContent: 'center',
  padding: `8px`,
  minWidth: '50px',
  maxWidth: 'max-content',
  height: '40px',
  pointerEvents: 'all',
  fontSize: '14px',
  textAlign: 'center',
  justifyContent: 'center',
}));

export function AvailableAccessEdge({
  id,
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: EdgeProps<AvailableAccessEdgeProps>) {
  const hoveredEdge = useContext(EdgeHoverContext);

  const visibleEdgeLabel = hoveredEdge && hoveredEdge.id === id;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  if (!data) return null;
  const { edge } = data;

  let label = '';
  if (edge.availableAccess) {
    label =
      edge.availableAccess.permissions.length > 1
        ? `${edge.availableAccess.permissions.slice(0, 1).join(', ')} +(${
            edge.availableAccess.permissions.length - 1
          } more)`
        : edge.availableAccess.permissions.join(', ');
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={
          visibleEdgeLabel
            ? { strokeWidth: '1px', stroke: aponoColors.primary[400] }
            : { strokeWidth: '1px', stroke: AVAILABLE_ACCESS_COLOR }
        }
      />

      <EdgeLabelRenderer>
        {visibleEdgeLabel && (
          <EdgeLabel
            sx={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" color={AVAILABLE_ACCESS_COLOR} noWrap>
              {label}
            </Typography>
          </EdgeLabel>
        )}
      </EdgeLabelRenderer>
    </>
  );
}
