// @jsx: react-jsx
import { Edge, Node, XYPosition } from 'reactflow';
import {
  AccessGraphChildNode,
  AccessGraphEdge,
  AccessGraphNode,
  AccessGraphResourceMoreNode,
  AccessGraphResourceNode,
  AccessGraphUserMoreNode,
  AccessGraphUserNode,
  AccessGraphAccessTargetNode,
  AccessGraphAttributeConditionAndNode,
  AccessGraphAttributeConditionOrNode,
} from '@api/gql/graphql';
import {
  AND_NODE_CONDITION_HEIGHT,
  RESOURCE_NODE_HEIGHT,
  RESOURCE_NODES_GAP,
  INTEGRATION_NODE_HEIGHT,
  GROUPED_NODE_H_PADDING,
  INTEGRATION_NODE_GAP,
  NODE_BORDER,
  NODE_PADDING,
  CHILD_NODE_HEIGHT,
  CHILD_NODE_GAP,
  MORE_NODE_HEIGHT,
  NodeTypeTypes,
} from './components/node/common';
import {
  isAccessTargetNode,
  isAndConditionNode,
  isOrConditionNode,
  isResourceMoreNode,
  isResourceNode,
  isUserMoreNode,
  isUserNode,
  isAvailableAccessEdge,
} from './node-type';

type EdgeMetaProps = { edge: AccessGraphEdge };
export type FlowNode = Node<NodeMetaProps | ResourceIntegration, NodeTypeTypes>;
export type FlowEdge = Edge<EdgeMetaProps>;
type NodeMetaProps = {
  node: AccessGraphNode | AccessGraphChildNode | ResourceIntegration;
  childrenHeight: number;
  additionalNodes?: number;
  hasConnectedEdges?: boolean;
};

export type ResourceIntegration = { name: string; icon?: string };

/**
 * NodesBuilder
 * @description Build nodes for react-flow
 */
export class NodesBuilder {
  private edges: AccessGraphEdge[] = [];

  private nodes: AccessGraphNode[] = [];

  private edgesMap: {
    targets: Map<string, boolean>;
    sources: Map<string, boolean>;
  } = {
    targets: new Map<string, boolean>(),
    sources: new Map<string, boolean>(),
  };

  private nodesHashMap: Map<string, AccessGraphNode | AccessGraphChildNode> = new Map();

  private edgesHashMap: Map<string, AccessGraphEdge> = new Map();

  private userNodesMap: Map<string, AccessGraphNode[]> = new Map<string, AccessGraphNode[]>();

  private resourceNodesMap: Map<string, AccessGraphAccessTargetNode[]> = new Map<
    string,
    AccessGraphAccessTargetNode[]
  >();

  private resourceIntegrationsMap: Map<string, ResourceIntegration> = new Map<string, ResourceIntegration>();

  private userIntegrationsMap: Map<string, ResourceIntegration> = new Map<string, ResourceIntegration>();

  private flowNodes: FlowNode[] = [];

  private flowEdges: FlowEdge[] = [];

  constructor(edges: AccessGraphEdge[], nodes: AccessGraphNode[]) {
    this.edges = edges;
    this.nodes = nodes;

    this.edgesMap = this.edges.reduce((acc, edge) => {
      // create a map of edges
      acc.targets.set(edge.targetId, true);
      acc.sources.set(edge.sourceId, true);
      return acc;
    }, this.edgesMap);

    this.buildEdges();

    this.mapResourceNodes();
    this.mapUserNodes();

    this.buildUserNodes();
    this.buildResourceNodes();
  }

  public hasConnectedEdges(id: string): boolean {
    return this.edgesMap.targets.has(id) || this.edgesMap.sources.has(id);
  }

  public getFlowNodes() {
    return this.flowNodes;
  }

  public getFlowEdges() {
    return this.flowEdges;
  }

  public getNode(id: string): AccessGraphNode | AccessGraphChildNode | undefined {
    return this.nodesHashMap.get(id);
  }

  public getEdge(id: string): AccessGraphEdge | undefined {
    return this.edgesHashMap.get(id);
  }

  /*
   * Public API
   */

  /*
   * Private API
   */
  private buildEdges() {
    this.edges.forEach((edge) => {
      this.flowEdges.push(this.toFlowEdge(edge));
      this.edgesHashMap.set(edge.id, edge);
    });
  }

  private mapResourceNodes() {
    // Iterate over the accessTargets array
    const resourceNodes = this.nodes.filter((node) => isAccessTargetNode(node)) as AccessGraphAccessTargetNode[];
    resourceNodes.forEach((accessTargetNode) => {
      const integrationName = accessTargetNode.accessTarget?.integration.name;
      if (!integrationName) return;
      // Check if an array exists for the current integration name, if not, create one
      let accessTargetNodeArray = this.resourceNodesMap.get(integrationName);
      if (!accessTargetNodeArray) {
        accessTargetNodeArray = [];
        this.resourceNodesMap.set(integrationName, accessTargetNodeArray);
        this.resourceIntegrationsMap.set(integrationName, {
          name: integrationName,
          icon: accessTargetNode.accessTarget?.integration.icons.svg,
        });
      }
      // Push the current accessTargetNode to the array corresponding to the integration name
      accessTargetNodeArray.push(accessTargetNode);
    });
  }

  private buildResourceNodes() {
    const initX = 1000;
    const initY = 20;
    const categoriesCoords = { x: initX, y: initY };
    this.resourceNodesMap.forEach((integration, key) => {
      // push condition node
      const resourceIntegration = this.resourceIntegrationsMap.get(key);
      if (resourceIntegration) {
        this.flowNodes.push(this.toIntegrationsNode(resourceIntegration, { x: initX, y: categoriesCoords.y }));
        categoriesCoords.y += INTEGRATION_NODE_HEIGHT + INTEGRATION_NODE_GAP;
      }
      integration.forEach((node) => {
        if (node.children == undefined || node.children.length == 0) {
          // push node no expand
          this.flowNodes.push(this.toAccessTargetNode(node, categoriesCoords));
          this.nodesHashMap.set(node.id, node);
          categoriesCoords.y += this.nodeHeight(RESOURCE_NODE_HEIGHT) + RESOURCE_NODES_GAP;
        } else if ((node.totalChildren || 0) > 0 && node.children.length > 0) {
          const childNodes: FlowNode[] = [];
          // moving X coord to the right to place children nodes
          const resourcesCoords = {
            x: GROUPED_NODE_H_PADDING + categoriesCoords.x,
            y: RESOURCE_NODE_HEIGHT + RESOURCE_NODES_GAP + categoriesCoords.y,
          };
          node.children.forEach((child) => {
            if (isResourceNode(child)) {
              childNodes.push(this.toResourceNode(child, resourcesCoords));
              resourcesCoords.y += CHILD_NODE_HEIGHT + CHILD_NODE_GAP;
              this.nodesHashMap.set(child.id, node);
            }
            if (isResourceMoreNode(child)) {
              // push more node
              let additionalNodes = 0;
              if (node.children?.length) {
                additionalNodes = (node.totalChildren || 0) - node.children.length + 1;
              }
              childNodes.push(this.toResourceMoreNode(child, resourcesCoords, additionalNodes));
              resourcesCoords.y += MORE_NODE_HEIGHT + CHILD_NODE_GAP;
              this.nodesHashMap.set(child.id, node);
            }
          });
          //push outer node
          this.flowNodes.push(
            this.toAccessTargetNodeExpanded(
              node,
              resourcesCoords.y - categoriesCoords.y - RESOURCE_NODE_HEIGHT - 2 * RESOURCE_NODES_GAP,
              categoriesCoords,
            ),
          );
          this.nodesHashMap.set(node.id, node);
          this.flowNodes.push(...childNodes);
          categoriesCoords.y =
            resourcesCoords.y + RESOURCE_NODE_HEIGHT / 2 + RESOURCE_NODES_GAP + NODE_PADDING + NODE_BORDER;
        }
      });
      categoriesCoords.y += INTEGRATION_NODE_GAP;
    });
  }

  private mapUserNodes() {
    const conditionAndNodes = this.nodes.filter((node) =>
      isAndConditionNode(node),
    ) as AccessGraphAttributeConditionAndNode[];
    const conditionOrNodes = this.nodes.filter((node) =>
      isOrConditionNode(node),
    ) as AccessGraphAttributeConditionOrNode[];

    conditionOrNodes.forEach((conditionOrNode) => {
      let integrationName = conditionOrNode.attributeConditionOr?.integration?.name;

      if (!integrationName) {
        // this is local apono connector
        integrationName = 'Apono';
      }
      // Check if an array exists for the current integration name, if not, create one
      let conditionOrNodeArray = this.userNodesMap.get(integrationName);
      if (!conditionOrNodeArray) {
        conditionOrNodeArray = [];
        this.userNodesMap.set(integrationName, conditionOrNodeArray);
        this.userIntegrationsMap.set(integrationName, {
          name: integrationName,
          icon: conditionOrNode.attributeConditionOr?.integration?.icons.svg,
        });
      }
      // Push the current accessTargetNode to the array corresponding to the integration name
      conditionOrNodeArray.push(conditionOrNode);
    });
    this.userNodesMap.set('AND', conditionAndNodes);
  }

  private buildUserNodes() {
    const initX = 100;
    const initY = 20;
    const categoriesCoords = { x: initX, y: initY };

    this.userNodesMap.forEach((integration, key) => {
      // push condition node
      const resourceIntegration = this.userIntegrationsMap.get(key);
      if (resourceIntegration) {
        this.flowNodes.push(this.toIntegrationsNode(resourceIntegration, { x: initX, y: categoriesCoords.y }, true));
        categoriesCoords.y += INTEGRATION_NODE_HEIGHT + INTEGRATION_NODE_GAP;
      }

      integration.forEach((node) => {
        if (node.children == undefined || node.children?.length == 0) {
          if (isAndConditionNode(node)) {
            this.flowNodes.push(this.toConditionAndNode(node, categoriesCoords));
            this.nodesHashMap.set(node.id, node);
            const numConditions = node.attributeConditionAnd?.length || 0;
            categoriesCoords.y +=
              this.nodeHeight(numConditions * AND_NODE_CONDITION_HEIGHT + AND_NODE_CONDITION_HEIGHT) +
              RESOURCE_NODES_GAP;
          } else if (isOrConditionNode(node)) {
            this.flowNodes.push(this.toConditionOrNode(node, categoriesCoords));
            this.nodesHashMap.set(node.id, node);
            categoriesCoords.y += this.nodeHeight(RESOURCE_NODE_HEIGHT) + RESOURCE_NODES_GAP;
          }
        } else if ((node.totalChildren || 0) > 0 && node.children?.length > 0) {
          const childNodes: FlowNode[] = [];

          // moving X coord to the right to place children nodes
          const resourcesCoords = {
            x: GROUPED_NODE_H_PADDING + categoriesCoords.x,
            y: RESOURCE_NODE_HEIGHT + RESOURCE_NODES_GAP + categoriesCoords.y,
          };

          node.children.forEach((child) => {
            if (isUserNode(child)) {
              childNodes.push(this.toUserNode(child, resourcesCoords));
              resourcesCoords.y += CHILD_NODE_HEIGHT + CHILD_NODE_GAP;
              this.nodesHashMap.set(child.id, node);
            }
            if (isUserMoreNode(child)) {
              // push more node
              let additionalNodes = 0;
              if (node.children?.length) {
                additionalNodes = (node.totalChildren || 0) - node.children.length + 1;
              }
              childNodes.push(this.toUserMoreNode(child, resourcesCoords, additionalNodes));
              resourcesCoords.y += MORE_NODE_HEIGHT + CHILD_NODE_GAP;
              this.nodesHashMap.set(child.id, node);
            }
          });
          if (isAndConditionNode(node)) {
            this.flowNodes.push(
              this.toConditionAndNodeExpanded(
                node,
                resourcesCoords.y - categoriesCoords.y - RESOURCE_NODE_HEIGHT - 3 * RESOURCE_NODES_GAP,
                categoriesCoords,
              ),
            );
            this.nodesHashMap.set(node.id, node);
            categoriesCoords.y =
              resourcesCoords.y + RESOURCE_NODE_HEIGHT / 2 + RESOURCE_NODES_GAP + NODE_PADDING + NODE_BORDER;
          } else if (isOrConditionNode(node)) {
            this.flowNodes.push(
              this.toConditionOrNodeExpanded(
                node,
                resourcesCoords.y - categoriesCoords.y - RESOURCE_NODE_HEIGHT - 2 * RESOURCE_NODES_GAP,
                categoriesCoords,
              ),
            );
            this.nodesHashMap.set(node.id, node);
            categoriesCoords.y =
              resourcesCoords.y + RESOURCE_NODE_HEIGHT / 2 + RESOURCE_NODES_GAP + NODE_PADDING + NODE_BORDER;
          }
          this.flowNodes.push(...childNodes);
        }
      });
      categoriesCoords.y += INTEGRATION_NODE_GAP;
    });
  }

  private toFlowEdge(edge: AccessGraphEdge): Edge<EdgeMetaProps> {
    return {
      id: edge.id,
      type: isAvailableAccessEdge(edge) ? 'availableAccess' : 'activeAccess',
      source: edge.sourceId,
      target: edge.targetId,
      data: { edge },
    };
  }

  private toAccessTargetNode(
    node: AccessGraphAccessTargetNode,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'accessTarget',
      data: {
        node,
        childrenHeight: 0,
      },
      position: { ...position },
    };
  }

  private toAccessTargetNodeExpanded(
    node: AccessGraphAccessTargetNode,
    childrenHeight: number,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'accessTargetExpanded',
      data: {
        node,
        childrenHeight,
        hasConnectedEdges: this.hasConnectedEdges(node.id),
      },
      position: { ...position },
    };
  }

  private toIntegrationsNode(
    node: ResourceIntegration,
    position: XYPosition,
    isSource = false,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: `${node.name}-${isSource ? 'source' : 'target'}`,
      type: 'integrationNode',
      data: {
        node,
        childrenHeight: 0,
      },
      position: { ...position },
    };
  }

  private toConditionAndNode(
    node: AccessGraphAttributeConditionAndNode,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'conditionAnd',
      data: {
        node,
        childrenHeight: 0,
      },
      position: { ...position },
    };
  }

  private toConditionAndNodeExpanded(
    node: AccessGraphAttributeConditionAndNode,
    childrenHeight: number,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'conditionAndExpanded',
      data: {
        node,
        childrenHeight,
        hasConnectedEdges: this.hasConnectedEdges(node.id),
      },
      position: { ...position },
    };
  }

  private toConditionOrNode(
    node: AccessGraphAttributeConditionOrNode,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'conditionOr',
      data: {
        node,
        childrenHeight: 0,
      },
      position: { ...position },
    };
  }

  private toConditionOrNodeExpanded(
    node: AccessGraphAttributeConditionOrNode,
    childrenHeight: number,
    position: XYPosition,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'conditionOrExpanded',
      data: {
        node,
        childrenHeight,
        hasConnectedEdges: this.hasConnectedEdges(node.id),
      },
      position: { ...position },
    };
  }

  private toResourceNode(node: AccessGraphResourceNode, position: XYPosition): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'resourceNode',
      data: {
        node,
        childrenHeight: 0,
      },
      position: { ...position },
    };
  }

  private toResourceMoreNode(
    node: AccessGraphResourceMoreNode,
    position: XYPosition,
    additionalNodes: number,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'resourceMoreNode',
      data: {
        node,
        childrenHeight: 0,
        additionalNodes,
      },
      position: { ...position },
    };
  }

  private toUserNode(node: AccessGraphUserNode, position: XYPosition): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'userNode',
      data: {
        node,
        childrenHeight: 0,
      },
      position: { ...position },
    };
  }

  private toUserMoreNode(
    node: AccessGraphUserMoreNode,
    position: XYPosition,
    additionalNodes: number,
  ): Node<NodeMetaProps, NodeTypeTypes> {
    return {
      id: node.id,
      type: 'userMoreNode',
      data: {
        node,
        childrenHeight: 0,
        additionalNodes,
      },
      position: { ...position },
    };
  }

  private nodeHeight(baseHeight: number) {
    return baseHeight + NODE_PADDING * 2 + NODE_BORDER * 2;
  }
}
