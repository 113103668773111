import { ReactNode } from 'react';

import {
  InteractiveDropdown,
  InteractiveDropdownSelect,
  InteractiveDropdownView,
  Popover,
  usePopover,
} from '@components';
import { AttributeOperatorModel } from '@AccessFlows/common/constants';

interface SelectAttributeOperatorProps {
  trigger: ReactNode;
  value?: AttributeOperatorModel;
  onChange: (value: AttributeOperatorModel) => void;
}

export default function FlowFormSelectAttributeOperator({ trigger, value, onChange }: SelectAttributeOperatorProps) {
  const externalState = usePopover();

  const handleChange = (newVal: AttributeOperatorModel) => {
    onChange(newVal);
    externalState.closeDropdown();
  };

  return (
    <Popover testId="flow-form-select-attribute-operator" trigger={trigger} externalState={externalState}>
      <InteractiveDropdown>
        <InteractiveDropdownView current>
          <InteractiveDropdownSelect
            options={[
              {
                key: AttributeOperatorModel.Is,
                value: 'Is',
              },
              {
                key: AttributeOperatorModel.IsNot,
                value: 'Is not',
              },
              {
                key: AttributeOperatorModel.Contains,
                value: 'Contains',
              },
              {
                key: AttributeOperatorModel.DoesNotContain,
                value: 'Does not contain',
              },
              {
                key: AttributeOperatorModel.StartsWith,
                value: 'Starts with',
              },
            ]}
            value={value}
            onChange={handleChange}
          />
        </InteractiveDropdownView>
      </InteractiveDropdown>
    </Popover>
  );
}
