import { Grid, Stack, styled, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ColumnsLayoutSection } from '../ColumnsLayout';
import { ReactNode } from 'react';

export interface ResourceAccessHeaderProps {
  opened?: boolean;
  icon?: ReactNode;
  title?: string;
  subTitle?: string;
  indicator?: ReactNode;
  onClick?: () => void;
}

const IconContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '48px',
  minHeight: '48px',
  backgroundColor: theme.palette.background.active,
  borderRadius: '50%',
}));

export function ResourceAccessHeader({ subTitle, opened, icon, title, indicator, onClick }: ResourceAccessHeaderProps) {
  return (
    <ColumnsLayoutSection onClick={onClick}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <IconContainer item>{icon}</IconContainer>
        <Grid item xs sx={{ px: 1 }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            {opened ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            <Typography variant="buttonMedium" component="strong">
              {title}
            </Typography>
          </Stack>
          {subTitle && (
            <Typography variant="caption" color="text.filter" ml={1}>
              {subTitle}
            </Typography>
          )}
        </Grid>
        <IconContainer sx={{ backgroundColor: 'transparent' }} item>
          {indicator}
        </IconContainer>
      </Grid>
    </ColumnsLayoutSection>
  );
}
