import { Box, styled } from '@mui/material';

const DotElem = styled(Box)(({ theme }) => ({
  width: theme.spacing(1.25),
  height: theme.spacing(1.25),
  borderRadius: '50%',
  background: theme.palette.text.secondary,
  display: 'inline-block',

  '&.small': {
    width: theme.spacing(1),
    height: theme.spacing(1),
  },

  '&.large': {
    width: theme.spacing(1.5),
    height: theme.spacing(2.5),
  },

  '&.primary': {
    background: theme.palette.primary.main,
  },

  '&.secondary': {
    background: theme.palette.secondary.main,
  },

  '&.error': {
    background: theme.palette.error.main,
  },

  '&.info': {
    background: theme.palette.info.main,
  },

  '&.success': {
    background: theme.palette.success.main,
  },

  '&.warning': {
    background: theme.palette.warning.main,
  },
}));

export interface DotProps {
  size?: 'small' | 'large';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export default function Dot({ size, color }: DotProps) {
  const classes = [];

  if (size) classes.push(size);
  if (color) classes.push(color);

  return <DotElem className={classes.join(' ')} />;
}
