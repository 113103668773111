import { ElementType } from 'react';

import { Avatar, Box, Stack, styled, Typography } from '@mui/material';

import { RESOURCE_NODE_HEIGHT, RESOURCE_NODE_WIDTH } from './common';

// We need it to be able to use the styled-components in the tests
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NodeMetaContainer = styled(Stack)(({ theme }) => ({
  height: RESOURCE_NODE_HEIGHT,
}));

export const ResourceNodeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export const IntegrationNodeAvatar = styled(Avatar)(() => ({
  width: '24px',
  height: '24px',
}));

export const ResourceNodeTypography = styled(Typography)<{
  component: ElementType;
}>(() => ({
  lineHeight: '20px',
}));

export const LabelWrap = styled(Box)(() => ({
  maxWidth: RESOURCE_NODE_WIDTH - 40,
}));

export const AndNodeMetaContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  marginRight: '15px',
  width: RESOURCE_NODE_WIDTH,
}));
