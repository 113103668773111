import { AppDrawer, AppDrawerHeader, Loader } from '@components';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TriggerTypeChip from '@common/ui/TriggerTypeChip';
import { routes } from '@routes';
import { DrawerItemCard } from '../components/DrawerItemCard';
import { useListActivityRequests } from '@Activity/Audit/ActivityTable/query';
import { DateTime } from '@utils';
import { TriggerTypeAppModel } from '@api';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function AccessRequestDrawer({
  open,
  onClose,
  accessRequestIds,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  accessRequestIds: string[];
}) {
  return (
    <AppDrawer
      open={open}
      onClose={() => onClose(false)}
      width={800}
      header={<AppDrawerHeader title="Access Requests" onCloseClick={() => onClose(false)} />}
    >
      {accessRequestIds.length > 0 && <AccessRequestDrawerContent accessRequestIds={accessRequestIds} />}
    </AppDrawer>
  );
}

function AccessRequestDrawerContent({ accessRequestIds }: { accessRequestIds: string[] }) {
  const filterAccessRequestId = accessRequestIds.join(',');
  const { requests, isRequestsFetched } = useListActivityRequests(
    {
      filterAccessRequestId,
    },
    0,
  );
  const navigate = useNavigate();

  const handleOnClick = (id: string) => {
    navigate(`${routes.Activity.path}?request_id=${id}`);
  };

  if (!isRequestsFetched || !requests) {
    return <Loader />;
  }

  return (
    <Stack direction="column" spacing={2}>
      {requests.map((req) => (
        <DrawerItemCard
          key={req.id}
          title={req.friendly_id}
          onClick={() => handleOnClick(req.id)}
          icon={<TriggerTypeChip triggerType={req.trigger_type as TriggerTypeAppModel} />}
          actionText="View Access Request"
          subtitle={DateTime.fromUnix(Number(req.created_date)).format('DD/MM/YYYY')}
          data-trigger={CustomAnalyticsEvents.VIEW_ACCESS_REQUEST_CLICKED}
        />
      ))}
    </Stack>
  );
}
