import Handlebars from 'handlebars';
import { IntegrationConfigAppModelV2 } from '@api';
import { ObjectValues } from '@Integrations/organisms/IntegrationConfigForm/Provider';

interface TemplateData {
  resourceName: string;
  name: string;
  type: string;
  connectorId: string;
  params: Record<string, { value: string; optional?: boolean }>;
  requiresSecret: boolean;
  secretType?: string;
  secretInfo?: string;
}

const secretTemplates: Record<string, string> = {
  AWS: `
  aws_secret = {
    region    = "us-east-1"
    secret_id = "arn:aws:secretsmanager:us-east-1:123456789012:secret/prod/postgresql/apono"
  }`,
  GCP: `
  gcp_secret = {
    project    = "project_template_value"
    secret_id = "gcp_secret_id_template_value"
  }`,
  KUBERNETES: `
  kubernetes_secret = {
    name      = "name_template_value"
    namespace = "namespace_template_value"
  }`,
};

export function convertIntegrationConfigToHcl(
  integrationConfig: IntegrationConfigAppModelV2,
  integrationName: string,
  dynamicParams: ObjectValues,
  secretType = 'AWS',
  connectorId = '00000-11111-22222-33333-44444',
): string {
  const sanitizedParams = Object.entries(dynamicParams || {}).reduce<Record<string, string>>((acc, [key, value]) => {
    if (typeof value === 'string') acc[key] = value;
    return acc;
  }, {});

  const templateData: TemplateData = {
    resourceName: integrationConfig.name.toLowerCase().replace(/\s+/g, '_'),
    name: integrationName || integrationConfig.name,
    type: integrationConfig.type,
    connectorId,
    params: Object.fromEntries(
      integrationConfig.params.map((param) => {
        const value =
          sanitizedParams[param.id] && sanitizedParams[param.id] !== ''
            ? sanitizedParams[param.id]
            : param.default || param.placeholder || `${param.id}_template_value`;
        return [param.id, { value, optional: param.optional }];
      }),
    ),
    requiresSecret: integrationConfig.requires_secret,
    secretType,
    secretInfo: integrationConfig.requires_secret ? secretTemplates[secretType] : undefined,
  };

  const template = `
resource "apono_integration" "{{resourceName}}" {
  name = "{{name}}"
  type = "{{type}}"
  connector_id = "{{connectorId}}"
  {{#if params}}
  metadata = {
    {{#each params}}
    {{@key}} = "{{this.value}}"{{#if this.optional}} # optional{{/if}}
    {{/each}}
  }{{/if}}{{#if requiresSecret}}{{{secretInfo}}}{{/if}}
}`.trim();

  const compiledTemplate = Handlebars.compile(template);
  return compiledTemplate(templateData);
}
