import { Box, Stack, styled, Typography } from '@mui/material';
import { ResourceIntegration } from 'AccessGraph/nodes-builder';
import { IntegrationNodeAvatar, LabelWrap } from './node';
import { NodeProps } from 'reactflow';
import { RESOURCE_NODE_WIDTH, RESOURCE_NODES_GAP } from './common';

const IntegrationNodeWrapper = styled(Box)(() => ({
  cursor: 'default',
  width: RESOURCE_NODE_WIDTH,
}));

export type IntegrationNodeProps = { node: ResourceIntegration };

export function IntegrationNode({ data }: NodeProps<IntegrationNodeProps>) {
  const { node } = data;

  return (
    <IntegrationNodeWrapper>
      <Stack direction="column" alignItems="start" spacing={`${RESOURCE_NODES_GAP}px`}>
        <Stack direction="row" alignItems="start" spacing={1}>
          <IntegrationNodeAvatar src={node.icon || '/static/logo-only.svg'} variant="circular" />
          <LabelWrap>
            <Typography variant="caption" component="div" color="text.primary" fontSize={14} fontWeight={500} noWrap>
              {node.name}
            </Typography>
          </LabelWrap>
        </Stack>
      </Stack>
    </IntegrationNodeWrapper>
  );
}
