import { useEffect, useMemo, useRef, useState } from 'react';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { useGetIdentities, useListActivityFilterOptions } from '@api';
import { FilterProps } from './types';

export const GRANTEE_FILTER_LABEL = 'Grantee';
export const GRANTEE_PARAM = 'requestor_id';
export const GRANTEE_FILTER_PARAM = 'filter.requestor_id';

export default function GranteeFilterAsync({ opened, onClose, onOpen, selected, onSelect, onClear }: FilterProps) {
  const [enabled] = useState(() => selected.length > 0);

  const {
    options,
    isOptionsFetched,
    refetchQuery: refetchFilterOptions,
  } = useListActivityFilterOptions(GRANTEE_PARAM, enabled);
  const {
    identities: { identities },
    isIdentitiesFetched,
    refetch: refetchListIdentities,
  } = useGetIdentities(enabled);

  const identityOptions = useMemo(() => {
    return options.map((id) => {
      const identity = identities.find((i) => i.id === id);
      if (identity) {
        return {
          value: id,
          label: `${identity.first_name} ${identity.last_name} (${identity.email})`,
        };
      }

      return { value: id, label: id };
    });
  }, [options, identities]);

  const selectedOptions = useMemo(() => {
    const list: ListFilterOption[] = [];
    for (const option of identityOptions) {
      if (selected.includes(option.value)) {
        list.push(option);
      }
    }
    return list;
  }, [identityOptions, selected]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    !opened && onOpen && onOpen();
    setAnchorEl(event.currentTarget);

    if (!isIdentitiesFetched && !isOptionsFetched) {
      refetchFilterOptions();
      refetchListIdentities();
    }
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isIdentitiesFetched && !isOptionsFetched) {
        refetchFilterOptions();
        refetchListIdentities();
      }
    }
  }, [isIdentitiesFetched, isOptionsFetched, opened, ref, refetchFilterOptions, refetchListIdentities]);

  const isFetching = (!isIdentitiesFetched || !isOptionsFetched) && (opened || selected.length > 0);

  const open = (opened && Boolean(anchorEl)) || false;

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={GRANTEE_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={identityOptions}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${GRANTEE_FILTER_LABEL} Filter`}
            loading={isFetching}
          />
        </Box>
      </Popover>
    </>
  );
}
