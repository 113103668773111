import { ReactNode, useCallback, useMemo, useState } from 'react';

import { AttributeAppModel, AttributeGroupAppModel } from '@api';
import {
  InteractiveDropdown,
  InteractiveDropdownFilterInput,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
  MaterialIcon,
  Popover,
  usePopover,
  usePopoverContext,
} from '@components';
import { useAppListAttributes } from '@AccessFlows/services/attributesQueries';
import { ButtonBase, Chip, Divider, ListItem, Stack, Tooltip } from '@mui/material';
import { AttributeModelStatus } from '@common/constants';
import IdentitiesByAttributeModalPaginated from '@Identities/organisms/IdentitiesByAttributeModalPaginated';
import { CustomAnalyticsEvents } from '@utils/analytics';

interface SelectAttributeValueDropdownViewProps {
  implicit?: boolean;
  attributeType: AttributeGroupAppModel;
  value?: string[] | string;
  onChange: (value: string[], matchingAttributes: AttributeAppModel[]) => void;
}

interface SelectAttributeValueProps extends SelectAttributeValueDropdownViewProps {
  trigger: ReactNode;
  initOpen?: boolean;
  onClose?: () => void;
}

export default function FlowFormSelectAttributeValue({
  trigger,
  initOpen = false,
  onClose,
  ...restProps
}: SelectAttributeValueProps) {
  const externalState = usePopover({
    initOpen,
    onClose,
  });

  return (
    <Popover testId="flow-form-select-attribute-value" trigger={trigger} externalState={externalState}>
      <SelectAttributeValueDropdown {...restProps} />
    </Popover>
  );
}

function SelectAttributeValueDropdown(props: SelectAttributeValueDropdownViewProps) {
  const [selectedAttributeToView, setSelectedAttributeToView] = useState<AttributeAppModel | undefined>();
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();

  const handleViewAttribute = (attribute: AttributeAppModel) => {
    setSelectedAttributeToView(attribute);
    popoverDisableOnClose();
  };

  const handleModalClose = () => {
    setSelectedAttributeToView(undefined);
    popoverEnableOnClose();
  };

  return (
    <>
      <InteractiveDropdown wide>
        <SelectAttributeValueDropdownView {...props} handleViewAttribute={handleViewAttribute} />
      </InteractiveDropdown>
      {selectedAttributeToView && (
        <IdentitiesByAttributeModalPaginated attribute={selectedAttributeToView} onClose={handleModalClose} />
      )}
    </>
  );
}

function SelectAttributeValueDropdownView({
  attributeType,
  handleViewAttribute,
  ...restProps
}: SelectAttributeValueDropdownViewProps & {
  handleViewAttribute: (attribute: AttributeAppModel) => void;
}) {
  const { data, isFetched } = useAppListAttributes({
    attributeType: attributeType.type.type,
    integrationId: attributeType.integration?.id,
    onlyLocal: !attributeType.integration,
    limit: 9999,
  });

  if (!isFetched) {
    return <InteractiveDropdownView current loading header={<InteractiveDropdownHeader title="Select values" />} />;
  }

  const isCanViewAttribute = attributeType.type.type !== 'user';

  const attributeValues = data?.pages.flatMap((page) => page.data) || [];
  return (
    <AttributeValuesList
      attributeValues={attributeValues}
      handleViewAttribute={isCanViewAttribute ? handleViewAttribute : undefined}
      {...restProps}
    />
  );
}

interface AttributeValuesListProps extends Omit<SelectAttributeValueDropdownViewProps, 'attributeType'> {
  attributeValues: AttributeAppModel[];
  handleViewAttribute?: (attribute: AttributeAppModel) => void;
}

function AttributeValuesList({
  attributeValues,
  handleViewAttribute,
  value,
  onChange,
  implicit,
}: AttributeValuesListProps) {
  value = value ? (Array.isArray(value) ? value : [value]) : undefined;

  const [filter, setFilter] = useState('');

  const options: InteractiveDropdownSelectOption[] = useMemo(() => {
    const valuesSet = new Set(value);
    const attributesOptions: InteractiveDropdownSelectOption[] = [];

    for (const av of attributeValues) {
      if (!av.value.toLowerCase().includes(filter.toLowerCase())) {
        continue;
      }

      const rightIcon =
        av.status === AttributeModelStatus.Deleted ? (
          <OptionDeletedWarning attribute={av} />
        ) : handleViewAttribute ? (
          <Tooltip title="View users" placement="top" arrow>
            <ButtonBase
              onClick={(e) => {
                e.stopPropagation();
                handleViewAttribute(av);
              }}
              data-trigger={CustomAnalyticsEvents.VIEW_GROUP_MEMBERS_IN_FLOW_BUILDER}
            >
              <MaterialIcon symbol="visibility" color="primary" />
            </ButtonBase>
          </Tooltip>
        ) : undefined;

      const option: InteractiveDropdownSelectOption = {
        key: av.source_id,
        value: av.value,
        noInput: implicit,
        dense: implicit,
        deselectOnly: av.status === AttributeModelStatus.Deleted,
        rightIcon,
      };

      if (!implicit || (implicit && !valuesSet.has(av.value))) {
        attributesOptions.push(option);
      }
    }

    return attributesOptions;
  }, [value, attributeValues, filter, handleViewAttribute, implicit]);

  const handleOnChange = useCallback(
    (newValues: string[]) => {
      const matchingAttributes = attributeValues.filter((av) => newValues.includes(av.source_id));
      return onChange(newValues, matchingAttributes);
    },
    [attributeValues, onChange],
  );

  const handleAddValue = useCallback(
    (newValue: string) => {
      if (!newValue || newValue.trim() === '') {
        return;
      }

      if (value?.includes(newValue)) return;

      handleOnChange([...(value || []), newValue]);
    },
    [handleOnChange, value],
  );

  const handleInputSubmit = useCallback(() => {
    handleAddValue(filter);
    setFilter('');
  }, [filter, handleAddValue]);

  const handleOnSelect = useCallback(
    (newValue: string) => {
      const selectedOption = options.find((option) => option.key === newValue);
      if (selectedOption) {
        handleAddValue(implicit ? selectedOption.value : selectedOption.key);
      }
    },
    [implicit, handleAddValue, options],
  );

  const deleteValue = useCallback(
    (item: string) => {
      if (!value) return;

      handleOnChange(value.filter((i) => i !== item));
    },
    [handleOnChange, value],
  );

  const header = <InteractiveDropdownHeader title="Select value" />;

  if (implicit) {
    return (
      <InteractiveDropdownView
        current
        header={header}
        filter={
          <InteractiveDropdownFilterInput
            value={filter}
            onChange={setFilter}
            onAddClick={handleInputSubmit}
            placeholder={`Type and select or press "Enter" to add value`}
          />
        }
      >
        {value && value.length > 0 && <ChipsList items={value} onDelete={(i) => deleteValue(i)} />}
        <InteractiveDropdownSelect<string>
          options={options}
          value={undefined}
          onChange={handleOnSelect}
          selectedFirst
        />
      </InteractiveDropdownView>
    );
  }

  return (
    <InteractiveDropdownView
      current
      header={header}
      filter={<InteractiveDropdownFilterInput value={filter} onChange={setFilter} placeholder="Search value" />}
    >
      <InteractiveDropdownSelect<string>
        multiple
        options={options}
        value={value}
        onChange={handleOnChange}
        selectedFirst
      />
    </InteractiveDropdownView>
  );
}

function ChipsList({ items, onDelete }: { items: string[]; onDelete: (item: string) => void }) {
  return (
    <>
      <Divider />
      <ListItem>
        <Stack direction="row" useFlexGap flexWrap="wrap" spacing={0.5}>
          {items.map((item) => (
            <Chip key={item} data-testid="value-list-chip" size="small" label={item} onDelete={() => onDelete(item)} />
          ))}
        </Stack>
      </ListItem>
      <Divider />
    </>
  );
}

function OptionDeletedWarning({ attribute }: { attribute: AttributeAppModel }) {
  if (attribute.status !== AttributeModelStatus.Deleted) return null;

  return (
    <Tooltip title="This attribute is deleted and can be only deselected" placement="top" arrow>
      <div>
        <MaterialIcon symbol="warning" color="warning" />
      </div>
    </Tooltip>
  );
}
