import { FormSection } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSection';
import { IntegrationConfigParamAppModel } from '@api';
import { FormSectionSummary } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionSummary';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { LabeledTextField } from '@components';
import { MetadataInputField } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/ConfigForm';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { Grid } from '@mui/material';

export function IntegrationConfigFormSection({
  params,
  actions,
  expanded,
  isEdit,
  onSummaryClick,
  isViewOnly,
}: {
  params: IntegrationConfigParamAppModel[];
  actions?: ReactNode;
  expanded?: boolean;
  isEdit?: boolean;
  onSummaryClick?: () => void;
  isViewOnly?: boolean;
}) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <FormSection
      expanded={expanded}
      summary={<FormSectionSummary title="Integration Config" onClick={onSummaryClick} withIcon={!isEdit} />}
      actions={expanded && actions}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="name"
            rules={{ required: { value: true, message: 'Name is required' } }}
            render={({ field, fieldState: { invalid, error } }) => (
              <LabeledTextField
                label="Integration Name"
                placeholder="Eg. My Integration"
                error={invalid}
                helperText={error?.message}
                inputProps={field}
                autoFocus
                data-testid="integration-name"
                disabled={isViewOnly}
              />
            )}
          />
        </Grid>
        {params.map((p) => (
          <Grid item xs={6} key={p.id}>
            <Controller
              control={control}
              name={`metadata.${p.id}`}
              render={(props) => <MetadataInputField disabled={isViewOnly} param={p} {...props} />}
              rules={{
                required: {
                  value: !p.optional,
                  message: `${p.label} is required`,
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormSection>
  );
}
