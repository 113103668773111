export interface AlertOption {
  errorName: string;
  level: string;
  details: string;
  troubleshoot: string;
}

export const TYPE_TO_TROUBLESHOOTING: { [key: string]: string } = {
  IntegrationUnreachable:
    '**What can cause this error?**\n' +
    '\n- The integrated service is down\n' +
    '\n- The Integration can be down if the integrated service is down in the environment.\n' +
    '\n- There is a network connectivity issue\n' +
    '\n- The Connector should have network access to the integrated service/resource.\n' +
    '\n**Identifying the issue:**\n' +
    '\n- Validate the Instance is up and running\n' +
    "\n- Validate the Subnet you've specified when integrating\n" +
    "\n- Validate the Security Group you've specified when integrating\n" +
    '\n- Validate network connectivity in GCP or AWS\n' +
    '\n- Validate network connectivity in Kubernetes\n' +
    '\n**Resolving the issue:**\n' +
    '\nWhen the network connectivity issue is resolved, or the integration service is up again, the will Connector will automatically reconnect to the integrated resources.\n' +
    'You can force an update by selecting Refresh.',
  ConnectorUnreachable:
    '**What can cause this error?**' +
    '\n- The Connector is down' +
    '\n- The Connector can be down if it is deleted from the environment.\n' +
    '\n- There is a network connectivity issue\n' +
    '\n- If the Connector is on a network with no outbound communication allowed, it will not be discovered by Apono.\n' +
    '\n**Identifying the issue:**\n' +
    '\n- Checking the Connector in AWS CloudFormation.\n' +
    '\n- Checking the Connector in GCP Deployment Manager.\n' +
    '\n- Checking the Connector Deployment is up.\n' +
    '\n**Resolving the issue:**\n' +
    '\nWhen the Connector is up again, it automatically reconnects Apono.\n' +
    'Refresh the integration to see the updated status.',
  ResourceTypeUnreachable:
    '**What can cause this error?**\n' +
    '\n- The integrated service is down\n' +
    '\n- The Integration can be down if the integrated service is down in the environment.\n' +
    '\n- There is a network connectivity issue\n' +
    '\n- The Connector should have network access to the integrated service/resource.\n' +
    '\n**Identifying the issue:**\n' +
    '\n- Validate the Instance is up and running\n' +
    "\n- Validate the Subnet you've specified when integrating\n" +
    "\n- Validate the Security Group you've specified when integrating\n" +
    '\n- Validate network connectivity in GCP or AWS\n' +
    '\n- Validate network connectivity in Kubernetes\n' +
    '\n**Resolving the issue:**\n' +
    '\nWhen the network connectivity issue is resolved, or the integration service is up again, the will Connector will automatically reconnect to the integrated resources.\n' +
    'You can force an update by selecting Refresh.',
  SecretUnreachable:
    '**What can cause this error?**\n' +
    '\n- The secret does not exist\n' +
    '\n- No secret with the specified name was found. Check for typos\n' +
    '\n- Missing permissions to the secret\n' +
    '\n- The Connector does not have sufficient privileges to access the secret.\n' +
    '\n**Identifying the issue:**\n' +
    "\n- Validate the region as you've specified in the integration.\n" +
    "\n- Validate the secret name as you've specified in the integration.\n" +
    "\n- Validate you've tagged the secret with the tag apono-connector-read: true\n" +
    '\n**Resolving the issue:**\n' +
    '\nWhen the error is resolved, the integration status will be updated automatically.\n' +
    'You can force an update by selecting Refresh.',
  SecretInvalid:
    '**What can cause this error?**\n' +
    '\n- Username field is missing\n' +
    '\n- Password field is missing\n' +
    '\n- Username or password incorrect\n' +
    '\n**Identifying the issue:**\n' +
    '\n- Navigate to your secret in the secret store and validate the.username and password fields exist.\n' +
    '\n- Copy the username and password, try to connect to the integration (based on the integration parameters specified in the Apono Integration page), and try using the secret manually to validate it is correct.\n' +
    '\n**Resolving the issue:**\n' +
    '\nWhen the secret parameters have been corrected, the integration status will be updated automatically.\n' +
    'You can force an update by selecting Refresh.',
  ResourceDiscoveryFailed:
    '**What can cause this error?**' +
    '\n- Missing permissions.\n' +
    '\n**Identifying the issue:**\n' +
    '\n- Validate that you have added the secret has the necessary permissions required by the integration.\n' +
    '\n- Navigate to your secret in the secret store and validate the.username and password fields exist.\n' +
    '\n- Copy the username and password and try to connect to the integration (based on the integration parameters specified in the Apono Integration page) and try using the secret manually to validate it is correct.\n' +
    '\n**Resolving the issue:**\n' +
    '\nWhen the secret parameters have been corrected, the integration status will be updated automatically.\n' +
    'You can force an update by selecting Refresh.',
  FailedProvisioning:
    '**What can cause this error?**' +
    '\n- Missing permissions.\n' +
    '\n**Identifying the issue:**\n' +
    '\n- Validate that you have added the secret has the necessary permissions required by the integration.\n' +
    '\n- Navigate to your secret in the secret store and validate the.username and password fields exist.\n' +
    '\n- Copy the username and password, try to connect to the integration (based on the integration parameters specified in the Apono Integration page), and try using the secret manually to validate it is correct.\n' +
    '\n- Validate that you can grant and revoke access to a user.\n' +
    '\n**Resolving the issue:**\n' +
    '\nFor each failed attempt, you can view the issue in the Activity Page.',
};
