import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';

const DrawerSection = styled(Box)({
  position: 'relative',
  flex: '0 1 0%',
  '&.grow': {
    flex: '1 1 0%',
  },
  '&.left': { alignItems: 'flex-start' },
});

export function SidebarSection({ grow = false, children }: { grow?: boolean; children: ReactNode }) {
  let className = '';
  if (grow) className += ' grow';
  return <DrawerSection className={className}>{children}</DrawerSection>;
}
