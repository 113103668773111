export type InsightType =
  | 'resources_not_requested'
  | 'users_not_requesting'
  | 'permissions_not_requested'
  | 'avg_access_request_duration';
export interface InsightConfig {
  title: string;
  recommendation: string;
  insightBannerHeader: string;
  description: string;
  findingsText: string;
  entity: string;
}

export const INSIGHT_CONFIG_MAP = {
  resources_not_requested: {
    title: 'Unused Resources',
    recommendation: 'Remove from Access Flow',
    insightBannerHeader: 'We suggest removing these resources from the Access Flow',
    description:
      'These resources were not requested in the last 30 Days. We recommend removing them from the access flow.',
    findingsText: 'Not requested',
    entity: 'Resources',
  },
  users_not_requesting: {
    title: 'Inactive Users',
    recommendation: 'Remove from Access Flow',
    insightBannerHeader: 'We suggest removing these users from the Access Flow',
    description: 'These users were not requested in the last 30 Days. We recommend removing them from the access flow.',
    findingsText: 'Not requested',
    entity: 'Users',
  },
  permissions_not_requested: {
    title: 'Unused Permissions',
    recommendation: 'Remove from Access Flow',
    insightBannerHeader: 'We suggest removing these permissions from the Access Flow',
    description:
      'These permissions were not requested in the last 30 Days. We recommend removing them from the access flow.',
    findingsText: 'Not requested',
    entity: 'Permissions',
  },
  avg_access_request_duration: {
    title: 'Excessive Access Duration',
    recommendation: 'Reduce maximum duration',
    insightBannerHeader: 'We suggest reducing maximum duration',
    description: '',
    findingsText: 'Avg. request time',
    entity: '',
  },
};

export type MetricType =
  | 'total_resources_not_requested'
  | 'total_permissions_not_requested'
  | 'total_users_not_requesting'
  | 'avg_access_request_duration'
  | 'total_requests';

export const METRIC_TYPE_LABELS = {
  total_resources_not_requested: 'Unused Resources',
  total_permissions_not_requested: 'Unused Permissions',
  total_users_not_requesting: 'Inactive Users',
  avg_access_request_duration: 'Excessive access duration',
  total_requests: 'Total Requests',
};

export const DURATION_METRIC = 'avg_access_request_duration';

export const METRIC_TYPES = {
  total_resources_not_requested: 'total_resources_not_requested',
  total_permissions_not_requested: 'total_permissions_not_requested',
  total_users_not_requesting: 'total_users_not_requesting',
  avg_access_request_duration: 'avg_access_request_duration',
  total_requests: 'total_requests',
} as const;

export const TOTAL_REQUESTS_METRIC = 'total_requests';
