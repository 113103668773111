import { Box, ListItem, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

const TextContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

export function EmptyText({ children }: { children: ReactNode }) {
  return (
    <ListItem component="div">
      <TextContainer>
        <Typography variant="caption">{children}</Typography>
      </TextContainer>
    </ListItem>
  );
}
