import { AponoUserModel } from '@api';
import AppModal from '@common/ui/AppModal';
import { EmptyState, Loader, SearchBar } from '@components';
import UserBanner from '@Groups/components/UserBanner';
import { useListGroupMembers, useListUsers } from '@Groups/services/queries';
import { Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const GROUP_MODAL_QUERY_PARAM = 'groupModal';

function useViewGroups(groupId: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { groupMembers, isLoading: isGroupMembersLoading } = useListGroupMembers(groupId);
  const { users } = useListUsers();

  useEffect(() => {
    const groupIdForModal = searchParams.get(GROUP_MODAL_QUERY_PARAM) || '';
    if (!groupIdForModal) {
      searchParams.delete(GROUP_MODAL_QUERY_PARAM);
      setSearchParams(searchParams);
    }
  }, [groupId, searchParams, setSearchParams]);

  const handleOnClose = () => {
    searchParams.delete(GROUP_MODAL_QUERY_PARAM);
    setSearchParams(searchParams);
  };

  return { groupId, groupMembers, isGroupMembersLoading, users, handleOnClose };
}

export default function ViewGroupModal({ groupId }: { groupId: string }) {
  const { groupMembers, isGroupMembersLoading, users, handleOnClose } = useViewGroups(groupId);
  const [search, setSearch] = useState('');

  const members = useMemo(() => {
    const membersList: AponoUserModel[] = [];
    groupMembers?.user_ids?.forEach((groupMember) => {
      const user = users?.find((u) => u.id === groupMember);
      if (user) {
        membersList.push(user);
      }
    });
    return membersList;
  }, [groupMembers, users]);

  const filteredUsers = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();
    return members.filter((user) =>
      `${user.first_name} ${user.last_name} ${user.email}`.toLowerCase().includes(lowerCaseSearch),
    );
  }, [members, search]);

  return (
    <AppModal open={!!groupId} onClose={handleOnClose} header="View Group" width={540}>
      <Stack spacing={2}>
        <SearchBar value={search} onChange={setSearch} total={filteredUsers?.length} />
        <Stack height={600} gap={1} sx={{ overflow: 'auto', scrollbarWidth: 'none' }}>
          {isGroupMembersLoading && <Loader />}
          {!isGroupMembersLoading && filteredUsers?.length === 0 && <EmptyState title="No users found" />}
          {filteredUsers.map((user) => (
            <UserBanner
              key={user.id}
              firstName={user.first_name}
              lastName={user.last_name}
              email={user.email}
              role={user.roles.length > 0 ? user.roles[0] : undefined}
            />
          ))}
        </Stack>
      </Stack>
    </AppModal>
  );
}
