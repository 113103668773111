import { ReactNode } from 'react';

import { Loader, MaterialIcon } from '@components';
import { Box, Stack, Typography } from '@mui/material';
import { ActivityAuditEventType, ActivityRequestAppModel, ActivityTimelineEventModel } from '@api';

import { toHumanReadableDuration } from '@utils';
import { useGetActivityRequestTimeline } from './query';
import { TimelineItem, TimelineItemProps } from './TimelineItem';
import { TimelineItemMeta } from './TimelineItemMeta';

const RequestTimelineTitles: Record<ActivityAuditEventType, string> = {
  RequestCreated: 'Request arrived from',
  RequestReviewed: 'Request reviewed',
  RequestRejected: 'Request rejected',
  PendingForApprover: 'Pending approval',
  RejectedByApprover: 'Rejected by approver',
  ApprovedByApprover: 'Approved by approver',
  ApprovedAutomatically: 'Approved automatically',
  PendingForProvisioning: 'Pending for provisioning',
  AccessGranted: 'Access granted',
  AccessGrantFailed: 'Access grant failed',
  PendingForDeprovisioning: 'Pending for deprovisioning',
  DeprovisioningDone: 'Deprovisioning done',
  AccessRevoked: 'Access revoked',
  AccessRevokeFailed: 'Access revoke failed',
  CredentialsRotated: 'Credentials rotated',
  CredentialsRotationFailed: 'Credentials rotation failed',
  RequestPendingMfa: 'Pending MFA',
  RequestApprovedMfa: 'MFA passed successfully',
  RequestPreviouslyApprovedMfa: 'MFA was successfully passed previously',
};

export default function RequestTimeline({ request }: { request: ActivityRequestAppModel }) {
  const { timeline, isFetched } = useGetActivityRequestTimeline(request.id);

  if (!isFetched) {
    return <Loader absolute={false} />;
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch">
      {timeline.map((item, index) => {
        const isLastItem = index === timeline.length - 1;
        const mood = isLastItem ? activityEventMood(item.event_type) : undefined;

        switch (item.event_type) {
          case ActivityAuditEventType.RequestCreated:
            return (
              <RequestCreatedEvent
                key={index}
                date={Number(item.timestamp)}
                request={request}
                mood={mood}
                userName={item?.metadata?.user_name}
              />
            );
          default:
            return (
              <TimelineItem
                key={index}
                date={Number(item.timestamp)}
                title={RequestTimelineTitles[item.event_type]}
                icon={<MaterialIcon symbol={activityEventIcon(item.event_type)} />}
                mood={mood}
              >
                <ActivityEventMetadata metadata={item.metadata} />
              </TimelineItem>
            );
        }
      })}
    </Stack>
  );
}

function RequestCreatedEvent({
  request,
  date,
  mood,
  userName,
}: {
  request: ActivityRequestAppModel;
  date: number;
  mood: TimelineItemProps['mood'];
  userName: string;
}) {
  return (
    <TimelineItem
      date={date}
      title={`${RequestTimelineTitles[ActivityAuditEventType.RequestCreated]} ${userName}`}
      secondTitle="User"
      icon={<MaterialIcon symbol="person" />}
      mood={mood}
    >
      <TimelineItemMeta title="Justification" bordered>
        <Typography variant="inputLabel">{request.justification}</Typography>
      </TimelineItemMeta>
    </TimelineItem>
  );
}

function ActivityEventMetadata({ metadata }: { metadata: ActivityTimelineEventModel['metadata'] }) {
  const items: ReactNode[] = [];

  if (metadata.user_name) {
    items.push(
      <TimelineItemMeta key="approver" title="Approver" icon={<MaterialIcon symbol="person" />}>
        <Typography variant="inputLabel">{metadata.user_name}</Typography>
      </TimelineItemMeta>,
    );
  }

  if (metadata.Duration) {
    items.push(
      <TimelineItemMeta key="duration" title="Duration">
        <Typography variant="inputLabel">{toHumanReadableDuration(Number(metadata.Duration))}</Typography>
      </TimelineItemMeta>,
    );
  }

  if (metadata.Reason) {
    items.push(
      <TimelineItemMeta key="reason" title="Reason" bordered>
        <Typography variant="inputLabel" sx={{ overflowWrap: 'break-word' }}>
          {metadata.Reason}
        </Typography>
      </TimelineItemMeta>,
    );
  }

  if (metadata.Approvers) {
    items.push(
      <TimelineItemMeta key="approver" title="Approver" icon={<MaterialIcon symbol="group" />}>
        <Typography variant="inputLabel">{metadata.Approvers}</Typography>
      </TimelineItemMeta>,
    );
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
      {items.map((item, index) => (
        <Box key={index}>{item}</Box>
      ))}
    </Stack>
  );
}

function activityEventIcon(type: ActivityTimelineEventModel['event_type']) {
  switch (type) {
    case ActivityAuditEventType.RequestCreated:
      return 'person';
    case ActivityAuditEventType.PendingForApprover:
      return 'schedule_send';
    case ActivityAuditEventType.ApprovedAutomatically:
    case ActivityAuditEventType.AccessGranted:
      return 'check';
    case ActivityAuditEventType.RequestRejected:
    case ActivityAuditEventType.RejectedByApprover:
      return 'thumb_down_off';
    case ActivityAuditEventType.AccessGrantFailed:
    case ActivityAuditEventType.AccessRevoked:
      return 'broken_image';
    default:
      return 'timeline';
  }
}

function activityEventMood(type: ActivityTimelineEventModel['event_type']): TimelineItemProps['mood'] {
  switch (type) {
    case ActivityAuditEventType.RequestCreated:
      return 'primary';
    case ActivityAuditEventType.PendingForApprover:
    case ActivityAuditEventType.PendingForProvisioning:
    case ActivityAuditEventType.PendingForDeprovisioning:
      return 'warning';
    case ActivityAuditEventType.ApprovedAutomatically:
    case ActivityAuditEventType.AccessGranted:
      return 'success';
    case ActivityAuditEventType.RequestRejected:
    case ActivityAuditEventType.AccessGrantFailed:
    case ActivityAuditEventType.RejectedByApprover:
    case ActivityAuditEventType.AccessRevoked:
      return 'error';
  }
}
