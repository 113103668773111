import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { SearchBar } from '@components';
import { AnalyticsTriggerElementProps } from '@common/types';

export const SEARCH_QUERY_PARAM = 'search';

export default function SearchBarFilter({
  placeholder = 'Search',
  onSetSearchParams,
  ...analyticsProps
}: {
  placeholder?: string;
  onSetSearchParams?: (searchParams: URLSearchParams) => void;
} & AnalyticsTriggerElementProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useMemo(() => searchParams.get(SEARCH_QUERY_PARAM) || undefined, [searchParams]);

  const handleSearchChange = (value: string) => {
    if (value === search) return;

    const newSearchParams = new URLSearchParams(searchParams);

    if (value === '') newSearchParams.delete(SEARCH_QUERY_PARAM);
    else newSearchParams.set(SEARCH_QUERY_PARAM, value);

    if (onSetSearchParams) {
      onSetSearchParams(newSearchParams);
    } else {
      setSearchParams(newSearchParams);
    }
  };

  return <SearchBar value={search} onChange={handleSearchChange} placeholder={placeholder} {...analyticsProps} />;
}
