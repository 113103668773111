import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Avatar, Badge, Box, Button, Stack, styled, Typography } from '@mui/material';
import { ClientIcons, MaterialIcon } from '@components';
import { AccessFlowAppModelV2, TriggerType } from '@api';
import { Link } from 'react-router-dom';
import LoadingSentences from '@common/ui/LoadingSentences';
import SuccessIllustration from '@components/SuccessIllustration';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { useListActivityRequests } from '../../../Activity/Audit/ActivityTable/query';
import { AccessStatusModel } from '@common/constants';

const REFETCH_INTERVAL = 2000;

const FlowFormSummaryContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(80),
  textAlign: 'center',
  margin: '0 auto',
}));

interface FlowFormSummaryProps {
  accessFlow: Pick<AccessFlowAppModelV2, 'id' | 'name' | 'trigger_type'>;
  donePath: string;
  doneButtonLabel?: string;
}

export function FlowFormSummary({ accessFlow, donePath, doneButtonLabel = 'Done' }: FlowFormSummaryProps) {
  const doneButton = (
    <Button variant="contained" component={Link} to={donePath}>
      {doneButtonLabel}
    </Button>
  );
  switch (accessFlow.trigger_type) {
    case TriggerType.AutoGrant:
      return <AutoGrantSummary accessFlow={accessFlow} />;
    case TriggerType.UserRequest:
      return <UserRequestSummary accessFlowName={accessFlow.name}>{doneButton}</UserRequestSummary>;
    default:
      return (
        <FlowFormSummaryContainer>
          <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
            <Typography variant="body3bold" component="p">
              All set
            </Typography>
            {doneButton}
          </Stack>
        </FlowFormSummaryContainer>
      );
  }
}

export function AutoGrantSummary({ accessFlow }: { accessFlow: Pick<AccessFlowAppModelV2, 'id' | 'name'> }) {
  const [delayPassed, setDelayPassed] = useState(false);
  const { track } = useAnalyticsContext();

  const { requests } = useListActivityRequests({ filterAccessFlowId: accessFlow.id }, REFETCH_INTERVAL);

  const isOneRequestGranted = useMemo(
    () =>
      requests.some(
        (request) => request.status === AccessStatusModel.Active || request.status === AccessStatusModel.GRANTED,
      ),
    [requests],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayPassed(true);
    }, 2000); // 2 seconds

    return () => clearTimeout(timer);
  }, []);

  const isLoading = !isOneRequestGranted && !delayPassed;

  useEffect(() => {
    if (!isLoading) track(CustomAnalyticsEvents.AUTO_GRANT_SUCCEED);
  }, [isLoading, track]);

  return (
    <FlowFormSummaryContainer>
      <Stack direction="column" justifyContent="center" spacing={2} alignItems="center">
        {isLoading ? (
          <LoadingSentences sentences={['Gathering users', 'Granting access', 'Almost there', 'Hang in there']} />
        ) : (
          <SuccessIllustration />
        )}
        <Typography variant="h5">All set! The Auto Grant workflow is now enabled</Typography>
        <Typography variant="subtitle1" color="primary">
          {accessFlow.name}
        </Typography>
        <Typography variant="body1" color="text.muted">
          {isLoading
            ? 'The access grant is in progress. This may take several minutes.'
            : 'Go to the Activity page to see all granted access.'}
        </Typography>
        <Button
          variant="contained"
          component={Link}
          disabled={requests.length === 0}
          data-trigger={CustomAnalyticsEvents.VIEW_IN_ACTIVITY_CLICKED}
          to={`/activity?filter.trigger_type=${TriggerType.AutoGrant}&filter.access_flow_id=${accessFlow.id}`}
        >
          View In Activity Page
        </Button>
      </Stack>
    </FlowFormSummaryContainer>
  );
}

export function UserRequestSummary({
  accessFlowName,
  children,
}: PropsWithChildren<{
  accessFlowName: string;
}>) {
  return (
    <FlowFormSummaryContainer>
      <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <TopIcon symbol="mail_lock" />
        <Typography variant="body3bold" component="p">
          All set! The Access Flow is now enabled.
        </Typography>
        <Typography
          variant="body3bold"
          sx={{ inlineSize: '100%', overflowWrap: 'break-word' }}
          justifyContent="center"
          color="primary"
          component="p"
        >
          {accessFlowName}
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Now you can create an access request in our web portal. app.
        </Typography>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Typography variant="body2" component="p">
            Psst: We also support
          </Typography>
          <ClientIcons />
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          {children}
        </Stack>
      </Stack>
    </FlowFormSummaryContainer>
  );
}

function TopIcon({ symbol }: { symbol: string }) {
  return (
    <Box>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        badgeContent={<MaterialIcon symbol="magic_button" color="success" />}
      >
        <Avatar sx={{ width: 32, height: 32 }} variant="rounded">
          <MaterialIcon symbol={symbol} />
        </Avatar>
      </Badge>
    </Box>
  );
}
