import { AccessUnitStatus, ActivityRequestAppModel } from '@api';
import { EmptyState, Loader, MaterialIcon } from '@components';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import IntegrationList from './IntegrationsList';
import { useGetActivityRequestAccessUnitGroups } from './query';

export default function RequestAccessUnits({
  request,
  statuses,
  withEmptyState = true,
  withLoader = true,
  defaultExpanded = false,
}: {
  request: ActivityRequestAppModel;
  statuses: string[];
  withEmptyState?: boolean;
  withLoader?: boolean;
  defaultExpanded?: boolean;
}) {
  const { data, isFetched, hasNextPage, fetchNextPage } = useGetActivityRequestAccessUnitGroups({
    id: request.id,
    status: statuses,
  });

  const accessUnitGroups = data?.pages.flatMap((page) => page.data) ?? [];

  if (!isFetched && withLoader) return <Loader absolute={false} />;

  if (accessUnitGroups.length === 0 && withEmptyState)
    return (
      <EmptyState
        imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
        title="No results found"
        body="Try different search terms or remove filters"
      />
    );

  return (
    <>
      {accessUnitGroups.length > 0 && statuses.includes(AccessUnitStatus.FailedRevoking) && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{ color: 'error.main' }}
          icon={<MaterialIcon symbol="warning" />}
        >
          <AlertTitle>Failed to revoke</AlertTitle>
          Warning! access to some resources failed to be revoked, indicating that the user may still have active access
          privileges; please review the errors and take immediate action.
        </Alert>
      )}
      {accessUnitGroups.length > 0 && statuses.includes(AccessUnitStatus.FailedGranting) && (
        <Alert
          severity="warning"
          variant="outlined"
          sx={{ color: 'warning.main' }}
          icon={<MaterialIcon symbol="warning" />}
        >
          <AlertTitle>Failed to grant</AlertTitle>
          Access to some resources failed to be granted
        </Alert>
      )}
      {accessUnitGroups.map((group) => (
        <IntegrationList
          requestId={request.id}
          key={group.integration.id}
          status={statuses}
          integration={group.integration}
          resourceTypes={group.resource_types}
          defaultExpanded={defaultExpanded}
        />
      ))}
      {hasNextPage && (
        <Stack direction="row" justifyContent="center">
          <Button onClick={() => fetchNextPage({ pageParam: accessUnitGroups.length })}>Load More</Button>
        </Stack>
      )}
    </>
  );
}
