import { useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import type { Serie } from '@nivo/line';
import { ResponsiveLine } from '@nivo/line';
import { ChartLegends, Loader, WidgetCard, WidgetCardEmpty } from '@components';
import { AccessOverTimeDataPoint } from '@api';
import { DateTime } from '@utils';
import type { Theme } from '@nivo/core';

const ResponsiveBarWrapper = styled(Box)({
  height: 200,
});

export interface ActiveAccessOverTimeCardProps {
  isLoading?: boolean;
  isFetched?: boolean;
  dataPoints: AccessOverTimeDataPoint[];
}

function useColors() {
  const theme = useTheme();

  const colors: { [key: string]: string } = {
    total_revoked: theme.palette.graph.warning,
    total_granted: theme.palette.graph.primary,
    active_access: theme.palette.graph.success,
  };

  return colors;
}

function useGraphTheme(): Theme {
  const theme = useTheme();

  return {
    grid: {
      line: {
        stroke: theme.palette.divider,
      },
    },
    axis: {
      ticks: {
        text: {
          stroke: theme.palette.text.primary,
          fontFamily: theme.typography.fontFamily,
          fontWeight: 200,
        },
      },
    },
  };
}

function useActiveAccessOverTimeCard(dataPoints: ActiveAccessOverTimeCardProps['dataPoints']) {
  const colors = useColors();

  const serie: Serie[] = useMemo(() => {
    let min = 0;
    let max = 0;

    const data = dataPoints
      .sort((a, b) => (a.date > b.date ? 1 : -1))
      .map(({ active_access, date }) => {
        if (active_access < min) {
          min = active_access;
        }
        if (active_access > max) {
          max = active_access;
        }

        return {
          x: DateTime.shortDate(Number(date)),
          y: active_access,
        };
      });

    return [{ id: 'active_access', color: colors.active_access, data }];
  }, [dataPoints, colors]);

  const legends = [
    {
      color: colors.active_access,
      name: 'Active Access',
    },
  ];

  return {
    serie,
    colors,
    legends,
  };
}

export function ActiveAccessOverTimeCard({
  isLoading = false,
  isFetched = false,
  dataPoints,
}: ActiveAccessOverTimeCardProps) {
  const { serie, colors, legends } = useActiveAccessOverTimeCard(dataPoints);
  const theme = useGraphTheme();

  const title = 'Active Access Over Time';

  if (!isFetched) {
    return (
      <WidgetCard title={title}>
        <Loader absolute={false} />
      </WidgetCard>
    );
  }

  if (serie.length === 0) {
    return (
      <WidgetCard title={title}>
        <WidgetCardEmpty />
      </WidgetCard>
    );
  }

  return (
    <WidgetCard title={title} titleActions={<ChartLegends legends={legends} />} loading={isLoading}>
      <ResponsiveBarWrapper>
        <ResponsiveLine
          data={serie}
          colors={(b) => colors[b.id]}
          curve="natural"
          margin={{
            top: 25,
            right: 25,
            bottom: 25,
            left: 25,
          }}
          enableArea
          enableGridX
          enableGridY={false}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
          }}
          isInteractive={false}
          theme={theme}
        />
      </ResponsiveBarWrapper>
    </WidgetCard>
  );
}
