import { AccessTargetNode, AccessTargetNodeExpanded } from './AccessGraphAccessTargetNode';
import { AttributeConditionAndNode, AttributeConditionAndNodeExpanded } from './AccessGraphAttributeConditionAndNode';
import { AttributeConditionOrNode, AttributeConditionOrNodeExpanded } from './AccessGraphAttributeConditionOrNode';
import { ResourceMoreNode, UserMoreNode } from './MoreNode';
import { UserNode, ResourceNode } from './ChildNode';
import { IntegrationNode } from './IntegrationNode';
import { AvailableAccessEdge } from '../edge/AvailableAccessEdge';
import { ActiveAccessEdge } from '../edge/ActiveAccessEdge';
import { aponoColors } from '@utils';

export const AVAILABLE_ACCESS_COLOR = aponoColors.neutral[600];
export const ACTIVE_ACCESS_COLOR = aponoColors.success[500];
export const ADMIN_ACCESS_COLOR = aponoColors.error[500];

export const NodeTypes = {
  accessTarget: AccessTargetNode,
  accessTargetExpanded: AccessTargetNodeExpanded,
  conditionOr: AttributeConditionOrNode,
  conditionOrExpanded: AttributeConditionOrNodeExpanded,
  conditionAnd: AttributeConditionAndNode,
  conditionAndExpanded: AttributeConditionAndNodeExpanded,
  resourceMoreNode: ResourceMoreNode,
  resourceNode: ResourceNode,
  userMoreNode: UserMoreNode,
  userNode: UserNode,
  integrationNode: IntegrationNode,
} as const;

export const EdgeTypes = {
  availableAccess: AvailableAccessEdge,
  activeAccess: ActiveAccessEdge,
};
