import { FlowNode } from '@AccessGraph/nodes-builder';
import { AccessGraphActiveAccessEdge } from '@api/gql/graphql';
import { Box, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { aponoColors, DateTime, Duration } from '@utils';
import { EdgeHoverContext } from '@AccessGraph/AccessGraphMap';
import { ACTIVE_ACCESS_COLOR, AVAILABLE_ACCESS_COLOR, ADMIN_ACCESS_COLOR } from '../node/node_and_edge_types';
import { MaterialIcon } from '@components';

export type ActiveAccessEdgeProps = {
  edge: AccessGraphActiveAccessEdge;
  source: FlowNode;
  target: FlowNode;
};

export interface EdgeMetaProps {
  edge: AccessGraphActiveAccessEdge;
}

const EdgeContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
}));

const EdgeLabel = styled(Box)(({ theme }) => ({
  cursor: 'default',
  border: `1px solid ${theme.palette.border.default}`,
  backgroundColor: theme.palette.background.active,
  color: theme.palette.border.default,
  borderRadius: '20px',
  alignContent: 'center',
  padding: `8px`,
  minWidth: '50px',
  maxWidth: 'max-content',
  height: '40px',
  pointerEvents: 'all',
  fontSize: '14px',
  textAlign: 'center',
  justifyContent: 'center',

  '&.duration': {
    border: `1px solid ${ACTIVE_ACCESS_COLOR}`,
    color: ACTIVE_ACCESS_COLOR,
  },

  '&.admin': {
    border: `1px solid ${ADMIN_ACCESS_COLOR}`,
    color: ADMIN_ACCESS_COLOR,
  },
}));

export function ActiveAccessEdge({
  id,
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: EdgeProps<ActiveAccessEdgeProps>) {
  const hoveredEdge = useContext(EdgeHoverContext);

  const visibleEdgeLabel = hoveredEdge && hoveredEdge.id === id;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  if (!data) return null;
  const { edge } = data;

  let label1 = '';
  let label2 = '';
  if (edge.activeAccess) {
    label1 =
      edge.activeAccess.permissions.length > 1
        ? `${edge.activeAccess.permissions.slice(0, 1).join(', ')} +(${edge.activeAccess.permissions.length - 1} more)`
        : edge.activeAccess.permissions.join(', ');
    const timestamp = Math.max(...edge.activeAccess.durations);
    const expiryDateTime = DateTime.fromUnix(timestamp);
    const currentDateTime = DateTime.now();
    if (expiryDateTime.unix > currentDateTime.unix) {
      const timeLeftDuration = Duration.fromSeconds(expiryDateTime.unix - currentDateTime.unix);
      label2 = timeLeftDuration.readable;
    }
  }

  const adminAccess = edge.activeAccess?.permissions.includes('ADMIN');

  const defaultEdgeStyle = adminAccess
    ? { strokeWidth: '1px', stroke: ADMIN_ACCESS_COLOR }
    : { strokeWidth: '1px', stroke: ACTIVE_ACCESS_COLOR };

  const edgeStyle = visibleEdgeLabel ? { strokeWidth: '1px', stroke: aponoColors.primary[400] } : defaultEdgeStyle;

  return (
    <>
      <BaseEdge path={edgePath} style={edgeStyle} />
      <EdgeLabelRenderer>
        {visibleEdgeLabel && (
          <EdgeContainer
            style={{
              transform: `translate(${labelX - (label2.length > 0 ? 100 : 0)}px,${labelY}px)`,
            }}
          >
            <EdgeLabel className={adminAccess ? 'admin' : ''}>
              <Typography variant="caption" color={adminAccess ? ADMIN_ACCESS_COLOR : AVAILABLE_ACCESS_COLOR}>
                {label1}
              </Typography>
            </EdgeLabel>
            {label2.length ? (
              <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <EdgeLabel
                  className="duration"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                >
                  <MaterialIcon symbol="schedule" />
                  <Typography variant="caption" color={ACTIVE_ACCESS_COLOR} noWrap>
                    {label2}
                  </Typography>
                </EdgeLabel>
              </div>
            ) : null}
          </EdgeContainer>
        )}
      </EdgeLabelRenderer>
    </>
  );
}
