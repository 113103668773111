import { useListIntegrationsV2 } from '@api';
import { LabeledSelectField } from '@components';

interface WebhookIntegrationsFieldProps {
  integrationType: string;
  value?: string;
  onChange: (value: string) => void;
}

export default function WebhookIntegrationsField({ integrationType, value, onChange }: WebhookIntegrationsFieldProps) {
  const { integrations, isIntegrationsFetched } = useListIntegrationsV2();

  const options = integrations.filter((i) => i.type === integrationType).map((m) => ({ key: m.id, value: m.name }));

  const handleOnChange = (newVal: string) => onChange(newVal);

  return (
    <LabeledSelectField
      label="Integration"
      loading={!isIntegrationsFetched}
      displayEmpty
      options={options}
      value={value}
      onChange={(e) => handleOnChange(String(e.target.value))}
      inputProps={{
        id: 'integration',
      }}
    />
  );
}
