import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';

import { LabeledTextField, Markdown } from '@components';

import { IntegrationConfigFormPayload } from '../../Provider';

const INFO = [
  'Apono doesn&apos;t store credentials and uses the GCP secret manager. Please specify where the secret ID (used to connect to the DB) is located.',
  'For a detailed explanation see the [docs](https://docs.apono.io/docs/creating-secrets-in-google-secret-manager).',
].join('\n\n');

export function GcpSecretForm({ required, isViewOnly }: { required: boolean; isViewOnly?: boolean }) {
  const { control } = useFormContext<IntegrationConfigFormPayload>();

  return (
    <>
      <Typography variant="inputLabel" component="div" gutterBottom>
        <Markdown>{INFO}</Markdown>
      </Typography>
      <Controller
        control={control}
        name="secret_config.project"
        rules={{
          required: {
            value: required,
            message: `Project is required`,
          },
        }}
        render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
          return (
            <LabeledTextField
              label="Project"
              placeholder="Enter project name"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              disabled={isViewOnly}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="secret_config.secret_id"
        rules={{
          required: {
            value: required,
            message: `Secret ID is required`,
          },
        }}
        render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
          return (
            <LabeledTextField
              label="Secret ID"
              placeholder="Enter secret ID"
              error={invalid}
              helperText={error?.message}
              optional={!required}
              disabled={isViewOnly}
              {...{ onChange, onBlur, value, name }}
            />
          );
        }}
      />
    </>
  );
}
