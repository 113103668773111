import { AlertsQueryKeys } from '@AccessAlerts/services/queryKeys';
import { FrontierService } from '@api';
import {
  alertIntegrationOptionsQuery,
  alertResourceTypeOptionsQuery,
  alertSeverityOptionsQuery,
  alertTypesOptionsQuery,
  alertUserOptionsQuery,
} from '@AccessAlerts/services/gql';
import { useQuery } from '@tanstack/react-query';

export function useAlertTypeFilterOptions(enabled?: boolean) {
  return useQuery([AlertsQueryKeys.AlertTypesOptions], () => FrontierService.request(alertTypesOptionsQuery, {}), {
    enabled,
  });
}

export function useAlertSeverityFilterOptions(enabled?: boolean) {
  return useQuery(
    [AlertsQueryKeys.AlertSeverityOptions],
    () => FrontierService.request(alertSeverityOptionsQuery, {}),
    {
      enabled,
    },
  );
}

export function useAlertUserFilterOptions(enabled?: boolean) {
  return useQuery([AlertsQueryKeys.AlertUserOptions], () => FrontierService.request(alertUserOptionsQuery, {}), {
    enabled,
  });
}

export function useAlertResourceTypeFilterOptions(enabled?: boolean) {
  return useQuery(
    [AlertsQueryKeys.AlertResourceTypeOptions],
    () => FrontierService.request(alertResourceTypeOptionsQuery, {}),
    {
      enabled,
    },
  );
}

export function useAlertIntegrationFilterOptions(enabled?: boolean) {
  return useQuery(
    [AlertsQueryKeys.AlertIntegrationOptions],
    () => FrontierService.request(alertIntegrationOptionsQuery, {}),
    {
      enabled,
    },
  );
}
