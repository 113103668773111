import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFlagFixed, useGlobalNotifier } from '@hooks';
import { Flag } from '@utils';
import {
  AccessFlowUpsertAppModel,
  AccessFlowUpsertAppModelV2,
  AccessTargetType,
  ApproverAppModel,
  ApproverPolicyAppModel,
  GranteeFilterGroupAppModel,
  GranteeModel,
  LabelAppModel,
  Timeframe,
  TriggerType,
} from '@api';
import { FlowFormTargetType } from '@AccessFlows/organisms/FlowForm';
import { isEmpty, objectDiff, yup } from '@libs';
import { schema, schemaV2 } from '@AccessFlows/organisms/AccessFlowForm/validation';
import { AccessFlowFormData, AccessFlowFormProps } from '@AccessFlows/common/types';
import { WITH_BUNDLE_QUERY_PARAM } from '@AccessFlows/common/constants';
import { toApproverPolicyUpsertAppModel, toGranteeFilterGroupUpsertAppModel } from '@AccessFlows/common/helpers';

export function useBundleIdFromUrl() {
  const [searchParams, setSearchParams] = useSearchParams();
  const bundleId = searchParams.get(WITH_BUNDLE_QUERY_PARAM);

  useEffect(() => {
    if (bundleId) {
      searchParams.delete(WITH_BUNDLE_QUERY_PARAM);
      setSearchParams(searchParams);
    }
  }, [bundleId, searchParams, setSearchParams]);

  return bundleId;
}

export function useAccessFlowForm({ formData, accessFlowId, onChange, onSubmit, onSubmitV2 }: AccessFlowFormProps) {
  const { notifyServerError } = useGlobalNotifier();
  const { isEnabled: isBundlesOn } = useFlagFixed(Flag.UI_BUNDLES);
  const isApproverJustification = useFlagFixed(Flag.APPROVER_JUSTIFICATION);
  const isApproverCannotApproveHimself = useFlagFixed(Flag.REQUESTER_CANNOT_APPROVE);
  const { isEnabled: isAdvancedApproversEnabled } = useFlagFixed(Flag.ADVANCED_APPROVER);
  const { isEnabled: isRequiredMfaEnabled } = useFlagFixed(Flag.REQUEST_MFA);

  const [formModel, setFormModel] = useState<AccessFlowFormData>(formData);
  const [autoGrantFormModel, setAutoGrantFormModel] = useState<AccessFlowUpsertAppModel | undefined>();
  const [autoGrantFormModelV2, setAutoGrantFormModelV2] = useState<AccessFlowUpsertAppModelV2 | undefined>();

  const [name, setName] = useState<string>(formData.name);
  const [selectedActive, setSelectedActive] = useState<boolean>(formData.active);
  const [selectedTriggerType, setSelectedTriggerType] = useState<TriggerType>(formData.trigger_type);
  const [selectedGrantees, setSelectedGrantees] = useState<GranteeModel[] | undefined>(formData.grantees);
  const [selectedGranteesV2, setSelectedGranteesV2] = useState<GranteeFilterGroupAppModel | undefined>(
    formData.granteesV2,
  );
  const [selectedApproverPolicy, setSelectedApproverPolicy] = useState<ApproverPolicyAppModel | undefined>(
    formData.approver_policy,
  );
  const bundleIdFromUrl = useBundleIdFromUrl();
  const [accessTargets, setAccessTargets] = useState<FlowFormTargetType[] | undefined>(() => {
    if (!bundleIdFromUrl) return formData.targets;

    const targets = formData.targets || [];

    if (!targets.find((target) => target.bundle?.bundle_id === bundleIdFromUrl)) {
      targets.push({ target_type: AccessTargetType.Bundle, bundle: { bundle_id: bundleIdFromUrl } });
    }

    return targets;
  });

  const [selectedApprovers, setSelectedApprovers] = useState<ApproverAppModel[] | undefined>(formData.approvers);
  const [selectedDuration, setSelectedDuration] = useState<number>(formData.revoke_after_in_sec); // default 1 hour
  const [selectedJustification, setSelectedJustification] = useState<boolean>(
    formData.justification_required !== undefined ? formData.justification_required : true,
  );
  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe | undefined>(formData.timeframe || undefined);
  const [validationError, setValidationError] = useState<yup.ValidationError | undefined>();
  const [enforceAllApprovers, setEnforceAllApprovers] = useState<boolean>(formData.require_all_approvers || false);
  const [requireApproverJustification, setRequireApproverJustification] = useState<boolean>(
    formData.settings?.require_approver_justification || false,
  );
  const [approverCannotApproveHimself, setApproverCannotApproveHimself] = useState<boolean>(
    formData.settings?.approver_cannot_approve_himself || false,
  );
  const [labels, setLabels] = useState<LabelAppModel[]>(formData.labels || []);
  const [requiredMfa, setRequiredMfa] = useState<boolean>(formData?.settings?.required_mfa || false);

  useEffect(() => {
    const newFormData: AccessFlowFormData = {
      name,
      active: selectedActive,
      trigger_type: selectedTriggerType,
      grantees: selectedGrantees,
      granteesV2: selectedGranteesV2,
      targets: accessTargets,
      approvers: selectedApprovers,
      approver_policy: selectedApproverPolicy,
      revoke_after_in_sec: selectedDuration,
      justification_required: selectedJustification,
      require_all_approvers: isAdvancedApproversEnabled ? false : enforceAllApprovers,
      timeframe: selectedTimeframe,
      settings: {
        require_approver_justification: requireApproverJustification,
        approver_cannot_approve_himself: approverCannotApproveHimself,
        required_mfa: requiredMfa,
      },
      labels,
    };

    setFormModel(newFormData);
    setValidationError(undefined);

    if (onChange && !isEmpty(objectDiff(formModel, newFormData))) onChange(newFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    selectedActive,
    selectedTriggerType,
    selectedGrantees,
    selectedGranteesV2,
    accessTargets,
    selectedApprovers,
    selectedApproverPolicy,
    selectedDuration,
    selectedJustification,
    selectedTimeframe,
    enforceAllApprovers,
    approverCannotApproveHimself,
    requireApproverJustification,
    labels,
    requiredMfa,
  ]);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    try {
      const validatedValue = schema.validateSync(formModel, {
        abortEarly: false,
      });

      if (!isApproverJustification.isEnabled) delete validatedValue.settings?.require_approver_justification;
      if (!isApproverCannotApproveHimself.isEnabled) delete validatedValue.settings?.approver_cannot_approve_himself;

      if (validatedValue.trigger_type === TriggerType.AutoGrant) {
        validatedValue.revoke_after_in_sec = -1;
        validatedValue.justification_required = false;
      }

      if (!accessFlowId && validatedValue.trigger_type === TriggerType.AutoGrant) {
        setAutoGrantFormModel(validatedValue);
      } else {
        onSubmit(validatedValue);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationError(error.inner.length > 0 ? error.inner[0] : error);
      } else {
        notifyServerError(error, 'Something wrong with form');
      }
    }
  }

  function handleSubmitV2(e: React.FormEvent) {
    e.preventDefault();

    try {
      const validatedValueV2 = schemaV2.validateSync(formModel, {
        abortEarly: false,
      });

      const { settings, granteesV2, grantees, ...rest } = validatedValueV2;

      const upsertModel: AccessFlowUpsertAppModelV2 = {
        ...rest,
        approver_policy: selectedApproverPolicy && toApproverPolicyUpsertAppModel(selectedApproverPolicy),
        grantees: toGranteeFilterGroupUpsertAppModel(granteesV2),
        settings: {},
      };

      if (upsertModel.settings) {
        if (isApproverJustification.isEnabled && upsertModel.settings) {
          upsertModel.settings.require_approver_justification = settings?.require_approver_justification;
        }
        if (isApproverCannotApproveHimself.isEnabled) {
          upsertModel.settings.approver_cannot_approve_himself = settings?.approver_cannot_approve_himself;
        }
        if (isRequiredMfaEnabled) {
          upsertModel.settings.required_mfa = settings?.required_mfa;
        }
      }
      if (isAdvancedApproversEnabled) upsertModel.approvers = [];
      else upsertModel.approver_policy = undefined;

      if (validatedValueV2.trigger_type === TriggerType.AutoGrant) {
        upsertModel.revoke_after_in_sec = -1;
        upsertModel.justification_required = false;
      }

      if (!accessFlowId && validatedValueV2.trigger_type === TriggerType.AutoGrant) {
        setAutoGrantFormModelV2(upsertModel);
      } else {
        onSubmitV2(upsertModel);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationError(error.inner.length > 0 ? error.inner[0] : error);
      } else {
        notifyServerError(error, 'Something wrong with form');
      }
    }
  }

  const getValidationError = (path: keyof AccessFlowFormData) => {
    if (!validationError || !validationError.path) return undefined;
    return validationError.path.startsWith(path) ? validationError : undefined;
  };

  const handleAutoGrantModal = (approved = false) => {
    if (approved && autoGrantFormModel) {
      onSubmit(autoGrantFormModel);
    }
    setAutoGrantFormModel(undefined);
  };

  const handleAutoGrantModalV2 = (approved = false) => {
    if (approved && autoGrantFormModelV2) {
      onSubmitV2(autoGrantFormModelV2);
    }
    setAutoGrantFormModelV2(undefined);
  };

  return {
    name,
    setName,
    selectedTriggerType,
    setSelectedTriggerType,
    selectedActive,
    setSelectedActive,
    selectedGrantees,
    setSelectedGrantees,
    selectedGranteesV2,
    setSelectedGranteesV2,
    accessTargets,
    setAccessTargets,
    selectedApprovers,
    setSelectedApprovers,
    selectedApproverPolicy,
    setSelectedApproverPolicy,
    selectedDuration,
    setSelectedDuration,
    selectedJustification,
    setSelectedJustification,
    selectedTimeframe,
    setSelectedTimeframe,
    getValidationError,
    handleSubmit,
    handleSubmitV2,
    noBundles: !isBundlesOn,
    enforceAllApprovers,
    setEnforceAllApprovers,
    requireApproverJustification,
    setRequireApproverJustification,
    isApproverJustification,
    isApproverCannotApproveHimself,
    approverCannotApproveHimself,
    setApproverCannotApproveHimself,
    labels,
    setLabels,
    requiredMfa,
    setRequiredMfa,
    autoGrantFormModel,
    handleAutoGrantModal,
    autoGrantFormModelV2,
    handleAutoGrantModalV2,
  };
}
