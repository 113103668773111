import { Handle, NodeProps, Position } from 'reactflow';

import { Box, styled } from '@mui/material';
import { NodeMeta, NodeMetaProps } from './common/NodeMeta';
import { RESOURCE_NODE_WIDTH } from './common/common';

const ResourceNodeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.active,
  width: RESOURCE_NODE_WIDTH,
  position: 'relative',
  cursor: 'pointer',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: `1px solid transparent`,
  },

  '&:hover': {
    '&:before': {
      borderColor: theme.palette.warning.light,
    },
  },

  '.selected &': {
    cursor: 'default',

    '&:before': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export function ResourceNodeInput({ data }: NodeProps<NodeMetaProps>) {
  return (
    <ResourceNodeWrapper>
      <NodeMeta {...data} />
      <Handle type="source" position={Position.Right} />
    </ResourceNodeWrapper>
  );
}

export function ResourceNodeOutput({ data }: NodeProps<NodeMetaProps>) {
  return (
    <ResourceNodeWrapper>
      <Handle type="target" position={Position.Left} />
      <NodeMeta {...data} />
    </ResourceNodeWrapper>
  );
}

export function ResourceNode({ data }: NodeProps<NodeMetaProps>) {
  return (
    <ResourceNodeWrapper>
      <Handle type="target" position={Position.Left} />
      <NodeMeta {...data} />
      <Handle type="source" position={Position.Right} />
    </ResourceNodeWrapper>
  );
}
