import { conditionalAdminLayoutWrap } from '@middlewares';

import { Flag } from '@utils';
import DashboardV2 from './pages/DashboardPage';
import { HomePage } from '@pages';

export const routes = {
  Dashboard: {
    label: 'Home',
    path: '/',
    page: conditionalAdminLayoutWrap(<DashboardV2 />, <HomePage />, Flag.DASHBOARD_V2),
  },
};
