import { styled, Avatar, Typography, Stack, Tooltip } from '@mui/material';

const AttributeItemAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  backgroundColor: theme.palette.background.active,
}));

interface UsersListItemGroupNameProps {
  name: string;
  icon: string;
  integrationName: string;
}

export default function UsersListItemGroupName({ name, icon, integrationName }: UsersListItemGroupNameProps) {
  const nameElem = (
    <Typography variant="helperText" color="text.muted">
      <strong>{name}</strong>
    </Typography>
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip title={integrationName} placement="top" arrow>
        <AttributeItemAvatar src={icon} />
      </Tooltip>
      {nameElem}
    </Stack>
  );
}
