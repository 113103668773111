import { CloudTypeModel } from '@api';
import { MaterialIcon } from '@components';
import PrimarySecondary from '@common/ui/PrimarySecondary';

export interface ConnectorNameAndIdProps {
  id: string;
  name: string;
  typeName?: string;
  provider?: CloudTypeModel;
}

function getProviderIcon(provider?: CloudTypeModel) {
  if (provider === CloudTypeModel.Aws) {
    return '/static/applications/aws.svg';
  } else if (provider === CloudTypeModel.Gcp) {
    return '/static/applications/gcp.svg';
  } else if (provider === CloudTypeModel.Azure) {
    return '/static/applications/azure.svg';
  } else {
    return <MaterialIcon symbol={'conversion_path'} color="primary" />;
  }
}

export function ConnectorNameAndId({ id, name, provider }: ConnectorNameAndIdProps) {
  const providerIcon = getProviderIcon(provider);
  return <PrimarySecondary primary={name} secondary={id} icon={providerIcon} />;
}
