import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import PrimarySecondaryAvatar, { PrimarySecondaryAvatarProps } from './PrimarySecondaryAvatar';

export interface PrimarySecondaryProps extends Omit<PrimarySecondaryAvatarProps, 'icon'> {
  primary: string;
  primaryMore?: ReactNode;
  secondary?: string;
  icon?: ReactNode;
  iconSpacing?: number;
  variant?: 'default' | 'big';
  maxWidth?: string | number;
}

export default function PrimarySecondary({
  primary,
  primaryMore,
  secondary,
  variant = 'default',
  icon,
  iconSpacing = 1,
  iconSize,
  maxWidth = '100%',
}: PrimarySecondaryProps) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={iconSpacing}
      data-testid="primary-secondary-stack"
    >
      {icon && <PrimarySecondaryAvatar icon={icon} iconSize={iconSize} />}
      <Stack direction="column" spacing={0.5} sx={{ maxWidth }} data-testid="primary-column">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography variant={variant === 'big' ? 'subtitle1' : 'body2'} component="div" noWrap={!!maxWidth}>
            {primary}
          </Typography>
          {primaryMore}
        </Stack>
        {secondary && (
          <Typography
            variant={variant === 'big' ? 'subtitle2' : 'helperText'}
            color="text.muted"
            component="div"
            noWrap={!!maxWidth}
          >
            {secondary}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
