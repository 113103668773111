import { useMemo, useRef, useState } from 'react';
import { Box, Chip, Popover, Stack } from '@mui/material';

import { AttributesApiAppListAttributesRequest } from '@api';

import { ListFilterOption } from '@components/ListFilters/common';
import { useAppListAttributes } from '@AccessFlows/services/attributesQueries';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import { styled } from '@mui/system';
import { LabeledTextField, MaterialIcon } from '@components';
const ValuesWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(0.5, 0),
}));

export interface AttributeValueSelectValue {
  integrationId?: string;
  attributeType: string;
}

interface AttributeValueSelectProps {
  props?: AttributesApiAppListAttributesRequest;
  value?: string[];
  onChange: (newVal: string[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isViewOnly?: boolean;
  preload?: boolean;
  optional?: boolean;
}

export default function AttributeValueSelect({
  props,
  value,
  onChange,
  onOpen,
  onClose,
  isViewOnly,
  preload = false,
  optional = true,
}: AttributeValueSelectProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>();

  const isDisabled = useMemo(() => !props?.attributeType, [props]);

  if (!props?.integrationId) {
    props = { ...props, onlyLocal: true };
  }

  const { data, isFetched, refetch } = useAppListAttributes(
    {
      ...props,
      limit: 9999,
    },
    preload,
  );

  const ref = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  const options: ListFilterOption[] =
    data?.pages
      .flatMap((page) => page.data)
      .map((attr) => {
        return {
          value: attr.source_id,
          label: attr.value,
        };
      }) || [];

  const selectedValues = options.filter((option) => value?.includes(option.value));

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    onOpen && onOpen();
    setAnchorEl(event.currentTarget);

    if (!isFetched) {
      refetch();
    }
  };

  const handleOnSelect = (newVal: ListFilterOption[]) => {
    onChange(newVal.map((v) => v.value));
  };

  const onDeleteValue = (val: ListFilterOption) => {
    onChange(value?.filter((v) => v !== val.value) ?? []);
  };

  return (
    <>
      <LabeledTextField
        label="Value"
        placeholder="Select Attribute Value"
        ref={ref}
        variant="outlined"
        size="small"
        onClick={!isDisabled ? toggleDropdown : undefined}
        disabled={isDisabled}
        optional={optional}
        InputProps={{
          readOnly: selectedValues.length > 0,
          startAdornment:
            selectedValues.length > 0 ? (
              <ValuesWrapper>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={0.5}>
                  {selectedValues.map((val) => (
                    <Chip
                      key={val.value}
                      label={val.label}
                      size="small"
                      onDelete={() => onDeleteValue(val)}
                      disabled={isViewOnly}
                    />
                  ))}
                </Stack>
              </ValuesWrapper>
            ) : undefined,
          sx:
            selectedValues.length > 0
              ? {
                  '& .MuiInputBase-input': {
                    display: 'none',
                  },
                }
              : undefined,
          endAdornment: <MaterialIcon symbol="expand_more" />,
        }}
        data-testid="attribute-value-select-label"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={options}
            value={selectedValues}
            onSelect={handleOnSelect}
            enableSearch
            title={`Select Attribute Values`}
            loading={!isFetched}
          />
        </Box>
      </Popover>
    </>
  );
}
