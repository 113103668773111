import { AccessRequestsApiRevokeAccessRequestsRequest, AccessRequestsService } from '@api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../common/constants';

export function useRevokeAccessRequests() {
  const queryClient = useQueryClient();

  const mutationFn = async ({ revokeAccessRequestsAppModel, dryRun }: AccessRequestsApiRevokeAccessRequestsRequest) => {
    return AccessRequestsService.revokeAccessRequests({
      revokeAccessRequestsAppModel,
      dryRun,
    });
  };

  const {
    mutate: revokeAccessRequests,
    data: revokedAccess,
    ...rest
  } = useMutation({
    mutationFn,
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.Activity] }),
  });

  return {
    revokeAccessRequests,
    revokedAccess,
    ...rest,
  };
}
