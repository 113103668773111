import { Loader } from '@components';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { useAccessRightSizingReports } from '@AccessRightSizing/services/use-right-sizing-reports';
import { AccessRightSizingWidget } from '@DashboardV2/components/AccessRightSizingWidget/AccessRightSizingWidget';
import { sub } from 'date-fns';
import { getFragmentData } from '@api/gql';
import { RightSizingReports_Fragment } from '@AccessRightSizing/services/gql';

export function AccessRightSizing() {
  return (
    <WidgetCard title="Right Sizing Insights">
      <AccessRightSizingContent />
    </WidgetCard>
  );
}

function AccessRightSizingContent() {
  const period = getPeriod();
  const { data, isFetched } = useAccessRightSizingReports({ take: 5, period });
  const { rightSizingReports } = data || { rightSizingReports: null };

  const rightSizingReportData = getFragmentData(RightSizingReports_Fragment, rightSizingReports);

  if (!isFetched) {
    return <Loader />;
  }

  if (!data?.rightSizingReports || !rightSizingReportData || rightSizingReportData?.edges.length === 0) {
    return (
      <PieChartEmptyState
        title="No Access Flows Were Created"
        subtitle="Create Access Flows and instruct users to create access requests to see insights"
        animationSrc={'/static/EmptyStateImages/no-data-table-chart.svg'}
      />
    );
  }

  return <AccessRightSizingWidget reports={rightSizingReportData} />;
}

function getPeriod() {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const dateStart = new Date(year, month, 1);
  const dateEnd = sub(new Date(year, month + 1, 1), { days: 1 });
  return `${formatDate(dateStart)}/${formatDate(dateEnd)}`;
}

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
