import { AppDrawer, AppDrawerHeader, Loader } from '@components';
import { useAppListAccessFlowsV2 } from '@AccessFlows/services/accessFlowsQueries';
import { Stack } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import TriggerTypeChip from '@common/ui/TriggerTypeChip';
import { routes } from '@routes';
import { useMemo } from 'react';
import { DrawerItemCard } from '../components/DrawerItemCard';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function AccessFlowDrawer({
  open,
  onClose,
  accessFlowsIds,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  accessFlowsIds: string[];
}) {
  return (
    <AppDrawer
      open={open}
      onClose={() => onClose(false)}
      width={800}
      header={<AppDrawerHeader title="Access Flows" onCloseClick={() => onClose(false)} />}
    >
      {accessFlowsIds.length > 0 && <AccessGraphDrawerContent accessFlowsIds={accessFlowsIds} />}
    </AppDrawer>
  );
}

function AccessGraphDrawerContent({ accessFlowsIds }: { accessFlowsIds: string[] }) {
  const { accessFlows, isFetched } = useAppListAccessFlowsV2();
  const navigate = useNavigate();

  const filteredAccessFlows = useMemo(
    () => accessFlows?.filter((af) => accessFlowsIds.includes(af.id)),
    [accessFlows, accessFlowsIds],
  );

  const handleOnClick = (id: string) => {
    navigate(generatePath(routes.EditAccessFlow.path, { id }));
  };

  if (!isFetched || !filteredAccessFlows) {
    return <Loader />;
  }

  return (
    <Stack direction="column" spacing={2}>
      {filteredAccessFlows.map((af) => (
        <DrawerItemCard
          key={af.id}
          title={af.name}
          onClick={() => handleOnClick(af.id)}
          actionText="View Access Flow"
          icon={<TriggerTypeChip triggerType={af.trigger_type} />}
          data-trigger={CustomAnalyticsEvents.VIEW_ACCESS_FLOW_CLICKED}
        />
      ))}
    </Stack>
  );
}
