import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ListFilters from '@components/ListFilters/ListFilters';
import StatusFilter, {
  STATUS_FILTER_LABEL,
  STATUS_FILTER_PARAM,
} from '@common/organisms/CommonFilters/ActivityStatusFilter';
import IntegrationFilterAsync, {
  INTEGRATION_FILTER_LABEL,
  INTEGRATION_FILTER_PARAM,
} from '@common/organisms/CommonFilters/IntegrationFilter';
import GranteeFilterAsync, {
  GRANTEE_FILTER_LABEL,
  GRANTEE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/GranteeFilterAsync';
import PermissionFilterAsync, {
  PERMISSION_FILTER_LABEL,
  PERMISSION_FILTER_PARAM,
} from '@common/organisms/CommonFilters/PermissionFilterAsync';
import ResourceTypeFilterAsync, {
  RESOURCE_TYPE_FILTER_LABEL,
  RESOURCE_TYPE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/ResourceTypeFilterAsync';
import AccessFlowFilterAsync, {
  ACCESS_FLOW_FILTER_LABEL,
  ACCESS_FLOW_FILTER_PARAM,
} from '@common/organisms/CommonFilters/AccessFlowFilter';
import ResourceFilterAsync, {
  RESOURCE_FILTER_LABEL,
  RESOURCE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/ResourceFilterAsync';
import { QueryParams } from '@utils';
import TriggerTypeFilter, {
  TRIGGER_TYPE_FILTER_LABEL,
  TRIGGER_TYPE_FILTER_PARAM,
} from '@common/organisms/CommonFilters/TriggerTypeFilter';
import { DateFilter } from '@common/organisms/CommonFilters/DateFilter';

export const PAGE_FILTER_PARAM = 'page';

const DEFAULT_VISIBLE_FILTERS = [GRANTEE_FILTER_PARAM, INTEGRATION_FILTER_PARAM, STATUS_FILTER_PARAM];

export default function ActivityFiltersNew() {
  const [openedFilter, setOpenedFilter] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const selected = useMemo(() => {
    return {
      INTEGRATION_FILTER_PARAM: searchParams.get(INTEGRATION_FILTER_PARAM)?.split(',') || [],
      ACCESS_FLOW_FILTER_PARAM: searchParams.get(ACCESS_FLOW_FILTER_PARAM)?.split(',') || [],
      GRANTEE_FILTER_PARAM: searchParams.get(GRANTEE_FILTER_PARAM)?.split(',') || [],
      PERMISSION_FILTER_PARAM: searchParams.get(PERMISSION_FILTER_PARAM)?.split(',') || [],
      RESOURCE_FILTER_PARAM: searchParams.get(RESOURCE_FILTER_PARAM)?.split(',') || [],
      RESOURCE_TYPE_FILTER_PARAM: searchParams.get(RESOURCE_TYPE_FILTER_PARAM)?.split(',') || [],
      STATUS_FILTER_PARAM: searchParams.get(STATUS_FILTER_PARAM)?.split(',') || [],
      TRIGGER_TYPE_FILTER_PARAM: searchParams.get(TRIGGER_TYPE_FILTER_PARAM)?.split(',') || [],
    };
  }, [searchParams]);

  const onCloseFilter = () => setOpenedFilter(undefined);
  const onOpenFilter = (value: string) => setOpenedFilter(value);

  const onSelect = useCallback(
    ({ values, filterParam }: { values: string[]; filterParam: string }) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(filterParam);
      if (values.length > 0) {
        const valuesToSet = values.join(',');
        newSearchParams.set(filterParam, valuesToSet);
      }
      if (newSearchParams.get(PAGE_FILTER_PARAM)) {
        newSearchParams.delete(PAGE_FILTER_PARAM);
      }
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const onClear = useCallback(
    (filterParam: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(filterParam);
      if (newSearchParams.get(PAGE_FILTER_PARAM)) {
        newSearchParams.delete(PAGE_FILTER_PARAM);
      }
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const filters = useMemo(
    () => [
      {
        name: ACCESS_FLOW_FILTER_LABEL,
        queryParam: ACCESS_FLOW_FILTER_PARAM,
        element: (
          <AccessFlowFilterAsync
            opened={openedFilter === ACCESS_FLOW_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(ACCESS_FLOW_FILTER_PARAM)}
            selected={selected.ACCESS_FLOW_FILTER_PARAM}
            onClear={() => onClear(ACCESS_FLOW_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: ACCESS_FLOW_FILTER_PARAM })}
          />
        ),
      },
      {
        name: GRANTEE_FILTER_LABEL,
        queryParam: GRANTEE_FILTER_PARAM,
        element: (
          <GranteeFilterAsync
            opened={openedFilter === GRANTEE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(GRANTEE_FILTER_PARAM)}
            selected={selected.GRANTEE_FILTER_PARAM}
            onClear={() => onClear(GRANTEE_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: GRANTEE_FILTER_PARAM })}
          />
        ),
      },
      {
        name: INTEGRATION_FILTER_LABEL,
        queryParam: INTEGRATION_FILTER_PARAM,
        element: (
          <IntegrationFilterAsync
            opened={openedFilter === INTEGRATION_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(INTEGRATION_FILTER_PARAM)}
            selected={selected.INTEGRATION_FILTER_PARAM}
            onClear={() => onClear(INTEGRATION_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: INTEGRATION_FILTER_PARAM })}
          />
        ),
      },
      {
        name: PERMISSION_FILTER_LABEL,
        queryParam: PERMISSION_FILTER_PARAM,
        element: (
          <PermissionFilterAsync
            opened={openedFilter === PERMISSION_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(PERMISSION_FILTER_PARAM)}
            selected={selected.PERMISSION_FILTER_PARAM}
            onClear={() => onClear(PERMISSION_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: PERMISSION_FILTER_PARAM })}
          />
        ),
      },
      {
        name: RESOURCE_FILTER_LABEL,
        queryParam: RESOURCE_FILTER_PARAM,
        element: (
          <ResourceFilterAsync
            opened={openedFilter === RESOURCE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_FILTER_PARAM)}
            selected={selected.RESOURCE_FILTER_PARAM}
            onClear={() => onClear(RESOURCE_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: RESOURCE_FILTER_PARAM })}
          />
        ),
      },
      {
        name: RESOURCE_TYPE_FILTER_LABEL,
        queryParam: RESOURCE_TYPE_FILTER_PARAM,
        element: (
          <ResourceTypeFilterAsync
            opened={openedFilter === RESOURCE_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(RESOURCE_TYPE_FILTER_PARAM)}
            selected={selected.RESOURCE_TYPE_FILTER_PARAM}
            onClear={() => onClear(RESOURCE_TYPE_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: RESOURCE_TYPE_FILTER_PARAM })}
          />
        ),
      },
      {
        name: STATUS_FILTER_LABEL,
        queryParam: STATUS_FILTER_PARAM,
        element: (
          <StatusFilter
            opened={openedFilter === STATUS_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(STATUS_FILTER_PARAM)}
            selected={selected.STATUS_FILTER_PARAM}
            onClear={() => onClear(STATUS_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: STATUS_FILTER_PARAM })}
          />
        ),
      },
      {
        name: TRIGGER_TYPE_FILTER_LABEL,
        queryParam: TRIGGER_TYPE_FILTER_PARAM,
        element: (
          <TriggerTypeFilter
            opened={openedFilter === TRIGGER_TYPE_FILTER_PARAM}
            onClose={onCloseFilter}
            onOpen={() => onOpenFilter(TRIGGER_TYPE_FILTER_PARAM)}
            selected={selected.TRIGGER_TYPE_FILTER_PARAM}
            onClear={() => onClear(TRIGGER_TYPE_FILTER_PARAM)}
            onSelect={(values) => onSelect({ values, filterParam: TRIGGER_TYPE_FILTER_PARAM })}
          />
        ),
      },
    ],
    [
      onClear,
      onSelect,
      openedFilter,
      selected.ACCESS_FLOW_FILTER_PARAM,
      selected.GRANTEE_FILTER_PARAM,
      selected.INTEGRATION_FILTER_PARAM,
      selected.PERMISSION_FILTER_PARAM,
      selected.RESOURCE_FILTER_PARAM,
      selected.RESOURCE_TYPE_FILTER_PARAM,
      selected.STATUS_FILTER_PARAM,
      selected.TRIGGER_TYPE_FILTER_PARAM,
    ],
  );

  const [visibleFilters, setVisibleFilters] = useState<string[]>(() => {
    const searchParamsVisibleFilters: string[] = [];
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam) && !DEFAULT_VISIBLE_FILTERS.includes(filter.queryParam)) {
        searchParamsVisibleFilters.push(filter.queryParam);
      }
    }

    return [...DEFAULT_VISIBLE_FILTERS, ...searchParamsVisibleFilters];
  });

  useEffect(() => {
    const filtersFromQP: string[] = [];
    for (const filterParam of searchParams.keys()) {
      if (!visibleFilters.includes(filterParam)) {
        filtersFromQP.push(filterParam);
      }
    }

    const stillVisibleFilters: string[] = [];
    for (const visibleFilter of visibleFilters) {
      if (
        searchParams.has(visibleFilter) ||
        DEFAULT_VISIBLE_FILTERS.includes(visibleFilter) ||
        visibleFilter === openedFilter
      ) {
        stillVisibleFilters.push(visibleFilter);
      }
    }

    if (filtersFromQP.length > 0 || stillVisibleFilters.length !== visibleFilters.length) {
      setVisibleFilters([...stillVisibleFilters, ...filtersFromQP]);
    }
  }, [openedFilter, searchParams, visibleFilters]);

  const hasActiveFilters = useMemo(() => {
    for (const filter of filters) {
      if (searchParams.has(filter.queryParam)) {
        return true;
      }
    }
    return false;
  }, [filters, searchParams]);

  const handleOnClear = useCallback(() => {
    for (const filter of filters) {
      searchParams.delete(filter.queryParam);
    }
    for (const dateParam of [QueryParams.startDate, QueryParams.endDate, QueryParams.utc, QueryParams.period]) {
      searchParams.delete(dateParam);
    }
    searchParams.delete(PAGE_FILTER_PARAM);
    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  const handleOnOnAddFilter = (qp: string) => {
    setVisibleFilters((prev) => [...prev, qp]);
    setOpenedFilter(qp);
  };

  return (
    <ListFilters
      visibleFilters={visibleFilters}
      filters={filters}
      onAddFilter={handleOnOnAddFilter}
      onClear={hasActiveFilters ? handleOnClear : undefined}
      prefix={<DateFilter />}
    />
  );
}
