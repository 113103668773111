import { ElementType, PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';

import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';

const ErroredTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: `${theme.spacing(1)} !important`,
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: `${theme.spacing(1)} !important`,
  },
}));

const FlowFormTextWrap = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.45),
  borderBottom: `1px solid transparent`,

  '&.selectable': {
    borderColor: theme.palette.border.dark,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  '&.muted': {
    color: theme.palette.text.disabled,
  },

  '&.has-error': {
    borderColor: theme.palette.error.main,
  },
}));

const FlowInlineTextUnderline = styled(Typography)<{
  component: ElementType;
}>(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 0.5),

  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: theme.spacing(-0.5),
    left: 0,
    right: 0,
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.text.primary,
  },
}));

export interface FlowFormErrorProps {
  errorText?: ReactNode;
  hideErrorText?: boolean;
}

interface FlowFormTextProps extends FlowFormErrorProps {
  selectable?: boolean;
  muted?: boolean;
}

export function FlowFormText({
  children,
  selectable,
  muted,
  errorText,
  hideErrorText,
}: PropsWithChildren<FlowFormTextProps>) {
  const text = useMemo(() => {
    const classes: string[] = [];
    if (selectable) classes.push('selectable');
    if (muted) classes.push('muted');
    if (errorText) classes.push('has-error');

    return (
      <FlowFormTextWrap className={classes.join(' ')} component="span">
        <Typography variant="body3">{children}</Typography>
      </FlowFormTextWrap>
    );
  }, [children, selectable, muted, errorText]);

  if (errorText && !hideErrorText) {
    return <ErrorTooltip errorText={errorText}>{text}</ErrorTooltip>;
  }

  return text;
}

export interface ErrorTooltipProps {
  errorText: NonNullable<ReactNode>;
  children: ReactElement;
}

export function ErrorTooltip({ children, errorText }: ErrorTooltipProps) {
  return (
    <ErroredTooltip title={errorText} arrow placement="bottom" open>
      {children}
    </ErroredTooltip>
  );
}

export interface FlowInlineTextProps {
  inline?: 'strong' | 'underline' | boolean;
}

export function FlowInlineText({ children, inline = true }: PropsWithChildren<FlowInlineTextProps>) {
  switch (inline) {
    case 'strong':
      return <strong>{children}</strong>;
    case 'underline':
      return (
        <FlowInlineTextUnderline component="span" variant="inherit" noWrap>
          {children}
        </FlowInlineTextUnderline>
      );
    default:
      return <>{children}</>;
  }
}
