import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '@routes';
import { AppSidebarItemWrapper } from '@common/ui/AppSidebarItem';
import { MaterialIcon } from '@components';
import { Box, IconButton, InputAdornment, Paper, styled, TextField } from '@mui/material';

const StyledWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  transition: 'opacity 0.5s ease',
}));

const SearchWrapper = styled(Box)(() => ({
  transition: 'opacity 0.5s ease',
}));

function NavBarSearch({ minimized, setMinimized }: { minimized?: boolean; setMinimized: (value: boolean) => void }) {
  return (
    <AppSidebarItemWrapper>
      {minimized ? (
        <StyledWrapper elevation={0} onClick={() => setMinimized(false)}>
          <MaterialIcon symbol="search" />
        </StyledWrapper>
      ) : (
        <SearchWrapper>
          <NavBarSearchBar />
        </SearchWrapper>
      )}
    </AppSidebarItemWrapper>
  );
}

export default NavBarSearch;

function NavBarSearchBar() {
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (value) {
      navigate(`${routes.Search.path}?q=${value}`);
      setValue('');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        size="small"
        fullWidth
        variant="outlined"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Quick find"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value === '' ? (
                <MaterialIcon symbol="search" />
              ) : (
                <IconButton onClick={() => setValue('')} edge="end" size="small">
                  <MaterialIcon symbol="close" onClick={() => setValue('')} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}
