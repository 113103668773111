import { Box, Grid, styled } from '@mui/material';
import { ReactNode } from 'react';

const UsersListItemContainerWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),

  '&.bordered': {
    border: `1px solid ${theme.palette.border.default}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

interface UsersListItemContainerProps {
  user: ReactNode;
  roles?: ReactNode;
  status?: ReactNode;
  attributes?: ReactNode;
  noBorder?: boolean;
}

export default function UsersListItemContainer({
  user,
  status,
  roles,
  attributes,
  noBorder = false,
}: UsersListItemContainerProps) {
  return (
    <UsersListItemContainerWrapper className={noBorder ? '' : 'bordered'}>
      <Grid container alignItems="center" columnSpacing={2}>
        <Grid item width={300}>
          {user}
        </Grid>
        {roles && (
          <Grid item width={150}>
            {roles}
          </Grid>
        )}
        {attributes && (
          <Grid item xs>
            {attributes}
          </Grid>
        )}
        <Grid justifyContent="flex-end" item width={100}>
          {status}
        </Grid>
      </Grid>
    </UsersListItemContainerWrapper>
  );
}
