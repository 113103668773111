import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ContentRenderer, Popover as TinyPopover } from 'react-tiny-popover';
import { ButtonBase } from '@mui/material';

export interface UsePopoverProps {
  isOpen: boolean;
  toggleDropdown: () => void;
  closeDropdown: (noReset?: boolean) => void;
}

export function usePopover(props?: {
  onClose?: PopoverProps['onClose'];
  onOpen?: PopoverProps['onOpen'];
  initOpen?: boolean;
}): UsePopoverProps {
  const { onClose, onOpen, initOpen = false } = props || {};

  const [isOpen, setIsOpen] = useState(initOpen);

  const toggleDropdown = () => {
    if (isOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };

  const openDropdown = (noReset?: boolean) => {
    setIsOpen(true);
    if (onOpen && !noReset) onOpen();
  };

  const closeDropdown = (noReset?: boolean) => {
    setIsOpen(false);
    if (onClose && !noReset) onClose();
  };

  return {
    isOpen,
    toggleDropdown,
    closeDropdown,
  };
}

export interface PopoverProps {
  children: ContentRenderer | JSX.Element;
  trigger?: ReactNode;
  externalState?: UsePopoverProps;
  testId?: string;
  onClose?: () => void;
  onOpen?: () => void;
}

const PopoverContext = createContext<{
  popoverOnCloseDisabled: boolean;
  popoverDisableOnClose: () => void;
  popoverEnableOnClose: () => void;
}>({
  popoverOnCloseDisabled: false,
  popoverDisableOnClose: () => {
    //noop
  },
  popoverEnableOnClose: () => {
    //noop
  },
});

export function usePopoverContext() {
  return useContext(PopoverContext);
}

function useDisableOnClose() {
  const [onCloseDisabled, setOnCloseDisabled] = useState(false);
  const disableOnClose = () => setOnCloseDisabled(true);
  const enableOnClose = () => setOnCloseDisabled(false);

  return {
    popoverOnCloseDisabled: onCloseDisabled,
    popoverDisableOnClose: disableOnClose,
    popoverEnableOnClose: enableOnClose,
  };
}

export function Popover(props: PopoverProps) {
  return (
    <PopoverContext.Provider value={useDisableOnClose()}>
      <PopoverElement {...props} />
    </PopoverContext.Provider>
  );
}

function PopoverElement({ children, trigger, externalState, testId, onClose, onOpen }: PopoverProps) {
  let { isOpen, toggleDropdown, closeDropdown } = usePopover({
    onClose,
    onOpen,
  });
  const { popoverOnCloseDisabled } = usePopoverContext();

  if (externalState) {
    isOpen = externalState.isOpen;
    toggleDropdown = externalState.toggleDropdown;
    closeDropdown = externalState.closeDropdown;
  }

  const content = useMemo(() => (isOpen ? children : <></>), [isOpen, children]);

  return (
    <TinyPopover
      isOpen={isOpen}
      containerStyle={{ zIndex: '1250' }}
      onClickOutside={() => !popoverOnCloseDisabled && closeDropdown()}
      positions={['bottom', 'top', 'right', 'left']}
      align="start"
      content={content}
      padding={8}
      reposition
    >
      <ButtonBase onClick={toggleDropdown} data-testid={testId}>
        {trigger}
      </ButtonBase>
    </TinyPopover>
  );
}
