import { useState } from 'react';
import { useAppQuery } from '../../api/query/use-app-query';
import { FrontierService } from '../../api/services';
import { QueryRightSizingReportArgs } from '@api/gql/graphql';
import { rightSizingReportQueryDocument } from './gql';

export function useAccessRightSizingReport(args: QueryRightSizingReportArgs) {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(rightSizingReportQueryDocument, args);
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery(['right-sizing-report', JSON.stringify(args)], fetch, {
    keepPreviousData: true,
  });

  return { ...res, isFetched: isFirstTimeFetched };
}
