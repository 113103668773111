export enum QueryKeys {
  Session = 'Session',
  Onboarding = 'Onboarding',
  UserTokens = 'UserTokens',
  Settings = 'Settings',
  Connectors = 'Connectors',
  ConnectorsTokens = 'ConnectorsTokens',
  Catalog = 'Catalog',
  CatalogV2 = 'CatalogV2',
  Integrations = 'Integrations',
  CanDeleteIntegration = 'CanDeleteIntegration',
  IntegrationAwsSecrets = 'IntegrationAwsSecrets',
  IntegrationResources = 'IntegrationResources',
  AccessFlows = 'AccessFlows',
  AccessBundles = 'AccessBundles',
  CanDeleteAccessBundle = 'CanDeleteAccessBundle',
  AccessFlowsIssues = 'AccessFlowsIssues',
  Identities = 'Identities',
  Dashboard = 'Dashboard',
  ContextsAttributes = 'ContextsAttributes',
  ActivityAutocomplete = 'ActivityAutocomplete',
  ActivityReports = 'ActivityReports',
  ReportTemplates = 'ReportTemplates',
  ConnectorInstallation = 'ConnectorInstallation',
  ActiveAccessFilterTypes = 'ActiveAccessFilterTypes',
  ActiveAccessFilterOptions = 'ActiveAccessFilterOptions',
  IntegrationResourcesErrors = 'IntegrationResourcesErrors',
  Search = 'Search',
  FallbackPolicy = 'FallbackPolicy',
  CustomAccessDetailsPreview = 'CustomAccessDetailsPreview',
}
