import { Box, Grid } from '@mui/material';
import { AlertSeverity } from './AlertSeverity';
import styled from 'styled-components';
import { AlertFilterInput } from '@api/gql/graphql';
import { AlertStatsOverTime } from './AlertStatsOverTime';

const StyledWidgetsContainer = styled(Box)(() => ({
  padding: 0,
}));

export function AccessAlertsWidgets({ filters }: { filters?: AlertFilterInput }) {
  return (
    <StyledWidgetsContainer>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AlertSeverity filters={filters} />
        </Grid>
        <Grid item xs={9}>
          <AlertStatsOverTime filters={filters} />
        </Grid>
      </Grid>
    </StyledWidgetsContainer>
  );
}
