import { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';

import { Loader, Page } from '@components';
import { useAccessFlowsPack, useGlobalNotifier, useRequiredParams } from '@hooks';
import { useGetAccessBundle } from '@api';

import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { routes } from '@routes';
import { DocsUrls } from '@utils';
import { useAppUpdateBundle } from '@AccessFlows/services/bundlesMutations';
import { ErrorPage } from '@pages';
import { BundleForm } from '@AccessFlows/organisms/BundleForm';
import useHandleBundleUpdate from '@AccessFlows/hooks/use-handle-bundle-update';

function useEditBundlePage() {
  const { isFetched: isPackFetched } = useAccessFlowsPack();
  const { notifySuccess } = useGlobalNotifier();
  const handleBundleUpdate = useHandleBundleUpdate();

  const { track } = useAnalyticsContext();
  const { id } = useRequiredParams(['id']);
  const { accessBundle, isAccessBundleFetched } = useGetAccessBundle(id);
  const {
    updateBundle,
    isLoading,
    data: updatedAccessBundle,
    isSuccess: isUpdatedSuccessfully,
  } = useAppUpdateBundle(id);

  useEffect(() => {
    if (!updatedAccessBundle || !isUpdatedSuccessfully) return;

    track(CustomAnalyticsEvents.BUNDLE_EDITED, {
      name: updatedAccessBundle.name,
    });

    notifySuccess('Bundle updated successfully');

    handleBundleUpdate(updatedAccessBundle.id);
  }, [updatedAccessBundle, track, notifySuccess, handleBundleUpdate, isUpdatedSuccessfully]);

  return {
    isFetched: isAccessBundleFetched && isPackFetched,
    accessBundle,
    updatedAccessBundle,
    handleSubmit: updateBundle,
    isSubmitting: isLoading,
  };
}

export function EditBundlePage() {
  return (
    <Page
      specialLayout
      title={routes.EditBundle.label}
      breadcrumbs={[
        {
          label: routes.Bundles.label,
          href: routes.Bundles.path,
        },
      ]}
      link={{
        label: 'Read Documentation',
        href: DocsUrls.ACCESS_BUNDLES,
      }}
    >
      <EditBundlePageContent />
    </Page>
  );
}

export function EditBundlePageContent() {
  const { isFetched, accessBundle, handleSubmit, isSubmitting } = useEditBundlePage();

  if (!isFetched) {
    return <Loader />;
  }

  if (!accessBundle) {
    return <ErrorPage errorCode={404} errorMessage="Access flow not found" />;
  }

  return (
    <BundleForm formData={accessBundle} onSubmit={handleSubmit}>
      <LoadingButton variant="contained" loading={isSubmitting} type="submit">
        Update Bundle
      </LoadingButton>
    </BundleForm>
  );
}
