import { Loader, Page } from '@components';
import { routes } from '@routes';
import { EditIntegrationContent } from '@Integrations/pages/EditIntegrationPageV2';
import { useOnboardingContext } from '@Onboarding/context';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { SYNC_PARAM } from '@Integrations/organisms/EditConnectedIntegration/EditConnectedIntegration';

export function OnboardingEditIntegrationPage() {
  const { onboardingIds } = useOnboardingContext();
  const [searchParams] = useSearchParams();
  const integrationSync = useMemo(() => searchParams.has(SYNC_PARAM), [searchParams]);

  if (!onboardingIds?.added_integration_id) return <Loader />;

  return (
    <Page title={!integrationSync && routes.EditIntegration.label} contentPadded>
      <EditIntegrationContent
        integrationId={onboardingIds.added_integration_id}
        paths={{
          back: routes.OnboardingEditIntegration.path,
          next: routes.OnboardingAccessFlow.path,
        }}
      />
    </Page>
  );
}
