import { useMemo } from 'react';
import { TagAppModel } from '@api';

import { useSelectResourceDrawerContext } from '../provider';
import { yup } from '@libs';
import { HumanReadableResources } from '../../HumanReadable';

const DEFAULT_PLACEHOLDER = 'any resource';

export interface DropdownTriggerProps {
  matchers?: TagAppModel[];
  excludes?: TagAppModel[];
  error?: yup.ValidationError;
}

function useDropdownTrigger({ matchers, excludes }: DropdownTriggerProps) {
  const { errorTextHidden, resourceTypeHumanName, resources, isFetched } = useSelectResourceDrawerContext();

  const remappedResources = useMemo(
    () =>
      resources.reduce((acc, resource) => {
        acc.set(resource.id, resource.name);
        return acc;
      }, new Map<string, string>()),
    [resources],
  );

  const humanReadableMatchers = useMemo(
    () =>
      matchers?.map((m) => ({
        ...m,
        value: remappedResources.get(m.value) || m.value,
      })),
    [remappedResources, matchers],
  );

  const humanReadableExcludes = useMemo(
    () =>
      excludes?.map((m) => ({
        ...m,
        value: remappedResources.get(m.value) || m.value,
      })),
    [remappedResources, excludes],
  );

  const defaultResourcePlaceholder = useMemo(
    () => (resourceTypeHumanName ? `any ${resourceTypeHumanName}` : DEFAULT_PLACEHOLDER),
    [resourceTypeHumanName],
  );

  return {
    humanReadableMatchers,
    humanReadableExcludes,
    errorTextHidden,
    resourceTypeHumanName,
    defaultResourcePlaceholder,
    isFetched,
  };
}

export function DropdownTrigger({ matchers, excludes, error }: DropdownTriggerProps) {
  const {
    humanReadableMatchers,
    humanReadableExcludes,
    errorTextHidden,
    resourceTypeHumanName,
    defaultResourcePlaceholder,
    isFetched,
  } = useDropdownTrigger({
    matchers,
    excludes,
    error,
  });

  return (
    <HumanReadableResources
      isLoading={!isFetched}
      resourceType={resourceTypeHumanName}
      matchers={humanReadableMatchers || []}
      excludes={humanReadableExcludes || []}
      placeholder={defaultResourcePlaceholder}
      errorText={error?.message}
      hideErrorText={errorTextHidden}
    />
  );
}
