import { useMemo } from 'react';

import { Typography } from '@mui/material';
import {
  AccessTargetAppModel,
  AccessTargetAppModelBundle,
  AccessTargetAppModelIntegration,
  AccessTargetType,
  useGetCatalogV2,
} from '@api';

import { HumanReadableResources } from './Resources';
import { HumanReadableIntegration } from './Integration';

import { Tooltipped } from '../common/Tooltipped';
import { AccessFlowTargetTemplate } from '@af-templates';
import { FlowInlineText, FlowInlineTextProps } from '../common/Text';
import { HumanReadablePlaceholderProps, HumanReadableText } from '../common/HumanReadableText';
import { HumanReadableBundle } from './Bundle';
import { HumanReadableResourcesCount } from './ResourcesCount';

export interface HumanReadableAccessTargetsProps extends FlowInlineTextProps, HumanReadablePlaceholderProps {
  value: AccessTargetAppModel[];
  template?: AccessFlowTargetTemplate;
}

export function HumanReadableAccessTargets({ value, template, inline, noTooltip }: HumanReadableAccessTargetsProps) {
  if (value.length === 0)
    return template ? (
      <HumanReadableAccessTargetTemplate {...{ template, inline }} />
    ) : (
      <FlowInlineText inline={inline}>anything</FlowInlineText>
    );

  const firstResource = value[0];

  return (
    <span>
      <HumanReadableAccessTarget value={firstResource} inline={inline} noTooltip={noTooltip} />
      {value.length > 1 ? <HumanReadableAccessMoreTargets value={value.slice(1)} noTooltip={noTooltip} /> : ''}
    </span>
  );
}

function HumanReadableAccessTargetTemplate({
  template,
  inline,
}: { template: AccessFlowTargetTemplate } & FlowInlineTextProps) {
  return (
    <Typography variant="inherit" component="span">
      <HumanReadableText placeholder={template.permissions} inline={inline} /> to{' '}
      <HumanReadableResources
        resourceType="resources"
        matchers={[]}
        excludes={[]}
        placeholder={template.resource_tag_matchers}
        inline={inline}
      />{' '}
      from <HumanReadableIntegration placeholder={template.integration_id} inline={inline} />
    </Typography>
  );
}

export function HumanReadableAccessTarget({
  value,
  inline,
  noTooltip,
}: {
  value: AccessTargetAppModel;
} & FlowInlineTextProps &
  HumanReadablePlaceholderProps) {
  if (value.target_type === AccessTargetType.Integration && value.integration) {
    return <HumanReadableAccessIntegrationTarget value={value.integration} inline={inline} noTooltip={noTooltip} />;
  }

  if (value.target_type === AccessTargetType.Bundle && value.bundle) {
    return <HumanReadableAccessBundleTarget value={value.bundle} inline={inline} noTooltip={noTooltip} />;
  }

  return (
    <Typography variant="inherit" component="span" color="error">
      Unknown access target type
    </Typography>
  );
}

function HumanReadableAccessBundleTarget({
  value,
  inline,
  noTooltip,
}: {
  value: AccessTargetAppModelBundle;
} & FlowInlineTextProps &
  HumanReadablePlaceholderProps) {
  return (
    <Typography variant="inherit" component="span">
      access to resources from <HumanReadableBundle value={value.bundle_id} inline={inline} noTooltip={noTooltip} />
    </Typography>
  );
}

function HumanReadableAccessIntegrationTarget({
  value,
  inline,
  noTooltip,
}: {
  value: AccessTargetAppModelIntegration;
} & FlowInlineTextProps &
  HumanReadablePlaceholderProps) {
  const { integrationsConfigs } = useGetCatalogV2();

  const integrationConfig = useMemo(
    () => integrationsConfigs.find((c) => c.resource_types[value.resource_type]),
    [integrationsConfigs, value],
  );

  const humanReadableResourceType = useMemo(() => {
    if (!integrationConfig) return value.resource_type;
    return integrationConfig.resource_types[value.resource_type]?.display_name || value.resource_type;
  }, [integrationConfig, value.resource_type]);

  const integrationInfo = useMemo(() => {
    return {
      integrationId: value.integration_id,
      resourceType: value.resource_type,
    };
  }, [value.integration_id, value.resource_type]);

  return (
    <Typography variant="inherit" component="span">
      <HumanReadableText values={value.permissions} inline={inline} noTooltip={noTooltip} /> to{' '}
      <HumanReadableResourcesCount
        resourceType={humanReadableResourceType}
        matchers={value.resource_tag_matchers}
        excludes={value.resource_tag_excludes}
      />{' '}
      from <HumanReadableIntegration value={integrationInfo} inline={inline} noTooltip={noTooltip} />
    </Typography>
  );
}

function HumanReadableAccessMoreTargets({
  value,
  noTooltip = false,
}: {
  value: AccessTargetAppModel[];
  noTooltip?: boolean;
}) {
  if (noTooltip) {
    return <span> and +{value.length} more</span>;
  }

  const moreResources = value.map((r, i) => (
    <HumanReadableAccessTarget noTooltip={noTooltip} key={`r-${i}`} value={r} inline />
  ));
  const length = moreResources.length;

  return (
    <>
      {' '}
      and <Tooltipped values={moreResources}>{length} more</Tooltipped>
    </>
  );
}
