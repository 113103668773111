import { useMemo, useState } from 'react';
import { Alert, Button, Stack } from '@mui/material';

import { IntegrationConfigAppModelV2, useCreateIntegrationV2 } from '@api';
import { ConfigFormHeader } from '@components';
import { IntegrationConnectorForm } from '@organisms';
import { IntegrationConfigFormQP } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { SelectConfig } from './SelectConfig';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty, RouterLink } from '@libs';
import { NEW_CONNECTOR_VALUE } from '@organisms/IntegrationConnectorForm/SelectConnector';
import { CustomAnalyticsEvents } from '@utils/analytics';
import { IntegrationConfigForm } from '@Integrations/organisms/IntegrationConfigForm/IntegrationConfigForm';
import { SYNC_PARAM } from '@Integrations/organisms/EditConnectedIntegration/EditConnectedIntegration';
import { ConnectResourceIntegrationPathsProps } from '@Integrations/organisms/ConnectIntegration/ConnectResourceIntegration';

export const ConnectIntegrationsGroupQp = {
  ...IntegrationConfigFormQP,
  ConfigType: 'type',
  ConfigForm: 'config',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ConnectIntegrationsGroupQp = (typeof ConnectIntegrationsGroupQp)[keyof typeof ConnectIntegrationsGroupQp];

export interface ConnectIntegrationsGroupProps {
  group: string;
  configs: IntegrationConfigAppModelV2[];
  paths: ConnectResourceIntegrationPathsProps;
  align?: 'center' | 'left';
}

function useConnectIntegrationsGroup({ group, configs, paths }: ConnectIntegrationsGroupProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createIntegration, isCreateIntegrationLoading, createdIntegration } = useCreateIntegrationV2();

  const [selectedConfigType, setSelectedConfigType] = useState<string>(
    searchParams.get(ConnectIntegrationsGroupQp.ConfigType) ?? '',
  );
  const [selectedConnectorId, setSelectedConnectorId] = useState<string>(
    searchParams.get(ConnectIntegrationsGroupQp.ConnectorId) ?? '',
  );
  const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[]>(
    searchParams.getAll(ConnectIntegrationsGroupQp.ResourceTypes) ?? [],
  );

  const groupedConfigs = useMemo(() => configs.filter((i) => i.integrations_group === group), [group, configs]);

  const showConfigForm = useMemo(
    () => searchParams.get(ConnectIntegrationsGroupQp.ConfigForm) === 'true',
    [searchParams],
  );

  const selectedConfig = useMemo(
    () => groupedConfigs.find((c) => c.type === selectedConfigType),
    [groupedConfigs, selectedConfigType],
  );

  useMemo(() => {
    if (createdIntegration) {
      navigate(`${generatePath(paths.editPath, { id: createdIntegration.id })}?${SYNC_PARAM}=true`);
    }
  }, [createdIntegration, navigate, paths.editPath]);

  const handleNextClick = () => {
    if (isEmpty(selectedConfigType) || isEmpty(selectedConnectorId) || selectedResourceTypes.length === 0) {
      return;
    }

    const newSearchParams = new URLSearchParams();
    newSearchParams.set(ConnectIntegrationsGroupQp.ConfigType, selectedConfigType);
    newSearchParams.set(ConnectIntegrationsGroupQp.ConnectorId, selectedConnectorId);
    selectedResourceTypes.forEach((t) => newSearchParams.append(ConnectIntegrationsGroupQp.ResourceTypes, t));
    newSearchParams.set(ConnectIntegrationsGroupQp.ConfigForm, 'true');
    setSearchParams(newSearchParams);
  };

  const handleBackClick = () => {
    searchParams.delete(ConnectIntegrationsGroupQp.ConfigForm);
    setSearchParams(searchParams);
  };

  const nextDisabled = useMemo(
    () => !selectedConnectorId || selectedConnectorId === NEW_CONNECTOR_VALUE || selectedResourceTypes?.length === 0,
    [selectedConnectorId, selectedResourceTypes?.length],
  );

  return {
    groupedConfigs,
    selectedConfigType,
    setSelectedConfigType,
    selectedConnectorId,
    setSelectedConnectorId,
    selectedResourceTypes,
    setSelectedResourceTypes,
    showConfigForm,
    selectedConfig,
    handleNextClick,
    handleBackClick,
    createIntegration,
    isCreateIntegrationLoading,
    nextDisabled,
  };
}

export function ConnectIntegrationsGroup({ group, configs, paths, align }: ConnectIntegrationsGroupProps) {
  const {
    groupedConfigs,
    selectedConfigType,
    setSelectedConfigType,
    selectedConnectorId,
    setSelectedConnectorId,
    selectedResourceTypes,
    setSelectedResourceTypes,
    showConfigForm,
    selectedConfig,
    handleNextClick,
    handleBackClick,
    createIntegration,
    isCreateIntegrationLoading,
    nextDisabled,
  } = useConnectIntegrationsGroup({
    group,
    configs,
    paths,
  });

  if (groupedConfigs.length === 0) {
    return <Alert color="warning">No integrations configs found for this group</Alert>;
  }

  if (selectedConfig && showConfigForm) {
    return (
      <>
        <ConfigFormHeader
          icons={[selectedConfig.icons.svg]}
          title="Complete setup"
          description="To complete the integration process, provide Apono with the configuration parameters below."
        />
        <IntegrationConfigForm
          config={selectedConfig}
          selectedConnectorId={selectedConnectorId}
          selectedResourceTypes={selectedResourceTypes}
          submitButtonText="Connect"
          loading={isCreateIntegrationLoading}
          onSubmit={createIntegration}
          onBackClick={handleBackClick}
        />
      </>
    );
  }

  return (
    <>
      <SelectConfig
        groupedConfigs={groupedConfigs}
        selectedConfigType={selectedConfigType}
        onSelect={setSelectedConfigType}
        align={align}
      />
      {selectedConfig && (
        <IntegrationConnectorForm
          config={selectedConfig}
          connectorId={selectedConnectorId}
          onConnectorIdChange={setSelectedConnectorId}
          resourceTypes={selectedResourceTypes}
          onResourceTypesChange={setSelectedResourceTypes}
        >
          <Stack direction="row" spacing={2}>
            <Button variant="text" component={RouterLink} to={paths.catalogPath}>
              Cancel
            </Button>
            <Button
              data-trigger={CustomAnalyticsEvents.INTEGRATION_NEXT_CLICKED}
              variant="contained"
              onClick={handleNextClick}
              disabled={nextDisabled}
            >
              Next
            </Button>
          </Stack>
        </IntegrationConnectorForm>
      )}
    </>
  );
}
