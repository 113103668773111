import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ActivityApiAppListActivityRequestsRequest } from '@api';
import { getNumberQP, getStartEndDateFromQP, getStringQP } from '@utils';

import { QueryParams } from '../../common/constants';

import { useListActivityRequests } from './query';

const LIMIT = 50;

function toActivityProps(qp: URLSearchParams): ActivityApiAppListActivityRequestsRequest {
  const page = getNumberQP(qp, QueryParams.page);
  const offset = page ? (Number(page) || 1) * LIMIT - LIMIT : undefined;
  const { startDate, endDate } = getStartEndDateFromQP(qp);

  return {
    filterIntegrationId: getStringQP(qp, QueryParams.filterIntegrationId),
    filterPermission: getStringQP(qp, QueryParams.filterPermission),
    filterRequestorId: getStringQP(qp, QueryParams.filterRequestorId),
    filterResourceType: getStringQP(qp, QueryParams.filterResourceType),
    filterResource: getStringQP(qp, QueryParams.filterResource),
    filterStatus: getStringQP(qp, QueryParams.filterStatus),
    filterTriggerType: getStringQP(qp, QueryParams.filterTriggerType),
    filterAccessFlowId: getStringQP(qp, QueryParams.filterAccessFlowId),
    offset,
    limit: LIMIT,
    startDate: startDate ? startDate.unix : undefined,
    endDate: endDate ? endDate.unix : undefined,
  };
}

export default function useActivityTable() {
  const [queryParams, setQueryParams] = useSearchParams();
  const activityProps = useMemo(() => toActivityProps(queryParams), [queryParams]);
  const { requests, pagination, isRequestsFetched, isRequestsLoading } = useListActivityRequests(activityProps);

  const selectedRow = useMemo(() => getStringQP(queryParams, QueryParams.requestId), [queryParams]);

  const handleOnPageChange = (page: number) => {
    queryParams.set(QueryParams.page, String(page));
    setQueryParams(queryParams);
  };

  const handleAccessRequestClick = (requestId: string) => {
    queryParams.set(QueryParams.requestId, requestId);
    setQueryParams(queryParams);
  };

  return {
    requests,
    pagination,
    isFetched: isRequestsFetched,
    isLoading: isRequestsLoading,
    handleOnPageChange,
    handleAccessRequestClick,
    selectedRow,
  };
}
