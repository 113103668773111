import { useState, useEffect } from 'react';
import loader from '@monaco-editor/loader';

function useSafeMonaco() {
  const [monaco, setMonaco] = useState(loader.__getMonacoInstance());
  const [loadingError, setLoadingError] = useState<Error | null>(null);

  useEffect(() => {
    let cancelable: ReturnType<typeof loader.init>;
    let isCancelled = false;

    if (!monaco) {
      cancelable = loader.init();

      cancelable
        .then((monacoInstance) => {
          if (!isCancelled) {
            setMonaco(monacoInstance);
          }
        })
        .catch((error) => {
          if (error && error.type !== 'cancelation') {
            setLoadingError(error);
          }
        });
    }

    return () => {
      isCancelled = true;
      cancelable?.cancel();
    };
  }, [monaco]);

  if (loadingError) {
    console.error(loadingError); // You can handle the error more gracefully here if needed
  }

  return monaco;
}

export default useSafeMonaco;
