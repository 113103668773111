import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import {
  AttributesApiAppListAttributeGroupsRequest,
  AttributesApiAppListAttributesRequest,
  AttributesService,
  useListQuery,
} from '@api';

import { AttributesQueryKeys } from './queryKeys';

export function useAppListAttributeGroups(props?: AttributesApiAppListAttributeGroupsRequest, enabled = true) {
  const fetchMethod = async () => {
    const res = await AttributesService.appListAttributeGroups({
      limit: 1000,
      ...props,
    });

    return res.data;
  };

  const { data: attributeGroups, ...rest } = useQuery([AttributesQueryKeys.ListGroups], fetchMethod, {
    initialData: [],
    enabled,
  });

  return {
    attributeGroups,
    ...rest,
  };
}

export function useAppListAttributes(
  props: AttributesApiAppListAttributesRequest = {
    limit: 100,
  },
  enabled = true,
) {
  const fetchMethod = async ({ pageParam: skipParam = 0 }: QueryFunctionContext) => {
    return AttributesService.appListAttributes({
      ...props,
      limit: props.limit || 100,
      skip: Math.max(skipParam, 0),
    });
  };

  return useListQuery({
    fetchMethod,
    queryKey: [AttributesQueryKeys.ListAttributes, props],
    withoutPageParam: true,
    enabled,
  });
}
