import AppModal from '@common/ui/AppModal';
import { useState } from 'react';
import { Button, Stack, Tab, Tabs } from '@mui/material';
import { MaterialIcon } from '@components';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { Code } from '@components/Markdown/Code';
import { CustomAnalyticsEvents } from '@utils/analytics';
import { AnalyticsTriggerElementProps } from '@common/types';

export default function ViewAsCodeModal({
  code,
  buttonText,
  ...analyticsProps
}: { code: string; buttonText?: string } & AnalyticsTriggerElementProps) {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('Terraform');
  return (
    <>
      <Button
        variant="text"
        startIcon={<MaterialIcon symbol="code" />}
        onClick={() => setOpen(true)}
        sx={{ alignSelf: 'flex-start' }}
        {...analyticsProps}
      >
        {buttonText || tab}
      </Button>
      <AppModal
        width={800}
        open={open}
        onClose={() => setOpen(false)}
        header={<PrimarySecondary primary="View as code" variant="big" />}
      >
        <Stack spacing={2}>
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <Tab label="Terraform" value="Terraform" />
          </Tabs>
          {tab === 'Terraform' && (
            <Code withWhitespaces language="json" data-trigger={CustomAnalyticsEvents.COPY_AS_CODE_CLICKED}>
              {code}
            </Code>
          )}
        </Stack>
      </AppModal>
    </>
  );
}
