import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  AccessFlowSearchResult,
  AponoSearchObjectUser,
  ResourceDetailed1,
  SearchResponse,
  SearchResponseTagsInner,
  useSearchResults,
} from '@api';
import { splitStringToWords } from '@utils';
import { EmptyState, Loader, SearchBar } from '@components';

type SearchResultKey = keyof SearchResponse;

export function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.getAll('q');

  const { searchResults, isLoadingSearchResults, isFetchedSearchResults, isSearchResultEmpty } =
    useSearchResults(searchQuery);

  const handleQueryChange = (newQuery: string[]) => {
    const params = new URLSearchParams(newQuery.map((s) => ['q', s]));
    setSearchParams(params);
  };

  const resultsComponents = (Object.keys(searchResults) as SearchResultKey[]).map((resultKey) => (
    <SearchResultsSection
      key={resultKey}
      {...({
        resultKey,
        data: searchResults[resultKey],
      } as SearchResultsSectionProps)}
    />
  ));

  return (
    <Stack spacing={1}>
      <SearchBar
        total={Object.values(searchResults).flat().length}
        value={searchQuery}
        onChange={handleQueryChange}
        multiple
      />
      <Stack>
        {!isFetchedSearchResults && isLoadingSearchResults && <Loader />}
        {isFetchedSearchResults && isSearchResultEmpty && <EmptyState title={'No results found'} />}
        {isFetchedSearchResults && !isSearchResultEmpty && resultsComponents}
      </Stack>
    </Stack>
  );
}

type SearchResultsSectionProps = {
  [K in SearchResultKey]: {
    resultKey: K;
    data: SearchResponse[K];
  };
}[SearchResultKey];

const SearchResultsSection = ({ resultKey, data }: SearchResultsSectionProps) => {
  if (data.length === 0) return null;

  return (
    <>
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        {splitStringToWords(resultKey)}
      </Typography>
      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers[resultKey].map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: unknown, index) => (
              <TableRow key={index}>
                {cells(resultKey, item).map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    <>{cell}</>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const headers: Record<SearchResultKey, string[]> = {
  users: ['Account ID', 'Email', 'Role', 'First Name', 'Last Name', 'Member of Groups'],
  groups: ['Group'],
  resources: ['Account ID', 'Resource Integration Name', 'Resource Integration Type', 'Resource Name', 'All Tags'],
  access_flows: ['Name', 'Access', 'Duration in Seconds', 'Created'],
  tags: ['First', 'Second'],
};

function cells<T>(key: SearchResultKey, item: T) {
  let result: Array<unknown> = [];

  const userItem = item as AponoSearchObjectUser;
  const resourceItem = item as ResourceDetailed1;
  const accessFlowItem = item as AccessFlowSearchResult;
  const tagItem = item as SearchResponseTagsInner;

  switch (key) {
    case 'users':
      result = [
        userItem.account_id,
        userItem.email,
        userItem.role,
        userItem.first_name,
        userItem.last_name,
        userItem.member_of_groups,
      ];
      break;
    case 'groups':
      result = [item];
      break;
    case 'resources':
      result = [
        resourceItem.account_id,
        resourceItem.resource_integration_name,
        resourceItem.resource_integration_type,
        resourceItem.resource_name,
        JSON.stringify(resourceItem.all_tags),
      ];
      break;
    case 'access_flows':
      result = [
        accessFlowItem.name,
        accessFlowItem.access.join(', '),
        accessFlowItem.duration_in_seconds,
        accessFlowItem.created,
      ];
      break;
    case 'tags':
      result = [tagItem.first, tagItem.second];
      break;
  }

  return result;
}
