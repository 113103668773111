import { memo, useEffect, useMemo, useState } from 'react';
import { Stack, RadioGroup, Radio, FormControlLabel, CircularProgress } from '@mui/material';

import { AppDrawer, AppDrawerFooter, AppDrawerHeader } from '@components';
import { useAppGetIntegration } from '@api';
import { ResourceDetailedItem } from '@components/ResourceDetailedItem';

interface DrawerResourceTypesSelectProps {
  integrationId: string;
  open: boolean;
  onClose: () => void;
  value?: string;
  onChange: (value: string) => void;
}

function useDrawerResourceTypesSelect({
  integrationId,
  value,
  onChange,
}: Pick<DrawerResourceTypesSelectProps, 'integrationId' | 'value' | 'onChange'>) {
  const { integration, integrationConfig, isIntegrationFetched } = useAppGetIntegration(integrationId);
  const [resourceType, setResourceType] = useState<string | undefined>(value);

  useEffect(() => {
    setResourceType(value);
  }, [value]);

  const options = useMemo(() => {
    if (!integration || !integrationConfig) {
      return [];
    }

    const optionsList: Record<string, Option> = {};
    for (const connected of integration.connected_resource_types) {
      const rt = integrationConfig.resource_types[connected];
      const hierarchyParts = rt.hierarchy.split('/');
      optionsList[rt.type] = {
        type: rt.type,
        display_name: rt.display_name,
        hierarchy: hierarchyParts.map((h) => integrationConfig.resource_types[h].display_name).join('/'),
      };
    }

    return Object.values(optionsList);
  }, [integration, integrationConfig]);

  const handleChange = (val: string) => setResourceType(val);

  const handleSubmit = () => {
    if (resourceType) onChange(resourceType);
  };

  const handleClear = () => setResourceType(undefined);

  const canSubmit = Boolean(resourceType);

  return {
    isFetched: isIntegrationFetched ?? false,
    options,
    resourceType,
    setResourceType,
    handleChange,
    handleSubmit,
    handleClear,
    canSubmit,
  };
}

export const DrawerResourceTypesSelect = memo(function DrawerResourceTypesSelect({
  integrationId,
  open,
  onClose,
  value,
  onChange,
}: DrawerResourceTypesSelectProps) {
  const { options, isFetched, resourceType, handleChange, handleSubmit, handleClear, canSubmit } =
    useDrawerResourceTypesSelect({
      integrationId,
      value,
      onChange,
    });

  return (
    <AppDrawer
      open={open}
      onClose={onClose}
      header={<AppDrawerHeader title="Select resource type" onCloseClick={onClose} />}
      footer={<AppDrawerFooter onSubmit={handleSubmit} onClear={handleClear} disabled={!canSubmit} />}
    >
      <OptionsList value={resourceType} isFetched={isFetched} options={options} handleChange={handleChange} />
    </AppDrawer>
  );
});

interface Option {
  type: string;
  display_name: string;
  hierarchy: string;
}

interface OptionsListProps {
  isFetched: boolean;
  options: Option[];
  value?: string;
  handleChange: (value: string) => void;
}

function OptionsList({ isFetched, options, value, handleChange }: OptionsListProps) {
  if (!isFetched) return <CircularProgress size="24px" />;

  return (
    <RadioGroup value={value} onChange={(e) => handleChange(e.target.value)}>
      <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
        {options.map((option) => (
          <div key={option.type}>
            <FormControlLabel
              data-testid="interactive-dropdown-select-option"
              value={option.type}
              control={<Radio size="small" checked={value === option.type} />}
              label={<ResourceDetailedItem displayName={option.display_name} logicalPath={option.hierarchy} />}
            />
          </div>
        ))}
      </Stack>
    </RadioGroup>
  );
}
