import { AgentViewModel, ConnectorsService } from '@api';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormHeader, IntegrationInfo, LabeledTextField, Loader, Page } from '@components';
import { useAsync, useConnectors } from '@hooks';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { routes } from '@routes';
import { ErrorPage } from '@pages/ErrorPage';

const connectorInfo = {
  links: [
    {
      label: 'What is a connector',
      url: 'https://docs.apono.io/docs/connector-management',
    },
    {
      label: 'How to install a connector',
      url: 'https://docs.apono.io/docs/installing-a-connector-on-kubernetes',
    },
  ],
  info: {
    title: 'Best used for',
    items: ['When recieving **approve** user', 'Request from **BI-Team**', 'Grant access to **12 Hours**.'],
  },
};

export function EditConnectorPage() {
  return (
    <Page
      contentCentered
      contentPadded
      breadcrumbs={[{ label: 'Connectors', href: '/connectors' }]}
      title="Edit Connector"
    >
      <EditConnectorContent />
    </Page>
  );
}

function EditConnectorContent() {
  const { id } = useParams();
  const { connectors, connectorsLoaded } = useConnectors();
  const connector = connectors?.find((currentConnector) => currentConnector.agent_id == id);

  if (!connectorsLoaded) {
    return <Loader />;
  }

  if (!connector) {
    return <ErrorPage errorCode={404} />;
  }

  return <EditConnectorForm connector={connector} />;
}

function EditConnectorForm({ connector }: { connector: AgentViewModel }) {
  const { track } = useAnalyticsContext();
  const navigate = useNavigate();
  const [connectorName, setConnectorName] = useState<string | undefined>(connector.name);

  const {
    execute: onSubmit,
    hasSucceeded,
    isLoading,
  } = useAsync(
    async () =>
      ConnectorsService.updateAgentName({
        id: connector.agent_id,
        updateConnector: {
          connector_name: connectorName || '',
        },
      }),
    [connectorName],
    {
      executeOnUpdate: false,
      executeOnMount: false,
    },
  );

  useEffect(() => {
    if (hasSucceeded) {
      navigate(routes.Connectors.path);
    }
  }, [hasSucceeded, navigate]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      track(CustomAnalyticsEvents.UPDATE_CONNECTOR, {
        name: connectorName,
      });
      onSubmit();
    }, 0);
  };

  return (
    <Grid container justifyContent="space-between" direction="row">
      <Grid item xs={6}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormHeader
              name="Edit the Connector"
              description={`The Apono Connector is an on-prem connection that can be used to connect resources to Apono and seperate the Apono web app from the environment for security purposes.`}
            />
            <Stack spacing={1}>
              <LabeledTextField
                onChange={(e) => {
                  setConnectorName(e.target.value || '');
                }}
                label={'Connector Name'}
                placeholder={'Type name'}
                defaultValue={connectorName}
              />
            </Stack>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <LoadingButton type="submit" size="large" variant="contained" color="primary" loading={isLoading}>
                {'Update Connector'}
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </Grid>
      <Grid item xs={4}>
        <IntegrationInfo info={connectorInfo.info} links={connectorInfo.links} />
      </Grid>
    </Grid>
  );
}
