import { IntegrationConfigAppModelV2 } from '@api';
import { Stack } from '@mui/material';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import ViewAsCode from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/ViewAsCode';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function IntegrationFormHeader({
  connectorId,
  config,
  title,
}: {
  connectorId?: string;
  title?: string;
  config: IntegrationConfigAppModelV2;
}) {
  return (
    <Stack
      py={2}
      top={0}
      zIndex={1}
      direction="row"
      position="sticky"
      alignItems="center"
      bgcolor="background.default"
      justifyContent="space-between"
    >
      <PrimarySecondary
        icon={config.icons.svg}
        primary={title || config.name}
        secondary={title && config.name}
        variant={title ? 'default' : 'big'}
        data-testid="integration-form-header"
      />
      <ViewAsCode
        config={config}
        withTitle={false}
        buttonText="View as code"
        connectorId={connectorId}
        data-props={JSON.stringify({ integrationType: config.type })}
        data-trigger={CustomAnalyticsEvents.VIEW_AS_CODE_CLICKED_IN_INTEGRATION_FORM}
      />
    </Stack>
  );
}
