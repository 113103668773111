import { TriggerTypeAppModel } from '@api';
import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { useState } from 'react';
import { FilterProps } from './types';
import { ListFilterOption } from '@components/ListFilters/common';

export const TRIGGER_TYPE_FILTER_LABEL = 'Trigger Type';
export const TRIGGER_TYPE_FILTER_PARAM = 'filter.trigger_type';

export default function TriggerTypeFilter({
  opened = false,
  onClose,
  onOpen,
  selected,
  onClear,
  onSelect,
}: FilterProps) {
  const { isEnabled: showAutoGrant } = useFlagFixed(Flag.AUTO_GRANT);
  const [isOpen, setIsOpen] = useState(opened);

  interface TriggerTypeOption {
    label: string;
    value: TriggerTypeAppModel;
  }
  const activeOptions: TriggerTypeOption[] = [
    {
      label: 'Self Serve',
      value: TriggerTypeAppModel.UserRequest,
    },
  ];

  if (showAutoGrant) {
    activeOptions.push({
      label: 'Automatic',
      value: TriggerTypeAppModel.AutoGrant,
    });
  }

  const triggerTypeOptions: TriggerTypeOption[] = [];
  const selectedOptions: TriggerTypeOption[] = [];
  for (const option of activeOptions) {
    const listOption = {
      label: option.label,
      value: option.value,
    };

    if (selected.includes(option.value)) {
      selectedOptions.push(listOption);
    }

    triggerTypeOptions.push(listOption);
  }

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleOnClear = () => {
    onClear();
    onClose && onClose();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = () => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={TRIGGER_TYPE_FILTER_LABEL}
          value={selectedOptions ? selectedOptions : []}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      <ListFilterMultiSelect
        options={activeOptions}
        value={selectedOptions}
        onSelect={onSelectHandler}
        title="Select Trigger Type Filter"
      />
    </ListFilter>
  );
}
