import { TriggerTypeAppModel } from '@api';
import { useSearchParams } from 'react-router-dom';
import { TRIGGER_TYPE_FILTER_PARAM } from '@AccessFlows/common/constants';
import { useCallback } from 'react';
import TriggerTypeChip from '@common/ui/TriggerTypeChip/TriggerTypeChip';

export function TriggerTypeClickableChip({ triggerType }: { triggerType: TriggerTypeAppModel }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isSelected = searchParams.getAll(TRIGGER_TYPE_FILTER_PARAM)?.includes(triggerType) || false;
  const handleOnClick = useCallback(() => {
    const filterTriggerTypes = searchParams.getAll(TRIGGER_TYPE_FILTER_PARAM);

    if (filterTriggerTypes?.includes(triggerType)) {
      searchParams.delete(TRIGGER_TYPE_FILTER_PARAM);
      filterTriggerTypes.forEach((filterTriggerType) => {
        if (filterTriggerType !== triggerType) {
          searchParams.append(TRIGGER_TYPE_FILTER_PARAM, filterTriggerType);
        }
      });
    } else {
      searchParams.append(TRIGGER_TYPE_FILTER_PARAM, triggerType);
    }

    setSearchParams(searchParams);
  }, [triggerType, searchParams, setSearchParams]);

  return <TriggerTypeChip triggerType={triggerType} isSelected={isSelected} onClick={handleOnClick} />;
}
