import { CodeEditor } from '@components/CodeEditor';

export interface CodeViewerProps {
  value: string;
  height?: number;
  defaultLanguage?: string;
}

export default function CodeViewer({ value, height = 300, defaultLanguage }: CodeViewerProps) {
  return (
    <CodeEditor
      value={value}
      height={height}
      defaultLanguage={defaultLanguage}
      options={{
        readOnly: true,
        lineNumbers: 'off',
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        glyphMargin: false,
      }}
    />
  );
}
