import { Box, Divider, Stack, Typography } from '@mui/material';
import { isValidElement, ReactNode } from 'react';

export function DrawerDetail({
  label,
  value,
  noDivider,
}: {
  label: string;
  value: ReactNode | string;
  noDivider?: boolean;
}) {
  return (
    <>
      <Box paddingY={1.5} paddingX={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Typography variant="body2" sx={{ width: '100px' }} color="text.muted">
            {label}:
          </Typography>
          {isValidElement(value) ? value : <Typography variant="inputLabel">{value}</Typography>}
        </Stack>
      </Box>
      {!noDivider && <Divider />}
    </>
  );
}
