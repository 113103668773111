import { FrontierService } from '@api';
import { AlertFilterInput } from '@api/gql/graphql';
import { useQuery } from '@tanstack/react-query';
import { alertStatsOverTime } from './gql';
import { AlertsQueryKeys } from './queryKeys';

export function useAlertStatsOverTime({ filter }: { filter: AlertFilterInput }) {
  return useQuery([AlertsQueryKeys.AlertStatsOverTime, filter], () =>
    FrontierService.request(alertStatsOverTime, { filter }),
  );
}
