import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from '@routes';
import { useGlobalNotifier } from '@hooks';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { AccessBundleAppModel, useCanDeleteAccessBundle } from '@api';
import { ActionsMenuV2, ConfirmDialog, ConfirmDialogProps, LabeledTextField } from '@components';
import { Button, DialogActions, Link, Stack } from '@mui/material';
import { RouterLink } from '@libs';
import { useAppCreateBundle, useAppDeleteBundle } from '@AccessFlows/services/bundlesMutations';
import { LoadingButton } from '@mui/lab';
import AppModal from '@common/ui/AppModal';
function useItemDuplicate(onSuccess: () => void) {
  const { track } = useAnalyticsContext();
  const { notifySuccess } = useGlobalNotifier();
  const {
    createBundle,
    data: duplicatedAccessBundle,
    isLoading: isDuplicateLoading,
    isSuccess: isDuplicateSuccess,
  } = useAppCreateBundle();
  useEffect(() => {
    if (!isDuplicateSuccess || !duplicatedAccessBundle) return;
    track(CustomAnalyticsEvents.BUNDLE_DUPLICATED, {
      policyId: duplicatedAccessBundle.id,
    });
    notifySuccess(`Bundle ${duplicatedAccessBundle.name} duplicated`);
    if (onSuccess) onSuccess();
  }, [isDuplicateSuccess, notifySuccess, onSuccess, duplicatedAccessBundle, track, isDuplicateLoading]);
  const duplicate = (duplicateBundle: AccessBundleAppModel) => createBundle(duplicateBundle);
  return {
    duplicate,
    isDuplicateLoading,
    isDuplicateSuccess,
  };
}
function useItemDelete(bundle: AccessBundleAppModel) {
  const { track } = useAnalyticsContext();
  const { notifySuccess } = useGlobalNotifier();
  const { deleteBundle, isLoading: isDeleteLoading, isSuccess } = useAppDeleteBundle(bundle.id);
  useEffect(() => {
    if (!isSuccess) return;
    track(CustomAnalyticsEvents.BUNDLE_DELETED, {
      bundleId: bundle.id,
    });
    notifySuccess(`Access flow ${bundle.name} deleted`);
  }, [isSuccess, bundle, track, notifySuccess]);
  return {
    deleteBundle,
    isDeleteLoading,
  };
}
export default function BundleItemActions({ bundle }: { bundle: AccessBundleAppModel }) {
  const navigate = useNavigate();
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);
  const { deleteBundle, isDeleteLoading } = useItemDelete(bundle);
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        component={RouterLink}
        to={`${routes.AddAccessFlow.path}?fromBundle=${bundle.id}`}
        data-trigger={CustomAnalyticsEvents.ACCESS_FLOW_CREATE_INITIATED_IN_BUNDLE_LIST}
      >
        Create Access Flow
      </Button>
      <ActionsMenuV2
        loading={isDeleteLoading}
        options={[
          {
            label: 'Edit',
            iconSymbol: 'edit_square',
            onClick: () => navigate(generatePath(routes.EditBundle.path, { id: bundle.id })),
          },
          {
            label: 'Duplicate',
            iconSymbol: 'content_copy',
            onClick: () => setIsDuplicateModalOpen(true),
          },
          {
            label: 'Delete',
            iconSymbol: 'delete',
            onClick: () => setIsConfirmDeleteOpened(true),
          },
        ]}
      />
      {isConfirmDeleteOpened && (
        <ConfirmDeleteDialog
          open={isConfirmDeleteOpened}
          onClose={() => setIsConfirmDeleteOpened(false)}
          bundle={bundle}
          onConfirm={deleteBundle}
        />
      )}
      {isDuplicateModalOpen && (
        <DuplicateBundleModal
          open={isDuplicateModalOpen}
          onClose={() => setIsDuplicateModalOpen(false)}
          bundle={bundle}
        />
      )}
    </Stack>
  );
}
function DuplicateBundleModal({
  open,
  onClose,
  bundle,
}: {
  open: boolean;
  onClose: () => void;
  bundle: AccessBundleAppModel;
}) {
  const { duplicate, isDuplicateLoading } = useItemDuplicate(onClose);
  const [duplicateBundle, setDuplicateBundle] = useState({ ...bundle, name: bundle.name + ' (Copy)' });
  return (
    <AppModal open={open} onClose={onClose} header="Duplicate Bundle">
      <Stack spacing={1}>
        <LabeledTextField
          label="Name"
          value={duplicateBundle.name}
          onChange={(e) => setDuplicateBundle({ ...duplicateBundle, name: e.target.value })}
        />
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => duplicate(duplicateBundle)}
            loading={isDuplicateLoading}
          >
            Duplicate
          </LoadingButton>
        </DialogActions>
      </Stack>
    </AppModal>
  );
}

function ConfirmDeleteDialog({
  bundle,
  open,
  onClose,
  onConfirm,
}: ConfirmDialogProps & {
  bundle: AccessBundleAppModel;
}) {
  const { canDeleteAccessBundle, isCanDeleteAccessBundleLoading } = useCanDeleteAccessBundle(bundle.id);

  if (isCanDeleteAccessBundleLoading) {
    return <ConfirmDialog open={open} onClose={onClose} loading={isCanDeleteAccessBundleLoading}></ConfirmDialog>;
  }

  if (!canDeleteAccessBundle?.can_delete) {
    return (
      <ConfirmDialog open={open} onClose={onClose} disproveLabel="Ok">
        <div>
          This bundle is used by those access flows:
          <ul>
            {(canDeleteAccessBundle?.access_flows_used || []).map((flow) => (
              <li key={flow.id}>
                <Link
                  component={RouterLink}
                  to={generatePath(routes.EditAccessFlow.path, { id: flow.id })}
                  underline="hover"
                >
                  {flow.name}
                </Link>
              </li>
            ))}
          </ul>
          Please, remove it from the flows before deleting.
        </div>
      </ConfirmDialog>
    );
  }

  return (
    <ConfirmDialog open={open} onClose={onClose} onConfirm={onConfirm}>
      Are you sure you want to delete <strong>{bundle.name}</strong> bundle?
    </ConfirmDialog>
  );
}
