import { Avatar, styled } from '@mui/material';

export interface ResourceAccessIconProps {
  src: string;
  alt?: string;
  height?: string;
  width?: string;
}

const ResourceAccessIconAvatar = styled(Avatar)(({ theme }) => ({
  margin: '0 auto',
  '&.MuiAvatar-colorDefault': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

export function ResourceAccessIcon({ src, alt, height = '100%', width = 'auto' }: ResourceAccessIconProps) {
  return (
    <ResourceAccessIconAvatar alt={alt} src={src} imgProps={{ sx: { width, height } }} variant="rounded" sx={{}} />
  );
}
