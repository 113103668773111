import { FrontierService } from '@api';
import { graphql } from '@api/gql';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from './query-keys';

export const activeAccessStatsFields = graphql(/* GraphQL */ `
  fragment activeAccessStatsFields on ActiveAccessStats {
    __typename
    automaticApproval
    manualApproval
    autoGrant
  }
`);

export const activeAccessStatsQueryDocument = graphql(/* GraphQL */ `
  query ActiveAccessStats {
    activeAccessStats {
      ...activeAccessStatsFields
    }
  }
`);

export function useActiveAccessStats() {
  return useQuery([QueryKeys.ActiveAccessStats], () => FrontierService.request(activeAccessStatsQueryDocument));
}

// Access Flow Usage Stats
export const accessFlowsUsageStatsFields = graphql(/* GraphQL */ `
  fragment accessFlowsUsageStatsFields on AccessFlowsUsageStats {
    __typename
    requested
    requestedIds
    notRequested
    notRequestedIds
  }
`);

export const accessFlowUsageQueryDocument = graphql(/* GraphQL */ `
  query AccessFlowsUsageStats {
    accessFlowsUsageStats {
      ...accessFlowsUsageStatsFields
    }
  }
`);

export function useAccessFlowUsageStats() {
  return useQuery([QueryKeys.AccessFlowUsageStats], () => FrontierService.request(accessFlowUsageQueryDocument));
}

// New Requests Stats
export const newRequestsStatsFields = graphql(/* GraphQL */ `
  fragment newRequestsStatsFields on NewRequestsStats {
    __typename
    current
    previous
  }
`);

export const newRequestsQueryDocument = graphql(/* GraphQL */ `
  query NewRequestsStats {
    newRequestsStats {
      ...newRequestsStatsFields
    }
  }
`);

export function useNewRequestsStats() {
  return useQuery([QueryKeys.NewRequestStats], () => FrontierService.request(newRequestsQueryDocument));
}

// Attack Surface Reduction Stats
export const attackSurfaceReductionStatsFields = graphql(/* GraphQL */ `
  fragment attackSurfaceReductionStatsFields on AttackSurfaceStats {
    currentPeriod
    previousPeriod
  }
`);

export const attackSurfaceReductionQueryDocument = graphql(/* GraphQL */ `
  query AttackSurfaceStats {
    attackSurfaceStats {
      ...attackSurfaceReductionStatsFields
    }
  }
`);

export function useAttackSurfaceReductionStats() {
  return useQuery([QueryKeys.AttackSurfaceReductionStats], () =>
    FrontierService.request(attackSurfaceReductionQueryDocument),
  );
}

export const integrationUsageStatsFields = graphql(/* GraphQL */ `
  query GetRequestsPerIntegrationStats($take: Int) {
    requestsPerIntegrationStats(take: $take) {
      integration {
        id
        name
        typeDisplayName
        icons {
          svg
        }
      }
      totalRequests
    }
  }
`);

export function useIntegrationUsageStats({ take }: { take?: number } = {}) {
  return useQuery([QueryKeys.IntegrationUsageStats], () =>
    FrontierService.request(integrationUsageStatsFields, { take }),
  );
}

export const resourceTypesUsageStatsFields = graphql(/* GraphQL */ `
  query GetRequestsPerResourceTypeStats($take: Int) {
    requestsPerResourceTypeStats(take: $take) {
      integration {
        id
        name
      }
      resourceType {
        type
        typeDisplayName
        icons {
          svg
        }
      }
      totalRequests
    }
  }
`);

export function useResourceTypesUsageStats({ take }: { take?: number } = {}) {
  return useQuery([QueryKeys.ResourceTypesUsageStats], () =>
    FrontierService.request(resourceTypesUsageStatsFields, { take }),
  );
}
