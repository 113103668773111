import { selector } from 'recoil';
import { AccountsService, AponoUserModel, AponoGroupModel } from '@api';

export const usersSelector = selector<AponoUserModel[]>({
  key: 'UsersSelector',
  get: async () => AccountsService.listUsers(),
});

export const groupsSelector = selector<AponoGroupModel[]>({
  key: 'GroupsSelector',
  get: async () => AccountsService.listGroups(),
});
