import { useQuery } from '@tanstack/react-query';
import { TopQueryEntityType } from '@api/apn-core/api';
import { DashboardService } from '@api/services';
import { QueryKeys } from './query-keys';

enum DashboardQueryKeys {
  AccessRequests = 'AccessRequests',
  AccessUnits = 'AccessUnits',
  AccessOverTime = 'AccessOverTime',
  TopOverTime = 'TopOverTime',
}

export function useQueryCurrentAccessRequests() {
  const {
    data: currentAccessRequests,
    isFetched: isCurrentAccessRequestsFetched,
    fetchStatus,
    refetch: refetchCurrentAccessRequests,
  } = useQuery({
    queryKey: [QueryKeys.Dashboard, DashboardQueryKeys.AccessRequests],
    queryFn: async () => DashboardService.queryCurrentAccessRequests(),
    initialData: {
      granted_access_requests: 0,
      pending_access_requests: 0,
    },
    refetchOnWindowFocus: false,
  });

  return {
    currentAccessRequests,
    isCurrentAccessRequestsFetched,
    isLoadingCurrentAccessRequests: fetchStatus === 'fetching',
    refetchCurrentAccessRequests,
  };
}

export function useQueryAccessOverTime(endDate: string, startDate: string) {
  const {
    data: accessOverTime,
    isFetched: isAccessOverTimeFetched,
    fetchStatus,
    refetch: refetchAccessOverTime,
  } = useQuery({
    queryKey: [QueryKeys.Dashboard, DashboardQueryKeys.AccessOverTime],
    queryFn: async () =>
      DashboardService.queryAccessOverTime({
        endDate: endDate as unknown as number,
        startDate: startDate as unknown as number,
      }),
    initialData: {
      data_points: [],
    },
    refetchOnWindowFocus: false,
  });

  return {
    accessOverTime,
    isAccessOverTimeFetched,
    isLoadingAccessOverTime: fetchStatus === 'fetching',
    refetchAccessOverTime,
  };
}

export function useQueryTopOverTime(endDate: string, startDate: string, type: TopQueryEntityType, limit = 5) {
  const {
    data: topOverTime,
    isFetched: isTopOverTimeFetched,
    fetchStatus,
    refetch: refetchTopOverTime,
  } = useQuery({
    queryKey: [QueryKeys.Dashboard, DashboardQueryKeys.TopOverTime, type],
    queryFn: async () =>
      DashboardService.queryTopOverTime({
        endDate: endDate as unknown as number,
        limit,
        startDate: startDate as unknown as number,
        type,
      }),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  return {
    topOverTime,
    isTopOverTimeFetched,
    isLoadingTopOverTime: fetchStatus === 'fetching',
    refetchTopOverTime,
  };
}
