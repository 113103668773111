import React from 'react';
import { Button, ButtonProps as MuiButtonProps, ButtonTypeMap } from '@mui/material';

import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';
import { UpgradeWrapper } from '@components';

export type ProButtonProps<D extends React.ElementType = ButtonTypeMap['defaultComponent'], P = {}> = MuiButtonProps<
  D,
  P
> & {
  flag: Flag;
  component?: React.ElementType;
};

export function ProButton<D extends React.ElementType = ButtonTypeMap['defaultComponent'], P = {}>({
  flag,
  component,
  ...restProps
}: ProButtonProps<D, P>) {
  const { isPremium } = useFlagFixed(flag);

  return (
    <UpgradeWrapper isPremium={isPremium}>
      <Button {...restProps} disabled={isPremium || restProps.disabled} component={component} />
    </UpgradeWrapper>
  );
}
