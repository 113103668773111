import { useEffect, useMemo, useRef, useState } from 'react';
import { FilterProps } from '@common/organisms/CommonFilters/types';
import { useAuditEventTypes } from '@AuditEvents/services/use-audit-events-filter-options';
import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterSingleSelect from '@components/ListFilters/ListFilterSingleSelect';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { AnalyticsTriggerElementProps } from '@common/types';

export const EVENT_TYPE_LABEL = 'Event Type';
export const EVENT_TYPE_FILTER_PARAM = 'event_type';

export default function AuditEventTypeFilter({
  opened,
  onClose,
  onOpen,
  selected,
  onClear,
  onSelect,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps) {
  const [enabled] = useState(() => selected.length > 0);

  const { data, isFetched, refetch } = useAuditEventTypes(enabled);

  const options = useMemo(() => {
    return data
      ? data.auditEventTypes.map((type) => ({
          value: type,
          label: type,
        }))
      : [];
  }, [data]);

  const selectedOption =
    useMemo(() => selected.length > 0 && options.find((option) => option.value === selected[0]), [selected, options]) ||
    undefined;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const handleOnSelect = (value: ListFilterOption) => {
    onSelect([value.value]);
    closeDropdown();
  };

  const handleOnClear = () => {
    onClear();
    closeDropdown();
  };

  const closeDropdown = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    !opened && onOpen && onOpen();
    setAnchorEl(event.currentTarget);
    if (!isFetched) {
      refetch();
    }
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isFetched) {
        refetch();
      }
    }
  }, [isFetched, opened, ref, refetch]);

  const isFetching = !isFetched && (opened || selected.length > 0);
  const open = (opened && Boolean(anchorEl)) || false;

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={EVENT_TYPE_LABEL}
        value={selectedOption ? [selectedOption] : []}
        onClear={handleOnClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterSingleSelect
            options={options}
            value={selectedOption}
            onSelect={handleOnSelect}
            title={`Select ${EVENT_TYPE_LABEL} Filter`}
            loading={isFetching}
            {...analyticsProps}
          />
        </Box>
      </Popover>
    </>
  );
}
