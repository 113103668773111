import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PremiumPage } from '@components';
import { DocsUrls, Flag } from '@utils';
import { useGlobalNotifier } from '@hooks';
import { routes } from '@routes';

import { useAppCreateWebhook } from '../services/webhooksMutations';
import WebhookForm from '../organisms/WebhookForm';

export default function CreateWebhookForm() {}

export function AddWebhookPage() {
  const navigate = useNavigate();
  const { notifySuccess, notifyServerError } = useGlobalNotifier();
  const { createWebhook, isLoading, isSuccess } = useAppCreateWebhook();

  useEffect(() => {
    if (isSuccess) {
      notifySuccess('Webhook successfully created');
      navigate(routes.Webhooks.path);
    }
  }, [isSuccess, notifySuccess, notifyServerError, navigate]);

  return (
    <PremiumPage
      flag={Flag.OUTBOUND_WEBHOOKS}
      breadcrumbs={[
        {
          label: routes.Webhooks.label,
          href: routes.Webhooks.path,
        },
      ]}
      title={routes.AddWebhook.label}
      subTitle="Adding webhooks allows you to send data to a third-party systems or your own server"
      link={{
        label: 'Read Documentation',
        href: DocsUrls.WEBHOOKS,
      }}
      contentPadded
    >
      <WebhookForm loading={isLoading} onSubmit={createWebhook} />
    </PremiumPage>
  );
}
