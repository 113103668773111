import { generatePath } from 'react-router-dom';
import { Link, Paper, Stack, Typography } from '@mui/material';

import { DateTime, Flag } from '@utils';
import { RouterLink } from '@libs';
import { routes } from '@routes';

import { ActivityRequestAppModel } from '@api';
import TriggerTypeChip from '@common/ui/TriggerTypeChip';
import { strToTriggerType } from '../../utils';
import { useFlagFixed } from '@hooks';
import { DrawerDetail } from '@common/ui/DrawerDetail/DrawerDetail';
import { StatusColumn } from '@components';

export default function RequestDetails({ request }: { request: ActivityRequestAppModel }) {
  const { isEnabled: isSessionAuditEnabled } = useFlagFixed(Flag.SESSION_AUDIT);

  return (
    <Paper>
      <DrawerDetail label="Access ID" value={request.friendly_id} />
      <DrawerDetail label="Creation date" value={DateTime.fromUnixWithFormat(Number(request.created_date))} />
      {request.access_flow && (
        <DrawerDetail
          label="Access Flow"
          value={
            <Typography variant="caption">
              <Link
                component={RouterLink}
                to={generatePath(routes.EditAccessFlow.path, {
                  id: request.access_flow.id,
                })}
              >
                {request.access_flow.name}
              </Link>
            </Typography>
          }
        />
      )}
      {request.trigger_type && (
        <DrawerDetail
          label="Access type"
          value={<TriggerTypeChip triggerType={strToTriggerType(request.trigger_type)} />}
        />
      )}
      <DrawerDetail
        label="Grantee"
        value={
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ flex: 1 }}>
            <Typography variant="caption">
              {request.user.name}{' '}
              <Typography variant="caption" component="span" color="text.muted">
                ({request.user.email})
              </Typography>
            </Typography>
            {isSessionAuditEnabled && request.user.email && (
              <Link
                component={RouterLink}
                to={`${generatePath(routes.AuditEvents.path)}?user=${request.user.email}`}
                variant="caption"
              >
                See Session Audit
              </Link>
            )}
          </Stack>
        }
      />
      {request.duration_in_sec > 0 && (
        <DrawerDetail label="Duration" value={`${request.duration_in_sec / 3600} hours`} />
      )}
      {!!request.justification && <DrawerDetail label="Justification" value={request.justification} />}
      {request.access_bundle && (
        <DrawerDetail
          label="Bundle"
          value={
            <Typography variant="caption">
              <Link
                component={RouterLink}
                to={generatePath(routes.EditBundle.path, {
                  id: request.access_bundle.id,
                })}
              >
                {request.access_bundle.name}
              </Link>
            </Typography>
          }
        />
      )}
      <DrawerDetail label="Status" value={<StatusColumn status={request.status} />} noDivider />
    </Paper>
  );
}
