// eslint-disable-next-line no-restricted-imports
import dayjs, { extend } from 'dayjs';
import duration, { Duration as DayjsDuration } from 'dayjs/plugin/duration';
import capitalize from 'lodash/capitalize';
import { DurationUnit } from './DurationUnit';

extend(duration);

export class Duration {
  private readonly duration: DayjsDuration;

  private constructor(dayjsDuration: DayjsDuration) {
    this.duration = dayjsDuration;
  }

  get toSeconds(): number {
    return this.duration.asSeconds();
  }

  get readable() {
    return capitalize(this.duration.humanize());
  }

  get asMinimalDurationTuple(): { unit: DurationUnit; seconds: number } {
    if (this.duration.asSeconds() === -1) return { unit: DurationUnit.Indefinite, seconds: -1 };
    if (this.duration.asYears() % 1 === 0) return { unit: DurationUnit.Years, seconds: this.duration.asYears() };
    if (this.duration.asMonths() % 1 === 0) return { unit: DurationUnit.Months, seconds: this.duration.asMonths() };
    if (this.duration.asWeeks() % 1 === 0) return { unit: DurationUnit.Weeks, seconds: this.duration.asWeeks() };
    if (this.duration.asDays() % 1 === 0) return { unit: DurationUnit.Days, seconds: this.duration.asDays() };
    if (this.duration.asHours() % 1 === 0) return { unit: DurationUnit.Hours, seconds: this.duration.asHours() };
    if (this.duration.asMinutes() % 1 === 0) return { unit: DurationUnit.Minutes, seconds: this.duration.asMinutes() };
    return { unit: DurationUnit.Seconds, seconds: this.duration.asSeconds() };
  }

  static fromSeconds(secondsDuration: number): Duration {
    return new Duration(dayjs.duration(secondsDuration, 'seconds'));
  }

  static fromMinutes(minutesDuration: number) {
    return new Duration(dayjs.duration(minutesDuration, 'minutes'));
  }

  static fromHours(hoursDuration: number) {
    return new Duration(dayjs.duration(hoursDuration, 'hours'));
  }

  static fromDays(daysDuration: number) {
    return new Duration(dayjs.duration(daysDuration, 'days'));
  }

  static fromWeeks(weeksDuration: number) {
    return new Duration(dayjs.duration(weeksDuration, 'weeks'));
  }

  static fromMonths(monthDuration: number) {
    return new Duration(dayjs.duration(monthDuration, 'months'));
  }

  static fromYears(yearsDuration: number) {
    return new Duration(dayjs.duration(yearsDuration, 'years'));
  }

  public toString = () => this.readable;
}
