import { ResourceAppModel, IntegrationConfigAppModelV2 } from '@api';

export default function buildHierarchyPath(
  resource?: ResourceAppModel,
  integrationConfig?: IntegrationConfigAppModelV2,
  skipFirstPart = false,
  skipLastPart = false,
) {
  if (!resource || !integrationConfig) return { logicalPath: '', literalPath: '' };

  const hierarchyItem = integrationConfig.resource_types[resource.type];
  const hierarchyParticles = hierarchyItem.hierarchy.split('/');
  const logicalPathArr: string[] = [];
  const literalPathArr: string[] = [];
  hierarchyParticles.forEach((resourceType, index) => {
    if (resource.path[resourceType]) {
      literalPathArr[index] = resource.path[resourceType];
    }
    const logicalParticleDisplayName = integrationConfig.resource_types[resourceType].display_name;
    logicalPathArr[index] = logicalParticleDisplayName;
  });

  if (skipFirstPart) {
    if (logicalPathArr.length > 0) logicalPathArr.shift();
    if (literalPathArr.length > 0) literalPathArr.shift();
  }

  if (skipLastPart) {
    if (logicalPathArr.length > 0) logicalPathArr.pop();
    if (literalPathArr.length > 0) literalPathArr.pop();
  }
  const logicalPath = logicalPathArr.length > 0 ? logicalPathArr.join('/') : '-';
  const literalPath = literalPathArr.length > 0 ? literalPathArr.join('/') : '';

  return { logicalPath, literalPath };
}
