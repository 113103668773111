import { Box, Divider, Typography } from '@mui/material';
import useAnimatedLabel from '@common/hooks/use-animated-label';
import { styled } from '@mui/system';

function DividerText({ text, minimized }: { text: string; minimized?: boolean }) {
  const labelVisible = useAnimatedLabel({ visible: minimized, enabled: true });

  const BoxStyled = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'block',
    height: theme.spacing(5),
  }));

  return (
    <BoxStyled>
      {labelVisible ? (
        <Typography variant="helperText" color="dark.main">
          {text}
        </Typography>
      ) : (
        <Divider />
      )}
    </BoxStyled>
  );
}

export default DividerText;
