import { ReactNode } from 'react';
import { Avatar, ListSubheader, Stack, Typography } from '@mui/material';

export interface ListFilterItemsCategoryProps {
  type: 'category';
  label: string;
  icon?: ReactNode;
}

export function isListFilterItemsCategoryProps(
  props: ListFilterItemsCategoryProps,
): props is ListFilterItemsCategoryProps {
  return props.type === 'category';
}

export default function ListFilterItemsCategory({ label, icon }: ListFilterItemsCategoryProps) {
  const iconElem = icon ? typeof icon === 'string' ? <Avatar src={icon} className="size-20" /> : icon : undefined;

  return (
    <ListSubheader component="div">
      <Stack direction="row" alignItems="center" spacing={1}>
        {iconElem}
        <Typography variant="inherit">{label}</Typography>
      </Stack>
    </ListSubheader>
  );
}
