import { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

export interface CodeLineProps {
  width?: number;
}

export default function CodeLine({ width, children }: PropsWithChildren<CodeLineProps>) {
  return (
    <Typography noWrap variant="code" sx={{ maxWidth: width }}>
      {children}
    </Typography>
  );
}
