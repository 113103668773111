import { CircularProgress, Fade, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const useCycleSentences = (sentences: string[], delay: number) => {
  const [index, setIndex] = useState(0);
  const [inProp, setInProp] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setInProp(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        setInProp(true);
      }, 500);
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, [delay, sentences]);

  return { sentence: sentences[index], inProp };
};

interface LoadingSentencesProps {
  sentences: string[];
  cycleTime?: number;
  textVariant?: 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'h5';
  direction?: 'row' | 'column';
}

export default function LoadingSentences({
  sentences,
  cycleTime = 2000,
  textVariant = 'subtitle1',
  direction = 'column',
}: LoadingSentencesProps) {
  const { sentence, inProp } = useCycleSentences(sentences, cycleTime);

  return (
    <Stack direction={direction} alignItems="center" spacing={2}>
      <CircularProgress />
      <Fade in={inProp}>
        <Typography variant={textVariant}>{sentence}</Typography>
      </Fade>
    </Stack>
  );
}
