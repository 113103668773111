import { PropsWithChildren, ReactNode } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';

import AppModal from '@common/ui/AppModal';
import PrimarySecondary from '@common/ui/PrimarySecondary';

interface AccessFlowReviewModalProps {
  open: boolean;
  title: string;
  subTitle?: string;
  onClose: () => void;
  text?: string;
  actions?: ReactNode;
  width?: number;
}

export default function AccessFlowReviewModal({
  open,
  title,
  subTitle,
  onClose,
  children,
  text,
  actions,
  width = 900,
}: PropsWithChildren<AccessFlowReviewModalProps>) {
  return (
    <AppModal
      header={<PrimarySecondary primary={title} secondary={subTitle} variant="big" />}
      open={open}
      onClose={onClose}
      width={width}
    >
      <Stack spacing={2}>
        <Card elevation={0}>
          <CardContent>{children}</CardContent>
        </Card>
        {text && (
          <Typography variant="body1" color="text.muted">
            {text}
          </Typography>
        )}
        {actions}
      </Stack>
    </AppModal>
  );
}
