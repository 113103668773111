import { Button, ButtonProps, Stack, Tooltip } from '@mui/material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

export function FormSectionActions({
  backProps,
  nextProps,
  nextText = 'Next',
  backText = 'Back',
}: {
  backProps?: ButtonProps;
  nextProps?: LoadingButtonProps & { disabledtooltiptext?: string };
  nextText?: string;
  backText?: string;
}) {
  return (
    <Stack direction="row" spacing={2} alignSelf="flex-end">
      {backProps && (
        <Button variant="outlined" {...backProps}>
          {backText}
        </Button>
      )}
      {nextProps &&
        (nextProps.disabled && nextProps.disabledtooltiptext ? (
          <Tooltip title={nextProps.disabledtooltiptext} arrow placement="right">
            <span>
              <LoadingButton variant="contained" disabled={nextProps.disabled} {...nextProps}>
                {nextText}
              </LoadingButton>
            </span>
          </Tooltip>
        ) : (
          <LoadingButton variant="contained" {...nextProps}>
            {nextText}
          </LoadingButton>
        ))}
    </Stack>
  );
}
