import { METRIC_TYPES } from '@AccessRightSizing/common/constants';
import {
  ACCESS_FLOW_FRAGMENT,
  ACCESS_FLOW_METRICS_FRAGMENT,
  AccessFlowUsageMetricValue_Fragment,
} from '@AccessRightSizing/services/gql';
import { getFragmentData } from '@api/gql';
import { useNavigate } from 'react-router-dom';
import AccessRightSizingItemGrid from './AccessRightSizingItemGrid';
import { Box, Button, Typography } from '@mui/material';
import { routes } from '@routes';
import { MaterialIcon } from '@components';
import { RightSizingReportFieldsFragment } from '@api/gql/graphql';

export function AccessRightSizingListItem({ report }: { report: RightSizingReportFieldsFragment }) {
  const navigate = useNavigate();

  const accessFlow = getFragmentData(ACCESS_FLOW_FRAGMENT, report.accessFlow);
  const metrics = getFragmentData(ACCESS_FLOW_METRICS_FRAGMENT, report.metrics);
  let total_permissions_not_requested,
    total_users_not_requesting,
    avg_access_request_duration,
    total_resources_not_requested = '';
  metrics.forEach((metric) => {
    const unusedValues = getFragmentData(AccessFlowUsageMetricValue_Fragment, metric.currentValue);
    const unusedPercentage = ((unusedValues.notUsed / unusedValues.defined) * 100).toFixed(1);
    switch (metric.metricType) {
      case METRIC_TYPES.total_permissions_not_requested:
        total_permissions_not_requested = unusedValues.notUsed ? `${unusedPercentage}%` : '0%';
        break;
      case METRIC_TYPES.total_users_not_requesting:
        total_users_not_requesting = unusedValues.notUsed ? `${unusedPercentage}%` : '0%';
        break;
      case METRIC_TYPES.avg_access_request_duration:
        avg_access_request_duration = unusedValues.notUsed ? `${unusedPercentage}%` : '0%';
        break;
      case METRIC_TYPES.total_resources_not_requested:
        total_resources_not_requested = unusedValues.notUsed ? `${unusedPercentage}%` : '0%';
        break;
    }
  });
  return (
    <Box>
      <AccessRightSizingItemGrid
        accessFlows={accessFlow ? <Typography variant="subtitle2">{accessFlow.name}</Typography> : null}
        unusedResources={<Typography variant="subtitle2">{total_resources_not_requested}</Typography>}
        unusedPermissions={<Typography variant="subtitle2">{total_permissions_not_requested}</Typography>}
        usersNotRequesting={<Typography variant="subtitle2">{total_users_not_requesting}</Typography>}
        avgDurationRequested={<Typography variant="subtitle2">{avg_access_request_duration}</Typography>}
        actions={
          <Button
            onClick={() => navigate(`${routes.AccessRightSizing.path}?access_flow_id=${report.id}`)}
            endIcon={<MaterialIcon symbol="east" weight={800} />}
          >
            More Details
          </Button>
        }
      />
    </Box>
  );
}
