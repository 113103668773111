import { Fragment, ReactNode } from 'react';

import { FlowFormErrorProps, FlowFormText, FlowInlineText, FlowInlineTextProps } from './Text';
import { Tooltipped } from './Tooltipped';
import { Typography } from '@mui/material';

export interface HumanReadablePlaceholderProps {
  placeholder?: ReactNode;
  placeholderMuted?: boolean;
  noTooltip?: boolean;
}

export interface HumanReadableTextProps extends HumanReadablePlaceholderProps, FlowInlineTextProps, FlowFormErrorProps {
  slice?: number;
  value?: ReactNode;
  values?: ReactNode[];
}

export function HumanReadableText({
  inline,
  noTooltip,
  value,
  values,
  placeholder,
  placeholderMuted,
  errorText,
  hideErrorText,
  slice = 1,
}: HumanReadableTextProps) {
  if ((!value && !values) || (values && values.length === 0)) {
    return inline ? (
      <FlowInlineText inline={inline}>{placeholder}</FlowInlineText>
    ) : (
      <FlowFormText selectable muted={placeholderMuted} {...{ errorText, hideErrorText }}>
        {placeholder}
      </FlowFormText>
    );
  }

  let text = value;
  if (values) {
    const sliced = values.slice(0, slice) || [];
    const more = values.slice(slice) || [];

    text = (
      <span>
        {sliced.map((v, i) => (
          <Fragment key={i}>
            {i > 0 && <OrText inline={inline} />}
            {v}
          </Fragment>
        ))}
        {more.length > 0 && (
          <>
            {' '}
            <SingleHumanReadableMore values={more} noTooltip={noTooltip} />
          </>
        )}
      </span>
    );
  }

  return <SingleHumanReadableText value={text} inline={inline} />;
}

interface SingleHumanReadableMoreProps {
  values: ReactNode[];
  noTooltip?: boolean;
  andPrefix?: boolean;
}

function SingleHumanReadableMore({ values, noTooltip, andPrefix }: SingleHumanReadableMoreProps) {
  const moreText = <span>(+{values.length})</span>;

  if (noTooltip) {
    return moreText;
  }

  return (
    <Tooltipped values={values} andPrefix={andPrefix}>
      {moreText}
    </Tooltipped>
  );
}

interface SingleHumanReadableTextProps extends FlowInlineTextProps, FlowFormErrorProps {
  value: ReactNode;
  muted?: boolean;
  errorText?: ReactNode;
}

function SingleHumanReadableText({ value, inline, muted, errorText, hideErrorText }: SingleHumanReadableTextProps) {
  if (inline) {
    return <FlowInlineText inline={inline}>{value}</FlowInlineText>;
  }

  return (
    <FlowFormText selectable muted={muted} {...{ errorText, hideErrorText }}>
      {value}
    </FlowFormText>
  );
}

function OrText({ inline = false }: FlowInlineTextProps) {
  return inline ? (
    <Typography variant="inherit" color="text.muted" component="span">
      {' '}
      or{' '}
    </Typography>
  ) : (
    <FlowFormText muted> or </FlowFormText>
  );
}
