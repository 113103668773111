import { Page } from '@components';
import { CatalogV2 } from '@organisms';
import { routes } from '@routes';

export function OnboardingCatalogPage() {
  return (
    <Page title="Catalog" subTitle="Which app would you like to start with?">
      <CatalogV2
        withCategoryFilters={false}
        enforceCategory="Resources"
        paths={{
          connectIntegration: routes.OnboardingConnectIntegration.path,
          connectIntegrationGroup: routes.OnboardingConnectIntegrationsGroup.path,
          addIntegration: routes.AddIntegration.path,
        }}
        titles={{
          available: 'Available for you',
          premium: 'Later',
          premiumDescription:
            'Talk to us to connect Resource Integrations, ChatOps, Identity Providers and OnCall tools',
        }}
      />
    </Page>
  );
}
