import { useMemo } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import { useFormContext } from 'react-hook-form';
import AttributeGroupSelect, { AttributeGroupSelectValue } from '@common/organisms/AttributeGroupSelect';
import AttributeValueSelect from '@common/organisms/AttributeValueSelect';
import FormSectionHeader from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionHeader';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export function IntegrationOwnerFormSection({
  divider = true,
  isViewOnly,
}: {
  divider?: boolean;
  isViewOnly?: boolean;
}) {
  const { track } = useAnalyticsContext();
  const { setValue, watch } = useFormContext<IntegrationConfigFormPayload>();

  const owners = watch('integration_owners.owners');
  const resourceOwners = watch('resource_owners_mappings');
  const firstOwner = useMemo(() => owners?.[0], [owners]);

  const selectedAttribute = useMemo(() => {
    if (!firstOwner) return;
    return {
      integrationId: firstOwner.integration_id,
      attributeType: firstOwner.attribute_type_id,
    };
  }, [firstOwner]);

  const selectedAttributeValues = useMemo(() => {
    return firstOwner?.attribute_value || [];
  }, [firstOwner]);

  const handleAttributeChange = (newVal: AttributeGroupSelectValue) => {
    setValue('integration_owners.owners', [
      {
        integration_id: newVal.integrationId,
        attribute_type_id: newVal.attributeType,
        attribute_value: [],
      },
    ]);
  };

  const handleAttributeValueChange = (newVal: string[]) => {
    if (!firstOwner) return;

    setValue('integration_owners.owners', [
      {
        ...firstOwner,
        attribute_value: newVal,
      },
    ]);

    track(CustomAnalyticsEvents.OWNER_INTEGRATION_ATTRIBUTE_AND_VALUE_SELECTED);
  };

  const isOptional = useMemo(() => {
    if (resourceOwners && resourceOwners.length > 0) return false;

    if (!firstOwner) return true;
    return !firstOwner.attribute_type_id && !firstOwner.attribute_value.length;
  }, [firstOwner, resourceOwners]);

  return (
    <Stack spacing={2}>
      <FormSectionHeader
        optional
        title="Integration Owner"
        tooltip="Apono can use the integration owner for access requests approval if no owner is found. Enter one or more users, groups, shifts or attributes. This field is mandatory when using Resource Owners and serves as a fallback approver if no resource owner is found."
      />
      <Box>
        <Stack direction="row" spacing={2} justifyContent="center">
          <AttributeGroupSelect
            value={selectedAttribute}
            onChange={handleAttributeChange}
            preload={!!selectedAttribute}
            optional={isOptional}
            isViewOnly={isViewOnly}
          />
          <AttributeValueSelect
            props={{
              integrationId: selectedAttribute?.integrationId,
              attributeType: selectedAttribute?.attributeType,
            }}
            value={selectedAttributeValues}
            onChange={handleAttributeValueChange}
            preload={selectedAttributeValues.length > 0}
            optional={isOptional}
            isViewOnly={isViewOnly}
          />
        </Stack>
      </Box>
      {divider && <Divider />}
    </Stack>
  );
}
