import { PropsWithChildren, ReactNode } from 'react';

import { Flag, UserRole } from '@utils';

import { ConditionalFlagMiddleware, FlagMiddleware } from './FlagMiddleware';
import { RoleMiddleware } from './RoleMiddleware';

export interface ApplyMiddlewaresProps {
  flag?: Flag;
  allowedRoles?: UserRole[];
}

export function ApplyMiddlewares({ children, flag, allowedRoles }: PropsWithChildren<ApplyMiddlewaresProps>) {
  if (allowedRoles) {
    children = <RoleMiddleware allowedRoles={allowedRoles}>{children}</RoleMiddleware>;
  }

  if (flag) {
    children = <FlagMiddleware flag={flag}>{children}</FlagMiddleware>;
  }

  return <>{children}</>;
}
export interface ApplyConditionalFlagMiddlewaresProps extends ApplyMiddlewaresProps {
  ifElement: ReactNode;
  elseElement: ReactNode;
  flag: Flag;
}

export function ApplyConditionalFlagMiddlewares({
  ifElement,
  elseElement,
  flag,
  allowedRoles,
}: ApplyConditionalFlagMiddlewaresProps) {
  const applyRoleMiddleware = (element: ReactNode) =>
    allowedRoles ? <RoleMiddleware allowedRoles={allowedRoles}>{element}</RoleMiddleware> : element;

  return (
    <ConditionalFlagMiddleware
      flag={flag}
      ifElement={applyRoleMiddleware(ifElement)}
      elseElement={applyRoleMiddleware(elseElement)}
    />
  );
}
