import { alpha, createTheme, emphasize } from '@mui/material/styles';
import { aponoColors } from '@utils/colors';

export const aponoTheme = (prefersDarkMode = false) => {
  function modeColor<T>(darkColor: T, lightColor: T) {
    return prefersDarkMode ? darkColor : lightColor;
  }

  return createTheme({
    typography: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
      muted: {
        color: modeColor(aponoColors.neutral[300], 'rgba(0, 0, 0, 0.38)'),
      },
      h1: {
        fontWeight: 300,
        fontSize: '96px',
      },
      h2: {
        fontWeight: 300,
        fontSize: '59px',
      },
      h3: {
        fontWeight: 400,
        fontSize: '47px',
      },
      h4: {
        fontWeight: 800,
        fontSize: '33px',
      },
      h5: {
        fontWeight: 600,
        fontSize: '24px',
      },
      h6: {
        fontWeight: 500,
        fontSize: '20px',
      },
      subtitle1: {
        fontWeight: 600,
        fontSize: '16px',
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: '14px',
      },
      body1: {
        fontWeight: 400,
        fontSize: '16px',
      },
      body2: {
        fontWeight: 400,
        fontSize: '14px',
      },
      body3: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '26px',
      },
      body3bold: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',
      },
      body4: {
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '0.17px',
        lineHeight: '143%',
      },
      body4bold: {
        fontWeight: 600,
        fontSize: '16px',
        letterSpacing: '0.17px',
        lineHeight: '143%',
      },
      buttonLarge: {
        fontWeight: 700,
        fontSize: '16px',
      },
      buttonMedium: {
        fontWeight: 700,
        fontSize: '14px',
      },
      buttonSmall: {
        fontWeight: 700,
        fontSize: '13px',
      },
      caption: {
        fontWeight: 600,
        fontSize: '12px',
      },
      overline: {
        fontWeight: 400,
        fontSize: '10px',
      },
      avatarLetter: {
        fontWeight: 400,
        fontSize: '20px',
      },
      inputLabel: {
        fontWeight: 400,
        fontSize: '14px',
      },
      helperText: {
        fontWeight: 400,
        fontSize: '12px',
      },
      inputText: {
        fontWeight: 400,
        fontSize: '16px',
      },
      tooltip: {
        fontWeight: 400,
        fontSize: '12px',
      },
      code: {
        fontFamily: "'Fira Code', monospace",
        fontSize: '12px',
        display: 'inline-block',
        padding: '2px 8px',
        backgroundColor: modeColor(aponoColors.neutral[700], aponoColors.neutral[100]),
        border: `1px solid ${modeColor(aponoColors.neutral[600], aponoColors.neutral[300])}`,
        borderRadius: '4px',
      },
    },
    palette: {
      mode: modeColor('dark', 'light'),
      primary: {
        main: modeColor(aponoColors.primary[600], aponoColors.primary[600]),
        contrastText: aponoColors.black,
      },
      secondary: {
        main: aponoColors.secondary[700],
        light: '#7e64e5',
        dark: '#001081',
        contrastText: aponoColors.white,
      },
      success: {
        main: aponoColors.success[500],
        light: aponoColors.success[300],
        dark: aponoColors.success[700],
        contrastText: aponoColors.white,
      },
      error: {
        main: aponoColors.error[500],
        light: aponoColors.error[300],
        dark: aponoColors.error[700],
        contrastText: aponoColors.white,
      },
      warning: {
        main: aponoColors.warning[700],
        light: aponoColors.warning[300],
        dark: aponoColors.warning[700],
        contrastText: aponoColors.white,
      },
      dark: {
        main: modeColor(aponoColors.neutral[300], aponoColors.neutral[900]),
      },
      text: {
        primary: modeColor(aponoColors.neutral[50], aponoColors.neutral[700]),
        secondary: modeColor(aponoColors.neutral[300], 'rgba(0, 0, 0, 0.6)'),
        disabled: modeColor(aponoColors.neutral[400], aponoColors.neutral[400]),
        button: modeColor(aponoColors.neutral[900], aponoColors.neutral[900]),
        highlight: modeColor(aponoColors.primary[700], aponoColors.primary[700]),
        muted: modeColor(aponoColors.neutral[300], aponoColors.neutral[500]),
        dropdown: modeColor(aponoColors.neutral[500], aponoColors.neutral[300]),
        filter: modeColor(aponoColors.neutral[300], aponoColors.neutral[700]),
      },
      action: {
        active: modeColor(aponoColors.white, 'rgba(0, 0, 0, 0.54)'),
      },
      background: {
        hover: modeColor(aponoColors.neutral[600], aponoColors.light[400]),
        active: modeColor(aponoColors.neutral[700], aponoColors.light[100]),
        default: modeColor(aponoColors.neutral[800], aponoColors.white),
        paper: modeColor(aponoColors.neutral[750], aponoColors.white),
        banner: modeColor(aponoColors.neutral[700], aponoColors.neutral[50]),
        awsBanner: '#FF9900',
        gcpBanner: '#4285F4',
        azureBanner: '#3AC6F2',
        button: modeColor(aponoColors.primary[700], aponoColors.primary[700]),
        outlinedFiltersHover: modeColor(alpha(aponoColors.primary[400], 0.4), alpha(aponoColors.neutral[100], 0.4)),
        drawer: modeColor(aponoColors.neutral[900], aponoColors.neutral[50]),
        popup: modeColor(aponoColors.neutral[900], aponoColors.neutral[900]),
        filterActive: modeColor(aponoColors.primary[900], aponoColors.primary[600]),
      },
      divider: modeColor('rgba(255, 255, 255, 0.12)', aponoColors.neutral[300]),
      border: {
        default: modeColor(aponoColors.neutral[600], aponoColors.neutral[300]),
        hover: modeColor(aponoColors.neutral[900], aponoColors.neutral[100]),
        active: modeColor(aponoColors.primary[900], aponoColors.primary[300]),
        button: modeColor(aponoColors.neutral[400], aponoColors.neutral[400]),
        banner: modeColor(aponoColors.neutral[100], aponoColors.neutral[900]),
        dark: modeColor(aponoColors.neutral[100], aponoColors.neutral[900]),
      },
      button: {
        lightText: modeColor(aponoColors.primary[800], aponoColors.black),
        text: modeColor(aponoColors.primary[700], aponoColors.primary[700]),
        hover: modeColor(aponoColors.primary[700], aponoColors.primary[700]),
        default: modeColor(aponoColors.primary[700], aponoColors.primary[700]),
      },
      graph: {
        primary: modeColor(aponoColors.primary[700], aponoColors.primary[700]),
        success: modeColor(aponoColors.success[400], aponoColors.success[400]),
        warning: modeColor(aponoColors.warning[600], aponoColors.warning[600]),
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              fontWeight: 700,
              borderRadius: 1,
              textTransform: 'none',
              boxShadow: 'none',
              lineHeight: 1.6,
              whiteSpace: 'nowrap',

              ':hover': { boxShadow: 'none' },
            }),
          sizeMedium: ({ theme }) => ({
            padding: theme.spacing(0.5, 2),
          }),
          sizeSmall: ({ theme }) => ({
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          }),
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: ({ theme }) => ({
              padding: theme.spacing(0.5, 2),
              border: `1px solid ${aponoColors.neutral[400]}`,
              backgroundColor: alpha(aponoColors.white, 0.02),
              color: aponoColors.primary[600],

              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
                borderColor: aponoColors.neutral[400],
                backgroundColor: alpha(aponoColors.white, 0.12),
              },

              '&:hover:not(.Mui-disabled)': {
                backgroundColor: alpha(aponoColors.primary[600], 0.04),
                borderColor: aponoColors.primary[400],
              },
            }),
          },
          {
            props: { variant: 'text' },
            style: ({ theme }) => ({
              color: aponoColors.primary[700],
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
              },
            }),
          },
          {
            props: { variant: 'contained' },
            style: ({ theme }) => ({
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),

              '&.MuiButton-containedPrimary': {
                '&:hover': {
                  backgroundColor: aponoColors.primary[700],
                },
              },
            }),
          },
          {
            props: { variant: 'outlined-filters' },
            style: ({ theme }) => ({
              border: `1px solid ${theme.palette.border.default}`,
              padding: theme.spacing(0.5, 1, 0.5, 2),
              minWidth: 180,
              display: 'flex',
              justifyContent: 'flex-start',

              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },

              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
                borderColor: theme.palette.border.default,
                backgroundColor: modeColor(aponoColors.neutral[700], aponoColors.neutral[100]),
              },

              '&:hover:not(.Mui-disabled)': {
                backgroundColor: modeColor(
                  alpha(aponoColors.primary[600], 0.08),
                  alpha(aponoColors.neutral[100], 0.08),
                ),
                borderColor: modeColor(aponoColors.primary[400], aponoColors.neutral[100]),
              },
            }),
          },
          {
            props: { variant: 'default-filters' },
            style: ({ theme }) => ({
              padding: theme.spacing(0.5, 1, 0.5, 2),
              minWidth: 180,
              display: 'flex',
              justifyContent: 'flex-start',
              backgroundColor: theme.palette.background.active,

              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },

              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
                borderColor: theme.palette.border.default,
                backgroundColor: modeColor(aponoColors.neutral[700], aponoColors.neutral[100]),
              },

              '&:hover:not(.Mui-disabled)': {
                backgroundColor: emphasize(theme.palette.background.active, 0.1),
              },
            }),
          },
          {
            props: { variant: 'contained-filters' },
            style: ({ theme }) => ({
              border: `1px solid ${aponoColors.primary[900]}`,
              padding: theme.spacing(0.5, 1, 0.5, 2),
              lineHeight: 1.6,
              color: aponoColors.white,
              minWidth: 180,
              display: 'flex',
              justifyContent: 'flex-start',
              backgroundColor: aponoColors.primary[900],

              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },

              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
                borderColor: theme.palette.border.default,
                backgroundColor: modeColor(aponoColors.neutral[700], aponoColors.neutral[50]),
              },

              '&:hover:not(.Mui-disabled)': {
                backgroundColor: aponoColors.primary[900],
                borderColor: aponoColors.primary[900],
              },
            }),
          },
          {
            props: { variant: 'tag-action-button' },
            style: ({ theme }) => ({
              variant: 'contained',
              width: theme.spacing(6),
              height: theme.spacing(2.5),
              backgroundColor: alpha(theme.palette.primary.main, 0.14),
              borderRadius: '18px',
              '& .text': { color: theme.palette.button.lightText },
            }),
          },
          {
            props: { variant: 'explore-button' },
            style: ({ theme }) => ({
              variant: 'contained',
              height: theme.spacing(4),
              border: `1px solid ${aponoColors.secondary[300]}`,
              background: `linear-gradient(90deg, #6851FE 0%, ${aponoColors.secondary[400]} 100%)`,
              borderRadius: '4px',
              color: theme.palette.secondary.contrastText,
              '&:hover:not(.Mui-disabled)': {
                backgroundColor: theme.palette.text.muted,
              },
            }),
          },
          {
            props: { variant: 'outlined-narrow' },
            style: ({ theme }) => ({
              border: `1px solid ${aponoColors.neutral[400]}`,
              color: aponoColors.primary[600],
              minWidth: 0,
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),

              '&.MuiButton-outlined-narrowSizeSmall': {
                fontSize: theme.typography.pxToRem(10),
                padding: theme.spacing(0.5),

                '& .MuiIcon-root': {
                  fontSize: theme.typography.pxToRem(21),
                },
              },
            }),
          },
          {
            props: { variant: 'outlined-revoke' },
            style: ({ theme }) => ({
              color: theme.palette.text.disabled,
              border: `1px solid ${theme.palette.text.disabled}`,
            }),
          },
          {
            props: { variant: 'outlined-base' },
            style: ({ theme }) => ({
              border: `1px solid ${aponoColors.neutral[900]}`,
              color: aponoColors.neutral[900],
              minWidth: 0,
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            }),
          },
        ],
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          labelPlacementStart: {
            marginRight: -6,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: modeColor(aponoColors.primary[400], aponoColors.primary[700]),
          },
        },
        defaultProps: {
          underline: 'none',
        },
      },
      MuiInput: {
        styleOverrides: {
          input: ({ theme }) =>
            theme.unstable_sx({
              fontWeight: 400,
            }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              borderRadius: '4px',
              borderColor: aponoColors.neutral[400],
            }),
        },
        variants: [
          {
            props: { variant: 'standard' },
            style: ({ theme }) => ({
              '& .Mui-focused': {
                backgroundColor: theme.palette.background.active,
                '&:before': {
                  borderColor: `${theme.palette.primary.main} !important`,
                },

                '& .MuiInputBase-input': {
                  '&:hover': {
                    '&::placeholder': {
                      color: theme.palette.text.disabled,
                    },
                  },
                },
              },

              '& .MuiInputBase-root': {
                borderColor: theme.palette.text.dropdown,
                '&:before': {
                  borderColor: theme.palette.text.dropdown,
                },

                '&:after': {
                  display: 'none',
                },

                '&:hover': {
                  '&:before': {
                    borderWidth: '1px !important',
                  },
                },
              },

              '& .MuiInputBase-input': {
                padding: theme.spacing(0.8, 0.5),

                '&:hover': {
                  '&::placeholder': {
                    color: theme.palette.primary.main,
                    opacity: 1,
                  },
                },

                '&::placeholder': {
                  color: theme.palette.text.disabled,
                  opacity: 1,
                },
              },
            }),
          },
          {
            props: { variant: 'outlined' },
            style: ({ theme }) => ({
              borderColor: theme.palette.border.default,
            }),
          },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: ({ theme }) => ({
            borderColor: theme.palette.border.default,
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          inputSizeSmall: ({ theme }) => ({
            lineHeight: '1.2rem',
            fontSize: '14px',
            padding: `${theme.spacing(0.75, 1.25)} !important`,

            '&.MuiAutocomplete-input': {
              padding: `0 !important`,
            },

            '&.MuiInputBase-inputMultiline': {
              padding: `0 !important`,
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            boxShadow: `0 0 0 1px ${theme.palette.border.default}`,
            overflow: 'hidden',
          }),
        },
        variants: [
          {
            props: { variant: 'contained' },
            style: ({ theme }) => ({
              background: theme.palette.background.active,
              padding: theme.spacing(2),
              border: 'none',
              boxShadow: 'none',
            }),
          },
        ],
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              boxShadow: 'none',
            }),
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: () => ({
              '&.MuiAlert-outlinedError': {
                border: `1px solid ${aponoColors.error[500]}`,
              },
              '&.MuiAlert-outlinedWarning': {
                border: `1px solid ${aponoColors.warning[700]}`,
                '& .MuiAlert-icon': {
                  color: aponoColors.warning[700],
                },
              },
              '&.MuiAlert-outlinedSuccess': {
                border: `1px solid ${aponoColors.success[500]}`,
              },
            }),
          },
          {
            props: { variant: 'outlinedInsights' },
            style: () => ({
              border: `1px solid ${aponoColors.secondary[300]}`,
              color: aponoColors.secondary[300],
              width: '100%',
              '&. MuiAlert-icon': {
                color: aponoColors.secondary[300],
              },
            }),
          },
          {
            props: { variant: 'outlinedInfo' },
            style: () => ({
              border: `1px solid ${aponoColors.tertiary[300]}`,
              color: aponoColors.tertiary[300],
              width: 'fit-content',
              '&. MuiAlert-icon': {
                color: aponoColors.tertiary[300],
              },
            }),
          },
          {
            props: { variant: 'error-banner' },
            style: ({ theme }) => ({
              padding: theme.spacing(1.5, 3),
              backgroundColor: aponoColors.error[400],
              color: aponoColors.neutral[900],
            }),
          },
          {
            props: { variant: 'simple-info' },
            style: () => ({
              padding: '0px',
              backgroundColor: 'transparent',
              color: aponoColors.primary[100],
            }),
          },
          {
            props: { variant: 'warning-banner' },
            style: ({ theme }) => ({
              padding: theme.spacing(1.5, 3),
              backgroundColor: aponoColors.warning[400],
              color: aponoColors.neutral[900],
            }),
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          paper: ({ theme }) => ({
            backgroundColor: aponoColors.neutral[900],
            color: aponoColors.neutral[50],
            boxShadow: theme.shadows[15],
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2.5, 2),
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(0, 1, 1, 1),
          }),
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: aponoColors.white,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: aponoColors.neutral[700],
          },
          arrow: { color: aponoColors.neutral[700] },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: ({ theme }) => ({
            minHeight: 'auto',
            borderBottom: `1px solid ${theme.palette.border.default}`,
          }),
          indicator: {
            height: '1px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            textTransform: 'none',
            minHeight: 'auto',
            minWidth: 'auto',
            padding: theme.spacing(1, 2),
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderColor: theme.palette.border.default,
            padding: theme.spacing(1.5, 2),
          }),
          head: ({ theme }) => ({
            textTransform: 'uppercase',
            fontWeight: 700,
            color: theme.palette.text.muted,
          }),
          alignCenter: {
            align: 'center',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiTableRow-hover': {
              '&:hover': {
                'td:first-of-type': {
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '1px',
                    backgroundColor: theme.palette.primary.main,
                  },
                },
              },
            },
            '&.MuiTableRow-error': {
              'td:first-of-type': {
                position: 'relative',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '1px',
                  backgroundColor: theme.palette.error.main,
                },
              },
            },
            '&.MuiTableRow-clickable': {
              cursor: 'pointer',
            },
          }),
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: ({ theme }) => ({
            margin: theme.spacing(1, 0),
          }),
        },
      },
      MuiList: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(0.5, 0),
          }),
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...theme.typography.subtitle2,
            padding: theme.spacing(1, 1.4),
          }),
        },
      },
      MuiMenuList: {
        styleOverrides: {
          root: {
            maxHeight: 300,
            overflow: 'auto',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontWeight: 600,
            fontSize: '12px',
            borderBottom: `1px solid ${theme.palette.border.hover}`,
            padding: theme.spacing(1, 1.5),
            alignItems: 'center',
          }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: ({ theme }) => ({
            paddingLeft: `${theme.spacing(1.25)} !important`,
          }),
          paper: ({ theme }) => ({
            margin: theme.spacing(1, 0),

            '&:empty': {
              boxShadow: 'none',
            },
          }),
          listbox: ({ theme }) => ({
            padding: theme.spacing(0.5, 0),
          }),
          option: ({ theme }) => ({
            fontWeight: 600,
            fontSize: '12px',
            borderBottom: `1px solid ${theme.palette.border.hover}`,
            padding: theme.spacing(1, 1.5),
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...theme.typography.body2,
            color: theme.palette.text.primary,
          }),
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {},
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...theme.typography.subtitle2,
            lineHeight: 1,
          }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ theme }) => ({
            verticalAlign: 'middle',

            '&.MuiChip-iconReversed': {
              flexDirection: 'row-reverse',

              '& .MuiChip-icon': {
                marginLeft: '-6px',
                marginRight: theme.spacing(0.5),
              },
            },
          }),
          avatar: ({ theme }) => ({
            '&.rt-chip-avatar': {
              marginLeft: theme.spacing(1),
              marginRight: 0,
            },
          }),
          label: ({ theme }) => ({
            '&.rt-chip-avatar': {
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            },
          }),
          deleteIcon: ({ theme }) => ({
            color: 'inherit',
            marginRight: theme.spacing(1),
          }),
        },
        variants: [
          {
            props: { variant: 'label' },
            style: ({ theme }) => ({
              ...theme.typography.helperText,

              '&.MuiChip-sizeSmall': {
                height: theme.spacing(3),
              },
            }),
          },
          {
            props: { variant: 'filter' },
            style: ({ theme }) => ({
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.active,

              '&:hover': {
                backgroundColor: emphasize(theme.palette.background.active, 0.1),
              },
            }),
          },
          {
            props: { variant: 'filter-active' },
            style: ({ theme }) => ({
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.filterActive,

              '&:hover': {
                backgroundColor: emphasize(theme.palette.background.filterActive, 0.1),
              },
            }),
          },
          {
            props: { variant: 'outlined' },
            style: {
              '&.MuiChip-no-border': {
                borderColor: 'transparent',
              },
            },
          },
          {
            props: { variant: 'filled' },
            style: ({ theme }) => ({
              '&.MuiChip-selected': {
                boxShadow: `0 0 0 1px ${theme.palette.border.dark}`,
              },
            }),
          },
        ],
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.size-16': {
              width: theme.spacing(2),
              height: theme.spacing(2),
            },
            '&.size-20': {
              width: theme.spacing(2.5),
              height: theme.spacing(2.5),
            },
            '&.size-24': {
              width: theme.spacing(3),
              height: theme.spacing(3),
            },
          }),
        },
        variants: [
          {
            props: { variant: 'small' },
            style: {
              width: '24px',
              height: '24px',
              fontSize: '12px',
            },
          },
        ],
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => ({
            border: `1px solid ${theme.palette.border.default}`,
            borderRadius: theme.shape.borderRadius,
            boxShadow: 'none',
          }),
        },
        variants: [
          {
            props: { variant: 'userBanner' },
            style: ({ theme }) => ({
              padding: theme.spacing(1, 2),
              m: theme.spacing(1, 0),
              display: 'flex',
              borderRadius: theme.spacing(1),
              alignItems: 'center',
              minHeight: '60px !important',
              justifyContent: 'space-between',
            }),
          },
          {
            props: { variant: 'modal' },
            style: ({ theme }) => ({
              padding: theme.spacing(2),
              minHeight: '250px',
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }),
          },
        ],
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2),

            '&:last-child': {
              paddingBottom: theme.spacing(2),
            },
          }),
        },
      },
    },
  });
};
