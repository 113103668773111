import { useCallback, useMemo } from 'react';

import { MaterialIcon, SelectDropdown } from '@components';
import { Button } from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '@utils';
import { useListIntegrationsV2 } from '@api';

function useActiveAccessIntegrationSelect() {
  const { integrations } = useListIntegrationsV2();

  const [searchParams, setSearchParams] = useSearchParams();

  const options = useMemo(
    () =>
      integrations
        .filter((i) => ['k8s-roles', 'aws-eks', 'azure-aks', 'gcp-gke'].includes(i.type))
        .map((i) => ({
          label: i.name,
          value: i.id,
        })),
    [integrations],
  );

  const selectedOption = useMemo(
    () => options.find((o) => o.value === searchParams.get(QueryParams.integrationId)),
    [options, searchParams],
  );

  const handleSelect = useCallback(
    (value: string) => {
      setSearchParams({
        [QueryParams.integrationId]: value,
      });
    },
    [setSearchParams],
  );

  return {
    selectedOption,
    options,
    handleSelect,
  };
}

export function ActiveAccessIntegrationSelect() {
  const { selectedOption, options, handleSelect } = useActiveAccessIntegrationSelect();
  return (
    <SelectDropdown<string>
      options={options}
      trigger={
        <Button
          size="small"
          variant={selectedOption ? 'contained-filters' : 'outlined-filters'}
          endIcon={<MaterialIcon symbol="expand_more" />}
          component="div"
        >
          {selectedOption?.label || 'Select integration'}
        </Button>
      }
      title="Choose integration to display"
      value={selectedOption?.value}
      onChange={handleSelect}
    />
  );
}
