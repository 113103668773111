import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';

export interface ColumnsLayoutSectionNoPaddingProps {
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
}

export interface ColumnsLayoutSectionProps {
  active?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  np?: ColumnsLayoutSectionNoPaddingProps;
}

const ColumnsLayoutSectionContainer = styled(Box)(({ theme }) => ({
  '&.pt': {
    paddingTop: theme.spacing(3),
  },

  '&.pr': {
    paddingRight: theme.spacing(6),
  },

  '&.pb': {
    paddingBottom: theme.spacing(3),
  },

  '&.pl': {
    paddingLeft: theme.spacing(6),
  },

  '&.active': {
    backgroundColor: theme.palette.background.active,
  },

  '&.clickable': {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.background.active,
      transition: 'background-color 0.2s',
    },
  },
}));

export function ColumnsLayoutSection({ active, onClick, children, np }: ColumnsLayoutSectionProps) {
  const className = [];
  if (onClick) className.push('clickable');
  if (active) className.push('active');
  if (!np?.top) className.push('pt');
  if (!np?.right) className.push('pr');
  if (!np?.bottom) className.push('pb');
  if (!np?.left) className.push('pl');

  return (
    <ColumnsLayoutSectionContainer
      data-testid="columns-layout-section-container"
      onClick={onClick}
      className={className.join(' ')}
    >
      {children}
    </ColumnsLayoutSectionContainer>
  );
}
