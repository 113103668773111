import { Box, Chip, ChipProps } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { LabelAppModel } from '@api';

export default function LabelWithColor({ label, size = 'small' }: { label: LabelAppModel; size?: ChipProps['size'] }) {
  return (
    <Chip
      icon={
        <Box sx={(theme) => ({ paddingLeft: theme.spacing(0.5) })}>
          <FiberManualRecordIcon
            sx={(theme) => ({
              width: theme.spacing(2),
              height: theme.spacing(1.75),
              fill: label.color,
              display: 'block',
            })}
          />
        </Box>
      }
      label={label.name}
      variant="outlined"
      size={size}
    />
  );
}
