import { useState } from 'react';

import { ButtonBase, Chip, InputAdornment, List, ListItem, OutlinedInput, styled, Typography } from '@mui/material';
import { EmptyText } from './EmptyText';

const InteractiveDropdownChipsList = styled(List)({
  overflow: 'auto',
  maxHeight: '210px',
  width: '100%',
  paddingTop: 0,
});

export interface InteractiveDropdownChipsProps {
  inputType?: 'text' | 'email' | 'password' | 'number';
  value: string[];
  emptyText?: string;
  onChange: (value: string[]) => void;
}

function useInteractiveDropdownChips(
  value: InteractiveDropdownChipsProps['value'],
  onChange: InteractiveDropdownChipsProps['onChange'],
) {
  const [inputValue, setInputValue] = useState('');

  const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleDelete = (item: string) => {
    onChange(value.filter((i) => i !== item));
  };

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!value.includes(inputValue)) {
      onChange([...value, inputValue]);
    }

    setInputValue('');
  };

  return {
    inputValue,
    handleInputValueChange,
    handleDelete,
    handleChange,
  };
}

export function InteractiveDropdownChips({
  inputType = 'text',
  value,
  emptyText,
  onChange,
}: InteractiveDropdownChipsProps) {
  const { inputValue, handleInputValueChange, handleDelete, handleChange } = useInteractiveDropdownChips(
    value,
    onChange,
  );
  return (
    <form onSubmit={handleChange}>
      <ListItem component="div">
        <OutlinedInput
          type={inputType}
          size="small"
          value={inputValue}
          required
          fullWidth
          placeholder="mail@example.com"
          onChange={handleInputValueChange}
          endAdornment={
            <InputAdornment position="end">
              <ButtonBase data-testid="interactive-dropdown-chips-btn" type="submit">
                <Typography color="primary" fontSize="small">
                  Add
                </Typography>
              </ButtonBase>
            </InputAdornment>
          }
        />
      </ListItem>
      <ValueList value={value} handleDelete={handleDelete} emptyText={emptyText} />
    </form>
  );
}

function ValueList({
  value,
  emptyText,
  handleDelete,
}: {
  value: string[];
  emptyText?: string;
  handleDelete: (item: string) => void;
}) {
  if (value.length === 0) {
    if (!emptyText) return <></>;

    return <EmptyText>{emptyText}</EmptyText>;
  }

  return (
    <InteractiveDropdownChipsList dense>
      {value.map((item) => (
        <ListItem key={item}>
          <Chip data-testid="value-list-chip" label={item} onDelete={() => handleDelete(item)} />
        </ListItem>
      ))}
    </InteractiveDropdownChipsList>
  );
}
