import GroupsModals from '@Groups/organisms/GroupsModals';
import GroupsTable from '@Groups/organisms/GroupsTable';
import { useListGroups } from '@Groups/services/queries';
import { AponoGroupModel } from '@api';
import { Loader, SearchBar } from '@components';
import { useFlagFixed } from '@hooks';
import { Button, Stack } from '@mui/material';
import { ErrorPage } from '@pages';
import { Flag } from '@utils';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GROUP_MODAL_QUERY_PARAM } from '@Groups/organisms/modals/ViewGroupModal';

export default function GroupsPage() {
  const { groups } = useListGroups();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [groupInEdit, setGroupInEdit] = useState<AponoGroupModel | null>(null);
  const [groupInDeletion, setGroupInDeletion] = useState<AponoGroupModel | null>(null);
  const [searchParams] = useSearchParams();
  const [groupId, setGroupId] = useState<string | null>(searchParams.get(GROUP_MODAL_QUERY_PARAM) || null);
  const [search, setSearch] = useState('');
  const { isEnabled } = useFlagFixed(Flag.GROUPS_PAGE);

  useEffect(() => {
    const newGroupId = searchParams.get(GROUP_MODAL_QUERY_PARAM);
    if (newGroupId) {
      setGroupId(newGroupId);
    } else setGroupId(null);
  }, [searchParams, setGroupId]);

  const filteredGroups = useMemo(
    () => groups?.filter((group) => group.name.toLowerCase().includes(search.toLowerCase())),
    [groups, search],
  );

  if (!isEnabled) return <ErrorPage errorCode={404} />;

  if (!groups) return <Loader />;

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }} alignItems="center" justifyContent="space-between">
        <SearchBar value={search} onChange={setSearch} total={filteredGroups?.length} />
        <Button variant="contained" onClick={() => setIsCreateModalOpen(true)}>
          Create group
        </Button>
      </Stack>
      <GroupsTable {...{ groups: filteredGroups, setGroupInEdit, setGroupInDeletion, setIsCreateModalOpen }} />
      <GroupsModals
        {...{
          isCreateModalOpen,
          setIsCreateModalOpen,
          groupInEdit,
          setGroupInEdit,
          groupInDeletion,
          setGroupInDeletion,
          groupId,
        }}
      />
    </>
  );
}
