import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import { NoResults } from '@components';
import TablePagination from '@components/TablePagination';

import { PrimarySecondaryLoading } from '@common/ui/PrimarySecondary';
import { SEARCH_QUERY_PARAM } from '@common/organisms/SearchBarFilter';
import TableListTitle from '@common/components/TableListTitle';
import { useAppListIdentities } from '@common/services/accountQueries';
import UsersListItemContainer from '@Identities/components/UsersListItemContainer';

import UsersListItem from './UsersListItem';
import { API_LIST_LIMIT, PAGE_PARAM, getSkipParam } from '@api';
import { TableLoading } from '@Activity/Audit/ActivityTable/TableLoading';
import { ATTRIBUTE_FILTER_PARAM, ROLE_FILTER_PARAM } from '@Identities/common/constants';

export default function UsersListPaginated() {
  const [queryParams, setQueryParams] = useSearchParams();
  const pageQp = parseInt(queryParams.get(PAGE_PARAM) || '1');
  const searchQp = queryParams.get(SEARCH_QUERY_PARAM) || undefined;
  const attrsQp = queryParams.getAll(ATTRIBUTE_FILTER_PARAM);
  const rolesQp = queryParams.getAll(ROLE_FILTER_PARAM);

  const { identities, pagination, isFetched, isFetching } = useAppListIdentities({
    limit: API_LIST_LIMIT,
    skip: getSkipParam(pageQp),
    search: searchQp,
    attributeId: attrsQp,
    role: rolesQp,
  });

  const handleOnPageChange = (pageNumber: number) => {
    queryParams.set(PAGE_PARAM, String(pageNumber));
    setQueryParams(queryParams);
    window.scrollTo(0, 0);
  };

  if (!isFetched) {
    return <UsersListItemContainer user={<PrimarySecondaryLoading secondary icon iconSpacing={2} />} />;
  }

  if (identities.length === 0) {
    return <NoResults>No identities found</NoResults>;
  }

  return (
    <Stack spacing={2} sx={{ position: 'relative' }}>
      <UsersListItemContainer
        user={<TableListTitle>USER</TableListTitle>}
        roles={<TableListTitle>APONO ROLE</TableListTitle>}
        status={<TableListTitle>STATUS</TableListTitle>}
        attributes={<TableListTitle>ATTRIBUTES</TableListTitle>}
        noBorder
      />
      <TableLoading loading={isFetching} />
      {identities.map((identity) => (
        <UsersListItem key={identity.id} user={identity} />
      ))}
      <TablePagination pagination={pagination} isLoading={false} onChange={handleOnPageChange} />
    </Stack>
  );
}
