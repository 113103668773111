import { forwardRef, ReactNode } from 'react';
import { Avatar, styled } from '@mui/material';

const StyledPrimarySecondaryAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.background.active,
}));

export interface PrimarySecondaryAvatarProps {
  icon: ReactNode;
  iconSize?: number;
  innerRef?: React.Ref<HTMLDivElement>;
}

export default function PrimarySecondaryAvatar({
  icon,
  iconSize = 24,
  innerRef,
  ...restProps
}: PrimarySecondaryAvatarProps) {
  const iconSizes = { width: iconSize, height: iconSize };

  if (typeof icon === 'string') {
    return (
      <StyledPrimarySecondaryAvatar
        src={icon}
        sx={iconSizes}
        ref={innerRef}
        {...restProps}
        data-testid="primary-secondary-avatar"
      />
    );
  }

  return (
    <StyledPrimarySecondaryAvatar sx={iconSizes} ref={innerRef} {...restProps} data-testid="primary-secondary-avatar">
      {icon}
    </StyledPrimarySecondaryAvatar>
  );
}

export const PrimarySecondaryAvatarWrapped = forwardRef<HTMLDivElement, PrimarySecondaryAvatarProps>(
  function PrimarySecondaryAvatarWrapped(props, ref) {
    return <PrimarySecondaryAvatar {...props} innerRef={ref} />;
  },
);
