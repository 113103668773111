import { useState } from 'react';

import {
  Box,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  NativeSelect,
  Radio,
  styled,
  TextField,
} from '@mui/material';

import { DurationUnitInSeconds, DurationUnitLabel, DurationValue } from '@utils';
import { InteractiveDropdownSelectList, ListItemIconStyled } from './InteractiveDropdownSelect';

const UNITS = [
  {
    value: DurationUnitInSeconds.Minutes,
    label: DurationUnitLabel.Minute + 's',
  },
  { value: DurationUnitInSeconds.Hours, label: DurationUnitLabel.Hour + 's' },
  { value: DurationUnitInSeconds.Days, label: DurationUnitLabel.Day + 's' },
  { value: DurationUnitInSeconds.Weeks, label: DurationUnitLabel.Week + 's' },
];

const AFTER = 'after';
const INDEFINITE = 'indefinite';

export const InteractiveDropdownDurationSelectList = styled(InteractiveDropdownSelectList)({
  width: '100%',
});

export interface InteractiveDropdownDurationSelectProps {
  value?: DurationValue;
  onChange: (value?: DurationValue) => void;
}

const useInteractiveDropdownDurationSelect = ({ onChange, value }: InteractiveDropdownDurationSelectProps) => {
  const [selected, setSelected] = useState<string | undefined>(value ? AFTER : INDEFINITE);
  const [durationValue, setDurationValue] = useState<DurationValue>(
    value || {
      amount: 1,
      unitInSeconds: UNITS[1].value,
    },
  );

  const handleSelectedChange = (selectedValue: string) => {
    setSelected(selectedValue);
    onChange(selectedValue === INDEFINITE ? undefined : durationValue);
  };

  const handleDurationValueChange = (durationVal: DurationValue) => {
    setDurationValue(durationVal);
    onChange(durationVal);
  };

  return {
    selected,
    handleSelectedChange,
    durationValue,
    handleDurationValueChange,
  };
};

export function InteractiveDropdownDurationSelect({ value, onChange }: InteractiveDropdownDurationSelectProps) {
  const { selected, handleSelectedChange, durationValue, handleDurationValueChange } =
    useInteractiveDropdownDurationSelect({ onChange, value });

  return (
    <InteractiveDropdownDurationSelectList dense disablePadding>
      <ListItem disablePadding>
        <ListItemButton
          data-testid="interactive-dropdown-duration-select-after"
          onClick={() => handleSelectedChange(AFTER)}
        >
          <ListItemIconStyled>
            <Radio edge="start" tabIndex={-1} value={AFTER} disableRipple checked={selected === AFTER} />
          </ListItemIconStyled>
          <DurationForm disabled={selected !== AFTER} value={durationValue} onChange={handleDurationValueChange} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          data-testid="interactive-dropdown-duration-select-indefinite"
          onClick={() => handleSelectedChange(INDEFINITE)}
        >
          <ListItemIconStyled>
            <Radio edge="start" tabIndex={-1} value={INDEFINITE} disableRipple checked={selected === INDEFINITE} />
          </ListItemIconStyled>
          <ListItemText primary="Indefinite" />
        </ListItemButton>
      </ListItem>
    </InteractiveDropdownDurationSelectList>
  );
}

interface DurationFormProps {
  disabled?: boolean;
  value: DurationValue;
  onChange: (value: DurationValue) => void;
}

function useDurationForm(onChange: DurationFormProps['onChange'], defaultValue: DurationValue) {
  const [amount, setAmount] = useState(defaultValue.amount);
  const [unitInSeconds, setUnitInSeconds] = useState(defaultValue.unitInSeconds);

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const durationAmount = Math.max(Number(event.target.value), 0);
    setAmount(durationAmount);
    onChange({ amount: durationAmount, unitInSeconds });
  };

  const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const unitInSecond = Math.max(Number(event.target.value), 0);
    setUnitInSeconds(unitInSecond);
    onChange({ amount, unitInSeconds: unitInSecond });
  };

  return {
    amount,
    handleDurationChange,
    unitInSeconds,
    handleUnitChange,
  };
}

function DurationForm({ disabled, value, onChange }: DurationFormProps) {
  const { amount, handleDurationChange, unitInSeconds, handleUnitChange } = useDurationForm(onChange, value);

  const unitOptions = UNITS.map((type) => (
    <option key={type.value} value={type.value}>
      {type.label}
    </option>
  ));

  return (
    <Box
      component="form"
      sx={{
        width: '200px',
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container direction="row" justifyContent="space-around" alignItems="flex-end" spacing={2}>
        <Grid item xs={6}>
          <TextField
            data-testid="interactive-dropdown-duration-select-amount"
            type="number"
            placeholder="0"
            value={amount}
            onChange={handleDurationChange}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            variant="standard"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <NativeSelect
            data-testid="interactive-dropdown-duration-select-unit"
            value={unitInSeconds}
            onChange={handleUnitChange}
            variant="standard"
            fullWidth
            disabled={disabled}
          >
            {unitOptions}
          </NativeSelect>
        </Grid>
      </Grid>
    </Box>
  );
}
