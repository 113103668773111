import { useState } from 'react';
import { useAppQuery } from '../../api/query/use-app-query';
import { FrontierService } from '../../api/services';
import { accessGraphFilterOptionsQueryDocument } from './gql';

export function useAccessGraphFilterOptions() {
  const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

  const fetch = async () => {
    const res = await FrontierService.request(accessGraphFilterOptionsQueryDocument);
    setIsFirstTimeFetched(true);
    return res;
  };

  const res = useAppQuery(['access-graph-filter-options'], fetch, {
    keepPreviousData: true,
  });

  return { ...res, isFetched: isFirstTimeFetched };
}
