import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Alert, Button, Menu, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { WebhookInvocationAppModel, WebhookTriggerEventAppModel, WebhookUpdateAppModel } from '@api';
import { MaterialIcon } from '@components';

import WebhookDrawer from '../components/WebhookDrawer';
import { useAppTestWebhook } from '../services/webhooksMutations';

export default function WebhookTest() {
  const [invocationData, setInvocationData] = useState<WebhookInvocationAppModel | undefined>();

  const { formState, trigger: triggerValidation, getValues } = useFormContext<WebhookUpdateAppModel>();
  const { testWebhook, isLoading, data } = useAppTestWebhook();

  const handleTest = useCallback(
    (trigger: WebhookTriggerEventAppModel) => {
      if (!formState.isValid) {
        triggerValidation();
        return;
      }

      const formValues = getValues();
      testWebhook({
        trigger,
        url: formValues.url,
        method: formValues.method,
        headers: formValues.headers,
        body_template: formValues.body_template,
      });
    },
    [formState.isValid, getValues, testWebhook, triggerValidation],
  );

  const closeDrawer = useCallback(() => setInvocationData(undefined), []);

  return (
    <>
      <WebhookTestButton onSelect={handleTest} isLoading={isLoading} />
      {data && <WebhookTestStatusText status={data?.success} onClick={() => setInvocationData(data)} />}
      <WebhookDrawer invocationData={invocationData} onClose={closeDrawer} />
    </>
  );
}

function WebhookTestStatusText({ status, onClick }: { status: boolean; onClick: () => void }) {
  return (
    <Alert
      variant="outlined"
      severity={status ? 'success' : 'error'}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          View invocation data
        </Button>
      }
    >
      {status ? 'Test successful' : 'Test failed'}
    </Alert>
  );
}

function WebhookTestButton({
  isLoading,
  onSelect,
}: {
  isLoading?: boolean;
  onSelect: (trigger: WebhookTriggerEventAppModel) => void;
}) {
  const { watch } = useFormContext<WebhookUpdateAppModel>();
  const selectedTriggers = watch('triggers');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (selectedTriggers.length === 0) return;
      if (selectedTriggers.length === 1) return onSelect(selectedTriggers[0]);
      setAnchorEl(event.currentTarget);
    },
    [onSelect, selectedTriggers],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const endIcon = useMemo(
    () => (selectedTriggers.length > 1 ? <MaterialIcon symbol="expand_more" /> : undefined),
    [selectedTriggers],
  );

  const options = useMemo(
    () =>
      selectedTriggers.map((t) => ({
        key: t,
        children: t,
        onClick: () => {
          onSelect(t);
          handleClose();
        },
      })),
    [onSelect, selectedTriggers],
  );

  return (
    <>
      <LoadingButton variant="outlined" type="button" onClick={handleClick} endIcon={endIcon} loading={isLoading}>
        Test
      </LoadingButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem {...option} key={option.key} />
        ))}
      </Menu>
    </>
  );
}
