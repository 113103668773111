import { Page } from '@components';
import { ConnectIntegrationContent } from '@Integrations/organisms/ConnectIntegration/ConnectIntegrationContent';
import { routes } from '@routes';

export function OnboardingConnectIntegrationPage() {
  return (
    <Page contentCentered contentPadded>
      <ConnectIntegrationContent
        paths={{
          catalogPath: routes.OnboardingWelcome.path,
          editPath: routes.OnboardingEditIntegration.path,
        }}
      />
    </Page>
  );
}
