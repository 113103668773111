import { useAccessRightSizingInsightFindings } from '@AccessRightSizing/services/use-right-sizing-insight-findings';
import { AppDrawer, AppDrawerHeader, EmptyState, Loader, MaterialIcon } from '@components';
import { Alert, AlertTitle, Avatar, Box, Button, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import { useState } from 'react';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import {
  ResourceTypeFieldsFragment,
  RightSizingInsightFieldsFragment,
  RightSizingInsightFindingsEdgeFieldsFragment,
  UserFieldsFragment,
} from '@api/gql/graphql';
import {
  ACCESS_REQUEST_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  RESOURCE_FRAGMENT,
  RESOURCE_TYPE_FRAGMENT,
  RightSizingInsightFindingsEdge_Fragment,
  USER_FRAGMENT,
} from '@AccessRightSizing/services/gql';
import { getFragmentData } from '@api/gql';
import { INSIGHT_CONFIG_MAP, InsightConfig } from '../common/constants';
import { getInsightConfig, secondsToHours } from '@AccessRightSizing/common/common';
import { routes } from '@AccessFlows/routes';
import { generatePath, RouterLink, useSearchParams } from '@libs';
import { ACCESS_FLOW_QUERY_PARAM } from '@AccessRightSizing/components/RightSizingAccessFlowFilter';
import { DateTime } from '@utils';
import TriggerTypeChip from '@common/ui/TriggerTypeChip';
import { strToTriggerType } from '@Activity/utils';
import { TriggerTypeAppModel } from '@api';

const ListInsightFindingContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: '12px',
  border: `1px solid ${theme.palette.divider}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

interface InsightDrawerProps {
  open: boolean;
  onClose: () => void;
  insight: RightSizingInsightFieldsFragment;
}

export function InsightDrawer({ open, onClose, insight }: InsightDrawerProps) {
  const insightConfig = getInsightConfig(insight.insightType);
  const resourceType = getFragmentData(RESOURCE_TYPE_FRAGMENT, insight.resourceType);

  return (
    <AppDrawer
      open={open}
      onClose={onClose}
      width={800}
      header={<AppDrawerHeader title={insightConfig.title} onCloseClick={onClose} />}
    >
      {resourceType && (
        <InsightDrawerInner insight={insightConfig} insightId={insight.id} resourceType={resourceType} />
      )}
    </AppDrawer>
  );
}

export function InsightDrawerInner({
  insight,
  insightId,
  resourceType,
}: {
  insight: InsightConfig;
  insightId: string;
  resourceType: ResourceTypeFieldsFragment;
}) {
  const [searchParams] = useSearchParams();

  const accessFlowId = searchParams.get(ACCESS_FLOW_QUERY_PARAM);
  const [take, setTake] = useState(10);
  const {
    data,
    isFetched: isInsightsFindingFetched,
    isFetching: isLoading,
    error,
  } = useAccessRightSizingInsightFindings({ id: insightId, take });

  const { rightSizingInsightFindings } = data || { rightSizingInsightFindings: null };
  const { edges, pageInfo, totalCount } = rightSizingInsightFindings || { edges: [], pageInfo: null, totalCount: 0 };

  const pageInfoData = getFragmentData(PAGE_INFO_FRAGMENT, pageInfo);
  const { hasNextPage } = pageInfoData || { hasNextPage: false };

  const edgesData = getFragmentData(RightSizingInsightFindingsEdge_Fragment, edges);

  if (error || (isInsightsFindingFetched && edges.length === 0)) {
    return <EmptyState title="No Data Found" imgSrc="/static/EmptyStateImages/not-found-illustration.svg" />;
  }

  const isAccessRequestInsight = insight.title === INSIGHT_CONFIG_MAP.avg_access_request_duration.title;

  return (
    <Stack direction="column" spacing={2}>
      <Alert
        variant="outlinedInsights"
        icon={<MaterialIcon symbol="auto_awesome" size="small" />}
        action={
          <Button
            component={RouterLink}
            variant="explore-button"
            to={generatePath(routes.EditAccessFlow.path, { id: accessFlowId })}
            startIcon={<MaterialIcon symbol="auto_awesome" weight={800} />}
            style={{ alignSelf: 'center' }}
          >
            Edit Access Flow
          </Button>
        }
      >
        <Stack direction="column" spacing={1} maxWidth="500px">
          <AlertTitle>{insight.insightBannerHeader}</AlertTitle>
          {insight.description && <Typography variant="body2">{insight.description}</Typography>}
        </Stack>
      </Alert>
      {(!isInsightsFindingFetched || isLoading) && <Loader />}

      <Stack direction="row" spacing={2} justifyContent="space-between">
        <PrimarySecondary primary={resourceType.typeDisplayName} icon={resourceType.icons.svg} />
        <Typography variant="subtitle2" color="text.muted">
          Total Requests {insight.entity}: {totalCount}
        </Typography>
      </Stack>
      {isAccessRequestInsight && isInsightsFindingFetched && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" color="text.muted">
              REQUEST ID
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" color="text.muted">
              GRANTEE
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" color="text.muted">
              DURATION REQUESTED
            </Typography>
          </Grid>
        </Grid>
      )}
      {isInsightsFindingFetched && <ListInsightFindings insights={edgesData} />}
      {hasNextPage && isInsightsFindingFetched && <Button onClick={() => setTake(take + 10)}>Load More</Button>}
    </Stack>
  );
}

function ListInsightFindings({ insights }: { insights: readonly RightSizingInsightFindingsEdgeFieldsFragment[] }) {
  return (
    <ListInsightFindingContainer>
      {insights.map((insight, index) => (
        <InsightFinding insight={insight} index={index} length={insights.length} key={index} />
      ))}
    </ListInsightFindingContainer>
  );
}

function InsightFinding({
  insight,
  index,
  length,
}: {
  insight: RightSizingInsightFindingsEdgeFieldsFragment;
  index: number;
  length: number;
}) {
  const { node } = insight;
  if (!node) return <></>;
  if (node.__typename === 'User') {
    const nodeData = getFragmentData(USER_FRAGMENT, node);

    return (
      <>
        <UserDisplay user={nodeData} />
        {length - 1 > index && <Divider />}
      </>
    );
  }
  if (node.__typename === 'Resource') {
    const nodeData = getFragmentData(RESOURCE_FRAGMENT, node);
    return (
      <>
        <PrimarySecondary primary={nodeData.name} secondary={nodeData.path.map((val) => val.value).join('/')} />
        {length - 1 > index && <Divider />}
      </>
    );
  }
  if (node.__typename === 'AccessRequest') {
    const nodeData = getFragmentData(ACCESS_REQUEST_FRAGMENT, node);
    const grantee = getFragmentData(USER_FRAGMENT, nodeData.grantee);

    const date = DateTime.fromDate(nodeData.date);
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <PrimarySecondary
            primary={nodeData.id}
            secondary={date.isoString}
            icon={<TriggerTypeChip triggerType={strToTriggerType(TriggerTypeAppModel.UserRequest)} />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UserDisplay user={grantee} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="inputLabel">{secondsToHours(nodeData.durationInSeconds)}</Typography>
        </Grid>
        {length - 1 > index && <Divider />}
      </Grid>
    );
  }
  if (node.__typename === 'Permission') {
    return (
      <>
        <PrimarySecondary primary={node.name} />
        {length - 1 > index && <Divider />}
      </>
    );
  }
}

function UserDisplay({ user }: { user: UserFieldsFragment }) {
  const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase();
  return (
    <PrimarySecondary
      primary={`${user.firstName} ${user.lastName}`}
      secondary={user.email}
      icon={<Avatar sx={{ fontSize: '12px' }}>{initials}</Avatar>}
    />
  );
}
