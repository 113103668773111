import { adminLayoutWrap } from '@middlewares';
import { AuditEventsPage } from '@AuditEvents/pages/AuditEventsPage';
import { Flag } from '@utils';

export const routes = {
  AuditEvents: {
    label: 'Session Audit',
    path: '/session-audit',
    page: adminLayoutWrap(<AuditEventsPage />, Flag.SESSION_AUDIT),
  },
};
