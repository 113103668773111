import {
  InteractiveDropdown,
  InteractiveDropdownSelect,
  InteractiveDropdownView,
  Popover,
  usePopover,
} from '@components';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { HumanReadableText } from '@AccessFlows/organisms/FlowForm/common';
import { ApproverTypeModel } from '@AccessFlows/components/forms/ComplexUserRequestForm';

export interface FlowFormSelectAdvancedApproversProps {
  value: string;
  onChange: (value: string) => void;
}

export function FlowFormSelectApproversType({ value, onChange }: FlowFormSelectAdvancedApproversProps) {
  const { track } = useAnalyticsContext();
  const externalState = usePopover();

  const handleApproverTypeChange = (type: string) => {
    onChange(type);
    track(CustomAnalyticsEvents.SELECT_APPROVER, { approverType: type });
    externalState.closeDropdown(true);
  };

  const options = [
    { key: ApproverTypeModel.Auto, value: ApproverTypeModel.Auto },
    { key: ApproverTypeModel.Approval, value: ApproverTypeModel.Approval },
  ];

  return (
    <Popover
      testId="flow-form-select-approver-type"
      trigger={<HumanReadableText value={value} placeholder={value} />}
      externalState={externalState}
    >
      <InteractiveDropdown wide currentView={0}>
        <InteractiveDropdownView current>
          <InteractiveDropdownSelect<string> value={value} options={options} onChange={handleApproverTypeChange} />
        </InteractiveDropdownView>
      </InteractiveDropdown>
    </Popover>
  );
}
