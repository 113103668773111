import { Handle, NodeProps, Position } from 'reactflow';

import { alpha, Box, Stack, styled } from '@mui/material';

import { GROUPED_NODE_H_PADDING, GROUPED_NODE_WIDTH, RESOURCE_NODE_WIDTH, RESOURCE_NODES_GAP } from './common/common';
import { NodeMeta, NodeMetaProps } from './common/NodeMeta';
import { ExpandButton } from './common/ExpandButton';

const GroupedNodeWrapper = styled(Box)(({ theme }) => ({
  cursor: 'default',
  border: `1px solid ${theme.palette.border.button}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${RESOURCE_NODES_GAP}px ${GROUPED_NODE_H_PADDING}px`,
  width: GROUPED_NODE_WIDTH,
  backgroundColor: alpha(theme.palette.background.default, 0.9),

  '& .collapse-btn': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: RESOURCE_NODE_WIDTH,
    color: theme.palette.text.dropdown,
  },
}));

export function GroupedNode({ data }: NodeProps<NodeMetaProps>) {
  return (
    <GroupedNodeWrapper>
      <Handle type="target" position={Position.Left} />
      <NodeMeta {...data} showExpand />
      <Handle type="source" position={Position.Right} />
    </GroupedNodeWrapper>
  );
}

export function GroupedNodeInput({ data }: NodeProps<NodeMetaProps>) {
  return (
    <GroupedNodeWrapper>
      <NodeMeta {...data} showExpand />
      <Handle type="source" position={Position.Right} />
    </GroupedNodeWrapper>
  );
}

export function GroupedNodeOutput({ data }: NodeProps<NodeMetaProps>) {
  return (
    <GroupedNodeWrapper>
      <Handle type="target" position={Position.Left} />
      <NodeMeta {...data} showExpand />
    </GroupedNodeWrapper>
  );
}

export interface GroupedNodeExpandedProps extends NodeMetaProps {
  childrenHeight: number;
}

export function GroupedNodeExpanded({ data }: NodeProps<GroupedNodeExpandedProps>) {
  const { childrenHeight, ...rest } = data;

  return (
    <GroupedNodeWrapper>
      <Stack direction="column" alignItems="center" spacing={`${RESOURCE_NODES_GAP}px`}>
        <NodeMeta {...rest} />
        {childrenHeight > 0 ? <Box sx={{ height: childrenHeight }}></Box> : null}
        <ExpandButton less />
      </Stack>
    </GroupedNodeWrapper>
  );
}
