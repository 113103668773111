import { PropsWithChildren, useState } from 'react';
import { Stack, Box, styled, IconButton, Fade } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

const AppSidebarContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.drawer,
  position: 'relative',

  '& > .toggler': {
    position: 'absolute',
    right: theme.spacing(-1.3),
    top: theme.spacing(4),
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const AppSidebarBody = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
});

interface AppSidebarProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  minimized?: boolean;
  onMinimizeToggle?: () => void;
}

export default function AppSidebar({
  header,
  footer,
  minimized = false,
  onMinimizeToggle,
  children,
}: PropsWithChildren<AppSidebarProps>) {
  let timer: NodeJS.Timeout;

  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    clearTimeout(timer);
    setHovered(true);
  };

  const handleMouseLeave = () => {
    timer = setTimeout(() => setHovered(false), 500);
  };

  return (
    <AppSidebarContainer
      direction="column"
      justifyContent="stretch"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {header && <div>{header}</div>}
      <AppSidebarBody>{children}</AppSidebarBody>
      {footer && <div>{footer}</div>}
      {onMinimizeToggle && hovered && (
        <Fade in={hovered}>
          <IconButton size="small" className="toggler" onClick={onMinimizeToggle}>
            {minimized ? <ChevronRightRoundedIcon fontSize="inherit" /> : <ChevronLeftRoundedIcon fontSize="inherit" />}
          </IconButton>
        </Fade>
      )}
    </AppSidebarContainer>
  );
}
