/* tslint:disable */
/* eslint-disable */
/**
 * Apono
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessBundle
 */
export interface AccessBundle {
    /**
     * 
     * @type {string}
     * @memberof AccessBundle
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessBundle
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AccessBundleAppModel
 */
export interface AccessBundleAppModel {
    /**
     * 
     * @type {string}
     * @memberof AccessBundleAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessBundleAppModel
     */
    'name': string;
    /**
     * 
     * @type {Array<AccessTargetAppModel>}
     * @memberof AccessBundleAppModel
     */
    'targets': Array<AccessTargetAppModel>;
    /**
     * 
     * @type {number}
     * @memberof AccessBundleAppModel
     */
    'created_date': number;
    /**
     * 
     * @type {number}
     * @memberof AccessBundleAppModel
     */
    'updated_date': number;
}
/**
 * 
 * @export
 * @interface AccessBundleUpsertAppModel
 */
export interface AccessBundleUpsertAppModel {
    /**
     * 
     * @type {string}
     * @memberof AccessBundleUpsertAppModel
     */
    'name': string;
    /**
     * 
     * @type {Array<AccessTargetAppModel>}
     * @memberof AccessBundleUpsertAppModel
     */
    'targets': Array<AccessTargetAppModel>;
}
/**
 * 
 * @export
 * @interface AccessDetailsPreviewAppModel
 */
export interface AccessDetailsPreviewAppModel {
    /**
     * 
     * @type {AccessSessionDetailsAppModel}
     * @memberof AccessDetailsPreviewAppModel
     */
    'with_credentials': AccessSessionDetailsAppModel;
    /**
     * 
     * @type {AccessSessionDetailsAppModel}
     * @memberof AccessDetailsPreviewAppModel
     */
    'without_credentials': AccessSessionDetailsAppModel;
}
/**
 * 
 * @export
 * @interface AccessEdgeModel
 */
export interface AccessEdgeModel {
    /**
     * 
     * @type {string}
     * @memberof AccessEdgeModel
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof AccessEdgeModel
     */
    'target': string;
    /**
     * 
     * @type {string}
     * @memberof AccessEdgeModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AccessFlow
 */
export interface AccessFlow {
    /**
     * 
     * @type {string}
     * @memberof AccessFlow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessFlow
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AccessFlowAppModel
 */
export interface AccessFlowAppModel {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowAppModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModel
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerTypeAppModel}
     * @memberof AccessFlowAppModel
     */
    'trigger_type': TriggerTypeAppModel;
    /**
     * 
     * @type {Array<GranteeAppModel>}
     * @memberof AccessFlowAppModel
     */
    'grantees': Array<GranteeAppModel>;
    /**
     * 
     * @type {Array<AccessTargetAppModel>}
     * @memberof AccessFlowAppModel
     */
    'targets': Array<AccessTargetAppModel>;
    /**
     * 
     * @type {Array<ApproverAppModel>}
     * @memberof AccessFlowAppModel
     */
    'approvers': Array<ApproverAppModel>;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModel
     */
    'require_all_approvers': boolean;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowAppModel
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModel
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowAppModelTimeframe}
     * @memberof AccessFlowAppModel
     */
    'timeframe'?: AccessFlowAppModelTimeframe;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowAppModel
     */
    'created_date': number;
    /**
     * 
     * @type {AccessFlowAppModelSettings}
     * @memberof AccessFlowAppModel
     */
    'settings'?: AccessFlowAppModelSettings;
    /**
     * 
     * @type {Array<LabelAppModel>}
     * @memberof AccessFlowAppModel
     */
    'labels': Array<LabelAppModel>;
}
/**
 * 
 * @export
 * @interface AccessFlowAppModelSettings
 */
export interface AccessFlowAppModelSettings {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModelSettings
     */
    'require_approver_justification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModelSettings
     */
    'approver_cannot_approve_himself'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModelSettings
     */
    'required_mfa'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowAppModelTimeframe
 */
export interface AccessFlowAppModelTimeframe {
    /**
     * 
     * @type {number}
     * @memberof AccessFlowAppModelTimeframe
     */
    'start_time_sec': number;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowAppModelTimeframe
     */
    'end_time_sec': number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof AccessFlowAppModelTimeframe
     */
    'days_in_week': Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowAppModelTimeframe
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface AccessFlowAppModelV2
 */
export interface AccessFlowAppModelV2 {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowAppModelV2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowAppModelV2
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModelV2
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerTypeAppModel}
     * @memberof AccessFlowAppModelV2
     */
    'trigger_type': TriggerTypeAppModel;
    /**
     * 
     * @type {GranteeFilterGroupAppModel}
     * @memberof AccessFlowAppModelV2
     */
    'grantees': GranteeFilterGroupAppModel;
    /**
     * 
     * @type {Array<AccessTargetAppModel>}
     * @memberof AccessFlowAppModelV2
     */
    'targets': Array<AccessTargetAppModel>;
    /**
     * 
     * @type {Array<ApproverAppModel>}
     * @memberof AccessFlowAppModelV2
     */
    'approvers': Array<ApproverAppModel>;
    /**
     * 
     * @type {AccessFlowAppModelV2ApproverPolicy}
     * @memberof AccessFlowAppModelV2
     */
    'approver_policy'?: AccessFlowAppModelV2ApproverPolicy;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModelV2
     */
    'require_all_approvers': boolean;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowAppModelV2
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowAppModelV2
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowAppModelTimeframe}
     * @memberof AccessFlowAppModelV2
     */
    'timeframe'?: AccessFlowAppModelTimeframe;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowAppModelV2
     */
    'created_date': number;
    /**
     * 
     * @type {AccessFlowAppModelSettings}
     * @memberof AccessFlowAppModelV2
     */
    'settings'?: AccessFlowAppModelSettings;
    /**
     * 
     * @type {Array<LabelAppModel>}
     * @memberof AccessFlowAppModelV2
     */
    'labels': Array<LabelAppModel>;
}
/**
 * 
 * @export
 * @interface AccessFlowAppModelV2ApproverPolicy
 */
export interface AccessFlowAppModelV2ApproverPolicy {
    /**
     * 
     * @type {ApproverPolicyGroupsOperatorAppModel}
     * @memberof AccessFlowAppModelV2ApproverPolicy
     */
    'groups_operator': ApproverPolicyGroupsOperatorAppModel;
    /**
     * 
     * @type {Array<ApproverConditionGroupAppModel>}
     * @memberof AccessFlowAppModelV2ApproverPolicy
     */
    'condition_groups': Array<ApproverConditionGroupAppModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccessFlowAreaModel = {
    Grantee: 'Grantee',
    Approver: 'Approver'
} as const;

export type AccessFlowAreaModel = typeof AccessFlowAreaModel[keyof typeof AccessFlowAreaModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const AccessFlowFilterAppModel = {
    User: 'user',
    Group: 'group',
    Integration: 'integration',
    Resource: 'resource',
    Permission: 'permission'
} as const;

export type AccessFlowFilterAppModel = typeof AccessFlowFilterAppModel[keyof typeof AccessFlowFilterAppModel];


/**
 * 
 * @export
 * @interface AccessFlowFilterResult
 */
export interface AccessFlowFilterResult {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowFilterResult
     */
    'access_flow_id': string;
    /**
     * 
     * @type {Array<FilterResult>}
     * @memberof AccessFlowFilterResult
     */
    'results': Array<FilterResult>;
}
/**
 * 
 * @export
 * @interface AccessFlowIssuesAppModel
 */
export interface AccessFlowIssuesAppModel {
    /**
     * 
     * @type {Array<ResourcesIssuesAppModel>}
     * @memberof AccessFlowIssuesAppModel
     */
    'resources': Array<ResourcesIssuesAppModel>;
}
/**
 * 
 * @export
 * @interface AccessFlowModelV1
 */
export interface AccessFlowModelV1 {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowModelV1
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowModelV1
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessFlowModelV1
     */
    'group_ids': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessFlowModelV1
     */
    'grantee_user_emails': Array<string>;
    /**
     * 
     * @type {GroupsLogicalOperatorModel}
     * @memberof AccessFlowModelV1
     */
    'groups_logical_operator': GroupsLogicalOperatorModel;
    /**
     * 
     * @type {Array<AccessTargetModelV1>}
     * @memberof AccessFlowModelV1
     */
    'access_targets': Array<AccessTargetModelV1>;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV1
     */
    'duration_in_sec': number;
    /**
     * 
     * @type {Array<ApprovalChallenge>}
     * @memberof AccessFlowModelV1
     */
    'challenges': Array<ApprovalChallenge>;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV1
     */
    'created_date': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV1
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowModelV1Timeframe}
     * @memberof AccessFlowModelV1
     */
    'timeframe'?: AccessFlowModelV1Timeframe;
    /**
     * 
     * @type {AccessFlowModelV1Settings}
     * @memberof AccessFlowModelV1
     */
    'settings'?: AccessFlowModelV1Settings;
}
/**
 * 
 * @export
 * @interface AccessFlowModelV1Settings
 */
export interface AccessFlowModelV1Settings {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV1Settings
     */
    'require_approver_justification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV1Settings
     */
    'approver_cannot_approve_himself'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowModelV1Timeframe
 */
export interface AccessFlowModelV1Timeframe {
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV1Timeframe
     */
    'start_time_sec': number;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV1Timeframe
     */
    'end_time_sec': number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof AccessFlowModelV1Timeframe
     */
    'days_in_week': Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowModelV1Timeframe
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface AccessFlowModelV2
 */
export interface AccessFlowModelV2 {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowModelV2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowModelV2
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV2
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerType}
     * @memberof AccessFlowModelV2
     */
    'trigger_type': TriggerType;
    /**
     * 
     * @type {Array<GranteeModel>}
     * @memberof AccessFlowModelV2
     */
    'grantees': Array<GranteeModel>;
    /**
     * 
     * @type {Array<AccessTargetModel>}
     * @memberof AccessFlowModelV2
     */
    'access_targets': Array<AccessTargetModel>;
    /**
     * 
     * @type {Array<ApproverModel>}
     * @memberof AccessFlowModelV2
     */
    'approvers': Array<ApproverModel>;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV2
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV2
     */
    'justification_required': boolean;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV2
     */
    'created_date': number;
    /**
     * 
     * @type {AccessFlowModelV2Timeframe}
     * @memberof AccessFlowModelV2
     */
    'timeframe'?: AccessFlowModelV2Timeframe;
    /**
     * 
     * @type {AccessFlowModelV2Settings}
     * @memberof AccessFlowModelV2
     */
    'settings'?: AccessFlowModelV2Settings;
}
/**
 * 
 * @export
 * @interface AccessFlowModelV2Settings
 */
export interface AccessFlowModelV2Settings {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV2Settings
     */
    'require_approver_justification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowModelV2Settings
     */
    'approver_cannot_approve_himself'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowModelV2Timeframe
 */
export interface AccessFlowModelV2Timeframe {
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV2Timeframe
     */
    'start_time_sec': number;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowModelV2Timeframe
     */
    'end_time_sec': number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof AccessFlowModelV2Timeframe
     */
    'days_in_week': Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowModelV2Timeframe
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface AccessFlowSearchResult
 */
export interface AccessFlowSearchResult {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowSearchResult
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessFlowSearchResult
     */
    'access': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowSearchResult
     */
    'duration_in_seconds': number;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowSearchResult
     */
    'created': number;
}
/**
 * 
 * @export
 * @interface AccessFlowSetActiveAppRequest
 */
export interface AccessFlowSetActiveAppRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSetActiveAppRequest
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowSettings
 */
export interface AccessFlowSettings {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettings
     */
    'require_approver_justification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettings
     */
    'approver_cannot_approve_himself'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowSettingsAppModel
 */
export interface AccessFlowSettingsAppModel {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettingsAppModel
     */
    'require_approver_justification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettingsAppModel
     */
    'approver_cannot_approve_himself'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettingsAppModel
     */
    'required_mfa'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowSettingsModelV1
 */
export interface AccessFlowSettingsModelV1 {
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettingsModelV1
     */
    'require_approver_justification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowSettingsModelV1
     */
    'approver_cannot_approve_himself'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessFlowUpsertAppModel
 */
export interface AccessFlowUpsertAppModel {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowUpsertAppModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowUpsertAppModel
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerTypeAppModel}
     * @memberof AccessFlowUpsertAppModel
     */
    'trigger_type': TriggerTypeAppModel;
    /**
     * 
     * @type {Array<GranteeAppModel>}
     * @memberof AccessFlowUpsertAppModel
     */
    'grantees': Array<GranteeAppModel>;
    /**
     * 
     * @type {Array<AccessTargetAppModel>}
     * @memberof AccessFlowUpsertAppModel
     */
    'targets': Array<AccessTargetAppModel>;
    /**
     * 
     * @type {Array<ApproverAppModel>}
     * @memberof AccessFlowUpsertAppModel
     */
    'approvers': Array<ApproverAppModel>;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowUpsertAppModel
     */
    'require_all_approvers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowUpsertAppModel
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowUpsertAppModel
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowAppModelTimeframe}
     * @memberof AccessFlowUpsertAppModel
     */
    'timeframe'?: AccessFlowAppModelTimeframe;
    /**
     * 
     * @type {AccessFlowAppModelSettings}
     * @memberof AccessFlowUpsertAppModel
     */
    'settings'?: AccessFlowAppModelSettings;
    /**
     * 
     * @type {Array<LabelAppModel>}
     * @memberof AccessFlowUpsertAppModel
     */
    'labels': Array<LabelAppModel>;
}
/**
 * 
 * @export
 * @interface AccessFlowUpsertAppModelV2
 */
export interface AccessFlowUpsertAppModelV2 {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerTypeAppModel}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'trigger_type': TriggerTypeAppModel;
    /**
     * 
     * @type {GranteeFilterGroupUpsertAppModel}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'grantees': GranteeFilterGroupUpsertAppModel;
    /**
     * 
     * @type {Array<AccessTargetAppModel>}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'targets': Array<AccessTargetAppModel>;
    /**
     * 
     * @type {Array<ApproverAppModel>}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'approvers': Array<ApproverAppModel>;
    /**
     * 
     * @type {AccessFlowUpsertAppModelV2ApproverPolicy}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'approver_policy'?: AccessFlowUpsertAppModelV2ApproverPolicy;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'require_all_approvers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowAppModelTimeframe}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'timeframe'?: AccessFlowAppModelTimeframe;
    /**
     * 
     * @type {AccessFlowAppModelSettings}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'settings'?: AccessFlowAppModelSettings;
    /**
     * 
     * @type {Array<LabelAppModel>}
     * @memberof AccessFlowUpsertAppModelV2
     */
    'labels': Array<LabelAppModel>;
}
/**
 * 
 * @export
 * @interface AccessFlowUpsertAppModelV2ApproverPolicy
 */
export interface AccessFlowUpsertAppModelV2ApproverPolicy {
    /**
     * 
     * @type {ApproverPolicyGroupsOperatorAppModel}
     * @memberof AccessFlowUpsertAppModelV2ApproverPolicy
     */
    'groups_operator': ApproverPolicyGroupsOperatorAppModel;
    /**
     * 
     * @type {Array<ApproverConditionGroupUpsertAppModel>}
     * @memberof AccessFlowUpsertAppModelV2ApproverPolicy
     */
    'condition_groups': Array<ApproverConditionGroupUpsertAppModel>;
}
/**
 * 
 * @export
 * @interface AccessFlowsLinkedToIntegration
 */
export interface AccessFlowsLinkedToIntegration {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsLinkedToIntegration
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsLinkedToIntegration
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AccessGraphUnitModel
 */
export interface AccessGraphUnitModel {
    /**
     * 
     * @type {string}
     * @memberof AccessGraphUnitModel
     */
    'user_email': string;
    /**
     * 
     * @type {string}
     * @memberof AccessGraphUnitModel
     */
    'integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessGraphUnitModel
     */
    'resource_type': string;
    /**
     * 
     * @type {ResourceAppModel}
     * @memberof AccessGraphUnitModel
     */
    'resource': ResourceAppModel;
    /**
     * 
     * @type {string}
     * @memberof AccessGraphUnitModel
     */
    'permission': string;
    /**
     * 
     * @type {string}
     * @memberof AccessGraphUnitModel
     */
    'request_id': string;
    /**
     * 
     * @type {number}
     * @memberof AccessGraphUnitModel
     */
    'expiration'?: number;
}
/**
 * 
 * @export
 * @interface AccessGraphUnitsSearchRequestModel
 */
export interface AccessGraphUnitsSearchRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphUnitsSearchRequestModel
     */
    'integration_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphUnitsSearchRequestModel
     */
    'user_emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphUnitsSearchRequestModel
     */
    'resource_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphUnitsSearchRequestModel
     */
    'request_ids'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccessGraphUnitsSearchRequestModel
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessGraphUnitsSearchRequestModel
     */
    'limit'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccessNodeCategoryModel = {
    Identity: 'Identity',
    Resource: 'Resource',
    Action: 'Action',
    Other: 'Other'
} as const;

export type AccessNodeCategoryModel = typeof AccessNodeCategoryModel[keyof typeof AccessNodeCategoryModel];


/**
 * 
 * @export
 * @interface AccessNodeFilterModel
 */
export interface AccessNodeFilterModel {
    /**
     * 
     * @type {string}
     * @memberof AccessNodeFilterModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof AccessNodeFilterModel
     */
    'limit': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessNodeFilterModel
     */
    'filters': Array<string>;
}
/**
 * 
 * @export
 * @interface AccessNodeModel
 */
export interface AccessNodeModel {
    /**
     * 
     * @type {string}
     * @memberof AccessNodeModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessNodeModel
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof AccessNodeModel
     */
    'type': string;
    /**
     * 
     * @type {AccessNodeCategoryModel}
     * @memberof AccessNodeModel
     */
    'category': AccessNodeCategoryModel;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccessNodeModel
     */
    'metadata': { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof AccessNodeModel
     */
    'more'?: number;
}
/**
 * 
 * @export
 * @interface AccessOverTimeDataPoint
 */
export interface AccessOverTimeDataPoint {
    /**
     * 
     * @type {number}
     * @memberof AccessOverTimeDataPoint
     */
    'date': number;
    /**
     * 
     * @type {number}
     * @memberof AccessOverTimeDataPoint
     */
    'total_granted': number;
    /**
     * 
     * @type {number}
     * @memberof AccessOverTimeDataPoint
     */
    'total_revoked': number;
    /**
     * 
     * @type {number}
     * @memberof AccessOverTimeDataPoint
     */
    'active_access': number;
}
/**
 * 
 * @export
 * @interface AccessOverTimeQueryResponse
 */
export interface AccessOverTimeQueryResponse {
    /**
     * 
     * @type {Array<AccessOverTimeDataPoint>}
     * @memberof AccessOverTimeQueryResponse
     */
    'data_points': Array<AccessOverTimeDataPoint>;
}
/**
 * 
 * @export
 * @interface AccessRequestBulkActionResponse
 */
export interface AccessRequestBulkActionResponse {
    /**
     * 
     * @type {string}
     * @memberof AccessRequestBulkActionResponse
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestBulkActionResponse
     */
    'affected_count': number;
}
/**
 * 
 * @export
 * @interface AccessSessionDetailsAppModel
 */
export interface AccessSessionDetailsAppModel {
    /**
     * 
     * @type {InstructionAppModel}
     * @memberof AccessSessionDetailsAppModel
     */
    'instructions': InstructionAppModel;
}
/**
 * 
 * @export
 * @interface AccessTargetAppModel
 */
export interface AccessTargetAppModel {
    /**
     * 
     * @type {AccessTargetType}
     * @memberof AccessTargetAppModel
     */
    'target_type': AccessTargetType;
    /**
     * 
     * @type {AccessTargetAppModelBundle}
     * @memberof AccessTargetAppModel
     */
    'bundle'?: AccessTargetAppModelBundle;
    /**
     * 
     * @type {AccessTargetAppModelIntegration}
     * @memberof AccessTargetAppModel
     */
    'integration'?: AccessTargetAppModelIntegration;
}
/**
 * 
 * @export
 * @interface AccessTargetAppModelBundle
 */
export interface AccessTargetAppModelBundle {
    /**
     * 
     * @type {string}
     * @memberof AccessTargetAppModelBundle
     */
    'bundle_id': string;
}
/**
 * 
 * @export
 * @interface AccessTargetAppModelIntegration
 */
export interface AccessTargetAppModelIntegration {
    /**
     * 
     * @type {string}
     * @memberof AccessTargetAppModelIntegration
     */
    'integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTargetAppModelIntegration
     */
    'resource_type': string;
    /**
     * 
     * @type {Array<TagAppModel>}
     * @memberof AccessTargetAppModelIntegration
     */
    'resource_tag_matchers': Array<TagAppModel>;
    /**
     * 
     * @type {Array<TagAppModel>}
     * @memberof AccessTargetAppModelIntegration
     */
    'resource_tag_excludes': Array<TagAppModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessTargetAppModelIntegration
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface AccessTargetModel
 */
export interface AccessTargetModel {
    /**
     * 
     * @type {string}
     * @memberof AccessTargetModel
     */
    'resource_integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTargetModel
     */
    'resource_type': string;
    /**
     * 
     * @type {Array<TagSelectorModel>}
     * @memberof AccessTargetModel
     */
    'resource_tag_restrictors': Array<TagSelectorModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessTargetModel
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {Array<TagSelectorModel>}
     * @memberof AccessTargetModel
     */
    'resource_tag_excludes': Array<TagSelectorModel>;
}
/**
 * 
 * @export
 * @interface AccessTargetModelV1
 */
export interface AccessTargetModelV1 {
    /**
     * 
     * @type {string}
     * @memberof AccessTargetModelV1
     */
    'resource_integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTargetModelV1
     */
    'resource_type': string;
    /**
     * 
     * @type {Array<TagSelectorModelV1>}
     * @memberof AccessTargetModelV1
     */
    'resource_tag_restrictors': Array<TagSelectorModelV1>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessTargetModelV1
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccessTargetType = {
    Integration: 'integration',
    Bundle: 'bundle'
} as const;

export type AccessTargetType = typeof AccessTargetType[keyof typeof AccessTargetType];


/**
 * 
 * @export
 * @interface AccessUnitAppModel
 */
export interface AccessUnitAppModel {
    /**
     * 
     * @type {string}
     * @memberof AccessUnitAppModel
     */
    'id': string;
    /**
     * 
     * @type {ResourceAppModel}
     * @memberof AccessUnitAppModel
     */
    'resource': ResourceAppModel;
    /**
     * 
     * @type {string}
     * @memberof AccessUnitAppModel
     */
    'permission': string;
    /**
     * 
     * @type {AccessUnitStatusAppModel}
     * @memberof AccessUnitAppModel
     */
    'status': AccessUnitStatusAppModel;
    /**
     * 
     * @type {number}
     * @memberof AccessUnitAppModel
     */
    'updated_date': number;
}
/**
 * 
 * @export
 * @interface AccessUnitModel
 */
export interface AccessUnitModel {
    /**
     * 
     * @type {Resource}
     * @memberof AccessUnitModel
     */
    'resource': Resource;
    /**
     * 
     * @type {string}
     * @memberof AccessUnitModel
     */
    'permission': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccessUnitStatus = {
    Granted: 'Granted',
    Failure: 'Failure',
    FailedGranting: 'FailedGranting',
    FailedRevoking: 'FailedRevoking',
    Pending: 'Pending',
    Revoked: 'Revoked'
} as const;

export type AccessUnitStatus = typeof AccessUnitStatus[keyof typeof AccessUnitStatus];


/**
 * 
 * @export
 * @interface AccessUnitStatusAppModel
 */
export interface AccessUnitStatusAppModel {
    /**
     * 
     * @type {AccessUnitStatus}
     * @memberof AccessUnitStatusAppModel
     */
    'status': AccessUnitStatus;
    /**
     * 
     * @type {string}
     * @memberof AccessUnitStatusAppModel
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface AccessUnitStatusSummaryAppModel
 */
export interface AccessUnitStatusSummaryAppModel {
    /**
     * 
     * @type {AccessUnitStatus}
     * @memberof AccessUnitStatusSummaryAppModel
     */
    'status': AccessUnitStatus;
    /**
     * 
     * @type {number}
     * @memberof AccessUnitStatusSummaryAppModel
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AccessUnitsGroupAppModel
 */
export interface AccessUnitsGroupAppModel {
    /**
     * 
     * @type {IntegrationPartialAppModel}
     * @memberof AccessUnitsGroupAppModel
     */
    'integration': IntegrationPartialAppModel;
    /**
     * 
     * @type {Array<ResourceTypeAccessUnitGroupAppModel>}
     * @memberof AccessUnitsGroupAppModel
     */
    'resource_types': Array<ResourceTypeAccessUnitGroupAppModel>;
}
/**
 * 
 * @export
 * @interface AccessUnitsSummaryAppModel
 */
export interface AccessUnitsSummaryAppModel {
    /**
     * 
     * @type {Array<AccessUnitStatusSummaryAppModel>}
     * @memberof AccessUnitsSummaryAppModel
     */
    'total': Array<AccessUnitStatusSummaryAppModel>;
}
/**
 * 
 * @export
 * @interface AccountModel
 */
export interface AccountModel {
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'created_date': number;
    /**
     * 
     * @type {AccountStatusModel}
     * @memberof AccountModel
     */
    'status': AccountStatusModel;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'plan': string;
}
/**
 * 
 * @export
 * @interface AccountSettingsModel
 */
export interface AccountSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsModel
     */
    'enable_flow_failure_notifications': boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingsModel
     */
    'max_access_grant_extend_times': number;
    /**
     * 
     * @type {object}
     * @memberof AccountSettingsModel
     */
    'default_integration_metadata_by_connection_type': object;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingsModel
     */
    'credentials_rotation_period_in_days'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingsModel
     */
    'inactive_user_retention_in_days'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsModel
     */
    'integrations_updates_notifications'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingsModel
     */
    'access_provision_timeout_seconds'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsModel
     */
    'require_duration_for_access_request'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccountStatusModel = {
    Onboarding: 'ONBOARDING',
    InitialDiscovery: 'INITIAL_DISCOVERY',
    Ready: 'READY'
} as const;

export type AccountStatusModel = typeof AccountStatusModel[keyof typeof AccountStatusModel];


/**
 * 
 * @export
 * @interface ActiveAccessFilterOptionModel
 */
export interface ActiveAccessFilterOptionModel {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessFilterOptionModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessFilterOptionModel
     */
    'value': string;
    /**
     * 
     * @type {object}
     * @memberof ActiveAccessFilterOptionModel
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface ActiveAccessFiltersResponse
 */
export interface ActiveAccessFiltersResponse {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessFiltersResponse
     */
    'type': string;
    /**
     * 
     * @type {Array<ActiveAccessFilterOptionModel>}
     * @memberof ActiveAccessFiltersResponse
     */
    'options': Array<ActiveAccessFilterOptionModel>;
}
/**
 * 
 * @export
 * @interface ActiveAccessRequestLinkedToIntegration
 */
export interface ActiveAccessRequestLinkedToIntegration {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessRequestLinkedToIntegration
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessRequestLinkedToIntegration
     */
    'friendly_id': string;
}
/**
 * 
 * @export
 * @interface ActiveAccessResponse
 */
export interface ActiveAccessResponse {
    /**
     * 
     * @type {Array<AccessNodeModel>}
     * @memberof ActiveAccessResponse
     */
    'nodes': Array<AccessNodeModel>;
    /**
     * 
     * @type {Array<AccessEdgeModel>}
     * @memberof ActiveAccessResponse
     */
    'edges': Array<AccessEdgeModel>;
}
/**
 * 
 * @export
 * @interface ActiveAccessUserAppModel
 */
export interface ActiveAccessUserAppModel {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessUserAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessUserAppModel
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityAuditEventType = {
    RequestCreated: 'RequestCreated',
    RequestReviewed: 'RequestReviewed',
    RequestRejected: 'RequestRejected',
    PendingForApprover: 'PendingForApprover',
    RejectedByApprover: 'RejectedByApprover',
    ApprovedByApprover: 'ApprovedByApprover',
    ApprovedAutomatically: 'ApprovedAutomatically',
    PendingForProvisioning: 'PendingForProvisioning',
    AccessGranted: 'AccessGranted',
    AccessGrantFailed: 'AccessGrantFailed',
    PendingForDeprovisioning: 'PendingForDeprovisioning',
    DeprovisioningDone: 'DeprovisioningDone',
    AccessRevoked: 'AccessRevoked',
    AccessRevokeFailed: 'AccessRevokeFailed',
    CredentialsRotated: 'CredentialsRotated',
    CredentialsRotationFailed: 'CredentialsRotationFailed',
    RequestPendingMfa: 'RequestPendingMfa',
    RequestApprovedMfa: 'RequestApprovedMfa',
    RequestPreviouslyApprovedMfa: 'RequestPreviouslyApprovedMfa'
} as const;

export type ActivityAuditEventType = typeof ActivityAuditEventType[keyof typeof ActivityAuditEventType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityFilter = {
    RequestorId: 'requestor_id',
    IntegrationId: 'integration_id',
    Resource: 'resource',
    Permission: 'permission',
    Status: 'status',
    ResourceType: 'resource_type',
    AccessFlowId: 'access_flow_id',
    TriggerType: 'trigger_type'
} as const;

export type ActivityFilter = typeof ActivityFilter[keyof typeof ActivityFilter];


/**
 * 
 * @export
 * @interface ActivityReportExportModel
 */
export interface ActivityReportExportModel {
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'request_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'request_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'request_grant_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'request_revoke_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'requestor_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'requestor_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'resources'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'resource_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'permissions'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'approver_names'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'approver_emails'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'approver_types'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'justification'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'resources_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'trigger_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'access_flow'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportExportModel
     */
    'bundle_name'?: string;
}
/**
 * 
 * @export
 * @interface ActivityReportFiltersModel
 */
export interface ActivityReportFiltersModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'requestor_id'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'integration_id'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'resource'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'resource_type'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'permission'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'status'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportFiltersModel
     */
    'trigger_type'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityReportModel
 */
export interface ActivityReportModel {
    /**
     * 
     * @type {string}
     * @memberof ActivityReportModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityReportModel
     */
    'active': boolean;
    /**
     * 
     * @type {ActivityReportFiltersModel}
     * @memberof ActivityReportModel
     */
    'filters': ActivityReportFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof ActivityReportModel
     */
    'start_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityReportModel
     */
    'end_date'?: number;
    /**
     * 
     * @type {ActivityReportModelPeriod}
     * @memberof ActivityReportModel
     */
    'period'?: ActivityReportModelPeriod;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportModel
     */
    'time_zone'?: string;
    /**
     * 
     * @type {Array<ReportField>}
     * @memberof ActivityReportModel
     */
    'fields': Array<ReportField>;
    /**
     * 
     * @type {ActivityReportModelSchedule}
     * @memberof ActivityReportModel
     */
    'schedule'?: ActivityReportModelSchedule;
    /**
     * 
     * @type {number}
     * @memberof ActivityReportModel
     */
    'created_date': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityReportModel
     */
    'updated_date': number;
}
/**
 * 
 * @export
 * @interface ActivityReportModelPeriod
 */
export interface ActivityReportModelPeriod {
    /**
     * 
     * @type {ReportPeriodUnit}
     * @memberof ActivityReportModelPeriod
     */
    'unit': ReportPeriodUnit;
    /**
     * 
     * @type {number}
     * @memberof ActivityReportModelPeriod
     */
    'value': number;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityReportModelPeriod
     */
    'rounded': boolean;
}
/**
 * 
 * @export
 * @interface ActivityReportModelSchedule
 */
export interface ActivityReportModelSchedule {
    /**
     * 
     * @type {string}
     * @memberof ActivityReportModelSchedule
     */
    'cron': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReportModelSchedule
     */
    'format': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityReportModelSchedule
     */
    'recipients': Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityRequestAppModel
 */
export interface ActivityRequestAppModel {
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModel
     */
    'friendly_id': string;
    /**
     * 
     * @type {User}
     * @memberof ActivityRequestAppModel
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModel
     */
    'trigger_type': string;
    /**
     * 
     * @type {Array<AggregatedResourceType>}
     * @memberof ActivityRequestAppModel
     */
    'aggregated_resource_types': Array<AggregatedResourceType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityRequestAppModel
     */
    'aggregated_permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ActivityRequestAppModel
     */
    'access_units_count': number;
    /**
     * 
     * @type {Array<AccessUnitAppModel>}
     * @memberof ActivityRequestAppModel
     */
    'access_units_preview': Array<AccessUnitAppModel>;
    /**
     * 
     * @type {AccessUnitsSummaryAppModel}
     * @memberof ActivityRequestAppModel
     */
    'access_units_summary': AccessUnitsSummaryAppModel;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityRequestAppModel
     */
    'applied_fallback_policy': boolean;
    /**
     * 
     * @type {ActivityRequestAppModelAccessFlow}
     * @memberof ActivityRequestAppModel
     */
    'access_flow'?: ActivityRequestAppModelAccessFlow;
    /**
     * 
     * @type {ActivityRequestAppModelAccessBundle}
     * @memberof ActivityRequestAppModel
     */
    'access_bundle'?: ActivityRequestAppModelAccessBundle;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModel
     */
    'justification': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityRequestAppModel
     */
    'duration_in_sec': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModel
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModel
     */
    'source'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityRequestAppModel
     */
    'updated_date': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityRequestAppModel
     */
    'created_date': number;
}
/**
 * 
 * @export
 * @interface ActivityRequestAppModelAccessBundle
 */
export interface ActivityRequestAppModelAccessBundle {
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModelAccessBundle
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModelAccessBundle
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ActivityRequestAppModelAccessFlow
 */
export interface ActivityRequestAppModelAccessFlow {
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModelAccessFlow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAppModelAccessFlow
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ActivityTimelineEventModel
 */
export interface ActivityTimelineEventModel {
    /**
     * 
     * @type {ActivityAuditEventType}
     * @memberof ActivityTimelineEventModel
     */
    'event_type': ActivityAuditEventType;
    /**
     * 
     * @type {number}
     * @memberof ActivityTimelineEventModel
     */
    'timestamp': number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ActivityTimelineEventModel
     */
    'metadata': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface AgentViewModel
 */
export interface AgentViewModel {
    /**
     * 
     * @type {string}
     * @memberof AgentViewModel
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModel
     */
    'agent_id': string;
    /**
     * 
     * @type {number}
     * @memberof AgentViewModel
     */
    'last_connected'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModel
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModel
     */
    'version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentViewModel
     */
    'is_latest_version': boolean;
    /**
     * 
     * @type {AgentViewModelCloudProvider}
     * @memberof AgentViewModel
     */
    'cloud_provider'?: AgentViewModelCloudProvider;
    /**
     * 
     * @type {boolean}
     * @memberof AgentViewModel
     */
    'latest_version': boolean;
}
/**
 * 
 * @export
 * @interface AgentViewModelCloudProvider
 */
export interface AgentViewModelCloudProvider {
    /**
     * 
     * @type {CloudTypeModel}
     * @memberof AgentViewModelCloudProvider
     */
    'type': CloudTypeModel;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'aws_account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'project_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'subscription_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'resource_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'region': string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentViewModelCloudProvider
     */
    'local_deploy': boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'kubernetes_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentViewModelCloudProvider
     */
    'kubernetes_version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentViewModelCloudProvider
     */
    'is_kubernetes_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentViewModelCloudProvider
     */
    'kubernetes_admin'?: boolean;
}
/**
 * 
 * @export
 * @interface AggregatedResourceType
 */
export interface AggregatedResourceType {
    /**
     * 
     * @type {string}
     * @memberof AggregatedResourceType
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedResourceType
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedResourceType
     */
    'path': string;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof AggregatedResourceType
     */
    'icons': IconsConfigAppModel;
    /**
     * 
     * @type {number}
     * @memberof AggregatedResourceType
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AponoGroup
 */
export interface AponoGroup {
    /**
     * 
     * @type {string}
     * @memberof AponoGroup
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroup
     */
    'source_idp_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroup
     */
    'source_idp_entity_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AponoGroup
     */
    'last_members_sync_time'?: number;
}
/**
 * 
 * @export
 * @interface AponoGroupModel
 */
export interface AponoGroupModel {
    /**
     * 
     * @type {string}
     * @memberof AponoGroupModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroupModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroupModel
     */
    'source_idp_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AponoGroupModel
     */
    'source_idp_type'?: string;
}
/**
 * 
 * @export
 * @interface AponoGroupModelCreateUpdateRequest
 */
export interface AponoGroupModelCreateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AponoGroupModelCreateUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AponoIdentity
 */
export interface AponoIdentity {
    /**
     * 
     * @type {string}
     * @memberof AponoIdentity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoIdentity
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AponoIdentity
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoIdentity
     */
    'last_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AponoIdentity
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof AponoIdentity
     */
    'idp_type'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<AponoIdentityAttribute>; }}
     * @memberof AponoIdentity
     */
    'attributes': { [key: string]: Array<AponoIdentityAttribute>; };
}
/**
 * 
 * @export
 * @interface AponoIdentityAttribute
 */
export interface AponoIdentityAttribute {
    /**
     * 
     * @type {string}
     * @memberof AponoIdentityAttribute
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoIdentityAttribute
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoIdentityAttribute
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface AponoSearchObjectUser
 */
export interface AponoSearchObjectUser {
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'idp_handler': string;
    /**
     * 
     * @type {string}
     * @memberof AponoSearchObjectUser
     */
    'member_of_groups': string;
}
/**
 * 
 * @export
 * @interface AponoUserModel
 */
export interface AponoUserModel {
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AponoUserModel
     */
    'email_aliases': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'last_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AponoUserModel
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'role': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AponoUserModel
     */
    'roles': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'source_idp_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AponoUserModel
     */
    'source_idp_type'?: string;
}
/**
 * 
 * @export
 * @interface ApprovalChallenge
 */
export interface ApprovalChallenge {
    /**
     * 
     * @type {string}
     * @memberof ApprovalChallenge
     */
    'id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApprovalChallenge
     */
    'config': object;
    /**
     * 
     * @type {Array<ApprovalChallenge>}
     * @memberof ApprovalChallenge
     */
    'then': Array<ApprovalChallenge>;
}
/**
 * 
 * @export
 * @interface ApproverAppModel
 */
export interface ApproverAppModel {
    /**
     * 
     * @type {string}
     * @memberof ApproverAppModel
     */
    'id': string;
    /**
     * 
     * @type {ApproverTypeAppModel}
     * @memberof ApproverAppModel
     */
    'type': ApproverTypeAppModel;
}
/**
 * 
 * @export
 * @interface ApproverConditionAppModel
 */
export interface ApproverConditionAppModel {
    /**
     * 
     * @type {ApproverConditionAppModelAttributeCondition}
     * @memberof ApproverConditionAppModel
     */
    'attribute_condition'?: ApproverConditionAppModelAttributeCondition;
    /**
     * 
     * @type {ApproverConditionAppModelRequestContextBasedAttributeCondition}
     * @memberof ApproverConditionAppModel
     */
    'request_context_based_attribute_condition'?: ApproverConditionAppModelRequestContextBasedAttributeCondition;
}
/**
 * 
 * @export
 * @interface ApproverConditionAppModelAttributeCondition
 */
export interface ApproverConditionAppModelAttributeCondition {
    /**
     * 
     * @type {string}
     * @memberof ApproverConditionAppModelAttributeCondition
     */
    'operator': string;
    /**
     * 
     * @type {AttributeTypeAppModel}
     * @memberof ApproverConditionAppModelAttributeCondition
     */
    'attribute_type': AttributeTypeAppModel;
    /**
     * 
     * @type {any}
     * @memberof ApproverConditionAppModelAttributeCondition
     */
    'attribute_value': any;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof ApproverConditionAppModelAttributeCondition
     */
    'integration'?: AttributeAppModelIntegration;
    /**
     * 
     * @type {Array<AttributeAppModel>}
     * @memberof ApproverConditionAppModelAttributeCondition
     */
    'matching_attributes': Array<AttributeAppModel>;
}
/**
 * 
 * @export
 * @interface ApproverConditionAppModelRequestContextBasedAttributeCondition
 */
export interface ApproverConditionAppModelRequestContextBasedAttributeCondition {
    /**
     * 
     * @type {AttributeTypeAppModel}
     * @memberof ApproverConditionAppModelRequestContextBasedAttributeCondition
     */
    'attribute_type': AttributeTypeAppModel;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof ApproverConditionAppModelRequestContextBasedAttributeCondition
     */
    'integration'?: AttributeAppModelIntegration;
}
/**
 * 
 * @export
 * @interface ApproverConditionGroupAppModel
 */
export interface ApproverConditionGroupAppModel {
    /**
     * 
     * @type {ApproverGroupOperatorAppModel}
     * @memberof ApproverConditionGroupAppModel
     */
    'logical_operator': ApproverGroupOperatorAppModel;
    /**
     * 
     * @type {Array<ApproverConditionAppModel>}
     * @memberof ApproverConditionGroupAppModel
     */
    'conditions': Array<ApproverConditionAppModel>;
}
/**
 * 
 * @export
 * @interface ApproverConditionGroupUpsertAppModel
 */
export interface ApproverConditionGroupUpsertAppModel {
    /**
     * 
     * @type {ApproverGroupOperatorAppModel}
     * @memberof ApproverConditionGroupUpsertAppModel
     */
    'logical_operator': ApproverGroupOperatorAppModel;
    /**
     * 
     * @type {Array<ApproverConditionUpsertAppModel>}
     * @memberof ApproverConditionGroupUpsertAppModel
     */
    'conditions': Array<ApproverConditionUpsertAppModel>;
}
/**
 * 
 * @export
 * @interface ApproverConditionUpsertAppModel
 */
export interface ApproverConditionUpsertAppModel {
    /**
     * 
     * @type {ApproverConditionUpsertAppModelAttributeCondition}
     * @memberof ApproverConditionUpsertAppModel
     */
    'attribute_condition'?: ApproverConditionUpsertAppModelAttributeCondition;
    /**
     * 
     * @type {ApproverConditionUpsertAppModelRequestContextBasedAttributeCondition}
     * @memberof ApproverConditionUpsertAppModel
     */
    'request_context_based_attribute_condition'?: ApproverConditionUpsertAppModelRequestContextBasedAttributeCondition;
}
/**
 * 
 * @export
 * @interface ApproverConditionUpsertAppModelAttributeCondition
 */
export interface ApproverConditionUpsertAppModelAttributeCondition {
    /**
     * 
     * @type {string}
     * @memberof ApproverConditionUpsertAppModelAttributeCondition
     */
    'operator': string;
    /**
     * 
     * @type {string}
     * @memberof ApproverConditionUpsertAppModelAttributeCondition
     */
    'attribute_type_id': string;
    /**
     * 
     * @type {any}
     * @memberof ApproverConditionUpsertAppModelAttributeCondition
     */
    'attribute_value': any;
    /**
     * 
     * @type {string}
     * @memberof ApproverConditionUpsertAppModelAttributeCondition
     */
    'integration_id'?: string;
}
/**
 * 
 * @export
 * @interface ApproverConditionUpsertAppModelRequestContextBasedAttributeCondition
 */
export interface ApproverConditionUpsertAppModelRequestContextBasedAttributeCondition {
    /**
     * 
     * @type {string}
     * @memberof ApproverConditionUpsertAppModelRequestContextBasedAttributeCondition
     */
    'attribute_type_id': string;
    /**
     * 
     * @type {string}
     * @memberof ApproverConditionUpsertAppModelRequestContextBasedAttributeCondition
     */
    'integration_id'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApproverGroupOperatorAppModel = {
    And: 'AND',
    Or: 'OR'
} as const;

export type ApproverGroupOperatorAppModel = typeof ApproverGroupOperatorAppModel[keyof typeof ApproverGroupOperatorAppModel];


/**
 * 
 * @export
 * @interface ApproverModel
 */
export interface ApproverModel {
    /**
     * 
     * @type {string}
     * @memberof ApproverModel
     */
    'id': string;
    /**
     * 
     * @type {ApproverTypeModel}
     * @memberof ApproverModel
     */
    'type': ApproverTypeModel;
}
/**
 * 
 * @export
 * @interface ApproverPolicyAppModel
 */
export interface ApproverPolicyAppModel {
    /**
     * 
     * @type {ApproverPolicyGroupsOperatorAppModel}
     * @memberof ApproverPolicyAppModel
     */
    'groups_operator': ApproverPolicyGroupsOperatorAppModel;
    /**
     * 
     * @type {Array<ApproverConditionGroupAppModel>}
     * @memberof ApproverPolicyAppModel
     */
    'condition_groups': Array<ApproverConditionGroupAppModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApproverPolicyGroupsOperatorAppModel = {
    AnyOf: 'ANY_OF',
    AllOf: 'ALL_OF'
} as const;

export type ApproverPolicyGroupsOperatorAppModel = typeof ApproverPolicyGroupsOperatorAppModel[keyof typeof ApproverPolicyGroupsOperatorAppModel];


/**
 * 
 * @export
 * @interface ApproverPolicyUpsertAppModel
 */
export interface ApproverPolicyUpsertAppModel {
    /**
     * 
     * @type {ApproverPolicyGroupsOperatorAppModel}
     * @memberof ApproverPolicyUpsertAppModel
     */
    'groups_operator': ApproverPolicyGroupsOperatorAppModel;
    /**
     * 
     * @type {Array<ApproverConditionGroupUpsertAppModel>}
     * @memberof ApproverPolicyUpsertAppModel
     */
    'condition_groups': Array<ApproverConditionGroupUpsertAppModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApproverTypeAppModel = {
    User: 'USER',
    Group: 'GROUP',
    ContextAttribute: 'CONTEXT_ATTRIBUTE'
} as const;

export type ApproverTypeAppModel = typeof ApproverTypeAppModel[keyof typeof ApproverTypeAppModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApproverTypeModel = {
    User: 'USER',
    Group: 'GROUP',
    ContextAttribute: 'CONTEXT_ATTRIBUTE'
} as const;

export type ApproverTypeModel = typeof ApproverTypeModel[keyof typeof ApproverTypeModel];


/**
 * 
 * @export
 * @interface AttributeAppModel
 */
export interface AttributeAppModel {
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModel
     */
    'source_id': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModel
     */
    'value': string;
    /**
     * 
     * @type {AttributeTypeAppModel}
     * @memberof AttributeAppModel
     */
    'type': AttributeTypeAppModel;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof AttributeAppModel
     */
    'integration'?: AttributeAppModelIntegration;
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModel
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface AttributeAppModelIntegration
 */
export interface AttributeAppModelIntegration {
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModelIntegration
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModelIntegration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModelIntegration
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeAppModelIntegration
     */
    'type_display_name': string;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof AttributeAppModelIntegration
     */
    'icons': IconsConfigAppModel;
}
/**
 * 
 * @export
 * @interface AttributeFilterAppModel
 */
export interface AttributeFilterAppModel {
    /**
     * 
     * @type {string}
     * @memberof AttributeFilterAppModel
     */
    'operator': string;
    /**
     * 
     * @type {AttributeTypeAppModel}
     * @memberof AttributeFilterAppModel
     */
    'attribute_type': AttributeTypeAppModel;
    /**
     * 
     * @type {any}
     * @memberof AttributeFilterAppModel
     */
    'attribute_value': any;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof AttributeFilterAppModel
     */
    'integration'?: AttributeAppModelIntegration;
    /**
     * 
     * @type {Array<AttributeAppModel>}
     * @memberof AttributeFilterAppModel
     */
    'matching_attributes': Array<AttributeAppModel>;
}
/**
 * 
 * @export
 * @interface AttributeFilterUpsertAppModel
 */
export interface AttributeFilterUpsertAppModel {
    /**
     * 
     * @type {string}
     * @memberof AttributeFilterUpsertAppModel
     */
    'operator': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeFilterUpsertAppModel
     */
    'attribute_type_id': string;
    /**
     * 
     * @type {any}
     * @memberof AttributeFilterUpsertAppModel
     */
    'attribute_value': any;
    /**
     * 
     * @type {string}
     * @memberof AttributeFilterUpsertAppModel
     */
    'integration_id'?: string;
}
/**
 * 
 * @export
 * @interface AttributeGroupAppModel
 */
export interface AttributeGroupAppModel {
    /**
     * 
     * @type {AttributeTypeAppModel}
     * @memberof AttributeGroupAppModel
     */
    'type': AttributeTypeAppModel;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof AttributeGroupAppModel
     */
    'integration'?: AttributeAppModelIntegration;
}
/**
 * 
 * @export
 * @interface AttributeIdAndValue
 */
export interface AttributeIdAndValue {
    /**
     * 
     * @type {string}
     * @memberof AttributeIdAndValue
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeIdAndValue
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AttributeModel
 */
export interface AttributeModel {
    /**
     * 
     * @type {string}
     * @memberof AttributeModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeModel
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeModel
     */
    'integration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeModel
     */
    'category': string;
    /**
     * 
     * @type {Array<AccessFlowAreaModel>}
     * @memberof AttributeModel
     */
    'applicable_places': Array<AccessFlowAreaModel>;
    /**
     * 
     * @type {string}
     * @memberof AttributeModel
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface AttributeType
 */
export interface AttributeType {
    /**
     * 
     * @type {string}
     * @memberof AttributeType
     */
    'type': string;
    /**
     * 
     * @type {Array<AttributeIdAndValue>}
     * @memberof AttributeType
     */
    'values': Array<AttributeIdAndValue>;
}
/**
 * 
 * @export
 * @interface AttributeTypeAppModel
 */
export interface AttributeTypeAppModel {
    /**
     * 
     * @type {string}
     * @memberof AttributeTypeAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeTypeAppModel
     */
    'data_type': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeTypeAppModel
     */
    'display_name': string;
    /**
     * 
     * @type {Array<AccessFlowAreaModel>}
     * @memberof AttributeTypeAppModel
     */
    'applicable_places': Array<AccessFlowAreaModel>;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeTypeAppModel
     */
    'request_context_based': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AwsIamResource = {
    Role: 'ROLE',
    Policy: 'POLICY',
    Group: 'GROUP',
    User: 'USER'
} as const;

export type AwsIamResource = typeof AwsIamResource[keyof typeof AwsIamResource];


/**
 * 
 * @export
 * @interface AwsIamUsageResponse
 */
export interface AwsIamUsageResponse {
    /**
     * 
     * @type {number}
     * @memberof AwsIamUsageResponse
     */
    'num_of_roles': number;
    /**
     * 
     * @type {number}
     * @memberof AwsIamUsageResponse
     */
    'num_of_policies': number;
    /**
     * 
     * @type {number}
     * @memberof AwsIamUsageResponse
     */
    'num_of_groups': number;
    /**
     * 
     * @type {number}
     * @memberof AwsIamUsageResponse
     */
    'num_of_users': number;
    /**
     * 
     * @type {Array<DataPoint>}
     * @memberof AwsIamUsageResponse
     */
    'data_points': Array<DataPoint>;
    /**
     * 
     * @type {boolean}
     * @memberof AwsIamUsageResponse
     */
    'partial_data': boolean;
}
/**
 * 
 * @export
 * @interface AwsIntegration
 */
export interface AwsIntegration {
    /**
     * 
     * @type {string}
     * @memberof AwsIntegration
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegration
     */
    'aws_account_id': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegration
     */
    'iam_role_arn': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegration
     */
    'stack_id': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegration
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegration
     */
    'connector_id'?: string;
}
/**
 * 
 * @export
 * @interface AwsIntegrationAccountModel
 */
export interface AwsIntegrationAccountModel {
    /**
     * 
     * @type {string}
     * @memberof AwsIntegrationAccountModel
     */
    'aws_account_id': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegrationAccountModel
     */
    'connector_id': string;
    /**
     * 
     * @type {string}
     * @memberof AwsIntegrationAccountModel
     */
    'region'?: string;
    /**
     * 
     * @type {object}
     * @memberof AwsIntegrationAccountModel
     */
    'extra_metadata': object;
}
/**
 * 
 * @export
 * @interface AwsRdsResource
 */
export interface AwsRdsResource {
    /**
     * 
     * @type {string}
     * @memberof AwsRdsResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AwsRdsResource
     */
    'hostname': string;
    /**
     * 
     * @type {number}
     * @memberof AwsRdsResource
     */
    'port': number;
    /**
     * 
     * @type {string}
     * @memberof AwsRdsResource
     */
    'dbname'?: string;
    /**
     * 
     * @type {string}
     * @memberof AwsRdsResource
     */
    'engine': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AwsResourceType = {
    Ec2: 'EC2',
    Iam: 'IAM',
    Lambda: 'LAMBDA',
    S3: 'S3'
} as const;

export type AwsResourceType = typeof AwsResourceType[keyof typeof AwsResourceType];


/**
 * 
 * @export
 * @interface AwsSecret
 */
export interface AwsSecret {
    /**
     * 
     * @type {string}
     * @memberof AwsSecret
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AwsSecret
     */
    'arn': string;
}
/**
 * 
 * @export
 * @interface AzureIntegration
 */
export interface AzureIntegration {
    /**
     * 
     * @type {string}
     * @memberof AzureIntegration
     */
    'connector_id': string;
    /**
     * 
     * @type {string}
     * @memberof AzureIntegration
     */
    'subscription_id': string;
    /**
     * 
     * @type {string}
     * @memberof AzureIntegration
     */
    'resource_group': string;
    /**
     * 
     * @type {string}
     * @memberof AzureIntegration
     */
    'region': string;
}
/**
 * 
 * @export
 * @interface BundleAccessTargetAppModel
 */
export interface BundleAccessTargetAppModel {
    /**
     * 
     * @type {string}
     * @memberof BundleAccessTargetAppModel
     */
    'bundle_id': string;
}
/**
 * 
 * @export
 * @interface CanDeleteAccessBundleModel
 */
export interface CanDeleteAccessBundleModel {
    /**
     * 
     * @type {boolean}
     * @memberof CanDeleteAccessBundleModel
     */
    'can_delete': boolean;
    /**
     * 
     * @type {Array<AccessFlowsLinkedToIntegration>}
     * @memberof CanDeleteAccessBundleModel
     */
    'access_flows_used': Array<AccessFlowsLinkedToIntegration>;
}
/**
 * 
 * @export
 * @interface CanDeleteIntegration
 */
export interface CanDeleteIntegration {
    /**
     * 
     * @type {boolean}
     * @memberof CanDeleteIntegration
     */
    'can_delete': boolean;
    /**
     * 
     * @type {Array<AccessFlowsLinkedToIntegration>}
     * @memberof CanDeleteIntegration
     */
    'access_flows_used': Array<AccessFlowsLinkedToIntegration>;
    /**
     * 
     * @type {Array<ActiveAccessRequestLinkedToIntegration>}
     * @memberof CanDeleteIntegration
     */
    'access_requests_used': Array<ActiveAccessRequestLinkedToIntegration>;
}
/**
 * 
 * @export
 * @interface CloudProviderModel
 */
export interface CloudProviderModel {
    /**
     * 
     * @type {CloudTypeModel}
     * @memberof CloudProviderModel
     */
    'type': CloudTypeModel;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'aws_account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'project_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'subscription_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'resource_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'region': string;
    /**
     * 
     * @type {boolean}
     * @memberof CloudProviderModel
     */
    'local_deploy': boolean;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'kubernetes_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudProviderModel
     */
    'kubernetes_version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CloudProviderModel
     */
    'is_kubernetes_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CloudProviderModel
     */
    'kubernetes_admin'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CloudTypeModel = {
    Aws: 'AWS',
    Gcp: 'GCP',
    Azure: 'AZURE',
    Generic: 'GENERIC'
} as const;

export type CloudTypeModel = typeof CloudTypeModel[keyof typeof CloudTypeModel];


/**
 * 
 * @export
 * @interface ConnectedSubIntegrationResourceTypesAppModel
 */
export interface ConnectedSubIntegrationResourceTypesAppModel {
    /**
     * 
     * @type {string}
     * @memberof ConnectedSubIntegrationResourceTypesAppModel
     */
    'integration_type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectedSubIntegrationResourceTypesAppModel
     */
    'connected_resource_types': Array<string>;
}
/**
 * 
 * @export
 * @interface Connector
 */
export interface Connector {
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'connector_id': string;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'last_connected'?: number;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface CreateAccessFlowRequestV2
 */
export interface CreateAccessFlowRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof CreateAccessFlowRequestV2
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccessFlowRequestV2
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerType}
     * @memberof CreateAccessFlowRequestV2
     */
    'trigger_type': TriggerType;
    /**
     * 
     * @type {Array<GranteeModel>}
     * @memberof CreateAccessFlowRequestV2
     */
    'grantees': Array<GranteeModel>;
    /**
     * 
     * @type {Array<AccessTargetModel>}
     * @memberof CreateAccessFlowRequestV2
     */
    'access_targets': Array<AccessTargetModel>;
    /**
     * 
     * @type {Array<ApproverModel>}
     * @memberof CreateAccessFlowRequestV2
     */
    'approvers': Array<ApproverModel>;
    /**
     * 
     * @type {number}
     * @memberof CreateAccessFlowRequestV2
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccessFlowRequestV2
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowModelV2Timeframe}
     * @memberof CreateAccessFlowRequestV2
     */
    'timeframe'?: AccessFlowModelV2Timeframe;
    /**
     * 
     * @type {AccessFlowModelV2Settings}
     * @memberof CreateAccessFlowRequestV2
     */
    'settings'?: AccessFlowModelV2Settings;
}
/**
 * 
 * @export
 * @interface CreateAccessRequestModelV2
 */
export interface CreateAccessRequestModelV2 {
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestModelV2
     */
    'user_email': string;
    /**
     * 
     * @type {Array<AccessUnitModel>}
     * @memberof CreateAccessRequestModelV2
     */
    'access_units': Array<AccessUnitModel>;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestModelV2
     */
    'justification': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAccessRequestModelV2
     */
    'duration_in_sec'?: number;
}
/**
 * 
 * @export
 * @interface CreateAponoUserRequest
 */
export interface CreateAponoUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAponoUserRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAponoUserRequest
     */
    'email_aliases': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAponoUserRequest
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAponoUserRequest
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAponoUserRequest
     */
    'role': string;
}
/**
 * 
 * @export
 * @interface CreateConnector
 */
export interface CreateConnector {
    /**
     * 
     * @type {string}
     * @memberof CreateConnector
     */
    'connector_id': string;
}
/**
 * 
 * @export
 * @interface CreateIntegration
 */
export interface CreateIntegration {
    /**
     * 
     * @type {string}
     * @memberof CreateIntegration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegration
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegration
     */
    'provisioner_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateIntegration
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof CreateIntegration
     */
    'secret_config'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateIntegration
     */
    'connected_resource_types'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegration
     */
    'custom_access_details'?: string;
}
/**
 * 
 * @export
 * @interface CreateIntegrationAppModel
 */
export interface CreateIntegrationAppModel {
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationAppModel
     */
    'provisioner_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateIntegrationAppModel
     */
    'connected_resource_types': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateIntegrationAppModel
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof CreateIntegrationAppModel
     */
    'secret_config'?: object;
    /**
     * 
     * @type {CreateIntegrationAppModelCustomAccessDetailsSettings}
     * @memberof CreateIntegrationAppModel
     */
    'custom_access_details_settings'?: CreateIntegrationAppModelCustomAccessDetailsSettings;
    /**
     * 
     * @type {CreateIntegrationAppModelIntegrationOwners}
     * @memberof CreateIntegrationAppModel
     */
    'integration_owners'?: CreateIntegrationAppModelIntegrationOwners;
    /**
     * 
     * @type {Array<ResourceOwnerMappingAppModel>}
     * @memberof CreateIntegrationAppModel
     */
    'resource_owners_mappings'?: Array<ResourceOwnerMappingAppModel>;
    /**
     * 
     * @type {Array<ConnectedSubIntegrationResourceTypesAppModel>}
     * @memberof CreateIntegrationAppModel
     */
    'connected_sub_integration_resource_types'?: Array<ConnectedSubIntegrationResourceTypesAppModel>;
}
/**
 * 
 * @export
 * @interface CreateIntegrationAppModelCustomAccessDetailsSettings
 */
export interface CreateIntegrationAppModelCustomAccessDetailsSettings {
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationAppModelCustomAccessDetailsSettings
     */
    'instructions_access_message'?: string;
}
/**
 * 
 * @export
 * @interface CreateIntegrationAppModelIntegrationOwners
 */
export interface CreateIntegrationAppModelIntegrationOwners {
    /**
     * 
     * @type {Array<IntegrationOwnerAppModel>}
     * @memberof CreateIntegrationAppModelIntegrationOwners
     */
    'owners': Array<IntegrationOwnerAppModel>;
}
/**
 * 
 * @export
 * @interface CreateUserPersonalTokenRequest
 */
export interface CreateUserPersonalTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserPersonalTokenRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserPersonalTokenRequest
     */
    'expiry_date'?: number;
}
/**
 * 
 * @export
 * @interface CurrentAccessRequestsQueryResponse
 */
export interface CurrentAccessRequestsQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof CurrentAccessRequestsQueryResponse
     */
    'granted_access_requests': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAccessRequestsQueryResponse
     */
    'pending_access_requests': number;
}
/**
 * 
 * @export
 * @interface CustomAccessDetailsSettingsAppModel
 */
export interface CustomAccessDetailsSettingsAppModel {
    /**
     * 
     * @type {string}
     * @memberof CustomAccessDetailsSettingsAppModel
     */
    'instructions_access_message'?: string;
}
/**
 * 
 * @export
 * @interface DataPoint
 */
export interface DataPoint {
    /**
     * 
     * @type {number}
     * @memberof DataPoint
     */
    'time': number;
    /**
     * 
     * @type {number}
     * @memberof DataPoint
     */
    'used_actions': number;
    /**
     * 
     * @type {number}
     * @memberof DataPoint
     */
    'total_actions': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DayOfWeek = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];


/**
 * 
 * @export
 * @interface DetailsSummaryAppModel
 */
export interface DetailsSummaryAppModel {
    /**
     * 
     * @type {Array<LoadedEntityAppModel>}
     * @memberof DetailsSummaryAppModel
     */
    'synced': Array<LoadedEntityAppModel>;
    /**
     * 
     * @type {Array<LoadedEntityAppModel>}
     * @memberof DetailsSummaryAppModel
     */
    'failed': Array<LoadedEntityAppModel>;
    /**
     * 
     * @type {number}
     * @memberof DetailsSummaryAppModel
     */
    'errors': number;
    /**
     * 
     * @type {number}
     * @memberof DetailsSummaryAppModel
     */
    'warnings': number;
}
/**
 * 
 * @export
 * @interface DiscoveredAwsResource
 */
export interface DiscoveredAwsResource {
    /**
     * 
     * @type {AwsResourceType}
     * @memberof DiscoveredAwsResource
     */
    'type': AwsResourceType;
    /**
     * 
     * @type {number}
     * @memberof DiscoveredAwsResource
     */
    'num_of_resources': number;
    /**
     * 
     * @type {number}
     * @memberof DiscoveredAwsResource
     */
    'fetch_time': number;
}
/**
 * 
 * @export
 * @interface DiscoveredAwsResourcesResponse
 */
export interface DiscoveredAwsResourcesResponse {
    /**
     * 
     * @type {Array<DiscoveredAwsResource>}
     * @memberof DiscoveredAwsResourcesResponse
     */
    'resources': Array<DiscoveredAwsResource>;
}
/**
 * 
 * @export
 * @interface DiscoveryStatusApiModel
 */
export interface DiscoveryStatusApiModel {
    /**
     * 
     * @type {number}
     * @memberof DiscoveryStatusApiModel
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof DiscoveryStatusApiModel
     */
    'updated': number;
}
/**
 * 
 * @export
 * @interface EnrichedInsightModel
 */
export interface EnrichedInsightModel {
    /**
     * 
     * @type {string}
     * @memberof EnrichedInsightModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichedInsightModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichedInsightModel
     */
    'details': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichedInsightModel
     */
    'type': string;
    /**
     * 
     * @type {object}
     * @memberof EnrichedInsightModel
     */
    'metadata': object;
    /**
     * 
     * @type {InsightStatus}
     * @memberof EnrichedInsightModel
     */
    'status': InsightStatus;
    /**
     * 
     * @type {number}
     * @memberof EnrichedInsightModel
     */
    'created_date': number;
    /**
     * 
     * @type {EnrichedInsightModelSuggestedAccessFlow}
     * @memberof EnrichedInsightModel
     */
    'suggested_access_flow'?: EnrichedInsightModelSuggestedAccessFlow;
}
/**
 * 
 * @export
 * @interface EnrichedInsightModelSuggestedAccessFlow
 */
export interface EnrichedInsightModelSuggestedAccessFlow {
    /**
     * 
     * @type {string}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'group_ids': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'grantee_user_emails': Array<string>;
    /**
     * 
     * @type {GroupsLogicalOperatorModel}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'groups_logical_operator': GroupsLogicalOperatorModel;
    /**
     * 
     * @type {Array<AccessTargetModelV1>}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'access_targets': Array<AccessTargetModelV1>;
    /**
     * 
     * @type {number}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'duration_in_sec': number;
    /**
     * 
     * @type {Array<ApprovalChallenge>}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'challenges': Array<ApprovalChallenge>;
    /**
     * 
     * @type {number}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'created_date': number;
    /**
     * 
     * @type {boolean}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowModelV1Timeframe}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'timeframe'?: AccessFlowModelV1Timeframe;
    /**
     * 
     * @type {AccessFlowModelV1Settings}
     * @memberof EnrichedInsightModelSuggestedAccessFlow
     */
    'settings'?: AccessFlowModelV1Settings;
}
/**
 * 
 * @export
 * @interface EvaluatedToggle
 */
export interface EvaluatedToggle {
    /**
     * 
     * @type {string}
     * @memberof EvaluatedToggle
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluatedToggle
     */
    'enabled': boolean;
    /**
     * 
     * @type {EvaluatedToggleVariant}
     * @memberof EvaluatedToggle
     */
    'variant'?: EvaluatedToggleVariant;
}
/**
 * 
 * @export
 * @interface EvaluatedToggleVariant
 */
export interface EvaluatedToggleVariant {
    /**
     * 
     * @type {string}
     * @memberof EvaluatedToggleVariant
     */
    'name': string;
    /**
     * 
     * @type {VariantPayload}
     * @memberof EvaluatedToggleVariant
     */
    'payload'?: VariantPayload;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluatedToggleVariant
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof EvaluatedToggleVariant
     */
    'stickiness'?: string;
}
/**
 * 
 * @export
 * @interface FallbackPolicyApiModel
 */
export interface FallbackPolicyApiModel {
    /**
     * 
     * @type {object}
     * @memberof FallbackPolicyApiModel
     */
    'approver': object;
    /**
     * 
     * @type {number}
     * @memberof FallbackPolicyApiModel
     */
    'duration_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof FallbackPolicyApiModel
     */
    'is_active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FallbackPolicyApiModel
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface FeatureFlagsResponse
 */
export interface FeatureFlagsResponse {
    /**
     * 
     * @type {Array<EvaluatedToggle>}
     * @memberof FeatureFlagsResponse
     */
    'toggles': Array<EvaluatedToggle>;
}
/**
 * 
 * @export
 * @interface FilterActiveAccessRequest
 */
export interface FilterActiveAccessRequest {
    /**
     * 
     * @type {Array<AccessNodeFilterModel>}
     * @memberof FilterActiveAccessRequest
     */
    'type_filters': Array<AccessNodeFilterModel>;
}
/**
 * 
 * @export
 * @interface FilterResult
 */
export interface FilterResult {
    /**
     * 
     * @type {FilterResultContext}
     * @memberof FilterResult
     */
    'context': FilterResultContext;
    /**
     * 
     * @type {string}
     * @memberof FilterResult
     */
    'filter_phrase': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterResult
     */
    'matched_values': Array<string>;
    /**
     * 
     * @type {FilterResultContextType}
     * @memberof FilterResult
     */
    'type': FilterResultContextType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FilterResultContext = {
    Approvers: 'Approvers',
    AccessTarget: 'AccessTarget',
    Grantees: 'Grantees',
    AccessFlowName: 'AccessFlowName',
    TriggerType: 'TriggerType'
} as const;

export type FilterResultContext = typeof FilterResultContext[keyof typeof FilterResultContext];


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterResultContextType = {
    Resource: 'Resource',
    ResourceIntegration: 'ResourceIntegration',
    User: 'User',
    Group: 'Group',
    ContextAttribute: 'ContextAttribute',
    Permissions: 'Permissions',
    Metadata: 'Metadata',
    AccessFlowName: 'AccessFlowName'
} as const;

export type FilterResultContextType = typeof FilterResultContextType[keyof typeof FilterResultContextType];


/**
 * 
 * @export
 * @interface FilterValueAppModel
 */
export interface FilterValueAppModel {
    /**
     * 
     * @type {string}
     * @memberof FilterValueAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FilterValueAppModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GcpIntegrationModel
 */
export interface GcpIntegrationModel {
    /**
     * 
     * @type {string}
     * @memberof GcpIntegrationModel
     */
    'connector_id': string;
    /**
     * 
     * @type {string}
     * @memberof GcpIntegrationModel
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof GcpIntegrationModel
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof GcpIntegrationModel
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof GcpIntegrationModel
     */
    'zone': string;
}
/**
 * 
 * @export
 * @interface GetOauthInstallLinkModel
 */
export interface GetOauthInstallLinkModel {
    /**
     * 
     * @type {object}
     * @memberof GetOauthInstallLinkModel
     */
    'metadata': object;
}
/**
 * 
 * @export
 * @interface GettingStarted
 */
export interface GettingStarted {
    /**
     * 
     * @type {boolean}
     * @memberof GettingStarted
     */
    'active': boolean;
    /**
     * 
     * @type {OnboardingSteps}
     * @memberof GettingStarted
     */
    'steps': OnboardingSteps;
}
/**
 * 
 * @export
 * @interface GranteeAppModel
 */
export interface GranteeAppModel {
    /**
     * 
     * @type {string}
     * @memberof GranteeAppModel
     */
    'id': string;
    /**
     * 
     * @type {GranteeTypeAppModel}
     * @memberof GranteeAppModel
     */
    'type': GranteeTypeAppModel;
}
/**
 * 
 * @export
 * @interface GranteeAttributeAppModel
 */
export interface GranteeAttributeAppModel {
    /**
     * 
     * @type {string}
     * @memberof GranteeAttributeAppModel
     */
    'attribute_type': string;
    /**
     * 
     * @type {string}
     * @memberof GranteeAttributeAppModel
     */
    'operator': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GranteeAttributeAppModel
     */
    'attribute_list': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GranteeAttributeAppModel
     */
    'integration_id'?: string;
    /**
     * 
     * @type {Array<ActiveAccessUserAppModel>}
     * @memberof GranteeAttributeAppModel
     */
    'action_access_users': Array<ActiveAccessUserAppModel>;
}
/**
 * 
 * @export
 * @interface GranteeConditionAppModel
 */
export interface GranteeConditionAppModel {
    /**
     * 
     * @type {GranteeFilterGroupOperatorAppModel}
     * @memberof GranteeConditionAppModel
     */
    'operator': GranteeFilterGroupOperatorAppModel;
    /**
     * 
     * @type {Array<GranteeAttributeAppModel>}
     * @memberof GranteeConditionAppModel
     */
    'attributes': Array<GranteeAttributeAppModel>;
}
/**
 * 
 * @export
 * @interface GranteeFilterGroupAppModel
 */
export interface GranteeFilterGroupAppModel {
    /**
     * 
     * @type {GranteeFilterGroupOperatorAppModel}
     * @memberof GranteeFilterGroupAppModel
     */
    'logical_operator': GranteeFilterGroupOperatorAppModel;
    /**
     * 
     * @type {Array<AttributeFilterAppModel>}
     * @memberof GranteeFilterGroupAppModel
     */
    'attribute_filters': Array<AttributeFilterAppModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GranteeFilterGroupOperatorAppModel = {
    And: 'AND',
    Or: 'OR'
} as const;

export type GranteeFilterGroupOperatorAppModel = typeof GranteeFilterGroupOperatorAppModel[keyof typeof GranteeFilterGroupOperatorAppModel];


/**
 * 
 * @export
 * @interface GranteeFilterGroupUpsertAppModel
 */
export interface GranteeFilterGroupUpsertAppModel {
    /**
     * 
     * @type {GranteeFilterGroupOperatorAppModel}
     * @memberof GranteeFilterGroupUpsertAppModel
     */
    'logical_operator': GranteeFilterGroupOperatorAppModel;
    /**
     * 
     * @type {Array<AttributeFilterUpsertAppModel>}
     * @memberof GranteeFilterGroupUpsertAppModel
     */
    'attribute_filters': Array<AttributeFilterUpsertAppModel>;
}
/**
 * 
 * @export
 * @interface GranteeModel
 */
export interface GranteeModel {
    /**
     * 
     * @type {string}
     * @memberof GranteeModel
     */
    'id': string;
    /**
     * 
     * @type {GranteeTypeModel}
     * @memberof GranteeModel
     */
    'type': GranteeTypeModel;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GranteeTypeAppModel = {
    User: 'USER',
    Group: 'GROUP',
    ExternalEmail: 'EXTERNAL_EMAIL',
    ContextAttribute: 'CONTEXT_ATTRIBUTE'
} as const;

export type GranteeTypeAppModel = typeof GranteeTypeAppModel[keyof typeof GranteeTypeAppModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const GranteeTypeModel = {
    User: 'USER',
    Group: 'GROUP',
    ExternalEmail: 'EXTERNAL_EMAIL',
    ContextAttribute: 'CONTEXT_ATTRIBUTE'
} as const;

export type GranteeTypeModel = typeof GranteeTypeModel[keyof typeof GranteeTypeModel];


/**
 * 
 * @export
 * @interface GroupMembersModel
 */
export interface GroupMembersModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupMembersModel
     */
    'user_ids': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GroupsLogicalOperatorModel = {
    AnyOf: 'ANY_OF',
    AllOf: 'ALL_OF'
} as const;

export type GroupsLogicalOperatorModel = typeof GroupsLogicalOperatorModel[keyof typeof GroupsLogicalOperatorModel];


/**
 * 
 * @export
 * @interface IconsConfigAppModel
 */
export interface IconsConfigAppModel {
    /**
     * 
     * @type {string}
     * @memberof IconsConfigAppModel
     */
    'png': string;
    /**
     * 
     * @type {string}
     * @memberof IconsConfigAppModel
     */
    'svg': string;
}
/**
 * 
 * @export
 * @interface IdentitiesModel
 */
export interface IdentitiesModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentitiesModel
     */
    'attributes': Array<string>;
    /**
     * 
     * @type {Array<AponoIdentity>}
     * @memberof IdentitiesModel
     */
    'identities': Array<AponoIdentity>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InsightStatus = {
    Active: 'ACTIVE',
    Snoozed: 'SNOOZED',
    Resolved: 'RESOLVED'
} as const;

export type InsightStatus = typeof InsightStatus[keyof typeof InsightStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const InstallationMethod = {
    AwsOrganizationCloudFormationEcs: 'AWS_Organization_CloudFormation_ECS',
    AwsOrganizationTerraformEcs: 'AWS_Organization_Terraform_ECS',
    AwsAccountCloudFormationEcs: 'AWS_Account_CloudFormation_ECS',
    AwsAccountCloudFormationEcsPermissionless: 'AWS_Account_CloudFormation_ECS_Permissionless',
    AwsAccountTerraformEcsPermissionless: 'AWS_Account_Terraform_ECS_Permissionless',
    AwsAccountTerraformEks: 'AWS_Account_Terraform_EKS',
    AwsAccountTerraformEcs: 'AWS_Account_Terraform_ECS',
    AwsAccountCliEks: 'AWS_Account_CLI_EKS',
    GcpProjectCliGke: 'GCP_Project_CLI_GKE',
    GcpProjectCloudRun: 'GCP_Project_Cloud_Run',
    GcpOrganizationCliGke: 'GCP_Organization_CLI_GKE',
    GcpOrganizationCloudRun: 'GCP_Organization_Cloud_Run',
    GcpTerraformGke: 'GCP_Terraform_GKE',
    AzureSubscriptionTerraformCiPermissionless: 'Azure_Subscription_Terraform_CI_Permissionless',
    AzureSubscriptionTerraformCi: 'Azure_Subscription_Terraform_CI',
    AzureSubscriptionCliCiPermissionless: 'Azure_Subscription_CLI_CI_Permissionless',
    AzureSubscriptionCliCi: 'Azure_Subscription_CLI_CI',
    K8sHelm: 'K8s_Helm',
    K8sTerraform: 'K8s_Terraform',
    K8sHelmPermissionless: 'K8s_Helm_Permissionless',
    K8sTerraformPermissionless: 'K8s_Terraform_Permissionless'
} as const;

export type InstallationMethod = typeof InstallationMethod[keyof typeof InstallationMethod];


/**
 * 
 * @export
 * @interface InstructionAppModel
 */
export interface InstructionAppModel {
    /**
     * 
     * @type {string}
     * @memberof InstructionAppModel
     */
    'plain': string;
    /**
     * 
     * @type {string}
     * @memberof InstructionAppModel
     */
    'markdown': string;
    /**
     * 
     * @type {string}
     * @memberof InstructionAppModel
     */
    'custom_message'?: string;
}
/**
 * 
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'type': string;
    /**
     * 
     * @type {IntegrationStatus}
     * @memberof Integration
     */
    'status': IntegrationStatus;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'provisioner_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof Integration
     */
    'connection'?: object;
    /**
     * 
     * @type {number}
     * @memberof Integration
     */
    'last_sync_time'?: number;
    /**
     * 
     * @type {object}
     * @memberof Integration
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof Integration
     */
    'secret_config'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Integration
     */
    'connected_resource_types': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'custom_access_details'?: string;
}
/**
 * 
 * @export
 * @interface IntegrationAccessTargetAppModel
 */
export interface IntegrationAccessTargetAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationAccessTargetAppModel
     */
    'integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationAccessTargetAppModel
     */
    'resource_type': string;
    /**
     * 
     * @type {Array<TagAppModel>}
     * @memberof IntegrationAccessTargetAppModel
     */
    'resource_tag_matchers': Array<TagAppModel>;
    /**
     * 
     * @type {Array<TagAppModel>}
     * @memberof IntegrationAccessTargetAppModel
     */
    'resource_tag_excludes': Array<TagAppModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationAccessTargetAppModel
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface IntegrationAggregatedStatusModel
 */
export interface IntegrationAggregatedStatusModel {
    /**
     * 
     * @type {IntegrationStatus}
     * @memberof IntegrationAggregatedStatusModel
     */
    'status': IntegrationStatus;
    /**
     * 
     * @type {number}
     * @memberof IntegrationAggregatedStatusModel
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface IntegrationAppModel
 */
export interface IntegrationAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationAppModel
     */
    'type': string;
    /**
     * 
     * @type {IntegrationStatus}
     * @memberof IntegrationAppModel
     */
    'status': IntegrationStatus;
    /**
     * 
     * @type {string}
     * @memberof IntegrationAppModel
     */
    'details'?: string;
    /**
     * 
     * @type {DetailsSummaryAppModel}
     * @memberof IntegrationAppModel
     */
    'details_summary': DetailsSummaryAppModel;
    /**
     * 
     * @type {DetailsSummaryAppModel}
     * @memberof IntegrationAppModel
     */
    'entities_summary': DetailsSummaryAppModel;
    /**
     * 
     * @type {string}
     * @memberof IntegrationAppModel
     */
    'provisioner_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationAppModel
     */
    'connected_resource_types': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof IntegrationAppModel
     */
    'connection'?: object;
    /**
     * 
     * @type {number}
     * @memberof IntegrationAppModel
     */
    'last_sync_time'?: number;
    /**
     * 
     * @type {object}
     * @memberof IntegrationAppModel
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof IntegrationAppModel
     */
    'secret_config'?: object;
    /**
     * 
     * @type {CreateIntegrationAppModelCustomAccessDetailsSettings}
     * @memberof IntegrationAppModel
     */
    'custom_access_details_settings'?: CreateIntegrationAppModelCustomAccessDetailsSettings;
    /**
     * 
     * @type {CreateIntegrationAppModelIntegrationOwners}
     * @memberof IntegrationAppModel
     */
    'integration_owners'?: CreateIntegrationAppModelIntegrationOwners;
    /**
     * 
     * @type {Array<ResourceOwnerMappingAppModel>}
     * @memberof IntegrationAppModel
     */
    'resource_owners_mappings'?: Array<ResourceOwnerMappingAppModel>;
    /**
     * 
     * @type {string}
     * @memberof IntegrationAppModel
     */
    'parent_integration_id'?: string;
    /**
     * 
     * @type {Array<ConnectedSubIntegrationResourceTypesAppModel>}
     * @memberof IntegrationAppModel
     */
    'connected_sub_integration_resource_types'?: Array<ConnectedSubIntegrationResourceTypesAppModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationCategoryAppModel = {
    Resources: 'Resources',
    UserInformation: 'UserInformation',
    Communication: 'Communication'
} as const;

export type IntegrationCategoryAppModel = typeof IntegrationCategoryAppModel[keyof typeof IntegrationCategoryAppModel];


/**
 * 
 * @export
 * @interface IntegrationConfigAppModel
 */
export interface IntegrationConfigAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModel
     */
    'type': string;
    /**
     * 
     * @type {IntegrationFamilyType}
     * @memberof IntegrationConfigAppModel
     */
    'family_type': IntegrationFamilyType;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigAppModel
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModel
     */
    'description': string;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof IntegrationConfigAppModel
     */
    'icons': IconsConfigAppModel;
    /**
     * 
     * @type {Array<IntegrationConfigLink>}
     * @memberof IntegrationConfigAppModel
     */
    'links': Array<IntegrationConfigLink>;
    /**
     * 
     * @type {IntegrationConfigInfo}
     * @memberof IntegrationConfigAppModel
     */
    'info': IntegrationConfigInfo;
    /**
     * 
     * @type {Array<IntegrationConfigParamAppModel>}
     * @memberof IntegrationConfigAppModel
     */
    'params': Array<IntegrationConfigParamAppModel>;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigAppModel
     */
    'requires_secret': boolean;
    /**
     * 
     * @type {Array<IntegrationConfigSecretParamAppModel>}
     * @memberof IntegrationConfigAppModel
     */
    'secret_params': Array<IntegrationConfigSecretParamAppModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationConfigAppModel
     */
    'supported_secret_types': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModel
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModel
     */
    'connection_type': string;
    /**
     * 
     * @type {{ [key: string]: ResourceTypeConfig; }}
     * @memberof IntegrationConfigAppModel
     */
    'resource_types': { [key: string]: ResourceTypeConfig; };
}
/**
 * 
 * @export
 * @interface IntegrationConfigAppModelV2
 */
export interface IntegrationConfigAppModelV2 {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModelV2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModelV2
     */
    'type': string;
    /**
     * 
     * @type {IntegrationFamilyType}
     * @memberof IntegrationConfigAppModelV2
     */
    'family_type': IntegrationFamilyType;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigAppModelV2
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModelV2
     */
    'description': string;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof IntegrationConfigAppModelV2
     */
    'icons': IconsConfigAppModel;
    /**
     * 
     * @type {Array<IntegrationConfigLink>}
     * @memberof IntegrationConfigAppModelV2
     */
    'links': Array<IntegrationConfigLink>;
    /**
     * 
     * @type {IntegrationConfigInfo}
     * @memberof IntegrationConfigAppModelV2
     */
    'info': IntegrationConfigInfo;
    /**
     * 
     * @type {Array<IntegrationConfigParamAppModel>}
     * @memberof IntegrationConfigAppModelV2
     */
    'params': Array<IntegrationConfigParamAppModel>;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigAppModelV2
     */
    'requires_secret': boolean;
    /**
     * 
     * @type {Array<IntegrationConfigSecretParamAppModel>}
     * @memberof IntegrationConfigAppModelV2
     */
    'secret_params': Array<IntegrationConfigSecretParamAppModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationConfigAppModelV2
     */
    'supported_secret_types': Array<string>;
    /**
     * 
     * @type {IntegrationCategoryAppModel}
     * @memberof IntegrationConfigAppModelV2
     */
    'category': IntegrationCategoryAppModel;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModelV2
     */
    'integrations_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigAppModelV2
     */
    'connection_type': string;
    /**
     * 
     * @type {{ [key: string]: ResourceTypeConfigAppModel; }}
     * @memberof IntegrationConfigAppModelV2
     */
    'resource_types': { [key: string]: ResourceTypeConfigAppModel; };
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationConfigAppModelV2
     */
    'labels_categories': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigAppModelV2
     */
    'premium': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationConfigAppModelV2
     */
    'sub_integration_types': Array<string>;
}
/**
 * 
 * @export
 * @interface IntegrationConfigInfo
 */
export interface IntegrationConfigInfo {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigInfo
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationConfigInfo
     */
    'items': Array<string>;
}
/**
 * 
 * @export
 * @interface IntegrationConfigLink
 */
export interface IntegrationConfigLink {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigLink
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigLink
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface IntegrationConfigParamAppModel
 */
export interface IntegrationConfigParamAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamAppModel
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamAppModel
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamAppModel
     */
    'placeholder': string;
    /**
     * 
     * @type {Array<IntegrationConfigParamValueModel>}
     * @memberof IntegrationConfigParamAppModel
     */
    'values': Array<IntegrationConfigParamValueModel>;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamAppModel
     */
    'default': string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigParamAppModel
     */
    'optional'?: boolean;
}
/**
 * 
 * @export
 * @interface IntegrationConfigParamValueModel
 */
export interface IntegrationConfigParamValueModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamValueModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigParamValueModel
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface IntegrationConfigSecretParamAppModel
 */
export interface IntegrationConfigSecretParamAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigSecretParamAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigSecretParamAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigSecretParamAppModel
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigSecretParamAppModel
     */
    'placeholder': string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigSecretParamAppModel
     */
    'optional': boolean;
}
/**
 * 
 * @export
 * @interface IntegrationErrorAppModel
 */
export interface IntegrationErrorAppModel {
    /**
     * 
     * @type {IntegrationStatus}
     * @memberof IntegrationErrorAppModel
     */
    'status': IntegrationStatus;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof IntegrationErrorAppModel
     */
    'icon': IconsConfigAppModel;
    /**
     * 
     * @type {string}
     * @memberof IntegrationErrorAppModel
     */
    'name': string;
    /**
     * 
     * @type {IntegrationErrorTypeAppModel}
     * @memberof IntegrationErrorAppModel
     */
    'type': IntegrationErrorTypeAppModel;
    /**
     * 
     * @type {string}
     * @memberof IntegrationErrorAppModel
     */
    'message': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationErrorTypeAppModel = {
    ConnectorUnreachable: 'ConnectorUnreachable',
    ResourceTypeUnreachable: 'ResourceTypeUnreachable',
    SecretUnreachable: 'SecretUnreachable',
    SecretInvalid: 'SecretInvalid',
    ResourceDiscoveryFailed: 'ResourceDiscoveryFailed',
    IntegrationUnreachable: 'IntegrationUnreachable',
    FailedProvisioning: 'FailedProvisioning',
    GenericFailure: 'GenericFailure'
} as const;

export type IntegrationErrorTypeAppModel = typeof IntegrationErrorTypeAppModel[keyof typeof IntegrationErrorTypeAppModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationFamilyType = {
    ResourceIntegration: 'ResourceIntegration',
    IdentityProvider: 'IdentityProvider',
    Communication: 'Communication',
    IdentityContext: 'IdentityContext'
} as const;

export type IntegrationFamilyType = typeof IntegrationFamilyType[keyof typeof IntegrationFamilyType];


/**
 * 
 * @export
 * @interface IntegrationOwnerAppModel
 */
export interface IntegrationOwnerAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationOwnerAppModel
     */
    'integration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOwnerAppModel
     */
    'attribute_type_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationOwnerAppModel
     */
    'attribute_value': Array<string>;
}
/**
 * 
 * @export
 * @interface IntegrationOwnersAppModel
 */
export interface IntegrationOwnersAppModel {
    /**
     * 
     * @type {Array<IntegrationOwnerAppModel>}
     * @memberof IntegrationOwnersAppModel
     */
    'owners': Array<IntegrationOwnerAppModel>;
}
/**
 * 
 * @export
 * @interface IntegrationPartialAppModel
 */
export interface IntegrationPartialAppModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationPartialAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationPartialAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationPartialAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationPartialAppModel
     */
    'type_display_name': string;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof IntegrationPartialAppModel
     */
    'icons': IconsConfigAppModel;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationStatus = {
    Initializing: 'Initializing',
    Refreshing: 'Refreshing',
    Active: 'Active',
    Error: 'Error',
    Warning: 'Warning',
    Disabled: 'Disabled'
} as const;

export type IntegrationStatus = typeof IntegrationStatus[keyof typeof IntegrationStatus];


/**
 * 
 * @export
 * @interface IntegrationSummaryModel
 */
export interface IntegrationSummaryModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSummaryModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationSummaryModel
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationSummaryModel
     */
    'connected': number;
    /**
     * 
     * @type {Array<IntegrationAggregatedStatusModel>}
     * @memberof IntegrationSummaryModel
     */
    'aggregated_status': Array<IntegrationAggregatedStatusModel>;
}
/**
 * 
 * @export
 * @interface IntegrationsCatalogModel
 */
export interface IntegrationsCatalogModel {
    /**
     * 
     * @type {Array<IntegrationConfigAppModel>}
     * @memberof IntegrationsCatalogModel
     */
    'integration_configs': Array<IntegrationConfigAppModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationsCatalogModel
     */
    'categories': Array<string>;
}
/**
 * 
 * @export
 * @interface IntegrationsSummaryResponse
 */
export interface IntegrationsSummaryResponse {
    /**
     * 
     * @type {Array<IntegrationSummaryModel>}
     * @memberof IntegrationsSummaryResponse
     */
    'summary': Array<IntegrationSummaryModel>;
    /**
     * 
     * @type {Array<IntegrationAggregatedStatusModel>}
     * @memberof IntegrationsSummaryResponse
     */
    'aggregated_status': Array<IntegrationAggregatedStatusModel>;
}
/**
 * 
 * @export
 * @interface LabelAppModel
 */
export interface LabelAppModel {
    /**
     * 
     * @type {string}
     * @memberof LabelAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LabelAppModel
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface LinkResponse
 */
export interface LinkResponse {
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface ListAttributesResponse
 */
export interface ListAttributesResponse {
    /**
     * 
     * @type {Array<AttributeModel>}
     * @memberof ListAttributesResponse
     */
    'attributes': Array<AttributeModel>;
}
/**
 * 
 * @export
 * @interface ListAvailableAttributesResponse
 */
export interface ListAvailableAttributesResponse {
    /**
     * 
     * @type {Array<AttributeType>}
     * @memberof ListAvailableAttributesResponse
     */
    'attributes': Array<AttributeType>;
}
/**
 * 
 * @export
 * @interface ListRequestAccessUnitsRequest
 */
export interface ListRequestAccessUnitsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListRequestAccessUnitsRequest
     */
    'request_access_units_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface ListResourcesResponse
 */
export interface ListResourcesResponse {
    /**
     * 
     * @type {Array<AwsRdsResource>}
     * @memberof ListResourcesResponse
     */
    'rds': Array<AwsRdsResource>;
}
/**
 * 
 * @export
 * @interface LoadedEntityAppModel
 */
export interface LoadedEntityAppModel {
    /**
     * 
     * @type {string}
     * @memberof LoadedEntityAppModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof LoadedEntityAppModel
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof LoadedEntityAppModel
     */
    'sub_type'?: string;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ModifyActivityReportModel
 */
export interface ModifyActivityReportModel {
    /**
     * 
     * @type {string}
     * @memberof ModifyActivityReportModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModifyActivityReportModel
     */
    'active': boolean;
    /**
     * 
     * @type {ActivityReportFiltersModel}
     * @memberof ModifyActivityReportModel
     */
    'filters': ActivityReportFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof ModifyActivityReportModel
     */
    'start_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyActivityReportModel
     */
    'end_date'?: number;
    /**
     * 
     * @type {ActivityReportModelPeriod}
     * @memberof ModifyActivityReportModel
     */
    'period'?: ActivityReportModelPeriod;
    /**
     * 
     * @type {string}
     * @memberof ModifyActivityReportModel
     */
    'time_zone'?: string;
    /**
     * 
     * @type {Array<ReportField>}
     * @memberof ModifyActivityReportModel
     */
    'fields': Array<ReportField>;
    /**
     * 
     * @type {ActivityReportModelSchedule}
     * @memberof ModifyActivityReportModel
     */
    'schedule'?: ActivityReportModelSchedule;
}
/**
 * 
 * @export
 * @interface OnboardingModel
 */
export interface OnboardingModel {
    /**
     * 
     * @type {string}
     * @memberof OnboardingModel
     */
    'added_integration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingModel
     */
    'added_access_flow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingModel
     */
    'added_access_request_id'?: string;
}
/**
 * 
 * @export
 * @interface OnboardingStatus
 */
export interface OnboardingStatus {
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'is_cloud_connected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'is_connector_added': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'is_slack_connected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'is_access_requested': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'is_idp_connected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'is_policy_defined': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'cloud_connected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'connector_added': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'slack_connected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'access_requested': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'idp_connected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStatus
     */
    'policy_defined': boolean;
}
/**
 * 
 * @export
 * @interface OnboardingSteps
 */
export interface OnboardingSteps {
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingSteps
     */
    'added_resource_integration': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingSteps
     */
    'added_idp_integration': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingSteps
     */
    'added_access_flow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingSteps
     */
    'added_slack_integration': boolean;
}
/**
 * 
 * @export
 * @interface PaginatedAccessFlowAppSearchResponse
 */
export interface PaginatedAccessFlowAppSearchResponse {
    /**
     * 
     * @type {Array<AccessFlowAppModel>}
     * @memberof PaginatedAccessFlowAppSearchResponse
     */
    'data': Array<AccessFlowAppModel>;
    /**
     * 
     * @type {Array<AccessFlowFilterResult>}
     * @memberof PaginatedAccessFlowAppSearchResponse
     */
    'filter_result'?: Array<AccessFlowFilterResult>;
}
/**
 * 
 * @export
 * @interface PaginatedAccessFlowAppSearchResponseV2
 */
export interface PaginatedAccessFlowAppSearchResponseV2 {
    /**
     * 
     * @type {Array<AccessFlowAppModelV2>}
     * @memberof PaginatedAccessFlowAppSearchResponseV2
     */
    'data': Array<AccessFlowAppModelV2>;
    /**
     * 
     * @type {Array<AccessFlowFilterResult>}
     * @memberof PaginatedAccessFlowAppSearchResponseV2
     */
    'filter_result'?: Array<AccessFlowFilterResult>;
}
/**
 * 
 * @export
 * @interface PaginatedAccessFlowSearchResponse
 */
export interface PaginatedAccessFlowSearchResponse {
    /**
     * 
     * @type {Array<AccessFlowModelV2>}
     * @memberof PaginatedAccessFlowSearchResponse
     */
    'data': Array<AccessFlowModelV2>;
    /**
     * 
     * @type {Array<AccessFlowFilterResult>}
     * @memberof PaginatedAccessFlowSearchResponse
     */
    'filter_result'?: Array<AccessFlowFilterResult>;
}
/**
 * 
 * @export
 * @interface PaginatedResponseAccessBundleAppModel
 */
export interface PaginatedResponseAccessBundleAppModel {
    /**
     * 
     * @type {Array<AccessBundleAppModel>}
     * @memberof PaginatedResponseAccessBundleAppModel
     */
    'data': Array<AccessBundleAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseAccessBundleAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseAccessUnitAppModel
 */
export interface PaginatedResponseAccessUnitAppModel {
    /**
     * 
     * @type {Array<AccessUnitAppModel>}
     * @memberof PaginatedResponseAccessUnitAppModel
     */
    'data': Array<AccessUnitAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseAccessUnitAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseAccessUnitsGroupAppModel
 */
export interface PaginatedResponseAccessUnitsGroupAppModel {
    /**
     * 
     * @type {Array<AccessUnitsGroupAppModel>}
     * @memberof PaginatedResponseAccessUnitsGroupAppModel
     */
    'data': Array<AccessUnitsGroupAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseAccessUnitsGroupAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseActivityReportModel
 */
export interface PaginatedResponseActivityReportModel {
    /**
     * 
     * @type {Array<ActivityReportModel>}
     * @memberof PaginatedResponseActivityReportModel
     */
    'data': Array<ActivityReportModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseActivityReportModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseActivityRequestAppModel
 */
export interface PaginatedResponseActivityRequestAppModel {
    /**
     * 
     * @type {Array<ActivityRequestAppModel>}
     * @memberof PaginatedResponseActivityRequestAppModel
     */
    'data': Array<ActivityRequestAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseActivityRequestAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseActivityTimelineEventModel
 */
export interface PaginatedResponseActivityTimelineEventModel {
    /**
     * 
     * @type {Array<ActivityTimelineEventModel>}
     * @memberof PaginatedResponseActivityTimelineEventModel
     */
    'data': Array<ActivityTimelineEventModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseActivityTimelineEventModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseAppModelAccessGraphUnitModel
 */
export interface PaginatedResponseAppModelAccessGraphUnitModel {
    /**
     * 
     * @type {Array<AccessGraphUnitModel>}
     * @memberof PaginatedResponseAppModelAccessGraphUnitModel
     */
    'data': Array<AccessGraphUnitModel>;
    /**
     * 
     * @type {PaginationInfoAppModel}
     * @memberof PaginatedResponseAppModelAccessGraphUnitModel
     */
    'pagination': PaginationInfoAppModel;
}
/**
 * 
 * @export
 * @interface PaginatedResponseAttributeAppModel
 */
export interface PaginatedResponseAttributeAppModel {
    /**
     * 
     * @type {Array<AttributeAppModel>}
     * @memberof PaginatedResponseAttributeAppModel
     */
    'data': Array<AttributeAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseAttributeAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseAttributeGroupAppModel
 */
export interface PaginatedResponseAttributeGroupAppModel {
    /**
     * 
     * @type {Array<AttributeGroupAppModel>}
     * @memberof PaginatedResponseAttributeGroupAppModel
     */
    'data': Array<AttributeGroupAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseAttributeGroupAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseFilterValueAppModel
 */
export interface PaginatedResponseFilterValueAppModel {
    /**
     * 
     * @type {Array<FilterValueAppModel>}
     * @memberof PaginatedResponseFilterValueAppModel
     */
    'data': Array<FilterValueAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseFilterValueAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseIntegrationAppModel
 */
export interface PaginatedResponseIntegrationAppModel {
    /**
     * 
     * @type {Array<IntegrationAppModel>}
     * @memberof PaginatedResponseIntegrationAppModel
     */
    'data': Array<IntegrationAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseIntegrationAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseIntegrationConfigAppModelV2
 */
export interface PaginatedResponseIntegrationConfigAppModelV2 {
    /**
     * 
     * @type {Array<IntegrationConfigAppModelV2>}
     * @memberof PaginatedResponseIntegrationConfigAppModelV2
     */
    'data': Array<IntegrationConfigAppModelV2>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseIntegrationConfigAppModelV2
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseIntegrationErrorAppModel
 */
export interface PaginatedResponseIntegrationErrorAppModel {
    /**
     * 
     * @type {Array<IntegrationErrorAppModel>}
     * @memberof PaginatedResponseIntegrationErrorAppModel
     */
    'data': Array<IntegrationErrorAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseIntegrationErrorAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseIntegrationModel
 */
export interface PaginatedResponseIntegrationModel {
    /**
     * 
     * @type {Array<Integration>}
     * @memberof PaginatedResponseIntegrationModel
     */
    'data': Array<Integration>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseIntegrationModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseResolvedAccessFlowModel
 */
export interface PaginatedResponseResolvedAccessFlowModel {
    /**
     * 
     * @type {Array<ResolvedAccessFlowModel>}
     * @memberof PaginatedResponseResolvedAccessFlowModel
     */
    'data': Array<ResolvedAccessFlowModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseResolvedAccessFlowModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseResourceAppModel
 */
export interface PaginatedResponseResourceAppModel {
    /**
     * 
     * @type {Array<ResourceAppModel>}
     * @memberof PaginatedResponseResourceAppModel
     */
    'data': Array<ResourceAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseResourceAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseResourceDetailedModel
 */
export interface PaginatedResponseResourceDetailedModel {
    /**
     * 
     * @type {Array<ResourceDetailed>}
     * @memberof PaginatedResponseResourceDetailedModel
     */
    'data': Array<ResourceDetailed>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseResourceDetailedModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseString
 */
export interface PaginatedResponseString {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedResponseString
     */
    'data': Array<string>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseString
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseUserIdentityAppModel
 */
export interface PaginatedResponseUserIdentityAppModel {
    /**
     * 
     * @type {Array<UserIdentityAppModel>}
     * @memberof PaginatedResponseUserIdentityAppModel
     */
    'data': Array<UserIdentityAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseUserIdentityAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseWebhookAppModel
 */
export interface PaginatedResponseWebhookAppModel {
    /**
     * 
     * @type {Array<WebhookAppModel>}
     * @memberof PaginatedResponseWebhookAppModel
     */
    'data': Array<WebhookAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseWebhookAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginatedResponseWebhookInvocationAppModel
 */
export interface PaginatedResponseWebhookInvocationAppModel {
    /**
     * 
     * @type {Array<WebhookInvocationAppModel>}
     * @memberof PaginatedResponseWebhookInvocationAppModel
     */
    'data': Array<WebhookInvocationAppModel>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PaginatedResponseWebhookInvocationAppModel
     */
    'pagination': PaginationInfo;
}
/**
 * 
 * @export
 * @interface PaginationInfo
 */
export interface PaginationInfo {
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface PaginationInfoAppModel
 */
export interface PaginationInfoAppModel {
    /**
     * 
     * @type {number}
     * @memberof PaginationInfoAppModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfoAppModel
     */
    'offset': number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationInfoAppModel
     */
    'has_more': boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfoAppModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface Payload
 */
export interface Payload {
    /**
     * 
     * @type {string}
     * @memberof Payload
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Payload
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface PermissionDictionary
 */
export interface PermissionDictionary {
    /**
     * 
     * @type {string}
     * @memberof PermissionDictionary
     */
    'integration_type': string;
    /**
     * 
     * @type {Array<ResourcePermissions>}
     * @memberof PermissionDictionary
     */
    'resources_permissions': Array<ResourcePermissions>;
    /**
     * 
     * @type {string}
     * @memberof PermissionDictionary
     */
    'resource': string;
    /**
     * 
     * @type {Array<ResourcePermissions>}
     * @memberof PermissionDictionary
     */
    'permissions': Array<ResourcePermissions>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportField = {
    RequestId: 'request_id',
    RequestDate: 'request_date',
    RequestGrantDate: 'request_grant_date',
    RequestRevokeDate: 'request_revoke_date',
    RequestorName: 'requestor_name',
    RequestorEmail: 'requestor_email',
    Integration: 'integration',
    Resources: 'resources',
    ResourceType: 'resource_type',
    Permissions: 'permissions',
    ApproverNames: 'approver_names',
    ApproverEmails: 'approver_emails',
    ApproverTypes: 'approver_types',
    Justification: 'justification',
    Status: 'status',
    ResourcesStatus: 'resources_status',
    TriggerType: 'trigger_type',
    AccessFlow: 'access_flow',
    BundleName: 'bundle_name'
} as const;

export type ReportField = typeof ReportField[keyof typeof ReportField];


/**
 * 
 * @export
 * @interface ReportPeriodModel
 */
export interface ReportPeriodModel {
    /**
     * 
     * @type {ReportPeriodUnit}
     * @memberof ReportPeriodModel
     */
    'unit': ReportPeriodUnit;
    /**
     * 
     * @type {number}
     * @memberof ReportPeriodModel
     */
    'value': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportPeriodModel
     */
    'rounded': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportPeriodUnit = {
    Hours: 'hours',
    Days: 'days',
    Weeks: 'weeks',
    Months: 'months'
} as const;

export type ReportPeriodUnit = typeof ReportPeriodUnit[keyof typeof ReportPeriodUnit];


/**
 * 
 * @export
 * @interface ReportScheduleModel
 */
export interface ReportScheduleModel {
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleModel
     */
    'cron': string;
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleModel
     */
    'format': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportScheduleModel
     */
    'recipients': Array<string>;
}
/**
 * 
 * @export
 * @interface RequestContextBasedAttributeFilterAppModel
 */
export interface RequestContextBasedAttributeFilterAppModel {
    /**
     * 
     * @type {AttributeTypeAppModel}
     * @memberof RequestContextBasedAttributeFilterAppModel
     */
    'attribute_type': AttributeTypeAppModel;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof RequestContextBasedAttributeFilterAppModel
     */
    'integration'?: AttributeAppModelIntegration;
}
/**
 * 
 * @export
 * @interface RequestContextBasedAttributeFilterUpsertAppModel
 */
export interface RequestContextBasedAttributeFilterUpsertAppModel {
    /**
     * 
     * @type {string}
     * @memberof RequestContextBasedAttributeFilterUpsertAppModel
     */
    'attribute_type_id': string;
    /**
     * 
     * @type {string}
     * @memberof RequestContextBasedAttributeFilterUpsertAppModel
     */
    'integration_id'?: string;
}
/**
 * 
 * @export
 * @interface ResolvedAccessFlowModel
 */
export interface ResolvedAccessFlowModel {
    /**
     * 
     * @type {string}
     * @memberof ResolvedAccessFlowModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResolvedAccessFlowModel
     */
    'name': string;
    /**
     * 
     * @type {GranteeConditionAppModel}
     * @memberof ResolvedAccessFlowModel
     */
    'grantee_condition': GranteeConditionAppModel;
    /**
     * 
     * @type {Array<IntegrationAccessTargetAppModel>}
     * @memberof ResolvedAccessFlowModel
     */
    'access_targets_resolved': Array<IntegrationAccessTargetAppModel>;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'resource_integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'type': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Resource
     */
    'tags': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ResourceAppModel
 */
export interface ResourceAppModel {
    /**
     * 
     * @type {string}
     * @memberof ResourceAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceAppModel
     */
    'integration_id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResourceAppModel
     */
    'path': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResourceAppModel
     */
    'tags': { [key: string]: string; };
    /**
     * 
     * @type {ResourceStatusModel}
     * @memberof ResourceAppModel
     */
    'status': ResourceStatusModel;
    /**
     * 
     * @type {Array<ResourceOwnerAppModel>}
     * @memberof ResourceAppModel
     */
    'owners'?: Array<ResourceOwnerAppModel>;
}
/**
 * 
 * @export
 * @interface ResourceDetailed
 */
export interface ResourceDetailed {
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed
     */
    'resource_integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed
     */
    'type': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResourceDetailed
     */
    'tags': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ResourceDetailed1
 */
export interface ResourceDetailed1 {
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed1
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed1
     */
    'resource_integration_name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed1
     */
    'resource_integration_type': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDetailed1
     */
    'resource_name': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResourceDetailed1
     */
    'all_tags': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ResourceOwnerAppModel
 */
export interface ResourceOwnerAppModel {
    /**
     * 
     * @type {string}
     * @memberof ResourceOwnerAppModel
     */
    'integration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOwnerAppModel
     */
    'attribute_type_id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOwnerAppModel
     */
    'attribute_value': string;
}
/**
 * 
 * @export
 * @interface ResourceOwnerMappingAppModel
 */
export interface ResourceOwnerMappingAppModel {
    /**
     * 
     * @type {string}
     * @memberof ResourceOwnerMappingAppModel
     */
    'tag_name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOwnerMappingAppModel
     */
    'attribute_type': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOwnerMappingAppModel
     */
    'attribute_integration_id'?: string;
}
/**
 * 
 * @export
 * @interface ResourcePermissions
 */
export interface ResourcePermissions {
    /**
     * 
     * @type {string}
     * @memberof ResourcePermissions
     */
    'resource_type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourcePermissions
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResourcePermissions
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ResourceStatusModel
 */
export interface ResourceStatusModel {
    /**
     * 
     * @type {ResourceStatusV1}
     * @memberof ResourceStatusModel
     */
    'status': ResourceStatusV1;
    /**
     * 
     * @type {string}
     * @memberof ResourceStatusModel
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResourceStatusV1 = {
    Active: 'Active',
    Error: 'Error',
    Deleted: 'Deleted'
} as const;

export type ResourceStatusV1 = typeof ResourceStatusV1[keyof typeof ResourceStatusV1];


/**
 * 
 * @export
 * @interface ResourceTypeAccessUnitGroupAppModel
 */
export interface ResourceTypeAccessUnitGroupAppModel {
    /**
     * 
     * @type {ResourceTypeConfigAppModel}
     * @memberof ResourceTypeAccessUnitGroupAppModel
     */
    'type': ResourceTypeConfigAppModel;
    /**
     * 
     * @type {number}
     * @memberof ResourceTypeAccessUnitGroupAppModel
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ResourceTypeConfig
 */
export interface ResourceTypeConfig {
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeConfig
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeConfig
     */
    'display_name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceTypeConfig
     */
    'group_by': Array<string>;
}
/**
 * 
 * @export
 * @interface ResourceTypeConfigAppModel
 */
export interface ResourceTypeConfigAppModel {
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeConfigAppModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeConfigAppModel
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeConfigAppModel
     */
    'hierarchy': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceTypeConfigAppModel
     */
    'requestable': boolean;
    /**
     * 
     * @type {IconsConfigAppModel}
     * @memberof ResourceTypeConfigAppModel
     */
    'icons': IconsConfigAppModel;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceTypeConfigAppModel
     */
    'premium': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceTypeConfigAppModel
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface ResourcesIssuesAppModel
 */
export interface ResourcesIssuesAppModel {
    /**
     * 
     * @type {string}
     * @memberof ResourcesIssuesAppModel
     */
    'integration_id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesIssuesAppModel
     */
    'resource_type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourcesIssuesAppModel
     */
    'resource_names': Array<string>;
}
/**
 * 
 * @export
 * @interface ResourcesSearchAppModel
 */
export interface ResourcesSearchAppModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourcesSearchAppModel
     */
    'resource_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResourcesSearchAppModel
     */
    'resource_type'?: string;
}
/**
 * 
 * @export
 * @interface RevokeAccessRequestsAppModel
 */
export interface RevokeAccessRequestsAppModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'grantee_user_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'integration_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'resource_names'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'statuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'resource_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'trigger_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeAccessRequestsAppModel
     */
    'access_flow_ids'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RevokeAccessRequestsAppModel
     */
    'start_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevokeAccessRequestsAppModel
     */
    'end_date'?: number;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {Array<AponoSearchObjectUser>}
     * @memberof SearchResponse
     */
    'users': Array<AponoSearchObjectUser>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchResponse
     */
    'groups': Array<string>;
    /**
     * 
     * @type {Array<ResourceDetailed1>}
     * @memberof SearchResponse
     */
    'resources': Array<ResourceDetailed1>;
    /**
     * 
     * @type {Array<AccessFlowSearchResult>}
     * @memberof SearchResponse
     */
    'access_flows': Array<AccessFlowSearchResult>;
    /**
     * 
     * @type {Array<SearchResponseTagsInner>}
     * @memberof SearchResponse
     */
    'tags': Array<SearchResponseTagsInner>;
}
/**
 * 
 * @export
 * @interface SearchResponseTagsInner
 */
export interface SearchResponseTagsInner {
    /**
     * 
     * @type {string}
     * @memberof SearchResponseTagsInner
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResponseTagsInner
     */
    'second'?: string;
}
/**
 * 
 * @export
 * @interface SetAccessFlowActiveFlagRequestV2
 */
export interface SetAccessFlowActiveFlagRequestV2 {
    /**
     * 
     * @type {boolean}
     * @memberof SetAccessFlowActiveFlagRequestV2
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface TagAppModel
 */
export interface TagAppModel {
    /**
     * 
     * @type {string}
     * @memberof TagAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagAppModel
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface TagSelectorModel
 */
export interface TagSelectorModel {
    /**
     * 
     * @type {string}
     * @memberof TagSelectorModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagSelectorModel
     */
    'value_wildcard': string;
}
/**
 * 
 * @export
 * @interface TagSelectorModelV1
 */
export interface TagSelectorModelV1 {
    /**
     * 
     * @type {string}
     * @memberof TagSelectorModelV1
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagSelectorModelV1
     */
    'value_wildcard': string;
}
/**
 * 
 * @export
 * @interface Timeframe
 */
export interface Timeframe {
    /**
     * 
     * @type {number}
     * @memberof Timeframe
     */
    'start_time_sec': number;
    /**
     * 
     * @type {number}
     * @memberof Timeframe
     */
    'end_time_sec': number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof Timeframe
     */
    'days_in_week': Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof Timeframe
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface TimeframeAppModel
 */
export interface TimeframeAppModel {
    /**
     * 
     * @type {number}
     * @memberof TimeframeAppModel
     */
    'start_time_sec': number;
    /**
     * 
     * @type {number}
     * @memberof TimeframeAppModel
     */
    'end_time_sec': number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof TimeframeAppModel
     */
    'days_in_week': Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof TimeframeAppModel
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface TimeframeModelV1
 */
export interface TimeframeModelV1 {
    /**
     * 
     * @type {number}
     * @memberof TimeframeModelV1
     */
    'start_time_sec': number;
    /**
     * 
     * @type {number}
     * @memberof TimeframeModelV1
     */
    'end_time_sec': number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof TimeframeModelV1
     */
    'days_in_week': Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof TimeframeModelV1
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface TopOverTimeQueryResponse
 */
export interface TopOverTimeQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof TopOverTimeQueryResponse
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof TopOverTimeQueryResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TopQueryEntityType = {
    Grantees: 'Grantees',
    Approvers: 'Approvers',
    Integrations: 'Integrations',
    AccessFlows: 'AccessFlows'
} as const;

export type TopQueryEntityType = typeof TopQueryEntityType[keyof typeof TopQueryEntityType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TriggerType = {
    UserRequest: 'USER_REQUEST',
    AutoGrant: 'AUTO_GRANT'
} as const;

export type TriggerType = typeof TriggerType[keyof typeof TriggerType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TriggerTypeAppModel = {
    UserRequest: 'USER_REQUEST',
    AutoGrant: 'AUTO_GRANT'
} as const;

export type TriggerTypeAppModel = typeof TriggerTypeAppModel[keyof typeof TriggerTypeAppModel];


/**
 * 
 * @export
 * @interface Unmasked
 */
export interface Unmasked {
    /**
     * 
     * @type {string}
     * @memberof Unmasked
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Unmasked
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof Unmasked
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof Unmasked
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Unmasked
     */
    'token': string;
    /**
     * 
     * @type {number}
     * @memberof Unmasked
     */
    'expiry_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof Unmasked
     */
    'created_date': number;
}
/**
 * 
 * @export
 * @interface UpdateAccessFlowModelV2
 */
export interface UpdateAccessFlowModelV2 {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessFlowModelV2
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccessFlowModelV2
     */
    'active': boolean;
    /**
     * 
     * @type {TriggerType}
     * @memberof UpdateAccessFlowModelV2
     */
    'trigger_type': TriggerType;
    /**
     * 
     * @type {Array<GranteeModel>}
     * @memberof UpdateAccessFlowModelV2
     */
    'grantees': Array<GranteeModel>;
    /**
     * 
     * @type {Array<AccessTargetModel>}
     * @memberof UpdateAccessFlowModelV2
     */
    'access_targets': Array<AccessTargetModel>;
    /**
     * 
     * @type {Array<ApproverModel>}
     * @memberof UpdateAccessFlowModelV2
     */
    'approvers': Array<ApproverModel>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccessFlowModelV2
     */
    'revoke_after_in_sec': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccessFlowModelV2
     */
    'justification_required': boolean;
    /**
     * 
     * @type {AccessFlowModelV2Timeframe}
     * @memberof UpdateAccessFlowModelV2
     */
    'timeframe'?: AccessFlowModelV2Timeframe;
    /**
     * 
     * @type {AccessFlowModelV2Settings}
     * @memberof UpdateAccessFlowModelV2
     */
    'settings'?: AccessFlowModelV2Settings;
}
/**
 * 
 * @export
 * @interface UpdateAccountSettingsModel
 */
export interface UpdateAccountSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountSettingsModel
     */
    'enable_flow_failure_notifications'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSettingsModel
     */
    'max_access_grant_extend_times'?: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateAccountSettingsModel
     */
    'default_integration_metadata_by_connection_type'?: object;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSettingsModel
     */
    'credentials_rotation_period_in_days'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSettingsModel
     */
    'inactive_user_retention_in_days'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountSettingsModel
     */
    'integrations_updates_notifications'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountSettingsModel
     */
    'access_provision_timeout_seconds'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountSettingsModel
     */
    'require_duration_for_access_request'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnector
 */
export interface UpdateConnector {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnector
     */
    'connector_name': string;
}
/**
 * 
 * @export
 * @interface UpdateExpiryRequest
 */
export interface UpdateExpiryRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateExpiryRequest
     */
    'expiry_date'?: number;
}
/**
 * 
 * @export
 * @interface UpdateIntegration
 */
export interface UpdateIntegration {
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegration
     */
    'provisioner_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateIntegration
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof UpdateIntegration
     */
    'secret_config'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateIntegration
     */
    'connected_resource_types'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegration
     */
    'custom_access_details'?: string;
}
/**
 * 
 * @export
 * @interface UpdateIntegrationAppModel
 */
export interface UpdateIntegrationAppModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationAppModel
     */
    'provisioner_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateIntegrationAppModel
     */
    'connected_resource_types': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof UpdateIntegrationAppModel
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof UpdateIntegrationAppModel
     */
    'secret_config'?: object;
    /**
     * 
     * @type {CreateIntegrationAppModelCustomAccessDetailsSettings}
     * @memberof UpdateIntegrationAppModel
     */
    'custom_access_details_settings'?: CreateIntegrationAppModelCustomAccessDetailsSettings;
    /**
     * 
     * @type {CreateIntegrationAppModelIntegrationOwners}
     * @memberof UpdateIntegrationAppModel
     */
    'integration_owners'?: CreateIntegrationAppModelIntegrationOwners;
    /**
     * 
     * @type {Array<ResourceOwnerMappingAppModel>}
     * @memberof UpdateIntegrationAppModel
     */
    'resource_owners_mappings'?: Array<ResourceOwnerMappingAppModel>;
    /**
     * 
     * @type {Array<ConnectedSubIntegrationResourceTypesAppModel>}
     * @memberof UpdateIntegrationAppModel
     */
    'connected_sub_integration_resource_types'?: Array<ConnectedSubIntegrationResourceTypesAppModel>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserDetailedModel
 */
export interface UserDetailedModel {
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'last_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailedModel
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'role': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDetailedModel
     */
    'roles': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserDetailedModel
     */
    'last_active'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'idp_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailedModel
     */
    'idp_type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDetailedModel
     */
    'group_ids'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserDetailedModel
     */
    'attributes': { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface UserGroupsModel
 */
export interface UserGroupsModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupsModel
     */
    'group_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface UserIdentityAppModel
 */
export interface UserIdentityAppModel {
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAppModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAppModel
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAppModel
     */
    'last_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserIdentityAppModel
     */
    'active': boolean;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof UserIdentityAppModel
     */
    'integration'?: AttributeAppModelIntegration;
    /**
     * 
     * @type {Array<UserIdentityAttributeAppModel>}
     * @memberof UserIdentityAppModel
     */
    'attributes': Array<UserIdentityAttributeAppModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserIdentityAppModel
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface UserIdentityAttributeAppModel
 */
export interface UserIdentityAttributeAppModel {
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAttributeAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAttributeAppModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityAttributeAppModel
     */
    'value': string;
    /**
     * 
     * @type {AttributeAppModelIntegration}
     * @memberof UserIdentityAttributeAppModel
     */
    'integration'?: AttributeAppModelIntegration;
    /**
     * 
     * @type {Array<AccessFlowAreaModel>}
     * @memberof UserIdentityAttributeAppModel
     */
    'applicable_places': Array<AccessFlowAreaModel>;
}
/**
 * 
 * @export
 * @interface UserPersonalToken
 */
export interface UserPersonalToken {
    /**
     * 
     * @type {string}
     * @memberof UserPersonalToken
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPersonalToken
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPersonalToken
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPersonalToken
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserPersonalToken
     */
    'masked_token': string;
    /**
     * 
     * @type {number}
     * @memberof UserPersonalToken
     */
    'expiry_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPersonalToken
     */
    'created_date': number;
}
/**
 * 
 * @export
 * @interface UserSessionApiModel
 */
export interface UserSessionApiModel {
    /**
     * 
     * @type {AponoUserModel}
     * @memberof UserSessionApiModel
     */
    'user': AponoUserModel;
    /**
     * 
     * @type {AccountModel}
     * @memberof UserSessionApiModel
     */
    'account': AccountModel;
    /**
     * 
     * @type {GettingStarted}
     * @memberof UserSessionApiModel
     */
    'getting_started': GettingStarted;
    /**
     * 
     * @type {UserSessionApiModelOnboardingFlow}
     * @memberof UserSessionApiModel
     */
    'onboarding_flow'?: UserSessionApiModelOnboardingFlow;
    /**
     * 
     * @type {GettingStarted}
     * @memberof UserSessionApiModel
     */
    'onboarding': GettingStarted;
}
/**
 * 
 * @export
 * @interface UserSessionApiModelOnboardingFlow
 */
export interface UserSessionApiModelOnboardingFlow {
    /**
     * 
     * @type {string}
     * @memberof UserSessionApiModelOnboardingFlow
     */
    'added_integration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSessionApiModelOnboardingFlow
     */
    'added_access_flow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSessionApiModelOnboardingFlow
     */
    'added_access_request_id'?: string;
}
/**
 * 
 * @export
 * @interface Variant
 */
export interface Variant {
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    'name': string;
    /**
     * 
     * @type {VariantPayload}
     * @memberof Variant
     */
    'payload'?: VariantPayload;
    /**
     * 
     * @type {boolean}
     * @memberof Variant
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    'stickiness'?: string;
}
/**
 * 
 * @export
 * @interface VariantPayload
 */
export interface VariantPayload {
    /**
     * 
     * @type {string}
     * @memberof VariantPayload
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof VariantPayload
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface WebhookAppModel
 */
export interface WebhookAppModel {
    /**
     * 
     * @type {string}
     * @memberof WebhookAppModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookAppModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookAppModel
     */
    'active': boolean;
    /**
     * 
     * @type {Array<WebhookTriggerEventAppModel>}
     * @memberof WebhookAppModel
     */
    'triggers': Array<WebhookTriggerEventAppModel>;
    /**
     * 
     * @type {WebhookMethodAppModel}
     * @memberof WebhookAppModel
     */
    'method': WebhookMethodAppModel;
    /**
     * 
     * @type {string}
     * @memberof WebhookAppModel
     */
    'url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebhookAppModel
     */
    'headers': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WebhookAppModel
     */
    'body_template'?: string;
    /**
     * 
     * @type {Array<WebhookResponseValidatorAppModel>}
     * @memberof WebhookAppModel
     */
    'response_validators'?: Array<WebhookResponseValidatorAppModel>;
    /**
     * 
     * @type {WebhookAppModelRetryPolicy}
     * @memberof WebhookAppModel
     */
    'retry_policy'?: WebhookAppModelRetryPolicy;
    /**
     * 
     * @type {number}
     * @memberof WebhookAppModel
     */
    'last_invocation_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebhookAppModel
     */
    'last_successful_invocation_date'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebhookAppModel
     */
    'created_date': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookAppModel
     */
    'updated_date': number;
    /**
     * 
     * @type {WebhookAppModelFilters}
     * @memberof WebhookAppModel
     */
    'filters'?: WebhookAppModelFilters;
}
/**
 * 
 * @export
 * @interface WebhookAppModelFilters
 */
export interface WebhookAppModelFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'grantee_user_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'integration_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'resource_names'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'resource_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'trigger_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookAppModelFilters
     */
    'access_flow_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WebhookAppModelRetryPolicy
 */
export interface WebhookAppModelRetryPolicy {
    /**
     * 
     * @type {number}
     * @memberof WebhookAppModelRetryPolicy
     */
    'timeout_in_sec': number;
}
/**
 * 
 * @export
 * @interface WebhookFiltersAppModel
 */
export interface WebhookFiltersAppModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'grantee_user_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'integration_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'resource_names'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'resource_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'trigger_types'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookFiltersAppModel
     */
    'access_flow_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WebhookInvocationAppModel
 */
export interface WebhookInvocationAppModel {
    /**
     * 
     * @type {string}
     * @memberof WebhookInvocationAppModel
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookInvocationAppModel
     */
    'success': boolean;
    /**
     * 
     * @type {WebhookTriggerEventAppModel}
     * @memberof WebhookInvocationAppModel
     */
    'trigger': WebhookTriggerEventAppModel;
    /**
     * 
     * @type {number}
     * @memberof WebhookInvocationAppModel
     */
    'invocation_date': number;
    /**
     * 
     * @type {WebhookInvocationRequestAppModel}
     * @memberof WebhookInvocationAppModel
     */
    'request': WebhookInvocationRequestAppModel;
    /**
     * 
     * @type {WebhookInvocationAppModelResponse}
     * @memberof WebhookInvocationAppModel
     */
    'response'?: WebhookInvocationAppModelResponse;
    /**
     * 
     * @type {number}
     * @memberof WebhookInvocationAppModel
     */
    'attempt_num': number;
}
/**
 * 
 * @export
 * @interface WebhookInvocationAppModelResponse
 */
export interface WebhookInvocationAppModelResponse {
    /**
     * 
     * @type {number}
     * @memberof WebhookInvocationAppModelResponse
     */
    'status_code': number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebhookInvocationAppModelResponse
     */
    'headers': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WebhookInvocationAppModelResponse
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface WebhookInvocationRequestAppModel
 */
export interface WebhookInvocationRequestAppModel {
    /**
     * 
     * @type {WebhookMethodAppModel}
     * @memberof WebhookInvocationRequestAppModel
     */
    'method': WebhookMethodAppModel;
    /**
     * 
     * @type {string}
     * @memberof WebhookInvocationRequestAppModel
     */
    'url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebhookInvocationRequestAppModel
     */
    'headers': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WebhookInvocationRequestAppModel
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface WebhookInvocationResponseAppModel
 */
export interface WebhookInvocationResponseAppModel {
    /**
     * 
     * @type {number}
     * @memberof WebhookInvocationResponseAppModel
     */
    'status_code': number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebhookInvocationResponseAppModel
     */
    'headers': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WebhookInvocationResponseAppModel
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WebhookMethodAppModel = {
    Post: 'POST',
    Put: 'PUT',
    Delete: 'DELETE',
    Get: 'GET',
    Patch: 'PATCH'
} as const;

export type WebhookMethodAppModel = typeof WebhookMethodAppModel[keyof typeof WebhookMethodAppModel];


/**
 * 
 * @export
 * @interface WebhookResponseValidatorAppModel
 */
export interface WebhookResponseValidatorAppModel {
    /**
     * 
     * @type {string}
     * @memberof WebhookResponseValidatorAppModel
     */
    'json_path': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookResponseValidatorAppModel
     */
    'expected_values': Array<string>;
}
/**
 * 
 * @export
 * @interface WebhookRetryPolicyAppModel
 */
export interface WebhookRetryPolicyAppModel {
    /**
     * 
     * @type {number}
     * @memberof WebhookRetryPolicyAppModel
     */
    'timeout_in_sec': number;
}
/**
 * 
 * @export
 * @interface WebhookTestAppModel
 */
export interface WebhookTestAppModel {
    /**
     * 
     * @type {WebhookTriggerEventAppModel}
     * @memberof WebhookTestAppModel
     */
    'trigger': WebhookTriggerEventAppModel;
    /**
     * 
     * @type {WebhookMethodAppModel}
     * @memberof WebhookTestAppModel
     */
    'method': WebhookMethodAppModel;
    /**
     * 
     * @type {string}
     * @memberof WebhookTestAppModel
     */
    'url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebhookTestAppModel
     */
    'headers': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WebhookTestAppModel
     */
    'body_template'?: string;
    /**
     * 
     * @type {Array<WebhookResponseValidatorAppModel>}
     * @memberof WebhookTestAppModel
     */
    'response_validators'?: Array<WebhookResponseValidatorAppModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WebhookTriggerEventAppModel = {
    RequestCreated: 'RequestCreated',
    RequestApproved: 'RequestApproved',
    RequestRejected: 'RequestRejected',
    RequestGranted: 'RequestGranted',
    RequestExpired: 'RequestExpired',
    RequestFailed: 'RequestFailed',
    RequestAnomalyDetected: 'RequestAnomalyDetected',
    Manual: 'Manual'
} as const;

export type WebhookTriggerEventAppModel = typeof WebhookTriggerEventAppModel[keyof typeof WebhookTriggerEventAppModel];


/**
 * 
 * @export
 * @interface WebhookUpdateAppModel
 */
export interface WebhookUpdateAppModel {
    /**
     * 
     * @type {string}
     * @memberof WebhookUpdateAppModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookUpdateAppModel
     */
    'active': boolean;
    /**
     * 
     * @type {Array<WebhookTriggerEventAppModel>}
     * @memberof WebhookUpdateAppModel
     */
    'triggers': Array<WebhookTriggerEventAppModel>;
    /**
     * 
     * @type {WebhookMethodAppModel}
     * @memberof WebhookUpdateAppModel
     */
    'method': WebhookMethodAppModel;
    /**
     * 
     * @type {string}
     * @memberof WebhookUpdateAppModel
     */
    'url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebhookUpdateAppModel
     */
    'headers': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WebhookUpdateAppModel
     */
    'body_template'?: string;
    /**
     * 
     * @type {Array<WebhookResponseValidatorAppModel>}
     * @memberof WebhookUpdateAppModel
     */
    'response_validators'?: Array<WebhookResponseValidatorAppModel>;
    /**
     * 
     * @type {WebhookAppModelRetryPolicy}
     * @memberof WebhookUpdateAppModel
     */
    'retry_policy'?: WebhookAppModelRetryPolicy;
    /**
     * 
     * @type {WebhookAppModelFilters}
     * @memberof WebhookUpdateAppModel
     */
    'filters'?: WebhookAppModelFilters;
}

/**
 * AccessFlowsApi - axios parameter creator
 * @export
 */
export const AccessFlowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get delta of issues in access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appAccessFlowIssues: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appAccessFlowIssues', 'id', id)
            const localVarPath = `/apn/core/api/app/access-flows/{id}/issues`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary check if access bundle can be deleted and not contains active access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCanDeleteAccessBundle: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appCanDeleteAccessBundle', 'id', id)
            const localVarPath = `/apn/core/api/app/access-bundles/{id}/can-delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {AccessFlowSetActiveAppRequest} accessFlowSetActiveAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appChangeAccessFlowActiveFlag: async (id: string, accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appChangeAccessFlowActiveFlag', 'id', id)
            // verify required parameter 'accessFlowSetActiveAppRequest' is not null or undefined
            assertParamExists('appChangeAccessFlowActiveFlag', 'accessFlowSetActiveAppRequest', accessFlowSetActiveAppRequest)
            const localVarPath = `/apn/core/api/app/access-flows/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessFlowSetActiveAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {AccessFlowSetActiveAppRequest} accessFlowSetActiveAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appChangeAccessFlowActiveFlagV2: async (id: string, accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appChangeAccessFlowActiveFlagV2', 'id', id)
            // verify required parameter 'accessFlowSetActiveAppRequest' is not null or undefined
            assertParamExists('appChangeAccessFlowActiveFlagV2', 'accessFlowSetActiveAppRequest', accessFlowSetActiveAppRequest)
            const localVarPath = `/apn/core/api/app/v2/access-flows/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessFlowSetActiveAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create access bundle
         * @param {AccessBundleUpsertAppModel} accessBundleUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateAccessBundle: async (accessBundleUpsertAppModel: AccessBundleUpsertAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessBundleUpsertAppModel' is not null or undefined
            assertParamExists('appCreateAccessBundle', 'accessBundleUpsertAppModel', accessBundleUpsertAppModel)
            const localVarPath = `/apn/core/api/app/access-bundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessBundleUpsertAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create access flow
         * @param {AccessFlowUpsertAppModel} accessFlowUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateAccessFlow: async (accessFlowUpsertAppModel: AccessFlowUpsertAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessFlowUpsertAppModel' is not null or undefined
            assertParamExists('appCreateAccessFlow', 'accessFlowUpsertAppModel', accessFlowUpsertAppModel)
            const localVarPath = `/apn/core/api/app/access-flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessFlowUpsertAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create access flow
         * @param {AccessFlowUpsertAppModelV2} accessFlowUpsertAppModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateAccessFlowV2: async (accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessFlowUpsertAppModelV2' is not null or undefined
            assertParamExists('appCreateAccessFlowV2', 'accessFlowUpsertAppModelV2', accessFlowUpsertAppModelV2)
            const localVarPath = `/apn/core/api/app/v2/access-flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessFlowUpsertAppModelV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete access bundle
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteAccessBundle: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appDeleteAccessBundle', 'id', id)
            const localVarPath = `/apn/core/api/app/access-bundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteAccessFlow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appDeleteAccessFlow', 'id', id)
            const localVarPath = `/apn/core/api/app/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteAccessFlowV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appDeleteAccessFlowV2', 'id', id)
            const localVarPath = `/apn/core/api/app/v2/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get access bundle
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetAccessBundle: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetAccessBundle', 'id', id)
            const localVarPath = `/apn/core/api/app/access-bundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetAccessFlow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetAccessFlow', 'id', id)
            const localVarPath = `/apn/core/api/app/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetAccessFlowV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetAccessFlowV2', 'id', id)
            const localVarPath = `/apn/core/api/app/v2/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list access bundles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessBundles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/access-bundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list access flows
         * @param {Array<string>} [accessFlowIds] 
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessFlows: async (accessFlowIds?: Array<string>, filters?: string, onlyActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/access-flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessFlowIds) {
                localVarQueryParameter['accessFlowIds'] = accessFlowIds;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['only_active'] = onlyActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccessFlowFilterAppModel} filterName 
         * @param {number} [limit] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessFlowsFilterOptions: async (filterName: AccessFlowFilterAppModel, limit?: number, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterName' is not null or undefined
            assertParamExists('appListAccessFlowsFilterOptions', 'filterName', filterName)
            const localVarPath = `/apn/core/api/app/access-flows/autocomplete/{filter_name}`
                .replace(`{${"filter_name"}}`, encodeURIComponent(String(filterName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list access flows
         * @param {Array<string>} [accessFlowIds] 
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessFlowsV2: async (accessFlowIds?: Array<string>, filters?: string, onlyActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/v2/access-flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessFlowIds) {
                localVarQueryParameter['accessFlowIds'] = accessFlowIds;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['only_active'] = onlyActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update access bundle
         * @param {string} id 
         * @param {AccessBundleUpsertAppModel} accessBundleUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateAccessBundle: async (id: string, accessBundleUpsertAppModel: AccessBundleUpsertAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appUpdateAccessBundle', 'id', id)
            // verify required parameter 'accessBundleUpsertAppModel' is not null or undefined
            assertParamExists('appUpdateAccessBundle', 'accessBundleUpsertAppModel', accessBundleUpsertAppModel)
            const localVarPath = `/apn/core/api/app/access-bundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessBundleUpsertAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {AccessFlowUpsertAppModel} accessFlowUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateAccessFlow: async (id: string, accessFlowUpsertAppModel: AccessFlowUpsertAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appUpdateAccessFlow', 'id', id)
            // verify required parameter 'accessFlowUpsertAppModel' is not null or undefined
            assertParamExists('appUpdateAccessFlow', 'accessFlowUpsertAppModel', accessFlowUpsertAppModel)
            const localVarPath = `/apn/core/api/app/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessFlowUpsertAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {AccessFlowUpsertAppModelV2} accessFlowUpsertAppModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateAccessFlowV2: async (id: string, accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appUpdateAccessFlowV2', 'id', id)
            // verify required parameter 'accessFlowUpsertAppModelV2' is not null or undefined
            assertParamExists('appUpdateAccessFlowV2', 'accessFlowUpsertAppModelV2', accessFlowUpsertAppModelV2)
            const localVarPath = `/apn/core/api/app/v2/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessFlowUpsertAppModelV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {SetAccessFlowActiveFlagRequestV2} setAccessFlowActiveFlagRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccessFlowActiveFlag: async (id: string, setAccessFlowActiveFlagRequestV2: SetAccessFlowActiveFlagRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccessFlowActiveFlag', 'id', id)
            // verify required parameter 'setAccessFlowActiveFlagRequestV2' is not null or undefined
            assertParamExists('changeAccessFlowActiveFlag', 'setAccessFlowActiveFlagRequestV2', setAccessFlowActiveFlagRequestV2)
            const localVarPath = `/apn/core/api/v2/access-flows/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAccessFlowActiveFlagRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create access flow
         * @param {CreateAccessFlowRequestV2} createAccessFlowRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessFlow: async (createAccessFlowRequestV2: CreateAccessFlowRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccessFlowRequestV2' is not null or undefined
            assertParamExists('createAccessFlow', 'createAccessFlowRequestV2', createAccessFlowRequestV2)
            const localVarPath = `/apn/core/api/v2/access-flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccessFlowRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessFlow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAccessFlow', 'id', id)
            const localVarPath = `/apn/core/api/v2/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessFlow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccessFlow', 'id', id)
            const localVarPath = `/apn/core/api/v2/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFallbackPolicy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/fallback-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list access flows
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessFlows: async (filters?: string, onlyActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v2/access-flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['only_active'] = onlyActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync auto grant access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAutoGrantAccessFlow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncAutoGrantAccessFlow', 'id', id)
            const localVarPath = `/apn/core/api/app/access-flows/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {UpdateAccessFlowModelV2} updateAccessFlowModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessFlow: async (id: string, updateAccessFlowModelV2: UpdateAccessFlowModelV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccessFlow', 'id', id)
            // verify required parameter 'updateAccessFlowModelV2' is not null or undefined
            assertParamExists('updateAccessFlow', 'updateAccessFlowModelV2', updateAccessFlowModelV2)
            const localVarPath = `/apn/core/api/v2/access-flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccessFlowModelV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FallbackPolicyApiModel} fallbackPolicyApiModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFallbackPolicy: async (fallbackPolicyApiModel: FallbackPolicyApiModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fallbackPolicyApiModel' is not null or undefined
            assertParamExists('updateFallbackPolicy', 'fallbackPolicyApiModel', fallbackPolicyApiModel)
            const localVarPath = `/apn/core/api/v1/fallback-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fallbackPolicyApiModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessFlowsApi - functional programming interface
 * @export
 */
export const AccessFlowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessFlowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get delta of issues in access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appAccessFlowIssues(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowIssuesAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appAccessFlowIssues(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary check if access bundle can be deleted and not contains active access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCanDeleteAccessBundle(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanDeleteAccessBundleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCanDeleteAccessBundle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {AccessFlowSetActiveAppRequest} accessFlowSetActiveAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appChangeAccessFlowActiveFlag(id: string, accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appChangeAccessFlowActiveFlag(id, accessFlowSetActiveAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {AccessFlowSetActiveAppRequest} accessFlowSetActiveAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appChangeAccessFlowActiveFlagV2(id: string, accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appChangeAccessFlowActiveFlagV2(id, accessFlowSetActiveAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create access bundle
         * @param {AccessBundleUpsertAppModel} accessBundleUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCreateAccessBundle(accessBundleUpsertAppModel: AccessBundleUpsertAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessBundleAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCreateAccessBundle(accessBundleUpsertAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create access flow
         * @param {AccessFlowUpsertAppModel} accessFlowUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCreateAccessFlow(accessFlowUpsertAppModel: AccessFlowUpsertAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCreateAccessFlow(accessFlowUpsertAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create access flow
         * @param {AccessFlowUpsertAppModelV2} accessFlowUpsertAppModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCreateAccessFlowV2(accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCreateAccessFlowV2(accessFlowUpsertAppModelV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete access bundle
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appDeleteAccessBundle(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appDeleteAccessBundle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appDeleteAccessFlow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appDeleteAccessFlow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appDeleteAccessFlowV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appDeleteAccessFlowV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get access bundle
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetAccessBundle(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessBundleAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetAccessBundle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetAccessFlow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetAccessFlow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetAccessFlowV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetAccessFlowV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list access bundles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListAccessBundles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAccessBundleAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListAccessBundles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list access flows
         * @param {Array<string>} [accessFlowIds] 
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListAccessFlows(accessFlowIds?: Array<string>, filters?: string, onlyActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAccessFlowAppSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListAccessFlows(accessFlowIds, filters, onlyActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccessFlowFilterAppModel} filterName 
         * @param {number} [limit] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListAccessFlowsFilterOptions(filterName: AccessFlowFilterAppModel, limit?: number, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseFilterValueAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListAccessFlowsFilterOptions(filterName, limit, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list access flows
         * @param {Array<string>} [accessFlowIds] 
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListAccessFlowsV2(accessFlowIds?: Array<string>, filters?: string, onlyActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAccessFlowAppSearchResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListAccessFlowsV2(accessFlowIds, filters, onlyActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update access bundle
         * @param {string} id 
         * @param {AccessBundleUpsertAppModel} accessBundleUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUpdateAccessBundle(id: string, accessBundleUpsertAppModel: AccessBundleUpsertAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessBundleAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUpdateAccessBundle(id, accessBundleUpsertAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {AccessFlowUpsertAppModel} accessFlowUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUpdateAccessFlow(id: string, accessFlowUpsertAppModel: AccessFlowUpsertAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUpdateAccessFlow(id, accessFlowUpsertAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {AccessFlowUpsertAppModelV2} accessFlowUpsertAppModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUpdateAccessFlowV2(id: string, accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowAppModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUpdateAccessFlowV2(id, accessFlowUpsertAppModelV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {SetAccessFlowActiveFlagRequestV2} setAccessFlowActiveFlagRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccessFlowActiveFlag(id: string, setAccessFlowActiveFlagRequestV2: SetAccessFlowActiveFlagRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccessFlowActiveFlag(id, setAccessFlowActiveFlagRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create access flow
         * @param {CreateAccessFlowRequestV2} createAccessFlowRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessFlow(createAccessFlowRequestV2: CreateAccessFlowRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessFlow(createAccessFlowRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessFlow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccessFlow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessFlow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessFlow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFallbackPolicy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FallbackPolicyApiModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFallbackPolicy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list access flows
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccessFlows(filters?: string, onlyActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAccessFlowSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccessFlows(filters, onlyActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync auto grant access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAutoGrantAccessFlow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAutoGrantAccessFlow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {UpdateAccessFlowModelV2} updateAccessFlowModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessFlow(id: string, updateAccessFlowModelV2: UpdateAccessFlowModelV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessFlowModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessFlow(id, updateAccessFlowModelV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FallbackPolicyApiModel} fallbackPolicyApiModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFallbackPolicy(fallbackPolicyApiModel: FallbackPolicyApiModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FallbackPolicyApiModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFallbackPolicy(fallbackPolicyApiModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessFlowsApi - factory interface
 * @export
 */
export const AccessFlowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessFlowsApiFp(configuration)
    return {
        /**
         * 
         * @summary get delta of issues in access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appAccessFlowIssues(id: string, options?: any): AxiosPromise<AccessFlowIssuesAppModel> {
            return localVarFp.appAccessFlowIssues(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary check if access bundle can be deleted and not contains active access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCanDeleteAccessBundle(id: string, options?: any): AxiosPromise<CanDeleteAccessBundleModel> {
            return localVarFp.appCanDeleteAccessBundle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {AccessFlowSetActiveAppRequest} accessFlowSetActiveAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appChangeAccessFlowActiveFlag(id: string, accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest, options?: any): AxiosPromise<AccessFlowAppModel> {
            return localVarFp.appChangeAccessFlowActiveFlag(id, accessFlowSetActiveAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {AccessFlowSetActiveAppRequest} accessFlowSetActiveAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appChangeAccessFlowActiveFlagV2(id: string, accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest, options?: any): AxiosPromise<AccessFlowAppModelV2> {
            return localVarFp.appChangeAccessFlowActiveFlagV2(id, accessFlowSetActiveAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create access bundle
         * @param {AccessBundleUpsertAppModel} accessBundleUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateAccessBundle(accessBundleUpsertAppModel: AccessBundleUpsertAppModel, options?: any): AxiosPromise<AccessBundleAppModel> {
            return localVarFp.appCreateAccessBundle(accessBundleUpsertAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create access flow
         * @param {AccessFlowUpsertAppModel} accessFlowUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateAccessFlow(accessFlowUpsertAppModel: AccessFlowUpsertAppModel, options?: any): AxiosPromise<AccessFlowAppModel> {
            return localVarFp.appCreateAccessFlow(accessFlowUpsertAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create access flow
         * @param {AccessFlowUpsertAppModelV2} accessFlowUpsertAppModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateAccessFlowV2(accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2, options?: any): AxiosPromise<AccessFlowAppModelV2> {
            return localVarFp.appCreateAccessFlowV2(accessFlowUpsertAppModelV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete access bundle
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteAccessBundle(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.appDeleteAccessBundle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteAccessFlow(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.appDeleteAccessFlow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteAccessFlowV2(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.appDeleteAccessFlowV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get access bundle
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetAccessBundle(id: string, options?: any): AxiosPromise<AccessBundleAppModel> {
            return localVarFp.appGetAccessBundle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetAccessFlow(id: string, options?: any): AxiosPromise<AccessFlowAppModel> {
            return localVarFp.appGetAccessFlow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetAccessFlowV2(id: string, options?: any): AxiosPromise<AccessFlowAppModelV2> {
            return localVarFp.appGetAccessFlowV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list access bundles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessBundles(options?: any): AxiosPromise<PaginatedResponseAccessBundleAppModel> {
            return localVarFp.appListAccessBundles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list access flows
         * @param {Array<string>} [accessFlowIds] 
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessFlows(accessFlowIds?: Array<string>, filters?: string, onlyActive?: boolean, options?: any): AxiosPromise<PaginatedAccessFlowAppSearchResponse> {
            return localVarFp.appListAccessFlows(accessFlowIds, filters, onlyActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessFlowFilterAppModel} filterName 
         * @param {number} [limit] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessFlowsFilterOptions(filterName: AccessFlowFilterAppModel, limit?: number, query?: string, options?: any): AxiosPromise<PaginatedResponseFilterValueAppModel> {
            return localVarFp.appListAccessFlowsFilterOptions(filterName, limit, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list access flows
         * @param {Array<string>} [accessFlowIds] 
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAccessFlowsV2(accessFlowIds?: Array<string>, filters?: string, onlyActive?: boolean, options?: any): AxiosPromise<PaginatedAccessFlowAppSearchResponseV2> {
            return localVarFp.appListAccessFlowsV2(accessFlowIds, filters, onlyActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update access bundle
         * @param {string} id 
         * @param {AccessBundleUpsertAppModel} accessBundleUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateAccessBundle(id: string, accessBundleUpsertAppModel: AccessBundleUpsertAppModel, options?: any): AxiosPromise<AccessBundleAppModel> {
            return localVarFp.appUpdateAccessBundle(id, accessBundleUpsertAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {AccessFlowUpsertAppModel} accessFlowUpsertAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateAccessFlow(id: string, accessFlowUpsertAppModel: AccessFlowUpsertAppModel, options?: any): AxiosPromise<AccessFlowAppModel> {
            return localVarFp.appUpdateAccessFlow(id, accessFlowUpsertAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {AccessFlowUpsertAppModelV2} accessFlowUpsertAppModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateAccessFlowV2(id: string, accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2, options?: any): AxiosPromise<AccessFlowAppModelV2> {
            return localVarFp.appUpdateAccessFlowV2(id, accessFlowUpsertAppModelV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary enable/disable access flow
         * @param {string} id 
         * @param {SetAccessFlowActiveFlagRequestV2} setAccessFlowActiveFlagRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccessFlowActiveFlag(id: string, setAccessFlowActiveFlagRequestV2: SetAccessFlowActiveFlagRequestV2, options?: any): AxiosPromise<AccessFlowModelV2> {
            return localVarFp.changeAccessFlowActiveFlag(id, setAccessFlowActiveFlagRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create access flow
         * @param {CreateAccessFlowRequestV2} createAccessFlowRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessFlow(createAccessFlowRequestV2: CreateAccessFlowRequestV2, options?: any): AxiosPromise<AccessFlowModelV2> {
            return localVarFp.createAccessFlow(createAccessFlowRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessFlow(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteAccessFlow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessFlow(id: string, options?: any): AxiosPromise<AccessFlowModelV2> {
            return localVarFp.getAccessFlow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFallbackPolicy(options?: any): AxiosPromise<FallbackPolicyApiModel> {
            return localVarFp.getFallbackPolicy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list access flows
         * @param {string} [filters] 
         * @param {boolean} [onlyActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessFlows(filters?: string, onlyActive?: boolean, options?: any): AxiosPromise<PaginatedAccessFlowSearchResponse> {
            return localVarFp.listAccessFlows(filters, onlyActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync auto grant access flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAutoGrantAccessFlow(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.syncAutoGrantAccessFlow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update access flow
         * @param {string} id 
         * @param {UpdateAccessFlowModelV2} updateAccessFlowModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessFlow(id: string, updateAccessFlowModelV2: UpdateAccessFlowModelV2, options?: any): AxiosPromise<AccessFlowModelV2> {
            return localVarFp.updateAccessFlow(id, updateAccessFlowModelV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FallbackPolicyApiModel} fallbackPolicyApiModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFallbackPolicy(fallbackPolicyApiModel: FallbackPolicyApiModel, options?: any): AxiosPromise<FallbackPolicyApiModel> {
            return localVarFp.updateFallbackPolicy(fallbackPolicyApiModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appAccessFlowIssues operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppAccessFlowIssuesRequest
 */
export interface AccessFlowsApiAppAccessFlowIssuesRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppAccessFlowIssues
     */
    readonly id: string
}

/**
 * Request parameters for appCanDeleteAccessBundle operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppCanDeleteAccessBundleRequest
 */
export interface AccessFlowsApiAppCanDeleteAccessBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppCanDeleteAccessBundle
     */
    readonly id: string
}

/**
 * Request parameters for appChangeAccessFlowActiveFlag operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppChangeAccessFlowActiveFlagRequest
 */
export interface AccessFlowsApiAppChangeAccessFlowActiveFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppChangeAccessFlowActiveFlag
     */
    readonly id: string

    /**
     * 
     * @type {AccessFlowSetActiveAppRequest}
     * @memberof AccessFlowsApiAppChangeAccessFlowActiveFlag
     */
    readonly accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest
}

/**
 * Request parameters for appChangeAccessFlowActiveFlagV2 operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppChangeAccessFlowActiveFlagV2Request
 */
export interface AccessFlowsApiAppChangeAccessFlowActiveFlagV2Request {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppChangeAccessFlowActiveFlagV2
     */
    readonly id: string

    /**
     * 
     * @type {AccessFlowSetActiveAppRequest}
     * @memberof AccessFlowsApiAppChangeAccessFlowActiveFlagV2
     */
    readonly accessFlowSetActiveAppRequest: AccessFlowSetActiveAppRequest
}

/**
 * Request parameters for appCreateAccessBundle operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppCreateAccessBundleRequest
 */
export interface AccessFlowsApiAppCreateAccessBundleRequest {
    /**
     * 
     * @type {AccessBundleUpsertAppModel}
     * @memberof AccessFlowsApiAppCreateAccessBundle
     */
    readonly accessBundleUpsertAppModel: AccessBundleUpsertAppModel
}

/**
 * Request parameters for appCreateAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppCreateAccessFlowRequest
 */
export interface AccessFlowsApiAppCreateAccessFlowRequest {
    /**
     * 
     * @type {AccessFlowUpsertAppModel}
     * @memberof AccessFlowsApiAppCreateAccessFlow
     */
    readonly accessFlowUpsertAppModel: AccessFlowUpsertAppModel
}

/**
 * Request parameters for appCreateAccessFlowV2 operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppCreateAccessFlowV2Request
 */
export interface AccessFlowsApiAppCreateAccessFlowV2Request {
    /**
     * 
     * @type {AccessFlowUpsertAppModelV2}
     * @memberof AccessFlowsApiAppCreateAccessFlowV2
     */
    readonly accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2
}

/**
 * Request parameters for appDeleteAccessBundle operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppDeleteAccessBundleRequest
 */
export interface AccessFlowsApiAppDeleteAccessBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppDeleteAccessBundle
     */
    readonly id: string
}

/**
 * Request parameters for appDeleteAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppDeleteAccessFlowRequest
 */
export interface AccessFlowsApiAppDeleteAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppDeleteAccessFlow
     */
    readonly id: string
}

/**
 * Request parameters for appDeleteAccessFlowV2 operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppDeleteAccessFlowV2Request
 */
export interface AccessFlowsApiAppDeleteAccessFlowV2Request {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppDeleteAccessFlowV2
     */
    readonly id: string
}

/**
 * Request parameters for appGetAccessBundle operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppGetAccessBundleRequest
 */
export interface AccessFlowsApiAppGetAccessBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppGetAccessBundle
     */
    readonly id: string
}

/**
 * Request parameters for appGetAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppGetAccessFlowRequest
 */
export interface AccessFlowsApiAppGetAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppGetAccessFlow
     */
    readonly id: string
}

/**
 * Request parameters for appGetAccessFlowV2 operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppGetAccessFlowV2Request
 */
export interface AccessFlowsApiAppGetAccessFlowV2Request {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppGetAccessFlowV2
     */
    readonly id: string
}

/**
 * Request parameters for appListAccessFlows operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppListAccessFlowsRequest
 */
export interface AccessFlowsApiAppListAccessFlowsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessFlowsApiAppListAccessFlows
     */
    readonly accessFlowIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppListAccessFlows
     */
    readonly filters?: string

    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowsApiAppListAccessFlows
     */
    readonly onlyActive?: boolean
}

/**
 * Request parameters for appListAccessFlowsFilterOptions operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppListAccessFlowsFilterOptionsRequest
 */
export interface AccessFlowsApiAppListAccessFlowsFilterOptionsRequest {
    /**
     * 
     * @type {AccessFlowFilterAppModel}
     * @memberof AccessFlowsApiAppListAccessFlowsFilterOptions
     */
    readonly filterName: AccessFlowFilterAppModel

    /**
     * 
     * @type {number}
     * @memberof AccessFlowsApiAppListAccessFlowsFilterOptions
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppListAccessFlowsFilterOptions
     */
    readonly query?: string
}

/**
 * Request parameters for appListAccessFlowsV2 operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppListAccessFlowsV2Request
 */
export interface AccessFlowsApiAppListAccessFlowsV2Request {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessFlowsApiAppListAccessFlowsV2
     */
    readonly accessFlowIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppListAccessFlowsV2
     */
    readonly filters?: string

    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowsApiAppListAccessFlowsV2
     */
    readonly onlyActive?: boolean
}

/**
 * Request parameters for appUpdateAccessBundle operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppUpdateAccessBundleRequest
 */
export interface AccessFlowsApiAppUpdateAccessBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppUpdateAccessBundle
     */
    readonly id: string

    /**
     * 
     * @type {AccessBundleUpsertAppModel}
     * @memberof AccessFlowsApiAppUpdateAccessBundle
     */
    readonly accessBundleUpsertAppModel: AccessBundleUpsertAppModel
}

/**
 * Request parameters for appUpdateAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppUpdateAccessFlowRequest
 */
export interface AccessFlowsApiAppUpdateAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppUpdateAccessFlow
     */
    readonly id: string

    /**
     * 
     * @type {AccessFlowUpsertAppModel}
     * @memberof AccessFlowsApiAppUpdateAccessFlow
     */
    readonly accessFlowUpsertAppModel: AccessFlowUpsertAppModel
}

/**
 * Request parameters for appUpdateAccessFlowV2 operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiAppUpdateAccessFlowV2Request
 */
export interface AccessFlowsApiAppUpdateAccessFlowV2Request {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiAppUpdateAccessFlowV2
     */
    readonly id: string

    /**
     * 
     * @type {AccessFlowUpsertAppModelV2}
     * @memberof AccessFlowsApiAppUpdateAccessFlowV2
     */
    readonly accessFlowUpsertAppModelV2: AccessFlowUpsertAppModelV2
}

/**
 * Request parameters for changeAccessFlowActiveFlag operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiChangeAccessFlowActiveFlagRequest
 */
export interface AccessFlowsApiChangeAccessFlowActiveFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiChangeAccessFlowActiveFlag
     */
    readonly id: string

    /**
     * 
     * @type {SetAccessFlowActiveFlagRequestV2}
     * @memberof AccessFlowsApiChangeAccessFlowActiveFlag
     */
    readonly setAccessFlowActiveFlagRequestV2: SetAccessFlowActiveFlagRequestV2
}

/**
 * Request parameters for createAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiCreateAccessFlowRequest
 */
export interface AccessFlowsApiCreateAccessFlowRequest {
    /**
     * 
     * @type {CreateAccessFlowRequestV2}
     * @memberof AccessFlowsApiCreateAccessFlow
     */
    readonly createAccessFlowRequestV2: CreateAccessFlowRequestV2
}

/**
 * Request parameters for deleteAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiDeleteAccessFlowRequest
 */
export interface AccessFlowsApiDeleteAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiDeleteAccessFlow
     */
    readonly id: string
}

/**
 * Request parameters for getAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiGetAccessFlowRequest
 */
export interface AccessFlowsApiGetAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiGetAccessFlow
     */
    readonly id: string
}

/**
 * Request parameters for listAccessFlows operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiListAccessFlowsRequest
 */
export interface AccessFlowsApiListAccessFlowsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiListAccessFlows
     */
    readonly filters?: string

    /**
     * 
     * @type {boolean}
     * @memberof AccessFlowsApiListAccessFlows
     */
    readonly onlyActive?: boolean
}

/**
 * Request parameters for syncAutoGrantAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiSyncAutoGrantAccessFlowRequest
 */
export interface AccessFlowsApiSyncAutoGrantAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiSyncAutoGrantAccessFlow
     */
    readonly id: string
}

/**
 * Request parameters for updateAccessFlow operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiUpdateAccessFlowRequest
 */
export interface AccessFlowsApiUpdateAccessFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessFlowsApiUpdateAccessFlow
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAccessFlowModelV2}
     * @memberof AccessFlowsApiUpdateAccessFlow
     */
    readonly updateAccessFlowModelV2: UpdateAccessFlowModelV2
}

/**
 * Request parameters for updateFallbackPolicy operation in AccessFlowsApi.
 * @export
 * @interface AccessFlowsApiUpdateFallbackPolicyRequest
 */
export interface AccessFlowsApiUpdateFallbackPolicyRequest {
    /**
     * 
     * @type {FallbackPolicyApiModel}
     * @memberof AccessFlowsApiUpdateFallbackPolicy
     */
    readonly fallbackPolicyApiModel: FallbackPolicyApiModel
}

/**
 * AccessFlowsApi - object-oriented interface
 * @export
 * @class AccessFlowsApi
 * @extends {BaseAPI}
 */
export class AccessFlowsApi extends BaseAPI {
    /**
     * 
     * @summary get delta of issues in access flow
     * @param {AccessFlowsApiAppAccessFlowIssuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appAccessFlowIssues(requestParameters: AccessFlowsApiAppAccessFlowIssuesRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appAccessFlowIssues(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary check if access bundle can be deleted and not contains active access flows
     * @param {AccessFlowsApiAppCanDeleteAccessBundleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appCanDeleteAccessBundle(requestParameters: AccessFlowsApiAppCanDeleteAccessBundleRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appCanDeleteAccessBundle(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary enable/disable access flow
     * @param {AccessFlowsApiAppChangeAccessFlowActiveFlagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appChangeAccessFlowActiveFlag(requestParameters: AccessFlowsApiAppChangeAccessFlowActiveFlagRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appChangeAccessFlowActiveFlag(requestParameters.id, requestParameters.accessFlowSetActiveAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary enable/disable access flow
     * @param {AccessFlowsApiAppChangeAccessFlowActiveFlagV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appChangeAccessFlowActiveFlagV2(requestParameters: AccessFlowsApiAppChangeAccessFlowActiveFlagV2Request, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appChangeAccessFlowActiveFlagV2(requestParameters.id, requestParameters.accessFlowSetActiveAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create access bundle
     * @param {AccessFlowsApiAppCreateAccessBundleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appCreateAccessBundle(requestParameters: AccessFlowsApiAppCreateAccessBundleRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appCreateAccessBundle(requestParameters.accessBundleUpsertAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create access flow
     * @param {AccessFlowsApiAppCreateAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appCreateAccessFlow(requestParameters: AccessFlowsApiAppCreateAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appCreateAccessFlow(requestParameters.accessFlowUpsertAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create access flow
     * @param {AccessFlowsApiAppCreateAccessFlowV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appCreateAccessFlowV2(requestParameters: AccessFlowsApiAppCreateAccessFlowV2Request, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appCreateAccessFlowV2(requestParameters.accessFlowUpsertAppModelV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete access bundle
     * @param {AccessFlowsApiAppDeleteAccessBundleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appDeleteAccessBundle(requestParameters: AccessFlowsApiAppDeleteAccessBundleRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appDeleteAccessBundle(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete access flow
     * @param {AccessFlowsApiAppDeleteAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appDeleteAccessFlow(requestParameters: AccessFlowsApiAppDeleteAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appDeleteAccessFlow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete access flow
     * @param {AccessFlowsApiAppDeleteAccessFlowV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appDeleteAccessFlowV2(requestParameters: AccessFlowsApiAppDeleteAccessFlowV2Request, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appDeleteAccessFlowV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get access bundle
     * @param {AccessFlowsApiAppGetAccessBundleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appGetAccessBundle(requestParameters: AccessFlowsApiAppGetAccessBundleRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appGetAccessBundle(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get access flow
     * @param {AccessFlowsApiAppGetAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appGetAccessFlow(requestParameters: AccessFlowsApiAppGetAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appGetAccessFlow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get access flow
     * @param {AccessFlowsApiAppGetAccessFlowV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appGetAccessFlowV2(requestParameters: AccessFlowsApiAppGetAccessFlowV2Request, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appGetAccessFlowV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list access bundles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appListAccessBundles(options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appListAccessBundles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list access flows
     * @param {AccessFlowsApiAppListAccessFlowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appListAccessFlows(requestParameters: AccessFlowsApiAppListAccessFlowsRequest = {}, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appListAccessFlows(requestParameters.accessFlowIds, requestParameters.filters, requestParameters.onlyActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessFlowsApiAppListAccessFlowsFilterOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appListAccessFlowsFilterOptions(requestParameters: AccessFlowsApiAppListAccessFlowsFilterOptionsRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appListAccessFlowsFilterOptions(requestParameters.filterName, requestParameters.limit, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list access flows
     * @param {AccessFlowsApiAppListAccessFlowsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appListAccessFlowsV2(requestParameters: AccessFlowsApiAppListAccessFlowsV2Request = {}, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appListAccessFlowsV2(requestParameters.accessFlowIds, requestParameters.filters, requestParameters.onlyActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update access bundle
     * @param {AccessFlowsApiAppUpdateAccessBundleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appUpdateAccessBundle(requestParameters: AccessFlowsApiAppUpdateAccessBundleRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appUpdateAccessBundle(requestParameters.id, requestParameters.accessBundleUpsertAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update access flow
     * @param {AccessFlowsApiAppUpdateAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appUpdateAccessFlow(requestParameters: AccessFlowsApiAppUpdateAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appUpdateAccessFlow(requestParameters.id, requestParameters.accessFlowUpsertAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update access flow
     * @param {AccessFlowsApiAppUpdateAccessFlowV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public appUpdateAccessFlowV2(requestParameters: AccessFlowsApiAppUpdateAccessFlowV2Request, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).appUpdateAccessFlowV2(requestParameters.id, requestParameters.accessFlowUpsertAppModelV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary enable/disable access flow
     * @param {AccessFlowsApiChangeAccessFlowActiveFlagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public changeAccessFlowActiveFlag(requestParameters: AccessFlowsApiChangeAccessFlowActiveFlagRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).changeAccessFlowActiveFlag(requestParameters.id, requestParameters.setAccessFlowActiveFlagRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create access flow
     * @param {AccessFlowsApiCreateAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public createAccessFlow(requestParameters: AccessFlowsApiCreateAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).createAccessFlow(requestParameters.createAccessFlowRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete access flow
     * @param {AccessFlowsApiDeleteAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public deleteAccessFlow(requestParameters: AccessFlowsApiDeleteAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).deleteAccessFlow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get access flow
     * @param {AccessFlowsApiGetAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public getAccessFlow(requestParameters: AccessFlowsApiGetAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).getAccessFlow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public getFallbackPolicy(options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).getFallbackPolicy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list access flows
     * @param {AccessFlowsApiListAccessFlowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public listAccessFlows(requestParameters: AccessFlowsApiListAccessFlowsRequest = {}, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).listAccessFlows(requestParameters.filters, requestParameters.onlyActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync auto grant access flow
     * @param {AccessFlowsApiSyncAutoGrantAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public syncAutoGrantAccessFlow(requestParameters: AccessFlowsApiSyncAutoGrantAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).syncAutoGrantAccessFlow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update access flow
     * @param {AccessFlowsApiUpdateAccessFlowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public updateAccessFlow(requestParameters: AccessFlowsApiUpdateAccessFlowRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).updateAccessFlow(requestParameters.id, requestParameters.updateAccessFlowModelV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessFlowsApiUpdateFallbackPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessFlowsApi
     */
    public updateFallbackPolicy(requestParameters: AccessFlowsApiUpdateFallbackPolicyRequest, options?: AxiosRequestConfig) {
        return AccessFlowsApiFp(this.configuration).updateFallbackPolicy(requestParameters.fallbackPolicyApiModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccessGraphApi - axios parameter creator
 * @export
 */
export const AccessGraphApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Resolve access graphs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResolvedAccessFlows: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/v1/access-graph/access-flows-resolved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resolve access graphs
         * @param {Array<string>} [integrationId] 
         * @param {number} [limit] 
         * @param {Array<string>} [requestId] 
         * @param {Array<string>} [resourceType] 
         * @param {number} [skip] 
         * @param {Array<string>} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResolvedAccessUnits: async (integrationId?: Array<string>, limit?: number, requestId?: Array<string>, resourceType?: Array<string>, skip?: number, userEmail?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/v1/access-graph/access-units-resolved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (integrationId) {
                localVarQueryParameter['integration_id'] = integrationId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (requestId) {
                localVarQueryParameter['request_id'] = requestId;
            }

            if (resourceType) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (userEmail) {
                localVarQueryParameter['user_email'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resolve access graphs
         * @param {AccessGraphUnitsSearchRequestModel} accessGraphUnitsSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchResolvedAccessUnits: async (accessGraphUnitsSearchRequestModel: AccessGraphUnitsSearchRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessGraphUnitsSearchRequestModel' is not null or undefined
            assertParamExists('searchResolvedAccessUnits', 'accessGraphUnitsSearchRequestModel', accessGraphUnitsSearchRequestModel)
            const localVarPath = `/apn/core/api/app/v1/access-graph/access-units-resolved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessGraphUnitsSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessGraphApi - functional programming interface
 * @export
 */
export const AccessGraphApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessGraphApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Resolve access graphs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listResolvedAccessFlows(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseResolvedAccessFlowModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listResolvedAccessFlows(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resolve access graphs
         * @param {Array<string>} [integrationId] 
         * @param {number} [limit] 
         * @param {Array<string>} [requestId] 
         * @param {Array<string>} [resourceType] 
         * @param {number} [skip] 
         * @param {Array<string>} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listResolvedAccessUnits(integrationId?: Array<string>, limit?: number, requestId?: Array<string>, resourceType?: Array<string>, skip?: number, userEmail?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAppModelAccessGraphUnitModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listResolvedAccessUnits(integrationId, limit, requestId, resourceType, skip, userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resolve access graphs
         * @param {AccessGraphUnitsSearchRequestModel} accessGraphUnitsSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchResolvedAccessUnits(accessGraphUnitsSearchRequestModel: AccessGraphUnitsSearchRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAppModelAccessGraphUnitModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchResolvedAccessUnits(accessGraphUnitsSearchRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessGraphApi - factory interface
 * @export
 */
export const AccessGraphApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessGraphApiFp(configuration)
    return {
        /**
         * 
         * @summary Resolve access graphs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResolvedAccessFlows(options?: any): AxiosPromise<PaginatedResponseResolvedAccessFlowModel> {
            return localVarFp.listResolvedAccessFlows(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resolve access graphs
         * @param {Array<string>} [integrationId] 
         * @param {number} [limit] 
         * @param {Array<string>} [requestId] 
         * @param {Array<string>} [resourceType] 
         * @param {number} [skip] 
         * @param {Array<string>} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResolvedAccessUnits(integrationId?: Array<string>, limit?: number, requestId?: Array<string>, resourceType?: Array<string>, skip?: number, userEmail?: Array<string>, options?: any): AxiosPromise<PaginatedResponseAppModelAccessGraphUnitModel> {
            return localVarFp.listResolvedAccessUnits(integrationId, limit, requestId, resourceType, skip, userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resolve access graphs
         * @param {AccessGraphUnitsSearchRequestModel} accessGraphUnitsSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchResolvedAccessUnits(accessGraphUnitsSearchRequestModel: AccessGraphUnitsSearchRequestModel, options?: any): AxiosPromise<PaginatedResponseAppModelAccessGraphUnitModel> {
            return localVarFp.searchResolvedAccessUnits(accessGraphUnitsSearchRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listResolvedAccessUnits operation in AccessGraphApi.
 * @export
 * @interface AccessGraphApiListResolvedAccessUnitsRequest
 */
export interface AccessGraphApiListResolvedAccessUnitsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphApiListResolvedAccessUnits
     */
    readonly integrationId?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AccessGraphApiListResolvedAccessUnits
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphApiListResolvedAccessUnits
     */
    readonly requestId?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphApiListResolvedAccessUnits
     */
    readonly resourceType?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AccessGraphApiListResolvedAccessUnits
     */
    readonly skip?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGraphApiListResolvedAccessUnits
     */
    readonly userEmail?: Array<string>
}

/**
 * Request parameters for searchResolvedAccessUnits operation in AccessGraphApi.
 * @export
 * @interface AccessGraphApiSearchResolvedAccessUnitsRequest
 */
export interface AccessGraphApiSearchResolvedAccessUnitsRequest {
    /**
     * 
     * @type {AccessGraphUnitsSearchRequestModel}
     * @memberof AccessGraphApiSearchResolvedAccessUnits
     */
    readonly accessGraphUnitsSearchRequestModel: AccessGraphUnitsSearchRequestModel
}

/**
 * AccessGraphApi - object-oriented interface
 * @export
 * @class AccessGraphApi
 * @extends {BaseAPI}
 */
export class AccessGraphApi extends BaseAPI {
    /**
     * 
     * @summary Resolve access graphs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGraphApi
     */
    public listResolvedAccessFlows(options?: AxiosRequestConfig) {
        return AccessGraphApiFp(this.configuration).listResolvedAccessFlows(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resolve access graphs
     * @param {AccessGraphApiListResolvedAccessUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGraphApi
     */
    public listResolvedAccessUnits(requestParameters: AccessGraphApiListResolvedAccessUnitsRequest = {}, options?: AxiosRequestConfig) {
        return AccessGraphApiFp(this.configuration).listResolvedAccessUnits(requestParameters.integrationId, requestParameters.limit, requestParameters.requestId, requestParameters.resourceType, requestParameters.skip, requestParameters.userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resolve access graphs
     * @param {AccessGraphApiSearchResolvedAccessUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGraphApi
     */
    public searchResolvedAccessUnits(requestParameters: AccessGraphApiSearchResolvedAccessUnitsRequest, options?: AxiosRequestConfig) {
        return AccessGraphApiFp(this.configuration).searchResolvedAccessUnits(requestParameters.accessGraphUnitsSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccessRequestsApi - axios parameter creator
 * @export
 */
export const AccessRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccessRequestModelV2} createAccessRequestModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRequestV2: async (createAccessRequestModelV2: CreateAccessRequestModelV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccessRequestModelV2' is not null or undefined
            assertParamExists('createAccessRequestV2', 'createAccessRequestModelV2', createAccessRequestModelV2)
            const localVarPath = `/apn/core/api/v2/access-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccessRequestModelV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeAccess: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revokeAccess', 'id', id)
            const localVarPath = `/apn/core/api/v2/access-requests/{id}/revoke`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke multiple access requests
         * @param {RevokeAccessRequestsAppModel} revokeAccessRequestsAppModel 
         * @param {boolean} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeAccessRequests: async (revokeAccessRequestsAppModel: RevokeAccessRequestsAppModel, dryRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeAccessRequestsAppModel' is not null or undefined
            assertParamExists('revokeAccessRequests', 'revokeAccessRequestsAppModel', revokeAccessRequestsAppModel)
            const localVarPath = `/apn/core/api/app/v1/access-requests-bulk/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dryRun !== undefined) {
                localVarQueryParameter['dry_run'] = dryRun;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeAccessRequestsAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessRequestsApi - functional programming interface
 * @export
 */
export const AccessRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAccessRequestModelV2} createAccessRequestModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessRequestV2(createAccessRequestModelV2: CreateAccessRequestModelV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessRequestV2(createAccessRequestModelV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeAccess(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeAccess(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revoke multiple access requests
         * @param {RevokeAccessRequestsAppModel} revokeAccessRequestsAppModel 
         * @param {boolean} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeAccessRequests(revokeAccessRequestsAppModel: RevokeAccessRequestsAppModel, dryRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRequestBulkActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeAccessRequests(revokeAccessRequestsAppModel, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessRequestsApi - factory interface
 * @export
 */
export const AccessRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAccessRequestModelV2} createAccessRequestModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRequestV2(createAccessRequestModelV2: CreateAccessRequestModelV2, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.createAccessRequestV2(createAccessRequestModelV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeAccess(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.revokeAccess(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke multiple access requests
         * @param {RevokeAccessRequestsAppModel} revokeAccessRequestsAppModel 
         * @param {boolean} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeAccessRequests(revokeAccessRequestsAppModel: RevokeAccessRequestsAppModel, dryRun?: boolean, options?: any): AxiosPromise<AccessRequestBulkActionResponse> {
            return localVarFp.revokeAccessRequests(revokeAccessRequestsAppModel, dryRun, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAccessRequestV2 operation in AccessRequestsApi.
 * @export
 * @interface AccessRequestsApiCreateAccessRequestV2Request
 */
export interface AccessRequestsApiCreateAccessRequestV2Request {
    /**
     * 
     * @type {CreateAccessRequestModelV2}
     * @memberof AccessRequestsApiCreateAccessRequestV2
     */
    readonly createAccessRequestModelV2: CreateAccessRequestModelV2
}

/**
 * Request parameters for revokeAccess operation in AccessRequestsApi.
 * @export
 * @interface AccessRequestsApiRevokeAccessRequest
 */
export interface AccessRequestsApiRevokeAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessRequestsApiRevokeAccess
     */
    readonly id: string
}

/**
 * Request parameters for revokeAccessRequests operation in AccessRequestsApi.
 * @export
 * @interface AccessRequestsApiRevokeAccessRequestsRequest
 */
export interface AccessRequestsApiRevokeAccessRequestsRequest {
    /**
     * 
     * @type {RevokeAccessRequestsAppModel}
     * @memberof AccessRequestsApiRevokeAccessRequests
     */
    readonly revokeAccessRequestsAppModel: RevokeAccessRequestsAppModel

    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestsApiRevokeAccessRequests
     */
    readonly dryRun?: boolean
}

/**
 * AccessRequestsApi - object-oriented interface
 * @export
 * @class AccessRequestsApi
 * @extends {BaseAPI}
 */
export class AccessRequestsApi extends BaseAPI {
    /**
     * 
     * @param {AccessRequestsApiCreateAccessRequestV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRequestsApi
     */
    public createAccessRequestV2(requestParameters: AccessRequestsApiCreateAccessRequestV2Request, options?: AxiosRequestConfig) {
        return AccessRequestsApiFp(this.configuration).createAccessRequestV2(requestParameters.createAccessRequestModelV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessRequestsApiRevokeAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRequestsApi
     */
    public revokeAccess(requestParameters: AccessRequestsApiRevokeAccessRequest, options?: AxiosRequestConfig) {
        return AccessRequestsApiFp(this.configuration).revokeAccess(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revoke multiple access requests
     * @param {AccessRequestsApiRevokeAccessRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRequestsApi
     */
    public revokeAccessRequests(requestParameters: AccessRequestsApiRevokeAccessRequestsRequest, options?: AxiosRequestConfig) {
        return AccessRequestsApiFp(this.configuration).revokeAccessRequests(requestParameters.revokeAccessRequestsAppModel, requestParameters.dryRun, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [userEmail] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroupMember: async (id: string, userEmail?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addGroupMember', 'id', id)
            const localVarPath = `/apn/core/api/v1/groups/{id}/add-member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userEmail !== undefined) {
                localVarQueryParameter['user_email'] = userEmail;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [attributeId] 
         * @param {boolean} [includeAttributes] 
         * @param {number} [limit] 
         * @param {Array<string>} [role] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIdentities: async (attributeId?: Array<string>, includeAttributes?: boolean, limit?: number, role?: Array<string>, search?: string, skip?: number, userId?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/identities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (attributeId) {
                localVarQueryParameter['attribute_id'] = attributeId;
            }

            if (includeAttributes !== undefined) {
                localVarQueryParameter['include_attributes'] = includeAttributes;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (role) {
                localVarQueryParameter['role'] = role;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (userId) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AponoGroupModelCreateUpdateRequest} aponoGroupModelCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aponoGroupModelCreateUpdateRequest' is not null or undefined
            assertParamExists('createGroup', 'aponoGroupModelCreateUpdateRequest', aponoGroupModelCreateUpdateRequest)
            const localVarPath = `/apn/core/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aponoGroupModelCreateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAponoUserRequest} createAponoUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createAponoUserRequest: CreateAponoUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAponoUserRequest' is not null or undefined
            assertParamExists('createUser', 'createAponoUserRequest', createAponoUserRequest)
            const localVarPath = `/apn/core/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAponoUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserPersonalTokenRequest} createUserPersonalTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserToken: async (createUserPersonalTokenRequest: CreateUserPersonalTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserPersonalTokenRequest' is not null or undefined
            assertParamExists('createUserToken', 'createUserPersonalTokenRequest', createUserPersonalTokenRequest)
            const localVarPath = `/apn/core/api/v1/settings/personal-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserPersonalTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnectorToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('deleteConnectorToken', 'token', token)
            const localVarPath = `/apn/core/api/v1/accounts/agent-tokens/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroup', 'id', id)
            const localVarPath = `/apn/core/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/apn/core/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserToken', 'id', id)
            const localVarPath = `/apn/core/api/v1/settings/personal-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary finish account onboarding funnel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOnboarding: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/finish-onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateConnectorToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/agent-tokens/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDiscoveryStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/discovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroup', 'id', id)
            const localVarPath = `/apn/core/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/identities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/apn/core/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserToken', 'id', id)
            const localVarPath = `/apn/core/api/v1/settings/personal-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/users-detailed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove account getting started steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideGettingStarted: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/agent-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroupMembers: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listGroupMembers', 'id', id)
            const localVarPath = `/apn/core/api/v1/groups/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserGroups: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listUserGroups', 'id', id)
            const localVarPath = `/apn/core/api/v1/users/{id}/groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/settings/personal-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAccountDiscovery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/accounts/discovery/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountSettingsModel} updateAccountSettingsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountSettings: async (updateAccountSettingsModel: UpdateAccountSettingsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountSettingsModel' is not null or undefined
            assertParamExists('updateAccountSettings', 'updateAccountSettingsModel', updateAccountSettingsModel)
            const localVarPath = `/apn/core/api/v1/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountSettingsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AponoGroupModelCreateUpdateRequest} aponoGroupModelCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (id: string, aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroup', 'id', id)
            // verify required parameter 'aponoGroupModelCreateUpdateRequest' is not null or undefined
            assertParamExists('updateGroup', 'aponoGroupModelCreateUpdateRequest', aponoGroupModelCreateUpdateRequest)
            const localVarPath = `/apn/core/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aponoGroupModelCreateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GroupMembersModel} groupMembersModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupMembers: async (id: string, groupMembersModel: GroupMembersModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroupMembers', 'id', id)
            // verify required parameter 'groupMembersModel' is not null or undefined
            assertParamExists('updateGroupMembers', 'groupMembersModel', groupMembersModel)
            const localVarPath = `/apn/core/api/v1/groups/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupMembersModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AponoUserModel} aponoUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, aponoUserModel: AponoUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'aponoUserModel' is not null or undefined
            assertParamExists('updateUser', 'aponoUserModel', aponoUserModel)
            const localVarPath = `/apn/core/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aponoUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserGroupsModel} userGroupsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGroups: async (id: string, userGroupsModel: UserGroupsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserGroups', 'id', id)
            // verify required parameter 'userGroupsModel' is not null or undefined
            assertParamExists('updateUserGroups', 'userGroupsModel', userGroupsModel)
            const localVarPath = `/apn/core/api/v1/users/{id}/groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateExpiryRequest} updateExpiryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTokenExpiry: async (id: string, updateExpiryRequest: UpdateExpiryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserTokenExpiry', 'id', id)
            // verify required parameter 'updateExpiryRequest' is not null or undefined
            assertParamExists('updateUserTokenExpiry', 'updateExpiryRequest', updateExpiryRequest)
            const localVarPath = `/apn/core/api/v1/settings/personal-tokens/{id}/expiry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpiryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [userEmail] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGroupMember(id: string, userEmail?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGroupMember(id, userEmail, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [attributeId] 
         * @param {boolean} [includeAttributes] 
         * @param {number} [limit] 
         * @param {Array<string>} [role] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIdentities(attributeId?: Array<string>, includeAttributes?: boolean, limit?: number, role?: Array<string>, search?: string, skip?: number, userId?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseUserIdentityAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIdentities(attributeId, includeAttributes, limit, role, search, skip, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AponoGroupModelCreateUpdateRequest} aponoGroupModelCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AponoGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(aponoGroupModelCreateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAponoUserRequest} createAponoUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createAponoUserRequest: CreateAponoUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AponoUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createAponoUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserPersonalTokenRequest} createUserPersonalTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserToken(createUserPersonalTokenRequest: CreateUserPersonalTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unmasked>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserToken(createUserPersonalTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnectorToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnectorToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary finish account onboarding funnel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishOnboarding(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishOnboarding(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateConnectorToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateConnectorToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountDiscoveryStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveryStatusApiModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountDiscoveryStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AponoGroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitiesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AponoUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPersonalToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDetailedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove account getting started steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideGettingStarted(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideGettingStarted(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGroupMembers(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupMembersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGroupMembers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AponoGroupModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserGroups(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserGroups(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPersonalToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AponoUserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerAccountDiscovery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerAccountDiscovery(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountSettingsModel} updateAccountSettingsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountSettings(updateAccountSettingsModel: UpdateAccountSettingsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountSettings(updateAccountSettingsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AponoGroupModelCreateUpdateRequest} aponoGroupModelCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(id: string, aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AponoGroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(id, aponoGroupModelCreateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {GroupMembersModel} groupMembersModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupMembers(id: string, groupMembersModel: GroupMembersModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupMembersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupMembers(id, groupMembersModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AponoUserModel} aponoUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, aponoUserModel: AponoUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AponoUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, aponoUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserGroupsModel} userGroupsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserGroups(id: string, userGroupsModel: UserGroupsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserGroups(id, userGroupsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateExpiryRequest} updateExpiryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserTokenExpiry(id: string, updateExpiryRequest: UpdateExpiryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unmasked>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserTokenExpiry(id, updateExpiryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [userEmail] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroupMember(id: string, userEmail?: string, userId?: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.addGroupMember(id, userEmail, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [attributeId] 
         * @param {boolean} [includeAttributes] 
         * @param {number} [limit] 
         * @param {Array<string>} [role] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIdentities(attributeId?: Array<string>, includeAttributes?: boolean, limit?: number, role?: Array<string>, search?: string, skip?: number, userId?: Array<string>, options?: any): AxiosPromise<PaginatedResponseUserIdentityAppModel> {
            return localVarFp.appGetIdentities(attributeId, includeAttributes, limit, role, search, skip, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AponoGroupModelCreateUpdateRequest} aponoGroupModelCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest, options?: any): AxiosPromise<AponoGroup> {
            return localVarFp.createGroup(aponoGroupModelCreateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAponoUserRequest} createAponoUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createAponoUserRequest: CreateAponoUserRequest, options?: any): AxiosPromise<AponoUserModel> {
            return localVarFp.createUser(createAponoUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserPersonalTokenRequest} createUserPersonalTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserToken(createUserPersonalTokenRequest: CreateUserPersonalTokenRequest, options?: any): AxiosPromise<Unmasked> {
            return localVarFp.createUserToken(createUserPersonalTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnectorToken(token: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConnectorToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserToken(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteUserToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary finish account onboarding funnel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOnboarding(options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.finishOnboarding(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateConnectorToken(options?: any): AxiosPromise<string> {
            return localVarFp.generateConnectorToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDiscoveryStatus(options?: any): AxiosPromise<DiscoveryStatusApiModel> {
            return localVarFp.getAccountDiscoveryStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo(options?: any): AxiosPromise<AccountModel> {
            return localVarFp.getAccountInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSettings(options?: any): AxiosPromise<AccountSettingsModel> {
            return localVarFp.getAccountSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatus(options?: any): AxiosPromise<AccountModel> {
            return localVarFp.getAccountStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(id: string, options?: any): AxiosPromise<AponoGroupModel> {
            return localVarFp.getGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentities(options?: any): AxiosPromise<IdentitiesModel> {
            return localVarFp.getIdentities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<AponoUserModel> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserToken(id: string, options?: any): AxiosPromise<UserPersonalToken> {
            return localVarFp.getUserToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersDetails(options?: any): AxiosPromise<Array<UserDetailedModel>> {
            return localVarFp.getUsersDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove account getting started steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideGettingStarted(options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.hideGettingStarted(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorTokens(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listConnectorTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroupMembers(id: string, options?: any): AxiosPromise<GroupMembersModel> {
            return localVarFp.listGroupMembers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(options?: any): AxiosPromise<Array<AponoGroupModel>> {
            return localVarFp.listGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserGroups(id: string, options?: any): AxiosPromise<UserGroupsModel> {
            return localVarFp.listUserGroups(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserTokens(options?: any): AxiosPromise<Array<UserPersonalToken>> {
            return localVarFp.listUserTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options?: any): AxiosPromise<Array<AponoUserModel>> {
            return localVarFp.listUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAccountDiscovery(options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.triggerAccountDiscovery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountSettingsModel} updateAccountSettingsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountSettings(updateAccountSettingsModel: UpdateAccountSettingsModel, options?: any): AxiosPromise<AccountSettingsModel> {
            return localVarFp.updateAccountSettings(updateAccountSettingsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AponoGroupModelCreateUpdateRequest} aponoGroupModelCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(id: string, aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest, options?: any): AxiosPromise<AponoGroupModel> {
            return localVarFp.updateGroup(id, aponoGroupModelCreateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {GroupMembersModel} groupMembersModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupMembers(id: string, groupMembersModel: GroupMembersModel, options?: any): AxiosPromise<GroupMembersModel> {
            return localVarFp.updateGroupMembers(id, groupMembersModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AponoUserModel} aponoUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, aponoUserModel: AponoUserModel, options?: any): AxiosPromise<AponoUserModel> {
            return localVarFp.updateUser(id, aponoUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserGroupsModel} userGroupsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGroups(id: string, userGroupsModel: UserGroupsModel, options?: any): AxiosPromise<UserGroupsModel> {
            return localVarFp.updateUserGroups(id, userGroupsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateExpiryRequest} updateExpiryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTokenExpiry(id: string, updateExpiryRequest: UpdateExpiryRequest, options?: any): AxiosPromise<Unmasked> {
            return localVarFp.updateUserTokenExpiry(id, updateExpiryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addGroupMember operation in AccountsApi.
 * @export
 * @interface AccountsApiAddGroupMemberRequest
 */
export interface AccountsApiAddGroupMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAddGroupMember
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAddGroupMember
     */
    readonly userEmail?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAddGroupMember
     */
    readonly userId?: string
}

/**
 * Request parameters for appGetIdentities operation in AccountsApi.
 * @export
 * @interface AccountsApiAppGetIdentitiesRequest
 */
export interface AccountsApiAppGetIdentitiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly attributeId?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly includeAttributes?: boolean

    /**
     * 
     * @type {number}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly role?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly skip?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountsApiAppGetIdentities
     */
    readonly userId?: Array<string>
}

/**
 * Request parameters for createGroup operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateGroupRequest
 */
export interface AccountsApiCreateGroupRequest {
    /**
     * 
     * @type {AponoGroupModelCreateUpdateRequest}
     * @memberof AccountsApiCreateGroup
     */
    readonly aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest
}

/**
 * Request parameters for createUser operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateUserRequest
 */
export interface AccountsApiCreateUserRequest {
    /**
     * 
     * @type {CreateAponoUserRequest}
     * @memberof AccountsApiCreateUser
     */
    readonly createAponoUserRequest: CreateAponoUserRequest
}

/**
 * Request parameters for createUserToken operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateUserTokenRequest
 */
export interface AccountsApiCreateUserTokenRequest {
    /**
     * 
     * @type {CreateUserPersonalTokenRequest}
     * @memberof AccountsApiCreateUserToken
     */
    readonly createUserPersonalTokenRequest: CreateUserPersonalTokenRequest
}

/**
 * Request parameters for deleteConnectorToken operation in AccountsApi.
 * @export
 * @interface AccountsApiDeleteConnectorTokenRequest
 */
export interface AccountsApiDeleteConnectorTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiDeleteConnectorToken
     */
    readonly token: string
}

/**
 * Request parameters for deleteGroup operation in AccountsApi.
 * @export
 * @interface AccountsApiDeleteGroupRequest
 */
export interface AccountsApiDeleteGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiDeleteGroup
     */
    readonly id: string
}

/**
 * Request parameters for deleteUser operation in AccountsApi.
 * @export
 * @interface AccountsApiDeleteUserRequest
 */
export interface AccountsApiDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiDeleteUser
     */
    readonly id: string
}

/**
 * Request parameters for deleteUserToken operation in AccountsApi.
 * @export
 * @interface AccountsApiDeleteUserTokenRequest
 */
export interface AccountsApiDeleteUserTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiDeleteUserToken
     */
    readonly id: string
}

/**
 * Request parameters for getGroup operation in AccountsApi.
 * @export
 * @interface AccountsApiGetGroupRequest
 */
export interface AccountsApiGetGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetGroup
     */
    readonly id: string
}

/**
 * Request parameters for getUser operation in AccountsApi.
 * @export
 * @interface AccountsApiGetUserRequest
 */
export interface AccountsApiGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetUser
     */
    readonly id: string
}

/**
 * Request parameters for getUserToken operation in AccountsApi.
 * @export
 * @interface AccountsApiGetUserTokenRequest
 */
export interface AccountsApiGetUserTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetUserToken
     */
    readonly id: string
}

/**
 * Request parameters for listGroupMembers operation in AccountsApi.
 * @export
 * @interface AccountsApiListGroupMembersRequest
 */
export interface AccountsApiListGroupMembersRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiListGroupMembers
     */
    readonly id: string
}

/**
 * Request parameters for listUserGroups operation in AccountsApi.
 * @export
 * @interface AccountsApiListUserGroupsRequest
 */
export interface AccountsApiListUserGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiListUserGroups
     */
    readonly id: string
}

/**
 * Request parameters for updateAccountSettings operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateAccountSettingsRequest
 */
export interface AccountsApiUpdateAccountSettingsRequest {
    /**
     * 
     * @type {UpdateAccountSettingsModel}
     * @memberof AccountsApiUpdateAccountSettings
     */
    readonly updateAccountSettingsModel: UpdateAccountSettingsModel
}

/**
 * Request parameters for updateGroup operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateGroupRequest
 */
export interface AccountsApiUpdateGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateGroup
     */
    readonly id: string

    /**
     * 
     * @type {AponoGroupModelCreateUpdateRequest}
     * @memberof AccountsApiUpdateGroup
     */
    readonly aponoGroupModelCreateUpdateRequest: AponoGroupModelCreateUpdateRequest
}

/**
 * Request parameters for updateGroupMembers operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateGroupMembersRequest
 */
export interface AccountsApiUpdateGroupMembersRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateGroupMembers
     */
    readonly id: string

    /**
     * 
     * @type {GroupMembersModel}
     * @memberof AccountsApiUpdateGroupMembers
     */
    readonly groupMembersModel: GroupMembersModel
}

/**
 * Request parameters for updateUser operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateUserRequest
 */
export interface AccountsApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateUser
     */
    readonly id: string

    /**
     * 
     * @type {AponoUserModel}
     * @memberof AccountsApiUpdateUser
     */
    readonly aponoUserModel: AponoUserModel
}

/**
 * Request parameters for updateUserGroups operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateUserGroupsRequest
 */
export interface AccountsApiUpdateUserGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateUserGroups
     */
    readonly id: string

    /**
     * 
     * @type {UserGroupsModel}
     * @memberof AccountsApiUpdateUserGroups
     */
    readonly userGroupsModel: UserGroupsModel
}

/**
 * Request parameters for updateUserTokenExpiry operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateUserTokenExpiryRequest
 */
export interface AccountsApiUpdateUserTokenExpiryRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateUserTokenExpiry
     */
    readonly id: string

    /**
     * 
     * @type {UpdateExpiryRequest}
     * @memberof AccountsApiUpdateUserTokenExpiry
     */
    readonly updateExpiryRequest: UpdateExpiryRequest
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {AccountsApiAddGroupMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public addGroupMember(requestParameters: AccountsApiAddGroupMemberRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).addGroupMember(requestParameters.id, requestParameters.userEmail, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAppGetIdentitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public appGetIdentities(requestParameters: AccountsApiAppGetIdentitiesRequest = {}, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).appGetIdentities(requestParameters.attributeId, requestParameters.includeAttributes, requestParameters.limit, requestParameters.role, requestParameters.search, requestParameters.skip, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiCreateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createGroup(requestParameters: AccountsApiCreateGroupRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createGroup(requestParameters.aponoGroupModelCreateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createUser(requestParameters: AccountsApiCreateUserRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createUser(requestParameters.createAponoUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiCreateUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createUserToken(requestParameters: AccountsApiCreateUserTokenRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createUserToken(requestParameters.createUserPersonalTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiDeleteConnectorTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteConnectorToken(requestParameters: AccountsApiDeleteConnectorTokenRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteConnectorToken(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiDeleteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteGroup(requestParameters: AccountsApiDeleteGroupRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteUser(requestParameters: AccountsApiDeleteUserRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiDeleteUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteUserToken(requestParameters: AccountsApiDeleteUserTokenRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteUserToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary finish account onboarding funnel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public finishOnboarding(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).finishOnboarding(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public generateConnectorToken(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).generateConnectorToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccountDiscoveryStatus(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getAccountDiscoveryStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccountInfo(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getAccountInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccountSettings(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getAccountSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccountStatus(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getAccountStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiGetGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getGroup(requestParameters: AccountsApiGetGroupRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getIdentities(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getIdentities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getUser(requestParameters: AccountsApiGetUserRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiGetUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getUserToken(requestParameters: AccountsApiGetUserTokenRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getUserToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getUsersDetails(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getUsersDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove account getting started steps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public hideGettingStarted(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).hideGettingStarted(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listConnectorTokens(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listConnectorTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiListGroupMembersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listGroupMembers(requestParameters: AccountsApiListGroupMembersRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listGroupMembers(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listGroups(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiListUserGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listUserGroups(requestParameters: AccountsApiListUserGroupsRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listUserGroups(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listUserTokens(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listUserTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listUsers(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public triggerAccountDiscovery(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).triggerAccountDiscovery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiUpdateAccountSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateAccountSettings(requestParameters: AccountsApiUpdateAccountSettingsRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateAccountSettings(requestParameters.updateAccountSettingsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiUpdateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateGroup(requestParameters: AccountsApiUpdateGroupRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateGroup(requestParameters.id, requestParameters.aponoGroupModelCreateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiUpdateGroupMembersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateGroupMembers(requestParameters: AccountsApiUpdateGroupMembersRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateGroupMembers(requestParameters.id, requestParameters.groupMembersModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateUser(requestParameters: AccountsApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateUser(requestParameters.id, requestParameters.aponoUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiUpdateUserGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateUserGroups(requestParameters: AccountsApiUpdateUserGroupsRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateUserGroups(requestParameters.id, requestParameters.userGroupsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiUpdateUserTokenExpiryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateUserTokenExpiry(requestParameters: AccountsApiUpdateUserTokenExpiryRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateUserTokenExpiry(requestParameters.id, requestParameters.updateExpiryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActiveAccessApi - axios parameter creator
 * @export
 */
export const ActiveAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAccessFilterOptions: async (integrationId: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getActiveAccessFilterOptions', 'integrationId', integrationId)
            const localVarPath = `/apn/core/api/v1/active-access/{integration_id}/filters`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAccessFilterTypes: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getActiveAccessFilterTypes', 'integrationId', integrationId)
            const localVarPath = `/apn/core/api/v1/active-access/{integration_id}/filters/types`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {FilterActiveAccessRequest} filterActiveAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActiveAccess: async (integrationId: string, filterActiveAccessRequest: FilterActiveAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('searchActiveAccess', 'integrationId', integrationId)
            // verify required parameter 'filterActiveAccessRequest' is not null or undefined
            assertParamExists('searchActiveAccess', 'filterActiveAccessRequest', filterActiveAccessRequest)
            const localVarPath = `/apn/core/api/v1/active-access/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterActiveAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActiveAccessApi - functional programming interface
 * @export
 */
export const ActiveAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActiveAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAccessFilterOptions(integrationId: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveAccessFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAccessFilterOptions(integrationId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAccessFilterTypes(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAccessFilterTypes(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {FilterActiveAccessRequest} filterActiveAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActiveAccess(integrationId: string, filterActiveAccessRequest: FilterActiveAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActiveAccess(integrationId, filterActiveAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActiveAccessApi - factory interface
 * @export
 */
export const ActiveAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActiveAccessApiFp(configuration)
    return {
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAccessFilterOptions(integrationId: string, type?: string, options?: any): AxiosPromise<ActiveAccessFiltersResponse> {
            return localVarFp.getActiveAccessFilterOptions(integrationId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAccessFilterTypes(integrationId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getActiveAccessFilterTypes(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {FilterActiveAccessRequest} filterActiveAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActiveAccess(integrationId: string, filterActiveAccessRequest: FilterActiveAccessRequest, options?: any): AxiosPromise<ActiveAccessResponse> {
            return localVarFp.searchActiveAccess(integrationId, filterActiveAccessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getActiveAccessFilterOptions operation in ActiveAccessApi.
 * @export
 * @interface ActiveAccessApiGetActiveAccessFilterOptionsRequest
 */
export interface ActiveAccessApiGetActiveAccessFilterOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessApiGetActiveAccessFilterOptions
     */
    readonly integrationId: string

    /**
     * 
     * @type {string}
     * @memberof ActiveAccessApiGetActiveAccessFilterOptions
     */
    readonly type?: string
}

/**
 * Request parameters for getActiveAccessFilterTypes operation in ActiveAccessApi.
 * @export
 * @interface ActiveAccessApiGetActiveAccessFilterTypesRequest
 */
export interface ActiveAccessApiGetActiveAccessFilterTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessApiGetActiveAccessFilterTypes
     */
    readonly integrationId: string
}

/**
 * Request parameters for searchActiveAccess operation in ActiveAccessApi.
 * @export
 * @interface ActiveAccessApiSearchActiveAccessRequest
 */
export interface ActiveAccessApiSearchActiveAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof ActiveAccessApiSearchActiveAccess
     */
    readonly integrationId: string

    /**
     * 
     * @type {FilterActiveAccessRequest}
     * @memberof ActiveAccessApiSearchActiveAccess
     */
    readonly filterActiveAccessRequest: FilterActiveAccessRequest
}

/**
 * ActiveAccessApi - object-oriented interface
 * @export
 * @class ActiveAccessApi
 * @extends {BaseAPI}
 */
export class ActiveAccessApi extends BaseAPI {
    /**
     * 
     * @param {ActiveAccessApiGetActiveAccessFilterOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveAccessApi
     */
    public getActiveAccessFilterOptions(requestParameters: ActiveAccessApiGetActiveAccessFilterOptionsRequest, options?: AxiosRequestConfig) {
        return ActiveAccessApiFp(this.configuration).getActiveAccessFilterOptions(requestParameters.integrationId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActiveAccessApiGetActiveAccessFilterTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveAccessApi
     */
    public getActiveAccessFilterTypes(requestParameters: ActiveAccessApiGetActiveAccessFilterTypesRequest, options?: AxiosRequestConfig) {
        return ActiveAccessApiFp(this.configuration).getActiveAccessFilterTypes(requestParameters.integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActiveAccessApiSearchActiveAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveAccessApi
     */
    public searchActiveAccess(requestParameters: ActiveAccessApiSearchActiveAccessRequest, options?: AxiosRequestConfig) {
        return ActiveAccessApiFp(this.configuration).searchActiveAccess(requestParameters.integrationId, requestParameters.filterActiveAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetActivityRequest', 'id', id)
            const localVarPath = `/apn/core/api/app/activity/access-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {Array<string>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequestAccessUnitGroups: async (id: string, limit?: number, skip?: number, status?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetActivityRequestAccessUnitGroups', 'id', id)
            const localVarPath = `/apn/core/api/app/activity/access-requests/{id}/access-unit-groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [integrationId] 
         * @param {number} [limit] 
         * @param {Array<string>} [resourceType] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequestAccessUnits: async (id: string, integrationId?: Array<string>, limit?: number, resourceType?: Array<string>, search?: string, skip?: number, status?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetActivityRequestAccessUnits', 'id', id)
            const localVarPath = `/apn/core/api/app/activity/access-requests/{id}/access-units`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (integrationId) {
                localVarQueryParameter['integration_id'] = integrationId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceType) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequestTimeline: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetActivityRequestTimeline', 'id', id)
            const localVarPath = `/apn/core/api/app/activity/access-requests/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListRequestAccessUnitsRequest} listRequestAccessUnitsRequest 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListActivityAccessUnits: async (listRequestAccessUnitsRequest: ListRequestAccessUnitsRequest, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listRequestAccessUnitsRequest' is not null or undefined
            assertParamExists('appListActivityAccessUnits', 'listRequestAccessUnitsRequest', listRequestAccessUnitsRequest)
            const localVarPath = `/apn/core/api/app/activity/requests-access-units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listRequestAccessUnitsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {string} [filterAccessFlowId] 
         * @param {string} [filterAccessRequestId] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListActivityRequests: async (endDate?: number, filterAccessFlowId?: string, filterAccessRequestId?: string, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, limit?: number, offset?: number, startDate?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/activity/access-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (filterAccessFlowId !== undefined) {
                localVarQueryParameter['filter[access_flow_id]'] = filterAccessFlowId;
            }

            if (filterAccessRequestId !== undefined) {
                localVarQueryParameter['filter[access_request_id]'] = filterAccessRequestId;
            }

            if (filterIntegrationId !== undefined) {
                localVarQueryParameter['filter[integration_id]'] = filterIntegrationId;
            }

            if (filterPermission !== undefined) {
                localVarQueryParameter['filter[permission]'] = filterPermission;
            }

            if (filterRequestorId !== undefined) {
                localVarQueryParameter['filter[requestor_id]'] = filterRequestorId;
            }

            if (filterResource !== undefined) {
                localVarQueryParameter['filter[resource]'] = filterResource;
            }

            if (filterResourceType !== undefined) {
                localVarQueryParameter['filter[resource_type]'] = filterResourceType;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }

            if (filterTriggerType !== undefined) {
                localVarQueryParameter['filter[trigger_type]'] = filterTriggerType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyActivityReportModel} modifyActivityReportModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportTemplate: async (modifyActivityReportModel: ModifyActivityReportModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyActivityReportModel' is not null or undefined
            assertParamExists('createReportTemplate', 'modifyActivityReportModel', modifyActivityReportModel)
            const localVarPath = `/apn/core/api/v1/activity/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyActivityReportModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReportTemplate', 'id', id)
            const localVarPath = `/apn/core/api/v1/activity/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {Array<ReportField>} [fields] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFile: async (endDate?: number, fields?: Array<ReportField>, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, startDate?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/activity/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            if (filterIntegrationId !== undefined) {
                localVarQueryParameter['filter[integration_id]'] = filterIntegrationId;
            }

            if (filterPermission !== undefined) {
                localVarQueryParameter['filter[permission]'] = filterPermission;
            }

            if (filterRequestorId !== undefined) {
                localVarQueryParameter['filter[requestor_id]'] = filterRequestorId;
            }

            if (filterResource !== undefined) {
                localVarQueryParameter['filter[resource]'] = filterResource;
            }

            if (filterResourceType !== undefined) {
                localVarQueryParameter['filter[resource_type]'] = filterResourceType;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }

            if (filterTriggerType !== undefined) {
                localVarQueryParameter['filter[triggerType]'] = filterTriggerType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFromStoredTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportFromStoredTemplate', 'id', id)
            const localVarPath = `/apn/core/api/v1/activity/reports/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {Array<ReportField>} [fields] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportPreview: async (endDate?: number, fields?: Array<ReportField>, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, startDate?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/activity/report/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            if (filterIntegrationId !== undefined) {
                localVarQueryParameter['filter[integration_id]'] = filterIntegrationId;
            }

            if (filterPermission !== undefined) {
                localVarQueryParameter['filter[permission]'] = filterPermission;
            }

            if (filterRequestorId !== undefined) {
                localVarQueryParameter['filter[requestor_id]'] = filterRequestorId;
            }

            if (filterResource !== undefined) {
                localVarQueryParameter['filter[resource]'] = filterResource;
            }

            if (filterResourceType !== undefined) {
                localVarQueryParameter['filter[resource_type]'] = filterResourceType;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }

            if (filterTriggerType !== undefined) {
                localVarQueryParameter['filter[triggerType]'] = filterTriggerType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportTemplate', 'id', id)
            const localVarPath = `/apn/core/api/v1/activity/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivityFilter} filterName 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivityFilterOptions: async (filterName: ActivityFilter, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterName' is not null or undefined
            assertParamExists('listActivityFilterOptions', 'filterName', filterName)
            const localVarPath = `/apn/core/api/v1/activity/autocomplete/{filter_name}`
                .replace(`{${"filter_name"}}`, encodeURIComponent(String(filterName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/activity/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncReactiveAccessRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncReactiveAccessRequest', 'id', id)
            const localVarPath = `/apn/core/api/app/activity/access-requests/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ModifyActivityReportModel} modifyActivityReportModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportTemplate: async (id: string, modifyActivityReportModel: ModifyActivityReportModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReportTemplate', 'id', id)
            // verify required parameter 'modifyActivityReportModel' is not null or undefined
            assertParamExists('updateReportTemplate', 'modifyActivityReportModel', modifyActivityReportModel)
            const localVarPath = `/apn/core/api/v1/activity/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyActivityReportModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetActivityRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityRequestAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetActivityRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {Array<string>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetActivityRequestAccessUnitGroups(id: string, limit?: number, skip?: number, status?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAccessUnitsGroupAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetActivityRequestAccessUnitGroups(id, limit, skip, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [integrationId] 
         * @param {number} [limit] 
         * @param {Array<string>} [resourceType] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetActivityRequestAccessUnits(id: string, integrationId?: Array<string>, limit?: number, resourceType?: Array<string>, search?: string, skip?: number, status?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAccessUnitAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetActivityRequestAccessUnits(id, integrationId, limit, resourceType, search, skip, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetActivityRequestTimeline(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseActivityTimelineEventModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetActivityRequestTimeline(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListRequestAccessUnitsRequest} listRequestAccessUnitsRequest 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListActivityAccessUnits(listRequestAccessUnitsRequest: ListRequestAccessUnitsRequest, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAccessUnitAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListActivityAccessUnits(listRequestAccessUnitsRequest, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {string} [filterAccessFlowId] 
         * @param {string} [filterAccessRequestId] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListActivityRequests(endDate?: number, filterAccessFlowId?: string, filterAccessRequestId?: string, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, limit?: number, offset?: number, startDate?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseActivityRequestAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListActivityRequests(endDate, filterAccessFlowId, filterAccessRequestId, filterIntegrationId, filterPermission, filterRequestorId, filterResource, filterResourceType, filterStatus, filterTriggerType, limit, offset, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyActivityReportModel} modifyActivityReportModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportTemplate(modifyActivityReportModel: ModifyActivityReportModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportTemplate(modifyActivityReportModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {Array<ReportField>} [fields] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportFile(endDate?: number, fields?: Array<ReportField>, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, startDate?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFile(endDate, fields, filterIntegrationId, filterPermission, filterRequestorId, filterResource, filterResourceType, filterStatus, filterTriggerType, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportFromStoredTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityReportExportModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFromStoredTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {Array<ReportField>} [fields] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportPreview(endDate?: number, fields?: Array<ReportField>, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, startDate?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityReportExportModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportPreview(endDate, fields, filterIntegrationId, filterPermission, filterRequestorId, filterResource, filterResourceType, filterStatus, filterTriggerType, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActivityFilter} filterName 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivityFilterOptions(filterName: ActivityFilter, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivityFilterOptions(filterName, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseActivityReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncReactiveAccessRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncReactiveAccessRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ModifyActivityReportModel} modifyActivityReportModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportTemplate(id: string, modifyActivityReportModel: ModifyActivityReportModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportTemplate(id, modifyActivityReportModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequest(id: string, options?: any): AxiosPromise<ActivityRequestAppModel> {
            return localVarFp.appGetActivityRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {Array<string>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequestAccessUnitGroups(id: string, limit?: number, skip?: number, status?: Array<string>, options?: any): AxiosPromise<PaginatedResponseAccessUnitsGroupAppModel> {
            return localVarFp.appGetActivityRequestAccessUnitGroups(id, limit, skip, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [integrationId] 
         * @param {number} [limit] 
         * @param {Array<string>} [resourceType] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequestAccessUnits(id: string, integrationId?: Array<string>, limit?: number, resourceType?: Array<string>, search?: string, skip?: number, status?: Array<string>, options?: any): AxiosPromise<PaginatedResponseAccessUnitAppModel> {
            return localVarFp.appGetActivityRequestAccessUnits(id, integrationId, limit, resourceType, search, skip, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetActivityRequestTimeline(id: string, options?: any): AxiosPromise<PaginatedResponseActivityTimelineEventModel> {
            return localVarFp.appGetActivityRequestTimeline(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListRequestAccessUnitsRequest} listRequestAccessUnitsRequest 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListActivityAccessUnits(listRequestAccessUnitsRequest: ListRequestAccessUnitsRequest, limit?: number, skip?: number, options?: any): AxiosPromise<PaginatedResponseAccessUnitAppModel> {
            return localVarFp.appListActivityAccessUnits(listRequestAccessUnitsRequest, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {string} [filterAccessFlowId] 
         * @param {string} [filterAccessRequestId] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListActivityRequests(endDate?: number, filterAccessFlowId?: string, filterAccessRequestId?: string, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, limit?: number, offset?: number, startDate?: number, options?: any): AxiosPromise<PaginatedResponseActivityRequestAppModel> {
            return localVarFp.appListActivityRequests(endDate, filterAccessFlowId, filterAccessRequestId, filterIntegrationId, filterPermission, filterRequestorId, filterResource, filterResourceType, filterStatus, filterTriggerType, limit, offset, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyActivityReportModel} modifyActivityReportModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportTemplate(modifyActivityReportModel: ModifyActivityReportModel, options?: any): AxiosPromise<ActivityReportModel> {
            return localVarFp.createReportTemplate(modifyActivityReportModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportTemplate(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteReportTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {Array<ReportField>} [fields] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFile(endDate?: number, fields?: Array<ReportField>, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, startDate?: number, options?: any): AxiosPromise<void> {
            return localVarFp.getReportFile(endDate, fields, filterIntegrationId, filterPermission, filterRequestorId, filterResource, filterResourceType, filterStatus, filterTriggerType, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFromStoredTemplate(id: string, options?: any): AxiosPromise<Array<ActivityReportExportModel>> {
            return localVarFp.getReportFromStoredTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {Array<ReportField>} [fields] 
         * @param {string} [filterIntegrationId] 
         * @param {string} [filterPermission] 
         * @param {string} [filterRequestorId] 
         * @param {string} [filterResource] 
         * @param {string} [filterResourceType] 
         * @param {string} [filterStatus] 
         * @param {string} [filterTriggerType] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportPreview(endDate?: number, fields?: Array<ReportField>, filterIntegrationId?: string, filterPermission?: string, filterRequestorId?: string, filterResource?: string, filterResourceType?: string, filterStatus?: string, filterTriggerType?: string, startDate?: number, options?: any): AxiosPromise<Array<ActivityReportExportModel>> {
            return localVarFp.getReportPreview(endDate, fields, filterIntegrationId, filterPermission, filterRequestorId, filterResource, filterResourceType, filterStatus, filterTriggerType, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTemplate(id: string, options?: any): AxiosPromise<ActivityReportModel> {
            return localVarFp.getReportTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivityFilter} filterName 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivityFilterOptions(filterName: ActivityFilter, limit?: number, skip?: number, options?: any): AxiosPromise<PaginatedResponseString> {
            return localVarFp.listActivityFilterOptions(filterName, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportTemplates(options?: any): AxiosPromise<PaginatedResponseActivityReportModel> {
            return localVarFp.listReportTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncReactiveAccessRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.syncReactiveAccessRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ModifyActivityReportModel} modifyActivityReportModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportTemplate(id: string, modifyActivityReportModel: ModifyActivityReportModel, options?: any): AxiosPromise<ActivityReportModel> {
            return localVarFp.updateReportTemplate(id, modifyActivityReportModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appGetActivityRequest operation in ActivityApi.
 * @export
 * @interface ActivityApiAppGetActivityRequestRequest
 */
export interface ActivityApiAppGetActivityRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppGetActivityRequest
     */
    readonly id: string
}

/**
 * Request parameters for appGetActivityRequestAccessUnitGroups operation in ActivityApi.
 * @export
 * @interface ActivityApiAppGetActivityRequestAccessUnitGroupsRequest
 */
export interface ActivityApiAppGetActivityRequestAccessUnitGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppGetActivityRequestAccessUnitGroups
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppGetActivityRequestAccessUnitGroups
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppGetActivityRequestAccessUnitGroups
     */
    readonly skip?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityApiAppGetActivityRequestAccessUnitGroups
     */
    readonly status?: Array<string>
}

/**
 * Request parameters for appGetActivityRequestAccessUnits operation in ActivityApi.
 * @export
 * @interface ActivityApiAppGetActivityRequestAccessUnitsRequest
 */
export interface ActivityApiAppGetActivityRequestAccessUnitsRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly id: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly integrationId?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly resourceType?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly skip?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityApiAppGetActivityRequestAccessUnits
     */
    readonly status?: Array<string>
}

/**
 * Request parameters for appGetActivityRequestTimeline operation in ActivityApi.
 * @export
 * @interface ActivityApiAppGetActivityRequestTimelineRequest
 */
export interface ActivityApiAppGetActivityRequestTimelineRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppGetActivityRequestTimeline
     */
    readonly id: string
}

/**
 * Request parameters for appListActivityAccessUnits operation in ActivityApi.
 * @export
 * @interface ActivityApiAppListActivityAccessUnitsRequest
 */
export interface ActivityApiAppListActivityAccessUnitsRequest {
    /**
     * 
     * @type {ListRequestAccessUnitsRequest}
     * @memberof ActivityApiAppListActivityAccessUnits
     */
    readonly listRequestAccessUnitsRequest: ListRequestAccessUnitsRequest

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppListActivityAccessUnits
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppListActivityAccessUnits
     */
    readonly skip?: number
}

/**
 * Request parameters for appListActivityRequests operation in ActivityApi.
 * @export
 * @interface ActivityApiAppListActivityRequestsRequest
 */
export interface ActivityApiAppListActivityRequestsRequest {
    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly endDate?: number

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterAccessFlowId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterAccessRequestId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterIntegrationId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterPermission?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterRequestorId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterResource?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterResourceType?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterStatus?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly filterTriggerType?: string

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ActivityApiAppListActivityRequests
     */
    readonly startDate?: number
}

/**
 * Request parameters for createReportTemplate operation in ActivityApi.
 * @export
 * @interface ActivityApiCreateReportTemplateRequest
 */
export interface ActivityApiCreateReportTemplateRequest {
    /**
     * 
     * @type {ModifyActivityReportModel}
     * @memberof ActivityApiCreateReportTemplate
     */
    readonly modifyActivityReportModel: ModifyActivityReportModel
}

/**
 * Request parameters for deleteReportTemplate operation in ActivityApi.
 * @export
 * @interface ActivityApiDeleteReportTemplateRequest
 */
export interface ActivityApiDeleteReportTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiDeleteReportTemplate
     */
    readonly id: string
}

/**
 * Request parameters for getReportFile operation in ActivityApi.
 * @export
 * @interface ActivityApiGetReportFileRequest
 */
export interface ActivityApiGetReportFileRequest {
    /**
     * 
     * @type {number}
     * @memberof ActivityApiGetReportFile
     */
    readonly endDate?: number

    /**
     * 
     * @type {Array<ReportField>}
     * @memberof ActivityApiGetReportFile
     */
    readonly fields?: Array<ReportField>

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterIntegrationId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterPermission?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterRequestorId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterResource?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterResourceType?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterStatus?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFile
     */
    readonly filterTriggerType?: string

    /**
     * 
     * @type {number}
     * @memberof ActivityApiGetReportFile
     */
    readonly startDate?: number
}

/**
 * Request parameters for getReportFromStoredTemplate operation in ActivityApi.
 * @export
 * @interface ActivityApiGetReportFromStoredTemplateRequest
 */
export interface ActivityApiGetReportFromStoredTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportFromStoredTemplate
     */
    readonly id: string
}

/**
 * Request parameters for getReportPreview operation in ActivityApi.
 * @export
 * @interface ActivityApiGetReportPreviewRequest
 */
export interface ActivityApiGetReportPreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof ActivityApiGetReportPreview
     */
    readonly endDate?: number

    /**
     * 
     * @type {Array<ReportField>}
     * @memberof ActivityApiGetReportPreview
     */
    readonly fields?: Array<ReportField>

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterIntegrationId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterPermission?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterRequestorId?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterResource?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterResourceType?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterStatus?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportPreview
     */
    readonly filterTriggerType?: string

    /**
     * 
     * @type {number}
     * @memberof ActivityApiGetReportPreview
     */
    readonly startDate?: number
}

/**
 * Request parameters for getReportTemplate operation in ActivityApi.
 * @export
 * @interface ActivityApiGetReportTemplateRequest
 */
export interface ActivityApiGetReportTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiGetReportTemplate
     */
    readonly id: string
}

/**
 * Request parameters for listActivityFilterOptions operation in ActivityApi.
 * @export
 * @interface ActivityApiListActivityFilterOptionsRequest
 */
export interface ActivityApiListActivityFilterOptionsRequest {
    /**
     * 
     * @type {ActivityFilter}
     * @memberof ActivityApiListActivityFilterOptions
     */
    readonly filterName: ActivityFilter

    /**
     * 
     * @type {number}
     * @memberof ActivityApiListActivityFilterOptions
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ActivityApiListActivityFilterOptions
     */
    readonly skip?: number
}

/**
 * Request parameters for syncReactiveAccessRequest operation in ActivityApi.
 * @export
 * @interface ActivityApiSyncReactiveAccessRequestRequest
 */
export interface ActivityApiSyncReactiveAccessRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiSyncReactiveAccessRequest
     */
    readonly id: string
}

/**
 * Request parameters for updateReportTemplate operation in ActivityApi.
 * @export
 * @interface ActivityApiUpdateReportTemplateRequest
 */
export interface ActivityApiUpdateReportTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivityApiUpdateReportTemplate
     */
    readonly id: string

    /**
     * 
     * @type {ModifyActivityReportModel}
     * @memberof ActivityApiUpdateReportTemplate
     */
    readonly modifyActivityReportModel: ModifyActivityReportModel
}

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {ActivityApiAppGetActivityRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public appGetActivityRequest(requestParameters: ActivityApiAppGetActivityRequestRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).appGetActivityRequest(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiAppGetActivityRequestAccessUnitGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public appGetActivityRequestAccessUnitGroups(requestParameters: ActivityApiAppGetActivityRequestAccessUnitGroupsRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).appGetActivityRequestAccessUnitGroups(requestParameters.id, requestParameters.limit, requestParameters.skip, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiAppGetActivityRequestAccessUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public appGetActivityRequestAccessUnits(requestParameters: ActivityApiAppGetActivityRequestAccessUnitsRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).appGetActivityRequestAccessUnits(requestParameters.id, requestParameters.integrationId, requestParameters.limit, requestParameters.resourceType, requestParameters.search, requestParameters.skip, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiAppGetActivityRequestTimelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public appGetActivityRequestTimeline(requestParameters: ActivityApiAppGetActivityRequestTimelineRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).appGetActivityRequestTimeline(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiAppListActivityAccessUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public appListActivityAccessUnits(requestParameters: ActivityApiAppListActivityAccessUnitsRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).appListActivityAccessUnits(requestParameters.listRequestAccessUnitsRequest, requestParameters.limit, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiAppListActivityRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public appListActivityRequests(requestParameters: ActivityApiAppListActivityRequestsRequest = {}, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).appListActivityRequests(requestParameters.endDate, requestParameters.filterAccessFlowId, requestParameters.filterAccessRequestId, requestParameters.filterIntegrationId, requestParameters.filterPermission, requestParameters.filterRequestorId, requestParameters.filterResource, requestParameters.filterResourceType, requestParameters.filterStatus, requestParameters.filterTriggerType, requestParameters.limit, requestParameters.offset, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiCreateReportTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public createReportTemplate(requestParameters: ActivityApiCreateReportTemplateRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).createReportTemplate(requestParameters.modifyActivityReportModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiDeleteReportTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public deleteReportTemplate(requestParameters: ActivityApiDeleteReportTemplateRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).deleteReportTemplate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiGetReportFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getReportFile(requestParameters: ActivityApiGetReportFileRequest = {}, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getReportFile(requestParameters.endDate, requestParameters.fields, requestParameters.filterIntegrationId, requestParameters.filterPermission, requestParameters.filterRequestorId, requestParameters.filterResource, requestParameters.filterResourceType, requestParameters.filterStatus, requestParameters.filterTriggerType, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiGetReportFromStoredTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getReportFromStoredTemplate(requestParameters: ActivityApiGetReportFromStoredTemplateRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getReportFromStoredTemplate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiGetReportPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getReportPreview(requestParameters: ActivityApiGetReportPreviewRequest = {}, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getReportPreview(requestParameters.endDate, requestParameters.fields, requestParameters.filterIntegrationId, requestParameters.filterPermission, requestParameters.filterRequestorId, requestParameters.filterResource, requestParameters.filterResourceType, requestParameters.filterStatus, requestParameters.filterTriggerType, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiGetReportTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getReportTemplate(requestParameters: ActivityApiGetReportTemplateRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getReportTemplate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiListActivityFilterOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public listActivityFilterOptions(requestParameters: ActivityApiListActivityFilterOptionsRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).listActivityFilterOptions(requestParameters.filterName, requestParameters.limit, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public listReportTemplates(options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).listReportTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiSyncReactiveAccessRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public syncReactiveAccessRequest(requestParameters: ActivityApiSyncReactiveAccessRequestRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).syncReactiveAccessRequest(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiUpdateReportTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public updateReportTemplate(requestParameters: ActivityApiUpdateReportTemplateRequest, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).updateReportTemplate(requestParameters.id, requestParameters.modifyActivityReportModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttributesApi - axios parameter creator
 * @export
 */
export const AttributesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list attributes groups
         * @param {Array<AccessFlowAreaModel>} [applicablePlaces] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAttributeGroups: async (applicablePlaces?: Array<AccessFlowAreaModel>, limit?: number, search?: string, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/v1/attribute-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applicablePlaces) {
                localVarQueryParameter['applicable_places'] = applicablePlaces;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list attributes
         * @param {Array<AccessFlowAreaModel>} [applicablePlaces] 
         * @param {Array<string>} [attributeId] 
         * @param {string} [attributeType] 
         * @param {string} [integrationId] 
         * @param {number} [limit] 
         * @param {boolean} [onlyLocal] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAttributes: async (applicablePlaces?: Array<AccessFlowAreaModel>, attributeId?: Array<string>, attributeType?: string, integrationId?: string, limit?: number, onlyLocal?: boolean, search?: string, skip?: number, statuses?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/v1/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applicablePlaces) {
                localVarQueryParameter['applicable_places'] = applicablePlaces;
            }

            if (attributeId) {
                localVarQueryParameter['attributeId'] = attributeId;
            }

            if (attributeType !== undefined) {
                localVarQueryParameter['attribute_type'] = attributeType;
            }

            if (integrationId !== undefined) {
                localVarQueryParameter['integration_id'] = integrationId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (onlyLocal !== undefined) {
                localVarQueryParameter['only_local'] = onlyLocal;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttributesApi - functional programming interface
 * @export
 */
export const AttributesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttributesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list attributes groups
         * @param {Array<AccessFlowAreaModel>} [applicablePlaces] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListAttributeGroups(applicablePlaces?: Array<AccessFlowAreaModel>, limit?: number, search?: string, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAttributeGroupAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListAttributeGroups(applicablePlaces, limit, search, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list attributes
         * @param {Array<AccessFlowAreaModel>} [applicablePlaces] 
         * @param {Array<string>} [attributeId] 
         * @param {string} [attributeType] 
         * @param {string} [integrationId] 
         * @param {number} [limit] 
         * @param {boolean} [onlyLocal] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListAttributes(applicablePlaces?: Array<AccessFlowAreaModel>, attributeId?: Array<string>, attributeType?: string, integrationId?: string, limit?: number, onlyLocal?: boolean, search?: string, skip?: number, statuses?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseAttributeAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListAttributes(applicablePlaces, attributeId, attributeType, integrationId, limit, onlyLocal, search, skip, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttributesApi - factory interface
 * @export
 */
export const AttributesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttributesApiFp(configuration)
    return {
        /**
         * 
         * @summary list attributes groups
         * @param {Array<AccessFlowAreaModel>} [applicablePlaces] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAttributeGroups(applicablePlaces?: Array<AccessFlowAreaModel>, limit?: number, search?: string, skip?: number, options?: any): AxiosPromise<PaginatedResponseAttributeGroupAppModel> {
            return localVarFp.appListAttributeGroups(applicablePlaces, limit, search, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list attributes
         * @param {Array<AccessFlowAreaModel>} [applicablePlaces] 
         * @param {Array<string>} [attributeId] 
         * @param {string} [attributeType] 
         * @param {string} [integrationId] 
         * @param {number} [limit] 
         * @param {boolean} [onlyLocal] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {Array<string>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListAttributes(applicablePlaces?: Array<AccessFlowAreaModel>, attributeId?: Array<string>, attributeType?: string, integrationId?: string, limit?: number, onlyLocal?: boolean, search?: string, skip?: number, statuses?: Array<string>, options?: any): AxiosPromise<PaginatedResponseAttributeAppModel> {
            return localVarFp.appListAttributes(applicablePlaces, attributeId, attributeType, integrationId, limit, onlyLocal, search, skip, statuses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appListAttributeGroups operation in AttributesApi.
 * @export
 * @interface AttributesApiAppListAttributeGroupsRequest
 */
export interface AttributesApiAppListAttributeGroupsRequest {
    /**
     * 
     * @type {Array<AccessFlowAreaModel>}
     * @memberof AttributesApiAppListAttributeGroups
     */
    readonly applicablePlaces?: Array<AccessFlowAreaModel>

    /**
     * 
     * @type {number}
     * @memberof AttributesApiAppListAttributeGroups
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AttributesApiAppListAttributeGroups
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof AttributesApiAppListAttributeGroups
     */
    readonly skip?: number
}

/**
 * Request parameters for appListAttributes operation in AttributesApi.
 * @export
 * @interface AttributesApiAppListAttributesRequest
 */
export interface AttributesApiAppListAttributesRequest {
    /**
     * 
     * @type {Array<AccessFlowAreaModel>}
     * @memberof AttributesApiAppListAttributes
     */
    readonly applicablePlaces?: Array<AccessFlowAreaModel>

    /**
     * 
     * @type {Array<string>}
     * @memberof AttributesApiAppListAttributes
     */
    readonly attributeId?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AttributesApiAppListAttributes
     */
    readonly attributeType?: string

    /**
     * 
     * @type {string}
     * @memberof AttributesApiAppListAttributes
     */
    readonly integrationId?: string

    /**
     * 
     * @type {number}
     * @memberof AttributesApiAppListAttributes
     */
    readonly limit?: number

    /**
     * 
     * @type {boolean}
     * @memberof AttributesApiAppListAttributes
     */
    readonly onlyLocal?: boolean

    /**
     * 
     * @type {string}
     * @memberof AttributesApiAppListAttributes
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof AttributesApiAppListAttributes
     */
    readonly skip?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof AttributesApiAppListAttributes
     */
    readonly statuses?: Array<string>
}

/**
 * AttributesApi - object-oriented interface
 * @export
 * @class AttributesApi
 * @extends {BaseAPI}
 */
export class AttributesApi extends BaseAPI {
    /**
     * 
     * @summary list attributes groups
     * @param {AttributesApiAppListAttributeGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributesApi
     */
    public appListAttributeGroups(requestParameters: AttributesApiAppListAttributeGroupsRequest = {}, options?: AxiosRequestConfig) {
        return AttributesApiFp(this.configuration).appListAttributeGroups(requestParameters.applicablePlaces, requestParameters.limit, requestParameters.search, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list attributes
     * @param {AttributesApiAppListAttributesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributesApi
     */
    public appListAttributes(requestParameters: AttributesApiAppListAttributesRequest = {}, options?: AxiosRequestConfig) {
        return AttributesApiFp(this.configuration).appListAttributes(requestParameters.applicablePlaces, requestParameters.attributeId, requestParameters.attributeType, requestParameters.integrationId, requestParameters.limit, requestParameters.onlyLocal, requestParameters.search, requestParameters.skip, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectorsApi - axios parameter creator
 * @export
 */
export const ConnectorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateConnector} createConnector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent: async (createConnector: CreateConnector, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConnector' is not null or undefined
            assertParamExists('createAgent', 'createConnector', createConnector)
            const localVarPath = `/apn/core/api/v1/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgent', 'id', id)
            const localVarPath = `/apn/core/api/v1/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgent', 'id', id)
            const localVarPath = `/apn/core/api/v1/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [templateType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentInstructions: async (id: string, templateType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentInstructions', 'id', id)
            const localVarPath = `/apn/core/api/v1/agents/{id}/instructions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateType !== undefined) {
                localVarQueryParameter['template_type'] = templateType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get install link
         * @param {InstallationMethod} method 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallLink: async (method: InstallationMethod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'method' is not null or undefined
            assertParamExists('getInstallLink', 'method', method)
            const localVarPath = `/apn/core/api/v2/connectors-installation/install-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (method !== undefined) {
                localVarQueryParameter['method'] = method;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAgents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list connectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v2/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshAgent', 'id', id)
            const localVarPath = `/apn/core/api/v1/agents/{id}/refresh`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateConnector} updateConnector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentName: async (id: string, updateConnector: UpdateConnector, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentName', 'id', id)
            // verify required parameter 'updateConnector' is not null or undefined
            assertParamExists('updateAgentName', 'updateConnector', updateConnector)
            const localVarPath = `/apn/core/api/v1/agents/api/v1/agents/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectorsApi - functional programming interface
 * @export
 */
export const ConnectorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateConnector} createConnector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgent(createConnector: CreateConnector, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgent(createConnector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [templateType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentInstructions(id: string, templateType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentInstructions(id, templateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get install link
         * @param {InstallationMethod} method 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallLink(method: InstallationMethod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallLink(method, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAgents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAgents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list connectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Connector>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateConnector} updateConnector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentName(id: string, updateConnector: UpdateConnector, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentName(id, updateConnector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectorsApi - factory interface
 * @export
 */
export const ConnectorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectorsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateConnector} createConnector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent(createConnector: CreateConnector, options?: any): AxiosPromise<void> {
            return localVarFp.createAgent(createConnector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(id: string, options?: any): AxiosPromise<AgentViewModel> {
            return localVarFp.getAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [templateType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentInstructions(id: string, templateType?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getAgentInstructions(id, templateType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get install link
         * @param {InstallationMethod} method 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallLink(method: InstallationMethod, options?: any): AxiosPromise<LinkResponse> {
            return localVarFp.getInstallLink(method, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAgents(options?: any): AxiosPromise<Array<AgentViewModel>> {
            return localVarFp.listAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list connectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors(options?: any): AxiosPromise<Array<Connector>> {
            return localVarFp.listConnectors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAgent(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.refreshAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateConnector} updateConnector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentName(id: string, updateConnector: UpdateConnector, options?: any): AxiosPromise<void> {
            return localVarFp.updateAgentName(id, updateConnector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAgent operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiCreateAgentRequest
 */
export interface ConnectorsApiCreateAgentRequest {
    /**
     * 
     * @type {CreateConnector}
     * @memberof ConnectorsApiCreateAgent
     */
    readonly createConnector: CreateConnector
}

/**
 * Request parameters for deleteAgent operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiDeleteAgentRequest
 */
export interface ConnectorsApiDeleteAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectorsApiDeleteAgent
     */
    readonly id: string
}

/**
 * Request parameters for getAgent operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiGetAgentRequest
 */
export interface ConnectorsApiGetAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectorsApiGetAgent
     */
    readonly id: string
}

/**
 * Request parameters for getAgentInstructions operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiGetAgentInstructionsRequest
 */
export interface ConnectorsApiGetAgentInstructionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectorsApiGetAgentInstructions
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectorsApiGetAgentInstructions
     */
    readonly templateType?: string
}

/**
 * Request parameters for getInstallLink operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiGetInstallLinkRequest
 */
export interface ConnectorsApiGetInstallLinkRequest {
    /**
     * 
     * @type {InstallationMethod}
     * @memberof ConnectorsApiGetInstallLink
     */
    readonly method: InstallationMethod
}

/**
 * Request parameters for refreshAgent operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiRefreshAgentRequest
 */
export interface ConnectorsApiRefreshAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectorsApiRefreshAgent
     */
    readonly id: string
}

/**
 * Request parameters for updateAgentName operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiUpdateAgentNameRequest
 */
export interface ConnectorsApiUpdateAgentNameRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectorsApiUpdateAgentName
     */
    readonly id: string

    /**
     * 
     * @type {UpdateConnector}
     * @memberof ConnectorsApiUpdateAgentName
     */
    readonly updateConnector: UpdateConnector
}

/**
 * ConnectorsApi - object-oriented interface
 * @export
 * @class ConnectorsApi
 * @extends {BaseAPI}
 */
export class ConnectorsApi extends BaseAPI {
    /**
     * 
     * @param {ConnectorsApiCreateAgentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public createAgent(requestParameters: ConnectorsApiCreateAgentRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).createAgent(requestParameters.createConnector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectorsApiDeleteAgentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public deleteAgent(requestParameters: ConnectorsApiDeleteAgentRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).deleteAgent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectorsApiGetAgentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public getAgent(requestParameters: ConnectorsApiGetAgentRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).getAgent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectorsApiGetAgentInstructionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public getAgentInstructions(requestParameters: ConnectorsApiGetAgentInstructionsRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).getAgentInstructions(requestParameters.id, requestParameters.templateType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get install link
     * @param {ConnectorsApiGetInstallLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public getInstallLink(requestParameters: ConnectorsApiGetInstallLinkRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).getInstallLink(requestParameters.method, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public listAgents(options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).listAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list connectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public listConnectors(options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).listConnectors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectorsApiRefreshAgentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public refreshAgent(requestParameters: ConnectorsApiRefreshAgentRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).refreshAgent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectorsApiUpdateAgentNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public updateAgentName(requestParameters: ConnectorsApiUpdateAgentNameRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).updateAgentName(requestParameters.id, requestParameters.updateConnector, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeInsight: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('acknowledgeInsight', 'id', id)
            const localVarPath = `/apn/core/api/v1/insights/{id}/ack`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInsights: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/insights/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Set<AwsResourceType>} [awsResourceTypes] 
         * @param {number} [days] 
         * @param {Set<AwsIamResource>} [iamResourceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsIamUsage: async (awsResourceTypes?: Set<AwsResourceType>, days?: number, iamResourceTypes?: Set<AwsIamResource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/usage/iam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (awsResourceTypes) {
                localVarQueryParameter['aws_resource_types'] = Array.from(awsResourceTypes);
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (iamResourceTypes) {
                localVarQueryParameter['iam_resource_types'] = Array.from(iamResourceTypes);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsResources: async (days?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/dashboard/aws-resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnBoardingStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/onboarding-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showNonActionable] 
         * @param {boolean} [showSnoozed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInsights: async (showNonActionable?: boolean, showSnoozed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/insights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showNonActionable !== undefined) {
                localVarQueryParameter['show_non_actionable'] = showNonActionable;
            }

            if (showSnoozed !== undefined) {
                localVarQueryParameter['show_snoozed'] = showSnoozed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAccessOverTime: async (endDate?: number, startDate?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/analytics-dashboard/active-access-over-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCurrentAccessRequests: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/analytics-dashboard/access-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {number} [limit] 
         * @param {number} [startDate] 
         * @param {TopQueryEntityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTopOverTime: async (endDate?: number, limit?: number, startDate?: number, type?: TopQueryEntityType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/analytics-dashboard/top-over-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unacknowledgeInsight: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unacknowledgeInsight', 'id', id)
            const localVarPath = `/apn/core/api/v1/insights/{id}/unack`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acknowledgeInsight(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeInsight(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInsights(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInsights(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Set<AwsResourceType>} [awsResourceTypes] 
         * @param {number} [days] 
         * @param {Set<AwsIamResource>} [iamResourceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAwsIamUsage(awsResourceTypes?: Set<AwsResourceType>, days?: number, iamResourceTypes?: Set<AwsIamResource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwsIamUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAwsIamUsage(awsResourceTypes, days, iamResourceTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAwsResources(days?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveredAwsResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAwsResources(days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnBoardingStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnBoardingStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [showNonActionable] 
         * @param {boolean} [showSnoozed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInsights(showNonActionable?: boolean, showSnoozed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnrichedInsightModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInsights(showNonActionable, showSnoozed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryAccessOverTime(endDate?: number, startDate?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessOverTimeQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryAccessOverTime(endDate, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCurrentAccessRequests(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentAccessRequestsQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCurrentAccessRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {number} [limit] 
         * @param {number} [startDate] 
         * @param {TopQueryEntityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryTopOverTime(endDate?: number, limit?: number, startDate?: number, type?: TopQueryEntityType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopOverTimeQueryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryTopOverTime(endDate, limit, startDate, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unacknowledgeInsight(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unacknowledgeInsight(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeInsight(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.acknowledgeInsight(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInsights(options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.fetchInsights(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Set<AwsResourceType>} [awsResourceTypes] 
         * @param {number} [days] 
         * @param {Set<AwsIamResource>} [iamResourceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsIamUsage(awsResourceTypes?: Set<AwsResourceType>, days?: number, iamResourceTypes?: Set<AwsIamResource>, options?: any): AxiosPromise<AwsIamUsageResponse> {
            return localVarFp.getAwsIamUsage(awsResourceTypes, days, iamResourceTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsResources(days?: number, options?: any): AxiosPromise<DiscoveredAwsResourcesResponse> {
            return localVarFp.getAwsResources(days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnBoardingStatus(options?: any): AxiosPromise<OnboardingStatus> {
            return localVarFp.getOnBoardingStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showNonActionable] 
         * @param {boolean} [showSnoozed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInsights(showNonActionable?: boolean, showSnoozed?: boolean, options?: any): AxiosPromise<Array<EnrichedInsightModel>> {
            return localVarFp.listInsights(showNonActionable, showSnoozed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {number} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAccessOverTime(endDate?: number, startDate?: number, options?: any): AxiosPromise<AccessOverTimeQueryResponse> {
            return localVarFp.queryAccessOverTime(endDate, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCurrentAccessRequests(options?: any): AxiosPromise<CurrentAccessRequestsQueryResponse> {
            return localVarFp.queryCurrentAccessRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [endDate] 
         * @param {number} [limit] 
         * @param {number} [startDate] 
         * @param {TopQueryEntityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTopOverTime(endDate?: number, limit?: number, startDate?: number, type?: TopQueryEntityType, options?: any): AxiosPromise<Array<TopOverTimeQueryResponse>> {
            return localVarFp.queryTopOverTime(endDate, limit, startDate, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unacknowledgeInsight(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.unacknowledgeInsight(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for acknowledgeInsight operation in DashboardApi.
 * @export
 * @interface DashboardApiAcknowledgeInsightRequest
 */
export interface DashboardApiAcknowledgeInsightRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiAcknowledgeInsight
     */
    readonly id: string
}

/**
 * Request parameters for getAwsIamUsage operation in DashboardApi.
 * @export
 * @interface DashboardApiGetAwsIamUsageRequest
 */
export interface DashboardApiGetAwsIamUsageRequest {
    /**
     * 
     * @type {Set<AwsResourceType>}
     * @memberof DashboardApiGetAwsIamUsage
     */
    readonly awsResourceTypes?: Set<AwsResourceType>

    /**
     * 
     * @type {number}
     * @memberof DashboardApiGetAwsIamUsage
     */
    readonly days?: number

    /**
     * 
     * @type {Set<AwsIamResource>}
     * @memberof DashboardApiGetAwsIamUsage
     */
    readonly iamResourceTypes?: Set<AwsIamResource>
}

/**
 * Request parameters for getAwsResources operation in DashboardApi.
 * @export
 * @interface DashboardApiGetAwsResourcesRequest
 */
export interface DashboardApiGetAwsResourcesRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiGetAwsResources
     */
    readonly days?: number
}

/**
 * Request parameters for listInsights operation in DashboardApi.
 * @export
 * @interface DashboardApiListInsightsRequest
 */
export interface DashboardApiListInsightsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiListInsights
     */
    readonly showNonActionable?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiListInsights
     */
    readonly showSnoozed?: boolean
}

/**
 * Request parameters for queryAccessOverTime operation in DashboardApi.
 * @export
 * @interface DashboardApiQueryAccessOverTimeRequest
 */
export interface DashboardApiQueryAccessOverTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiQueryAccessOverTime
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiQueryAccessOverTime
     */
    readonly startDate?: number
}

/**
 * Request parameters for queryTopOverTime operation in DashboardApi.
 * @export
 * @interface DashboardApiQueryTopOverTimeRequest
 */
export interface DashboardApiQueryTopOverTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiQueryTopOverTime
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiQueryTopOverTime
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiQueryTopOverTime
     */
    readonly startDate?: number

    /**
     * 
     * @type {TopQueryEntityType}
     * @memberof DashboardApiQueryTopOverTime
     */
    readonly type?: TopQueryEntityType
}

/**
 * Request parameters for unacknowledgeInsight operation in DashboardApi.
 * @export
 * @interface DashboardApiUnacknowledgeInsightRequest
 */
export interface DashboardApiUnacknowledgeInsightRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiUnacknowledgeInsight
     */
    readonly id: string
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {DashboardApiAcknowledgeInsightRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public acknowledgeInsight(requestParameters: DashboardApiAcknowledgeInsightRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).acknowledgeInsight(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public fetchInsights(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).fetchInsights(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiGetAwsIamUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getAwsIamUsage(requestParameters: DashboardApiGetAwsIamUsageRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getAwsIamUsage(requestParameters.awsResourceTypes, requestParameters.days, requestParameters.iamResourceTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiGetAwsResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getAwsResources(requestParameters: DashboardApiGetAwsResourcesRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getAwsResources(requestParameters.days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getOnBoardingStatus(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getOnBoardingStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiListInsightsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public listInsights(requestParameters: DashboardApiListInsightsRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).listInsights(requestParameters.showNonActionable, requestParameters.showSnoozed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiQueryAccessOverTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public queryAccessOverTime(requestParameters: DashboardApiQueryAccessOverTimeRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).queryAccessOverTime(requestParameters.endDate, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public queryCurrentAccessRequests(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).queryCurrentAccessRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiQueryTopOverTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public queryTopOverTime(requestParameters: DashboardApiQueryTopOverTimeRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).queryTopOverTime(requestParameters.endDate, requestParameters.limit, requestParameters.startDate, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiUnacknowledgeInsightRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public unacknowledgeInsight(requestParameters: DashboardApiUnacknowledgeInsightRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).unacknowledgeInsight(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureFlagsApi - axios parameter creator
 * @export
 */
export const FeatureFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureFlagsApi - functional programming interface
 * @export
 */
export const FeatureFlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureFlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureFlagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureFlagsApi - factory interface
 * @export
 */
export const FeatureFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureFlagsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeatures(options?: any): AxiosPromise<FeatureFlagsResponse> {
            return localVarFp.listFeatures(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureFlagsApi - object-oriented interface
 * @export
 * @class FeatureFlagsApi
 * @extends {BaseAPI}
 */
export class FeatureFlagsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public listFeatures(options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).listFeatures(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary check if integration can be deleted and not used by access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCanDeleteIntegration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appCanDeleteIntegration', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}/can-delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create integration
         * @param {CreateIntegrationAppModel} createIntegrationAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateIntegration: async (createIntegrationAppModel: CreateIntegrationAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIntegrationAppModel' is not null or undefined
            assertParamExists('appCreateIntegration', 'createIntegrationAppModel', createIntegrationAppModel)
            const localVarPath = `/apn/core/api/app/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIntegrationAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteIntegration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appDeleteIntegration', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetIntegration', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration config
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationConfig: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('appGetIntegrationConfig', 'type', type)
            const localVarPath = `/apn/core/api/app/integrations-catalog/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of integration related errors
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationErrors: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetIntegrationErrors', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}/errors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {GetOauthInstallLinkModel} getOauthInstallLinkModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationInstallLink: async (type: string, getOauthInstallLinkModel: GetOauthInstallLinkModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('appGetIntegrationInstallLink', 'type', type)
            // verify required parameter 'getOauthInstallLinkModel' is not null or undefined
            assertParamExists('appGetIntegrationInstallLink', 'getOauthInstallLinkModel', getOauthInstallLinkModel)
            const localVarPath = `/apn/core/api/app/integrations-catalog/{type}/install-link`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOauthInstallLinkModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration permissions list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationPermissions: async (id: string, resourceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetIntegrationPermissions', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}/permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration resources list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationResources: async (id: string, resourceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetIntegrationResources', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}/resources`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list integration configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListIntegrationConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/integrations-catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list integrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/app/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appRefreshIntegration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appRefreshIntegration', 'id', id)
            const localVarPath = `/apn/core/api/app/integrations/{id}/refresh`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search integration resources
         * @param {string} id 
         * @param {ResourcesSearchAppModel} resourcesSearchAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSearchIntegrationResources: async (id: string, resourcesSearchAppModel: ResourcesSearchAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appSearchIntegrationResources', 'id', id)
            // verify required parameter 'resourcesSearchAppModel' is not null or undefined
            assertParamExists('appSearchIntegrationResources', 'resourcesSearchAppModel', resourcesSearchAppModel)
            const localVarPath = `/apn/core/api/app/integrations/{id}/resources/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourcesSearchAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update integration
         * @param {string} id 
         * @param {UpdateIntegrationAppModel} updateIntegrationAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateIntegration: async (id: string, updateIntegrationAppModel: UpdateIntegrationAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appUpdateIntegration', 'id', id)
            // verify required parameter 'updateIntegrationAppModel' is not null or undefined
            assertParamExists('appUpdateIntegration', 'updateIntegrationAppModel', updateIntegrationAppModel)
            const localVarPath = `/apn/core/api/app/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIntegrationAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary check if integration can be deleted and not used by access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteIntegrationV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('canDeleteIntegrationV2', 'id', id)
            const localVarPath = `/apn/core/api/v2/integrations/{id}/can-delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create integration
         * @param {CreateIntegration} createIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegrationV2: async (createIntegration: CreateIntegration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIntegration' is not null or undefined
            assertParamExists('createIntegrationV2', 'createIntegration', createIntegration)
            const localVarPath = `/apn/core/api/v2/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIntegration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteIntegrationV2', 'id', id)
            const localVarPath = `/apn/core/api/v2/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIntegrationAccessDetailsPreview: async (integrationType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationType' is not null or undefined
            assertParamExists('generateIntegrationAccessDetailsPreview', 'integrationType', integrationType)
            const localVarPath = `/apn/core/api/app/integration-access-details/{integrationType}/preview`
                .replace(`{${"integrationType"}}`, encodeURIComponent(String(integrationType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsIntegrationInstallLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations/aws/install-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalog: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations-catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationConfig: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getIntegrationConfig', 'type', type)
            const localVarPath = `/apn/core/api/v1/integrations-catalog/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationInstallLink: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getIntegrationInstallLink', 'type', type)
            const localVarPath = `/apn/core/api/v1/integrations-catalog/{type}/install-link`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration permissions list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationPermissionsV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntegrationPermissionsV2', 'id', id)
            const localVarPath = `/apn/core/api/v2/integrations/{id}/permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration resources list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationResourcesV2: async (id: string, resourceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntegrationResourcesV2', 'id', id)
            const localVarPath = `/apn/core/api/v2/integrations/{id}/resources`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntegrationV2', 'id', id)
            const localVarPath = `/apn/core/api/v2/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/connected-integrations-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installAwsIntegration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations/aws/install`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountsWithConnectors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations/aws/accounts-with-connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations/aws`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} awsAccountId 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsResources: async (awsAccountId: string, region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'awsAccountId' is not null or undefined
            assertParamExists('listAwsResources', 'awsAccountId', awsAccountId)
            const localVarPath = `/apn/core/api/v1/integrations/aws/{awsAccountId}/resources`
                .replace(`{${"awsAccountId"}}`, encodeURIComponent(String(awsAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAzureIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations/azure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGcpIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/integrations/gcp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list integrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrationsV2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v2/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} awsAccountId 
         * @param {string} [filter] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSecrets: async (awsAccountId: string, filter?: string, region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'awsAccountId' is not null or undefined
            assertParamExists('listSecrets', 'awsAccountId', awsAccountId)
            const localVarPath = `/apn/core/api/v1/integrations/aws/{awsAccountId}/secrets`
                .replace(`{${"awsAccountId"}}`, encodeURIComponent(String(awsAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshIntegrationV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshIntegrationV2', 'id', id)
            const localVarPath = `/apn/core/api/v2/integrations/{id}/refresh`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update integration
         * @param {string} id 
         * @param {UpdateIntegration} updateIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationV2: async (id: string, updateIntegration: UpdateIntegration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIntegrationV2', 'id', id)
            // verify required parameter 'updateIntegration' is not null or undefined
            assertParamExists('updateIntegrationV2', 'updateIntegration', updateIntegration)
            const localVarPath = `/apn/core/api/v2/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIntegration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary check if integration can be deleted and not used by access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCanDeleteIntegration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanDeleteIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCanDeleteIntegration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create integration
         * @param {CreateIntegrationAppModel} createIntegrationAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCreateIntegration(createIntegrationAppModel: CreateIntegrationAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCreateIntegration(createIntegrationAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appDeleteIntegration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appDeleteIntegration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIntegration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIntegration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration config
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIntegrationConfig(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationConfigAppModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIntegrationConfig(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of integration related errors
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIntegrationErrors(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseIntegrationErrorAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIntegrationErrors(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {GetOauthInstallLinkModel} getOauthInstallLinkModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIntegrationInstallLink(type: string, getOauthInstallLinkModel: GetOauthInstallLinkModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIntegrationInstallLink(type, getOauthInstallLinkModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration permissions list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIntegrationPermissions(id: string, resourceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionDictionary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIntegrationPermissions(id, resourceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration resources list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetIntegrationResources(id: string, resourceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseResourceAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetIntegrationResources(id, resourceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list integration configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListIntegrationConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseIntegrationConfigAppModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListIntegrationConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list integrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseIntegrationAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appRefreshIntegration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appRefreshIntegration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary search integration resources
         * @param {string} id 
         * @param {ResourcesSearchAppModel} resourcesSearchAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSearchIntegrationResources(id: string, resourcesSearchAppModel: ResourcesSearchAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseResourceAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSearchIntegrationResources(id, resourcesSearchAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update integration
         * @param {string} id 
         * @param {UpdateIntegrationAppModel} updateIntegrationAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUpdateIntegration(id: string, updateIntegrationAppModel: UpdateIntegrationAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUpdateIntegration(id, updateIntegrationAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary check if integration can be deleted and not used by access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canDeleteIntegrationV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanDeleteIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canDeleteIntegrationV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create integration
         * @param {CreateIntegration} createIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntegrationV2(createIntegration: CreateIntegration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Integration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntegrationV2(createIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntegrationV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntegrationV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} integrationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateIntegrationAccessDetailsPreview(integrationType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessDetailsPreviewAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateIntegrationAccessDetailsPreview(integrationType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAwsIntegrationInstallLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAwsIntegrationInstallLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalog(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationsCatalogModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalog(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationConfig(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationConfigAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationConfig(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationInstallLink(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationInstallLink(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration permissions list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationPermissionsV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionDictionary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationPermissionsV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration resources list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationResourcesV2(id: string, resourceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseResourceDetailedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationResourcesV2(id, resourceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Integration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installAwsIntegration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installAwsIntegration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountsWithConnectors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AwsIntegrationAccountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountsWithConnectors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAwsIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AwsIntegration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAwsIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} awsAccountId 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAwsResources(awsAccountId: string, region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAwsResources(awsAccountId, region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAzureIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AzureIntegration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAzureIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGcpIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GcpIntegrationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGcpIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list integrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIntegrationsV2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseIntegrationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIntegrationsV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} awsAccountId 
         * @param {string} [filter] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSecrets(awsAccountId: string, filter?: string, region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AwsSecret>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSecrets(awsAccountId, filter, region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshIntegrationV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshIntegrationV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update integration
         * @param {string} id 
         * @param {UpdateIntegration} updateIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIntegrationV2(id: string, updateIntegration: UpdateIntegration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Integration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIntegrationV2(id, updateIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @summary check if integration can be deleted and not used by access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCanDeleteIntegration(id: string, options?: any): AxiosPromise<CanDeleteIntegration> {
            return localVarFp.appCanDeleteIntegration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create integration
         * @param {CreateIntegrationAppModel} createIntegrationAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateIntegration(createIntegrationAppModel: CreateIntegrationAppModel, options?: any): AxiosPromise<IntegrationAppModel> {
            return localVarFp.appCreateIntegration(createIntegrationAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteIntegration(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.appDeleteIntegration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegration(id: string, options?: any): AxiosPromise<IntegrationAppModel> {
            return localVarFp.appGetIntegration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration config
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationConfig(type: string, options?: any): AxiosPromise<IntegrationConfigAppModelV2> {
            return localVarFp.appGetIntegrationConfig(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of integration related errors
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationErrors(id: string, options?: any): AxiosPromise<PaginatedResponseIntegrationErrorAppModel> {
            return localVarFp.appGetIntegrationErrors(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {GetOauthInstallLinkModel} getOauthInstallLinkModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationInstallLink(type: string, getOauthInstallLinkModel: GetOauthInstallLinkModel, options?: any): AxiosPromise<LinkResponse> {
            return localVarFp.appGetIntegrationInstallLink(type, getOauthInstallLinkModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration permissions list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationPermissions(id: string, resourceType?: string, options?: any): AxiosPromise<PermissionDictionary> {
            return localVarFp.appGetIntegrationPermissions(id, resourceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration resources list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetIntegrationResources(id: string, resourceType?: string, options?: any): AxiosPromise<PaginatedResponseResourceAppModel> {
            return localVarFp.appGetIntegrationResources(id, resourceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list integration configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListIntegrationConfigs(options?: any): AxiosPromise<PaginatedResponseIntegrationConfigAppModelV2> {
            return localVarFp.appListIntegrationConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list integrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListIntegrations(options?: any): AxiosPromise<PaginatedResponseIntegrationAppModel> {
            return localVarFp.appListIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appRefreshIntegration(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.appRefreshIntegration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary search integration resources
         * @param {string} id 
         * @param {ResourcesSearchAppModel} resourcesSearchAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSearchIntegrationResources(id: string, resourcesSearchAppModel: ResourcesSearchAppModel, options?: any): AxiosPromise<PaginatedResponseResourceAppModel> {
            return localVarFp.appSearchIntegrationResources(id, resourcesSearchAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update integration
         * @param {string} id 
         * @param {UpdateIntegrationAppModel} updateIntegrationAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateIntegration(id: string, updateIntegrationAppModel: UpdateIntegrationAppModel, options?: any): AxiosPromise<IntegrationAppModel> {
            return localVarFp.appUpdateIntegration(id, updateIntegrationAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary check if integration can be deleted and not used by access flows
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteIntegrationV2(id: string, options?: any): AxiosPromise<CanDeleteIntegration> {
            return localVarFp.canDeleteIntegrationV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create integration
         * @param {CreateIntegration} createIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegrationV2(createIntegration: CreateIntegration, options?: any): AxiosPromise<Integration> {
            return localVarFp.createIntegrationV2(createIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationV2(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.deleteIntegrationV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} integrationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIntegrationAccessDetailsPreview(integrationType: string, options?: any): AxiosPromise<AccessDetailsPreviewAppModel> {
            return localVarFp.generateIntegrationAccessDetailsPreview(integrationType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsIntegrationInstallLink(options?: any): AxiosPromise<LinkResponse> {
            return localVarFp.getAwsIntegrationInstallLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalog(options?: any): AxiosPromise<IntegrationsCatalogModel> {
            return localVarFp.getCatalog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationConfig(type: string, options?: any): AxiosPromise<IntegrationConfigAppModel> {
            return localVarFp.getIntegrationConfig(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationInstallLink(type: string, options?: any): AxiosPromise<LinkResponse> {
            return localVarFp.getIntegrationInstallLink(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration permissions list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationPermissionsV2(id: string, options?: any): AxiosPromise<PermissionDictionary> {
            return localVarFp.getIntegrationPermissionsV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration resources list
         * @param {string} id 
         * @param {string} [resourceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationResourcesV2(id: string, resourceType?: string, options?: any): AxiosPromise<PaginatedResponseResourceDetailedModel> {
            return localVarFp.getIntegrationResourcesV2(id, resourceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationV2(id: string, options?: any): AxiosPromise<Integration> {
            return localVarFp.getIntegrationV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(options?: any): AxiosPromise<IntegrationsSummaryResponse> {
            return localVarFp.getSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installAwsIntegration(options?: any): AxiosPromise<void> {
            return localVarFp.installAwsIntegration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountsWithConnectors(options?: any): AxiosPromise<Array<AwsIntegrationAccountModel>> {
            return localVarFp.listAccountsWithConnectors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsIntegrations(options?: any): AxiosPromise<Array<AwsIntegration>> {
            return localVarFp.listAwsIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} awsAccountId 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsResources(awsAccountId: string, region?: string, options?: any): AxiosPromise<ListResourcesResponse> {
            return localVarFp.listAwsResources(awsAccountId, region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAzureIntegrations(options?: any): AxiosPromise<Array<AzureIntegration>> {
            return localVarFp.listAzureIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGcpIntegrations(options?: any): AxiosPromise<Array<GcpIntegrationModel>> {
            return localVarFp.listGcpIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list integrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrationsV2(options?: any): AxiosPromise<PaginatedResponseIntegrationModel> {
            return localVarFp.listIntegrationsV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} awsAccountId 
         * @param {string} [filter] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSecrets(awsAccountId: string, filter?: string, region?: string, options?: any): AxiosPromise<Array<AwsSecret>> {
            return localVarFp.listSecrets(awsAccountId, filter, region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshIntegrationV2(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.refreshIntegrationV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update integration
         * @param {string} id 
         * @param {UpdateIntegration} updateIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationV2(id: string, updateIntegration: UpdateIntegration, options?: any): AxiosPromise<Integration> {
            return localVarFp.updateIntegrationV2(id, updateIntegration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appCanDeleteIntegration operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppCanDeleteIntegrationRequest
 */
export interface IntegrationsApiAppCanDeleteIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppCanDeleteIntegration
     */
    readonly id: string
}

/**
 * Request parameters for appCreateIntegration operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppCreateIntegrationRequest
 */
export interface IntegrationsApiAppCreateIntegrationRequest {
    /**
     * 
     * @type {CreateIntegrationAppModel}
     * @memberof IntegrationsApiAppCreateIntegration
     */
    readonly createIntegrationAppModel: CreateIntegrationAppModel
}

/**
 * Request parameters for appDeleteIntegration operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppDeleteIntegrationRequest
 */
export interface IntegrationsApiAppDeleteIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppDeleteIntegration
     */
    readonly id: string
}

/**
 * Request parameters for appGetIntegration operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppGetIntegrationRequest
 */
export interface IntegrationsApiAppGetIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegration
     */
    readonly id: string
}

/**
 * Request parameters for appGetIntegrationConfig operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppGetIntegrationConfigRequest
 */
export interface IntegrationsApiAppGetIntegrationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationConfig
     */
    readonly type: string
}

/**
 * Request parameters for appGetIntegrationErrors operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppGetIntegrationErrorsRequest
 */
export interface IntegrationsApiAppGetIntegrationErrorsRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationErrors
     */
    readonly id: string
}

/**
 * Request parameters for appGetIntegrationInstallLink operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppGetIntegrationInstallLinkRequest
 */
export interface IntegrationsApiAppGetIntegrationInstallLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationInstallLink
     */
    readonly type: string

    /**
     * 
     * @type {GetOauthInstallLinkModel}
     * @memberof IntegrationsApiAppGetIntegrationInstallLink
     */
    readonly getOauthInstallLinkModel: GetOauthInstallLinkModel
}

/**
 * Request parameters for appGetIntegrationPermissions operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppGetIntegrationPermissionsRequest
 */
export interface IntegrationsApiAppGetIntegrationPermissionsRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationPermissions
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationPermissions
     */
    readonly resourceType?: string
}

/**
 * Request parameters for appGetIntegrationResources operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppGetIntegrationResourcesRequest
 */
export interface IntegrationsApiAppGetIntegrationResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationResources
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppGetIntegrationResources
     */
    readonly resourceType?: string
}

/**
 * Request parameters for appRefreshIntegration operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppRefreshIntegrationRequest
 */
export interface IntegrationsApiAppRefreshIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppRefreshIntegration
     */
    readonly id: string
}

/**
 * Request parameters for appSearchIntegrationResources operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppSearchIntegrationResourcesRequest
 */
export interface IntegrationsApiAppSearchIntegrationResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppSearchIntegrationResources
     */
    readonly id: string

    /**
     * 
     * @type {ResourcesSearchAppModel}
     * @memberof IntegrationsApiAppSearchIntegrationResources
     */
    readonly resourcesSearchAppModel: ResourcesSearchAppModel
}

/**
 * Request parameters for appUpdateIntegration operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiAppUpdateIntegrationRequest
 */
export interface IntegrationsApiAppUpdateIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiAppUpdateIntegration
     */
    readonly id: string

    /**
     * 
     * @type {UpdateIntegrationAppModel}
     * @memberof IntegrationsApiAppUpdateIntegration
     */
    readonly updateIntegrationAppModel: UpdateIntegrationAppModel
}

/**
 * Request parameters for canDeleteIntegrationV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiCanDeleteIntegrationV2Request
 */
export interface IntegrationsApiCanDeleteIntegrationV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiCanDeleteIntegrationV2
     */
    readonly id: string
}

/**
 * Request parameters for createIntegrationV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiCreateIntegrationV2Request
 */
export interface IntegrationsApiCreateIntegrationV2Request {
    /**
     * 
     * @type {CreateIntegration}
     * @memberof IntegrationsApiCreateIntegrationV2
     */
    readonly createIntegration: CreateIntegration
}

/**
 * Request parameters for deleteIntegrationV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiDeleteIntegrationV2Request
 */
export interface IntegrationsApiDeleteIntegrationV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiDeleteIntegrationV2
     */
    readonly id: string
}

/**
 * Request parameters for generateIntegrationAccessDetailsPreview operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiGenerateIntegrationAccessDetailsPreviewRequest
 */
export interface IntegrationsApiGenerateIntegrationAccessDetailsPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGenerateIntegrationAccessDetailsPreview
     */
    readonly integrationType: string
}

/**
 * Request parameters for getIntegrationConfig operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiGetIntegrationConfigRequest
 */
export interface IntegrationsApiGetIntegrationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGetIntegrationConfig
     */
    readonly type: string
}

/**
 * Request parameters for getIntegrationInstallLink operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiGetIntegrationInstallLinkRequest
 */
export interface IntegrationsApiGetIntegrationInstallLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGetIntegrationInstallLink
     */
    readonly type: string
}

/**
 * Request parameters for getIntegrationPermissionsV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiGetIntegrationPermissionsV2Request
 */
export interface IntegrationsApiGetIntegrationPermissionsV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGetIntegrationPermissionsV2
     */
    readonly id: string
}

/**
 * Request parameters for getIntegrationResourcesV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiGetIntegrationResourcesV2Request
 */
export interface IntegrationsApiGetIntegrationResourcesV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGetIntegrationResourcesV2
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGetIntegrationResourcesV2
     */
    readonly resourceType?: string
}

/**
 * Request parameters for getIntegrationV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiGetIntegrationV2Request
 */
export interface IntegrationsApiGetIntegrationV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiGetIntegrationV2
     */
    readonly id: string
}

/**
 * Request parameters for listAwsResources operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiListAwsResourcesRequest
 */
export interface IntegrationsApiListAwsResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiListAwsResources
     */
    readonly awsAccountId: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiListAwsResources
     */
    readonly region?: string
}

/**
 * Request parameters for listSecrets operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiListSecretsRequest
 */
export interface IntegrationsApiListSecretsRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiListSecrets
     */
    readonly awsAccountId: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiListSecrets
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiListSecrets
     */
    readonly region?: string
}

/**
 * Request parameters for refreshIntegrationV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiRefreshIntegrationV2Request
 */
export interface IntegrationsApiRefreshIntegrationV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiRefreshIntegrationV2
     */
    readonly id: string
}

/**
 * Request parameters for updateIntegrationV2 operation in IntegrationsApi.
 * @export
 * @interface IntegrationsApiUpdateIntegrationV2Request
 */
export interface IntegrationsApiUpdateIntegrationV2Request {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsApiUpdateIntegrationV2
     */
    readonly id: string

    /**
     * 
     * @type {UpdateIntegration}
     * @memberof IntegrationsApiUpdateIntegrationV2
     */
    readonly updateIntegration: UpdateIntegration
}

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @summary check if integration can be deleted and not used by access flows
     * @param {IntegrationsApiAppCanDeleteIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appCanDeleteIntegration(requestParameters: IntegrationsApiAppCanDeleteIntegrationRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appCanDeleteIntegration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create integration
     * @param {IntegrationsApiAppCreateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appCreateIntegration(requestParameters: IntegrationsApiAppCreateIntegrationRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appCreateIntegration(requestParameters.createIntegrationAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete integration
     * @param {IntegrationsApiAppDeleteIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appDeleteIntegration(requestParameters: IntegrationsApiAppDeleteIntegrationRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appDeleteIntegration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration
     * @param {IntegrationsApiAppGetIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appGetIntegration(requestParameters: IntegrationsApiAppGetIntegrationRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appGetIntegration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration config
     * @param {IntegrationsApiAppGetIntegrationConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appGetIntegrationConfig(requestParameters: IntegrationsApiAppGetIntegrationConfigRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appGetIntegrationConfig(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of integration related errors
     * @param {IntegrationsApiAppGetIntegrationErrorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appGetIntegrationErrors(requestParameters: IntegrationsApiAppGetIntegrationErrorsRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appGetIntegrationErrors(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationsApiAppGetIntegrationInstallLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appGetIntegrationInstallLink(requestParameters: IntegrationsApiAppGetIntegrationInstallLinkRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appGetIntegrationInstallLink(requestParameters.type, requestParameters.getOauthInstallLinkModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration permissions list
     * @param {IntegrationsApiAppGetIntegrationPermissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appGetIntegrationPermissions(requestParameters: IntegrationsApiAppGetIntegrationPermissionsRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appGetIntegrationPermissions(requestParameters.id, requestParameters.resourceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration resources list
     * @param {IntegrationsApiAppGetIntegrationResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appGetIntegrationResources(requestParameters: IntegrationsApiAppGetIntegrationResourcesRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appGetIntegrationResources(requestParameters.id, requestParameters.resourceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list integration configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appListIntegrationConfigs(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appListIntegrationConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list integrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appListIntegrations(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appListIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh integration
     * @param {IntegrationsApiAppRefreshIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appRefreshIntegration(requestParameters: IntegrationsApiAppRefreshIntegrationRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appRefreshIntegration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary search integration resources
     * @param {IntegrationsApiAppSearchIntegrationResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appSearchIntegrationResources(requestParameters: IntegrationsApiAppSearchIntegrationResourcesRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appSearchIntegrationResources(requestParameters.id, requestParameters.resourcesSearchAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update integration
     * @param {IntegrationsApiAppUpdateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public appUpdateIntegration(requestParameters: IntegrationsApiAppUpdateIntegrationRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).appUpdateIntegration(requestParameters.id, requestParameters.updateIntegrationAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary check if integration can be deleted and not used by access flows
     * @param {IntegrationsApiCanDeleteIntegrationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public canDeleteIntegrationV2(requestParameters: IntegrationsApiCanDeleteIntegrationV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).canDeleteIntegrationV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create integration
     * @param {IntegrationsApiCreateIntegrationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public createIntegrationV2(requestParameters: IntegrationsApiCreateIntegrationV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).createIntegrationV2(requestParameters.createIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete integration
     * @param {IntegrationsApiDeleteIntegrationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public deleteIntegrationV2(requestParameters: IntegrationsApiDeleteIntegrationV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).deleteIntegrationV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationsApiGenerateIntegrationAccessDetailsPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public generateIntegrationAccessDetailsPreview(requestParameters: IntegrationsApiGenerateIntegrationAccessDetailsPreviewRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).generateIntegrationAccessDetailsPreview(requestParameters.integrationType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getAwsIntegrationInstallLink(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getAwsIntegrationInstallLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getCatalog(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getCatalog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationsApiGetIntegrationConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getIntegrationConfig(requestParameters: IntegrationsApiGetIntegrationConfigRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getIntegrationConfig(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationsApiGetIntegrationInstallLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getIntegrationInstallLink(requestParameters: IntegrationsApiGetIntegrationInstallLinkRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getIntegrationInstallLink(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration permissions list
     * @param {IntegrationsApiGetIntegrationPermissionsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getIntegrationPermissionsV2(requestParameters: IntegrationsApiGetIntegrationPermissionsV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getIntegrationPermissionsV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration resources list
     * @param {IntegrationsApiGetIntegrationResourcesV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getIntegrationResourcesV2(requestParameters: IntegrationsApiGetIntegrationResourcesV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getIntegrationResourcesV2(requestParameters.id, requestParameters.resourceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get integration
     * @param {IntegrationsApiGetIntegrationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getIntegrationV2(requestParameters: IntegrationsApiGetIntegrationV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getIntegrationV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getSummary(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public installAwsIntegration(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).installAwsIntegration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listAccountsWithConnectors(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listAccountsWithConnectors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listAwsIntegrations(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listAwsIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationsApiListAwsResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listAwsResources(requestParameters: IntegrationsApiListAwsResourcesRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listAwsResources(requestParameters.awsAccountId, requestParameters.region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listAzureIntegrations(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listAzureIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listGcpIntegrations(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listGcpIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list integrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listIntegrationsV2(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listIntegrationsV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationsApiListSecretsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listSecrets(requestParameters: IntegrationsApiListSecretsRequest, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listSecrets(requestParameters.awsAccountId, requestParameters.filter, requestParameters.region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh integration
     * @param {IntegrationsApiRefreshIntegrationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public refreshIntegrationV2(requestParameters: IntegrationsApiRefreshIntegrationV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).refreshIntegrationV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update integration
     * @param {IntegrationsApiUpdateIntegrationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public updateIntegrationV2(requestParameters: IntegrationsApiUpdateIntegrationV2Request, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).updateIntegrationV2(requestParameters.id, requestParameters.updateIntegration, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('authorize', 'clientId', clientId)
            const localVarPath = `/apn/auth/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.authorize(clientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authorize operation in OAuthApi.
 * @export
 * @interface OAuthApiAuthorizeRequest
 */
export interface OAuthApiAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof OAuthApiAuthorize
     */
    readonly clientId: string
}

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * 
     * @param {OAuthApiAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public authorize(requestParameters: OAuthApiAuthorizeRequest, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).authorize(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(filters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(filters?: string, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.search(filters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for search operation in SearchApi.
 * @export
 * @interface SearchApiSearchRequest
 */
export interface SearchApiSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchApiSearch
     */
    readonly filters?: string
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {SearchApiSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public search(requestParameters: SearchApiSearchRequest = {}, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).search(requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionApiModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options?: any): AxiosPromise<UserSessionApiModel> {
            return localVarFp.getSession(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getSession(options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).getSession(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamsApi - axios parameter creator
 * @export
 */
export const TeamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {string} [state] 
         * @param {string} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleTeamsOauthRequest: async (error?: string, errorDescription?: string, state?: string, tenant?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/teams/oauth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['error_description'] = errorDescription;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamsApi - functional programming interface
 * @export
 */
export const TeamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {string} [state] 
         * @param {string} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleTeamsOauthRequest(error?: string, errorDescription?: string, state?: string, tenant?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleTeamsOauthRequest(error, errorDescription, state, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamsApi - factory interface
 * @export
 */
export const TeamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {string} [state] 
         * @param {string} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleTeamsOauthRequest(error?: string, errorDescription?: string, state?: string, tenant?: string, options?: any): AxiosPromise<void> {
            return localVarFp.handleTeamsOauthRequest(error, errorDescription, state, tenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for handleTeamsOauthRequest operation in TeamsApi.
 * @export
 * @interface TeamsApiHandleTeamsOauthRequestRequest
 */
export interface TeamsApiHandleTeamsOauthRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamsApiHandleTeamsOauthRequest
     */
    readonly error?: string

    /**
     * 
     * @type {string}
     * @memberof TeamsApiHandleTeamsOauthRequest
     */
    readonly errorDescription?: string

    /**
     * 
     * @type {string}
     * @memberof TeamsApiHandleTeamsOauthRequest
     */
    readonly state?: string

    /**
     * 
     * @type {string}
     * @memberof TeamsApiHandleTeamsOauthRequest
     */
    readonly tenant?: string
}

/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
export class TeamsApi extends BaseAPI {
    /**
     * 
     * @param {TeamsApiHandleTeamsOauthRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public handleTeamsOauthRequest(requestParameters: TeamsApiHandleTeamsOauthRequestRequest = {}, options?: AxiosRequestConfig) {
        return TeamsApiFp(this.configuration).handleTeamsOauthRequest(requestParameters.error, requestParameters.errorDescription, requestParameters.state, requestParameters.tenant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableAttributes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v1/users/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableAttributesV2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/core/api/v2/users/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailableAttributes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAvailableAttributesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailableAttributes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailableAttributesV2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttributesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailableAttributesV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableAttributes(options?: any): AxiosPromise<ListAvailableAttributesResponse> {
            return localVarFp.listAvailableAttributes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableAttributesV2(options?: any): AxiosPromise<ListAttributesResponse> {
            return localVarFp.listAvailableAttributesV2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listAvailableAttributes(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listAvailableAttributes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listAvailableAttributesV2(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listAvailableAttributesV2(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WebhookUpdateAppModel} webhookUpdateAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateWebhook: async (webhookUpdateAppModel: WebhookUpdateAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookUpdateAppModel' is not null or undefined
            assertParamExists('appCreateWebhook', 'webhookUpdateAppModel', webhookUpdateAppModel)
            const localVarPath = `/apn/webhooks/api/app/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookUpdateAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteWebhook: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appDeleteWebhook', 'id', id)
            const localVarPath = `/apn/webhooks/api/app/webhooks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetWebhook: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetWebhook', 'id', id)
            const localVarPath = `/apn/webhooks/api/app/webhooks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetWebhookHistory: async (id: string, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appGetWebhookHistory', 'id', id)
            const localVarPath = `/apn/webhooks/api/app/webhooks/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<WebhookTriggerEventAppModel>} [eventTypes] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListWebhooks: async (eventTypes?: Array<WebhookTriggerEventAppModel>, limit?: number, search?: string, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apn/webhooks/api/app/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventTypes) {
                localVarQueryParameter['event-types'] = eventTypes;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WebhookTestAppModel} webhookTestAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTestWebhook: async (webhookTestAppModel: WebhookTestAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookTestAppModel' is not null or undefined
            assertParamExists('appTestWebhook', 'webhookTestAppModel', webhookTestAppModel)
            const localVarPath = `/apn/webhooks/api/app/webhooks-test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookTestAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WebhookUpdateAppModel} webhookUpdateAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateWebhook: async (id: string, webhookUpdateAppModel: WebhookUpdateAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appUpdateWebhook', 'id', id)
            // verify required parameter 'webhookUpdateAppModel' is not null or undefined
            assertParamExists('appUpdateWebhook', 'webhookUpdateAppModel', webhookUpdateAppModel)
            const localVarPath = `/apn/webhooks/api/app/webhooks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookUpdateAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WebhookUpdateAppModel} webhookUpdateAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appCreateWebhook(webhookUpdateAppModel: WebhookUpdateAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appCreateWebhook(webhookUpdateAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appDeleteWebhook(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appDeleteWebhook(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetWebhook(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetWebhook(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetWebhookHistory(id: string, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseWebhookInvocationAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetWebhookHistory(id, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<WebhookTriggerEventAppModel>} [eventTypes] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appListWebhooks(eventTypes?: Array<WebhookTriggerEventAppModel>, limit?: number, search?: string, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseWebhookAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appListWebhooks(eventTypes, limit, search, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WebhookTestAppModel} webhookTestAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appTestWebhook(webhookTestAppModel: WebhookTestAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookInvocationAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appTestWebhook(webhookTestAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {WebhookUpdateAppModel} webhookUpdateAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appUpdateWebhook(id: string, webhookUpdateAppModel: WebhookUpdateAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookAppModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appUpdateWebhook(id, webhookUpdateAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {WebhookUpdateAppModel} webhookUpdateAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appCreateWebhook(webhookUpdateAppModel: WebhookUpdateAppModel, options?: any): AxiosPromise<WebhookAppModel> {
            return localVarFp.appCreateWebhook(webhookUpdateAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appDeleteWebhook(id: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.appDeleteWebhook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetWebhook(id: string, options?: any): AxiosPromise<WebhookAppModel> {
            return localVarFp.appGetWebhook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetWebhookHistory(id: string, limit?: number, skip?: number, options?: any): AxiosPromise<PaginatedResponseWebhookInvocationAppModel> {
            return localVarFp.appGetWebhookHistory(id, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<WebhookTriggerEventAppModel>} [eventTypes] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appListWebhooks(eventTypes?: Array<WebhookTriggerEventAppModel>, limit?: number, search?: string, skip?: number, options?: any): AxiosPromise<PaginatedResponseWebhookAppModel> {
            return localVarFp.appListWebhooks(eventTypes, limit, search, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhookTestAppModel} webhookTestAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appTestWebhook(webhookTestAppModel: WebhookTestAppModel, options?: any): AxiosPromise<WebhookInvocationAppModel> {
            return localVarFp.appTestWebhook(webhookTestAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WebhookUpdateAppModel} webhookUpdateAppModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appUpdateWebhook(id: string, webhookUpdateAppModel: WebhookUpdateAppModel, options?: any): AxiosPromise<WebhookAppModel> {
            return localVarFp.appUpdateWebhook(id, webhookUpdateAppModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appCreateWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppCreateWebhookRequest
 */
export interface WebhooksApiAppCreateWebhookRequest {
    /**
     * 
     * @type {WebhookUpdateAppModel}
     * @memberof WebhooksApiAppCreateWebhook
     */
    readonly webhookUpdateAppModel: WebhookUpdateAppModel
}

/**
 * Request parameters for appDeleteWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppDeleteWebhookRequest
 */
export interface WebhooksApiAppDeleteWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiAppDeleteWebhook
     */
    readonly id: string
}

/**
 * Request parameters for appGetWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppGetWebhookRequest
 */
export interface WebhooksApiAppGetWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiAppGetWebhook
     */
    readonly id: string
}

/**
 * Request parameters for appGetWebhookHistory operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppGetWebhookHistoryRequest
 */
export interface WebhooksApiAppGetWebhookHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiAppGetWebhookHistory
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof WebhooksApiAppGetWebhookHistory
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof WebhooksApiAppGetWebhookHistory
     */
    readonly skip?: number
}

/**
 * Request parameters for appListWebhooks operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppListWebhooksRequest
 */
export interface WebhooksApiAppListWebhooksRequest {
    /**
     * 
     * @type {Array<WebhookTriggerEventAppModel>}
     * @memberof WebhooksApiAppListWebhooks
     */
    readonly eventTypes?: Array<WebhookTriggerEventAppModel>

    /**
     * 
     * @type {number}
     * @memberof WebhooksApiAppListWebhooks
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof WebhooksApiAppListWebhooks
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof WebhooksApiAppListWebhooks
     */
    readonly skip?: number
}

/**
 * Request parameters for appTestWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppTestWebhookRequest
 */
export interface WebhooksApiAppTestWebhookRequest {
    /**
     * 
     * @type {WebhookTestAppModel}
     * @memberof WebhooksApiAppTestWebhook
     */
    readonly webhookTestAppModel: WebhookTestAppModel
}

/**
 * Request parameters for appUpdateWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiAppUpdateWebhookRequest
 */
export interface WebhooksApiAppUpdateWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiAppUpdateWebhook
     */
    readonly id: string

    /**
     * 
     * @type {WebhookUpdateAppModel}
     * @memberof WebhooksApiAppUpdateWebhook
     */
    readonly webhookUpdateAppModel: WebhookUpdateAppModel
}

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {WebhooksApiAppCreateWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appCreateWebhook(requestParameters: WebhooksApiAppCreateWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appCreateWebhook(requestParameters.webhookUpdateAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiAppDeleteWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appDeleteWebhook(requestParameters: WebhooksApiAppDeleteWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appDeleteWebhook(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiAppGetWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appGetWebhook(requestParameters: WebhooksApiAppGetWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appGetWebhook(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiAppGetWebhookHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appGetWebhookHistory(requestParameters: WebhooksApiAppGetWebhookHistoryRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appGetWebhookHistory(requestParameters.id, requestParameters.limit, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiAppListWebhooksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appListWebhooks(requestParameters: WebhooksApiAppListWebhooksRequest = {}, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appListWebhooks(requestParameters.eventTypes, requestParameters.limit, requestParameters.search, requestParameters.skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiAppTestWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appTestWebhook(requestParameters: WebhooksApiAppTestWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appTestWebhook(requestParameters.webhookTestAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiAppUpdateWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appUpdateWebhook(requestParameters: WebhooksApiAppUpdateWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appUpdateWebhook(requestParameters.id, requestParameters.webhookUpdateAppModel, options).then((request) => request(this.axios, this.basePath));
    }
}


