import { Page } from '@components';
import { routes } from '@routes';
import { Button } from '@mui/material';
import { useState } from 'react';
import { SettingsOutlined } from '@mui/icons-material';
import { AddAccessFlowPageContent } from '@AccessFlows/organisms/AddAccessFlowPageContent';

export function AddAccessFlowPage() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [displaySettingsButton, setDisplaySettingsButton] = useState(true);

  const onSaveAccessFlow = () => setDisplaySettingsButton(false);

  return (
    <Page
      specialLayout
      title="Create Access Flow"
      breadcrumbs={[
        {
          label: routes.AccessFlows.label,
          href: routes.AccessFlows.path,
        },
      ]}
      button={
        displaySettingsButton ? (
          <Button
            variant="text"
            onClick={() => setIsDialogOpen(true)}
            startIcon={<SettingsOutlined />}
            sx={{ color: 'text.primary' }}
          >
            Settings
          </Button>
        ) : null
      }
    >
      <AddAccessFlowPageContent
        isSettingsOpen={isDialogOpen}
        onCloseSettings={() => setIsDialogOpen(false)}
        paths={{ donePath: routes.AccessFlows.path }}
        onSaveAccessFlow={onSaveAccessFlow}
      />
    </Page>
  );
}
