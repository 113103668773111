import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { ActivityApiGetReportPreviewRequest, ActivityFilter, ActivityReportExportModel } from '@api/apn-core';
import { ActivityService } from '@api/services';
import { QueryKeys } from './query-keys';

const REFETCH_INTERVAL = 10 * 1000; // 10 sec

export function useListActivityFilterOptions(filterName: ActivityFilter, enabled = true, refetch = false) {
  const {
    data: options,
    isFetched: isOptionsFetched,
    refetch: refetchQuery,
  } = useQuery({
    queryKey: [QueryKeys.ActivityAutocomplete, filterName],
    queryFn: async () => {
      const { data } = await ActivityService.listActivityFilterOptions({
        filterName,
      });
      return data;
    },
    initialData: [],
    refetchOnWindowFocus: false,
    refetchInterval: refetch ? REFETCH_INTERVAL : false,
    enabled,
  });

  return {
    options,
    isOptionsFetched,
    refetchQuery,
  };
}

export function useGetReportPreview(props: ActivityApiGetReportPreviewRequest) {
  const queryClient = useQueryClient();
  const [previousProps, setPreviousProps] = useState<ActivityApiGetReportPreviewRequest>(props);
  const [isReportPreviewFetched, setReportPreviewFetched] = useState<boolean>(false);

  const { data: reportPreview, isFetched } = useQuery({
    queryKey: [QueryKeys.ActivityReports, props],
    queryFn: async () => {
      setPreviousProps(props);

      return ActivityService.getReportPreview(props);
    },
    keepPreviousData: true,
    initialData: () => {
      const previousData = queryClient.getQueryData<ActivityReportExportModel[]>([
        QueryKeys.ActivityReports,
        previousProps,
      ]);

      if (previousData) {
        return previousData;
      }

      return [];
    },
    onSuccess: () => {
      if (!isReportPreviewFetched) setReportPreviewFetched(true);
    },
    refetchOnWindowFocus: false,
  });

  return {
    reportPreview,
    isReportPreviewFetched,
    isReportPreviewLoading: !isFetched,
  };
}

export function useGetReportFile() {
  const [fileName, setFileName] = useState<string>('report.csv');

  const { mutate: getReportFile, isLoading: isGetReportFileLoading } = useMutation({
    mutationFn: async (props: ActivityApiGetReportPreviewRequest) => {
      const response = await ActivityService.getReportFile(props, {
        responseType: 'blob',
        transformResponse: (data, headers) => {
          const contentDisposition = headers['content-disposition'];
          const responseFileName = contentDisposition?.split('filename=')[1];
          if (responseFileName) setFileName(responseFileName);
          return data;
        },
      });

      const url = window.URL.createObjectURL(new Blob([response as unknown as string]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },
  });

  return {
    getReportFile,
    isGetReportFileLoading,
  };
}
