import { PaginationInfo } from '@api';
import { Pagination, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

export default function TablePagination({
  pagination,
  isLoading,
  onChange,
}: {
  pagination: PaginationInfo;
  isLoading: boolean;
  onChange: (page: number) => void;
}) {
  const count = useMemo(() => Math.ceil(pagination.total / pagination.limit), [pagination]);
  const page = useMemo(() => Math.floor(pagination.offset / pagination.limit) + 1, [pagination]);

  const paginationText = useMemo(() => {
    if (pagination.total <= pagination.limit) {
      return 'all';
    }

    const requestsCount =
      pagination.offset + pagination.limit > pagination.total ? pagination.total - pagination.offset : pagination.limit;

    return `${pagination.offset + 1} - ${pagination.offset + requestsCount}`;
  }, [pagination]);

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Typography variant="tooltip" color="text.secondary">
        Showing {paginationText} of {pagination.total} requests
      </Typography>
      <Pagination count={count} page={page} onChange={(_, newPage) => onChange(newPage)} disabled={isLoading} />
    </Stack>
  );
}
