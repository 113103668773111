import { Box, Card, CardContent, Stack } from '@mui/material';

import { AccessBundleAppModel } from '@api';
import { NoResults } from '@components';
import AccessFlowItemTargets from './AccessFlowListItemColumns/AccessFlowItemTargets';
import { TargetItem } from '@AccessFlows/common/types';
import BundleItemGrid from '@AccessFlows/components/BundleItemGrid';
import TableListTitle from '@common/components/TableListTitle';
import BundleItemName from './BundleListItemColumns/BundleItemName';
import BundleItemActions from './BundleListItemColumns/BundleItemActions';
import { generatePath } from 'react-router-dom';
import { routes } from '@routes';

interface BundlesLisProps {
  bundlesItems: BundlesListItemProps[];
}

export default function BundlesList({ bundlesItems }: BundlesLisProps) {
  if (bundlesItems.length === 0) {
    return <NoResults>No access flows found</NoResults>;
  }

  return (
    <Stack direction="column" spacing={2}>
      <BundlesListHeader />
      {bundlesItems.map((item) => (
        <BundlesListItem key={item.bundle.id} {...item} />
      ))}
    </Stack>
  );
}

function BundlesListHeader() {
  return (
    <Box>
      <BundleItemGrid
        bundle={<TableListTitle pl={2}>BUNDLE</TableListTitle>}
        targets={<TableListTitle>ACCESS TARGETS</TableListTitle>}
        actions={<TableListTitle pr={2}>ACTIONS</TableListTitle>}
      />
    </Box>
  );
}

export interface BundlesListItemProps {
  bundle: AccessBundleAppModel;
  targets: TargetItem[];
}

function BundlesListItem({ bundle, targets }: BundlesListItemProps) {
  return (
    <Card elevation={0}>
      <CardContent>
        <BundleItemGrid
          bundle={<BundleItemName bundle={bundle} />}
          targets={
            <AccessFlowItemTargets
              targets={targets}
              moreLink={generatePath(routes.EditBundle.path, { id: bundle.id })}
            />
          }
          actions={<BundleItemActions bundle={bundle} />}
        />
      </CardContent>
    </Card>
  );
}
