import { useMemo } from 'react';

import { Button, Skeleton, Stack } from '@mui/material';

import { AccessFlowUpsertAppModelV2, GranteeFilterGroupAppModel } from '@api';

import AccessFlowItemGrid from '@AccessFlows/components/AccessFlowItemGrid';
import AccessFlowReviewModal from '@AccessFlows/components/AccessFlowReviewModal';

import { useAccessFlowFetch } from '@AccessFlows/hooks/use-access-flows-fetch';

import AccessFlowItemTargetChip from '../components/AccessFlowItemTargetChip';
import { CustomAnalyticsEvents } from '@utils/analytics';
import AccessFlowItemAdvancedGrantees from './AccessFlowListItemColumns/AccessFlowItemAdvancedGrantees';

interface AutoGrantReviewModalV2Props {
  onClose: () => void;
  formData: AccessFlowUpsertAppModelV2;
  granteesV2: GranteeFilterGroupAppModel;
  onApprove: () => void;
}

export default function AutoGrantReviewModalV2({
  onClose,
  formData,
  granteesV2,
  onApprove,
}: AutoGrantReviewModalV2Props) {
  const { processAccessFlow, remappedLoadingProgress } = useAccessFlowFetch();

  const { targets } = processAccessFlow({
    id: '',
    ...formData,
    grantees: [],
    granteesV2,
    require_all_approvers: false,
    created_date: new Date().getTime() / 1000,
  });

  const isLoading = useMemo(() => remappedLoadingProgress.some((p) => !p), [remappedLoadingProgress]);

  return (
    <AccessFlowReviewModal
      title="Automatic Access Flow Summary"
      subTitle={formData.name}
      open
      onClose={onClose}
      text="Click Create and Grant to automatically grant access to these grantees."
      width={1200}
      actions={
        <Button
          onClick={onApprove}
          variant="contained"
          sx={{ alignSelf: 'flex-end' }}
          data-trigger={CustomAnalyticsEvents.CREATE_AND_GRANT_CLICKED}
        >
          Create and Grant
        </Button>
      }
    >
      <AccessFlowItemGrid
        grantees={
          isLoading ? (
            <Skeleton variant="rounded" width={120} height={32} />
          ) : (
            <AccessFlowItemAdvancedGrantees grantees={granteesV2} />
          )
        }
        targets={
          isLoading ? (
            <Skeleton variant="rounded" width={300} height={32} />
          ) : (
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              {targets.map((target, i) => (
                <AccessFlowItemTargetChip key={i} target={target} />
              ))}
            </Stack>
          )
        }
      />
    </AccessFlowReviewModal>
  );
}
