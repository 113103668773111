import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Tooltip } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import {
  InteractiveDropdownFilterInput,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownSelectOption,
  InteractiveDropdownView,
} from '@components';
import { useIsIntegrationCanBeUsed } from '@hooks';
import { IntegrationFamilyType, IntegrationStatus, useGetCatalogV2, useListIntegrationsV2 } from '@api';
import { routes } from '@routes';
import { useOnboardingContext } from '@Onboarding/context';

interface DropdownIntegrationsViewProps {
  current: boolean;
  value?: string;
  onIntegrationChange: (integration: string) => void;
  onBackClick?: () => void;
}

function useDropdownIntegrationsView() {
  const { integrations, isIntegrationsFetched } = useListIntegrationsV2();
  const { integrationsConfigs, isIntegrationsConfigsFetched } = useGetCatalogV2();

  const isCanBeUsed = useIsIntegrationCanBeUsed();

  const [filter, setFilter] = useState('');
  const handleFilterChange = (val: string) => setFilter(val);

  const remappedIntegrations: InteractiveDropdownSelectOption<string>[] = useMemo(
    () =>
      integrations
        .filter((ci) => {
          const config = integrationsConfigs.find((с) => с.type === ci.type);
          return config && config.family_type === IntegrationFamilyType.ResourceIntegration;
        })
        .map((ci) => {
          const config = integrationsConfigs.find((с) => с.type === ci.type);
          const canBeUsed = isCanBeUsed(ci.status);
          return {
            key: ci.id,
            value: ci.name,
            iconSrc: config?.icons.svg,
            secondary: config?.name,
            hasNextView: true,
            isDisabled: !canBeUsed,
            rightIcon:
              ci.status === IntegrationStatus.Error && !canBeUsed ? (
                <Tooltip title="Error in integration">
                  <WarningAmberOutlinedIcon fontSize={'small'} color={'warning'} />
                </Tooltip>
              ) : null,
          };
        }),
    [integrations, integrationsConfigs, isCanBeUsed],
  );

  const options = useMemo(
    () =>
      remappedIntegrations.filter((v) =>
        `${v.key} ${v.value} ${v.secondary}`.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
      ),
    [filter, remappedIntegrations],
  );

  const isFetched = useMemo(
    () => isIntegrationsFetched && isIntegrationsConfigsFetched,
    [isIntegrationsFetched, isIntegrationsConfigsFetched],
  );

  return {
    options,
    filter,
    handleFilterChange,
    isFetched,
  };
}

export function DropdownIntegrationsView({
  current,
  value,
  onIntegrationChange,
  onBackClick,
}: DropdownIntegrationsViewProps) {
  const { isInOnboarding } = useOnboardingContext();
  const { options, filter, handleFilterChange, isFetched } = useDropdownIntegrationsView();

  const linkOptions = useMemo(() => {
    if (isInOnboarding) return [];
    return [
      <Link to={routes.Catalog.path} key="add-integration" component={RouterLink}>
        + Add new integration
      </Link>,
    ];
  }, [isInOnboarding]);

  if (!isFetched) {
    return <InteractiveDropdownView current loading />;
  }

  return (
    <InteractiveDropdownView
      current={current}
      header={<InteractiveDropdownHeader title="Select integration" onBackClick={onBackClick} />}
      filter={<InteractiveDropdownFilterInput value={filter} onChange={handleFilterChange} />}
    >
      <InteractiveDropdownSelect<string>
        options={options}
        value={value}
        onChange={onIntegrationChange}
        hideEmptyState
        linkOptions={linkOptions}
        selectedFirst
      />
    </InteractiveDropdownView>
  );
}
