import { useMemo, useState } from 'react';

import { ButtonBase, styled, TextField, Typography, TypographyProps } from '@mui/material';
import { yup } from '@libs';

import { FlowFormLine } from '../common/Line';
import { ErrorTooltip } from '../common/Text';
import { HumanReadableText } from '../common/HumanReadableText';

const NameField = styled(TextField)({
  width: '100%',
});

const NameTypography = styled((props: TypographyProps) => <Typography {...props} variant="body3" color="primary" />)(
  ({ theme }) => ({
    display: 'block',
    padding: theme.spacing(0.7, 0),
  }),
);

export interface FlowFormNameProps {
  value: string;
  onChange: (name: string) => void;
  placeholder?: string;
  error?: yup.ValidationError;
}

export function FlowFormName({ value, onChange, error, placeholder }: FlowFormNameProps) {
  const [inputValue, setInputValue] = useState<string>(value);
  const [editActive, setEditActive] = useState<boolean>(false);

  const hasError = useMemo(() => !!error, [error]);

  const handleOnChange = (selectedInput: string) => {
    setInputValue(selectedInput);
  };

  const handleOnBlur = () => {
    onChange(inputValue);
    setEditActive(false);
  };

  const content = useMemo(() => {
    if (editActive) {
      return (
        <FlowFormNameInput value={inputValue} onChange={handleOnChange} onBlur={handleOnBlur} hasError={hasError} />
      );
    }

    return (
      <ButtonBase data-testid="flow-form-name" onClick={() => setEditActive(true)}>
        {value ? (
          <NameTypography>{value}</NameTypography>
        ) : (
          <HumanReadableText placeholder={placeholder} placeholderMuted />
        )}
      </ButtonBase>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, editActive, hasError]);

  if (error) {
    return (
      <FlowFormLine>
        <ErrorTooltip errorText={error.message}>{content}</ErrorTooltip>
      </FlowFormLine>
    );
  }

  return <FlowFormLine>{content}</FlowFormLine>;
}

function FlowFormNameInput({
  value,
  hasError,
  onBlur,
  onChange,
}: {
  value: string;
  hasError: boolean;
  onBlur: () => void;
  onChange: (name: string) => void;
}) {
  return (
    <NameField
      data-testid="flow-form-name-input"
      autoFocus
      placeholder="Access Flow description"
      variant="standard"
      size="small"
      value={value}
      inputProps={{ maxLength: 100 }}
      onBlur={onBlur}
      onChange={(v) => onChange(v.target.value)}
      error={hasError}
    />
  );
}
