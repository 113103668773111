import { MaterialIcon, SelectDropdown, SelectDropdownOption } from '@components';
import { Button } from '@mui/material';

import { ReportField } from '@api';

import { useReportContext } from './ReportProvider';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '@utils';

function useReportColumnsSelect() {
  const [qp, setQp] = useSearchParams();
  const { columns, selectedColumns } = useReportContext();

  const options: SelectDropdownOption<ReportField>[] = columns.map((c) => ({
    value: c,
    label: c,
  }));

  const handleOnChange = (value: ReportField[]) => {
    qp.set(QueryParams.fields, columns.filter((c) => value.includes(c)).join(','));
    setQp(qp);
  };

  return {
    selectedColumns,
    options,
    handleOnChange,
  };
}

export function ReportColumnsSelect() {
  const { selectedColumns, options, handleOnChange } = useReportColumnsSelect();

  return (
    <SelectDropdown<ReportField>
      multiple
      options={options}
      trigger={
        <Button
          size="small"
          variant="outlined-filters"
          startIcon={<MaterialIcon symbol="visibility_off" />}
          endIcon={<MaterialIcon symbol="expand_more" />}
          component="div"
        >
          Show/Hide columns
        </Button>
      }
      title="Choose columns to display"
      value={selectedColumns}
      onChange={handleOnChange}
    />
  );
}
