import { ReactNode } from 'react';
import { IntegrationConfigAppModelV2 } from '@api';
import { FormSection } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSection';
import { FormSectionSummary } from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionSummary';
import { SecretStoreForm } from '@Integrations/organisms/IntegrationConfigForm/ConfigForm/SecretStoreForm/SecretStoreForm';

export function SecretStoreFormSection({
  config,
  actions,
  expanded,
  isEdit,
  onSummaryClick,
}: {
  config: IntegrationConfigAppModelV2;
  actions?: ReactNode;
  expanded?: boolean;
  isEdit?: boolean;
  onSummaryClick?: () => void;
}) {
  return (
    <FormSection
      expanded={expanded}
      summary={
        <FormSectionSummary
          title="Secret Store"
          onClick={onSummaryClick}
          withIcon={!isEdit}
          optional={!config.requires_secret}
        />
      }
      actions={expanded && actions}
    >
      <SecretStoreForm
        withTitle={false}
        requires_secret={config.requires_secret}
        supported_secret_types={config.supported_secret_types}
        secret_params={config.secret_params}
      />
    </FormSection>
  );
}
