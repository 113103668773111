import { useState } from 'react';

export function useErrorText() {
  const [errorTextHidden, setErrorTextHidden] = useState(false);

  const hideErrorText = () => {
    setErrorTextHidden(true);
  };

  const showErrorText = () => {
    setErrorTextHidden(false);
  };

  return {
    errorTextHidden,
    hideErrorText,
    showErrorText,
  };
}
