import { ActivityRequestAppModel } from '@api';
import { ResourceDetailedItem } from '@components';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

export default function AggregatedResourcesCell({
  accessUnitsCount,
  accessUnitsPreview,
  accessBundle,
}: {
  accessUnitsCount: ActivityRequestAppModel['access_units_count'];
  accessUnitsPreview: ActivityRequestAppModel['access_units_preview'];
  accessBundle: ActivityRequestAppModel['access_bundle'];
}) {
  const resourceLine = useMemo(() => {
    if (accessUnitsPreview.length === 0) {
      return (
        <Typography variant="tooltip" color="text.muted">
          -
        </Typography>
      );
    }

    if (accessUnitsPreview.length > 1) {
      return (
        <Typography variant="tooltip" color="text.muted">
          <strong>{accessUnitsCount}</strong> resources
        </Typography>
      );
    }

    return (
      <ResourceDetailedItem
        displayName={accessUnitsPreview[0].resource.name}
        literalPath={Object.values(accessUnitsPreview[0].resource.path).join('/')}
      />
    );
  }, [accessUnitsCount, accessUnitsPreview]);

  const bundleLine = useMemo(() => {
    if (!accessBundle) {
      return null;
    }

    return (
      <Typography variant="tooltip" color="text.muted">
        <strong>Bundle:</strong> {accessBundle.name}
      </Typography>
    );
  }, [accessBundle]);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      {bundleLine}
      {resourceLine}
    </Stack>
  );
}
