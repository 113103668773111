import { IntegrationCategoryAppModel, IntegrationConfigAppModelV2 } from '@api';
import { generatePath } from 'react-router-dom';
import { kababToCapitalCase } from '@utils';

// ------------------- Enums -------------------
export enum Category {
  Resources = 'Resources',
  UserInformation = 'User Information',
  Communication = 'Communication',
}

export enum CategoryDescription {
  Resources = "Add Resource integrations to sync Apono with data on resources and permissions, then automate  users' access to resource with Access Flows",
  UserInformation = 'Integrate your identity provider and on-call integrations to provide Apono with context on users: manager, department, shift, and more. You can only integrate 1 IdP.',
  Communication = 'Integrate your ChatOps integration so that users can request access and approve requests seamlessly.',
}

export const categoriesOrder: string[] = Object.keys(Category);

export const integrationGroups: { [key: string]: string } = {
  'amazon-web-services': 'AWS',
  'microsoft-azure': 'Azure',
  'google-cloud': 'GCP',
  okta: 'Okta',
};

// ------------------- Types -------------------
export interface ConfigsListPaths {
  connectIntegration: string;
  connectIntegrationGroup: string;
  addIntegration: string;
}

export interface CatalogTitles {
  available: string;
  availableDescription?: string;
  premium: string;
  premiumDescription?: string;
}

export type ConfigsListProps = {
  configs: CategoryData;
  paths: ConfigsListPaths;
  withCategoryFilters?: boolean;
  titles: CatalogTitles;
};

export type CategoryTitleProps = {
  title?: string;
  description?: string;
  sub?: boolean;
};

export type IntegrationGroup = {
  type: string;
  name: string;
  icon: string;
  category: keyof typeof Category;
  labelsCategories: string[];
  isPremium: boolean;
  config: IntegrationConfigAppModelV2;
};

export type CategoryData = Record<IntegrationCategoryAppModel, IntegrationGroup[]>;

export type CategoryListProps = {
  category: keyof typeof Category;
  categories: IntegrationGroup[];
  paths: ConfigsListPaths;
  withCategoryFilters?: boolean;
  titles: CatalogTitles;
};

// ------------------- Functions -------------------

export const formatIntegrationData = (
  configs: IntegrationConfigAppModelV2[],
  searchQuery?: string,
  searchLabel?: string,
  searchCategory?: string,
): CategoryData => {
  const isMatchingSearchQuery = (config: IntegrationConfigAppModelV2): boolean => {
    if (!searchQuery && !searchLabel && !searchCategory) return true;
    const resourceTypes = Object.keys(config.resource_types).join(' ');
    const filteredConfigsByLabel = searchLabel ? config.labels_categories.includes(searchLabel) : true;
    const filteredConfigsByCategory = searchCategory ? config.category === searchCategory : true;
    return searchQuery
      ? `${config.name} ${config.type} ${resourceTypes}`.toLowerCase().includes(searchQuery.toLowerCase()) &&
          filteredConfigsByLabel &&
          filteredConfigsByCategory
      : filteredConfigsByLabel && filteredConfigsByCategory;
  };
  const visibleConfigs = configs.filter((config) => config.visible && isMatchingSearchQuery(config));
  return visibleConfigs.sort(sortByCategoryAndGroup).reduce(groupByCategoryAndGroup, {} as CategoryData);
};

export const generateLink = (config: IntegrationConfigAppModelV2, paths: ConfigsListPaths) => {
  if (config.integrations_group) {
    return generatePath(paths.connectIntegrationGroup, {
      group: config.integrations_group,
    });
  }

  if (config.family_type === 'ResourceIntegration') {
    return generatePath(paths.connectIntegration, { type: config.type });
  }

  return generatePath(paths.addIntegration, { type: config.type });
};

// ------------------- Helpers -------------------

const getCloudTitle = (group: string) => integrationGroups[group] || kababToCapitalCase(group);

const sortByCategoryAndGroup = (a: IntegrationConfigAppModelV2, b: IntegrationConfigAppModelV2) => {
  if (a.category !== b.category) return categoriesOrder.indexOf(a.category) - categoriesOrder.indexOf(b.category);
  return b.integrations_group ? 1 : -1;
};

const groupByCategoryAndGroup = (acc: CategoryData, config: IntegrationConfigAppModelV2) => {
  const category = config.category;
  if (!acc[category]) acc[category] = [];

  const name = config.integrations_group ? getCloudTitle(config.integrations_group) : config.name;
  const icon = config.icons.svg;
  const type = config.integrations_group ?? config.type;
  const labelsCategories = config.labels_categories;
  const isPremium = config.premium;

  const prevItemIndex = acc[category].findIndex((item) => item.type === type);
  if (prevItemIndex === -1) {
    acc[category].push({
      config,
      type,
      name,
      icon,
      category,
      labelsCategories,
      isPremium,
    });
  } else if (acc[category][prevItemIndex].isPremium && !isPremium) {
    acc[category][prevItemIndex].isPremium = false;
  }

  return acc;
};
