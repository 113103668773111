import { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Stack, Tab } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { WebhookMethodAppModel, WebhookTriggerEventAppModel, WebhookUpdateAppModel } from '@api';

import { triggerOptions, WebhookSchemaType, WebhookTypeAppModel } from '@Webhooks/common/constants';
import WebhookTypeField from '@Webhooks/components/form-fields/WebhookTypeField';
import NameField from '@Webhooks/components/form-fields/NameField';
import MethodField from '@Webhooks/components/form-fields/MethodField';
import UrlField from '@Webhooks/components/form-fields/UrlField';
import HeadersFields from '@Webhooks/components/form-fields/HeadersFields';
import BodyTemplateField from '@Webhooks/components/form-fields/BodyTemplateField';
import StatusField from '@Webhooks/components/form-fields/StatusField';
import WebhookIntegrationsField from '@Webhooks/components/form-fields/WebhookIntegrationsField';
import WebhookActionsField from '@Webhooks/components/form-fields/WebhookActionsField';
import ResponseValidatorsFields from '@Webhooks/components/form-fields/ResponseValidatorsFields';
import TimeoutField from '@Webhooks/components/form-fields/TimeoutField';

import WebhookTest from './WebhookTest';
import WebhookActivityFilters from '../components/form-fields/WebhookFilters';
import TabsSwitch from '@common/ui/TabsSwitch';
import { CustomAnalyticsEvents } from '@utils/analytics';
import TriggersField from '@Webhooks/components/form-fields/TriggersField';

export interface WebhookFormProps {
  loading?: boolean;
  webhook?: WebhookUpdateAppModel;
  onSubmit: (webhook: WebhookUpdateAppModel) => void;
}

const APONO_INTEGRATION_PREFIX = 'apono://';

export default function WebhookForm({ loading, webhook, onSubmit }: WebhookFormProps) {
  const { isEnabled: isWebhooksChallengesEnabled } = useFlagFixed(Flag.WEBHOOK_CHALLENGES);
  const { isEnabled: isAlertsEnabled } = useFlagFixed(Flag.ACCESS_ANOMALIES);

  const [schemaType, setSchemaType] = useState<WebhookSchemaType>(() => {
    const isAlertWebhook = triggerOptions[WebhookSchemaType.RequestAlert].some((trigger) =>
      webhook?.triggers?.includes(trigger),
    );

    if (isAlertsEnabled && isAlertWebhook) {
      return WebhookSchemaType.RequestAlert;
    }

    return WebhookSchemaType.UserRequest;
  });

  const { handleSubmit, control, formState, getValues, setValue, ...restMethods } = useForm<WebhookUpdateAppModel>({
    mode: 'onChange',
    defaultValues: {
      active: webhook?.active ?? true,
      method: webhook?.method ?? WebhookMethodAppModel.Post,
      url: webhook?.url ?? '',
      headers: webhook?.headers ?? {},
      triggers: webhook?.triggers ?? [],
      name: webhook?.name ?? '',
      body_template: webhook?.body_template ?? '',
      retry_policy: webhook?.retry_policy ?? {
        timeout_in_sec: 0,
      },
      filters: webhook?.filters ?? {},
      response_validators: webhook?.response_validators ?? [],
    },
  });

  const [webhookType, setWebhookType] = useState<WebhookTypeAppModel>(() => {
    if (webhook?.url?.startsWith(APONO_INTEGRATION_PREFIX)) {
      return WebhookTypeAppModel.IntegrationAction;
    }

    return WebhookTypeAppModel.Http;
  });

  const handleWebhookTypeChange = (newType: WebhookTypeAppModel) => {
    setWebhookType(newType);
    setValue('method', WebhookMethodAppModel.Post);
    setValue('url', '');
  };

  return (
    <FormProvider {...{ handleSubmit, control, formState, getValues, setValue, ...restMethods }}>
      <Grid>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Stack direction="column" spacing={3}>
            {isAlertsEnabled && <SchemaTypesTabs value={schemaType} onChange={setSchemaType} />}
            <TwoColumns
              col1={
                <NameField
                  label={schemaType === WebhookSchemaType.UserRequest ? 'Request Webhook Name' : 'Anomaly Webhook Name'}
                />
              }
              col2={<StatusField />}
            />

            {isWebhooksChallengesEnabled ? (
              <Box>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={3}>
                    <WebhookTypeField value={webhookType} onChange={handleWebhookTypeChange} />
                  </Grid>
                  {webhookType === WebhookTypeAppModel.Http && <HttpWebhookType />}
                  {webhookType === WebhookTypeAppModel.IntegrationAction && <IntegrationWebhookType />}
                </Grid>
              </Box>
            ) : (
              <Box>
                <Grid container direction="row" spacing={2}>
                  <HttpWebhookType />
                </Grid>
              </Box>
            )}
            <Divider />
            <TwoColumns col1={<BodyTemplateField schemaType={schemaType} />} col2={<HeadersFields />} />
            <TriggersField triggers={triggerOptions[schemaType]} />
            {schemaType === WebhookSchemaType.UserRequest && <RequestWebhookFilters />}
            {isWebhooksChallengesEnabled && (
              <>
                <Divider />
                <Box>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={3}>
                      <TimeoutField />
                    </Grid>
                  </Grid>
                </Box>
                <ResponseValidatorsFields />
              </>
            )}
            <Divider />
            <Stack direction="row" alignItems="flex-start" spacing={4}>
              <LoadingButton data-testid="webhook-form-submit-btn" variant="contained" type="submit" loading={loading}>
                Save Webhook
              </LoadingButton>
              <WebhookTest />
            </Stack>
          </Stack>
        </form>
      </Grid>
    </FormProvider>
  );
}

function SchemaTypesTabs({
  value,
  onChange,
}: {
  value: WebhookSchemaType;
  onChange: (newType: WebhookSchemaType) => void;
}) {
  const { setValue } = useFormContext<WebhookUpdateAppModel>();

  const handleChange = (newValue: WebhookSchemaType) => {
    onChange(newValue);
    setValue('filters', {});

    if (newValue === WebhookSchemaType.RequestAlert) {
      setValue('triggers', [WebhookTriggerEventAppModel.RequestAnomalyDetected]);
    } else {
      setValue('triggers', []);
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <TabsSwitch value={value} onChange={(e, v) => handleChange(v)}>
        <Tab value={WebhookSchemaType.UserRequest} label="Request Webhook" />
        <Tab
          value={WebhookSchemaType.RequestAlert}
          label="Anomaly Webhook"
          data-trigger={CustomAnalyticsEvents.AUTOMATIC_TAB_CLICKED}
        />
      </TabsSwitch>
    </Stack>
  );
}

function RequestWebhookFilters() {
  const { watch } = useFormContext<WebhookUpdateAppModel>();
  const triggers = watch('triggers');

  const isManualTrigger = useMemo(() => {
    return !!triggers.find((trigger) => trigger === 'Manual');
  }, [triggers]);

  if (isManualTrigger) return null;

  return <WebhookActivityFilters />;
}

function TwoColumns({ col1, col2 }: { col1?: React.ReactNode; col2?: React.ReactNode }) {
  return (
    <Box>
      <Grid container columnSpacing={4}>
        {col1 && (
          <Grid item xs={6}>
            {col1}
          </Grid>
        )}
        {col2 && (
          <Grid item xs={6}>
            {col2}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

function HttpWebhookType() {
  return (
    <>
      <Grid item sx={{ width: 150 }}>
        <MethodField />
      </Grid>
      <Grid item xs>
        <UrlField />
      </Grid>
    </>
  );
}

function IntegrationWebhookType() {
  const { setValue, getValues } = useFormContext<WebhookUpdateAppModel>();

  const [selectedIntegration, setSelectedIntegration] = useState<string>(() => {
    const url = getValues('url');
    if (url.startsWith(APONO_INTEGRATION_PREFIX)) {
      const parts = url.split('/');
      if (parts.length === 4) return parts[2];
    }

    return '';
  });

  const [selectedAction, setSelectedAction] = useState<string>(() => {
    const url = getValues('url');
    if (url.startsWith(APONO_INTEGRATION_PREFIX)) {
      const parts = url.split('/');
      if (parts.length === 4) return parts[3];
    }

    return '';
  });

  const handleOnChangeSelectedIntegration = (newVal: string) => {
    setSelectedIntegration(newVal);
    setSelectedAction('');
  };

  const handleOnChangeSelectedAction = (newVal: string) => {
    setSelectedAction(newVal);
    setValue('method', WebhookMethodAppModel.Post);
    setValue('url', `${APONO_INTEGRATION_PREFIX}${selectedIntegration}/${newVal}`);
  };

  return (
    <>
      <Grid item xs={5}>
        <WebhookIntegrationsField
          integrationType="azure-subscription"
          value={selectedIntegration}
          onChange={handleOnChangeSelectedIntegration}
        />
      </Grid>
      <Grid item xs={4}>
        <WebhookActionsField value={selectedAction} onChange={handleOnChangeSelectedAction} />
      </Grid>
    </>
  );
}
