import { useAppGetIntegration } from '@api';
import { AppDrawer, AppDrawerHeader } from '@components';
import { AlertListContent } from '@organisms/AlertListContent/AlertListContent';

interface AlertsListDrawerProps {
  integrationId?: string;
  onClose: () => void;
}

export function AlertListDrawer({ integrationId, onClose }: AlertsListDrawerProps) {
  return (
    <AppDrawer
      width={600}
      open={!!integrationId}
      onClose={onClose}
      header={
        integrationId ? (
          <AlertListDrawerTitle {...{ integrationId, onClose }} />
        ) : (
          <AppDrawerHeader title={'Loading...'} onCloseClick={onClose} />
        )
      }
    >
      {integrationId && <AlertListContent integrationId={integrationId} />}
    </AppDrawer>
  );
}

function AlertListDrawerTitle({ integrationId, onClose }: { integrationId: string; onClose: () => void }) {
  const { integration } = useAppGetIntegration(integrationId);

  if (!integration) {
    return <AppDrawerHeader title={'Loading...'} onCloseClick={onClose} />;
  }

  return <AppDrawerHeader title={`Errors in ${integration.name}`} onCloseClick={onClose} />;
}
