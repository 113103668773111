import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { QueryProvider } from '@api/query';
import { Authenticated, Theme } from '@components';
import { routes } from '@routes';
import { AuthorizePage, ErrorPage } from '@pages';
import { AnalyticsProvider } from '@utils/analytics';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FeatureFlagsContext, FeatureFlagsProvider } from '@utils/flags';
import { ActivityLayout } from './Activity/common/ActivityLayout';
import { CssBaseline } from '@mui/material';
import AppLayout from '@layouts';
import { OnboardingProvider } from '@Onboarding/context';
import { OnboardingLayout } from '@Onboarding/layouts/OnboardingLayout';
import WebhookLayout from '@Webhooks/layouts/WebhookLayout';
import AccessFlowsLayout from '@AccessFlows/layouts/AccessFlowsLayout';
import IdentitiesLayout from '@Identities/layouts/IdentitiesLayout';
import BundlesLayout from '@AccessFlows/layouts/BundlesLayout';
import { SmokeTestsWrapper } from '@common/utils/smoke-testing/SmokeTestWrapper';
import AuditEventsLayout from './AuditEvents/layouts/AuditEventsLayout';
import SentryWrapper from '@utils/SentryWrapper';

export function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

    const matchedRoute = Object.values(routes).find((route) => matchPath(route.path, pathname));
    if (matchedRoute && matchedRoute.label) {
      document.title = `${matchedRoute.label} - Apono`;
    }
  }, [pathname]);

  return (
    <React.StrictMode>
      <SentryWrapper>
        <QueryProvider>
          <RecoilRoot>
            <Authenticated>
              <FeatureFlagsProvider>
                <FeatureFlagsContext />
                <SmokeTestsWrapper>
                  <Theme>
                    <CssBaseline />
                    <AnalyticsProvider>
                      <OnboardingProvider>
                        <Routes>
                          <Route element={<OnboardingLayout />}>
                            <Route path={routes.OnboardingWelcome.path} element={routes.OnboardingWelcome.page} />
                            <Route path={routes.OnboardingCatalog.path} element={routes.OnboardingCatalog.page} />
                            <Route
                              path={routes.OnboardingConnectIntegration.path}
                              element={routes.OnboardingConnectIntegration.page}
                            />
                            <Route
                              path={routes.OnboardingConnectIntegrationsGroup.path}
                              element={routes.OnboardingConnectIntegrationsGroup.page}
                            />
                            <Route path={routes.OnboardingAccessFlow.path} element={routes.OnboardingAccessFlow.page} />
                            <Route
                              path={routes.OnboardingAddAccessFlow.path}
                              element={routes.OnboardingAddAccessFlow.page}
                            />
                            <Route
                              path={routes.OnboardingEditAccessFlow.path}
                              element={routes.OnboardingEditAccessFlow.page}
                            />
                            <Route
                              path={routes.OnboardingEditIntegration.path}
                              element={routes.OnboardingEditIntegration.page}
                            />
                            <Route
                              path={routes.OnboardingRequestAccess.path}
                              element={routes.OnboardingRequestAccess.page}
                            />
                          </Route>

                          <Route element={<AppLayout />}>
                            <Route path="/oauth/authorize" element={<AuthorizePage />} />
                            <Route path={routes.TemplateSuggestions.path} element={routes.TemplateSuggestions.page} />
                            <Route path={routes.Search.path} element={routes.Search.page} />
                            <Route path={routes.AdminBoxPage.path} element={routes.AdminBoxPage.page} />

                            <Route element={<AccessFlowsLayout />}>
                              <Route path={routes.AccessFlows.path} element={routes.AccessFlows.page} />
                              <Route path={routes.AccessRightSizing.path} element={routes.AccessRightSizing.page} />
                            </Route>

                            <Route element={<BundlesLayout />}>
                              <Route path={routes.Bundles.path} element={routes.Bundles.page} />
                            </Route>

                            <Route path={routes.AddAccessFlow.path} element={routes.AddAccessFlow.page} />
                            <Route path={routes.EditAccessFlow.path} element={routes.EditAccessFlow.page} />
                            <Route path={routes.Bundles.path} element={routes.Bundles.page} />
                            <Route path={routes.AddBundle.path} element={routes.AddBundle.page} />
                            <Route path={routes.EditBundle.path} element={routes.EditBundle.page} />

                            <Route element={<ActivityLayout />}>
                              <Route path={routes.Activity.path} element={routes.Activity.page} />
                              <Route path={routes.Reports.path} element={routes.Reports.page} />
                            </Route>

                            <Route path={routes.EditReport.path} element={routes.EditReport.page} />
                            <Route path={routes.AddReport.path} element={routes.AddReport.page} />
                            <Route element={<IdentitiesLayout />}>
                              <Route path={routes.Identities.path} element={routes.Identities.page} />
                              <Route path={routes.Groups.path} element={routes.Groups.page} />
                            </Route>

                            <Route element={<AuditEventsLayout />}>
                              <Route path={routes.AuditEvents.path} element={routes.AuditEvents.page} />
                            </Route>

                            <Route path={routes.Connectors.path}>
                              <Route index element={routes.Connectors.page} />
                              <Route path="/connectors/add" element={<Navigate to={routes.InstallConnector.path} />} />
                              <Route path={routes.InstallConnector.path} element={routes.InstallConnector.page} />
                              <Route path={routes.EditConnector.path} element={routes.EditConnector.page} />
                            </Route>

                            <Route path={routes.PersonalApiTokens.path}>
                              <Route index element={routes.PersonalApiTokens.page} />
                              <Route path={routes.AddPersonalApiToken.path} element={routes.AddPersonalApiToken.page} />
                            </Route>

                            <Route path={routes.Settings.path} element={routes.Settings.page} />

                            <Route path={routes.Catalog.path} element={routes.Catalog.page} />
                            <Route path={routes.AddIntegration.path} element={routes.AddIntegration.page} />
                            <Route path={routes.EditIntegration.path} element={routes.EditIntegration.page} />
                            <Route path={routes.ViewIntegration.path} element={routes.ViewIntegration.page} />
                            <Route
                              path={routes.ConnectedIntegrations.path}
                              element={routes.ConnectedIntegrations.page}
                            />
                            <Route path={routes.ConnectIntegration.path} element={routes.ConnectIntegration.page} />
                            <Route
                              path={routes.ConnectIntegrationsGroup.path}
                              element={routes.ConnectIntegrationsGroup.page}
                            />
                            <Route path={routes.IntegrationResources.path} element={routes.IntegrationResources.page} />
                            <Route path={routes.Dashboard.path} element={routes.Dashboard.page} />
                            <Route path={routes.AccessAlerts.path} element={routes.AccessAlerts.page} />
                            <Route path={routes.ActiveAccess.path} element={routes.ActiveAccess.page} />
                            <Route path={routes.AccessGraph.path} element={routes.AccessGraph.page} />
                            <Route path={routes.Webhooks.path} element={routes.Webhooks.page} />
                            <Route path={routes.AddWebhook.path} element={routes.AddWebhook.page} />
                            <Route element={<WebhookLayout />}>
                              <Route path={routes.WebhookHistory.path} element={routes.WebhookHistory.page} />
                              <Route path={routes.EditWebhook.path} element={routes.EditWebhook.page} />
                            </Route>
                            <Route path="*" element={<ErrorPage errorCode={404} />} />
                          </Route>
                        </Routes>
                      </OnboardingProvider>
                    </AnalyticsProvider>
                  </Theme>
                </SmokeTestsWrapper>
              </FeatureFlagsProvider>
            </Authenticated>
          </RecoilRoot>
        </QueryProvider>
      </SentryWrapper>
    </React.StrictMode>
  );
}
