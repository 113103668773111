import { Autocomplete, CircularProgress } from '@mui/material';
import { LabeledTextField, LabeledTextFieldProps } from '@components/LabeledTextField';

export interface LabeledAutocompleteFieldAutocompleteOption {
  id: string;
  label: string;
}

export interface LabeledAutocompleteFieldAutocompleteProps {
  options: LabeledAutocompleteFieldAutocompleteOption[];
  freeSolo?: boolean;
  value?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: (value?: string) => void;
}

export type LabeledAutocompleteFieldProps = LabeledAutocompleteFieldAutocompleteProps &
  Omit<LabeledTextFieldProps, 'onChange'>;

function isOption(
  entity: string | LabeledAutocompleteFieldAutocompleteOption | null,
): entity is LabeledAutocompleteFieldAutocompleteOption {
  return entity !== null && typeof entity === 'object' && 'id' in entity && 'label' in entity;
}

export function LabeledAutocompleteField({
  options,
  freeSolo,
  value,
  disabled,
  loading,
  onChange,
  ...inputProps
}: LabeledAutocompleteFieldProps) {
  const handleChange = (val: string | LabeledAutocompleteFieldAutocompleteOption | null) => {
    onChange(val ? (isOption(val) ? val.id : val) : undefined);
  };

  return (
    <Autocomplete<LabeledAutocompleteFieldAutocompleteOption, false, false, typeof freeSolo>
      freeSolo={freeSolo}
      disabled={disabled}
      options={options}
      value={value || ''}
      fullWidth
      size="small"
      includeInputInList
      renderInput={(params) => (
        <LabeledTextField
          {...inputProps}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onInputChange={(_, val) => (freeSolo ? handleChange(val) : val)}
      getOptionLabel={(option) => (isOption(option) ? option.label : option)}
      onChange={(_, val) => handleChange(val)}
    />
  );
}
