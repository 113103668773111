import { PropsWithChildren, ReactNode, useMemo, useState } from 'react';

import { Box, Grid, IconButton, Stack, styled, Tooltip } from '@mui/material';
import { ConfirmDialog, MaterialIcon } from '@components';

const FlowFormLineRoot = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  minHeight: theme.spacing(7),

  '&.last-line': {
    marginTop: theme.spacing(1.5),
  },

  '&.with-border': {
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
}));

const IconBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  position: 'relative',
  marginRight: theme.spacing(0.5),

  '& span': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export interface FlowFormLineProps {
  last?: boolean;
  padded?: boolean;
  icon?: ReactNode;
  onDuplicate?: () => void;
  onAdd?: () => void;
  onDelete?: () => void;
  withBorder?: boolean;
}

export function FlowFormLine({
  children,
  last,
  padded,
  icon,
  onDuplicate,
  onAdd,
  onDelete,
  withBorder,
}: PropsWithChildren<FlowFormLineProps>) {
  const hasActions = !!onAdd || !!onDelete || !!onDuplicate;

  const hasIconBox = useMemo(() => {
    if (padded === false) return false;
    return !!(icon || padded);
  }, [padded, icon]);

  return (
    <FlowFormLineRoot component="div" className={`${last ? 'last-line' : ''} ${withBorder ? 'with-border' : ''}`}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        {hasIconBox ? (
          <Grid item>
            <IconBox>{icon}</IconBox>
          </Grid>
        ) : null}
        <Grid item xs>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.6}>
            {children}
          </Stack>
        </Grid>
        {hasActions ? <LineActions onAdd={onAdd} onDelete={onDelete} onDuplicate={onDuplicate} /> : null}
      </Grid>
    </FlowFormLineRoot>
  );
}

export function LineActions({
  onAdd,
  onDelete,
  onDuplicate,
}: Pick<FlowFormLineProps, 'onAdd' | 'onDelete' | 'onDuplicate'>) {
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);

  const handleOnConfirmDelete = () => {
    setIsConfirmDeleteOpened(false);
    if (onDelete) onDelete();
  };

  return (
    <Grid item>
      <Stack direction="row" spacing={1}>
        {onAdd ? (
          <Tooltip title="Add new" placement="top" arrow>
            <IconButton data-testid="flow-form-line-action-add" size="small" onClick={onAdd} color="dark">
              <MaterialIcon weight={200} symbol="add" />
            </IconButton>
          </Tooltip>
        ) : null}
        {onDuplicate ? (
          <Tooltip title="Duplicate" placement="top" arrow>
            <IconButton data-testid="flow-form-line-action-duplicate" size="small" onClick={onDuplicate} color="dark">
              <MaterialIcon weight={200} symbol="content_copy" />
            </IconButton>
          </Tooltip>
        ) : null}
        {onDelete ? (
          <>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                data-testid="flow-form-line-action-delete"
                size="small"
                onClick={() => setIsConfirmDeleteOpened(true)}
                color="dark"
              >
                <MaterialIcon weight={200} symbol="delete" />
              </IconButton>
            </Tooltip>
            <ConfirmDialog
              open={isConfirmDeleteOpened}
              onClose={() => setIsConfirmDeleteOpened(false)}
              onConfirm={handleOnConfirmDelete}
            >
              Are you sure you want to delete this line?
            </ConfirmDialog>
          </>
        ) : null}
      </Stack>
    </Grid>
  );
}
