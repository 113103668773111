import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AccountsService } from '@api/services';
import { UserSessionApiModel } from '@api/apn-core';

import { QueryKeys } from './query-keys';

export function useFinishOnboarding() {
  const queryClient = useQueryClient();

  const {
    mutate: finishOnboarding,
    isLoading: isFinishOnboardingLoading,
    isSuccess: isSuccessfullyFinishedOnboarding,
  } = useMutation({
    mutationFn: async () => AccountsService.hideGettingStarted(),
    onMutate: async (): Promise<UserSessionApiModel | undefined> => {
      await queryClient.cancelQueries({ queryKey: [QueryKeys.Session] });

      const previousState = queryClient.getQueryData<UserSessionApiModel>([QueryKeys.Session]);

      if (!previousState) return;

      queryClient.setQueryData<UserSessionApiModel>([QueryKeys.Session], (old): UserSessionApiModel | undefined => {
        if (!old) return;

        return {
          ...old,
          onboarding: {
            ...old.onboarding,
            active: false,
          },
        };
      });

      return previousState;
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([QueryKeys.Session], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.Session]);
    },
  });

  return {
    finishOnboarding,
    isFinishOnboardingLoading,
    isSuccessfullyFinishedOnboarding,
  };
}

export function useFinishOnboardingV2() {
  const {
    mutate: finishOnboarding,
    isLoading: isFinishOnboardingLoading,
    isSuccess: isSuccessfullyFinishedOnboarding,
  } = useMutation({ mutationFn: async () => AccountsService.finishOnboarding() });

  return {
    finishOnboarding,
    isFinishOnboardingLoading,
    isSuccessfullyFinishedOnboarding,
  };
}
