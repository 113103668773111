import FlowFormConditions from '@AccessFlows/organisms/FlowForm/Form/FlowFormConditions';
import { FlowFormLine, FlowFormTargets, FlowFormText } from '@AccessFlows/organisms/FlowForm';
import { TriggerTypeFormProps } from '@AccessFlows/common/types';

export function AutoGrantForm({
  selectedGrantees,
  setSelectedGrantees,
  accessTargets,
  setAccessTargets,
  getValidationError,
  template,
  noBundles,
}: TriggerTypeFormProps) {
  return (
    <>
      <FlowFormConditions
        value={selectedGrantees}
        onChange={setSelectedGrantees}
        error={getValidationError('grantees')}
      />
      <FlowFormLine>
        <FlowFormText>They will have access to:</FlowFormText>
      </FlowFormLine>
      <FlowFormTargets
        and
        noEmptyPrefix
        accessTargets={accessTargets}
        onChange={(v) => setAccessTargets(v)}
        error={getValidationError('targets')}
        template={template?.targets}
        noBundles={noBundles}
      />
    </>
  );
}
