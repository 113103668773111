import { useQuery } from '@tanstack/react-query';

import { AccountsService } from '@api/services';

import { QueryKeys } from './query-keys';

export function useGetIdentities(enabled = true) {
  const {
    data: identities,
    isFetched: isIdentitiesFetched,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.Identities],
    queryFn: async () => AccountsService.getIdentities(),
    initialData: {
      identities: [],
      attributes: [],
    },
    enabled,
  });

  return {
    identities,
    isIdentitiesFetched,
    refetch,
  };
}
