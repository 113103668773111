import { getAllTimezones, getTimezone, Timezone } from 'countries-and-timezones';
import { DayOfWeek } from '@api';

export const WeekDayName: Record<DayOfWeek, string> = {
  [DayOfWeek.Sunday]: 'Sunday',
  [DayOfWeek.Monday]: 'Monday',
  [DayOfWeek.Tuesday]: 'Tuesday',
  [DayOfWeek.Wednesday]: 'Wednesday',
  [DayOfWeek.Thursday]: 'Thursday',
  [DayOfWeek.Friday]: 'Friday',
  [DayOfWeek.Saturday]: 'Saturday',
};

export const WeekDayNameShort: Record<DayOfWeek, string> = {
  [DayOfWeek.Sunday]: 'Sun',
  [DayOfWeek.Monday]: 'Mon',
  [DayOfWeek.Tuesday]: 'Tue',
  [DayOfWeek.Wednesday]: 'Wed',
  [DayOfWeek.Thursday]: 'Thu',
  [DayOfWeek.Friday]: 'Fri',
  [DayOfWeek.Saturday]: 'Sat',
};

export interface TimeItem {
  value: number;
  label: string;
}

export function generateTimeOptions(amPm?: boolean): TimeItem[] {
  const options: TimeItem[] = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      let label = `${i}:${(j * 15).toString().padStart(2, '0')}`;

      if (amPm) {
        const hour = i > 12 ? i - 12 : i;
        const isAmPm = i >= 12 ? 'pm' : 'am';
        label = `${hour}:${(j * 15).toString().padStart(2, '0')}${isAmPm}`;
      }

      options.push({ value: (i * 60 + j * 15) * 60, label });
    }
  }
  return options;
}

export function getClosestTimeOption(options: TimeItem[]): TimeItem {
  const now = new Date();
  const seconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  const closest = options.reduce((prev, curr) => {
    return Math.abs(curr.value - seconds) < Math.abs(prev.value - seconds) ? curr : prev;
  });
  return closest;
}

export function getTimeOptionFromSeconds(seconds: number, options?: TimeItem[]): TimeItem | undefined {
  if (!options) options = generateTimeOptions();
  return options.find((option) => option.value === seconds);
}

export interface TimezoneItem {
  value: string;
  label: string;
}

export function getTimezonesOptions(): TimezoneItem[] {
  const allTimezones = getAllTimezones();

  const options: TimezoneItem[] = [];
  for (const timezoneName in allTimezones) {
    const timezone = allTimezones[timezoneName as keyof typeof allTimezones];
    options.push({ value: timezoneName, label: `(GMT${timezone.utcOffsetStr}) ${timezone.name}` });
  }

  return options;
}

export function getTimezoneOptionFromValue(value: string, options: TimezoneItem[]): TimezoneItem | undefined {
  return options.find((option) => option.value === value);
}

export function getTimezoneFromValue(value: string): Timezone | undefined {
  return getTimezone(value) || undefined;
}

export function getCurrentTimezoneOption(options?: TimezoneItem[]): TimezoneItem {
  if (!options) options = getTimezonesOptions();

  try {
    // try-catching because i don't know if this will work in all browsers
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return getTimezoneOptionFromValue(timezone, options) || options[0];
  } catch (_) {
    return options[0];
  }
}
