import { useCallback } from 'react';
import { ListChildComponentProps, FixedSizeList as VirtualList } from 'react-window';
import { Box, MenuItem, MenuList, Skeleton, Stack, TextField, Typography, styled } from '@mui/material';

import ListFilterSelectItem, { ListFilterSelectItemProps } from './ListFilterSelectItem';
import ListFilterItemsCategory, { ListFilterItemsCategoryProps } from './ListFilterItemsCategory';
import { AnalyticsTriggerElementProps } from '@common/types';

export type ListFilterCategoryOrSelectItem = ListFilterSelectItemProps | ListFilterItemsCategoryProps;

interface ListFilterSelectProps {
  title?: string;
  onSearch?: (value: string) => void;
  maxHeight?: number;
  items: ListFilterCategoryOrSelectItem[];
  grouped?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

function isListFilterSelectItemProps(props: ListFilterCategoryOrSelectItem): props is ListFilterSelectItemProps {
  return props.type === 'checkbox' || props.type === 'radio';
}

function isListFilterItemsCategoryProps(props: ListFilterCategoryOrSelectItem): props is ListFilterItemsCategoryProps {
  return props.type === 'category';
}

export default function ListFilterSelect({
  title,
  onSearch,
  maxHeight = 300,
  items,
  loading,
  disabled,
  ...analyticsProps
}: ListFilterSelectProps & AnalyticsTriggerElementProps) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch">
      {title && <ListFilterSelectTitle title={title} />}
      {onSearch && <ListFilterSelectSearch onSearch={onSearch} disabled={loading || disabled} {...analyticsProps} />}
      {loading ? (
        <ListFilterSelectLoading />
      ) : items.length > 0 ? (
        <ListFilterSelectVirtualized items={items} maxHeight={maxHeight} {...analyticsProps} />
      ) : (
        <ListFilterSelectEmpty />
      )}
    </Stack>
  );
}

const ITEM_SIZE = 37;

interface ListFilterSelectVirtualizedProps {
  items: ListFilterCategoryOrSelectItem[];
  maxHeight: number;
  grouped?: boolean;
}

function ListFilterSelectVirtualized({
  items,
  maxHeight,
  ...analyticsProps
}: ListFilterSelectVirtualizedProps & AnalyticsTriggerElementProps) {
  const height = Math.min(items.length * ITEM_SIZE, maxHeight);

  const Item = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const itemProps = items[index];
      if (isListFilterSelectItemProps(itemProps)) {
        return (
          <div style={style} {...analyticsProps}>
            <ListFilterSelectItem {...itemProps} {...analyticsProps} />
          </div>
        );
      }

      if (isListFilterItemsCategoryProps(itemProps)) {
        return (
          <div style={style}>
            <ListFilterItemsCategory {...itemProps} />
          </div>
        );
      }

      return null;
    },
    [items, analyticsProps],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <VirtualList height={height} width="100%" itemSize={ITEM_SIZE} itemCount={items.length} overscanCount={20}>
        {Item}
      </VirtualList>
    </Box>
  );
}

const ListFilterSelectWrapLine = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.border.hover}`,
}));

function ListFilterSelectTitle({ title }: { title: string }) {
  return (
    <ListFilterSelectWrapLine>
      <Typography variant="caption">{title}</Typography>
    </ListFilterSelectWrapLine>
  );
}

function ListFilterSelectSearch({ onSearch, disabled }: { onSearch: (value: string) => void; disabled?: boolean }) {
  return (
    <ListFilterSelectWrapLine>
      <TextField
        size="small"
        onChange={(e) => onSearch(e.target.value)}
        autoFocus
        fullWidth
        type="search"
        placeholder="Search..."
        disabled={disabled}
      />
    </ListFilterSelectWrapLine>
  );
}

function ListFilterSelectEmpty({ text }: { text?: string }) {
  return (
    <MenuList>
      <MenuItem disabled>
        <Typography variant="caption">
          <em>{text || 'No options'}</em>
        </Typography>
      </MenuItem>
    </MenuList>
  );
}

function ListFilterSelectLoading() {
  return (
    <MenuList>
      {Array.from({ length: 3 }, (v, i) => i).map((i) => (
        <MenuItem key={i}>
          <Skeleton variant="text" width="100%" height={20} />
        </MenuItem>
      ))}
    </MenuList>
  );
}
