import { Chip } from '@mui/material';

import { GranteeItem } from '@AccessFlows/common/types';

import GranteeTypeIcon from './GranteeTypeIcon';

interface AccessFlowItemGranteeChipProps {
  grantee: GranteeItem;
  isActive?: boolean;
  onClick?: () => void;
}

export default function AccessFlowItemGranteeChip({ grantee, isActive, onClick }: AccessFlowItemGranteeChipProps) {
  return (
    <Chip
      label={grantee.name}
      variant={isActive ? 'filled' : 'outlined'}
      icon={<GranteeTypeIcon type={grantee.type} inChip />}
      clickable={onClick !== undefined}
      onClick={onClick}
    />
  );
}
