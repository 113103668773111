import { Box, Typography } from '@mui/material';
import AccessRightSizingItemGrid from './AccessRightSizingItemGrid';

export function AccessRightSizingListHeader() {
  return (
    <Box>
      <AccessRightSizingItemGrid
        accessFlows={
          <Typography variant="helperText" color="text.muted">
            Access Flows
          </Typography>
        }
        unusedResources={
          <Typography variant="helperText" color="text.muted">
            Unused Resources
          </Typography>
        }
        unusedPermissions={
          <Typography variant="helperText" color="text.muted">
            Unused Permissions
          </Typography>
        }
        usersNotRequesting={
          <Typography variant="helperText" color="text.muted">
            Inactive Users
          </Typography>
        }
        avgDurationRequested={
          <Typography variant="helperText" color="text.muted">
            Excessive Access Duration
          </Typography>
        }
        actions={<Typography variant="helperText" color="text.muted"></Typography>}
      />
    </Box>
  );
}
