import { Stack } from '@mui/material';

import { PremiumPage } from '@components';
import { routes } from '@routes';
import { Flag } from '@utils';

import WebhooksTablePaginated from '../organisms/WebhooksTablePaginated';
import WebhooksTableSearch from '../organisms/WebhooksTableSearch';

export function WebhooksPage() {
  return (
    <PremiumPage
      flag={Flag.OUTBOUND_WEBHOOKS}
      title={routes.Webhooks.label}
      button={{
        label: routes.AddWebhook.label,
        href: routes.AddWebhook.path,
      }}
    >
      <Stack spacing={2}>
        <WebhooksTableSearch />
        <WebhooksTablePaginated />
      </Stack>
    </PremiumPage>
  );
}
