import { PropsWithChildren } from 'react';

import { Box, styled } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

const InteractiveDropdownStyled = styled(Box)(({ theme }) => ({
  width: '250px',
  border: `1px solid ${theme.palette.text.muted}`,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,

  '&.interactive-dropdown-wide': {
    width: '400px',
  },
}));

export interface InteractiveDropdownProps {
  multipleViews?: boolean;
  currentView?: number;
  wide?: boolean;
}

export function InteractiveDropdown({
  multipleViews,
  currentView = 0,
  children,
  wide,
}: PropsWithChildren<InteractiveDropdownProps>) {
  const className = wide ? 'interactive-dropdown-wide' : undefined;

  if (!multipleViews) {
    return <InteractiveDropdownStyled className={className}>{children}</InteractiveDropdownStyled>;
  }

  return (
    <InteractiveDropdownStyled className={className}>
      <SwipeableViews index={currentView} disabled ignoreNativeScroll>
        {children}
      </SwipeableViews>
    </InteractiveDropdownStyled>
  );
}
