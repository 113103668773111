import {
  ApproverPolicyAppModel,
  AttributeFilterAppModel,
  GranteeFilterGroupAppModel,
  GranteeFilterGroupUpsertAppModel,
} from '@api';

export function toGranteeFilterGroupUpsertAppModel(
  appFilterGroup: GranteeFilterGroupAppModel,
): GranteeFilterGroupUpsertAppModel {
  return {
    logical_operator: appFilterGroup.logical_operator,
    attribute_filters:
      appFilterGroup.attribute_filters.map((filter: AttributeFilterAppModel) => ({
        operator: filter.operator,
        attribute_type_id: filter.attribute_type.type,
        attribute_value: filter.attribute_value,
        integration_id: filter.integration?.id,
      })) || [],
  };
}

export function toApproverPolicyUpsertAppModel(approverPolicy: ApproverPolicyAppModel) {
  return {
    groups_operator: approverPolicy.groups_operator,
    condition_groups: approverPolicy.condition_groups.map(({ logical_operator, conditions }) => ({
      logical_operator,
      conditions: conditions.map(({ attribute_condition, request_context_based_attribute_condition }) => ({
        attribute_condition: attribute_condition && {
          operator: attribute_condition.operator,
          attribute_type_id: attribute_condition.attribute_type.type,
          attribute_value: attribute_condition.attribute_value,
          integration_id: attribute_condition.integration?.id,
        },
        request_context_based_attribute_condition: request_context_based_attribute_condition && {
          attribute_type_id: request_context_based_attribute_condition.attribute_type.type,
          integration_id: request_context_based_attribute_condition.integration?.id,
        },
      })),
    })),
  };
}
