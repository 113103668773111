import { IconButton, Tooltip } from '@mui/material';
import { MaterialIcon } from '@components/MaterialIcon';

export interface CloseButtonProps {
  onClose: () => void;
  testId?: string;
  tooltip?: string;
}

export function CloseButton({ onClose, testId, tooltip }: CloseButtonProps) {
  const button = (
    <IconButton onClick={onClose} data-testid={testId}>
      <MaterialIcon symbol="close" weight={700} />
    </IconButton>
  );

  if (tooltip) {
    return (
      <div>
        <Tooltip title={tooltip} arrow placement="top">
          {button}
        </Tooltip>
      </div>
    );
  }

  return <div>{button}</div>;
}
