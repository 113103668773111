import { Button, TableCell, TableRow } from '@mui/material';
import PrimarySecondary from '@common/ui/PrimarySecondary';
import { DateTime } from '@utils';
import ResourceTypesCell from './ResourceTypesCell';
import { SeverityCell } from './SeverityCell';
import { AlertEdgesFieldsFragment, ResourceTypeFieldsFragment } from '@api/gql/graphql';
import { MaterialIcon } from '@components';
import { getFragmentData } from '@api/gql';
import {
  AlertFields_Fragment,
  AlertRequestContextFields_Fragment,
  RequestAlertAccessGroupFields_Fragment,
} from '../services/gql';
import { RESOURCE_TYPE_FRAGMENT, USER_FRAGMENT } from '@AccessRightSizing/services/gql';
import { ALERT_ID_PARAM } from '@AccessAlerts/organisms/AlertDrawer';
import { useSearchParams } from 'react-router-dom';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function AlertTableRow({ alert }: { alert: AlertEdgesFieldsFragment }) {
  const [queryParams, setQueryParams] = useSearchParams();

  const setAlertId = (alertId: string) => {
    const newQueryParam = new URLSearchParams(queryParams);
    newQueryParam.set(ALERT_ID_PARAM, alertId);
    setQueryParams(newQueryParam);
  };

  const alertFields = getFragmentData(AlertFields_Fragment, alert.node);
  const requestContext = getFragmentData(AlertRequestContextFields_Fragment, alertFields.requestContext);
  const alertAccessGroup = getFragmentData(RequestAlertAccessGroupFields_Fragment, requestContext.accessGroups);
  const userDetails = getFragmentData(USER_FRAGMENT, requestContext.user);

  const resourceTypes: ResourceTypeFieldsFragment[] = [];
  alertAccessGroup.forEach((access) => {
    const resourceType = getFragmentData(RESOURCE_TYPE_FRAGMENT, access.resourceType);
    if (resourceType !== null && resourceType !== undefined) resourceTypes.push(resourceType);
  });

  if (!alert || !alert.node) return null;
  return (
    <TableRow key={alertFields.id}>
      <TableCell component="td">
        <PrimarySecondary
          primary={DateTime.fromUnixWithFormat(DateTime.fromString(alertFields.timestamp).unix)}
          maxWidth={250}
        />
      </TableCell>
      <TableCell component="td">
        <PrimarySecondary primary={alertFields.typeName} maxWidth={250} />
      </TableCell>
      <TableCell component="td">
        <PrimarySecondary
          primary={`${userDetails?.firstName} ${userDetails?.lastName}`}
          secondary={userDetails?.email}
        />
      </TableCell>
      <TableCell component="td">
        <ResourceTypesCell resourceTypes={resourceTypes} />
      </TableCell>
      <TableCell component="td">
        <SeverityCell severity={alertFields.severity} />
      </TableCell>
      <TableCell component="td">
        <Button
          size="small"
          variant="explore-button"
          data-testid="recommendation-button"
          onClick={() => setAlertId(alertFields.id)}
          data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_DRAWER_OPENED}
          data-props={JSON.stringify({ type: alertFields.type, typeName: alertFields.typeName })}
        >
          <MaterialIcon symbol="auto_awesome" weight={800} size="small" />
        </Button>
      </TableCell>
    </TableRow>
  );
}
