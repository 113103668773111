import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { Markdown, Page } from '@components';
import { InstallConnectorWizard, InstallStrategy, PlatformType } from '@organisms';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

export function InstallConnectorPage() {
  return (
    <Page
      contentPadded
      containerWidth="md"
      breadcrumbs={[{ label: 'Connectors', href: '/connectors' }]}
      title="Install Connector"
    >
      <InstallConnectorPageContent />
    </Page>
  );
}

function useInstallConnectorPageContent() {
  const [searchParams] = useSearchParams();
  const { isEnabled: isLocalConnectorEnabled } = useFlagFixed(Flag.LOCAL_CONNECTOR);

  const initPlatform = useMemo(() => {
    const qpType = searchParams.get('integrationType') ?? searchParams.get('cloudType');

    let type: PlatformType | undefined;
    const platforms = Object.values(PlatformType);
    for (const platform of platforms) {
      if (qpType?.toLowerCase().includes(platform.toLowerCase())) {
        type = platform;
        break;
      }
    }

    return type;
  }, [searchParams]);

  return { initPlatform, fixedStrategy: isLocalConnectorEnabled ? undefined : InstallStrategy.PLATFORM };
}

function InstallConnectorPageContent() {
  const { initPlatform, fixedStrategy } = useInstallConnectorPageContent();
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Typography component="span" variant="h5">
          Select an environment to install the connector
        </Typography>
        <Typography textAlign="center" component="span" variant="body2" color="text.filter">
          <Markdown>{`The Apono Connector runs in your environment and Securely connects integrations.\nYou can read more about the Apono Connector architecture [here](https://docs.apono.io/docs/security-and-architecture)`}</Markdown>
        </Typography>
      </Stack>
      <InstallConnectorWizard fixedStrategy={fixedStrategy} initPlatform={initPlatform} />
    </Stack>
  );
}
