import { isAccessTargetNode, isAndConditionNode, isOrConditionNode } from '@AccessGraph/node-type';
import { useAccessGraphFilterOptions } from './use-access-graph-filter-options';
import { buildAccessTargetLabel, getAccessTargetIcon } from '@AccessGraph/components/node/AccessGraphAccessTargetNode';
import { buildOrNodeLabel, getOrNodeIcon } from '@AccessGraph/components/node/AccessGraphAttributeConditionOrNode';
import { buildAndNodeLabel } from '@AccessGraph/components/node/AccessGraphAttributeConditionAndNode';
import { ListFilterOption } from '@components/ListFilters/common';
import {
  AccessGraphAccessTargetNode,
  AccessGraphAttributeConditionAndNode,
  AccessGraphAttributeConditionOrNode,
} from '@api/gql/graphql';

export function usePrepareFilterOptions(): {
  attributeOptions: ListFilterOption[];
  accessTargetOptions: ListFilterOption[];
  isLoading: boolean;
} {
  const { data, isLoading } = useAccessGraphFilterOptions();
  const { accessGraphFilterOptions } = data || { accessGraphFilterOptions: [] };

  const andConditionNodes = (
    accessGraphFilterOptions.filter((node) => isAndConditionNode(node)) as AccessGraphAttributeConditionAndNode[]
  ).map((option) => {
    return {
      value: option.id || '',
      label: buildAndNodeLabel(option) || '',
      icon: '',
      category: { key: 'other', label: 'Other', icon: '' },
    };
  });

  const orConditionNodes = (
    accessGraphFilterOptions.filter((node) => isOrConditionNode(node)) as AccessGraphAttributeConditionOrNode[]
  ).map((option) => {
    return {
      value: option.id,
      label: buildOrNodeLabel(option) || '',
      icon: getOrNodeIcon(option) || '',
      category: {
        key: option.attributeConditionOr?.integration?.id || '',
        label: option.attributeConditionOr?.integration?.name || '',
        icon: option.attributeConditionOr?.integration?.icons.png || '',
      },
    };
  });

  const accessTargetNodes = (
    accessGraphFilterOptions.filter((node) => isAccessTargetNode(node)) as AccessGraphAccessTargetNode[]
  ).map((option) => {
    return {
      value: option.id,
      label: buildAccessTargetLabel(option) || '',
      icon: getAccessTargetIcon(option) || '',
      category: {
        key: option.accessTarget?.integration.id || '',
        label: option.accessTarget?.integration.name || '',
        icon: option.accessTarget?.integration.icons.png || '',
      },
    };
  });

  const sortedAccessTargetNodes = sortOptions(accessTargetNodes);

  const attributeNodesSorted = sortOptions([...andConditionNodes, ...orConditionNodes]);

  return {
    attributeOptions: attributeNodesSorted,
    accessTargetOptions: sortedAccessTargetNodes,
    isLoading,
  };
}

function sortOptions(options: ListFilterOption[]) {
  return options.sort((a, b) => {
    // First, compare categories by label
    if (a.category !== null || b.category !== null) {
      const categoryComparison = a.category?.label.localeCompare(b.category?.label || '') || 0;
      if (categoryComparison !== 0) {
        return categoryComparison;
      }
      // If categories are the same, compare labels within the category
      return a.label.localeCompare(b.label) || 0;
    }
    return 0;
  });
}
