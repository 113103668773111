import { isValidElement, ReactNode } from 'react';

import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import { Link, Link as RouterLink } from 'react-router-dom';
import { PlanBanner } from '@components/PlanBanner/PlanBanner';
import { Alink } from '@components/Alink';

const PageTitleStyled = styled(Box)(({ theme }) => ({
  display: 'block',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  alignContent: 'center',
}));

export interface PageTitleBreadcrumb {
  label: string;
  href?: string;
}

export interface PageTitleLink {
  label: string;
  href: string;
  testId?: string;
}

export interface PageTitleProps {
  breadcrumbs?: PageTitleBreadcrumb[];
  title?: ReactNode;
  subTitle?: ReactNode;
  button?: PageTitleLink | ReactNode;
  link?: PageTitleLink | ReactNode;
}

const LinkStyled = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.action.disabled,
  textDecorationLine: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export function PageTitle({ breadcrumbs, title, subTitle, button, link }: PageTitleProps) {
  let titleContent = title;
  if (!isValidElement(title)) {
    titleContent = (
      <Typography variant="h6" component="h1" gutterBottom={!!subTitle}>
        {title}
      </Typography>
    );
  }

  let subTitleContent = subTitle;
  if (!isValidElement(subTitle)) {
    subTitleContent = (
      <Typography variant="subtitle2" component="p">
        {subTitle}
      </Typography>
    );
  }

  const titleElements = breadcrumbs?.map((breadcrumb, index) => (
    <Typography variant="h6" component="span" key={index}>
      <LinkStyled key={index} to={breadcrumb.href || '#'}>
        {breadcrumb.label}
      </LinkStyled>
      {' / '}
    </Typography>
  ));

  const hasActions = button || link;

  return (
    <PageTitleStyled>
      <Container maxWidth={false}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            {titleElements}
            {titleContent}
            {!breadcrumbs && <PlanBanner />}
          </Stack>
          {hasActions && (
            <Stack alignItems="center" direction="row" spacing={2}>
              {link && <PageTitleActionLink link={link} />}
              {button && <PageTitleActionButton button={button} />}
            </Stack>
          )}
        </Stack>
        {subTitleContent}
      </Container>
    </PageTitleStyled>
  );
}

function PageTitleActionLink({ link }: { link: PageTitleLink | ReactNode }) {
  if (!isPageTitleLink(link)) {
    return <>{link}</>;
  }

  return (
    <Typography variant="caption">
      <Alink data-testid={link.testId} href={link.href}>
        {link.label}
      </Alink>
    </Typography>
  );
}

function PageTitleActionButton({ button }: { button: PageTitleLink | ReactNode }) {
  if (!isPageTitleLink(button)) {
    return <>{button}</>;
  }

  return (
    <Button
      data-trigger={`${button.label} CTA Clicked`}
      variant="contained"
      component={Link}
      to={button.href}
      data-testid={button.testId}
    >
      {button.label}
    </Button>
  );
}

function isPageTitleLink(button: PageTitleLink | ReactNode): button is PageTitleLink {
  return !isValidElement(button);
}
