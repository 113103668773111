import { useState } from 'react';
import {
  FlowFormLine,
  FlowFormSelectApprovers,
  FlowFormSelectDuration,
  FlowFormSelectTimeframe,
  FlowFormTargets,
  FlowFormText,
} from '@AccessFlows/organisms/FlowForm';
import { Stack, Typography } from '@mui/material';
import { TriggerTypeFormProps } from '@AccessFlows/common/types';
import FlowFormSection from '@AccessFlows/components/FlowFormSection';
import FlowFormAdvancedGrantees from '@AccessFlows/organisms/FlowForm/Form/AdvancedGrantees';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { FlowFormSelectApproversType } from '@AccessFlows/organisms/FlowForm/SelectAdvancedApproverType';
import { FlowFormPolicyApprovers } from '@AccessFlows/organisms/FlowForm/Form/FlowFormPolicyApprovers';

export function ComplexUserRequestForm({
  selectedGranteesV2,
  setSelectedGranteesV2,
  accessTargets,
  setAccessTargets,
  selectedApprovers,
  setSelectedApprovers,
  selectedApproverPolicy,
  setSelectedApproverPolicy,
  selectedDuration,
  setSelectedDuration,
  selectedTimeframe,
  setSelectedTimeframe,
  getValidationError,
  template,
  noBundles,
}: TriggerTypeFormProps) {
  return (
    <Stack spacing={3}>
      <Stack spacing={1} alignItems="start">
        <Stack spacing={0.5} direction="row" alignItems="center">
          <FlowFormSelectTimeframe
            value={selectedTimeframe}
            onChange={(v) => setSelectedTimeframe(v)}
            error={getValidationError('timeframe')}
          />
          <FlowFormText>user</FlowFormText>
        </Stack>
        <FlowFormAdvancedGrantees value={selectedGranteesV2} onChange={setSelectedGranteesV2} />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body3">Request access to</Typography>
        <FlowFormTargets
          noPrefix
          withLines
          noBundles={noBundles}
          template={template?.targets}
          accessTargets={accessTargets}
          onChange={(v) => setAccessTargets(v)}
          error={getValidationError('targets')}
        />
      </Stack>
      <Stack spacing={1}>
        <AdvancedApproverSelector
          selectedApprovers={selectedApprovers}
          setSelectedApprovers={setSelectedApprovers}
          selectedApproverPolicy={selectedApproverPolicy}
          setSelectedApproverPolicy={setSelectedApproverPolicy}
          getValidationError={getValidationError}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
          template={template}
        />
      </Stack>
    </Stack>
  );
}

export enum ApproverTypeModel {
  Auto = 'Automatic',
  Approval = 'Approval of',
}

function AdvancedApproverSelector({
  selectedApprovers,
  setSelectedApprovers,
  selectedApproverPolicy,
  setSelectedApproverPolicy,
  getValidationError,
  selectedDuration,
  setSelectedDuration,
  template,
}: Pick<
  TriggerTypeFormProps,
  | 'selectedApprovers'
  | 'setSelectedApprovers'
  | 'selectedApproverPolicy'
  | 'setSelectedApproverPolicy'
  | 'getValidationError'
  | 'selectedDuration'
  | 'setSelectedDuration'
  | 'template'
>) {
  const { isEnabled: isAdvancedApprover } = useFlagFixed(Flag.ADVANCED_APPROVER);
  const [approverType, setApproverType] = useState<string>(
    selectedApproverPolicy ? ApproverTypeModel.Approval : ApproverTypeModel.Auto,
  );

  const handleApproverTypeChange = (type: string) => {
    if (type === ApproverTypeModel.Auto) setSelectedApproverPolicy(undefined);
    setApproverType(type);
  };

  if (isAdvancedApprover)
    return (
      <>
        <FlowFormLine>
          <Typography variant="body3">Grant for</Typography>
          <FlowFormSelectDuration
            value={selectedDuration}
            onChange={(v) => setSelectedDuration(v)}
            error={getValidationError('revoke_after_in_sec')}
          />
          <FlowFormText>with</FlowFormText>
          <FlowFormSelectApproversType value={approverType} onChange={handleApproverTypeChange} />
          {approverType === ApproverTypeModel.Auto && <FlowFormText>approval.</FlowFormText>}
        </FlowFormLine>
        {approverType === ApproverTypeModel.Approval && (
          <FlowFormPolicyApprovers value={selectedApproverPolicy} onChange={setSelectedApproverPolicy} />
        )}
      </>
    );

  return (
    <>
      <Typography variant="body3">Grant for</Typography>
      <FlowFormSection>
        <FlowFormLine withBorder>
          <FlowFormSelectDuration
            value={selectedDuration}
            onChange={(v) => setSelectedDuration(v)}
            error={getValidationError('revoke_after_in_sec')}
          />
          <FlowFormText>with</FlowFormText>
          <FlowFormSelectApprovers
            template={template?.approvers}
            value={selectedApprovers}
            onChange={(v) => setSelectedApprovers(v)}
            error={getValidationError('approvers')}
          />
          <FlowFormText>approval.</FlowFormText>
        </FlowFormLine>
      </FlowFormSection>
    </>
  );
}
