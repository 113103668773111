import { useAccessFlowsPack, useFlagFixed } from '@hooks';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';
import { useGetAccessFlow, useUpdateAccessFlowFlow } from '@api';
import { useEffect, useMemo } from 'react';
import { Loader } from '@components';
import { ErrorPage } from '@pages';
import { WarningIntegrationBanner, WarningResourceBanner } from '@organisms';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { RouterLink } from '@libs';
import { FlowFormSummary } from './FlowForm';
import { AccessFlowForm } from './AccessFlowForm/AccessFlowForm';
import { useUpdateAccessFlowFlowV2 } from '@AccessFlows/services/accessFlowsMutations';
import { Flag } from '@utils';
import { useGetAccessFlowV2 } from '@AccessFlows/services/accessFlowsQueries';

function useEditAccessFlowPage(id: string, onSaveAccessFlow?: () => void) {
  const { isFetched: isPackFetched, connectedIntegrations } = useAccessFlowsPack();
  const { isEnabled: isComplexAccessFlowEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);
  const { track } = useAnalyticsContext();
  const { accessFlow, isAccessFlowFetched } = useGetAccessFlow(id, !isComplexAccessFlowEnabled);
  const { accessFlow: accessFlowV2, isFetched: isAccessFlowV2Fetched } = useGetAccessFlowV2(
    id,
    isComplexAccessFlowEnabled,
  );
  const { updateAccessFlow, isUpdateAccessFlowFlowLoading, updatedAccessFlow } = useUpdateAccessFlowFlow(id);
  const {
    updateAccessFlow: updateAccessFlowV2,
    isLoading: isUpdateAccessFlowFlowLoadingV2,
    data: updatedAccessFlowV2,
  } = useUpdateAccessFlowFlowV2(id);

  useEffect(() => {
    if (updatedAccessFlow || updatedAccessFlowV2) {
      track(CustomAnalyticsEvents.ACCESS_FLOW_EDITED, {
        name: updatedAccessFlow?.name ?? updatedAccessFlowV2?.name,
      });
      onSaveAccessFlow && onSaveAccessFlow();
    }
  }, [updatedAccessFlow, updatedAccessFlowV2, track, onSaveAccessFlow]);

  return {
    isFetched: (isAccessFlowFetched || isAccessFlowV2Fetched) && isPackFetched,
    accessFlow,
    accessFlowV2,
    updatedAccessFlow,
    handleSubmit: updateAccessFlow,
    isSubmitting: isUpdateAccessFlowFlowLoading || isUpdateAccessFlowFlowLoadingV2,
    updatedAccessFlowV2,
    handleSubmitV2: updateAccessFlowV2,
    connectedIntegrations,
  };
}

type EditAccessFlowPageContentProps = {
  accessFlowId: string;
  isSettingsOpen?: boolean;
  onCloseSettings?: () => void;
  paths: {
    donePath: string;
    backPath?: string;
    doneButtonLabel?: string;
  };
  onSaveAccessFlow?: () => void;
};

export function EditAccessFlowPageContent({
  accessFlowId,
  isSettingsOpen,
  onCloseSettings,
  paths: { donePath, backPath, doneButtonLabel },
  onSaveAccessFlow,
}: EditAccessFlowPageContentProps) {
  const {
    isFetched,
    updatedAccessFlow,
    accessFlow,
    accessFlowV2,
    handleSubmit,
    isSubmitting,
    connectedIntegrations,
    updatedAccessFlowV2,
    handleSubmitV2,
  } = useEditAccessFlowPage(accessFlowId, onSaveAccessFlow);
  const { isEnabled: isComplexAccessFlowEnabled } = useFlagFixed(Flag.COMPLEX_GRANTEES_CONDITION);

  const commonFormProps = {
    isSettingsOpen,
    onCloseSettings,
    onSubmit: handleSubmit,
    onSubmitV2: handleSubmitV2,
  };

  const accessFlowToUse = useMemo(
    () =>
      isComplexAccessFlowEnabled && accessFlowV2
        ? {
            ...accessFlowV2,
            grantees: undefined,
            granteesV2: accessFlowV2.grantees,
          }
        : accessFlow,
    [isComplexAccessFlowEnabled, accessFlow, accessFlowV2],
  );

  if (!isFetched) return <Loader />;
  if (!accessFlowToUse) return <ErrorPage errorCode={404} errorMessage="Access flow not found" />;

  const updatedAccessFlowToUse = isComplexAccessFlowEnabled ? updatedAccessFlowV2 : updatedAccessFlow;

  return updatedAccessFlowToUse ? (
    <FlowFormSummary accessFlow={updatedAccessFlowToUse} donePath={donePath} doneButtonLabel={doneButtonLabel} />
  ) : (
    <Stack spacing={2}>
      <WarningIntegrationBanner accessFlow={accessFlowToUse} connectedIntegrations={connectedIntegrations} />
      <WarningResourceBanner accessFlow={accessFlowToUse} />
      <AccessFlowForm formData={accessFlowToUse} {...commonFormProps} accessFlowId={accessFlowId}>
        <Stack direction="row" spacing={2}>
          {backPath && (
            <Button component={RouterLink} to={backPath} variant="outlined">
              Back
            </Button>
          )}
          <LoadingButton data-testid="create-access-flow-btn" variant="contained" loading={isSubmitting} type="submit">
            Save Access Flow
          </LoadingButton>
        </Stack>
      </AccessFlowForm>
    </Stack>
  );
}
