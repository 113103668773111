import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import { Box, Popover } from '@mui/material';
import { searchParamsToFilters, SP_FILTER_PREFIX, SP_TAKE_PREFIX } from '@AccessGraph/AccessGraphMap';

interface AttributeFilterProps {
  options: ListFilterOption[];
  opened?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  loading: boolean;
}

export const ATTRIBUTE_FILTER_PARAM = 'attribute';

export const ATTRIBUTE_FILTER_LABEL = 'Grantee';

export default function AttributeNodeFilter({
  options,
  opened = false,
  onClose,
  onOpen,
  loading,
}: AttributeFilterProps) {
  const [isOpen, setIsOpen] = useState(opened);

  const [searchParams, setSearchParams] = useSearchParams();
  const { filter } = searchParamsToFilters(searchParams);
  const selectedQp = filter.filter((f) => options.find((option) => option.value === f.nodeId)).map((f) => f.nodeId);

  const selectedOptions = [];
  for (const option of options) {
    if (selectedQp.includes(option.value)) {
      selectedOptions.push(option);
    }
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const open = (opened && Boolean(anchorEl)) || false;

  const ref = useRef<HTMLButtonElement>(null);

  const handleOnSelect = (values: ListFilterOption[]) => {
    for (const chosenOption of selectedQp) {
      searchParams.delete(`${SP_FILTER_PREFIX}${chosenOption}`);
    }
    for (const value of values) {
      searchParams.set(`${SP_FILTER_PREFIX}${value.value}`, '');
    }
    setSearchParams(searchParams);
  };

  const handleOnClear = () => {
    for (const chosenOption of selectedQp) {
      searchParams.delete(`${SP_FILTER_PREFIX}${chosenOption}`);
      searchParams.delete(`${SP_TAKE_PREFIX}${chosenOption}`);
    }
    Array.from(searchParams.keys()).forEach((key) => {
      if (key.startsWith(SP_TAKE_PREFIX)) {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
  };

  const closeDropdown = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={ATTRIBUTE_FILTER_LABEL}
        value={selectedOptions}
        onClear={handleOnClear}
        onClick={toggleDropdown}
        loading={loading}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 500 }}>
          <ListFilterMultiSelect
            options={options}
            value={selectedOptions}
            onSelect={handleOnSelect}
            enableSearch
            title={`Select ${ATTRIBUTE_FILTER_LABEL} Filter`}
            loading={loading}
            groupedByCategory
          />
        </Box>
      </Popover>
    </>
  );
}
