import { CloseButton } from '@components';
import { Box, Modal, Stack, styled } from '@mui/material';
import { ReactNode, PropsWithChildren } from 'react';

const ModalContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(8, 'auto'),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

interface AppModalProps {
  open: boolean;
  onClose: () => void;
  header?: ReactNode;
  width?: number | string;
  closeButton?: boolean;
}

export default function AppModal({
  children,
  open,
  onClose,
  header,
  width = 540,
  closeButton = true,
}: PropsWithChildren<AppModalProps>) {
  return (
    <Modal open={open} onClose={onClose} sx={{ overflow: 'auto' }}>
      <ModalContainer sx={{ width }}>
        <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <div>{header}</div>
            {closeButton && <CloseButton onClose={onClose} testId="close-button" />}
          </Stack>
          <div>{children}</div>
        </Stack>
      </ModalContainer>
    </Modal>
  );
}
