import { RadioGroup, Stack, Typography } from '@mui/material';

import { InstallStrategy } from './types';
import { RadioBox, RadioBoxLabelComplex } from '@components';

interface SelectInstallStrategyProps {
  strategy?: InstallStrategy;
  onSelect: (strategy: InstallStrategy) => void;
}

export default function SelectInstallStrategy({ strategy, onSelect }: SelectInstallStrategyProps) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="subtitle1" component="div">
          Select connector installation strategy
        </Typography>
        <Typography variant="inputLabel" color="text.filter" textAlign="center">
          The Apono connector handles data syncs and access provisioning. You can deploy it locally or in your cloud
          environment.
        </Typography>
      </Stack>
      <RadioGroup value={strategy || ''} onChange={(e) => onSelect(e.target.value as InstallStrategy)}>
        <Stack direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
          <RadioBox
            value={InstallStrategy.LOCAL}
            label={
              <RadioBoxLabelComplex
                title="Local installation"
                description="Deploy local docker image for playground and testing."
              />
            }
            labelPlacement="top"
            sx={{ width: '250px' }}
          />
          <RadioBox
            value={InstallStrategy.PLATFORM}
            label={
              <RadioBoxLabelComplex
                title="Cloud installation"
                description="Deploy in your cloud environment. You can always do this later."
              />
            }
            labelPlacement="top"
            sx={{ width: '250px' }}
          />
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
