import { InteractiveDropdown, Popover } from '@components';
import { TagAppModel } from '@api';
import { yup } from '@libs';

import { DrawerSelectResources } from './organisms/DrawerSelectResources';
import { DropdownSelectMode } from './organisms/DropdownSelectMode';

import { SelectResourceDrawerProvider, useSelectResourceDrawerContext } from './provider';
import { DropdownSelectTagsKeys } from './organisms/DropdownSelectTagsKeys';
import { DrawerSelectTags } from './organisms/DrawerSelectTags';
import { DropdownTrigger } from './organisms/Trigger';
import { IntegrationInfo } from '../common';

export interface FlowFormSelectResourceDrawerProps {
  integration: IntegrationInfo;
  template?: string;
  matchers?: TagAppModel[];
  excludes?: TagAppModel[];
  onChange: (matchers: TagAppModel[], excludes: TagAppModel[]) => void;
  error?: yup.ValidationError;
}

export function FlowFormSelectResourceDrawer({
  integration,
  template,
  matchers,
  excludes,
  onChange,
  error,
}: FlowFormSelectResourceDrawerProps) {
  return (
    <SelectResourceDrawerProvider {...{ integration, template, matchers, excludes, onChange }}>
      <FlowFormSelectResourceDrawerContent {...{ integration, template, matchers, excludes, onChange, error }} />
    </SelectResourceDrawerProvider>
  );
}

function FlowFormSelectResourceDrawerContent({ matchers, excludes, error }: FlowFormSelectResourceDrawerProps) {
  const { externalState, showSelectTagsKeys } = useSelectResourceDrawerContext();

  return (
    <>
      <Popover
        testId="flow-form-select-resource"
        trigger={<DropdownTrigger {...{ matchers, excludes, error }} />}
        externalState={externalState}
      >
        <InteractiveDropdown wide>
          {showSelectTagsKeys ? <DropdownSelectTagsKeys /> : <DropdownSelectMode />}
        </InteractiveDropdown>
      </Popover>
      <DrawerSelectResources />
      <DrawerSelectTags />
    </>
  );
}
