import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import {
  ActivityApiGetReportPreviewRequest,
  ActivityApiAppListActivityRequestsRequest,
  ActivityReportExportModel,
  ReportField,
  useGetReportFile,
  useGetReportPreview,
} from '@api';
import { getFieldsFromQP, getStartEndDateFromQP, getStringQP, QueryParams, reportFields } from '@utils';
import { useSearchParams } from 'react-router-dom';

interface ReportContextProps {
  columns: ReportField[];
  selectedColumns: ReportField[];
  activityProps: ActivityApiAppListActivityRequestsRequest;
  hasFilters: boolean;
  isFetched: boolean;
  isLoading: boolean;
  reportPreview: ActivityReportExportModel[];
  exportReportFile: () => void;
}

export const ReportContext = createContext<ReportContextProps>({
  columns: [],
  selectedColumns: [],
  activityProps: {},
  hasFilters: false,
  isFetched: false,
  isLoading: false,
  reportPreview: [],
  exportReportFile: () => {
    // noop
  },
});

export function useReportContext() {
  return useContext(ReportContext);
}

function hasFilters(qp: URLSearchParams) {
  return (
    qp.has(QueryParams.filterIntegrationId) ||
    qp.has(QueryParams.filterPermission) ||
    qp.has(QueryParams.filterRequestorId) ||
    qp.has(QueryParams.filterResource) ||
    qp.has(QueryParams.filterResourceType) ||
    qp.has(QueryParams.filterTriggerType) ||
    qp.has(QueryParams.filterStatus) ||
    qp.has(QueryParams.startDate) ||
    qp.has(QueryParams.endDate) ||
    qp.has(QueryParams.period)
  );
}

export function qpToReportProps(qp: URLSearchParams): ActivityApiGetReportPreviewRequest {
  const { startDate, endDate } = getStartEndDateFromQP(qp);
  const propFields = getFieldsFromQP(qp);

  return {
    filterIntegrationId: getStringQP(qp, QueryParams.filterIntegrationId),
    filterPermission: getStringQP(qp, QueryParams.filterPermission),
    filterRequestorId: getStringQP(qp, QueryParams.filterRequestorId),
    filterResource: getStringQP(qp, QueryParams.filterResource),
    filterStatus: getStringQP(qp, QueryParams.filterStatus),
    filterResourceType: getStringQP(qp, QueryParams.filterResourceType),
    filterTriggerType: getStringQP(qp, QueryParams.filterTriggerType),
    startDate: startDate ? startDate.unix : undefined,
    endDate: endDate ? endDate.unix : undefined,
    fields: propFields,
  };
}

function useReportProvider(): ReportContextProps {
  const [qp] = useSearchParams();
  const [reportProps, setReportProps] = useState<ActivityApiAppListActivityRequestsRequest>(qpToReportProps(qp));
  const [selectedColumns, setSelectedColumns] = useState<ReportField[]>(getFieldsFromQP(qp));

  const { reportPreview, isReportPreviewFetched, isReportPreviewLoading } = useGetReportPreview(reportProps);
  const { getReportFile, isGetReportFileLoading } = useGetReportFile();

  useEffect(() => {
    if (!qp) return;
    setReportProps(qpToReportProps(qp));
    setSelectedColumns(getFieldsFromQP(qp));
  }, [qp]);

  useEffect(() => {
    if (isReportPreviewLoading) return;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [isReportPreviewLoading]);

  const exportReportFile = () => {
    getReportFile(reportProps);
  };

  return {
    columns: reportFields,
    hasFilters: hasFilters(qp),
    selectedColumns,
    activityProps: reportProps,
    isFetched: isReportPreviewFetched,
    isLoading: isReportPreviewLoading || isGetReportFileLoading,
    reportPreview,
    exportReportFile,
  };
}

export function ReportProvider({ children }: PropsWithChildren<unknown>) {
  const value = useReportProvider();
  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
}
