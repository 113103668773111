import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from '@utils';

const StyledDateInput = styled(TextField)({
  width: '145px',
});

const StyledTimeInput = styled(TextField)({
  width: '95px',
});

export interface DateRangePickerAbsoluteProps {
  startDate?: DateTime;
  endDate?: DateTime;
  utc?: boolean;
  onChange: (startDate: DateTime, endDate: DateTime, utc?: boolean) => void;
}

function useDateRangePickerAbsolute({ startDate, endDate, utc, onChange }: DateRangePickerAbsoluteProps) {
  const initDate = (date?: DateTime): string => {
    if (!date) date = DateTime.now();
    return date.isoString.split('T')[0];
  };

  const initTime = (date?: DateTime, start?: boolean): string => {
    if (date) return date.isoString.split('T')[1];
    return start ? '00:00' : '23:59';
  };

  const [useUtc, setUseUtc] = useState<boolean>(utc || false);
  const [selectedStartDate, setSelectedStartDate] = useState<string>(initDate(startDate));
  const [selectedStartTime, setSelectedStartTime] = useState<string>(initTime(startDate, true));
  const [selectedEndDate, setSelectedEndDate] = useState<string>(initDate(endDate));
  const [selectedEndTime, setSelectedEndTime] = useState<string>(initTime(endDate));

  useEffect(() => {
    setUseUtc(utc || false);
    setSelectedStartDate(initDate(startDate));
    setSelectedStartTime(initTime(startDate, true));
    setSelectedEndDate(initDate(endDate));
    setSelectedEndTime(initTime(endDate));
  }, [startDate, endDate, utc]);

  function applyChange() {
    const start = DateTime.fromString(selectedStartDate + 'T' + selectedStartTime);
    const end = DateTime.fromString(selectedEndDate + 'T' + selectedEndTime);
    onChange(start, end, useUtc);
  }

  return {
    selectedStartDate,
    selectedStartTime,
    selectedEndDate,
    selectedEndTime,
    useUtc,
    setSelectedStartDate,
    setSelectedStartTime,
    setSelectedEndDate,
    setSelectedEndTime,
    setUseUtc,
    applyChange,
  };
}

export function DateRangePickerAbsolute({ startDate, endDate, utc, onChange }: DateRangePickerAbsoluteProps) {
  const {
    selectedStartDate,
    selectedStartTime,
    selectedEndDate,
    selectedEndTime,
    useUtc,
    setSelectedStartDate,
    setSelectedStartTime,
    setSelectedEndDate,
    setSelectedEndTime,
    setUseUtc,
    applyChange,
  } = useDateRangePickerAbsolute({ startDate, endDate, utc, onChange });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        applyChange();
      }}
    >
      <Stack direction="column" spacing={2}>
        <FormLabel>
          <InputLabel shrink>From</InputLabel>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <StyledDateInput
              required
              size="small"
              type="date"
              variant="outlined"
              value={selectedStartDate}
              onChange={(e) => setSelectedStartDate(e.target.value)}
              inputProps={{
                max: selectedEndDate || '9999-12-31',
              }}
            />
            <StyledTimeInput
              required
              size="small"
              type="time"
              variant="outlined"
              value={selectedStartTime}
              onChange={(e) => setSelectedStartTime(e.target.value)}
              inputProps={{
                max: selectedEndTime || '23:59',
              }}
            />
          </Stack>
        </FormLabel>
        <FormLabel>
          <InputLabel shrink>To</InputLabel>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <StyledDateInput
              required
              size="small"
              type="date"
              variant="outlined"
              value={selectedEndDate}
              onChange={(e) => setSelectedEndDate(e.target.value)}
              inputProps={{
                min: selectedStartDate || '0000-01-01',
              }}
            />
            <StyledTimeInput
              required
              size="small"
              type="time"
              variant="outlined"
              value={selectedEndTime}
              onChange={(e) => setSelectedEndTime(e.target.value)}
              inputProps={{
                min: selectedStartTime || '00:00',
              }}
            />
          </Stack>
        </FormLabel>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={<Checkbox checked={useUtc} size="small" onChange={(e) => setUseUtc(e.target.checked)} />}
            label={<Typography variant="inputLabel">Use UTC</Typography>}
          />
          <Button size="small" variant="contained" type="submit">
            Apply
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
