import { Avatar, ButtonBase, Tooltip, styled } from '@mui/material';
import { TriggerTypeAppModel } from '@api';

const TriggerTypeChipAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '& .MuiAvatar-img': {
    width: '18px',
    height: '18px',
  },

  '&.color-auto-grant': {
    backgroundColor: theme.palette.success.main,
  },

  '&.color-user-request': {
    backgroundColor: theme.palette.primary.main,
  },

  '&.color-selected': {
    boxShadow: `0 0 0 1px ${theme.palette.border.dark}`,
  },
}));

interface TriggerProps {
  class: string;
  icon: string;
  displayName: string;
}

const TriggerTypesProps: Record<TriggerTypeAppModel, TriggerProps> = {
  AUTO_GRANT: {
    class: 'color-auto-grant',
    icon: '/static/icons/af-auto-grant-type.svg',
    displayName: 'Automatic',
  },
  USER_REQUEST: {
    class: 'color-user-request',
    icon: '/static/icons/af-user-request-type.svg',
    displayName: 'Self Serve',
  },
};

export default function TriggerTypeChip({
  triggerType,
  isSelected = false,
  onClick,
}: {
  triggerType: TriggerTypeAppModel;
  isSelected?: boolean;
  onClick?: () => void;
}) {
  const triggerTypeProps = TriggerTypesProps[triggerType];

  if (!triggerTypeProps) return <></>;

  const classes = ['size-20', triggerTypeProps.class];

  if (isSelected) {
    classes.push('color-selected');
  }

  return (
    <Tooltip title={triggerTypeProps.displayName} arrow placement="top">
      <ButtonBase onClick={onClick}>
        <TriggerTypeChipAvatar className={classes.join(' ')} variant="rounded" src={triggerTypeProps.icon} />
      </ButtonBase>
    </Tooltip>
  );
}
