import { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { formatISO, sub } from 'date-fns';

import { TopQueryEntityType, useQueryAccessOverTime } from '@api';
import { useUpdateEffect } from '@libs';

import { ActiveAccessOverTimeCard } from './ActiveAccessOverTimeCard';
import { RequestsOverTimeCard } from './RequestsOverTimeCard';
import { TopOverTime } from './TopOverTime';

function useDashboard() {
  const now = new Date();
  const [selectedDays, setSelectedDays] = useState(7);
  const [startDate, setStartDate] = useState(formatISO(sub(now, { days: selectedDays })));
  const endDate = formatISO(now);
  const { accessOverTime, isAccessOverTimeFetched, refetchAccessOverTime } = useQueryAccessOverTime(endDate, startDate);

  const handleOnSelectDays = (days: number) => {
    setSelectedDays(days);
    setStartDate(formatISO(sub(now, { days })));
  };

  useUpdateEffect(() => {
    refetchAccessOverTime();
  }, [startDate]);

  return {
    endDate: formatISO(now),
    startDate,
    selectedDays,
    overTimeDataPoints: accessOverTime.data_points,
    overTimeFetched: isAccessOverTimeFetched,
    handleOnSelectDays,
  };
}

export function Dashboard() {
  const { endDate, startDate, overTimeDataPoints, overTimeFetched } = useDashboard();

  return (
    <Stack direction="column" justifyContent="center" spacing={3} mb={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography variant="subtitle1">Overview</Typography>
      </Stack>
      <Box>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={6}>
            <ActiveAccessOverTimeCard dataPoints={overTimeDataPoints} isFetched={overTimeFetched} />
          </Grid>
          <Grid item xs={6}>
            <RequestsOverTimeCard dataPoints={overTimeDataPoints} isFetched={overTimeFetched} />
          </Grid>
          <Grid item xs={6}>
            <TopOverTime endDate={endDate} startDate={startDate} type={TopQueryEntityType.Grantees} />
          </Grid>
          <Grid item xs={6}>
            <TopOverTime endDate={endDate} startDate={startDate} type={TopQueryEntityType.Integrations} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
