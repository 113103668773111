import { AttributeAppModel } from '@api';
import { FlowFormErrorProps, FlowInlineTextProps, HumanReadablePlaceholderProps, HumanReadableText } from '../common';

type Props = HumanReadablePlaceholderProps & FlowInlineTextProps & FlowFormErrorProps;

export interface HumanReadableAttributeValueProps extends Props {
  attributeValue?: string[] | string;
  matchingAttributes: AttributeAppModel[];
}

export default function HumanReadableAttributeValue({
  attributeValue,
  matchingAttributes,
  ...rest
}: HumanReadableAttributeValueProps) {
  const values: string[] = [];
  if (Array.isArray(attributeValue)) {
    for (const v of attributeValue) {
      const matchedAttribute = matchingAttributes.find((attr) => attr.source_id === v);
      values.push(matchedAttribute?.value || v);
    }
  } else {
    const matchedAttribute = matchingAttributes.find((attr) => attr.source_id === attributeValue);
    const value = matchedAttribute?.value || attributeValue;
    if (value) values.push(value);
  }

  rest.placeholderMuted = rest.placeholderMuted || values.length === 0;

  if (values.length > 0) return <HumanReadableText values={values} slice={2} {...rest} />;

  return <HumanReadableText {...rest} />;
}
