import { ReactNode } from 'react';
import { CircularProgress, FormControl, FormLabel, Stack, styled, TextField, TextFieldProps } from '@mui/material';
import slugify from '@sindresorhus/slugify';

const InputLabelWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const InputLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export interface LabeledTextFieldLabelProps {
  label: string;
  subLabel?: ReactNode;
  optional?: boolean;
  loading?: boolean;
  testId?: string;
  spaceBetweenLabels?: boolean;
  maxLength?: number;
  showCharCount?: boolean;
  disabled?: boolean;
}

export type LabeledTextFieldProps = TextFieldProps & LabeledTextFieldLabelProps;

export function LabeledTextField({
  label,
  subLabel,
  optional,
  loading,
  testId,
  spaceBetweenLabels,
  maxLength,
  showCharCount,
  disabled,
  ...rest
}: LabeledTextFieldProps) {
  const id = rest.id || slugify(label) || testId;
  const required = !optional;
  const showCounter = showCharCount && maxLength !== undefined;

  return (
    <FormControl fullWidth error={rest.error}>
      <InputLabelWrapper
        direction="row"
        spacing={0.5}
        alignItems="center"
        justifyContent={spaceBetweenLabels ? 'space-between' : 'flex-start'}
      >
        <InputLabel htmlFor={id}>
          {label}
          {!required && ' (optional)'}
        </InputLabel>
        {subLabel}
      </InputLabelWrapper>
      <TextField
        id={id}
        size="small"
        variant="outlined"
        required={required}
        disabled={disabled}
        {...rest}
        InputProps={{
          ...rest.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {rest.InputProps?.endAdornment}
            </>
          ),
        }}
        inputProps={{ ...rest.inputProps, maxLength }}
        helperText={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <span>{rest.helperText}</span>
            {showCounter && (
              <span>
                {(rest.value as string)?.length ?? 0}/{maxLength}
              </span>
            )}
          </Stack>
        }
      />
    </FormControl>
  );
}
