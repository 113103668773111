import { graphql } from '@api/gql';
export const accessGraphQueryDocument = graphql(/* GraphQL */ `
  query AccessGraph($filter: [AccessGraphFilterInput!], $take: [AccessGraphTakeInput!], $onlyActiveAccess: Boolean) {
    accessGraph(filter: $filter, take: $take, onlyActiveAccess: $onlyActiveAccess) {
      edges {
        ... on AccessGraphActiveAccessEdge {
          id
          sourceId
          targetId
          activeAccess {
            permissions
            durations
            requestIds
          }
        }
        ... on AccessGraphAvailableAccessEdge {
          id
          sourceId
          targetId
          availableAccess {
            permissions
            accessFlowsIds
          }
        }
      }
      nodes {
        ... on AccessGraphAccessTargetNode {
          id
          totalChildren
          accessTarget {
            integration {
              id
              name
              type
              typeDisplayName
              icons {
                png
                svg
              }
            }
            resourceType {
              type
              typeDisplayName
              icons {
                png
                svg
              }
            }
          }
          children {
            ... on AccessGraphResourceMoreNode {
              id
              resourceMore {
                more
              }
            }
            ... on AccessGraphResourceNode {
              id
              resource {
                id
                name
                path
              }
            }
            ... on AccessGraphUserMoreNode {
              id
              userMore {
                more
              }
            }
            ... on AccessGraphUserNode {
              id
              user {
                id
                email
                firstName
                lastName
              }
            }
          }
        }
        ... on AccessGraphAttributeConditionAndNode {
          id
          totalChildren
          attributeConditionAnd {
            integration {
              id
              name
              type
              typeDisplayName
              icons {
                png
                svg
              }
            }
            attribute {
              type
              name
            }
            attributeType {
              type
              name
            }
            operator
            value
          }
          children {
            ... on AccessGraphResourceMoreNode {
              id
              resourceMore {
                more
              }
            }
            ... on AccessGraphResourceNode {
              id
              resource {
                id
                name
                path
              }
            }
            ... on AccessGraphUserMoreNode {
              id
              userMore {
                more
              }
            }
            ... on AccessGraphUserNode {
              id
              user {
                id
                email
                firstName
                lastName
              }
            }
          }
        }
        ... on AccessGraphAttributeConditionOrNode {
          id
          totalChildren
          attributeConditionOr {
            integration {
              id
              name
              type
              typeDisplayName
              icons {
                png
                svg
              }
            }
            attribute {
              type
              name
            }
            attributeType {
              type
              name
            }
            operator
            value
          }
          children {
            ... on AccessGraphResourceMoreNode {
              id
              resourceMore {
                more
              }
            }
            ... on AccessGraphResourceNode {
              id
              resource {
                id
                name
                path
              }
            }
            ... on AccessGraphUserMoreNode {
              id
              userMore {
                more
              }
            }
            ... on AccessGraphUserNode {
              id
              user {
                id
                email
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`);

export const accessGraphFilterOptionsQueryDocument = graphql(/* GraphQL */ `
  query AccessGraphFilterOption {
    accessGraphFilterOptions {
      ... on AccessGraphAccessTargetNode {
        id
        totalChildren
        accessTarget {
          integration {
            id
            name
            type
            typeDisplayName
            icons {
              png
              svg
            }
          }
          resourceType {
            type
            typeDisplayName
            icons {
              png
              svg
            }
          }
        }
        children {
          ... on AccessGraphResourceMoreNode {
            id
            resourceMore {
              more
            }
          }
          ... on AccessGraphResourceNode {
            id
            resource {
              id
              name
              path
            }
          }
          ... on AccessGraphUserMoreNode {
            id
            userMore {
              more
            }
          }
          ... on AccessGraphUserNode {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
      ... on AccessGraphAttributeConditionAndNode {
        id
        totalChildren
        attributeConditionAnd {
          integration {
            id
            name
            type
            typeDisplayName
            icons {
              png
              svg
            }
          }
          attribute {
            type
            name
          }
          attributeType {
            type
            name
          }
          operator
          value
        }
        children {
          ... on AccessGraphResourceMoreNode {
            id
            resourceMore {
              more
            }
          }
          ... on AccessGraphResourceNode {
            id
            resource {
              id
              name
              path
            }
          }
          ... on AccessGraphUserMoreNode {
            id
            userMore {
              more
            }
          }
          ... on AccessGraphUserNode {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
      ... on AccessGraphAttributeConditionOrNode {
        id
        totalChildren
        attributeConditionOr {
          integration {
            id
            name
            type
            typeDisplayName
            icons {
              png
              svg
            }
          }
          attribute {
            type
            name
          }
          attributeType {
            type
            name
          }
          operator
          value
        }
        children {
          ... on AccessGraphResourceMoreNode {
            id
            resourceMore {
              more
            }
          }
          ... on AccessGraphResourceNode {
            id
            resource {
              id
              name
              path
            }
          }
          ... on AccessGraphUserMoreNode {
            id
            userMore {
              more
            }
          }
          ... on AccessGraphUserNode {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`);
