import { useSearchParams } from 'react-router-dom';
import { ROLE_FILTER_PARAM } from '@Identities/common/constants';
import { useCallback } from 'react';
import { Chip } from '@mui/material';

export function UsersListItemRole({ role }: { role: string }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = searchParams.getAll(ROLE_FILTER_PARAM).includes(role);

  const handleOnClick = useCallback(() => {
    const allFilterRoles = searchParams.getAll(ROLE_FILTER_PARAM);

    if (allFilterRoles.includes(role)) {
      searchParams.delete(ROLE_FILTER_PARAM);
      allFilterRoles.forEach((filterRole) => {
        if (filterRole !== role) {
          searchParams.append(ROLE_FILTER_PARAM, role);
        }
      });
    } else {
      searchParams.append(ROLE_FILTER_PARAM, role);
    }

    setSearchParams(searchParams);
  }, [role, searchParams, setSearchParams]);

  return (
    <div>
      <Chip label={role} variant={isActive ? 'filled' : 'outlined'} size="small" clickable onClick={handleOnClick} />
    </div>
  );
}
