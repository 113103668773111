import { ReportField } from '@api';
import { dateFromRelativePeriod, DateTime, parseRelativePeriod } from './datetime';

export const QueryParams = {
  filterIntegrationId: 'filter.integration_id',
  filterPermission: 'filter.permission',
  filterRequestorId: 'filter.requestor_id',
  filterResource: 'filter.resource',
  filterStatus: 'filter.status',
  filterResourceType: 'filter.resource_type',
  filterTriggerType: 'filter.trigger_type',
  filterAccessFlowId: 'filter.access_flow_id',
  page: 'page',
  fields: 'fields',
  period: 'period',
  startDate: 'start_date',
  endDate: 'end_date',
  utc: 'utc',
  integrationId: 'integration_id',
  search: 'search',
  requestId: 'request_id',
} as const;

export type QueryParamsKeys = (typeof QueryParams)[keyof typeof QueryParams];

export function getDateQP(params: URLSearchParams, key: QueryParamsKeys): DateTime | undefined {
  const value = params.get(key);
  if (value) {
    return DateTime.fromString(value);
  }
  return undefined;
}

export function getBooleanQP(params: URLSearchParams, key: QueryParamsKeys): boolean | undefined {
  const value = params.get(key);
  if (value) return true;
  return undefined;
}

export function getStringQP(params: URLSearchParams, key: QueryParamsKeys): string | undefined {
  const value = params.get(key);
  return value || undefined;
}

export function getNumberQP(params: URLSearchParams, key: QueryParamsKeys): number | undefined {
  const value = params.get(key);
  if (value) return parseInt(value);
  return undefined;
}

export function getArrayQP(params: URLSearchParams, key: QueryParamsKeys): string[] | undefined {
  const value = params.get(key);
  if (value) return value.split(',');
  return undefined;
}

export function removeFromQP(params: URLSearchParams, key: QueryParamsKeys[]): URLSearchParams {
  const newQP = new URLSearchParams(params);
  key.forEach((k) => newQP.delete(k));
  return newQP;
}

export function getStartEndDateFromQP(params: URLSearchParams): { startDate?: DateTime; endDate?: DateTime } {
  const periodQp = getStringQP(params, QueryParams.period);
  const startDateQp = getDateQP(params, QueryParams.startDate);
  const endDateQp = getDateQP(params, QueryParams.endDate);
  const utcQp = getBooleanQP(params, QueryParams.utc);

  let endDate: DateTime | undefined;
  let startDate: DateTime | undefined;
  if (periodQp) {
    const parsedPeriod = parseRelativePeriod(periodQp);
    endDate = DateTime.now();
    startDate = dateFromRelativePeriod(parsedPeriod, endDate);
  }

  if (startDateQp && endDateQp) {
    endDate = endDateQp;
    startDate = startDateQp;
  }

  if (utcQp && startDate && endDate) {
    const offset = DateTime.utcOffset();
    endDate = endDate.add(offset, 'minute');
    startDate = startDate.add(offset, 'minute');
  }

  return { startDate, endDate };
}

export const reportFields: ReportField[] = Object.values(ReportField);

export function getFieldsFromQP(params: URLSearchParams): ReportField[] {
  const fieldsQp = getStringQP(params, QueryParams.fields);

  if (!fieldsQp) {
    return reportFields;
  }

  return fieldsQp.split(',').filter((c) => reportFields.includes(c as ReportField)) as ReportField[];
}
