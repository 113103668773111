export const RESOURCE_TYPE_FILTER_LABEL = 'Resource Type';
export const RESOURCE_TYPE_FILTER_PARAM = 'resource_type';

export const TAG_FILTER_LABEL = 'Tag';
export const TAG_FILTER_PARAM = 'tags';

export const STATUS_FILTER_LABEL = 'Status';
export const STATUS_FILTER_PARAM = 'status';

export const INTEGRATION_FILTER_LABEL = 'Integration';
export const INTEGRATION_FILTER_PARAM = 'integration';

export const BUNDLE_FILTER_LABEL = 'Bundle';
export const BUNDLE_FILTER_PARAM = 'bundle';

export const LABEL_FILTER_LABEL = 'Label';
export const LABEL_FILTER_PARAM = 'label';

export const GRANTEE_FILTER_PARAM = 'grantee';
export const GRANTEE_FILTER_LABEL = 'Grantee';
