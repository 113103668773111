import { PropsWithChildren, ReactNode } from 'react';
import { Tooltip, Chip, Typography } from '@mui/material';

interface LeftOversChipProps {
  label: string;
  onClick?: () => void;
  tooltip?: ReactNode;
}

export default function LeftOversChip({ label, onClick, tooltip }: PropsWithChildren<LeftOversChipProps>) {
  const chip = (
    <Chip
      label={
        <Typography variant="inherit" color="primary" component="span">
          {label}
        </Typography>
      }
      size="small"
      variant="outlined"
      clickable={!!onClick}
      onClick={onClick}
    />
  );

  if (!tooltip) return chip;

  return (
    <Tooltip title={tooltip} placement="top" arrow>
      {chip}
    </Tooltip>
  );
}
