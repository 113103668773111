import { PropsWithChildren, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { WidgetCardTitle, WidgetCardTitleProps } from './WidgetCardTitle';
import { WidgetCardBody, WidgetCardBodyProps } from './WidgetCardBody';
import { WidgetCardLoading } from './WidgetCardLoading';

const WidgetCardContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
}));

export interface WidgetCardProps extends WidgetCardTitleProps, WidgetCardBodyProps {
  title?: ReactNode;
  loading?: boolean;
}

export function WidgetCard({
  title,
  titleCentered,
  titleActions,
  disableSidePadding,
  children,
  loading,
}: PropsWithChildren<WidgetCardProps>) {
  return (
    <WidgetCardContainer>
      {title && (
        <WidgetCardTitle titleCentered={titleCentered} titleActions={titleActions}>
          {title}
        </WidgetCardTitle>
      )}
      <WidgetCardBody disableSidePadding={disableSidePadding} withTopPadding={!title}>
        {children}
      </WidgetCardBody>
      {loading && <WidgetCardLoading />}
    </WidgetCardContainer>
  );
}
