import { TriggerType } from '@api';

import { AccessFlowTemplate } from './types';

export const templates: AccessFlowTemplate[] = [
  {
    id: 'readonly_access_to_production',
    name: 'Developer temporary readonly access to production',
    trigger_type: TriggerType.UserRequest,
    grantees: 'Developers',
    targets: {
      integration_id: 'Amazon S3 Prod',
      resource_tag_matchers: 'any resource',
      resource_tag_excludes: 'any resource except for "dev"',
      permissions: 'READ_ONLY',
    },
    approvers: 'automatic',
    revoke_after_in_sec: 60 * 60,
  },
  {
    id: 'just_in_time_access',
    name: 'CloudSQL Just In Time Access',
    trigger_type: TriggerType.UserRequest,
    grantees: 'Developers',
    targets: {
      integration_id: 'Google Cloud SQL',
      resource_tag_matchers: 'postgres',
      resource_tag_excludes: 'any resource except for "dev"',
      permissions: 'READ_WRITE',
    },
    approvers: 'gcp-monitoring-admins',
    revoke_after_in_sec: 60 * 60 * 4,
  },
  {
    id: 'access_to_customers_data',
    name: 'Support access to Customers Data',
    trigger_type: TriggerType.UserRequest,
    grantees: 'Customer Support',
    targets: {
      integration_id: 'MongoDB/Demo-MongoDB',
      resource_tag_matchers: 'any resource',
      resource_tag_excludes: 'any resource except for "dev"',
      permissions: 'READ_ONLY',
    },
    approvers: 'automatic',
    revoke_after_in_sec: 60 * 60 * 2,
  },
];
