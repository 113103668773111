import * as Sentry from '@sentry/react';
import { PropsWithChildren } from 'react';

const PENDO_URL = 'https://data.pendo.io';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: ['localhost', 'https://app.apono.io/'],
      shouldCreateSpanForRequest(url) {
        // Exclude Pendo URL from performance metrics
        if (url.includes(PENDO_URL)) {
          return false;
        }
        return true;
      },
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: ENVIRONMENT,
});

function SentryWrapper({ children }: PropsWithChildren) {
  return children;
}

export default SentryWrapper;
