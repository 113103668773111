import { CircularProgress, Stack, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { ProIconType, ProSizes, UpgradeWrapper } from '@components';
import { ReactNode } from 'react';

type AccessFlowLabelSelectorProps = {
  title: string;
  description: ReactNode;
  value?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  isPremium?: boolean;
};

export const SettingSelector = ({
  title,
  description,
  onChange,
  value,
  isLoading,
  isPremium = false,
}: AccessFlowLabelSelectorProps) => {
  return (
    <Stack spacing={1} py={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <UpgradeWrapper isPremium={isPremium} size={ProSizes.Medium} iconType={ProIconType.IconWithText}>
            <Typography variant="subtitle2">{title}</Typography>{' '}
          </UpgradeWrapper>
        </Stack>
        <Stack direction="row" alignItems="center">
          {isLoading && <CircularProgress size={16} color="success" />}
          <Switch
            checked={value}
            color="success"
            onChange={onChange}
            disabled={isPremium}
            data-testid="setting-selector-switch"
          />
        </Stack>
      </Stack>
      {typeof description === 'string' ? (
        <Typography variant="body2" color="text.filter" sx={{ whiteSpace: 'pre-line' }}>
          {description}
        </Typography>
      ) : (
        description
      )}
    </Stack>
  );
};
