import { styled, Stack, Avatar, Tooltip } from '@mui/material';

const AvatarIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
}));

export function ClientIcons() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <Tooltip title="Slack" arrow placement="top">
        <AvatarIcon alt="slack-icon" src="https://slack-cdn.apono.io/integrations/slack.svg" variant="square" />
      </Tooltip>
      <Tooltip title="CLI" arrow placement="top">
        <AvatarIcon alt="terminal-icon" src="https://slack-cdn.apono.io/integrations/ssh.svg" variant="square" />
      </Tooltip>
      <Tooltip title="Teams" arrow placement="top">
        <AvatarIcon alt="teams-icon" src="https://slack-cdn.apono.io/integrations/teams.svg" variant="square" />
      </Tooltip>
      <Tooltip title="Web Portal" arrow placement="top">
        <AvatarIcon alt="apono-icon" src="https://slack-cdn.apono.io/integrations/web-app.svg" variant="square" />
      </Tooltip>
    </Stack>
  );
}
