import { Box, IconButton, Stack, styled } from '@mui/material';
import { GROUPED_NODE_COLLAPSE_BUTTON_HEIGHT, MaterialIcon } from '@components';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: GROUPED_NODE_COLLAPSE_BUTTON_HEIGHT,
  '& .MuiIcon-root': {
    fontSize: theme.spacing(2),
  },
}));

export interface ExpandButtonProps {
  less?: boolean;
}

export function ExpandButton({ less }: ExpandButtonProps) {
  return (
    <Stack alignItems="center">
      <Box>
        <StyledIconButton>
          <MaterialIcon symbol={less ? 'expand_less' : 'expand_more'} id="expand-btn" />
        </StyledIconButton>
      </Box>
    </Stack>
  );
}
