import { getFragmentData } from '@api/gql';
import { Loader } from '@components';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { Button } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from '@routes';
import { sub } from 'date-fns';
import { AlertTypesWidget } from '@DashboardV2/components/AlertsTypesWidget/AlertsTypesWidget';
import { AlertStatsType } from '@api/gql/graphql';
import { useAlertStats } from '@AccessAlerts/services/use-alert-stats';
import { alertStatsFields } from '@AccessAlerts/services/gql';
import { useState } from 'react';
import { CustomAnalyticsEvents } from '@utils/analytics';

export function AlertTypes() {
  const navigate = useNavigate();
  return (
    <WidgetCard
      title="Anomalies"
      subtitle="Last 30 days"
      button={
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate(generatePath(routes.AccessAlerts.path))}
          data-trigger={CustomAnalyticsEvents.ACCESS_ANOMALIES_VIEW_ALL_CLICKED}
        >
          View all
        </Button>
      }
    >
      <AlertTypesContent />
    </WidgetCard>
  );
}

function AlertTypesContent() {
  const [filter] = useState(() => {
    const currentDate = new Date();
    const previous = sub(currentDate, { days: 30 });
    const timestamp = { between: [previous.toISOString(), currentDate.toISOString()] };
    return { timestamp };
  });
  const { data, isFetched } = useAlertStats({ filter, type: AlertStatsType.Type });

  const { alertStats } = data || { alertStats: [] };
  const alertTypeStatsData = getFragmentData(alertStatsFields, alertStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (!alertStats || !alertTypeStatsData || alertTypeStatsData?.length === 0) {
    return (
      <PieChartEmptyState
        title="No data available"
        animationSrc={'/static/EmptyStateImages/no-data-table-chart.svg'}
        size={{ width: 100, height: 75 }}
      />
    );
  }

  return <AlertTypesWidget alerts={alertTypeStatsData} />;
}
