import { Skeleton, Typography } from '@mui/material';

import { FlowFormText, FlowInlineText } from '../common/Text';
import { HumanReadableText, HumanReadableTextProps } from '../common/HumanReadableText';
import { useListAccessBundles } from '@api';
import { useMemo } from 'react';

export interface HumanReadableBundleProps extends Omit<HumanReadableTextProps, 'value' | 'values'> {
  value?: string;
}

function useHumanReadableBundle(value?: string) {
  const { accessBundles, isAccessBundlesFetched } = useListAccessBundles();
  const bundle = useMemo(() => accessBundles.find((b) => b.id === value), [accessBundles, value]);

  return {
    notFound: !bundle,
    name: bundle?.name,
    isAccessBundlesFetched,
  };
}

export function HumanReadableBundle({
  value,
  inline,
  errorText,
  hideErrorText,
  placeholder,
}: HumanReadableBundleProps) {
  const { notFound, name, isAccessBundlesFetched } = useHumanReadableBundle(value);
  const errorProps = { errorText, hideErrorText };

  if (!value) {
    return inline ? (
      <FlowInlineText inline={inline}>{placeholder}</FlowInlineText>
    ) : (
      <FlowFormText selectable muted {...errorProps}>
        {placeholder}
      </FlowFormText>
    );
  }

  if (!isAccessBundlesFetched || !name) {
    return inline ? (
      <FlowInlineText inline={inline}>
        <Skeleton variant="text" width={100} />
      </FlowInlineText>
    ) : (
      <FlowFormText selectable muted {...errorProps}>
        <Skeleton variant="text" width={100} />
      </FlowFormText>
    );
  }

  if (notFound && isAccessBundlesFetched) {
    const notFoundText = (
      <Typography color="error" variant="inherit" component="span">
        Bundle not found
      </Typography>
    );

    return inline ? (
      <FlowInlineText inline={inline}>{notFoundText}</FlowInlineText>
    ) : (
      <FlowFormText selectable {...errorProps}>
        {notFoundText}
      </FlowFormText>
    );
  }

  return <HumanReadableText value={name} inline={inline} {...errorProps} />;
}
