import { AccessFlowAppModelV2, IntegrationAppModel, IntegrationStatus } from '@api';
import { useMemo } from 'react';
import { AlertBanner } from '@components/AlertBanner';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { Stack } from '@mui/material';

export interface WarningIntegrationBannerProps {
  accessFlow: Pick<AccessFlowAppModelV2, 'targets'>;
  connectedIntegrations: IntegrationAppModel[];
}

export function WarningIntegrationBanner({ accessFlow, connectedIntegrations }: WarningIntegrationBannerProps) {
  const problematicIntegrations = useMemo(() => {
    if (!accessFlow?.targets) return [];

    const accessFlowIntegrationsIds = accessFlow.targets.map((i) => i.integration?.integration_id);

    return connectedIntegrations
      .filter((iwe: IntegrationAppModel) => accessFlowIntegrationsIds.includes(iwe.id))
      .filter((i) => i.status === IntegrationStatus.Error || i.status === IntegrationStatus.Warning);
  }, [accessFlow, connectedIntegrations]);

  if (problematicIntegrations.length === 0) return null;

  return (
    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      {problematicIntegrations.map((i) => {
        if (i.status === IntegrationStatus.Warning) {
          return (
            <AlertBanner
              key={i.id}
              message={
                <>
                  Warning in integration &ldquo;<strong>{i.name}</strong>&rdquo;. This can effect your users when they
                  need access.
                </>
              }
              icon={<WarningIcon />}
              link={`/catalog/connected?alertDrawer=${i.id}`}
            />
          );
        }

        if (i.status === IntegrationStatus.Error) {
          return (
            <AlertBanner
              key={i.id}
              type="error-banner"
              message={
                <>
                  Error in integration &ldquo;<strong>{i.name}</strong>&rdquo;. This can effect your users when they
                  need access.
                </>
              }
              icon={<ErrorIcon />}
              link={`/catalog/connected?alertDrawer=${i.id}`}
            />
          );
        }

        return <></>;
      })}
    </Stack>
  );
}
