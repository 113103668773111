import { getFragmentData } from '@api/gql';
import { Loader } from '@components';
import { CurrentActiveAccessWidget } from '@DashboardV2/components/CurrentActiveAccessWidget';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { activeAccessStatsFields, useActiveAccessStats } from '@DashboardV2/services/gql';
import { aponoColors } from '@utils';

export const CUSTOM_COLORS = [aponoColors.tertiary[500], aponoColors.primary[500], aponoColors.success[500]];

export function CurrentActiveAccess() {
  return (
    <WidgetCard title="Active Requests" subtitle="Current State">
      <CurrentActiveAccessContent />
    </WidgetCard>
  );
}

function CurrentActiveAccessContent() {
  const { data, isFetched } = useActiveAccessStats();

  const activeAccessStats = getFragmentData(activeAccessStatsFields, data?.activeAccessStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (
    !activeAccessStats ||
    (!activeAccessStats?.automaticApproval && !activeAccessStats?.manualApproval && !activeAccessStats?.autoGrant)
  ) {
    return (
      <PieChartEmptyState
        title="No active access to display"
        animationSrc={'/static/EmptyStateImages/no-data-donut-chart.svg'}
        size={{ width: 130, height: 80 }}
      />
    );
  }

  return <CurrentActiveAccessWidget data={activeAccessStats} />;
}
