import { PropsWithChildren } from 'react';
import { MaterialIcon, Page } from '@components';
import { Stack, Typography } from '@mui/material';

export function OnboardingAccessFlowPageWrap({ children }: PropsWithChildren) {
  return (
    <Page
      title="Time to create your first Access Flow!"
      subTitle={
        <Stack direction="column" alignItems="flex-start" spacing={2}>
          <Typography variant="body1">See how it works - grant access to your integration</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <MaterialIcon symbol="lightbulb" color="success" />
            <Typography variant="body2" color="success.main">
              Did you know? 79% of users create access flows successfully in under 2 minutes
            </Typography>
          </Stack>
        </Stack>
      }
      specialLayout
    >
      {children}
    </Page>
  );
}
