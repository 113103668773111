import { PropsWithChildren, ReactNode } from 'react';

import { Box, Stack, styled, Typography } from '@mui/material';

const TimelineItemMetaContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
}));

export interface TimelineItemMetaProps {
  title: string;
  icon?: ReactNode;
  bordered?: boolean;
}

export function TimelineItemMeta({ title, icon, bordered, children }: PropsWithChildren<TimelineItemMetaProps>) {
  if (!bordered) {
    return (
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} py={1}>
        {icon}
        {children}
        <Typography variant="tooltip" color="text.muted">
          {title}
        </Typography>
      </Stack>
    );
  }

  return (
    <TimelineItemMetaContainer>
      <Typography variant="tooltip" component="p" color="text.muted">
        {title}
      </Typography>
      {children}
    </TimelineItemMetaContainer>
  );
}
