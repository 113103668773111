export const GRANTEE_FILTER_PARAM = 'grantee';
export const INTEGRATION_FILTER_PARAM = 'integration';
export const BUNDLE_FILTER_PARAM = 'bundle';
export const STATUS_FILTER_PARAM = 'active';
export const APPROVAL_FILTER_PARAM = 'approval';
export const LABEL_FILTER_PARAM = 'label';
export const TRIGGER_TYPE_FILTER_PARAM = 'trigger-type';
export const WITH_BUNDLE_QUERY_PARAM = 'withBundle';
export const FROM_ACCESS_FLOW_QUERY_PARAM = 'fromAccessFlow';
export const EDIT_BUNDLE_QUERY_PARAM = 'edit';
export const NEW_ACCESS_FLOW_QUERY_PARAM_VALUE = 'true';
export const BUNDLE_QUERY_PARAM = 'bundleModal';

export const AttributeOperatorModel = {
  Is: 'is',
  IsNot: 'is_not',
  Contains: 'contains',
  DoesNotContain: 'does_not_contain',
  StartsWith: 'starts_with',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AttributeOperatorModel = (typeof AttributeOperatorModel)[keyof typeof AttributeOperatorModel];
