import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { UserIdentityAppModel, UserIdentityAttributeAppModel, AttributeAppModelIntegration } from '@api';

import { MaterialIcon, StatusColumn } from '@components';
import PrimarySecondary from '@common/ui/PrimarySecondary';

import AttributeItemContainer from '@Identities/components/AttributeItemContainer';
import UsersListItemAttributesList from '@Identities/components/UsersListItemAttributesList';
import UsersListItemContainer from '@Identities/components/UsersListItemContainer';
import UsersListItemGroupName from '@Identities/components/UsersListItemGroupName';
import UserListAttribute from './UsersListItemAttribute';
import { UsersListItemRole } from '@Identities/organisms/UsersListItemRole';
import { Dot } from '@common/ui/StatusIcon/StatusIcon';

interface UsersListItemProps {
  user: UserIdentityAppModel;
}

export default function UsersListItem({ user }: UsersListItemProps) {
  const userIcon = useMemo(() => {
    return user.integration?.icons.svg || <MaterialIcon symbol={'person'} weight={700} />;
  }, [user]);
  const statusLabel = user.active ? 'Active' : 'Disabled';

  return (
    <UsersListItemContainer
      user={
        <PrimarySecondary
          icon={userIcon}
          iconSpacing={2}
          primary={`${user.first_name} ${user.last_name}`}
          secondary={user.email}
        />
      }
      attributes={<UserListItemAttributesGroups attributes={user.attributes} />}
      roles={
        <Stack spacing={1}>
          {user.roles.map((role) => (
            <UsersListItemRole key={role} role={role} />
          ))}
        </Stack>
      }
      status={
        <StatusColumn status={statusLabel} icon={<Dot className={user.active ? 'success' : 'error'} p={1.5} />} />
      }
    />
  );
}

function keyFromAttribute(attribute: UserIdentityAttributeAppModel) {
  return JSON.stringify([attribute.name, attribute.integration]);
}

function attributeFromKey(key: string): [string, AttributeAppModelIntegration | undefined] {
  return JSON.parse(key) as [string, AttributeAppModelIntegration];
}

function UserListItemAttributesGroups({ attributes }: { attributes: UserIdentityAttributeAppModel[] }) {
  const groupedAttributes = useMemo(() => {
    const grouped = new Map<string, UserIdentityAttributeAppModel[]>();

    attributes.forEach((a) => {
      const uniqueKey = keyFromAttribute(a);
      const group = grouped.get(uniqueKey) || [];
      group.push(a);
      grouped.set(uniqueKey, group);
    });

    return Array.from(grouped.entries());
  }, [attributes]);

  return (
    <Stack spacing={3}>
      {groupedAttributes.map(([key, attrs]) => {
        const [name, integration] = attributeFromKey(key);
        return (
          <AttributeItemContainer
            key={key}
            groupName={
              <UsersListItemGroupName
                name={name}
                icon={integration?.icons.svg || '/static/logo-only.svg'}
                integrationName={integration?.name ?? 'Apono'}
              />
            }
          >
            <UsersListItemAttributesList
              attributes={attrs}
              renderAttribute={(a) => <UserListAttribute attribute={a} />}
            />
          </AttributeItemContainer>
        );
      })}
    </Stack>
  );
}
