import { useMemo, memo } from 'react';

import { Stack, Typography, Pagination } from '@mui/material';
import { PaginatedResponseModel } from '@api';

const TablePagination = memo(function TablePagination<T>({
  pageData: { data, pagination },
  isLoading,
  onChange,
}: {
  pageData: PaginatedResponseModel<T>;
  isLoading: boolean;
  onChange: (page: number) => void;
}) {
  const count = useMemo(() => Math.ceil(pagination.total / pagination.limit), [pagination]);
  const page = useMemo(() => Math.floor(pagination.offset / pagination.limit) + 1, [pagination]);

  const paginationText = useMemo(() => {
    if (pagination.total <= pagination.limit) {
      return 'all';
    }

    return `${pagination.offset + 1} - ${pagination.offset + data.length}`;
  }, [pagination.total, pagination.limit, pagination.offset, data.length]);

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Typography variant="caption" color="text.muted">
        Showing {paginationText} of {pagination.total} requests
      </Typography>
      <Pagination count={count} page={page} onChange={(_, newPage) => onChange(newPage)} disabled={isLoading} />
    </Stack>
  );
});

export default TablePagination;
