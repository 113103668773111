import { useState } from 'react';

import { ActivityRequestAppModel } from '@api';
import { useRevokeAccess } from './query';
import { LoadingButton } from '@mui/lab';
import { ConfirmDialog } from '@components';

export default function RevokeAccess({ request }: { request: ActivityRequestAppModel }) {
  const { revokeAccess, isRevokeAccessLoading } = useRevokeAccess(request.id);
  const [opened, setOpened] = useState(false);

  return (
    <>
      <LoadingButton
        variant="contained"
        color="error"
        loading={isRevokeAccessLoading}
        onClick={() => setOpened(true)}
        fullWidth
      >
        Revoke Access
      </LoadingButton>
      <ConfirmDialog open={opened} onClose={() => setOpened(false)} onConfirm={() => revokeAccess()}>
        Are you sure you want to revoke <strong>{request.friendly_id}</strong> access?
      </ConfirmDialog>
    </>
  );
}
