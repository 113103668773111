import { FilterProps } from '@common/organisms/CommonFilters/types';
import { AnalyticsTriggerElementProps } from '@common/types';
import { useMemo, useState } from 'react';
import { useAlertUserFilterOptions } from '@AccessAlerts/services/use-alerts-filter-options';
import { getFragmentData } from '@api/gql';
import { DateTimeFilterInput, UserFieldsFragmentDoc } from '../../api/gql/graphql';
import GenericAsyncFilterMultiSelect from '@common/organisms/CommonFilters/GenericAsyncFilterMultiSelect';

export const USER_FILTER_LABEL = 'User';
export const USER_FILTER_PARAM = 'user';

export function AlertUserFilter({
  opened,
  onClose,
  onOpen,
  selected,
  onSelect,
  onClear,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps & { timestamp?: DateTimeFilterInput }) {
  const [enabled] = useState(() => selected.length > 0);
  const { data, isFetched, refetch } = useAlertUserFilterOptions(enabled);

  const options = useMemo(() => {
    return (
      data?.alertsFilterOptions.users.map((i) => {
        const user = getFragmentData(UserFieldsFragmentDoc, i);
        return {
          value: user.email,
          label: `${user.firstName} ${user.lastName} (${user.email})`,
        };
      }) || []
    );
  }, [data]);

  return (
    <GenericAsyncFilterMultiSelect
      opened={opened}
      onClear={onClear}
      onClose={onClose}
      options={options}
      filterLabel={USER_FILTER_LABEL}
      filterParam={USER_FILTER_PARAM}
      isOptionsFetched={isFetched}
      refetchOptions={refetch}
      {...analyticsProps}
    />
  );
}
