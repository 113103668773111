import { EmptyState } from '@components';

export interface WidgetCardEmptyProps {
  text?: string;
}

export function WidgetCardEmpty({ text }: WidgetCardEmptyProps) {
  return (
    <EmptyState
      noPadding
      imgSrc="/static/EmptyStateImages/not-found-illustration.svg"
      body={text || 'No data to display'}
    />
  );
}
