import { useMemo, useState } from 'react';
import { Alert, Box, Button, Fade, Modal, Paper, Stack, Typography, styled } from '@mui/material';

import { useConnectorToken, useListAgents } from '@api';
import { LocalInstallInstructions } from '@organisms/InstallConnectorWizard/NewConnectorsWatcher';
import { CloseButton } from '@components';

const BannerWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 6, 0),
}));

const ModalPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(6, 'auto'),
  maxWidth: '600px',
}));

export default function LocalConnectorFailedBanner() {
  const { connectors } = useListAgents(true);
  const { token } = useConnectorToken();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const failedLocalConnector = useMemo(
    () =>
      connectors.find(
        (connector) => connector.cloud_provider?.local_deploy && connector.status.toLowerCase() === 'disconnected',
      ),
    [connectors],
  );

  if (!failedLocalConnector || !token) return null;

  return (
    <BannerWrapper>
      <Alert
        severity="error"
        action={
          <Button variant="contained" size="small" onClick={handleOpen}>
            Rerun installation command
          </Button>
        }
      >
        Connector <strong>{failedLocalConnector.name}</strong> is down.
      </Alert>
      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <ModalPaper>
            <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">Rerun the local installation command</Typography>
                <CloseButton onClose={handleClose} />
              </Stack>
              <LocalInstallInstructions token={token} noTitle />
            </Stack>
          </ModalPaper>
        </Fade>
      </Modal>
    </BannerWrapper>
  );
}
