import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  InteractiveDropdown,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownView,
  MaterialIcon,
  Popover,
  usePopover,
} from '@components';
import { useAccessFlowsPack } from '@hooks';
import { AccessTargetType } from '@api';
import { useSearchParams, yup } from '@libs';
import { HumanReadableBundle, HumanReadableIntegration } from '../HumanReadable';
import { useErrorText } from '../common/use-error-text.hook';
import { IntegrationInfo } from '../common/interfaces';
import { DropdownBundlesView } from './organisms/DropdownBundlesView';
import { DropdownIntegrationsView } from './organisms/DropdownIntegrationsView';
import { DrawerResourceTypesSelect } from './organisms/DrawerResourceTypesSelect';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export interface FlowFormSelectIntegrationOrBundleDrawerProps {
  integrationInfo?: IntegrationInfo;
  onIntegrationInfoChange: (value: IntegrationInfo) => void;
  bundleId?: string;
  onBundleIdChange: (value: string) => void;
  template?: string;
  error?: yup.ValidationError;
  placeholder?: string;
  noBundles?: boolean;
}

const DEFAULT_PLACEHOLDER = 'Select target';

function useFlowFormSelectIntegrationOrBundleDrawer({
  integrationInfo,
  onIntegrationInfoChange,
  onBundleIdChange,
  template,
  bundleId,
  noBundles,
}: FlowFormSelectIntegrationOrBundleDrawerProps) {
  const { track } = useAnalyticsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const fromIntegration = searchParams.get('fromIntegration');
  const withResourceType = searchParams.get('withResourceType');

  const { isLoading } = useAccessFlowsPack();
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string | undefined>(template);
  const [currentView, setCurrentView] = useState(0);
  const [drawerOpened, setDrawerOpened] = useState(false);

  const [selectedTargetType, setSelectedTargetType] = useState<AccessTargetType | undefined>();

  const [integrationId, setIntegrationId] = useState(integrationInfo?.integrationId);
  const [resourceType, setResourceType] = useState(integrationInfo?.resourceType);

  useEffect(() => {
    if (fromIntegration && !withResourceType) {
      setIntegrationId(fromIntegration);
      setDrawerOpened(true);
    }
  }, [fromIntegration, searchParams, setSearchParams, withResourceType]);

  useEffect(() => {
    if (integrationInfo?.integrationId || bundleId || noBundles) {
      setSelectedTargetType(noBundles || !bundleId ? AccessTargetType.Integration : AccessTargetType.Bundle);
      setCurrentView(1);
    }
  }, [bundleId, integrationInfo?.integrationId, noBundles, setCurrentView, setSelectedTargetType]);

  const onOpen = () => {
    hideErrorText();
    setIntegrationId(integrationInfo?.integrationId);
    setResourceType(integrationInfo?.resourceType);
  };

  const onClose = () => {
    showErrorText();
  };

  const externalState = usePopover({
    onOpen,
    onClose,
  });

  const closeDrawer = useCallback(() => {
    setDrawerOpened(false);

    if (fromIntegration) {
      const newSp = new URLSearchParams(searchParams);
      newSp.delete('fromIntegration');
      setSearchParams(newSp);
    }
  }, [fromIntegration, searchParams, setSearchParams]);

  const handleResourceTypeChange = useCallback(
    (type: string) => {
      if (!integrationId) return;

      setResourceType(type);
      onIntegrationInfoChange({ integrationId, resourceType: type });
      if (templatePlaceholder) setTemplatePlaceholder(undefined);
      closeDrawer();
    },
    [closeDrawer, integrationId, onIntegrationInfoChange, templatePlaceholder],
  );

  const handleIntegrationSelectChange = useCallback(
    (val: string) => {
      if (templatePlaceholder) setTemplatePlaceholder(undefined);
      setIntegrationId(val);

      if (integrationInfo?.integrationId !== val) {
        setResourceType(undefined);
      } else {
        setResourceType(integrationInfo.resourceType);
      }

      externalState.closeDropdown();
      setDrawerOpened(true);
    },
    [externalState, integrationInfo?.integrationId, integrationInfo?.resourceType, templatePlaceholder],
  );

  const handleTargetTypeChange = (newTargetType?: AccessTargetType) => {
    setSelectedTargetType(newTargetType);
    setCurrentView(1);
    if (newTargetType === AccessTargetType.Bundle) {
      track(CustomAnalyticsEvents.BUNDLE_SELECTED_IN_FLOW_BUILDER);
    }
  };

  const handleBundleIdChange = (bundleID: string) => {
    onBundleIdChange(bundleID);
    externalState.closeDropdown();
    if (templatePlaceholder) setTemplatePlaceholder(undefined);
  };

  return {
    templatePlaceholder,
    isLoading,
    errorTextHidden,
    externalState,
    currentView,
    integrationId,
    resourceType,
    selectedTargetType,
    setSelectedTargetType,
    handleIntegrationSelectChange,
    handleResourceTypeChange,
    handleTargetTypeChange,
    handleBundleIdChange,
    onOpen,
    drawerOpened,
    closeDrawer,
    setCurrentView,
  };
}

export function FlowFormSelectIntegrationOrBundleDrawer({
  integrationInfo,
  onIntegrationInfoChange,
  bundleId,
  onBundleIdChange,
  error,
  template,
  placeholder,
  noBundles,
}: FlowFormSelectIntegrationOrBundleDrawerProps) {
  const {
    templatePlaceholder,
    isLoading,
    errorTextHidden,
    externalState,
    currentView,
    integrationId,
    resourceType,
    handleIntegrationSelectChange,
    handleResourceTypeChange,
    handleBundleIdChange,
    onOpen,
    drawerOpened,
    closeDrawer,
    selectedTargetType,
    handleTargetTypeChange,
    setCurrentView,
  } = useFlowFormSelectIntegrationOrBundleDrawer({
    integrationInfo,
    onIntegrationInfoChange,
    error,
    template,
    placeholder,
    bundleId,
    onBundleIdChange,
    noBundles,
  });

  const humanReadable = useMemo(() => {
    if (selectedTargetType === AccessTargetType.Bundle) {
      return (
        <HumanReadableBundle
          value={bundleId}
          placeholder={placeholder || templatePlaceholder || DEFAULT_PLACEHOLDER}
          placeholderMuted
          errorText={error?.message}
          hideErrorText={errorTextHidden}
        />
      );
    } else {
      return (
        <HumanReadableIntegration
          value={integrationInfo}
          placeholder={placeholder || templatePlaceholder || DEFAULT_PLACEHOLDER}
          placeholderMuted
          errorText={error?.message}
          hideErrorText={errorTextHidden}
        />
      );
    }
  }, [error, errorTextHidden, integrationInfo, placeholder, bundleId, templatePlaceholder, selectedTargetType]);

  return (
    <>
      <Popover
        testId="flow-form-select-integration-or-bundle"
        trigger={humanReadable}
        externalState={externalState}
        onOpen={onOpen}
      >
        <InteractiveDropdown wide multipleViews currentView={currentView}>
          <DropDownTargetType value={selectedTargetType} onChange={handleTargetTypeChange} current={currentView} />
          {selectedTargetType === AccessTargetType.Integration ? (
            <DropdownIntegrationsView
              value={integrationId}
              current={currentView === 1}
              onBackClick={noBundles ? undefined : () => setCurrentView(0)}
              onIntegrationChange={handleIntegrationSelectChange}
            />
          ) : (
            <DropdownBundlesView
              value={bundleId}
              isLoading={isLoading}
              current={currentView === 1}
              onBackClick={() => setCurrentView(0)}
              onBundleChange={handleBundleIdChange}
            />
          )}
        </InteractiveDropdown>
      </Popover>
      {integrationId && (
        <DrawerResourceTypesSelect
          open={drawerOpened}
          onClose={closeDrawer}
          integrationId={integrationId}
          value={resourceType}
          onChange={handleResourceTypeChange}
        />
      )}
    </>
  );
}

function DropDownTargetType({
  value,
  onChange,
  current,
}: {
  value?: AccessTargetType;
  onChange: (type: AccessTargetType) => void;
  current: number;
}) {
  const targetTypeOptions = useMemo(
    () => [
      {
        key: AccessTargetType.Integration,
        value: 'Integrations',
        hasNextView: true,
        icon: <MaterialIcon symbol="cable" />,
      },
      {
        key: AccessTargetType.Bundle,
        value: 'Bundles',
        hasNextView: true,
        icon: <MaterialIcon symbol="stacks" />,
      },
    ],
    [],
  );

  return (
    <InteractiveDropdownView header={<InteractiveDropdownHeader title="Select target type" />} current={current === 0}>
      <InteractiveDropdownSelect<AccessTargetType> value={value} options={targetTypeOptions} onChange={onChange} />
    </InteractiveDropdownView>
  );
}
