import { Link } from 'react-router-dom';

import { Page } from '@components';
import { routes } from '@routes';
import { Button, Stack } from '@mui/material';

import { ConnectedIntegrationsFilters } from '@Integrations/organisms/ConnectedIntegrationsFilters';
import { ConnectedIntegrationsTable } from '@Integrations/organisms/ConnectedIntegrationsTable';
import SearchBarFilter from '@common/organisms/SearchBarFilter';
import TableListTotal from '@common/components/TableListTotal';
import { useState } from 'react';

export default function ConnectedIntegrationsPageV2() {
  const [integrationResults, setIntegrationResults] = useState({
    total: 0,
    displayed: 0,
  });

  return (
    <Page
      title="Integrations"
      tabs={[
        {
          label: 'Connected',
          to: routes.ConnectedIntegrations.path,
        },
        {
          label: 'Catalog',
          to: routes.Catalog.path,
        },
      ]}
    >
      <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <SearchBarFilter />
            <ConnectedIntegrationsFilters />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <TableListTotal totalResults={integrationResults.total} displayedResults={integrationResults.displayed} />
            <Button variant="contained" component={Link} to={routes.Catalog.path}>
              Add Integration
            </Button>
          </Stack>
        </Stack>
        <ConnectedIntegrationsTable setIntegrationResults={setIntegrationResults} />
      </Stack>
    </Page>
  );
}
