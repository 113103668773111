import { useMemo, useState } from 'react';
import { AccessFlowAreaModel, ApproverConditionAppModel, ApproverConditionAppModelAttributeCondition } from '@api';
import AdvancedAttribute from '@AccessFlows/organisms/FlowForm/Form/AdvancedAttribute';
import { AttributeOperatorModel } from '@AccessFlows/common/constants';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

interface ApproverConditionSelectorProps {
  value?: ApproverConditionAppModel;
  onChange: (value: ApproverConditionAppModel) => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
}

export default function ApproverConditionSelector({
  value,
  onChange,
  onDelete,
  onDuplicate,
}: ApproverConditionSelectorProps) {
  const { track } = useAnalyticsContext();
  const [approverCondition, setApproverCondition] = useState<ApproverConditionAppModel | undefined>(value);
  const currentFilter = useMemo(
    () =>
      approverCondition?.request_context_based_attribute_condition
        ? {
            attribute_type: approverCondition.request_context_based_attribute_condition.attribute_type,
            integration: approverCondition.request_context_based_attribute_condition.integration,
            operator: AttributeOperatorModel.Is,
            attribute_value: [],
            matching_attributes: [],
          }
        : approverCondition?.attribute_condition,
    [approverCondition],
  );

  const handleAttributeChange = (newVal?: ApproverConditionAppModelAttributeCondition) => {
    const newCondition = {
      attribute_condition: newVal?.attribute_type.request_context_based ? undefined : newVal,
      request_context_based_attribute_condition: newVal?.attribute_type.request_context_based ? newVal : undefined,
    };

    if (newVal?.attribute_type.type === 'Owner')
      track(CustomAnalyticsEvents.OWNER_SELECTED_AS_APPROVER_IN_FLOW_BUILDER);

    setApproverCondition(newCondition);
    onChange(newCondition);
  };

  return (
    <AdvancedAttribute
      filter={currentFilter}
      onChange={handleAttributeChange}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      applicablePlaces={[AccessFlowAreaModel.Approver]}
    />
  );
}
