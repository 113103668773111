import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Box, styled } from '@mui/system';
import { Alink } from '@components';

import { Code } from './Code';
import { Span } from './Span';

const MarkdownWrapper = styled(Box)(({ theme }) => ({
  '& p:first-of-type': {
    marginTop: 0,
  },
  '& p:last-of-type': {
    marginBottom: 0,
  },
  '& ol': {
    paddingInlineStart: theme.spacing(2),
  },
}));

const StyledMarkdown = styled(ReactMarkdown)({
  '&.fix-spaces': {},
});

export function Markdown({ children }: { children: string }) {
  children = children.replace(/\\n/g, '\n');
  if (children.includes('*_') && children.includes('_*')) {
    children = children.replace(/\*_/g, '<span class="md-alert">_').replace(/_\*/g, '_</span>');
  }

  return (
    <MarkdownWrapper>
      <StyledMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          a: Alink,
          code: Code,
          span: Span,
        }}
        className="fix-spaces"
      >
        {children}
      </StyledMarkdown>
    </MarkdownWrapper>
  );
}
