import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { usePopoverContext } from '@components/Popover';
import { dateFromRelativePeriod, RelativePeriodParams } from '@utils';
import { ReportPeriodUnit } from '@api';

const StyledNumberInput = styled(TextField)({
  width: '70px',
});

const StyledUnitSelect = styled(Select)({
  width: '140px',
});

const StyledExampleDate = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.active,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  ...theme.typography.helperText,
}));

export interface DateRangePickerRelativeProps {
  period?: RelativePeriodParams;
  onChange: (period: RelativePeriodParams) => void;
}

function useDateRangePickerRelative({ period, onChange }: DateRangePickerRelativeProps) {
  const [selectedPeriod, setSelectedPeriod] = useState<number>(period?.value || 1);
  const [selectedPeriodUnit, setSelectedPeriodUnit] = useState<ReportPeriodUnit>(
    period?.unit || ReportPeriodUnit.Hours,
  );
  const [selectedRoundToUnit, setSelectedRoundToUnit] = useState(period?.rounded || false);

  const periodOptions = useMemo(() => {
    return [ReportPeriodUnit.Hours, ReportPeriodUnit.Days, ReportPeriodUnit.Months].map((u) => ({
      value: u,
      label: selectedPeriod > 1 ? u : u.slice(0, -1),
    }));
  }, [selectedPeriod]);

  useEffect(() => {
    setSelectedPeriod(period?.value || 1);
    setSelectedPeriodUnit(period?.unit || ReportPeriodUnit.Hours);
    setSelectedRoundToUnit(period?.rounded || false);
  }, [period]);

  function applyChange() {
    onChange({
      value: selectedPeriod,
      unit: selectedPeriodUnit,
      rounded: selectedRoundToUnit,
    });
  }

  return {
    periodOptions,
    selectedPeriod,
    setSelectedPeriod,
    selectedPeriodUnit,
    setSelectedPeriodUnit,
    selectedRoundToUnit,
    setRoundToUnit: setSelectedRoundToUnit,
    applyChange,
  };
}

export function DateRangePickerRelative(props: DateRangePickerRelativeProps) {
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();
  const {
    periodOptions,
    selectedPeriod,
    setSelectedPeriod,
    selectedPeriodUnit,
    setSelectedPeriodUnit,
    selectedRoundToUnit,
    setRoundToUnit,
    applyChange,
  } = useDateRangePickerRelative(props);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        applyChange();
      }}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="inputLabel">Last</Typography>
          <StyledNumberInput
            variant="outlined"
            size="small"
            type="number"
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(Number(e.target.value))}
            inputProps={{
              min: 1,
            }}
          />
          <StyledUnitSelect
            value={selectedPeriodUnit}
            onChange={(e) => setSelectedPeriodUnit(e.target.value as ReportPeriodUnit)}
            size="small"
            onOpen={popoverDisableOnClose}
            onClose={popoverEnableOnClose}
          >
            {periodOptions.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </StyledUnitSelect>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox checked={selectedRoundToUnit} size="small" onChange={(e) => setRoundToUnit(e.target.checked)} />
            }
            label={<Typography variant="inputLabel">Round to the {selectedPeriodUnit}</Typography>}
          />
          <Button size="small" variant="contained" type="submit">
            Apply
          </Button>
        </Stack>
        <ExampleDate value={selectedPeriod} unit={selectedPeriodUnit} rounded={selectedRoundToUnit} />
      </Stack>
    </form>
  );
}

function ExampleDate(period: RelativePeriodParams) {
  return (
    <StyledExampleDate>
      <Typography color="text.muted" component="span" variant="inherit">
        Start date:
      </Typography>{' '}
      <strong>{dateFromRelativePeriod(period).readable}</strong>
    </StyledExampleDate>
  );
}
