import { AlertSeverityWidget } from '@AccessAlerts/components/AlertSeverityWidget';
import { alertStatsFields } from '@AccessAlerts/services/gql';
import { useAlertStats } from '@AccessAlerts/services/use-alert-stats';
import { getFragmentData } from '@api/gql';
import { AlertFilterInput, AlertStatsType } from '@api/gql/graphql';
import { Loader } from '@components';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { aponoColors } from '@utils';
import { sub } from 'date-fns';
import { useMemo } from 'react';

export const CUSTOM_COLORS = [aponoColors.error[500], aponoColors.warning[700], aponoColors.neutral[300]];

const DEFAULT_TIMESTAMP = { between: [sub(new Date(), { years: 1 }).toISOString(), new Date().toISOString()] };

export function AlertSeverity({ filters }: { filters?: AlertFilterInput }) {
  return (
    <WidgetCard title="Anomalies">
      <AlertSeverityContent filters={filters} />
    </WidgetCard>
  );
}

function AlertSeverityContent({ filters }: { filters?: AlertFilterInput }) {
  const filtersWithDateFilter: AlertFilterInput = useMemo(() => {
    const filtersCopy = filters || {};
    if (!filters?.timestamp) {
      filtersCopy.timestamp = DEFAULT_TIMESTAMP;
    }
    return filtersCopy;
  }, [filters]);

  const { data, isFetched } = useAlertStats({ filter: filtersWithDateFilter, type: AlertStatsType.Severity });

  const { alertStats } = data || { alertStats: [] };

  const alertSeverity = getFragmentData(alertStatsFields, alertStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (!alertSeverity || alertSeverity.length === 0) {
    return (
      <PieChartEmptyState
        title="No alerts to display"
        animationSrc={'/static/EmptyStateImages/no-data-donut-chart.svg'}
        size={{ width: 130, height: 80 }}
      />
    );
  }

  return <AlertSeverityWidget data={alertSeverity} />;
}
