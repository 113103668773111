import { FilterProps } from '@common/organisms/CommonFilters/types';
import { AnalyticsTriggerElementProps } from '@common/types';
import { useMemo, useState } from 'react';
import { useAlertIntegrationFilterOptions } from '@AccessAlerts/services/use-alerts-filter-options';
import { getFragmentData } from '@api/gql';
import GenericAsyncFilterMultiSelect from '@common/organisms/CommonFilters/GenericAsyncFilterMultiSelect';
import { INTEGRATION_FRAGMENT } from '../../AccessRightSizing/services/gql';

export const INTEGRATION_FILTER_LABEL = 'Integration';
export const INTEGRATION_FILTER_PARAM = 'integration';

export function AlertIntegrationFilter({
  opened,
  onClose,
  onOpen,
  selected,
  onSelect,
  onClear,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps) {
  const [enabled] = useState(() => selected.length > 0);
  const { data, isFetched, refetch } = useAlertIntegrationFilterOptions(enabled);

  const options = useMemo(() => {
    return (
      data?.alertsFilterOptions.integrations.map((i) => {
        const integration = getFragmentData(INTEGRATION_FRAGMENT, i);
        return {
          value: integration.id,
          label: integration.name,
          icon: integration.icons.svg,
        };
      }) || []
    );
  }, [data]);

  return (
    <GenericAsyncFilterMultiSelect
      opened={opened}
      onClear={onClear}
      onClose={onClose}
      options={options}
      filterLabel={INTEGRATION_FILTER_LABEL}
      filterParam={INTEGRATION_FILTER_PARAM}
      isOptionsFetched={isFetched}
      refetchOptions={refetch}
      {...analyticsProps}
    />
  );
}
