import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

// flex: 0 0 56px - there is no way to do it with theme.spacing(7) because there is no theme yet
const SidebarWrapper = styled.div`
  position: relative;
  flex: 0 0 56px;
`;

const SidebarContent = styled.div`
  position: fixed;
  height: 100%;
  left: 0;
  z-index: 99;
  display: flex;
`;

const AppBodyContainer = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const AppContentContainer = styled.div`
  flex: 1;
`;

const AppFooterContainer = styled.div``;

export interface LayoutProps {
  sidebar?: ReactNode;
  footer?: ReactNode;
}

export function Wrapper({ sidebar, footer, children }: PropsWithChildren<LayoutProps>) {
  return (
    <AppWrapper>
      {sidebar && (
        <SidebarWrapper>
          <SidebarContent>{sidebar}</SidebarContent>
        </SidebarWrapper>
      )}
      <AppBodyContainer>
        <AppContentContainer>{children}</AppContentContainer>
        {footer && <AppFooterContainer>{footer}</AppFooterContainer>}
      </AppBodyContainer>
    </AppWrapper>
  );
}
