import { useMemo, useState } from 'react';

import {
  InteractiveDropdownFilterInput,
  InteractiveDropdownHeader,
  InteractiveDropdownSelect,
  InteractiveDropdownView,
} from '@components';

import { useFlowFormSelectPermissionsContext } from './context';

function useDropdownPermissionsView(handlePermissionsSelect: (value: string[]) => void) {
  const { selectedResourceType, fetchedPermissions } = useFlowFormSelectPermissionsContext();

  const [filter, setFilter] = useState('');
  const handleFilterChange = (val: string) => setFilter(val);

  const remappedOptions = useMemo(() => {
    if (!selectedResourceType) return [];

    const permissionsType = fetchedPermissions.find((i) => i.resource_type === selectedResourceType);

    if (!permissionsType) {
      return [];
    }

    return permissionsType.permissions.map((p) => ({
      key: p,
      value: p,
    }));
  }, [fetchedPermissions, selectedResourceType]);

  const options = useMemo(
    () => remappedOptions.filter((v) => v.value.toLocaleLowerCase().includes(filter.toLocaleLowerCase())),
    [filter, remappedOptions],
  );

  const handleChange = (newValue: string[]) => {
    newValue = newValue.filter((v) => remappedOptions.find((o) => o.key === v));
    handlePermissionsSelect(newValue);
  };

  return { options, filter, handleFilterChange, handleChange };
}

export function DropdownPermissionsView() {
  const { selectedPermissions, isPermissionsFetched, handlePermissionsSelect } = useFlowFormSelectPermissionsContext();

  const { options, filter, handleFilterChange, handleChange } = useDropdownPermissionsView(handlePermissionsSelect);

  const header = <InteractiveDropdownHeader title="Permissions" />;

  if (!isPermissionsFetched) {
    return <InteractiveDropdownView current loading header={header} />;
  }

  return (
    <InteractiveDropdownView
      current
      header={header}
      filter={<InteractiveDropdownFilterInput value={filter} onChange={handleFilterChange} />}
    >
      <InteractiveDropdownSelect<string>
        hasSelectAll
        multiple
        options={options}
        value={selectedPermissions}
        onChange={handleChange}
        selectedFirst
      />
    </InteractiveDropdownView>
  );
}
