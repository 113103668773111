import { HTMLAttributes, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  CircularProgress,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { usePopoverContext } from '@components/Popover';
import { SearchFilterValueSelectOption } from '@components';

export interface SearchFilterMultipleSelectProps {
  loading?: boolean;
  options: SearchFilterValueSelectOption[];
  value?: string | string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
}

const Wrapper = styled(Box)({
  minWidth: 320,
});

const StyledOptions = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
}));

export function SearchFilterMultipleSelect({
  loading,
  options,
  value,
  onChange,
  placeholder = 'Search',
}: SearchFilterMultipleSelectProps) {
  const [selectedValues, setSelectedValues] = useState<SearchFilterValueSelectOption[]>([]);
  const { popoverDisableOnClose, popoverEnableOnClose } = usePopoverContext();
  const [optionsOrder, setOptionsOrder] = useState<SearchFilterValueSelectOption[]>(options);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (options?.length === 0) return;
    const selected = options.filter((o) => value?.includes(o.value));
    setSelectedValues(selected);
  }, [value, options]);

  useEffect(() => {
    if (loading) return;
    setOptionsOrder(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handlePopoverClose = () => {
    popoverEnableOnClose();
    const orderedOptions = [...selectedValues, ...options.filter((option) => !selectedValues.includes(option))];
    setOptionsOrder(orderedOptions);
  };

  const handleValueChange = (val: SearchFilterValueSelectOption[] | null) => {
    setSelectedValues(val || []);
    onChange(val?.map((v) => v.value) || []);
    setInputValue('');
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string, reason: string) => {
    if (reason !== 'reset') setInputValue(newInputValue);
  };

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: SearchFilterValueSelectOption,
    { selected }: { selected: boolean },
  ) => (
    <StyledOptions {...props} key={`${option.value}-${option.label}`} data-testid="search-filter-option">
      <Checkbox checked={selected} size="small" sx={{ p: 0 }} />
      <Typography variant="caption">{option.label}</Typography>
    </StyledOptions>
  );

  const renderTags = (tagValue: SearchFilterValueSelectOption[]) =>
    tagValue.length > 0 && (
      <Typography variant="body2" sx={{ pr: 0.5 }}>
        {tagValue[0].label}
        {tagValue.length > 1 && ` + ${tagValue.length - 1}`}
      </Typography>
    );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Wrapper>
      <Autocomplete
        multiple
        loading={loading}
        size="small"
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={optionsOrder}
        value={selectedValues}
        disableCloseOnSelect
        onChange={(_, val) => handleValueChange(val)}
        onOpen={() => popoverDisableOnClose()}
        onClose={handlePopoverClose}
        isOptionEqualToValue={(option, val) => option.value === val.value && option.label === val.label}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
      />
    </Wrapper>
  );
}
