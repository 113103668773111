import { NoResults, TableWrapper } from '@components';
import { Box, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useReportContext } from './ReportProvider';
import { ActivityReportExportModel, ReportField } from '@api';

export function ReportTable() {
  const { selectedColumns, reportPreview, hasFilters, isLoading } = useReportContext();

  if (reportPreview.length === 0 && hasFilters) {
    return <NoResults>No activity found</NoResults>;
  }

  if (selectedColumns.length === 0) {
    return <NoResults>No columns selected</NoResults>;
  }

  return (
    <TableWrapper loading={isLoading}>
      <MuiTable size="small">
        <THead />
        <TableBody>
          {reportPreview.map((report, index) => (
            <TRow key={index} report={report} />
          ))}
        </TableBody>
      </MuiTable>
    </TableWrapper>
  );
}

function THead() {
  const { selectedColumns } = useReportContext();

  return (
    <TableHead>
      <TableRow>
        {selectedColumns.map((column) => (
          <TableCell key={column} component="th">
            {column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TRow({ report: report }: { report: ActivityReportExportModel }) {
  const { selectedColumns } = useReportContext();

  const Cell = ({ column }: { column: ReportField }) => {
    let data = report[column as keyof typeof report];
    if (Array.isArray(data)) {
      data = data.join(',');
    }

    return (
      <TableCell component="td" sx={{ maxWidth: '220px' }}>
        <Box>
          <Typography variant="inputLabel" component="div" noWrap>
            {data}
          </Typography>
        </Box>
      </TableCell>
    );
  };

  return (
    <TableRow>
      {selectedColumns.map((column) => (
        <Cell key={column} column={column} />
      ))}
    </TableRow>
  );
}
