import { Children, PropsWithChildren, ReactNode } from 'react';
import { Box, styled } from '@mui/material';

const SectionWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5, 1.5),
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const SectionLineWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(2.5),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  transition: 'all 0.3s ease-in-out',

  '&:before': {
    content: '""',
    width: theme.spacing(2),
    height: '2px',
    borderBottom: `1px dashed ${theme.palette.border.default}`,
    position: 'absolute',
    top: '50%',
    left: theme.spacing(0.5),
    transform: 'translateY(-50%)',
    display: 'block',
  },

  '&:after': {
    content: '""',
    width: '2px',
    borderLeft: `1px dashed ${theme.palette.border.default}`,
    position: 'absolute',
    top: 0,
    left: theme.spacing(0.5),
    bottom: 0,
    display: 'block',
  },

  '&:first-of-type': {
    paddingTop: 0,

    '&:before': {
      top: '50%',
    },
  },

  '&:last-of-type': {
    paddingBottom: 0,

    '&:after': {
      bottom: '50%',
      top: '0',
    },
  },

  '&:only-child': {
    padding: 0,

    '&:before': {
      display: 'none',
    },

    '&:after': {
      display: 'none',
    },
  },
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '&:after': {
    content: '""',
    width: '2px',
    borderLeft: `1px dashed ${theme.palette.border.default}`,
    position: 'absolute',
    height: theme.spacing(0.5),
    left: theme.spacing(0.5),
    bottom: 0,
  },
}));

interface FlowFormSectionProps {
  operator?: ReactNode;
  returnDefault?: boolean;
  actions?: ReactNode;
}

function FlowFormSection({ children, operator, returnDefault, actions }: PropsWithChildren<FlowFormSectionProps>) {
  if (returnDefault) return <>{children}</>;

  const childrenArray = Children.toArray(children);

  return (
    <SectionWrapper>
      {operator && (
        <SectionHeader>
          {operator}
          {actions}
        </SectionHeader>
      )}
      {childrenArray.map((child, index) => (
        <SectionLineWrapper key={index}>{child}</SectionLineWrapper>
      ))}
    </SectionWrapper>
  );
}

export default FlowFormSection;
