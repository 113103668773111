import { FrontierService } from '@api';
import { useQuery } from '@tanstack/react-query';
import { graphql } from '@api/gql';
import { QueryKeys } from './query-keys';

export const integrationsStatsFields = graphql(/* GraphQL */ `
  fragment integrationsStatsFields on IntegrationsStats {
    __typename
    connected
    inAccessFlows
    requested
  }
`);

export const integrationsStatsQueryDocument = graphql(/* GraphQL */ `
  query IntegrationsStats {
    integrationsStats {
      ...integrationsStatsFields
    }
  }
`);

export default function useIntegrationsStats() {
  return useQuery([QueryKeys.IntegrationsStats], () => FrontierService.request(integrationsStatsQueryDocument));
}
