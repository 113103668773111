import { getFragmentData } from '@api/gql';
import { Loader } from '@components';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';

import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import YourEnvironmentsChart from '@DashboardV2/components/YourEnvironmentsChart';
import useIntegrationsStats, { integrationsStatsFields } from '@DashboardV2/services/use-integrations-stats';
import { RouterLink } from '@libs';
import { Button } from '@mui/material';
import { routes } from '@routes';
export default function YourEnvironments() {
  return (
    <WidgetCard
      title="Integrations"
      subtitle="Current state"
      button={
        <Button variant="text" color="primary" size="small" component={RouterLink} to={routes.Catalog.path}>
          Connect Integration
        </Button>
      }
    >
      <YourEnvironmentsBody />
    </WidgetCard>
  );
}

function YourEnvironmentsBody() {
  const { data, isFetched } = useIntegrationsStats();
  const activeAccessStats = getFragmentData(integrationsStatsFields, data?.integrationsStats);

  if (!isFetched) {
    return <Loader />;
  }

  if (!activeAccessStats || activeAccessStats.connected === 0) {
    return (
      <PieChartEmptyState
        title="No environments connected yet"
        subtitle="Start by connecting new environment to see data"
        action={
          <Button variant="contained" color="primary" size="small" component={RouterLink} to={routes.Catalog.path}>
            Connect Integration
          </Button>
        }
        animationSrc="/static/EmptyStateImages/no-data-horizontal-bar-chat.svg"
      />
    );
  }

  return <YourEnvironmentsChart data={activeAccessStats} />;
}
