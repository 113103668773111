import { AccessGraphAttributeConditionAndNode } from '@api/gql/graphql';
import { RESOURCE_NODE_WIDTH, RESOURCE_NODES_GAP } from './common';
import { ExpandButton } from '@components';
import { Box, Stack, styled, Tooltip } from '@mui/material';
import { LabelWrap, ResourceNodeTypography } from './node';
import { Handle, NodeProps, Position } from 'reactflow';
import { AttributeConditionNodeTotal } from './AccessGraphAttributeConditionNodeTotal';
import { AVAILABLE_ACCESS_COLOR } from './node_and_edge_types';

const GroupedNodeWrapper = styled(Box)(({ theme }) => ({
  cursor: 'default',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
  padding: `12px`,
  width: RESOURCE_NODE_WIDTH,
  alignItems: 'start',

  '& .collapse-btn': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: RESOURCE_NODE_WIDTH,
    color: theme.palette.text.dropdown,
  },
}));

type AttributeConditionAndNodeProps = {
  node: AccessGraphAttributeConditionAndNode;
  childrenHeight: number;
  hasConnectedEdges: boolean;
};

export function AttributeConditionAndNodeExpanded({ data }: NodeProps<AttributeConditionAndNodeProps>) {
  const { childrenHeight, node, hasConnectedEdges } = data;
  return (
    <GroupedNodeWrapper>
      {hasConnectedEdges && (
        <Handle
          type="source"
          position={Position.Right}
          id={node.id}
          style={{ backgroundColor: AVAILABLE_ACCESS_COLOR, borderColor: AVAILABLE_ACCESS_COLOR }}
        />
      )}
      <Stack direction="column" alignItems="center" spacing={`${RESOURCE_NODES_GAP}px`}>
        <AttributeConditionAndNodeMeta node={node} />
        {childrenHeight > 0 ? <Box sx={{ height: childrenHeight }}></Box> : null}
        <ExpandButton less />
      </Stack>
    </GroupedNodeWrapper>
  );
}

export function AttributeConditionAndNode({ data }: NodeProps<AttributeConditionAndNodeProps>) {
  const { node } = data;

  return (
    <GroupedNodeWrapper>
      <Handle
        type="source"
        position={Position.Right}
        id={node.id}
        style={{ backgroundColor: AVAILABLE_ACCESS_COLOR, borderColor: AVAILABLE_ACCESS_COLOR }}
      />
      <AttributeConditionAndNodeMeta node={node} allowExpand />
    </GroupedNodeWrapper>
  );
}

function AttributeConditionAndNodeMeta({
  node,
  allowExpand,
}: {
  node: AccessGraphAttributeConditionAndNode;
  allowExpand?: boolean;
}) {
  const total = node?.totalChildren || 0;
  const showExpand = total > 0 && allowExpand;

  let label = '';
  if (
    node?.attributeConditionAnd?.length === 1 &&
    node.attributeConditionAnd[0].operator === 'is' &&
    node.attributeConditionAnd[0].value.length === 1
  ) {
    label = node?.attributeConditionAnd[0]?.value[0];
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Stack direction="column" width="100%">
        {label.length > 0 ? (
          <Tooltip title={label}>
            <LabelWrap
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis !important',
                lineHeight: '20px',
              }}
            >
              <ResourceNodeTypography
                variant="caption"
                component="span"
                color="text.primary"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {label}
              </ResourceNodeTypography>
            </LabelWrap>
          </Tooltip>
        ) : (
          node?.attributeConditionAnd?.map((condition, index) => {
            const tooltipLabel = `${condition.attributeType.name} ${condition.operator} ${
              Array.isArray(condition.value) ? condition.value.join(' or ') : condition.value
            }`;
            const additionalValues = condition.value.length > 2 ? condition.value.length - 2 : undefined;
            return (
              <Tooltip title={tooltipLabel} key={index}>
                <Box
                  sx={{
                    maxWidth: '230px',
                  }}
                >
                  <LabelWrap
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis !important',
                      lineHeight: '20px',
                    }}
                  >
                    {index > 0 && (
                      <>
                        <ResourceNodeTypography
                          variant="caption"
                          component="span"
                          color="text.muted"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          AND
                        </ResourceNodeTypography>{' '}
                      </>
                    )}
                    <ResourceNodeTypography
                      variant="caption"
                      component="span"
                      color="text.primary"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {condition.attributeType.name}
                    </ResourceNodeTypography>{' '}
                    <ResourceNodeTypography
                      variant="caption"
                      component="span"
                      color="text.muted"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {condition.operator}
                    </ResourceNodeTypography>{' '}
                    {condition.value.length > 0 && (
                      <>
                        <ResourceNodeTypography
                          variant="caption"
                          component="span"
                          color="text.primary"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {condition.value[0]}
                        </ResourceNodeTypography>{' '}
                      </>
                    )}
                    {condition.value.length > 1 && (
                      <>
                        <ResourceNodeTypography
                          variant="caption"
                          component="span"
                          color="text.muted"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          or
                        </ResourceNodeTypography>{' '}
                        <ResourceNodeTypography
                          variant="caption"
                          component="span"
                          color="text.primary"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {condition.value[1]}
                        </ResourceNodeTypography>{' '}
                      </>
                    )}
                    {additionalValues && (
                      <ResourceNodeTypography
                        variant="caption"
                        component="span"
                        color="text.muted"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        +({additionalValues})
                      </ResourceNodeTypography>
                    )}
                  </LabelWrap>
                </Box>
              </Tooltip>
            );
          })
        )}
        <AttributeConditionNodeTotal total={total} />
      </Stack>
      {showExpand && <ExpandButton />}
    </Stack>
  );
}

export function buildAndNodeLabel(node: AccessGraphAttributeConditionAndNode) {
  return node?.attributeConditionAnd
    ?.map((condition) => {
      return `${condition.attributeType.name} ${condition.operator} ${
        Array.isArray(condition.value) ? condition.value.join(' or ') : condition.value
      }`;
    })
    .join(' AND ');
}
