import { useState } from 'react';

import {
  InteractiveDropdown,
  InteractiveDropdownHeader,
  InteractiveDropdownTimeframeSelect,
  InteractiveDropdownView,
  Popover,
} from '@components';
import { yup } from '@libs';
import { Timeframe } from '@api';
import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';
import { HumanReadableTimeframe } from './HumanReadable';
import { useErrorText } from './common/use-error-text.hook';
import { FlowFormText } from './common/Text';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export interface FlowFormSelectTimeframeProps {
  value?: Timeframe;
  onChange: (value?: Timeframe) => void;
  error?: yup.ValidationError;
}

function useFlowFormSelectTimeframe({ value, onChange }: FlowFormSelectTimeframeProps) {
  const [timeframe, setTimeframe] = useState<Timeframe | undefined>(value);
  const { track } = useAnalyticsContext();
  const handleTimeframeChange = (timeFrameValue?: Timeframe) => {
    setTimeframe(timeFrameValue);
    onChange(timeFrameValue);
    track(CustomAnalyticsEvents.SELECT_TIMEFRAME, {
      timeFrameValue,
    });
  };

  return {
    timeframe,
    handleTimeframeChange,
  };
}

export function FlowFormSelectTimeframe({ value, onChange, error }: FlowFormSelectTimeframeProps) {
  const { timeframe, handleTimeframeChange } = useFlowFormSelectTimeframe({
    value,
    onChange,
  });
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();
  const { isEnabled: isOn } = useFlagFixed(Flag.ACCESS_FLOW_TIMEFRAME);

  if (!isOn) {
    return <FlowFormText>When</FlowFormText>;
  }

  return (
    <Popover
      testId="flow-form-select-timeframe"
      trigger={<HumanReadableTimeframe value={value} errorText={error?.message} hideErrorText={errorTextHidden} />}
      onOpen={hideErrorText}
      onClose={showErrorText}
    >
      <InteractiveDropdown wide>
        <InteractiveDropdownView
          current
          header={<InteractiveDropdownHeader title="The days and hours in which the Access Flow is enabled" />}
        >
          <InteractiveDropdownTimeframeSelect value={timeframe} onChange={handleTimeframeChange} />
        </InteractiveDropdownView>
      </InteractiveDropdown>
    </Popover>
  );
}
