import { Autocomplete, Chip, styled } from '@mui/material';
import { LabeledTextField, LabeledTextFieldProps } from '@components/LabeledTextField';

const StyledChip = styled(Chip)(({ theme }) => ({
  lineHeight: 1,
  height: '20px',
  margin: `${theme.spacing(0, 0.5, 0, 0)} !important`,
}));

export interface LabeledMultiSelectFieldLabelProps {
  options: string[];
  values?: string[];
  onChange: (value?: string[]) => void;
}

export type LabeledMultiSelectFieldProps = LabeledTextFieldProps & LabeledMultiSelectFieldLabelProps;

export function LabeledMultiSelectField({
  options,
  values,
  onChange,
  required,
  ...inputProps
}: LabeledMultiSelectFieldProps) {
  return (
    <Autocomplete
      disablePortal
      options={options}
      value={values}
      multiple
      fullWidth
      size="small"
      renderInput={(params) => (
        <LabeledTextField {...inputProps} {...params} required={!values || values.length === 0} />
      )}
      filterSelectedOptions
      renderTags={(strings: string[], getTagProps) =>
        strings.map((option: string, index: number) => (
          // disabling because key in getTagProps
          // eslint-disable-next-line react/jsx-key
          <StyledChip variant="filled" size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      onChange={(_, val) => onChange(val ?? undefined)}
    />
  );
}
