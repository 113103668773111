import { Page } from '@components';
import { routes } from '@routes';
import { Settings } from '@organisms';

export function SettingsPage() {
  return (
    <Page title={routes.Settings.label}>
      <Settings />
    </Page>
  );
}
