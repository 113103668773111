import { ApproverModel, ApproverTypeModel, useListAvailableAttributesV2 } from '@api';
import { useGroupsFromState, useUsersFromState } from '@hooks';

import { HumanReadableText, HumanReadableTextProps } from '../common/HumanReadableText';

function useHumanReadableApprover(value: ApproverModel[]) {
  const { groups } = useGroupsFromState();
  const { users } = useUsersFromState();
  const { contextsAttributes } = useListAvailableAttributesV2();

  const txtApprovers: string[] = [];
  value.forEach((approver) => {
    switch (approver.type) {
      case ApproverTypeModel.Group: {
        const group = groups.find((g) => g.id === approver.id);
        txtApprovers.push(group?.name || '');
        break;
      }
      case ApproverTypeModel.User: {
        const user = users?.find((u) => u.id === approver.id);
        txtApprovers.push(user ? `${user.first_name} ${user.last_name}` : '');
        break;
      }
      case ApproverTypeModel.ContextAttribute: {
        const contextAttribute = contextsAttributes?.find((oc) => oc.id === approver.id);
        txtApprovers.push(contextAttribute?.value || '');
        break;
      }
    }
  });

  return txtApprovers;
}

export interface HumanReadableApproverProps extends Omit<HumanReadableTextProps, 'value' | 'values'> {
  value: ApproverModel[];
}

export function HumanReadableApprover(props: HumanReadableApproverProps) {
  const { value, ...rest } = props;
  const approvers = useHumanReadableApprover(value);
  return <HumanReadableText values={approvers} {...rest} />;
}
