import PrimarySecondary from '@common/ui/PrimarySecondary';
import { MaterialIcon } from '@components';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export function SeverityCell({ severity }: { severity: number }) {
  switch (severity) {
    case 10:
      return <Severity text="Low" icon={<MaterialIcon symbol="error" color="inherit" weight={700} />} />;
    case 20:
      return <Severity text="Medium" icon={<MaterialIcon symbol="warning" color="warning" weight={700} />} />;
    case 30:
      return <Severity text="High" icon={<MaterialIcon symbol="warning-error" weight={700} color="error" />} />;
  }
}

function Severity({ text, icon }: { text: string; icon: ReactNode }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {icon}
      <div>
        <PrimarySecondary primary={text} />
      </div>
    </Stack>
  );
}
