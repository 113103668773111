import { Controller, useFormContext } from 'react-hook-form';

import { WebhookMethodAppModel, WebhookUpdateAppModel } from '@api';
import { LabeledSelectField } from '@components';

export default function MethodField() {
  const { control } = useFormContext<WebhookUpdateAppModel>();

  const webhookMethods = Object.values(WebhookMethodAppModel).map((m) => ({ key: m, value: m }));

  return (
    <Controller
      control={control}
      name="method"
      rules={{
        required: 'Method is required',
      }}
      render={({ field: { onChange, value, ...restField }, fieldState: { invalid, error } }) => (
        <LabeledSelectField
          label="Method"
          options={webhookMethods}
          value={value}
          onChange={onChange}
          error={invalid}
          helperText={error?.message}
          inputProps={restField}
        />
      )}
    />
  );
}
