import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useLoadingProgress(statuses: boolean[]) {
  const totalFetchedItems = statuses.length;
  const progressPart = Math.ceil(100 / totalFetchedItems);
  const completedProgressCount = statuses.filter((fetched) => fetched).length;
  return Math.min(completedProgressCount * progressPart, 100);
}

export function useScrollToTop() {
  const { search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);
}
