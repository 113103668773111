import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Divider, Stack } from '@mui/material';

import { IntegrationConfigFormPayload } from '@Integrations/organisms/IntegrationConfigForm/Provider';
import AttributeGroupSelect, { AttributeGroupSelectValue } from '@common/organisms/AttributeGroupSelect';
import FormSectionHeader from '@Integrations/IntegrationConfigForm/components/FormSection/FormSectionHeader';
import { LabeledTextField, MaterialIcon } from '@components';
import { CustomAnalyticsEvents, useAnalyticsContext } from '@utils/analytics';

export function ResourceOwnerFormSection({ divider = true, disabled }: { divider?: boolean; disabled?: boolean }) {
  const { track } = useAnalyticsContext();
  const { setValue, watch } = useFormContext<IntegrationConfigFormPayload>();

  const resourceOwners = watch('resource_owners_mappings');

  const firstOwner = resourceOwners?.[0];

  const tagName = firstOwner?.tag_name || '';

  const selectedAttribute = useMemo(() => {
    if (!firstOwner) return;

    return {
      integrationId: firstOwner.attribute_integration_id,
      attributeType: firstOwner.attribute_type,
    };
  }, [firstOwner]);

  const handleAttributeChange = (newVal: AttributeGroupSelectValue) => {
    setValue('resource_owners_mappings', [
      {
        attribute_integration_id: newVal.integrationId,
        attribute_type: newVal.attributeType,
        tag_name: firstOwner?.tag_name || '',
      },
    ]);
    track(CustomAnalyticsEvents.OWNER_RESOURCE_ATTRIBUTE_SELECTED);
  };

  const handleTagNameChange = (newVal: string) => {
    setValue('resource_owners_mappings', [
      {
        attribute_integration_id: firstOwner?.attribute_integration_id,
        attribute_type: firstOwner?.attribute_type || '',
        tag_name: newVal,
      },
    ]);
    track(CustomAnalyticsEvents.OWNER_RESOURCE_ATTRIBUTE_VALUE_SELECTED);
  };

  const isOptional = useMemo(() => {
    if (!firstOwner) return true;
    return !firstOwner.tag_name && !firstOwner.attribute_type;
  }, [firstOwner]);

  return (
    <Stack spacing={2}>
      <FormSectionHeader
        optional
        title="Resource Owner"
        titleIcon={<MaterialIcon symbol="person_add" />}
        tooltip="Apono will sync each resource's owner from the source integration. Use this for Resource Owner access requests approval. Enter the tag key that contains owners info, and map it to an attribute in Apono."
      />
      <Box>
        <Stack direction="row" spacing={2} justifyContent="center">
          <LabeledTextField
            label="Key name"
            placeholder="Eg. Owner"
            optional={isOptional}
            value={tagName}
            onChange={(e) => handleTagNameChange(e.target.value)}
            data-testid="resource-owner-key-name"
            disabled={disabled}
          />
          <AttributeGroupSelect
            value={selectedAttribute}
            onChange={handleAttributeChange}
            preload={!!selectedAttribute}
            optional={isOptional}
            isViewOnly={disabled}
          />
        </Stack>
      </Box>
      {divider && <Divider />}
    </Stack>
  );
}
