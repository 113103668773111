import { styled } from '@mui/material';
import { aponoColors } from '@utils/colors';
import { ReactNode } from 'react';

const StyledSpan = styled('span')(({ theme }) => ({
  '&.md-alert': {
    display: 'block',
    backgroundColor: aponoColors.warning[900],
    color: aponoColors.warning[500],
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderRadius: theme.spacing(1),
    fontSize: theme.typography.fontSize,
  },
}));

export function Span(props: { className?: string; children: ReactNode }) {
  return <StyledSpan className={props.className}>{props.children}</StyledSpan>;
}
