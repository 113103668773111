import { Box, styled } from '@mui/material';
import { ReactNode, PropsWithChildren } from 'react';

const AccessFlowItemContainerWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
}));

const AccessFlowItemContainerHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

const AccessFlowItemContainerTargets = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
}));

interface AccessFlowItemContainerProps {
  targets?: ReactNode;
}

export default function AccessFlowItemContainer({
  targets,
  children,
}: PropsWithChildren<AccessFlowItemContainerProps>) {
  return (
    <AccessFlowItemContainerWrapper>
      <AccessFlowItemContainerHeader>{children}</AccessFlowItemContainerHeader>
      <AccessFlowItemContainerTargets>{targets}</AccessFlowItemContainerTargets>
    </AccessFlowItemContainerWrapper>
  );
}
