import { atom } from 'recoil';

export type NotificationSeverity = 'success' | 'info' | 'warning' | 'error';

export interface NotificationMessage {
  text: string;
  severity: NotificationSeverity;
}

export const globalNotificationsState = atom<NotificationMessage | null>({
  key: 'globalNotificationsState',
  default: null,
});

export const installationProgressNotificationState = atom<string | null>({
  key: 'installationNotificationState',
  default: null,
});
