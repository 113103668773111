import { Loader, ProButton } from '@components';
import { routes } from '@routes';
import { useListReportTemplates } from '@api';
import { Stack } from '@mui/material';
import { ReportListItem } from '@organisms';
import { Link } from 'react-router-dom';
import { Flag } from '@utils';

export function ReportsPage() {
  const { reportTemplates, isReportTemplatesFetched } = useListReportTemplates();

  if (!isReportTemplatesFetched) {
    return <Loader />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <ProButton
          flag={Flag.PREMIUM_CREATE_REPORT_BUTTON}
          variant="contained"
          size="small"
          component={Link}
          to={`${routes.AddReport.path}?period=30-days`}
        >
          Create Report
        </ProButton>
      </Stack>
      {reportTemplates.map((report) => (
        <ReportListItem key={report.id} report={report} />
      ))}
    </Stack>
  );
}
