import { useMemo } from 'react';
import {
  AccessFlowAreaModel,
  AttributeModel,
  GranteeModel,
  GranteeTypeModel,
  useListAvailableAttributesV2,
  useListIntegrationsV2,
} from '@api';
import { InteractiveDropdownSelectOption } from '@components';

export type GroupedAttributes = Record<string, AttributeModel[]>;

export function toContextKey(curr: AttributeModel) {
  return JSON.stringify([curr.integration_id, curr.category]);
}

export function fromContextKey(key: string) {
  try {
    const [integrationId, category] = JSON.parse(key);

    return {
      integrationId,
      category,
    } as {
      integrationId?: string;
      category: string;
    };
  } catch (error) {
    return {
      integrationId: '',
      category: '',
    };
  }
}

export function useAttributeOptions({
  flowArea,
  values,
  lookMultiple = true,
  labelPrefix,
}: {
  flowArea: AccessFlowAreaModel;
  values?: GranteeModel[];
  lookMultiple?: boolean;
  labelPrefix?: string;
}) {
  const { integrations, configs, isIntegrationsFetched } = useListIntegrationsV2();
  const { contextsAttributes, isContextsAttributesFetched } = useListAvailableAttributesV2();

  const attributeOptions = useMemo(() => {
    if (!contextsAttributes || contextsAttributes.length === 0) return [];

    const options: InteractiveDropdownSelectOption<string>[] = [];

    const groupedAttributes: GroupedAttributes = contextsAttributes.reduce((acc, curr) => {
      if (!curr.applicable_places.includes(flowArea)) return acc;

      const key = toContextKey(curr);

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(curr);

      return acc;
    }, {} as GroupedAttributes);

    Object.entries(groupedAttributes).forEach(([categoryKey, attrs]) => {
      if (attrs.length === 0) return;

      const { integrationId, category } = fromContextKey(categoryKey);

      let iconSrc: string | undefined;
      let secondary: string | undefined;
      const integration = integrations.find((int) => int.id === integrationId);
      if (integration) {
        secondary = integration.name;
        const config = configs.find((conf) => conf.type === integration.type);
        if (config) {
          iconSrc = config.icons.svg;
        }
      }

      let counted = 0;
      values?.forEach((val) => {
        if (val.type === GranteeTypeModel.ContextAttribute && attrs.find((attr) => attr.id === val.id)) {
          counted += 1;
        }
      });

      let label = category;
      if (counted > 0 && !labelPrefix) {
        label = `${category}/${counted}`;
      }

      if (labelPrefix) {
        label = `${labelPrefix} ${label}`;
      }

      options.push({
        key: `${GranteeTypeModel.ContextAttribute}/${categoryKey}`,
        iconSrc,
        value: label,
        secondary,
        hasNextView: true,
        lookMultiple,
        lookSelected: counted > 0,
      });
    });

    return options;
  }, [configs, contextsAttributes, flowArea, integrations, labelPrefix, lookMultiple, values]);

  return {
    attributeOptions,
    isAttributesFetched: isContextsAttributesFetched && isIntegrationsFetched,
  };
}
