import { NewRequestsStats } from '@api/gql/graphql';
import { MetricsBoxBody } from '@common/ui/MetricBox';

export function NewRequestsWidget({ data }: { data: NewRequestsStats }) {
  const mainValue = data.current;
  const changeValue = data.previous != undefined ? data.current - data?.previous : undefined;
  const changePercentage =
    changeValue && data.previous != undefined ? Number(((changeValue / data?.previous) * 100).toFixed(1)) : 0;

  return (
    <MetricsBoxBody
      mainValue={mainValue.toString()}
      changeValue={changeValue}
      changePercentage={changePercentage}
      timePeriod="Previous period"
      isPositiveTrendError={false}
    />
  );
}
