import { Button, Stack } from '@mui/material';

interface AppDrawerFooterProps {
  disabled?: boolean;
  onSubmit?: () => void;
  onSubmitLabel?: string;
  onClear?: () => void;
  onClearLabel?: string;
}

export function AppDrawerFooter({ onSubmit, onSubmitLabel, onClear, onClearLabel, disabled }: AppDrawerFooterProps) {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      {onSubmit && (
        <Button variant="contained" onClick={onSubmit} disabled={disabled}>
          {onSubmitLabel || 'Done'}
        </Button>
      )}
      {onClear && (
        <Button type="button" onClick={onClear} disabled={disabled}>
          {onClearLabel || 'Clear All'}
        </Button>
      )}
    </Stack>
  );
}
