import { Outlet } from 'react-router-dom';
import { Page } from '@components';

export default function BundlesLayout() {
  return (
    <Page title="Bundles">
      <Outlet />
    </Page>
  );
}
