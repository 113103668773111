/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList as VirtualList } from 'react-window';

import { ResourceAppModel, ResourceStatusV1, ResourceTypeConfigAppModel } from '@api';

import { TreeNode, TreeNodeItem } from './ResourceTreeNode';
import { Box, Stack } from '@mui/material';
import TreeFilterBar from './TreeFilterBar';

type ResourcesTreeNodeItem = {
  id: string;
  pathNode?: TreeNodeItem;
  resourceNode?: TreeNodeItem;
  resource?: ResourceAppModel;
};

type FlattenedResourcesTreeNode = {
  item: ResourcesTreeNodeItem;
  level: number;
};

export interface ResourcesTreeProps {
  resourceType: string;
  resourceTypeConfigs: { [key: string]: ResourceTypeConfigAppModel };
  resources: ResourceAppModel[];
  value: ResourceAppModel[];
  onChange: (value: ResourceAppModel[]) => void;
}

function isSubArray(mainArray: string[][], subArray: string[][]): boolean {
  return subArray.every((subElem) =>
    mainArray.some((mainElem) => JSON.stringify(mainElem) === JSON.stringify(subElem)),
  );
}

export const ResourcesTree = memo(function ResourcesTree({
  resourceType,
  resourceTypeConfigs,
  value,
  resources,
  onChange,
}: ResourcesTreeProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);
  const [filter, setFilter] = useState<string>('');
  const [showSelected, setShowSelected] = useState<boolean>(false);

  const handleShowSelectedChange = useCallback(() => {
    setShowSelected((prev) => !prev);
    setFilter('');
  }, []);

  const selectedIds = useMemo(() => {
    const ids: Record<string, number> = {};
    value.forEach((v, i) => {
      ids[v.id] = i;
    });
    return ids;
  }, [value]);

  const nodeHierarchy = useMemo(() => {
    const typeConfig = resourceTypeConfigs[resourceType];
    const filterQuery = filter?.trim().toLowerCase();
    const typeHierarchy = typeConfig.hierarchy.split('/').slice(0, -1);

    const grouped = new Map<string, FlattenedResourcesTreeNode[]>();
    const pathCounts = new Map<string, { total: number; selected: number }>();

    for (const resource of resources) {
      if (filterQuery && !resource.name.toLowerCase().includes(filterQuery)) {
        continue;
      }

      const selected = selectedIds[resource.id] !== undefined;

      if (showSelected && !selected) {
        continue;
      }

      const hierarchy = [];
      for (const part of typeHierarchy) {
        if (resource.path[part]) hierarchy.push([part, resource.path[part]]);

        const pathKey = JSON.stringify(hierarchy);
        const pathCount = pathCounts.get(pathKey);
        if (!pathCount) {
          pathCounts.set(pathKey, { total: 1, selected: selected ? 1 : 0 });
        } else {
          pathCount.total++;
          if (selected) pathCount.selected++;
        }
      }
      const hierarchyKey = JSON.stringify(hierarchy);

      let inHierarchy = grouped.get(hierarchyKey);
      if (!inHierarchy) {
        inHierarchy = [];
      }

      inHierarchy.push({
        level: hierarchy.length,
        item: {
          id: resource.id,
          resourceNode: {
            id: resource.id,
            name: resource.name,
            logicalPath: typeConfig.display_name,
            selected,
            errorMessage: resource.status?.status === ResourceStatusV1.Error ? resource.status.message : undefined,
          },
          resource,
        },
      });

      grouped.set(hierarchyKey, inHierarchy);
    }

    return { grouped, pathCounts };
  }, [filter, resourceType, resourceTypeConfigs, resources, selectedIds, showSelected]);

  const nodeList: FlattenedResourcesTreeNode[] = useMemo(() => {
    const usedTypesIds = new Set<string>();
    const result: FlattenedResourcesTreeNode[] = [];
    nodeHierarchy.grouped.forEach((v, key) => {
      const hierarchy: [string, string][] = JSON.parse(key);

      const pathIdParts: [string, string][] = [];
      hierarchy.map(([hType, hTypeName], i) => {
        const hResourceTypeConfig = resourceTypeConfigs[hType];

        pathIdParts.push([hType, hTypeName]);
        const pathId = JSON.stringify(pathIdParts);
        if (!hResourceTypeConfig || usedTypesIds.has(pathId)) {
          return;
        }

        const pathCounts = nodeHierarchy.pathCounts.get(pathId);

        usedTypesIds.add(pathId);

        result.push({
          level: i,
          item: {
            id: pathId,
            pathNode: {
              id: pathId,
              name: hTypeName,
              icon: i === 0 ? hResourceTypeConfig.icons.svg : undefined,
              logicalPath: hResourceTypeConfig.display_name,
              selected: pathCounts?.total === pathCounts?.selected,
            },
          },
        });
      });

      result.push(...v);
    });
    return result;
  }, [nodeHierarchy, resourceTypeConfigs]);

  const filteredCount = useMemo(() => {
    let total = 0;
    nodeHierarchy.grouped.forEach((list) => {
      total += list.length;
    });
    return total;
  }, [nodeHierarchy]);

  const onPathSelect = useCallback(
    (node: TreeNodeItem) => {
      const newValue = [...value];
      const indexesToRemove: number[] = [];

      let addedNew = false;

      const pathKeyParts = JSON.parse(node.id);
      Array.from(nodeHierarchy.grouped.keys()).forEach((k) => {
        const parts = JSON.parse(k);
        if (!isSubArray(parts, pathKeyParts)) return;

        const nodesToSelect = nodeHierarchy.grouped.get(k) || [];
        for (const nodeToSelect of nodesToSelect) {
          if (selectedIds[nodeToSelect.item.id] === undefined) {
            newValue.push(nodeToSelect.item.resource!);
            addedNew = true;
          } else {
            indexesToRemove.push(selectedIds[nodeToSelect.item.id]);
          }
        }
      });

      onChange(addedNew ? newValue : value.filter((_, index) => !indexesToRemove.includes(index)));
    },
    [nodeHierarchy, onChange, selectedIds, value],
  );

  const onResourceSelect = useCallback(
    (node: TreeNodeItem, selectedResource: ResourceAppModel) => {
      const newValue = [...value];
      if (selectedIds[node.id] === undefined) {
        newValue.push(selectedResource);
      } else {
        newValue.splice(selectedIds[node.id], 1);
      }

      onChange(newValue);
    },
    [onChange, selectedIds, value],
  );

  const onToggleSelectAll = useCallback(() => {
    const newValue = [...value];
    const indexesToRemove: number[] = [];
    let changesMade = false;

    Array.from(nodeHierarchy.grouped.keys()).forEach((k) => {
      const nodesToSelect = nodeHierarchy.grouped.get(k) || [];
      for (const nodeToSelect of nodesToSelect) {
        const isSelected = selectedIds[nodeToSelect.item.id] !== undefined;

        if (!isSelected) {
          newValue.push(nodeToSelect.item.resource!);
          changesMade = true;
        } else {
          indexesToRemove.push(selectedIds[nodeToSelect.item.id]);
        }
      }
    });

    onChange(changesMade ? newValue : value.filter((_, index) => !indexesToRemove.includes(index)));
  }, [nodeHierarchy, onChange, selectedIds, value]);

  const renderOption = useCallback(
    (node: FlattenedResourcesTreeNode, style?: CSSProperties) => {
      return (
        <div key={node.item.id} style={style}>
          {node.item.pathNode && (
            <TreeNode item={node.item.pathNode} level={node.level} onSelect={() => onPathSelect(node.item.pathNode!)} />
          )}
          {node.item.resourceNode && node.item.resource && (
            <TreeNode
              item={node.item.resourceNode}
              level={node.level}
              onSelect={() => onResourceSelect(node.item.resourceNode!, node.item.resource!)}
            />
          )}
        </div>
      );
    },
    [onPathSelect, onResourceSelect],
  );

  const itemSize = useCallback(
    (index: number) => {
      let nodeHeight = 54;
      const node = nodeList[index];
      const hasName = node.item.resourceNode?.name || node.item.pathNode?.name;
      const hasErrorMessage = node.item.resourceNode?.errorMessage || node.item.pathNode?.errorMessage;

      if (hasName) {
        nodeHeight = nodeHeight + 3;
      }

      if (hasErrorMessage) {
        nodeHeight = nodeHeight + 18;
      }

      return nodeHeight;
    },
    [nodeList],
  );

  useEffect(() => {
    if (!wrapperRef.current) return;

    setWrapperHeight(wrapperRef.current.offsetHeight || 0);
    const resizeObserver = new ResizeObserver(() => {
      setWrapperHeight(wrapperRef.current?.offsetHeight || 0);
    });
    resizeObserver.observe(wrapperRef.current);
  }, []);

  return (
    <Stack direction="column" justifyContent="flex-start" sx={{ height: '100%' }}>
      <TreeFilterBar
        filter={filter}
        onFilterChange={setFilter}
        filteredCount={filteredCount}
        totalCount={resources.length}
        selectedCount={value.length}
        showSelected={showSelected}
        onChangeShowSelected={handleShowSelectedChange}
        onSelectToggle={onToggleSelectAll}
      />
      <Box ref={wrapperRef} sx={{ flex: 1 }}>
        {wrapperHeight > 0 && (
          <VirtualList
            height={wrapperHeight}
            width="100%"
            itemSize={itemSize}
            itemCount={nodeList.length}
            overscanCount={20}
          >
            {({ index, style }) => renderOption(nodeList[index], style)}
          </VirtualList>
        )}
      </Box>
    </Stack>
  );
});
