import { CreateIntegrationAppModel, IntegrationAppModel, IntegrationConfigAppModelV2 } from '@api';
import { Alert } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const IntegrationConfigFormQP = {
  ConnectorId: 'connector',
  ResourceTypes: 'rType',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IntegrationConfigFormQP = (typeof IntegrationConfigFormQP)[keyof typeof IntegrationConfigFormQP];

export enum SecretStoreType {
  AWS = 'AWS',
  GCP = 'GCP',
  KUBERNETES = 'KUBERNETES',
  AZURE = 'AZURE',
  APONO = 'APONO',
  HASHICORP_VAULT = 'HASHICORP_VAULT',
}

export interface SecretConfig {
  type: SecretStoreType;
  secret_id?: string;
  region?: string;
  project?: string;
  namespace?: string;
  name?: string;
  vault_url?: string;
}

export interface IntegrationConfigFormProviderProps {
  config: IntegrationConfigAppModelV2;
  integration?: IntegrationAppModel;
  selectedResourceTypes?: string[];
  selectedConnectorId?: string;
}

export type ObjectValues = { [p: string]: ObjectValues | string | undefined };

export interface IntegrationConfigFormPayload extends CreateIntegrationAppModel {
  metadata: ObjectValues;
  secret_config?: ObjectValues;
}

export function IntegrationConfigFormProvider({
  config,
  integration,
  selectedResourceTypes,
  selectedConnectorId,
  children,
}: PropsWithChildren<IntegrationConfigFormProviderProps>) {
  const metadata = useMemo(() => {
    const metadataToEdit: ObjectValues = {};

    config.params.forEach((param) => {
      const integrationMetadata = integration ? (integration.metadata as ObjectValues) : undefined;
      if (integrationMetadata?.[param.id]) {
        metadataToEdit[param.id] = integrationMetadata[param.id];
        return;
      }

      if (param.default) {
        metadataToEdit[param.id] = param.default;
        return;
      }

      metadataToEdit[param.id] = '';
    });

    return metadataToEdit;
  }, [config, integration]);

  const secret_config = useMemo(() => {
    let secretConfigToEdit: ObjectValues = {
      type: config.supported_secret_types.length > 0 ? config.supported_secret_types[0] : SecretStoreType.AWS,
    };

    if (integration?.secret_config) {
      secretConfigToEdit = { ...(integration.secret_config as ObjectValues) };
    }

    return secretConfigToEdit;
  }, [integration, config.supported_secret_types]);

  const provisionerId = useMemo(
    () => integration?.provisioner_id ?? selectedConnectorId ?? undefined,
    [integration?.provisioner_id, selectedConnectorId],
  );

  const connectedResourceTypes = useMemo(() => {
    let resourceTypes: string[] = [];

    if (integration?.connected_resource_types) {
      resourceTypes = [...resourceTypes, ...integration.connected_resource_types];
    }

    if (selectedResourceTypes) {
      resourceTypes = [...resourceTypes, ...selectedResourceTypes];
    }

    return resourceTypes;
  }, [integration?.connected_resource_types, selectedResourceTypes]);

  const methods = useForm<IntegrationConfigFormPayload>({
    mode: 'onSubmit',
    values: {
      name: integration?.name ?? '',
      type: config.type,
      provisioner_id: provisionerId,
      connected_resource_types: connectedResourceTypes,
      metadata,
      secret_config,
      custom_access_details_settings: integration?.custom_access_details_settings,
    },
  });

  if (!provisionerId) {
    return <Alert severity="warning">Please select connector</Alert>;
  }

  return <FormProvider {...methods}>{children}</FormProvider>;
}
