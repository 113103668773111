import { AponoGroupModel, useListIntegrationsV2 } from '@api';
import { ActionsMenuV2, EmptyState, Loader } from '@components';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Avatar,
  Stack,
  Typography,
  Skeleton,
  Box,
} from '@mui/material';
import { GROUP_MODAL_QUERY_PARAM } from '@Groups/organisms/modals/ViewGroupModal';
import { useSearchParams } from 'react-router-dom';

export default function GroupsTable({
  groups,
  setGroupInEdit,
  setGroupInDeletion,
  setIsCreateModalOpen,
}: {
  groups?: AponoGroupModel[];
  setGroupInEdit: (group: AponoGroupModel) => void;
  setGroupInDeletion: (group: AponoGroupModel) => void;
  setIsCreateModalOpen: (value: boolean) => void;
}) {
  const { integrations, configs, isIntegrationsFetched } = useListIntegrationsV2();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnClick = (groupId: string) => {
    searchParams.set(GROUP_MODAL_QUERY_PARAM, groupId);
    setSearchParams(searchParams);
  };

  if (!integrations || !groups) return <Loader />;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Source</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group) => {
            const idp = integrations.find((integration) => integration.id === group.source_idp_id);
            const icon = configs?.find((config) => config.type === idp?.type)?.icons.svg || '/static/logo-only.svg';
            return (
              <TableRow key={group.id}>
                <TableCell>{group.name}</TableCell>
                <TableCell>
                  {isIntegrationsFetched ? (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar src={icon} alt={idp?.name || 'Apono'} variant="small" />
                      <Typography variant="body2" color="text.secondary">
                        {idp?.name || 'Apono Group'}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Skeleton variant="circular" width={24} height={24} />
                      <Skeleton variant="text" width={85} />
                    </Stack>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Button onClick={() => handleOnClick(group.id)} variant="outlined">
                      View Members
                    </Button>
                    {group.source_idp_type === idp?.type ? (
                      <ActionsMenuV2
                        options={[
                          {
                            label: 'Edit',
                            iconSymbol: 'edit',
                            onClick: () => setGroupInEdit(group),
                          },
                          {
                            label: 'Delete',
                            iconSymbol: 'delete',
                            onClick: () => setGroupInDeletion(group),
                          },
                        ]}
                      />
                    ) : (
                      <Box sx={{ width: 24, height: 24 }} />
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
          {groups.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <EmptyState
                  title="No groups found"
                  body="Create a group to manage access to your organization's resources."
                  action={
                    <Button variant="contained" onClick={() => setIsCreateModalOpen(true)}>
                      Create group
                    </Button>
                  }
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
