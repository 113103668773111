import { TopListWidget } from '@DashboardV2/components/TopListWidget/TopListWidget';
import { useIntegrationUsageStats } from '@DashboardV2/services/gql';
import { AVAILABLE_COLORS } from '@common/constants';
import { useMemo } from 'react';
import { Loader } from '@components';
import { PieChartEmptyState } from '@DashboardV2/components/PieChartEmptyState';
import { WidgetCard } from '@DashboardV2/components/WidgetCard';
import { IntegrationRowModel } from '@DashboardV2/components/TopListWidget/IntegrationRows';

export function Top10Environments() {
  return (
    <WidgetCard title="Top 10 Requested Integrations" subtitle="Last 30 days">
      <Top10Integrations />
    </WidgetCard>
  );
}

function Top10Integrations() {
  const { data, isFetched } = useIntegrationUsageStats({ take: 10 });

  const integrationsData: IntegrationRowModel[] = useMemo(() => {
    if (!data) return [];

    const totalRequests = data.requestsPerIntegrationStats.reduce((acc, curr) => acc + curr.totalRequests, 0);
    const result: IntegrationRowModel[] = [];

    data.requestsPerIntegrationStats.forEach((request, i) => {
      if (request.integration && request.totalRequests) {
        result.push({
          iconSrc: request.integration.icons.svg,
          id: `${request.integration.id}-${i}`,
          title: request.integration.name,
          subTitle: request.integration.typeDisplayName,
          color: AVAILABLE_COLORS[i % AVAILABLE_COLORS.length],
          sum: request.totalRequests,
          percent: Number(((request.totalRequests / totalRequests) * 100).toFixed(2)),
        });
      }
    });

    return result;
  }, [data]);

  if (!isFetched) return <Loader />;

  if (integrationsData.length === 0)
    return (
      <PieChartEmptyState
        title="No Requests Created for this period"
        subtitle="No access requests match the chosen time period."
        size={{ width: 100, height: 80 }}
        animationSrc={'/static/EmptyStateImages/no-data-big-pie-chart.svg'}
      />
    );

  return <TopListWidget integrationsData={integrationsData} />;
}
