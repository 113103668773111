import { useEffect, useMemo, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';

import { ListFilterOption } from '@components/ListFilters/common';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';

import { FilterProps } from '@common/organisms/CommonFilters/types';
import { useAuditEventsUsers } from '@AuditEvents/services/use-audit-events-filter-options';
import { AnalyticsTriggerElementProps } from '@common/types';

export const USER_FILTER_LABEL = 'User';
export const USER_FILTER_PARAM = 'user';

export default function AuditEventsUsersFilter({
  opened,
  onClose,
  onOpen,
  selected,
  onSelect,
  onClear,
  ...analyticsProps
}: FilterProps & AnalyticsTriggerElementProps) {
  const [enabled] = useState(() => selected.length > 0);

  const { data, isFetched, refetch } = useAuditEventsUsers(enabled);

  const options = useMemo(
    () =>
      data?.auditEventsUsers?.map((i) => {
        let label = i.email;
        if (i.firstName && i.lastName) {
          label = `${i.firstName} ${i.lastName} (${i.email})`;
        }

        return {
          value: i.email,
          label,
        };
      }) || [],
    [data],
  );

  const selectedOptions = useMemo(
    () => options?.filter((option) => selected.includes(option.value)) || [],
    [options, selected],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const onSelectHandler = (values: ListFilterOption[]) => {
    onSelect(values.map((value) => value.value));
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClose && onClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    !opened && onOpen && onOpen();
    setAnchorEl(event.currentTarget);
    if (!isFetched) {
      refetch();
    }
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
      if (opened && !isFetched) {
        refetch();
      }
    }
  }, [isFetched, opened, ref, refetch]);

  const isFetching = !isFetched && (opened || selected.length > 0);

  const open = (opened && Boolean(anchorEl)) || false;

  return (
    <>
      <ListFilterSelectChipTrigger
        ref={ref}
        active={open}
        label={USER_FILTER_LABEL}
        value={selectedOptions}
        onClear={onClear}
        onClick={toggleDropdown}
        loading={isFetching}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <ListFilterMultiSelect
            options={options}
            value={selectedOptions}
            onSelect={onSelectHandler}
            enableSearch
            title={`Select ${USER_FILTER_LABEL} Filter`}
            loading={isFetching}
            {...analyticsProps}
          />
        </Box>
      </Popover>
    </>
  );
}
