import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListFilterOption } from '@components/ListFilters/common';
import { ROLE_FILTER_PARAM } from '@Identities/common/constants';

import ListFilter from '@components/ListFilters/ListFilter';
import ListFilterSelectChipTrigger from '@components/ListFilters/ListFilterSelectChipTrigger';
import ListFilterMultiSelect from '@components/ListFilters/ListFilterMultiSelect';
import { PAGE_PARAM } from '@api';

export interface RolesFilterOption {
  value: string;
  label: string;
}

interface RolesFilterProps {
  options: RolesFilterOption[];
  opened?: boolean;
}

export const ROLE_FILTER_LABEL = 'Apono Role';

export default function RolesFilter({ options, opened }: RolesFilterProps) {
  const [isOpen, setIsOpen] = useState(opened || false);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQp = searchParams.getAll(ROLE_FILTER_PARAM);

  const selectedOptions = [];
  for (const option of options) {
    if (selectedQp.includes(option.value)) {
      selectedOptions.push(option);
    }
  }

  const applySearchParams = useCallback(
    (sp: URLSearchParams) => {
      if (sp.has(PAGE_PARAM)) sp.delete(PAGE_PARAM);
      setSearchParams(sp);
    },
    [setSearchParams],
  );

  const handleOnSelect = (values: ListFilterOption[]) => {
    searchParams.delete(ROLE_FILTER_PARAM);
    for (const value of values) {
      searchParams.append(ROLE_FILTER_PARAM, value.value);
    }
    applySearchParams(searchParams);
  };

  const handleOnClear = () => {
    searchParams.delete(ROLE_FILTER_PARAM);
    applySearchParams(searchParams);
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <ListFilter
      trigger={
        <ListFilterSelectChipTrigger
          active={isOpen}
          label={ROLE_FILTER_LABEL}
          value={selectedOptions}
          onClear={handleOnClear}
        />
      }
      popoverProps={{
        isOpen,
        toggleDropdown: () => {
          setIsOpen((prev) => !prev);
        },
        closeDropdown,
      }}
    >
      <ListFilterMultiSelect
        options={options}
        value={selectedOptions}
        onSelect={handleOnSelect}
        title="Select Apono Role Filter"
      />
    </ListFilter>
  );
}
