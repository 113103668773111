import { useMemo, useState } from 'react';
import { AddIntegrationSelectField } from './AddIntegrationSelectField';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from '@routes';
import { LabeledTextField, Loader } from '@components';
import { Integration, IntegrationConfigAppModel as IntegrationConfig } from '@api';
import { AddIntegrationParamsForm } from '@Integrations/organisms/AddIntegration/AddIntegrationParamsForm';
import { CloudSecretForm, SecretConfig } from '@Integrations/organisms/AddIntegration/CloudSecretForm';
import { useIntegrationConnectionConfig } from '@hooks/integrations-catalog.hooks';
import OpenReplayObscured from '@utils/analytics/OpenReplayObscured';

export function ConnectIntegrationForm({
  integration,
  integrationConfig,
  onChange,
  disabled,
}: {
  integration: Integration;
  integrationConfig: IntegrationConfig;
  onChange: (v: Integration) => void;
  disabled?: boolean;
}) {
  const navigate = useNavigate();
  const { integrationConnectionConfig, getConnectorAccount, hasLoaded } = useIntegrationConnectionConfig();
  const [cloudAccountId, setCloudAccountId] = useState<string | undefined>(undefined);
  const connectionConfig = integrationConnectionConfig[integrationConfig.connection_type];
  const isHasCloudSecret = integrationConfig.supported_secret_types.length > 0;

  const selectedValue = useMemo(() => {
    const connector_id = integration.provisioner_id;
    const aws_account_id = (integration.metadata as { aws_account_id?: string }).aws_account_id;

    if (!connector_id) return;

    return connectionConfig?.values.find((item) => {
      try {
        const { connector_id: item_connector_id, cloud_account: item_cloud_account } = JSON.parse(item.id);
        return connector_id === item_connector_id && (!aws_account_id ? true : aws_account_id === item_cloud_account);
      } catch (e) {
        return false;
      }
    })?.id;
  }, [integration, connectionConfig]);

  if (!hasLoaded || !connectionConfig) {
    return <Loader />;
  }

  return (
    <OpenReplayObscured>
      <Stack spacing={4}>
        <Stack spacing={1}>
          {integrationConfig.connection_type === 'oauth' ? null : (
            <LabeledTextField
              onChange={(e) => {
                onChange({
                  ...integration,
                  name: e.target.value || '',
                });
              }}
              label={'Integration Name'}
              placeholder={'Integration name'}
              defaultValue={integration.name}
              disabled={disabled}
            />
          )}
          {integrationConfig.connection_type === 'none' || integrationConfig.connection_type === 'oauth' ? null : (
            <AddIntegrationSelectField
              label={connectionConfig.label}
              values={connectionConfig.values}
              selectedValue={selectedValue}
              placeholder={connectionConfig.placeholder}
              onChange={(value) => {
                if (value && value.length > 0) {
                  const { connector_id, cloud_account } = JSON.parse(value);
                  setCloudAccountId(cloud_account);
                  switch (connector_id) {
                    case 'manual':
                      navigate(routes.InstallConnector.path);
                      break;
                    default: {
                      const connector = connectionConfig.values.find((item) => {
                        const { connector_id: item_connector_id, cloud_account: item_cloud_account } = JSON.parse(
                          item.id,
                        );
                        return connector_id === item_connector_id && cloud_account === item_cloud_account;
                      });
                      const md = connector?.md || {};
                      onChange({
                        ...integration,
                        metadata: { ...integration.metadata, ...md },
                        provisioner_id: connector_id,
                      });
                    }
                  }
                } else {
                  onChange({
                    ...integration,
                    provisioner_id: value,
                  });
                }
              }}
              disabled={disabled}
            />
          )}
        </Stack>
        <AddIntegrationParamsForm
          params={integrationConfig.params || []}
          values={integration.metadata as unknown as { [k: string]: string }}
          onChange={(params) => {
            onChange({
              ...integration,
              metadata: {
                ...integration.metadata,
                ...(params as unknown as { [k: string]: object }),
              },
            });
          }}
          disabled={disabled}
        />
        {isHasCloudSecret ? (
          <CloudSecretForm
            secretConfig={integration.secret_config as SecretConfig}
            required={integrationConfig.requires_secret}
            secretParams={integrationConfig.secret_params}
            supportedSecretTypes={integrationConfig.supported_secret_types}
            cloudAccountId={getConnectorAccount(integration.provisioner_id || '') || cloudAccountId}
            onChange={(secretConfig) =>
              onChange({
                ...integration,
                secret_config: secretConfig,
              })
            }
            disabled={disabled}
          />
        ) : null}
      </Stack>
    </OpenReplayObscured>
  );
}
