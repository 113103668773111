import { FormEvent, useState } from 'react';

import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import { Markdown, Page } from '@components';
import { DocsUrls } from '@utils';
import { LoadingButton } from '@mui/lab';
import { Unmasked, useCreateUserToken } from '@api';

import { Link } from 'react-router-dom';
import OpenReplayObscured from '@utils/analytics/OpenReplayObscured';

export function AddPersonalApiTokenPage() {
  const breadcrumbs = [{ label: 'Personal API Tokens', href: '/personal-api-tokens' }];

  return (
    <Page
      title="Add API Token"
      breadcrumbs={breadcrumbs}
      link={{
        label: 'API Reference',
        href: DocsUrls.PERSONAL_API_TOKENS,
      }}
      contentNarrowed
      contentPadded
    >
      <Stack spacing={3}>
        <div>
          <Typography variant="subtitle1" component="h2">
            Add Personal API Token
          </Typography>
          <Typography variant="body2" component="div">
            <Markdown>
              {`Apono Personal API Tokens can be used for integration with external tools and working with the Apono API.
            Please specify a descriptive name to be used for this personal API token. Tokens are specific to users. You
            can learn more about the Apono API [here](${DocsUrls.PERSONAL_API_TOKENS})`}
            </Markdown>
          </Typography>
        </div>
        <GenerateTokenForm />
      </Stack>
    </Page>
  );
}

function useGenerateTokenForm() {
  const [name, setName] = useState('');

  const { createUserToken, newUserToken, isCreateTokenLoading } = useCreateUserToken();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createUserToken({
      name,
    });
  };

  return {
    name,
    setName,
    newUserToken,
    isLoading: isCreateTokenLoading,
    handleSubmit,
  };
}

function GenerateTokenForm() {
  const { name, setName, newUserToken, isLoading, handleSubmit } = useGenerateTokenForm();

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          title="Name"
          placeholder="Type token name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          size="small"
          fullWidth
          inputProps={{
            minLength: 3,
          }}
          disabled={!!newUserToken}
          required
        />
        <GeneratedToken isLoading={isLoading} newUserToken={newUserToken} />
      </Stack>
    </form>
  );
}

interface GeneratedTokenProps {
  newUserToken?: Unmasked;
  isLoading: boolean;
}

function GeneratedToken({ newUserToken, isLoading }: GeneratedTokenProps) {
  if (!newUserToken) {
    return (
      <div>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>
          Generate New Token
        </LoadingButton>
      </div>
    );
  }

  return (
    <OpenReplayObscured>
      <Typography variant="subtitle1" component="h2">
        Token
      </Typography>
      <Typography variant="body2" component="div" id="new-token-container">
        <Markdown>
          {[
            'A new Apono API Token was created. Make sure to save this token. You will not be able to view it again.',
            '```',
            newUserToken.token,
            '```',
          ].join('\n')}
        </Markdown>
      </Typography>
      <Box mt={3}>
        <Button variant="outlined" component={Link} to="/personal-api-tokens">
          Done
        </Button>
      </Box>
    </OpenReplayObscured>
  );
}
