import { useMemo } from 'react';

import { RESOURCE_TYPE_FILTER_PARAM } from '@common/constants';
import useFilterSearchParams from '@common/hooks/use-filter-search-params';

import { useAppListAccessBundles } from '@AccessFlows/services/bundlesQueries';

import { BundlesListItemProps } from '@AccessFlows/organisms/BundlesList';
import { INTEGRATION_FILTER_PARAM } from '@AccessFlows/common/constants';

import { useLoadingProgress } from './shared';
import { useRemappedIntegrations } from '@common/hooks/use-remapped-integrations';
import { FilterOption } from '@common/organisms/CommonFilters/GenericFilterMultiSelect';

export default function useBundlesFetch() {
  const { bundles, isFetched: isBundlesFetched } = useAppListAccessBundles();

  const { remappedIntegrations, isIntegrationsFetched } = useRemappedIntegrations();

  const loadingProgress = useLoadingProgress([isBundlesFetched, isIntegrationsFetched]);

  const { isFilteredBy, isSearchedBy } = useFilterSearchParams();

  return useMemo(() => {
    const bundlesList: BundlesListItemProps[] = [];
    const integrationFilterOptionsMap: Record<string, FilterOption> = {};
    const resourceTypeFilterOptionsMap: Record<string, FilterOption> = {};

    for (const bundle of bundles) {
      const integrationsToFilterBy = new Set<string>();
      const resourceTypesToFilterBy = new Set<string>();

      const targets = [];
      for (const target of bundle.targets) {
        if (!target.integration) continue;

        integrationsToFilterBy.add(target.integration.integration_id);
        resourceTypesToFilterBy.add(target.integration.resource_type);

        const integration = remappedIntegrations[target.integration.integration_id];
        if (!integration) continue;

        integrationFilterOptionsMap[integration.integration.id] = {
          value: integration.integration.id,
          label: integration.integration.name,
          icon: integration.config.icons.svg,
          searchHelper: `${integration.integration.name} ${integration.config.name}`,
        };

        const rtConfig = integration.config.resource_types[target.integration.resource_type];
        if (rtConfig) {
          resourceTypeFilterOptionsMap[rtConfig.type] = {
            value: rtConfig.type,
            label: rtConfig.display_name,
            icon: rtConfig.icons.svg,
          };
        }

        targets.push({
          icon: rtConfig?.icons.svg ?? integration.config.icons.svg,
          target,
        });
      }

      if (
        isFilteredBy(INTEGRATION_FILTER_PARAM, integrationsToFilterBy) &&
        isFilteredBy(RESOURCE_TYPE_FILTER_PARAM, resourceTypesToFilterBy) &&
        isSearchedBy(new Set([bundle.name, ...resourceTypesToFilterBy]))
      ) {
        bundlesList.push({
          bundle,
          targets,
        });
      }
    }

    return {
      bundlesList,
      loadingProgress,
      filterOptions: {
        integration: Object.values(integrationFilterOptionsMap),
        resourceType: Object.values(resourceTypeFilterOptionsMap),
      },
      totalBundles: bundles.length,
    };
  }, [bundles, isFilteredBy, isSearchedBy, loadingProgress, remappedIntegrations]);
}
