import { Outlet } from 'react-router-dom';

import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';
import { Page } from '@components';
import { routes } from '@routes';
import { useMemo } from 'react';

export default function IdentitiesLayout() {
  const { isEnabled } = useFlagFixed(Flag.GROUPS_PAGE);

  const tabs = useMemo(() => {
    if (!isEnabled) return undefined;

    return [
      { label: 'Users', to: routes.Identities.path },
      { label: 'Groups', to: routes.Groups.path },
    ];
  }, [isEnabled]);

  return (
    <Page title="Identities" tabs={tabs}>
      <Outlet />
    </Page>
  );
}
