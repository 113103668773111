import { Outlet } from 'react-router-dom';
import { Page } from '@components';
import { AccessGraphButton } from '@AccessGraph/organisms/AccessGraphButton';
import { Flag } from '@utils';
import { useFlagFixed } from '@hooks';
import { routes } from '@AccessFlows/routes';

export default function AccessFlowsLayout() {
  const { isEnabled: isAccessGraphEnabled } = useFlagFixed(Flag.APONO_ACCESS_GRAPH);
  const { isEnabled: isAccessRightSizingEnabled } = useFlagFixed(Flag.ACCESS_RIGHT_SIZING);

  let tabs = undefined;

  if (isAccessRightSizingEnabled) {
    tabs = [
      {
        label: 'Access Flows',
        to: routes.AccessFlows.path,
      },
      {
        label: 'Right Sizing',
        to: routes.AccessRightSizing.path,
      },
    ];
  }

  return (
    <Page title="Access Flows" tabs={tabs} button={isAccessGraphEnabled ? <AccessGraphButton /> : undefined}>
      <Outlet />
    </Page>
  );
}
