import { FC } from 'react';
import { Avatar, Button, Link, Stack, styled, Typography } from '@mui/material';
import { generatePath } from 'react-router-dom';
import { routes } from '@routes';
import { RouterLink } from '@libs';
import { MaterialIcon } from '@components';
import { useFlagFixed } from '@hooks';
import { Flag } from '@utils';

const AccessFlowSuggestItemStyled = styled(Stack)(({ theme }) => ({
  display: 'block',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    border: `1px solid ${theme.palette.border.button}`,
  },
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

type AccessFlowSuggestItemProps = {
  id: string;
  resourceType: string;
  displayName: string;
  type: string;
  iconPath: string;
  synced: number;
  failed: number;
  textBtn: string;
  basePath?: string;
};

const SyncedResources = ({
  synced,
  type,
  basePath,
}: Pick<AccessFlowSuggestItemProps, 'synced' | 'type' | 'basePath'>) => {
  const text = `${type + (synced > 1 ? 's' : '')} synced`;
  if (basePath) {
    return (
      <Link component={RouterLink} underline="hover" color="text.muted" variant="tooltip" to={basePath}>
        <ResourcesFoundText count={synced} text={text} color="text.primary" />
      </Link>
    );
  } else {
    return (
      <Typography variant="tooltip">
        <ResourcesFoundText count={synced} text={text} color="text.primary" />
      </Typography>
    );
  }
};
const FailedResources = ({
  failed,
  type,
  basePath,
}: Pick<AccessFlowSuggestItemProps, 'failed' | 'type' | 'basePath'>) => {
  const text = `${' ' + type + (failed > 1 ? 's' : '')} failed`;
  if (basePath) {
    return (
      <Link component={RouterLink} underline="hover" color="error.main" variant="tooltip" to={basePath}>
        <ResourcesFoundText count={failed} text={text} color="error.main" />
      </Link>
    );
  } else {
    return (
      <Typography variant="tooltip">
        <ResourcesFoundText count={failed} text={text} color="error.main" />
      </Typography>
    );
  }
};

const ResourcesFoundText = ({ count, text, color }: { count: number; text: string; color: string }) => (
  <>
    <Typography variant="body2" color={color} component="span">
      <strong>{count}</strong>{' '}
    </Typography>
    {text}
  </>
);
export const IntegrationResourceTypeItem: FC<AccessFlowSuggestItemProps> = ({
  id,
  resourceType,
  displayName,
  type,
  iconPath,
  synced,
  failed,
  basePath,
  textBtn,
}) => {
  const { isEnabled: isResourceOwnersEnabled } = useFlagFixed(Flag.RESOURCE_OWNERS);

  const isNoResourcesSynced = synced === 0;
  const path = basePath
    ? `${generatePath(basePath, { id })}?resource_type=${isResourceOwnersEnabled ? resourceType : displayName}`
    : undefined;

  return (
    <AccessFlowSuggestItemStyled>
      <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1} gap={1} justifyContent="space-between">
          <Avatar src={iconPath} variant="square" sx={{ width: 24, height: 24 }} />
          <Stack direction="column" alignItems="start">
            <Typography variant="subtitle2" noWrap>
              {displayName}
            </Typography>
            <Stack direction="row" spacing={2}>
              {synced > 0 && <SyncedResources synced={synced} type={type} basePath={path} />}
              {isNoResourcesSynced && (
                <Typography variant="tooltip" color="text.muted">
                  No resources found
                </Typography>
              )}
              {failed > 0 && (
                <FailedResources failed={failed} type={type} basePath={path ? `${path}&search=error` : undefined} />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Button
          data-testid="create-access-flow-btn"
          variant="outlined-narrow"
          startIcon={<MaterialIcon symbol="add" />}
          component={RouterLink}
          to={`${routes.AddAccessFlow.path}?fromIntegration=${id}&withResourceType=${resourceType}`}
          disabled={isNoResourcesSynced}
        >
          <Typography variant="buttonSmall" noWrap component="span">
            {textBtn}
          </Typography>
        </Button>
      </Stack>
    </AccessFlowSuggestItemStyled>
  );
};
