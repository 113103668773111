import { PropsWithChildren, ReactNode } from 'react';

import { Box, Stack, styled, Typography } from '@mui/material';

const ActivityTimelineItemMetaContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
}));

export interface ActivityTimelineItemMetaProps {
  title: string;
  icon?: ReactNode;
  bordered?: boolean;
}

export function ActivityTimelineItemMeta({
  title,
  icon,
  bordered,
  children,
}: PropsWithChildren<ActivityTimelineItemMetaProps>) {
  if (!bordered) {
    return (
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} py={1}>
        {icon}
        {children}
        <Typography variant="tooltip" color="text.muted">
          {title}
        </Typography>
      </Stack>
    );
  }

  return (
    <ActivityTimelineItemMetaContainer>
      <Typography variant="tooltip" component="p" color="text.muted">
        {title}
      </Typography>
      {children}
    </ActivityTimelineItemMetaContainer>
  );
}
