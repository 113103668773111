import { PropsWithChildren, useMemo, useState } from 'react';
import { TagAppModel, useAppGetIntegration, useIntegrationResourcesV2 } from '@api';
import {
  DrawerType,
  ID_TAG,
  NAME_TAG,
  SelectResourceDrawerContext,
  SelectResourceDrawerContextProps,
  SelectResourceMode,
} from './context';
import { usePopover } from '@components';
import { IntegrationInfo } from '../../common';
import { useErrorText } from '../../common/use-error-text.hook';

export interface SelectResourceDrawerProviderProps {
  integration: IntegrationInfo;
  template?: string;
  matchers?: TagAppModel[];
  excludes?: TagAppModel[];
  onChange: (restrictors: TagAppModel[], excludes: TagAppModel[]) => void;
}

function useSelectResourceDrawerProvider({
  integration,
  matchers = [],
  excludes = [],
  onChange,
}: SelectResourceDrawerProviderProps): SelectResourceDrawerContextProps {
  const { errorTextHidden, hideErrorText, showErrorText } = useErrorText();

  const initMode = useMemo(() => {
    if (matchers.length > 0) {
      const firstMatcher = matchers[0];
      if (firstMatcher.name === ID_TAG || firstMatcher.name === NAME_TAG) {
        return SelectResourceMode.ByName;
      } else {
        return SelectResourceMode.ByTag;
      }
    }

    if (excludes.length > 0) {
      return SelectResourceMode.AnyExcept;
    }

    return SelectResourceMode.Any;
  }, [excludes, matchers]);

  const initTagKey = useMemo(() => {
    if (matchers.length > 0) {
      const firstMatcher = matchers[0];
      if (firstMatcher.name !== ID_TAG && firstMatcher.name !== NAME_TAG) {
        return firstMatcher.name;
      }
    }

    return undefined;
  }, [matchers]);

  const [selectedMode, setSelectedMode] = useState<SelectResourceMode>(initMode);
  const [selectedTagsKey, setSelectedTagsKey] = useState<string | undefined>(initTagKey);
  const [selectedMatchers, setSelectedMatchers] = useState<TagAppModel[]>(matchers);
  const [selectedExcludes, setSelectedExcludes] = useState<TagAppModel[]>(excludes);

  const [drawerOpened, setDrawerOpened] = useState<DrawerType | undefined>();
  const [showSelectTagsKeys, setShowSelectTagsKeys] = useState<boolean>(false);

  const { integrationConfig, isIntegrationFetched } = useAppGetIntegration(integration.integrationId);
  const { isFetched: isResourcesFetched, integrationResources: resources } = useIntegrationResourcesV2(
    integration.integrationId,
    integration.resourceType,
  );

  const externalState = usePopover({
    onOpen: () => {
      setSelectedMode(initMode);
      setSelectedMatchers(matchers);
      setSelectedExcludes(excludes);
      setSelectedTagsKey(initTagKey);
      setDrawerOpened(undefined);
      setShowSelectTagsKeys(false);
      hideErrorText();
    },
    onClose: () => {
      showErrorText();
    },
  });

  const onSelectMode = (mode: SelectResourceMode) => {
    setSelectedMode(mode);
    switch (mode) {
      case SelectResourceMode.Any:
        onChange([], []);
        setShowSelectTagsKeys(false);
        externalState.closeDropdown();
        break;
      case SelectResourceMode.ByTag:
        setShowSelectTagsKeys(true);
        break;
      default:
        setDrawerOpened(DrawerType.SelectResources);
        setShowSelectTagsKeys(false);
        externalState.closeDropdown();
        break;
    }
  };

  const onSelectTagsKey = (key: string) => {
    setSelectedTagsKey(key);
    setDrawerOpened(DrawerType.SelectTags);
    setShowSelectTagsKeys(false);
    externalState.closeDropdown();
  };

  const onDoneSelect = () => {
    onChange(selectedMatchers, selectedExcludes);
    setDrawerOpened(undefined);
    externalState.closeDropdown();
  };

  const isFetched: boolean =
    useMemo(() => isIntegrationFetched && isResourcesFetched, [isIntegrationFetched, isResourcesFetched]) ?? false;

  const resourceTypeHumanName = useMemo(
    () => integrationConfig?.resource_types[integration.resourceType]?.display_name,
    [integrationConfig, integration.resourceType],
  );

  return {
    resourceType: integration.resourceType,
    resourceTypeHumanName,
    externalState,
    isFetched,
    integrationConfig,
    resources,
    selectedMode,
    onSelectMode,
    selectedMatchers,
    onSelectMatchers: setSelectedMatchers,
    selectedExcludes,
    onSelectExcludes: setSelectedExcludes,
    selectedTagsKey,
    onSelectTagsKey,
    drawerOpened,
    onOpenDrawer: (type: DrawerType) => setDrawerOpened(type),
    onCloseDrawer: () => setDrawerOpened(undefined),
    onDoneSelect,
    showSelectTagsKeys,
    errorTextHidden,
  };
}

export function SelectResourceDrawerProvider({
  children,
  ...props
}: PropsWithChildren<SelectResourceDrawerProviderProps>) {
  const value = useSelectResourceDrawerProvider(props);
  return <SelectResourceDrawerContext.Provider value={value}>{children}</SelectResourceDrawerContext.Provider>;
}
