import { Box, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import OpenReplayObscured from '@utils/analytics/OpenReplayObscured';
import { Markdown } from '@components';
import DisableClick from '@common/components/DisableClick';

export default function AccessDetailsPreview({ accessDetails }: { accessDetails?: string }) {
  const [animation, setAnimation] = useState<'pulse' | false>('pulse');

  // Stop the animation after 4.5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => setAnimation(false), 4500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Stack spacing={2}>
      <Tabs value="Instructions">
        <Tab label="Instructions" value="Instructions" sx={{ cursor: 'default' }} />
      </Tabs>
      {accessDetails ? (
        <AccessDetailsTab isActive>
          <Typography variant="body2" component="div">
            <DisableClick>
              <Markdown>{accessDetails}</Markdown>
            </DisableClick>
          </Typography>
        </AccessDetailsTab>
      ) : (
        <Stack>
          <Skeleton height={40} animation={animation} />
          <Skeleton height={40} animation={animation} />
          <Skeleton height={40} animation={animation} />
        </Stack>
      )}
    </Stack>
  );
}

function AccessDetailsTab({ isActive = false, children }: PropsWithChildren<{ isActive?: boolean }>) {
  return (
    <div role="tabpanel" hidden={!isActive}>
      {isActive && (
        <OpenReplayObscured>
          <Box sx={{ pX: 2 }}>{children}</Box>
        </OpenReplayObscured>
      )}
    </div>
  );
}
