import { IntegrationConfigAppModelV2, ResourceAppModel, TagAppModel } from '@api';
import { UsePopoverProps } from '@components';
import { createContext, useContext } from 'react';

export const ID_TAG = '__id';
export const NAME_TAG = '__name';

export enum SelectResourceMode {
  Any = 'any',
  AnyExcept = 'anyExcept',
  ByName = 'byName',
  ByTag = 'byTag',
}

export enum DrawerType {
  SelectResources = 'SelectResources',
  SelectTags = 'SelectTags',
}

export interface SelectResourceDrawerContextProps {
  resourceType: string;
  resourceTypeHumanName: string | undefined;
  externalState: UsePopoverProps | undefined;
  isFetched: boolean;
  resources: ResourceAppModel[];
  integrationConfig: IntegrationConfigAppModelV2 | undefined;
  selectedMode: SelectResourceMode;
  onSelectMode: (val: SelectResourceMode) => void;
  selectedMatchers: TagAppModel[];
  onSelectMatchers: (val: TagAppModel[]) => void;
  selectedExcludes: TagAppModel[];
  onSelectExcludes: (val: TagAppModel[]) => void;
  selectedTagsKey: string | undefined;
  onSelectTagsKey: (val: string) => void;
  drawerOpened: DrawerType | undefined;
  onOpenDrawer: (type: DrawerType) => void;
  onCloseDrawer: () => void;
  onDoneSelect: () => void;
  showSelectTagsKeys: boolean;
  errorTextHidden: boolean;
}

export const SelectResourceDrawerContext = createContext<SelectResourceDrawerContextProps>({
  resourceType: '',
  resourceTypeHumanName: undefined,
  externalState: undefined,
  isFetched: false,
  resources: [],
  integrationConfig: undefined,
  selectedMode: SelectResourceMode.Any,
  onSelectMode: () => {},
  selectedMatchers: [],
  onSelectMatchers: () => {},
  selectedExcludes: [],
  onSelectExcludes: () => {},
  selectedTagsKey: undefined,
  onSelectTagsKey: () => {},
  drawerOpened: undefined,
  onOpenDrawer: () => {},
  onCloseDrawer: () => {},
  onDoneSelect: () => {},
  showSelectTagsKeys: false,
  errorTextHidden: true,
});

export const useSelectResourceDrawerContext = () => {
  return useContext<SelectResourceDrawerContextProps>(SelectResourceDrawerContext);
};
