import { Stack } from '@mui/material';
import { OnboardingDrawer } from '@Onboarding/layouts/OnboardingDrawer/OnboardingDrawer';
import { Outlet } from 'react-router-dom';

export function OnboardingLayout() {
  return (
    <Stack direction="row" spacing={2}>
      <OnboardingDrawer />
      <Stack direction="column" spacing={2} sx={{ width: '100%', minHeight: '100vh' }}>
        <Outlet />
      </Stack>
    </Stack>
  );
}
