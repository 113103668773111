import { Avatar, Stack, Typography } from '@mui/material';
import { IntegrationRowModel } from '@DashboardV2/components/TopListWidget/IntegrationRows';
import PrimarySecondary from '@common/ui/PrimarySecondary';

const BoxStyle = {
  width: 12,
  height: 12,
  borderRadius: 2,
};

export function IntegrationRow({ iconSrc, title, subTitle, color, sum, percent }: IntegrationRowModel) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2} alignItems="center">
        <div style={{ ...BoxStyle, backgroundColor: color }} />
        {iconSrc && <Avatar src={iconSrc} variant="square" sx={{ width: 24, height: 24 }} />}
        <PrimarySecondary primary={title} secondary={subTitle} />
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-end">
        <Typography variant="helperText" width={50} align="right">
          {sum}
        </Typography>
        <Typography variant="helperText" width={50} align="right">
          {percent}%
        </Typography>
      </Stack>
    </Stack>
  );
}
