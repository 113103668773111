import { Box, styled } from '@mui/material';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import dracula from 'prism-react-renderer/themes/okaidia';

import { aponoColors } from '@utils/colors';
import { CopyButton } from '../CopyButton';
import { ReactNode } from 'react';
import { AnalyticsTriggerElementProps } from '@common/types';

const InlineCode = styled('code')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  padding: `0 ${theme.spacing(4)} 0 ${theme.spacing(1)}`,

  '& > button': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    zIndex: 99,
  },
}));

function Inline({ className, children }: { className?: string; children: string }) {
  return (
    <InlineCode className={className}>
      {children}
      <CopyButton text={children} />
    </InlineCode>
  );
}

const MultilineCode = styled(Box)(({ theme }) => ({
  backgroundColor: aponoColors.neutral[900],
  padding: theme.spacing(2),
  paddingRight: theme.spacing(8),
  borderRadius: theme.spacing(1),
  position: 'relative',
  fontSize: '0.9em',

  '& > button': {
    position: 'absolute',
    top: theme.spacing(1.3),
    right: theme.spacing(1.3),
    zIndex: 99,
    color: aponoColors.neutral[50],
  },

  '& .token-line': {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },

  '&.with-whitespaces': {
    '& .token-line': {
      whiteSpace: 'pre-wrap',
    },
  },
}));

export function Code({
  inline,
  className,
  children,
  withWhitespaces = false,
  language = 'bash',
  ...analyticsProps
}: {
  inline?: boolean;
  className?: string;
  children: ReactNode;
  language?: Language;
  withWhitespaces?: boolean;
} & AnalyticsTriggerElementProps) {
  const stringCode = String(children)
    .replace(/\n$/, '')
    .replace(/&quot;/g, '\\"')
    .trim();

  if (inline) {
    return <Inline className={className}>{stringCode}</Inline>;
  }

  return (
    <Highlight {...defaultProps} code={stringCode} language={language} theme={dracula}>
      {({ className: clasName, tokens, getLineProps, getTokenProps }) => (
        <MultilineCode className={`${clasName} ${withWhitespaces ? 'with-whitespaces' : ''}`}>
          <CopyButton text={stringCode} {...analyticsProps} />
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })} key={i}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} key={key} />
              ))}
            </div>
          ))}
        </MultilineCode>
      )}
    </Highlight>
  );
}
